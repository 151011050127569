<!-- Handouts -->
<app-ui-modal #handoutModal [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true" id="handoutModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Handout Documents</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="handoutModal?.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 paddingcss">
                <!-- <h6 class="handout_list">List</h6> -->
                <div class="handout-pdf-list">
                    <div class="row">
                        <ng-container *ngFor="let document of allHandoutDocs; let i = index">
                            <div class="col-lg-6 col-md-6 col-sm-12 pb-0 pr-0 form-group text-left paddingcss1">
                                <div class="doc_name ellipse-text" [class.active]="selectedIndexActive === i"
                                    title="{{document?.documentName}}" (click)="viewSelectedDoc(document, i)">
                                    {{document?.documentName | titlecase}}</div>
                            </div>
                        </ng-container>
                        <!-- <div class="col-lg-6 col-md-6 col-sm-6 form-group text-left paddingcss1">
                            <div class="checkbox checkbox-primary d-flex">
                                <input type="checkbox" class="check-comp ng-pristine ng-valid ng-touched"
                                    id="Select Applicable Problems​Annual Wellness Exam">
                                <label class="handout-label">
                                    Handout pdf List 1
                                </label>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="handoutsPdfModal">
                    <iframe id="handouts-doc-pdf" frameBorder="0" width="100%" height="450px"></iframe>
                </div>
                <!-- <div class="handout-footer">
                    <a href="javascript:;" class="previous">
                        <i class="las la-angle-left"></i> Previous
                    </a>
                    <a href="javascript:;" class="btn btn-primary">
                        Close
                    </a>
                    <a href="javascript:;" class="next">
                        Next <i class="las la-angle-right"></i>
                    </a>
                </div> -->
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" disabled class="btn btn-primary dash-actions">Notify</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>