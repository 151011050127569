<div id="review-sec"> 
    <div class="row">
        <!-- <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="sticky-left-nav-items">

                <ng-container *ngFor="let module of moduleList; let modI = index">
                    <ng-container *ngIf="module?.name !== 'Demographics' && module?.name !== 'Review' && isShowHideElements[careFlowModuleAccess[module?.name]] && isDataisAvailable(module?.name)">
                        
                        <div class="navigation-item" [ngClass]="{'active': selectedLetNavIndex === (modI + 1)}"
                            (click)="scroll(createDynamicHtmlRefVar(module?.name), modI + 1)">{{module?.name}}</div>
                    </ng-container>
                </ng-container>
            </div>
        </div> -->
        <!-- <ng-container *ngFor="let module of moduleList; let modI = index">
            <ng-container *ngIf="module?.name">

            </ng-container>
        </ng-container> -->
        <div class="col-lg-12 col-md-12 col-sm-12 border-right-sec">
            <!-- <aw-wizard #wizard class="arc-wizard" class="new-designed-left-arcs" navBarLocation="left" [awNavigationMode]
        navigateBackward="allow" navigateForward="allow">
        <aw-wizard-step stepTitle="Chief Complaints" awOptionalStep> -->
            <section id="ChiefComplaintsSection" class="mb-4"
                *ngIf="allCareFlowData?.chiefComplaints && checkIfSavedSections(allCareFlowData?.chiefComplaints)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Chief Complaints</h5>
                    </div>
                </div>
                <app-review-cc [ccData]="allCareFlowData?.chiefComplaints"></app-review-cc>
                <hr>
            </section>

            <!-- </aw-wizard-step> -->

            <!-- History of Present Illness section -->
            <!-- <aw-wizard-step stepTitle="History of Present Illness" awOptionalStep> -->
            <section id="HPISection" class="mb-4"
                *ngIf="allCareFlowData?.hpi && allCareFlowData?.hpi?.length > 0 && checkIfSavedSections(allCareFlowData?.hpi)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>History of Present Illness</h5>
                    </div>
                </div>
                <app-review-hpi [hpiSummaryData]="allCareFlowData?.hpi"
                    [pastVisitMedications]="pastVisitMedications"></app-review-hpi>
                <hr>
            </section>

            <!-- </aw-wizard-step> -->
            <!-- End HPI -->

            <!-- Vitals section -->
            <!-- <aw-wizard-step stepTitle="Vitals" awOptionalStep> -->
            <section id="VitalsSection" class="mb-4"
                *ngIf="allCareFlowData?.vitals && allCareFlowData?.vitals?.length > 0 && checkIfSavedSections(allCareFlowData?.vitals)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Vitals</h5>
                    </div>
                </div>
                <app-review-vitals [vitalsData]="allCareFlowData?.vitals"></app-review-vitals>
                <hr>
            </section>

            <!-- </aw-wizard-step> -->

            <!-- Review of System section -->
            <!-- <aw-wizard-step stepTitle="Review of Systems" awOptionalStep> -->
            <section id="ROSSection" class="mb-4"
                *ngIf="allCareFlowData?.reviewOfsystems && checkIfSavedSections(allCareFlowData?.reviewOfsystems)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Review of Systems</h5>
                    </div>
                </div>
                <app-review-ros [rosData]="allCareFlowData?.reviewOfsystems"></app-review-ros>
                <hr>
            </section>

            <!-- </aw-wizard-step> -->

            <!-- Behavior Screening section -->
            <!-- <aw-wizard-step stepTitle="Behavior Screening" awOptionalStep> -->
            <section id="BehavioralScreeningSection"
                *ngIf="allCareFlowData?.behavioralScreening && checkIfSavedSections(allCareFlowData?.behavioralScreening)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Behavioral Screening</h5>
                    </div>
                </div>
                <app-review-behaviousscreening
                    [behaviourScreeningData]="allCareFlowData?.behavioralScreening"></app-review-behaviousscreening>
                <hr>
            </section>

            <!-- </aw-wizard-step> -->


            <section id="inhouseOrders" class="mb-1" id="OrdersSection"
                *ngIf="this.allInHouseProcedures?.length > 0 || this.allInternalVaccineDetails?.length > 0 || this.allInternalLabOrders?.length > 0 || allRxOrderDetails?.length > 0 || alllabRadOrderDetails?.length > 0">
                <div class="row">
                    <div class="col-sm-12">
                        <h5> Orders</h5>

                    </div>
                </div>

            </section>

            <!-- In House -Labs Section -->
            <section id="inhouselabs" class="mb-4" id="InhouseLabsSection"
                *ngIf="this.allInternalLabOrders?.length > 0">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>InHouse Labs</h5>
                    </div>
                </div>
                <app-review-inhouselabs [inhouseLabsData]="allInternalLabOrders"></app-review-inhouselabs>
                <hr>
            </section>

            <!-- In House Injections -->
            <section class="inhouse-injections mb-4" id="InhouseInjectionsSection"
                *ngIf="this.allInternalVaccineDetails?.length > 0">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Immunizations</h5>
                    </div>
                </div>
                <app-review-immunizations [immunizationData]="allInternalVaccineDetails"
                    [allergiesFromVaccine]="allergiesFromVaccine"></app-review-immunizations>
                <hr>
            </section>

            <!-- End- Inhouse Injections -->

            <!-- In House Procedures -->
            <section class="inhouse-injections mb-4" id="InhouseproceduresSection"
                *ngIf="this.allInHouseProcedures?.length > 0">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Procedures</h5>
                    </div>
                </div>
                <app-review-inhouse-procedures
                    [inhouseProceduresData]="allInHouseProcedures"></app-review-inhouse-procedures>
                <hr>
            </section>
            <!-- End- Inhouse Procedures -->

            <!-- Orders -->
            <section id="OrdersSection" class="mb-4"
                *ngIf="allRxOrderDetails?.length > 0 || alllabRadOrderDetails?.length > 0">
                <app-review-orders [allRxOrderDetails]="allRxOrderDetails"
                    [alllabRadOrderDetails]="alllabRadOrderDetails"
                    [PatientDetailsCardReload]="PatientDetailsCardReload" [notifyText]="notifyText"
                    [exceptionModal]="exceptionModal"></app-review-orders>
                <hr>
            </section>

            <!-- <aw-wizard-step stepTitle="Exam" awOptionalStep> -->
            <section id="ExamSection" class="mb-4"
                *ngIf="allCareFlowData?.exam && checkIfSavedSections(allCareFlowData?.exam)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Exam</h5>
                    </div>
                </div>
                <app-review-exam [examData]="allCareFlowData?.exam"></app-review-exam>
                <hr>
            </section>

            <!-- </aw-wizard-step> -->

            <!-- <aw-wizard-step stepTitle="Plan" awOptionalStep> -->


            <!-- </aw-wizard-step> -->

            <!-- <aw-wizard-step stepTitle="Assessment" awOptionalStep> -->
            <section class="patient-consent" id="AssessmentSection"
                *ngIf="allCareFlowData?.carePlan && checkIfSavedSectionsCarePlan(allCareFlowData?.carePlan, 'Assessment')">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                       <h5>Assessment</h5>
                    </div>
                </div>
                <!--<app-review-assessment [carePlanData]="allCareFlowData?.carePlan"
                    [codeMissingAssessments]="codeMissingAssessments"></app-review-assessment>-->
					
				<ng-container *ngFor="let category of allCareFlowData?.carePlan; let cati = index">
                <!--<h5>{{category?.resourceType}}</h5>-->
                <ng-contaier *ngFor="let section of category?.sections">
                    <!-- *ngIf="section?.name !== 'New Orders'" -->
                    <ng-contaier>
                       <!-- <h6 *ngIf="section?.name !== 'New Orders'"><u>{{section?.name}}</u></h6>-->
                        <div class="row">

                            <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let answer of section?.answers">
								<ng-contaier *ngIf="section?.name === 'Assessment'">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="table-responsive">
                                                <table class="table table-striped table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>ICD Code</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let tbody of answer?.actualValue; let listi = index">
                                                            <td>{{tbody?.code}}</td>
                                                            <td>{{tbody?.description || cmpareCodesDesc(tbody?.code)}}</td>
                                                        </tr>
                                                        <tr *ngIf="answer?.actualValue?.length === 0">
                                                            <td colspan="2" class="text-center">ICD Codes are unavailable for this patient.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </ng-contaier>

                                
                            </div>
                        </div>
                    </ng-contaier>
                </ng-contaier>

                <!-- <ng-container *ngIf="category?.otherNotes">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Additional Notes</label> <span
                                class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{category?.otherNotes || '--'}}</label>
                        </div>
                    </div>
                </ng-container> -->
                
            </ng-container>
				
                <hr>
            </section>

            <!-- </aw-wizard-step> -->

            <!-- Only Plan sections Display -->
            <section class="patient-consent" id="PlanSection"
                *ngIf="allCareFlowData?.carePlan && checkIfSavedSectionsCarePlan(allCareFlowData?.carePlan, 'Plan')">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5>Plan</h5>
                    </div>
                </div>
                <app-review-careplan [planData]="allCareFlowData?.carePlan"></app-review-careplan>
                <hr>
            </section>

        </div>
    </div>
    <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12">
            <!-- <div class="text-sm-left mt-4">
                <a href="javascript:" class="btn btn-primary text-sm-left mt-md-0 mt-2" (click)="goPrevious()">
                    <i class="mdi mdi-truck-fast mr-1"></i> Back </a>

            </div> -->
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12">
            <div class="text-sm-right mt-4">
                <button class="btn btn-primary text-sm-right mt-md-0 mt-2" id="review_signOff"
                    [disabled]="loginUserDetails?.roleType !== 'Doctor'"
                    (click)="validateIcdBillingCodes(validateICdBillingCodesModal)">Sign Off</button>
                <!-- <a href="javascript:" [disabled]="loginUserDetails?.roleType !== 'Doctor'" (click)="validateIcdBillingCodes(validateICdBillingCodesModal)" class="btn btn-primary text-sm-right mt-md-0 mt-2"><i
                        class="mdi mdi-truck-fast mr-1"></i> Sign Off
                </a> -->
            </div>
        </div>
    </div>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<app-ui-modal #claimSubmitModal [hideHeader]="false" [modalCentered]="true" id="claimSubmitModal">
    <div class="app-modal-header">
        <h5>Electronically Signed by{{appointmentDetails?.appointment?.authorized_official_last_name}},
            {{appointmentDetails?.appointment?.authorized_official_first_name}}
            </h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="claimSubmitModal?.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body text-center">
        <h5>E-Signature</h5>
        <div class="signature">
            <h5><i>{{appointmentDetails?.appointment?.authorized_official_last_name}},
                    {{appointmentDetails?.appointment?.authorized_official_first_name}}</i></h5>
        </div>
        <h5 class="mt-4">Initials</h5>
        <div class="signature">
            <h5><i>{{getInitailsDoctorName()}}</i></h5>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" style="margin-right: 15px;" data-dismiss="modal" id="review_ok1"
            (click)="claimSubmitModal?.hide();lockUnlockSignoffModal?.show()">Ok</button>
    </div>
</app-ui-modal>

<!-- Validate the ICD and Billing Codes -->
<app-ui-modal #validateICdBillingCodesModal [hideHeader]="false" [modalCentered]="true"
    id="validateICdBillingCodesModal">
    <div class="app-modal-header">
        <!-- <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="validateICdBillingCodesModal?.hide();signOffCall()"><span aria-hidden="true">&times;</span></button> -->
    </div>
    <div class="app-modal-body text-center">
        <h5>{{validateMessage}}</h5>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" style="margin-right: 15px;" data-dismiss="modal" id="review_ok2"
            (click)="validateICdBillingCodesModal?.hide();signOffCall()">Ok</button>
    </div>
</app-ui-modal>

<!-- Validate the ICD and Billing Codes -->
<app-ui-modal #lockUnlockSignoffModal [hideHeader]="false" [modalCentered]="true" id="lockUnlockSignoffModal">
    <div class="app-modal-header">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="lockUnlockSignoffModal?.hide();signOffCall()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body text-center">
        <!-- <h5>Do you want to secure the patient chart from edits?</h5> -->
        <div class="app-modal-body">
            <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                        <!-- <i class="las la-exclamation-triangle"></i> -->
                        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                    </h5>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10">
                    <h5 class="modal-title" style=" text-align: left;">Do you want to secure the patient chart from
                        edits?</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" id="review_yes" class="btn btn-primary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="lockUnlockSignoffModal?.hide();lockUnlockSignoff(false)">Yes</button>
        <button type="button" id="review_no" class="btn btn-primary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="lockUnlockSignoffModal?.hide();lockUnlockSignoff(true)">No</button>
    </div>
</app-ui-modal>