import { Component, Input } from '@angular/core';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-review-inhouse-procedures',
  templateUrl: './review-inhouse-procedures.component.html',
  styleUrls: ['./review-inhouse-procedures.component.scss']
})
export class ReviewInhouseProceduresComponent {

  @Input() inhouseProceduresData: any;

  constructor(public httpService: HttpService) { }

}
