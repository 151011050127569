import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ViewportScroller } from "@angular/common";
import { Subject, Subscription } from 'rxjs';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { SendSmsNotifyComponent } from 'src/app/theme/shared/hop-common-components/send-sms-notify/send-sms-notify.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { CardComponent } from '../../components/card/card.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { UploadAdditionalDocumentsComponent } from '../upload-additional-documents/upload-additional-documents.component';
import { CancelAppointmentComponent } from 'src/app/theme/shared/hop-common-components/cancel-appointment/cancel-appointment.component';
import { ReScheduleAppointmentComponent } from 'src/app/theme/shared/hop-common-components/re-schedule-appointment/re-schedule-appointment.component';
import { UserVisitDetailsComponent } from 'src/app/theme/shared/hop-common-components/user-visit-details/user-visit-details.component';
import { EligibilityCheckComponent } from 'src/app/theme/shared/hop-common-components/eligibility-check/eligibility-check.component';
import SignaturePad from 'signature_pad';
import { NewTaskComponent } from 'src/app/theme/shared/hop-common-components/new-task/new-task.component';
import { CommomPastVisitDetailsWizardComponent } from '../commom-past-visit-details-wizard/commom-past-visit-details-wizard.component';
import { ViewAppointmentDetailsComponent } from '../view-appointment-details/view-appointment-details.component';
import { AddendumNotesChartComponent } from '../addendum-notes-chart/addendum-notes-chart.component';

@Component({
  selector: 'app-appointment-history-for-user',
  templateUrl: './appointment-history-for-user.component.html',
  styleUrls: ['./appointment-history-for-user.component.scss']
})
export class AppointmentHistoryForUserComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() userInfo: any;
  public dtOptionsAppntHistory: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElementAppntHistory: DataTableDirective;
  public documentForm: FormGroup;
  public allDocumentTypes = [];

  public dtTriggerAppntHistory: Subject<any> = new Subject<any>();
  public allAppntHistory = [];
  public allAppointments = [];
  public loginDetails: any;
  public waitTimeDetails: any;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public loadSpinner: boolean;
  public journalNotes: FormGroup;
  public selectedAppointmentUser: any;
  public journalNotesClick: boolean;
  public lateNotifyPatient: any;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  public getRouteParam: Subscription;
  @Input() userInfoId: any;
  @ViewChild('files') public files: ElementRef;
  @Input() cardReload: CardComponent;
  @ViewChild(UploadAdditionalDocumentsComponent) uploadDocument: UploadAdditionalDocumentsComponent;
  @Output() callNotifycationUpdate = new EventEmitter<any>();


  @Input() encounterId: any;
  @ViewChild(ReScheduleAppointmentComponent) reScheduleComp: ReScheduleAppointmentComponent;
  @ViewChild(CancelAppointmentComponent) cancelAppntComp: CancelAppointmentComponent;
  @ViewChild(UserVisitDetailsComponent) userVisitComp: UserVisitDetailsComponent;
  public displayuserInfo: boolean = false;
  public userAppointmentId: any;
  public statusButtonClass: any;
  eligibilityAppointment: any;
  @ViewChild(EligibilityCheckComponent) eligibilityComp: EligibilityCheckComponent;

  signaturePad!: SignaturePad;
  @ViewChild('canvas')
  canvasEl!: ElementRef;
  signatureImg!: string;
  signatureImgString!: string;
  public signatureForm: FormGroup;
  public selectedappointmentId: any;
  public providerFirstName: any;
  public providerLastName: any;


  public addCss: boolean = false;
  public isDocumentDownloaded: boolean = false;
  downloadDocumentErrorMessage: boolean = false;
  public additionalDocName: any;
  public collectionName: any;
  @ViewChild(NewTaskComponent) newTakComp: NewTaskComponent;
  @ViewChild(AddendumNotesChartComponent) addendumComp: AddendumNotesChartComponent;
  @ViewChild('verifyAvailableReportModal') openVeriy: UiModalComponent;
  @ViewChild('encounterViewDetails') openViewAppointments: UiModalComponent;

  @ViewChild(CommomPastVisitDetailsWizardComponent) public pastVisitComp: CommomPastVisitDetailsWizardComponent;
  public isReviewDoc: boolean = false;
  @ViewChild(ViewAppointmentDetailsComponent) viewAppt: ViewAppointmentDetailsComponent;
  public appointmentUSerData: any = '';
  public LabShow: boolean = false;
  public labType: any;
  selectedApptIndex: number;
  viewEncounterDetailsAppId: any;
  public allCareFlowData: any;
  public facilityId: string = '';
  @ViewChild('lockAppointment') private lockAppointment: UiModalComponent;
  @ViewChild('lockAppointmentEmpty') private lockAppointmentEmpty: UiModalComponent;
  public selectedAppointmentEdit: any;
  allUsersList: any[];
  public noLockUser: boolean = false;

  public openActions: boolean = false;
  public enableActions: boolean = false;
  public selectedActionRow: any;
  public sendTypeDocs: string = 'Chart Appts';

  @HostListener('document:click', ['$event'])
  clickInside(event) {

    const eventId = (event.target as HTMLInputElement).id;
    if (!this.enableActions && eventId !== 'claimActions_btn') {
      this.openActions = false;
    }
  }

  constructor(private httpService: HttpService, private router: Router, private formBuilder: FormBuilder, private activateRoute: ActivatedRoute, private scroller: ViewportScroller) {

    this.journalNotes = formBuilder?.group({
      'notes': [null, Validators.required],
      'date': [null]
    });

    this.signatureForm = formBuilder?.group({
      'signature': [null, Validators.required]
    });

    this.additionalDocName = "";
    this.collectionName = "Encounter";


  }

  ngOnInit(): void {
    this.dtOptionsAppntHistory = {
      pagingType: 'simple_numbers',
      pageLength: 5,
      autoWidth: true,
      lengthChange: false,
      order: [[0, "desc"]],
      columnDefs: [{ targets: [0], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "No Appointments available for this patient",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };

    // this.userInfo = JSON.parse(sessionStorage.getItem('establishedUserInfo')) || null;
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.facilityId = this.loginDetails.facilityId;
    // this.getWaitTime(this.loginDetails && this.loginDetails.facilityId);
    this.waitTimeDetails = JSON.parse(sessionStorage?.getItem('waitTimeDetails'));
    if (this.userInfoId) {
      this.getUserInfoDetails();
    }
    this.getAllUsersList();
    // this.router.navigate(["/"]);


  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {

      // if (this.userInfoId) {
      //   this.getUserInfoDetails();
      // } else {
      //   this.dtTriggerAppntHistory?.next();
      // }
    }
  }
  ngAfterViewInit() { }


  //  get user ifno details
  public getUserInfoDetails(): void {
    if (this.userInfo) {
      return;
    }
    const action = `common/getChartInfo?userInfoId=${this.userInfoId}&chartModuleName=UserInfo`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.userInfo = data?.responseObject || null;
        // this.getAppointmentHistory(this.userInfoId);
      }
    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  public getAppointmentHistory(userInfoId?: string): void {
    this.displayuserInfo = false;
    this.addCss = false;;
    // const action = `appointment/registration/getAppointmentsByUserInfoId?userInfoId=${userInfoId}`;
    const action = `common/getChartInfo?userInfoId=${userInfoId}&chartModuleName=Encounters`;
    this.loadSpinner = true;
    this.allAppntHistory = [];
    this.rerender();
    this.allAppointments = [];
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.allAppntHistory = data?.responseObject || [];
      this.allAppntHistory?.forEach((element: any) => {
        if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'SCHEDULED') {
          this.statusButtonClass = 'btn-status-rescheule';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKIN') {
          this.statusButtonClass = 'btn-status-checkin';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'NURSE_GREET') {
          this.statusButtonClass = 'btn-status-nurse_greet';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'DOCTOR_GREET') {
          this.statusButtonClass = 'btn-status-doctor_greet';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT') {
          this.statusButtonClass = 'btn-status-success';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'PATIENT_ARRIVED') {
          this.statusButtonClass = 'btn-status-rescheule';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CANCELLED') {
          this.statusButtonClass = 'btn-status-cancel';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'QR_VERIFIED') {
          this.statusButtonClass = 'btn-status-rescheule';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'APPT_BOOKED') {
          this.statusButtonClass = 'btn-status-success';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CONFIRMED') {
          this.statusButtonClass = 'btn-status-success';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CONSULTATION') {
          this.statusButtonClass = 'btn-status-rescheule';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'NOTAVAILABLE') {
          this.statusButtonClass = 'btn-status-cancel';
        }
        else if (element?.appointmentRegistration?.appointmentStatus?.currentStatus === 'TRIAGE') {
          this.statusButtonClass = 'btn-status-triage';
        }
        element.appointmentRegistration.appointmentStatus.statusButtonClass = this.statusButtonClass;
      });
      this.dtTriggerAppntHistory?.next();
      this.loadSpinner = false;
    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  public getAppointmentTime(date: any): string {
    return this.httpService?.getAppointmentTime(date);
  }

  // get doctor name from list
  public doctorNameWithNpi(npi: number): string {
    const doctor = this.waitTimeDetails?.eligibilityDetails?.doctor?.find((item: any) => {
      return npi === item?.number
    });
    const firstName = doctor?.basic?.authorized_official_first_name || '';
    const lastName = doctor?.basic?.authorized_official_last_name || '';
    return `${firstName} ${lastName}` || '--';
  }

  public viewAppointment(id: any) {
    this.httpService.clearTreatmentFlowSession();
    this.router?.navigate([`/hospital/past-visit/${id}`]);
  }

  public getUserName(userId?: string): string {
    let name: string;
    // this.loadSpinner = true;
    if (userId) {
      return this.allUsersList?.find(item => item?.userId === userId)?.name || userId;
    }
    //this.loadSpinner = false;
    return name = userId;
  }

  public getUserRole(userId?: string): string {
    let name: string;
    //this.loadSpinner = true;
    if (userId) {
      return this.allUsersList?.find(item => item?.userId === userId)?.role || userId;
    }
    //this.loadSpinner = false;
    return name = userId;
  }

  public getAllUsersList(): void {
    const action = `provider/getAllProviders/${this.loginDetails.facilityId}`;
    this.allUsersList = [];
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const allUsers = data?.responseObject?.filter(item => item?.active) || [];
        const allDoctors = allUsers?.map((doctorItem: any) => {
          const firstName = doctorItem?.firstName || '';
          const lastName = doctorItem?.lastName || '';
          return {
            name: `${firstName} ${lastName}`,
            npi: doctorItem?.providerNPI,
            userId: doctorItem?.emailId,
            role: doctorItem?.roleType
          }
        }) || [];
        this.allUsersList = allDoctors || [];
      }
    },
      (error) => {
      });
  }

  public lockUnLocakAppointment(appointment, userLock?: boolean, userUnLock?: boolean): void {
    this.selectedAppointmentEdit = appointment;
    const action = `appointment/registration/lockAppointmentUsage?appointmentId=${this.selectedAppointmentEdit?.id}&lock=${userLock}&unlock=${userUnLock}`;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body === false) {
        if (this.selectedAppointmentEdit?.lockUser != null && this.selectedAppointmentEdit?.lockUser != '') {
          this.noLockUser = false;
          this.lockAppointment?.show();
          return;
        }
        else {
          this.noLockUser = true;
          this.lockAppointmentEmpty.show();
          return;
        }

      }
      else {
        const action = `treatmentFlow/validateLock?appointmentId=${this.selectedAppointmentEdit?.id}`;
        this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
          const validateLock = data;
          if (validateLock?.responseObject?.locked === false || (validateLock?.responseObject?.allow === true && validateLock?.responseObject?.locked === false)) {
            console.log("validated");
            this.router?.navigate([`/hospital/hosp-patientdetails/${appointment?.id}`]);
          }
          if (validateLock?.responseObject?.locked === true) {
            if (validateLock?.responseObject?.allow === true) {
              this.getAllModulesSavedData();
              this.addendumComp?.openAddendumModal(appointment?.id, true);
            }
            if (validateLock?.responseObject?.allow === false) {
              this.notifyText = validateLock?.message;
              this.isSuccessNotify = false;
              this.exceptionModal?.modalShow();
            }
          }
        },
          (error) => {
          });


      }

    }, error => {

      return false;
    });
  }
  // edit appointment
  public async editAppointment(user: any) {
    this.selectedAppointmentUser = user?.appointmentRegistration;
    if (user?.appointmentType === 'Future') {
      this.router?.navigate([`hospital/hosp-updatepatientdetails/${user?.id}`]);
      sessionStorage?.setItem('AppntEditNavigateFlow', JSON?.stringify({ name: 'Chart', url: `/hospital/user-data-update/${this.userInfoId}` }));
    } else {
      this.cardReload?.cardRefreshShow();
      try {
        const validateLock = await this.httpService?.validateLockAppointment(user?.id)?.toPromise();
        this.cardReload?.cardRefreshHide();
        if (validateLock?.responseObject?.locked === false || (validateLock?.responseObject?.allow === true && validateLock?.responseObject?.locked === false)) {
          sessionStorage.setItem('careflowPath', 'appointment');
          this.httpService?.navigationDataSession(`/hospital/user-data-update/${this.userInfoId}`, 'Chart');
          this.router?.navigate([`/hospital/hosp-patientdetails/${user?.id}`]);
        }
        if (validateLock?.responseObject?.locked === true) {
          if (validateLock?.responseObject?.allow === true) {
            this.getAllModulesSavedData();
            this.addendumComp?.openAddendumModal(user?.id, true);
            return;
          }
          if (validateLock?.responseObject?.allow === false) {
            this.notifyText = validateLock?.message;
            this.isSuccessNotify = false;
            this.exceptionModal?.modalShow();
            return;
          }
        }
      } catch {
        this.cardReload?.cardRefreshHide();
      }
      //  this.lockUnLocakAppointment(this.selectedAppointmentUser,true,false);
    }
  }

  public ageCalculate(dateOfBirth: any): any {
    return this.httpService?.calculateAge(dateOfBirth);
  }

  // ngAfterViewInit(): void {
  //   if (this.userInfoId) {
  //     this.getUserInfoDetails();
  //   } else {
  //     this.dtTriggerAppntHistory?.next();
  //   }
  // }
  // open jounal notes
  public openJournamNotes(user: any, journalNotesModal: UiModalComponent): void {
    this.displayuserInfo = false;
    this.addCss = false;
    this.selectedAppointmentUser = user;
    this.getAllModulesSavedData();
    journalNotesModal?.show();
  }

  // open Upload Documents popup
  public openUploadDocument(user: any): void {
    this.displayuserInfo = false;
    this.addCss = false;
    this.selectedAppointmentUser = user;
    this.uploadDocument?.openUploadDocument(user, 'Encounter', '');
  }

  // jounal notes update
  public journalNotesUpdate(journalNotesModal: UiModalComponent): void {
    if (this.journalNotes?.get('notes')?.invalid) {
      this.journalNotes?.get('notes')?.markAllAsTouched();
      return;
    };
    const action = `treatmentFlow/saveJournalNotes?appointmentId=${this.selectedAppointmentUser?.id}`;
    const notesValue = this.journalNotes?.value;
    notesValue.date = new Date()?.toISOString();
    const params = {
      notes: [
        notesValue
      ]
    };
    this.journalNotesClick = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status) {
        journalNotesModal?.hide();
        this.journalNotes?.reset();
        this.notifyText = 'Progress Notes added successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
      }
      this.journalNotesClick = false;
    },
      (error) => {
        this.journalNotesClick = false;
      });
  }

  // send Notifications
  public lateNotify(patient: any, type: any): void {
    this.sendTypeDocs = 'Chart Appts';
    this.lateNotifyPatient = patient || {};
    if (type == 'DocReview') {
      this.sendTypeDocs = 'Chart Apps Docs';
      this.sendNotifyComp?.showNotifyModalForEncounter(patient, '', type);
    } else {
      if (patient?.appointmentStatus?.checkIn || patient?.appointmentStatus?.currentStatus === 'CANCELLED') {
        this.sendNotifyComp?.showNotifyModalForEncounter(patient);
      } else {
        this.sendNotifyComp?.showNotifyModalForEncounter(patient);
      }
    }

  }

  public sendNotification(appointment?: any): void {
    this.sendNotifyComp?.showNotifyModalForEncounter(appointment);
  }

  public rerender(): void {
    this.dtElementAppntHistory?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
      //  this.dtTriggerFutureAppnt.next(); 
    });
  }

  // download the treatment flow data pdf view
  public downloadMedicalRecord(appointmentId: string, signatureModal: UiModalComponent, medicalRecordModal?: UiModalComponent): void {
    if (this.signatureForm?.invalid) {
      this.signatureForm?.markAllAsTouched();
      return;
    }
    const params = this.signatureForm.value;
    params.appointmentId = appointmentId;

    // const action = `pdf/generateMedicalHistory?appointmentid=${appointmentId}`;
    const action = `pdf/generateMedicalHistory`;
    signatureModal?.hide();
    this.clearPad();

    this.cardReload?.cardRefreshShow();
    this.httpService.downloadS3Image(action, params).subscribe((data: any) => {
      /* var file = new Blob([data], { type: 'application/pdf' })
       var fileURL = URL.createObjectURL(file);
       var a = document.createElement('a');
       a.href = fileURL;
       a.target = '_blank';
       a.download = `${appointmentId}.pdf`;
       document.body.appendChild(a);
       a.click();*/
      var file = new Blob([data], { type: 'application/pdf' });
      const blobToFile = new File([file], `${appointmentId}_Referral.pdf`, {
        type: file?.type,
      });
      var fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('medical-record-pdf') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      if (medicalRecordModal) {
        medicalRecordModal?.show();
      }
      this.isDocumentDownloaded = true;
      this.loadSpinner = false;
      this.downloadDocumentErrorMessage = false;
      this.cardReload?.cardRefreshHide();
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.cardReload?.cardRefreshHide();
      });
  }

  public openAddendumModalNotes(patient: any) {
    this.displayuserInfo = false;
    this.addCss = false;
    this.selectedAppointmentUser = patient;
    this.addendumComp?.openAddendumModal(this.selectedAppointmentUser?.id);
    this.getAllModulesSavedData();
  }

  public updateNotifiyUpload(event: any): void {
    if (event.documentType !== 'Consent Form') {
      //const linkId = document.getElementById('doclink_' + event.id) as HTMLElement;
      // linkId.classList.remove('a-disabled');
    }
    if (event.documentType === 'Radiology Results' || event.documentType === 'Lab Results' || event.documentType === 'EMG') {
      this.getAppointmentHistory(this.userInfoId);
      this.callNotifycationUpdate?.emit(event);
    }
  }


  public openSignatureModal(signatureModal: UiModalComponent, userid: any, firstName: any, lastName: any): void {
    this.displayuserInfo = false;
    this.addCss = false;
    this.signatureForm.reset();
    this.selectedappointmentId = userid;
    this.providerFirstName = firstName;
    this.providerLastName = lastName;
    const providername = lastName + ", " + firstName  ;

    const canvas = <HTMLCanvasElement>document.getElementById('textCanvas');
    const tCtx = canvas.getContext('2d');

    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://fonts.googleapis.com/css?family=Open+Sans|Rock+Salt|Shadows+Into+Light|Cedarville+Cursive';
    document.getElementsByTagName('head')[0].appendChild(link);

    //tCtx.canvas.width = tCtx.measureText(providername).width*2;
    tCtx.font = 'bold 1.5em "Rock Salt",cursive';
    tCtx.textBaseline = 'top';
    tCtx.fillText(providername, 0, 100);
    this.signatureImg = tCtx.canvas.toDataURL();
    this.signatureForm?.get('signature')?.setValue(this.signatureImg);
    signatureModal?.show();
  }

  public startDrawing(event: Event) {

  }

  public moved(event: Event) {

  }

  public clearPad(): void {
    //this.signaturePad.clear();
    const canvas = <HTMLCanvasElement>document.getElementById('textCanvas');
    const tCtx = canvas.getContext('2d');
    tCtx.clearRect(0, 0, canvas.width, canvas.height);

    this.signatureImg = '';
    this.signatureImgString = '';
    this.signatureForm?.get('signature')?.setValue('');
  }

  public savePad(): void {
    if (this.signaturePad.isEmpty()) {
      return;
    }
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.signatureImgString = "Signature saved"
    this.signatureForm?.get('signature')?.setValue(this.signatureImg);
  }

  public refresh(): void {
    this.allAppntHistory = [];
    this.getAppointmentHistory(this.userInfoId);
    this.getUserInfoDetails();
    //this.rerender();
  }
  // re schedule appointment 
  public reScheduleAppointment(appointment: any): void {

    this.displayuserInfo = false;
    this.addCss = false;
    this.reScheduleComp.openSetAppointment(appointment);
  }

  // Cancel Apppintment commom compoent open
  public cancelAppointment(appointment: any): void {
    this.displayuserInfo = false;
    this.addCss = false;
    this.cancelAppntComp.openCancelAppointment(appointment);
  }


  public viewUserInfo(appointmentId) {
    this.displayuserInfo = false;
    this.userAppointmentId = appointmentId;
    this.loadSpinner = true;
    this.isReviewDoc = false;
    setTimeout(() => {
      this.loadSpinner = false;

      this.displayuserInfo = true;
      this.scroller.scrollToAnchor("previewDiv");

    }, 500);
  }

  public showhidedetails() {
    this.displayuserInfo = false;
  }

  public refreshCss(add: boolean) {
    //this.addCss = add ? true : false;
    this.addCss = !this.addCss;
  }

  // check eligibility
  public checkEligibility(appointment: any): void {
    this.displayuserInfo = false;
    this.addCss = false;
    const getPrimaryInsurance = appointment?.insuranceDetails?.find(item => item?.priority === 'Primary');
    if (!getPrimaryInsurance || !getPrimaryInsurance?.memberId || !getPrimaryInsurance?.payerId || !getPrimaryInsurance?.subscriberlastname || !getPrimaryInsurance?.subscriberfirstname) {
      this.notifyText = 'Insurance details are unavailable for this Appointment.';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      return;
    }
    // this.futureAppointments?.cardRefreshShow();
    this.eligibilityAppointment = appointment;
    this.eligibilityComp?.checkEligibilityPatient(getPrimaryInsurance, appointment);
  }

  // appointment status showing
  public getStatus(status: string, appointment: any): string {
    return this.httpService.getStatus(status, appointment);
  }

  public openTaskModal(appointment: any): void {
    this.newTakComp?.showTaskModal(appointment);
  }

  public viewDocumentInfo(user) {
    this.displayuserInfo = false;
    this.userAppointmentId = user?.appointmentRegistration?.id;
    this.loadSpinner = true;
    this.isReviewDoc = true;
    //this.pastVisitComp?.gotoDcoumentSection();
    setTimeout(() => {
      this.loadSpinner = false;
      this.displayuserInfo = true;
      this.scroller.scrollToAnchor("previewDiv");
    }, 500);
  }

  public testModalData(testModal: UiModalComponent) {
    testModal.show();
  }

  public openerifyReports(user: any, type: any) {
    if (user) {
      if (type == 'LAB' && (!user?.labOrder || user?.labDetails.length === 0)) {
        return false;
      }
      if (type == 'RAD' && (!user?.radOrder || user?.radDetails.length === 0)) {
        return false;
      }
      if (type == 'EMG' && (!user?.emgOrder || user?.emgDetails.length === 0)) {
        return false;
      }
      this.LabShow = true;
      this.appointmentUSerData = user;
      this.labType = type;
    }
    this.openVeriy.show();
  }

  public openViewAppointmentDetails(userid: any, index?: number) {
    this.selectedappointmentId = userid;
    this.viewEncounterDetailsAppId = userid;
    this.selectedApptIndex = index;
    this.openViewAppointments.show();
  }

  // call the Prev and Next
  public callNextPrevEncounter(actionType?: string): void {
    const index = actionType == 'prev' ? this.selectedApptIndex - 1 : this.selectedApptIndex + 1;
    this.selectedApptIndex = index;
    const getNxtPrevEncounter = this.allAppntHistory[index];
    if (!getNxtPrevEncounter) {
      return;
    }
    this.viewEncounterDetailsAppId = null;
    this.selectedappointmentId = getNxtPrevEncounter?.appointmentRegistration?.id;
    setTimeout(() => {
      this.viewEncounterDetailsAppId = getNxtPrevEncounter?.appointmentRegistration?.id;
    });
  }

  public getAllModulesSavedData(): void {
    const action = `careFlow/readCareFlowData?facilityId=${this.facilityId}&appointmentId=${this.selectedAppointmentUser?.id}&userInfoId=${this.selectedAppointmentUser?.userInfoId}`;
    this.cardReload?.cardRefreshShow();
    this.allCareFlowData = null;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      this.allCareFlowData = data?.responseObject;
      this.cardReload?.cardRefreshHide();
    },
      (error) => {
        this.cardReload?.cardRefreshHide();
      })
  }

  public openUniversalActions(event: any, row) {
    if (this.selectedActionRow === row) {
      this.openActions = !this.openActions;
    }
    else {
      this.openActions = true;
    }

    this.selectedActionRow = row;

  }
  public closeUniversalActions() {

    this.openActions = false;
  }

  public mouseIn() {
    this.enableActions = true;
  }

  public mouseOut() {
    this.enableActions = false;
  }

  ngOnDestroy(): void {
    if (this.dtTriggerAppntHistory) {
      this.dtTriggerAppntHistory?.unsubscribe();
    }
    sessionStorage.removeItem('establishedUserInfo');
    if (this.getRouteParam) {
      this.getRouteParam?.unsubscribe();
    }
  }

}
