import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, SimpleChange, OnChanges, Input, SimpleChanges, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subscription, Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { SendSmsNotifyComponent } from 'src/app/theme/shared/hop-common-components/send-sms-notify/send-sms-notify.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { PaymentsCommonNewComponent } from 'src/app/theme/shared/hop-common-components/payments-common-new/payments-common-new.component';
import { PostChargesUpdateComponent } from '../post-charges-update/post-charges-update.component';
import { PaymentsRefactorComponent } from '../payments-refactor/payments-refactor.component';
import { ClaimsService } from '../../services/claims.service';
import { ApisService } from '../../services/apis.services';
import { ClaimJournalComponent } from '../claim-journal/claim-journal.component';

@Component({
  selector: 'app-pending-payments-for-user',
  templateUrl: './pending-payments-for-user.component.html',
  styleUrls: ['./pending-payments-for-user.component.scss']
})
export class PendingPaymentsForUserComponent implements OnInit {

  @Input() userInfoId: any;
  public dtOptionsPendingPayments: DataTables.Settings = {};
  public dtTriggerPendingPayments: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElementPandingPayments: DataTableDirective;
  public loginDetails: any;
  @ViewChild('futureAppointments') futureAppointments: CardComponent;
  @ViewChild(UiModalComponent) modalComp: UiModalComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;

  @ViewChild('viewAccountBalDetailsModal') viewAccDetails: UiModalComponent;
  @ViewChild(PostChargesUpdateComponent) postChargesUpdateComp: PostChargesUpdateComponent;

  public showDetails: boolean = false

  public notifyText: string;
  public isSuccessNotify: boolean;

  public waitTimeDetails: any;
  public lateNotifyPatient: any;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  allPendingPayments: any;
  public appointmentCostOfCare: FormGroup;
  appointmentCostOfCareDetails: any;
  public appointmentId: string;
  public paymentStatus: string;
  public appointmentInfoDetails: any;
  public addCss: boolean = false;
  public saveComplete: boolean = true;
  subscriberDetails: any;
  @ViewChild(PaymentsRefactorComponent) private payComp: PaymentsRefactorComponent;
  public paymentConfigDetails: any;
  loader: boolean = false;
  public getRouteParam: Subscription;
  @Input() updateUserdataCard: CardComponent;
  @Input() patientID: any;
  allEobDouemnts: any;
  public loadSpinner: boolean;
  public eraDetails: any;
  public manualEob: boolean;
  public allEobDocuments: any[] = [];
  public openActions: boolean = false;
  public selectedActionRow: any;
  public claimSubmitNotifyText = '';
  public eobViewIndex: number = 1;
  @HostListener('document:click', ['$event'])
  clickInside(event) {
    const eventId = (event.target as HTMLInputElement).id;
    if (eventId !== 'claimActions_btn') {
      this.openActions = false;
    }
  }
  @ViewChild(ClaimJournalComponent) claimJournalComp: ClaimJournalComponent;
  @Input() userInfoDetails: any;
  @Input() payClaimJournalNotRequired: boolean; // this will be to hide the Pay and Claim Journal option in Claims queues
  @Input() recieptAbId: string;
  @Input() isClaimPage: any;
  @Input() isFromChart: boolean;

  constructor(public httpService: HttpService, private router: Router, private formBuilder: FormBuilder, private activateRoute: ActivatedRoute, private claimsService: ClaimsService, private apiService: ApisService) {
    this.appointmentCostOfCare = this.formBuilder?.group({
      "appointmentId": [this.appointmentId || null],
      "discount": [null, Validators?.required],
      "nonPayment": [null, Validators?.required],
      "patientResponsibility": [null, Validators?.required],
      "reimburseAmount": [null, Validators?.required],
      "credits": [null, Validators?.required],
      "approvedAmount": [null, Validators?.required],
      "coverageCost": [null, Validators?.required],
    });
    this.getRouteParam = this.activateRoute?.params?.subscribe(params => {
      const userInfoId = params['userInfoId'];
      this.userInfoId = userInfoId;
    });
  }

  ngOnInit(): void {
    this.dtOptionsPendingPayments = {
      pagingType: 'simple_numbers',
      pageLength: 5,
      lengthChange: false,
      autoWidth: true,
      order: [[0, "desc"]],
      columnDefs: [
        { orderData:[1], targets: [0] },
        { targets: [1], type: 'date', visible: false, searchable: false, orderable: true },
        { targets: [8], type: 'date', orderable: true }
      ],
      searching: false,
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.waitTimeDetails = JSON?.parse(sessionStorage?.getItem('waitTimeDetails'));
    this.showDetails = false;
    this.getPaymentConfig();

  }


  // customize the Appointment Date time
  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.userInfoId) {
        for (let propName in changes) {
          if (propName === 'userInfoId') {
            let chng = changes[propName];
            const previous: any = chng?.previousValue;
            const current: any = chng?.currentValue;
            if (current !== previous) {
              this.showDetails = false;
              // this.getPendingPaymentsByUser(this.userInfoId);
            }
          }
        }
      }
    }
  }

  // get future appointments by user info id
  public async getPendingPaymentsByUser(userInfoId): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.userInfoId = userInfoId;
      //const action = `appointment/registration/getPendingPaymentsforUser?UserInfoId=${this.userInfoId}`;
      // const action = `common/getChartInfo?userInfoId=${this.userInfoId}&chartModuleName=Payments`;
      this.updateUserdataCard?.cardRefreshShow();
      this.rerender();
      //this.loadSpinner = true;
      this.apiService?.getChartInfoAccountBalance(this.userInfoId).subscribe((data: any) => {
        this.updateUserdataCard?.cardRefreshHide();
        resolve();
        if (data?.status == 'SUCCESS') {
          this.allPendingPayments = data?.responseObject || [];
        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.dtTriggerPendingPayments.next();
       // this.loadSpinner = false;
      }, error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
        this.dtTriggerPendingPayments.next();
        this.updateUserdataCard?.cardRefreshHide();
        resolve()
      });
    })
  }


  // this is for table render, we need to destroy everytime to update the new data
  public rerender(): void {
    this.dtElementPandingPayments?.dtInstance?.then((dtInstanceFutureAppnt: DataTables.Api) => {
      dtInstanceFutureAppnt?.destroy();
      //  this.dtTriggerPendingPayments.next(); 
    });
  }

  // appointment status showing
  public getStatus(status: string, appointment: any): string {
    return this.httpService.getStatus(status, appointment);
  }

  // send Notifications
  public lateNotify(patient: any): void {
    this.lateNotifyPatient = patient || {};
    this.sendNotifyComp?.showNotifyModal(patient);
    this.showDetails = false;
  }

  public viewAppointment(id: any, status: any) {
    // this.viewAccDetails.show();
    this.appointmentId = null;
    this.showDetails = false;
    this.loader = true;
    this.appointmentId = id;
    this.paymentStatus = status;
    this.getAppointmentById();
    this.showDetails = true;
    this.httpService.clearTreatmentFlowSession();
    this.loader = false;
    // this.getAppointmentCost();

  }
  // get appointment cost of care
  public getAppointmentCost(): void {
    const action = `appointment/registration/retrieveApptCostofCare?appointmentId=${this.appointmentId}`;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.showDetails = true;
        this.appointmentCostOfCareDetails = data?.body?.responseObject || {};
        this.appointmentCostOfCare?.get('reimburseAmount')?.setValue(data?.body?.responseObject?.apptCostofCare?.reimburseAmount);
        this.appointmentCostOfCare?.get('discount')?.setValue(data?.body?.responseObject?.apptCostofCare?.discount);
        this.appointmentCostOfCare?.get('nonPayment')?.setValue(data?.body?.responseObject?.apptCostofCare?.nonPayment);
        this.appointmentCostOfCare?.get('credits')?.setValue(data?.body?.responseObject?.apptCostofCare?.credits);
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(data?.body?.responseObject?.apptCostofCare?.patientResponsibility);
        this.appointmentCostOfCare?.get('approvedAmount')?.setValue(data?.body?.responseObject?.apptCostofCare?.approvedAmount);
        this.appointmentCostOfCare?.get('coverageCost')?.setValue(data?.body?.responseObject?.apptCostofCare?.coverageCost);

        if (data?.body?.responseObject?.apptCostofCare?.patientResponsibility === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
          const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((data?.body?.responseObject?.apptCostofCare?.reimburseAmount || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (data?.body?.responseObject?.apptCostofCare?.discount || 0) + (data?.body?.responseObject?.apptCostofCare?.nonPayment || 0));
          this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
        }
        this.loader = false;
      }
    }, (error) => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
    });
  }

  public submitPayCost(): void {
    const action = `appointment/registration/saveApptCostofCare`;
    this.appointmentCostOfCare.value.appointmentId = this.appointmentId;
    const params = this.appointmentCostOfCare?.value;
    this.futureAppointments?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      this.futureAppointments?.cardRefreshHide();
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = 'Payment details have been updated successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.saveComplete = true;
        this.getPendingPaymentsByUser(this.userInfoId);
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    }, (error) => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.futureAppointments?.cardRefreshHide();
    });

  }

  // change paymeCore
  public changeFormPaymentChanges(): void {
    this.appointmentCostOfCare?.get('reimburseAmount')?.valueChanges?.subscribe(value => {
      if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
        const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((value || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (this.appointmentCostOfCare?.get('discount')?.value || 0) + (this.appointmentCostOfCare?.get('nonPayment')?.value || 0));
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      }
    });
    this.appointmentCostOfCare?.get('discount')?.valueChanges?.subscribe(value => {
      if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
        const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((this.appointmentCostOfCare?.get('reimburseAmount')?.value || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (value || 0) + (this.appointmentCostOfCare?.get('nonPayment')?.value || 0));
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      }
    });
    this.appointmentCostOfCare?.get('nonPayment')?.valueChanges?.subscribe(value => {
      if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
        const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((this.appointmentCostOfCare?.get('reimburseAmount')?.value || 0) + ((this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) || 0) + (this.appointmentCostOfCare?.get('discount')?.value || 0) + (value || 0));
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      }
    });
  }

  public getAppointmentById(): void {

    let action: any = 'appointment/registration/getAppointmentById?id=';
    let params: any = {
      'id': this.appointmentId
    }
    this.futureAppointments?.cardRefreshShow();
    this.httpService.makeGetRequest(action, params?.id)
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          this.appointmentInfoDetails = data?.responseObject;
          this.appointmentInfoDetails.dob = this.httpService?.formatDobTimeZoneWithDisplay(this.appointmentInfoDetails?.dob);
          if (this.appointmentInfoDetails?.insuranceDetails?.length > 0 && this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth) {
            this.appointmentInfoDetails.insuranceDetails[0].subscriberdateOfBirth = this.httpService?.formatDobTimeZoneWithDisplay(this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth);
            this.subscriberDetails = this.appointmentInfoDetails?.insuranceDetails[0] || null;
          }
        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.futureAppointments?.cardRefreshHide();
      }, error => {
        this.futureAppointments?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      });
  }

  // Open Payment
  public async openPayment(appointment?: any, isPatientResp?: boolean, isAction?: boolean) {
    //console.log('appointment',appointment);
    // this.payComp?.enableCardPay();
    this.appointmentId = appointment?.appointmentId;
    if (!this.saveComplete) {
      this.notifyText = 'Please Save befor payment';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      return;
    }
    let appntDetails: any;
    try {
      this.updateUserdataCard?.cardRefreshShow();
      let action: any = `appointment/registration/getAppointmentById?id=${appointment?.appointmentId}`;
      appntDetails = await this.httpService.makeGetRequest(action, '').toPromise();
      this.updateUserdataCard?.cardRefreshHide();
    } catch {
      this.updateUserdataCard?.cardRefreshHide();
    }

    let payObj: any;
    if (isAction) {
      payObj = {
        'userInfoId': this.userInfoId,
        'id': appointment?.appointmentId,
        'facilityId': this.loginDetails?.facilityId
      }
    }
    this.payComp?.openModal(appntDetails?.responseObject || payObj, isPatientResp || true);
  }

  public responseChange(change: any) {
    this.saveComplete = false;
  }
  toggleDiv() {
    this.addCss = !this.addCss;
  }
  refresh() {
    console.log(this.appointmentId);
    this.viewAppointment(this.appointmentId, this.paymentStatus);
    setTimeout(() => {
      this.getPendingPaymentsByUser(this.userInfoId);
    }, 3000)
  }

  private getPaymentConfig(): any {
    // const action = `providerConfiguration/getPaymentConfig?facilityId=${this.loginDetails?.facilityId}`;
    this.apiService?.getPaymentConfig(this.loginDetails?.facilityId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentConfigDetails = data?.responseObject;
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      });
  }

  public openPostChangesUpdate(claimAppID: string, index: number, paymentDetails?: any): void {
    paymentDetails.userInfoId = this.userInfoId;
    this.postChargesUpdateComp?.showPostChargesUpdateModal(claimAppID, index, paymentDetails);
  }

  // open Post Charges respctively when click on Prev and Next
  public callPostChargesPrevNext(event: any) {
    const getClaimAppnt = this.allPendingPayments?.paymentsDetails[event];
    if (!getClaimAppnt) {
      return;
    }
    this.postChargesUpdateComp?.showPostChargesUpdateModal(getClaimAppnt?.appointmentId, event, getClaimAppnt);
  }

  public viewEOBDocument(eobDocuments: any, eobDocuModal: UiModalComponent): void {
    this.allEobDouemnts = (eobDocuments || []).reverse();    
    const eobDocument = eobDocuments && eobDocuments?.length > 0 && eobDocuments[0];
    if (!eobDocument || !eobDocument?.s3Key || !eobDocument?.s3Path) {
      return;
    }
    this.viewDownloadDocument(eobDocument, eobDocuModal, 1);
  }

  // Show EOB Documents view popup
  public viewDownloadDocument(eobDocument: any, eobDocuModal: UiModalComponent, index?: number) {
    this.eobViewIndex = index;
    const fileType = eobDocument?.fileType;
    this.updateUserdataCard?.cardRefreshShow();
    const action = `record/downloadDocument?s3Key=${eobDocument?.s3Key}&s3Path=${eobDocument?.s3Path}`;
    this.loadSpinner = true;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      if (!data) {
        this.updateUserdataCard?.cardRefreshHide();
        return;
      }
      const file = new Blob([data], { type: fileType })
      const fileURL = URL.createObjectURL(file);
      setTimeout(() => {
        const iframeEle = document.getElementById('eob-document-view') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
      }, 500);
      eobDocuModal?.show();
      this.updateUserdataCard?.cardRefreshHide();
      this.loadSpinner = false;
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.updateUserdataCard?.cardRefreshHide();
      });
  }


  public downloadERAPDF(appointmentId: any, claimNumber?: any, viewEobAccBal?: UiModalComponent): void {
    if (!claimNumber) {
      this.notifyText = 'No Claim details are present.';
      this.exceptionModal?.modalShow();
      this.isSuccessNotify = false;
      return;
    }
    const action = `claim/getEraPdf?appointmentId=${appointmentId}&claimId=${claimNumber || ''}`;
    this.loadSpinner = true;
    this.updateUserdataCard?.cardRefreshShow();
    this.claimsService?.downloadS3Image(action, '').subscribe((data: any) => {
      this.loadSpinner = false;
      this.updateUserdataCard?.cardRefreshHide();
      var file = new Blob([data], { type: 'application/pdf' });
      if (file.size == 0) {
        this.notifyText = 'Explanation of Benefits not received';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      } else {
        const blobToFile = new File([file], `${appointmentId}_EOB.pdf`, {
          type: file?.type,
        });
        var fileURL = URL.createObjectURL(file);
        const iframeEle = document.getElementById('eob-pdf-accbal') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
        if (viewEobAccBal) {
          viewEobAccBal?.show();
        }
      }
    }, error => {
      this.loadSpinner = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.exceptionModal?.modalShow();
      this.updateUserdataCard?.cardRefreshHide();
    })

  }
  public checkDownloadEob(eobDocuments: any, appointmentId?: any, claimNumber?: any, viewEobAccBal?: UiModalComponent, viewDownloadEobEncounterDoc?: UiModalComponent): void {
    this.allEobDouemnts = (eobDocuments || []).reverse();    
    const eobDocument = eobDocuments && eobDocuments?.length > 0 && eobDocuments[0];
    if (!eobDocument || !eobDocument?.s3Key || !eobDocument?.s3Path) {
      this.downloadERAPDF(appointmentId, claimNumber, viewEobAccBal);
    }
    else {
      this.viewDownloadDocument(eobDocument, viewDownloadEobEncounterDoc, 1);
    }
  }

  public checkDownloadEobTst(eobDocuments: any, appointmentId?: any, viewEobAccBal?: UiModalComponent, viewDownloadEobEncounterDoc?: UiModalComponent): void {
    const action = `claim/getEraDetails?appointmentId=${appointmentId}`;
    this.claimsService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject) {
          this.eraDetails = data?.body?.responseObject;
          console.log(data?.body?.responseObject);
          this.manualEob = this.eraDetails?.manual;
          if (this.manualEob) {
            this.allEobDouemnts = eobDocuments || [].sort((a, b) => {
              const dateA:any = new Date(a.auditInfo.createdDate);
              const dateB:any = new Date(b.auditInfo.createdDate);
              return dateB - dateA;
            });
            
            const eobDocument = eobDocuments && eobDocuments?.length > 0 && eobDocuments[0];
            if (!eobDocument || !eobDocument?.s3Key || !eobDocument?.s3Path) {
              this.downloadERAPDF(appointmentId, viewEobAccBal);
            }
            else {
              this.viewDownloadDocument(eobDocument, viewDownloadEobEncounterDoc, 1);
            }
          }
          else {
            this.downloadERAPDF(appointmentId, viewEobAccBal);
          }
        }
        else {
          this.manualEob = false;
          this.downloadERAPDF(appointmentId, viewEobAccBal);
        }
      }
      else {
        this.manualEob = false;
        this.downloadERAPDF(appointmentId, viewEobAccBal);
      }
    }, error => {
    });
  }

  public openUniversalActions(event: any, row) {
    if (this.selectedActionRow === row) {
      this.openActions = !this.openActions;
    }
    else {
      this.openActions = true;
    }

    this.selectedActionRow = row;

  }

  // Open Claim Journal
  public openClaimJournal(appntDetails: any): void {
    if (appntDetails) {
      appntDetails.patientFirstName = this.userInfoDetails?.firstName;
      appntDetails.patientLastName = this.userInfoDetails?.lastName;
      appntDetails.id = null;
    }
    this.claimJournalComp?.showClaimJournalModal(appntDetails);
    // this.newTaskComp?.showTaskModal(claimDetails);
  }

  // Clear the Iframe src
  public clearIframeSrc(): void {
    const iframeEle = document?.getElementById('eob-document-view') as HTMLElement;
    if (iframeEle) {
      iframeEle?.removeAttribute('src');
    }
  }

  // to destroy the subscription
  ngOnDestroy(): void {
    this.dtTriggerPendingPayments.unsubscribe();
  }

}
