<app-ui-modal #visitTypeModal [modalCentered]="true" [loadSpinner]="loadSpinner" [hideHeader]="false">
    <div class="app-modal-header" id="visitTypeUpdate">
        <h5 class="modal-title">Update Visit Type</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="visitTypeModal?.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body send-notifications" [formGroup]="visitTypeForm">
        <div class="form-group mb-3">
            <ng-select placeholder="Choose Visit Type" bindLabel="desc" bindValue="value"
                [items]="addPatientTypeOfService" id="visitTypeUpdate-edit" (change)="getServiceListList()" formControlName="SERVICE_TYPE">
            </ng-select>
            <span class="required"
                *ngIf="visitTypeForm.controls['SERVICE_TYPE'].hasError('required') && visitTypeForm.controls['SERVICE_TYPE'].touched">Visit type is Required</span>
        </div>
    </div>
    <div class="app-modal-footer">
        <button class="btn btn-primary dash-actions" type="button" id="visitTypeUpdatesubmit" (click)="updateVisitType()">Submit</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>