import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { PaymentsCommonNewComponent } from '../payments-common-new/payments-common-new.component';
import { BillingCodesComponent } from '../billing-codes/billing-codes.component';
import { ApisService } from '../../services/apis.services';
import { escapeSelector } from 'jquery';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { PaymentsRefactorComponent } from '../payments-refactor/payments-refactor.component';
import { ClaimsService } from '../../services/claims.service';

@Component({
  selector: 'app-account-balance-new',
  templateUrl: './account-balance-new.component.html',
  styleUrls: ['./account-balance-new.component.scss']
})
export class AccountBalanceNewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() appointmentId: string;
  public appointmentCostOfCare: FormGroup;
  public appointmentCostOfCareDetails: any;
  @Input() cardRefresh: CardComponent;
  notifyText: string;
  public isSuccessNotify: boolean;
  @Input() noPayOption: boolean;
  @Input() isFollowup: boolean;

  @ViewChild(PaymentsRefactorComponent) private payComp: PaymentsRefactorComponent;
  @ViewChild(ExceptionModalComponent) private exceptionalModal: ExceptionModalComponent;
  @ViewChild('claimSubmitNotify') claimLineSubmit: UiModalComponent;
  
  saveComplete: boolean;
  loginDetails: any;
  @Input() paymentConfigDetails: any;
  appointmentDetails: any;
  @Output() callPendingPayments = new EventEmitter<any>();
  @Output() paymentReloadAccBalance = new EventEmitter<any>();
  @ViewChild(BillingCodesComponent) billingcodesComp: BillingCodesComponent;
  errorMessageShown: string;
  successMessageShown: any;
  public errorMessageClaimShown: string;
  public successMessageClaimShown: string;
  public erroMsgClaimLine: string;
  public writeOffReasonsList = [
    'Failure of Timely submission',
    'Pending Sign off',
    'Collection Failure'
  ];
  allClaimWriteoffCodes: any;
  @Input() noSaveButton: boolean;
  loadSpinner: boolean;
  @Input() patientID: string;
  @Input() isClaimPage: any;
  @Output() paymentCall = new EventEmitter<any>();
  @Input() isPostChargesPage: boolean;
  @Input() mainExceptionModal: ExceptionModalComponent;
  public adjustmentTypes = AppConstantsListConfig?.adjustmentTypes || [];
  dupAppntCostOfCare: any;
  public visitClaimsSummaryForm: FormGroup;

  public eraDetails: any;
  public totalBilledAmountPDF: any;
  public totalAllowedAmountPDF: any;
  public totalPatientResponsibilityPDF: any;
  public totalPaidAmountPDF: any;
  public selectedclaimProcessorType: null;
  public lastStatementDates: any;
  public pastChargesItemized: any[];
  public postChargesItemized: any[];
  public arrowUp: boolean=false;
  public arrowDown: boolean=true;
  public selectedClaimId: any;
  public adjCodeSelected: boolean;
  public adjValueEntered: boolean;

  public serviceLineCharges: FormGroup;
  public isManulEdit: boolean;
  public serviceLineChargesResponse: any[];
  public claimSubmitNotifyText = '';
  @Input() accountBalancePaymentStatus: any;
 
  public initialWriteOffValue: any;
  public intialWriteOffReason: any;
  @Input() postChargesUserDetails: any;
  @Output() claimNumberEmit = new EventEmitter<any>();
  public checkNumberDateForm: FormGroup;
  public allClaimProcessorTypes = AppConstantsListConfig?.allClaimProcessorTypes || [];
  @ViewChild('checkNumberDateModal') checkDetailsModal: UiModalComponent;
  public guardControl = new FormControl(null, Validators?.required);
  public allEobDocuments: any[] = [];
  public filterProcessorEobDocs: any[] = [];
  @Output() updateCostOfCareItems = new EventEmitter<any>();
  @ViewChild('eobCheckDetailsCaptureNotify') eobCheckNotify: UiModalComponent;
  @Output() openEobModal = new EventEmitter<any>();
  @Output() eobDocuments = new EventEmitter<any>();
  public eobViewIndex: number = 1;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private apiService: ApisService, private claimsService: ClaimsService) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.appointmentCostOfCare = this.formBuilder?.group({
      "apptCostofCare": this.formBuilder?.array([]),
      "itemizedPayments": [null],
      "paymentStatus": [null],
      "payments": [null],
      'writeOffReason': [null, Validators?.required],
      'writeOffNotes': [null],
    });

    // Visit Summary Details
    this.visitClaimsSummaryForm = this.formBuilder?.group({
      'totalVisitCharges': [null],
      'patientResponsibility': [null],
      'credits': [null],
      'creditsGenerated': [null],
      'creditsRemaining': [null],
      'coPay': [null],
      'refund': [null],
      'adjFacilityCode': [null],
      'adjFacility': [0],
      'balance': [null],
      'writeOff': [null],
      'writeOffReason': [null, Validators?.required]
    });

    this.serviceLineCharges = this.formBuilder?.group({
      "serviceLine": this.formBuilder?.array([]),
      "claimId":[null],
      "patientControlNumber":[null],
    });

    this.checkNumberDateForm = this.formBuilder?.group({
      "checkNumber": [null, Validators?.required],
      "checkDate": [null, [Validators?.required, this.httpService?.yearValidator?.bind(this)]],
      "processorType": [null, Validators?.required],
    });
  }

  ngOnInit(): void {
    this.guardControl?.reset();
    this.getPaymentConfig();
    this.getAppointmentById();
    this.getClaimConfigCodes();
    this.getAllEobDocs();
    this.getAppointmentCost();
    //this.viewEob();
    this.eraDetails = [];
   // this.selectedclaimProcessorType = 'Primary';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.appointmentId) {
        this.guardControl?.reset();
        this.getAppointmentById();
        this.getPaymentConfig();
        this.getAppointmentCost();
        this.getAllEobDocs();
        //this.viewEob();
        this.eraDetails = [];
        // for (let propName in changes) {
        //   if (propName === 'appointmentId') {
        //     let chng = changes[propName];
        //     const previous: any = chng?.previousValue;
        //     const current: any = chng?.currentValue;
        //     if (current !== previous) {
        //       this.getAppointmentById();
        //       this.getPaymentConfig();
        //       this.getAppointmentCost();
        //     }
        //   }
        // }
        // this.appointmentCostOfCare?.get('appointmentId')?.setValue(this.appointmentId);
      }
      if (this.postChargesUserDetails) {
        this.getPendingPaymentsByUser(this.postChargesUserDetails?.userInfoId, this.appointmentId);
      }
      
    }
  }

  ngAfterViewInit(): void {
    
  }

  public getAppointmentById(): void {
    
    let action: any = `appointment/registration/getAppointmentById?id=${this.appointmentId}`;
    this.httpService.makeGetRequest(action, '')
      .subscribe((data: any) => {
        if (data?.status === 'SUCCESS') {
          this.appointmentDetails = data?.responseObject;
        }
      }, error => {

      });
  }

  // to get payment config details
  private getPaymentConfig(): any {
    if (this.paymentConfigDetails) {
      return;
    }
    // const action = `providerConfiguration/getPaymentConfig?facilityId=${this.loginDetails?.facilityId}`;
    this.apiService?.getPaymentConfig(this.loginDetails?.facilityId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentConfigDetails = data?.responseObject;
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      });
  }

  public addClaimsCharges(): void {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    let type: any;
    if (costofCareArray?.controls?.length === 1) {
      const getPrimaryCharges = costofCareArray?.value?.find(fItemCharges => fItemCharges?.claimProcessorType === 'Primary') || null;
      type = { claimProcessorType: 'Secondary' };
      // if(getPrimaryCharges) {
      //   type.totalVisitCharges = getPrimaryCharges?.patientResponsibility > 0 ? getPrimaryCharges?.patientResponsibility : 0;
      // }
      const insudetailsCheck = this.appointmentDetails?.insuranceDetails && this.appointmentDetails?.insuranceDetails?.find((insuItem: any) => insuItem?.active && insuItem?.priority === 'Secondary');
      if (!insudetailsCheck) {
        this.notifyText = 'Secondary insurance details were not found for the appointment to add secondary claim processor.';
        this.isSuccessNotify = false;
        if (this.mainExceptionModal) {
          this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
        } else {
          this.exceptionalModal?.modalShow()
        }
        return;
      }
    }
    if (costofCareArray?.controls?.length === 2) {
      const getSecondaryCharges = costofCareArray?.value?.find(fItemCharges => fItemCharges?.claimProcessorType === 'Secondary') || null;
      type = { claimProcessorType: 'Tertiary' };
      // if(getSecondaryCharges) {
      //   type.totalVisitCharges = getSecondaryCharges?.patientResponsibility > 0 ? getSecondaryCharges?.patientResponsibility : 0;
      // }
      const insudetailsCheck = this.appointmentDetails?.insuranceDetails && this.appointmentDetails?.insuranceDetails?.find((insuItem: any) => insuItem?.active && insuItem?.priority === 'Tertiary');
      if (!insudetailsCheck) {
        this.notifyText = 'Tertiary insurance details were not found for the appointment to add tertiary claim processor.';
        this.isSuccessNotify = false;
        if (this.mainExceptionModal) {
          this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
        } else {
          this.exceptionalModal?.modalShow();
        }
        return;
      }
    }
    costofCareArray?.push(this.apptCostofCareGroup(type));
  }

  // Delete Claim App Cost of Care
  public deleteClaimAppCostOfCare(index: number): void {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    costofCareArray?.removeAt(index);
  }

  public totalBalanceAmountCount(): any {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    let arPatient: number;
    let remainingCreds: number;
    if (costofCareArray?.controls?.length > 0) {
      arPatient = costofCareArray?.value?.map(item => item?.patientResponsibility || 0)?.reduce((prev, curr) => prev + curr);
      remainingCreds = costofCareArray?.value?.map(item => item?.creditsRemaining || 0)?.reduce((prev, curr) => prev + curr);
    }
    const totalBalance = arPatient - remainingCreds;
    return arPatient > 0 ? arPatient?.toFixed(2) : 0;
  }

  public apptCostofCareGroup(constofCareItem?: any): FormGroup {
    const ajFacility = constofCareItem?.adjFacility && this.checkDecimalValues(constofCareItem?.adjFacility);
    const ajCredits = constofCareItem?.adjCredits && this.checkDecimalValues(constofCareItem?.adjCredits);
    const getClaimProcessor = this.dupAppntCostOfCare?.claimInformation?.find((costCareItem: any) => costCareItem?.claimProcessorType === constofCareItem?.claimProcessorType) || null;
    return this.formBuilder?.group({
      "appointmentId": [constofCareItem?.appointmentId || this.appointmentId || null],
      "allowedAmount": [(constofCareItem?.allowedAmount && this.checkDecimalValues(constofCareItem?.allowedAmount)) || null, Validators?.required],
      "claimId": [getClaimProcessor?.claimNumber || constofCareItem?.claimId || null, Validators?.required],
      "claimProcessorType": [constofCareItem?.claimProcessorType || 'Primary' || null, Validators?.required],
      "coPay": [(constofCareItem?.coPay && this.checkDecimalValues(constofCareItem?.coPay)) || null, Validators?.required],
      "coverageCost": [(constofCareItem?.coverageCost && this.checkDecimalValues(constofCareItem?.coverageCost)) || null, Validators?.required],
      "credits": [(constofCareItem?.credits && this.checkDecimalValues(constofCareItem?.credits)) || null],
      "creditsRemaining": [(constofCareItem?.creditsRemaining && this.checkDecimalValues(constofCareItem?.creditsRemaining)) || 0 || null],
      "creditsRemainingDup": [(constofCareItem?.creditsRemaining && this.checkDecimalValues(constofCareItem?.creditsRemaining)) || null],
      "creditsUsed": [(constofCareItem?.creditsUsed || 0) || null],
      "discountOrAdjusted": [(constofCareItem?.discountOrAdjusted && this.checkDecimalValues(constofCareItem?.discountOrAdjusted)) || null, Validators?.required],
      "nonPayment": [(constofCareItem?.nonPayment && this.checkDecimalValues(constofCareItem?.nonPayment)) || null, Validators?.required],
      "patientId": [constofCareItem?.patientId || null, Validators?.required],
      "patientResponsibility": [(constofCareItem?.patientResponsibility && this.checkDecimalValues(constofCareItem?.patientResponsibility)) || null, Validators?.required],
      "reimburseAmount": [(constofCareItem?.reimburseAmount && this.checkDecimalValues(constofCareItem?.reimburseAmount)) || null, Validators?.required],
      "totalPatientResponsibility": [(constofCareItem?.totalPatientResponsibility && this.checkDecimalValues(constofCareItem?.totalPatientResponsibility)) || null, Validators?.required],
      "totalVisitCharges": [(constofCareItem?.totalVisitCharges && this.checkDecimalValues(constofCareItem?.totalVisitCharges)) || null, Validators?.required],
      "insuranceName": [getClaimProcessor?.payerName || (constofCareItem?.insuranceName !== "" && constofCareItem?.insuranceName !== null ? constofCareItem?.insuranceName : null) || null],
      "writeOff": [(constofCareItem?.writeOff && this.checkDecimalValues(constofCareItem?.writeOff)) || null],
      "writeOffReason": [constofCareItem?.writeOffReason || null],
      "currentCredits": [(constofCareItem?.currentCredits && this.checkDecimalValues(constofCareItem?.currentCredits)) || null],
      "refund": [(constofCareItem?.refund && this.checkDecimalValues(constofCareItem?.refund)) || null],
      "refundDup": [(constofCareItem?.refund && this.checkDecimalValues(constofCareItem?.refund)) || null],
      "balance": [(constofCareItem?.balance && this.checkDecimalValues(constofCareItem?.balance)) || null],
      // "adjReceivable": [(constofCareItem?.adjReceivable && this.checkDecimalValues(constofCareItem?.adjReceivable)) || null],
      // "adjPayable": [(constofCareItem?.adjPayable && this.checkDecimalValues(constofCareItem?.adjPayable)) || null],
      // "adjFacility": [ajFacility > 0 ? ajFacility : ajFacility * -1 || null],
      // "adjFacilityCode": [constofCareItem?.adjFacilityCode || null],
      // "adjFacilityRadio": [(constofCareItem?.adjFacility ? (constofCareItem?.adjFacility < 0 ? 'minus' : 'plus') : null) || null],
      // "adjCredits": [ajCredits > 0 ? ajCredits : ajCredits * -1 || null],
      // "adjCreditsRadio": [(constofCareItem?.adjCredits ? (constofCareItem?.adjCredits < 0 ? 'minus' : 'plus') : null) || null]
      "adjFacility": [0],
      "adjFacilityCode": [null],
      "notes": [null],
      // "allowedAmount": [(constofCareItem?.allowedAmount && this.checkDecimalValues(constofCareItem?.allowedAmount)) || null],
      "initialOtherChargesPaid": [(constofCareItem?.initialOtherChargesPaid && this.checkDecimalValues(constofCareItem?.initialOtherChargesPaid)) || null],
      "postVisitPatientPayments": [(constofCareItem?.postVisitPatientPayments && this.checkDecimalValues(constofCareItem?.postVisitPatientPayments)) || null],
      "reduceCopay": [(constofCareItem?.reduceCopay && this.checkDecimalValues(constofCareItem?.reduceCopay)) || null],
      "totalAmountPatientPaid": [(constofCareItem?.totalAmountPatientPaid && this.checkDecimalValues(constofCareItem?.totalAmountPatientPaid)) || null],
      "creditsGenerated": [(constofCareItem?.creditsGenerated && this.checkDecimalValues(constofCareItem?.creditsGenerated)) || null],
      "payerDecidedPatientResponsibility": [(constofCareItem?.payerDecidedPatientResponsibility && this.checkDecimalValues(constofCareItem?.payerDecidedPatientResponsibility)) || null],
      "payerPayment": [(constofCareItem?.payerPayment && this.checkDecimalValues(constofCareItem?.payerPayment, 'payerPayment', constofCareItem?.claimProcessorType || 'Primary')) || null],
      "selfPay": [constofCareItem?.selfPay || this.appointmentDetails?.selfPay],
      "serviceDate": [constofCareItem?.serviceDate || null],
      "userInfoId": [constofCareItem?.userInfoId || null],
      "claimSubmittedDate": [getClaimProcessor?.claimSubmittedDate || null]
    });
  }

  private checkDecimalValues(value: any, isPayer?: string, processorType?: string): number {
    if (value && value % 1 != 0) {
      value?.toFixed(2)
      // value = Math?.round((value + Number.EPSILON) * 100) / 100;
    }
    // Payor payment if we update as Negative value
    if (value && isPayer) {
      const getPayerPay = this.dupAppntCostOfCare?.payments?.find((item: any) => item?.description === 'Reimbursements' && item?.transactionType === 'DEBIT' && processorType === item?.claimProcessorType);
      if (getPayerPay) {
        value = value * -1;
      }
    }
    return value;
  }

  // get appointment cost of care
  public getAppointmentCost(): void {
    this.adjCodeSelected = false;
    this.arrowDown = false;
    this.arrowUp = false;
    // this.selectedClaimId = null;
    // const action = `appointment/registration/retrieveApptCostofCare?appointmentId=${this.appointmentId}`;
    this.loadSpinner = true;
    this.apiService?.retreiveAppntCostOfCareExtrGateway(this.appointmentId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS' || data?.status === 'SUCCESS') {
        this.dupAppntCostOfCare = JSON?.parse(JSON?.stringify(data?.responseObject));
        this.updateCostOfCareItems?.emit(this.dupAppntCostOfCare);
        this.lastStatementDates = this.dupAppntCostOfCare?.lastStatementDate;
        // this.appointmentCostOfCareDetails = data?.responseObject?.apptCostofCare || [];
        this.appointmentCostOfCareDetails = data?.responseObject?.costOfCares || [];

        const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
        this.httpService?.clearFormArray(costofCareArray);

        const itemizedPayments = data?.responseObject?.payments;
        this.pastChargesItemized = [];
        this.postChargesItemized = [];

        itemizedPayments?.forEach((item: any) => {
          item?.itemization?.forEach((element: any) => {
            if (element?.serviceType === 'Past Visit Charges' || element?.serviceType === 'Past Visit Credits Applied' || element?.serviceType === 'Reimbursements' || element?.serviceType === 'Patient Responsibility' || element?.serviceType === 'Payor Payments' || element?.serviceType === 'AR Insurance') {
              element.createdAt = item?.claimInfo?.checkDate || item?.paymentDate;
              element.claimInfo = item?.claimInfo;
              if(element?.amountPaid > 0 && element?.modeOfPayment !== 'ACH' && element?.serviceType !== 'Reimbursements') {
                this.pastChargesItemized?.push(element);
              }
            }
            else { //if (item?.paymentCategory === 'Visit Charges')
              element.createdAt = item?.claimInfo?.checkDate || item?.paymentDate;
              element.claimInfo = item?.claimInfo;
              if(element?.amountPaid > 0) {
                this.postChargesItemized?.push(element);
              }
            }
          });
        });
        // console.log('pastChargesItemized',this.pastChargesItemized);
        //console.log('postChargesItemized',this.postChargesItemized);

        this.appointmentCostOfCareDetails?.forEach((item: any) => {
          // if (item?.patientResponsibility === 0 && item?.totalVisitCharges > 0) {
          //   const patientResponsibilityAmount = (item?.totalVisitCharges || 0) - ((item?.reimburseAmount || 0) + (item?.coPay || 0) + (item?.discount || 0) + (item?.nonPayment || 0));
          //   item.patientResponsibility = patientResponsibilityAmount;
          // }

          // if (item?.approvedAmount > 0) {
          //   if (item?.totalVisitCharges > 0) {
          //     item.discount = item?.approvedAmount - item?.totalVisitCharges;
          //     item.discount = item?.discount < 0 ? item?.discount * -1 : item?.discount;
          //   }
          //   const servicesChanges = data?.responseObject?.itemizedPayments?.filter(serItem => serItem?.serviceType !== 'Co-Pay' && serItem?.serviceType !== 'Past Visit Charges') || [];
          //   let itemizedPayment: number = 0;
          //   servicesChanges?.forEach((amountItem: any) => {
          //     itemizedPayment = itemizedPayment + amountItem?.amount;
          //   });
          //   const prCalc = (item?.approvedAmount + itemizedPayment) - item?.reimburseAmount - item?.coPay;
          //   if (prCalc > 0) {
          //     item.patientResponsibility = prCalc;
          //   } else if (prCalc < 0) {
          //     item.credits = prCalc * -1;
          //   }
          // }
          if (item?.claimProcessorType === "Primary") {
            this.appointmentCostOfCare?.get('writeOffReason')?.setValue(item?.writeOffReason);
            // this.appointmentCostOfCare?.get('writeOffNotes')?.setValue(item?.notes);
          }
          costofCareArray?.push(this.apptCostofCareGroup(item));
        });

        if (!this.appointmentCostOfCareDetails || this.appointmentCostOfCareDetails?.length === 0) {
          costofCareArray?.push(this.apptCostofCareGroup());
        }

        const primaryForm = costofCareArray?.controls?.find((formGrp: FormGroup) => formGrp?.value?.claimProcessorType === 'Primary') as FormGroup;
        primaryForm?.get('balance')?.setValue(this.totalBalanceAmountCount() || null);
        const primaryClaim = costofCareArray?.value?.find((ptItem: any) => ptItem?.claimProcessorType === "Primary") || null;
        const secondaryClaim = costofCareArray?.value?.find((ptItem: any) => ptItem?.claimProcessorType === "Secondary") || null;
        const tertiaryClaim = costofCareArray?.value?.find((ptItem: any) => ptItem?.claimProcessorType === "Tertiary") || null;
        // To update Claim Number to Post Charges update component to get EOB download/view
        const claimNumber = tertiaryClaim ? tertiaryClaim?.claimId : (secondaryClaim ? secondaryClaim?.claimId : (primaryClaim ? primaryClaim?.claimId : null));
        this.claimNumberEmit?.emit(claimNumber);
        // Set Form of visit summary
        const creditsGenerated = costofCareArray?.value?.map(item => item?.creditsGenerated || 0)?.reduce((prev, curr) => prev + curr) || 0;
        const remainingCreds = costofCareArray?.value?.map(item => item?.creditsRemaining || 0)?.reduce((prev, curr) => prev + curr);
        const coPay = costofCareArray?.value?.map(item => item?.coPay || 0)?.reduce((prev, curr) => prev + curr) || 0;
        const refund = costofCareArray?.value?.map(item => item?.refund || 0)?.reduce((prev, curr) => prev + curr) || 0;

        this.visitClaimsSummaryForm?.get('creditsGenerated')?.setValue(creditsGenerated?.toFixed(2));
        this.visitClaimsSummaryForm?.get('creditsRemaining')?.setValue(remainingCreds?.toFixed(2));
        this.visitClaimsSummaryForm?.get('coPay')?.setValue(coPay?.toFixed(2));
        this.visitClaimsSummaryForm?.get('refund')?.setValue(refund?.toFixed(2));
        this.visitClaimsSummaryForm?.get('balance')?.setValue(this.totalBalanceAmountCount() || null);
        this.visitClaimsSummaryForm?.get('writeOff')?.setValue(tertiaryClaim?.writeOff || secondaryClaim?.writeOff || primaryForm?.value?.writeOff || null);
        this.visitClaimsSummaryForm?.get('writeOffReason')?.setValue(tertiaryClaim?.writeOffReason || secondaryClaim?.writeOffReason || primaryForm?.value?.writeOffReason || null);

        this.visitClaimsSummaryForm?.get('totalVisitCharges')?.setValue(tertiaryClaim?.totalVisitCharges || secondaryClaim?.totalVisitCharges || primaryClaim?.totalVisitCharges || null);
        this.visitClaimsSummaryForm?.get('patientResponsibility')?.setValue(tertiaryClaim?.patientResponsibility || secondaryClaim?.patientResponsibility || primaryClaim?.patientResponsibility || null);

        // End - visit summary details

        // this.appointmentCostOfCare?.patchValue(data?.responseObject);
        this.appointmentCostOfCare?.get('itemizedPayments')?.setValue(data?.responseObject?.itemizedPayments);
        this.appointmentCostOfCare?.get('paymentStatus')?.setValue(data?.responseObject?.paymentStatus);
        this.appointmentCostOfCare?.get('payments')?.setValue(data?.responseObject?.payments);
        // to hide Secondary Claim in Adjuments
        if (secondaryClaim || tertiaryClaim) {
          this.adjustmentTypes = this.adjustmentTypes?.filter((item: any) => item !== 'Secondary Claim') || [];
        }
        this.initialWriteOffValue = tertiaryClaim?.writeOff || secondaryClaim?.writeOff || primaryForm?.value?.writeOff || null;
        this.intialWriteOffReason = tertiaryClaim?.writeOffReason || secondaryClaim?.writeOffReason || primaryForm?.value?.writeOffReason || null;
      }
      this.loadSpinner = false;
      this.onWriteOffFormValueChange();
      setTimeout(() => {this.appointmentCostOfCareValueChanges()}, 3000);
    }, (error) => {
      this.loadSpinner = false;
    });
  }

  // change paymeCore
  public changeFormPaymentChanges(): void {
    this.appointmentCostOfCare?.get('payerPayment')?.valueChanges?.subscribe(value => {
      // if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
      //   const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((value || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (this.appointmentCostOfCare?.get('discount')?.value || 0) + (this.appointmentCostOfCare?.get('nonPayment')?.value || 0));
      //   this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      // }
    });
    this.appointmentCostOfCare?.get('discountOrAdjusted')?.valueChanges?.subscribe(value => {

    });
    this.appointmentCostOfCare?.get('nonPayment')?.valueChanges?.subscribe(value => {

    });
  }

  // If Reimbursed Amount value changes
  public reimburseAmountChanges(claimFormGroup?: FormGroup): void {
    const value = claimFormGroup?.get('payerPayment')?.value;
    if (claimFormGroup?.get('patientResponsibility')?.value === 0 && claimFormGroup?.value?.totalVisitCharges > 0) {
      const patientResponsibilityAmount = (claimFormGroup?.value?.totalVisitCharges || 0) - ((value || 0) + (claimFormGroup?.value?.coPay || 0) + (claimFormGroup?.get('discountOrAdjusted')?.value || 0) + (claimFormGroup?.get('nonPayment')?.value || 0));
      claimFormGroup?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
    }
  }

  // If Reimbursed Amount value changes
  public discountAmountChanges(claimFormGroup?: FormGroup): void {
    const value = claimFormGroup?.get('discountOrAdjusted')?.value;
    if (claimFormGroup?.get('patientResponsibility')?.value === 0 && claimFormGroup?.value?.totalVisitCharges > 0) {
      const patientResponsibilityAmount = (claimFormGroup?.value?.totalVisitCharges || 0) - ((claimFormGroup?.get('payerPayment')?.value || 0) + (claimFormGroup?.value?.coPay || 0) + (value || 0) + (claimFormGroup?.get('nonPayment')?.value || 0));
      claimFormGroup?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
    }
  }

  // If Non Payment value Changes
  public changeNonPayment(claimFormGroup?: FormGroup): void {
    const value = claimFormGroup?.get('nonPayment')?.value;
    if (claimFormGroup?.get('patientResponsibility')?.value === 0 && claimFormGroup?.value?.totalVisitCharges > 0) {
      const patientResponsibilityAmount = (claimFormGroup?.value?.totalVisitCharges || 0) - ((claimFormGroup?.get('payerPayment')?.value || 0) + ((claimFormGroup?.value?.coPay || 0) || 0) + (claimFormGroup?.get('discountOrAdjusted')?.value || 0) + (value || 0));
      claimFormGroup?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
    }
  }

  // If Approved Amount Change
  public approvedReimburseAmountChange(claimFormGroup?: FormGroup): void {
    if (claimFormGroup?.value?.allowedAmount > 0) {

      if (claimFormGroup?.get('totalVisitCharges')?.value > 0) {
        const value = claimFormGroup?.get('allowedAmount')?.value - claimFormGroup?.get('totalVisitCharges')?.value;
        claimFormGroup?.get('discountOrAdjusted')?.setValue(value < 0 ? value * -1 : value);
      }

      const servicesChanges = this.appointmentCostOfCare?.value?.itemizedPayments?.filter(serItem => serItem?.serviceType !== 'Co-Pay') || [];
      let itemizedPayment: number = 0;
      servicesChanges?.forEach((amountItem: any) => {
        itemizedPayment = itemizedPayment + amountItem?.amount;
      });
      const prCalc = (claimFormGroup?.get('allowedAmount')?.value + itemizedPayment) - claimFormGroup?.get('payerPayment')?.value - claimFormGroup?.get('coPay')?.value;
      if (prCalc >= 0) {
        claimFormGroup?.get('patientResponsibility')?.setValue(prCalc);
      } else if (prCalc < 0) {
        claimFormGroup?.get('creditsGenerated')?.setValue(prCalc * -1);
      }
    }
  }

  // submit the pay of const of care for appointment
  public submitPayCost(checkEobDocs?: any[]): void {
    // const action = `appointment/registration/saveApptCostofCare`;
    if(this.errorMessageShown) {
      return;
    }
    const params = {
      "appointmentId": this.appointmentId,
      "costOfCares": this.appointmentCostOfCare?.get('apptCostofCare')?.value || []
    }

    if (this.visitClaimsSummaryForm?.get('writeOff')?.value && this.visitClaimsSummaryForm?.get('writeOffReason')?.invalid) {
      this.visitClaimsSummaryForm?.markAllAsTouched();
      return;
    }

    const claimVisitDup = JSON?.parse(JSON?.stringify(this.appointmentCostOfCare?.get('apptCostofCare')?.value)) || [];
    const primaryClaim = claimVisitDup?.find((ptItem: any) => ptItem?.claimProcessorType === "Primary") || null;
    const secondaryClaim = claimVisitDup?.find((ptItem: any) => ptItem?.claimProcessorType === "Secondary") || null;
    const tertiaryClaim = claimVisitDup?.find((ptItem: any) => ptItem?.claimProcessorType === "Tertiary") || null;

    // check is secondary or tertiary is present
    // if (tertiaryClaim) {
    //   const dataMap = params?.costOfCares?.find((ptItem: any) => ptItem?.claimProcessorType === "Tertiary");
    //   if (this.visitClaimsSummaryForm?.value?.adjFacilityCode != 'Add Co-Pay') {
    //     dataMap.adjFacilityCode = this.visitClaimsSummaryForm?.value?.adjFacilityCode;
    //     dataMap.adjFacility = this.visitClaimsSummaryForm?.value?.adjFacility;
    //   }
    // } else if (secondaryClaim) {
    //   const dataMap = params?.costOfCares?.find((ptItem: any) => ptItem?.claimProcessorType === "Secondary");
    //   if (this.visitClaimsSummaryForm?.value?.adjFacilityCode != 'Add Co-Pay') {
    //     dataMap.adjFacilityCode = this.visitClaimsSummaryForm?.value?.adjFacilityCode;
    //     dataMap.adjFacility = this.visitClaimsSummaryForm?.value?.adjFacility;
    //   }
    // } else if (primaryClaim) {
    //   const dataMap = params?.costOfCares?.find((ptItem: any) => ptItem?.claimProcessorType === "Primary");
    //   if (this.visitClaimsSummaryForm?.value?.adjFacilityCode != 'Add Co-Pay') {
    //     dataMap.adjFacilityCode = this.visitClaimsSummaryForm?.value?.adjFacilityCode;
    //     dataMap.adjFacility = this.visitClaimsSummaryForm?.value?.adjFacility;
    //   }
    // }

    params?.costOfCares?.forEach((wItem: any) => {
      if (wItem?.claimProcessorType === "Primary") {
        // All adjustments Code and Value should be apply under Primary, also if have multiple Processor Types
        wItem.adjFacilityCode = this.visitClaimsSummaryForm?.value?.adjFacility !== null && this.visitClaimsSummaryForm?.value?.adjFacility !== undefined ? this.visitClaimsSummaryForm?.value?.adjFacilityCode : null;
        wItem.adjFacility = wItem?.adjFacilityCode ? this.visitClaimsSummaryForm?.value?.adjFacility : null;

        wItem.writeOff = this.visitClaimsSummaryForm?.get('writeOff')?.value;
        wItem.writeOffReason = this.visitClaimsSummaryForm?.get('writeOffReason')?.value;
        // WriteOff
        const dupWriteOffVal = JSON?.parse(JSON?.stringify(wItem.writeOff));
        if (this.initialWriteOffValue && dupWriteOffVal && this.initialWriteOffValue !== dupWriteOffVal) {
          wItem.writeOff = dupWriteOffVal - this.initialWriteOffValue;
          wItem.adjFacility = dupWriteOffVal - this.initialWriteOffValue;
        }
        if (this.visitClaimsSummaryForm?.get('adjFacilityCode')?.value === 'Add Co-Pay') {
          wItem.addCopay = this.visitClaimsSummaryForm?.get('adjFacility')?.value;
        }
        if (this.visitClaimsSummaryForm?.get('adjFacilityCode')?.value === 'Reduce Co-Pay') {
          wItem.reduceCopay = this.visitClaimsSummaryForm?.get('adjFacility')?.value;
        }
      }
    });

    // if(this.appointmentCostOfCare?.get('apptCostofCare')?.value?.find((ptItem: any) => ptItem?.claimProcessorType === "Primary")?.writeOff && this.appointmentCostOfCare?.get('writeOffReason')?.invalid) {
    //   this.appointmentCostOfCare?.markAllAsTouched();
    //   return;
    // }

    // params?.listApptCostofCares?.forEach((wItem: any) => {
    //   if (wItem?.claimProcessorType === "Primary") {
    //     wItem.writeOffReason = this.appointmentCostOfCare?.get('writeOffReason')?.value;
    //     wItem.notes = this.appointmentCostOfCare?.get('writeOffNotes')?.value;
    //   }
    //   if (wItem?.adjFacilityCode) {
    //     wItem.adjFacility = wItem?.adjFacilityCode?.includes('reduce') || wItem?.adjFacilityCode?.includes('Reduce') ? wItem?.adjFacility * -1 : wItem?.adjFacility;
    //   }
    //   if (wItem?.adjCreditsRadio) {
    //     wItem.adjCredits = wItem?.adjCreditsRadio === 'minus' ? wItem?.adjCredits * -1 : wItem?.adjCredits;
    //   }
    // });

    this.cardRefresh?.cardRefreshShow();
    this.loadSpinner = true;
    this.apiService?.saveCostOfCareExtrGateway(params)?.subscribe((data: any) => {
      this.cardRefresh?.cardRefreshHide();
      if (data?.body?.status === 'SUCCESS') {
        const getCostofcareItem: any = this.dupAppntCostOfCare?.costOfCares?.find((payItem: any) => payItem?.claimProcessorType === 'Primary') || null;
        const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
        const primaryClaim = costofCareArray?.value?.find((ptItem: any) => ptItem?.claimProcessorType === "Primary") || null;
        // if (checkEobDocs && checkEobDocs?.length > 0) {
        if ((getCostofcareItem && getCostofcareItem?.selfPay) || this.isFollowup || !primaryClaim?.claimId) {
          this.notifyText = 'Payment details have been updated successfully.';
          this.notifyText = this.notifyText + "<br>" + this.claimSubmitNotifyText;
          this.isSuccessNotify = true;
          if (this.mainExceptionModal) {
            this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
          } else {
            this.exceptionalModal?.modalShow();
          }
        } else {
          // this.notifyText = 'Payment details have been updated successfully. If the EOB document is available, please upload.';
          this.notifyText = `Payment details have been successfully updated. Please update EOB check Details`;
          this.notifyText = this.notifyText + "<br>" + this.claimSubmitNotifyText;
          this.isSuccessNotify = true;
          this.eobCheckNotify?.show();
        }
        this.saveComplete = false;
        this.getAppointmentCost();
        if (this.postChargesUserDetails) {
          this.getPendingPaymentsByUser(this.postChargesUserDetails?.userInfoId, this.appointmentId);
        }
        this.visitClaimsSummaryForm?.get('adjFacility')?.setValue(null);
        this.visitClaimsSummaryForm?.get('adjFacilityCode')?.setValue(null);
        this.visitClaimsSummaryForm?.get('adjFacilityCode')?.setValidators(null);
        this.visitClaimsSummaryForm?.get('adjFacilityCode')?.updateValueAndValidity();
        this.visitClaimsSummaryForm?.get('adjFacility')?.updateValueAndValidity();
        this.callPendingPayments?.emit(true);
        
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.notifyText = this.notifyText + "<br>" + this.claimSubmitNotifyText;
        this.isSuccessNotify = false;
        if (this.mainExceptionModal) {
          this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
        } else {
          this.exceptionalModal?.modalShow();
        }
      }
      this.loadSpinner = false;
      this.guardControl?.reset();
    }, (error) => {
      this.loadSpinner = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.notifyText = this.notifyText + "<br>" + this.claimSubmitNotifyText;
      this.isSuccessNotify = false;
      if (this.mainExceptionModal) {
        this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
      } else {
        this.exceptionalModal?.modalShow();
      }
      this.cardRefresh?.cardRefreshHide();
      this.guardControl?.reset();
    });
  }

  public responseChange(change?: any) {
    this.saveComplete = true;
  }

  // open card payment 
  public async openPayment() {
    this.errorMessageShown = null;
    if (this.totalBalanceAmountCount() === 0) {
      this.erroMsg('Balance should be minimum of $1 to make payment.');
      return;
    }
    if (this.saveComplete) {
      this.notifyText = 'Please Save befor payment';
      this.isSuccessNotify = false;
      if (this.mainExceptionModal) {
        this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
      } else {
        this.exceptionalModal?.modalShow();
      }
      return;
    }
    this.cardRefresh?.cardRefreshShow();
    let appointmentInfoDetails: any
    try {
      const action = `appointment/registration/getAppointmentById?id=${this.appointmentId}`;
      appointmentInfoDetails = await this.httpService?.makeGetRequest(action, '')?.toPromise();
    } catch (error) {
      this.cardRefresh?.cardRefreshHide();
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      if (this.mainExceptionModal) {
        this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
      } else {
        this.exceptionalModal?.modalShow();
      }
      return;
    }
    this.cardRefresh?.cardRefreshHide();
    if (this.isPostChargesPage || this.isClaimPage) {
      this.paymentCall?.emit(appointmentInfoDetails?.responseObject);
    } else {
      this.payComp?.openModal(appointmentInfoDetails?.responseObject, true);
    }
    // this.payComp?.enableCardPay();
  }

  // Calculate by Column
  public calculateTotalAccountBal(controlsName: string): any {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    let sum: number;
    if (costofCareArray?.controls?.length > 0 && controlsName !== 'adjFacility') {
      sum = costofCareArray?.value?.map(item => item[controlsName] || 0)?.reduce((prev, curr) => prev + curr);
    }
    if (costofCareArray?.controls?.length > 0 && controlsName === 'adjFacility') {
      sum = costofCareArray?.value?.map(item => (item?.adjFacilityRadio === 'minus' ? item?.adjFacility * -1 : item?.adjFacility) || 0)?.reduce((prev, curr) => prev + curr);
    }
    return sum || 0;
  }

  public loadPaymentInfo() {
    this.guardControl?.reset();
    console.log(4444);
    this.paymentReloadAccBalance?.emit(true);
    
    this.getAppointmentCost();
    this.billingcodesComp?.getBillingCodesList();
  }

  // check Write off value should be less than or equal to Patient Responsibilty
  public checkwithArPatient(costofCareItem?: FormGroup): void {
    this.errorMessageShown = null;
    if (costofCareItem?.get('patientResponsibility')?.value < costofCareItem?.get('writeOff')?.value) {
      costofCareItem?.get('writeOff')?.setValue(null);
      this.erroMsg('Write Off value should not be greater than Balance.');
    }
  }

  // Check if any enetered value should not be great than the TotalVisit Charges
  public checkTotalCharges(formGroup: FormGroup, controlName: string): void {
    // if (formGroup?.get('claimProcessorType')?.value !== 'Primary') {
    //   return;
    // }
    this.errorMessageShown = null;
    if (formGroup?.get('totalVisitCharges')?.value < formGroup?.get(controlName)?.value) {
      // formGroup?.get(controlName)?.setValue(null);
      this.erroMsg('The entered value must not exceed the billed charges.');
    }
    if (controlName === 'writeOff' && !formGroup?.get('writeOff')?.value) {
      formGroup?.get('writeOffReason')?.setValue(null);
      // this.visitClaimsSummaryForm?.get('adjFacility')?.setValue(null);
      // this.visitClaimsSummaryForm?.get('adjFacilityCode')?.setValue(null);
    }
    if (controlName === 'writeOff' && formGroup?.get('writeOff')?.value) {
      formGroup?.get('writeOffReason')?.setValue(this.intialWriteOffReason || null);
    }
  }

  public checkIncreaseReduce(costofCareItem: FormGroup, controlName: string): void {
    const dupRemainingCreds = costofCareItem?.get('creditsRemaining')?.value;
    const patientResp = costofCareItem?.get('patientResponsibility')?.value;
    const facCodeVal = costofCareItem?.get('adjFacilityCode')?.value;
    this.errorMessageShown = null;
    if (facCodeVal === 'Reduce Credits') {
      if (dupRemainingCreds < costofCareItem?.get(controlName)?.value) {
        // costofCareItem?.get(controlName)?.setValue(null);
        this.erroMsg('The entered value must not exceed the credits available.');
      }
    }
    if (facCodeVal === 'Reduce Balance') {
      if (patientResp < costofCareItem?.get(controlName)?.value) {
        // costofCareItem?.get(controlName)?.setValue(null);
        this.erroMsg('The entered value must not exceed the balance.');
      }
    }
    if (facCodeVal && facCodeVal === 'Secondary Claim') {
      if (patientResp < costofCareItem?.get(controlName)?.value) {
        // costofCareItem?.get(controlName)?.setValue(null);
        this.erroMsg('The entered value must not exceed the balance.');
      }
    }
  }

  public erroMsg(msg): void {
    this.errorMessageShown = msg;
    // setTimeout(() => {
    //   this.errorMessageShown = null;
    // }, 4000);
  }

  public successMsg(msg): void {
    this.successMessageShown = msg;
    setTimeout(() => {
      this.successMessageShown = null;
    }, 4000);
  }

  // Calculate by Column
  public calculateTotalWriteOff(): boolean {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    let sum: number;
    if (costofCareArray?.controls?.length > 0) {
      sum = costofCareArray?.value?.map(item => item?.writeOff || 0)?.reduce((prev, curr) => prev + curr);
    }
    return sum > 0 ? true : false;
  }

  // get claim config code
  public getClaimConfigCodes(): void {
    this.apiService?.getBillingClaimCofigCodes('Claim Status Reason')?.subscribe((data?: any) => {
      if (data?.status === 'SUCCESS') {
        this.allClaimWriteoffCodes = data?.responseObject || [];
      }
    }, error => {

    });
  }

  // customize the Appointment Date time
  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

  public checkradioSelection(eleId: string, accountBalance: FormGroup, optValue: any): void {
    const selectedValue = accountBalance?.get('adjFacilityRadio')?.value;
    const element = document?.getElementById(`credit${optValue}-${eleId}`) as HTMLInputElement;
    if (optValue === selectedValue) {
      element.checked = false;
      accountBalance?.get('adjFacilityRadio')?.reset();
      accountBalance?.get('adjFacility')?.reset();
    }
  }

  public checkCreditsAdjustSelection(eleId: string, accountBalance: FormGroup, optValue: any): void {
    const selectedValue = accountBalance?.get('adjCreditsRadio')?.value;
    const element = document?.getElementById(`${optValue}-${eleId}`) as HTMLInputElement;
    if (optValue === selectedValue) {
      element.checked = false;
      accountBalance?.get('adjCreditsRadio')?.reset();
      accountBalance?.get('adjCredits')?.reset();
    }
  }

  // Update remianing Credits
  public updateRemainingCreditsOnAdjCredis(costofCareItem: FormGroup, creditsRemainingDup: string): void {
    const selectedRadio = costofCareItem?.get('adjCreditsRadio')?.value;
    const updateVal = costofCareItem?.get('adjCredits')?.value;
    const ogVal = selectedRadio === 'minus' ? updateVal * -1 : updateVal;
    const ogRemaningCrdtsVal = costofCareItem?.get('creditsRemaining')?.value;
    costofCareItem?.get(creditsRemainingDup)?.setValue(ogRemaningCrdtsVal + ogVal);
  }

  // Calculate Refund and Remainign Credits
  public calculateRefundRemainingCredits(costofCareItem: FormGroup): void {
    const getDupRefund = costofCareItem?.get('refundDup')?.value || 0;
    const refundVal = costofCareItem?.get('refund')?.value || 0;
    const creditsRemaining = costofCareItem?.get('creditsRemaining')?.value || 0;
    const dupRemainingCreds = costofCareItem?.get('creditsRemainingDup')?.value;
    const calCreds = (dupRemainingCreds - (refundVal - getDupRefund)) || 0;
    costofCareItem?.get('creditsRemaining')?.setValue(calCreds > 0 ? calCreds : 0);
  }

  // NEW - Adjustment
  public changeAdjustmentTypes(costofCareItem: FormGroup): void {
    const dupRemainingCreds = costofCareItem?.get('creditsRemaining')?.value;
    const patientResp = costofCareItem?.get('patientResponsibility')?.value;
    const adjCode = costofCareItem?.get('adjFacilityCode')?.value;
    if (adjCode) {
      this.adjCodeSelected =true;
    }
    else{
      this.adjCodeSelected =false;
    }
    this.errorMessageShown = null;
    costofCareItem?.get('adjFacility')?.enable();
    if (adjCode && adjCode?.includes('Reduce')) {
      if (adjCode === 'Reduce Credits' && (!dupRemainingCreds || parseInt(dupRemainingCreds) === 0)) {
        // costofCareItem?.get('adjFacilityCode')?.setValue(null);
        costofCareItem?.get('adjFacility')?.setValue(null);
        costofCareItem?.get('adjFacility')?.disable();
        this.erroMsg('The patient account currently has no available credits.');
      }
      if (adjCode === 'Reduce Balance' && (this.totalBalanceAmountCount() === 0 || parseInt(patientResp) === 0)) {
        // costofCareItem?.get('adjFacilityCode')?.setValue(null);
        costofCareItem?.get('adjFacility')?.setValue(null);
        costofCareItem?.get('adjFacility')?.disable();
        this.erroMsg('The patient account currently has no balance.');
      }
    }
    if(adjCode && adjCode === 'Refund') {
      if (!dupRemainingCreds || parseInt(dupRemainingCreds) === 0) {
        // costofCareItem?.get('adjFacilityCode')?.setValue(null);
        costofCareItem?.get('adjFacility')?.setValue(null);
        costofCareItem?.get('adjFacility')?.disable();
        this.erroMsg('The patient account currently has no available credits.');
      }
    }
    if (adjCode && adjCode === 'Secondary Claim') {
      if (this.totalBalanceAmountCount() === 0 || parseInt(patientResp) === 0) {
        // costofCareItem?.get('adjFacilityCode')?.setValue(null);
        costofCareItem?.get('adjFacility')?.setValue(null);
        costofCareItem?.get('adjFacility')?.disable();
        this.erroMsg('The patient account currently has no balance.');
      }
    }
    // if (adjCode === 'Add Co-Pay' && (!this.visitClaimsSummaryForm?.get('coPay')?.value || parseInt(this.visitClaimsSummaryForm?.get('coPay')?.value) === 0)) {
    //   costofCareItem?.get('adjFacilityCode')?.setValue(null);
    //   costofCareItem?.get('adjFacility')?.setValue(null);
    //   this.erroMsg('The patient account currently has no available credits.');
    // }
    //  || adjCode === 'Add Co-Pay')
    if (adjCode === 'Reduce Co-Pay' && (!this.visitClaimsSummaryForm?.get('coPay')?.value || parseInt(this.visitClaimsSummaryForm?.get('coPay')?.value) === 0)) {
      // costofCareItem?.get('adjFacilityCode')?.setValue(null);
      // costofCareItem?.get('adjFacility')?.setValue(null);
      this.erroMsg('No Payments made by Patient, as a result credits/balance will be generated or modified.');
      // this.erroMsg('The Co-Pay/Co-Insurance should be greater than zero.');
    }
  }

  // Checking if the value of the adjustment is entered or code is sleected or not
  public checkAdjustmentValue(costofCareItem: FormGroup, controlName: string) {
    this.errorMessageShown = null;
    if (this.adjCodeSelected && costofCareItem?.get(controlName)?.value) {
      this.adjValueEntered = true;
    } else {
      this.adjValueEntered = false;
      if(!costofCareItem?.get('adjFacilityCode')?.value) {
        this.erroMsg('Please select the adjustment type before entering the value.');
        // costofCareItem?.get('adjFacility')?.setValue(null);
      }
    }
  }

  // Validation if adjustment values neter and type not selected
  public validateAdjustmentType(costofCareItem: FormGroup): void {
    const adjFacility = costofCareItem?.get('adjFacility')?.value || 0;
    if (adjFacility) {
      costofCareItem?.get('adjFacilityCode')?.setValidators([Validators?.required]);
      costofCareItem?.get('adjFacilityCode')?.markAllAsTouched();
    } else {
      costofCareItem?.get('adjFacilityCode')?.setValidators(null);
    }
    costofCareItem?.get('adjFacilityCode')?.updateValueAndValidity();
  }

  // Calculate all Itimized total paid amount
  public calculateTotalaPaidItimized(): number {
    let sum: number;
    // if (this.dupAppntCostOfCare?.itemizedPayments?.length > 0) {
    //   sum = this.dupAppntCostOfCare?.itemizedPayments?.map(item => item?.amount || 0)?.reduce((prev, curr) => prev + curr);
    // }
    // if (this.dupAppntCostOfCare?.payments?.length > 0) {
    //   sum = this.dupAppntCostOfCare?.payments?.map(item => (item?.amountPaid > 0 ? (item?.amountPaid) : item?.amountPaid) || 0)?.reduce((prev, curr) => prev + curr);
    // }
    if (this.dupAppntCostOfCare?.costOfCares?.length > 0) {
      sum = this.dupAppntCostOfCare?.costOfCares?.map(item => (item?.totalAmountPatientPaid > 0 ? (item?.totalAmountPatientPaid) : item?.totalAmountPatientPaid) || 0)?.reduce((prev, curr) => prev + curr);
    }
    return sum || 0;
  }

  // Reset the appntcost of care while closing the popup
  public resetPostCharges(): void {
    this.guardControl?.reset();
    const formArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    this.httpService?.clearFormArray(formArray);
    this.visitClaimsSummaryForm?.reset();
  }

  public viewEob(claimId , position:string): void {
    if(position === 'mLink' && this.selectedClaimId === claimId){
      
        this.arrowUp = !this.arrowUp;
        this.arrowDown = !this.arrowDown;

        if(!this.arrowUp){
          return ;

        }    
    }
    this.eraDetails = [];
    const action = `claim/getEraDetails?appointmentId=${this.appointmentId}&claimId=${claimId}`;
    this.loadSpinner = true;
    this.claimsService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.body?.status === 'SUCCESS') {
        this.arrowUp = true;
        this.arrowDown = false;
        this.selectedClaimId = claimId;
        if (data?.body?.responseObject) {
          

          this.eraDetails = data?.body?.responseObject;
          if(this.eraDetails){
          this.isManulEdit = this.eraDetails?.manual;
          this.selectedclaimProcessorType = this.eraDetails?.claimDetails?.claimProcessorType;

          const lineCharges = data?.body?.responseObject?.claimInsightsEra?.detailsPerServiceLines || [];
          this.serviceLineChargesResponse = lineCharges;
          
        const serviceLineArray = this.serviceLineCharges?.get('serviceLine') as FormArray;
        this.httpService?.clearFormArray(serviceLineArray);
        lineCharges?.forEach((item: any) => {
         // console.log(item);
          const itemValues = {
            "cptCode":item?.cptCode,
            "cptCodeDescription":item?.cptCodeDescription,
            "icdCode1":item?.icdCode1,
            "icdCode2":item?.icdCode2,
            "icdCode3":item?.icdCode3,
            "icdCode4":item?.icdCode4,
            "cptModifier":item?.cptModifier,
            "billedChargesCptCode":item?.billedChargesCptCode,
            "payerAllowedAmountCptCode":item?.payerAllowedAmountCptCode,
            "adjustmentOrDiscountAmount":item?.adjustmentOrDiscountAmount,
            "nonPayment":item?.nonPayment,
            "patientResponsibility":item?.patientResponsibility,
            "isExistingRow":true

          };
         // console.log(itemValues);
          serviceLineArray?.push(this.serviceLineGroup(itemValues));
          

        })
        if (!lineCharges || lineCharges?.length === 0) {
          serviceLineArray?.push(this.serviceLineGroup());
        }
        
        this.serviceLineCharges?.get('claimId')?.setValue(data?.body?.responseObject?.claimDetails?.id);
        this.serviceLineCharges?.get('patientControlNumber')?.setValue(data?.body?.responseObject?.claimInsightsEra?.patientControlNumber);

       // console.log("serviceLineCharges form-",this.serviceLineCharges);
      } 
        
         /* const detailsPerServiceLines = this.eraDetails?.claimInsightsEra?.detailsPerServiceLines;
          this.totalBilledAmountPDF = '';
          this.totalAllowedAmountPDF = '';
          this.totalPatientResponsibilityPDF = '';
          this.totalPaidAmountPDF = '';
          
          let billedChargesCptCode = 0;
          let payerAllowedAmountCptCode = 0;
          let patientResponsibility = 0;
          let payerPaymentCptCode = 0;

          detailsPerServiceLines?.forEach((lineInfo: any) => {
             billedChargesCptCode += lineInfo?.billedChargesCptCode;
             payerAllowedAmountCptCode += lineInfo?.payerAllowedAmountCptCode;
             patientResponsibility += lineInfo?.patientResponsibility;
             payerPaymentCptCode += lineInfo?.payerPaymentCptCode;

            this.totalBilledAmountPDF = billedChargesCptCode;
            this.totalAllowedAmountPDF = payerAllowedAmountCptCode;
            this.totalPatientResponsibilityPDF = patientResponsibility;
            this.totalPaidAmountPDF = payerPaymentCptCode;

          });  */          
         
         
        }
        else {
          const serviceLineArray = this.serviceLineCharges?.get('serviceLine') as FormArray;
            
            this.httpService?.clearFormArray(serviceLineArray);

        }

      }

    }, error => {
      
      this.loadSpinner = false;

    })
  }

  public dateFormate(date: string): string {
    let splitDate: any;
    let actDate: any;
    if (date?.includes('T')) {
      splitDate = date?.toString()?.split('T');
      actDate = splitDate && splitDate?.length > 0 && splitDate[0]
    } else {
      actDate = date;
    }
    return actDate || date;
    // return this.httpService?.getAppointmentTime(date) || date;
  }

  public closeLineOverview(){
    this.eraDetails = [];
    this.arrowDown = true;
    this.arrowUp = false;
  
  }

  public serviceLineGroup(serviceLineItem?: any): FormGroup {
    
    return this.formBuilder?.group({
      "cptCode":[(serviceLineItem?.cptCode) || null],
      "cptCodeDescription":[(serviceLineItem?.cptCodeDescription) || null],
      "icdCode1":[(serviceLineItem?.icdCode1) || null],
      "icdCode2":[(serviceLineItem?.icdCode2) || null],
      "icdCode3":[(serviceLineItem?.icdCode3) || null],
      "icdCode4":[(serviceLineItem?.icdCode4) || null],
      "cptModifier":[(serviceLineItem?.cptModifier) || null],
      "billedChargesCptCode":[(serviceLineItem?.billedChargesCptCode && this.checkDecimalValues(serviceLineItem?.billedChargesCptCode)) || null, Validators?.required],
      "payerAllowedAmountCptCode": [(serviceLineItem?.payerAllowedAmountCptCode && this.checkDecimalValues(serviceLineItem?.payerAllowedAmountCptCode)) || null, Validators?.required],
      "adjustmentOrDiscountAmount": [(serviceLineItem?.adjustmentOrDiscountAmount && this.checkDecimalValues(serviceLineItem?.adjustmentOrDiscountAmount)) || null, Validators?.required],
      "nonPayment": [(serviceLineItem?.nonPayment  && this.checkDecimalValues(serviceLineItem?.nonPayment)) || null, Validators?.required],
      "patientResponsibility":[(serviceLineItem?.patientResponsibility && this.checkDecimalValues(serviceLineItem?.patientResponsibility)) || null, Validators?.required],      
      "isExistingRow":[serviceLineItem?.isExistingRow || false]
    });
  }

  
  public submitServiceLineCharges(){
    if(!this.isManulEdit || !this.arrowUp){
      this.claimSubmitNotifyText = '';
     return;
    }
    const action = `claim/saveManualClaimEraResponse`;
    const lineList = [];
    const claimLineInfoList = this.serviceLineCharges?.get('serviceLine')?.value || [];
    claimLineInfoList?.forEach((wItem: any) => {
     // console.log(wItem);
      const dxCodeString = wItem?.icdCode1+","+wItem?.icdCode2+","+wItem?.icdCode3+","+wItem?.icdCode4;
      const obj = {
      "adjusted": parseFloat(wItem?.adjustmentOrDiscountAmount),
      "allowedAmount": parseFloat(wItem?.payerAllowedAmountCptCode),
      "approvedAmount": parseFloat(wItem?.payerAllowedAmountCptCode),
      "nonAmount": parseFloat(wItem?.nonPayment),
      "billedAmount": parseFloat(wItem?.billedChargesCptCode),
      //"dxCodes":[],
     // "modifier":wItem?.cptModifier,
      "patientResponsibility":wItem?.patientResponsibility,
      "procedureCode":wItem?.cptCode
      }
      lineList?.push(obj);
    });

    const params = {
      "appointmentId": this.appointmentId,
      "claimId":this.serviceLineCharges?.get('claimId')?.value,
      "patientControlNumber":this.serviceLineCharges?.get('patientControlNumber')?.value,
      "claimLineInfoList": lineList
    }
  
  // console.log(params);
    //return;
    this.cardRefresh?.cardRefreshShow();
    this.loadSpinner = true;
    this.claimsService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      this.arrowDown = true;
       this.arrowUp = false;
      this.cardRefresh?.cardRefreshHide();
      if (data?.body?.status === 'SUCCESS') {
        this.claimSubmitNotifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
       
        this.isSuccessNotify = true;
        
       // this.claimLineSubmit?.show();
        this.viewEob(this.selectedClaimId, 'mLink')
        
      } else {
        this.claimSubmitNotifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        
       // this.claimLineSubmit?.show();
        
      }
      this.loadSpinner = false;
    }, (error) => {
      this.loadSpinner = false;
      this.claimSubmitNotifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
     // this.claimLineSubmit?.show();
      
    });
  
  }

  public addServiceLineCharges(): void {
   // console.log(this.serviceLineChargesResponse);
    const serviceLineArray = this.serviceLineCharges?.get('serviceLine') as FormArray;  
    
    serviceLineArray?.push(this.serviceLineGroup());
  }

  public deleteServiceLine(index: number): void {
    const serviceLineArray = this.serviceLineCharges?.get('serviceLine') as FormArray;
    serviceLineArray?.removeAt(index);
  }

  public validateBilledAmount(formGroup: FormGroup, controlName: string): void {
    // if (formGroup?.get('claimProcessorType')?.value !== 'Primary') {
    //   return;
    // }
    if (formGroup?.get('billedChargesCptCode')?.value < formGroup?.get(controlName)?.value) {
      formGroup?.get(controlName)?.setValue(null);
      this.erroMsgClaim('The entered value must not exceed the billed charges.');
    }
    
  }


  public erroMsgClaim(msg): void {
    this.errorMessageClaimShown = msg;
    setTimeout(() => {
      this.errorMessageClaimShown = null;
    }, 3000);
  }

  public successMsgClaim(msg): void {
    this.successMessageClaimShown = msg;
    setTimeout(() => {
      this.successMessageClaimShown = null;
    }, 3000);
  }

  // Write Off value changes
  public onWriteOffFormValueChange(): void {
    this.visitClaimsSummaryForm?.get("writeOff")?.valueChanges?.subscribe((writeoffValue: any) => {
      if (this.initialWriteOffValue !== writeoffValue) {
        this.visitClaimsSummaryForm?.get('adjFacility')?.setValue(writeoffValue);
        this.visitClaimsSummaryForm?.get('adjFacilityCode')?.setValue('Write Off');
      } else {
        this.visitClaimsSummaryForm?.get('adjFacility')?.setValue(null);
        this.visitClaimsSummaryForm?.get('adjFacilityCode')?.setValue(null);
      }
    });
  }

  // Get Account Balance with userinfo to get the status
  public getPendingPaymentsByUser(userInfoId: string, appntId: string): void {
    this.apiService?.getChartInfoAccountBalance(userInfoId)?.subscribe((data: any) => {
      if (data?.status == 'SUCCESS') {
        if (data?.responseObject && data?.responseObject?.paymentsDetails && data?.responseObject?.paymentsDetails?.length > 0) {
          this.accountBalancePaymentStatus = data?.responseObject?.paymentsDetails?.find((allPayments: any) => allPayments?.appointmentId === appntId) || null;
        }
      }
    }, error => {

    });
  }

  // Open check details modal
  public openCheckDetailsModal(): void {
    this.checkDetailsModal?.show();
    this.checkNumberDateForm?.get('processorType')?.setValue('Primary');
    this.changeProcessorTypeData('Primary');
  }

  // Save the check umber and date details
  public saveCheckNumberDateDetails(checkNumberDateModal: UiModalComponent): void {
    if (this.checkNumberDateForm?.invalid) {
      this.checkNumberDateForm?.markAllAsTouched();
      return;
    }
    const params: any = this.checkNumberDateForm?.value;
    params.appointmentId = this.appointmentId;
    this.loadSpinner = true;
    this.apiService?.saveCheckNumberDateDetails(params)?.subscribe((data: any) => {
      if (data?.body?.status === "SUCCESS") {
        this.notifyText = data?.body?.message || 'Check details updated successfully.';
        this.isSuccessNotify = true;
        this.exceptionalModal?.modalShow();
        this.getAppointmentCost();
      } else {
        this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
      }
      checkNumberDateModal?.hide();
      this.checkNumberDateForm?.reset();
      this.loadSpinner = false;
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.loadSpinner = false;
      checkNumberDateModal?.hide();
      this.checkNumberDateForm?.reset();
    });
  }

  // Value changes for post charges
  private appointmentCostOfCareValueChanges(): void {
    this.appointmentCostOfCare?.valueChanges?.subscribe((valueChange: any) => {
      this.guardControl?.setValue(true);
    });

    this.serviceLineCharges?.valueChanges?.subscribe((valueChange: any) => {
      this.guardControl?.setValue(true);
    });

    this.visitClaimsSummaryForm?.valueChanges?.subscribe((valueChange: any) => {
      this.guardControl?.setValue(true);
    });
  }

  // change processor type data
  public changeProcessorTypeData(event: any): void {
    const getPayment: any = this.dupAppntCostOfCare?.payments?.find((payItem: any) => payItem?.claimProcessorType === event && payItem?.paymentCategory === 'Payer' && payItem?.description === 'Reimbursements') || null;
    if (getPayment) {
      const getCheckDate = getPayment?.claimInfo?.checkDate ? (getPayment?.claimInfo?.checkDate?.includes('T') ? getPayment?.claimInfo?.checkDate.split('T')[0] : getPayment?.claimInfo?.checkDate) : null;
      this.checkNumberDateForm?.get('checkNumber')?.setValue(getPayment?.claimInfo?.checkNumber || null);
      this.checkNumberDateForm?.get('checkDate')?.setValue(getCheckDate || null);
    } else {
      this.checkNumberDateForm?.get('checkNumber')?.setValue(null);
      this.checkNumberDateForm?.get('checkDate')?.setValue(null);
    }
  }

  // Download or View EOB documents
  // View or Download the document
  public viewDonloadEobDocu(costofCareItem?: FormGroup, isView?: string, viewDownloadEobEncounterDoc?: UiModalComponent): void {
    const eobDocument = this.checkEobDocAvailable(costofCareItem) || [];
    if (eobDocument && eobDocument?.length === 0) {
      this.notifyText = 'Explanation of Benefits not received.';
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
    } else {
      this.filterProcessorEobDocs = eobDocument || [];
      this.viewDownloadDocument(eobDocument[0], isView, viewDownloadEobEncounterDoc, 1);
    }
  }

  // Check is EOB documet is there 
  private checkEobDocAvailable(costofCareItem: FormGroup): any[] {
    let eobDocument: any[] = [];
    if (costofCareItem?.value?.claimProcessorType === 'Primary') {
      eobDocument = this.allEobDocuments?.filter((listItem: any) => listItem?.documentType === 'EOB') || [];
    }
    if (costofCareItem?.value?.claimProcessorType === 'Secondary') {
      eobDocument = this.allEobDocuments?.filter((listItem: any) => listItem?.documentType === 'EOB2') || [];
    }
    if (costofCareItem?.value?.claimProcessorType === 'Tertiary') {
      eobDocument = this.allEobDocuments?.filter((listItem: any) => listItem?.documentType === 'EOB3') || [];
    }
    return eobDocument || [];
  }
  
  //  to get all the additonal documents
  public getAllEobDocs(isDocUpload?: boolean): void {
    if(isDocUpload) {
      // this.reloadPendingPayments?.emit(true);
    }
    const action = `record/listDocuments?appointmentId=${this.appointmentId}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action, '')
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          const responseObj = data?.responseObject || [];
          this.allEobDocuments = responseObj?.filter((listItem: any) => listItem?.documentType === 'EOB' || listItem?.documentType === 'EOB2' || listItem?.documentType === 'EOB3') || [];
          this.allEobDocuments?.sort((a, b) => {
            const dateA:any = new Date(a?.auditInfo?.createdDate);
            const dateB:any = new Date(b?.auditInfo?.createdDate);
            return dateB - dateA;
          });
          
          this.eobDocuments?.emit(this.allEobDocuments);
        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
        }
        this.loadSpinner = false;
      }, error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // Show Documents view popup
  public viewDownloadDocument(eobDocument: any, isViewDownload: string, patientMedicalRecordModal: UiModalComponent, index?: number) {
    if (!eobDocument?.s3Key || !eobDocument?.s3Path) {
      return;
    }
    this.eobViewIndex = index;
    const fileType = eobDocument?.fileType;
    this.loadSpinner = true;
    const action = `record/downloadDocument?s3Key=${eobDocument?.s3Key}&s3Path=${eobDocument?.s3Path}`;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      if (!data) {
        this.loadSpinner = false;
        return;
      }
      const file = new Blob([data], { type: fileType })
      const fileURL = URL.createObjectURL(file);
      if (isViewDownload === 'view' || this.allEobDocuments?.length > 1) {
        setTimeout(() => {
          const iframeEle = document.getElementById(this.isClaimPage === 'yes' ? 'claims-acc-bal-eob-doc' : 'view-download-eob-doc-account-bal') as HTMLElement;
          iframeEle.setAttribute('src', fileURL);
        }, 500);
        patientMedicalRecordModal?.show();
      } else {
        let a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = eobDocument?.documentName;
        document.body.appendChild(a);
        a.click();
      }
      this.loadSpinner = false;
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  public downloadERAPDF(costofCareItem?: FormGroup, viewEobPostCharge?: UiModalComponent, viewDownloadEobEncounterDoc?: UiModalComponent): void {
    let action = '';
    const eobDocuments = this.checkEobDocAvailable(costofCareItem) || [];
    if(eobDocuments && eobDocuments?.length > 0) {
      this.filterProcessorEobDocs = eobDocuments || [];
      this.viewDownloadDocument(eobDocuments[0], 'view', viewDownloadEobEncounterDoc, 1);
      return;
    }
    if (!costofCareItem?.value.claimId) {
      this.notifyText = 'No Claim details are present.';
      this.exceptionalModal?.modalShow();
      this.isSuccessNotify = false;
      return;
    }
    if (costofCareItem?.value.claimId) {
      action = `claim/getEraPdf?appointmentId=${this.appointmentId}&claimId=${costofCareItem?.value.claimId}`;
    }
    this.loadSpinner = true;
    this.claimsService.downloadS3Image(action, '').subscribe((data: any) => {
      this.loadSpinner = false;
      var file = new Blob([data], { type: 'application/pdf' });
      if (file.size == 0) {
        this.notifyText = 'Explanation of Benefits not received';
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
      }
      else {
        const blobToFile = new File([file], `${costofCareItem?.value.claimId}_EOB.pdf`, {
          type: file?.type,
        });
        var fileURL = URL.createObjectURL(file);
        const iframeEle = document.getElementById('eob-pdf-postcharge') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
        if (viewEobPostCharge) {
          viewEobPostCharge?.show();
        }
      }
    }, error => {
      this.loadSpinner = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
    });
  }

  public showUploadEobCheckDetails(): void {
    this.openEobModal?.emit(true);
  }
  
  // Payor payments validation to check with Allowed amount, Payor payments should not be greater than allowed amount
  public payorPaymentValidation(formGroup: FormGroup, controlName?: string): void {
    this.errorMessageShown = null;
    if (parseFloat(formGroup?.get('allowedAmount')?.value) < parseFloat(formGroup?.get(controlName)?.value)) {
      // formGroup?.get(controlName)?.setValue(null);
      this.erroMsg('The payor payment should not exceed the allowed amount.');
    }
  }

  // Clear the Iframe src
  public clearIframeSrc(): void {
    const iframeEle = document?.getElementById(this.isClaimPage === 'yes' ? 'claims-acc-bal-eob-doc' : 'view-download-eob-doc-account-bal') as HTMLElement;
    if (iframeEle) {
      iframeEle?.removeAttribute('src');
    }
  }

  ngOnDestroy(): void {
    this.guardControl?.reset();
  }
}
 