import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { Router } from '@angular/router';
import { ClaimsService } from '../../services/claims.service';

@Component({
  selector: 'app-new-task',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.scss']
})
export class NewTaskComponent implements OnInit, OnChanges {

  public NewTaskForm: FormGroup;
  public lateNotifySubmitClick: boolean;
  public loadSpinner: boolean;
  public lateNotifyPatient: any;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild('createTaskModal') createTaskModal: UiModalComponent;

  @Input() noDelay: boolean;

  public loginDetails: any;
  public facilityId: any;
  public showComposedNotifyMessages: boolean;
  selectedIndex: number;
  public isUserLevelNotification: string;
  public categoriesList = [
    { value: 'A1C', desc: 'A1C' },
    { value: 'Blood Work', desc: 'Blood Work' },
    { value: 'ECG', desc: 'ECG' },
    { value: 'Notify Patient On Colon Exam Results', desc: 'Notify Patient On  Colon Exam Results' },
    { value: 'Notify Patient On LAB Results', desc: 'Notify Patient  On LAB Results' },
    { value: 'Notify Patient On Mammogram Results', desc: 'Notify Patient On  Mammogram Results' },
    { value: 'Notify Patient On RAD Results', desc: 'Notify Patient On  RAD Results' },
    { value: 'Notify Patient On Rx Results', desc: 'Notify Patient On  Rx Results' },
    { value: 'Patient Query', desc: 'Patient Query ' },
    { value: 'Referral', desc: 'Referral' },
    { value: 'Urine Analysis', desc: 'Urine Analysis' },
    { value: 'Claim Action', desc: 'Claim Action' },
    { value: 'Other', desc: 'Other' }
  ];

  public statusList = [
    { value: 'Open', desc: 'Open' },
    { value: 'In-Progress', desc: 'In-Progress' },
    { value: 'Hold', desc: 'Hold' },
    { value: 'Completed', desc: 'Completed' }
  ];

  public priorityList = [
    { value: 'Low', desc: 'Low' },
    { value: 'Medium', desc: 'Medium' },
    { value: 'High', desc: 'High' }
  ];
  public asigneeList = [];
  public allTasks = [];
  public allExistingUsers = [];
  public taskTypeList = [];
  public loginUser: any;
  public isUpdate: boolean;
  public isUpdateResolution: boolean;
  @Input() idType: any;
  @Input() pageId: any;
  public selectedId: any;
  public displayTable: boolean = false;
  public disableField: boolean;
  public disableDescField: boolean;
  @ViewChild('claimsStatusGrid') taskCard: CardComponent;
  @ViewChild(DataTableDirective, { static: false })
  dtElementTasksStatus: DataTableDirective;
  public dtOptionsTasksStatus: DataTables.Settings = {};
  public dtTriggerTasksStatus: Subject<any> = new Subject<any>();
  public selectedTaskId: any;
  public patientJournalUserId: any;
  public roleType: any;
  public userInformation: any;
  public ownerName: any;
  public taskInfo: any;
  @Output() onTaskUpdate = new EventEmitter();
  @Output() onCloseNewTask = new EventEmitter();
  public disableCompleteField: boolean
  public selectedTask: string;

  public selectedUserInfoId: null;
  public selectedAppointmentId: null;
  @ViewChild('newtaskSection') newtaskSection: ElementRef
  @Input() isClaim: boolean;
  createdTaskId: any;
  @Output() onClaimJournalCreated = new EventEmitter();

  public pageSize: number;
  public pageSizeDt = [
    { desc: 10, value: 10 },
    { desc: 25, value: 25 },
    { desc: 50, value: 50 },
    { desc: 100, value: 100 }
  ]
  public pageNumber: number;
  public sortDirection: string;
  public sortBy: string;
  public sortId: any;
  public totalRecords: number;
  public stNumber: number;
  public endNumber: number;
  public totalPages: number;
  public pagesArr = [];
  public visiblePages: number;
  public selectedPage: number;
  public paginationForm: FormGroup;
  @Input() isfromClaim: boolean = false;
  


  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router, private claimsService: ClaimsService) {
    this.loginUser = JSON.parse(sessionStorage?.getItem('userDetails'));
    this.ownerName = this.loginUser.lastName + ", " + this.loginUser.firstName;

    this.paginationForm = this.formBuilder?.group({
      "pageLength": [null]
    });

    this.selectedPage = 1;
    this.visiblePages = 7;
    this.totalPages = 0;
    this.pageSize = 5;
    this.pageNumber = 0;
    this.sortDirection = 'desc';
    this.sortBy = "category";
    this.stNumber = 0;
    this.endNumber = 0;
    this.totalRecords = 0;
    this.sortId = 0;


    this.NewTaskForm = this.formBuilder.group({
      'category': [null, Validators.required],
      'assignee': [null, Validators.required],
      'priority': [null, Validators.required],
      'taskType': [null, Validators.required],
      'status': [null, Validators.required],
      'description': [null, Validators.required],
      //'resolution': [null],
      'role': [null],
      'owner': [null],
    })
  }

  ngOnInit(): void {
    this.displayTable = true;

    this.dtOptionsTasksStatus = {
      pagingType: 'full_numbers',
      lengthChange: false,
      autoWidth: true,
      paging: false,
      searching: false,
      order: [[this.sortId || 3, this.sortDirection]],
      columnDefs: [{ targets: [this.sortId || 3], type: 'date', orderable: true }],
      "language": {
        /*"info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",*/
        "info": '',
        "infoEmpty": '',
        "emptyTable": "Data is unavailable",
        "lengthMenu": "Showing _MENU_ Entries",
      },
    };


    this.loadSpinner = false;
    this.getExistingProvidersByFacility();
    if (!this.isUpdate) {
      this.NewTaskForm?.get('status')?.setValue('Open');
      this.NewTaskForm?.get('status')?.disable();
    }

  }

  ngAfterViewInit(): void {
    this.paginationForm?.get('pageLength')?.setValue(this.pageSize);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isClaim) {
      // const claimJournalCategories = [
      //   { value: 'Analysis', desc: 'Analysis' },
      //   { value: 'Call', desc: 'Call' },
      //   { value: 'Voice Mail', desc: 'Voice Mail' }
      // ]
      // this.categoriesList = [...claimJournalCategories, ...this.categoriesList];
      this.categoriesList = this.categoriesList?.filter(item => item?.value === 'Claim Action') || [];
    }
  }

  public rerender(): void {
    this.dtElementTasksStatus?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }

  public resetForm(): void {
    this.NewTaskForm?.reset();
  
  }

  public openTaskReferralFormCareFlow(appointment?: any, isReferral?: string): void {
    // console.log('task appointment',appointment);
    if (isReferral) {
      this.showTaskModal(appointment);
      this.NewTaskForm?.get('category')?.setValue('Referral');
      this.getTaskTypeList('Referral');
    } else {
      this.showTaskModal(appointment);
    }
  };

  public showTaskModal(details, taskIdFormClaims?: string): void {
   // console.log('app-details',details);
    /* console.log('app-details',details);
     console.log('pageId',this.pageId);
     console.log("idType",this.idType);
     console.log('isClaim',this.isClaim);
     */
    this.userInformation = details;
    if(!this.isClaim){
      this.createTaskModal?.show();
    }
    

    //this.selectedId = details?.id || details?.userInfoId;

    if (this.pageId === 'tasks') {
      this.selectedId = details?.id || details?.userInfoId || details?.appointmentId;
      if (details?.userInfoId !== null) {
        this.idType = "userId";
        this.selectedUserInfoId = details?.userInfoId || null;
        this.selectedAppointmentId = details?.appointmentId || null;
      }
      else {
        this.idType = "appointmentId";
        this.selectedAppointmentId = details?.appointmentId || null;
        this.selectedUserInfoId = details?.userInfoId || null;
      }
      this.selectedTask = details.taskId;
      //console.log('details',details);
      this.openUpdatetask(details);


      setTimeout(() => {

        let buttonElement = document.getElementById('update_' + details?.taskId) as HTMLElement;
        buttonElement.click();
        /* let element = document.getElementById('newtaskSection');
        // this.newtaskSection.nativeElement.scrollTo({behavior: 'smooth'});
         element?.scrollIntoView();*/
      }, 1000)
    }
    else {

      if (this.idType === 'userId') {
        this.patientJournalUserId = details?.id || details?.userInfoId;
        this.selectedId = details?.id || details?.userInfoId;
        this.selectedUserInfoId = details?.userInfoId || null;
        this.selectedAppointmentId = details?.appointmentId || null;

      }
      else if (this.idType === 'appointmentId') {
        this.patientJournalUserId = details?.id || details?.appointmentId;
        this.selectedId = details?.id || details?.appointmentId;
        this.selectedAppointmentId = details?.id || details?.appointmentId || null;
        if (this.isClaim) {
          this.selectedAppointmentId = details?.appointmentId || null;
        }
        this.selectedUserInfoId = details?.userInfoId || null;

      }
    }

    if (this.isClaim) {
      this.selectedAppointmentId = details?.appointmentId || null;
      this.selectedUserInfoId = details?.userInfoId || null;
    }

    if (this.isClaim && taskIdFormClaims) {

      this.selectedTask = taskIdFormClaims || details?.taskId;

      details.taskId = this.selectedTask;
      setTimeout(() => {
        this.openUpdatetask(details);
      }, 1000)


    }

    this.getTaskList();
    /* this.selectedId = details?.id || details?.userInfoId;
     this.createTaskModal?.show();
     this.getTaskList();*/
  }




  public showTaskDetails(details, taskIdFormClaims?: string): void {
    this.userInformation = details;


    //this.selectedId = details?.id || details?.userInfoId;

    if (this.pageId === 'tasks') {
      this.selectedId = details?.id || details?.userInfoId || details?.appointmentId;
      if (details?.userInfoId !== null) {
        this.idType = "userId";
        this.selectedUserInfoId = details?.userInfoId || null;
        this.selectedAppointmentId = details?.appointmentId || null;
      }
      else {
        this.idType = "appointmentId";
        this.selectedAppointmentId = details?.appointmentId || null;
        this.selectedUserInfoId = details?.userInfoId || null;
      }
      this.selectedTask = details.taskId;
      this.openUpdatetask(details);


      setTimeout(() => {

        let buttonElement = document.getElementById('update_' + details?.taskId) as HTMLElement;
        buttonElement.click();
        /* let element = document.getElementById('newtaskSection');
        // this.newtaskSection.nativeElement.scrollTo({behavior: 'smooth'});
         element?.scrollIntoView();*/
      }, 1000)
    }
    else {

      if (this.idType === 'userId') {
        this.patientJournalUserId = details?.id || details?.userInfoId;
        this.selectedId = details?.id || details?.userInfoId;
        this.selectedUserInfoId = details?.userInfoId || null;
        this.selectedAppointmentId = details?.appointmentId || null;

      }
      else if (this.idType === 'appointmentId') {
        this.patientJournalUserId = details?.id || details?.appointmentId;
        this.selectedId = details?.id || details?.appointmentId;
        this.selectedAppointmentId = details?.id || details?.appointmentId || null;
        if (this.isClaim) {
          this.selectedAppointmentId = details?.appointmentId || null;
        }
        this.selectedUserInfoId = details?.userInfoId || null;

      }
    }

    if (this.isClaim) {
      this.selectedAppointmentId = details?.appointmentId || null;
      this.selectedUserInfoId = details?.userInfoId || null;
    }

    if (this.isClaim && taskIdFormClaims) {
      
      this.selectedTask = taskIdFormClaims || details?.taskId;
      details.taskId = this.selectedTask;
      setTimeout(() => {
        this.openUpdatetask(details);
      }, 1000)


    }

    this.getTaskList();
    /* this.selectedId = details?.id || details?.userInfoId;
     this.createTaskModal?.show();
     this.getTaskList();*/
  }



  public getExistingProvidersByFacility(): void {

    // const action = `provider/getAllProviders/${this.loginUser?.facilityId}`;
    const action = `provider/getProvidersListforfacility/${this.loginUser?.facilityId}`;
    this.asigneeList = [];
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      
      if (data?.status === 'SUCCESS') {
        //   const allUsers = data?.responseObject?.filter(item => item?.active) || [];

        const allUsers = data?.responseObject || [];
        const allDoctors = allUsers?.map((doctorItem: any) => {
          const firstName = doctorItem?.firstName || '';
          const lastName = doctorItem?.lastName || '';
          const asssigneeUserId = doctorItem?.userId;
          //const asssigneeUserId = firstName+" "+lastName+"-"+doctorItem?.userId;
          return {
            name: `${lastName}, ${firstName}`,
            npi: doctorItem?.providerNPI,
            asssigneeUserId: asssigneeUserId,
            userId: doctorItem?.userId,
            roleType: doctorItem?.roleType
          }
        }) || [];
        this.asigneeList = allDoctors || [];
      }
      this.loadSpinner = false;
    },
      (error) => {

        this.loadSpinner = false;
      });
  }

  public selectRoleType(event: any): any {
    this.NewTaskForm?.get('role')?.setValue(event?.roleType);
    this.roleType = event?.roleType;
  }

  public getTaskTypeList(event) {
    if (event.value === 'A1C' || event.value === 'Urine Analysis' || event.value === 'Blood Work' || event.value === 'ECG' || event.value === 'Clinical') {
      this.NewTaskForm?.get('taskType')?.setValue('Clinical');
    }

    else if (event.value === 'Referral' || event === 'Referral') {
      this.NewTaskForm?.get('taskType')?.setValue('Referral');
    }

    else if (event.value === 'Patient Query' || event.value === 'Notify Patient On LAB Results' || event.value === 'Notify Patient On RAD Results' || event.value === 'Notify Patient On Rx Results' || event.value === 'Notify Patient On Colon Exam Results' || event.value === 'Notify Patient On Mammogram Results') {
      this.NewTaskForm?.get('taskType')?.setValue('Patient Communication');
    }

    else if (event.value === 'Other') {
      this.NewTaskForm?.get('taskType')?.setValue('Operational');
    }
    else if (event?.value === 'Claim Action') {
      this.NewTaskForm?.get('taskType')?.setValue('Request');
    }
  }


  public addTask(): void {
    if (this.NewTaskForm?.invalid) {
      this.NewTaskForm?.markAllAsTouched();
      return;
    }

    const category = this.NewTaskForm?.get('category')?.value;
    const taskType = this.NewTaskForm?.get('taskType')?.value;
    const assigneeValue = this.NewTaskForm?.get('assignee')?.value;
    

    let assignee = null;
    let roleType = null;
   
    const assigneeObj = this.asigneeList?.filter(item => item?.userId === assigneeValue) || [];
    
    if (assigneeObj.length > 0) {


      assignee = assigneeObj[0].name || null;
      roleType = assigneeObj[0].roleType;

    }
    else {
      assignee = null;
      roleType = null;
    }


    const description = this.NewTaskForm?.get('description')?.value;
    const id = this.selectedId;
    const priority = this.NewTaskForm?.get('priority')?.value;
    /* const userObj = this.asigneeList?.filter(item => item?.userId === assigneeValue) || [];
 
     let roleType = null;
     if (userObj.length > 0) {
       roleType = userObj[0].roleType;
     }
     else {
       roleType = null;
     }*/

    let taskDto = null;

    if (this.idType === 'userId') {
      taskDto =
      {
        "role": roleType,
        "taskDetails": {
          "assignee": assignee,
          "asssigneeUserId": assigneeValue,
          "category": category,
          "createdBy": this.ownerName,
          "description": description,
          "priority": priority,
          "status": "Open"
        },
        "taskType": taskType,
        "appointmentId": this.selectedAppointmentId,
        "userInfoId": this.selectedUserInfoId || this.selectedId
      };
      //action = `tasks/createTasks?category=${category}&taskType=${taskType}&assignee=${assignee}&description=${description}&userInfoId=${id}&priority=${priority}&role=${this.NewTaskForm?.get('role')?.value}`;
    }
    else if (this.idType === 'appointmentId') {
      taskDto =
      {
        "appointmentId": this.selectedAppointmentId,
        "userInfoId": this.selectedUserInfoId,
        "role": roleType,
        "taskDetails": {
          "assignee": assignee,
          "asssigneeUserId": assigneeValue,
          "category": category,
          "createdBy": this.ownerName,
          "description": description,
          "priority": priority,
          "status": "Open"
        },
        "taskType": taskType
      };
      //action = `tasks/createTasks?category=${category}&taskType=${taskType}&assignee=${assignee}&description=${description}&appointmentId=${id}&priority=${priority}&role=${this.NewTaskForm?.get('role')?.value}`;
    }
    // this.taskCard?.cardRefreshShow();
    this.lateNotifySubmitClick = true;
    const action = `tasks/createTasks`;
    this.httpService?.makeRequestWithAction(action, taskDto)?.subscribe((data: any) => {
      this.lateNotifySubmitClick = false;
      /* if(taskType === 'Patient Communication'){
     this.updateJournalNotes(this.userInformation,description);
     }*/
      this.createdTaskId = data?.body?.uniqueIdCreatedOnPost || data?.uniqueIdCreatedOnPost;
      this.saveClaimJournalNotes(this.NewTaskForm);
      this.resetTask();
      this.displayTable = true;
      // this.taskCard?.cardRefreshHide();
      this.getTaskList();

    }, error => {
      // this.taskCard?.cardRefreshHide();
      this.lateNotifySubmitClick = false;
      this.isSuccessNotify = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.exceptionModal?.modalShow();
    });
  }

  /*public getTaskList(): void {
    this.rerender();
    // const action = `tasks/listTasks`;
    let id = this.selectedId;
    let action = '';
    if (this.idType === 'userId') {
      action = `tasks/listTasks?userInfoId=${id}`;
    }
    else if (this.idType === 'appointmentId') {
      if (this.isClaim) {
        id = this.selectedAppointmentId
      }
      action = `tasks/listTasks?appointmentId=${id}`;
    }
    this.taskCard?.cardRefreshShow();
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.allTasks = data?.responseObject || [];
        const tasksList = data?.responseObject || [];
        if (this.allTasks.length !== 0) {
          this.displayTable = true;
        }
        else {
          this.displayTable = false;
        }
      } else {
        this.displayTable = false;
      }

      //this.dataTableFilter();
      this.taskCard?.cardRefreshHide();
      this.dtTriggerTasksStatus?.next();

    },
      (error) => {
        this.loadSpinner = false;

        this.taskCard?.cardRefreshHide();
        this.dtTriggerTasksStatus?.next();
      });
  }*/


  public getTaskList(): void {
    this.rerender();
    // const action = `tasks/listTasks`;
    let id = this.selectedId;
    let requestBody = null;
    const action = `tasks/listTasks`;

    if (this.idType === 'userId') {
      //action = `tasks/listTasks?userInfoId=${id}`;
      requestBody = {
        pageRequest: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          sortBy: this.sortBy,
          sortDirection: this.sortDirection
        },
        userInfoId: id
      };

    }
    else if (this.idType === 'appointmentId') {
      if (this.isClaim) {
        id = this.selectedAppointmentId
      }
      //action = `tasks/listTasks?appointmentId=${id}`;
      requestBody = {
        pageRequest: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          sortBy: this.sortBy,
          sortDirection: this.sortDirection
        },
        appointmentId: id
      };
    }
    this.taskCard?.cardRefreshShow();
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, requestBody)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.body?.status === "SUCCESS") {
        this.allTasks = data?.body?.responseObject?.response || [];
        this.totalRecords = data?.body?.responseObject?.totalRecords || 0;
        if (this.totalRecords > 0) {
          this.createPagination();
        }
        else {
          this.resetPagination();

        }


        if (this.allTasks.length !== 0) {
          this.displayTable = true;
        }
        else {
          this.displayTable = false;
        }
      } else {
        this.resetPagination();
        this.displayTable = false;
      }

      //this.dataTableFilter();
      this.taskCard?.cardRefreshHide();
      this.dtTriggerTasksStatus?.next();

    },
      (error) => {
        this.loadSpinner = false;

        this.taskCard?.cardRefreshHide();
        this.dtTriggerTasksStatus?.next();
      });
  }

  public openUpdatetask(task: any): void {
   // console.log(task);

    let assignee = null;
    let asssigneeUserId = null;

    if (task.asssigneeUserId && task.asssigneeUserId !== null) {

      this.NewTaskForm?.get('assignee')?.setValue(task.asssigneeUserId);
      const assigneeObj = this.asigneeList?.filter(item => item?.userId === task.asssigneeUserId) || [];
      if (assigneeObj.length > 0) {
        this.roleType = assigneeObj[0].roleType;
      }
      else {
        this.roleType = null;
      }
      /*assignee =  task.assignee;
      asssigneeUserId = task.asssigneeUserId;*/


    }
    else {
      let asssigneeUserIdVal = null;

      const assigneeObj = this.asigneeList?.filter(item => item?.name === task.assignee) || [];

      if (assigneeObj.length > 0) {
        this.roleType = assigneeObj[0].roleType;

        asssigneeUserIdVal = assigneeObj[0].userId;
        this.NewTaskForm?.get('assignee')?.setValue(asssigneeUserIdVal);
        /* assignee =  task.assignee;
         asssigneeUserId = asssigneeUserIdVal;*/
      }
      else {
        this.roleType = null;
        /* assignee =  task.assignee;
         asssigneeUserId = task.asssigneeUserIdVal;*/
      }

    }

    /*const userObj = this.asigneeList?.filter(item => item?.firstName === task.assignee) || [];
  	
      if (userObj.length > 0) {
        this.roleType = userObj[0].roleType;
      
      }
      else {
        this.roleType = null;
     
      }*/

    this.isUpdate = true;
    this.selectedTask = task.taskId;
    this.selectedAppointmentId = task.appointmentId;
    this.selectedUserInfoId = task.userInfoId;

    this.NewTaskForm?.get('category')?.setValue(task.category);
    this.NewTaskForm?.get('taskType')?.setValue(task.taskType);

    //this.NewTaskForm?.get('description')?.setValue(task.description);
    this.NewTaskForm?.get('priority')?.setValue(task.priority);
    this.NewTaskForm?.get('status')?.setValue(task.status);
    this.NewTaskForm?.get('owner')?.setValue(task.createdBy);

    this.selectedTaskId = task.taskId;

    //this.NewTaskForm = task as FormGroup;
    this.disableField = true;
    this.NewTaskForm?.get('status')?.enable();
    if (this.isUpdate) {
      this.NewTaskForm?.get('category').disable();
    }
    else {
      this.NewTaskForm?.get('category').enable();
    }

    if (task.status === "Completed") {
      this.disableCompleteField = true;
    }
    else {
      this.disableCompleteField = false;

    }
    if (task?.createdBy === task?.assignee) {
      this.disableDescField = false;
    }
    else {
      this.disableDescField = true;
    }

    //this.NewTaskForm?.get('category')?.setAttribute('readonly', true);

    this.NewTaskForm.get('status').setValidators([Validators.required]);
    //this.NewTaskForm.get('resolution').setValidators([Validators.required]);
    this.getTaskDetailedInfo(this.selectedTaskId);

  }

  public getTaskDetailedInfo(taskId) {
    const action = `tasks/getTaskDetails?taskId=${taskId}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.taskInfo = data?.responseObject || {};
        if (this.isClaim && !this.NewTaskForm?.get('category')?.value) {
          let task: any;
          if (this.taskInfo && this.taskInfo?.taskDetails && this.taskInfo?.taskDetails?.length > 0) {
            task = this.taskInfo?.taskDetails[0];
          };
          this.NewTaskForm?.get('category')?.setValue(task?.category);
          this.NewTaskForm?.get('taskType')?.setValue(task?.taskType || this.taskInfo?.taskType);
          this.NewTaskForm?.get('assignee')?.setValue(task?.assignee);
          //this.NewTaskForm?.get('description')?.setValue(task.description);
          this.NewTaskForm?.get('priority')?.setValue(task?.priority);
          this.NewTaskForm?.get('status')?.setValue(task?.status);
          this.NewTaskForm?.get('owner')?.setValue(task?.createdBy);
        }
      }
    },
      (error) => {

      });
  }

  public getAppointmentTime(date: any): string {
    return this.httpService?.getAppointmentTime(date);
  }

  public displayResolution(event) {
    const status = this.NewTaskForm?.get('status')?.value;
    if (status === 'Completed') {
      this.isUpdateResolution = true;
      this.NewTaskForm.get('resolution').setValidators([Validators.required]);
    }
    else {
      this.isUpdateResolution = false;
      this.NewTaskForm.get('resolution').setValidators([null]);
    }
  }

  public updateTask(): void {
    if (this.NewTaskForm?.invalid) {
      this.NewTaskForm?.markAllAsTouched();
      return;
    }

    const description = this.NewTaskForm?.get('description')?.value;
    const taskId = this.selectedTaskId;
    const status = this.NewTaskForm?.get('status')?.value;
    //const resolution = this.NewTaskForm?.get('resolution')?.value;
    const category = this.NewTaskForm?.get('category')?.value;
    const taskType = this.NewTaskForm?.get('taskType')?.value;
    const owner = this.NewTaskForm?.get('owner')?.value;
    //const assignee = this.NewTaskForm?.get('assignee')?.value;
    const assigneeValue = this.NewTaskForm?.get('assignee')?.value;
    const priority = this.NewTaskForm?.get('priority')?.value;
    let assignee = null;
    let roleType = null;
    const assigneeObj = this.asigneeList?.filter(item => item?.userId === assigneeValue) || [];
    if (assigneeObj.length > 0) {
      roleType = assigneeObj[0].roleType;

      assignee = assigneeObj[0].name || null;

    }
    else {
      assignee = null;
      roleType = null;

    }


    /*const userObj = this.asigneeList?.filter(item => item?.firstName === assignee) || [];
    let roleType = null;
    if (userObj.length > 0) {
      roleType = userObj[0].roleType;
    }
    else {
      roleType = null;
    }*/
    const taskDto =
    {
      "role": roleType,
      "taskDetails": {
        "assignee": assignee,
        "asssigneeUserId": assigneeValue,
        "category": category,
        "createdBy": owner,
        "description": description,
        "priority": priority,
        "status": status
      },
      "taskId": taskId,
      "taskType": taskType,
      "appointmentId": this.selectedAppointmentId,
      "userInfoId": this.selectedUserInfoId,
    };
    const action = `tasks/updateTask`;
    this.lateNotifySubmitClick = true;
    //const action = `tasks/updateTask?resolution=${resolution}&status=${status}&taskId=${taskId}&description=${description}`;
    //this.taskCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, taskDto)?.subscribe((data: any) => {
      /*if(taskType === 'Patient Communication' && status === 'Completed'){
      this.updateJournalNotes(this.userInformation,resolution);
      }*/
      this.displayTable = true;
      this.resetTask();
      this.getTaskList();
      this.lateNotifySubmitClick = false;
      this.onClaimJournalCreated?.emit(true);
    }, error => {
      this.lateNotifySubmitClick = false;
      // this.taskCard?.cardRefreshHide();  
    });

  }

  public resetTask() {
    this.onCloseNewTask?.emit(true);
    if (this.pageId === 'tasks' || this.isClaim) {
      //location.reload();
      this.onTaskUpdate?.emit(true);
    }
    this.NewTaskForm.reset();
    this.selectedTask = null;
    this.NewTaskForm.get('status').setValidators([]);
    //this.NewTaskForm.get('resolution').setValidators([]);

    this.NewTaskForm?.get('category')?.setValue(null);
    this.NewTaskForm?.get('taskType')?.setValue(null);
    this.NewTaskForm?.get('assignee')?.setValue(null);
    this.NewTaskForm?.get('description')?.setValue(null);
    this.NewTaskForm?.get('priority')?.setValue(null);
    this.NewTaskForm?.get('status')?.setValue("Open");
    //this.NewTaskForm?.get('resolution')?.setValue(null);
    this.isUpdate = false;
    this.isUpdateResolution = false;
    this.disableDescField = false;
    this.disableField = false;
    this.disableCompleteField = false;
    this.NewTaskForm?.get('category').enable();
  }

  public closeTaskModal(createTaskModal: UiModalComponent) {
    if (this.pageId === 'tasks') {
      createTaskModal.hide();
    }

  }

  public updateJournalNotes(userDetails: any, notes: any): void {
    const action = `userInfo/savePatientJournalNotesRequest`;
    const notesRequest = {
      "channel": "SMS",
      "node": null,
      "notes": notes,
      "notifyResponder": "no",
      "responderNPI": null,
      "responderName": null,
      "responderRole": this.roleType,
    }
    const params = {
      "appointmentId": null,
      "facilityId": this.loginUser?.facilityId,
      "notesRequest": notesRequest,
      "notesResponse": null,
      "patientFirstName": userDetails?.firstName,
      "patientLastName": userDetails?.lastName,
      "type": "Patient Journal Notes",
      "uniqueId": null,
      "userInfoId": this.patientJournalUserId
    }
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {

      } else {

      }
    }, (error) => {

    });
  }

  public navigateToTop(el: HTMLElement): void {
    // this.scroller.scrollToAnchor("mainDetails");
    el.scrollIntoView();
  }

  ngOnDestroy(): void {
    if (this.dtTriggerTasksStatus) {
      this.dtTriggerTasksStatus?.unsubscribe();
    }
  }

  // create claim jorunal if its Facility Action
  public saveClaimJournalNotes(taskForm?: any): void {
    const action = `claim/saveClaimJournalNotes`;
    const params = {
      "actionType": 'Facility Action',
      "appointmentId": this.userInformation?.appointmentId || this.userInformation?.id,
      "claimId": this.userInformation?.id || null,
      "documentName": null,
      "facilityId": this.loginUser?.facilityId,
      "journalCategory": this.NewTaskForm?.get('category')?.value || "Claim Journal",
      "notes": this.NewTaskForm?.get('description')?.value,
      "s3Key": null,
      "taskId": this.createdTaskId || null,
      // "uniqueId": null,
      // "userInfoId": this.userInformation?.userInfoId
    };

    this.loadSpinner = true;
    this.claimsService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        // createClaimJournalModal?.hide();
        // this.notifyText = data?.body?.message || data?.body?.msg;
        // this.exceptionModal?.modalShow();
        this.onClaimJournalCreated?.emit(true);
      } else {
        // this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    }, (error) => {
      // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      // this.exceptionModal?.modalShow();
      this.loadSpinner = false;
    });
  }

  // Add additonal items
  public addTag(tag: string) {
    return {
      desc: tag,
      value: tag
    }
  }


  /* pagination functions */

  public createPagination() {
    this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
    const pagesDt = [];
    for (var i = 1; i <= this.totalPages; i++) {
      pagesDt.push(i);

    }
    this.pagesArr = this.getPageList(this.totalPages, this.selectedPage, this.visiblePages);
    if (this.pageNumber === 0) { //|| this.pageNumber === 1
      this.stNumber = 1;
      if (this.totalRecords < this.pageSize) {
        this.endNumber = this.totalRecords;
      }
      else { this.endNumber = this.pageSize; }
    }
    else {
      this.stNumber = (((this.pageNumber + 1) * this.pageSize) + 1) - this.pageSize;
      this.endNumber = (this.pageNumber + 1) * this.pageSize;
    }
  }

  public pageSizeChange(event: any) {
    this.pageSize = event.value;
    this.pageNumber = 0;
    this.sortDirection = 'desc';
    this.sortBy = "createdDate";

    this.getTaskList();

  }

  public pageNumberChange(event: any, changeType: any) {
    if (changeType === 'prev') {
      this.pageNumber = event - 1;
    }
    else if (changeType === 'next') {
      this.pageNumber = event + 1;
    }
    else {
      this.pageNumber = event - 1;
    }

    //this.selectedPage = this.pageNumber; // index starts from 0 hence adding 1 for selected page display
    this.selectedPage = this.pageNumber + 1;
    this.getTaskList();

  }

  public range(start, end) {
    return Array.from(Array(end - start + 1), (_, i) => i + start);
  }

  public getPageList(totalPages, page, maxLength) {

    var sideWidth = maxLength < 9 ? 1 : 2;
    var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
    if (totalPages <= maxLength) {
      
      // no breaks in list
      return this.range(1, totalPages);
    }
    if (page <= maxLength - sideWidth - 1 - rightWidth) {
      
      // no break on left of page
      // return this.range(1, maxLength - sideWidth - 1);
      return this.range(1, maxLength - sideWidth - 1)
        .concat(['...'])
        .concat(this.range(totalPages - sideWidth + 1, totalPages));
    }
    if (page >= totalPages - sideWidth - 1 - rightWidth) {
     
      // no break on right of page
      return this.range(1, sideWidth)
        .concat(['...'])
        .concat(this.range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
    }
    // Breaks on both sides
    return this.range(1, sideWidth)
      .concat(['...'])
      .concat(this.range(page - leftWidth, page + rightWidth),

        this.range(totalPages - sideWidth + 1, totalPages));
  }

  public sortClaimData(event: any) {
    // const itemData = event.target.id;
    const itemString = event.target.id?.split('_');
    const itemData = itemString[0];
    this.sortId = itemString[1];

    this.sortBy = itemData;


    this.pageNumber = 0;
    this.selectedPage = 1;
    const sortDirection = this.toggleSort(this.sortDirection, 'asc', 'desc');
    this.sortDirection = sortDirection;
    this.getTaskList();

  }

  public filterData() {
    this.pageNumber = 0;
    this.selectedPage = 1;
    this.sortDirection = 'desc';
    this.getTaskList();

  }

  public resetPagination() {

    this.stNumber = 0;
    this.endNumber = 0;
    this.totalRecords = 0;
    this.pagesArr = [];

  }

  public toggleSort(sortDirection: any, direction1: any, direction2: any) {
    return sortDirection == direction1 && direction2 || direction1;
  }

  /* pagination scrips ends here */


}
