import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UiModalComponent } from '../modal/ui-modal/ui-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exception-modal',
  templateUrl: './exception-modal.component.html',
  styleUrls: ['./exception-modal.component.scss']
})
export class ExceptionModalComponent implements OnInit, OnChanges {

  @ViewChild('notifyTextModal') private notifyTextModal: UiModalComponent
  @Input() notifyText: string;
  @Input() redirectFlag: boolean;
  @Input() isSuccessNotify: any;
  @Input() requiredFields: any[];
  @Input() claimSubmitNotifyText: any;
  @Output() updateOk = new EventEmitter<any>();

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.notifyText) {
        if (this.notifyText?.includes('||')) {
          let replaceString: any = this.notifyText;
          this.notifyText = replaceString?.replaceAll('||', '<br/>')
        }
      }
    }
  }

  public modalShow(notifyText?: string, isSuccess?: any): void {
    this.notifyText = notifyText || this.notifyText;
    this.isSuccessNotify = isSuccess !== null && isSuccess !== undefined ? isSuccess : this.isSuccessNotify;
    this.notifyTextModal.show();
  }
  public modalHide(): void {
    this.notifyText = '';
    this.notifyTextModal.hide();
  }

  public redirecttoFlowboard() {
    if (window.location.href.includes('follow-up-review')) {
      if (this.redirectFlag && this.redirectFlag == true) {
        this.router.navigate(['/hospital/hosp-dashboard']);
      }
    }
  }

  // Navigate back while click on OK
  public navigateBack(): void {
    this.updateOk?.emit(true);
  }
}
