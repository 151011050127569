<div class="summary-cc-data">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <ng-container *ngFor="let category of ccData; let cati = index">
                <ng-contaier *ngFor="let section of category?.sections">
                    <ng-contaier *ngFor="let answers of section?.answers">
                        <div class="chief_list">
                            <ng-container *ngFor="let value of answers?.actualValue; let li = index">
                                <span style="margin-right:10px;">{{value?.answer}} <span
                                        *ngIf="(li+1) < answers?.actualValue.length">,</span> <span
                                        *ngIf="value?.answer === 'Other' && value?.notes">&nbsp;&nbsp;-&nbsp;&nbsp;<b>{{value?.notes}}</b></span></span>
                            </ng-container>
                        </div>
                    </ng-contaier>
                    <ng-container *ngIf="!section?.answers || section?.answers?.length === 0">
                        <label>No option has been selected under Chief Complaints.</label>
                    </ng-container>
                </ng-contaier>
                <ng-contaier *ngIf="category?.otherNotes">
                    <div class="form-group row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{category?.otherNotes || '--'}}</label>
                        </div>
                    </div>
                </ng-contaier>
            </ng-container>
            <ng-container *ngIf="!ccData || ccData?.length === 0">
                <span>No option has been selected under Chief Complaints.</span>
            </ng-container>
        </div>
    </div>
</div>