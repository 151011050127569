<app-ui-modal #paymentsRefNewModal [modalCentered]="true" [loadSpinner]="loadSpinner" id="paymentsRefNewModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Payments</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="paymentsRefNewModal?.hide();closeModal()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <aw-wizard #paymentWizard class="arc-wizard" navBarLocation="top" navBarLayout="large-filled-symbols">
            <!-- Billing Provider -->
            <aw-wizard-step stepTitle="Payment Services">
                <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                    <span *ngIf="!wizardStep?.completed && !wizardStep?.selected">1</span>
                    <i *ngIf="!wizardStep?.completed && wizardStep?.selected"
                        style="color:#fff;font-size: 20px;line-height: 42px;" class="las la-pen"></i>
                    <i *ngIf="wizardStep?.completed" style="color: #fff;font-size: 20px;line-height: 42px;"
                        class="las la-check"></i>
                </ng-template>
                <form class="paymentform mt-4">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12" [formGroup]="switchArPatientInsurancePayForm">
                                    <div class="switch-sec" *ngIf="!accountLevelPay && pageName !== 'Healthpass'">
                                        AR Patient
                                        <label class="switch">
                                            <input type="checkbox" formControlName="switchCheck" id="paymentsCommonNew_checkbox"
                                                (change)="checkIsARInsurancePatient($event)">
                                            <span class="slider round"></span>
                                        </label>
                                        AR Insurance
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <!-- ADD AR Patient Action -->
                                    <ng-container
                                        *ngIf="pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                        <button type="button" class="btn btn-secondary add-service float-right"
                                            id="payment_addServive" (click)="addPaymentServices()">Add Service</button>
                                    </ng-container>
                                    <!-- ADD AR Insurance action -->
                                    <ng-container
                                        *ngIf="pageName !== 'Healthpass' && switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                        <button type="button" class="btn btn-secondary add-service float-right"
                                            id="payment_addservice" (click)="addArInsurance()">Add Service</button>
                                    </ng-container>

                                    <!-- Chart Account Balance -->
                                    <ng-container
                                        *ngIf="selectedUserInfoId || accoutLevelUserInfoId || selectedPayAppointment?.userInfoId">
                                        <button class="icon-queue-events float-right"
                                            style="background-color: #5ca6db;color: #fff;" placement="bottom"
                                            ngbTooltip="Chart - Account Balance" id="payment_chartAccBal"
                                            (click)="paymentsRefNewModal?.hide();closeModal();navigateUserAccountBalance()"><i
                                                class="las la-user-circle"></i></button>
                                    </ng-container>
                                </div>
                            </div>

                            <!-- AR PAtient Related Rows -->
                            <ng-container *ngIf="!switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                <div class="ar-patient" [formGroup]="newPaymentServicesForm">
                                    <div class="form-array-sec mt-2" formArrayName="paymentServicesForm">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <h6 class="mt-2"
                                                    *ngIf="newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 0">
                                                    <b>Services</b>
                                                </h6>
                                                <ng-container *ngIf="isSelectedServiceIsPresent">
                                                    <h4 class="required error">{{isSelectedServiceIsPresent}}</h4>
                                                </ng-container>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-12 ">
                                                <h6 class="mt-2"
                                                    *ngIf="newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 0">
                                                    <b>Amount</b>
                                                </h6>
                                            </div>
                                            <div class="col-lg-2 col-md-5 col-sm-12 selfpay">
                                                <h6 class="mt-2"
                                                    *ngIf="newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 0">
                                                    <b>SelfPay</b>
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="row"
                                            *ngFor="let service of newPaymentServicesForm?.get('paymentServicesForm')?.controls; let servI = index"
                                            [formGroupName]="servI">
                                            <!-- Service Type Selection -->
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <ng-select [addTag]="true"
                                                        (change)="changeServiceTypeCheck(service, servI)"
                                                        id="payment_chooseService"
                                                        [readonly]="service?.get('serviceType')?.value === 'Credit' || isHealthpassReadinly || pageName === 'Healthpass' || switchArPatientInsurancePayForm?.get('switchCheck')?.value"
                                                        placeholder="Add or Choose Service" [items]="paymentServices"
                                                        [clearable]="false" formControlName="serviceType">
                                                    </ng-select>
                                                    <label class="required error"
                                                        *ngIf="service?.get('serviceType')?.errors?.required && service?.get('serviceType')?.touched">Service
                                                        Type is required</label>
                                                </div>
                                            </div>
                                            <!-- Amount -->
                                            <div class="col-lg-3 col-md-5 col-sm-12">
                                                <div class="input-group input-sm">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="payment_amount"
                                                        [readonly]="service?.get('serviceType')?.value === 'Credit' || isHealthpassReadinly"
                                                        [attr.disabled]="pageName === 'Healthpass' ? 'true' : null"
                                                        (keydown.enter)="preventdefault()" formControlName="amount"
                                                        placeholder="Enter Amount">
                                                </div>
                                                <label class="required error"
                                                    *ngIf="service?.get('amount')?.errors?.required && service?.get('amount')?.touched">Amount
                                                    is required</label>
                                            </div>
                                            <!-- Self Pay check -->
                                            <div class="col-lg-2 col-md-5 col-sm-12 selfpay">
                                                <div class="checkbox checkbox-primary d-inline">
                                                    <input type="checkbox" formControlName="selfPay" name="selfPay"
                                                        id="selfPayCheck">
                                                </div>
                                            </div>

                                            <!-- newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 1 &&  -->
                                            <div class="col-lg-1 col-md-1 col-sm-12 text-right"
                                                *ngIf="service?.get('serviceType')?.value !== 'Credit' && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                                <button class="icon-queue-events delete-action-bg"
                                                    (click)="deletePaymentServices(servI)" placement="bottom"
                                                    id="payment_delete" ngbTooltip="Delete">
                                                    <!-- <span class="material-symbols-outlined">delete</span> -->
                                                    <i class="lar la-trash-alt"
                                                        style="font-size: 25px; color: black; margin-top: 10x; cursor: pointer;"></i>
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </ng-container>


                            <!-- AR Insurnace related Rows -->
                            <ng-container *ngIf="switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                <div class="ar-insurnace-sec" [formGroup]="arInsuranceFormGroup">
                                    <div class="fomr-array-sec" formArrayName="arInsuranceTypes">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-sm-12">
                                                <h6 class="mt-2"><b>Service Date</b></h6>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-12 ">
                                                <h6 class="mt-2"><b>Claim Processor</b></h6>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-12 ">
                                                <h6 class="mt-2"><b>Amount</b></h6>
                                            </div>
                                        </div>

                                        <div class="row"
                                            *ngFor="let arService of arInsuranceFormGroup?.get('arInsuranceTypes')?.controls; let arInsuI = index"
                                            [formGroupName]="arInsuI">
                                            <div class="col-lg-4 col-md-4 col-sm-12">
                                                <div class="form-group">
                                                    <ng-select [addTag]="true" id="payment_seviceDate"
                                                        (change)="changeServiceDate(arService, arInsuI)"
                                                        placeholder="Choose Service Date" bindLabel="appointmentTime"
                                                        bindValue="appointmentId" [items]="allActiveEncounters"
                                                        [clearable]="false" [hideSelected]="true"
                                                        formControlName="appointmentId">
                                                    </ng-select>
                                                    <label class="required error"
                                                        *ngIf="arService?.get('appointmentId')?.errors?.required && arService?.get('appointmentId')?.touched">Service
                                                        Date is required</label>
                                                </div>
                                            </div>

                                            <!-- Claim Processor Type -->
                                            <div class="col-lg-4 col-md-4 col-sm-12">
                                                <div class="form-group">
                                                    <ng-select [addTag]="true" placeholder="Choose Claim Processor"
                                                        id="payment_claimProcessor" [items]="allClaimProcessorTypes"
                                                        [clearable]="true"
                                                        formControlName="claimProcessorType"></ng-select>
                                                    <label class="required error"
                                                        *ngIf="arService?.get('claimProcessorType')?.errors?.required && arService?.get('claimProcessorType')?.touched">Claim
                                                        Processor is required</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-3 col-sm-12">
                                                <div class="input-group input-sm">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="number" class="form-control" formControlName="amount"
                                                        id="payment_amount2" placeholder="Enter Amount">
                                                </div>
                                                <label class="required error"
                                                    *ngIf="arService?.get('amount')?.errors?.required && arService?.get('amount')?.touched">Amount
                                                    is
                                                    required</label>
                                            </div>
                                            <div class="col-lg-1 col-md-1 col-sm-12 text-right">
                                                <button class="icon-queue-events delete-action-bg"
                                                    (click)="deleteArInsurance(arInsuI)" placement="bottom"
                                                    id="payment_delete2" ngbTooltip="Delete"><span
                                                        class="material-symbols-outlined">delete</span></button>
                                            </div>

                                        </div>
                                        <ng-container *ngIf="isSelectedServiceIsPresent">
                                            <h4 class="required error">{{isSelectedServiceIsPresent}}</h4>
                                        </ng-container>
                                    </div>

                                </div>

                            </ng-container>

                        </div>
                    </div>

                    <!-- Previous Visits Pending Patient Responsibility -->
                    <ng-container
                        *ngIf="patientResponsibilityResponse?.patientRespNCredits?.length > 0 && totalPatientRespCharges() > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                        <hr>
                        <h5 class="text-center">Previous Visits</h5>
                        <div class="patient-resp-items" [formGroup]="patientResponsibilityForm">
                            <div class="patient-sub-reps-items" formArrayName="pastVisitPendingPayments">
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 col-sm-12">
                                        <h6 class="mt-2"
                                            [class.isPartialPay]="partialPaymentsForm?.get('isPartialPayment')?.value">
                                            <b>Service Date</b>
                                        </h6>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                                        <h6 class="balance text-center"><b>Balance</b></h6>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                                        <h6 class="ar-type text-center"><b>AR</b></h6>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12 mt-2">
                                        <h6 class="claim-processor-type text-center"><b>Claim Processor</b></h6>
                                    </div>
                                    <!-- <div class="col-lg-3 col-md-3 col-sm-12 mt-2" *ngIf="pentResp?.get('arType')?.value !== 'AR Insurance'">
                                        <h6 class="claim-processor-type text-center"><b>Credits</b></h6>
                                    </div> -->
                                </div>
                                <div class="row mb-2"
                                    *ngFor="let pentResp of patientResponsibilityForm?.get('pastVisitPendingPayments')?.controls; let in = index;"
                                    [formGroupName]="in">
                                    <ng-container *ngIf="pentResp?.get('paymentAmount')?.value > 0">
                                        <div class="col-lg-3 col-md-3 col-sm-12"
                                            *ngIf="!partialPaymentsForm?.get('isPartialPayment')?.value">
                                            <!-- <h6 class="mt-2">{{ pentResp?.get('serviceDate')?.value | date:'MM/dd/yyyy' }}
                                            </h6> -->
                                            <h6 class="mt-2">{{pentResp?.get('serviceDate')?.value ?
                                                httpService?.formatDobTimeZoneWithDisplay(pentResp?.get('serviceDate')?.value)
                                                : '--'}}</h6>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12"
                                            *ngIf="partialPaymentsForm?.get('isPartialPayment')?.value">
                                            <div class="form-group">
                                                <div class="checkbox checkbox-primary d-inline">
                                                    <input type="checkbox" formControlName="isPartialPaymentCheck"
                                                        id="payment_checkbox">
                                                    <label class="cr list-name-label ml-4"
                                                        style="margin-left: 40px !important;"
                                                        for="">{{pentResp?.get('serviceDate')?.value ?
                                                        httpService?.formatDobTimeZoneWithDisplay(pentResp?.get('serviceDate')?.value)
                                                        : '--'}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12">
                                            <div class="input-group" id="priorchanges">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input type="number" id="payment_balance" class="form-control"
                                                    formControlName="paymentAmount" readonly placeholder="Balance">
                                            </div>
                                        </div>
                                        <!-- AR Type -->
                                        <div class="col-lg-3 col-md-3 col-sm-12">
                                            <div class="form-group">
                                                <ng-select [addTag]="true" id="payment_chooseAr" placeholder="Choose AR"
                                                    [items]="allArTypes"
                                                    [readonly]="!switchArPatientInsurancePayForm?.get('switchCheck')?.value"
                                                    [clearable]="true" formControlName="arType">
                                                </ng-select>
                                                <!-- <label class="required error" *ngIf="service?.get('serviceType')?.errors?.required && service?.get('serviceType')?.touched">Service Type is required</label> -->
                                            </div>
                                        </div>
                                        <!-- Claim Processor Type -->
                                        <div class="col-lg-3 col-md-3 col-sm-12"
                                            *ngIf="pentResp?.get('arType')?.value === 'AR Insurance'">
                                            <div class="form-group">
                                                <ng-select [addTag]="true" id="payment_chooseClaimProcessor"
                                                    placeholder="Choose Claim Processor"
                                                    [items]="allClaimProcessorTypes" [clearable]="true"
                                                    formControlName="claimProcessorType"></ng-select>
                                                <!-- <label class="required error" *ngIf="service?.get('serviceType')?.errors?.required && service?.get('serviceType')?.touched">Service Type is required</label> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12"
                                            *ngIf="pentResp?.get('arType')?.value !== 'AR Insurance'">
                                            <div class="form-group text-center">
                                                <span class="">NA</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row" *ngIf="showArInsuranceMessage">
                                    <div class="col-12">
                                        <h6 class="required error">We can't submit AR Insurance with AR Patient. Please
                                            make payment of AR Insurance individually.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>


                    <!-- Credits Available -->
                    <ng-container
                        *ngIf="totalCredits > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                        <hr>
                        <div class="row mb-2">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <h4 class="mt-2"><b>Credits Available</b></h4>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="input-group input-sm-text-1" id="visitcharges">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <div class="payments-common-showdue">{{totalCredits | number : '1.2-2'}}</div>
                                    <!-- <input type="number"  class="form-control" readonly value="{{totalCredits | number : '1.2-2'}}" placeholder="Credits"> -->
                                </div>
                            </div>

                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="totalCharges() > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                        <hr>
                        <div class="row" [formGroup]="partialPaymentsForm">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group text-left list-group">
                                    <div class="checkbox checkbox-primary d-inline">
                                        <input type="checkbox" name="payment-check"
                                            (change)="makePartialPayment($event)" id="payment-check"
                                            formControlName="isPartialPayment">
                                        <label for="payment-check" class="cr list-name-label ml-2">Any Partial
                                            Payment?</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <label for="priorchanges"></label>
                                <div class="input-group input-sm-text-1" id="priorchanges">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input type="number" class="form-control" id="payment_partialAmount"
                                        [readonly]="!partialPaymentsForm?.get('isPartialPayment')?.value"
                                        formControlName="partialAmount" placeholder="Enter Partial Amount">
                                </div>
                                <div class="form-group error-message text-right">
                                    <label class="required error"
                                        *ngIf="partialPaymentsForm?.get('partialAmount')?.errors?.required && partialPaymentsForm?.get('partialAmount')?.touched">Partial
                                        Payment is required to make payment</label>
                                    <label class="required error"
                                        *ngIf="partialPaymentsForm?.get('isPartialPayment')?.value && partialPaymentsForm?.get('partialAmount')?.value > totalCharges()">Partial
                                        Amount should not be greater than Total Charges</label>
                                    <label class="required error"
                                        *ngIf="!partialPaymentsForm?.get('partialAmount')?.errors?.required && totalCharges() > 0 && partialPaymentsForm?.get('isPartialPayment')?.value && (partialPaymentsForm?.get('partialAmount')?.value === 0 || partialPaymentsForm?.get('partialAmount')?.value < 1)">
                                        Partial Amount should be minimum of $1 to make payment
                                    </label>
                                    <!-- <label class="required error"
                                        *ngIf="!partialPaymentsForm?.get('partialAmount')?.errors?.required && isPriorChargesValidationShown()">
                                        Partial Amount cannot be less than selected previous visits balance.
                                    </label> -->
                                </div>
                            </div>

                        </div>

                        <!-- If Charges are Pending, Due pending with partial charges && totalChargesCount() >  && (totalVisitChargesForm?.get('totalVisitCharges')?.value > partialPaymentsForm?.get('partialAmount')?.value) && ((totalVisitChargesForm?.get('totalVisitCharges')?.value - partialPaymentsForm?.get('partialAmount')?.value) > 0) -->
                    </ng-container>

                    <ng-container>
                        <hr>
                        <h4>Summary</h4>

                        <div class="row" *ngIf="!switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                            <div class="col-md-4">
                                <label class="text-label" *ngIf="pageName === 'Healthpass'">Plan Charges</label>
                                <label class="text-label" *ngIf="pageName !== 'Healthpass'">Current Visit
                                    Charges</label>
                            </div>
                            <div class="col-md-8 text-right">
                                <label class="text-form-control">$ {{(getTotalVisitCharges() || 0) | number :
                                    '1.2-2'}}</label>
                            </div>
                        </div>

                        <div class="row"
                            *ngIf="totalPatientRespCharges() > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                            <div class="col-md-4">
                                <label class="text-label">Past Visit Charges</label>
                            </div>
                            <div class="col-md-8 text-right">
                                <label class="text-form-control">$ {{(totalPatientRespCharges() || 0) | number :
                                    '1.2-2'}}</label>
                            </div>
                        </div>

                        <div class="row"
                            *ngIf="totalCredits > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                            <div class="col-md-4">
                                <label class="text-label">Credits Applied</label>
                            </div>
                            <div class="col-md-8 text-right">
                                <label *ngIf="(totalPatientRespCharges() + totalChargesCount()) > 0"
                                    class="text-form-control">$ -{{ totalCredits | number : '1.2-2'}}</label>
                                <label *ngIf="(totalPatientRespCharges() + totalChargesCount()) <= 0"
                                    class="text-form-control">$ {{0 | number : '1.2-2'}}</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <label class="text-label">Total Charges</label>
                            </div>
                            <div class="col-md-8 text-right">
                                <label class="text-form-control">$ {{totalCharges() | number :
                                    '1.2-2'}}</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-8 col-md-8 col-sm-8">
                                <h4>Amount Payable</h4>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 text-right"
                                *ngIf="!switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                <h4 class="text-form-control"
                                    *ngIf="grandTotalCharges() < 0 || grandTotalCharges() === 0"><b>$ {{0 | number
                                        : '1.2-2'}}</b></h4>
                                <h4 class="text-form-control"
                                    *ngIf="grandTotalCharges() > 0 && grandTotalCharges() !== 0"><b>$
                                        {{(grandTotalCharges() || 0) | number : '1.2-2'}}</b></h4>
                            </div>
                            <!-- AR Insurnace Total -->
                            <div class="col-lg-4 col-md-4 col-sm-4 text-right"
                                *ngIf="switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                <h4 class="text-form-control"
                                    *ngIf="grandTotalCharges() < 0 || grandTotalCharges() === 0"><b>$ {{0 | number
                                        : '1.2-2'}}</b></h4>
                                <h4 class="text-form-control"
                                    *ngIf="grandTotalCharges() > 0 && grandTotalCharges() !== 0"><b>$
                                        {{(grandTotalCharges() || 0) | number : '1.2-2'}}</b></h4>
                            </div>

                        </div>

                        <div class="row"
                            *ngIf="(grandTotalCharges() < 0 || grandTotalCharges() === 0) && totalCredits > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                            <div class="col-lg-8 col-md-8 col-sm-8">
                                <h4 class="error-msg error">Credits Balance</h4>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 text-right">
                                <label class="text-form-control">$ {{((grandTotalCharges() || 0) * -1) | number :
                                    '1.2-2'}}</label>
                            </div>
                        </div>

                        <div class="row"
                            *ngIf="partialPaymentsForm?.get('isPartialPayment')?.value && partialPaymentsForm?.get('partialAmount')?.value > 0 && totalBalanceDue() >= 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                            <div class="col-lg-8 col-md-8 col-sm-8">
                                <h4 class="error-msg error">Balance Due</h4>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 text-right">
                                <label class="text-form-control">$ {{totalBalanceDue() | number : '1.2-2'}}</label>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="totalChargesPayingCheck && pageName !== 'Healthpass'">
                        <h5 class="error">{{totalChargesPayingCheck}}</h5>
                    </ng-container>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="btn-group mt-10 float-right">
                                <button type="button" class="btn btn-primary dash-actions" id="commonPayment_next"
                                    (click)="checkPaymentServices()" awNextStep>Next <i
                                        class="material-symbols-outlined">chevron_right</i></button>
                            </div>
                        </div>
                    </div>
                </form>
            </aw-wizard-step>

            <!-- Payment Method [canEnter]="paymentTypeSelectionForm?.valid"-->
            <aw-wizard-step stepTitle="Payment Method" [canEnter]="canEnterPaymentTypeValidation()">
                <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                    <span *ngIf="!wizardStep?.completed && !wizardStep?.selected">2</span>
                    <i *ngIf="!wizardStep?.completed && wizardStep?.selected"
                        style="color: #fff;font-size: 20px;line-height: 42px;" class="las la-pen"></i>
                    <i *ngIf="wizardStep?.completed" style="color: #fff;font-size: 20px;line-height: 42px;"
                        class="las la-check"></i>
                </ng-template>

                <div class="row mt-4" *ngIf="grandTotalCharges() > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12" [formGroup]="paymentTypeSelectionForm">
                        <h5 class="mt-2">Payment Type:</h5>
                        <div class="form-group">
                            <ng-select (change)="changePayType($event)" formControlName="paymentType"
                                id="payment_paymentType" placeholder="Choose Payment Type"
                                [items]="paymentType"></ng-select>
                            <label class="required error"
                                *ngIf="paymentTypeSelectionForm?.get('paymentType')?.errors?.required && paymentTypeSelectionForm?.get('paymentType')?.touched">Payment
                                Type is required</label>
                        </div>
                        <!-- this is only for AR Insurane Payments Types  *ngIf="!switchArPatientInsurancePayForm?.get('switchCheck')?.value"-->
                        <!-- <div class="form-group" *ngIf="switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                            <ng-select (change)="changePayType($event)" formControlName="paymentType"
                                placeholder="Choose Payment Type" [items]="arInsurancePaymentType"></ng-select>
                            <label class="required error"
                                *ngIf="paymentTypeSelectionForm?.get('paymentType')?.errors?.required && paymentTypeSelectionForm?.get('paymentType')?.touched">Payment
                                Type is required</label>
                        </div> -->
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12"
                        *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS' && terminalTypes?.length > 1"
                        [formGroup]="paymentTypeSelectionForm">
                        <h5 class="mt-2">Terminal:</h5>
                        <div class="form-group">
                            <ng-select formControlName="terminalType" id="payment_terminal"
                                placeholder="Choose Terminal" bindLabel="name" [items]="terminalTypes"></ng-select>
                            <label class="required error"
                                *ngIf="paymentTypeSelectionForm?.get('terminalType')?.errors?.required && paymentTypeSelectionForm?.get('terminalType')?.touched">Terminal
                                is required</label>
                        </div>
                    </div>
                    <div style="margin-top: 35px;"
                        [ngClass]="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS' && terminalTypes?.length > 1 ? 'col-lg-1 col-md-1 col-sm-12' : 'col-lg-6 col-md-6 col-sm-12'">
                        <button class="icon-queue-events float-right" id="payment_AccBalance"
                            style="background-color: #5ca6db;color: #fff;margin-right: 0px;" placement="bottom"
                            ngbTooltip="Notify - Account Balance" (click)="sendAccountBalanceNotify()"><i
                                class="lab la-telegram"></i></button>
                    </div>
                </div>

                <div class="total-payment">
                    <span class="pull-left">
                        <b> Total Payment</b>
                    </span>
                    <span class="pull-right float-right"
                        *ngIf="grandTotalCharges() < 0 || grandTotalCharges() === 0"><b>$ {{0 | number
                            : '1.2-2'}}</b></span>
                    <span class="pull-right float-right"
                        *ngIf="grandTotalCharges() > 0 && grandTotalCharges() !== 0"><b>$
                            {{(grandTotalCharges() || 0) | number : '1.2-2'}}</b></span>

                    <!-- <span class="pull-right float-right"><b>$ {{(grandTotalCharges() || 0) | number : '1.2-2'}}</b></span> -->
                </div>

                <!-- <div class="row">
                    <div class="col-12 notify-account-balance">
                        <a  href="javascript:" (click)="sendAccountBalanceNotify()"><i class="lab la-telegram"></i> Notify</a>
                        <button class="icon-queue-events float-right" style="background-color: #5ca6db;color: #fff;" placement="bottom" ngbTooltip="Notify - Account Balance" (click)="sendAccountBalanceNotify()"><i class="las la-user-circle"></i>&nbsp;Notify</button>
                    </div>
                </div> -->

                <!-- POS Terminal Payment -->
                <ng-container *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS'">
                    <div class="accordion" id="paymentAccordion">

                        <div class="card">
                            <div class="card-header" id="posTerminalPayment">
                                <h2 class="text-left collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                    aria-expanded="false" aria-controls="collapseTwo">
                                    <span class="pull-left">
                                        <form>
                                            <div class="custom-control">
                                                <!-- <input type="radio" class="custom-control-input" id="achradio" name="nonPosPaymentsRadio"
                                                    value="customEx"> -->
                                                <label class="payment-label-name">POS Payment</label>
                                            </div>
                                        </form>
                                    </span>
                                    <span class="pull-left">
                                        Click on Make Payment to open payment in Terminal Device.
                                    </span>
                                    <span class="pull-right text-right">
                                        <img src="../../../../../assets/images/pay/pos.png"
                                            style="height: 50px; width: 50px;">
                                    </span>
                                </h2>
                            </div>
                            <!-- <div id="collapseTwo" class="collapse" aria-labelledby="posTerminalPayment" data-parent="#paymentAccordion">
                                <div class="card-body">
                                    <div class="form-group" style=" text-align: center;">
                                        <button id="ach-button" (click)="achPayment()" class="btn btn-primary dash-actions" type="button">Pay with Bank Account</button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Non POS'">
                    <div class="accordion" id="paymentAccordion">
                        <!-- ACH Payment -->
                        <!-- <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h2 class="text-left collapsed" (click)="achPayOpen()" data-toggle="collapse"
                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span class="pull-left">
                                        <form>
                                            <div class="custom-control">
                                                <label class="payment-label-name">ACH Payment</label>
                                            </div>
                                        </form>
                                    </span>
                                    <span class="pull-left">
                                        You will be redirected to the ACH Payments website
                                        after submitting your order
                                    </span>
                                    <span class="pull-right text-right">
                                        <img src="../../../../../assets/images/pay/Ach.png">
                                    </span>

                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#paymentAccordion">
                                <div class="card-body">
                                    <div class="form-group" style=" text-align: center;">
                                        <button id="ach-button" (click)="achPayment()"
                                            class="btn btn-primary dash-actions" type="button">Pay with Bank
                                            Account</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Credit Card Payment -->
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h2 class="text-left collapsed" type="button" (click)="cardPayment()"
                                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                    <span class="pull-left">
                                        <form>
                                            <div class="custom-control">
                                                <!-- <input type="radio" class="custom-control-input" id="creditcardadio"
                                                    name="nonPosPaymentsRadio" value="creditcardadio"> -->
                                                <label class="payment-label-name">Pay with Credit/Debit
                                                    Card</label>
                                            </div>
                                        </form>
                                    </span>
                                    <span class="pull-left dnone">
                                        will be redirected to the ACH Payments website
                                        after submitting your order
                                    </span>
                                    <span class="pull-right text-right">
                                        <img src="../../../../../assets/images/pay/cards-img.png">
                                    </span>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                data-parent="#paymentAccordion">
                                <div class="card-body">
                                    <form id="payment-form">
                                        <!-- card details enter -->
                                        <div id="card-container"></div>
                                        <div class="credit-card-btns">
                                            <div class="form-group" style=" text-align: center;">
                                                <button *ngIf="!enableNonPosCancelPayment" id="card-button"
                                                    class="btn btn-primary dash-actions" type="button"><i
                                                        class="las la-check-double"></i>&nbsp; Submit Payment</button>
                                                <button *ngIf="enableNonPosCancelPayment" id="terminal-pay"
                                                    class="btn btn-primary dash-actions" type="button"
                                                    (click)="cancelPayment()">Cancel Payment</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Digital Wallets -->
                        <div class="card">
                            <div class="card-header" id="paycreditcard1">
                                <h2 class="text-left collapsed" type="button" (click)="applePayOpen();googlePayOpen()"
                                    data-toggle="collapse" data-target="#paycreditcard" aria-expanded="false"
                                    aria-controls="collapseThree">
                                    <span class="pull-left">
                                        <form>
                                            <div class="custom-control">
                                                <!-- <input type="radio" class="custom-control-input" id="digitalwallet" name="nonPosPaymentsRadio"
                                                    value="digitalwallet"> -->
                                                <label class="payment-label-name">Digital
                                                    Wallets</label>
                                            </div>
                                        </form>
                                    </span>
                                    <span class="pull-left">
                                        You will be redirected to the Google pay
                                        & Apple Pay after submitting your order
                                    </span>
                                    <span class="pull-right text-right">
                                        <img src="../../../../../assets/images/pay/gpay-1.png">
                                    </span>

                                </h2>
                            </div>
                            <div id="paycreditcard" class="collapse" aria-labelledby="paycreditcard1"
                                data-parent="#paymentAccordion">
                                <div class="card-body">
                                    <div class="row" style="text-align: center;">
                                        <form style="width: 45%;">
                                            <button id="apple-pay-button" (click)="applePayOpen();"
                                                class="btn btn-primary dash-actions">Apple
                                                Pay</button>
                                        </form>
                                        <br />
                                        <form style="width: 45%;">
                                            <button (click)="googlePayOpen()"
                                                class="btn btn-primary dash-actions mb-3">Google Pay</button>
                                            <br />
                                            <div id="google-pay-button"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Check' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Paper Work' ">
                    <div class="accordion" id="paymentAccordion">

                        <div class="card">
                            <div class="card-header" id="cashChecqueOtherPayments">
                                <h2 class="text-left collapsed" data-toggle="collapse" data-target="#cashChequePayments"
                                    aria-expanded="false" aria-controls="cashChequePayments">
                                    <span class="pull-left">
                                        <form>
                                            <div class="custom-control">
                                                <!-- <input type="radio" class="custom-control-input" id="achradio" name="nonPosPaymentsRadio"
                                                    value="customEx"> -->
                                                <label
                                                    class="payment-label-name">{{paymentTypeSelectionForm?.get('paymentType')?.value}}</label>
                                            </div>
                                        </form>
                                    </span>
                                    <span class="pull-left">
                                        <!-- Click on Make Payment to open payment in Terminal Device. -->
                                    </span>
                                    <span class="pull-right text-right">
                                        <!-- <img src="../../../../../assets/images/pay/Ach.png"> -->
                                    </span>
                                </h2>
                            </div>
                            <!-- <div id="cashChequePayments" class="collapse" aria-labelledby="cashChecqueOtherPayments" data-parent="#paymentAccordion">
                                <div class="card-body">
                                    <div class="form-group" style=" text-align: center;">
                                        <button id="ach-button" (click)="achPayment()" class="btn btn-primary dash-actions" type="button">Pay with Bank Account</button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="form-group" style=" text-align: center;">
                        <button *ngIf="!showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button" (click)="createCashPayment()">Pay ${{amountForm.controls['amount']?.value}}</button>
                    </div> -->
                </ng-container>

                <div class="row mt-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <button type="button" class="btn btn-primary dash-actions" awPreviousStep><i
                                class="las la-angle-left"></i> &nbsp;Previous</button>
                        <div class="btn-group mt-10 float-right">
                            <ng-container *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS'">
                                <!-- <button *ngIf="showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button" (click)="cancelPosPayment()">Cancel Payment</button> -->
                                <button type="button" (click)="createPosPayment()" id="submit-payment-pos"
                                    class="btn btn-primary dash-actions"><i class="las la-check-double"></i>&nbsp;
                                    Submit Payment</button>
                            </ng-container>
                            <ng-container
                                *ngIf="(paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Check' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Paper Work') && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                <button type="button" class="btn btn-primary dash-actions" id="submit-payment-cash"
                                    (click)="createCashPayment()"><i class="las la-check-double"></i>&nbsp; Submit
                                    Payment</button>
                            </ng-container>
                            <ng-container
                                *ngIf="(paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Check' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Paper Work') && switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                <button type="button" class="btn btn-primary dash-actions" id="submit-payment-cash2"
                                    (click)="createArInsurancePayment()"><i class="las la-check-double"></i>&nbsp;
                                    Submit Payment</button>
                            </ng-container>
                            <ng-container *ngIf="grandTotalCharges() < 0 || grandTotalCharges() === 0">
                                <button type="button" class="btn btn-primary dash-actions" id="credit-payment"
                                    (click)="createCreditPayment()"><i class="las la-check-double"></i>&nbsp;Make
                                    Credits Payment</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>
        </aw-wizard>
    </div>
    <div class="app-modal-footer">
        <!-- <button type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal"
        (click)="paymentModal.hide(); loadSpinner = false">Close</button> -->
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Success -->
<app-ui-modal #paySuccessModal [hideHeader]="true" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="paymentSuccessDailogue">
    <div class="app-modal-body">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="paySuccessModal?.hide(); clearSession(); loadPaymentInfo(); futureBalnceUpdate();"><span
                aria-hidden="true">&times;</span></button>

        <div class="success-container">
            <div class="row">
                <div class="col-md-12 text-success">
                    <i class="fa fa-check-circle" style="font-size: 50px;"></i>
                </div>
                <div class="col-md-12">
                    <h4>Payment Successful!</h4>
                </div>
                <div class="col-md-12">
                    <p>Transaction Number: {{successPayDetails?.paymentId}}</p>
                </div>
                <hr>
                <div class="col-md-12">
                    <p>
                        <span class="float-left">Amount Paid: </span>
                        <span class="float-right">$ {{successPayDetails?.amount / 100}}</span>
                    </p>
                    <div class="link-text" id="payment_receipt"
                        *ngIf="successPayDetails && successPayDetails?.paymentId && pageName !== 'Healthpass' && !accountLevelPay"
                        (click)="viewPaymentReciept(successPayDetails?.paymentId, commonPaymentInvoiceModal); futureBalnceUpdate();">
                        View/Print
                        Payment Receipt</div>

                    <div class="link-text" *ngIf="pageName === 'Healthpass'">
                        <div class="link-text-sub" id="payment_receipt2"
                            (click)="viewPaymentReciept(successPayDetails?.paymentId, commonPaymentInvoiceModal)">
                            View/Print Payment Receipt</div><br>
                        <div class="link-text-sub"
                            (click)="generateHealthpassPlanReceipt(planInvoiceModal); loadSpinner = false">View/Print
                            Plan Summary</div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <!--<button *ngIf="pageName === 'Healthpass'"  type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="paySuccessModal.hide(); generateHealthpassPlanReceipt(); loadSpinner = false">Close</button>-->

        <button type="button" class="btn btn-primary" style="margin-right: 15px;" data-dismiss="modal"
            id="payment_close"
            (click)="paySuccessModal.hide(); clearSession(); loadPaymentInfo(); futureBalnceUpdate(); loadSpinner = false">Close</button>
    </div>
</app-ui-modal>

<!-- Payment Invoice -->
<app-ui-modal #commonPaymentInvoiceModal [hideHeader]="false" [modalCentered]="true" id="commonPaymentInvoiceModal">

    <div class="app-modal-header">
        <h5 class="modal-title">Payment Receipt</h5>

        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="closeModal();commonPaymentInvoiceModal?.hide();loadPaymentInfo(); loadSpinner = false "><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="payment-reciept">
            <iframe id="{{recieptID || 'common-new-payment-reciept-embed'}}" frameBorder="0" width="100%"
                height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary"
            (click)="closeModal();commonPaymentInvoiceModal?.hide();loadPaymentInfo(); loadSpinner = false "
            id="payment_ok">Ok</button>
        <button *ngIf="pageName === 'Healthpass'" type="button" class="btn btn-primary" data-dismiss="modal"
            (click)="closeModal(); commonPaymentInvoiceModal?.hide(); loadSpinner = false;loadPaymentInfo();"
            id="payment_close2">Close</button>
    </div>
</app-ui-modal>


<!-- Payment Invoice -->
<app-ui-modal #planInvoiceModal [hideHeader]="false" [modalCentered]="true" id="planInvoiceModal">

    <div class="app-modal-header">

        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="planInvoiceModal?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <h5 class="modal-title">Plan Summary</h5>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="payment-reciept">

            <iframe id="healthpass-receipt" frameBorder="0" width="100%" height="500px"></iframe>

        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="planInvoiceModal?.hide();"
            id="payment_close3">Close</button>
    </div>
</app-ui-modal>

<!-- Late Notify PopUp -->
<app-send-sms-notify [pageType]="'Claim AR'"></app-send-sms-notify>