import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ExamRoomComponent } from 'src/app/demo/panels/hospital/exam-room/exam-room.component';
import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateFormGuard implements CanDeactivate<ExamRoomComponent> {
  canDeactivate(component: ExamRoomComponent): boolean {
    const sessionUser = JSON.parse(sessionStorage.getItem('userDetails'));
    if (component?.hasUnsavedData() && sessionUser) {
      if (confirm("Changes to the current section will be lost if you leave. Please click Save and Continue.")) {
        return true;
      } else {
        // window?.location?.reload();
        return false;
      }
    }
    return true;
  }

}
