<div class="summary-inhouselabs-data">
    <div class="in-house-labs-sec">
        <ng-container *ngFor="let internalOrder of inhouseLabsData">
            <!-- Urine Analysis Order -->
            <ng-container *ngIf="internalOrder?.testName === 'Urine Analysis'">
                <div class="inhouseLabs-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Urine Analysis ({{internalOrder?.urinAnalysisReportForm?.type}}) -
                                {{internalOrder?.status}}:</h5>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5><u>Physical Examination</u></h5>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label class="labelStyle">Appearance</label> <span class="label-colon">
                                        : </span>
                                    <!-- </div>
                                <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                    <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.appearance
                                        || '--'}}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label class="labelStyle">Color</label> <span class="label-colon"> :
                                    </span>
                                    <!-- </div>
                                <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                    <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.color
                                        || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <!-- Chemical Examination -->
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5><u>Chemical Examination</u></h5>
                            <div class="row">
                                <ng-container
                                    *ngFor="let chemicalExam of internalOrder?.urinAnalysisReportForm?.chemicalExamination; let i = index">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{chemicalExam?.examName}}&nbsp;&nbsp;:</label>
                                        <label class="text-center">&nbsp;&nbsp;{{chemicalExam?.value ||
                                            '--'}}</label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Urine Analysis Notes</label> <span class="label-colon"> : </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.urinAnalysisReportForm?.notes || '--'}} </label>
                        </div>

                    </div>
                </div>
            </ng-container>

            <!-- Pregnancy Test Order -->
            <ng-container *ngIf="internalOrder?.testName === 'Pregnancy Test'">
                <div class="inhouseLabs-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Pregnancy Test - {{internalOrder?.status}}:</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">HCG</label> <span class="label-colon"> : </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.pregnancyTest?.result || '--'}} </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Result</label> <span class="label-colon"> : </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{getHcgResult(internalOrder?.pregnancyTest?.result) || '--'}} </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.pregnancyTest?.notes || '--'}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Drug Screening Order -->
            <ng-container *ngIf="internalOrder?.testName === 'Drug Screening'">
                <div class="inhouseLabs-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Drug Screening - {{internalOrder?.status}}:</h5>
                        </div>
                    </div>
                    <div class="row">
                        <ng-container *ngFor="let drugItem of internalOrder?.drugScreening?.drugs; let i = index">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{drugItem?.drugName}} -
                                    {{drugItem?.deviceCode}}&nbsp;&nbsp;:</label>
                                <label class="text-center">&nbsp;&nbsp;{{drugItem?.result || '--'}}</label>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.drugScreening?.notes || '--'}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Rapid A Strep Test Order -->
            <ng-container *ngIf="internalOrder?.testName === 'Rapid A Strep Test'">
                <div class="inhouseLabs-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Rapid A Strep Test - {{internalOrder?.status}}:</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Result</label> <span class="label-colon"> : </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.strepTest?.result || '--'}} </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h6>Diagnosis Values:</h6>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <ul>
                                <ng-container
                                    *ngFor="let diagnosis of internalOrder?.strepTest?.diagnosis; let i = index">
                                    <li>{{diagnosis}}</li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.strepTest?.notes || '--'}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Anticoagulant Test Order -->
            <ng-container *ngIf="internalOrder?.testName === 'Anticoagulant Test'">
                <div class="inhouseLabs-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Anticoagulant Test - {{internalOrder?.status}}:</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Result Range</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.anticoagulantTestResults?.range || '--'}} </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Units</label> <span class="label-colon"> : </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.anticoagulantTestResults?.units || '--'}} </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.anticoagulantTestResults?.notes || '--'}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Blood Glucose Order -->
            <ng-container *ngIf="internalOrder?.testName === 'Blood Glucose'">
                <div class="inhouseLabs-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Blood Glucose - {{internalOrder?.status}}:</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Blood Glucose (With Fasting)</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.bloodGlucoseResults?.bloodSugarWithFasting || '--'}}
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Blood Glucose (Without Fasting)</label> <span class="label-colon">
                                : </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.bloodGlucoseResults?.bloodSugarWithoutFasting || '--'}}
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">A1C Value</label> <span class="label-colon"> : </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.bloodGlucoseResults?.a1CValue || '--'}}
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.bloodGlucoseResults?.notes || '--'}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Covid-19 Test Order -->
            <ng-container *ngIf="internalOrder?.testName === 'Covid-19 Test'">
                <div class="inhouseLabs-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Covid-19 Test - {{internalOrder?.status}}:</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Test Type</label> <span class="label-colon"> : </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.covidTestResults?.type || '--'}} </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Test Result</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.covidTestResults?.result || '--'}} </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.covidTestResults?.notes || '--'}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Influenza Test Order -->
            <ng-container *ngIf="internalOrder?.testName === 'Influenza Test'">
                <div class="inhouseLabs-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Influenza Test - {{internalOrder?.status}}:</h5>
                        </div>
                    </div>
                    <div class="row">
                        <ng-container *ngFor="let influTest of internalOrder?.influenzaTest?.tests; let i = index">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label>{{influTest?.fluType}}&nbsp;&nbsp;:</label>
                                <label class="text-center">&nbsp;&nbsp;{{influTest?.result || '--'}}</label>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.influenzaTest?.notes || '--'}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Cologuard Test Order -->
            <ng-container *ngIf="internalOrder?.testName === 'Cologuard Test'">
                <div class="inhouseLabs-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Cologuard Test - {{internalOrder?.status}}:</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Requisition Date</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.requisitionDate ?
                                httpService?.formatDobTimeZoneWithDisplay(internalOrder?.requisitionDate) :
                                '--'}} </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="labelStyle">Collection Date</label> <span class="label-colon"> :
                            </span>
                            <!-- </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                            <label>{{internalOrder?.collectionDate ?
                                httpService?.formatDobTimeZoneWithDisplay(internalOrder?.collectionDate) :
                                '--'}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>

        </ng-container>
        <ng-container *ngIf="inhouseLabsData?.length === 0">
            <span>Inhouse Labs are not applicable for this patient’s record.</span>
        </ng-container>
    </div>
</div>