<div class="demographics-sec" [formGroup]="demographicsForm">
    <div class="patient-basic-details"></div>

    <aw-wizard #wizard class="arc-wizard new-designed-left-arcs" navBarLocation="left" [awNavigationMode]
        navigateBackward="allow" navigateForward="allow">
        <aw-wizard-step stepTitle="Demographics" awOptionalStep>
            <div class="row">
                <div class="col-sm-12">
                    <h5>Demographics</h5>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">First Name<span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="First Name" id="patient-demo-info-first-name"
                            formControlName="firstName" />
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('firstName').errors?.required && demographicsForm.get('firstName').touched">First
                            Name is Required</span>
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('firstName').invalid && !demographicsForm.get('firstName').errors?.required">
                            Please enter a valid First Name
                        </span>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="Middle-name">Middle Name</label>
                        <input class="form-control input-text" type="text" placeholder="Middle Name" id="patient-demo-info-middle-name"
                            formControlName="middleName" />
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('middleName').errors?.required && demographicsForm.get('middleName').touched">Middle
                            Name is Required</span>
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('middleName').invalid && !demographicsForm.get('middleName').errors?.required">
                            Please enter a valid Middle Name
                        </span>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Last Name <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Last Name" id="patient-demo-info-last-name"
                            formControlName="lastName" />
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('lastName').errors?.required && demographicsForm.get('lastName').touched">Last
                            Name is Required</span>
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('lastName').invalid && !demographicsForm.get('lastName').errors?.required">Please
                            enter a valid Last Name</span>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <!-- onkeydown="return false" -->
                    <div class="form-group">
                        <label for="last-name">Date of Birth <span class="text-danger">*</span></label>
                        <input type="date" (change)="dobChange($event)" name="datemax" [min]="minDOB"
                            [max]="maxAppointmentDate" id="patient-demo-info-dob" class="form-control input-text" formControlName="dob"
                            (keyup.enter)="validateManualEnterdob($event)" (mouseout)="validateManualEnterdob($event)" (blur)="validateManualEnterdob($event)">
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('dob').errors?.required && demographicsForm.get('dob').touched">Date
                            of Birth is Required</span>
                            <span class="text-danger"
                        *ngIf="demographicsForm.get('dob').errors?.invalidDate && demographicsForm.get('dob').touched">Please Enter Valid Date Of Birth</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                        <label>Age <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="number" placeholder="Age" id="Age" formControlName="age" />
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('age').errors?.required && demographicsForm.get('age').touched">Age
                            is Required</span>
                    </div>
                </div> -->
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">Gender <span class="text-danger">*</span></label>
                        <ng-select placeholder="Select Gender" id="patient-demo-info-gender" formControlName="gender" [items]="genderList"
                            bindLabel="desc" bindValue="value"></ng-select>
                        <!-- <select class="form-control" placeholder="Gender" formControlName="gender" id="GENDER">
                            <option [disabled]='true' [value]='null' [selected]="true">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select> -->
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('gender').errors?.required && demographicsForm.get('gender').touched">Gender
                            is Required</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="MaritalStatus">Marital Status <span *ngIf="maritaMandatory" class="text-danger">*</span> </label>
                        <div class="input-group mb-0">
                            <ng-select placeholder="Select Marital Status" id="patient-demo-info-marital-status" formControlName="maritalStatus"
                                [items]="maritalStatusList" bindLabel="desc" bindValue="value"></ng-select>
                            <!-- <select class="form-control" name="MaritalStatus" id="MaritalStatus"
                                formControlName="maritalStatus">
                                <option [disabled]='true' [value]='null' [selected]="true" value="Select">Select Marital
                                    Status</option>
                                <option> Single</option>
                                <option> Married</option>
                                <option> Divorcee</option>
                                <option> Widow</option>
                                <option> Other</option>
                            </select> -->
                        </div>
                        <span class="text-danger"
                            *ngIf="maritaMandatory && (demographicsForm.get('maritalStatus').errors?.required && demographicsForm.get('maritalStatus').touched)">Marital
                            Status is Required</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Race <span *ngIf="validationRequired" class="text-danger">*</span></label>
                        <div class="input-group mb-0">
                            <ng-select placeholder="Select Race" formControlName="race" id="patient-demo-info-race" [items]="raceList"
                                bindLabel="desc" bindValue="value"></ng-select>
                            <!-- <select class="form-control" name="Race" id="Race" formControlName="race">
                                <option [disabled]='true' [value]='null' [selected]="true" value="Select">Select Race
                                </option>
                                <option> American Indian or Alaska Native</option>
                                <option> Black or African American</option>
                                <option> White</option>
                                <option> Asian</option>
                                <option> Native Hawaiian or Other pacific Islander</option>
                                <option> Other Race/Multiracial </option>
                                <option> Unknown</option>
                                <option> I don’t want to specify</option>
                            </select> -->
                        </div>
                        <span class="text-danger"
                            *ngIf="validationRequired && (demographicsForm.get('race').errors?.required && demographicsForm.get('race').touched)">Race
                            is Required</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Ethnicity <span *ngIf="validationRequired" class="text-danger">*</span></label>
                        <div class="input-group mb-0">
                            <ng-select placeholder="Select Ethnicity" formControlName="ethnicity" id="patient-demo-info-ethnicity" [items]="ethnicityList"
                                bindLabel="desc" bindValue="value"></ng-select>
                            <!-- <select class="form-control" name="Ethnicity" id="Ethinicity" formControlName="ethnicity">
                                <option [disabled]='true' [value]='null' [selected]="true" value="Select">Select
                                    Ethnicity
                                </option>
                                <option value="Hispanic"> Hispanic</option>
                                <option value="Not Hispanic"> Not Hispanic</option>
                                <option value="Unknown"> Unknown</option>
                                <option value="I don’t want to specify"> I don’t want to specify</option>
                            </select> -->
                        </div>
                        <span class="text-danger"
                            *ngIf="validationRequired && (demographicsForm.get('ethnicity').errors?.required && demographicsForm.get('ethnicity').touched)">Ethnicity
                            is Required</span>
                    </div>
                </div>
            </div>
            <h5 class="mb-2">Contact Details </h5>
            <hr />
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Primary Phone Type <span class="text-danger">*</span></label>
                        <div class="input-group mb-0">
                            <ng-select placeholder="Select Primary Phone Type"  id="patient-demo-info-primary-phone-type" formControlName="primaryPhoneType"
                                [items]="phoneTypeList" bindLabel="desc" bindValue="value"></ng-select>

                            <!-- <select class="form-control" formControlName="primaryPhoneType" name="phone_Type"
                                id="phone_Type">
                                <option [disabled]='true' [value]='null' [selected]="true" value="Select">Select Type
                                </option>
                                <option value="true"> Mobile Phone</option>
                                <option value="false"> Landline Phone</option>
                            </select> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Primary Phone Number <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" mask="(000) 000-0000"
                            placeholder="(xxx) xxx-xxxx" id="patient-demo-info-primary-phone-number" formControlName="patientPrimaryPhone" />
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('patientPrimaryPhone').errors?.required && demographicsForm.get('patientPrimaryPhone').touched">Phone
                            number is Required</span>
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('patientPrimaryPhone').invalid && !demographicsForm.get('patientPrimaryPhone').errors?.required">Please
                            enter a valid Phone number</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Alternate Phone Type <span class="text-danger"
                                *ngIf="checkAlternatePhoneNUmber">*</span></label>
                        <div class="input-group mb-0">
                            <ng-select placeholder="Select Alternate Phone Type" (change)="changeAlternatePhoneType()"
                                formControlName="alternativePhoneType" id="patient-demo-info-alt-phone-type" [items]="phoneTypeList" bindLabel="desc"
                                bindValue="value"></ng-select>
                            <span class="text-danger"
                                *ngIf="demographicsForm.get('alternativePhoneType').errors?.required && demographicsForm.get('alternativePhoneType').touched">Alternate
                                Phone Type is Required</span>
                            <!-- <select class="form-control" formControlName="alternativePhoneType" name="phone_Type"
                                id="phone_Type">
                                <option [disabled]='true' [value]='null' [selected]="true" value="Select">Select Type
                                </option>
                                <option value="true"> Mobile Phone</option>
                                <option value="false"> Landline Phone</option>
                            </select> -->
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Alternate Phone Number <span class="text-danger"
                                *ngIf="demographicsForm?.get('alternativePhoneType')?.value === true || demographicsForm?.get('alternativePhoneType')?.value === false">*</span></label>
                        <input class="form-control input-text" formControlName="alternativePhoneNumber" type="text"
                            mask="(000) 000-0000" placeholder="(xxx) xxx-xxxx" id="patient-demo-info-alt-ph-number" />
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('alternativePhoneNumber').errors?.required && demographicsForm.get('alternativePhoneNumber').touched">Alternate
                            Phone Number is Required</span>
                        <span class="text-danger"
                            *ngIf="demographicsForm?.get('alternativePhoneNumber')?.invalid && !demographicsForm.get('alternativePhoneNumber')?.errors?.required">Please
                            enter a valid Phone number</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="Email">Email </label>
                        <input class="form-control input-text" type="email" placeholder="Email" id="patient-demo-info-email"
                            formControlName="email" />
                        <!-- <span class="text-danger"
                                    *ngIf="demographicsForm.get('email').errors?.required && demographicsForm.get('email').touched">email
                                    is Required</span> -->
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('email').invalid && !demographicsForm.get('email').errors?.required">Please
                            enter a valid Email</span>
                    </div>
                </div>

            </div>
            <h5 class="mb-2">Address Details </h5>
            <hr />
            <div class="patient-address-details">
                <app-address [addressForm]="demographicsForm.get('address')"></app-address>
            </div>
            <!-- <div class="address-form row" [formGroup]="demographicsForm.get('address')">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Street <span class="text-danger">*</span></label>
                        <input type="text" class="form-control input-text" autocorrect="off" autocapitalize="off"
                            spellcheck="off" #searchStreet formControlName="street" id="Street"
                            placeholder="Enter Street">
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('address').get('street').errors?.required && demographicsForm.get('address').get('street').touched">Street
                            is Required</span>
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('address').get('street').invalid && !demographicsForm.get('address').get('street').errors?.required">Please
                            enter a valid Street</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                        <label for="last-name">City <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="City" id="City"
                            formControlName="city" />

                        <span class="text-danger"
                            *ngIf="demographicsForm.get('address').get('city').errors?.required && demographicsForm.get('address').get('city').touched">City
                            should be Required</span>
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('address').get('city').invalid && !demographicsForm.get('address').get('city').errors?.required">Please
                            enter a valid City</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                        <label for="last-name">State <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="state" id="state"
                            formControlName="state" />
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('address').get('state').errors?.required && demographicsForm.get('address').get('state').touched">State
                            should be Required</span>
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('address').get('state').invalid && !demographicsForm.get('address').get('state').errors?.required">Please
                            enter a valid State</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">ZIP Code <span class="text-danger">*</span></label>
                        <input class="form-control input-text" minlength="5" maxlength="5" type="text"
                            placeholder="ZIP Code" id="Zipcode" formControlName="zipCode" />
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('address').get('zipCode').errors?.required && demographicsForm.get('address').get('zipCode').touched">ZIP
                            Code
                            is Required</span>
                        <span class="text-danger"
                            *ngIf="demographicsForm.get('address').get('zipCode').invalid && !demographicsForm.get('address').get('zipCode').errors?.required">Please
                            enter a valid ZIP Code</span>
                    </div>
                </div>
            </div> -->
            
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                        <label for="Insurance-Name">SSN<span class="text-danger">*</span></label>
                        <!-- <input class="form-control input-text" type="text" [hiddenInput]="false" mask="XXX-XXX-XXXX"
                                        placeholder="SSN" id="subscriber_ssn" formControlName="ssn" />
                                    <span
                                        *ngIf="demographicsForm?.get('ssn')?.errors?.required && demographicsForm?.get('ssn')?.touched"
                                        class="text-danger">SSN is Required</span> -->

                        <div class="input-group">
                            <input class="form-control input-text" type="text" [hiddenInput]="ssnShow"
                                mask="XXX-XX-XXXX" placeholder="SSN" id="subscriber_ssn" formControlName="ssn" />
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <i class="fa fa-eye-slash" [ngClass]="{'fa-eye-slash': ssnShow, 'fa-eye': !ssnShow}"
                                        (click)="ssnShowToggle()"></i>
                                </span>
                            </div>
                        </div>
                        <span
                            *ngIf="demographicsForm?.get('ssn')?.errors?.required && demographicsForm?.get('ssn')?.touched"
                            class="text-danger">SSN is Required</span>
                    </div>
                </div>
            </div>
            <h5 class="mb-2">Preferred Language </h5>
            <hr />
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Language for communication </label>
                        <div class="input-group mb-0">

                            <ng-select placeholder="Choose Language" id="preferred-language"
                                 [items]="languageList" [clearable]="false"
                                formControlName="preferredLanguage" bindLabel="desc" bindValue="value"></ng-select>

                            
                        </div>
                    </div>
                </div>



            </div>
        </aw-wizard-step>
        <!-- Insurance Details -->
        <aw-wizard-step stepTitle="Insurance Details" awOptionalStep>
            <div class="row">
                <div class="col-sm-12">
                    <h5>Insurance Details</h5>
                    <hr>
                </div>
            </div>

            <div class="row">
                <!-- check self pay -->
                <div class="form-group col-lg-8 col-md-8 col-sm-12">
                    <ng-container>
                        <label>Coverage Type <span class="text-danger">*</span></label><br />
                        <label class="radio-inline" *ngFor="let item of isSelfPay">
                            <input
                                [ngClass]="item.value ==='healthPass' && ((demographicsForm && !demographicsForm?.get('healthPass')?.value) )?'disabled' : ''"
                                formControlName="selfPay" id="add-appt-insu-coverage-type" type="radio"
                                (change)="toggleInsuranceSection(item.value);" name="selfPay" [value]="item.value"
                                [attr.disabled]="item.value ==='healthPass' && ((demographicsForm && !demographicsForm?.get('healthPass')?.value) )?'true':null">
                            <span class="desc"
                                [ngClass]="item.value ==='healthPass' && ((demographicsForm && !demographicsForm?.get('healthPass')?.value) )?'disabled' : ''">{{item.desc}}</span></label>
                         <br />
                        
                    </ng-container>
                </div>
                </div>
                <div *ngIf="demographicsForm?.get('selfPay')?.value === 'Insurance'">
                    <ng-container>
                        <app-common-insurance-addl  [idType]="'appointmentId'" [userInfoDetailsForm]="demographicsForm" [pagename]="'demographics'" (reloaddemographics)="updateDemographics($event)"  [updateUserdataCard]="PatientDetailsCardReload" [userInfoDetails]="patientDetailsInfo"></app-common-insurance-addl>
                    </ng-container>
                </div>

           <!-- <app-additional-insurances [insuranceForm]="demographicsForm" [isUploadRequired]="true"
                [PatientDetailsCardReload]="PatientDetailsCardReload" [patientDetailsInfo]="patientDetailsInfo">
            </app-additional-insurances>-->
       
        </aw-wizard-step>

        <!-- Emeregency Contact Details -->
        <aw-wizard-step stepTitle="Emergency Contact Details" awOptionalStep>
            <!-- <div class="row">
                <div class="col-sm-12">
                    <h5>Emergency Contact Details</h5>
                    <hr>
                </div>
            </div> -->
            <app-emergency-contact-details [emergencyDetailsForm]="demographicsForm"
                [appointmentDetails]="patientDetailsInfo"></app-emergency-contact-details>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Billing Address Details" awOptionalStep>
            <div class="row">
                <div class="col-sm-12">
                    <h5>Billing Address Details</h5>
                    <hr>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <input type="checkbox" name="checkbox-s-in-1" (change)="isBillingAddressSame()"
                            formControlName="billingAddressSameAsMailing" id="checkbox-s-infill-1">
                        <span for="checkbox-s-infill-1" class="cr p-2"><small> Same as contact
                                address</small></span>
                    </div>
                </div>
            </div> -->
            <div class="row">
                <!-- Billing details -->
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label class="radio-inline" *ngFor="let item of billingAddressDetails">
                        <input formControlName="billingAddressSameAsMailing" (change)="isBillingAddressSame()"
                            type="radio" name="billingAddressSameAsMailing" id="billing-address" [value]="item?.value">
                        <span class="desc">{{item.desc}}</span>
                    </label>
                </div>
            </div>
            <div class="billing-address-details">
                <app-address [addressForm]="demographicsForm.get('billingAddress')"></app-address>
            </div>
        </aw-wizard-step>

        <!-- Preferred Pharmacy -->
        <aw-wizard-step stepTitle="Preferred Pharmacy" awOptionalStep>
            <div class="row">
                <div class="col-sm-12">
                    <h5>Preferred Pharmacy</h5>
                    <hr>
                </div>
            </div>
            <app-preferred-pharmacy [pharmacyFormGroup]="demographicsForm?.get('pharmacy')"></app-preferred-pharmacy>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="ID Documents" awOptionalStep>
            <div class="row">
                <div class="col-sm-12">
                    <h5>ID Documents</h5>
                    <hr>
                </div>
            </div>
            <div class="images-sec">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <app-upload-insurance-driving-docs [hideInsurance]="true"
                            [uploadIdForm]="demographicsForm?.get('idDocument')"
                            [patientDetailsInfo]="patientDetailsInfo"
                            [PatientDetailsCardReload]="PatientDetailsCardReload" [checkUnSavedDataForm]="checkUnSavedDataForm" [sectionName]="'demographics'">
                        </app-upload-insurance-driving-docs>
                    </div>
                </div>

            </div>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Consent Forms" awOptionalStep>
            <div class="row">
                <div class="col-sm-12">
                    <h5>Consent Forms</h5>
                    <hr>
                </div>
            </div>
            <!-- <ng-container *ngIf="patientDetailsInfo?.consentForms && patientDetailsInfo?.consentForms?.length > 0">
                <div class="consent-form">
                    <ng-container *ngIf="patientDetailsInfo?.consentForms">
                        <ng-container *ngFor="let form of patientDetailsInfo?.consentForms">
                            <ul>
                                <li *ngIf="form?.status">
                                    <span class="template-name">{{form?.templateName}}</span> 
                                    <span class="pdf-icon"><i (click)="downloadConsentForm(form, pdfConsentModal)" class="fa fa-file-pdf"></i></span>
                                </li>
                            </ul>
                        </ng-container>
                    </ng-container>
                    
                </div>
            </ng-container>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <ng-container *ngIf="patientDetailsInfo?.consentForms && patientDetailsInfo?.consentForms?.length === 0">
                        <h5 class="head-color">Consent Forms not available</h5>
                    </ng-container>
                </div>
            </div> -->
            <div class="upload-documents">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <p class="text-info">* Note: Supports only PDF, PNG and JPEG files and Files larger than 25MB
                            cannot be uploaded</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <!-- <h5 class="head-color">Driving Licence Card</h5> -->
                        <div class="item-wrapper one">
                            <div class="item">
                                <form data-validation="true" action="#" method="post" enctype="multipart/form-data">
                                    <div class="item-inner">
                                        <div class="item-content">
                                            <div class="image-upload">
                                                <label (change)="selectConsentForm($event)"
                                                    style="cursor: pointer;width: 100%;" for="consent-form-upload">
                                                    <!-- <i class="las la-cloud-upload-alt"></i> -->
                                                    <img class="img-upload" alt="Document Upload"
                                                        src="../../../../../../assets/images/ic_image_upload.svg" />
                                                    <div class="h-100">
                                                        <div class="dplay-tbl">
                                                            <div class="dplay-tbl-cell">
                                                                <h5><b>Choose Consent Form to Upload</b></h5>
                                                                <h6>{{consentUploadedFileName}}</h6>
                                                                <!-- <h6 class="mt-10 mb-70">Or Drop Your Image Here</h6> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--upload-content image/png, image/jpeg, -->
                                                    <input data-required="image" #consentFromUpload
                                                        accept="application/pdf, image/*" type="file"
                                                        name="consent-form-upload" id="consent-form-upload"
                                                        class="image-input" data-traget-resolution="image_resolution"
                                                        value="">
                                                </label>
                                            </div>
                                        </div>
                                        <!--item-content-->
                                    </div>
                                    <!--item-inner-->
                                </form>
                            </div>
                            <!--item-->
                        </div>
                    </div>
                </div>
            </div>
            <app-view-consent-forms [allConsentFormDocs]="allConsentFormDocs" [showDelete]="true"
                [patientDetailsInfo]="patientDetailsInfo" [cardReload]="PatientDetailsCardReload"
                (consentFormDelete)="getCommonConsentFormDocs()"></app-view-consent-forms>
        </aw-wizard-step>

    </aw-wizard>

</div>


<!-- Display the Consent Form PDF -->
<app-ui-modal #pdfConsentModal [hideHeader]="false" [modalCentered]="true" id="pdfConsentModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Consent Form</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="pdfConsentModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="consent-pdf" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="pdfConsentModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>