import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { SendSmsNotifyComponent } from 'src/app/theme/shared/hop-common-components/send-sms-notify/send-sms-notify.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { CardComponent } from '../../components/card/card.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { ApisService } from '../../services/apis.services';

@Component({
  selector: 'app-eob-document-check-update',
  templateUrl: './eob-document-check-update.component.html',
  styleUrls: ['./eob-document-check-update.component.scss']
})
export class EobDocumentCheckUpdateComponent {
  public allAppntHistory = [];
  public loginDetails: any;
  public waitTimeDetails: any;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild('fileUpload') public fileUpload: ElementRef;
  @ViewChild('updateUserInfoData') public updateUserdataCard?: CardComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public loadSpinner: boolean;
  public selectedAppointmentUser: any;
  @ViewChild('files') public files: ElementRef;
  public documentForm: FormGroup;
  @ViewChild('eobCheckDetails') eobCheckDetailsModal: UiModalComponent;
  public allDocumentTypes = ["Lab Results", "Radiology Results", "Referral", "Wellness Forms", "Other"];
  @Output() notifyDocUpload = new EventEmitter<any>();
  @Output() DocIframeUpdate = new EventEmitter<any>();

  @ViewChild('documentUploadNotify') documentUploadNotify: UiModalComponent;
  public addCollectionTypes = [
    { value: 'Encounter', desc: 'Encounter' },
    { value: 'Account', desc: 'Account' },
    { value: 'Facility', desc: 'Facility' }
  ];
  public addDocumentTypes = [];
  public uploadAdditioinalDocsForm: FormGroup;
  public isReadonly: boolean = false;
  @Input() collection: any;
  @Input() documentSelected: any;

  @Input() hideInsurance: boolean;
  @Input() userInfoDetailsForm: FormGroup;
  @Input() uploadIdForm: FormGroup;
  @Input() patientDetailsInfo: any;
  @Input() userdataupdate: boolean;
  @Input() PatientDetailsCardReload: CardComponent;
  public drivingLicenseFile: string;
  public insuranceCardFile: string;
  public getSelectedInsuValue: any;
  @Input() insuranceDetails: FormGroup;
  public documentdetails = [];
  public insuranceCardDetails: FormArray;
  public UploadedFiletype: any;
  public documentsList: any;
  public isFileUploaded: boolean = false;
  public uploadedFileInformation: any;
  @Output() onDocumentUploadChange = new EventEmitter<boolean>();
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  public lateNotifyPatient: any;
  @Output() docUplaod = new EventEmitter<any>();
  @Output() docUplaodClose = new EventEmitter<any>();
  public checkNumberDateForm: FormGroup;
  @Input() dupAppntCostOfCare: any;
  public allClaimProcessorTypes = AppConstantsListConfig?.allClaimProcessorTypes || [];
  @Output() refreshAppntCostOfCare = new EventEmitter<any>();
  errorMessageValidation: any;
  public maxStartDate: Date = this.httpService?.convertDate(new Date());

  constructor(private httpService: HttpService, private router: Router, private formBuilder: FormBuilder, private activateRoute: ActivatedRoute, private apiService: ApisService) {
    this.documentForm = formBuilder?.group({
      'documentType': [null, Validators?.required],
      'documentName': [null, Validators?.required],
      'multipartFile': [null, Validators?.required],
    });

    this.uploadAdditioinalDocsForm = this.formBuilder?.group({
      'documentType': [null, Validators?.required],
      'documentName': [null, Validators?.required],
      'multipartFile': [null, Validators?.required],
      'collectionType': [null],
    });

    this.checkNumberDateForm = this.formBuilder?.group({
      "checkNumber": [null],
      "checkDate": [null, [this.httpService?.yearValidator?.bind(this)]],
      "processorType": [null, Validators?.required],
    });

  }

  ngOnInit(): void {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.uploadAdditioinalDocsForm?.get('collectionType')?.setValue(this.collection);
    if (this.documentSelected !== '') {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue(this.documentSelected);
      this.isReadonly = true;
    }
  }

  ngAfterViewInit(): void {
    //this.resetDocumentsPopup();
  }

  public openUploadDocument(user: any, collection: any, documentSelected: any): void {
    this.resetDocumentsPopup();
    this.checkNumberDateForm?.get('processorType')?.setValue('Primary');
    this.changeProcessorTypeData('Primary');
    this.collection = collection;
    this.documentSelected = documentSelected;
    this.uploadAdditioinalDocsForm?.get('collectionType')?.setValue(this.collection);

    if (this.documentSelected !== '') {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue(this.documentSelected);
      this.isReadonly = true;
    }
    else {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue(null);
      this.isReadonly = false;
    }

    this.selectedAppointmentUser = user;
    // this.getDocumenttypeList();
    this.eobCheckDetailsModal?.show();
  }

  public openNotificationJournalNotesAdd(): void {
    const uploadObj = {
      id: this.collection === 'Encounter' ? this.selectedAppointmentUser?.id : null,
      documentType: this.uploadAdditioinalDocsForm?.value?.documentType
    }
    //console.log(uploadObj);
    this.notifyDocUpload?.emit(uploadObj);
    this.resetDocumentsPopup();
  }

  public resetDocumentsPopup() {
    this.uploadAdditioinalDocsForm?.reset();
    this.checkNumberDateForm?.reset();
    this.uploadAdditioinalDocsForm?.get('multipartFile')?.setValue(null);
    this.fileUpload.nativeElement.value = null;
    this.documentsList = null;
    this.errorMessageValidation = null;
  }

  public getDocumenttypeList() {
    // const collectionType = event.value;
    const collectionType = this.uploadAdditioinalDocsForm?.get('collectionType').value;
    const action = `record/listDocumentTypes?collectionType=${collectionType}`;
    this.addDocumentTypes = [];
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.addDocumentTypes = data?.responseObject;
        if (collectionType == 'Account' && this.documentSelected == '') {
          this.addDocumentTypes?.forEach((formList: any, index: number) => {
            if (formList == 'Image') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Image') || [];
            }
            else if (formList == 'Identification Record') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Identification Record') || [];
            }
            else if (formList == 'Insurance') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Insurance') || [];
            }
            else if (formList == 'Vaccination Record') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Vaccination Record') || [];
            }
          });
        }
        else if (collectionType == 'Encounter' && this.documentSelected == '') {
          this.addDocumentTypes?.forEach((formList: any, index: number) => {

            if (formList == 'Claim Journal') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Claim Journal') || [];
            }
          });
        }
      }
    },
      error => {
        this.notifyText = error?.message || error?.msg || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.loadSpinner = false;
        this.exceptionModal?.modalShow();
      })
  }

  // Delete  Uploaded File
  public deleteSelectedFile(index: number): void {
    this.documentsList = null;
    this.clearFileUpload();
  }

  // on upload get the file
  public uploadFileSelect(event: any): void {
    this.documentsList = null;
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      const fileSize = files.size / 1024 / 1024;
      this.documentsList = files;
      if (fileSize <= 25 && (files.type === 'application/pdf' || files.type === 'image/png' || files.type === 'image/jpeg')) {
        this.uploadAdditioinalDocsForm?.get('documentName')?.setValue(files.name);
        this.uploadAdditioinalDocsForm?.get('multipartFile')?.setValue(files);
        this.UploadedFiletype = files.type;
      }
      else {
        this.uploadAdditioinalDocsForm?.get('multipartFile')?.setValue(null);
        this.fileUpload.nativeElement.value = null;
        this.notifyText = "Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded";
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    }
    // this.processorTypeWithCostofCare();
    this.clearFileUpload();
  }

  // Clear File Upload
  public clearFileUpload(): void {
    this.fileUpload.nativeElement.value = null;
  }

  public getCurrentTimeStamp() {
    const current = new Date();
    current.setHours(0)
    current.setMinutes(0)
    current.setSeconds(0)
    current.setMilliseconds(0)
    const timestamp = current.getTime();
    return timestamp;
  }

  public uploadAdditionalDocuments(documentUpload?: UiModalComponent): void {
    if (this.uploadAdditioinalDocsForm?.invalid) {
      this.uploadAdditioinalDocsForm?.markAllAsTouched();
      return;
    }
    const formData = new FormData();
    const timestamp = this.getCurrentTimeStamp();
    const documentType = this.uploadAdditioinalDocsForm?.get('documentType')?.value;
    const nameValue = this.uploadAdditioinalDocsForm?.get('documentName')?.value;

    const isFile = true;
    const documentName = documentType + "_" + timestamp + "_" + nameValue;
    this.uploadAdditioinalDocsForm?.get('documentName')?.setValue(documentName);
    let actionAppend = '';

    formData.append('idFile', this.uploadAdditioinalDocsForm?.value?.multipartFile);
    actionAppend = `record/addDocument?appointmentId=${this.selectedAppointmentUser?.id}&documentName=${documentName}&documentType=${this.uploadAdditioinalDocsForm?.value?.documentType}&isFile=${isFile}&fileType=${this.UploadedFiletype}`;

    const action = actionAppend;
    this.loadSpinner = true;
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.isFileUploaded = true;
        this.uploadedFileInformation = data?.fileName || null;
        this.onDocumentUploadChange?.emit(true);
        this.updateDocStatus(data?.keyName);
        this.notifyText = data?.msg || data?.message || 'Document uploaded successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        documentUpload.hide();
        // setTimeout(()=> {this.resetDocumentsPopup();}, 500);
        // this.docUplaod?.emit('docUpdated');
      } else {
        this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.updateUserdataCard?.cardRefreshHide();
    },
      (error) => {
        this.resetDocumentsPopup();
        documentUpload.hide();
        this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.updateUserdataCard?.cardRefreshHide();
        this.loadSpinner = false;
      });
  }

  // send Notifications
  public lateNotify(patient: any): void {
    this.lateNotifyPatient = patient || {};
    this.sendNotifyComp?.showNotifyModal(patient);
  }

  public updateDocStatus(docName: any) {
    const action = `changeHealth/updateReviewNotes?appointmentId=${this.selectedAppointmentUser?.id}&notes=&providerName=${this.loginDetails?.firstName + " " + this.loginDetails?.lastName || null}&reportS3Key=${docName}&type=${this.uploadAdditioinalDocsForm?.value?.documentType}&addToProgressNotes=false`;
    //&reportId=${this.userDetails?.id}
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.body?.status === 'SUCCESS') {
        //reviewLabRecord?.hide();
        //  this.notifyText = data?.body?.message || data?.body?.msg || 'Selected Patient Review Notes has been updated successfully.';
        // this.exceptionModal?.modalShow();    
      } else {
        // this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();

      }
    }, (error) => {
      // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      // this.exceptionModal?.modalShow();
      // this.loadSpinner = false;
    })
  }

  // Save the check umber and date details
  public saveCheckNumberDateDetails(checkNumberDateModal: UiModalComponent): void {
    if (this.checkNumberDateForm?.invalid) {
      this.checkNumberDateForm?.markAllAsTouched();
      return;
    }
    const params: any = this.checkNumberDateForm?.value;
    params.appointmentId = this.selectedAppointmentUser?.id;
    this.loadSpinner = true;
    this.apiService?.saveCheckNumberDateDetails(params)?.subscribe((data: any) => {
      if (data?.body?.status === "SUCCESS") {
        this.notifyText = data?.body?.message || 'Check details updated successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.refreshAppntCostOfCare?.emit(true);
      } else {
        this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      checkNumberDateModal?.hide();
      this.checkNumberDateForm?.reset();
      this.loadSpinner = false;
      this.resetDocumentsPopup();
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
      checkNumberDateModal?.hide();
      this.checkNumberDateForm?.reset();
    });
  }

  // change processor type data
  public changeProcessorTypeData(event: any): void {
    this.errorMessageValidation = null;
    const getCostofcareItem: any = this.dupAppntCostOfCare?.costOfCares?.find((payItem: any) => payItem?.claimProcessorType === this.checkNumberDateForm?.get('processorType')?.value) || null;
    if (!getCostofcareItem) {
      this.checkNumberDateForm?.get('processorType')?.setValue(null);
      this.erroMsgClaim('No claim details were found for the selected processor type. Please update the claim details to continue.');
      return;
    }
    const getPayment: any = this.dupAppntCostOfCare?.payments?.find((payItem: any) => payItem?.claimProcessorType === event && payItem?.paymentCategory === 'Payer' && payItem?.description === 'Reimbursements' && (payItem?.amount > 0 || payItem?.amountPaid > 0)) || null;
    if (getPayment) {
      const getCheckDate = getPayment?.claimInfo?.checkDate ? (getPayment?.claimInfo?.checkDate?.includes('T') ? getPayment?.claimInfo?.checkDate.split('T')[0] : getPayment?.claimInfo?.checkDate) : null;
      this.checkNumberDateForm?.get('checkNumber')?.setValue(getPayment?.claimInfo?.checkNumber || null);
      this.checkNumberDateForm?.get('checkDate')?.setValue(getCheckDate || null);
    } else {
      this.checkNumberDateForm?.get('checkNumber')?.setValue(null);
      this.checkNumberDateForm?.get('checkDate')?.setValue(null);
    }
    // to give the document Type value based on Processor Type
    if (event === 'Primary') {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue('EOB');
    }
    if (event === 'Secondary') {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue('EOB2');
    }
    if (event === 'Tertiary') {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue('EOB3');
    }
  }

  // Submit the EOb doc uploaded or Check Number/date
  public submitEobCheckDetails(eobCheckDetails: UiModalComponent): void {
    this.errorMessageValidation = null;
    if (!this.documentsList?.name && !this.checkNumberDateForm?.get('checkNumber')?.value && !this.checkNumberDateForm?.get('checkNumber')?.value) {
      this.erroMsgClaim('Either the EOB document or check details are required to continue.');
    }
    if (this.checkNumberDateForm?.invalid) {
      this.checkNumberDateForm?.markAllAsTouched();
      return;
    }
    // upload selected document
    if (this.documentsList?.name) {
      this.uploadAdditionalDocuments(eobCheckDetails);
    }
    // Update check number and date
    if (this.checkNumberDateForm?.get('checkNumber')?.value && this.checkNumberDateForm?.get('checkNumber')?.value) {
      this.saveCheckNumberDateDetails(eobCheckDetails);
    }
  }

  // Check Number and Date Validation
  public checkNumberDateValidation(controlName: string): void {
    this.errorMessageValidation = null;
    if (!this.checkNumberDateForm?.get('processorType')?.value) {
      this.erroMsgClaim('Please choose processor type.');
      this.checkNumberDateForm?.get(controlName)?.setValue(null);
      return;
    }
    if (this.checkNumberDateForm?.get('checkNumber')?.value || this.checkNumberDateForm?.get('checkDate')?.value) {
      this.checkNumberDateForm?.get('checkNumber')?.setValidators([Validators?.required]);
      this.checkNumberDateForm?.get('checkDate')?.setValidators([Validators?.required]);
    }
    if (!this.checkNumberDateForm?.get('checkNumber')?.value && !this.checkNumberDateForm?.get('checkDate')?.value) {
      this.checkNumberDateForm?.get('checkNumber')?.setValidators(null);
      this.checkNumberDateForm?.get('checkDate')?.setValidators(null);
    }
    this.checkNumberDateForm?.get('checkNumber')?.updateValueAndValidity();
    this.checkNumberDateForm?.get('checkDate')?.updateValueAndValidity();
    // this.processorTypeWithCostofCare();
  }

  // validation of selected processor type
  private processorTypeWithCostofCare(): void {
    const getCostofcareItem: any = this.dupAppntCostOfCare?.costOfCares?.find((payItem: any) => payItem?.claimProcessorType === this.checkNumberDateForm?.get('processorType')?.value) || null;
    if (getCostofcareItem && (this.checkNumberDateForm?.get('checkNumber')?.value || this.checkNumberDateForm?.get('checkDate')?.value)) {
      if (!getCostofcareItem?.allowedAmount || getCostofcareItem?.allowedAmount === 0 || !getCostofcareItem?.payerPayment || getCostofcareItem?.payerPayment === 0) {
        this.erroMsgClaim('No Approved Amount/Payor Payment for slected processor type.');
      }
    }
    if (!getCostofcareItem && (this.checkNumberDateForm?.get('checkNumber')?.value || this.checkNumberDateForm?.get('checkDate')?.value || this.documentsList?.name)) {
      this.erroMsgClaim('There is no claim details for selected processor type, make sure to update the correct details.');
    }
  }

  public erroMsgClaim(msg): void {
    this.errorMessageValidation = msg;
    // setTimeout(() => {
    //   this.errorMessageValidation = null;
    // }, 3000);
  }

}
