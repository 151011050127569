<div class="followup-main-form">
  <h5>Self-Pay services
    <!--<span class="add-new-btn"> <i placement="top" ngbTooltip="Add New"  class="las la-plus-circle"></i></span>-->

    <!--<span class="add-new-btn"> <button class="btn btn-primary right blue-btn"
        type="button" placement="top" ngbTooltip="Add New" (click)="openBillingcodeNew(addBillingcodes)">+</button></span>-->

  </h5>
  <!-- <div class="add-new-btn">
    <button class="btn btn-primary right blue-btn" type="button" placement="top" ngbTooltip="Add New" (click)="showHideForm()">+</button>
</div>-->
  <div class="followup-form">
    <div [formGroup]="billingcodesForm">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Billing Code <span class="text-danger">*</span></label>

            <ng-select placeholder="Select Billing Code" [items]="cptCodeList" formControlName="cptCode"
              bindLabel="cptCode" bindValue="cptCode" (change)="getCPTcodeInfo($event)"></ng-select>

            <span class="text-danger"
              *ngIf="billingcodesForm?.get('cptCode').errors?.required && billingcodesForm?.get('cptCode').touched">
              Please Select Billing Code
            </span>
          </div>
        </div>
        <div class="col-md-7">
          <div class="form-group">
            <label>Description <span class="text-danger">*</span></label>
            <textarea class="form-control" placeholder="" formControlName="cptCodeDescription" readonly rows="10"
              cols="10">
        </textarea>
            <span class="text-danger"
              *ngIf="billingcodesForm?.get('cptCodeDescription').errors?.required && billingcodesForm?.get('cptCodeDescription').touched">
              Please Enter Description
            </span>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label>Price <span class="text-danger">*</span></label>
            <input type="text" class="form-control" placeholder="Enter Price" formControlName="selfpayFee" readonly>
            <input type="hidden" class="form-control" placeholder="Enter Price" formControlName="totalFee" readonly
              value="0">
            <span class="text-danger"
              *ngIf="billingcodesForm?.get('selfpayFee').errors?.required && billingcodesForm?.get('selfpayFee').touched">
              Please Enter Price
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <button type="button" class="btn btn-primary" (click)="saveBillingCodes()">Add</button>
        </div>
      </div>

    </div>

    <!-- <app-card [hidHeader]="true" cardClass="card-datatable" class="new-designed-card"> -->

    <div class="table-responsive new-designed-table" *ngIf="billingList.length > 0">
      <hr>
      <table datatable [dtOptions]="dtOptionsBillingcodes" [dtTrigger]="dtTriggerBillingcodes"
        class="table table-borderless custom-table">

        <thead>
          <tr>
            <th>BILLING CODES</th>
            <th>SERVICE DESCRIPTION</th>
            <th>PRICE</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let billing of billingList; let index = index">
            <td>{{billing?.cptCode}}</td>
            <td class="colWidthTask" placement="bottom"
              title="{{(billing?.cptCodeDescription?.length > 50) ? billing?.cptCodeDescription : ''}}">
              {{billing?.cptCodeDescription}}</td>
            <td>$ {{billing?.selfPayFee}}</td>
            <td>

              <button class="icon-queue-events delete-action-bg" placement="top" ngbTooltip="Delete"
                (click)="deleteSelectedCode(billing)">
                <i class="las la-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="billingList && billingList.length>0">
          <tr>
            <td></td>
            <td></td>
            <td class="total-col"> <span class="total-txt">TOTAL</span> &nbsp;<span class="total-val"> $
                {{totalSelectedFee}} </span></td>
            <td></td>
          </tr>

          <tr *ngIf="pendingFee > 0">
            <td></td>
            <td></td>
            <td class="pending-col"><span class="pending-val">PENDING </span>&nbsp;<span class="total-val"> $
                {{pendingFee}} </span></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- </app-card> -->
  </div>
</div>



<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>


<!--
<app-ui-modal #addBillingcodes [hideHeader]="false" [modalCentered]="true" id="billingcodesForm">
    <div class="app-modal-header">
        <h5 class="modal-title">Add Billing Code</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="closeBillingCodePopup(); addBillingcodes.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" [formGroup]="billingcodesForm">

    <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Billing Code <span class="text-danger">*</span></label>

            <ng-select placeholder="Select Billing Code" [items]="billingCodeList" formControlName="cptCode"
              bindLabel="desc" bindValue="value"></ng-select>

            <span class="text-danger"
              *ngIf="billingcodesForm?.get('cptCode').errors?.required && billingcodesForm?.get('cptCode').touched">
              Please Select Billing Code
            </span>
          </div>
        </div>
	</div>
	<div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Description <span class="text-danger">*</span></label>
            <textarea class="form-control" placeholder="" formControlName="cptCodeDescription">Lorem ipsum dolor sit ... 
        </textarea>
            <span class="text-danger"
              *ngIf="billingcodesForm?.get('cptCodeDescription').errors?.required && billingcodesForm?.get('cptCodeDescription').touched">
              Please Enter Description
            </span>
          </div>
        </div>
	</div>
	<div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Price <span class="text-danger">*</span></label>
            <input type="text" class="form-control" placeholder="Enter Price" formControlName="totalFee">
            <span class="text-danger"
              *ngIf="billingcodesForm?.get('totalFee').errors?.required && billingcodesForm?.get('totalFee').touched">
              Please Enter Price
            </span>
          </div>
        </div>
     </div>
        

    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
            (click)="saveBillingCodes(addBillingcodes);">SUBMIT</button>
    </div>

</app-ui-modal>-->