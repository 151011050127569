import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../../../environments/environment';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';


@Component({
  selector: 'app-schedule-followup-appointment',
  templateUrl: './schedule-followup-appointment.component.html',
  styleUrls: ['./schedule-followup-appointment.component.scss']
})
export class ScheduleFollowupAppointmentComponent implements OnInit {

  @ViewChild('scheduleFollowup') scheduleFollowup: UiModalComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  loadSpinner: boolean;
  public scheduleApptForm: FormGroup;
  public purposeOfVisitList = [];
  public cohortsList = [];
  public loginDetails: any;
  @Input() appointmentId: any;
  @Input() scheduleType: any;
  public selectedUserInfoId: any;
  public dtPatientDetails: any;
  public caregapList = [];
  public cohortSelected: boolean;
  public minAppointmentDate: Date;
  public notifyText: string;
  public isSuccessNotify: boolean;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router) {
    
    this.scheduleApptForm = this.formBuilder.group({
      'patientPrimaryPhone': [null, Validators.required],
      'purposeOfVisit': [null, Validators.required],
      'followupDate': [null, Validators.required],
      //'caregapId': [null],
      'cohortDescription': [null],
      'careGaps': [null],
      'notes': [null, Validators.required]
    });
    this.scheduleApptForm?.get('followupDate')?.setValidators([Validators?.required,this.httpService.yearValidator.bind(this)]);

    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.getPurposeOfVisit(this.loginDetails && this.loginDetails.facilityId);
    this.listCohorts(this.loginDetails && this.loginDetails.facilityId);
    
    this.minAppointmentDate = this.httpService?.convertDate(new Date());
	


  }

  ngOnInit(): void {
  
    }

  ngAfterViewInit(): void {


  }

  public getPurposeOfVisit(facilityId: any): void {
    const action = `waitTime/`;
    this.httpService.makeGetRequest(action, facilityId).subscribe((data: any) => {

      this.purposeOfVisitList = (data && data.purposeOfVisit) || [];

    },
      error => {
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();
      })
  }


  public listCohorts(facilityId: any): void {
    const action = `careGaps/listCohorts/`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      this.cohortsList = (data && data.responseObject) || [];

    },
      error => {
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();
      })
  }

  public getCaregaps(event: any): void {
    this.cohortSelected = false;
    this.scheduleApptForm.get('careGaps')?.setValue(null);

    const cohortDescription = event;
    const action = `careGaps/listCareGaps?cohortDescription=${cohortDescription}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.cohortSelected = true;

      this.caregapList = (data && data.responseObject) || [];

    },
      error => {
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();
      });
  }

  public openModal() {
    this.resetForm();
	this.getAppointmentById();
	
	
    this.scheduleFollowup.show();
  }
  
  public resetForm(){
     this.scheduleApptForm.reset();
  }

  public formateISOdateTime(followupDate): any {
  
    const currentLocalDate = new Date(followupDate)?.toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    const currentTime = new Date().toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit'
    });
    const splitDate = currentLocalDate.split('/');
    const dateConvert = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
    return `${dateConvert}T${currentTime}`;
  }

  public scheduleAppointment() {

    if (this.scheduleApptForm?.invalid) {
      this.scheduleApptForm?.markAllAsTouched();
      return;
    }

    //const patientPrimaryPhone = this.scheduleApptForm?.get('patientPrimaryPhone')?.value;
    const purposeOfVisit = this.scheduleApptForm?.get('purposeOfVisit')?.value;
    const followupDate = this.scheduleApptForm?.get('followupDate')?.value;
    const followupDateTime = this.formateISOdateTime(followupDate);

    const caregapId = null;
    const careGaps = this.scheduleApptForm?.get('careGaps')?.value;
    const notes = this.scheduleApptForm?.get('notes')?.value;
	
	const patientPrimaryPhone = this.dtPatientDetails?.patientPrimaryPhone;

    const dob = this.dtPatientDetails?.dob;
    const age = this.dtPatientDetails?.age;
    const firstName = this.dtPatientDetails?.firstName;
    const gender = this.dtPatientDetails?.gender;
    const lastName = this.dtPatientDetails?.lastName;
    const sourceType = 'Follow Up';
    const apptDto =
    {

      "age": age,
      "appointmentId": this.appointmentId,
      "careGaps": careGaps,
      "caregapId": null,
      "dob": dob,
      "firstName": firstName,
      "followupDate": followupDateTime,
      "gender": gender,
      "lastName": lastName,
      "notes": notes,
      "patientPrimaryPhone": patientPrimaryPhone,
      "purposeOfVisit": purposeOfVisit,
      "sourceType": sourceType,
      "userInfoId": this.selectedUserInfoId
    };

    console.log(apptDto);


    const action = `careGaps/insertCaregapsTransactions`;
    this.httpService?.makeRequestWithAction(action, apptDto)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
	  this.isSuccessNotify = true;
	  if(careGaps !== null && careGaps !== ''){
			this.updateMasterPatientCaregaps(careGaps);
		}
		else{
		this.scheduleApptForm.reset();
        this.scheduleFollowup.hide();
		
		}

        //this.notifyText = data.body.message || '';
		this.notifyText = "Follow-up Reminder has been created successfully.";
        this.exceptionModal.modalShow();
      }
      else {
	  this.isSuccessNotify = false;
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
		this.exceptionModal.modalShow();
      }

      console.log(data);


    }, error => {
	this.isSuccessNotify = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
      this.exceptionModal.modalShow();

    });

  }

  public updateMasterPatientCaregaps(caregaps: any) {
    const caregapDTO = { "caregap": caregaps };


    const action = `careGaps/updateMasterPatientCaregaps?userInfoId=${this.selectedUserInfoId}`;
    this.httpService?.makeRequestWithAction(action, caregapDTO)?.subscribe((data: any) => {
      console.log(data);
      this.scheduleApptForm.reset();
      this.scheduleFollowup.hide();

    }, error => {

    });

  }


  public getAppointmentById(): void {

    if (!this.appointmentId) {
      return;
    }
    let action: any = 'appointment/registration/getAppointmentById?id=';
    let params: any = {
      'id': this.appointmentId
    }
    this.dtPatientDetails = [];
    this.httpService.makeGetRequest(action, params?.id)
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          this.dtPatientDetails = data?.responseObject;
          console.log(this.dtPatientDetails);
          if (this.dtPatientDetails) {

            this.selectedUserInfoId = this.dtPatientDetails?.userInfoId;
			console.log(this.dtPatientDetails?.patientPrimaryPhone);
			this.scheduleApptForm?.get('patientPrimaryPhone')?.setValue(this.dtPatientDetails?.patientPrimaryPhone);

          }

        }

      }, error => {

      });
  }

  // Input Appnt date validation 
  public inputAppntDateValidation($event: any, scheduleApptForm: FormGroup, contrlName?: string): void {
    this.httpService?.validateAppointmentDate($event, scheduleApptForm, contrlName);
  }

  // Change appnt date with validation
  public changeAppntDateWithValidation(scheduleApptForm?: FormGroup, controlName?: string): void {
    const currentYear = new Date().getFullYear()
    const enteredDate = new Date(this.scheduleApptForm?.get('followupDate')?.value);
    if (enteredDate?.getFullYear() <= 1900 || enteredDate?.getFullYear() > currentYear) {
      return;
    }
   
    if (this.httpService?.changeAppntDateWithValValidation(this.scheduleApptForm?.get('followupDate')?.value)) {
      this.scheduleApptForm?.get('followupDate')?.setValue(null);
      return;
    }
   
   
  }

}


