<app-breadcrumb-custom [navigationList]="navigationList" *ngIf="!isccmPrograms"></app-breadcrumb-custom>
<div class="date-search">
    <div class="row mb-4">
        <div class="col-lg-2 col-md-2 col-sm-6"></div>
        <div class="col-lg-2 col-md-2 col-sm-6"></div>
        <div class="col-lg-2 col-md-2 col-sm-6"></div>
        <div class="col-lg-2 col-md-2 col-sm-6"></div>
        <div class="col-lg-2 col-md-2 col-sm-6"></div>
        <div class="col-lg-2 col-md-2 col-sm-6 date-search-btn"></div>
    </div>
</div>
<div class="{{showFilter?'col-collapse':'col-expand'}}">
    <div class="row">

        <div class="col-md-12 pull-right">
            <button placement="right" title="Filters" id="appList_filter"
                class="flow-board-main-actions new-appointment-action {{showFilter?'btn-active':''}}"
                (click)="enableFilter()">
                <i class="material-symbols-outlined">
                    filter_alt
                </i>
            </button>
        </div>
    </div>

    <div class="row">
        <app-card #futureAppointments class="future-appnts new-designed-card" cardTitle="{{cardTitleVal}}"
            [options]="false">
            <div class="table-responsive new-designed-table">
                <table datatable [dtOptions]="dtOptionsFutureAppnt" [dtTrigger]="dtTriggerFutureAppnt"
                    class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Patient Name</th>
                            <th>Gender</th>
                            <th>Appointment Time</th>
                            <th>Purpose</th>
                            <th>Service Provider</th>
                            <th>Type of service</th>

                            <th>Status</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="appointment-tr-queue" *ngFor="let appointment of allFutureAppointments; let i=index">
                            <td>
                                <ng-container>
                                    <span>{{appointment?.lastName | titlecase}}, {{appointment?.firstName | titlecase}}
                              {{appointment?.middleName | titlecase}}</span>
                                </ng-container>
                                <div class="gender-patient-type" style="font-size: 12px;">

                                    <span><b>{{appointment?.age || '--'}}</b></span> &nbsp;&nbsp;
                                    <span><b>{{ appointment?.newPatient ? 'New' : 'Established' }}</b></span>
                                </div>
                            </td>
                            <td><span>{{appointment?.gender || '--'}}</span></td>
                            <td>{{ getAppointmentTime(appointment?.appointment?.dateTime) }}</td>
                            <td>
                                {{appointment?.appointment?.purposeOfVisit || 'N/A'}}
                                <!-- to show Chief Complaints -->
                                <div class="cc-details chief-complaints-drop dropdown-actions"
                                    *ngIf="appointment?.chiefComplaints && appointment?.chiefComplaints?.length > 0"
                                    style="font-size: 12px;width: 100px;">
                                    <span style="cursor: pointer; color: #5CA6DB;"><b>Chief Complaints</b></span>
                                    <div class="chief-complaints-hover-content"
                                        [ngStyle]="{'height': getChiefComplaintsSectionHeight(appointment?.chiefComplaints)}">
                                        <ul class="chief-complaints-list" style="white-space: normal;">
                                            <li *ngFor="let message of appointment?.chiefComplaints"
                                                [innerHTML]="message"></li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <td>{{ doctorNameWithNpi(appointment?.appointment) | titlecase }}</td>
                            <td>{{appointment?.appointment?.typeOfService || 'N/A'}}</td>

                            <td class="appointment-status-clrs">
                                <span class="status-clr"
                                    [class]="getStatus(appointment?.appointmentStatus?.currentStatus, appointment)">{{getStatus(appointment?.appointmentStatus?.currentStatus,
                                    appointment) || '--'}}</span>
                            </td>
                            <td class="text-center">

                                <div class="dropdown-actions" *ngIf="isMinRequired">
                                    <button
                                        class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}"
                                        id="claimActions_btn" (click)="openUniversalActions($event, i)">...</button>
                                    <div class="dropdown-content-actions"
                                        *ngIf="openActions && selectedActionRow === i">
                                        <a href="javascript:" id="appList_notify" [class.a-disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                            (click)="lateNotify(appointment);closeUniversalActions();">
                                            <i _ngcontent-xdg-c260="" class="lab la-telegram"></i>Notify</a>
                                        <a href="javascript:" id="appList_task"
                                            (click)="openTaskModal(appointment);closeUniversalActions();"><i
                                                class="las la-tasks"></i>
                                            Tasks</a>
                                        <a href="javascript:" id="appList_chart"
                                            [class.a-disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                                            (click)="modifyUserData(appointment);closeUniversalActions();">
                                            <i class="las la-user-circle"></i> Chart</a>

                                        <a href="javascript:" id="appList_edit"
                                            [class.a-disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                                            (click)="editAppointment(appointment);closeUniversalActions();"><i
                                                class="las la-pencil-alt"></i>Edit</a>

                                        <a href="javascript:" id="appList_eligibility"
                                            [class.a-disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                            [class.checkedEligibility]="appointment?.eligibilityFlag"
                                            (click)="checkEligibility(appointment);closeUniversalActions();"><i
                                                class="las la-check-circle"></i>Eligibility</a>

                                        <a href="javascript:" id="appList_reschedule"
                                            [class.a-disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                            (click)="reScheduleAppointment(appointment);closeUniversalActions();"><i
                                                class="las la-redo-alt"></i>Re-Schedule</a>

                                        <a href="javascript:" id="appList_cancel"
                                            [class.a-disabled]="appointment?.appointmentStatus?.checkOut || appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                            (click)="cancelAppointment(appointment, false);closeUniversalActions();"><i
                                                class="las la-times"></i>Cancel</a>

                                        <a href="javascript:" id="appList_delete"
                                            [class.a-disabled]="appointment?.appointmentStatus?.checkOut || appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                            (click)="cancelAppointment(appointment, true);closeUniversalActions();"><i
                                                class="lar la-trash-alt"></i>Delete</a>

                                    </div>
                                </div>
                                <div class="dropdown-actions" *ngIf="isMaxRequired">
                                    <button
                                        class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}"
                                        id="claimActions_btn" (click)="openUniversalActions($event, i)">...</button>
                                    <div class="dropdown-content-actions past"
                                        *ngIf="openActions && selectedActionRow === i">

                                        <a href="javascript:" id="appList_view"
                                            (click)="viewAppointment(appointment?.id);closeUniversalActions();"><i
                                                class="material-symbols-outlined">visibility</i> View</a>
                                        <a href="javascript:" id="appList_notify1" [class.a-disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                            (click)="lateNotify(appointment);closeUniversalActions();">
                                            <i _ngcontent-xdg-c260="" class="lab la-telegram"></i>Notify</a>
                                            <a href="javascript:" id="appList_chart1"
                                            [class.a-disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                                            (click)="modifyUserData(appointment);closeUniversalActions();">
                                            <i class="las la-user-circle"></i> Chart</a>
                                            <a href="javascript:" id="appList_edit1"
                                            [class.a-disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointmentStatus?.currentStatus ==='NOTAVAILABLE'"
                                            (click)="editAppointmentPastAppointment(appointment);closeUniversalActions();"><i
                                                class="las la-pencil-alt"></i>Edit</a>

                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="summary-card" style="margin-top: 20px; padding-bottom: 50px;" *ngIf="isccmPrograms">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="Summary" style="padding-top: 0px;">Summary</h4>
                                <div class="vl"></div>
                            </div>
                            <div class="col-md-10">
                                <div class="Count">{{completedAppointmentsCount}}</div>
                                <div class="summary-name">Completed </div>
            
                            </div>
                           
            
                        </div>
                    </div>
                </div>
            </div>

        </app-card>
    </div>
</div>
<div class="filters-col">
    <h5>Filters <span class="close pull-right" (click)="enableFilter()">&times;</span></h5>
    
    
    <!--<div [formGroup]="futureAppointmentForm" *ngIf="isccmPrograms">
        <label>Programm Type <span class="required">*</span></label><br />
        <ng-select placeholder="Programm Type" id="appList_appType"  (change)="changeProgrammtype($event,notifyTextModal)"   [items]="programmTypes" [readonly]="enableDisableSubmitFilter" formControlName="programmType" bindLabel="name"
            bindValue="value"  [clearable]="false"></ng-select>
        <label class="required error"
            *ngIf="futureAppointmentForm?.get('programmType')?.touched && futureAppointmentForm?.get('programmType')?.errors?.required">Programm
            Type is required</label>
    </div>-->

    <div [formGroup]="futureAppointmentForm">
        <label>Appointment Type <span class="required">*</span></label><br />
        <ng-select placeholder="Appointment Type" id="appList_appType" [items]="appointmentType" [readonly]="enableDisableSubmitFilter" formControlName="apt_type" bindLabel="name"
            bindValue="value" (change)="changeApttype($event,notifyTextModal)" [clearable]="false"></ng-select>
        <label class="required error"
            *ngIf="futureAppointmentForm?.get('apt_type')?.touched && futureAppointmentForm?.get('apt_type')?.errors?.required">Appointment
            Type is required</label>
    </div>
    <div [formGroup]="futureAppointmentForm">
        <label>Start Date <span class="required">*</span></label><br />
        <input type="date"  [min]="isMinRequired ? minAppointmentDate : null"
            [max]="isMaxRequired ? maxStartDate:null" 
            
            (change)="changeAppntDateWithValidation(futureAppointmentForm, 'facilityStartDate');"
                                (input)="inputAppntDateValidation($event, futureAppointmentForm, 'facilityStartDate')"
            formControlName="facilityStartDate" name="datemin" class="form-control input-text"
            id="appList_sdate" placeholder="Future Appointment Date">
        <label class="required error"
            *ngIf="futureAppointmentForm?.get('facilityStartDate')?.touched && futureAppointmentForm?.get('facilityStartDate')?.errors?.required">Start
            Date is required</label>
    </div>
    <div [formGroup]="futureAppointmentForm">
        <label>End Date <span class="required">*</span></label><br />
        <input type="date" [min]="isMinRequired ?minEndDate: null" [max]="isMaxRequired? maxEndDate:null"
                         
             (change)="changeAppntDateWithValidation(futureAppointmentForm, 'facilityEndDate');"
             (input)="inputAppntDateValidation($event, futureAppointmentForm, 'facilityEndDate')"

             formControlName="facilityEndDate" name="datemin"
            class="form-control input-text" id="appList_edate" placeholder="Future Appointment Date">
        <label class="required error"
            *ngIf="futureAppointmentForm?.get('facilityEndDate')?.touched && futureAppointmentForm?.get('facilityEndDate')?.errors?.required">End
            Date is required.</label>
    </div>
    <div [formGroup]="futureAppointmentForm" *ngIf="!isccmPrograms">
        <label class="form-label">Purpose of Visit</label>
        <ng-select placeholder="Choose Purpose of Visit" id="appList_pov" [items]="addPatientPurposeVisit"
            formControlName="purpose_of_visit"></ng-select>

    </div>
    <div [formGroup]="futureAppointmentForm" *ngIf="!isccmPrograms">
        <label class="form-label">Status</label>
        <ng-select placeholder="Choose Status" id="appList_status" [items]="appointmentStatusLiust" formControlName="apt_status"
            bindLabel="name" bindValue="value"></ng-select>

    </div>
    <div class="form-group text-center">
        <button class="btn btn-primary" id="appList_submit" [disabled]="enableDisableSubmitFilter" (click)="filterAppointments(notifyTextModal);">Submit</button>

    </div>
</div>

<!-- Cancel Appointment compoenent -->
<app-cancel-appointment (onCancelAppntChange)="refresh()"></app-cancel-appointment>

<!-- re-schedule common appointment -->
<app-re-schedule-appointment (onReScheduleUpdateChange)="refresh()"
    [exceptionModal]="exceptionModal"></app-re-schedule-appointment>

<!-- notify popup -->
<app-ui-modal #notifyTextModal [hideHeader]="false" id="notifyTextModal">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">{{notifyText}}</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="notifyTextModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">

                <h5 *ngIf="isSuccessNotify" class="modal-title text-success text-center" style="font-size: 50px;"><i
                        class="fa fa-check-circle" aria-hidden="true"></i>
                </h5>
                <h5 *ngIf="!isSuccessNotify" class="modal-title text-danger text-center" style="font-size: 40px;">
                    <i class="las la-minus-circle"></i>
                </h5>


            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">

                <h5 class="modal-title wordAdjustment">{{notifyText}}</h5>

            </div>
        </div>

    </div>
    <!-- <div class="app-modal-body add-app-body" [formGroup]="rescheduleForm">
      <div class="form-group">
       {{}}  
      </div>
    </div> -->
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="appList_ok"
            (click)="notifyTextModal.hide()">Ok</button>
    </div>
</app-ui-modal>


<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Eligibility check -->
<app-eligibility-check [eligibilityDoctorDetails]="waitTimeDetails?.eligibilityDetails"
    (hideCardReload)="futureAppointments?.cardRefreshHide()"></app-eligibility-check>

<!-- Late Notify PopUp -->
<app-send-sms-notify [noDelay]="true" [pageType]="isFuturePastAppts"></app-send-sms-notify>

<app-new-task [idType]="'appointmentId'"></app-new-task>

<app-addendum-notes-chart [addendamData]="allCareFlowData?.addendum"></app-addendum-notes-chart>