import { formatDate } from '@angular/common';
import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Hl7DetailsComponent } from 'src/app/theme/shared/hop-common-components/hl7-details/hl7-details.component';
import { LabRadOrderDetailsModalComponent } from 'src/app/theme/shared/hop-common-components/lab-rad-order-details-modal/lab-rad-order-details-modal.component';
import { LabRadResultsModalComponent } from 'src/app/theme/shared/hop-common-components/lab-rad-results-modal/lab-rad-results-modal.component';
import { PaymentComponent } from 'src/app/theme/shared/hop-common-components/payment/payment.component';
import { PaymentsCommonNewComponent } from 'src/app/theme/shared/hop-common-components/payments-common-new/payments-common-new.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { AccountBalanceComponent } from '../account-balance/account-balance.component';
import { DocumentReviewComponent } from '../document-review/document-review.component';
import { PaymentsRefactorComponent } from '../payments-refactor/payments-refactor.component';
import { ApisService } from '../../services/apis.services';


@Component({
  selector: 'app-accordian-past-visit-details-wizard',
  templateUrl: './accordian-past-visit-details-wizard.component.html',
  styleUrls: ['./accordian-past-visit-details-wizard.component.scss']
})
export class AccordianPastVisitDetailsWizardComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  public getRouteParam: Subscription;
  @ViewChild('accordianVisitCard') private accordianVisitCard: CardComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) public exceptionModal: ExceptionModalComponent;
  @Input() appointmentId: string;
  public appointmentInfoDetails: any;

  public navigationList: Array<any> = []

  public othersReviewSystems = [];
  public chiefComplaints = [];
  public assessmentValues = [];
  public billingValues = [];
  public allBehaviourScreening = [];
  public hpiForm: any;
  public vitalsForm: any;
  public examValues = [];
  public allRxOrderDetails = [];
  public alllabRadOrderDetails = [];
  treatmentFlowAddNotes: any;
  public treatmentFlowData: any;
  public allLabResultsDocs = [];
  public allRadiologyResultsDocs = [];
  public allOtherDocs = [];
  public allReferralDocs = [];
  subscriberDetails: any;
  public allInternalLabOrders: any[] = [];

  // plan order table 
  public dtOptionsLabOrders: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public dtTriggerLabOrders: Subject<any> = new Subject<any>();
  public legacyLabOrderResults = [];

  public dtOptionsRxOrders: DataTables.Settings = {};
  public dtTriggerRxOrders: Subject<any> = new Subject<any>();
  public loginDetails: any;
  @ViewChild(PaymentsRefactorComponent) private payComp: PaymentsRefactorComponent;
  public paymentConfigDetails: any;
  public appointmentCostOfCare: FormGroup;
  appointmentCostOfCareDetails: any;
  wellnessFormDocs: any;
  public defaultStepIndex = 0;
  public allergiesFromVaccine: any[] = [];
  public otherAddedForms: any[] = [];
  public checkCommonIdDocument: any;
  public allConsentFormDocs: any[] = [];
  public allAdditonalDocuments: any[] = [];
  @ViewChild(WizardComponent) public pastVisitDetailsWizard?: WizardComponent;
  allInternalVaccineDetails: any[];
  allCareFlowData: any;

  @ViewChild(LabRadResultsModalComponent) labRadResultsComp: LabRadResultsModalComponent;
  @ViewChild(LabRadOrderDetailsModalComponent) labRadOrderDetailsComp: LabRadOrderDetailsModalComponent;
  @ViewChild(Hl7DetailsComponent) public hl7DetailsComp: Hl7DetailsComponent;
  followUpAppointmentDetails: any;
  @Output() cssRefresh = new EventEmitter<boolean>();
  @Input() showExpand: boolean;
  isFollowUpFinalCheckLIstView: boolean;
  public saveComplete: boolean = true;
  allInHouseProcedures: any;
  @Input() isCareFlowView: boolean;
  @ViewChild(DocumentReviewComponent) documentReview: DocumentReviewComponent;
  public appointmentIdForReview: any;
  public pastVisitMedications: any;
  public codeMissingAssessments: any[] = [];
  public appointmentPiadAmount = 0;
  public careplandefaultShow: boolean = true;
  public careplanExpaned: boolean = true;
  @Output() closeMoreInfoDetails = new EventEmitter<boolean>();

  @ViewChild(AccountBalanceComponent) public accBalance: AccountBalanceComponent;

  constructor(private activateRoute: ActivatedRoute, private httpService: HttpService, private formBuilder: FormBuilder, private router: Router, private apiService: ApisService) {
    this.appointmentCostOfCare = this.formBuilder?.group({
      "appointmentId": [this.appointmentId || null],
      "discount": [null, Validators?.required],
      "nonPayment": [null, Validators?.required],
      "patientResponsibility": [null, Validators?.required],
      "reimburseAmount": [null, Validators?.required],
      "credits": [null, Validators?.required],
      "approvedAmount": [null, Validators?.required],
      "coverageCost": [null, Validators?.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.appointmentId) {
        this.callQueryParams();
        this.getAppointmentById(this.appointmentId);
        this.getAllAdditionalDocs(this.appointmentId);
        // this.getAppointmentCost();
        this.appointmentCostOfCare?.get('appointmentId')?.setValue(this.appointmentId);
      }
    }
  }

  callQueryParams(): void {
    setTimeout(() => {
      this.activateRoute?.queryParams?.subscribe(
        (params: Params) => {
          this.isFollowUpFinalCheckLIstView = false;
          if (params && params['payments'] === 'yes') {
            this.defaultStepIndex = 17;
            this.pastVisitDetailsWizard?.goToStep(17);
          }
          if (params && params['labresults'] === 'yes') {
            this.defaultStepIndex = 18;
            this.pastVisitDetailsWizard?.goToStep(18);
          }
          if (params && params['followup'] === 'yes') {
            this.isFollowUpFinalCheckLIstView = true;
            setTimeout(() => {
              this.pastVisitDetailsWizard?.goToStep(21);
            }, 500)
          }
        });
    }, 500);
  }

  ngOnInit(): void {
    this.dtOptionsLabOrders = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[1, "desc"]],
      columnDefs: [{ targets: [1], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };

    this.dtOptionsRxOrders = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[1, "desc"]],
      columnDefs: [{ targets: [1], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.getPaymentConfig();
    this.changeFormPaymentChanges();
    this.getAmountPaidForAppointment();

    this.careplandefaultShow = false;
    this.careplanExpaned = false;

  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  ngAfterViewInit(): void {
    if (this.isCareFlowView) {
      this.labRadOrderDetails();
      this.getInhouseProceduresByAppointment();
      this.rxOrderDetails();
      //this.labRadOrderDetails();
      /* const mainIdEle = document?.getElementById('pastVisitsDetailsWizard') as HTMLElement;
       const wizardStepsEle = mainIdEle?.getElementsByTagName('aw-wizard-step') as HTMLCollection;
       if(wizardStepsEle && wizardStepsEle?.length > 0) {
         Array?.from(wizardStepsEle)?.forEach((element: HTMLElement) => {
           if(element) {
             element?.removeAttribute('hidden');
           }
         });
       }*/
    }
  }

  public getInhouseProceduresByAppointment(): void {
    const action = `treatmentFlow/getInhouseProcedures?appointmentId=${this.appointmentId}`;
    // this.accordianVisitCard?.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject) {
          this.allInHouseProcedures = data?.responseObject || [];
        } else {
          // this.notifyText = data?.message || AppConstantsListConfig.uiErrorException;
          // this.exceptionModal?.modalShow();
        }
      } else {
        // this.notifyText = data?.message || AppConstantsListConfig.uiErrorException;
        // this.exceptionModal?.modalShow();
      }
      // this.accordianVisitCard?.cardRefreshHide();
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        //this.accordianVisitCard?.cardRefreshHide();
      })
  };


  // Read all Care Flow Data
  public getAllModulesSavedData(): void {
    const action = `careFlow/readCareFlowData?facilityId=${this.appointmentInfoDetails?.facilityId}&appointmentId=${this.appointmentId}&userInfoId=${this.appointmentInfoDetails?.userInfoId}`;
    this.accordianVisitCard.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      this.allCareFlowData = data?.responseObject;
      this.accordianVisitCard?.cardRefreshHide();
      this.getBillingForInHouseLabs();
      this.getAssessmentCodesDesc();
      // Legacy Medical Record
      let allLegacyLabResults: any = [];
      if (this.appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record') {

        this.assessmentValues = this.allCareFlowData?.assessment?.icdCodes || [];

        this.allCareFlowData?.labRequisition?.order?.forEach(element => {
          element?.reports?.forEach(reportMsg => {
            if (reportMsg?.hl7Message) {
              let replace = reportMsg?.hl7Message?.replace('"[', '');
              replace = replace?.replace(']"', '');
              const splitArray = replace?.split('||,');
              splitArray?.forEach(splitItem => {
                splitItem = splitItem?.replace('[', '');
                splitItem = splitItem?.replace(']', '');
                const itemSplit = splitItem?.split(', ');
                const obj = {
                  lastvisitdate: itemSplit && (itemSplit[0] === ' null' || itemSplit[0] === 'null') ? null : (itemSplit[0] || null),
                  orderName: itemSplit && itemSplit[1] || null,
                  labAttribute: itemSplit && itemSplit[2] || null,
                  labAttributeValue: itemSplit && itemSplit[3] || null,
                }
                allLegacyLabResults = [...allLegacyLabResults, ...[obj]];
              })
            }
          })
        });

      };
      this.legacyLabOrderResults = allLegacyLabResults || [];
    },
      (error) => {
        this.accordianVisitCard?.cardRefreshHide();
      })
  }

  // just validate the all data got saved or not
  public checkIfSavedSections(moduleData?: any): boolean {
    if (moduleData && moduleData?.length > 0) {
      const allSections = [];
      moduleData?.forEach((checkItem: any) => {
        if (checkItem && (checkItem?.sections?.length > 0 || checkItem?.otherNotes)) {
          allSections?.push(checkItem);
        }
      });
      if (allSections && allSections?.length === 0) {
        return false;
      }
    };
    return true;
  }

  // get the cpt billing codes based on InHouse labs
  public getBillingForInHouseLabs(): void {
    const action = `treatmentFlow/getCPTCodesForAppointment?appointmentId=${this.appointmentId}`;
    this.accordianVisitCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {

      const codeDescConvert = data?.map(item => {
        return {
          cptCode: item?.cptCode,
          codeDescription: item?.cptCodeDescription || item?.codeDescription
        }
      }) || [];

      if (this.allCareFlowData?.carePlan && this.allCareFlowData?.carePlan?.length > 0) {
        const billingData = this.allCareFlowData?.carePlan?.find((secItem: any) => secItem?.resourceType === 'Billing Codes' || secItem?.resourceType?.includes('Billing'));
        if (!billingData?.sections && billingData?.sections?.length > 0) {
          billingData?.sections?.forEach((secAnsItem: any) => {
            if (secAnsItem?.answers && secAnsItem?.answers?.length > 0) {
              secAnsItem?.answers?.forEach((ansActVal: any) => {
                let allCodes = [...ansActVal?.actualValue, codeDescConvert];
                // let allCodes = codeDescConvert || [];
                allCodes = [...new Map(allCodes?.map(item => [(item?.cptCode), item])).values()];
                ansActVal.actualValue = allCodes || [];
              });
            };
          });
        }
      }
      this.accordianVisitCard?.cardRefreshHide();
    },
      (error) => {
        this.accordianVisitCard?.cardRefreshHide();
      })
  }

  // get all the past visits medicatons and allergies with userInfoId
  private getAllMedicationDetails(): void {
    const action = `common/getMedicationDetails?userInfoId=${this.appointmentInfoDetails?.userInfoId}&filterCriterion=Active&current=true`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.pastVisitMedications = data?.orderRxes || [];
      this.pastVisitMedications?.sort((a: any, b: any) => {
        const bDate: any = new Date(b?.creation_date);
        const aDate: any = new Date(a?.creation_date);
        return bDate - aDate;
      });
    }, error => {

    });
  }

  public getAppointmentById(appointmentId: string): void {
    let action: any = 'appointment/registration/getAppointmentById?id=';
    let params: any = {
      'id': appointmentId
    }
    this.accordianVisitCard?.cardRefreshShow();
    this.httpService.makeGetRequest(action, params?.id)
      .subscribe((data: any) => {
        this.accordianVisitCard?.cardRefreshHide();
        if (data?.status == 'SUCCESS') {


          this.followUpAppointmentDetails = JSON.parse(JSON.stringify(data?.responseObject));
          this.appointmentInfoDetails = data?.responseObject;
          this.getAllModulesSavedData();
          this.getAllMedicationDetails();
          if (!data?.responseObject) {
            this.notifyText = 'Appointment details not available';
            this.isSuccessNotify = false;
            this.exceptionModal?.modalShow();
            return;
          }
          this.navigationList = [
            {
              breadcrumbs: true,
              title: "Patient Chart",
              type: "item",
              url: `hospital/user-data-update/${this.appointmentInfoDetails?.userInfoId}`
            },
            {
              breadcrumbs: true,
              title: "Encounter Details",
              type: "item",
              url: null
            }
          ]

          this.getCommonIdDocument();
          this.getCommonConsentFormDocs();
          this.getAllInternalVaccinationDetails();
          // this.downloadDlCard(this.appointmentInfoDetails?.idDocument?.identityImageId);
          // this.appointmentInfoDetails.dob = formatDate(this.appointmentInfoDetails?.dob, 'MM/dd/yyyy', 'en-US');
          this.appointmentInfoDetails.dob = this.httpService?.formatDobTimeZoneWithDisplay(this.appointmentInfoDetails?.dob);
          if (this.appointmentInfoDetails?.insuranceDetails?.length > 0 && this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth) {
            this.appointmentInfoDetails.insuranceDetails[0].subscriberdateOfBirth = this.httpService?.formatDobTimeZoneWithDisplay(this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth);
            this.subscriberDetails = this.appointmentInfoDetails?.insuranceDetails[0] || null;
          }

          if (this.appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record') {
            if (this.defaultStepIndex === 17) {
              this.defaultStepIndex = 3;
            }
          }
          let buttonElement = document.getElementById('careplan_link') as HTMLElement;

          let careplan_info = document.getElementById('careplan_info') as HTMLElement;

          careplan_info.scrollIntoView();

          if (!this.careplandefaultShow) {
            buttonElement.click();
            this.careplandefaultShow = true;
            this.careplanExpaned = true;
          }
          //let buttonElement = document.getElementById('careplan_link') as HTMLElement;
          //buttonElement.click();

        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
      }, error => {
        this.accordianVisitCard?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      });
  }

  // get Internal LAB Order details
  public labRadOrderDetails(): void {
    const action = `changeHealth/getLabRadbyAppointment?appointmentId=${this.appointmentId}`;
    //this.accordianVisitCard?.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const labOrder = data?.responseObject || [];
        const internalLabORders = JSON.parse(JSON.stringify(labOrder || []));
        const allInternalOrders = [];
        internalLabORders?.forEach(element => {
          if (element?.location === 'Internal' && element?.orderType === 'LAB') {
            allInternalOrders?.push(element?.internalLabdetails);
          }
        });
        this.allInternalLabOrders = allInternalOrders || [];
        const externalLabOrders = JSON.parse(JSON.stringify(labOrder || []));
        this.alllabRadOrderDetails = externalLabOrders?.filter((exOrder: any) => exOrder?.location === 'External') || [];
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;;
        this.isSuccessNotify = false;
        // this.exceptionModal.modalShow();
      }
      //this.accordianVisitCard?.cardRefreshHide();
      this.dtTriggerLabOrders.next();
    },
      error => {
        // this.accordianVisitCard?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
        this.dtTriggerLabOrders.next();
      });
  }

  //  to read the all vaccinations from other caollection
  public async getAllInternalVaccinationDetails(): Promise<any> {
    this.allergiesFromVaccine = [];
    const action = `vaccinationHistory/readVaccinationDetails?appointmentId=${this.appointmentId}&userInfoId=${this.appointmentInfoDetails?.userInfoId}`;
    //this.accordianVisitCard?.cardRefreshShow();
    try {
      const allVaccineDetails: any = await this.httpService?.makeGetRequest(action, '')?.toPromise();
      //this.accordianVisitCard?.cardRefreshHide();
      let vaccinations = [];
      allVaccineDetails?.responseObject?.forEach((element: any) => {
        vaccinations?.push(element?.vaccinationDetails);
      });
      this.allInternalVaccineDetails = vaccinations || [];
      this.allergiesFromVaccine = (allVaccineDetails?.responseObject && allVaccineDetails?.responseObject?.length > 0) ? allVaccineDetails?.responseObject[0]?.vaccineAllergies : [];
    } catch {
      this.allInternalVaccineDetails = [];
      this.allergiesFromVaccine = [];
      //this.accordianVisitCard?.cardRefreshHide();
    }
  }

  // Navigate to Chart Encounters
  public navigatePatientJournal(): void {
    this.router.navigate([], {
      queryParams: {
        'patientJournalNotes': null,
        'documentsUpload': null,
        'labresults': null,
      },
      queryParamsHandling: 'merge'
    });
    setTimeout(() => {
      this.router?.navigate([`/hospital/user-data-update/${this.appointmentInfoDetails?.userInfoId}`], { queryParams: { patientJournalNotes: 'yes' } });
    }, 500)
  }

  //  to get all the additonal documents
  public getAllAdditionalDocs(appointmentId: string): void {
    // let action: any = 'appointment/registration/getAllAdditionalDocs?appointmentId=';
    const action = `record/listDocuments?appointmentId=${appointmentId}`;
    this.accordianVisitCard?.cardRefreshShow();
    this.httpService.makeGetRequest(action, '')
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          const responseObj = data?.responseObject || [];
          this.allAdditonalDocuments = responseObj?.filter((listItem: any) => listItem?.documentType !== 'Consent Form') || [];
        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.accordianVisitCard?.cardRefreshHide();
      }, error => {
        this.accordianVisitCard?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      });
  }

  public getAppointmentTime(date: any): string {
    return this.httpService?.getAppointmentTime(date);
  }

  // get RX Order details
  public rxOrderDetails(): void {
    const action = `changeHealth/rxOrders/${this.appointmentId}`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const rdOrder = data?.responseObject?.rxOrder || null;
        this.allRxOrderDetails = rdOrder || [];
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
      }
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
      });
  }

  // check object and object keys have values or not
  public checkObjValues(obj: any): boolean {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "")
        return true;
    }
    return false;
  }

  public checkObjBehvaiourScreen(behavValue: any): boolean {
    for (const objeItem of behavValue) {
      if (objeItem?.value || objeItem?.value === 0) {
        return true;
      }
    }
    return false;
  }

  public behaviorScreeningValues(): boolean {
    let checkIfValue: boolean = false;
    for (const key in this.allBehaviourScreening) {
      const keyValue = this.allBehaviourScreening[key];
      for (const subKey of keyValue) {
        if (subKey?.value || subKey?.value === 0) {
          checkIfValue = true;
        }
      }
    }
    return checkIfValue;
  }

  public pointsCount(sectionsValues: any): any {
    let sum = 0;
    sectionsValues?.forEach((item: any) => {
      const answersFormGroup = item?.answers;
      answersFormGroup?.forEach((ansItemGroup: any) => {
        sum += ansItemGroup?.actualValue?.map(item => this.checkValues(item?.answer) || 0)?.reduce((prev, curr) => prev + curr);
      });
    });
    return sum;
  }

  public checkValues(value: any): number {
    let numberValue: number = 0;
    if (value?.includes('0')) {
      numberValue = 0;
    }
    if (value?.includes('+1')) {
      numberValue = 1;
    }
    if (value?.includes('+2')) {
      numberValue = 2;
    }
    if (value?.includes('+3')) {
      numberValue = 3;
    }
    return numberValue;
  }

  public checkCountsSectionsDisplay(sectionsName: string, resourceType: string): boolean {
    if (sectionsName?.includes(resourceType)) {
      return true;
    }
    return false;
  }

  // co-pay and c-insurance summation
  public coPayCoInsurance(payments: any): any {
    const sum = payments?.map((item: any) => parseInt(item?.amount || 0))?.reduce((prev, curr) => prev + curr);
    return (sum / 100) || 0;
  }

  // Amount Paid Summation summation
  public amountPaidSummation(payments: any): any {
    const filterSuccessPayment = payments?.filter((item: any) => item?.status === 'COMPLETED') || [];
    if (filterSuccessPayment?.length > 0) {
      const sum = filterSuccessPayment?.map((item: any) => parseInt(item?.amount || 0))?.reduce((prev, curr) => prev + curr);
      return (sum / 100) || 0;
    }
    return 0;
  }

  // open card payment 
  public openPayment(): void {
    if (!this.saveComplete) {
      this.notifyText = 'Please Save befor payment';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      return;
    }
    // this.payComp?.enableCardPay();
    this.payComp?.openModal(this.appointmentInfoDetails, true);
  }
  // to get payment config details
  private getPaymentConfig(): any {
    // const action = `providerConfiguration/getPaymentConfig?facilityId=${this.loginDetails?.facilityId}`;
    this.apiService?.getPaymentConfig(this.loginDetails?.facilityId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentConfigDetails = data?.responseObject;
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      });
  }

  // Show Documents view popup
  public downloadDocument(upDoc: any, pdfConsentModal: UiModalComponent) {
    if (!upDoc?.s3Key || !upDoc?.s3Path) {
      return;
    }
    // const action = `appointment/registration/getAdditionalDoc?s3Key=${s3Key}`;
    const action = `record/downloadDocument?s3Key=${upDoc?.s3Key}&s3Path=${upDoc?.s3Path}`;
    this.accordianVisitCard?.cardRefreshShow();
    this.httpService.downloadImage(action).subscribe((data: any) => {
      if(!data) {
        this.accordianVisitCard?.cardRefreshHide();
        return;
      }
      const file = new Blob([data], { type: upDoc?.fileType });
      const fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('additonal-doc-pdf-view') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
      pdfConsentModal?.show();
      this.accordianVisitCard?.cardRefreshHide();
    },
      (error: any) => {
        this.notifyText = error?.message || 'User does not have privileges to perform action';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.accordianVisitCard?.cardRefreshHide();
      });
  }

  public getHcgResult(result: any) {
    let note: string = '';
    result = parseInt(result);
    if (result || result === 0) {
      if (result <= 5)
        note = 'Negative';
      else if (result >= 6 && result <= 24)
        note = 'Retest';
      else if (result >= 25)
        note = 'Positive';
      return note;
    }
  }

  // get appointment cost of care
  public getAppointmentCost(): void {
    const action = `appointment/registration/retrieveApptCostofCare?appointmentId=${this.appointmentId}`;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.appointmentCostOfCareDetails = data?.body?.responseObject || {};
        this.appointmentCostOfCare?.get('reimburseAmount')?.setValue(data?.body?.responseObject?.apptCostofCare?.reimburseAmount);
        this.appointmentCostOfCare?.get('discount')?.setValue(data?.body?.responseObject?.apptCostofCare?.discount);
        this.appointmentCostOfCare?.get('nonPayment')?.setValue(data?.body?.responseObject?.apptCostofCare?.nonPayment);
        this.appointmentCostOfCare?.get('credits')?.setValue(data?.body?.responseObject?.apptCostofCare?.credits);
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(data?.body?.responseObject?.apptCostofCare?.patientResponsibility);
        this.appointmentCostOfCare?.get('approvedAmount')?.setValue(data?.body?.responseObject?.apptCostofCare?.approvedAmount);
        this.appointmentCostOfCare?.get('coverageCost')?.setValue(data?.body?.responseObject?.apptCostofCare?.coverageCost);

        if (data?.body?.responseObject?.apptCostofCare?.patientResponsibility === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
          const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((data?.body?.responseObject?.apptCostofCare?.reimburseAmount || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (data?.body?.responseObject?.apptCostofCare?.discount || 0) + (data?.body?.responseObject?.apptCostofCare?.nonPayment || 0));
          this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
        }
      }
    }, (error) => {

    });
  }

  // submit the pay of const of care for appointment
  public submitPayCost(): void {
    const action = `appointment/registration/saveApptCostofCare`;
    const params = this.appointmentCostOfCare?.value;
    this.accordianVisitCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      this.accordianVisitCard?.cardRefreshHide();
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = 'Payment details have been updated successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.saveComplete = true;
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    }, (error) => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.accordianVisitCard?.cardRefreshHide();
    });
  }

  // change paymeCore
  public changeFormPaymentChanges(): void {
    this.appointmentCostOfCare?.get('reimburseAmount')?.valueChanges?.subscribe(value => {
      if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
        const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((value || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (this.appointmentCostOfCare?.get('discount')?.value || 0) + (this.appointmentCostOfCare?.get('nonPayment')?.value || 0));
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      }
    });
    this.appointmentCostOfCare?.get('discount')?.valueChanges?.subscribe(value => {
      if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
        const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((this.appointmentCostOfCare?.get('reimburseAmount')?.value || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (value || 0) + (this.appointmentCostOfCare?.get('nonPayment')?.value || 0));
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      }
    });
    this.appointmentCostOfCare?.get('nonPayment')?.valueChanges?.subscribe(value => {
      if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
        const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((this.appointmentCostOfCare?.get('reimburseAmount')?.value || 0) + ((this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) || 0) + (this.appointmentCostOfCare?.get('discount')?.value || 0) + (value || 0));
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      }
    });
  }

  // Insurance / DL Image Card Download
  public downloadInsuranceCard(cardId: string, insuraceCardModal: UiModalComponent): void {
    this.downloadIdentificationCard(cardId, 'Insurance', this.appointmentInfoDetails?.userInfoId, insuraceCardModal);
    // const action = `document/user/getInsuranceCard/${cardId}`;
    // this.accordianVisitCard?.cardRefreshShow();
    // this.httpService.downloadImage(action).subscribe((data: any) => {
    //   var file = new Blob([data], { type: 'application/pdf' })
    //   var fileURL = URL.createObjectURL(file);
    //   // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
    //   // if you want to open PDF in new tab
    //   // created dynamic iframe SRC attribute and assigned lookup url
    //   // window.open(fileURL);
    //   insuraceCardModal?.show();
    //   this.accordianVisitCard?.cardRefreshHide();
    //   const iframeEle = document.getElementById('insurance-card-past-visit-multiple') as HTMLElement;
    //   iframeEle.setAttribute('src', fileURL);
    // },
    //   (error: any) => {
    //     this.accordianVisitCard?.cardRefreshHide();
    //   });
  }

  // Common id Document download
  private getCommonIdDocument(): void {
    this.httpService?.getDlIdDocument(this.loginDetails?.facilityId, this.appointmentInfoDetails?.userInfoId, this.appointmentInfoDetails?.id)?.subscribe((data: any) => {
      if (data?.responseObject?.identityImageId) {
        this.checkCommonIdDocument = data?.responseObject?.identityImageId;
        //this.downloadDlCard(data?.responseObject?.identityImageId);
        const documentType = "Identification Record";
        this.downloadIdentificationCard(data?.responseObject?.identityImageId, documentType, this.appointmentInfoDetails?.userInfoId);
      }
    })
  }

  public downloadIdentificationCard(additionalKey, documentType, userInfoId, insuraceCardModal?: UiModalComponent) {
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.accordianVisitCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.accordianVisitCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {

        }
        else {
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;

          const fileType = data?.responseObject?.fileType;
          this.downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);

        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.accordianVisitCard?.cardRefreshHide();
    });
  }

  public downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal?: UiModalComponent) {
    //alert(fileType);
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.accordianVisitCard?.cardRefreshShow();
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if(!dData) {
        this.accordianVisitCard?.cardRefreshHide();
        return;
      }
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      this.accordianVisitCard?.cardRefreshHide();
      if (insuraceCardModal) {
        insuraceCardModal?.show();
        const iframeEle = document.getElementById('insurance-card-past-visit-multiple') as HTMLElement;
        iframeEle?.setAttribute('src', fileURL);
      } else {
        const iframeEle = document.getElementById('dl-past-visit-card') as HTMLElement;
        iframeEle?.setAttribute('src', fileURL);
      }

    }, (error) => {
      this.accordianVisitCard?.cardRefreshHide();
    });
  }

  private getCommonConsentFormDocs(): void {
    /* this.httpService?.getConsentFormsDocuments(this.loginDetails?.facilityId, this.appointmentInfoDetails?.userInfoId, this.appointmentInfoDetails?.id)?.subscribe((data: any) => {
       if(data?.status === 'SUCCESS'){
         this.allConsentFormDocs = data?.responseObject || [];
         this.allConsentFormDocs = this.allConsentFormDocs?.filter(item => item?.status === 'SIGNED') || [];
       }
     })*/
    const documentType = 'Consent Form';
    const action = `record/listDocuments?appointmentId=${this.appointmentInfoDetails?.id}&documentType=${documentType}`;
    this.accordianVisitCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.accordianVisitCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        this.allConsentFormDocs = data?.responseObject || [];

        //this.allConsentFormDocs = this.allConsentFormDocs?.filter((listItem: any) => listItem?.documentType === 'Consent Form') || [];
      }
      else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.accordianVisitCard?.cardRefreshHide();
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
    });
  }

  public downloadDlCard(cardId: string): void {
    if (!cardId) {
      return;
    }
    const action = `document/user/getIdCard/${cardId}`;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // window.open(fileURL);
      const iframeEle = document.getElementById('dl-past-visit-card') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
    },
      (error: any) => {
      });
  }

  public checkAnnulWellnessVisit(othersReviewSystems: any): void {
    return othersReviewSystems?.filter(item => item?.name === 'Annual Wellness Visit') || [];
  }

  // Lab Rad ORders status
  public statusCodeDesc(charStatus: string): string {
    return this.httpService?.statusCodeDesc(charStatus);
  }

  // to get Internal Lab Reults 
  public getOrderDetails(labOrder: any): void {
    this.labRadOrderDetailsComp?.openOrderDetailsPopup(labOrder?.labName?.includes('Radiology') || labOrder?.labName?.includes('Rad') ? 'RAD' : 'LAB', null, labOrder);
  }

  // Show the HL7 details
  public showHl7Details(labOrder: any): void {
    this.hl7DetailsComp?.openOrderDetailsPopup(labOrder?.labName?.includes('Radiology') || labOrder?.labName?.includes('Rad') ? 'RAD' : 'LAB', null, labOrder);
  }

  // Open Results Popup if have results
  public getReportsAvailable(labOrder: any): void {
    this.labRadResultsComp?.getReportsAvailable(labOrder);
  }

  public navigateToTop(el: HTMLElement): void {
    // this.scroller.scrollToAnchor("mainDetails");
    el.scrollIntoView();
  }

  public onAddClass(): void {
    this.cssRefresh.emit(true);
  }

  public responseChange(change: any) {
    this.saveComplete = false;
  }

  public updateRecordReview(user: any, reviewLabRecord: UiModalComponent): void {
    this.documentReview?.openReviewModal(user, reviewLabRecord, this.appointmentId);
  }
  refresh() {
    this.getAllAdditionalDocs(this.appointmentId);
    this.getAllModulesSavedData();
  }

  // Call Assessment Code with Desc if miss the Code and Description
  public getAssessmentCodesDesc(): void {
    let icdCodeForm = [];
    this.allCareFlowData?.carePlan?.forEach((formGroupItem: any) => {
      if (formGroupItem?.resourceType === 'General') {

        formGroupItem?.sections?.forEach((secFormGroup: any) => {
          if (secFormGroup && secFormGroup?.name === 'Assessment') {
            secFormGroup?.answers?.forEach((ansForm: any) => {
              if (secFormGroup?.name === 'Assessment' && ansForm?.label === 'Diagnosis Codes (ICD10)') {
                icdCodeForm = ansForm?.actualValue || [];
              }
            });
          }
        });
      }

    });

    if (icdCodeForm && icdCodeForm?.length === 0) {
      return;
    }

    if (icdCodeForm && icdCodeForm?.length > 0 && icdCodeForm[0]?.description) {
      return;
    }

    const icdCodes: Array<string> = icdCodeForm?.map(item => item?.code) || [];
    const action = `billingCodes/getICDCodesDesc`;
    this.httpService?.makeRequestWithAction(action, icdCodes)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        this.codeMissingAssessments = data?.body?.responseObject || [];
      }
    },
      error => {
      });
  };

  public cmpareCodesDesc(icdCode: string): void {
    if (icdCode && this.allCareFlowData?.carePlan && this.allCareFlowData?.carePlan?.length > 0 && this.codeMissingAssessments && this.codeMissingAssessments?.length > 0) {
      const codesDesc = this.codeMissingAssessments?.map((item: any) => {
        return {
          code: item?.code,
          description: item?.codeDescription || item?.description
        }
      });
      return this.codeMissingAssessments?.find(item => item?.code === icdCode)?.codeDescription;
    }
  }

  public getAmountPaidForAppointment(): void {
    // const action = `common/getTotalPaidAmountForAppointment?appointmentId=${this.appointmentId}`;
    this.apiService?.getTotalPaidAmountForAppnt(this.appointmentId)?.subscribe((data: any) => {
      this.appointmentPiadAmount = data?.responseObject || 0;
    },
      (error) => {
      })
  }

  public loadAccountbalance() {
    this.accBalance.getAppointmentCost();
  }

  public downloadConsentForm(form: any, pdfConsentModal: UiModalComponent) {
    if (!form?.s3Key || !form?.s3Path) {
      return;
    }
    const fileType = form.fileType;
    let action: string;
    if (form?.s3Key) {
      // action = `SignForm/getSignedConsentForm?consentFormKey=${form?.s3Key}`;
      action = `record/downloadDocument?s3Key=${form?.s3Key}&s3Path=${form?.s3Path}`;
    } else if (form?.agreementId) {
      action = `SignForm/getSignedDoc?agreementId=${form?.agreementId}`;
    };

    this.httpService?.downloadImage(action).subscribe((data: any) => {
      if(!data) {
        return;
      }
      var file = new Blob([data], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('consent-form-view-pdf-accordian') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
      pdfConsentModal?.show();

    },
      (error: any) => {

      });
  }

  public checkBehaviouralScreenigDisplayAccordian(sectionsValues: any): any {
    let isDisplay = false;
    sectionsValues?.forEach((item: any) => {
      const answersFormGroup = item?.answers;
      answersFormGroup?.forEach((ansItemGroup: any) => {
        const checkisAns = ansItemGroup?.actualValue?.filter(item => item?.answer);
        if (checkisAns && checkisAns?.length > 0) {
          isDisplay = true;
        }
      });
    });
    return isDisplay;
  }

  public mainSectionDisplayAcordian(allBehavioralScreeningData: any): boolean {
    let isMainSecDisplay = false;
    if (!allBehavioralScreeningData) {
      isMainSecDisplay = false;
      return;
    }
    allBehavioralScreeningData?.forEach((mainItem: any) => {
      mainItem?.sections?.forEach((item: any) => {
        const answersFormGroup = item?.answers;
        answersFormGroup?.forEach((ansItemGroup: any) => {
          const checkisAns = ansItemGroup?.actualValue?.filter(item => item?.answer);
          if (checkisAns && checkisAns?.length > 0) {
            isMainSecDisplay = true;
          }
        });
      });
    })

    return isMainSecDisplay;
  }

  public checkIsValuesPresent(examSec: any): boolean {
    let isValueCheck: boolean = false;
    examSec?.answers?.forEach((answerItem: any) => {
      if (answerItem?.actualValue && answerItem?.actualValue?.length > 0) {
        isValueCheck = true;
      }
    });
    return isValueCheck;
  }
  public addPrefixZipCode(addressZipCode: any,valid:any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
   
    return prefixZipcode || addressZipCode || '';
  }

  // Close view more
  public closeHistoryViewMore(): void {
    this.closeMoreInfoDetails?.emit(true);
  }

  ngOnDestroy(): void {
    this.getRouteParam?.unsubscribe();
  }

}
