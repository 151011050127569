import { Component, Input, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CardComponent } from '../../components/card/card.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { LabRadOrderDetailsModalComponent } from '../lab-rad-order-details-modal/lab-rad-order-details-modal.component';
import { Hl7DetailsComponent } from '../hl7-details/hl7-details.component';
import { LabRadResultsModalComponent } from '../lab-rad-results-modal/lab-rad-results-modal.component';

@Component({
  selector: 'app-review-orders',
  templateUrl: './review-orders.component.html',
  styleUrls: ['./review-orders.component.scss']
})
export class ReviewOrdersComponent {

  @Input() allRxOrderDetails: any;
  @Input() alllabRadOrderDetails: any;
  @Input() PatientDetailsCardReload: CardComponent;
  @Input() exceptionModal: ExceptionModalComponent;
  isSuccessNotify: boolean;
  @Input() notifyText: any;
  @ViewChild(LabRadOrderDetailsModalComponent) labRadOrderDetailsComp: LabRadOrderDetailsModalComponent;
  @ViewChild(Hl7DetailsComponent) public hl7DetailsComp: Hl7DetailsComponent;
  @ViewChild(LabRadResultsModalComponent) labRadResultsComp: LabRadResultsModalComponent;

  constructor(private httpService: HttpService) { }

  // Open Lab Requisition from Change HEalth
  public downloadLabRequisition(orderNumber: any): void {
    const action = `changeHealth/getLabRequisition?orderNumber=${orderNumber}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        window.open(data?.responseObject, 'order-requisition');
      }
      this.PatientDetailsCardReload?.cardRefreshHide();
    },
      (error) => {
        this.PatientDetailsCardReload?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      })
  }

  // to get Internal Lab Reults 
  public getOrderDetails(labOrder: any): void {
    this.labRadOrderDetailsComp?.openOrderDetailsPopup(labOrder?.labName?.includes('Radiology') || labOrder?.labName?.includes('Rad') ? 'RAD' : 'LAB', null, labOrder);
  }

  // Get Lab Orders status description with codes
  public statusCodeDesc(charStatus: string): string {
    return this.httpService?.statusCodeDesc(charStatus);
  }

  // Show the HL7 details
  public showHl7Details(labOrder: any): void {
    this.hl7DetailsComp?.openOrderDetailsPopup(labOrder?.labName?.includes('Radiology') || labOrder?.labName?.includes('Rad') ? 'RAD' : 'LAB', null, labOrder);
  }

  // Open Results Popup if have results
  public getReportsAvailable(labOrder: any): void {
    this.labRadResultsComp?.getReportsAvailable(labOrder);
  }

}
