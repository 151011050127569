import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { DataService } from '../../services/data-sharing.service';
import { CardComponent } from '../../components/card/card.component';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { AutoFocus } from '../../directives/autofocus.directive'

@Component({
  selector: 'app-healthpass-patient-search',
  templateUrl: './healthpass-patient-search.component.html',
  styleUrls: ['./healthpass-patient-search.component.scss']
})
export class HealthpassPatientSearchComponent implements OnInit, AfterViewInit {

  public dtExistingOptions: DataTables.Settings = {};
  public dtExistingTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  @Input() exceptionModal: ExceptionModalComponent;

  public contactSearchForm: FormGroup;
  public existingContactSearchClick: boolean;
  public notifyText: string;
  public contactSearchResults: any[];
  private loginDetails: any;
  @Input() addNewPatientForm: FormGroup;
  @Input() demographicForm: FormGroup;
  @Input() familyDetailsForm: FormGroup;

  @Input() screenName: string = '';

  @ViewChild('contactSearchModal') private contactSearchModal: UiModalComponent;
  public minAppointmentDate: Date;
  public maxAppointmentDate: Date;
  public selectedExistingContact: any;

  public maskDateDash = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskMobileNo = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public isErrorNotify: boolean;
  @ViewChild('establishedPatientTable') private establishedPatientTable: CardComponent;
  public noUerInfoDetails: boolean;
  public userInfoError: string;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  @Input() insuranceForm: FormGroup;
  @ViewChild('searchElement') searchElement: ElementRef;
  @Input() selectedIndex: any;
  public notValidDob: boolean = false;
  public isDateFormat: boolean = false;
  public isNumber: boolean = false;
  searchParameter: any;
  public validName: boolean = false;


  constructor(public httpService: HttpService, public router: Router, public _fb: FormBuilder, private dataService: DataService) {
    this.contactSearchForm = this._fb.group({
      // 'TYPE_OF_SEARCH': [null, Validators.required],
      'SEARCH_PATIENT_FN_LN_PN': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
      // 'FIRST_NAME': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
      // 'LAST_NAME': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
      // 'PRIMARY_NUM': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.mobile)])],
      // 'DOB': [null],
    });
  }

  ngOnInit(): void {
    this.dtExistingOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[0, "desc"]],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
        },
    };
    this.minAppointmentDate = this.httpService.convertDate(new Date());
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }

  ngAfterViewInit(): void {
    this.dtExistingTrigger?.next();
  }

  // open search modal
  public openSearchModal(): void {
    this.contactSearchModal.show();
    //this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN').setValue('');
  }

  // contact serach api call
  public contactSearchCall(navigateNewAppointmentModal: UiModalComponent): void {
    this.validName = false;
    if (this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.invalid || this.notValidDob) {
      this.contactSearchForm?.markAllAsTouched();
      this.contactSearchResults = [];
      this.noUerInfoDetails= false;
      return;
    };
    if (this.contactSearchForm.controls['SEARCH_PATIENT_FN_LN_PN'].value === '' || this.contactSearchForm.controls['SEARCH_PATIENT_FN_LN_PN'].value === null) {
      /*this.notifyText = 'Type something to search..';
      this.isErrorNotify = true;
      this.exceptionModal?.modalShow();*/
      this.contactSearchResults = [];
      this.noUerInfoDetails = false;
      return;
    };
    this.contactSearchResults = [];
    let searchValue = this.contactSearchForm?.controls['SEARCH_PATIENT_FN_LN_PN']?.value;
    // searchValue = this.isValueNumber ? `%2B1${searchValue}` : searchValue;

    // let action: string = `patientSearch/${searchValue}`;
    // const action = `userInfo/patientSearch/${searchValue}`;

    // const action = `healthPass/healthpassPatientSearch/${searchValue}`;
    const action = `userInfo/patientSearch?searchParameter=${this.searchParameter}&searchParameterValue=${searchValue}`;

    this.existingContactSearchClick = true;
    this.isErrorNotify = false;
    this.noUerInfoDetails = false;
    this.httpService.makePostWithFormData(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.contactSearchResults = data?.responseObject || [];
        if (data?.responseObject && data?.responseObject?.length === 0) {
          if (this.screenName === 'newAppnt') {
            this.notifyText = 'No established patient is available with the given details.';
            this.isErrorNotify = true;
            this.existingContactSearchClick = false;
            return;
          }
          this.notifyText = 'No patients found with the given details';
          navigateNewAppointmentModal?.show();
          this.isErrorNotify = false;
        }
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        // navigateNewAppointmentModal?.show();
        this.isErrorNotify = true;
      }
      this.existingContactSearchClick = false;
      this.dtExistingTrigger.next();
    },
      error => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // navigateNewAppointmentModal?.show();
        this.isErrorNotify = false;
        // this.exceptionModal.modalShow();
        this.existingContactSearchClick = false;
        this.validName = true;
      });
  }

  //reset the search form
  public resetSearchForm(): void {
    this.contactSearchForm.reset();
  }

  public selectedContact(contact: any, contactSearchModal?: UiModalComponent, navigateNewAppointmentModal?: UiModalComponent): void {
    if (this.screenName == 'RxLabOrders') {
      this.mapUserToGetUserInfo(contact, contactSearchModal, navigateNewAppointmentModal);
      // this.router.navigate(['/hospital/new-rx-lab-orders']);
      sessionStorage.setItem('selectedPatient', JSON.stringify(contact));
      return;
    }
    if (this.screenName === 'flowBoard') {
      return;
    }
    // this.patchAppointmentForms(contact);
    if (this.screenName === 'newAppnt' || this.screenName === 'healthpass') {
      this.patchAppointmentForms(contact);
      contactSearchModal?.hide();
    }

    if (this.screenName === 'healthpassFamily') {
      this.patchFamilyMemberForms(contact);
      contactSearchModal?.hide();
    }


    if (this.screenName === 'Referral') {
      contactSearchModal?.hide();
      this.router.navigate([`/hospital/manage-referrals/${contact?.userInfoId}`]);
      sessionStorage.setItem('selectedPatient', JSON.stringify(contact));
    }
  }

  public patchFamilyMemberForms(contact: any): void {
    let familyArr = <FormArray>this.familyDetailsForm?.controls['familyMemberdetails'];

    familyArr.controls.forEach((element, index) => {
      if (this.selectedIndex === index) {

        element?.get('userInfoId')?.setValue(contact?.userInfoId || null);
        element?.get('firstName')?.setValue(contact?.firstName?.trim() || null);
        element?.get('lastName')?.setValue(contact?.lastName?.trim() || null);
        element?.get('middleName')?.setValue(contact?.middleName?.trim() || null);
        element?.get('dateOfBirth')?.setValue(this.httpService?.formatDobTimeZone(contact?.dob || contact?.usrDateOfBirth) || null);
        element?.get('maritalStatus')?.setValue(contact?.maritalStatus || null);
        element?.get('gender')?.setValue(contact?.gender || null);
        element?.get('ethnicity')?.setValue(contact?.ethnicity || null);
        element?.get('race')?.setValue(contact?.race || null);

        const name = contact?.middleName
          ? `${contact?.firstName} ${contact?.middleName} ${contact?.lastName}`
          : `${contact?.firstName} ${contact?.lastName}`;


        element?.get('EXISTING_PATIENT_SEARCH')?.setValue(name || null);
      }

    });
  }

  public patchAppointmentForms(contact: any): void {
    this.selectedExistingContact = contact;
    let primaryNumber: string = contact?.primaryPhone || contact?.patientPrimaryPhone;
    if (contact?.patientPrimaryPhone?.includes('+1')) {
      primaryNumber = contact?.patientPrimaryPhone.replace('+1', '');
    }
    if (contact?.primaryPhone?.includes('+1')) {
      primaryNumber = contact?.primaryPhone.replace('+1', '');
    }
    const searchname = contact?.middleName
      ? `${contact?.firstName} ${contact?.middleName} ${contact?.lastName}`
      : `${contact?.firstName} ${contact?.lastName}`;



    this.demographicForm?.get('EXISTING_PATIENT_SEARCH')?.setValue(searchname || null);

    this.demographicForm?.get('userInfoId')?.setValue(contact?.userInfoId);
    this.demographicForm?.get('FIRST_NAME')?.setValue(contact?.firstName?.trim() || null);
    this.demographicForm?.get('LAST_NAME')?.setValue(contact?.lastName?.trim() || null);
    this.demographicForm?.get('MIDDLE_NAME')?.setValue(contact?.middleName?.trim() || null);
    this.demographicForm?.get('DATE_OF_BIRTH')?.setValue(this.httpService?.formatDobTimeZone(contact?.dob || contact?.usrDateOfBirth) || null);
    this.demographicForm?.get('ETHNICITY')?.setValue(contact?.ethnicity || null);
    this.demographicForm?.get('GENDER')?.setValue(contact?.gender || null);
    this.demographicForm?.get('MARITAL_STATUS')?.setValue(contact?.maritalStatus || null);
    this.demographicForm?.get('PHONE_NUMBER')?.setValue(primaryNumber || null);
    this.demographicForm?.get('RACE')?.setValue(contact?.race || null);
    this.demographicForm?.get('USERINFO_ID')?.setValue(contact?.id || null);
    this.demographicForm?.get('PHONE_TYPE')?.setValue(contact?.mobile !== null && contact?.mobile === true ? true : false);
    this.demographicForm?.get('EMAIL')?.setValue(contact?.email);
    // emergency details
    let emergencyPhone: string;
    if (contact?.emergencyDetails?.phone?.includes('+1')) {
      emergencyPhone = contact?.emergencyDetails?.phone.replace('+1', '');
    };
    this.demographicForm?.get('NAME')?.setValue(contact?.emergencyDetails?.name);
    this.demographicForm?.get('RELATIONSHIP')?.setValue(contact?.emergencyDetails?.relationship);
    this.demographicForm?.get('EMEGENCY_CONTACT')?.setValue(emergencyPhone);

    const name = contact?.middleName ? `${contact?.firstName} ${contact?.middleName} ${contact?.lastName}` : `${contact?.firstName} ${contact?.lastName}`;
    if (this.screenName != 'Referral') {
      this.addNewPatientForm?.get('EXISTING_PATIENT_SEARCH')?.setValue(name || null);
    }
    if (this.screenName == 'Referral') {
      this.demographicForm?.get('FULL_NAME')?.setValue(name || null);
    }
    const age = this.httpService?.calculateAge(contact?.dob) || 0;
    this.demographicForm?.get('AGE')?.setValue(contact?.age || age || 0);
    if (contact && contact?.address) {
      this.demographicForm?.get('ADDRESS')?.setValue((contact?.address?.street) || null);
      this.demographicForm?.get('CITY')?.setValue((contact?.address?.city) || null);
      this.demographicForm?.get('STATE')?.setValue((contact?.address?.state) || null);
      this.demographicForm?.get('COUNTRY')?.setValue((contact?.address?.country) || null);
      //this.demographicForm?.get('ZIPCODE')?.setValue((contact?.address?.zipCode) || null);
	  const prefixZipCode = this.addPrefixZipCode(contact?.address?.zipCode,'');
	  this.demographicForm?.get('ZIPCODE')?.setValue((prefixZipCode) || null);
    }
    this.getUserInfoDetails(contact);
    if (this.insuranceForm) {
      this.clearFormArray(this.insuranceForm?.get('insuranceDetails') as FormArray);
      this.insuranceForm?.get('selfPay')?.setValue(null);
      this.insuranceForm?.get('employerName')?.reset();
      this.insuranceForm?.get('employerAddress')?.reset();
    }
    if (contact?.insuranceDetails) {
      const insurances = contact?.insuranceDetails || [];
      const insuranceDetails = insurances?.filter((item: any) => item?.active === true) || [];
      sessionStorage?.setItem('insuranceDetails', JSON.stringify(insuranceDetails));
      if (insuranceDetails?.length === 0) {
        sessionStorage?.removeItem('insuranceDetails');
      }
    } else {
      sessionStorage?.removeItem('insuranceDetails');
    }
    if (contact?.idDetails) {
      this.demographicForm?.get('idDocument')?.get('documentType')?.setValue(contact?.idDetails?.type || null);
      this.demographicForm?.get('idDocument')?.get('documentId')?.setValue(contact?.idDetails?.number || null);
      this.demographicForm?.get('idDocument')?.get('issueDate')?.setValue(contact?.idDetails?.idValidFromDate || null);
      this.demographicForm?.get('idDocument')?.get('expiryDate')?.setValue(contact?.idDetails?.idValidToDate || null);
      this.demographicForm?.get('idDocument')?.get('identityImageId')?.setValue(contact?.idDetails?.imageId || null);
      // this.demographicForm?.get('idDocument')?.patchValue(contact?.idDetails);
    }
    // Pharmacy Details patch
    const pharmacySample = {
      "address": {
        "city": null,
        "country": null,
        "state": null,
        "street": null,
        "zipCode": null
      },
      "name": null,
      "pharmacyholderName": null,
      "fax": null,
      "phoneNumber": null,
    }
    contact.pharmacy = contact?.pharmacy || pharmacySample;
    this.demographicForm?.get('pharmacy')?.patchValue(contact?.pharmacy || pharmacySample);

    this.demographicForm.updateValueAndValidity();
  }

  // to check the phone number validation

  get isValueNumber(): boolean {
    this.searchParameter = '';
    const actualValue = this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.value || null;
    let value = null;
    if (actualValue) {
      value = actualValue.trimLeft();
    }
    const c = value?.charAt(0);
    const isNumber = (c >= '0' && c <= '9');
    const regex = /^(?:(0[1-9]|1[012])[\/.](0[1-9]|[12][0-9]|3[01])[\/.](19|20)[0-9]{2})$/;
    const alphaNumericRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
    const aplhaNumberic = alphaNumericRegex.test(value);
    if (value !== null && value !== '' && value.includes("/")) {
      this.isDateFormat = true;
      if (!regex.test(value)) {
        this.notValidDob = true;
        this.contactSearchResults = [];
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators(null);
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.clearValidators();
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.updateValueAndValidity();
        return false;

      }
      else {
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value?.trim());
        this.notValidDob = false;
        this.searchParameter = "dateOfBirth";
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators(null);
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.clearValidators();
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.updateValueAndValidity();
        return false;
      }
    }
    else {
      this.isDateFormat = false;
      this.notValidDob = false;
      this.isNumber = isNumber;
      this.validName = false;
      if (isNumber && value.length < 10) {
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value?.trim());
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.acceptNumeric)]);
        this.isDateFormat = true;
        this.searchParameter = 'patientEmrId';
        // this.searchParameter = 'patientPrimaryPhone';
        return true;
      }
      if (isNumber && value.length === 10) {
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value?.trim());
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.phoneDigit)]);
        this.searchParameter = 'patientPrimaryPhone';
        this.isDateFormat = true;
        return true;
      }
      if ((value)&& (value.length >4) && (aplhaNumberic)) {
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value?.trim())
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.aplhaNumeric)]);
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.updateValueAndValidity();
        this.searchParameter = 'patientEmrId';
        return true;
      }
      if ((value) && (value.length < 5) && (aplhaNumberic)) {
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value);
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.mobile)]);
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.updateValueAndValidity();
        this.searchParameter = 'patientEmrId';
        return false;
      }
      if ((value && value.length > 3) && (!aplhaNumberic) && !isNumber) {
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value)
        let content = value;
        if(content.endsWith(",")|| content.endsWith(" ")){
          this.validName = true;
          this.contactSearchResults = [];
        }else{
          this.validName = false;
        }
        if (content.includes(',')) {
          this.searchParameter = 'fullName';
          this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value)
          this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.fullName)]);
          this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.updateValueAndValidity();
          return true;
        } else {
          this.searchParameter = 'lastName';
          this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
          this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.updateValueAndValidity();
          this.isDateFormat = true;
          return true;
        }
      }
      if ((value && value.length < 4) && (!aplhaNumberic) && !isNumber) {
        this.contactSearchResults = [];
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value)
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.mobile)]);
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.updateValueAndValidity();
        let content = value;
        if (content.includes(',')) {
          this.searchParameter = 'fullName';
        } else {
          this.searchParameter = 'lastName';
        } return false;
      }
      else if (value) {
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value)
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.updateValueAndValidity();
        this.contactSearchResults = [];
        this.validName= false;
        return false;
      }else{
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValue(value)
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.mobile)]);
        this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.updateValueAndValidity();
        this.contactSearchResults = [];
        this.validName= false;
        return false;
      }
    }

  }


  public newAppointment(patient: any): void {
    // sessionStorage?.setEstablishedPatient(patient);
    sessionStorage?.setItem('selectedPatientFlowBoard', JSON.stringify(patient));
    this.navigateNewAppointment();
  }

  public navigateNewAppointment(isNoAppnt?: boolean): void {
    if (isNoAppnt) {
      sessionStorage?.setItem('isNoAppnt', 'Yes');
    }
    this.router.navigate(['/hospital/addAppt']);
  }

  // map user to get user info details.
  public mapUserToGetUserInfo(existingAppnt: any, contactSearchModal?: UiModalComponent, navigateNewAppointmentModal?: UiModalComponent): void {
    const action = 'userInfo/mapUser';
    const params = {
      "address": existingAppnt?.address,
      "dob": this.httpService?.formatDobTimeZone(existingAppnt?.dob),
      "firstName": existingAppnt?.firstName,
      "gender": existingAppnt?.gender,
      "lastName": existingAppnt?.lastName,
      "patientPrimaryPhone": existingAppnt?.patientPrimaryPhone
    };
    this.noUerInfoDetails = false;
    this.establishedPatientTable?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        const userInfo = data?.body?.responseObject || data?.body?.userInfo || null;
        if (userInfo) {
          if (!navigateNewAppointmentModal) {
            if (data?.body?.pastAppointmentHistory) {
              sessionStorage.setItem('establishedUserInfo', JSON.stringify(userInfo));
              contactSearchModal?.hide();
              this.router?.navigate([`hospital/appointment-history/${userInfo?.id}`]);
            } else {
              this.noUerInfoDetails = true;
              this.userInfoError = 'No past Visits for the selected Patient.';
            }
          } else {
            sessionStorage.setItem('selectedPatient', JSON.stringify(userInfo));
            // sessionStorage.setItem('selectedOrderType', 'Rx');
            contactSearchModal?.hide();
            this.router.navigate(['/hospital/new-rx-lab-orders']);
            sessionStorage?.setItem('addhocRxLabOrders', 'hospital/lab-orders?order=1');
          }
        } else {
          if (!navigateNewAppointmentModal) {
            this.noUerInfoDetails = true;
            this.userInfoError = 'No past Visits for the selected Patient.';
          } else {
            this.notifyText = 'No Past Visits for the selected Patient, Please Register the patient to place Rx or Lab/Rad Order';
            navigateNewAppointmentModal?.show();
            sessionStorage?.setItem('selectedPatientFlowBoard', JSON.stringify(existingAppnt));
          }
        }
      }
      this.establishedPatientTable?.cardRefreshHide();
    },
      (error) => {
        this.noUerInfoDetails = true;
        this.userInfoError = error?.message || AppConstantsListConfig?.uiErrorException;
        this.establishedPatientTable?.cardRefreshHide();
      })
  }

  // open send notification popup
  public sendNotification(patient: any, contactSearchModal: UiModalComponent): void {
    contactSearchModal?.hide();
    this.sendNotifyComp?.showNotifyModal(patient, 'user');
    this.clearForm();
  }

  // due to timezone is added in dob, split the date and formate mm/dd/yyyy
  public formatDob(dob: string) {
    let split = dob?.includes('T') ? dob?.toString()?.split('T') : dob;
    split = split && split[0] ? split[0] : split;
    return split || '';
  }

  public clearForm(): void {
    this.contactSearchForm?.reset();
    this.contactSearchResults = [];
    this.noUerInfoDetails = false;
    this.isErrorNotify = false;
  }

  //format the primary phone remove +1
  public formatPrimaryPhone(primaryPhone: string): string {
    return primaryPhone?.includes('+1') ? primaryPhone?.replace('+1', '') : primaryPhone;
  }

  // modify user ifno data
  public modifyUserData(userDetails: any, contactSearchModal: UiModalComponent): void {
    if (userDetails?.userInfoId) {
      contactSearchModal?.hide();
      this.router?.navigate([`/hospital/user-data-update/${userDetails?.userInfoId}`]);
      this.clearForm();
    } else {
      this.noUerInfoDetails = true;
      this.userInfoError = 'Patient is not registered, please complete the patient registration';
    }
  }

  // clear the Form Array
  public clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  //  get user ifno details
  public getUserInfoDetails(establishedPatient: any): void {
    const action = `userInfo/getUserInfo?id=${establishedPatient?.userInfoId}`;
    if (!establishedPatient?.userInfoId) {
      return;
    }
    this.establishedPatientTable?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.establishedPatientTable?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        const userInfo = data?.userInfoResponse || null;
        if (userInfo) {
          const pharmacySample = {
            "address": {
              "city": null,
              "country": null,
              "state": null,
              "street": null,
              "zipCode": null
            },
            "name": null,
            "pharmacyholderName": null
          }
          userInfo.pharmacy = userInfo?.pharmacy || pharmacySample;
          this.demographicForm?.get('pharmacy')?.patchValue(userInfo?.pharmacy || pharmacySample);
          sessionStorage.setItem('establishedUserInfo', JSON.stringify(userInfo));
        }
      }
    },
      (error) => {
        this.establishedPatientTable?.cardRefreshHide();
      });
  }

  public checkAppointmentHistory(establishedPatient: any, contactSearchModal: UiModalComponent): void {
    const action = `appointment/history/forUser?userInfoId=${establishedPatient?.userInfoId}`;
    this.noUerInfoDetails = false;
    if (!establishedPatient?.userInfoId) {
      this.noUerInfoDetails = true;
      this.userInfoError = 'No past Visits for the selected Patient.';
      return;
    }
    this.establishedPatientTable?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data && data?.length > 0) {
        contactSearchModal?.hide();
        this.router?.navigate([`hospital/appointment-history/${establishedPatient?.userInfoId}`]);
      } else {
        this.noUerInfoDetails = true;
        this.userInfoError = 'No past Visits for the selected Patient.';
      }
      this.establishedPatientTable?.cardRefreshHide();
    },
      (error) => {
        this.establishedPatientTable?.cardRefreshHide();
      });
  }

  // click on auto focus on the element
  public autoFocusSearch(): void {
    setTimeout(() => {
      this.searchElement?.nativeElement?.focus();
    }, 500)
  }
   public addPrefixZipCode(addressZipCode: any,valid:any): any {
      let prefixZipcode = addressZipCode;
      if (prefixZipcode) {
        const checkLen = prefixZipcode?.toString()?.length;
        if (checkLen === 4) {
          prefixZipcode = '0' + prefixZipcode;
        }
        if (checkLen === 3) {
          prefixZipcode = '00' + prefixZipcode;
        }
      }
     
      
      return prefixZipcode || addressZipCode || '';
    }

   
    public isValidDOB() {
      const value = this.contactSearchForm?.get('SEARCH_PATIENT_FN_LN_PN')?.value || null;
  
      if (value !== null && value !== '' && value.includes("/")) {
  
        const regex = /^(?:(0[1-9]|1[012])[\/.](0[1-9]|[12][0-9]|3[01])[\/.](19|20)[0-9]{2})$/;
  
        if (regex.test(value)) {
          this.notValidDob = false;
  
        } else {
          this.notValidDob = true;
  
        }
  
      }
  
  
    }

  // destroy the table trigger function to overcome to memory leak issues
  ngOnDestroy(): void {
    this.dtExistingTrigger?.unsubscribe();
  }

}
