import { Component, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, interval, Subscription } from 'rxjs';
import { HospDashboardComponent } from 'src/app/demo/panels/hospital/hosp-dashboard/hosp-dashboard.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { ExistingPatientSearchComponent } from 'src/app/theme/shared/hop-common-components/existing-patient-search/existing-patient-search.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { AppRegExpressionsConfig } from 'src/app/theme/shared/litterals/app.regularexpressions';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { NextConfig } from '../../../../app-config';

//import { WaitTimeComponent } from './wait-time/wait-time.component';


import { FlowBoardCardsComponent } from 'src/app/demo/panels/hospital/hosp-dashboard/flow-board-cards/flow-board-cards.component';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { KioskAppointmentsGridComponent } from 'src/app/demo/panels/hospital/hosp-dashboard/kiosk-appointments-grid/kiosk-appointments-grid.component';
import { SendSmsNotifyComponent } from 'src/app/theme/shared/hop-common-components/send-sms-notify/send-sms-notify.component';
import { NewTaskComponent } from 'src/app/theme/shared/hop-common-components/new-task/new-task.component';
import { NotificationsViewComponent } from './notifications-view/notifications-view.component';
import { ApisService } from 'src/app/theme/shared/services/apis.services';
import { FacilityPaymentComponent } from 'src/app/theme/shared/hop-common-components/facility-payment/facility-payment.component';
import { PaymentsRefactorComponent } from 'src/app/theme/shared/hop-common-components/payments-refactor/payments-refactor.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  // host: {
  //   '(document:click)': 'onClick($event)',
  // },

})
export class NavBarComponent implements OnInit {
  public flatConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();
  public facilityName: any;

  @ViewChild(ExistingPatientSearchComponent) private existingPatientSearchComp: ExistingPatientSearchComponent;
  public adhocSmsForm: FormGroup;
  public loginDetails: any;
  public loadSpinner: boolean;
  public loadSearchData: boolean;
  public notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;

  public notificationsCount: any[] = [];

  public checkIsNoAccess: boolean;
  public waitTimeDetails: any;
  public addPatientPurposeVisit: any;
  public isUrgentCare: any;
  public refetchClick: boolean;
  public allAppointments = [];
  public checkInServicesAppointment: any;
  public checkInServicesClick: boolean;
  public allErrorPhoneNumAppointments = [];
  public eligibilityAppointment: any;
  public dtTrigger: Subject<any> = new Subject<any>();
  public facilityData: any;
  dtElement: DataTableDirective;
  public userInfoId: any;
  public userInfoData:any;

  @ViewChild(KioskAppointmentsGridComponent) private kioskComp: KioskAppointmentsGridComponent;
  @ViewChild(FlowBoardCardsComponent) flowBoardMetricComp: FlowBoardCardsComponent;
  @ViewChild('AppointmentQueueCard') public AppointmentQueueCard: CardComponent;
  @ViewChild(PaymentsRefactorComponent) private payComp: PaymentsRefactorComponent;


  public searchForm: FormGroup;
  public isErrorNotify: boolean;
  public noUerInfoDetails: boolean;
  public userInfoError: string;
  public dtExistingTrigger: Subject<any> = new Subject<any>();
  searchElement: any = null;
  @ViewChild('searchElement') inputName;
  public existingContactSearchClick: boolean;
  public contactSearchResults: any[];
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;

  @ViewChild('searchResult') searchResult: ElementRef;
  isSearchResultOpen = false;
  @ViewChild(NewTaskComponent) newTakComp: NewTaskComponent;
  @ViewChild(NotificationsViewComponent) private notificationsView: NotificationsViewComponent;
  public patientRegForm: FormGroup;
  public registrationTypes = [
    { desc: 'Register the patient and Schedule an appointment', value: 'Yes' },
    { desc: 'Register the patient without an appointment', value: 'No' }
  ]
  public enableAction: boolean = false;
  public notValidDob: boolean = false;
  public isDateFormat: boolean = false;
  public isNumber: boolean = false;
  @ViewChild(FacilityPaymentComponent) facilityPayComp: FacilityPaymentComponent;
  paymentConfigDetails: any;
  searchParameter: any;
  validName: boolean = false;
  appointmentId:any;
  facilityId:any;

  // @HostListener('window:keydown')


  constructor(private renderer: Renderer2, private router: Router, private formBuilder: FormBuilder, private httpService: HttpService, private elementRef: ElementRef, private apiService: ApisService) {
    this.flatConfig = NextConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;

    this.adhocSmsForm = this.formBuilder?.group({
      'smsPhoneNumber': [null, Validators?.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig?.mobile)])],
      'firstName': [null, Validators?.required],
      'message': [null]
    })

    this.searchForm = this.formBuilder?.group({
      'SEARCH_PATIENT_FIELD': [null],
    })

    /*this.renderer.listen('window', 'click', (e: Event) => {


      if (e.target !== this.inputName.nativeElement && this.searchResult && e.target !== this.searchResult.nativeElement) {
        this.isSearchResultOpen = false;
        this.inputName.nativeElement.value = '';
        this.inputName.nativeElement.classList.add('col-md-8');
      }
    });*/

    this.patientRegForm = this.formBuilder?.group({
      'typeRegistrationSelect': [null, Validators?.required]
    });

  }

  // onClick(event) {
  //   if(event && event.target){
  //   if (!this.inputName.nativeElement.contains(event.target)) {
  //     // this.clearForm();
  //   }
  //    }
  //  }

  @HostListener('window:mouseup', ['$event']) onMouseUp(event: MouseEvent) {
    if (event && event.target) {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        // Clicked outside the component
        // Add your logic here to handle the click event
        this.enableAction = false;
      }
    }
  }

  ngOnInit() {
    const sessionUser = JSON.parse(sessionStorage.getItem('userDetails'));
    this.facilityName = sessionUser?.facilityName;
    this.facilityId = sessionUser?.facilityId ;
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));

    this.getWaitTime(this.loginDetails && this.loginDetails.facilityId);
    this.getPaymentConfig();
  }

  ngAfterViewInit(): void {
    if (this.searchElement && this.searchElement.nativeElement) {
      this.searchElement.nativeElement.focus();
    }
  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }
  //get isValidDate(dateString): boolean {
  /* public  isValidDOB(dateString) {
     const regex = /^(?:(0[1-9]|1[012])[\/.](0[1-9]|[12][0-9]|3[01])[\/.](19|20)[0-9]{2})$/;
     return regex.test(dateString);
   }*/

  public isValidDOB() {
    const value = this.searchForm?.get('SEARCH_PATIENT_FIELD')?.value || null;

    if (value !== null && value !== '' && value.includes("/")) {

      const regex = /^(?:(0[1-9]|1[012])[\/.](0[1-9]|[12][0-9]|3[01])[\/.](19|20)[0-9]{2})$/;

      if (regex.test(value)) {
        this.notValidDob = false;

      } else {
        this.notValidDob = true;

      }

    }


  }


  // new appointment open
  public newAppointment(): void {
    sessionStorage?.removeItem('selectedPatientFlowBoard');
    sessionStorage?.removeItem('establishedUserInfo');
    if (window.location.href.includes('addAppt')) {
      location.reload();
    }
    this.router.navigate(['/hospital/addAppt']);
    sessionStorage.setItem('navigateAddAppnt', 'dashboard');
  }
  // established patient search open
  public establishedPAtientModal(): void {
    /*this.existingPatientSearchComp.openSearchModal();
    this.existingPatientSearchComp?.autoFocusSearch();*/
  }

  public sendadHocSms(sendAdHocSmsPatient: UiModalComponent): void {
    if (this.adhocSmsForm?.invalid) {
      this.adhocSmsForm?.markAllAsTouched();
      return;
    }
    let action = 'notification/sendlate';
    let params = {
      appointmentId: null,
      messageToUsers: `Please click ${environment?.userWebUrl}/${this.loginDetails?.facilityId}/bookappt to schedule your appointment.`,
      phoneNumber: `+1${this.adhocSmsForm?.value?.smsPhoneNumber}`,
      notificationType: 'NeAppointment'
    }
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data?.body?.status == 'SUCCESS') {
          this.notifyText = 'SMS has been sent successfully.';
          this.isSuccessNotify = true;
          this.exceptionModal?.modalShow();
          this.adhocSmsForm?.reset();
          sendAdHocSmsPatient?.hide();
        }
        this.loadSpinner = false;
      },
        (error) => {
          this.loadSpinner = false;
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        });
  }

  get isKiosk(): boolean {
    return this.loginDetails?.roleType === 'Kiosk' ? true : false;
  }

  get isShowHideElements(): any {
    const allItems = JSON.parse(sessionStorage?.getItem('allProvideLoginNavItems'));
    this.checkIsNoAccess = allItems?.length === 0 ? true : false;
    const getScreenItem = allItems?.find(item => item?.coreFeature === 'Flow Board');
    return getScreenItem?.childFeature || {};
  }

  // get isCreateInvoice(): any {
  //   const allItems = JSON.parse(sessionStorage?.getItem('allProvideLoginNavItems'));
  //   this.checkIsNoAccess = allItems?.length === 0 ? true : false;
  //   const getScreenItem = allItems?.find(item => item?.coreFeature === 'Invoice Board');
  //   return getScreenItem?.childFeature || {};
  // }

  public navigateUserAddAppnt(): void {
    window.name = environment?.windowName;
    window.open(`${environment?.userWebUrl}/${this.loginDetails?.facilityId}/bookappt?kioskAppointment=yes`, 'kioskAddAppointment');
  }

  public getWaitTime(facilityId: any): void {
    const action = `waitTime/`;
    this.httpService.makeGetRequest(action, facilityId).subscribe((data: any) => {
      sessionStorage?.setItem('waitTimeDetails', JSON.stringify(data));
      this.waitTimeDetails = data;
      this.addPatientPurposeVisit = (data && data?.purposeOfVisit) || [];
      this.isUrgentCare = (data && data?.urgentcare);
      // this.adPatientAllDoctors = (data && data.eligibilityDetails && data.eligibilityDetails.doctor) || [];
      // this.addPatientTypeOfService = (data && data.typeOfService) || [];
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal.modalShow();
    })
  }

  public refresh(): void {
    this.refetchClick = true;
    if (this.isKiosk) {
      this.kioskComp?.refetchKioskData();
    }
    this.allAppointments = [];
    this.rerender();
    this.getPatientsByFacilityId(this.loginDetails && this.loginDetails.facilityId);
    this.flowBoardMetricComp?.callAllCountersServices();
    // this.getPatientsByCompleted(this.loginDetails && this.loginDetails.facilityId);
  }

  public getPatientsByFacilityId(facilityid: any): void {
    if (!facilityid || !JSON.parse(sessionStorage.getItem('userDetails'))) {
      return;
    }
    let action: any = `appointment/registration/statusByFacilityId?facilityId=${facilityid}`;
    // let action: any = `appointment/registration/statusByFacilityId?facilityId=${facilityid}&doctorNpi=${this.loginDetails?.providerNPI}`;
    // let params: any = {
    //   'id': facilityid
    // }
    this.allErrorPhoneNumAppointments = [];
    this.AppointmentQueueCard.cardRefreshShow();
    this.httpService.getPatientsByFacilityId(action, '')
      .subscribe((data: any) => {
        if (data.status == 'SUCCESS') {
          this.facilityData = data;
          this.allAppointments = [...this.allAppointments, ...data.resultList] || [];
          // this.dtOptions.data = this.allAppointments || [];
          this.allAppointments.map(item => {
            if (item?.invalidPrimaryPhone) {
              this.allErrorPhoneNumAppointments.push(item);
            }
          })
          sessionStorage.setItem('token', JSON.stringify(data));

        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal.modalShow();
        }
        this.dtTrigger.next();
        this.refetchClick = false;
        this.AppointmentQueueCard.cardRefreshHide();
      }, error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
        this.refetchClick = false;
        this.AppointmentQueueCard.cardRefreshHide();
        this.dtTrigger.next();
      }
      );
  }

  public getPatientsByCompleted(facilityid: any): void {
    let action: any = 'appointment/registration/byCompleted?facilityId=';
    let params: any = {
      'id': facilityid
    }
    this.httpService.getPatientsByFacilityId(action, params.id)
      .subscribe((data: any) => {
        if (data.status == 'SUCCESS') {
          // this.facilityData = data;
          this.allAppointments = [...this.allAppointments, ...data.resultList] || [];
          // this.dtOptions.data = this.allAppointments || [];
          sessionStorage.setItem('token', JSON.stringify(data));
        } else {
          let toastrData = AppConstantsListConfig?.uiErrorException;
        }
      }, error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
      });
  }

  public rerender(): void {
    this.dtElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
      //  this.dtTrigger.next(); 
    });
  }

  // to check the phone number validation
  get isValueNumber(): boolean {
    this.searchParameter = '';
    const actualValue = this.searchForm?.get('SEARCH_PATIENT_FIELD')?.value || null;
    let value = null;
    if (actualValue) {
      value = actualValue.trimLeft();
    }
    const c = value?.charAt(0);
    const isNumber = (c >= '0' && c <= '9');
    const regex = /^(?:(0[1-9]|1[012])[\/.](0[1-9]|[12][0-9]|3[01])[\/.](19|20)[0-9]{2})$/;
    const alphaNumericRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
    const aplhaNumberic = alphaNumericRegex.test(value);
    if (value !== null && value !== '' && value.includes("/")) {
      this.isDateFormat = true;
      if (!regex.test(value)) {
        this.notValidDob = true;
        this.contactSearchResults = [];
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators(null);
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.clearValidators();
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
        return false;

      }
      else {
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value?.trim());
        this.notValidDob = false;
        this.searchParameter = "dateOfBirth";
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators(null);
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.clearValidators();
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
        return false;
      }
    }
    else {
      this.isDateFormat = false;
      this.notValidDob = false;
      this.isNumber = isNumber;
      this.validName = false;
      if (isNumber && value.length < 10) {
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value?.trim());
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.acceptNumeric)]);
        this.isDateFormat = true;
        this.searchParameter = 'patientEmrId';
        // this.searchParameter = 'patientPrimaryPhone';
        return true;
      }
      if (isNumber && value.length === 10) {
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value?.trim());
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.phoneDigit)]);
        this.searchParameter = 'patientPrimaryPhone';
        this.isDateFormat = true;
        return true;
      }
      if ((value)&& (value.length >4) && (aplhaNumberic)) {
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value?.trim())
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.aplhaNumeric)]);
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
        this.searchParameter = 'patientEmrId';
        return true;
      }
      if ((value) && (value.length < 5) && (aplhaNumberic)) {
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value);
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.mobile)]);
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
        this.searchParameter = 'patientEmrId';
        return false;
      }
      if ((value && value.length > 3) && (!aplhaNumberic) && !isNumber) {
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value)
        let content = value;
        if(content.endsWith(",") || content.endsWith(" ")){
          this.validName = true;
          this.contactSearchResults = [];
        }else{
          this.validName = false;
        }
        if (content.includes(',')) {
          this.searchParameter = 'fullName';
          this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value)
          this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.fullName)]);
          this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
          return true;
        } else {
          this.searchParameter = 'lastName';
          this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
          this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
          this.isDateFormat = true;
          return true;
        }
      }
      if ((value && value.length < 4) && (!aplhaNumberic) && !isNumber) {
        this.contactSearchResults = [];
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value)
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.mobile)]);
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
        let content = value;
        if (content.includes(',')) {
          this.searchParameter = 'fullName';
        } else {
          this.searchParameter = 'lastName';
        } return false;
      }
      else if (value) {
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value)
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
        this.contactSearchResults = [];
        this.validName= false;
        return false;
      }else{
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value)
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.mobile)]);
        this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
        this.contactSearchResults = [];
        this.validName= false;
        return false;
      }

      //   else{
      //   this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value);
      //   this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required,Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
      // 	return false;
      //  }
    }
    //  else if( (value && value.length<3 ) && (aplhaNumberic)){
    //     this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValue(value?.trim());
    //     this.searchForm?.get('SEARCH_PATIENT_FIELD')?.setValidators([Validators.required,Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
    //     this.searchForm?.get('SEARCH_PATIENT_FIELD')?.updateValueAndValidity();
    //     return false;
    //  }

  }

  public contactSearchCall(navigateNewAppointmentModal: UiModalComponent): void {
    this.enableAction = false;
    //this.isSearchResultOpen = !this.isSearchResultOpen;
    if (this.searchForm?.get('SEARCH_PATIENT_FIELD')?.invalid || this.notValidDob  || this.validName) {
      this.searchForm?.markAllAsTouched();
      this.contactSearchResults = [];
      this.noUerInfoDetails= false;
      return;
    };
    if (this.searchForm.controls['SEARCH_PATIENT_FIELD'].value === '' || this.searchForm.controls['SEARCH_PATIENT_FIELD'].value === null) {
      /*this.notifyText = 'Type something to search..';
      this.isErrorNotify = true;
      this.exceptionModal?.modalShow();*/
      this.contactSearchResults = [];
      this.noUerInfoDetails = false;
      return;
    };
    this.contactSearchResults = [];
    this.validName = false;
    let searchValue = this.searchForm?.controls['SEARCH_PATIENT_FIELD']?.value;
    // searchValue = this.isValueNumber ? `%2B1${searchValue}` : searchValue;

    // let action: string = `patientSearch/${searchValue}`;
    const action = `userInfo/patientSearch?searchParameter=${this.searchParameter}&searchParameterValue=${searchValue}`;

    this.existingContactSearchClick = true;
    this.isErrorNotify = false;
    this.noUerInfoDetails = false;
    this.loadSearchData = true;
    this.httpService.makePostWithFormData(action, '').subscribe((data: any) => {
      this.loadSearchData = false;
      if (data?.status === 'SUCCESS') {
        this.isSearchResultOpen = !this.isSearchResultOpen;
        this.contactSearchResults = data?.responseObject || [];
        if (data?.responseObject && data?.responseObject?.length === 0) {
          this.searchForm.controls['SEARCH_PATIENT_FIELD'].setValue('');
          this.notifyText = 'No patients found with the given details. Do you want to register a new patient?';
          this.isSuccessNotify = false;
          this.isErrorNotify = false;
          navigateNewAppointmentModal?.show();
        }
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        // navigateNewAppointmentModal?.show();
        this.isErrorNotify = true;
      }
      this.existingContactSearchClick = false;
      this.dtExistingTrigger.next();
    },
      error => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // navigateNewAppointmentModal?.show();
        this.isSuccessNotify = false;
        this.isErrorNotify = true;
        this.validName = true;
        // this.exceptionModal.modalShow();
        this.existingContactSearchClick = false;
        this.loadSearchData = false;
      });
  }


  /*public contactSearchCall(event): void {

    this.contactSearchResults = [];
    let searchValue = event.target.value;


    // let action: string = `patientSearch/${searchValue}`;
    const action = `userInfo/patientSearch/${searchValue}`;

    this.existingContactSearchClick = true;
    this.isErrorNotify = false;
    this.noUerInfoDetails = false;
    if (searchValue != '' && searchValue != null && searchValue != undefined) {
      this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
        if (data?.status === 'SUCCESS') {
          this.isSearchResultOpen = !this.isSearchResultOpen;
          this.contactSearchResults = data?.responseObject || [];
          if (data?.responseObject && data?.responseObject?.length === 0) {

            this.notifyText = 'No patients found with the given details. Do you want to register a new patient?';

            this.isErrorNotify = false;
      //this.exceptionModal?.modalShow();
    	
          }
        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          // navigateNewAppointmentModal?.show();
          this.isErrorNotify = true;
        }
        this.existingContactSearchClick = false;
        this.dtExistingTrigger.next();
      },
        error => {
          // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          // navigateNewAppointmentModal?.show();
          this.isErrorNotify = true;
          // this.exceptionModal.modalShow();
          this.existingContactSearchClick = false;
        });

    }
  }*/

  public formatPrimaryPhone(primaryPhone: string): string {
    return primaryPhone?.includes('+1') ? primaryPhone?.replace('+1', '') : primaryPhone;
  }
  public formatDob(dob: string) {
    let split = dob?.includes('T') ? dob?.toString()?.split('T') : dob;
    split = split && split[0] ? split[0] : split;
    return split || '';
  }

  public modifyUserData(userDetails: any, contactSearchModal: UiModalComponent): void {
    if (userDetails?.userInfoId) {
      //contactSearchModal?.hide();
      this.router?.navigate([`/hospital/user-data-update/${userDetails?.userInfoId}`]);
      this.clearForm();
    } else {
      this.noUerInfoDetails = true;
      this.userInfoError = 'Patient is not registered, please complete the patient registration';
    }
  }

  public contactSearchModal(): void {
    this.existingPatientSearchComp.openSearchModal();
  }


  public newAppointmentForm(patient: any): void {
    // sessionStorage?.setEstablishedPatient(patient);
    sessionStorage.removeItem('selectedPatientFlowBoard');
    sessionStorage.removeItem('establishedUserInfo');
    sessionStorage?.setItem('selectedPatientFlowBoard', JSON.stringify(patient));
    if (!patient?.userInfoId) {
      sessionStorage?.setItem('isNoAppnt', 'No');
    }
    this.navigateNewAppointment();
  }

  // Navigate to new appointment
  public navigateNewAppointment(navigateNewAppointmentModal?: UiModalComponent, isNoAppnt?: boolean): void {
    // if(this.patientRegForm?.get('typeRegistrationSelect')?.invalid && this.contactSearchResults?.length === 0) {
    //   this.patientRegForm?.get('typeRegistrationSelect')?.markAllAsTouched();
    //   return;
    // }

    if (navigateNewAppointmentModal) {
      navigateNewAppointmentModal?.hide();
    }
    if (isNoAppnt) {
      sessionStorage?.setItem('isNoAppnt', 'No');
      sessionStorage?.removeItem('establishedUserInfo');
      sessionStorage.removeItem('selectedPatientFlowBoard');
    } else if (this.patientRegForm?.get('typeRegistrationSelect')?.value === 'No') {
      sessionStorage?.setItem('isNoAppnt', 'No');
    }

    if (window.location.href.includes('addAppt')) {
      location.reload();
    }
    else {
      this.router.navigate(['/hospital/addAppt']);
    }
    this.patientRegForm?.reset();
    //location.reload();

  }

  /*public modifyUserData(appointment: any): void {
    if (appointment?.userInfoId) {
      this.router?.navigate([`/hospital/user-data-update/${appointment?.userInfoId}`]);

    } else {
      //this.userInfoError = 'Patient is not registered, please complete the patient registration';
      alert("Patient is not registered, please complete the patient registration");
    }
  }*/

  public sendNotification(patient: any, contactSearchModal: UiModalComponent): void {
    this.sendNotifyComp?.showNotifyModal(patient, 'user');
    this.clearForm();
  }

  public clearForm(): void {
    if (this.inputName && this.inputName.nativeElement) {
      this.inputName.nativeElement.value = '';
      // this.inputName.nativeElement.classList.add('col-md-8');
      this.searchForm?.reset();
      this.contactSearchResults = [];
      this.noUerInfoDetails = false;
      this.isErrorNotify = false;
      this.isSearchResultOpen = false;
    }
    /*window.addEventListener("click", function(){
    
    });*/
    //document.getElementById("searchclose").click();

  }

  onBlurMethod() {
    this.clearForm();


  }

  inputBlurMethod(event) {


    if (event.target.value === '') {
      event.target.classList.add('col-md-8');
      this.clearForm();

    }
    else {
      event.target.classList.remove('col-md-8');

    }
  }

  changesize(event) {
    event.target.classList.remove('col-md-8');
    this.enableAction = false;
  }

  public totalNotificationsCount(event: any[]): void {
    this.notificationsCount = event || []
  }
  public openNewSearchPage() {
    //alert(this.searchForm.controls['SEARCH_PATIENT_FIELD'].value);
    this.inputName.nativeElement.value = '';
    this.router.navigate(['/hospital/detailed-search']);
  }

  public openTaskModal(appointment: any): void {
    if (!appointment?.userInfoId) {
      this.noUerInfoDetails = true;
      this.userInfoError = 'Patient is not registered, please complete the patient registration';
      return;
    }
    this.noUerInfoDetails = false;
    this.newTakComp?.showTaskModal(appointment);
    this.clearForm();
  }
  public toggleNotificationsView() {
    this.notificationsView.resetNotifications();
    this.enableAction = false;
  }
  public enableAcctions() {
    this.enableAction = !this.enableAction;
  }
  public clearOldAppointment() {
    sessionStorage?.removeItem('isNoAppnt');
    sessionStorage?.removeItem('establishedUserInfo');
    sessionStorage?.removeItem('selectedPatientFlowBoard');
  }
  public openSmsPopUp(sendAdHocSmsPatient: UiModalComponent) {
    sendAdHocSmsPatient?.show();
    this.enableAcctions();
  }

  // New Outreach Message API calls
  // get notofication message based on type selection
  public getMessageBody(sendAdHocSmsPatient: any): void {
    if (this.adhocSmsForm?.invalid) {
      this.adhocSmsForm?.markAllAsTouched();
      return;
    }
    const requestParams = {
      "facilityId": this.loginDetails?.facilityId,
      "firstName": this.adhocSmsForm?.value?.firstName,
      "phoneNumber": '+1' + this.adhocSmsForm?.value?.smsPhoneNumber || null,
      "messageType": 'New Patient Registration'
    }
    this.loadSpinner = true;
    this.apiService?.getOutreachConfigMessage(requestParams)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.body?.status === 'SUCCESS') {
        this.adhocSmsForm?.get('message')?.setValue(data?.body?.responseObject);
        this.sendCareOutreachNotification(sendAdHocSmsPatient)
      }
    }, (error) => {
      this.loadSpinner = false;
    });
  }

  // Post an Notification
  public sendCareOutreachNotification(sendAdHocSmsPatient?: UiModalComponent): void {
    const requestParams = {
      "facilityId": this.loginDetails?.facilityId,
      "firstName": this.adhocSmsForm?.value?.firstName,
      "phoneNumber": '+1' + this.adhocSmsForm?.value?.smsPhoneNumber || null,
      "messageType": 'New Patient Registration',
      "messageBody": this.adhocSmsForm?.get('message')?.value,
    }
    this.loadSpinner = true;
    this.apiService?.postNotificationMessage(requestParams)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        this.notifyText = 'SMS has been sent successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.adhocSmsForm?.reset();
        sendAdHocSmsPatient?.hide();
      }
      this.loadSpinner = false;
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // open Facility payment 
  public openFacilityPayment(): void {
    this.facilityPayComp?.openModal();
    this.enableAcctions();
  }

  // to get payment config details
  private getPaymentConfig(): any {
    this.apiService?.getPaymentConfig(this.loginDetails?.facilityId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentConfigDetails = data?.responseObject;
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      });
  }
  

  public async openPayment(appointment?: any, isPatientResp?: boolean, isAction?: boolean) {
   // This is Temp fix for Account level payemtn
   let payObj = {
    'userInfoId': appointment.userInfoId,
    'id': appointment?.id,
    'facilityId': this.loginDetails?.facilityId
  }
  let statusVal = true;
  this.userInfoId = appointment.userInfoId
  if(appointment){
    payObj = {...appointment};
    statusVal = isPatientResp;
  }    
  this.getUserInfoDetails(this.userInfoId,payObj,statusVal);
  }

  private getUserInfoDetails(user,payObj,statusVal): void {
    // const action = `userInfo/getUserInfo?id=${this.userInfoId}`;
    if(user){
    const action = `common/getChartInfo?userInfoId=${user}&chartModuleName=UserInfo`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        // this.userInfoForUser = JSON?.parse(JSON?.stringify(data?.responseObject));
        // this.userPatientEmrId = data?.responseObject && data?.responseObject?.patientId;
        this.userInfoData = (data?.responseObject && data?.responseObject?.userInfo) || null;
        this.payComp?.openModal(payObj, statusVal);
      }
    })
  }
  }

}
