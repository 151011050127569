import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-visit-type-update',
  templateUrl: './visit-type-update.component.html',
  styleUrls: ['./visit-type-update.component.scss']
})
export class VisitTypeUpdateComponent implements OnInit {

  public visitTypeForm: FormGroup;
  public appoitnmentDetails: any;
  @ViewChild('visitTypeModal') visitTypeModal: UiModalComponent;
  // public addPatientPurposeVisit: any[];
  public adPatientAllDoctors: any[];
  public loadSpinner: boolean;
  public notifyText: string;
  public loginDetails: any;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;

  @Output() reloadAppointmentQueue = new EventEmitter();
  public addPatientTypeOfService = [
    { value: 'Regular Visit', desc: 'Regular Visit' },
    { value: 'Tele-Health', desc: 'Tele-Health' }
  ]

  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
    this.visitTypeForm = this.formBuilder?.group({
      'SERVICE_TYPE': [null, Validators?.required],
      // 'doctor': [null, Validators?.required]
    });
  }

  ngOnInit(): void {
    const waitTimeDetails = JSON.parse(sessionStorage?.getItem('waitTimeDetails'));
  }

  // get doctors list based on purpose of visit
  /*public getDoctorsList(): void {
    const typeOfService = this.visitTypeForm?.value?.SERVICE_TYPE || '';
    // this.visitTypeForm?.get('doctor')?.reset();
    const action = `npi/getvalidNPIforvisit?facilityId=${this.appoitnmentDetails.facilityId}&typeOfService=`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action, typeOfService).subscribe((data: any) => {
      this.adPatientAllDoctors = (data && data.npiDoctorList) || [];
      this.loadSpinner = false;
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
    });
  }*/

  public getServiceListList(): void {

  }


  public openModal(appoitnmentDetails: any): void {
    this.appoitnmentDetails = appoitnmentDetails;
    if (appoitnmentDetails?.appointment?.virtualVisit === true) {
      this.visitTypeForm?.get('SERVICE_TYPE')?.setValue('Tele-Health');
    }
    else {
      this.visitTypeForm?.get('SERVICE_TYPE')?.setValue(appoitnmentDetails?.appointment?.typeOfService);
    }
    this.getServiceListList();

    this.visitTypeModal?.show();
  }

  public updateVisitType(): void {
    var visitType = this.visitTypeForm?.get('SERVICE_TYPE')?.value;

    const action = 'appointment/registration/changeVisitType?id=' + this.appoitnmentDetails.id + '&visitType=' + visitType;

    /*const action = 'appointment/registration/changeVisitType';
    const params = { "id": this.appoitnmentDetails.id, "visitType": visitType };*/

    if (this.visitTypeForm?.invalid) {
      this.visitTypeForm?.markAllAsTouched();
      return;
    }

    /*if (visitType === this.appoitnmentDetails.typeOfService) {
        this.visitTypeModal?.hide();
        return;
      }*/
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        this.notifyText = "Service Type/Visit Type has been updated successfully.";
        this.exceptionModal?.modalShow();
        this.isSuccessNotify = true;
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal?.modalShow();
        this.isSuccessNotify = false;
      }
      this.loadSpinner = false;
      this.visitTypeModal?.hide();
      this.reloadAppointmentQueue?.emit(true);
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        this.reloadAppointmentQueue?.emit(true);
        this.isSuccessNotify = false;
      });
  }

}
