import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../services/data-sharing.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate {
  isLogin: any;

  constructor(public dataService: DataService, public router: Router){
    this.dataService.currentMessage.subscribe((data) => this.isLogin = data.isLogin)
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.isLogin || sessionStorage.getItem('isLogin') === 'true'){
      this.router.navigate([this.router.url]);
    }else{
      return true
    }
  }
}
