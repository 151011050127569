<div class="account-balance" [ngClass]="{'toggle-expanded': addCss}">
  <div class="table-responsive new-designed-table table-enc">
    <table style="width: 100% !important;" datatable [dtOptions]="dtOptionsPendingPayments" [dtTrigger]="dtTriggerPendingPayments"
      class="table table-striped table-bordered table-hover">
      <thead>
        <tr>
          <th>Visit Info</th>
          <!-- Not visisble field for service date filter -->
          <th>Service Date</th>
          <!-- <th>Purpose <br /> <span style="display: block;line-height: 1;"> of visit</span></th> -->
          <th>Billed <br /> <span style="display: block;line-height: 1;">Charges</span></th>
          <th>Allowed <br /> <span style="display: block;line-height: 1;">Amount</span></th>
          <th>Payor <br /> <span style="display: block;line-height: 1;">Payments</span></th>
          <th>Patient <br /> <span style="display: block;line-height: 1;">Payments</span></th>
          <!-- <th>Reimbursed <br/><span style="display: block;line-height: 1;">Amount</span></th> -->
          <th>Balance</th>
          <!-- <th>Credits <br /> <span style="font-size: 10px;display: block;line-height: 1;">(Generated)</span></th> -->
          <th>Credits <br /><span style="font-size: 10px;display: block;line-height: 1;">(Available)</span></th>
          <th>Claim Date</th>
          <th>Status</th>
          <th>EOB</th>
          <th class="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of allPendingPayments?.paymentsDetails; let i=index">
          <td style="white-space: inherit;">{{(payment?.serviceDate | yyymmdd) || '--'}}
            <p class="purpose-of-visit"><b>{{payment?.purposeOfVisit}}</b> </p>
          </td>
          <!-- <td>{{payment?.serviceDate ? httpService?.formatDobTimeZoneWithDisplay(payment?.serviceDate) : '--'}}</td> -->
          <!-- Not visisble field for service date filter -->
          <td>{{(payment?.serviceDate | yyymmdd) || '--'}}</td>
          <!-- <td>{{payment?.purposeOfVisit}}</td> -->
          <td>$ {{payment?.totalCharges | number : '1.2-2'}}</td>
          <!-- Allowed Amount -->
          <td>
            $ {{payment?.approvedAmount | number : '1.2-2'}}
          </td>
          <!-- Payor Payments -->
          <td>
            $ {{payment?.reimburseAmount | number : '1.2-2'}}
          </td>
          <!-- Patient Payments -->
          <td>$ {{payment?.coPay | number : '1.2-2'}}</td>
          <!-- <td class="tooltip-show">
            <button type="button"
              [ngClass]="payment?.patientResponsibility != 0 ?'icon-queue-events btn btn-status-cancel btn-popup':'icon-queue-events signoff-action-bg btn-popup'"
              data-toggle="tooltip" data-placement="top">
              <i class="material-symbols-outlined">
                price_change
              </i>
            </button>
            <div class="show-text text-3">
              <span> <b>Total Visit Charges: </b>{{payment?.totalCharges}}</span>
              <span> <b> Amount paid :</b> {{payment?.coPay}} </span>
              <span> <b> Approved amount :</b> {{payment?.approvedAmount}} </span>
              <span> <b> Reimbursed amount : </b>{{payment?.reimburseAmount}}</span>
              <span> <b> Outstanding Balance:</b> {{payment?.patientResponsibility}} </span>
            </div>
          </td> -->
          <td>
            $ {{payment?.patientResponsibility | number : '1.2-2'}}
          </td>
          <!-- <td>
            $ {{payment?.credits | number : '1.2-2'}}
          </td> -->
          <td>
            $ {{payment?.remainingCredits | number : '1.2-2'}}
          </td>
          <td>{{payment?.claimSubmittedDate ? (payment?.claimSubmittedDate | yyymmdd) : 'NA'}}</td>
          <td>
            <button
              [ngClass]="payment?.status == 'Completed' || payment?.status == 'COMPLETED' ?'btn btn-status-success disable':'btn btn-status-cancel disable'">{{payment?.status}}</button>
          </td>
          <td>
            <!-- <button class="icon-queue-events signoff-action-bg" [disabled]="!payment?.documents" placement="bottom" ngbTooltip="EOB Document"
              (click)="viewEOBDocument(payment?.documents, eobDocumentView)"><i class="fa fa-file-pdf"></i></button>
             -->

            <!--<button class="icon-queue-events signoff-action-bg"  placement="bottom" ngbTooltip="EOB Document"
              (click)="downloadERAPDF(payment?.appointmentId,viewEobAccBal)"><i class="fa fa-file-pdf"></i></button>
            
              -->
            <button class="icon-queue-events signoff-action-bg" placement="bottom" ngbTooltip="EOB Document"
              (click)="checkDownloadEob(payment?.documents, payment?.appointmentId, payment?.claimNumber ,viewEobAccBal, eobDocumentView);"><i
                class="fa fa-file-pdf"></i></button>
          </td>
          <td class="text-center">
            <div class="dropdown-actions">
              <button
                class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-account-selected':''}}"
                id="claimActions_btn" (click)="openUniversalActions($event, i)">...</button>
              <div class="dropdown-content-actions-account" [class.noPayCj]="payClaimJournalNotRequired" *ngIf="openActions && selectedActionRow === i">
                <a href="javascript:" [class.a-disabled]="payment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                  (click)="openPostChangesUpdate(payment?.appointmentId, i, payment)" id="pendingPayment_postcharges"><i
                    class="las la-dollar-sign" ngbTooltip=" Post Charges"></i> Post Charges</a>
                <!-- <a href="javascript:"
                      (click)="closeUniversalActions();"><i
                          class="las la-user-circle"></i> Pay</a> -->
                <a href="javascript:" *ngIf="!payClaimJournalNotRequired" (click)="openPayment(payment,true,true);" id="pendingPayment_pay"><i
                    class="las la-credit-card" ngbTooltip="Pay"></i>Pay</a>
                <a href="javascript:" *ngIf="!payClaimJournalNotRequired" (click)="openClaimJournal(payment);" id="pendingPayment_claimJournal"><span
                    class="material-symbols-outlined" ngbTooltip="Claim Journal"> note_alt</span>Claim Journal</a>
              </div>
            </div>
            <!-- <button class="icon-queue-events signoff-action-bg" placement="bottom" ngbTooltip="Post Charges"
              (click)="openPostChangesUpdate(payment?.appointmentId, i)">...</button> -->
            <!-- <button class="icon-queue-events signoff-action-bg" placement="bottom" ngbTooltip="Post Charges"
              (click)="openViewAccountBalance()"><i
                class="las la-dollar-sign"></i></button> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive">
    <table class="table table-bordered balance_summary">
      <tbody>
        <tr>
          <td>
            Summary
          </td>
          <td>
            <h1>$ {{allPendingPayments?.accountBalanceSummary?.totalCharges | number : '1.2-2'}}</h1>
            <!-- <span>Total Charges</span> -->
            <span>Billed Charges</span>
          </td>
          <td>
            <h1>$ {{allPendingPayments?.accountBalanceSummary?.totalApproved | number : '1.2-2'}}</h1>
            <span>Allowed Amount</span>
          </td>
          <td>
            <h1>$ {{allPendingPayments?.accountBalanceSummary?.totalARInsurance | number : '1.2-2'}}</h1>
            <!-- <span>Insurance Reimbursements</span> -->
            <span>Payor Payments</span>
          </td>
          <td>
            <h1>$ {{allPendingPayments?.accountBalanceSummary?.totalCoPay | number : '1.2-2'}}</h1>
            <!-- <span>Co-Pay/Co-Insurance Paid</span> -->
            <span>Patient Payments</span>
          </td>
          <td>
            <h1>$ {{allPendingPayments?.accountBalanceSummary?.totalARPatient | number : '1.2-2'}}</h1>
            <span>Balance</span>
          </td>
          <td>
            <h1>$ {{allPendingPayments?.accountBalanceSummary?.totalCreditsAvailable | number : '1.2-2'}}</h1>
            <span>Credits Available</span>
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</div>
<!-- <div class="preview" *ngIf="loader">
    <div class="row">
      <div class="col-md-12">Loading ....</div>
    </div>
  </div> -->
  <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [claimSubmitNotifyText]="claimSubmitNotifyText"
  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Late Notify PopUp -->
<app-send-sms-notify [noDelay]="true" [pageType]="'Chart'"></app-send-sms-notify>
<!-- Square Payment Card -->
<!-- <app-payments-common-new [paymentConfigDetails]="paymentConfigDetails"
  (patientRespCreditsUpdate)="refresh()" [recieptID]="'pending-payments-reciept-id'"></app-payments-common-new> -->

<app-payments-refactor [paymentConfigDetails]="paymentConfigDetails" (patientRespCreditsUpdate)="refresh()"
  [recieptID]="'pending-payments-reciept-id'"></app-payments-refactor>

<app-ui-modal #eobDocumentView [hideHeader]="false" [modalCentered]="true" id="eobDocumentViewId"  [loadSpinner]="loadSpinner">
  <div class="app-modal-header">
    <h5 class="modal-title">EOB Document</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="eobDocumentView.hide();clearIframeSrc()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="documents mb-3">
      <ng-container *ngFor="let document of allEobDouemnts; let i = 'index+1'">
        <button class="icon-queue-events signoff-action-bg" [class.active]="i === eobViewIndex" placement="right"
          (click)="viewDownloadDocument(document, eobDocumentView, i)" [ngbTooltip]="'EOB Document ' + i"><i
            style="color: #5ca6db;" class="fa fa-file-pdf"></i></button>
      </ng-container>
    </div>
    <iframe id="eob-document-view" frameBorder="0" width="100%" height="500px"></iframe>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
      id="pendingPayment_ok1" (click)="eobDocumentView.hide();clearIframeSrc()">Ok</button>
  </div>
</app-ui-modal>

<!-- Detailed Account Balance - Post Charges -->
<app-post-charges-update [claimsStatusCard]="updateUserdataCard" (callPrevNxt)="callPostChargesPrevNext($event)"
  [noSaveButton]="true" [noPayOption]="false" (callPendingPayments)="getPendingPaymentsByUser(userInfoId)"
  [patientID]="patientID" (reloadPendingPayments)="getPendingPaymentsByUser(userInfoId)" [recieptAbId]="recieptAbId" [isClaimPage]="isClaimPage"></app-post-charges-update>

<!-- Download EOB -->

<app-ui-modal #viewEobAccBal [hideHeader]="false" [modalCentered]="true" id="viewEobAccBal" class="common-modal">
  <div class="app-modal-header">
    <h5 class="modal-title">Explanation of Benefits (EOB)</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="viewEobAccBal?.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body add-app-body">
    <iframe id="eob-pdf-accbal" frameBorder="0" width="100%" height="500px"></iframe>

  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
      id="pendingPayment_ok2" (click)="viewEobAccBal?.hide()">Ok</button>
  </div>
</app-ui-modal>

<!-- Claim Journal -->
<app-claim-journal [isFromChart]="isFromChart"></app-claim-journal>