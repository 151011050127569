import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-internal-lab-order-results-modal',
  templateUrl: './internal-lab-order-results-modal.component.html',
  styleUrls: ['./internal-lab-order-results-modal.component.scss']
})
export class InternalLabOrderResultsModalComponent implements OnInit {

  @ViewChild('orderResultsModal') public orderResultsModal: UiModalComponent;

  @Input() orderType: string;
  @Input() selectedOrderDetails: any;
  @Input() cardComp: CardComponent;
  public internalOrder: any;
  public loadSpinner: boolean;

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {

    }
  }

  public openorderResultsPopup(orderType: string, selectedOrderDetails?: any,): void {
    this.orderType = orderType;
    this.selectedOrderDetails = selectedOrderDetails || null;
    this.getInternalLabResults();
    this.orderResultsModal?.show();
  }

  // to get the internal lab order results
  public getInternalLabResults(): void {
    const action = `changeHealth/getOrderinfobyOrder?location=${this.selectedOrderDetails?.location}&orderNumber=${this.selectedOrderDetails?.orderNumber}&testType=${this.orderType}`;
    this.cardComp?.cardRefreshShow();
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.internalOrder = data?.responseObject?.internalLabdetails || {};
      }
      this.cardComp?.cardRefreshHide();
      this.loadSpinner = false;
    },
      (error) => {
        this.cardComp?.cardRefreshHide();
        this.loadSpinner = false;
      })
  }

  public getHcgResult(result: any) {
    let note: string = '';
    result = parseInt(result);
    if (result || result === 0) {
      if (result <= 5) note = 'Negative';
      else if (result >= 6 && result <= 24) note = 'Retest';
      else if (result >= 25) note = 'Positive';
      return note;
    }
  }
  
}
