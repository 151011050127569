<div class="summary-ummunization-data">
    <div class="vaccination-sec">
        <ng-conatiner *ngFor="let vaccine of immunizationData">
            <div class="inhouseLabs-bg">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Order Name</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.orderName || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Order Type</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.orderType || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Vaccine Date Time</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.vaccineDateTime ?
                                    httpService?.getAppointmentTime(vaccine?.vaccineDateTime) : '--' ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Lot #</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.lot || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Vaccine Expiry Time</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.expiryDate ?
                                    httpService?.formatDobTimeZoneWithDisplay(vaccine?.administrationDetails?.expiryDate)
                                    : '--' || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">NDC ID</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.ndcId || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">CPT Code</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.cptCode || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Admin Code</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.adminCode || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Strength</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.strength || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Measurement</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.measurement || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Dose</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.dose || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Units</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.units || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Route</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.route || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Site</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.site || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Vaccine Priority</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.vaccinePriority || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Registery</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.registry || '--'}}</label>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Administartion Details -->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5>Administration Details</h5>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h6 class="mt-2"><b>With NPI</b></h6>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.npiDetails?.performedBy ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.npiDetails?.dateTime ?
                                    httpService?.getAppointmentTime(vaccine?.administrationDetails?.npiDetails?.dateTime)
                                    : '--' || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.npiDetails?.reason ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h6 class="mt-2"><b>Consent Documented</b></h6>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.consentDetails?.performedBy ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.consentDetails?.dateTime ?
                                    httpService?.getAppointmentTime(vaccine?.administrationDetails?.consentDetails?.dateTime)
                                    : '--' || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.consentDetails?.reason ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h6 class="mt-2"><b>Education Materials Provided</b></h6>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.educationDetails?.performedBy ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.educationDetails?.dateTime ?
                                    httpService?.getAppointmentTime(vaccine?.administrationDetails?.educationDetails?.dateTime)
                                    : '--' || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.educationDetails?.reason ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle"><b>Comments/Notes</b></label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-9 col-md-9 col-sm-6"> -->
                                <label>{{vaccine?.administrationDetails?.administrationNote ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-conatiner>
        <ng-conatiner *ngIf="!immunizationData || immunizationData?.length === 0">
            <span>Inhouse Injections are not applicable for this patient’s record.</span>
        </ng-conatiner>
    </div>
    <div class="inhouseLabs-bg">
        <!-- Vaccine Allergies -->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Allergies</h5>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Reaction</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="allergiesFromVaccine?.length > 0">
                                <tr *ngFor="let allergy of allergiesFromVaccine">
                                    <td>{{allergy?.name || '--'}}</td>
                                    <td>{{allergy?.reaction || '--'}}</td>
                                    <td>{{allergy?.documentedDate ?
                                        httpService?.formatDobTimeZoneWithDisplay(allergy?.documentedDate) :
                                        '--'}}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngIf="allergiesFromVaccine?.length === 0">
                                <tr>
                                    <td colspan="3" class="text-center">No allergies have been entered on this patient's
                                        record.</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>