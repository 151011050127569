<div class="container"
  *ngIf="this.flatConfig.layout === 'horizontal' && this.flatConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div class="m-header" (click)="this.enableAction = false">
    <!-- <a [routerLink]="['/hospital/hosp-dashboard']" class="b-brand"> -->
    <span class="logo"> </span>
    <span class="title"></span>
    <img src="assets/images/logo-icon.png" alt="" class="logo-thumb">
    <!-- </a> -->
    <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()"><i
        class="feather icon-more-vertical"></i></a>
    <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
  </div>


  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <!-- <app-nav-left class="mr-auto"></app-nav-left> -->
    <!--<app-wait-time class="mr-auto"></app-wait-time>-->


    <!--<div class="row wait-time-actions-sec">
  <ng-container *ngIf="!checkIsNoAccess">
    <div class="col-lg-6 col-md-6 col-sm-6 wait-time">
      <i class="las la-hourglass-half"></i> Wait Time : {{(waitTimeDetails?.waitTime | number :'1.0-0') || 'N/A'}} &nbsp;
      
    </div>
  </ng-container>

  
</div>-->
    <div class="facility-right-actions" id="searchclose" (click)="this.enableAction = false">
      <div class="sub-sec-search">
        <ng-container *ngIf="!checkIsNoAccess && isShowHideElements['NewAppointment']">
          <div class="wait-time">
            <i class="las la-hourglass-half"></i> Wait Time : {{(waitTimeDetails?.waitTime | number :'1.0-0') ||
            'N/A'}}&nbsp;
          </div>
        </ng-container>
        <div class=""   [ngClass]="facilityId == 91 ? 'facility-name-healthworks' : 'facility-name'">
          <h4>{{facilityName}}</h4>
        </div>

        <!--  search box -->
        <ng-container *ngIf="isShowHideElements['NewAppointment']">
          <div class="searh-action-sec drp-user dropdown" #dropClose="ngbDropdown" ngbDropdown placement="auto">
            <div class="row" [formGroup]="searchForm">
              <div ngbDropdownToggle class="col-lg-12 col-md-12 col-sm-12 ">
                <!--<span class="add-on" (click)="contactSearchCall(navigateNewAppointmentModal)" style="cursor:pointer;top:0px;bottom:5px;"><i
                    class="fa fa-search"></i></span>-->
                <input #searchElement type="text" id="navBarSearchPatient" [attr.minlength]="isValueNumber ? 10 : ''"
                  [attr.maxlength]=" isValueNumber && isNumber  ? 10: ''"
                  class="nosubmit form-control input-text searchInput" formControlName="SEARCH_PATIENT_FIELD"
                  name="searchElement" placeholder=" Search by Name, Phone Number, DOB, or Patient ID"
                  (keyup)="isValidDOB();" (keyup.enter)="contactSearchCall(navigateNewAppointmentModal);"
                  autocomplete="disabled">

              </div>
              <div class="dropdown-menu-right search-box-right profile-notification search-dropdown-notify"
                ngbDropdownMenu
                [ngClass]="{'show dropdown': ( this.searchForm.controls['SEARCH_PATIENT_FIELD'].value && (this.searchForm.controls['SEARCH_PATIENT_FIELD'].value.length > 0))}">

                <div class="pro-head">
                  <div class="row">
                    <div class="col-12">
                      <h5>Search Results</h5>
                      <div class="redirectIcon">

                        <a class="link-color" (click)="openNewSearchPage(); dropClose.close();"> <i
                            class="las la-external-link-alt"></i></a>
                        &nbsp;
                        <a (click)="onBlurMethod(); dropClose.close();">
                          <i class="las la-times"></i>
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
                <ul class="pro-body" style="margin-left: 0px; padding-left: 0px;padding-bottom: 0;">

                  <div class="error-user-info" *ngIf="noUerInfoDetails">
                    <div class="text-danger" style="margin-bottom: 10px;">{{userInfoError}}</div>
                  </div>
                  <ng-container *ngIf="!isValueNumber">
                    <div class="required text-center"
                      *ngIf="searchForm.get('SEARCH_PATIENT_FIELD').invalid && !searchForm.get('SEARCH_PATIENT_FIELD').errors?.required">
                      Enter a valid Last Name,First Name or Last Name or Patient ID
                    </div>
                  </ng-container>

                  <ng-container *ngIf="validName">
                    <div class="required text-center">
                      Enter a valid Last Name,First Name or Last Name or Patient ID
                    </div>
                  </ng-container>

                  <ng-container *ngIf="isValueNumber && !isDateFormat">
                    <div class="required text-center"
                      *ngIf="searchForm.get('SEARCH_PATIENT_FIELD').invalid && !searchForm.get('SEARCH_PATIENT_FIELD').errors?.required">
                      Please enter 10 digits for Phone Number search.
                    </div>
                  </ng-container>

                  <ng-container *ngIf="isDateFormat && notValidDob">
                    <div class="required text-center">
                      Enter a Valid Date of Birth (mm/dd/yyyy)
                    </div>
                  </ng-container>

                  <div class="error-user-info"
                    *ngIf="(searchForm.controls['SEARCH_PATIENT_FIELD'].value === null || !contactSearchResults || contactSearchResults.length == 0) && !searchForm.get('SEARCH_PATIENT_FIELD')?.value">
                    <div style="margin-left:15px;" class="text-center">Search by Name (First or Last Name), Full Name
                      (Last Name, First Name), Phone Number (10-digits), Date of Birth (MM/DD/YYYY), or Patient ID</div>
                  </div>
                  <div class="table-responsive new-designed-table" style="position:relative;">
                    <table class="table table-hover search-results-table">
                      <tbody>
                        <ng-container>
                          <tr class="patient-records" *ngFor="let contact of contactSearchResults">
                            <td class="result-column">
                              <span class="user-name-col">{{contact?.lastName | titlecase}},
                                {{contact?.firstName | titlecase}}
                              </span>
                            </td>
                            <td class="result-column ph-col">{{formatPrimaryPhone(contact?.patientPrimaryPhone)}}</td>
                            <td class="result-column dob-col">{{formatDob(contact?.dob) | date:'MM/dd/yyyy'}}</td>
                            <td class="existing-fb-actions result-column">
                              <button class="sendsms icon user-avatar" style="cursor: pointer;" id="navBarChart"
                                (click)="(contact?.userInfoId ? dropClose.close() : ''); modifyUserData(contact, contactSearchModal);">
                                <i class="las la-user-circle"></i>
                                <div class="profile-tooltip">
                                  <p>Chart</p>
                                  <span class="material-symbols-outlined arrow">
                                    arrow_drop_up
                                  </span>
                                </div>
                              </button>
                              <!-- <span class="new-appointment sendsms icon" placement="bottom" ngbTooltip="Chart"
                                style="cursor: pointer;" (click)="dropClose.close(); modifyUserData(contact, contactSearchModal);">
                                <img src="../../../../../assets/images/flowboardIcons/farmSearch/ic_farm.svg" />
                                <i class="las la-user-circle"></i>
                              </span> -->

                              <button class="sendsms icon user-avatar"
                                style=" cursor: pointer;" id="navBarNewAppointment"
                                (click)="dropClose.close(); newAppointmentForm(contact);clearForm()">
                                <i class="las la-calendar-plus"></i>
                                <div class="profile-tooltip">
                                  <p>New Appointment</p>
                                  <span class="material-symbols-outlined arrow">
                                    arrow_drop_up
                                  </span>
                                </div>
                              </button>
                              <!-- <span class="sendsms icon" placement="bottom" ngbTooltip="New Appointment"
                                style="cursor: pointer;"
                                (click)="dropClose.close(); newAppointmentForm(contact);clearForm()">
                                <img src="../../../../../assets/images/flowboardIcons/farmSearch/ic_add_appointment.svg" />
                                <i class="las la-calendar-plus"></i>
                              </span> -->

                              <button class="sendsms icon user-avatar"
                                style=" cursor: pointer;" id="navBarSendSms"
                                (click)="$event.stopPropagation(); sendNotification(contact, contactSearchModal)">
                                <i class="lab la-telegram"></i>
                                <div class="profile-tooltip">
                                  <p>Send SMS</p>
                                  <span class="material-symbols-outlined arrow">
                                    arrow_drop_up
                                  </span>
                                </div>
                              </button>
                              <!-- <span class="sendsms icon" style="margin-left: 20px;cursor: pointer;"
                                (click)="$event.stopPropagation(); sendNotification(contact, contactSearchModal)"
                                placement="bottom" ngbTooltip="Send SMS"><i class="lab la-telegram"></i></span> -->

                              <button class="sendsms icon user-avatar" style="cursor: pointer;"
                                id="navBarTask"
                                (click)="(contact?.userInfoId ? dropClose.close() : '');openTaskModal(contact)">
                                <i class="la la-tasks"></i>
                                <div class="profile-tooltip">
                                  <p>Task</p>
                                  <span class="material-symbols-outlined arrow">
                                    arrow_drop_up
                                  </span>
                                </div>
                              </button>

                              <button *ngIf="!contact?.userInfoId" class="sendsms icon user-avatar" style=" cursor: pointer;" id="navBarPatientRegister"
                                (click)="dropClose.close(); newAppointmentForm(contact);clearForm()">
                                <i class="las la-user-plus"></i>
                                <div class="profile-tooltip">
                                  <p>Patient Register</p>
                                  <span class="material-symbols-outlined arrow">
                                    arrow_drop_up
                                  </span>
                                </div>
                              </button>

                              <button class="sendsms icon user-avatar" style="cursor: pointer;"
                                id="navBarPay"
                                (click)="(contact?.userInfoId ? dropClose.close() : '');openPayment(contact,true,true);">
                                <i class="las la-credit-card"></i>
                                <div class="profile-tooltip">
                                  <p>Pay</p>
                                  <span class="material-symbols-outlined arrow">
                                    arrow_drop_up
                                  </span>
                                </div>
                              </button>
                              <!-- <span class="sendsms icon" style="margin-left: 20px;cursor: pointer;"
                                (click)="openTaskModal(contact)"
                                placement="bottom" ngbTooltip="Task"><i class="la la-tasks"></i></span> -->

                            </td>

                            <!-- if not user info id -->
                            <!-- <td class="existing-fb-actions result-column" *ngIf="!contact?.userInfoId">
                              <div class="new-appointment icon noUserId" placement="bottom" ngbTooltip="Patient Register"><i
                                  style="font-size: 20px; cursor: pointer;"
                                  (click)="dropClose.close(); newAppointmentForm(contact);clearForm()"
                                  class="las la-user-plus"></i></div>
                            </td> -->
                          </tr>
                          <!-- <tr>
                            <td style="background-color: #fff;" colspan="4"></td>
                            <td style="background-color: #fff;" colspan="4"></td>
                            <td style="background-color: #fff;" colspan="4"></td>
                          </tr> -->
                        </ng-container>
                      </tbody>
                    </table>
                    <div class="card-loader justify-content-center loadcenter" *ngIf="loadSearchData">
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <!--<div
              class="dropdown-menu-right search-box-right profile-notification search-dropdown-notify dropdown-menu show"
              *ngIf="contactSearchResults && contactSearchResults.length > 0 && isSearchResultOpen">
              <div class="" style="height: auto;" #searchResult>
                <div class="title existing-fb-actions">
                  <h5 style="float: left;padding-top: 6px;">Search Results</h5>
                  <a class="redirectIcon" (click)="openNewSearchPage()"> <i class="las la-external-link-alt" ></i></a> &nbsp;
				  <a class="closeIcon" (click)="onBlurMethod()"> X </a> 
					
                </div>
                <div class="error-user-info" *ngIf="noUerInfoDetails">
                  <div class="text-danger" style="margin-bottom: 10px;">{{userInfoError}}</div>
                </div>
                <div class="table-responsive new-designed-table">
                  <table class="table table-hover">
                    <tbody>
                      <ng-container>
                        <tr *ngFor="let contact of contactSearchResults">
                          <td class="result-column">{{contact?.firstName}} {{contact?.middleName}} {{contact?.lastName}}
                          </td>
                          <td class="result-column">{{formatPrimaryPhone(contact?.patientPrimaryPhone)}}</td>
                          <td class="result-column">{{formatDob(contact?.dob) | date:'MM/dd/yyyy'}}</td>
                          <td class="existing-fb-actions result-column">
                            <span class="new-appointment icon" placement="bottom" ngbTooltip="Chart" style="cursor: pointer;"
                              (click)="modifyUserData(contact, contactSearchModal)"><img
                                src="../../../../../assets/images/flowboardIcons/farmSearch/ic_farm.svg" /></span>
                            <span class="new-appointment icon" placement="bottom" ngbTooltip="New Appointment" style="margin-left: 20px;cursor: pointer;"
                              (click)="newAppointmentForm(contact);clearForm()"><img
                                src="../../../../../assets/images/flowboardIcons/farmSearch/ic_add_appointment.svg" />
                              </span>
                            <span class="sendsms icon" style="margin-left: 20px;cursor: pointer;"
                              (click)="$event.stopPropagation(); sendNotification(contact, contactSearchModal)"
                              placement="bottom" ngbTooltip="Send SMS"><i class="lab la-telegram"></i></span>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>-->
          </div>
        </ng-container>
      </div>
    </div>
    <!-- || isCreateInvoice['createInvoice'] -->
    <div class="nav-dropdown" *ngIf="isShowHideElements['NewAppointment']">
      <div class="dropdown-actions">
        <button style="margin-right: 15px;" class="btn btn-primary icon-queue-events more-actions-hover-icon {{enableAction?'quick-actions':''}}"
          id="navBarQuickAction" (click)="enableAcctions()">Quick Actions</button>
        <div class="dropdown-content-actions">
          <!-- <ng-container *ngIf="isCreateInvoice['createInvoice']">
            <a class="time menulink" id="navBarNewInvoice" [routerLink]="['/hospital/invoiceBoard/createInvoice']" (click)="enableAcctions()"> <span
              class="material-symbols-outlined">
              list_alt_add
            </span> Create New Invoice </a>
          </ng-container> -->
          <!-- <ng-container *ngIf="!isCreateInvoice['createInvoice']"> -->
            <a class="time menulink" id="navBarNewPatientRegister"
            (click)="navigateNewAppointment(navigateNewAppointmentModal, true);enableAcctions();"> <span
              class="material-symbols-outlined">
              list_alt_add
            </span> New Patient Registration </a>
          <a class="time menulink" id="navBarScheduleAppointment"
            (click)="newAppointment();enableAcctions();clearOldAppointment();"> <i class="material-symbols-outlined">
              add_notes
            </i> Schedule Appointment </a>
          <a class="time menulink" [routerLink]="['/hospital/appointments-list']" id="navBarPastFutureApp"
            (click)="enableAcctions()"> <i class="las la-calendar-day"></i> Past & Future Appointments </a>
          <a class="time menulink" id="navBarPaitientSms" (click)="openSmsPopUp(sendAdHocSmsPatient)"> <i
              class="las la-sms"></i> New Patient SMS </a>
          <a class="time menulink" id="navBarPaitientSms" (click)="openFacilityPayment()"> <i
              class="las la-credit-card"></i> Pay </a>
          <!-- </ng-container> -->
        </div>
      </div>
    </div>
    <div style="width: auto; display: none;" class="facility-name main-actions">
      <ng-container>
        <button style="margin-right: 15px;" placement="bottom" ngbTooltip="New Appointment" id="navBarCalendar"
          class="flow-board-main-actions new-appointment-action" (click)="newAppointment()">
          <i class="las la-calendar-plus"></i>
        </button>
      </ng-container>
      <!--<ng-container>
        <button placement="bottom" ngbTooltip="Established Patients Search" style="margin-right: 15px;"
          class="flow-board-main-actions patient-search-action" (click)="establishedPAtientModal()">
          <i class="las la-search"></i>
        </button>
		
      </ng-container>-->

      <ng-container>
        <!-- <button placement="bottom" ngbTooltip="Past Appointments" style="margin-right: 15px;"
          class="flow-board-main-actions patient-search-action" [routerLink]="['/hospital/past-appointments']">
          <i class="lar la-calendar-check"></i>
        </button> -->
        <button placement="bottom" ngbTooltip="Past & Future Appointments" style="margin-right: 15px;"
          id="navBarCalendarCheck" class="flow-board-main-actions patient-search-action"
          [routerLink]="['/hospital/appointments-list']">
          <i class="lar la-calendar-check"></i>
        </button>
      </ng-container>

      <ng-container>
        <button placement="bottom" ngbTooltip="New Appointment SMS" style="margin-right: 15px;" id="navBarSms"
          class="flow-board-main-actions patient-search-action" (click)="sendAdHocSmsPatient?.show()">
          <i class="las la-sms"></i>
        </button>
      </ng-container>
    </div>

    <!-- Notification view dropdown view -->
    <ng-container *ngIf="isShowHideElements['NewAppointment']">
      <div class="drp-user dropdown" #notificationDropToggle="ngbDropdown" ngbDropdown placement="auto">

        <span ngbDropdownToggle placement="bottom" ngbTooltip="Notifications" (click)="toggleNotificationsView()"
          style="margin-right: 20px;cursor: pointer;" class="list-inline-item">
          <i style="font-size: 25px;display: flex;" class="lar la-bell"></i>
          <small style="top: -7px;" class="badge badge-pill badge-primary">{{notificationsCount?.length || 0}}</small>
        </span>

        <div class="dropdown-menu-right profile-notification cust-dropdown-notify" ngbDropdownMenu>
          <app-notifications-view [notificationDropToggle]="notificationDropToggle"
            (onNotificationsRecieved)="totalNotificationsCount($event)"></app-notifications-view>
        </div>
      </div>
    </ng-container>
    <!-- Notification view dropdown view - End -->

    <app-nav-right class="ml-auto" (click)="this.enableAction = false"></app-nav-right>
   </div>
</ng-template>

<!-- Established Patient Search Popup -->
<app-existing-patient-search [screenName]="'flowBoard'"></app-existing-patient-search>

<!-- Adhoc Patient SMS -->
<app-ui-modal #sendAdHocSmsPatient [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
  id="sendAdHocSmsPatient">
  <div class="app-modal-header">
    <h5 class="modal-title">SMS</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="sendAdHocSmsPatient.hide(); adhocSmsForm?.reset()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body add-app-body">
    <div class="correct-phone" [formGroup]="adhocSmsForm">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="label-name">First Name <span class="required">*</span></label>
            <input class="form-control input-text" type="text" formControlName="firstName" placeholder="First Name"
              id="firstName" />
            <label class="text-danger"
              *ngIf="adhocSmsForm?.get('firstName')?.errors?.required && adhocSmsForm?.get('firstName')?.touched">First
              Name is required.</label>
          </div>
          <div class="form-group">
            <label class="label-name">Mobile Phone Number <span class="required">*</span></label>
            <input class="form-control" type="text" mask="(000) 000-0000" formControlName="smsPhoneNumber"
              placeholder="(xxx) xxx-xxxx" id="phoneNumberCorrection" />
            <label class="text-danger"
              *ngIf="adhocSmsForm.get('smsPhoneNumber').errors?.required && adhocSmsForm.get('smsPhoneNumber').touched">Mobile
              Number is Required.</label>
            <label class="required"
              *ngIf="adhocSmsForm?.get('smsPhoneNumber')?.invalid && !adhocSmsForm?.get('smsPhoneNumber')?.errors?.required">Enter
              valid Mobile Number to update.</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
      (click)="getMessageBody(sendAdHocSmsPatient)">Send SMS</button>
  </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Established Patient Search Popup -->
<app-existing-patient-search [screenName]="'flowBoard'" [exceptionModal]="exceptionModal"></app-existing-patient-search>
<app-ui-modal #navigateNewAppointmentModal [hideHeader]="false" class="confirmCustExtPatSearch"
  id="navbar-navigateNewAppoint-search">
  <div class="app-modal-header">
    <h5 class="modal-title text-center"><i class="lar la-question-circle"></i></h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="navigateNewAppointmentModal.hide()"><span aria-hidden="true">&times;</span></button>
  </div>

  <div class="app-modal-body">
    <h6 style="line-height: 30px;">{{notifyText}}</h6>

    <!-- <div class="form-group d-flex" [formGroup]="patientRegForm">
      <label>Patient Type <span class="required">*</span></label><br />
      <label class="radio-inline" *ngFor="let item of registrationTypes;let i = index">
        <input formControlName="typeRegistrationSelect" id="visit-info-patient-type" type="radio"
          name="typeRegistrationSelect" [value]="item.value"><span class="desc">{{item.desc}}</span></label>
      <br />
      <span class="required"
        *ngIf="patientRegForm?.get('typeRegistrationSelect')?.errors?.required && patientRegForm?.get('typeRegistrationSelect')?.touched">Type
        of Patient Registration is Required</span>
    </div> -->

  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
      (click)="navigateNewAppointmentModal.hide();patientRegForm?.reset()">No</button>
    <button type="button" *ngIf="!isErrorNotify" class="btn btn-primary dash-actions" style="margin-right: 15px;"
      data-dismiss="modal" (click)="navigateNewAppointment(navigateNewAppointmentModal, true);">Yes</button>
    <button type="button" *ngIf="isErrorNotify" class="btn btn-primary dash-actions" style="margin-right: 15px;"
      data-dismiss="modal" (click)="navigateNewAppointment(navigateNewAppointmentModal);">Yes</button>
  </div>
</app-ui-modal>

<app-send-sms-notify [noDelay]="true" [pageType]="'Chart'"></app-send-sms-notify>

<!-- create task -->
<app-new-task (reloadAppointmentQueue)="refresh()" [idType]="'userId'"></app-new-task>

<app-facility-payment [paymentConfigDetails]="paymentConfigDetails"></app-facility-payment>


<app-payments-refactor [paymentConfigDetails]="paymentConfigDetails" [accountLevelPay]="true"
  [recieptID]="'navbar-user-chart-update-reciept-id'" [accoutLevelUserInfoId]="userInfoId"
  [userInfoData]="userInfoData"></app-payments-refactor>