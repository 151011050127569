<div class="row" [formGroup]="pharmacyFormGroup">
    <div class="col-lg-3 col-md-3 col-sm-12" [formGroup]="pharmacyFormGroup?.get('address')">
        <div class="form-group">
            <label for="last-name">ZIP Code</label>
            <ng-select placeholder="Search ZIP Code" id="preferred-pharmacy-zipcode" [items]="(searchPharmacyByZipCode | async)?.responseObject"
                bindLabel="zip" [clearable]="true" [addTag]="true" [multiple]="false" [hideSelected]="true"
                [trackByFn]="trackByFn" [minTermLength]="5" [closeOnSelect]="true" [loading]="pharmacyLoading"
                formControlName="zipCode" typeToSearchText="Please enter 5 numbers"
                [typeahead]="pharmacySearchInput" (change)="selectedPharmacy($event)" [inputAttrs]="{maxlength: '5'}">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <!-- <div>{{item?.proprietaryName}} {{item?.activeNumeratorStrength}} {{item?.activeIngredUnit}}</div> -->
                    <div title ="Select the option you want to add to the chart">{{item?.name}}, {{item?.address_1}}, {{item?.city}}</div>
                </ng-template>
            </ng-select>
            <span class="text-danger"
                *ngIf="pharmacyFormGroup?.get('address').get('zipCode').invalid && !pharmacyFormGroup?.get('address').get('zipCode').errors?.required">Please
                enter a valid ZIP Code</span>
        </div>
    </div>
    <!-- <div class="col-lg-6 col-md-6 col-sm-12"></div> -->
    <div class="col-lg-3 col-md-3 col-sm-6">
        <div class="form-group">
            <label for="pName"> Pharmacy Name </label>
            <input class="form-control input-text" type="text" formControlName="name" placeholder="Pharmacy Name"
            id="preferred-pharmacy-name" />
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
        <div class="form-group">
            <label for="phNumber"> Phone Number </label>
            <input class="form-control input-text" type="text" formControlName="phoneNumber" mask="(000) 000-0000" placeholder="(xxx) xxx-xxxx" id="preferred-pharmacy-ph-number" />
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
        <div class="form-group">
            <label for="faxNumber"> Fax Number </label>
            <input class="form-control input-text" type="text" formControlName="fax" mask="(000) 000-0000" placeholder="(xxx) xxx-xxxx" id="preferred-pharmacy-fax-number" />
        </div>
    </div>
    <!-- <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group">
            <label for="phName"> Pharmacy Brand Name </label>
            <input class="form-control input-text" type="text" formControlName="pharmacyholderName"
                placeholder="Pharmacy Brand Name" id="phName" />
        </div>
    </div> -->
    <div class="col-lg-12 col-md-12 col-sm-6 pharmacy-address-details">
        <app-address [hideZipCode]="true" [addressForm]="pharmacyFormGroup?.get('address')">
        </app-address>
    </div>
</div>