import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CancelAppointmentComponent } from 'src/app/theme/shared/hop-common-components/cancel-appointment/cancel-appointment.component';
import { ReScheduleAppointmentComponent } from 'src/app/theme/shared/hop-common-components/re-schedule-appointment/re-schedule-appointment.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { Router } from '@angular/router';
import { EligibilityCheckComponent } from 'src/app/theme/shared/hop-common-components/eligibility-check/eligibility-check.component';
import { SendSmsNotifyComponent } from 'src/app/theme/shared/hop-common-components/send-sms-notify/send-sms-notify.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { NewTaskComponent } from 'src/app/theme/shared/hop-common-components/new-task/new-task.component';
import { AddendumNotesChartComponent } from 'src/app/theme/shared/hop-common-components/addendum-notes-chart/addendum-notes-chart.component';

@Component({
  selector: 'app-all-appointments-list',
  templateUrl: './all-appointments-list.component.html',
  styleUrls: ['./all-appointments-list.component.scss']
})
export class AllAppointmentsListComponent implements OnInit {

  public dtOptionsFutureAppnt: DataTables.Settings = {};
  public dtTriggerFutureAppnt: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElementFutureAppnt: DataTableDirective;
  public allFutureAppointments = [];
  public loginDetails: any;
  @ViewChild('futureAppointments') futureAppointments: CardComponent;
  @ViewChild(UiModalComponent) modalComp: UiModalComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;

  public futureAppointmentForm: FormGroup;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ReScheduleAppointmentComponent) reScheduleComp: ReScheduleAppointmentComponent;
  @ViewChild(CancelAppointmentComponent) cancelAppntComp: CancelAppointmentComponent;
  public isShownActions: boolean;
  public minAppointmentDate: Date;
  eligibilityAppointment: any;
  @ViewChild(EligibilityCheckComponent) eligibilityComp: EligibilityCheckComponent;
  public waitTimeDetails: any;
  public lateNotifyPatient: any;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  public minEndDate: Date;
  @ViewChild(NewTaskComponent) newTakComp: NewTaskComponent;
  public currentYear = new Date().getFullYear();
  public currentMonth = new Date().getMonth();
  public currentDate = new Date().getDate();
  public maxStartDate: Date;
  public maxEndDate: Date;
  public appointmentStatusLiust = [
    { name: 'Scheduled', value: 'SCHEDULED' },
    { name: 'Confirmed', value: 'CONFIRMED' },
    { name: 'No Show', value: 'NOTAVAILABLE' },
    { name: 'Cancelled', value: 'CANCELLED' },
    { name: 'Completed', value: 'CHECKOUT' },
    { name: 'Walk-In', value: 'QR_VERIFIED' },
    { name: 'Nurse Greet', value: 'NURSE_GREET' },
    { name: 'Consultation', value: 'CONSULTATION' }
  ];
  public appointmentType = [
    { name: 'Future', value: 'true' },
    { name: 'Past', value: 'false' }
  ];
  public programmTypes = [
    { name: 'CCM', value: 'CCM' },
    { name: 'RPM', value: 'RPM' }
  ];
  public addPatientPurposeVisit: any[] = [];
  public isMaxRequired: boolean;
  public isMinRequired: boolean;
  public cardTitleVal: any = "Future Appointments";
  public showFilter: boolean = true;
  @ViewChild(AddendumNotesChartComponent) addendumComp: AddendumNotesChartComponent;
  public navigationList: Array<any> = [
    {
      breadcrumbs: true,
      title: "Flow Board",
      type: "item",
      url: '/hospital/hosp-dashboard'
    },
    {
      breadcrumbs: true,
      title: "Past & Future Appointments",
      type: "item",
      url: ''
    }
  ];

  public allCareFlowData: any;

  public openActions: boolean = false;
  public enableActions: boolean = false;
  public selectedActionRow: any;
  isFuturePastAppts: string;
  enableDisableSubmitFilter: boolean;
  @Input() isccmPrograms: any;
  public completedAppointmentsCount = null;

  @HostListener('document:click', ['$event'])
  clickInside(event) {
    const eventId = (event.target as HTMLInputElement).id;
    if (!this.enableActions && eventId !== 'claimActions_btn') {
      this.openActions = false;
    }
  }

  constructor(private httpService: HttpService, private fb: FormBuilder, private router: Router) {
    this.futureAppointmentForm = this.fb.group({
      'facilityStartDate': [null, Validators.required],
      'facilityEndDate': [null, Validators.required],
      'apt_type': [null, Validators.required],
      'purpose_of_visit': [null],
      'apt_status': [null],
     // 'programmType': [null],
    });

    const navigationDAta = {
      breadcrumbs: true,
      title: "Past & Future Appointments",
      type: "item",
      url: '/hospital/appointments-list'
    };
    sessionStorage.setItem('navigationPath', JSON.stringify(navigationDAta));
  }

  ngOnInit(): void {
    this.dtOptionsFutureAppnt = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[2, "asc"]],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "No Appointments Booked for this date",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },

      columnDefs: [{ targets: [2], type: 'date', orderable: true }]
    };
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.minAppointmentDate = this.httpService.convertDate(new Date());
    this.waitTimeDetails = JSON?.parse(sessionStorage?.getItem('waitTimeDetails'));
    this.minEndDate = this.httpService.convertDate(new Date());
    this.maxStartDate = this.httpService?.convertDate(new Date());
    this.maxEndDate = this.httpService?.convertDate(new Date());
    this.addPatientPurposeVisit = JSON.parse(sessionStorage.getItem('waitTimeDetails'))?.purposeOfVisit || [];
    this.futureAppointmentForm.patchValue({
      apt_type: 'true'
    });
    this.isMaxRequired = false;
    this.isMinRequired = true;
    this.isFuturePastAppts = this.futureAppointmentForm?.get('apt_type')?.value === 'true' ? 'Future Appt' : 'Past Appt';
  }

  get isShowHideElements(): any {
    const allItems = JSON.parse(sessionStorage?.getItem('allProvideLoginNavItems'));
    const getScreenItem = allItems?.find(item => item?.coreFeature === 'Future Appointments');
    return getScreenItem?.childFeature || {};
  }
  
  public changeProgrammtype(event, notifyModal: UiModalComponent) {
    this.getAppoinments(notifyModal);
  }

  public changeApttype(event, notifyModal: UiModalComponent) {
    this.isMaxRequired = true;
    this.isMinRequired = false;
    this.cardTitleVal = "Past Appointments"
    this.isFuturePastAppts = this.futureAppointmentForm?.get('apt_type')?.value == 'true' ? 'Future Appt' : 'Past Appt';
    const todayDate = new Date();
    const fromDate = new Date();
    fromDate?.setDate(fromDate?.getDate() - 1);
    // const fDate = fromDate?.toLocaleDateString('en-CA');
    const fDate = this.httpService.convertDate(fromDate);
    this.futureAppointmentForm?.get('facilityStartDate')?.setValue(fDate || null);
    const toDate = new Date();
    toDate?.setDate(toDate?.getDate() - 1);
    // const tDate = toDate?.toLocaleDateString('en-CA');
    const tDate = this.httpService.convertDate(toDate);
    this.futureAppointmentForm?.get('facilityEndDate')?.setValue(tDate || null);

    if (event?.name === 'Future') {
      this.isMaxRequired = false;
      this.isMinRequired = true;
      this.cardTitleVal = "Future Appointments"
      const splitDate = todayDate.toISOString().split('T')[0] || null;
      const fromDate = new Date();
      fromDate?.setDate(fromDate?.getDate());
      // const fDate = fromDate?.toLocaleDateString('en-CA');
      const fDate = this.httpService.convertDate(fromDate);
      this.futureAppointmentForm?.get('facilityStartDate')?.setValue(fDate || null);

      const toDate = new Date();
      toDate?.setDate(toDate?.getDate());
      // const tDate = toDate?.toLocaleDateString('en-CA');
      const tDate = this.httpService.convertDate(toDate);
      this.futureAppointmentForm?.get('facilityEndDate')?.setValue(tDate || null);
    }

    this.getAppoinments(notifyModal);
  }

  // customize the Appointment Date time
  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

  // change Event trigger
  public getFutureAppointments(): void {

    if (this.futureAppointmentForm?.value?.apt_type) {
      this.minEndDate = this.httpService.convertDate(new Date());
      const startDate = this.futureAppointmentForm?.value?.facilityStartDate;
      const endDate = this.futureAppointmentForm?.value?.facilityEndDate;


      if (startDate && endDate && endDate < startDate) {
        this.futureAppointmentForm?.get('facilityEndDate')?.setValue(null);
        this.minEndDate = this.httpService.convertDate(new Date(startDate));
      }
      if (startDate) {
        this.minEndDate = this.httpService.convertDate(new Date(startDate));
      }
    } else {
      this.maxStartDate = this.httpService.convertDate(new Date());

      const startDate = this.futureAppointmentForm?.value?.facilityStartDate;
      const endDate = this.futureAppointmentForm?.value?.facilityEndDate;

      if (startDate && endDate && endDate < startDate) {
        this.futureAppointmentForm?.get('facilityStartDate')?.setValue(null);
        this.maxStartDate = this.httpService.convertDate(new Date(endDate));
      }
      if (endDate) {
        this.maxStartDate = this.httpService.convertDate(new Date(endDate));
      }
    }


  }

  public filterAppointments(notifyModal: UiModalComponent) {
    this.getAppoinments(notifyModal);
  }
  // get future appointments API calling
  public getAppoinments(notifyModal?: UiModalComponent): void {

    if (this.futureAppointmentForm?.invalid) {
      this.futureAppointmentForm?.markAllAsTouched();
      return;
    }
    this.allFutureAppointments = [];
    this.rerender();
    this.futureAppointments.cardRefreshShow();
    let purposeOfVisit = null;

    if (this.isccmPrograms) {

      const programmType = this.futureAppointmentForm?.value?.programmType;
     
      if (programmType === 'CCM') {
        purposeOfVisit = 'Chronic Care';

      }
      else if (programmType === 'RPM') {
        purposeOfVisit = 'RPM';
      }
    }
    else {
      purposeOfVisit = this.futureAppointmentForm?.value?.purpose_of_visit;
    }
   

    let action = `appointment/registration/getAppointmentsbyFacility?ccmPrograms=${this.isccmPrograms}&facilityStartDate=${this.futureAppointmentForm?.value?.facilityStartDate}&facilityEndDate=${this.futureAppointmentForm?.value?.facilityEndDate}&future=${this.futureAppointmentForm?.value?.apt_type}`;
    //if (this.futureAppointmentForm?.value?.purpose_of_visit) {
    if (purposeOfVisit !== null && purposeOfVisit !== '') {
      action = action + `&purposeOfVisit=${purposeOfVisit}`;
    }
    if (this.futureAppointmentForm?.value?.apt_status) {
      action = action + `&status=${this.futureAppointmentForm?.value?.apt_status}`;
    }
    //const action = `appointment/registration/getUpcoming?facilityStartDate=${this.futureAppointmentForm?.value?.facilityStartDate}&facilityEndDate=${this.futureAppointmentForm?.value?.facilityEndDate}&facilityId=${this.loginDetails.facilityId}`;
    this.enableDisableSubmitFilter = true;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status == 'SUCCESS') {
        if (this.isccmPrograms) {
          this.completedAppointmentsCount = data?.message;
        }

        this.allFutureAppointments = data?.resultList || [];
        this.dtTriggerFutureAppnt.next();
        if (this.allFutureAppointments && this.allFutureAppointments.length === 0) {
          // this.notifyText = 'No Appointments are available for selected date';
          // notifyModal.show();
        }
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        notifyModal.show();
      }
      this.futureAppointments.cardRefreshHide();
      this.enableDisableSubmitFilter = false;
    }, error => {
      // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
      // notifyModal.show();

      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal.modalShow();
      this.futureAppointments.cardRefreshHide();
      this.dtTriggerFutureAppnt.next();
      this.enableDisableSubmitFilter = false;
    });
  }

  // this is for table render, we need to destroy everytime to update the new data
  public rerender(): void {
    this.dtElementFutureAppnt?.dtInstance?.then((dtInstanceFutureAppnt: DataTables.Api) => {
      dtInstanceFutureAppnt?.destroy();
      //  this.dtTriggerFutureAppnt.next(); 
    });
  }

  // appointment status showing
  public getStatus(status: string, appointment: any): string {
    return this.httpService.getStatus(status, appointment);
  }

  // re schedule appointment 
  public reScheduleAppointment(appointment: any): void {
    this.reScheduleComp.openSetAppointment(appointment);
  }

  // Cancel Apppintment commom compoent open
  public cancelAppointment(appointment: any, isDelete?: boolean): void {
    this.cancelAppntComp.openCancelAppointment(appointment, isDelete);
  }

  // refetching the updated reschedule or cancelled appointments
  public refresh(): void {
    this.allFutureAppointments = [];
    // this.rerender();
    // const dateValue = this.futureAppointmentForm.get('DATE').value;
    this.getAppoinments(this.modalComp);
  }

  public editAppointment(appointment: any): void {
    if (appointment?.signOff) {
      this.router?.navigate([`/hospital/claim-submission/${appointment?.id}`]);
      return;
    }
    this.router?.navigate([`hospital/hosp-updatepatientdetails/${appointment?.id}`]);
    sessionStorage?.setItem('AppntEditNavigateFlow', JSON?.stringify({ name: 'Past & Future Appointments', url: '/hospital/appointments-list' }));
    sessionStorage?.setItem('futureAppointmentEdit', JSON?.stringify(true));
  }

  // check eligibility
  public checkEligibility(appointment: any): void {
    const getPrimaryInsurance = appointment?.insuranceDetails?.find(item => item?.priority === 'Primary');
    if (!getPrimaryInsurance || !getPrimaryInsurance?.memberId || !getPrimaryInsurance?.payerId || !getPrimaryInsurance?.subscriberlastname || !getPrimaryInsurance?.subscriberfirstname) {
      this.notifyText = 'Insurance details are unavailable for this Appointment.';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      return;
    }
    this.futureAppointments?.cardRefreshShow();
    this.eligibilityAppointment = appointment;
    this.eligibilityComp?.checkEligibilityPatient(getPrimaryInsurance, appointment);
  }

  // call the API after DOM view had done - Angular Life Cycle Hooks
  ngAfterViewInit(): void {
    const todayDate = new Date();
    const splitDate = todayDate.toISOString().split('T')[0] || null;
    // this.futureAppointmentForm.get('DATE').setValue(splitDate || null);
    this.dtTriggerFutureAppnt?.next();
    const fromDate = new Date();
    fromDate?.setDate(fromDate?.getDate());
    // const fDate = fromDate?.toLocaleDateString('en-CA');
    const fDate = this.httpService.convertDate(fromDate);
    this.futureAppointmentForm?.get('facilityStartDate')?.setValue(fDate || null);

    const toDate = new Date();
    toDate?.setDate(toDate?.getDate());
    // const tDate = toDate?.toLocaleDateString('en-CA');
    const tDate = this.httpService.convertDate(toDate);
    this.futureAppointmentForm?.get('facilityEndDate')?.setValue(tDate || null);

    this.getAppoinments(this.modalComp);
  }

  // send Notifications
  public lateNotify(patient: any): void {
    this.lateNotifyPatient = patient || {};
    this.sendNotifyComp?.showNotifyModal(patient);
  }

  // get doctor name from list
  public doctorNameWithNpi(appointment: any): string {
    if (appointment?.authorized_official_first_name && appointment?.authorized_official_last_name) {
      const firstNameApp = appointment?.authorized_official_first_name || '';
      const lastNameApp = appointment?.authorized_official_last_name || '';
      return `${lastNameApp}, ${firstNameApp}` || '--';
    } else {
      const doctor = this.waitTimeDetails?.eligibilityDetails?.doctor?.find((item: any) => {
        return appointment?.doctorNpi === item?.number
      });
      const firstName = doctor?.basic?.authorized_official_first_name || '';
      const lastName = doctor?.basic?.authorized_official_last_name || '';
      return `${lastName}, ${firstName}` || '--';
    }
  }

  public openTaskModal(appointment: any): void {
    console.log(appointment);

    this.newTakComp?.showTaskModal(appointment);

  }
  public viewAppointment(id: any) {
    this.httpService.clearTreatmentFlowSession();
    this.router?.navigate([`/hospital/past-visit/${id}`]);
  }

  public async editAppointmentPastAppointment(appointment: any) {
    this.futureAppointments?.cardRefreshShow();
    if (appointment?.signOff) {
      this.router?.navigate([`/hospital/claim-submission/${appointment?.id}`]);
      return;
    }
    const validateLock = await this.httpService?.validateLockAppointment(appointment?.id)?.toPromise();
    if (validateLock?.responseObject?.locked === false || (validateLock?.responseObject?.allow === true && validateLock?.responseObject?.locked === false)) {
      sessionStorage.setItem('careflowPath', 'appointment');
      this.httpService?.navigationDataSession('hospital/appointments-list', 'Past & Future Appointments');
      this.router?.navigate([`/hospital/hosp-patientdetails/${appointment?.id}`]);
    }
    this.futureAppointments?.cardRefreshHide();

    if (validateLock?.responseObject?.locked === true) {
      if (validateLock?.responseObject?.allow === true) {
        this.addendumComp?.openAddendumModal(appointment?.id, true);
        this.getAllModulesSavedData(appointment)
      }
      if (validateLock?.responseObject?.allow === false) {
        this.notifyText = validateLock?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    }
  }

  public getAllModulesSavedData(appointment: any): void {
    const action = `careFlow/readCareFlowData?facilityId=${appointment?.facilityId}&appointmentId=${appointment?.id}&userInfoId=${appointment?.userInfoId}`;
    // this.patientSummaryCard.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      this.allCareFlowData = data?.responseObject;
      //   this.patientSummaryCard.cardRefreshHide();
    },
      (error) => {
        //   this.patientSummaryCard.cardRefreshHide();
      })
  }

  enableFilter() {
    this.showFilter = !this.showFilter;
  }

  public openUniversalActions(event: any, row) {
    if (this.selectedActionRow === row) {
      this.openActions = !this.openActions;
    }
    else {
      this.openActions = true;
    }

    this.selectedActionRow = row;

  }
  public closeUniversalActions() {

    this.openActions = false;
  }

  public mouseIn() {
    this.enableActions = true;

  }

  public mouseOut() {
    this.enableActions = false;

  }

  public getChiefComplaintsSectionHeight(cc: any) {
    let height = null;
    if (cc === null || cc === undefined) {
      return;
    }
    if (cc.length === 1) {
      height = '47px';
    }
    else if (cc.length === 2) {
      height = '68px';
    }
    else if (cc.length === 3) {
      height = '90px';
    }
    else if (cc.length > 3) {
      height = '105px';
    }
    return height;
  }
  public modifyUserData(appointment: any): void {
    if (appointment?.userInfoId) {
      this.router?.navigate([`/hospital/user-data-update/${appointment?.userInfoId}`]);
    } else {
      //this.userInfoError = 'User Info details are not avialable for selected patient';
      alert("User Info details are not avialable for selected patient");
    }
  }


  // Input Appnt date validation 
  public inputAppntDateValidation($event: any, futureAppointmentForm: FormGroup, contrlName?: string): void {
    console.log("this.isFuturePastAppts*****", this.isFuturePastAppts);
    if (this.isFuturePastAppts == 'Past Appt') {
      this.httpService?.isPastDate($event, futureAppointmentForm, contrlName);
    } else {
      this.httpService?.validateAppointmentDate($event, futureAppointmentForm, contrlName);
    }

  }

  // Change appnt date with validation
  public changeAppntDateWithValidation(futureAppointmentForm?: FormGroup, controlName?: string): void {

    const enteredDate = new Date(this.futureAppointmentForm?.get(controlName)?.value);
    if (enteredDate?.getFullYear() <= 1900) {
      return;
    }
    if (this.isFuturePastAppts == 'Past Appt') {
      if (this.httpService?.isPastDateEntry(this.futureAppointmentForm?.get(controlName)?.value)) {
        this.futureAppointmentForm?.get(controlName)?.setValue(null);
        return;
      }

    } else {
      if (this.httpService?.changeAppntDateWithValValidation(this.futureAppointmentForm?.get(controlName)?.value)) {
        this.futureAppointmentForm?.get(controlName)?.setValue(null);
        return;
      }
    }
    this.getFutureAppointments();

  }

  // to destroy the subscription
  ngOnDestroy(): void {
    this.dtTriggerFutureAppnt.unsubscribe();
  }

}

