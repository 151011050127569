import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-review-legacy-medical-record',
  templateUrl: './review-legacy-medical-record.component.html',
  styleUrls: ['./review-legacy-medical-record.component.scss']
})
export class ReviewLegacyMedicalRecordComponent implements OnInit, OnChanges {

  public dtOptionsRxOrders: DataTables.Settings = {};
  public dtTriggerRxOrders: Subject<any> = new Subject<any>();

  public dtOptionsLabOrders: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public dtTriggerLabOrders: Subject<any> = new Subject<any>();
  @Input() legacyLabOrderResults: any;
  @Input() allCareFlowData: any;
  @Input() assessmentValues: any;
  @Input() allRxOrderDetails: any;

  constructor() { }

  ngOnInit(): void {
    this.dtOptionsRxOrders = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[1, "desc"]],
      columnDefs: [{ targets: [1], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.legacyLabOrderResults) {
        this.dtTriggerLabOrders?.next();
      }
    }
  }

}
