<app-ui-modal #initiateReferralModal [hideHeader]="false" id="initiateReferralModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Initiate Referral</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="initiateReferralModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body" [formGroup]="initiateReferral">
        <div class="form-group">
            <ng-container>
                <div class="form-group">
                    <label>Appointment Start Date <span class="required">*</span></label><br />
                    <input type="date" name="datemin"
                        [min]="minAppointmentDate" class="form-control" id="visit-date"
                        formControlName="APPOINTMENT_START_DATE" placeholder="Appointment Start Date">
                    <span class="required"
                        *ngIf="initiateReferral.get('APPOINTMENT_START_DATE').errors?.required && initiateReferral.get('APPOINTMENT_START_DATE').touched">Appointment
                        Start Date is Required</span>
                </div>

                <div class="form-group">
                    <label>Appointment End Date <span class="required">*</span></label><br />
                    <input type="date" name="datemin"
                        [min]="minAppointmentDate" class="form-control" id="visit-date"
                        formControlName="APPOINTMENT_END_TIME" placeholder="Appointment End Date">
                    <span class="required"
                        *ngIf="initiateReferral.get('APPOINTMENT_END_TIME').errors?.required && initiateReferral.get('APPOINTMENT_END_TIME').touched">Appointment
                        End Date is Required</span>
                </div>
 
                    <div class="form-group">
                        <label class="form-label">Referral Channel <span
                                class="required">*</span></label>
                        <select class="form-control" formControlName="PREFERRED_REFERRAL_CHANNEL"
                            name="validation-cmbGear">
                            <option [value]="null" [selected]='true' [disabled]="true">
                                Select Referral Channel</option>
                            <option
                                *ngFor="let preferredReferralChannel of preferredReferralChannelValues"
                                value="{{preferredReferralChannel.value}}">
                                {{preferredReferralChannel.desc}}
                            </option>
                        </select>
                        <label class="required"
                            *ngIf="initiateReferral.controls['PREFERRED_REFERRAL_CHANNEL'].hasError('required') && initiateReferral.controls['PREFERRED_REFERRAL_CHANNEL'].touched">Preferred
                            Referral Channel
                            Type should be required</label>
                    </div> 
                <div class="col-lg-12 col-md-12 col-sm-12 search-btns">
                    <button type="button" class="btn btn-primary reset-btn" (click)="resetSearchForm()">Reset</button>
                    <button class="btn btn-primary event-btn" type="button" 
                        [disabled]="!initiateReferral.valid" (click)="saveinitiateReferral()">
                        <span *ngIf="this.initiateReferralClick" class="spinner-border spinner-border-sm"
                            role="status"></span>
                        <span *ngIf="this.initiateReferralClick" class="load-text">Initiate Referral...</span>
                        <span *ngIf="!this.initiateReferralClick" class="btn-text">Initiate Referral</span>
                    </button>
                </div>
            </ng-container>

        </div>

        <!-- spinner for popup -->
        <div class="custom-loader justify-content-center" *ngIf="loadCard">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div> 
</app-ui-modal> 