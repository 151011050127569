<app-ui-modal #viewSubmittedClaimDetailsModal [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
    id="viewSubmittedClaimDetailsModal">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Claim Details</h5> -->
        <h5>

            <span  *ngIf="claimNumber && (claimQueueItemData?.name !== 'Ready for Submission' && claimQueueItemData?.name !== 'Clearing House Rejects')">
                  Claim Number: {{claimNumber}}, 
            </span>
            <span>
                {{claimPatientInfo?.lastName }},
                {{claimPatientInfo?.firstName }}    &nbsp;<b>|</b>&nbsp;
            </span>
            <span class="gender-age" *ngIf="claimItem?.purposeOfVisit !== 'Legacy Medical Record'">
                {{claimItem?.purposeOfVisit}}
                Visit </span>
            <span class="gender-age" *ngIf="claimItem?.purposeOfVisit === 'Legacy Medical Record'">
                {{claimItem?.purposeOfVisit}}</span>

            &nbsp;<b>|</b>&nbsp; {{claimItem?.serviceDate}}
        </h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="viewSubmittedClaimDetailsModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">

        <div class="claims-custom-tabs" *ngIf="!onlyERAStatusShown">
            <ul class="nav nav-tabs">
                <li (click)="claimQueueItemData?.name !== 'Ready for Submission' && claimQueueItemData?.name !== 'Clearing House Rejects' ? selectLink('summary') : null"
                    [ngClass]="{'active': selectedLink === 'summary'}"
                    [style.background-color]="claimQueueItemData?.name === 'Ready for Submission' || claimQueueItemData?.name === 'Clearing House Rejects' ? '#6e6c6c57' : null">
                    <a data-toggle="tab"
                        [attr.href]="claimQueueItemData?.name !== 'Ready for Submission' && claimQueueItemData?.name !== 'Clearing House Rejects' ? '#summary' : null">SUMMARY</a>
                </li>
                <li (click)="claimQueueItemData?.name !== 'Ready for Submission' && claimQueueItemData?.name !== 'Clearing House Rejects' ? selectLink('claimDetails') : null"
                    [ngClass]="{'active': selectedLink === 'claimDetails'}"
                    [style.background-color]="claimQueueItemData?.name === 'Ready for Submission' || claimQueueItemData?.name === 'Clearing House Rejects' ? '#6e6c6c57' : null">
                    <a data-toggle="tab"
                        [attr.href]="claimQueueItemData?.name !== 'Ready for Submission' && claimQueueItemData?.name !== 'Clearing House Rejects' ? '#claimdetails' : null">CLAIM
                        DETAILS</a></li>
                
                <li (click)="claimQueueItemData?.name !== 'Ready for Submission' ? selectLink('1500form') : null"
                    [ngClass]="{'active': selectedLink === '1500form'}"
                    [style.background-color]="claimQueueItemData?.name === 'Ready for Submission' ? '#6e6c6c57' : null">
                    <a data-toggle="tab" [attr.href]="claimQueueItemData?.name !== 'Ready for Submission' ? '#formclaim' : null">1500
                        FORM</a>
                </li>
                <li (click)="loadClaimJournal();selectLink('claimJournal')" [ngClass]="{'active': selectedLink === 'claimJournal'}">
                    <a data-toggle="tab" href="#claimjournal">CLAIM JOURNAL</a>
                </li>
                <li (click)="openFacilityActions(claimItem, claimItem?.claimAlerts?.facilityResponseAlert);selectLink('facilityAction')" id="facilityActionLink"
                    [ngClass]="{'active': selectedLink === 'facilityAction'}"><a data-toggle="tab" href="#facilityaction">FACILITY
                        ACTION</a></li>
            </ul>

            <div class="tab-content">
                <div id="summary" class="tab-pane " [ngClass]="{'active': selectedLink === 'summary'}">
                    <div class="tab-innercontent" id="mainOrdersAccordian">
                        <div class="order-accordion">
                            <div class="panel-group">
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" href="#claimTracker">CLAIM TRACKER</a>
                                        </h4>
                                    </div>
                                    <div id="claimTracker" class="panel-collapse collapse show">
                                        <div class="panel-body">
                                            <table class="table table-bordered table-panel">
                                                <thead>
                                                    <tr>
                                                        <th>Provider</th>
                                                        <th>Clearing house</th>
                                                        <th colspan="3">Payor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Claim submitted  </td>
                                                        <td> Processed </td>
                                                        <td>Received</td>
                                                        <!--<td>In Processing</td>-->
                                                        <td>Processed/Paid</td>
                                                    </tr>
                                                    <tr>
                                                        <ng-container *ngFor="let tc of claimTraclerCount">
                                                            <td class="done"><i class="fa fa-check"
                                                                    aria-hidden="true"></i></td>
                                                        </ng-container>
                                                        <ng-container *ngFor="let ntc of claimNoTraclerCount">
                                                            <td>-</td>
                                                        </ng-container>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" href="#claimDetails">CLAIM DETAILS</a>
                                        </h4>
                                    </div>
                                    <div id="claimDetails" class="panel-collapse collapse show">
                                        <div class="panel-body">
                                            <table class="table table-bordered table-panel panel-info">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Patient Information</th>
                                                        <th class="text-center">Submitter Information</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="td-width">
                                                            <div class="padding10">
                                                                <table class="table table-borderless panel-info2">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><span class="label">First Name</span>
                                                                                <span>:
                                                                                    {{claimPatientInfo?.firstName
                                                                                    || '--' | titlecase}} </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><span class="label">Last
                                                                                    Name</span><span>:
                                                                                    {{claimPatientInfo?.lastName
                                                                                    || '--' | titlecase}}</span> </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> <span class="label">Date of
                                                                                    Birth</span><span>:
                                                                                    {{claimPatientInfo?.dateOfBirth
                                                                                    ?
                                                                                    dateFormate(claimPatientInfo?.dateOfBirth)
                                                                                    : '--'}}</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> <span
                                                                                    class="label">Gender</span><span>:
                                                                                    <label
                                                                                        *ngIf="claimPatientInfo?.gender === 'M'">Male</label>
                                                                                    <label
                                                                                        *ngIf="claimPatientInfo?.gender === 'F'">Female
                                                                                    </label>
                                                                                    <label
                                                                                        *ngIf="claimPatientInfo?.gender === 'Other'">Other</label></span>

                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td> <span class="label">Member ID</span>
                                                                                <span>:
                                                                                    {{claimPatientInfo?.memberId
                                                                                    || '--'}}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> <span class="label">Group #</span>
                                                                                <span>: {{individualClaimDetails?.claimRequest?.dependent?.groupNumber || individualClaimDetails?.claimRequest?.subscriber?.groupNumber || '--'}}
                                                                                   <!-- {{claimPatientInfo?.groupNumber || '--'}}-->
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> <span class="label">Policy #
                                                                                </span> <span>:
                                                                                    {{claimPatientInfo?.policyNumber
                                                                                    || '--'}}</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> <span class="label">Email</span>
                                                                                <span>:
                                                                                    {{claimPatientInfo?.email
                                                                                    || '--'}}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> <span class="label">Organization
                                                                                    Name</span> <span
                                                                                    class="organization-name">:
                                                                                    {{claimPatientInfo?.organizationName
                                                                                    || '--'}}</span></td>
                                                                        </tr>


                                                                        <tr>
                                                                            <td><span class="label">
                                                                                    Subscriber Address</span><span
                                                                                    class="address address-width">
                                                                                    {{claimPatientInfo?.address?.address1}},<br>
                                                                                    {{claimPatientInfo?.address?.city}},<br>
                                                                                    {{claimPatientInfo?.address?.state}}
                                                                                    -
                                                                                    {{claimPatientInfo?.address?.postalCode}}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> <span class="label">Claim Payor
                                                                                    Id</span><span>:
                                                                                    {{individualClaimDetails?.claimRequest?.tradingPartnerServiceId
                                                                                    || '--'}}</span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                        <td class="td-width">
                                                            <ng-container
                                                                *ngFor="let claimDetails of individualClaimDetails?.claimRequest?.providers; let prvi = index">
                                                                <div *ngIf="claimDetails?.providerType === 'BillingProvider'"
                                                                    class="padding10">
                                                                    <table class="table table-borderless panel-info2">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="label"><b>Provider
                                                                                            Type</b></span>
                                                                                    <span>: Billing Provider </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><span class="label">First
                                                                                        Name</span><span>:
                                                                                        {{claimDetails?.firstName ||
                                                                                        '--' | titlecase }}</span> </td>

                                                                            </tr>
                                                                            <tr>
                                                                                <td><span class="label">Last
                                                                                        Name</span><span>:
                                                                                        {{claimDetails?.lastName||
                                                                                        '--' | titlecase}}</span> </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td> <span
                                                                                        class="label">NPI</span><span>:
                                                                                        {{claimDetails?.npi ||
                                                                                        '--'}}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td> <span class="label">Tax
                                                                                        ID</span><span>:
                                                                                        {{claimDetails?.employerId ||
                                                                                        '--'}}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td> <span class="label">State License #
                                                                                    </span> <span>:
                                                                                        {{claimDetails?.stateLicenseNumber
                                                                                        || '--'}}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td> <span class="label">Taxonomy
                                                                                        Code</span><span>:
                                                                                        {{claimDetails?.taxonomyCode ||
                                                                                        '--'}}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td> <span class="label">Tax
                                                                                        Id</span><span>:
                                                                                        {{claimDetails?.employerId ||
                                                                                        '--'}}</span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <h6 class="f14"><b>Contact Information</b></h6>
                                                                    <table class="table table-borderless panel-info2">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="label">Fax #
                                                                                    </span>
                                                                                    <span>:
                                                                                        {{claimDetails?.contactInformation?.faxNumber
                                                                                        || '--'}}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><span class="label">Phone #
                                                                                    </span><span>:
                                                                                        {{claimDetails?.contactInformation?.phoneNumber
                                                                                        || '--'}}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><span
                                                                                        class="label">Email</span><span>:
                                                                                        {{claimDetails?.contactInformation?.email
                                                                                        || '--'}}
                                                                                    </span> </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td> <span
                                                                                        class="label add-position">Address
                                                                                    </span><span
                                                                                        class="address address-width">
                                                                                        {{claimDetails?.address?.address1}},<br>
                                                                                        {{claimDetails?.address?.address2}},
                                                                                        {{claimDetails?.address?.city}},<br>
                                                                                        {{claimDetails?.address?.state}}
                                                                                        -
                                                                                        {{claimDetails?.address?.postalCode}}

                                                                                    </span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </ng-container>
                                                             
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" href="#claimStatus">CLAIM STATUS</a>
                                        </h4>
                                    </div>
                                    <div id="claimStatus" class="panel-collapse collapse show">
                                        <div class="panel-body">

                                            <div class="claim-status">
                                                <div class="row" *ngIf="!claimStatus">
                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                        <h5 class="text-center">Claim Status is not available</h5>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="claimStatus">
                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                        <table class="table table-borderless table-panel panel-info">
                                                            <tbody>
                                                                <tr>
                                                                    <td><span class="label">Status Category</span>
                                                                        <span class="status-width">:
                                                                            <span
                                                                                *ngIf="claimStatus?.statusCategoryCode">{{claimStatus?.statusCategoryCode}}
                                                                                - </span>
                                                                            <span
                                                                                *ngIf="claimStatus?.statusCategoryCode">{{claimStatus?.statusCategoryCodeValue}}
                                                                            </span>
                                                                            <span
                                                                                *ngIf="!claimStatus?.statusCategoryCode ">--
                                                                            </span>


                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span class="label">Status</span><span
                                                                            class="status-width">:
                                                                            <!-- {{claimStatus?.statusCode}} -
                                                                            {{claimStatus?.statusCodeValue}}-->
                                                                            <span
                                                                                *ngIf="claimStatus?.statusCode">{{claimStatus?.statusCode}}
                                                                                - </span>
                                                                            <span
                                                                                *ngIf="claimStatus?.statusCodeValue">{{claimStatus?.statusCodeValue}}
                                                                            </span>
                                                                            <span *ngIf="!claimStatus?.statusCode ">--
                                                                            </span>

                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td> <span class="label">Patient Account
                                                                            Number</span>
                                                                        <span>: {{claimStatus?.patientAccountNumber ||
                                                                            '--'}}</span>
                                                                    </td>
                                                                </tr>
                                                                <!-- <tr>
                                                <td>  <span class="label">Organization Name</span> <span>: {{individualClaimDetails?.claimRequest?.receiver?.organizationName || '--'}}</span></td>
                                            </tr> -->
                                                                <tr>
                                                                    <td> <span class="label">Submitted
                                                                            Amount</span><span>:
                                                                            <label
                                                                                *ngIf="claimStatus?.submittedAmount">$
                                                                                {{claimStatus?.submittedAmount}}</label>
                                                                            <label
                                                                                *ngIf="!claimStatus?.submittedAmount">--</label></span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td> <span class="label">Amount Paid</span> <span>:
                                                                            <label *ngIf="claimStatus?.amountPaid">$
                                                                                {{claimStatus?.amountPaid}}</label>
                                                                            <label
                                                                                *ngIf="!claimStatus?.amountPaid">--</label></span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td> <span class="label">Paid Date</span>
                                                                        <span>: <label>{{claimStatus?.paidDate}}</label>
                                                                            <label
                                                                                *ngIf="!claimStatus?.paidDate">--</label>

                                                                        </span>
                                                                    </td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" href="#remittance">REMITTANCE INFORMATION</a>
                                        </h4>
                                    </div>
                                    <div id="remittance" class="panel-collapse collapse show">
                                        <div class="panel-body">
                                            <table class="table table-borderless table-panel panel-info panel-info2">
                                                <tbody>
                                                    <tr>
                                                        <td><span class="label">Approved Amount</span><span>:
                                                                <label *ngIf="eraStatus?.totalClaimChargeAmount">$
                                                                    {{eraStatus?.totalClaimChargeAmount}}</label>
                                                                <label
                                                                    *ngIf="!eraStatus?.totalClaimChargeAmount && claimStatus?.submittedAmount">$
                                                                    {{claimStatus?.submittedAmount}}</label>
                                                                <label
                                                                    *ngIf="!eraStatus?.totalClaimChargeAmount && !claimStatus?.submittedAmount">--</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <span class="label">AR Insurance</span><span>:
                                                                <label *ngIf="eraStatus?.claimPaymentAmount">$
                                                                    {{eraStatus?.claimPaymentAmount}}</label>
                                                                <label
                                                                    *ngIf="!eraStatus?.claimPaymentAmount && claimStatus?.amountPaid">$
                                                                    {{claimStatus?.amountPaid}}</label>
                                                                <label
                                                                    *ngIf="!eraStatus?.claimPaymentAmount && !claimStatus?.amountPaid">--</label>

                                                            </span></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <span class="label">AR Patient</span><span>:
                                                                <label *ngIf="eraStatus?.patientResponsibilityAmount">$
                                                                    {{eraStatus?.patientResponsibilityAmount}}</label>
                                                                <label
                                                                    *ngIf="!eraStatus?.patientResponsibilityAmount">--</label>
                                                            </span></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <span class="label">Facility Type Code</span><span>:
                                                                {{eraStatus?.facilityTypeCode || '--'}}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <span class="label">Payor Claim Control
                                                                Number</span><span>:
                                                                {{eraStatus?.payerClaimControlNumber || '--'}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span class="label">Claim Filing Indicator</span><span>:
                                                                {{eraStatus?.claimFilingIndicatorCode || '--'}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <span class="label">Claim Frequency Code</span>
                                                            <span *ngIf="eraStatus?.claimFrequencyCode === '1'">:
                                                                {{individualClaimDetails?.submissionType}}</span>
                                                            <span *ngIf="eraStatus?.claimFrequencyCode !== '1'">:

                                                                {{eraStatus?.claimFrequencyCode === '7' ? 'CORRECTED' :
                                                                claim?.claimFrequencyCode
                                                                === '8' ? 'VOIDED' : '--'}}</span>

                                                        </td>


                                                    </tr>
                                                    <tr>
                                                        <td> <span class="label">Claim Status</span><span>:
                                                                {{eraStatus?.claimStatusCode || '--'}}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <span class="label">Patient Control Number</span><span>:
                                                                {{eraStatus?.patientControlNumber || '--'}}
                                                            </span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="label">Provider Type</span>
                                                            <span>: Billing Provider </span>
                                                        </td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" href="#claimHistory">CLAIM HISTORY</a>
                                        </h4>
                                    </div>
                                    <div id="claimHistory" class="panel-collapse collapse show">
                                        <div class="panel-body claim-history">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <b class="blacklabel">Claim Number:</b>
                                                </div>
                                                <div class="col-md-3">
                                                    {{claimNumber}}
                                                </div>
                                                <div class="col-md-3">
                                                    <b class="blacklabel">Patient Control Number:</b>
                                                </div>
                                                <div class="col-md-3">
                                                    {{claimResponse?.claimReference?.patientControlNumber
                                                    ||
                                                    '--'}}
                                                </div>
                                            </div>
                                            <!-- <h6 class="mt-2"><b>Aging History</b></h6> -->
                                            <table class="table table-bordered table-panel text-left">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                        <th>Aging</th>
                                                        <th>Notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngFor="let claim of agingHistory; let i = index">
                                                        <tr>
                                                            <th>{{(dateFormate(claim?.createdDate) | date: 'MM/dd/yyyy')
                                                                || '--'}}</th>
                                                            <td>{{claim?.toStatus || '--'}}</td>
                                                            <td>{{claim?.aging || '--'}}</td>
                                                            <td class="noteswidth">{{claim?.notes || '--'}}</td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" href="#claimSubmissionHistory">CLAIM SUBMISSION
                                                HISTORY</a>
                                        </h4>
                                    </div>

                                    <div id="claimSubmissionHistory" class="panel-collapse collapse show">
                                        <div class="panel-body claim-history">
                                            <ng-container
                                                *ngFor="let claims of allCurrentPreviousClaims; let claimIndex = index">
                                                <div class="row"
                                                    *ngIf="claims?.claimType === 'Previous' && claimIndex === 1">
                                                    <div class="col-12">
                                                        <h5>Previous Claims</h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <b class="blacklabel">Claim Number:</b>
                                                    </div>
                                                    <div class="col-md-3">
                                                        {{claims?.claimNumber || '--'}}
                                                    </div>
                                                    <div class="col-md-3">
                                                        <b class="blacklabel">Patient Control Number:</b>
                                                    </div>
                                                    <div class="col-md-3">
                                                        {{claims?.patientControlNumber || '--'}}
                                                    </div>
                                                </div>
                                                <table class="table table-bordered table-panel text-left">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Status</th>
                                                            <th>Aging</th>
                                                            <th>Notes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngFor="let claim of claims?.agingStatusHistory; let i = index">
                                                            <tr>
                                                                <th>{{(dateFormate(claim?.createdDate) | date: 'MM/dd/yyyy') || '--'}}</th>
                                                                <td>{{claim?.toStatus || '--'}}</td>
                                                                <td>{{claim?.aging || '--'}}</td>
                                                                <td class="noteswidth">{{claim?.notes || '--'}}</td>
                                                            </tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                            </ng-container>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="formclaim" class="tab-pane fade" [ngClass]="{'active': selectedLink === '1500form'}">
                    <div class="tab-innercontent">
                        <h5>HCFA 1500 FORM
                            <!--<button class="btn btn-primary download-form"><i class="las la-file-download"></i> Download</button>-->
                           
                          <button type="button" class="btn btn-primary dash-actions float-right mb-3" data-dismiss="modal"
                            (click)="openInitiateSendFaxModal(selectedClaimItem)">SEND FAX</button>
                        
                        </h5>
                        <div class="consent-pdf">
                            <iframe id="hcfa-form-view-detail" frameBorder="0" width="100%" height="500px"></iframe>
                        </div>
                    </div>
                </div>
                <div id="claimdetails" class="tab-pane fade" [ngClass]="{'active': selectedLink === 'claimDetails'}">
                    <div class="tab-innercontent">
                        <div class="order-accordion">
                            <div class="panel-group">
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" href="#providerinfo">PROVIDER INFORMATION</a>
                                        </h4>
                                    </div>
                                    <div id="providerinfo" class="panel-collapse collapse show">
                                        <div class="panel-body">
                                            <table class="table table-bordered table-panel panel-info">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Billing Provider</th>
                                                        <th class="text-center">Rendering Provider</th>
                                                        <th class="text-center">Referring Provider</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="padding10 width-3td"
                                                            *ngFor="let claimDetails of individualClaimDetails?.claimRequest?.providers; let prvi = index">
                                                            <table class="table table-borderless panel-info2">
                                                                <tbody>

                                                                    <tr>
                                                                        <td><span class="label">First Name</span><span>:
                                                                                {{claimDetails?.firstName ||
                                                                                '--' | titlecase}}</span> </td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td><span class="label">Last Name</span>
                                                                            <span>: {{claimDetails?.lastName ||
                                                                                '--' | titlecase}}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">NPI</span><span>:
                                                                                {{claimDetails?.npi || '--'}}</span>
                                                                        </td>
                                                                    </tr>


                                                                    <tr>
                                                                        <td> <span class="label">Tax Id</span><span>:
                                                                                {{claimDetails?.employerId ||
                                                                                '--'}}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">Taxonomy
                                                                                Code</span><span>:
                                                                                {{claimDetails?.taxonomyCode ||
                                                                                '--'}}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">State License #
                                                                            </span> <span>:
                                                                                {{claimDetails?.stateLicenseNumber ||
                                                                                '--'}}</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <h6 class="f14"><b>Contact Information</b></h6>
                                                            <table class="table table-borderless panel-info2">
                                                                <tbody>

                                                                    <tr>
                                                                        <td> <span class="label add-position">Address
                                                                            </span><span class="address address-width">
                                                                                {{claimDetails?.address?.address1}},
                                                                                <br>
                                                                                {{claimDetails?.address?.address2}},
                                                                                {{claimDetails?.address?.city}},<br>{{claimDetails?.address?.state}}
                                                                                -
                                                                                {{claimDetails?.address?.postalCode}}

                                                                            </span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span class="label">Phone #</span><span>:
                                                                                {{claimDetails?.contactInformation?.phoneNumber
                                                                                || '--'}}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="label">Fax #</span>
                                                                            <span>:
                                                                                {{claimDetails?.contactInformation?.faxNumber
                                                                                || '--'}}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span class="label">Email</span><span>:
                                                                                {{claimDetails?.contactInformation?.email
                                                                                || '--'}}
                                                                            </span> </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                             
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" href="#subscriberinfo">SUBSCRIBER INFORMATION</a>
                                        </h4>
                                    </div>
                                    <div id="subscriberinfo" class="panel-collapse collapse show">
                                        <div class="panel-body">
                                            <table class="table table-bordered table-panel panel-info">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Primary Information</th>
                                                        <th class="text-center">Dependent Information</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="w50 padding10 td-width">
                                                            <table class="table table-borderless panel-info2">
                                                                <tbody>

                                                                    <tr>
                                                                        <td><span class="label">First Name</span>
                                                                            <span>:
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.firstName
                                                                                || '--' | titlecase}} </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span class="label">Last Name</span>
                                                                            <span>:
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.lastName
                                                                                || '--' | titlecase}}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">Date of
                                                                                Birth</span><span>:
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.dateOfBirth
                                                                                ?
                                                                                dateFormate(individualClaimDetails?.claimRequest?.subscriber?.dateOfBirth)
                                                                                : '--'}}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">Gender</span><span>:
                                                                                <label
                                                                                    *ngIf="individualClaimDetails?.claimRequest?.subscriber?.gender === 'M'">Male</label>
                                                                                <label
                                                                                    *ngIf="individualClaimDetails?.claimRequest?.subscriber?.gender === 'F'">Female
                                                                                </label>
                                                                                <label
                                                                                    *ngIf="individualClaimDetails?.claimRequest?.subscriber?.gender === 'Other'">Other</label></span>
                                                                        </td>
                                                                    </tr>



                                                                    <tr>
                                                                        <td> <span class="label">Member ID</span>
                                                                            <span>:
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.memberId
                                                                                || '--'}}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">Group Number</span>
                                                                            <span>:
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.groupNumber
                                                                                || '--'}}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">Policy Number</span>
                                                                            <span>:
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.policyNumber
                                                                                || '--'}}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">Email</span> <span>:
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.email
                                                                                || '--'}}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">Organization
                                                                                Name</span> <span
                                                                                class="organization-name">:
                                                                                {{individualClaimDetails?.claimRequest?.receiver?.organizationName
                                                                                || '--'}}</span></td>
                                                                    </tr>


                                                                    <tr>
                                                                        <td><span class="label">
                                                                                Subscriber Address</span><span
                                                                                class="address address-width">
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.address?.address1}},
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.address?.city}},<br>
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.address?.state}}
                                                                                -
                                                                                {{individualClaimDetails?.claimRequest?.subscriber?.address?.postalCode}}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">Claim Payor
                                                                                Id</span><span>:
                                                                                {{individualClaimDetails?.claimRequest?.tradingPartnerServiceId
                                                                                || '--'}}</span></td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                             
                                                        </td>
                                                        <td class="padding10 td-width">
                                                            <table class="table table-borderless panel-info2">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span class="label">First Name</span>
                                                                            <span>:
                                                                                {{individualClaimDetails?.claimRequest?.dependent?.firstName
                                                                                || '--'}}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span class="label">Last
                                                                                Name</span><span>:
                                                                                {{individualClaimDetails?.claimRequest?.dependent?.lastName
                                                                                || '--'}}</span> </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td> <span class="label">Date of
                                                                                Birth</span><span>:
                                                                                {{dateFormate(individualClaimDetails?.claimRequest?.dependent?.dateOfBirth)
                                                                                || '--'}}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span class="label">Gender</span><span>:
                                                                                <label
                                                                                    *ngIf="individualClaimDetails?.claimRequest?.dependent?.gender === 'M'">Male</label>
                                                                                <label
                                                                                    *ngIf="individualClaimDetails?.claimRequest?.dependent?.gender === 'F'">Female
                                                                                </label>
                                                                                <label
                                                                                    *ngIf="individualClaimDetails?.claimRequest?.dependent?.gender === 'Other'">Other</label>

                                                                                <span
                                                                                    *ngIf="!individualClaimDetails?.claimRequest?.dependent?.gender">
                                                                                    --</span>

                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span class="label add-position">
                                                                                Subscriber Address </span>
                                                                            <span> :
                                                                                <span class="address address-width"
                                                                                    *ngIf="individualClaimDetails?.claimRequest?.dependent">

                                                                                    <span
                                                                                        *ngIf="individualClaimDetails?.claimRequest?.dependent?.address?.address1 !== ''">{{individualClaimDetails?.claimRequest?.dependent?.address?.address1}},</span>

                                                                                    <span
                                                                                        *ngIf="individualClaimDetails?.claimRequest?.dependent?.address?.address2 !== ''">{{individualClaimDetails?.claimRequest?.dependent?.address?.address2}},</span>

                                                                                    <span
                                                                                        *ngIf="individualClaimDetails?.claimRequest?.dependent?.address?.city !== ''">{{individualClaimDetails?.claimRequest?.dependent?.address?.city}},</span>

                                                                                    <span
                                                                                        *ngIf="individualClaimDetails?.claimRequest?.dependent?.address?.state !== ''">{{individualClaimDetails?.claimRequest?.dependent?.address?.state}}
                                                                                        - </span>

                                                                                    <!-- {{individualClaimDetails?.claimRequest?.dependent?.address?.address2}},
                                                                                {{individualClaimDetails?.claimRequest?.dependent?.address?.city}},<br>
                                                                                {{individualClaimDetails?.claimRequest?.dependent?.address?.state}}
                                                                                -
                                                                                {{individualClaimDetails?.claimRequest?.dependent?.address?.postalCode}}-->

                                                                                </span>
                                                                                <span
                                                                                    *ngIf="!individualClaimDetails?.claimRequest?.dependent">--</span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <ng-container
                                                                *ngIf="individualClaimDetails?.claimRequest?.payerAddress">
                                                                <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                                                                    <h5>Payor Address</h5>
                                                                    <hr />
                                                                </div>
                                                                <div class="col-lg-12 col-md-12 col-sm-12">

                                                                    <table class="table table-borderless panel-info2">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><span class="label">Address</span>
                                                                                    <span>
                                                                                        {{individualClaimDetails?.claimRequest?.payerAddress?.address1}},
                                                                                        {{individualClaimDetails?.claimRequest?.payerAddress?.city}},
                                                                                        {{individualClaimDetails?.claimRequest?.payerAddress?.state}}
                                                                                        -
                                                                                        {{individualClaimDetails?.claimRequest?.payerAddress?.postalCode}}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </ng-container>




                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a data-toggle="collapse" href="#serviceLineDetails">SERVICE LINE
                                                DETAILS</a>
                                        </h4>
                                    </div>
                                    <div id="serviceLineDetails" class="panel-collapse collapse show">
                                        <div class="panel-body">
                                            <!--   <h5>Service Line Details</h5> -->
                                            <!-- Claim Information -->
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <!--  <h5 class="mt-3">Claim Submission</h5>
                <hr />-->
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="mb-2"><b>Diagnosis Codes (ICD 10)</b></div>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>DIAGNOSIS POINTER</th>
                                                                <th>DIAGNOSIS CODE</th>
                                                                <th>DIAGNOSIS DESCRIPTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container
                                                                *ngFor="let icdCodes of individualClaimDetails?.claimRequest?.claimInformation?.healthCareCodeInformation; let i = index">
                                                                <tr>
                                                                    <td>{{i+1}}</td>
                                                                    <td>{{icdCodes?.diagnosisCode || '--'}}</td>
                                                                    <td>{{icdCodes?.description || '--'}}</td>
                                                                </tr>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="!individualClaimDetails?.claimRequest?.claimInformation?.healthCareCodeInformation || individualClaimDetails?.claimRequest?.claimInformation?.healthCareCodeInformation?.length === 0">
                                                                <tr>
                                                                    <td colspan="3" style="text-align: center;">
                                                                        Diagnosis details are not available</td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="mb-2"><b>Procedure Details</b></div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>DIAGNOSIS POINTER</th>
                                                                <th>CPT/HCPCS CODE</th>
                                                                <th>CPT DESCRIPTION</th>
                                                                <th>CHARGES</th>
                                                                <th>PLACE OF SERVICE</th>
                                                                <th>DAYS / UNITS</th>
                                                                <th>SERVICE DATE</th>
                                                                <th>IDENTIFIER</th>
                                                                <th>MODIFIER</th>
                                                                <th>DRUG IDENTIFICATION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container
                                                                *ngFor="let claimCptCodes of individualClaimDetails?.claimRequest?.claimInformation?.serviceLines; let i = index">
                                                                <tr>
                                                                    <td>
                                                                        <span
                                                                            *ngFor="let pointer of claimCptCodes?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers">{{pointer}}
                                                                        </span>
                                                                    </td>
                                                                    <td>{{claimCptCodes?.professionalService?.procedureCode
                                                                        || '--'}}</td>
                                                                    <td>{{claimCptCodes?.professionalService?.description
                                                                        || '--'}}</td>
                                                                    <td>${{claimCptCodes?.professionalService?.lineItemChargeAmount
                                                                        || '0'}}</td>
                                                                    <td>{{claimCptCodes?.professionalService?.placeOfServiceCode
                                                                        || '--'}}</td>
                                                                    <td>{{claimCptCodes?.professionalService?.serviceUnitCount
                                                                        || '--'}}</td>
                                                                    <td>{{(claimCptCodes?.serviceDate ?
                                                                        dateFormate(claimCptCodes?.serviceDate) : '--')
                                                                        || '--'}}</td>
                                                                    <td>{{claimCptCodes?.professionalService?.procedureIdentifier
                                                                        || '--'}}</td>
                                                                    <td>{{claimCptCodes?.professionalService?.procedureModifiers
                                                                        || '--'}}</td>
                                                                    <td>
                                                                        <div *ngIf="!claimCptCodes?.drugIdentification">
                                                                            --</div>
                                                                        <div *ngIf="claimCptCodes?.drugIdentification">
                                                                            <p>Service ID Qualifier:
                                                                                &nbsp;<b>{{claimCptCodes?.drugIdentification?.serviceIdQualifier
                                                                                    ||
                                                                                    '--'}}</b></p>
                                                                            <p>National Drug Code:
                                                                                &nbsp;<b>{{claimCptCodes?.drugIdentification?.nationalDrugCode
                                                                                    ||
                                                                                    '--'}}</b></p>
                                                                           <!-- <p>Strength:
                                                                                &nbsp;<b>{{claimCptCodes?.drugIdentification?.strength
                                                                                    ||
                                                                                    '--'}}</b></p>-->
                                                                            <p>Measurement:
                                                                                &nbsp;<b>{{claimCptCodes?.drugIdentification?.measurementUnitCode
                                                                                    ||
                                                                                    '--'}}</b></p>
                                                                            <p>Dosage:
                                                                                &nbsp;<b>{{claimCptCodes?.drugIdentification?.nationalDrugUnitCount
                                                                                    ||
                                                                                    '--'}}</b></p>
                                                                            <p>Days/Units:
                                                                                &nbsp;<b>{{claimCptCodes?.professionalService?.serviceUnitCount
                                                                                    ||
                                                                                    '--'}}</b></p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="!individualClaimDetails?.claimRequest?.claimInformation?.serviceLines || individualClaimDetails?.claimRequest?.claimInformation?.serviceLines?.length === 0">
                                                                <tr>
                                                                    <td colspan="10" style="text-align: center;">Claim
                                                                        Information is not available</td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                                                <div class="form-group row">
                                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                                        <label class="labelStyle">Total Claim Charges</label> <span
                                                            class="label-colon"> : </span>
                                                    </div>
                                                    <div class="col-lg-9 col-md-9 col-sm-9">
                                                        <label><b>$
                                                                {{individualClaimDetails?.claimRequest?.claimInformation?.claimChargeAmount
                                                                ||
                                                                '--'}}</b></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="form-group row">
                                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                                        <label class="labelStyle">Additional Notes</label> <span
                                                            class="label-colon"> : </span>
                                                    </div>
                                                    <div class="col-lg-9 col-md-9 col-sm-9">
                                                        <label>{{individualClaimDetails?.claimRequest?.claimNote?.additionalInformation}}</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Claim Response -->
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <h5 class="mt-3">Claim Response</h5>
                                                <hr />
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label class="labelStyle">Submitted Date</label> <span
                                                            class="label-colon"> : </span>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        {{claimResponse?.claimReference?.timeOfResponse}}
                                                        <label>{{claimResponse?.claimReference?.timeOfResponse
                                                            ?
                                                            getAppointmentTime(claimResponse?.claimReference?.timeOfResponse)
                                                            :
                                                            '--'}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label class="labelStyle">Status</label> <span
                                                            class="label-colon"> : </span>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label>{{claimResponse?.editStatus ||
                                                            '--'}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label class="labelStyle">RH Claim Number</label> <span
                                                            class="label-colon"> : </span>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label>{{claimResponse?.claimReference?.rhclaimNumber
                                                            || '--'}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label class="labelStyle">Patient Control Number</label> <span
                                                            class="label-colon"> : </span>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label>{{claimResponse?.claimReference?.patientControlNumber
                                                            ||
                                                            '--'}}</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div id="claimjournal" class="tab-pane fade" [ngClass]="{'active': selectedLink === 'claimJournal'}">
                    <div class="tab-innercontent">
                        <h5>Claim Journal</h5>
                        <app-claim-journal-details [claimDetails]='claimItem'
                            (onClaimJournalUpdate)="refreshClaims()" (onClose)="viewSubmittedClaimDetailsModal.hide()"></app-claim-journal-details>
                    </div>
                </div>
                <div id="facilityaction" class="tab-pane fade"
                    [ngClass]="{'active': selectedLink === 'facilityAction'}">
                    <h5>Facility Action</h5>
                  
                    <app-new-task  (onTaskUpdate)="refreshClaims()" (onCloseNewTask) = "viewSubmittedClaimDetailsModal.hide()" [idType]="'appointmentId'" [isClaim]="true" [isfromClaim]='true'></app-new-task>

                </div>
            </div>
        </div>

        <div *ngIf="onlyERAStatusShown">
            <div class="row" *ngIf="otherClaimDetals?.length > 0">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h5>Other Claim Information
                    </h5>
                    <hr />
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 Value-processor" *ngFor="let otherclaims of otherClaimDetals">
                    <div class="form-group row">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            Processor Type<span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <label><b>{{otherclaims.claimProcessorType || '--'}}</b></label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            claimNumber<span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <label><b>{{otherclaims.claimNumber || '--'}}</b></label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- only ERA STatus -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h5 class="mt-3">ERA Status</h5>
                    <hr />
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label class="labelStyle">Approved Amount</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label *ngIf="eraStatus?.totalClaimChargeAmount">$
                                {{eraStatus?.totalClaimChargeAmount}}</label>
                            <label *ngIf="!eraStatus?.totalClaimChargeAmount && claimStatus?.submittedAmount">$
                                {{claimStatus?.submittedAmount}}</label>
                            <label
                                *ngIf="!eraStatus?.totalClaimChargeAmount && !claimStatus?.submittedAmount">--</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label class="labelStyle">Claim Filing Indicator</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>{{eraStatus?.claimFilingIndicatorCode || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label class="labelStyle">AR Insurance</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label *ngIf="eraStatus?.claimPaymentAmount">$ {{eraStatus?.claimPaymentAmount}}</label>
                            <label *ngIf="!eraStatus?.claimPaymentAmount && claimStatus?.amountPaid">$
                                {{claimStatus?.amountPaid}}</label>
                            <label *ngIf="!eraStatus?.claimPaymentAmount && !claimStatus?.amountPaid">--</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label class="labelStyle">Claim Frequency Code</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>{{eraStatus?.claimFrequencyCode || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label class="labelStyle">AR Patient</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label *ngIf="eraStatus?.patientResponsibilityAmount">$
                                {{eraStatus?.patientResponsibilityAmount}}</label>
                            <label *ngIf="!eraStatus?.patientResponsibilityAmount">--</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label class="labelStyle">Claim Status</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>{{eraStatus?.claimStatusCode || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label class="labelStyle">Facility Type Code</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>{{eraStatus?.facilityTypeCode || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label class="labelStyle">Patient Control Number</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>{{eraStatus?.patientControlNumber || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label class="labelStyle">Payor Claim Control Number</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>{{eraStatus?.payerClaimControlNumber || '--'}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br><br><br>
        <!-- <div class="row" *ngIf="otherClaimDetals?.length > 0">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Other Claim Information
                </h5>
                <hr />
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 Value-processor" *ngFor="let otherclaims of otherClaimDetals">
               <div class="form-group row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        Processor Type<span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <label><b>{{otherclaims.claimProcessorType || '--'}}</b></label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        claimNumber<span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <label><b>{{otherclaims.claimNumber || '--'}}</b></label>
                    </div>
                </div>            
            </div>
        </div>
        <div class="row" *ngIf="!onlyERAStatusShown">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Provider Information</h5>
                <hr />
            </div>
            <ng-container
                *ngFor="let claimDetails of individualClaimDetails?.claimRequest?.providers; let prvi = index">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <label class="labelStyle"><b>Provider Type</b></label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <label><b>{{claimDetails?.providerType || '--'}}</b></label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">First Name</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{claimDetails?.firstName || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Last Name</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{claimDetails?.lastName || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">NPI</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{claimDetails?.npi || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">State License Number</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{claimDetails?.stateLicenseNumber || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Taxonomy Code</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{claimDetails?.taxonomyCode || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Tax Id</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{claimDetails?.employerId || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-2"><b>Contact Information</b></div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Fax Number</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{claimDetails?.contactInformation?.faxNumber || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Phone Number</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{claimDetails?.contactInformation?.phoneNumber || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Email</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{claimDetails?.contactInformation?.email || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <label class="labelStyle">Address</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <label>{{claimDetails?.address?.address1}}, {{claimDetails?.address?.address2}},
                                {{claimDetails?.address?.city}}, {{claimDetails?.address?.state}} -
                                {{claimDetails?.address?.postalCode}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-12"
                    *ngIf="(individualClaimDetails?.claimRequest?.providers?.length > 1 && prvi === 0) || (individualClaimDetails?.claimRequest?.providers?.length > 2 && prvi === 1)">
                    <hr />
                </div>
            </ng-container>

           
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Subscriber Information</h5>
                <hr />
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">First Name</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimRequest?.subscriber?.firstName || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Last Name</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimRequest?.subscriber?.lastName || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Email</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimRequest?.subscriber?.email || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Organization Name</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimRequest?.receiver?.organizationName || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Claim Payer Id</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimRequest?.tradingPartnerServiceId || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Member ID</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimRequest?.subscriber?.memberId || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Group Number</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimRequest?.subscriber?.groupNumber || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Policy Number</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimRequest?.subscriber?.policyNumber || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Date of Birth</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimRequest?.subscriber?.dateOfBirth ?
                            dateFormate(individualClaimDetails?.claimRequest?.subscriber?.dateOfBirth) : '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Gender</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label *ngIf="individualClaimDetails?.claimRequest?.subscriber?.gender === 'M'">Male</label>
                        <label *ngIf="individualClaimDetails?.claimRequest?.subscriber?.gender === 'F'">Female </label>
                        <label
                            *ngIf="individualClaimDetails?.claimRequest?.subscriber?.gender === 'Other'">Other</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <label class="labelStyle">Subscriber Address</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <label>{{individualClaimDetails?.claimRequest?.subscriber?.address?.address1}},
                            {{individualClaimDetails?.claimRequest?.subscriber?.address?.city}},
                            {{individualClaimDetails?.claimRequest?.subscriber?.address?.state}} -
                            {{individualClaimDetails?.claimRequest?.subscriber?.address?.postalCode}} </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-2"><b>Dependent Details</b></div>
            </div>
            <ng-container *ngIf="individualClaimDetails?.claimRequest?.dependent">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">First Name</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{individualClaimDetails?.claimRequest?.dependent?.firstName || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Last Name</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{individualClaimDetails?.claimRequest?.dependent?.lastName || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Date of Birth</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{dateFormate(individualClaimDetails?.claimRequest?.dependent?.dateOfBirth) || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Gender</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label *ngIf="individualClaimDetails?.claimRequest?.dependent?.gender === 'M'">Male</label>
                            <label *ngIf="individualClaimDetails?.claimRequest?.dependent?.gender === 'F'">Female
                            </label>
                            <label
                                *ngIf="individualClaimDetails?.claimRequest?.dependent?.gender === 'Other'">Other</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <label class="labelStyle">Address</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <label>{{individualClaimDetails?.claimRequest?.dependent?.address?.address1}},
                                {{individualClaimDetails?.claimRequest?.dependent?.address?.address2}},
                                {{individualClaimDetails?.claimRequest?.dependent?.address?.city}},
                                {{individualClaimDetails?.claimRequest?.dependent?.address?.state}} -
                                {{individualClaimDetails?.claimRequest?.dependent?.address?.postalCode}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!individualClaimDetails?.claimRequest?.dependent">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="text-center">No Dependent data available</label>
                </div>
            </ng-container>

           
            <ng-container *ngIf="individualClaimDetails?.claimRequest?.payerAddress">
                <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <h5>Payor Address</h5>
                    <hr />
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <label class="labelStyle">Address</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <label>{{individualClaimDetails?.claimRequest?.payerAddress?.address1}},
                                {{individualClaimDetails?.claimRequest?.payerAddress?.city}},
                                {{individualClaimDetails?.claimRequest?.payerAddress?.state}} -
                                {{individualClaimDetails?.claimRequest?.payerAddress?.postalCode}} </label>
                        </div>
                    </div>
                </div>
            </ng-container>

            
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5 class="mt-3">Claim Submission</h5>
                <hr />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-2"><b>Diagnosis Codes (ICD 10)</b></div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>DIAGNOSIS POINTER</th>
                                <th>DIAGNOSIS CODE</th>
                                <th>DIAGNOSIS DESCRIPTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let icdCodes of individualClaimDetails?.claimRequest?.claimInformation?.healthCareCodeInformation; let i = index">
                                <tr>
                                    <td>{{i+1}}</td>
                                    <td>{{icdCodes?.diagnosisCode || '--'}}</td>
                                    <td>{{icdCodes?.description || '--'}}</td>
                                </tr>
                            </ng-container>
                            <ng-container
                                *ngIf="!individualClaimDetails?.claimRequest?.claimInformation?.healthCareCodeInformation || individualClaimDetails?.claimRequest?.claimInformation?.healthCareCodeInformation?.length === 0">
                                <tr>
                                    <td colspan="3" style="text-align: center;">Diagnosis details are not available</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-2"><b>Claim Information</b></div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>DIAGNOSIS POINTER</th>
                                <th>CPT/HCPCS CODE</th>
                                <th>CPT DESCRIPTION</th>
                                <th>CHARGES</th>
                                <th>PLACE OF SERVICE</th>
                                <th>DAYS / UNITS</th>
                                <th>SERVICE DATE</th>
                                <th>IDENTIFIER</th>
                                <th>MODIFIER</th>
                                <th>DRUG IDENTIFICATION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let claimCptCodes of individualClaimDetails?.claimRequest?.claimInformation?.serviceLines; let i = index">
                                <tr>
                                    <td>
                                        <span
                                            *ngFor="let pointer of claimCptCodes?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers">{{pointer}}
                                            ,</span>
                                    </td>
                                    <td>{{claimCptCodes?.professionalService?.procedureCode || '--'}}</td>
                                    <td>{{claimCptCodes?.professionalService?.description || '--'}}</td>
                                    <td>${{claimCptCodes?.professionalService?.lineItemChargeAmount || '0'}}</td>
                                    <td>{{claimCptCodes?.professionalService?.placeOfServiceCode || '--'}}</td>
                                    <td>{{claimCptCodes?.professionalService?.serviceUnitCount || '--'}}</td>
                                    <td>{{(claimCptCodes?.serviceDate ? dateFormate(claimCptCodes?.serviceDate) : '--')
                                        || '--'}}</td>
                                    <td>{{claimCptCodes?.professionalService?.procedureIdentifier || '--'}}</td>
                                    <td>{{claimCptCodes?.professionalService?.procedureModifiers || '--'}}</td>
                                    <td>
                                        <div *ngIf="!claimCptCodes?.drugIdentification">--</div>
                                        <div *ngIf="claimCptCodes?.drugIdentification">
                                            <p>Service ID Qualifier:
                                                &nbsp;<b>{{claimCptCodes?.drugIdentification?.serviceIdQualifier ||
                                                    '--'}}</b></p>
                                            <p>National Drug Code:
                                                &nbsp;<b>{{claimCptCodes?.drugIdentification?.nationalDrugCode ||
                                                    '--'}}</b></p>
                                            <p>Strength: &nbsp;<b>{{claimCptCodes?.drugIdentification?.strength ||
                                                    '--'}}</b></p>
                                            <p>Measurement:
                                                &nbsp;<b>{{claimCptCodes?.drugIdentification?.measurementUnitCode ||
                                                    '--'}}</b></p>
                                            <p>Dosage:
                                                &nbsp;<b>{{claimCptCodes?.drugIdentification?.nationalDrugUnitCount ||
                                                    '--'}}</b></p>
                                            <p>Days/Units:
                                                &nbsp;<b>{{claimCptCodes?.professionalService?.serviceUnitCount ||
                                                    '--'}}</b></p>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container
                                *ngIf="!individualClaimDetails?.claimRequest?.claimInformation?.serviceLines || individualClaimDetails?.claimRequest?.claimInformation?.serviceLines?.length === 0">
                                <tr>
                                    <td colspan="10" style="text-align: center;">Claim Information is not available</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                <div class="form-group row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <label class="labelStyle">Total Claim Charges</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <label><b>$ {{individualClaimDetails?.claimRequest?.claimInformation?.claimChargeAmount ||
                                '--'}}</b></label>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <label>{{individualClaimDetails?.claimRequest?.claimNote?.additionalInformation}}</label>
                    </div>
                </div>
            </div>

           
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5 class="mt-3">Claim Response</h5>
                <hr />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Submitted Date</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimResponse?.claimReference?.timeOfResponse ?
                            getAppointmentTime(individualClaimDetails?.claimResponse?.claimReference?.timeOfResponse) :
                            '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Status</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimResponse?.editStatus || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">RH Claim Number</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimResponse?.claimReference?.rhclaimNumber || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Patient Control Number</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{individualClaimDetails?.claimResponse?.claimReference?.patientControlNumber ||
                            '--'}}</label>
                    </div>
                </div>
            </div>

           
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5 class="mt-3">Claim Status</h5>
                <hr />
            </div>
            
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <label class="labelStyle">Status Category</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <label>{{claimStatus?.statusCategoryCode}} -
                            {{claimStatus?.statusCategoryCodeValue}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <label class="labelStyle">Status</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <label>{{claimStatus?.statusCode}} - {{claimStatus?.statusCodeValue}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Patient Account Number</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{claimStatus?.patientAccountNumber || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Submitted Amount</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label *ngIf="claimStatus?.submittedAmount">$ {{claimStatus?.submittedAmount}}</label>
                        <label *ngIf="!claimStatus?.submittedAmount">--</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Amount Paid</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label *ngIf="claimStatus?.amountPaid">$ {{claimStatus?.amountPaid}}</label>
                        <label *ngIf="!claimStatus?.amountPaid">--</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Paid Date</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{claimStatus?.paidDate ? dateFormate(claimStatus?.paidDate) : '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Check Number</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{claimStatus?.checkNumber || '--'}}</label>
                    </div>
                </div>
            </div>
            
        </div>

        
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5 class="mt-3">ERA Status</h5>
                <hr />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label class="labelStyle">Approved Amount</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label *ngIf="eraStatus?.totalClaimChargeAmount">$ {{eraStatus?.totalClaimChargeAmount}}</label>
                        <label *ngIf="!eraStatus?.totalClaimChargeAmount && claimStatus?.submittedAmount">$
                            {{claimStatus?.submittedAmount}}</label>
                        <label *ngIf="!eraStatus?.totalClaimChargeAmount && !claimStatus?.submittedAmount">--</label>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label class="labelStyle">Claim Filing Indicator</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label>{{eraStatus?.claimFilingIndicatorCode || '--'}}</label>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label class="labelStyle">AR Insurance</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label *ngIf="eraStatus?.claimPaymentAmount">$ {{eraStatus?.claimPaymentAmount}}</label>
                        <label *ngIf="!eraStatus?.claimPaymentAmount && claimStatus?.amountPaid">$
                            {{claimStatus?.amountPaid}}</label>
                        <label *ngIf="!eraStatus?.claimPaymentAmount && !claimStatus?.amountPaid">--</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label class="labelStyle">Claim Frequency Code</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label>{{eraStatus?.claimFrequencyCode || '--'}}</label>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label class="labelStyle">AR Patient</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label *ngIf="eraStatus?.patientResponsibilityAmount">$
                            {{eraStatus?.patientResponsibilityAmount}}</label>
                        <label *ngIf="!eraStatus?.patientResponsibilityAmount">--</label>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label class="labelStyle">Claim Status</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label>{{eraStatus?.claimStatusCode || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label class="labelStyle">Facility Type Code</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label>{{eraStatus?.facilityTypeCode || '--'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label class="labelStyle">Patient Control Number</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label>{{eraStatus?.patientControlNumber || '--'}}</label>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label class="labelStyle">Payer Claim Control Number</label> <span class="label-colon"> :
                        </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label>{{eraStatus?.payerClaimControlNumber || '--'}}</label>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
    <div class="app-modal-footer">
        <!-- <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="viewSubmittedClaimDetailsModal.hide()">Ok</button>-->
    </div> 
</app-ui-modal>

<!-- Show More API Resources -->
<app-fax-referral-common  [appointmentId]="selectedappointmentId" [quickParentForm]="sendNewReferralForm" [documentName]="'Efax'" [defaultFile]="this.sendNewReferralForm?.value?.multipartFile" [userAppointmentDetails]="selectedClaimItem" [userInfoId]="selectedUserinfoId" [filesentFrom]="'1500form'" [filesentSource]="'1500form'"></app-fax-referral-common>
