import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-patient-basic-information',
  templateUrl: './patient-basic-information.component.html',
  styleUrls: ['./patient-basic-information.component.scss']
})
export class PatientBasicInformationComponent implements OnInit {

  //@Input() userInfo: any;
  @Input() userInfoId: any;
  public userInfoData: any;
  public userPatientEmrId: any;
 // public userInfoId: any;
  constructor(private httpService: HttpService, private router: Router, private activateRoute: ActivatedRoute) {
	
  }

  ngOnInit(): void {
    this.getUserInfoDetails();
   //this.userInfoId = this.userInfo.id;
  }
  
  private getUserInfoDetails(): void {
    // const action = `userInfo/getUserInfo?id=${this.userInfoId}`;
	
    const action = `common/getChartInfo?userInfoId=${this.userInfoId}&chartModuleName=UserInfo`;
	
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
	    this.userInfoData = (data?.responseObject && data?.responseObject?.userInfo) || null;
		console.log(this.userInfoData);
        
        this.userPatientEmrId = data?.responseObject && data?.responseObject?.patientId;
        
      }
      
    },
      (error) => {
       
      });

  }
   public ageCalculate(dateOfBirth: any): any {
    return this.httpService?.calculateAge(dateOfBirth);
  }
   public formatDob(date: string): any {
    return this.httpService?.formatDobTimeZoneWithDisplay(date);
  }
  public addPrefixZipCode(addressZipCode: any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
   
    
    return prefixZipcode || addressZipCode || '';
  }

}
