<!-- <div class="row" [ngClass]="{'toggle-collapsed': addCss}" id="chart-account-docs-view"> -->
<!-- <p class="mt-4"></p> -->
<div class="row">
    <div class="col-lg-8 col-md-8 col-sm-8"></div>
    <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="document-form" [formGroup]="filterDoctypeForm">
            <div class="form-group">
                <label for="documentType" style="font-weight: 600;">Document Type</label>
                <ng-select [searchable]="false" formControlName="filterdocumentType" [clearable]="false"
                    placeholder="Add or Choose Document Type" [items]="allDocumentTypes" bindLabel="documentType"
                    bindValue="documentType" [addTag]="true" (change)="filterDocuments($event)"></ng-select>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="table-responsive new-designed-table">
            <table class="table table-striped table-bordered table-hover" [class.careflow-right-view]="careFlowFont" datatable [dtOptions]="dtOptionsAccountDocs"
                [dtTrigger]="dtTriggerAccountDocs">
                <thead>
                    <tr>
                        <th>Document Name</th>
                        <!--<th>Classification</th>-->
                        <th>DOCUMENT TYPE</th>
                        <!--<th>Sevice Date</th>-->
                        <th>Updated Date</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let document of allUploadedDocumentsForUser; let i = index">

                        <td>{{document?.documentName || '--'}}</td>
                        <!--<td>{{document?.classificationType || '--'}}</td>-->
                        <td>{{document?.documentType || '--'}}</td>
                        <td>
                            {{formateDate(document?.auditInfo?.updatedDate)
                            ||
                            '--'}}</td>
                        <td>
                            <div class="dropdown-actions  text-center">
                                <button class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}" id="claimActions_btn" (click)="openUniversalActions($event, i)">...</button>
                                <div class="dropdown-content-actions" *ngIf="openActions && selectedActionRow === i">
                                    <a class="view menulink" id="view"
                                        (click)="viewDownloadDocument(i, document, 'view', viewDownloadDocumentModal);closeUniversalActions();">
                                        <span class="material-symbols-outlined">visibility</span> View</a>
                                    <a class="view menulink" id="download"
                                        (click)="viewDownloadDocument(i, document, 'download', patientMedicalRecordModal);closeUniversalActions();">
                                        <span class="material-symbols-outlined">download</span> Download</a>
                                    <a class="view menulink" id="delete"
                                        (click)="deleteMedicalRecordOpen(document, deletConfirmPatientMedical);closeUniversalActions();">
                                        <span class="material-symbols-outlined">delete</span> Delete</a>
                                </div>
                            </div>
                        </td>
                    </tr>


                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="custom-loader justify-content-center" *ngIf="loadSpinner">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Display the Document PDF -->
<app-ui-modal #patientMedicalRecordModal [hideHeader]="false" [modalCentered]="true" id="patientMedicalRecordModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Patient Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="patientMedicalRecordModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="consent-pdf" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="patientMedicalRecordModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<app-ui-modal #viewDownloadDocumentModal class="common-modal" [hideHeader]="false" [modalCentered]="true"
    id="viewDownloadDocumentModal">
    <div class="app-modal-header">
        <h5 class="modal-title">{{selectedDocumentName}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="viewDownloadDocumentModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body pb-0">
        <div class="consent-pdf">
            <iframe id="consent-pdf-view" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
        <!-- <div class="verify-stick-menu">
            <a class="verify-prev" *ngIf="allUploadedDocumentsForUser.length > 1 && this.selectedRecord !==0"
                (click)="callNextPrev('prev', this.selectedRecord, 'view', viewDownloadDocumentModal)"> &lt; &nbsp;
                Previous</a>
            <ul class="stick-menu-list">
                <li class="menu-select">
                    <a class="btn btn-primary" (click)="viewDownloadDocumentModal.hide()" id="close">
                        Close
                    </a>
                </li>
            </ul>
            <a class="verify-next"
                *ngIf="allUploadedDocumentsForUser.length > 1 && this.selectedRecord !== allUploadedDocumentsForUser.length-1"
                (click)="callNextPrev('next', this.selectedRecord, 'view', viewDownloadDocumentModal)"> Next &nbsp;
                &gt;</a>
        </div> -->
    </div>
    <div class="app-modal-footer">
        <div class="footer-actions-btns">
            <div class="prev-link text-left" style="width: 25%;">
                <a class="verify-prev" href="javascript:;"
                    *ngIf="allUploadedDocumentsForUser.length > 1 && this.selectedRecord !==0"
                    (click)="callNextPrev('prev', this.selectedRecord, 'view', viewDownloadDocumentModal)"> &lt; &nbsp;
                    Previous</a>
            </div>
            <div class="center-btn text-center" style="width: 50%;">
                <a class="btn btn-primary" href="javascript:;" (click)="viewDownloadDocumentModal?.hide()" id="close">
                    Close
                </a>
            </div>
            <div class="next-link text-right" style="width: 25%;">
                <a class="verify-next" href="javascript:;"
                    *ngIf="allUploadedDocumentsForUser.length > 1 && this.selectedRecord !== allUploadedDocumentsForUser.length-1"
                    (click)="callNextPrev('next', this.selectedRecord, 'view', viewDownloadDocumentModal)"> Next &nbsp;
                    &gt;</a>
            </div>
        </div>
        <!-- <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
                     >Ok</button> -->
    </div>
</app-ui-modal>

<!-- confirm the delete Patient Additional  Document -->
<app-ui-modal #deletConfirmPatientMedical [hideHeader]="false" [modalCentered]="true" id="deletConfirmPatientMedical">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title text-center">Do you want to delete the Patient's Additional  Document?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="deletConfirmPatientMedical?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left;">Do you want to delete the Patient's Additional
                    Document?</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="deletConfirmPatientMedical.hide()">No</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="deletConfirmPatientMedical?.hide(); deletePatientMedicalDocument(selectedMedicalRecordForDelete)">Yes</button>
    </div>
</app-ui-modal>

<!-- Upload Sucess Notify -->
<app-ui-modal #documentUploadNotify [hideHeader]="false" id="documentUploadNotify">
    <div class="app-modal-header">
        <!--  <h5 class="modal-title" [innerHTML]="notifyText"></h5>-->
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 *ngIf="isSuccessNotify" class="modal-title text-success text-center" style="font-size: 50px;"><i
                        class="fa fa-check-circle" aria-hidden="true"></i>
                </h5>
                <h5 *ngIf="!isSuccessNotify" class="modal-title text-danger text-center" style="font-size: 40px;">
                    <i class="las la-minus-circle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">
                <h5 class="modal-title" style=" text-align: left; ">{{notifyText}}</h5>
            </div>
        </div>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="documentUploadNotify.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="ok2"
            (click)="documentUploadNotify.hide(); openNotificationJournalNotesAdd()">Ok</button>
    </div>
</app-ui-modal>