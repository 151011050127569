<div class="followup-queue-total" id="followup-queue-list">
    <app-card #followupCard cardClass="card-datatable" class="new-designed-card" [options]="false">

      

            <div class="pull-right">
                <button class="flow-board-main-actions new-appointment-action" (click)="openFilterSection();">
                    <span class="material-symbols-outlined" id="followupQueueList_filter">
                        filter_alt
                    </span>
                </button>
            </div>
       
        <div [ngClass]="{'col-collapse': openFilters}">
            <div class="table-responsive new-designed-table">
                <table datatable [dtOptions]="dtOptionsFollowup" [dtTrigger]="dtTriggerFollowup" id="report-table"
                    class="table table-striped table-hover custom-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <!-- <th>Gender</th>
                            <th>Age</th> -->
                            <th>Contact</th>
                            <th>Purpose Of Visit</th>
                            <th>Scheduled/Follow Up</th>
                            <th>Status</th>
                            <th>Notes</th>
                            <th class="text-center no-sort">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="appointment-tr-queue" *ngFor="let followup of followupList; let i=index">
                            <td>{{followup?.lastName | titlecase}}, {{followup?.firstName | titlecase}} <br>
                                <span
                                    style="font-size: 12px; color: #373a3c; font-weight: 600;">{{followup?.gender}}</span>
                                &nbsp;&nbsp; <span
                                    style="font-size: 12px; color: #373a3c; font-weight: 600;">{{followup?.age}}</span>
                            </td>
                            <!-- <td><span>{{followup?.gender}}</span></td>
                            <td><span>{{followup?.age}}</span></td> -->
                            <td>{{followup?.patientPrimaryPhone || '--'}}</td>
                            <td>{{followup?.purposeOfVisit}}</td>
                            <td [ngClass]="followup.sourceType === 'Wait List' ? 'datecolor' : ''" class="time-tooltip">
                                {{getAppointmentTime(followup?.followupDate)}}

                                <div class="on-hover-date-box" style="width: 100px;"
                                    *ngIf="followup.sourceType === 'Wait List'">
                                    <ul
                                        style="white-space: normal; padding-left: 0px; margin-bottom: 5px; margin-top: 5px;">
                                        <li style="padding-left: 15px;">
                                            {{getAppointmentTimeString(followup?.followupDate)}}</li>
                                    </ul>
                                </div>
                            </td>
                            <td class="appointment-status-clrs">
                                <span class="status-clr">{{followup?.sourceType}}</span>
                            </td>
                            <td class="colWidthTask tooltip-show">

                                <div class="task-details">
                                    <span>{{followup?.latestNotes || '--'}} </span><br>
                                </div>
                                <div class="show-text"
                                    *ngIf="followup?.latestNotes !== '' && followup?.latestNotes !== null">
                                    <span><b>Notes:</b> {{followup?.latestNotes || '--'}} </span>

                                </div>

                            </td>
                            <td class="text-center">
                                <div class="dropdown-actions">
                                    <button
                                        class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}"
                                        id="claimActions_btn" (click)="openUniversalActions($event, i);">...</button>
                                    <div class="dropdown-content-actions"
                                        *ngIf="openActions && selectedActionRow === i">
                                        <a href="javascript:;" (click)="openNotifyFollowup(followup);closeUniversalActions();"><i
                                                class="lab la-telegram"></i>Notify</a>
                                        <a href="javascript:;" (click)="openScheduleFollowup(followup);closeUniversalActions();"
                                            *ngIf="followup.sourceType === 'Follow Up'">
                                            <span class="material-symbols-outlined">history</span>Schedule</a>
                                        <a href="javascript:;" (click)="openRescheduleFollowup(followup?.appointmentId);closeUniversalActions();"
                                            *ngIf="followup.sourceType === 'Wait List'">
                                            <span class="material-symbols-outlined">history</span> Re-Schedule</a>
                                        <a href="javascript:;" (click)="openNotesFollowup(followup);closeUniversalActions();">
                                            <span class="material-symbols-outlined">speaker_notes</span>Notes</a>
                                    </div>
                                </div>

                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
            <div class="summary-card" style="margin-top: 20px;">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="Summary" style="padding-top: 0px;">Summary</h4>
                                <div class="vl"></div>
                            </div>
                            <div class="col-md-5">
                                <div class="Count">{{counterInfo?.totWaitlistCount}}</div>
                                <div class="summary-name">Wait List Count</div>
                                <div class="vl"></div>
                            </div>
                            <div class="col-md-5">
                                <div class="Count">{{counterInfo?.totFollowUpCount}}</div>
                                <div class="summary-name">Follow-up Count</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [formGroup]="filtersForm">

            <div class="filters-col" *ngIf="openFilters">
                <h5>Filters <span class="close pull-right" (click)="closeFiltersection()">&times;</span></h5>
                <div class="form-group">
                    <label>Type</label>
                    <ng-select placeholder="Choose Type" id="type-list" (change)="enableFilters($event)"
                        [items]="typeList" formControlName="sourceType"></ng-select>


                </div>
                <div class="form-group">
                    <label>Purpose of Visit</label>

                    <ng-select placeholder="Choose Purpose of Visit" id="visit-info-purpose-visit"
                        formControlName="purposeOfVisit" [items]="purposeOfVisitList"
                        (change)="enableFilters($event)"></ng-select>
                </div>

                <div class="form-group">
                    <label>Follow-up/Wait List Date</label>

                    <input type="date" onkeydown="return false" formControlName="followupDate" name="datemin"
                        class="form-control input-text" id="future-appointment-date"
                        placeholder="Follow-up/ Wait list Date">
                </div>
                <div class="form-group text-center">
                    <button class="btn btn-primary" id="followupQueueList_submit" (click)="getSearchList()"
                        (click)="closeFiltersection()">Submit</button>
                    <button class="btn btn-primary ml-2" *ngIf="filterEnabled" (click)="clearFilters()" id="followupQueueList_clear">Clear</button>

                </div>
            </div>
        </div>
    </app-card>

</div>

<app-exception-modal [notifyText]="notifyText"></app-exception-modal>
<app-schedule-followup-appointment [appointmentId]="selectedAppointmentId"
    [scheduleType]="scheduleType"></app-schedule-followup-appointment>
<app-followup-notification></app-followup-notification>
<app-followup-notes (refreshFollowup)="refresh()"></app-followup-notes>
<app-re-schedule-appointment [exceptionModal]="exceptionModal"></app-re-schedule-appointment>
<app-send-sms-notify [followupQueueAppt]="followupQueueAppt" [pageType]="'Future Appt'"></app-send-sms-notify>