import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-vitals',
  templateUrl: './review-vitals.component.html',
  styleUrls: ['./review-vitals.component.scss']
})
export class ReviewVitalsComponent {

  @Input() vitalsData: any;

}
