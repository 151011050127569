import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';
import { WizardComponent } from 'angular-archwizard';
import { LabRadResultsModalComponent } from '../lab-rad-results-modal/lab-rad-results-modal.component';
import { LabRadOrderDetailsModalComponent } from '../lab-rad-order-details-modal/lab-rad-order-details-modal.component';
import { Hl7DetailsComponent } from '../hl7-details/hl7-details.component';
@Component({
  selector: 'app-rx-lab-order',
  templateUrl: './rx-lab-order.component.html',
  styleUrls: ['./rx-lab-order.component.scss']
})
export class RxLabOrderComponent implements OnInit, OnDestroy, AfterViewInit {

  public patientRxOrderLookupURL: string;
  public rxOrderDisplay: boolean;
  public labRadOrderDisplay: boolean;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @Input() orderType: string
  @Input() patientDetailsInfo: any;
  @Input() cardRefresh: CardComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @Input() hideSaveRxOrderButton: any;

  public orderTypeSelection = [
    { value: 'Rx', desc: 'Rx Order' },
    { value: 'Order', desc: 'Lab/Rad Order' }
  ]
  public loginUser: any;
  public rxLabOrderForm: FormGroup;
  alllabRadOrderDetails: any;
  lateNotifyPatient: any;
  @ViewChild(SendSmsNotifyComponent) notifyComp: SendSmsNotifyComponent;
  public value: any = '';
  public nameOnLabel: any = '';
  public orderAccountNum: any = '';
  public ordeNum: any = '';
  @Input() active: any;
  @Input() careFlowwizard: WizardComponent;
  @Input('rxLabOrderNotify') rxLabOrderNotify: UiModalComponent;
  @Input() isCareFlow: boolean;
  @ViewChild(LabRadResultsModalComponent) labRadResultsComp: LabRadResultsModalComponent;
  @ViewChild(LabRadOrderDetailsModalComponent) labRadOrderDetailsComp: LabRadOrderDetailsModalComponent;
  @ViewChild(Hl7DetailsComponent) public hl7DetailsComp: Hl7DetailsComponent;
  
  public openActions: boolean = false;
  public enableActions: boolean = false;
  public selectedActionRow: any;
  
   @HostListener('document:click', ['$event'])
    clickInside(event) {
	
     const eventId = (event.target as HTMLInputElement).id;
	 if(!this.enableActions && eventId !== 'claimActions_btn'){
	     this.openActions = false;
	  }	
  }

  constructor(private httpService: HttpService, private formBuilder: FormBuilder, private router: Router) {
    this.rxLabOrderForm = this.formBuilder?.group({
      'orderSelection': [null]
    });
    this.active = this.active || 1;
  };

  ngOnInit(): void {
    this.patientRxOrderLookup('Rx', 'rxorder');
    this.rxLabOrderForm?.get('orderSelection')?.setValue('Rx');
  }

  ngOnChanges(): void {
    if (this.patientDetailsInfo && this.orderType) {
      this.rxLabOrderForm?.get('orderSelection')?.setValue(this.orderType);
      // setTimeout(() => {
      //   let addElement: HTMLElement
      //   if (this.orderType === 'Rx') {
      //     addElement = document?.getElementById('rxOrdersCollapse') as HTMLElement;
      //   }
      //   if (this.orderType === 'Order') {
      //     addElement = document?.getElementById('labRadOrdersCollapse') as HTMLElement;
      //   }
      //   if (addElement) {
      //     addElement?.removeAttribute('class');
      //     addElement?.setAttribute('class', 'panel-collapse fade in collapse show');
      //   }
      //   this.patientRxOrderLookup(this.orderType);
      // });
    }
    let iframeType: string;
    if (this.active === 1) {
      iframeType = 'rxorder';
      this.rxLabOrderForm?.get('orderSelection')?.setValue('Rx');
      this.patientRxOrderLookup('Rx', iframeType);
    }
    if (this.active === 2) {
      iframeType = 'labrad';
      this.rxLabOrderForm?.get('orderSelection')?.setValue('Order');
      this.patientRxOrderLookup('Order', iframeType);
    }
    if (this.active === 3) {
      this.makeAdhocLabOrdersChangeHealth();
    }
  }

  ngAfterViewInit(): void {
    if (this.patientDetailsInfo && this.orderType) {
      setTimeout(() => {
        let addElement: HTMLElement
        let iframeType: string;
        if (this.orderType === 'Rx') {
          // addElement = document?.getElementById('rxOrdersCollapse') as HTMLElement;
          iframeType = 'rxorder';
          this.active = 1;
          this.rxLabOrderForm?.get('orderSelection')?.setValue('Rx');
        }
        if (this.orderType === 'Order') {
          // addElement = document?.getElementById('labRadOrdersCollapse') as HTMLElement;
          iframeType = 'labrad';
          this.active = 2;
          this.rxLabOrderForm?.get('orderSelection')?.setValue('Order');
        }
        // if (addElement) {
        //   addElement?.removeAttribute('class');
        //   addElement?.setAttribute('class', 'panel-collapse fade in collapse show');
        // }
        this.patientRxOrderLookup(this.orderType, iframeType);
      });
    }
  }

  // order selection to make service call
  public onRXLabRadRadioButtonChange(orderOrRx: string, iframType?: string): void {

    const iframeEle = document?.getElementById(`${iframType}-iframe-plan`) as HTMLElement;
    if (iframeEle?.hasAttribute('src')) {
      return;
    }
    // iframeEle?.setAttribute('src', this.patientRxOrderLookupURL);

    this.patientRxOrderLookupURL = '';
    iframeEle?.removeAttribute('src');
    this.rxLabOrderForm?.get('orderSelection')?.setValue(orderOrRx);
    this.patientRxOrderLookup(orderOrRx, iframType);
  }

  // rx/lab/rad orders service call trigger
  private patientRxOrderLookup(orderOrRx: string, iframType?: string): void {
    this.loginUser = JSON.parse(sessionStorage?.getItem('userDetails'));
    this.cardRefresh.cardRefreshShow();
    this.rxOrderDisplay = true;
    let action: any = 'changeHealth/patientRxOrderLookup';
    //  let action: any = orderOrRx === 'Rx' ? 'changeHealth/patientRxHistory' : 'changeHealth/patientRxOrderLookup';
    let params: any = {
      'appointmentId': this.patientDetailsInfo?.appointment ? this.patientDetailsInfo?.id : '',
      'dob': this.patientDetailsInfo?.dob || '',
      'doctorNpi': parseInt(this.loginUser?.providerNPI) || null, // this.patientDetailsInfo?.appointment?.doctorNpi
      'facilityId': this.loginUser?.facilityId || this.patientDetailsInfo?.facilityId,
      'firstName': this.patientDetailsInfo?.firstName || '',
      'lastName': this.patientDetailsInfo?.lastName || '',
      'orderOrRx': orderOrRx || '',
      'phoneNumber': this.patientDetailsInfo?.patientPrimaryPhone || this.patientDetailsInfo?.primaryPhone || '',
    }

    // if(orderOrRx === 'Order') {
    //   params.orderOrRx = orderOrRx;
    // }

    this.patientRxOrderLookupURL = '';
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data != null && data != undefined) {
          this.patientRxOrderLookupURL = data.body.msg;
          if(this.patientRxOrderLookupURL?.includes('access missing')) {
            this.notifyText = data.body.msg;
            if(this.careFlowwizard?.currentStep?.stepId === 'Orders' || !this.hideSaveRxOrderButton) {
              this.notifyText = data.body.msg || AppConstantsListConfig?.uiErrorException;
              this.isSuccessNotify = false;
              this.exceptionModal?.modalShow();
            }
            this.cardRefresh.cardRefreshHide();
            return;
          }
          // created dynamic iframe SRC attribute and assigned lookup url
          const iframeEle = document?.getElementById(`${iframType}-iframe-plan`) as HTMLElement;
          iframeEle?.setAttribute('src', this.patientRxOrderLookupURL);
          // just for safe side to hide the loader if there is an issue in below load event listener
          // setTimeout(() => {
          //   this.cardRefresh.cardRefreshHide();
          // }, 3000);
          iframeEle?.addEventListener("load", ev => {
            this.cardRefresh.cardRefreshHide();
          });
        }
      },
        error => {
          this.patientRxOrderLookupURL = '';
          this.cardRefresh.cardRefreshHide();
          if(this.careFlowwizard?.currentStep?.stepId === 'Orders' || !this.hideSaveRxOrderButton) {
            this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
            this.isSuccessNotify = false;
            this.exceptionModal?.modalShow();
          }
        });
  }

  // call rx order after successfull orderd from change health
  public callAdHocRxOrderChangeHealth(rxLabOrderNotify?: UiModalComponent, isModalShown?: boolean): void {
    const action = `changeHealth/rxOrdersByUser?userInfoId=${this.patientDetailsInfo?.userInfoId || this.patientDetailsInfo?.id}`;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        if (this.rxLabOrderForm?.get('orderSelection')?.value === 'Rx' && !isModalShown) {
          this.notifyText = data?.body?.message;
          this.isSuccessNotify = true;
          rxLabOrderNotify?.show();
        }
      } else if(!isModalShown && this.rxLabOrderForm?.get('orderSelection')?.value === 'Rx'){
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.cardRefresh?.cardRefreshHide();
    },
      (error) => {
        if(!isModalShown && this.rxLabOrderForm?.get('orderSelection')?.value === 'Rx') {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.cardRefresh?.cardRefreshHide();
      });
  }

  // call for adhocc lab order
  public makeAdhocLabOrdersChangeHealth(rxLabOrderNotify?: UiModalComponent, isModalShown?: boolean): void {
    const action = `changeHealth/orders`;
    const params = this.httpService?.getChangeHealthOrders(this.patientDetailsInfo);

    this.cardRefresh?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        if (rxLabOrderNotify && this.rxLabOrderForm?.get('orderSelection')?.value === 'Order' && !this.hideSaveRxOrderButton && !isModalShown) {
          this.notifyText = data?.body?.message;
          this.isSuccessNotify = true;
          rxLabOrderNotify?.show();
        }
      } else if(!isModalShown && this.rxLabOrderForm?.get('orderSelection')?.value === 'Order'){
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.cardRefresh?.cardRefreshHide();
      this.getLabRadOrderDetails();
    },
      error => {
        if(!isModalShown && this.rxLabOrderForm?.get('orderSelection')?.value === 'Order') {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.cardRefresh?.cardRefreshHide();
        this.getLabRadOrderDetails();
      });
  }

  // get LAB / RAD Order details
  public getLabRadOrderDetails(): void {
    // const action = `changeHealth/labOrder/${this.appointmentId}`;
    const action = `changeHealth/getLabRadbyAppointment?appointmentId=${this.patientDetailsInfo?.id}`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const labOrder = data?.responseObject || [];
        this.alllabRadOrderDetails = labOrder?.filter((item: any) => item?.location !== 'Internal' && item?.orderType !== 'LAB') || [];
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
      }
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
      });
  }

  // call Rx orders or lab orders
  public saveRxLabOrders(rxLabOrderNotify: UiModalComponent): void {
    this.callAdHocRxOrderChangeHealth(rxLabOrderNotify);
    this.makeAdhocLabOrdersChangeHealth(rxLabOrderNotify);
    // forkJoin([this.callAdHocRxOrderChangeHealth(), this.makeAdhocLabOrdersChangeHealth()])
    // .subscribe(data =>{
    // });
  }

  public redirectLocation(rxLabOrderNotify: UiModalComponent): void {
    rxLabOrderNotify?.hide();
    const navigateBack = sessionStorage?.getItem('addhocRxLabOrders');

    if (this.orderType === 'Rx') {
      this.router?.navigate(['hospital/rx-orders']);
      return;
    }

    if (navigateBack?.includes('?order=')) {
      this.router?.navigate(['hospital/lab-orders'], {
        queryParams: {
          order: navigateBack?.includes('?order=1') ? 1 : navigateBack?.includes('?order=2') ? 2 : navigateBack?.includes('?order=3') ? 3 : 1
        }
      });
    } else {
      this.router?.navigate([navigateBack === 'hospital/user-data-update' ? `hospital/user-data-update/${this.patientDetailsInfo?.id}` : navigateBack]);
    }
  }

  // Open Lab Requisition from Change HEalth
  public showLabRequisition(orderNumber: any): void {
    const action = `changeHealth/getLabRequisition?orderNumber=${orderNumber}`;
    this.cardRefresh?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        window.open(data?.responseObject, 'order-requisition');
      }
      this.cardRefresh?.cardRefreshHide();
    },
      (error) => {
        this.cardRefresh?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal?.modalShow();
      })
  }

  // to show the order status with description
  public statusCodeDesc(charStatus: string): string {
    return this.httpService?.statusCodeDesc(charStatus);
  }

  // to hide spinner after iframe loads
  onLoadHandler() {
    this.cardRefresh.cardRefreshHide();
  }

  // send Notifications
  public openNotifications(patient: any): void {
    this.lateNotifyPatient = patient || this.patientDetailsInfo;
    this.notifyComp?.showNotifyModal(this.patientDetailsInfo);
  }
  printLabelView() {

    const val = document.getElementById('printableArea').innerHTML;
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(val);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
  public printLabel(labOrder: any, printLabelModel: UiModalComponent) {
    this.nameOnLabel = labOrder?.patientName || '';
    this.orderAccountNum = labOrder?.clientId || '';
    this.ordeNum = labOrder?.orderNumber || '';
    this.value = this.ordeNum + this.orderAccountNum;
    printLabelModel.show();
  }

  // to show the Lab/Rad details
  
  // Open Results Popup if have results
  public getReportsAvailable(labOrder: any): void {
    this.labRadResultsComp?.getReportsAvailable(labOrder);
  }
  // to get Internal Lab Reults 
  public getOrderDetails(labOrder: any): void {
    this.labRadOrderDetailsComp?.openOrderDetailsPopup(labOrder?.labName?.includes('Radiology') || labOrder?.labName?.includes('Rad') ? 'RAD' : 'LAB', null, labOrder);
  }

  // Show the HL7 details
  public showHl7Details(labOrder: any): void {
    this.hl7DetailsComp?.openOrderDetailsPopup(labOrder?.labName?.includes('Radiology') || labOrder?.labName?.includes('Rad') ? 'RAD' : 'LAB', null, labOrder);
  }

  // Open Lab Requisition from Change HEalth
  public downloadLabRequisition(orderNumber: any): void {
    const action = `changeHealth/getLabRequisition?orderNumber=${orderNumber}`;
    this.cardRefresh?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        window.open(data?.responseObject, 'order-requisition');
      }
      this.cardRefresh?.cardRefreshHide();
    },
      (error) => {
        this.cardRefresh?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal?.modalShow();
        this.isSuccessNotify = false;

      })
  }
  
  public openUniversalActions(event: any, row) {
     if(this.selectedActionRow === row){
	 this.openActions = !this.openActions;
	 }
	 else{
	 this.openActions = true;
	 }
     
	 this.selectedActionRow = row;
	 
  }
   public closeUniversalActions() {
   
     this.openActions = false;
  }
  
  public mouseIn() {
  this.enableActions = true;
  
  }
  
   public mouseOut() {
	this.enableActions = false; 
	
  }

  ngOnDestroy(): void {
    // if(window?.location?.href?.includes('hospital/new-rx-lab-orders')) {
      this.callAdHocRxOrderChangeHealth(this.rxLabOrderNotify, true);
      this.makeAdhocLabOrdersChangeHealth(this.rxLabOrderNotify, true);
    // }
  }

}
