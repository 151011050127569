import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-update-visit-doctor',
  templateUrl: './update-visit-doctor.component.html',
  styleUrls: ['./update-visit-doctor.component.scss']
})
export class UpdateVisitDoctorComponent implements OnInit {

  public updateVisitDoctorForm: FormGroup;
  public loadSpinner: boolean;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;

  @Output() reloadAppointmentQueue = new EventEmitter();
  @ViewChild('updateVisitDoctorModal') private updateVisitDoctorModal: UiModalComponent;
  public appointmentDetails: any;
  public allVisitingDoctors: any = [];
  private selectedDoctorToUpdate: any;
  public eventTrigger: boolean = false;
  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
    this.updateVisitDoctorForm = this.formBuilder?.group({
      'doctorNpi': [null, Validators?.required]
    })
  }

  ngOnInit(): void {
  }

  public openModal(appointment: any): void {
    this.appointmentDetails = appointment;
	console.log(this.appointmentDetails);
	const doctorNpi = this.appointmentDetails?.appointment?.doctorNpi;
	this.updateVisitDoctorForm?.get('doctorNpi')?.setValue(doctorNpi);
    this.updateVisitDoctorModal?.show();
    this.getAllDoctorsList();
  }

  // get doctors list based on purpose of visit
  public getAllDoctorsList(): void {
    this.allVisitingDoctors = [];
    const action = `npi/getValidNPIForFacility?facilityId=${this.appointmentDetails?.facilityId}`;
    this.allVisitingDoctors = [];
    JSON?.parse(sessionStorage?.getItem('waitTimeDetails'))?.eligibilityDetails?.doctor?.forEach((doctorItem: any) => {
      if (doctorItem?.number && doctorItem?.basic) {
        const obj = {
          firstName: doctorItem?.basic?.authorized_official_first_name || '',
          lastName: doctorItem?.basic?.authorized_official_last_name || '',
          npi: doctorItem?.number,
          fullName: `${doctorItem?.basic?.authorized_official_last_name || ''}, ${doctorItem?.basic?.authorized_official_first_name || ''} `
        }
        this.allVisitingDoctors?.push(obj);
      }
    });
    // this.loadSpinner = true;
    // this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
    //   this.allVisitingDoctors = (data && data?.npiDoctorList) || [];
    //   this.loadSpinner = false;
    // }, error => {
    //   this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
    //   this.exceptionModal.modalShow();
    //   this.loadSpinner = false;
    // });
  }

  public updateDoctor(event): void {
    this.selectedDoctorToUpdate = event;
    this.eventTrigger= true;
    console.log("Event*********",event);
  }

  // submit the visit doctor update
  public updateVisitDoctor(): void {
    console.log("this.selectedDoctorToUpdate********",this.selectedDoctorToUpdate);
    if(!this.selectedDoctorToUpdate && this.eventTrigger == false){
      return;
    }
    const action = `appointment/registration/updateDoctorforAppt?appointmentId=${this.appointmentDetails?.id}&doctorNpi=${this.updateVisitDoctorForm?.value?.doctorNpi}&facilityId=${this.appointmentDetails?.facilityId}&firstName=${this.selectedDoctorToUpdate?.firstName}&lastName=${this.selectedDoctorToUpdate?.lastName}`;
    if (this.updateVisitDoctorForm?.invalid) {
      this.updateVisitDoctorForm?.markAllAsTouched();
      return;
    }

    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        this.notifyText = "Service Provider has been updated successfully.";
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.updateVisitDoctorModal?.hide();
        this.updateVisitDoctorForm?.reset();
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
      this.reloadAppointmentQueue?.emit(true);
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        this.reloadAppointmentQueue?.emit(true);
      });
  }

}
