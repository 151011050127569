import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../../../environments/environment';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';

@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.scss']
})
export class CancelAppointmentComponent implements OnInit {

  public cancelForm: FormGroup;
  public notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild('cancelModal') cancelModal: UiModalComponent;
  public minAppointmentDate: Date;
  public loadCard: boolean;
  @Output() onCancelAppntChange = new EventEmitter<boolean>();
  // @Input() exceptionModal: ExceptionModalComponent;
  cancelRequestSubmit: boolean;
  cancelAppointmentId: any;
  public isDeleteAppointment: boolean;

  constructor(private _fb: FormBuilder, private httpService: HttpService) {
    this.cancelForm = this._fb.group({
      'cancellationReason': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.aplhaNumericComments)])],
    })
  }

  ngOnInit(): void {
  }

  public openCancelAppointment(appointment: any, isDeleteAppt?: boolean): void {
    this.cancelAppointmentId = appointment;
    this.isDeleteAppointment = isDeleteAppt || null;
    this.cancelModal.show();
  }

  // Cancel Appointment API call
  public canceltheRequest(cancelModal: any, notifyModal: any): void {
    if (this.cancelForm?.controls['cancellationReason']?.invalid) {
      this.cancelForm?.markAllAsTouched();
      return;
    };
    if (this.isDeleteAppointment) {
      this.statusUpdate(cancelModal, notifyModal);
      return;
    }
    let action = `appointment/registration/cancel?id=${this.cancelAppointmentId?.webToken}&reason=${this.cancelForm?.controls['cancellationReason']?.value}`;
    // let params: any = { ${environment?.userWebUrl}/${this.cancelAppointmentId?.facilityId}/bookappt
    //   "id": this.cancelAppointmentId.webToken,
    //   "reason": this.cancelForm.controls['cancellationReason'].value
    // }
    this.cancelRequestSubmit = true;
    this.httpService.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data?.body?.status == 'SUCCESS') {
          cancelModal.hide();
          this.notifyText = 'Appointment has been Cancelled successfully.'
          this.isSuccessNotify = true;
          notifyModal?.show();
          this.onCancelAppntChange?.emit(true);
          this.cancelForm?.reset();
        } else {
          this.notifyText = data?.body?.msg || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          notifyModal?.show();
        }
        this.cancelRequestSubmit = false;
      },
        error => {
          // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          // notifyModal.show();
          this.notifyText = error?.message || error?.msg || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          notifyModal?.show();
          this.cancelRequestSubmit = false;
        });
  }

  public statusUpdate(cancelModal: UiModalComponent, notifyModal: UiModalComponent): void {
    let action: any = 'appointment/registration/updateStatus';
    let params: any = {
      "apiName": "",
      "appointmentId": this.cancelAppointmentId?.id,
      "buttonClick": 'cancel',
      "cancellationReason": `Appointment Deleted - ${this.cancelForm?.controls['cancellationReason']?.value}`,
      "email": "",
      "facilityID": this.cancelAppointmentId?.facilityId
    }
    this.cancelRequestSubmit = true;
    this.httpService?.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data?.body?.status == 'SUCCESS') {
          cancelModal.hide();
          this.notifyText = 'Appointment has been Deleted successfully.'
          this.isSuccessNotify = true;
          notifyModal?.show();
          this.onCancelAppntChange?.emit(true);
          this.cancelForm?.reset();
        } else {
          this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          notifyModal?.show();
        }
        this.cancelRequestSubmit = false;
      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          notifyModal?.show();
          this.cancelRequestSubmit = false;
        });
  }

}
