import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { AppConstantsListConfig } from '../../litterals/app.constants';
@Component({
  selector: 'app-hosp-demographics',
  templateUrl: './hosp-demographics.component.html',
  styleUrls: ['./hosp-demographics.component.scss']
})
export class HospDemographicsComponent implements OnInit {

  @Input() demographicForm: FormGroup;
  @Input() patientType: string;
  @ViewChild("search")
  public searchElementRef: ElementRef;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  latitude: any;
  longitude: any;
  currentLocation: string;
  public maxAppointmentDate: Date;
  public minDOB = this.httpService.convertDate(new Date('01 Jan 1910'));
  public currentYear = new Date().getFullYear();
  public currentMonth = new Date().getMonth();
  public currentDate = new Date().getDate();
  public isEmergencyNameRequired: boolean = false;
  public isEmergencyRelationRequired: boolean = false;
  public isEmergencyContactRequired: boolean = false;

  isdesabled: boolean = false;
  public userInfoId: any;
  public loginUser: any;
  public validationRequired: boolean = true;
  public maritaMandatory:boolean = true;


  public genderList = AppConstantsListConfig?.genderList;
  public insuranceRelationship = AppConstantsListConfig?.insuranceRelationship;
  public relationshipList = AppConstantsListConfig?.relationshipList;
  public maritalStatusList = AppConstantsListConfig?.maritalStatusList;
  public raceList = AppConstantsListConfig?.raceList;
  public ethnicityList = AppConstantsListConfig?.ethnicityList;

  public phoneTypeList = AppConstantsListConfig?.phoneTypeList;
  @Input() insuranceForm: FormGroup;
  public languageList = AppConstantsListConfig?.languageList;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private httpService: HttpService,) {
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
    this.loginUser = JSON.parse(sessionStorage?.getItem('userDetails'));
    this.validationRequired = true;
    if (this.loginUser?.facilityId == 86) {
      this.validationRequired = false
    }
    let providerStatus = JSON.parse(sessionStorage.getItem('providerConfiguration'));
    if(providerStatus?.maritalStatus){
      this.maritaMandatory = true;
    }else{
      this.maritaMandatory = false;
    }

  }

  ngOnInit(): void {
  console.log("demographicForm",this.demographicForm);
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.setCurrentPosition();
        });
      });
    });
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
    const dob = this.demographicForm.get('DATE_OF_BIRTH').value || null;
    console.log('dob',dob);
    this.demographicForm.get('DATE_OF_BIRTH').setValue(dob);
	
	
    this.demographicsValueChanges();
	
  }
  ngAfterViewInit(): void {
    const preferredLanguage = this.demographicForm.get('preferredLanguage').value;
	if(preferredLanguage === null){
	  this.demographicForm.get('preferredLanguage').setValue('English');
	}
	else{
	  this.demographicForm.get('preferredLanguage').setValue(preferredLanguage);
	}
	console.log("demographicFormUpdated",this.demographicForm);
  }

  private setCurrentPosition(): void {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng = {
        lat: this.latitude,
        lng: this.longitude
      };
      geocoder.geocode({
        'location': latlng
      }, (results) => {
        if (results[0]) {
          this.currentLocation = results[0].formatted_address;
          this.setZipCode(results);
        } else {
          console.log('Not found');
        }
      });
    });
  }


  private setZipCode(results): void {
    this.demographicForm.get('ADDRESS').setValue(null);
    let street = '';
    for (let i = 0; i < results.length; i++) {
      for (var j = 0; j < results[i].address_components.length; j++) {
        for (var k = 0; k < results[i].address_components[j].types.length; k++) {

          if (results[i].address_components[j].types[k] == "street_number") {
            let street_num = results[i].address_components[j].long_name;
            street += `${street_num} `;
          }
          if (this.demographicForm.get('ADDRESS').value === null && results[i].address_components[j].types[k] == "route") {
            let route = results[i].address_components[j].long_name;
            street += `${route}`;
            if (this.demographicForm.get('ADDRESS').value === null) {
              this.demographicForm.get('ADDRESS').setValue(street);
            }
          }

          if (results[i].address_components[j].types[k] == "postal_code") {
            const zipCode = results[i].address_components[j].short_name;
            const zipcodeUpdate = this.addPrefixZipCode(zipCode, '');
            this.demographicForm.get('ZIPCODE').setValue(zipcodeUpdate || zipCode);
          }

          if (results[i].address_components[j].types[k] == "country") {
            const country = results[i].address_components[j].long_name;
            this.demographicForm.get('COUNTRY').setValue(country);
          }

          if (results[i].address_components[j].types[k] == "administrative_area_level_1") {
            const state = results[i].address_components[j].short_name;
            this.demographicForm.get('STATE').setValue(state);
          }

          if (results[i].address_components[j].types[k] == "locality") {
            const city = results[i].address_components[j].long_name;
            this.demographicForm.get('CITY').setValue(city);
          }
        }
      }
    }
  }

  public dobChange(e: any): void {
    const age = this.httpService.calculateAge(e.target.value);
    this.demographicForm.get('AGE').setValue(age);
  }

  //This method is to validate manually entered date value
  public validateManualEnterdob(e: any): void {
    if (e.target.value != '' && e.target.value != null) {
      if (this.httpService.validateManualEnterdob(e.target.value, this.currentYear, this.currentMonth + 1, this.currentDate)) {
        // this.demographicForm.get('DATE_OF_BIRTH').setValue(null);
        const passwordControl = this.demographicForm.get('DATE_OF_BIRTH');
        passwordControl.setErrors({ invalidDate: true });
      } else {
        const insuFormArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
        if (insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscriberdateOfBirth')?.setValue(this.demographicForm.get('DATE_OF_BIRTH')?.value || null);
            }
          });
        }
      }
    }
  }

  // check if state and city as selected from google pes addresses
  public checkStateCityValidation(): void {
    if (this.demographicForm.controls["ADDRESS"].valid && this.demographicForm.controls["STATE"].invalid && this.demographicForm.controls["CITY"].invalid) {
      this.notifyText = 'Please select the valid Address from google suggested places..!';
      this.isSuccessNotify = false;
      this.exceptionModal.modalShow();
    }
  }

  // to change alternate phone type
  public changeAlternatePhoneType(): void {
    // this.demographicForm?.get('ALTERNATE_PHONE_NUMBER')?.setValue(null);
    if (this.demographicForm?.get('alternativePhoneType')?.value === true || this.demographicForm?.get('alternativePhoneType')?.value === false) {
      this.demographicForm?.get('ALTERNATE_PHONE_NUMBER')?.setValidators([Validators.required,Validators.pattern(AppRegExpressionsConfig.mobile)]);
    } else {
      this.demographicForm?.get('ALTERNATE_PHONE_NUMBER')?.clearValidators();
      this.demographicForm?.get('ALTERNATE_PHONE_NUMBER')?.setValue(null);
    }
    this.demographicForm?.get('ALTERNATE_PHONE_NUMBER')?.updateValueAndValidity();
  }

  get checkAlternatePhoneNUmber(): boolean {
    let value: boolean;
    if (this.demographicForm?.get('ALTERNATE_PHONE_NUMBER')?.value) {
      this.demographicForm?.get('alternativePhoneType')?.setValidators([Validators.required]);
      value = true;
    } else {
      this.demographicForm?.get('alternativePhoneType')?.clearValidators();
      // this.demographicForm?.get('alternativePhoneType')?.setValue(null);
      value = false
    }
    this.demographicForm?.get('alternativePhoneType')?.updateValueAndValidity();
    return value;
  }


  // Add PRefix of Zero before the zipcode if below 4 digits
  public addPrefixZipCode(addressZipCode: any, valid: any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
    if (valid) {
      this.demographicForm?.get('ZIPCODE')?.setValue(prefixZipcode || addressZipCode || '');
    }

    return prefixZipcode || addressZipCode || '';
  }

  // Update Insurance details if have if First Last Names, DOB and Gnder values
  // demographics first name and last name
  private demographicsValueChanges(): void {
    this.demographicForm?.get("FIRST_NAME")?.valueChanges?.subscribe(selectedValue => {
      const isSelfPay = this.insuranceForm?.get('selfPay')?.value;
      if (isSelfPay === 'Insurance' && selectedValue) {
        const insuFormArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
        if (insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscriberfirstname')?.setValue(selectedValue || null);
            }
          });
        }
      }
	  
		
    });

    this.demographicForm?.get("LAST_NAME")?.valueChanges?.subscribe(selectedValue => {
      const isSelfPay = this.insuranceForm?.get('selfPay')?.value;
      if (isSelfPay === 'Insurance' && selectedValue) {
        const insuFormArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
        if (insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscriberlastname')?.setValue(selectedValue || null);
            }
          });
        }
      }
    });

    this.demographicForm?.get("DATE_OF_BIRTH")?.valueChanges?.subscribe(selectedValue => {
      const isSelfPay = this.insuranceForm?.get('selfPay')?.value;
      if (isSelfPay === 'Insurance' && selectedValue) {
        const insuFormArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
        if (insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscriberdateOfBirth')?.setValue(selectedValue || null);
            }
          });
        }
      }
    });

    this.demographicForm?.get("GENDER")?.valueChanges?.subscribe(selectedValue => {
      const isSelfPay = this.insuranceForm?.get('selfPay')?.value;
      if (isSelfPay === 'Insurance' && selectedValue) {
        const insuFormArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
        if (insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscribergender')?.setValue(selectedValue || null);
            }
          });
        }
      }
    });
	
  }

}
