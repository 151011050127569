import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-careplan',
  templateUrl: './review-careplan.component.html',
  styleUrls: ['./review-careplan.component.scss']
})
export class ReviewCareplanComponent {

  @Input() planData: any;

  // Please remove the table only if have deleted items
  public isOnlyDeleted(cptItems?: any[]): boolean {
    const allCptCodeDup = JSON?.parse(JSON?.stringify(cptItems || []));
    const filterDeletedItems: any[] = allCptCodeDup?.filter((fItem: any) => fItem?.codeDescription !== 'DELETED') || [];
    return filterDeletedItems?.length > 0 ? true : false;
  }
  
}
