import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { Console } from 'console';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExceptionModalComponent } from './theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from './theme/shared/components/modal/ui-modal/ui-modal.component';
import { DataService } from './theme/shared/services/data-sharing.service';
import { UserIdleService } from 'angular-user-idle';
import { SwUpdate } from '@angular/service-worker';
import { HttpService } from './theme/shared/services/http.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private userActivity;
  private userInactive: Subject<any> = new Subject();
  @ViewChild('sessionExpiredInActivity') inActivityExpire: UiModalComponent;
  
  // @HostListener('window:keydown')
  // @HostListener('window:mousedown') refreshUserState() {
  //   // clearTimeout(this.userActivity);
  //   // this.setTimeout();
  //   this.userIdle?.resetTimer();
  //   this.userIdle?.startWatching();
  // }

  constructor(private router: Router, private dataService: DataService, private userIdle: UserIdleService, private swUpdate: SwUpdate, private httpService: HttpService) {
    this.dataService?.currentMessage?.subscribe((data) => {
      if(data?.isLogin === true){
        //Start watching for user inactivity.
        this.userIdle?.startWatching();

        this.userIdle?.onTimerStart()?.subscribe(count => {
          if(count){
            this.showInActivity()
          }
        });
        // Start watch when time is up.
        // this.userIdle.onTimeout().subscribe(() => this.showInActivity());
        // this.setTimeout();
        // this.userInactive.subscribe(() => this.showInActivity());
      }
    })
  }

  private restartIdle() {
    this.userIdle?.resetTimer();
  }

  // get isLogin(): boolean {
  //   return sessionStorage.getItem('isLogin') === 'true' || false;
  // }

  setTimeout() {
    const timer = 30 * 60 * 1000;
    this.userActivity = setTimeout(() => this.userInactive?.next(undefined), timer);
  }

  private showInActivity(): void{
    this.unlockModuleAccess();
    this.inActivityExpire?.show();
    sessionStorage?.clear();
    localStorage?.clear();
    this.userIdle?.stopTimer();
    this.userIdle?.stopWatching();
    // this.userInactive?.unsubscribe();
  }

  public unlockModuleAccess() {
    const getDetails = JSON?.parse(sessionStorage?.getItem('setAppointmentDetails'));
    if(!getDetails) {
      return
    };
    const previousLockModule = sessionStorage?.getItem('previousUnlockModuleName') || null;
    const action = `careFlow/unlockCareFlowModuleUserAccess?appointmentId=${getDetails?.id}&moduleName=&userInfoId=${getDetails?.userInfoId}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {});
  }

  public relogin(): void{
    sessionStorage?.clear();
    this.router.navigate(['auth/signin']);
    this.inActivityExpire?.hide();
    this.userInactive?.unsubscribe();
    window?.location?.reload();
  }

  ngOnInit() {
    // this.router.navigate(['/auth/signin']);
    window.name = environment?.windowName;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.swUpdate?.checkForUpdate();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        // if (confirm("New version available. Load New Version? sfas ff asf")) {
        //   window.location.reload();
        // }
      });
    }       

  }
}
