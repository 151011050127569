import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, FormArray } from '@angular/forms';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { HealthpassPatientSearchComponent } from '../healthpass-patient-search/healthpass-patient-search.component';

@Component({
  selector: 'app-family-members-details',
  templateUrl: './family-members-details.component.html',
  styleUrls: ['./family-members-details.component.scss']
})
export class FamilyMembersDetailsComponent implements OnInit, OnChanges {

  @Input() familyDetailsForm: FormGroup;
  @Input() appointmentDetails: any;
  @Input() exceptionModal: ExceptionModalComponent;
  public maxDate: Date;
  public addPatientTypeOfPatient = [
    { value: 'Old', desc: 'Established Patient' },
    { value: 'New', desc: 'New Patient' },
  ];


  public genderList = [
    { value: 'Male', desc: 'Male' },
    { value: 'Female', desc: 'Female' },
    { value: "Trans Female", desc: "Trans Female" },
    { value: "Trans Male", desc: "Trans Male" },
    { value: "Non-binary", desc: "Non-binary" },
    { value: 'Other', desc: 'Other' },
    { value: "Unknown", desc: "Unknown" }
  ];
  public insuranceRelationship = [
    /*{ value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' }*/
    { value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' },
    { value: 'Siblings', desc: 'Siblings' },
    { value: 'Friend', desc: 'Friend' },
    { value: 'Guardian', desc: 'Guardian' },
    { value: 'Son', desc: 'Son' },
    { value: 'Daughter', desc: 'Daughter' },
    { value: 'Partner', desc: 'Partner' },
    { value: 'Child', desc: 'Child' }
  ];
  public relationshipList = [
    { value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' },
    { value: 'Siblings', desc: 'Siblings' },
    { value: 'Friend', desc: 'Friend' },
    { value: 'Guardian', desc: 'Guardian' },
    { value: 'Son', desc: 'Son' },
    { value: 'Daughter', desc: 'Daughter' },
    { value: 'Partner', desc: 'Partner' },
    { value: 'Child', desc: 'Child' }
  ];
  public maritalStatusList = [
    { value: 'Single', desc: 'Single' },
    { value: "Partnered", desc: "Partnered" },
    { value: 'Married', desc: 'Married' },
    { value: "Separated", desc: "Separated" },
    { value: "Divorced", desc: "Divorced" },
    { value: "Widowed", desc: "Widowed" },
    { value: 'Other', desc: 'Other' },
    { value: "Unknown", desc: "Unknown" }
  ];

  public raceList = [
    { value: 'American Indian or Alaska Native', desc: 'American Indian or Alaska Native' },
    { value: 'Black or African American', desc: 'Black or African American' },
    { value: 'White', desc: 'White' },
    { value: 'Asian', desc: 'Asian' },
    { value: 'Native Hawaiian or Other pacific Islander', desc: 'Native Hawaiian or Other pacific Islander' },
    { value: 'Other Race/Multiracial', desc: 'Other Race/Multiracial' },
    { value: 'Unknown', desc: 'Unknown' },
    { value: 'I don’t want to specify', desc: 'I don’t want to specify' }
  ];
  public ethnicityList = [
    { value: 'Hispanic', desc: 'Hispanic' },
    { value: 'Not Hispanic', desc: 'Not Hispanic' },
    { value: 'Unknown', desc: 'Unknown' },
    { value: 'I don’t want to specify', desc: 'I don’t want to specify' }
  ];


  contactDeleteIndex: number;
  @ViewChild(HealthpassPatientSearchComponent) public healthPatientSearchComp: HealthpassPatientSearchComponent;

  public selectedIndex: any;
  public minDOB = this.httpService.convertDate(new Date('01 Jan 1910'));
  public currentYear = new Date().getFullYear();
  public currentMonth = new Date().getMonth();
  public currentDate = new Date().getDate();
  public maritaMandatory:boolean = false;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService) { }

  ngOnInit(): void {
    this.maxDate = this.httpService.convertDate(new Date());
    const formdetails = JSON.parse(sessionStorage.getItem('healthpassDemographics'));
    if (formdetails) {
      const familyDetailsArray = this.familyDetailsForm?.get('familyMemberdetails') as FormArray;
      formdetails.familyMemberdetails?.forEach((item: any, index: any) => {
        if (item?.phone && item?.phone?.includes('+1')) {
          item.phone = item?.phone?.replaceAll('+1', '');
        }
        familyDetailsArray?.push(this.familyFormGroup(item));

        /*  const name = item?.middleName
            ? `${item?.firstName} ${item?.middleName} ${item?.lastName}`
            : `${item?.firstName} ${item?.lastName}`;
            this.addNewPatientForm
            ?.get('EXISTING_PATIENT_SEARCH_'+index)
            ?.setValue(name || null);*/
      });
    }
    //this.addMember();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {

      const familyDetailsArray = this.familyDetailsForm?.get('familyMemberdetails') as FormArray;
      this.httpService?.clearFormArray(familyDetailsArray);
      this.appointmentDetails?.familyMemberdetails?.forEach((item: any) => {
        if (item?.phone && item?.phone?.includes('+1')) {
          item.phone = item?.phone?.replaceAll('+1', '');
        }
        familyDetailsArray?.push(this.familyFormGroup(item));
      });
    }
  }

  public familyFormGroup(contactItem?: any): FormGroup {
    let searchName = null;

    if (contactItem?.firstName !== null && contactItem?.firstName !== undefined && contactItem?.firstName !== '' && contactItem?.userInfoId !== null) {
      searchName = contactItem?.middleName
        ? `${contactItem?.firstName} ${contactItem?.middleName} ${contactItem?.lastName}`
        : `${contactItem?.firstName} ${contactItem?.lastName}`;


    }
    else {
      searchName = null;
    }

    return this.formBuilder.group({
      'firstName': [contactItem?.firstName || null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
      'lastName': [contactItem?.lastName || null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
      'middleName': [contactItem?.middleName || null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
      'dateOfBirth': [contactItem?.dateOfBirth || null, Validators.required],
      'gender': [contactItem?.gender || null, Validators.required],
      // 'AGE': [null, Validators.required],
      'maritalStatus': [contactItem?.maritalStatus || null],
      'race': [contactItem?.race || null, Validators.required],
      'ethnicity': [contactItem?.ethnicity || null, Validators.required],
      'userInfoId': [contactItem?.userInfoId || null],
      'EXISTING_PATIENT_SEARCH': [searchName]
    });
  }

  /* public familyFormGroupValues(contactItem?: any): FormGroup {
     return this.formBuilder.group({
       'firstName': [contactItem?.firstName || null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
       'lastName': [contactItem?.lastName || null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
       'middleName': [contactItem?.middleName || null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
       'dateOfBirth': [contactItem?.dateOfBirth || null, Validators.required],
       'gender': [contactItem?.gender || '', Validators.required],
       // 'AGE': [null, Validators.required],
       'maritalStatus': [contactItem?.maritalStatus || '', Validators.required],
       'race': [contactItem?.race || '', Validators.required],
       'ethnicity': [contactItem?.ethnicity || '', Validators.required],
     'EXISTING_PATIENT_SEARCH': [null]
     });
   }*/

  public addNewPatientForm(contactItem?: any): FormGroup {
    return this.formBuilder.group({
      'PATIENT_TYPE': [null]
    });
  }

  public openDeleteContactConfirm(i: number, confirmContactCardDelete: UiModalComponent): void {
    this.contactDeleteIndex = i;
    confirmContactCardDelete?.show();
  }

  public addMember(): void {
    const familyMemberdetailsArray = this.familyDetailsForm?.get('familyMemberdetails') as FormArray;
    familyMemberdetailsArray?.push(this.familyFormGroup());
    if(familyMemberdetailsArray){
    familyMemberdetailsArray.controls.forEach((item,i)=>{
          item[i].get('maritalStatus').clearValidators();
    })
   }

  }

  public deleteContactDetails(removeIndex: number): void {
    const familyMemberdetailsArray = this.familyDetailsForm?.get('familyMemberdetails') as FormArray;
    familyMemberdetailsArray?.removeAt(removeIndex);
    this.familyDetailsForm?.markAsDirty();
  }

  public onfamilyMemberdetailsChange(contactForm: FormGroup): void {
    if (
      (contactForm?.get('firstName').value != null && contactForm?.get('firstName').value != '') ||
      (contactForm?.get('lastName').value != null && contactForm?.get('lastName').value != '') ||
      (contactForm?.get('middleName').value != null && contactForm?.get('middleName').value != '') ||
      (contactForm?.get('dateOfBirth').value != null && contactForm?.get('dateOfBirth').value != '') ||
      (contactForm?.get('gender').value != null && contactForm?.get('gender').value != '') ||
      (contactForm?.get('maritalStatus').value != null && contactForm?.get('maritalStatus').value != '') ||
      (contactForm?.get('race').value != null && contactForm?.get('race').value != '') ||
      (contactForm?.get('ethnicity').value != null && contactForm?.get('ethnicity').value != '')) {
      contactForm?.get("firstName").setValidators(Validators.required);
      contactForm?.get("lastName").setValidators(Validators.required);
      contactForm?.get("middleName").setValidators(null);
      contactForm?.get("dateOfBirth").setValidators(Validators.required);
      contactForm?.get("gender").setValidators(Validators.required);
      // contactForm?.get("maritalStatus").setValidators(Validators.required);
      contactForm?.get("race").setValidators(Validators.required);
      contactForm?.get("ethnicity").setValidators(Validators.required);
    }
    else {
      contactForm?.get("firstName").clearValidators();
      contactForm?.get("lastName").clearValidators();
      contactForm?.get("middleName").clearValidators();
      contactForm?.get("dateOfBirth").clearValidators();
      contactForm?.get("gender").clearValidators();
      contactForm?.get("maritalStatus").clearValidators();
      contactForm?.get("race").clearValidators();
      contactForm?.get("ethnicity").clearValidators();
    }
    contactForm?.get("firstName").updateValueAndValidity();
    contactForm?.get("lastName").updateValueAndValidity();
    contactForm?.get("middleName").updateValueAndValidity();
    contactForm?.get("dateOfBirth").updateValueAndValidity();
    contactForm?.get("gender").updateValueAndValidity();
    // contactForm?.get("maritalStatus").updateValueAndValidity();
    contactForm?.get("race").updateValueAndValidity();
    contactForm?.get("ethnicity").updateValueAndValidity();
    this.martialstatusHide(contactForm);
  }

  public contactSearchModal(index): void {

    this.selectedIndex = index;

    this.healthPatientSearchComp?.openSearchModal();
    this.healthPatientSearchComp?.autoFocusSearch();

  }

  public clearFamilyDetails(removeIndex: number): void {

    // const familyDetailsArray = this.familyDetailsForm?.get('familyMemberdetails') as FormArray;
    //familyDetailsArray?.value[0]['EXISTING_PATIENT_SEARCH'] = '';

    let familyArr = <FormArray>this.familyDetailsForm?.controls['familyMemberdetails'];

    familyArr.controls.forEach((element, index) => {
      if (removeIndex === index) {

        element?.get('userInfoId')?.setValue(null);
        element?.get('firstName')?.setValue(null);
        element?.get('lastName')?.setValue(null);
        element?.get('middleName')?.setValue(null);
        element?.get('dateOfBirth')?.setValue(null);
        element?.get('maritalStatus')?.setValue(null);
        element?.get('gender')?.setValue(null);
        element?.get('ethnicity')?.setValue(null);
        element?.get('race')?.setValue(null);
        element?.get('EXISTING_PATIENT_SEARCH')?.setValue(null);
      }

    });

  }
  public validateManualEnterdob(e: any,contactForm: FormGroup): void {
    if (e.target.value != '' && e.target.value != null) {
      if (this.httpService?.validateManualEnterdob(e.target.value, this.currentYear, this.currentMonth + 1, this.currentDate)) {
        // this.demographicsForm?.get('dob')?.setValue(null);
        // this.demographicsForm?.get('age')?.setValue(0);
       // contactForm.get('dateOfBirth').setValue(null);
       const passwordControl = contactForm.get('dateOfBirth');
       passwordControl.setErrors({ invalidDate: true });
      }
    }
  }

  public martialstatusHide(form){
    let providerStatus = JSON.parse(sessionStorage.getItem('providerConfiguration'));
    if(providerStatus?.maritalStatus){
      this.maritaMandatory = true;
      form?.get("maritalStatus").updateValueAndValidity();
    }else{
      this.maritaMandatory = false;
      form?.get('maritalStatus').clearValidators();
    }
  }

}
