import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CommonRestService } from './common-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  public baseUrl: string = environment.reportsBaseUrl;
  private accessWithOptions: any;
  public userDetails = JSON.parse(sessionStorage?.getItem('userDetails'));
  public allUsersListForFacility: any[] = [];

  constructor(
    public http: HttpClient, private commonRestServices: CommonRestService
    // private apiHeader: ApiHeader
    //test
  ) {
    this.accessWithOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-code': sessionStorage.getItem('token'),
      }),
    }
  }

  public makeRequestWithAction(action: string, params: any): Observable<any> {
    return this.commonRestServices?.makeRequestWithAction(this.baseUrl, action, params);
  }

  public makeGetRequest(action: string, request: any): Observable<any> {
    return this.commonRestServices?.makeGetRequest(this.baseUrl, action, request);
  }

  // Generate PDF
  public generatePDF(action: string, params: any): any {
    return this.commonRestServices?.generatePDF(this.baseUrl, action, params);
  }
}

