 <div class="row">

                <div class="col-lg-10 col-md-7 col-sm-7 patient-info-sec">
                    <h5 class="full-name" *ngIf="userInfoData"><b>{{userInfoData?.lastName | titlecase}}, {{userInfoData?.firstName  | titlecase}} 
                         {{userInfoData?.middleName | titlecase}}</b>
                        <span class="gender-age">{{userInfoData?.gender}}</span>
                        <span class="gender-age">{{ageCalculate(userInfoData?.usrDateOfBirth)}}</span>
                        <span class="gender-age">{{formatDob(userInfoData?.usrDateOfBirth)}}</span>
                        <span class="gender-age">&nbsp;&nbsp; | &nbsp;&nbsp;Patient
                            ID:&nbsp;&nbsp;<b>{{userPatientEmrId}}</b></span>
                        <span style="margin-left: 15PX;"><i class="las la-phone"></i>&nbsp;{{userInfoData?.primaryPhone}}
                        </span>
                    </h5>

                </div>
                <div class="col-lg-2 col-md-2"></div>
                <div class="col-lg-3 col-md-3"></div>

            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                   <h5  *ngIf="userInfoData"> Address: <span class="gender-age">
				  <span *ngIf="userInfoData.address.street!=='' && userInfoData.address.street!==null"> {{userInfoData?.address?.street}}, </span>
				   <span *ngIf="userInfoData.address.state!=='' && userInfoData.address.state!==null"> {{userInfoData?.address?.state}},  </span>
				  <span *ngIf="userInfoData.address.city!=='' && userInfoData.address.city!==null">  {{userInfoData?.address?.city}},  </span>
				  <span *ngIf="userInfoData.address.country!=='' && userInfoData.address.country!==null">  {{userInfoData?.address?.country}},  </span>
				  <span *ngIf="userInfoData.address.zipCode!=='' && userInfoData.address.zipCode!==null">  {{addPrefixZipCode(userInfoData?.address?.zipCode)}} </span>
                        </span></h5>

                </div>
            </div>