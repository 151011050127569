<div class="move-claims">
    <!-- View Appointment Details -->
    <app-ui-modal #claimsMoveToOtherQueues [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true"
        id="claimsMoveToOtherQueues">
        <div class="app-modal-header">
            <h5 class="modal-title">Move Claim</h5>
            <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                (click)="claimsMoveToOtherQueues.hide();moveClaimForm?.reset();resetIsArCall()"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="app-modal-body add-app-body">
            <div class="row" [formGroup]="moveClaimForm">

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">From<span class="required">*</span></label>
                        <ng-select placeholder="Choose Queue" id="move-claims_queuefrom" bindLabel="name" bindValue="apiStatus" formControlName="fromStatus"
                            [items]="allMoveQueues" [readonly]="true"></ng-select>
                        <label class="required"
                            *ngIf="moveClaimForm?.controls['fromStatus'].hasError('required') && moveClaimForm?.controls['fromStatus'].touched">Queue
                            Selection is required</label>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">To<span class="required">*</span></label>
                        <ng-select placeholder="Choose Queue" id="move-claims_queue" bindLabel="name" bindValue="apiStatus" formControlName="toStatus"
                            [items]="allMoveToQueues" (change)="checkIfArCall($event)"></ng-select>
                        <label class="required"
                            *ngIf="moveClaimForm?.controls['toStatus'].hasError('required') && moveClaimForm?.controls['toStatus'].touched">Queue
                            Selection is required</label>
                    </div>
                </div>

               
                <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Assignee <span *ngIf="isArCall" class="required">*</span></label>
                            <ng-select placeholder="Choose Asignee" id="claim_assignee" (change)="getAssigneeUserName($event)"
                            formControlName="userId" [items]="claimAssigneeList" bindLabel="userName" bindValue="userName"></ng-select>
                            
                            <label class="required"
                            *ngIf="moveClaimForm?.controls['userId'].hasError('required') && moveClaimForm?.controls['userId'].touched">Assignee is required</label>
                    
                        </div>
        
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Priority </label>
                            <ng-select placeholder="Choose Priority" id="assign_priority"
                                formControlName="priority" [items]="assigneePriorityList" bindLabel="desc" bindValue="desc"></ng-select>
                                
                           
                        </div>
        
                    </div>
                
              <!--  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Reason <span *ngIf="isArCall" class="required">*</span></label>
                        <ng-select placeholder="Add or Choose Reason" id="move-claims_reason" [addTag]="true" bindLabel="description" bindValue="description" formControlName="reason"
                            [items]="allClaimStatusCodes"></ng-select>
                            <label class="required"
                            *ngIf="moveClaimForm?.controls['reason'].hasError('required') && moveClaimForm?.controls['reason'].touched">Reason is required</label>
                    </div>
                </div>-->

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label class="form-label">Notes <span class="required">*</span></label>
                        <textarea rows="3" class="form-control" formControlName="notes" id="move-claims_notes"
                            placeholder="Notes"></textarea>
                        <label class="required"
                            *ngIf="moveClaimForm?.controls['notes'].hasError('required') && moveClaimForm?.controls['notes'].touched">Notes
                            are required</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-modal-footer">
            <button type="button" class="btn btn-primary dash-actions" id="move-claims_submit" data-dismiss="modal"
                (click)="claimMove(claimsMoveToOtherQueues);">Submit</button>
        </div>
    </app-ui-modal>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>