import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/theme/shared/services/data-sharing.service';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  public loginUserName: string;
  public loginUserType: string;
  public isNavProfile: boolean;
  public loginUserId: string;
  public roleType: any;
  public allActiveFacilities: any = [];
  public newFacilityData: any;
  public switchPortal: FormGroup;
  public showblankCheckbox:boolean = false;
  public enableCheckbox:boolean = true ;
  @ViewChild('SwitchPortalPopup') userSwitchPortal: UiModalComponent;
  loadSpinner: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  isSuccessNotify: boolean;
  notifyText: any;
  public envTermsPolicy: any = environment;

  constructor(private router: Router, private httpService: HttpService, private formBuilder: FormBuilder, public dataService: DataService) {
    this.isNavProfile = false;
    this.switchPortal = formBuilder.group({
      'facilityName': [null, Validators.required],
      //'roleTypeVal': [null, Validators.required],
      'defaultLogin': [true]
    });
  }

  ngOnInit() {
    const sessionUser = JSON.parse(sessionStorage.getItem('userDetails'));
    this.loginUserName = `${sessionUser.lastName}, ${sessionUser.firstName}`;
    this.loginUserType = `${sessionUser?.roleType}`;
    this.loginUserId = `${sessionUser?.userId}`;
  }

  public async logout() {
    const getDetails = JSON?.parse(sessionStorage?.getItem('setAppointmentDetails'));
    if (getDetails) {
      try {
        await this.unlockModuleAccess(getDetails);
        this.clearSessions();
      } catch {
        this.clearSessions();
      }
    } else {
      this.clearSessions();
    }
  }

  closeModal(){
    this.showblankCheckbox = false;
    this.enableCheckbox = true;
  }

  private clearSessions(): void {
    sessionStorage?.clear();
    localStorage?.clear();
    window?.location?.reload();
    this.router.navigate(['/auth/signin']);
  }

  public openUserManual(): void {
    window?.open('https://careeco.online/getting-started/', '_blank', 'UserManual')
  }
  
  // get all Active Facility Details
  public getAllActiveFacilities(): void {
    const action = `login/getProviderloginListing`;
    const params = {
      'userId': this.loginUserId
    };
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.allActiveFacilities = data?.body?.responseObject || [];
      }

    }, error => {

    });
  }
  public changeFacility(event: any): void {

    const roleArray = event?.roleType || [];
    this.roleType = [];
    if (roleArray.length > 0) {
      roleArray.forEach(element => {
        this.roleType.push({ name: element });
      });

    }

  }
  public async changPortal() {
    if (this.switchPortal?.invalid) {
      this.switchPortal?.markAllAsTouched();
      return;
    }
    const facilityId = this.switchPortal?.get('facilityName').value;
    //const roleType = this.switchPortal?.get('roleTypeVal').value;
    const roleType = this.roleType[0]?.name;
    let setPreferred = this.switchPortal?.get('defaultLogin').value;
    const getDetails = JSON?.parse(sessionStorage?.getItem('setAppointmentDetails'));
    if(this.showblankCheckbox){
       setPreferred = true;
       this.switchPortal.controls['defaultLogin'].setValue(true);
    }else{
      setPreferred = false;
      this.switchPortal.controls['defaultLogin'].setValue(false);
    }
    if (getDetails) {
      try {
        await this.unlockModuleAccess(getDetails);
        sessionStorage?.removeItem('setAppointmentDetails');
        this.getNewPortalData(facilityId, roleType, setPreferred);       
      } catch {
        sessionStorage?.removeItem('setAppointmentDetails');
        this.getNewPortalData(facilityId, roleType, setPreferred);
      }
    } else {
      sessionStorage?.removeItem('setAppointmentDetails');
      this.getNewPortalData(facilityId, roleType, setPreferred);
    }
  }

  // Check default login Facility
  public checkDefaultLoginFacility(event: any) {
    if (this.enableCheckbox == true) {
      this.showblankCheckbox = true;
      this.enableCheckbox = false;
      this.switchPortal.controls['defaultLogin'].setValue(true);
      console.log('Checked');
    } else {
      this.enableCheckbox = true;
      this.showblankCheckbox = false
      this.switchPortal.controls['defaultLogin'].setValue(false)
      console.log('Un-Checked');
    }
  };

  public getNewPortalData(facilityId, roleType, setPreferred) {
    const action = `login/changeProviderProfile?facilityId=${facilityId}&roleType=${roleType}&setPreferred=${setPreferred ? setPreferred : false}&userId=${this.loginUserId}`;
    // const params = {
    //   'facilityId': facilityId,
    //   'roleType': roleType,
    //   'setPreferred': setPreferred ? setPreferred : false,
    //   'userId': this.loginUserId
    // };
    this.loadSpinner = true;
    this.httpService?.getRequest(action)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        sessionStorage.clear();
        this.userSwitchPortal.hide();
        this.newFacilityData = data?.responseObject || [];
        this.switchPortal?.reset();
        this.dataService.setLoginDetails(data?.responseObject);
        this.dataService.setIsLogin(true);
        sessionStorage.setItem('isLogin', 'true');
        sessionStorage.setItem('userDetails', JSON.stringify(data?.responseObject));
        this.getFacilityWaitTime(data?.responseObject?.facilityId);
        this.getFacilityDetails(data?.responseObject?.facilityId);
        sessionStorage?.removeItem('providerConfiguration');
        this.getProviderconfigInfo(data?.responseObject?.facilityId);
        this.httpService.getDocumentUploadRequired();
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    }, error => {
      this.loadSpinner = false;
    });
  }
  //Get the Facility Wait-Time 
  public getFacilityWaitTime(facilityid: any): void {
    let action: any = 'waitTime/';
    let params: any = {
      'id': facilityid.toString()
    }
    this.httpService.makeGetRequest(action, params.id)
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          this.dataService.setFacilityWaitTime(data);
          sessionStorage?.setItem('waitTimeDetails', JSON.stringify(data));
        }
      })
  }
  public getFacilityDetails(facilityid: any): void {
    let action: any = 'providerInfo/getProviderInfo';
    let params: any = {
      'id': facilityid,
      'facilityName': "",
      'phoneNumber': "",
      'zipCode': 0
    }
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data.body.status == 'SUCCESS') {
          this.dataService.setFacilityDetails(data.body.resultList);
          console.log(data);
          sessionStorage.setItem('token', JSON.stringify(data));
          const sessionUser = JSON.parse(sessionStorage.getItem('userDetails'));
          this.loginUserType = `${sessionUser?.roleType}`;
          let getItem = [];
          if (sessionUser?.uiFeatureGroups && sessionUser?.uiFeatureGroups?.length > 0) {
            getItem = sessionUser?.uiFeatureGroups?.map(item => {
              return {
                coreFeature: item?.coreFeature,
                childFeature: this.screenItemsShowHide(item?.childFeature),
                subFeature: this.screenItemsShowHide(item?.subFeature)
              }
            });
          }
          sessionStorage?.setItem('allProvideLoginNavItems', JSON.stringify(getItem));
          // if(this.loginUserType.toUpperCase() == 'DOCTOR')
          // this.router.navigate(['/hospital/hosp-dashboard'], {queryParams: { facilityId: facilityid}});         

          this.router.navigate(['/hospital/hosp-dashboard']);
          setTimeout(function () {
            window?.location?.reload();
          }, 1000);

          // else if(this.loginUserType.toUpperCase() == 'BUSINESSADMIN')
          // this.router.navigate(['/hospital/hosp-connect-dashboard']);
        }
      },
        error => {
          // this.displayErrorMessage = true;
          //this.errorMessage = error.message;
        });
  }
  public screenItemsShowHide(item: any): any {
    return item?.reduce((a: any, b: any) => (a[b] = true, a), {});
  }

  public async unlockModuleAccess(getDetails?: any) {
    const previousLockModule = sessionStorage?.getItem('previousUnlockModuleName') || null;
    const action = `careFlow/unlockCareFlowModuleUserAccess?appointmentId=${getDetails?.id}&moduleName=&userInfoId=${getDetails?.userInfoId}`;
    return this.httpService?.makeGetRequest(action, '')?.toPromise();
  }


  public getProviderconfigInfo(data:any){
    let action: any = 'providerConfiguration/getProviderValidation';
    this.httpService.getRequest(action).subscribe(res =>{
    sessionStorage.setItem('providerConfiguration',JSON.stringify(res?.responseObject?.userInfo));
    })
  }
 
  public privacy(): void{
    window.open(this.envTermsPolicy?.privacyPolicy, '_blank', 'privacyPolicy')
    
  }

  public terms(): void{
    window.open(this.envTermsPolicy?.termsService, '_blank', 'termService')
    
  }

}
