const userDetails = JSON.parse(sessionStorage?.getItem('userDetails'));
interface AppConstantsList {
    isKiosk: boolean;
    loginUser: any;
    uiErrorException: string;
    allClaimsQueues: any[];
    insuranceSequenceList: any[];
    insuranceClassList: any[];
    insurancePrimarySubscriber: any[];
    isSelfPay: any[];
    insuranceType: any[];
    genderList: any[];
    insuranceRelationship: any[];
    relationshipList: any[];
    maritalStatusList: any[];
    billingAddressDetails: any[];
    raceList: any[];
    ethnicityList: any[];
    phoneTypeList: any[];
    medicationFilters: any[];
    paymentServices: any[];
    adjustmentTypes: any[];
    notificationTypes: any[];
    shortWordsArr: any[];
    languageList: any[];
    allClaimProcessorTypes: any[];
    paymentTypes: any[];
}

// app constant flags
export const AppConstantsListConfig: AppConstantsList = {
    shortWordsArr: [
        { shortCode: 'pt', description: 'patient' },
        { shortCode: 'ht', description: 'height' },
        { shortCode: 'wt', description: 'weight' },
        { shortCode: 'pcp', description: 'primary care physician' },
        { shortCode: 'od', description: 'overdose' },
        { shortCode: 'cd', description: 'chemical dependence' },
        { shortCode: 'uti', description: 'urinary tract infection' },
        { shortCode: 'fa', description: 'father' },
        { shortCode: 'mo', description: 'mother' },

        /* {shortCode:'L' , description:'left'},    
        {shortCode:'p' , description:'after'},
        {shortCode:'R' , description:'right'},
        
        */
        { shortCode: 'noc', description: 'at night' },
        { shortCode: 'da', description: 'daughter' },
        { shortCode: 'gm', description: 'grand mother' },
        { shortCode: 'gf', description: 'grand father' },

        { shortCode: 'prn', description: 'As needed' },
        { shortCode: 'dx', description: 'diagnosis' },
        { shortCode: 'hx', description: 'history' },
        { shortCode: 'er', description: 'emergency room' },
        { shortCode: 'c/o', description: 'compliant/complaining of' },
        { shortCode: 'hd', description: 'head' }],


    isKiosk: userDetails?.roleType === 'Kiosk' ? true : false,
    loginUser: userDetails,
    uiErrorException: 'Something went wrong. Please try again later. Click OK to continue.',

    allClaimsQueues: [
        { name: 'Pending Sign off', sortIndex: 2, leftfixedColumns: 2, rightfixedColumns: 1, columns: ['Alert', 'Patient Info', 'Visit Info', 'Aging Days', 'Priority', 'Provider Info', 'Payor Name', 'Chart Time (Min)', 'Actions'], apiStatus: 'Pending Sign Off', actions: ['View Encounter', 'Pending SignOff', 'Eligibility Details'], icon: '<span class="material-symbols-outlined">running_with_errors</span>' },
        { name: 'Ready for Submission', leftfixedColumns: 2, rightfixedColumns: 1, sortIndex: 2, columns: ['Alert', 'Patient Info', 'Visit Info', 'Provider Info', 'Chart Time (Min)', 'Aging Days', 'Priority', 'Payor Name', 'Actions'], apiStatus: 'Ready For Submission', actions: ['View Encounter', 'Edit Encounter', 'Chart', 'Eligibility Details', 'External Claim', 'Submit Claim', 'Facility Action', 'Claim Journal'], icon: '<span class="material-symbols-outlined">alarm_on</span>' },
        { name: 'Submitted', sortIndex: 5, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Aging Days', 'Priority', 'Payor Name', 'Provider Info', 'Billed Charges', 'Actions'], apiStatus: 'Submitted', actions: ['View Encounter', 'Edit Encounter', 'Chart', 'Eligibility Details',   'View Claim Details', 'Post Charges', 'Account Balance', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records', 'Claim Submission History'], icon: '<span class="material-symbols-outlined">exit_to_app</span>' },
        { name: 'Clearing House Rejects', leftfixedColumns: 3, rightfixedColumns: 1, sortIndex: 5, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Aging Days', 'Priority', 'Payor Name', 'Provider Info', 'Billed Charges', 'Adjudication status', 'Actions'], apiStatus: 'Clearing House Rejects', actions: ['View Encounter', 'Edit Encounter', 'Chart', 'Eligibility Details',  'View Claim Details', 'Error Message', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records', 'Claim Submission History'], icon: '<span class="material-symbols-outlined">history</span>' },
        { name: 'Payor Rejects', sortIndex: 5, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Aging Days', 'Priority', 'Payor Name', 'Provider Info', 'Billed Charges', 'Adjudication status', 'Actions'], apiStatus: 'Payer Rejects', actions: ['View Encounter', 'Edit Encounter', 'Chart', 'Eligibility Details',  'View Claim Details', 'Post Charges', 'Account Balance', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records', 'Claim Submission History'], icon: '<span class="material-symbols-outlined">manage_search</span>' },
        { name: 'Denials', sortIndex: 5, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Aging Days', 'COB', 'Payor Name', 'Provider Info', 'Billed Charges', 'Adjudication status', 'Actions'], apiStatus: 'Denials', actions: ['View Encounter', 'Edit Encounter', 'Chart', 'Eligibility Details', 'View Claim Details', 'Error Message', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records', 'Claim Submission History'], icon: '<span class="material-symbols-outlined">do_not_disturb_on</span>' },
        { name: 'Review', sortIndex: 5, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Aging Days', 'COB', 'Priority', 'Payor Name', 'Provider Info', 'Billed Charges', 'Adjudication status', 'AR Call Status',  'Actions'], apiStatus: 'Review', actions: ['View Encounter', 'Edit Encounter', 'Chart',  'View Claim Details', 'Error Message', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Post Charges', 'Account Balance', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records', 'Claim Submission History'], icon: '<span class="material-symbols-outlined">search_check</span>' },
        { name: 'Pending EOB/ERA', sortIndex: 5, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Aging Days', 'Priority', 'Payor Name', 'Provider Info', 'Billed Charges', 'Check/EFT Trace Number', 'Actions'], apiStatus: 'Pending EOB/ERA', actions: ['Chart',   'View Claim Details', 'Post Charges', 'Account Balance', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records', 'Claim Submission History'], icon: '<span class="material-symbols-outlined">account_balance_wallet</span>' },
        { name: 'AR Insurance', sortIndex: 4, leftfixedColumns: 2, rightfixedColumns: 1, columns: ['Claim Info', 'Patient Info', 'Visit Info', 'Aging Days', 'Payor Name', 'Provider Info', 'Billed Charges', 'Approved Amount', 'AR Insurance', 'AR Patient', 'Check/EFT Trace Number', 'Actions'], apiStatus: 'AR Insurance', actions: ['View Encounter', 'Chart',    'View Claim Details', 'View EOB', 'Download EOB', 'Post Charges', 'Account Balance', 'Pay', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records', 'Claim Submission History'], icon: '<span class="material-symbols-outlined">other_admission</span>' },
        { name: 'AR Patient', sortIndex: 4, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert','Claim Info', 'Patient Info', 'Visit Info', 'Follow up Date', 'Aging Days', 'Payor Name', 'Provider Info', 'Billed Charges', 'Approved Amount', 'AR Insurance', 'AR Patient', 'Co-Pay/Co-Ins', 'Statement Status', 'Actions'], apiStatus: 'AR Patient', actions: ['View Encounter', 'Chart',   'View Claim Details', 'View EOB', 'Download EOB', 'Post Charges', 'Account Balance', 'Pay', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records' , 'Claim Submission History' , 'Notify'], icon: '<i class="las la-comment-dollar"></i>' },
        { name: 'Self-Pay', sortIndex: 1, leftfixedColumns: 2, rightfixedColumns: 1, columns: ['Patient Info', 'Visit Info', 'Provider Info', 'Billed Charges', 'Patient Responsibility', 'Actions'], apiStatus: 'Self Pay', actions: ['View Encounter', 'Chart', 'Pay', 'Post Charges', 'Account Balance'], icon: '<span class="material-symbols-outlined">account_box</span>' },
        { name: 'Collection', sortIndex: 1, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Aging Days', 'Payor Name', 'Provider Info', 'Billed Charges', 'Approved Amount', 'AR Insurance', 'AR Patient', 'Co-Pay/Co-Ins', 'Statement Status', 'Actions'], apiStatus: 'Collections', actions: ['Move', 'Export'], icon: '<span class="material-symbols-outlined">library_add</span>' },
        { name: 'Closed', sortIndex: 4, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Payor Name', 'Provider Info', 'Billed Charges', 'Approved Amount', 'AR Insurance', 'AR Patient', 'Co-Pay/Co-Ins', 'Check/EFT Trace Number', 'Reason', 'Actions'], apiStatus: 'Closed', actions: ['View Encounter', 'Chart',  'View Claim Details', 'Post Charges', 'Account Balance', 'Write Off',  'Move', 'HCFA1500 Form', 'Medical Records'], icon: '<i class="lar la-check-circle"></i>' },
        { name: 'Advanced Search', sortIndex: 5, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Aging Days', 'Priority', 'Payor Name', 'Provider Info', 'Status', 'Billed Charges', 'Actions'], apiStatus: 'Search All', actions: ['View Encounter', 'Edit Encounter', 'Chart', 'Eligibility Details',  'View Claim Details', 'Post Charges', 'Account Balance', 'Error Message', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records'], icon: '<span class="material-symbols-outlined">search_check</span>' },
        { name: 'AR Calls', sortIndex: 5, leftfixedColumns: 3, rightfixedColumns: 1, columns: ['Alert', 'Claim Info', 'Patient Info', 'Visit Info', 'Billed Charges',  'Payor Name', 'Provider Info', 'Assignee', 'Priority', 'Adjudication status', 'AR Call Info',  'Follow up Date', 'Aging Days', 'COB',  'Actions'], apiStatus: 'AR Call', actions: ['View Encounter', 'Edit Encounter', 'Chart',  'View Claim Details', 'Error Message', 'External Claim', 'Re-Submit Claim', 'Corrected Claim', 'Post Charges', 'Account Balance', 'Facility Action', 'Claim Journal',  'Move', 'HCFA1500 Form', 'Medical Records'], icon: '<span class="material-symbols-outlined">search_check</span>' },

    ],


    insuranceSequenceList: [
        { desc: 'Primary', value: 'Primary' },
        { desc: 'Secondary', value: 'Secondary' },
        { desc: 'Tertiary', value: 'Tertiary' }
    ],
    insuranceClassList: ['Commercial', 'Medicare', 'Medicare Advantage', 'Medicaid'],
    insurancePrimarySubscriber: [
        { value: 'Self', desc: 'Self' },
        { value: 'Other', desc: 'Other' }
    ],
    isSelfPay: [
        { value: 'Insurance', desc: 'Insurance' },
        { value: 'Self-Pay', desc: 'Self-Pay' },
        { value: 'healthPass', desc: 'Health Pass' },
        // {value: 'Un-Insured', desc: 'Un-Insured'}
    ],
    insuranceType: [
        { value: true, desc: 'Medicare' },
        { value: false, desc: 'Other' }
    ],
    genderList: [
        { value: 'Male', desc: 'Male' },
        { value: 'Female', desc: 'Female' },
        { value: "Trans Female", desc: "Trans Female" },
        { value: "Trans Male", desc: "Trans Male" },
        { value: "Non-binary", desc: "Non-binary" },
        { value: 'Other', desc: 'Other' },
        { value: "Unknown", desc: "Unknown" }
    ],
    insuranceRelationship: [
        /*{ value: 'Father', desc: 'Father' },
        { value: 'Mother', desc: 'Mother' },
        { value: 'Spouse', desc: 'Spouse' },
        { value: 'Child', desc: 'Child' }*/
        { value: 'Father', desc: 'Father' },
        { value: 'Mother', desc: 'Mother' },
        { value: 'Spouse', desc: 'Spouse' },
        { value: 'Siblings', desc: 'Siblings' },
        { value: 'Friend', desc: 'Friend' },
        { value: 'Guardian', desc: 'Guardian' },
        { value: 'Son', desc: 'Son' },
        { value: 'Daughter', desc: 'Daughter' },
        { value: 'Partner', desc: 'Partner' },
        { value: 'Child', desc: 'Child' }
    ],
    relationshipList: [
        { value: 'Father', desc: 'Father' },
        { value: 'Mother', desc: 'Mother' },
        { value: 'Spouse', desc: 'Spouse' },
        { value: 'Siblings', desc: 'Siblings' },
        { value: 'Friend', desc: 'Friend' },
        { value: 'Guardian', desc: 'Guardian' },
        { value: 'Son', desc: 'Son' },
        { value: 'Daughter', desc: 'Daughter' },
        { value: 'Partner', desc: 'Partner' },
        { value: 'Child', desc: 'Child' }
    ],
    maritalStatusList: [
        { value: 'Single', desc: 'Single' },
        { value: "Partnered", desc: "Partnered" },
        { value: 'Married', desc: 'Married' },
        { value: "Separated", desc: "Separated" },
        { value: "Divorced", desc: "Divorced" },
        { value: "Widowed", desc: "Widowed" },
        { value: 'Other', desc: 'Other' },
        { value: "Unknown", desc: "Unknown" }
    ],
    billingAddressDetails: [
        { value: 'Same', desc: 'Same' },
        { value: 'Other', desc: 'Other' }
    ],
    raceList: [
        { value: 'American Indian or Alaska Native', desc: 'American Indian or Alaska Native' },
        { value: 'Black or African American', desc: 'Black or African American' },
        { value: 'White', desc: 'White' },
        { value: 'Asian', desc: 'Asian' },
        { value: 'Native Hawaiian or Other pacific Islander', desc: 'Native Hawaiian or Other pacific Islander' },
        { value: 'Other Race/Multiracial', desc: 'Other Race/Multiracial' },
        { value: 'Unknown', desc: 'Unknown' },
        { value: 'I don’t want to specify', desc: 'I don’t want to specify' }
    ],
    ethnicityList: [
        { value: 'Hispanic', desc: 'Hispanic' },
        { value: 'Not Hispanic', desc: 'Not Hispanic' },
        { value: 'Unknown', desc: 'Unknown' },
        { value: 'I don’t want to specify', desc: 'I don’t want to specify' }
    ],
    phoneTypeList: [
        { value: true, desc: 'Mobile Phone' },
        { value: false, desc: 'Landline Phone' }
    ],
    languageList: [
        { value: 'English', desc: 'English' },
        { value: 'Chinese', desc: 'Chinese' },
        { value: 'Spanish', desc: 'Spanish' },
        { value: 'Hindi', desc: 'Hindi' }
    ],
    medicationFilters: ['All', 'Active', 'Discontinued', 'Lapsed'],
    paymentServices: ['Co-Pay', 'Consultation', 'In-House Labs', 'External Labs', 'Radiology Services', 'Healthpass', 'Injections', 'Weight Loss Program', 'Paper Work', 'Other'],
    adjustmentTypes: ['Add Co-Pay', 'Reduce Co-Pay', 'Increase Credits', 'Reduce Credits', 'Increase Balance', 'Reduce Balance', 'Refund'],
    notificationTypes: [
        { value: 'General Notification', desc: 'General', isShow: ['Chart', 'Past Appt', 'Future Appt', 'Chart Appts', 'Chart Apps Docs', 'Requisition Orders', 'Lab Order', 'Rad Order', 'GeneralAccount', 'Dashboard'] },
        { value: 'Appointment Delay', desc: 'Appointment Delay', isShow: ['Future Appt', 'Chart Appts', 'Dashboard'] },
        { value: 'Appointment Reminder', desc: 'Appointment Reminder', isShow: ['Future Appt', 'Dashboard'] },
        { value: 'Pending Pre-Visit Documentation', desc: 'Pending Pre-Visit Documentation', isShow: ['Future Appt', 'Chart Appts', 'Dashboard'] },
        // { value: 'Appointment Cancellation Confirmation', desc: 'Appointment Cancellation Confirmation' },
        // { value: 'Appointment Confirmation', desc: 'Appointment Confirmation' },
        // { value: 'Appointment Reschedule Confirmation', desc: 'Appointment Reschedule Confirmation' },
        { value: 'Lab Orders', desc: 'Lab Orders', isShow: ['Chart Appts', 'Dashboard'] },
        { value: 'Rad Orders', desc: 'Rad Orders', isShow: ['Chart Appts', 'Dashboard'] },
        { value: 'Diagnostics Results', desc: 'Diagnostics Results', isShow: ['Chart Apps Docs', 'Requisition Orders', 'Lab Order', 'Rad Order', 'Dashboard'] },
        // { value: 'Journal Notes', desc: 'Journal Notes' },
        { value: 'Account Balance', desc: 'Account Balance', isShow: ['Chart', 'Future Appt', 'Claim AR', 'GeneralAccount', 'Dashboard'] },
        { value: 'General Feedback', desc: 'General Feedback', isShow: ['Chart', 'Chart Appts', 'Dashboard'] },
        { value: 'New Patient Registration', desc: 'Schedule Appointment', isShow: ['Chart', 'Chart Appts', 'Dashboard'] },
        { value: 'Care Gap', desc :'General', isShow:['Care Gap']}
        // { value: 'Secure', desc: 'Secure' }
    ],
    allClaimProcessorTypes: ['Primary', 'Secondary', 'Tertiary'],
    paymentTypes: ['POS', 'Non POS', 'Cash', 'Check', 'Online', 'Direct Deposit'],
}
