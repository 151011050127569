<div class="add-appointment-sectons" [formGroup]="insuranceForm">
    <!-- Insurance Details -->
    <div class="row">
        <div class="form-group col-lg-8 col-md-8 col-sm-12">
            <ng-container>
                <label>Coverage Type<span class="text-danger">*</span></label><br />
                <label class="radio-inline" *ngFor="let item of isSelfPay">
                    <input
                        [ngClass]="item.value ==='healthPass' && ((insuranceForm && !insuranceForm?.get('healthPass')?.value) )?'disabled' : ''"
                        formControlName="selfPay" id="add-appt-insu-coverage-type" type="radio"
                        (change)="toggleInsuranceSection(item.value);" name="selfPay" [value]="item.value"
                        [attr.disabled]="item.value ==='healthPass' && ((insuranceForm && !insuranceForm?.get('healthPass')?.value) )?'true':null">
                    <span class="desc"
                        [ngClass]="item.value ==='healthPass' && ((insuranceForm && !insuranceForm?.get('healthPass')?.value) )?'disabled' : ''">{{item.desc}}</span></label>
                <br />
                <span class="text-danger"
                    *ngIf="insuranceForm?.get('selfPay')?.errors?.required && insuranceForm?.get('selfPay')?.touched">Coverage
                    Type is Required</span>
            </ng-container>
        </div>
    </div>

    <div *ngIf="insuranceForm?.get('selfPay')?.value === 'Insurance'">
        <ng-container>
            <app-common-insurance-addl [demographicFormDt]="demographicForm" [idType]="'appointmentId'"
                [userInfoDetailsForm]="insuranceForm" [pagename]="'demographics'"
                (reloaddemographics)="updateDemographics($event)" [userInfoDetails]="userInfoDetails"
                [selectedPatientFlowBoard]="selectedPatientFlowBoard"></app-common-insurance-addl>
        </ng-container>
    </div>

    <h5 class="mb-2">Billing Address Details</h5>
    <hr />

    <div class="row">
        <!-- Billing details -->
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label class="radio-inline" *ngFor="let item of billingAddressDetails">
                <input formControlName="BILLING_ADDRESS" id="billing-address" (change)="billingAddress()" type="radio"
                    name="BILLING_ADDRESS" [value]="item.value">
                <span class="desc">{{item.desc}}</span>
            </label>
        </div>
    </div>

    <ng-container>
        <div class="row billing-address-details">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="Address"> Street </label>
                    <input type="text" class="form-control input-text" autocorrect="off" autocapitalize="off"
                        spellcheck="off" #searchBilling formControlName="BILLING_STREET" id="common-address-street"
                        placeholder="Enter Billing Street">
                    <span class="text-danger"
                        *ngIf="insuranceForm.get('BILLING_STREET').invalid && !insuranceForm.get('BILLING_STREET').errors?.required">Please
                        enter a valid Address</span>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="city"> City </label>
                    <input class="form-control input-text" type="text" formControlName="BILLING_CITY"
                        placeholder="City " id="common-address-city" />
                    <span class="text-danger"
                        *ngIf="insuranceForm.get('BILLING_CITY').invalid && !insuranceForm.get('BILLING_CITY').errors?.required">Please
                        enter a valid City</span>
                </div>
            </div>
            <!-- </div>
    
        <div class="row"> -->
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="State"> State </label>
                    <input class="form-control input-text" type="text" formControlName="BILLING_STATE"
                        placeholder="State " id="common-address-state" />
                    <span class="text-danger"
                        *ngIf="insuranceForm.get('BILLING_STATE').invalid && !insuranceForm.get('BILLING_STATE').errors?.required">Please
                        enter a valid State</span>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="Zipcode"> ZIP Code </label>
                    <input class="form-control input-text" type="text" minlength="5" maxlength="5"
                        formControlName="BILLING_ZIPCODE" placeholder="ZIP Code " id="common-address-zipcode" />
                    <span class="text-danger"
                        *ngIf="insuranceForm.get('BILLING_ZIPCODE').invalid && !insuranceForm.get('BILLING_ZIPCODE').errors?.required">Please
                        enter a valid ZIP Code</span>
                </div>
            </div>
        </div>
    </ng-container>
</div>