<div class="ccm-section">
    <h5 class="mb-2">CCM Program</h5>
    <hr />

    <div class="row mb-3">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="add-new-items-ccm">
                <button type="button" class="btn btn-primary" (click)="openAddProgram(addProgram)">+ Add Program
                </button>
            </div>
        </div>
    </div>
    <div class="row care-management">
        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <div class="table-responsive new-designed-table">
                <table datatable [dtOptions]="dtOptionsCaregap" [dtTrigger]="dtTriggerCaregap" id="report-table"
                    class="table table-striped table-hover custom-table">
                    <thead>
                        <tr>
                            <th>Program Name</th>
                            <th>Enrolled Date</th>
                            <th style="width: 400px;">Condition</th>
                            <th>Status</th>
                            <th>Device Info</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="appointment-tr-queue" *ngFor="let program of careProgrammList; let i=index">
                            <td><span *ngIf="program?.name === 'Chronic Care'">CCM</span>
                                <span *ngIf="program?.name !== 'Chronic Care'">{{program?.name}}</span>
                            </td>

                            <td>
                                <span *ngIf="program?.consentDate">{{ visitDateFormat(program?.consentDate) ||
                                    '--'}}</span>
                                <span *ngIf="!program?.consentDate"> -- </span>
                            </td>

                            <td >
                                <span class="conditions" *ngIf="program?.conditions"> {{getConditionslist(program?.conditions) ||
                                    '--'}}</span>
                                <span *ngIf="!program?.conditions">--</span>

                            </td>
                            <td class="status-clr-code">

                                <span class="status-clr-code-main active"
                                    *ngIf="program?.status === 'Enrolled'">Active</span>
                                <span class="status-clr-code-main "
                                    [ngClass]="program?.status === 'Cancelled'  ? 'cancelled' : 'paused'"
                                    *ngIf="program?.status !== 'Enrolled'">
                                    {{program?.status || '--'}}</span>


                            </td>
                            <td class="device-info">
                                <div *ngIf="userDeviceList && userDeviceList.length !== 0 && program?.name === 'RPM'">
                                    <p ngbTooltip="Device Name" placement="bottom-left">{{userDeviceList[0]?.name}}
                                    </p>
                                    <p ngbTooltip="Device Number" placement="bottom-left">{{userDeviceList[0]?.number}}
                                    </p>
                                    <p ngbTooltip="Issue Date" placement="bottom-left">
                                        {{visitDateFormat(userDeviceList[0]?.issueDate)}}
                                    </p>

                                </div>
                                <div *ngIf="!userDeviceList || userDeviceList.length === 0 || program?.name !== 'RPM'">
                                    -- </div>


                            </td>
                            <td class="text-center">
                                <div class="dropdown-actions">
                                    <button
                                        class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}"
                                        id="claimActions_btn" (click)="openUniversalActions($event, i);">...</button>
                                    <div class="dropdown-content-actions"
                                        *ngIf="openActions && selectedActionRow === i">
                                        <a class="menulink" placement="bottom" style="cursor: pointer;"
                                            (click)="openUpdateProgram(addProgram,program);closeUniversalActions();">
                                            <i class="material-symbols-outlined">edit</i>Edit
                                        </a>
                                        <!-- <a class="menulink" placement="bottom" (click)="closeUniversalActions();">
                                            <i class="material-symbols-outlined">delete</i>Delete
                                        </a>-->
                                    </div>
                                </div>

                            </td>


                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>

<div class="caregap-section">
    <h5 class="mb-2">Care Gaps</h5>
    <hr />
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">

        </div>
    </div>
</div>

<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>
<!-- Add Program -->
<app-ui-modal #addProgram [hideHeader]="false" [modalCentered]="true" id="addProgram">
    <div class="app-modal-header">
        <h5 class="modal-title text-center">
            <span *ngIf="isUpdate">Update Program</span>
            <span *ngIf="!isUpdate">Add Program</span>
        </h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="addProgram?.hide();resetAddProgram();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row" [formGroup]="addProgrammForm">
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Program Name<span class="required">*</span></label>
                    <ng-select [ngClass]="isUpdate   ? 'disabled' : ''" (change)="changeProgramType($event)"
                        placeholder="Program Name" [items]="programmList" [addTag]="true" bindLabel="desc"
                        bindValue="value" formControlName="name"></ng-select>
                    <span class="text-danger"
                        *ngIf="addProgrammForm?.get('name')?.errors?.required && addProgrammForm?.get('name')?.touched">
                        Program is Required</span>

                    <span class="text-danger" *ngIf="savedItemAlert">
                        {{savedItemAlert}}</span>

                </div>
            </div>
            <!--<div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Enrolled Date<span class="required">*</span></label>
                    <input type="date" class="form-control input-text" placeholder="Enrolled Date" formControlName="name">
                   
                </div>
            </div>-->
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Condition</label>
                    <ng-select placeholder="Condition" formControlName="conditions" [items]="chronicConditionsList"
                        [multiple]="true" [addTag]="true" [closeOnSelect]="false" bindLabel="desc"
                        bindValue="value"></ng-select>

                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Status<span class="required">*</span></label>
                    <ng-select placeholder="Status" [ngClass]="!isUpdate   ? 'disabled' : ''" formControlName="status"
                        [items]="ccmStatusList" [addTag]="true" bindLabel="desc" bindValue="value"
                        (change)="checkIfActive($event)" [closeOnSelect]="false"></ng-select>
                    <span class="text-danger"
                        *ngIf="addProgrammForm?.get('status')?.errors?.required && addProgrammForm?.get('status')?.touched">
                        Status is Required</span>

                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Purpose</label>

                    <ng-select placeholder="Purpose" [addTag]="true" bindLabel="desc" bindValue="value"
                     formControlName="devicePurpose"></ng-select>
                    <span class="text-danger"
                        *ngIf="deviceAddForm?.get('devicePurpose')?.errors?.required && deviceAddForm?.get('devicePurpose')?.touched">
                        Purpose is Required</span>

                </div>
            </div> -->

        </div>
        <h5>RPM Device Details</h5>
        <hr>
        <div class="row" [formGroup]="deviceAddForm">
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Device Name<span class="required" *ngIf="!isCCM">*</span></label>
                    <input type="text" class="form-control input-text" placeholder="Device Name"
                        [ngClass]="isCCM   ? 'disabled' : ''" formControlName="deviceName">
                    <span class="text-danger"
                        *ngIf="deviceAddForm?.get('deviceName')?.errors?.required && deviceAddForm?.get('deviceName')?.touched">
                        Name is Required</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Device Number<span class="required" *ngIf="!isCCM">*</span></label>
                    <input type="text" class="form-control input-text" placeholder="Device Number"
                        [ngClass]="isCCM   ? 'disabled' : ''" formControlName="deviceNumber">
                    <span class="text-danger"
                        *ngIf="deviceAddForm?.get('deviceNumber')?.errors?.required && deviceAddForm?.get('deviceNumber')?.touched">
                        Number is Required</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Issue Date<span class="required" *ngIf="!isCCM">*</span></label>
                    <input type="date" class="form-control input-text" [max]="maxIssueDate" placeholder="Issue Date"
                        [ngClass]="isCCM   ? 'disabled' : ''" formControlName="deviceIssueDate"
                        (keyup.enter)="validateManualEnterdob($event)" (mouseout)="validateManualEnterdob($event)"
                        (blur)="validateManualEnterdob($event)">
                    <span class="text-danger"
                        *ngIf="deviceAddForm?.get('deviceIssueDate')?.errors?.required && deviceAddForm?.get('deviceIssueDate')?.touched">
                        Issue Date is Required</span>
                    <span class="text-danger"
                        *ngIf="deviceAddForm.get('deviceIssueDate').errors?.invalidDate && deviceAddForm.get('deviceIssueDate').touched">Please
                        Enter Valid Date</span>

                </div>
            </div>
        </div>

    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" id="addProgram_add" style="margin-right: 15px;"
            data-dismiss="modal" (click)="checkEnroll(addProgram,enrollPatient);">Save</button>

    </div>
</app-ui-modal>


<!-- Enroll Patient -->
<app-ui-modal #enrollPatient [hideHeader]="false" [modalCentered]="true" id="enrollPatient">
    <div class="app-modal-header">
        <h5>New Patient Consent</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="enrollPatient?.hide();resetEnrollProgram();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" [formGroup]="enrollPatientForm">

        <h6>By signing below you are enrolling in CCM and agreeing to the following:</h6>
        <ol>
            <li>Only one provider can furnish CCM Services to you during a calendar month.</li>
            <li>Your medical information will be shared electronically with your active providers as part of
                coordination your care.</li>
            <li>Depending on supplemental coverage all CCM Care Coordination support and does not include a face-to-face
                meeting with the provider.</li>
            <li>You may stop CCM services at any time, effective at the end of the calendar month, by notifying Care
                Harmony directly.</li>
        </ol>
        <div class="form-group text-left">
            <div class="checkbox checkbox-primary d-inline">
                <input type="checkbox" (change)="toggleConsent($event, 'verbalConsent')"
                    [checked]="isVerbalConsent === true" name="consent-form" id="consentCheck1"
                    formControlName="verbalConsent">
                <label class="consent-label"> Verbal consent by the patient.</label>
            </div>
        </div>
        <div class="form-group text-left">
            <div class="checkbox checkbox-primary d-inline">
                <input type="checkbox" (change)="toggleConsent($event, 'physicalConsent')"
                    [checked]="isPhysicalConsent === true" name="consent-form" id="consentCheck2"
                    formControlName="physicalConsent">
                <label class="consent-label">The patient's consent has already been obtained.</label>
            </div>
        </div>
        <div *ngIf="activeSelected && consentNotSelected" class="text-danger">Please select consent</div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="caregapListEnroll_ok" (click)="enrollpatient(addProgram,enrollPatient);">Enroll Patient</button>
        <!--  <button type="button" [disabled]="activeSelected" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="caregapListEnroll_ok" (click)="addProgramm(addProgram,enrollPatient);">Dont Enroll</button>-->
    </div>
</app-ui-modal>