<div class="row">
    <div class="col-md-6">
        <iframe id="lab-rad-medical-record-pdf" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="col-md-6">
        <div class="verify-form-content">
            <div class="form-group">
                <!-- <select class="form-control" placeholder="Lab Reports"  id="labReports">                          
                    <option value="Lab Reports">Lab Reports</option>
                </select> -->
                {{typeOfRecord}} Reports
            </div>
            <hr>
            <div class="verify-status">
                <span class="not-verify">{{this.labRadData?.reviewStatus || 'Not Available'}}</span>
                <h5> Digital Delivery</h5>
            </div>
            <div class="verify-name">
                <div class="row">
                    <div class="col-md-6">
                        <h4>{{userAppointmentDataVal?.appointmentRegistration?.lastName | titlecase}},&nbsp;{{userAppointmentDataVal?.appointmentRegistration?.firstName  | titlecase}}
                        </h4>
                        <!-- <p><small>Consented date: 02/04/2023</small></p> -->
                    </div>
                    <div class="col-md-6 consen-btn-group">
                        <button class="consented-btn" id="verifyReports_consented" *ngIf="userData?.patientConsent"><i class="fa fa-check"
                                aria-hidden="true"></i>Consented</button>
                        <button class="consented-btn non" id="verifyReports_nonConsented" *ngIf="!userData?.patientConsent"><i class="fa fa-times"
                                aria-hidden="true"></i>Non Consented</button>
                    </div>
                </div>
            </div>
            <div class="form-group verify-txtarea" [formGroup]="progressNotesForm">
                <label>Review Notes </label>
                <textarea class="form-control" rows="4" type="text" placeholder="Write..." id="verifyReports_notes"
                    formControlName="reviewNotes"></textarea>
                <span class="text-danger"
                    *ngIf="progressNotesForm?.get('reviewNotes')?.errors?.required && progressNotesForm?.get('reviewNotes')?.touched">Review
                    Comments
                    is Required</span>
            </div>
            <div class="verify-stick-menu">
                <a class="verify-prev">
                    <span *ngIf="indexCount > 0" (click)="updateIndex('less')" id="verifyReports_prev">
                        &lt; &nbsp; Previous
                    </span>
                </a>
                <ul class="stick-menu-list">
                    <li class="menu-select">
                        <a (click)="updateJournalNotes()" id="verifyReports_verify"
                            class="{{this.labRadData?.reviewStatus !='COMPLETED'? 'check-hide':''}}">
                            <i class="material-symbols-outlined">
                                done
                            </i> Verify
                        </a>
                    </li>
                    <li>
                        <a (click)="openTask(userAppointmentDataVal?.appointmentRegistration)" id="verifyReports_task">
                            Task
                        </a>
                    </li>
                    <li *ngIf="userData?.patientConsent && labRadData?.reviewStatus =='COMPLETED'">
                        <a (click)="openNotify(userAppointmentDataVal?.appointmentRegistration)" id="verifyReports_notify">
                            Notify
                        </a>
                    </li>
                </ul>
                <a class="verify-next">
                    <span *ngIf="indexCount < resultLength-1" (click)="updateIndex('add')" id="verifyReports_next">
                        Next &nbsp; &gt;
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Late Notify PopUp -->
<app-send-sms-notify [pageType]="'Chart Apps Docs'"></app-send-sms-notify>