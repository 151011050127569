import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-behaviousscreening',
  templateUrl: './review-behaviousscreening.component.html',
  styleUrls: ['./review-behaviousscreening.component.scss']
})
export class ReviewBehaviousscreeningComponent {

  @Input() behaviourScreeningData: any;

  public mainSectionDisplay(allBehavioralScreeningData: any): boolean {
    let isMainSecDisplay = false;
    if (!allBehavioralScreeningData) {
      isMainSecDisplay = false;
      return;
    }
    allBehavioralScreeningData?.forEach((mainItem: any) => {
      mainItem?.sections?.forEach((item: any) => {
        const answersFormGroup = item?.answers;
        answersFormGroup?.forEach((ansItemGroup: any) => {
          const checkisAns = ansItemGroup?.actualValue?.filter(item => item?.answer);
          if (checkisAns && checkisAns?.length > 0) {
            isMainSecDisplay = true;
          }
        });
      });
    });
    return isMainSecDisplay;
  }

  public checkCountsSectionsDisplay(sectionsName: string, resourceType: string): boolean {
    if (sectionsName?.includes(resourceType)) {
      return true;
    }
    return false;
  }

  public pointsCount(sectionsValues: any): any {
    let sum = 0;
    sectionsValues?.forEach((item: any) => {
      const answersFormGroup = item?.answers;
      answersFormGroup?.forEach((ansItemGroup: any) => {
        sum += ansItemGroup?.actualValue?.map(item => this.checkValues(item?.answer) || 0)?.reduce((prev, curr) => prev + curr);
      });
    });
    return sum;
  }

  public checkValues(value: any): number {
    let numberValue: number = 0;
    if (value?.includes('0')) {
      numberValue = 0;
    }
    if (value?.includes('+1')) {
      numberValue = 1;
    }
    if (value?.includes('+2')) {
      numberValue = 2;
    }
    if (value?.includes('+3')) {
      numberValue = 3;
    }
    return numberValue;
  }

  public checkBehaviouralScreenigDisplay(sectionsValues: any): any {
    let isDisplay = false;
    sectionsValues?.forEach((item: any) => {
      const answersFormGroup = item?.answers;
      answersFormGroup?.forEach((ansItemGroup: any) => {
       const checkisAns = ansItemGroup?.actualValue?.filter(item => item?.answer);
       if(checkisAns && checkisAns?.length > 0) {
        isDisplay = true;
       }
      });
    });
    return isDisplay;
  }

}
