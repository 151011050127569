<div class="row">

    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 16px;">

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <div class="webcam-capture">
                    <webcam [trigger]="triggerObservable" (imageCapture)="handleInsuranceImage($event)" [height]="300"
                        *ngIf="showInsuranceWebcam" [width]="300" [allowCameraSwitch]="allowCameraSwitch"
                        [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [imageQuality]="1"
                        (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
                    </webcam>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12"
                *ngIf="webcamInsuranceImage && this.webcamInsuranceImages?.length > 0">
                <!-- Thumbnail images -->
                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">

                        <div (click)="currentInsuranceSlide(this,i)"
                            *ngFor="let webcamInsuranceImage of webcamInsuranceImages;let i = index">

                            <img style="width: 300px; height: 300px;" class="demo cursor mb-3"
                                [src]="webcamInsuranceImage?.imageAsDataUrl" />
                        </div>

                        <div (click)="currentInsuranceSlide(this,i)"
                            *ngFor="let webcamInsuranceImage of webcamInsuranceImages;let i = index">
                            <!-- <img (click)="clearInsuranceSnapshot(i);" src="../../assets/images/DeleteBin.png"
                            alt="Clear Snapshot" class="mb-3" style="cursor: pointer;"> -->
                            <button class="icon-queue-events delete-bg" (click)="clearInsuranceSnapshot(i);"
                                placement="bottom" ngbTooltip="Delete" style="margin-bottom: 15px;">
                                <i class="lar la-trash-alt"
                                    style="font-size: 25px; color: black; margin-top: 10x; cursor: pointer;"></i>
                            </button>

                        </div>

                    </div>


                </div>


            </div>

        </div>

        <div class="row" style="margin-top: 10px;">
            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <button type="button" class="btn btn-primary" style="margin-right: 15px;"
                    *ngIf="showInsuranceWebcam && webcamInsuranceImages.length < 1"
                    (click)="triggerInsuranceSnapshot();" data-dismiss="modal"
                    (click)="modalDefault.hide()">Capture</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" style="margin-right: 15px;" id="save"
                    *ngIf="webcamInsuranceImages.length !== 0" (click)="uploadProfilepic();">Save</button>
                <button type="button" class="btn btn-primary" style="margin-right: 15px;"
                    (click)="closeInsuranceCamera();cancelUpdate();" id="commonProfilePicCareflow_cancel">Cancel</button>

            </div>
        </div>


                            <!--        
                    <div class="row">
                    <button *ngIf="showInsuranceWebcam && webcamInsuranceImages.length < 1" (click)="triggerInsuranceSnapshot();"
                        class="btn btn-primary " style="margin-right: 15px;">Capture
                    </button>
                    <button *ngIf="webcamInsuranceImages.length !== 0" (click)="uploadProfilepic();"
                        class="btn btn-primary " style="margin-right: 15px;">Save</button>
                    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
                        (click)="closeInsuranceCamera();cancelUpdate();">Cancel</button>
                    </div>-->

    </div>

</div>
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>
<!--  profile image upload popups -->
<!--<app-ui-modal #successModal [hideHeader]="false" [modalCentered]="true" id="successModal">
  <div class="app-modal-header">
    
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="successModal?.hide();emitUpload();"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
   <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-2">

        <h5 class="modal-title text-success text-center" style="font-size: 50px; margin: 0px;">
         
          <i aria-hidden="true" class="fa fa-check-circle"></i>
        </h5>

      </div>
      <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">

        <h5 class="modal-title" style=" text-align: left;"> {{notifyText}}</h5>

      </div>
    </div>
	
	
    
  </div>
   <div class="app-modal-footer">

    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
       (click)="successModal?.hide();emitUpload();">Ok</button>
  </div>

</app-ui-modal>-->