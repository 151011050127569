<!-- Show More API Resources -->

<app-ui-modal #eobCheckDetails [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
    id="eobCheckDetails">
    <div class="app-modal-header">
        <h5 class="modal-title">EOB Details</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="resetDocumentsPopup();eobCheckDetails?.hide()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="docs-form" [formGroup]="uploadAdditioinalDocsForm">
            <!-- <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <input type="hidden" formControlName="collectionType" value="Encounter">
                    <div class="form-group">
                        <label for="documentType">Document Collection <span class="text-danger">*</span></label>
                        <ng-select placeholder="Choose Document Type" id="uploadAdditionDoc_doccollection"
                            bindLabel="documentType" bindValue="documentType" [items]="addDocumentTypes"
                            [readonly]="isReadonly" formControlName="documentType"></ng-select>

                        <span class="text-danger"
                            *ngIf="uploadAdditioinalDocsForm?.get('documentType')?.errors?.required && uploadAdditioinalDocsForm?.get('documentType')?.touched">Document
                            Type is Required</span>
                    </div>
                </div>
            </div> -->

            <div class="row" [formGroup]="checkNumberDateForm">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label><b>Processor Type</b></label>
                        <ng-select [addTag]="true" placeholder="Choose Processor Type" [items]="allClaimProcessorTypes"
                            [clearable]="true" (change)="changeProcessorTypeData($event)"
                            formControlName="processorType"></ng-select>
                        <label class="required error"
                            *ngIf="checkNumberDateForm?.get('processorType')?.errors?.required && checkNumberDateForm?.get('processorType')?.touched">Claim
                            Processor is required</label>
                    </div>
                </div>
            </div>

            <!-- Check details -->
            <div class="row mt-2" [formGroup]="checkNumberDateForm">
                <!-- Check Number -->
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label><b>Check/Draft Number</b></label>
                        <input class="form-control input-text" (blur)="checkNumberDateValidation('checkNumber')"
                            type="text" placeholder="Check/Draft Number" id="payment-check-number"
                            formControlName="checkNumber" />
                        <label class="required error"
                            *ngIf="checkNumberDateForm?.get('checkNumber')?.errors?.required && checkNumberDateForm?.get('checkNumber')?.touched">Check/Draft
                            Number is Required</label>
                    </div>
                </div>
                <!-- Check/Paid Date -->
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label><b>Check/Paid Date</b></label>
                        <input type="date" id="payment-check-date" [max]="maxStartDate" (change)="checkNumberDateValidation('checkDate')"
                            name="checkDate" class="form-control input-text" id="payment-check-date"
                            formControlName="checkDate" placeholder="Check/Paid Date">
                        <label class="required error"
                            *ngIf="checkNumberDateForm?.get('checkDate')?.errors?.required && checkNumberDateForm?.get('checkDate')?.touched">Check/Paid
                            Date is required</label>
                        <label class="required error"
                            *ngIf="checkNumberDateForm?.get('checkDate')?.errors?.invalidDate && checkNumberDateForm?.get('checkDate')?.touched && !checkNumberDateForm?.get('checkDate')?.errors?.required">Invalid
                            Check/Paid Date</label>
                    </div>
                </div>
            </div>

            <!-- Document New Design - Start -->
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <label class="mt-2"><b>EOB Document</b></label>
                    <form class="custom-file-container">
                        <div class="custom-file">
                            <div class="">
                                <div class="image-upload">
                                    <label style="cursor: pointer;width: 100%;">
                                        <i class="material-symbols-outlined">
                                            upload_file
                                        </i>
                                        <h4><input type="file" (change)="uploadFileSelect($event)"
                                                class="custom-file-input" id="chart-acc-additonal-documents-upload"
                                                #fileUpload name="chart-acc-additonal-documents-upload"
                                                accept="application/pdf, image/*" value="">
                                            <label class="custom-file-label"></label>
                                        </h4>
                                        <p> Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be
                                            uploaded
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-lg-12 col-md-12 uploaded-data" *ngIf="documentsList?.name">
                    <ul class="uploaded-files">
                        <li class="success" id="fileName-delete"> {{documentsList?.name}} <span
                                class="material-symbols-outlined" (click)="deleteSelectedFile(index)"
                                style="cursor:pointer;">delete</span></li>
                    </ul>
                </div>
            </div>
            <!-- Document New Design - END -->

            <!-- Validation message shown -->
            <div class="row" *ngIf="errorMessageValidation">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="error required">{{errorMessageValidation}}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="app-modal-footer text-center mt-3">
        <div class="text-left">
            <!-- [disabled]="!documentsList?.name" -->
            <button class="btn btn-primary" id="uploadAdditionDoc_submit"
                (click)="submitEobCheckDetails(eobCheckDetails)">Submit</button>
        </div>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Upload Sucess Notify -->
<app-ui-modal #documentUploadNotify [hideHeader]="false" id="documentUploadNotify">
    <div class="app-modal-header">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 *ngIf="isSuccessNotify" class="modal-title text-success text-center" style="font-size: 50px;"><i
                        class="fa fa-check-circle" aria-hidden="true"></i></h5>
                <h5 *ngIf="!isSuccessNotify" class="modal-title text-danger text-center" style="font-size: 40px;">
                    <i class="las la-minus-circle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">
                <h5 class="modal-title" [innerHTML]="notifyText"></h5>
            </div>
        </div>

        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="documentUploadNotify.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-footer">
        <!-- <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="documentUploadNotify.hide(); openNotificationJournalNotesAdd()">Ok</button>-->
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="uploadAdditionDoc_ok2"
            (click)="documentUploadNotify.hide(); openNotificationJournalNotesAdd()">Ok</button>
    </div>
</app-ui-modal>

<!-- Late Notify PopUp -->
<app-send-sms-notify [noDelay]="true" [pageType]="'Chart'"></app-send-sms-notify>