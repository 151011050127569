<div class="summary-exam-data">
    <div class="exam-sec">
        <ng-container *ngFor="let exam of examData; let exami = index">
            <ng-container *ngFor="let examSec of exam?.sections">
                <ng-container *ngIf="checkIsValuesPresent(examSec)">
                    <h5><u>{{examSec?.name}}</u></h5>
                    <ng-container *ngFor="let answer of examSec?.answers">
                        <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                            <ul>
                                <li>{{value?.answer || '--'}}</li>
                            </ul>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="exam?.sections?.length === 0">
                <span>No exams are chosen for this patient.</span>
            </ng-container>

            <ng-contaier *ngIf="exam?.otherNotes">
                <div class="form-group row mb-0 inline-form">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                        </span>
                        <!-- </div>
                    <div class="col-lg-6 col-md-6 col-sm-6"> -->
                        <label>{{exam?.otherNotes || '--'}}</label>
                    </div>
                </div>
            </ng-contaier>
        </ng-container>

        <ng-container *ngIf="!examData || examData?.length === 0">
            <span>No exams are chosen for this patient.</span>
        </ng-container>
    </div>
</div>