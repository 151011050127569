<app-ui-modal #followupNotify [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner" id="followupNotify">
    <div class="app-modal-header">
        <h5 class="modal-title">Notification</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="followupNotify.hide()">
            <span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <!-- <h5 class="text-center">
            <p>FOLLOW UP NOTIFICATION!</p>
        </h5> -->

        <div class="row">
            <div class="col-md-12">
                <div class="row">

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Follow-up Reason<span class="text-danger">*</span></label>
                            <input type="text" id="followupNotification_reason" class="form-control" placeholder="Follow Up Reason">
                            <div class="required">Notification
                                Type is Required</div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Patient’s Phone Number<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" id="phonenumber"
                                placeholder="Patient’s Phone Number">
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Notification<span class="text-danger">*</span></label>
                            <textarea class="form-control" placeholder="Write Message....." id="notificationMessage"
                                style="height: 100px"></textarea>
                            <div class="required">Notification
                                Type is Required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" id="followupNotification_submit"> &nbsp;SUBMIT</button>
    </div>
</app-ui-modal>