import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { NewTaskComponent } from 'src/app/theme/shared/hop-common-components/new-task/new-task.component';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';


@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent implements OnInit {

  public active = 1;
  @ViewChild(DataTableDirective, { static: false })
  dtElementTasksStatus: DataTableDirective;
 
  public dtOptionsTasksStatus: DataTables.Settings = {};
  public dtTriggerTasksStatus: Subject<any> = new Subject<any>();
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalComp: ExceptionModalComponent;
  @Input() taskCard: CardComponent;
  public claimStatus: any;
  public allTasks = [];
  public loadSpinner: boolean;
  

  public eraStatus = {
    "claimFilingIndicatorCode": "12",
    "claimFrequencyCode": "1",
    "claimPaymentAmount": "500",
    "claimStatusCode": "1",
    "facilityTypeCode": "11",
    "patientControlNumber": "5554555444",
    "patientResponsibilityAmount": "300",
    "payerClaimControlNumber": "94060555410000",
    "totalClaimChargeAmount": "800"
  };
  public setServiceDate: string;
  selectedViewAppointmentId: any;
  @ViewChild(NewTaskComponent) newTakComp: NewTaskComponent;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  

  constructor(private httpService: HttpService, private router: Router) { }

  ngOnInit() {
    this.dtOptionsTasksStatus = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[1, "desc"]],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
        },
    };

  }

  ngAfterViewInit(): void {
    //this.getTaskList();
	
  }
  
   public rerender(): void {
    this.dtElementTasksStatus?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
	
	 
  }
  
  
  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

   public changeServiceDate(serviceDate: string): void {
    if (serviceDate) {
      this.setServiceDate = new Date(serviceDate)?.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
    } else {
      this.setServiceDate || null;
    }
  }



  // task details
  public getTaskList(): void {
  //alert(1);
    this.rerender();
	//const userInfoId = '5a61d2e955334577b8cd9f64ac79f3e6';
    const action = `tasks/listTasks`;
    this.taskCard?.cardRefreshShow();
	this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
	this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.allTasks = data?.responseObject || [];
      } else {
        this.notifyText = data?.message || AppConstantsListConfig.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalComp?.modalShow();
      }
      
     // this.dataTableFilter();
      this.taskCard?.cardRefreshHide();
	  this.dtTriggerTasksStatus?.next();
    },
      (error) => {
	  this.loadSpinner = false;
        this.notifyText = error?.message || AppConstantsListConfig.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalComp?.modalShow();
        this.taskCard?.cardRefreshHide();
      });
  }

  public formateStatusDate(date: string): any {
    return this.httpService?.formatDobTimeZoneWithDisplay(date);
  }

  public formateServiceDate(date: string): void {
    return this.httpService?.yymmddDateFormat(date);
  }

  public formatPrimaryPhone(primaryPhone: string): string {
    return primaryPhone?.includes('+1') ? primaryPhone?.replace('+1', '') : primaryPhone;
  }

  public openTaskModal(appointment: any): void {

    this.newTakComp?.showTaskModal(appointment);

  }

  public sendNotification(appointment?: any): void {
    this.sendNotifyComp?.showNotifyModalForEncounter(appointment, 'user');
  }

  ngOnDestroy(): void {
    this.dtTriggerTasksStatus?.unsubscribe();
  }

}
