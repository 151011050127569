import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { ClaimJournalDetailsComponent } from '../claim-journal-details/claim-journal-details.component';
import { NewTaskComponent } from '../new-task/new-task.component';
import { ClaimsService } from '../../services/claims.service';
import { FaxReferralCommonComponent } from '../fax-referral-common/fax-referral-common.component';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { isEmpty } from 'lodash';


@Component({
  selector: 'app-view-submitted-claim-details',
  templateUrl: './view-submitted-claim-details.component.html',
  styleUrls: ['./view-submitted-claim-details.component.scss']
})
export class ViewSubmittedClaimDetailsComponent implements OnInit {

  @Input() claimCardReload: CardComponent;
  @Input() claimQueueItemData: any;
  @ViewChild('viewSubmittedClaimDetailsModal') viewClaimModal: UiModalComponent;
  @ViewChild(ExceptionModalComponent) exceptionalComp: ExceptionModalComponent;
  public notifyText: string;
  public individualClaimDetails: any;
  eraStatus: any;
  claimStatus: any;
  onlyERAStatusShown: boolean;
  eraReport: any;
  public otherClaimDetals: any;
  allClaimSubmissionHistory: any;
  allCurrentPreviousClaims: any[];
  public claimNumber: any;
  public agingHistory: any[];
  public claimItem: any
  public loadSpinner: boolean;
  public selectedLink: any;
  public appointmentId: any;
  public claimQueueItem: any;
  public claimItemStatus: any;
  public claimTraclerCount: any[];
  public claimNoTraclerCount: any[];
  public claimPatientInfo: any = [];
  public claimResponse: any;
  @ViewChild(ClaimJournalDetailsComponent) public claimJournalComp: ClaimJournalDetailsComponent;
  @ViewChild(NewTaskComponent) newTaskComp: NewTaskComponent;

  constructor(private httpService: HttpService, private claimsService: ClaimsService, private formBuilder: FormBuilder) {
    this.sendNewReferralForm = formBuilder?.group({
      'preferredReferralChannel': [null, Validators?.required],
      'fax': [null, Validators?.compose([Validators?.required, Validators?.pattern(AppRegExpressionsConfig?.mobile)])],
      'email': [null],
      'recipientName': [null, Validators?.compose([Validators?.required, Validators?.pattern(AppRegExpressionsConfig?.nameValidator)])],
      'subject': [null, Validators?.required],
      'documentName': [null],
      'multipartFile': [null],
      'includeLastVisitMedicalRecord': [null],
      'referrralNotes': [null, Validators?.required]
    });

  }
  @Output() onClaimJournal = new EventEmitter();

  @ViewChild(FaxReferralCommonComponent) faxReferralComp: FaxReferralCommonComponent;
  public selectedappointmentId: any;
  public selectedUserinfoId: any;
  public selectedClaimItem: any;
  public sendNewReferralForm: FormGroup;
  public providerFirstName: any;
  public providerLastName: any;
  public userAppointmentDetails: any;
  @ViewChild('sendNewFaxEmail') sendFaxModal: UiModalComponent;
  public taskInfo: any;

  ngOnInit(): void {
  }

  public async showClaimDetailsModalWithDetailsOpen(claimItem: any, isEraShown?: boolean) {

    //window.scrollTo(0, 0);
    this.claimItem = claimItem;
    //console.log("claimItem", this.claimItem);
    this.selectedappointmentId = claimItem?.appointmentId;
    this.selectedUserinfoId = claimItem?.userInfoId;

    let appaction: any = `appointment/registration/getAppointmentById?id=${this.selectedappointmentId}`;
    const appointmentInfoDetails: any = await this.httpService?.makeGetRequest(appaction, '')?.toPromise();
    this.selectedClaimItem = appointmentInfoDetails?.responseObject;
    console.log(this.selectedClaimItem);


    this.claimQueueItem = sessionStorage.getItem('claimQueueItem');
    //console.log("claimQueueItem",this.claimQueueItem);
    this.claimNumber = claimItem?.claimNumber;
    this.appointmentId = claimItem?.appointmentId;
    this.selectedLink = 'claimDetails';
    this.viewClaimDetails(claimItem, this.selectedLink);
    this.onlyERAStatusShown = isEraShown;
    setTimeout(() => {
      document.getElementById("claimdetails").classList.remove("fade")
    }, 1000)
  }
  public async showClaimDetailsModal(claimItem: any, isEraShown?: boolean) {

    //window.scrollTo(0, 0);
    this.claimItem = claimItem;
    console.log("claimItem", this.claimItem);
    this.selectedappointmentId = claimItem?.appointmentId;
    this.selectedUserinfoId = claimItem?.userInfoId;

    let appaction: any = `appointment/registration/getAppointmentById?id=${this.selectedappointmentId}`;
    const appointmentInfoDetails: any = await this.httpService?.makeGetRequest(appaction, '')?.toPromise();
    this.selectedClaimItem = appointmentInfoDetails?.responseObject;
  //  console.log(this.selectedClaimItem);

    /*this.claimItemStatus = claimItem?.status;
    console.log("claimItemStatus", this.claimItemStatus);
    if (this.claimItemStatus === 'Submitted') {
      this.claimTraclerCount = ['Submitted'];
      this.claimNoTraclerCount = ['Processed', 'Received', 'Processed/Paid'];
    }

    else if (this.claimItemStatus === 'Payer Rejects' || this.claimItemStatus === 'Pending EOB/ERA' || this.claimItemStatus === 'Denials' || this.claimItemStatus === 'Review') {
      this.claimTraclerCount = ['Submitted', 'Processed', 'Received'];
      this.claimNoTraclerCount = ['Processed/Paid'];
    }

    else if (this.claimItemStatus === 'AR Insurance' || this.claimItemStatus === 'AR Patient' || this.claimItemStatus === 'Closed') {
      this.claimTraclerCount = ['Submitted', 'Processed', 'Received', 'Processed/Paid'];
      this.claimNoTraclerCount = [];
    }
    else {
      this.claimTraclerCount = ['Submitted'];
      this.claimNoTraclerCount = ['Processed', 'Received', 'Processed/Paid'];
    }*/

    this.claimQueueItem = sessionStorage.getItem('claimQueueItem');
    //console.log("claimQueueItem",this.claimQueueItem);
    this.claimNumber = claimItem?.claimNumber;
    this.appointmentId = claimItem?.appointmentId;
    this.selectedLink = 'summary';
    this.viewClaimDetails(claimItem, this.selectedLink);
    this.onlyERAStatusShown = isEraShown;
  }
  public async showFacilityactionModal(claimItem: any, isEraShown?: boolean) {

    this.claimItem = claimItem;
   // console.log("claimItem", this.claimItem);
    this.selectedappointmentId = claimItem?.appointmentId;
    this.selectedUserinfoId = claimItem?.userInfoId;
    this.selectedLink = 'facilityaction';

    this.openFacilityActions(claimItem)
    this.selectLink('facilityAction')

    this.claimNumber = claimItem?.claimNumber;
    this.appointmentId = claimItem?.appointmentId;
    this.selectedLink = 'facilityAction';
    this.onlyERAStatusShown = isEraShown;

    this.viewClaimModal?.show();
    setTimeout(() => {
      document.getElementById("facilityaction").classList.remove("fade")
    }, 1000)
  }


  public async showClaimJournalModal(claimItem: any, isEraShown?: boolean) {
    this.claimItem = claimItem;
    //console.log("claimItem", this.claimItem);
    this.selectedappointmentId = claimItem?.appointmentId;
    this.selectedUserinfoId = claimItem?.userInfoId;
    this.selectedLink = 'claimjournal';
    this.loadClaimJournal()
    this.selectLink('claimJournal')
    this.claimNumber = claimItem?.claimNumber;
    this.appointmentId = claimItem?.appointmentId;

    this.onlyERAStatusShown = isEraShown;
    this.viewClaimModal?.show();
    setTimeout(() => {
      document.getElementById("claimjournal").classList.remove("fade")
    }, 1000)
  }
  public async show1500FormModal(claimItem: any, isEraShown?: boolean) {

    this.claimItem = claimItem;
   // console.log("claimItem", this.claimItem);
    this.selectedappointmentId = claimItem?.appointmentId;
    this.selectedUserinfoId = claimItem?.userInfoId;
    this.selectedLink = '1500form';
    this.showHcfaForm()
    this.selectLink('1500form')

    this.claimNumber = claimItem?.claimNumber;
    this.appointmentId = claimItem?.appointmentId;

    this.onlyERAStatusShown = isEraShown;
    this.viewClaimModal?.show();
    setTimeout(() => {
      document.getElementById("formclaim").classList.remove("fade")
    }, 1000)
  }

  public viewClaimDetails(claimItem: any, selectedLink: any): void {

    this.selectLink(selectedLink);
    const action = `claim/getIndividualClaimDetails?appointmentId=${claimItem?.appointmentId}&claimId=${claimItem?.claimNumber}`;
    this.claimCardReload?.cardRefreshShow();
    this.claimsService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.claimCardReload?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        this.individualClaimDetails = data?.responseObject?.claimMaster || {};
        this.claimResponse = data?.responseObject?.claimResponse || {};

        this.claimPatientInfo = [];
          if (this.individualClaimDetails?.claimRequest?.dependent) {
            this.claimPatientInfo = this.individualClaimDetails?.claimRequest?.dependent;
          }
          else {
            this.claimPatientInfo = this.individualClaimDetails?.claimRequest?.subscriber;
          }
         // console.log('claimPatientInfo',this.claimPatientInfo);


        this.claimItemStatus = this.individualClaimDetails?.status;
        //console.log("claimItemStatus", this.claimItemStatus);
        if (this.claimItemStatus === 'Submitted') {
          this.claimTraclerCount = ['Submitted'];
          this.claimNoTraclerCount = ['Processed', 'Received', 'Processed/Paid'];
        }

        else if (this.claimItemStatus === 'Payer Rejects' || this.claimItemStatus === 'Pending EOB/ERA' || this.claimItemStatus === 'Denials' || this.claimItemStatus === 'Review') {
          this.claimTraclerCount = ['Submitted', 'Processed', 'Received'];
          this.claimNoTraclerCount = ['Processed/Paid'];
        }

        else if (this.claimItemStatus === 'AR Insurance' || this.claimItemStatus === 'AR Patient' || this.claimItemStatus === 'Closed') {
          this.claimTraclerCount = ['Submitted', 'Processed', 'Received', 'Processed/Paid'];
          this.claimNoTraclerCount = [];
        }
        else {
          this.claimTraclerCount = ['Submitted'];
          this.claimNoTraclerCount = ['Processed', 'Received', 'Processed/Paid'];
        }
        this.updateUser()

        this.otherClaimDetals = data?.responseObject?.otherClaimProcessors || {};
        if (this.individualClaimDetails?.claimRequest?.claimInformation?.healthCareCodeInformation) {
          const allDiagnosisCodes = this.individualClaimDetails?.claimRequest?.claimInformation?.healthCareCodeInformation?.map((item: any) => item?.diagnosisCode);
          const filtered = this.individualClaimDetails?.claimRequest?.claimInformation?.healthCareCodeInformation?.filter(({ diagnosisCode }, index) => !allDiagnosisCodes?.includes(diagnosisCode, index + 1));
          this.individualClaimDetails.claimRequest.claimInformation.healthCareCodeInformation = filtered || [];
        };
        this.viewClaimModal?.show();
        // this.claimStatus = data?.responseObject?.claimMaster?.claimsStatus && data?.responseObject?.claimMaster?.claimsStatus[0]?.claimStatus || {};
        this.claimStatus = data?.responseObject?.claimsStatus && data?.responseObject?.claimsStatus[0]?.claimStatus || {};
        this.eraStatus = (data?.responseObject?.claimMaster?.eraReport && data?.responseObject?.claimMaster?.eraReport?.paymentInfo && data?.responseObject?.claimMaster?.eraReport?.paymentInfo?.length > 0 && data?.responseObject?.claimMaster?.eraReport?.paymentInfo[0] && data?.responseObject?.claimMaster?.eraReport?.paymentInfo[0]?.claimPaymentInfo) || (data?.responseObject?.claimMaster?.eraReport && (data?.responseObject?.claimMaster?.eraReport?.claimPaymentInfo || data?.responseObject?.claimMaster?.eraReport?.transactions[0]?.detailInfo[0]?.paymentInfo[0]?.claimPaymentInfo)) || {};
        this.eraReport = data?.responseObject?.claimMaster?.claimMaster?.eraReport || null;

        this.getClaimHistory(this.individualClaimDetails.id);
      }
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.exceptionalComp?.modalShow();
      this.claimCardReload?.cardRefreshHide();
    })
  }

  public updateUser() {
   
    let claimNumber = ""
    if (this.claimItem?.claimNumber !== "" && this.claimItem?.claimNumber !== null) {
      claimNumber = this.claimItem?.claimNumber
    }
    
    if (!isEmpty(this.claimItem.patientFirstName)) {
      this.claimPatientInfo.lastName = this.claimItem.patientLastName
      this.claimPatientInfo.firstName = this.claimItem.patientFirstName


    }
    else {
      const action = `claim/getIndividualClaimDetails?appointmentId=${this.claimItem?.appointmentId}&claimId=${claimNumber}`;
      //  if (!this.claimItem || !this.claimItem.claimNumber) {

      this.claimCardReload?.cardRefreshShow();

      this.claimsService?.makeGetRequest(action, '')?.subscribe((data: any) => {
        this.claimCardReload?.cardRefreshHide();

        if (data?.status === 'SUCCESS' && data?.responseObject !== null) {
          this.individualClaimDetails = data?.responseObject?.claimMaster || {};
          this.claimResponse = data?.responseObject?.claimResponse || {};

          this.claimItemStatus = this.individualClaimDetails?.status;
         // console.log("claimItemStatus", this.claimItemStatus);
          this.claimPatientInfo = [];
          if (this.individualClaimDetails?.claimRequest?.dependent) {
            this.claimPatientInfo = this.individualClaimDetails?.claimRequest?.dependent;
          }
          else {
            this.claimPatientInfo = this.individualClaimDetails?.claimRequest?.subscriber;
          }
          
          
        }
      }, error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionalComp?.modalShow();
        this.claimCardReload?.cardRefreshHide();
      })
    }

  }
  public getClaimHistory(claimId?: any): void {
    const action = `claim/agingHistory/${claimId}`;

    this.claimsService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.allClaimSubmissionHistory = JSON.parse(JSON?.stringify(data?.responseObject)) || null;
        this.agingHistory = this.allClaimSubmissionHistory?.agingStatusHistory || [];
        const previousClaimsHitsory = (data?.responseObject && data?.responseObject?.previousClaimsAgingStatus) || [];
        previousClaimsHitsory?.forEach(element => {
          element.claimType = 'Previous';
        });
        delete data?.responseObject?.previousClaimsAgingStatus;
        this.allCurrentPreviousClaims = [...[data?.responseObject], ...previousClaimsHitsory];

      }

    }, error => {

    })
  }

  public showHcfaForm(): void {
    this.claimCardReload?.cardRefreshShow();
    this.loadSpinner = true;
    const action = `claim/downloadDocumentHcfa1500?appointmentId=${this.claimItem?.appointmentId}&claimId=${this.claimItem?.id}`;
    this.claimsService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' });
      const blobToFile = new File([file], `${this.claimItem?.appointmentId}_HCFA1500.pdf`, {
        type: file?.type,
      });
      this.sendNewReferralForm.value.multipartFile = blobToFile;

      var fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('hcfa-form-view-detail') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
      this.claimCardReload?.cardRefreshHide();
      this.loadSpinner = false;
    },
      (error: any) => {
        this.loadSpinner = false;
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionalComp?.modalShow();
        this.claimCardReload?.cardRefreshHide();
      });
  }

  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

  // dateFormate 
  public dateFormate(dateNum: string): any {
    if (!dateNum) {
      return null;
    }
    if (dateNum?.includes('-') || dateNum?.includes('/')) {
      return dateNum;
    }
    const spDate = dateNum?.split('');
    const date = `${spDate[4]}${spDate[5]}/${spDate[6]}${spDate[7]}/${spDate[0]}${spDate[1]}${spDate[2]}${spDate[3]}`;
    return date || null;
  }

  public selectLink(slectedHeading: any) {
    this.selectedLink = slectedHeading;
    this.updateUser()
    if (slectedHeading === '1500form') {
      this.showHcfaForm()
    }
  }

  public getCheckNumEraReport(eraCheckNumber: string): string {
    return (eraCheckNumber && eraCheckNumber?.split(" - ")[0]) || eraCheckNumber;
  }
  public loadClaimJournal() {
    this.claimJournalComp.showClaimJournalModal(this.claimItem);
  }

  public getTaskInfo(taskId) {
    const action = `tasks/getTaskDetails?taskId=${taskId}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.taskInfo = data?.responseObject || {};

        let task: any;
        if (this.taskInfo && this.taskInfo?.taskDetails && this.taskInfo?.taskDetails?.length > 0) {
          this.taskInfo = this.taskInfo?.taskDetails[0];
        };

        this.taskInfo.taskType = data?.responseObject?.taskType;
      }
    },
      (error) => {

      });
  }

  public openFacilityActions(claimItem?: any, taskId?: string) {
    this.updateUser()
    const taskIdVal = this.claimItem?.claimAlerts?.facilityResponseAlert;
    this.getTaskInfo(taskIdVal);
    setTimeout(() => {
      this.taskInfo.userInfoId = claimItem.userInfoId;
      this.taskInfo.appointmentId = claimItem.appointmentId;
      this.newTaskComp?.showTaskModal(this.taskInfo, taskIdVal);

    }, 1500)

    // this.newTaskComp?.showTaskDetails(claimItem, this.taskInfo);
  }

  public openFacilityActionOnLoad(claimItem?: any, taskId?: string, isEraShown?: boolean) {
    this.claimItem = claimItem;
    this.claimQueueItem = sessionStorage.getItem('claimQueueItem');
    //console.log("claimQueueItem",this.claimQueueItem);
    this.claimNumber = claimItem?.claimNumber;
    this.appointmentId = claimItem?.appointmentId;
    this.selectedLink = 'facilityAction';

    this.viewClaimDetails(claimItem, this.selectedLink);

    this.onlyERAStatusShown = isEraShown;
    //this.openFacilityActions(claimItem);

    setTimeout(() => {

      let facilityAction = document.getElementById('facilityActionLink') as HTMLElement;
      facilityAction.click();

    }, 1000)

  }

  public refreshClaims() {

    this.onClaimJournal?.emit(true);

  }

  public onTaskUpdate() {

    this.onClaimJournal?.emit(true);
  }

  public openInitiateSendFaxModal(appointment: any): void {
    /* this.providerFirstName = this.patientDetailsForm?.controls['authorized_official_first_name']?.value;
     this.providerLastName = this.patientDetailsForm?.controls['authorized_official_last_name']?.value;
     this.inputName.nativeElement.value = this.providerFirstName + " " + this.providerLastName;*/

    this.providerFirstName = appointment?.appointment?.authorized_official_first_name;
    this.providerLastName = appointment?.appointment?.authorized_official_last_name;
    this.selectedappointmentId = appointment?.id;
    //this.userAppointmentDetails = appointment;


    /* if (signatureValid === '' || signatureValid === null) {
       this.isSignatureChk = true;
       signatureCheckModal?.show();
 
 
       return;
     }
     else {
       this.isSignatureChk = false;
 
     }*/

    this.sendNewFaxShow();
    // sendFaxModal?.show();
  }

  public sendNewFaxShow(): void {
    this.faxReferralComp?.openFaxModal();
  }

}
