<!-- Show More API Resources -->
<app-ui-modal #sendNewFax [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner" id="sendNewFax">

    <div class="app-modal-header">
        <h5 class="modal-title">Send New Fax</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="sendNewFax?.hide();resetData()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="app-modal-body add-app-body">
        <div class="send-new-fax-form" [formGroup]="sendNewFaxForm">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="faxNumber">Receiver Fax Number <span class="required">*</span></label>
                        <input class="form-control input-text" type="text" mask="(000) 000-0000"
                            placeholder="(xxx) xxx-xxxx" id="faxNumber" formControlName="faxNumber" />
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('faxNumber')?.errors?.required && sendNewFaxForm?.get('faxNumber')?.touched">Receiver
                            Fax Number is required</span>
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('faxNumber')?.invalid && !sendNewFaxForm.get('faxNumber')?.errors?.required">Please
                            enter valid Fax Number. It should be 10 digit</span>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="recipientName">Receiver Name <span class="required">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Receiver Name"
                            id="recipientName" formControlName="recipientName" maxlength="40"/>
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('recipientName')?.errors?.required && sendNewFaxForm?.get('recipientName')?.touched">Receiver
                            Name is required</span>
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('recipientName')?.invalid && !sendNewFaxForm?.get('recipientName')?.errors?.required">Please
                            enter valid Receiver Name</span>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="patientName">Patient Name</label>
                        <input class="form-control input-text" type="text" placeholder="Patient Name " id="patientName"
                            formControlName="patientName" maxlength="40"/>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="subject">Subject <span class="required">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Subject" id="subject"
                            formControlName="subject" maxlength="40" />
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('subject')?.errors?.required && sendNewFaxForm?.get('subject')?.touched">Subject is required</span>
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="allLabRadResults?.length > 0">
                    <div class="form-group">
                        <label for="labRadResults">Past Lab/Rad Results</label>
                        <ng-select id="labRadResults" [multiple]="true" (change)="addLabRadResultstoUpload($event)" formControlName="labRadResults" bindLabel="documentName" placeholder="Choose Lab/Rad Results" [items]="allLabRadResults"
                            [clearable]="true"></ng-select>
                    </div>
                </div> -->
                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!isOutbound && filesentFrom !=='1500form' && filesentFrom !=='1500formClaim'">
                    <div class="form-group">
                        <label for="subject">Reason <span class="required">*</span></label>
                        <textarea class="form-control" placeholder="Write Reason" id="referrralNotes"
                            formControlName="referrralNotes"></textarea>
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('referrralNotes')?.errors?.required && sendNewFaxForm?.get('referrralNotes')?.touched">Notes
                            is required</span>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label for="documentName">Document Name <span class="required">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Document Name "
                            id="documentName" formControlName="documentName" />
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('documentName')?.errors?.required && sendNewFaxForm?.get('documentName')?.touched">Please
                            enter Document Name</span>
                    </div>
                </div> -->
                <div class="col-md-12">
                    <div *ngIf="filesentFrom !=='1500form'">
                    <form data-validation="true" action="#" method="post" class="custom-file-container"
                        enctype="multipart/form-data">
                        <div class="custom-file">
                            <div class="">
                                <div class="image-upload">
                                    <label (change)="onFileSelect($event)" style="cursor: pointer;width: 100%;"
                                        for="new-fax-upload-common">
                                        <!-- <i class="las la-cloud-upload-alt"></i> -->
                                        <i class="material-symbols-outlined">
                                            upload_file
                                        </i>
                                        <!--upload-content multiple-->
                                        <h4><input type="file" class="custom-file-input" id="new-fax-upload-common"
                                                data-required="image" #newFaxUpload type="file"
                                                name="new-fax-upload-common" data-traget-resolution="image_resolution"
                                                value="" multiple>
                                            <label class="custom-file-label" for="new-fax-upload-common"
                                                (change)="onFileSelect($event)"></label>
                                        </h4>
                                        <p> Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be
                                            uploaded </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div>
                    <div class="required"
                        *ngIf="sendNewFaxForm?.get('multipartFile')?.errors?.required && sendNewFaxForm?.get('multipartFile')?.touched">
                        File upload is required</div>

                        <!-- Past Lab Rad results -->
                    <div class="pastLabRadResultsTable mt-4" *ngIf="filesentFrom !=='1500form' && allLabRadResults && allLabRadResults?.length > 0">
                        <h5>Past Lab/Rad Results</h5>
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Service Date</th>
                                    <th>Document Type</th>
                                    <th>Document Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let labRadRes of allLabRadResults; let i = index">
                                    <tr>
                                        <td>
                                            <div class="form-group text-left list-group">
                                                <div class="checkbox checkbox-primary d-inline">
                                                    <input type="checkbox" value="{{labRadRes?.s3Key}}" [checked]="labRadRes?.isChecked"
                                                        name="labRadResultsUpload" (change)="addLabRadResultstoUpload($event, labRadRes)"
                                                        id="labRadResultsUpload{{labRadRes?.s3Key}}">
                                                    <!-- <label for="labRadResultsUpload{{labRadRes?.s3Key}}" class="cr list-name-label ml-2"></label> -->
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{(labRadRes?.serviceDate | yyymmdd) || '--'}}</td>
                                        <td>{{labRadRes?.documentType || '--'}}</td>
                                        <td style="white-space: break-spaces;">{{labRadRes?.documentName || '--'}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>

                    <!-- Uploaded Documents -->
                    <div class="uploaded-data mt-4" *ngIf="documentsList && documentsList?.length > 0">
                        <h5 *ngIf="filesentFrom !=='1500form'">Uploading - {{documentsList?.length + custLabRadResultsDocs?.length}}/{{documentsList?.length + custLabRadResultsDocs?.length}} files</h5>
                        
                        <h5 *ngIf="filesentFrom ==='1500form'">Uploading - {{documentsList?.length }}/{{documentsList?.length}} files</h5>
                        <ul class="uploaded-files" *ngFor="let doc of documentsList; let index = index">
                            <li class="success" id="fileName{{index}}" (click)="viewDocumentUploaded(doc, docsUploadView)">
                                {{doc?.name}} <span class="material-symbols-outlined" *ngIf="!doc?.noDelete" (click)="$event.stopPropagation();deleteSelectedFile(index)"
                                    style="cursor:pointer;">delete</span>
                            </li>
                        </ul>
                        <ul  class="uploaded-files" *ngFor="let doc of custLabRadResultsDocs; let index = index">
                            <li *ngIf="filesentFrom !=='1500form'" class="success" id="fileName_docs{{index}}" (click)="viewLabRadResultsDoc(doc, docsUploadView)">
                                {{doc?.documentName}} <span class="material-symbols-outlined" (click)="$event.stopPropagation();deleteLabRadDocs(index, doc)"
                                    style="cursor:pointer;">delete</span>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="app-modal-footer text-center">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="faxReferral_submit"
            (click)="createdNewFax(sendNewFax)">Submit</button>
    </div>

</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>


<!-- Display the document -->
<app-ui-modal #docsUploadView [hideHeader]="false" [modalCentered]="true" id="docsUploadView">
    <div class="app-modal-header">
        <h5 class="modal-title">Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="docsUploadView?.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="row">
            <div class="col-md-12">
                <div class="report-pdf" *ngIf="filesentSource === '1500formClaim'">
                    <iframe id="docs-upload-pdf-view-hiffa" frameBorder="0" width="100%" height="500px"></iframe>
                </div>

                <div class="report-pdf"  *ngIf="filesentSource !== '1500formClaim'">
                    <iframe id="docs-upload-pdf-view" frameBorder="0" width="100%" height="500px"></iframe>
                </div>

            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="faxReferral_ok1"
            (click)="docsUploadView?.hide()">Ok</button>
    </div>
</app-ui-modal>

