<div id="account-balance-comp">

    <div class="past-visit mb-3" id="mainDetails" #mainDetails *ngIf="appointmentDetails">
        <div class="patient-info-sec">
            <div class="patient-name row mb-2" *ngIf="isClaimPage">
                <div class="name mr-2"><b>{{appointmentDetails?.lastName}},
                        {{appointmentDetails?.firstName}} 
                        {{appointmentDetails?.middleName}}
                        </b></div>
            </div>
            <div class="row">
                <div class="col-md-12" style="padding-left: 0px;">
                    <ul class="info-details2">

                        <li class="box">
                            <p class="visit-title">Visit Details</p>

                            <span class="name"><b>{{appointmentDetails?.appointment?.purposeOfVisit}}
                                    Visit</b>
                                &nbsp;<b>|</b>&nbsp;</span>
                            <span class="gender-age">
                                {{getAppointmentTime(appointmentDetails?.appointment?.dateTime)}} </span>
                            &nbsp;<b>|</b>&nbsp;
                            <span> <b>Amount Paid: </b> $ <span>{{(calculateTotalaPaidItimized() || 0) | number :
                                    '1.2-2'}}</span>
                                <!-- $ <ng-container
                                    *ngFor="let apptCost of appointmentCostOfCare?.get('apptCostofCare')?.value"><span
                                        *ngIf="apptCost?.claimProcessorType === 'Primary'">{{(calculateTotalaPaidItimized()
                                        || apptCost?.coPay || 0) | number : '1.2-2'}}</span></ng-container> -->
                            </span>

                            <br>
                            <div class="claims-appnt-itemized">
                                <span class="name"> <b>Itemized: </b></span><br>

                                <ng-container *ngFor="let service of postChargesItemized;let last = last">
                                    <span>
                                        <b>{{service?.serviceType}}:</b>&nbsp;<span
                                            *ngIf="service?.serviceType === 'Adj CoPay'"><b>{{service?.transactionType
                                                === 'DEBIT' ? '(-)' : '(+)'}}</b></span>${{(service?.amountPaid) |
                                        number :
                                        '1.2-2'}}({{service?.modeOfPayment}}<span
                                            *ngIf="service?.claimInfo?.checkNumber || service?.claimInfo?.checkDate">-{{service?.claimInfo?.checkNumber}}</span>)
                                        &nbsp; {{service?.createdAt | yyymmdd}}
                                        <!--  | {{service?.claimInfo?.checkDate | yyymmdd}} -->
                                        <!-- Edit EOB pencil icon -->
                                        <!-- <span *ngIf="service?.claimInfo?.checkNumber || service?.claimInfo?.checkDate"><i (click)="showUploadEobCheckDetails()" class="las la-pencil-alt edit-eob-check"></i></span> -->
                                        <span *ngIf="service?.selfPay"> -
                                            Self-Pay</span> <br>
                                        <span *ngIf="!last"></span>
                                    </span>

                                </ng-container>

                                <ng-container *ngIf="!postChargesItemized || postChargesItemized?.length === 0">
                                    <span>N/A</span>
                                </ng-container>
                                <!--<ng-container>
                                    <span><b>&nbsp;&nbsp;External Labs:</b>&nbsp;$100 (ONLINE) <span
                                            *ngIf="service?.selfPay"> -
                                            Self-Pay</span><span *ngIf="!last"></span></span>
                                </ng-container>-->
                            </div>
                        </li>
                        <!-- POST VISIT Patient Paid -->
                        <li class="box">
                            <p class="visit-title">Post Visit Patient Payments</p>
                            <div class="claims-appnt-itemized">
                                <span class="name"> <b>Itemized: </b></span>
                                <br>

                                <ng-container *ngFor="let service of pastChargesItemized;let last = last">
                                    <span>
                                        <span *ngIf="service?.serviceType == 'Past Visit Charges'"> <b>Amount Paid:</b></span>
                                        <span *ngIf="service?.serviceType !== 'Past Visit Charges'">
                                            <b>{{service?.serviceType}}:</b>
                                        </span>
                                        &nbsp;${{(service?.amountPaid) | number :
                                        '1.2-2'}}<span>({{service?.modeOfPayment}}<span
                                                *ngIf="service?.claimInfo?.checkNumber || service?.claimInfo?.checkDate">-{{service?.claimInfo?.checkNumber}}</span>)</span>
                                        &nbsp; {{service?.createdAt | yyymmdd}}
                                        <!-- Edit EOB pencil icon -->
                                        <!-- <span *ngIf="service?.claimInfo?.checkNumber || service?.claimInfo?.checkDate"><i (click)="showUploadEobCheckDetails()" class="las la-pencil-alt edit-eob-check"></i></span> -->
                                        <!-- | {{service?.claimInfo?.checkDate | yyymmdd}} -->
                                        <span *ngIf="service?.selfPay"> -
                                            Self-Pay
                                        </span>
                                        <br>
                                        <span *ngIf="!last"></span>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="!pastChargesItemized || pastChargesItemized?.length === 0">
                                    <span>N/A</span>
                                </ng-container>
                                <!--<ng-container>
                                    <span><b>&nbsp;&nbsp;External Labs:</b>&nbsp;$100 (ONLINE) <span
                                            *ngIf="service?.selfPay"> -
                                            Self-Pay</span><span *ngIf="!last"></span></span>
                                </ng-container>-->
                            </div>
                        </li>

                        <!-- Visist Status -->
                        <li class="box">
                            <p class="visit-title">Visit Status</p>
                            <span><b>Statements:</b> &nbsp;
                                <ng-container *ngIf="!dupAppntCostOfCare?.lastStatementDate">
                                    --
                                </ng-container>
                                <ng-container *ngIf="dupAppntCostOfCare?.lastStatementDate">
                                    <b>Y</b>
                                    <span class="state-details">
                                        <!-- <span class="material-symbols-outlined info-btn">info</span> -->
                                        <i class="las la-info-circle info-btn"></i>
                                        <div class="Statement-item">
                                            <ul>
                                                <li *ngFor="let Statement of dupAppntCostOfCare?.lastStatementDate">
                                                    {{dateFormate(Statement)}}
                                                </li>

                                            </ul>
                                        </div>
                                    </span>
                                </ng-container>
                            </span>
                            <p><b>Status:</b> &nbsp;<span *ngIf="appointmentCostOfCare?.value?.paymentStatus ||
                                accountBalancePaymentStatus?.status" class="orange-label"
                                    [ngClass]="appointmentCostOfCare?.value?.paymentStatus === 'Completed' || accountBalancePaymentStatus?.status === 'COMPLETED' ? 'btn-status-success': 'btn-status-cancel'">
                                    {{appointmentCostOfCare?.value?.paymentStatus ||
                                    accountBalancePaymentStatus?.status || '--'}}</span>
                                <span *ngIf="!accountBalancePaymentStatus?.status">--</span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="claims-processors-details">

        <div class="row" [formGroup]="appointmentCostOfCare">
            <div [ngClass]="!noSaveButton ? 'col-lg-8 col-md-8 col-sm-12 pl-0' : 'col-lg-9 col-md-9 col-sm-12 pl-0'">
                <!-- Claims -->
                <div class="claim-line-overview" style="margin-bottom: 28px;">
                    <div class="add-new-claim d-flex-ver-center mb-2">
                        <h5 class="mr-1 mb-1">Claims</h5>
                        <ng-container *ngIf="appointmentCostOfCare?.get('apptCostofCare')?.controls?.length < 3">
                            <a type="button" id="add-account-claims-charges" placement="right"
                                ngbTooltip="Add Claim Processor" (click)="addClaimsCharges()">
                                <i class="las la-plus-circle"
                                    style="font-size: 20px; color: #5CA6DB; cursor: pointer;"></i>
                            </a>
                        </ng-container>
                    </div>
                    <div class="claims-processor-details">
                        <div class="table-responsive new-designed-table-claim-line-level"
                            formArrayName="apptCostofCare">
                            <table class="table table-hover mb-0">
                                <thead class="thead1">
                                    <tr>
                                        <th class="word-wrap1">Claim Processor</th>
                                        <th class="word-wrap1">Claim Date</th>
                                        <th class="word-wrap1">Payor Name</th>
                                        <!-- <th>Total Visit <br />Charges<span class="pay-sec-th"></span></th> -->
                                        <th>Billed Charges</th>
                                        <th>Allowed <br />Amount<span class="pay-sec-th"></span></th>
                                        <!-- <th>AR Insurance<span class="pay-sec-th"></span></th> -->
                                        <!-- <th>Discount/<br />Adjusted<span class="pay-sec-th"></span></th> -->
                                        <th>Payor Payments</th>
                                        <th>Patient Payments</th>
                                        <!-- <th>Non-Payment<span class="pay-sec-th"></span></th> -->
                                        <!-- <th>AR Patient<span class="pay-sec-th"></span></th> -->
                                        <th>Balance<span class="pay-sec-th"></span></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let costofCareItem of appointmentCostOfCare?.get('apptCostofCare')?.controls;let costI = index">
                                        <tr class="input-fields-form" [formGroupName]="costI">
                                            <td>
                                                <a style="position: relative;">
                                                    <b>{{costofCareItem?.value?.selfPay ? 'Self-Pay' :
                                                        costofCareItem?.value?.claimProcessorType
                                                        || '--'}}</b>
                                                    <br>

                                                    <!-- <span *ngIf="costofCareItem?.value.claimId !== null && costofCareItem?.value.claimId !== ''">-->

                                                    <b class="claim-eob"
                                                        (click)="viewEob(costofCareItem?.value.claimId,'mLink')">Line
                                                        Charges</b>
                                                    <span
                                                        *ngIf="costofCareItem?.value.claimId !== selectedClaimId || costofCareItem?.value.claimId === null"
                                                        (click)="viewEob(costofCareItem?.value.claimId,'IconLink')"
                                                        class="material-symbols-outlined charges-arrow claim-eob">
                                                        arrow_drop_up
                                                    </span>

                                                    <span
                                                        *ngIf="arrowDown &&  costofCareItem?.value?.claimProcessorType === selectedclaimProcessorType"
                                                        (click)="viewEob(costofCareItem?.value.claimId, 'IconLink')"
                                                        class="material-symbols-outlined charges-arrow claim-eob">
                                                        arrow_drop_up
                                                    </span>
                                                    <span
                                                        *ngIf="arrowUp  && costofCareItem?.value.claimId === selectedClaimId && costofCareItem?.value?.claimProcessorType === selectedclaimProcessorType"
                                                        (click)="closeLineOverview()"
                                                        class="material-symbols-outlined charges-arrow claim-eob">
                                                        arrow_drop_down
                                                    </span>
                                                </a>

                                            </td>
                                            <td>{{costofCareItem?.value?.claimSubmittedDate ?
                                                (costofCareItem?.value?.claimSubmittedDate | yyymmdd) : 'NA'}}</td>
                                            <td style="white-space: normal;">{{costofCareItem?.value?.insuranceName ||
                                                'NA'}}</td>
                                            <!-- <td>
                                                <div class="form-group input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <div class="form-control disabled">
                                                        {{costofCareItem?.value?.coPay}}
                                                    </div>
                                                </div>
                                            </td> -->
                                            <!-- Total Visit Charges / Billed Charges -->
                                            <td>
                                                <div class="form-group input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input class="form-control input-text" type="text"
                                                        mask="separator.2" placeholder="0.00" readonly
                                                        formControlName="totalVisitCharges" id="totalVisitCharges" />
                                                </div>
                                                <!-- <div class="form-group input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <div class="form-control disabled">
                                                        {{costofCareItem?.value?.totalVisitCharges}}
                                                    </div>
                                                </div> -->
                                            </td>
                                            <!-- Approved/Allowed Amount -->
                                            <td>
                                                <div class="form-group input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input class="form-control input-text" type="text"
                                                        mask="separator.2" placeholder="0.00"
                                                        (input)="checkTotalCharges(costofCareItem, 'allowedAmount')"
                                                        formControlName="allowedAmount" id="allowedAmount" />
                                                </div>
                                            </td>
                                            <!-- Discount/Adjusted -->
                                            <!-- <td>
                                                <div class="form-group input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input class="form-control input-text" type="text"
                                                        mask="separator.2" placeholder="0.00"
                                                        (input)="checkTotalCharges(costofCareItem, 'discountOrAdjusted')"
                                                        formControlName="discountOrAdjusted" id="discount" />
                                                </div>
                                            </td> -->
                                            <!-- AR Insurance / Payor Payments -->
                                            <td>
                                                <div class="form-group input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input class="form-control input-text" [class.negPayorValue]="costofCareItem?.value?.payerPayment && costofCareItem?.value?.payerPayment < 0" type="text"
                                                        mask="separator.2" [allowNegativeNumbers]="true" placeholder="0.00"
                                                        [readonly]="costofCareItem?.value?.selfPay ? true : null"
                                                        (input)="checkTotalCharges(costofCareItem, 'payerPayment');payorPaymentValidation(costofCareItem, 'payerPayment')"
                                                        formControlName="payerPayment" id="reimburseAmount" />
                                                </div>
                                            </td>
                                            <!-- Patient Payments -->
                                            <td>
                                                <div class="form-group input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input class="form-control input-text" type="text"
                                                        mask="separator.2" placeholder="0.00" readonly
                                                        formControlName="totalAmountPatientPaid" id="totalAmountPatientPaid" />
                                                </div>
                                            </td>
                                            <!-- Non-Payment -->
                                            <!--<td>
                                                 <div class="form-group input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input class="form-control input-text" type="text"
                                                        mask="separator.2" placeholder="0.00"
                                                        (input)="checkTotalCharges(costofCareItem, 'nonPayment')"
                                                        formControlName="nonPayment" id="nonPayment" />
                                                </div>
                                            </td> -->
                                            <!-- AR Patient / Balance -->
                                            <td>
                                                <div class="form-group input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input class="form-control input-text" type="text"
                                                        mask="separator.2" placeholder="0.00"
                                                        formControlName="patientResponsibility" readonly
                                                        id="patientResponsibility" (change)="responseChange()" />
                                                </div>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="allEobDocuments?.length !== 0">
                                                    <a href="javascript:" class="view-eob"
                                                        (click)="viewDonloadEobDocu(costofCareItem, 'view', viewDownloadEobEncounterDoc)"
                                                        id="view-eob">
                                                        <i style="font-size: 12px;" class="las la-eye"></i> View EOB
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="allEobDocuments?.length === 0">
                                                    <a href="javascript:" class="view-eob"
                                                        (click)="downloadERAPDF(costofCareItem, viewEobPostCharge, viewDownloadEobEncounterDoc)"
                                                        id="view-eob2">
                                                        <i style="font-size: 12px;" class="las la-eye"></i> View EOB
                                                    </a>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="costofCareItem?.value?.claimProcessorType !== 'Primary' && appointmentCostOfCare?.get('apptCostofCare')?.controls?.length > 1">
                                                    <a href="javascript:" (click)="deleteClaimAppCostOfCare(costI)"
                                                        class="red-label"><i class="lar la-trash-alt"></i></a>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 text-right" *ngIf="errorMessageShown">
                    <div class="error required">{{errorMessageShown}}</div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 text-right" *ngIf="successMessageShown">
                    <div class="success">{{successMessageShown}}</div>
                </div>


                <div class="claim-line-overview" [formGroup]="serviceLineCharges" *ngIf="arrowUp">
                    <div class="add-new-claim d-flex-ver-center mb-2">
                        <h5 class="mr-1 mb-1">Claim Line Overview
                            <span *ngIf="selectedclaimProcessorType">({{selectedclaimProcessorType}})</span>
                        </h5>
                        <!-- <ng-container>
                                <a *ngIf="isManulEdit" type="button" id="add-account-claims-charges" placement="right"
                                    ngbTooltip="Add Claim Line Charges" (click)="addServiceLineCharges()">
                                    <i class="las la-plus-circle"
                                        style="font-size: 20px; color: #5CA6DB; cursor: pointer;"></i>
                                </a>
                            </ng-container>-->
                        <a class="close-claim" (click)="closeLineOverview()">&times;</a>
                    </div>
                    <div *ngIf="serviceLineCharges?.get('serviceLine')?.controls.length === 0">No Claim Line Charges
                        Found</div>
                    <div class="claims-processor-details"
                        *ngIf="serviceLineCharges?.get('serviceLine')?.controls.length !== 0">
                        <div class="table-responsive new-designed-table-claim-line-level" formArrayName="serviceLine">
                            <table class="table table-hover">
                                <thead class="thead1">
                                    <tr>
                                        <th>PROC Code</th>
                                        <th>Description</th>
                                        <th>DX Codes</th>
                                        <th>Modifier</th>
                                        <th>Billed <br>Charges</th>
                                        <th>Allowed <br> Amount</th>
                                        <th>Discount/<br />Adjusted</th>
                                        <th>Non-Payment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let lineInfo of serviceLineCharges?.get('serviceLine')?.controls; let serI = index">
                                        <tr [formGroupName]="serI" class="input-fields-form">

                                            <td>
                                                <span>{{lineInfo?.value?.cptCode}}</span>

                                                <!--<div *ngIf="isManulEdit && !lineInfo?.value?.isExistingRow"
                                                        class="form-group input-group">


                                                        <input class="form-control input-text" type="text"
                                                            placeholder="Procedure Code" formControlName="cptCode"
                                                            id="cptCodeLine" />
                                                    </div>-->


                                            </td>
                                            <td style="white-space: normal;">
                                                <span>{{lineInfo?.value?.cptCodeDescription}}</span>
                                                <!-- <span *ngIf="isManulEdit && !lineInfo?.value?.isExistingRow"> -- </span>-->
                                            </td>
                                            <td>
                                                <div>
                                                    <span *ngIf="lineInfo?.value?.icdCode1">
                                                        {{lineInfo?.value?.icdCode1}}</span>
                                                    <span *ngIf="lineInfo?.value?.icdCode2">,
                                                        {{lineInfo?.value?.icdCode2}}</span>
                                                    <span *ngIf="lineInfo?.value?.icdCode3">
                                                        ,{{lineInfo?.value?.icdCode3}}</span>
                                                    <span *ngIf="lineInfo?.value?.icdCode4">,
                                                        {{lineInfo?.value?.icdCode4}}</span>

                                                </div>
                                                <!--  <div *ngIf="isManulEdit && !lineInfo?.value?.isExistingRow"> -- </div>-->

                                            </td>

                                            <td>
                                                <span>{{lineInfo?.value?.cptModifier}}</span>
                                                <!--<span *ngIf="isManulEdit && !lineInfo?.value?.isExistingRow"> -- </span>-->

                                            </td>

                                            <td>
                                                $<span
                                                    *ngIf="lineInfo?.value?.billedChargesCptCode">{{lineInfo?.value?.billedChargesCptCode}}</span>
                                                <span *ngIf="!lineInfo?.value?.billedChargesCptCode">0.00</span>

                                            </td>
                                            <td>
                                                <div *ngIf="!isManulEdit">
                                                    $<span
                                                        *ngIf="lineInfo?.value?.payerAllowedAmountCptCode && lineInfo?.value?.payerAllowedAmountCptCode !== 0">{{lineInfo?.value?.payerAllowedAmountCptCode}}</span>
                                                    <span
                                                        *ngIf="!lineInfo?.value?.payerAllowedAmountCptCode">0.00</span>

                                                </div>

                                                <div *ngIf="isManulEdit" class="form-group input-group">

                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input class="form-control input-text" type="text"
                                                        mask="separator.2" placeholder="0.00"
                                                        formControlName="payerAllowedAmountCptCode"
                                                        id="payerAllowedAmountCptCodeLine"
                                                        (input)="validateBilledAmount(lineInfo, 'payerAllowedAmountCptCode')" />
                                                </div>

                                            </td>
                                            <td>
                                                <div *ngIf="!isManulEdit"> $<span
                                                        *ngIf="lineInfo?.value?.adjustmentOrDiscountAmount">{{lineInfo?.value?.adjustmentOrDiscountAmount}}</span>
                                                    <span
                                                        *ngIf="!lineInfo?.value?.adjustmentOrDiscountAmount">0.00</span>

                                                </div>

                                                <div *ngIf="isManulEdit" class="form-group input-group">

                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input class="form-control input-text" type="text"
                                                        mask="separator.2" placeholder="0.00"
                                                        formControlName="adjustmentOrDiscountAmount"
                                                        id="adjustmentOrDiscountAmount"
                                                        (input)="validateBilledAmount(lineInfo, 'adjustmentOrDiscountAmount')" />
                                                </div>
                                            </td>
                                            <td>
                                                <!-- <div *ngIf="isManulEdit" class="form-group input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">$</span>
                                                        </div>
                                                        <input class="form-control input-text" type="text"
                                                            mask="separator.2" placeholder="Non-Payment"
                                                            formControlName="nonPayment" id="nonPaymentLine" />
                                                    </div>-->

                                                <div>
                                                    $<span
                                                        *ngIf="lineInfo?.value?.nonPayment">{{lineInfo?.value?.nonPayment}}</span>
                                                    <span *ngIf="!lineInfo?.value?.nonPayment">0.00</span>

                                                </div>
                                            </td>
                                        </tr>

                                    </ng-container>

                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 text-right" *ngIf="errorMessageClaimShown">
                        <div class="error required">{{errorMessageClaimShown}}</div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 text-right" *ngIf="successMessageClaimShown">
                        <div class="success">{{successMessageClaimShown}}</div>
                    </div>
                </div>
            </div>

            <!-- Right Panel Charges -->
            <div [ngClass]="!noSaveButton ? 'col-lg-4 col-md-4 col-sm-12 pl-0' : 'col-lg-3 col-md-3 col-sm-12 pl-0'">
                <!-- NEW FORM GROUP RIGHT PANEL STARTS -->
                <div class="claims-list" [formGroup]="visitClaimsSummaryForm">
                    <div class="table-responsive">

                        <table class="table table-bordered">
                            <tr>
                                <td class="claim-list-name">CREDITS (GENERATED)</td>
                                <td>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input class="form-control input-text" type="text" mask="separator.2"
                                            placeholder="0.00" formControlName="creditsGenerated" readonly
                                            id="credits" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="claim-list-name">CREDITS (AVAILABLE)</td>
                                <td>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input class="form-control input-text" type="text" mask="separator.2"
                                            placeholder="0.00" formControlName="creditsRemaining" readonly
                                            id="remainingCredits" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="claim-list-name">CO-PAY/ <br>
                                    CO-INSURANCE</td>
                                <td>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input class="form-control input-text" type="text" mask="separator.2"
                                            placeholder="0.00" readonly formControlName="coPay" id="coPay" />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="claim-list-name">REFUND</td>
                                <td>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input class="form-control input-text" type="text" mask="separator.2"
                                            placeholder="0.00" readonly formControlName="refund" id="refund" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="claim-list-name">
                                    ADJUSTMENTS</td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="required error" style="font-size: 12px; padding: 0;margin: 0;"
                                                *ngIf="visitClaimsSummaryForm?.get('adjFacilityCode')?.errors?.required && visitClaimsSummaryForm?.get('adjFacilityCode')?.touched">Type
                                                is required</label>
                                            <ng-select id="facility-adjustment" formControlName="adjFacilityCode"
                                                (change)="changeAdjustmentTypes(visitClaimsSummaryForm)"
                                                placeholder="Choose Type" [items]="adjustmentTypes" [clearable]="true">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="form-group input-group"
                                                [ngClass]="adjCodeSelected && !adjValueEntered ? 'textboxValidation': ''">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text " type="text" mask="separator.2"
                                                    placeholder="0.00"
                                                    (keyup)="checkAdjustmentValue(visitClaimsSummaryForm, 'adjFacility');"
                                                    (input)="changeAdjustmentTypes(visitClaimsSummaryForm);checkIncreaseReduce(visitClaimsSummaryForm, 'adjFacility'); validateAdjustmentType(visitClaimsSummaryForm)"
                                                    formControlName="adjFacility" id="adjFacility" />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="claim-list-name">BALANCE</td>
                                <td>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input class="form-control input-text bal" type="text" mask="separator.2"
                                            placeholder="0.00" readonly formControlName="balance" id="balance" />
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td class="claim-list-name">WRITE OFF</td>
                                <td>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input class="form-control input-text" type="text" mask="separator.2"
                                            placeholder="0.00"
                                            (input)="checkTotalCharges(visitClaimsSummaryForm, 'writeOff')"
                                            formControlName="writeOff" id="writeOff" />
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="visitClaimsSummaryForm?.value?.writeOff">
                                <td colspan="2" class="writecol post-charges-primary-writeoff">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-group">
                                                <label class="form-label">Write Off Reason<span
                                                        class="required">*</span></label>
                                                <ng-select placeholder="Add or Choose Reason" bindLabel="description"
                                                    bindValue="description" [items]="allClaimWriteoffCodes"
                                                    formControlName="writeOffReason" [addTag]="true">
                                                    <ng-template ng-option-tmp let-item="item" let-index="index"
                                                        let-search="searchTerm">
                                                        <div title="{{item?.description}}">{{item?.description}}</div>
                                                    </ng-template>
                                                </ng-select>
                                                <label class="required"
                                                    *ngIf="visitClaimsSummaryForm?.controls['writeOffReason'].hasError('required') && visitClaimsSummaryForm?.controls['writeOffReason'].touched">Reason
                                                    is required</label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="actions-payment text-right">
                                        <button class="icon-queue-events" id="accBalNew_savePay" *ngIf="!noSaveButton" placement="bottom"
                                            ngbTooltip="Save Payment Details"
                                            (click)="submitPayCost();submitServiceLineCharges()"><i
                                                class="lar la-save"></i></button>
                                        <button placement="bottom" id="accBalNew_makePay" *ngIf="isFollowup" ngbTooltip="Make Payment"
                                            class="icon-queue-events" (click)="openPayment()"><i
                                                class="las la-credit-card"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </div>

                </div>
                <!-- New Form Group for Right Panel END -->
            </div>
        </div>


    </div>

    <!-- Spinner -->
    <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [claimSubmitNotifyText]="claimSubmitNotifyText"
    [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Square Payment Card -->
<!-- <app-payments-common-new [paymentConfigDetails]="paymentConfigDetails" [recieptID]="'account-balance-reciept-id'"
    (checklistPayment)="loadPaymentInfo()"></app-payments-common-new> -->

<app-payments-refactor [paymentConfigDetails]="paymentConfigDetails" [recieptID]="'account-balance-reciept-id'"
    (checklistPayment)="loadPaymentInfo()"></app-payments-refactor>

<app-ui-modal #claimSubmitNotify [hideHeader]="false" id="claimSubmitNotify" class="custom-modal">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title" [innerHTML]="notifyText"></h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="claimSubmitNotify.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">

        <div class="row d-flex-ver-center">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 *ngIf="isSuccessNotify === true" class="modal-title text-success text-center"
                    style="font-size: 40px; margin: 0px;">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                </h5>
                <h5 *ngIf="isSuccessNotify === false" class="modal-title text-danger text-center"
                    style="font-size: 40px; margin: 0px;">
                    <i class="las la-minus-circle"></i>
                </h5>

            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left; " [innerHTML]="claimSubmitNotifyText"></h5>
            </div>
        </div>

        <!-- to Show required fields -->

    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="ok1" (click)="claimSubmitNotify.hide(); ">Ok</button>
    </div>
</app-ui-modal>

<!-- Check Number and Date Update popup -->
<app-ui-modal #checkNumberDateModal [hideHeader]="false" [loadSpinner]="loadSpinner" id="checkNumberDateModal"
    class="custom-modal">
    <div class="app-modal-header">
        <h5 class="modal-title">EOB Check</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="checkNumberDateModal?.hide();checkNumberDateForm?.reset()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" [formGroup]="checkNumberDateForm">
        <div class="row">
            <!-- Claim Processor Type -->
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <h6 class="mt-2"><b>Processor Type</b></h6>
                    <ng-select [addTag]="true" placeholder="Choose Claim Processor" [items]="allClaimProcessorTypes"
                        [clearable]="true" (change)="changeProcessorTypeData($event)"
                        formControlName="processorType"></ng-select>
                    <label class="required error"
                        *ngIf="checkNumberDateForm?.get('processorType')?.errors?.required && checkNumberDateForm?.get('processorType')?.touched">Claim
                        Processor is required</label>
                </div>
            </div>
            <!-- Check Number -->
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                    <h6 class="mt-2"><b>Check Number</b></h6>
                    <input class="form-control input-text" type="text" placeholder="Check Number"
                        id="payment-check-number" formControlName="checkNumber" />
                    <label class="required error"
                        *ngIf="checkNumberDateForm?.get('checkNumber')?.errors?.required && checkNumberDateForm?.get('checkNumber')?.touched">Check
                        Number is Required</label>
                </div>
            </div>
            <!-- Check Date -->
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                    <h6 class="mt-2"><b>Check Date</b></h6>
                    <input type="date" id="payment-check-date" name="checkDate" class="form-control input-text"
                        id="payment-check-date" formControlName="checkDate" placeholder="Check Date">
                    <label class="required error"
                        *ngIf="checkNumberDateForm?.get('checkDate')?.errors?.required && checkNumberDateForm?.get('checkDate')?.touched">Check
                        Date is required</label>
                    <label class="required error"
                        *ngIf="checkNumberDateForm?.get('checkDate')?.errors?.invalidDate && checkNumberDateForm?.get('checkDate')?.touched && !checkNumberDateForm?.get('checkDate')?.errors?.required">Invalid
                        Check Date</label>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="ok1" (click)="saveCheckNumberDateDetails(checkNumberDateModal)">Submit</button>
    </div>
</app-ui-modal>

<!-- View /Download Documents -->
<app-ui-modal #viewDownloadEobEncounterDoc [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="viewDownloadEobEncounterDoc">
    <div class="app-modal-header">
        <h5 class="modal-title">EOB Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="viewDownloadEobEncounterDoc.hide();filterProcessorEobDocs = [];clearIframeSrc()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="documents mb-3">
            <ng-container *ngFor="let document of filterProcessorEobDocs; let i = 'index+1'">
                <button class="icon-queue-events signoff-action-bg" [class.active]="i === eobViewIndex" placement="right"
                    (click)="viewDownloadDocument(document, 'view', viewDownloadEobEncounterDoc, i)"
                    [ngbTooltip]="'EOB Document ' + i"><i style="color: #5ca6db;" class="fa fa-file-pdf"></i></button>
            </ng-container>
        </div>
        <div class="consent-pdf">
            <iframe id="{{isClaimPage === 'yes' ? 'claims-acc-bal-eob-doc' : 'view-download-eob-doc-account-bal'}}" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="postChargesUpdate_ok1"
            (click)="viewDownloadEobEncounterDoc.hide();filterProcessorEobDocs = [];clearIframeSrc()">Ok</button>
    </div>
</app-ui-modal>

<!-- View EOB -->
<app-ui-modal #viewEobPostCharge [hideHeader]="false" [modalCentered]="true" id="viewEobPostCharge"
    class="common-modal">
    <div class="app-modal-header">
        <h5 class="modal-title">Explanation of Benefits (EOB)</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="viewEobPostCharge?.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <iframe id="eob-pdf-postcharge" frameBorder="0" width="100%" height="500px"></iframe>

    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="postChargesUpdate_ok2" (click)="viewEobPostCharge?.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- Enforcement of upload EOB / check details capture -->
<app-ui-modal #eobCheckDetailsCaptureNotify [hideHeader]="false" [modalCentered]="true"
    id="eobCheckDetailsCaptureNotify">
    <div class="app-modal-header">
        <div class="row d-flex-ver-center">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-success text-center" style="font-size: 30px; margin: 0px;border-bottom: none;">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                </h5>
                <!-- <h5 class="modal-title text-info text-center" style="font-size: 30px; margin: 0px;">
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5> -->
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left;border-bottom: none;" [innerHTML]="notifyText"></h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" id="eobCheckDetailsCaptureNotify_no"
            style="margin-right: 15px;" data-dismiss="modal"
            (click)="eobCheckDetailsCaptureNotify?.hide();showUploadEobCheckDetails()">Ok</button>
        <!-- <button type="button" class="btn btn-primary dash-actions" id="eobCheckDetailsCaptureNotify_yes"
            style="margin-right: 15px;" data-dismiss="modal"
            (click)="eobCheckDetailsCaptureNotify?.hide();showUploadEobCheckDetails()">Yes</button> -->
    </div>
</app-ui-modal>