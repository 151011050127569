<div class="commom-address-form">
    <div class="row" [formGroup]="addressForm">
        <div class="col-lg-3 col-md-3 col-sm-6">
            <div class="form-group">
                <label> Street <span *ngIf="isRequiredFields" class="required">*</span></label>
                <input type="text" class="form-control input-text" autocorrect="off" autocapitalize="off"
                    spellcheck="off" #searchStreet formControlName="street" id="address_street"
                    placeholder="Enter Address">
                <span class="text-danger"
                    *ngIf="addressForm?.get('street')?.errors?.required && addressForm?.get('street')?.touched">Street
                    is Required</span>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6">
            <div class="form-group">
                <label> City <span *ngIf="isRequiredFields" class="required">*</span></label>
                <input class="form-control input-text" type="text" placeholder="City" id="address_city"
                    formControlName="city" />
                <span class="text-danger"
                    *ngIf="addressForm?.get('city')?.errors?.required && addressForm?.get('city')?.touched">City is
                    Required</span>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6">
            <div class="form-group">
                <label> State <span *ngIf="isRequiredFields" class="required">*</span></label>
                <input class="form-control input-text" type="text" placeholder="State" id="address_state"
                    formControlName="state" />
                <span class="text-danger"
                    *ngIf="addressForm?.get('state')?.errors?.required && addressForm?.get('state')?.touched">State is
                    Required</span>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6" *ngIf="!hideZipCode">
            <div class="form-group">
                <label> ZIP Code <span *ngIf="isRequiredFields" class="required">*</span></label>
                <input class="form-control input-text" type="text" minlength="3" maxlength="5" placeholder="ZIP Code " id="Zipcode"
                    formControlName="zipCode" (blur)="addPrefixZipCode(addressForm?.get('zipCode')?.value,'valid')"/>
                <span class="text-danger" *ngIf="addressForm?.get('zipCode')?.invalid && !addressForm?.get('zipCode')?.errors?.required">Please enter a valid ZIP Code</span>
                <span class="text-danger" *ngIf="addressForm?.get('zipCode')?.errors?.required && addressForm?.get('zipCode')?.touched">ZIP Code is Required</span>
            </div>
        </div>
    </div>
</div>