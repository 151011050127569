<div class="table-responsive new-designed-table" id="dash-table">
    <table datatable [dtOptions]="dtOptionsKiosk" [dtTrigger]="dtTriggerKiosk"
        class="table table-striped table-bordered table-hover">
        <thead>
            <tr>
                <th>Patient Name</th>
                <th>Gender</th>
                <th>Appointment Time</th>
                <th>Service Provider</th>
                <th>Purpose</th>
                <th>Status</th>
                <th>Events</th>
                <th
                    *ngIf="isShowHideElements['Reschedule'] || isShowHideElements['Cancel'] || isShowHideElements['Pay']">
                    Actions</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="checkIsNoAccess">
                <tr>
                    <td colspan="8" class="text-center">No Access for Appointment Queue</td>
                </tr>
            </ng-container>
            <ng-container *ngIf="!checkIsNoAccess">
                <tr *ngFor="let appointment of allKioskAppointments; let i=index">
                    <td>
                        <!-- if kiosk name and pre visit document check -->
                        <ng-container
                            *ngIf="appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointmentQuestionnaire && (appointment?.consentForm || appointment?.consentForms?.length === 0)">
                            <span>{{appointment?.lastName}}, {{appointment?.firstName }}
                                 {{appointment?.middleName}}</span>
                        </ng-container>
                        <ng-container
                            *ngIf="appointment?.appointmentStatus?.currentStatus !== 'CANCELLED' && !appointment?.appointmentQuestionnaire || (!appointment?.consentForm && appointment?.consentForms?.length !== 0)">
                            <a href="javascript:;" placement="bottom" ngbTooltip="Complete Pre-Visit Documents"
                                style="color: red;"
                                (click)="redirectUserWebPreVisitDoc(appointment.id)">{{appointment?.lastName}}, &nbsp;{{appointment?.firstName}}</a>
                        </ng-container>
                    </td>
                    <td><span>{{appointment?.gender || '--'}}</span></td>
                    <td><span> {{ getAppointmentTime(appointment?.appointment?.dateTime) }} </span></td>
                    <td>{{ doctorNameWithNpi(appointment?.appointment) }}</td>
                    <td>{{appointment?.appointment?.purposeOfVisit || 'N/A'}}</td>
                    <td>{{getStatus(appointment?.appointmentStatus?.currentStatus, appointment) || '--'}}</td>
                    <!-- Events -->
                    <td>
                        <!-- Show Pre Visit Docs -->
                        <ng-container
                            *ngIf="appointment?.appointmentStatus?.currentStatus !== 'CANCELLED' && !appointment?.appointmentQuestionnaire || (!appointment?.consentForm && appointment?.consentForms?.length !== 0)">
                            <button class="btn btn-primary dash-actions" id="kioskApp_completePreVisit"
                                [disabled]="appointment?.appointmentStatus?.currentStatus === 'NOTAVAILABLE'"
                                (click)="redirectUserWebPreVisitDoc(appointment?.id)">Complete Pre-Visit
                                Documents</button>
                        </ng-container>

                        <!-- Check-In Buttons - it will enable only after an pre visit docs completed -->
                        <ng-container
                            *ngIf="appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointmentQuestionnaire && (appointment?.consentForm || appointment?.consentForms?.length === 0)">
                            <ng-container
                                *ngIf="!appointment?.appointment?.virtualVisit && isShowHideElements['Check-In']">
                                <button class="btn btn-primary dash-actions" type="button" id="kioskApp_checkin1"
                                    (click)="checkInDobValidation(appointment, 'checkIn', dobValidation)"
                                    [disabled]="appointment?.appointmentStatus?.checkIn || appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointmentStatus?.currentStatus === 'NOTAVAILABLE'">Check-In</button>
                            </ng-container>
                        </ng-container>

                        <!-- Tele health -->
                        <ng-container
                            *ngIf="appointment?.appointment?.virtualVisit && isShowHideElements['Tele-Health']">
                            <button class="btn btn-primary dash-actions" id="kioskApp_telehealth"
                                [disabled]="userType === 'Nurse' || appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointmentStatus?.checkOut || true">Tele-Health</button>
                        </ng-container>
                    </td>
                    <td class="action-btns"
                        *ngIf="isShowHideElements['Reschedule'] || isShowHideElements['Cancel'] || isShowHideElements['Pay']">
                        <!-- re-schedule appnt button *ngIf="userType === 'Nurse'"-->
                        <ng-container *ngIf="isShowHideElements['Reschedule']">
                            <button class="btn btn-primary dash-actions" id="kioskApp_reschedule"
                                [disabled]="appointment?.appointmentStatus?.checkIn || (appointment?.appointment?.purposeOfVisit === 'Medication Refill') || appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                (click)="reScheduleAppointment(appointment)">Reschedule</button>
                        </ng-container>

                        <!-- cancel button -->
                        <ng-container *ngIf="isShowHideElements['Cancel']">
                            <button class="btn btn-primary dash-actions"
                                [disabled]="appointment?.appointmentStatus?.checkIn || (appointment?.appointment?.purposeOfVisit === 'Medication Refill') || appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                (click)="cancelAppointment(appointment)"id="kioskApp_cancel">Cancel</button>
                        </ng-container>

                        <!-- payment button -->
                        <ng-container *ngIf="isShowHideElements['Pay']">
                            <button class="btn btn-primary dash-actions"
                                [disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'" id="kioskApp_pay"
                                (click)="openPayment(appointment)">Pay</button>
                        </ng-container>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<!-- Cancel Appointment compoenent  -->
<app-cancel-appointment (onCancelAppntChange)="makeRefresh()"></app-cancel-appointment>

<!-- Square Payment Card -->
<app-payments-common-new></app-payments-common-new>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Date Of Birth Validation on CheckIn -->
<app-ui-modal #dobValidation [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner">
    <div class="app-modal-header">
        <h5 class="modal-title">Confirm your Date of Birth?</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="dobValidation?.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <ng-container *ngIf="notValidDob">
                <div class="col-lg-12 col-md-12 col-sm-12 required">Date of Birth is not valid. Please enter valid Date
                    of Birth..!</div>
            </ng-container>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label>Date of Birth <span class="required">*</span></label><br />
                    <input type="date" name="max-date" [(ngModel)]="dateofbirthValid" [max]="mindateofdbirth"
                        class="form-control input-text" id="dob-date" placeholder="Date of Birth">
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="dobValidation?.hide()" id="kioskApp_dob-cancel">Cancel</button>

        <button class="btn btn-primary" style="margin-right: 15px;" type="button" (click)="statusUpdate(dobValidation)" id="kioskApp_checkin"
            [disabled]="!dateofbirthValid">
            <span *ngIf="this.checkInSumbit" class="spinner-border spinner-border-sm" role="status"></span>
            <span *ngIf="this.checkInSumbit" class="load-text"> Checking In...</span>
            <span *ngIf="!this.checkInSumbit" class="btn-text">Check-In</span>
        </button>
    </div>
</app-ui-modal>