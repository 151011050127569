<!-- Contact Search Popup -->
<app-ui-modal #contactSearchModal [hideHeader]="false" [modalCentered]="true" id="established-patient-search">
    <div class="app-modal-header">
        <h5 class="modal-title">Established Patient Search</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="contactSearchModal.hide();clearForm()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" [formGroup]="contactSearchForm">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isErrorNotify">
                <div class="text-danger" style="margin-bottom: 10px;">*{{notifyText}}</div>
                <!-- <button type="button" class="btn btn-primary reset-btn float-left" (click)="navigateNewAppointment()">New Appointment</button> -->
            </div>

            <div class="form-group col-lg-9 col-md-9 col-sm-9">
                <!-- <label>First Name <span class="required">*</span></label><br /> -->
                <input #searchElement type="text" id="navBarSearchPatient" [attr.minlength]="isValueNumber ? 10 : ''" 
                [attr.maxlength]=" isValueNumber && isNumber  ? 10: ''" 
                  class="nosubmit form-control input-text searchInput" formControlName="SEARCH_PATIENT_FN_LN_PN"
                  name="searchElement" placeholder=" Search by Name, Phone Number, DOB, or Patient ID" (keyup)="isValidDOB();"
                  (keyup.enter)="contactSearchCall(navigateNewAppointmentModal);" 
                  autocomplete="off">

                <span class="required"
                    *ngIf="contactSearchForm.get('SEARCH_PATIENT_FN_LN_PN').errors?.required && contactSearchForm.get('SEARCH_PATIENT_FN_LN_PN').touched">
                    Search by Name (First or Last Name), Full Name (Last Name, First Name), Phone Number (10-digits), Date of Birth (MM/DD/YYYY), or Patient ID
                 </span>

                <ng-container *ngIf="!isValueNumber">
                    <span class="required"
                        *ngIf="contactSearchForm.get('SEARCH_PATIENT_FN_LN_PN').invalid && !contactSearchForm.get('SEARCH_PATIENT_FN_LN_PN').errors?.required">
                        Search by Name (First or Last Name), Full Name (Last Name, First Name), Phone Number (10-digits), Date of Birth (MM/DD/YYYY), or Patient ID
                    </span>
                </ng-container>

                <ng-container *ngIf="validName">
                    <div class="required">
                      Enter a  valid Last Name,First Name or Last Name or Patient ID
                    </div>
                  </ng-container>

                <ng-container *ngIf="isValueNumber  && !isDateFormat">
                    <span class="required"
                        *ngIf="contactSearchForm.get('SEARCH_PATIENT_FN_LN_PN').invalid && !contactSearchForm.get('SEARCH_PATIENT_FN_LN_PN').errors?.required">
                        Please enter 10 digits for Phone Number search.
                    </span>
                </ng-container>

                <ng-container *ngIf="isDateFormat && notValidDob"> 
                    <div class="required">
                        Enter a Valid Date of Birth (mm/dd/yyyy)
                    </div>
                  </ng-container>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 search-btns">
                <!-- <button type="button" class="btn btn-primary reset-btn float-left"
                    (click)="resetSearchForm()">Reset</button> -->
                <button class="btn btn-primary event-btn" style="width: 100%;" type="button"
                    (click)="contactSearchCall(navigateNewAppointmentModal)" [disabled]="existingContactSearchClick">
                    <span *ngIf="this.existingContactSearchClick" class="spinner-border spinner-border-sm"
                        role="status"></span>
                    <span *ngIf="this.existingContactSearchClick" class="load-text"> Searching...</span>
                    <span *ngIf="!this.existingContactSearchClick" class="btn-text">Search</span>
                </button>
            </div>
        </div>

        <div class="row" *ngIf="contactSearchResults && contactSearchResults.length > 0">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="title existing-fb-actions">
                    <h5 style="float: left;padding-top: 6px;">Search Results</h5>
                    <span class="new-appointment icon" style="float: right;display: flex;" (click)="navigateNewAppointment(true);contactSearchModal.hide();clearForm()"><img src="../../../../../assets/images/flowboardIcons/farmSearch/ic_add_appointment.svg"/> &nbsp;Ad-Hoc Appointment</span>
                </div>
                <div class="error-user-info" *ngIf="noUerInfoDetails">
                    <div class="text-danger" style="margin-bottom: 10px;">*{{userInfoError}}</div>
                </div>

                <div class="table-responsive new-designed-table scrollable-table">
                    <table class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>DOB</th>
                                <th *ngIf="screenName === 'flowBoard'">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="screenName !== 'flowBoard' || screenName === 'newAppnt'">
                                <tr *ngFor="let contact of contactSearchResults" style="cursor: pointer;"
                                    (click)="selectedContact(contact, contactSearchModal, navigateNewAppointmentModal)">
                                    <td>{{contact?.lastName | titlecase}}, {{contact?.firstName |titlecase}}</td>
                                    <td>{{formatPrimaryPhone(contact?.patientPrimaryPhone)}}</td>
                                    <!-- <td>{{contact?.dob | date : 'MMM d, y'}}</td> -->
                                    <td>{{formatDob(contact?.dob) | date:'MM/dd/yyyy'}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="screenName === 'flowBoard'">
                                <tr *ngFor="let contact of contactSearchResults">
                                    <td>{{contact?.lastName | titlecase}}, {{contact?.firstName  | titlecase}} {{contact?.middleName | titlecase}}</td>
                                    <td>{{formatPrimaryPhone(contact?.patientPrimaryPhone)}}</td>
                                    <td>{{formatDob(contact?.dob) | date:'MM/dd/yyyy'}}</td>
                                    <td class="existing-fb-actions">
                                        <span class="new-appointment icon"
                                            (click)="modifyUserData(contact, contactSearchModal)"><img src="../../../../../assets/images/flowboardIcons/farmSearch/ic_farm.svg"/> Chart</span>
                                        <span class="new-appointment icon" style="margin-left: 20px;"
                                            (click)="newAppointment(contact);contactSearchModal.hide();clearForm()"><img src="../../../../../assets/images/flowboardIcons/farmSearch/ic_add_appointment.svg"/> New Appointment</span>
                                        <!-- <span class="history icon" style="margin-left: 20px;"
                                            (click)="$event.stopPropagation(); mapUserToGetUserInfo(contact, contactSearchModal)" placement="bottom"
                                            ngbTooltip="Appointment History"><i class="fa fa-history"></i></span> -->
                                        <!-- <span class="history icon" style="margin-left: 20px;"
                                            (click)="$event.stopPropagation(); checkAppointmentHistory(contact, contactSearchModal)"
                                            placement="bottom" ngbTooltip="Appointment History"><i
                                                class="fa fa-history"></i></span> -->

                                        <span class="sendsms icon" style="margin-left: 20px;"
                                            (click)="$event.stopPropagation(); sendNotification(contact, contactSearchModal)"
                                            placement="bottom" ngbTooltip="Send SMS"><i class="lab la-telegram"></i></span>

                                        <!-- <span class="sendsms icon" style="margin-left: 20px;"
                                            (click)="$event.stopPropagation(); mapUserToGetUserInfo(contact, contactSearchModal, navigateNewAppointmentModal)"
                                            placement="bottom" ngbTooltip="New Rx/Lab Order"><i
                                                class="fa fa-file-prescription"></i></span> -->

                                        <!-- <span class="history icon" style="margin-left: 20px;"
                                            (click)="$event.stopPropagation(); mapUserToGetUserInfo(contact, contactSearchModal)"
                                            placement="bottom" ngbTooltip="Upload Clinical Documents"><i
                                                class="fa fa-upload"></i></span> -->
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</app-ui-modal>

<!-- confirm to add new appointment with no established appointmnet Popup -->
<app-ui-modal #navigateNewAppointmentModal [hideHeader]="false" class="confirmCustExtPatSearch" id="navigateNewAppoint-search">
    <div class="app-modal-header">
        <h5 class="modal-title text-center"><i class="lar la-question-circle"></i></h5>
        <!-- <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="navigateNewAppointmentModal.hide()"><span aria-hidden="true">&times;</span></button> -->
    </div>
    <div class="app-modal-body">
        <h6 style="line-height: 30px;">{{notifyText}}</h6>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="navigateNewAppointmentModal.hide()">No</button>
        <button type="button" *ngIf="!isErrorNotify" class="btn btn-primary dash-actions" style="margin-right: 15px;"
            data-dismiss="modal"
            (click)="navigateNewAppointmentModal.hide();navigateNewAppointment(true);contactSearchModal?.hide()">Yes</button>
        <button type="button" *ngIf="isErrorNotify" class="btn btn-primary dash-actions" style="margin-right: 15px;"
            data-dismiss="modal"
            (click)="navigateNewAppointmentModal.hide();navigateNewAppointment();contactSearchModal?.hide()">Yes</button>
    </div>
</app-ui-modal>

<!-- Late Notify PopUp -->
<app-send-sms-notify [noDelay]="true" [pageType]="'Chart'"></app-send-sms-notify>