<div class="pro-head">
    <div class="row">
        <div class="col-5">
            <h5>Notifications</h5>
        </div>
		<div class="col-3">
            <div class="filter-notify ">
                <label>Filter</label>
                <div class="form-group pr-2 slect-doctor pl-width">
                    <ng-select placeholder="Choose Category" #categorySelect [items]="categoriesList" bindLabel="desc" bindValue="value"
                        (change)="filterNotifications($event.value)" [clearable]="false" [searchable]="false" [(ngModel)]="selectedCategory"
></ng-select>


                </div>
            </div>
        </div>
		
        <div class="col-3">
            <div class="switch-sec float-right">
                Unread
                <label class="switch">
                    <input type="checkbox" id="toggleChk" #toggleChk (change)="onlyShowUnRead($event)">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>

        <div class="col-1">
            <i class="las la-external-link-alt" *ngIf="checkNotificationsExpand" (click)="navigateNotifications(); notificationDropToggle.close();" style="font-size: 28px;cursor: pointer;"></i>
        </div>
    </div>
    <!--  <div class="row">
        <div class="col-12">
            <div class="filter-notify float-right">
			 
              <label>Filter</label>
                <div class="form-group pr-2 slect-doctor pl-width">
                    <ng-select placeholder="Choose Category" #categorySelect [items]="categoriesList" bindLabel="desc" bindValue="value"
                        (change)="filterNotifications($event.value)" [clearable]="false" [searchable]="false" [(ngModel)]="selectedCategory"
></ng-select>


                </div>
            </div>
           
        </div>

    </div>-->
</div>
<!-- Notifications Body -->
<ul class="pro-body notifications-body" style="margin-left: 0px; padding-left: 0px;">
    <div class="row all-notifications" style="padding: 0px 15px;">
        <div class="col-6 mb-2" *ngIf="todayNotifications?.length > 0">
            <label class="today-older">TODAY</label>
        </div>
        <!-- <div class="col-6 mb-2">
            <label *ngIf="todayNotifications?.length > 0 || olderNotifications?.length > 0" class="mark-all-as-read float-right">Mark all as read</label>
        </div> -->
        <ng-container *ngFor="let notifyItem of todayNotifications">
            <div class="col-10">
                <div class="notify-item mb-3" (click)="navigation(notifyItem);loadSpinner = false">
                    <h6><b *ngIf="notifyItem?.patientInfo?.lastName || notifyItem?.patientInfo?.firstName">
                        <span *ngIf="notifyItem?.patientInfo?.lastName">{{notifyItem?.patientInfo?.lastName}}</span>
                        <span  *ngIf="notifyItem?.patientInfo?.firstName">, {{notifyItem?.patientInfo?.firstName }}</span>
                        
                    </b> <span
                            *ngIf="notifyItem?.auditInfo?.createdDate"
                            class="timer">{{calculateNotificationTimer(notifyItem?.auditInfo?.createdDate)}}</span></h6>
                    <div class="item-info red"
                        [class.abnormalResultsRed]="notifyItem?.documentInfo?.type === 'Abnormal Lab Results'"
                        [class.labResultsRed]="notifyItem?.documentInfo?.type === 'Lab Results'">
                        {{notifyItem?.documentInfo?.type}}<span *ngIf="notifyItem?.documentInfo?.notes"> -
                            {{notifyItem?.documentInfo?.notes}}</span></div>
                    <div class="item-info sub">Assignee: {{((notifyItem?.notificationInfo?.responderRole === 'Doctor' ||
                        notifyItem?.notificationInfo?.responderRole === 'Clinician') &&
                        notifyItem?.notificationInfo?.responderName) ? 'Dr.' : ''}}
                        {{notifyItem?.notificationInfo?.responderName}}</div>
                </div>
            </div>
            <div class="col-2" *ngIf="notifyItem?.notificationInfo?.status">
                <div class="red-urread-btn" placement="bottom" ngbTooltip="Mark As Read"
                    (click)="updateNotificationsRead(notifyItem)"><span class="dot"></span></div>
            </div>
        </ng-container>

        <div class="col-12 mb-2" *ngIf="olderNotifications?.length > 0">
            <label class="today-older">OLDER</label>
        </div>
        <ng-container *ngFor="let notifyItem of olderNotifications">
            <div class="col-10">
                <div class="notify-item mb-3" (click)="navigation(notifyItem);loadSpinner = false">
                    <h6><b *ngIf="notifyItem?.patientInfo?.lastName || notifyItem?.patientInfo?.firstName">
                        
                        <span *ngIf="notifyItem?.patientInfo?.lastName">{{notifyItem?.patientInfo?.lastName}}</span>
                        <span  *ngIf="notifyItem?.patientInfo?.firstName">, {{notifyItem?.patientInfo?.firstName }}</span>
                        
                    </b> <span
                            *ngIf="notifyItem?.auditInfo?.createdDate"
                            class="timer">{{calculateNotificationTimer(notifyItem?.auditInfo?.createdDate)}}</span></h6>
                    <div class="item-info red"
                        [class.abnormalResultsRed]="notifyItem?.documentInfo?.type === 'Abnormal Lab Results'"
                        [class.labResultsRed]="notifyItem?.documentInfo?.type === 'Lab Results'">
                        {{notifyItem?.documentInfo?.type}}<span *ngIf="notifyItem?.documentInfo?.notes"> -
                            {{notifyItem?.documentInfo?.notes}}</span></div>

                    <!-- <div class="item-info" [class.labResultsRed]="notifyItem?.documentInfo?.type === 'Lab Results'">{{notifyItem?.documentInfo?.type}} - {{notifyItem?.documentInfo?.notes}}</div> {{((notifyItem?.notificationInfo?.responderRole === 'Doctor' || notifyItem?.notificationInfo?.responderRole === 'Clinician') && notifyItem?.notificationInfo?.responderName) ? notifyItem?.notificationInfo?.responderName : notifyItem?.notificationInfo?.responderRole}}-->
                    <div class="item-info sub">Assignee: {{((notifyItem?.notificationInfo?.responderRole === 'Doctor' ||
                        notifyItem?.notificationInfo?.responderRole === 'Clinician') &&
                        notifyItem?.notificationInfo?.responderName) ? 'Dr.' : ''}}
                        {{notifyItem?.notificationInfo?.responderName}}</div>
                </div>
            </div>
            <div class="col-2" *ngIf="notifyItem?.notificationInfo?.status">
                <div class="red-urread-btn" placement="bottom" ngbTooltip="Mark As Read"
                    (click)="updateNotificationsRead(notifyItem)"><span class="dot"></span></div>
            </div>
        </ng-container>
        <div class="col-12" *ngIf="todayNotifications?.length === 0 && olderNotifications?.length === 0">
            <label class="text-center">Notifications Not Found</label>
        </div>
    </div>
</ul>

<div class="custom-loader justify-content-center" *ngIf="loadSpinner">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>