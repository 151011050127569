<!-- <Ul class="nav-menu">
  <li>
    <a href="#">
      <img src="../../assets/images/Healthworks/menu-invoice.svg" >
      <span>Invoice Board</span>
    </a>
  </li>
  <li>
    <a href="#">
      <img src="../../assets/images/Healthworks/menu-charge.svg" >
    <span> Charge Master </span>
  </a>
  </li>
  <li>
    <a href="#">
      <img src="../../assets/images/Healthworks/menu-fax.svg" >
    <span>E-Fax</span>
  </a>
  </li>
  <li>
    <a href="#">
      <img src="../../assets/images/Healthworks/menu-network.svg" >
    <span> Network Management</span>
  </a>
  </li>
  <li>
    <a href="#">
      <img src="../../assets/images/Healthworks/menu-insights.svg" >
    <span>Insights</span>
  </a>
  </li>
  <li>
    <a href="#">
      <img src="../../assets/images/Healthworks/menu-msg.svg" >
    <span>Messages</span>
  </a>
  </li>
</Ul> -->

<!-- <div style="display: none;"> -->
<ng-container *ngIf="!item.hidden">
  <li class="nav-item pcoded-menu-caption"><label>{{item.title}}</label></li>
  <ng-container *ngFor="let item of item.children">
    <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
    <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
    <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
  </ng-container>
</ng-container>
<!-- </div> -->
