import { AfterViewInit, Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ScheduleFollowupAppointmentComponent } from '../schedule-followup-appointment/schedule-followup-appointment.component';
import { FollowupNotificationComponent } from '../followup-notification/followup-notification.component';
import { Router } from '@angular/router';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';

@Component({
  selector: 'app-caregaps-list',
  templateUrl: './caregaps-list.component.html',
  styleUrls: ['./caregaps-list.component.scss']
})
export class CaregapsListComponent implements OnInit {

  public caregapList = [];
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  loadSpinner: boolean;
  public notifyText: string;
  @ViewChild('caregapCard') caregapCard: CardComponent;
  @Input() queueCard: CardComponent;
  @ViewChild(DataTableDirective, { static: false })
  dtElementCaregap: DataTableDirective;
  public dtOptionsCaregap: DataTables.Settings = {};
  public dtTriggerCaregap: Subject<any> = new Subject<any>();
  public openFilters: boolean = false;
  public loginDetails: any;
  public purposeOfVisitList = [];
  public typeList = ["Wait List", "Follow Up"];
  public selectedAppointmentId: any;
  public counterInfo: any;
  @ViewChild(ScheduleFollowupAppointmentComponent) private scheduleFollowup: ScheduleFollowupAppointmentComponent;
  @ViewChild(FollowupNotificationComponent) private followupNotify: FollowupNotificationComponent;

  public openActions: boolean = false;
  public enableActions: boolean = false;
  public selectedActionRow: any;
  /*public chronicConditionsList = [
    { value: 'Hypertension', desc: 'Hypertension' },
    { value: 'Diabetes', desc: 'Diabetes' },
    { value: 'Heart Disease', desc: 'Heart Disease' },
    { value: 'Chronic Obstructive Pulmonary Disease (COPD)', desc: 'Chronic Obstructive Pulmonary Disease (COPD)' },
    { value: 'Asthma', desc: 'Asthma' },
    { value: 'Arthritis', desc: 'Arthritis' },
    { value: 'Chronic Kidney Disease (CKD)', desc: 'Chronic Kidney Disease (CKD)' },
    { value: 'Mental Health Conditions', desc: 'Mental Health Conditions' }
  ];*/

  public chronicConditionsList = ['Hypertension', 'Diabetes', 'Heart Disease',
    'Chronic Obstructive Pulmonary Disease (COPD)', 'Asthma', 'Arthritis', 'Chronic Kidney Disease (CKD)', 'Mental Health Conditions'];


  public masterPatientDetails: any;
  public totalEligiblePatientsCount: any;
  public selectedCaregap: any;
  public selectedConsent = null;
  public enrollPatientForm: FormGroup;
  public unenrollPatientForm: FormGroup;
  public conditionsForm: FormGroup;
  public isVerbalConsent: boolean;
  public isPhysicalConsent: boolean;
  public consentNotSelected: boolean;
  isSuccessNotify: boolean;
  public selectedConditions: string[] = [];

  @HostListener('document:click', ['$event'])
  clickInside(event) {

    const eventId = (event.target as HTMLInputElement).id;
    if (!this.enableActions && eventId !== 'claimActions_btn') {
      this.openActions = false;
    }
  }

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.getPurposeOfVisit(this.loginDetails && this.loginDetails.facilityId);

    this.enrollPatientForm = this.formBuilder?.group({
      'physicalConsent': [null],
      'verbalConsent': [null]
    });

    this.unenrollPatientForm = this.formBuilder?.group({
      'notQualified': [null],
      'reason': [null, Validators?.required]
    });

    this.conditionsForm = this.formBuilder?.group({
      'conditions': [null]
    });

    /*this.conditionsForm = this.formBuilder.group({
      'conditions': this.formBuilder.array([])
    });*/


  }

  ngOnInit(): void {

    this.dtOptionsCaregap = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      autoWidth: true,
      lengthMenu: [10, 25, 50, 75, 100],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };

  }
  ngAfterViewInit(): void {
    this.getCareGapsCounters();
    this.getCaregapList();
  }

  public rerenderCaregap(): void {
    this.dtElementCaregap?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }

  public openNotify(masterPatientId: any) {
    //console.log(masterPatientId);
    this.getMasterPatientDetails(masterPatientId);
    setTimeout(() => {
      this.sendNotifyComp?.showNotifyModal(this.masterPatientDetails);
    }, 1000);


  }

  public openSchedule(masterPatientId: any) {
    // console.log(masterPatientId);
    this.getMasterPatientDetails(masterPatientId);
    setTimeout(() => {
      const existingAppnt = this.masterPatientDetails;
      sessionStorage?.removeItem('selectedPatientFlowBoard');
      sessionStorage?.removeItem('establishedUserInfo');
      const establishedUserInfo = [];
      const userinfo = {
        "address": existingAppnt?.address,
        "age": existingAppnt?.age,
        "email": existingAppnt?.email,
        "dob": existingAppnt?.dob,
        "firstName": existingAppnt?.firstName,
        "gender": existingAppnt?.gender,
        "lastName": existingAppnt?.lastName,
        "patientPrimaryPhone": existingAppnt?.patientPrimaryPhone,
        "emergencyDetails": [],
        "ethnicity": existingAppnt?.ethnicity,
        "race": existingAppnt?.race,
        "idDetails": [],
        "insuranceDetails": [],
        "maritalStatus": existingAppnt?.maritalStatus,
        "middleName": existingAppnt?.middleName
      }
      establishedUserInfo.push(userinfo);
      // console.log(this.masterPatientDetails);
      sessionStorage.setItem('establishedUserInfo', JSON.stringify(establishedUserInfo[0]));
      this.router.navigate(['/hospital/addAppt']);
      // this.mapUserToGetUserInfo(existingAppnt);

    }, 1000);


  }


  public mapUserToGetUserInfo(existingAppnt: any): void {
    const action = 'userInfo/mapUser';
    const params = {
      "address": existingAppnt?.patientAddress,
      "dob": this.httpService?.formatDobTimeZone(existingAppnt?.patientDOB),
      "firstName": existingAppnt?.patientFirstName,
      "gender": existingAppnt?.patientGender,
      "lastName": existingAppnt?.patientLastName,
      "patientPrimaryPhone": existingAppnt?.patientPhoneNumber
    };
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        const userInfo = data?.body?.responseObject || data?.body?.userInfo || null;
        if (userInfo) {

          if (data?.body?.pastAppointmentHistory) {
            sessionStorage.setItem('establishedUserInfo', JSON.stringify(userInfo));
            this.router.navigate(['/hospital/addAppt']);

          } else {
            this.exceptionModal.notifyText = "No past Visits found for the selected Patient.";
            this.exceptionModal.modalShow();
          }

        } else {
          this.exceptionModal.notifyText = "No past Visits found for the selected Patient.";
          this.exceptionModal.modalShow();
        }
      }

    },
      (error) => {

      })
  }


  public openFilterSection() {
    this.openFilters = !this.openFilters;
  }

  public closeFiltersection() {
    this.openFilters = false;
  }
  public getPurposeOfVisit(facilityId: any): void {
    const action = `waitTime/`;
    this.httpService.makeGetRequest(action, facilityId).subscribe((data: any) => {

      this.purposeOfVisitList = (data && data.purposeOfVisit) || [];

    },
      error => {
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();
      })
  }


  public getCaregapList() {

    const caregapDto = {}
    const action = `careGaps/getMasterPatientCareGapsQualifiedList`;
    this.rerenderCaregap();
    this.queueCard?.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      // console.log(data);
      // console.log(data.responseObject?.careGapsQualifiedList);
      if (data?.status === 'SUCCESS') {
        this.totalEligiblePatientsCount = data?.responseObject?.careGapsQualifiedCount;
        this.caregapList = data?.responseObject?.careGapsQualifiedList || [];
        //  console.log(this.caregapList);
        /* setTimeout(() => {
           this.populateConditions();
           
         }, 1000);*/




      }
      else {
        this.caregapList = [];
      }
      // console.log(this.conditionsForm);
      this.dtTriggerCaregap?.next();
      this.queueCard?.cardRefreshHide();


    },
      error => {
        this.dtTriggerCaregap?.next();
        this.queueCard?.cardRefreshHide();
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();

      })

  }
  public displaycareGap(caregap: any) {
    if (caregap !== null) {

      const caregapData = [];
      caregap?.forEach((item: any) => {

        caregapData?.push(item.name);

      });
      const stringValue = caregapData.join(', ');
      return stringValue;

    }

  }
  public getCareGapsCounters() {

    const action = `counter/getCareGapsCounters`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      this.counterInfo = (data) || null;

    },
      error => {

      })
  }

  public getMasterPatientDetails(masterPatientId: any) {
    const action = `mpp/getMasterPatientPanelDetails?masterPatientPanelId=${masterPatientId}`;

    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {

      if (data?.status === 'SUCCESS') {
        this.masterPatientDetails = data?.responseObject || [];
        //console.log(this.masterPatientDetails);

      }
      else {
        this.masterPatientDetails = [];
      }


    },
      error => {


      })
  }

  public toggleConsent(event: any, controlName) {

    const consentValue = this.enrollPatientForm.get(controlName)?.value;
    if (controlName === 'verbalConsent') {
      if (consentValue == true) {
        this.isVerbalConsent = true;
        this.isPhysicalConsent = false;
        this.selectedConsent = 'Verbal';
      }
      else {
        this.isVerbalConsent = false;
        this.selectedConsent = '';
      }

    }
    else if (controlName === 'physicalConsent') {
      if (consentValue == true) {
        this.isPhysicalConsent = true;
        this.isVerbalConsent = false;
        this.selectedConsent = 'Physical';
      }
      else {
        this.isPhysicalConsent = false;
        this.selectedConsent = '';
      }
    }

    if (this.selectedConsent !== '' && this.selectedConsent !== null && this.selectedConsent !== undefined) {
      this.consentNotSelected = false;
    }
    // console.log('selectedConsent',this.selectedConsent);

  }
  public openEnrollPatient(enrollPatient: UiModalComponent, caregap: any) {
    this.selectedCaregap = caregap;
    enrollPatient?.show();

    /*this.getMasterPatientDetails(caregap.masterPatientId);
    setTimeout(() => {
      enrollPatient?.show();
    }, 1000);  */


  }
  public openUnenroll(unEnrollPatient: UiModalComponent, caregap: any) {
    this.unenrollPatientForm?.get('notQualified').setValue(true);
    this.selectedCaregap = caregap;
    unEnrollPatient?.show();

  }

  public enrollpatient(enrollPatient: UiModalComponent) {
    //console.log('selectedConsent', this.selectedConsent);

    /*if (this.selectedConsent === '' || this.selectedConsent === null || this.selectedConsent === undefined) {
      this.consentNotSelected = true;
      return;
    }*/

    const addAction = `careGaps/addCareProgram`;
    const addcareProgramDto = {
      "conditions": this.selectedCaregap.conditions,
      "consentType": this.selectedConsent,
      "name": this.selectedCaregap?.careGap,
      "notQualified": null,
      "patientEmrId": this.selectedCaregap.patientEmrId,
      "reason": null,
      "status": 'Paused',
      "userInfoId": this.selectedCaregap?.userInfoId
    };
    this.httpService?.makeRequestWithAction(addAction, addcareProgramDto)?.subscribe((data: any) => {

      if (data?.body?.status == 'SUCCESS') {
        if (this.selectedConsent !== '' && this.selectedConsent !== null && this.selectedConsent !== undefined) {
          const updateaction = `careGaps/updateCareProgram`;

          const updatecareProgramDto = {
            "conditions": this.selectedCaregap.conditions,
            "consentType": this.selectedConsent,
            "name": this.selectedCaregap?.careGap,
            "notQualified": null,
            "patientEmrId": this.selectedCaregap.patientEmrId,
            "reason": null,
            "status": 'Enrolled',
            "userInfoId": this.selectedCaregap?.userInfoId
          };

          //console.log('enroll', updatecareProgramDto);

          this.httpService?.makeRequestWithAction(updateaction, updatecareProgramDto)?.subscribe((data: any) => {

            if (data?.body?.status == 'SUCCESS') {
              this.isSuccessNotify = true;
              this.notifyText = data?.body?.msg || data?.body?.message;
              this.exceptionModal?.modalShow();
              enrollPatient?.hide();
              this.updateMasterPatient(this.selectedCaregap);
            }
          },
            (error) => {

            })
        }
        else {
          enrollPatient?.hide();
          this.updateMasterPatient(this.selectedCaregap);
        }


      }

    },
      (error) => {

      })




  }
  public updateMasterPatient(caregap) {
    const action = `careGaps/updateMasterPatientCaregaps`;
    const careProgramDto = {
      "conditions": caregap?.conditions,
      //"consentType": null,
      "name": caregap?.careGap,
      "patientEmrId": caregap?.patientEmrId,
      "userInfoId": caregap?.userInfoId
    };

    this.httpService?.makeRequestWithAction(action, careProgramDto)?.subscribe((data: any) => {

      if (data?.body?.status == 'SUCCESS') {
        this.getCaregapList();

       /* this.isSuccessNotify = true;
        this.notifyText = data?.body?.msg || data?.body?.message;
        this.exceptionModal?.modalShow();*/
      }

    },
      (error) => {

      })
  }

  public notqualifiyPatient(unEnrollPatient: UiModalComponent) {
    if (this.unenrollPatientForm?.invalid) {
      this.unenrollPatientForm?.markAllAsTouched();
      return;
    }
    //onst action = `careGaps/updateCareProgram`;
    const action = `careGaps/updateMasterPatientCaregaps`;
    const careProgramDto = {
      "conditions": this.selectedCaregap?.conditions,
      //"consentType": null,
      "name": this.selectedCaregap?.careGap,
      "notQualified": this.unenrollPatientForm?.get('notQualified')?.value,
      "patientEmrId": this.selectedCaregap.patientEmrId,
      "reason": this.unenrollPatientForm?.get('reason')?.value,
      //"status": 'Active',
      "userInfoId": this.selectedCaregap?.userInfoId
    };

    //console.log('enroll', careProgramDto);
    //return;

    this.httpService?.makeRequestWithAction(action, careProgramDto)?.subscribe((data: any) => {

      if (data?.body?.status == 'SUCCESS') {
        this.getCaregapList();
        unEnrollPatient?.hide();
        this.isSuccessNotify = true;
        this.notifyText = data?.body?.msg || data?.body?.message;
        this.exceptionModal?.modalShow();
      }

    },
      (error) => {

      })

  }

  public resetUnenrollForm() {
    this.unenrollPatientForm.reset();
  }

  public listConditions(event:any) {
    if(event !== null && event !== undefined && event !== ''){
      this.selectedConditions.push(event);
    }
    
  }

  public updateConditions(event: any, caregap: any) {
    // const conditions = this.conditionsForm?.get('conditions')?.value;
    //const conditions = event;
    if(this.selectedConditions.length === 0){
      return;
    }

    let conditions = null;
    if(this.selectedConditions.length > 1){
       conditions =  this.selectedConditions.slice(-1)[0];
    }
    else{
      conditions =  this.selectedConditions[0];
    }    
    
    //console.log(conditions);
    //console.log(caregap?.conditions);
    //console.log(event.target.value);

    const action = `careGaps/updateMasterPatientCaregaps`;
    const careProgramDto = {
      "conditions": conditions,
      //"consentType": this.selectedConsent,
      "name": caregap?.careGap,
      "notQualified": null,
      "patientEmrId": caregap.patientEmrId,
      "reason": null,
      "status": 'Active',
      "userInfoId": caregap.userInfoId
    };

    //console.log('conditions',careProgramDto);

    this.httpService?.makeRequestWithAction(action, careProgramDto)?.subscribe((data: any) => {

      if (data?.body?.status == 'SUCCESS') {
        this.selectedConditions = [];
        this.getCaregapList();
        this.isSuccessNotify = true;
        this.notifyText = data?.body?.msg || data?.body?.message;
        this.exceptionModal?.modalShow();
      }

    },
      (error) => {

      })

  }

  public populateConditions() {
    //console.log(this.caregapList);
    this.caregapList?.forEach((item: any, index: number) => {
      const conditionElement = document.getElementById('conditions_' + index) as HTMLElement;
      //console.log('conditionElement',conditionElement) ;
      conditionElement.setAttribute('value', item.conditions);

    });

    //const conditionElement = document.getElementById('conditions_'+id) as HTMLElement;
    //conditionElement.setAttribute('value', conditionList); 
  }

  public selectConditions(caregap) {
    const conditions = caregap?.conditions;
    return conditions;
  }

  public getConditionslist(conditions: any) {
    // console.log('conditions',conditions);
    return conditions.join(',');
  }


  public openUniversalActions(event: any, row) {
    if (this.selectedActionRow === row) {
      this.openActions = !this.openActions;
    }
    else {
      this.openActions = true;
    }

    this.selectedActionRow = row;

  }
  public closeUniversalActions() {

    this.openActions = false;
  }

  public mouseIn() {
    this.enableActions = true;

  }

  public mouseOut() {
    this.enableActions = false;

  }

}
