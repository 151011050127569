<app-ui-modal #reviewLabRecord [hideHeader]="false" [modalCentered]="true" id="reviewLabRecord">
    <div class="app-modal-header">
        <h5 class="modal-title text-center">Review Notes</h5>
    </div>
    <div class="app-modal-body" *ngIf="!confirmation">
        <div class="form-group" [formGroup]="progressNotesForm">
            <label for="notes">Review<span class="text-danger">*</span></label>
            <textarea class="form-control" rows="5" placeholder="Edit Review/Notes" id="documentReview_reviewNotes"
                formControlName="reviewNotes"></textarea>
            <span class="text-danger"
                *ngIf="progressNotesForm?.get('reviewNotes')?.errors?.required && progressNotesForm?.get('reviewNotes')?.touched">Review
                Comments
                is Required</span>
        </div>
    </div>
    <div class="app-modal-body" *ngIf="confirmation">
        <h5 class="modal-title text-center">{{notifyText}}</h5>
        <h5 class="modal-title text-center">Do you want to inform the Patient ?</h5>

    </div>
    <div class="app-modal-footer" *ngIf="confirmation"> <button type="button" class="btn btn-primary dash-actions"
            style="margin-right: 15px;" data-dismiss="modal" (click)="reviewLabRecord.hide()" id="documentReview_No">No</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="reviewLabRecord.hide();openTaskModal(userDetails);" id="documentReview_yes">Yes</button>
    </div>

    <div class="app-modal-footer" *ngIf="!confirmation">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="reviewLabRecord.hide()" id="documentReview_cancel">Cancel</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="updateJournalNotes(reviewLabRecord)" id="documentReview_submit">Submit</button>
    </div>
  
</app-ui-modal>


<!-- create task -->
<app-new-task [idType]="'appointmentId'"></app-new-task>