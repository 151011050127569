import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/theme/shared/services/apis.services';
import { ClaimsService } from 'src/app/theme/shared/services/claims.service';

@Component({
  selector: 'app-flow-board-cards',
  templateUrl: './flow-board-cards.component.html',
  styleUrls: ['./flow-board-cards.component.scss']
})
export class FlowBoardCardsComponent implements OnInit, AfterViewInit {

  @Input() facilityData: any;
  @Input() allCurrentDayAppointmentCounters: any;
  @Input() allErrorPhoneNumAppointments: any;
  public phoneCount: number;
  // @Input() isKiosk: any;
  @Input() isShowHideElements: any;

  public loginDetails: any;
  public rxOrderCount: any;
  public labOrderCount: any;
  futureAppointmnetsCount: any;
  paymentsCount: any;
  claimsCount: any;
  public paymentApprovalCount: any;
  isReadMore = false
  pendingSignoffCount: any;
  tasksCount: any;
  totalTaskCount: any;
  public counterInfo: any;
  allCardsLength: number;
  pendingCount: any;


  constructor(private httpService: HttpService, private router: Router, private apiService: ApisService, private claimsService: ClaimsService) { }

  ngOnInit(): void {
    this.loginDetails = JSON.parse(sessionStorage?.getItem('userDetails'));
    this.callAllCountersServices();
  }

  ngAfterViewInit(): void {
    const allClassesList = document?.getElementsByClassName('card-items') as HTMLCollection;
    this.allCardsLength = allClassesList?.length;
  }

  public callAllCountersServices(): void {
    this.getRxOrders();
    this.getLabOrders();
    this.futureAppointments();
    this.getPaymentsCounts();
    this.claims();
    this.getPaymentsForApproval();
    this.getDoctorPendingSignoffCount();
    this.getTotalTasksCount();
    this.getCareGapsCounters();
    this.getPendingAccount();
  }
  // get incorrect phone number counts
  // private incorrectPhoneCount(): void{
  //   const action = `appointment/registration/invalidPhoneCount`;
  //   this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
  //     if(data?.status === 'SUCCESS'){
  //       this.phoneCount = data?.responseObject || 0;
  //     }
  //   },
  //   (error) => {

  //   });
  // }

  private getRxOrders(): void {
    const action = `counter/totalRxOrders`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.rxOrderCount = data || null;
    },
      (error) => {
      });
  }

  private getLabOrders(): void {
    const action = `counter/totalLabOrders`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.labOrderCount = data || null;
    },
      (error) => {
      });
  }

  private futureAppointments(): void {
    const action = `counter/futureApptsCount`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.futureAppointmnetsCount = data || null;
    },
      (error) => {
      });
  }

  private getPaymentsCounts(): void {
    // const action = `counter/paymentCount`;
    this.apiService?.fbPaymentCounter()?.subscribe((data: any) => {
      this.paymentsCount = data?.paymentCount || null;
    },
      (error) => {
      });
  }

  private claims(): void {
    this.apiService?.getTotalClaimsCountFB()?.subscribe((data: any) => {
      this.claimsCount = data || null;
    },
      (error) => {
      });
  }

  private getPaymentsForApproval(): void {
    // const action = `payment/getPaymentsCountForApproval`;
    this.apiService?.getPaymentsCounts()?.subscribe((data: any) => {
      this.paymentApprovalCount = data || null;
    },
      (error) => {
      });
  }

  private getDoctorPendingSignoffCount(): void {
    const action = `counter/getPendingSignoffCount`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.pendingSignoffCount = data || null;
    },
      (error) => {
      });
  }

  public navigate(url: string, queryParams?: any): void {
    sessionStorage?.removeItem('navigateClaim');
    this.router?.navigate([url], {
      queryParams: queryParams || null
    });
  }

  public navigateFollowup(url: string, listName: any, queryParams?: any): void {
    sessionStorage?.setItem('followupStepindex', listName);
    this.router?.navigate([url], {
      queryParams: queryParams || null
    });
  }

  private getTotalTasksCount(): void {
    const action = `tasks/totalTasksCount`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.tasksCount = data || null;
      //this.totalTaskCount =  data.OpenTasks + data.InProgressTasks + data.HoldTasks ;
      this.totalTaskCount = data.TotalOpenTasks;

    },
      (error) => {
      });
  }

  public getCareGapsCounters() {
    const action = `counter/getCareGapsCounters`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      this.counterInfo = (data) || null;
    },
      error => {
      })
  }

  showCard() {
    this.isReadMore = !this.isReadMore
  }

  public getPendingAccount(): void {
    const action = `claim/getPendingBalanceCount?startDate=&endDate=`;
    this.claimsService?.makeGetRequest(action, '').subscribe((data: any) => {
      let pendingCount = (data.responseObject) || [];
      if (pendingCount && pendingCount?.length > 0 && pendingCount[0]?.patientsCount) {
        this.pendingCount = pendingCount[0]?.patientsCount
      }
    },
      error => {

      })
  }
}
