import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-lab-rad-results-modal',
  templateUrl: './lab-rad-results-modal.component.html',
  styleUrls: ['./lab-rad-results-modal.component.scss']
})
export class LabRadResultsModalComponent implements OnInit {

  @Input() selectedOrderDetails: any;
  @ViewChild('labRadResultsModal') public labRadResultsModal: UiModalComponent;
  loadSpinner: boolean;
  @Input() labRadOrdersCard: CardComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  getOrderReportsDetails: any;

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
  }

  // get all the reports available
  public getReportsAvailable(labOrder: any): void {
    this.selectedOrderDetails = labOrder;
    if (labOrder?.reportKey && labOrder?.reportKey?.length > 0) {
      this.getS3KeyIndividual(labOrder?.reportKey[0], this.labRadResultsModal);
      // reportsAvailableModal?.show();
    } else {
      this.notifyText = 'Document is not available';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
    }
  }

  // call list individividual API call to get s3 key
  public getS3KeyIndividual(reportKey?: any, reportsAvailableModal?: UiModalComponent): void {
    const action = `record/listIndividualDocument?appointmentId=${this.selectedOrderDetails?.appointmentId}&documentType=Lab Results&additionalKey=${reportKey}`;
    // const action = `changeHealth/getOrderinfobyOrder?location=${this.selectedOrderDetails?.location}&orderNumber=${this.selectedOrderDetails?.orderNumber}&testType=All4134520868`;
    this.labRadOrdersCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject && data?.responseObject?.s3Key) {
          this.downloadDocument(data?.responseObject);
          reportsAvailableModal?.show();
        } else {
          this.notifyText = 'Document is not available';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
      } else {
        this.notifyText = 'Document is not available';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.labRadOrdersCard?.cardRefreshHide();
    },
      (error) => {
        this.labRadOrdersCard?.cardRefreshHide();
      })
  }

  // Download Document with S3 key and S3 path
  public downloadDocument(s3Details: any): void {
    if (!s3Details?.s3Key || !s3Details?.s3Path) {
      return;
    }
    this.loadSpinner = true;
    const action = `record/downloadDocument?s3Key=${s3Details?.s3Key}&s3Path=${s3Details?.s3Path}`;
    this.httpService?.downloadImage(action)?.subscribe((data: any) => {
      if(!data) {
        this.loadSpinner = false;
        return;
      }
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('results-pdf-document') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      this.loadSpinner = false;
    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  // to get Internal Lab Reults 
  public getHtmlReport(labOrder: any, reportsAvailableModal: UiModalComponent): void {
    const action = `changeHealth/getOrderinfobyOrder?location=${labOrder?.location}&orderNumber=${labOrder?.orderNumber}&testType=LAB`;
    this.labRadOrdersCard?.cardRefreshShow();
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.getOrderReportsDetails = data?.responseObject;

        if (this.getOrderReportsDetails?.htmlReports && this.getOrderReportsDetails?.htmlReports?.length > 0) {
          this.openHtmlReport(this.getOrderReportsDetails?.htmlReports[0]);
          if (this.getOrderReportsDetails?.htmlReports?.length > 1) {
            reportsAvailableModal?.show();
          }
        } else {
          this.notifyText = 'HTML Report Document is not available.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
      }
      this.labRadOrdersCard?.cardRefreshHide();
      this.loadSpinner = false;
    },
      (error) => {
        this.labRadOrdersCard?.cardRefreshHide();
        this.loadSpinner = false;
      })
  }

  // open Html Report
  public openHtmlReport(htmlMessage: any): void {
    if (htmlMessage) {
      // window.open(labOrder?.htmlMessage);
      let w = window.open('about:blank');
      w.document.open();
      w.document.write(htmlMessage);
      w.document.close();
    }
  }

}
