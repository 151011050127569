import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { PaymentsCommonNewComponent } from '../payments-common-new/payments-common-new.component';
import { BillingCodesComponent } from '../billing-codes/billing-codes.component';
import { ApisService } from '../../services/apis.services';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { PaymentsRefactorComponent } from '../payments-refactor/payments-refactor.component';

@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss']
})
export class AccountBalanceComponent implements OnInit, OnChanges {

  @Input() appointmentId: string;
  public appointmentCostOfCare: FormGroup;
  public appointmentCostOfCareDetails: any;
  @Input() cardRefresh: CardComponent;
  notifyText: string;
  public isSuccessNotify: boolean;
  @Input() noPayOption: boolean;

  @ViewChild(PaymentsRefactorComponent) private payComp: PaymentsRefactorComponent;
  @ViewChild(ExceptionModalComponent) private exceptionalModal: ExceptionModalComponent;
  saveComplete: boolean;
  loginDetails: any;
  @Input() paymentConfigDetails: any;
  appointmentDetails: any;
  @Output() callPendingPayments = new EventEmitter<any>();
  @Output() paymentReloadAccBalance = new EventEmitter<any>();
  @ViewChild(BillingCodesComponent) billingcodesComp: BillingCodesComponent;
  errorMessageShown: string;
  successMessageShown: any;
  public writeOffReasonsList = [
    'Failure of Timely submission',
    'Pending Sign off',
    'Collection Failure'
  ];
  allClaimWriteoffCodes: any;
  @Input() noSaveButton: boolean;
  loadSpinner: boolean;
  @Input() patientID: string;
  @Input() isClaimPage: boolean;
  @Output() paymentCall = new EventEmitter<any>();
  @Input() isPostChargesPage: boolean;
  @Input() mainExceptionModal: ExceptionModalComponent;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private apiService: ApisService) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.appointmentCostOfCare = this.formBuilder?.group({
      "apptCostofCare": this.formBuilder?.array([]),
      "itemizedPayments": [null],
      "paymentStatus": [null],
      "payments": [null],
      'writeOffReason': [null, Validators?.required],
      'writeOffNotes': [null, Validators?.required],
    });
  }

  ngOnInit(): void {
    this.getPaymentConfig();
    this.getAppointmentCost();
    this.getAppointmentById();
    this.getClaimConfigCodes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.appointmentId) {
        for (let propName in changes) {
          if (propName === 'appointmentId') {
            let chng = changes[propName];
            const previous: any = chng?.previousValue;
            const current: any = chng?.currentValue;
            if (current !== previous) {
              this.getAppointmentById();
              this.getPaymentConfig();
              this.getAppointmentCost();
            }
          }
        }
        // this.appointmentCostOfCare?.get('appointmentId')?.setValue(this.appointmentId);
      }
    }
  }

  public getAppointmentById(): void {
    let action: any = `appointment/registration/getAppointmentById?id=${this.appointmentId}`;
    this.httpService.makeGetRequest(action, '')
      .subscribe((data: any) => {
        if (data?.status === 'SUCCESS') {
          this.appointmentDetails = data?.responseObject;
        }
      }, error => {

      });
  }

  // to get payment config details
  private getPaymentConfig(): any {
    if (this.paymentConfigDetails) {
      return;
    }
    const action = `providerConfiguration/getPaymentConfig?facilityId=${this.loginDetails?.facilityId}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentConfigDetails = data?.responseObject;
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      });
  }

  public addClaimsCharges(): void {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    let type: any;
    if (costofCareArray?.controls?.length === 1) {
      type = { claimProcessorType: 'Secondary' };
      const insudetailsCheck = this.appointmentDetails?.insuranceDetails && this.appointmentDetails?.insuranceDetails?.find((insuItem: any) => insuItem?.active && insuItem?.priority === 'Secondary');
      if (!insudetailsCheck) {
        this.notifyText = 'Secondary insurance details were not found for the appointment to add secondary claim processor.';
        this.isSuccessNotify = false;
        if (this.mainExceptionModal) {
          this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
        } else {
          this.exceptionalModal?.modalShow()
        }
        return;
      }
    }
    if (costofCareArray?.controls?.length === 2) {
      type = { claimProcessorType: 'Tertiary' };
      const insudetailsCheck = this.appointmentDetails?.insuranceDetails && this.appointmentDetails?.insuranceDetails?.find((insuItem: any) => insuItem?.active && insuItem?.priority === 'Tertiary');
      if (!insudetailsCheck) {
        this.notifyText = 'Tertiary insurance details were not found for the appointment to add tertiary claim processor.';
        this.isSuccessNotify = false;
        if (this.mainExceptionModal) {
          this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
        } else {
          this.exceptionalModal?.modalShow();
        }
        return;
      }
    }
    costofCareArray?.push(this.apptCostofCareGroup(type));
  }

  // Delete Claim App Cost of Care
  public deleteClaimAppCostOfCare(index: number): void {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    costofCareArray?.removeAt(index);
  }

  public totalAmountCount(): any {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    let sum: number;
    if (costofCareArray?.controls?.length > 0) {
      sum = costofCareArray?.value?.map(item => item?.patientResponsibility || 0)?.reduce((prev, curr) => prev + curr);;
    }
    return sum || 0;
  }

  public apptCostofCareGroup(constofCareItem?: any): FormGroup {
    const ajFacility = constofCareItem?.adjFacility && this.checkDecimalValues(constofCareItem?.adjFacility);
    return this.formBuilder?.group({
      // "appointmentId": [this.appointmentId || null],
      "approvedAmount": [(constofCareItem?.approvedAmount && this.checkDecimalValues(constofCareItem?.approvedAmount)) || null, Validators?.required],
      "claimId": [constofCareItem?.claimId || null, Validators?.required],
      "claimProcessorType": [constofCareItem?.claimProcessorType || 'Primary' || null, Validators?.required],
      "coPay": [(constofCareItem?.coPay && this.checkDecimalValues(constofCareItem?.coPay)) || null, Validators?.required],
      "coverageCost": [(constofCareItem?.coverageCost && this.checkDecimalValues(constofCareItem?.coverageCost)) || null, Validators?.required],
      "credits": [(constofCareItem?.credits && this.checkDecimalValues(constofCareItem?.credits)) || null, Validators?.required],
      "creditsUsed": [(constofCareItem?.creditsUsed || false) || null],
      "discount": [(constofCareItem?.discount && this.checkDecimalValues(constofCareItem?.discount)) || null, Validators?.required],
      "nonPayment": [(constofCareItem?.nonPayment && this.checkDecimalValues(constofCareItem?.nonPayment)) || null, Validators?.required],
      "patientId": [constofCareItem?.patientId || null, Validators?.required],
      "patientResponsibility": [(constofCareItem?.patientResponsibility && this.checkDecimalValues(constofCareItem?.patientResponsibility)) || null, Validators?.required],
      "reimburseAmount": [(constofCareItem?.reimburseAmount && this.checkDecimalValues(constofCareItem?.reimburseAmount)) || null, Validators?.required],
      "totalPatientResponsibility": [(constofCareItem?.totalPatientResponsibility && this.checkDecimalValues(constofCareItem?.totalPatientResponsibility)) || null, Validators?.required],
      "totalVisitCharges": [(constofCareItem?.totalVisitCharges && this.checkDecimalValues(constofCareItem?.totalVisitCharges)) || null, Validators?.required],
      "insuranceName": [(constofCareItem?.insuranceName !== "" && constofCareItem?.insuranceName !== null ? constofCareItem?.insuranceName : null) || null],
      "writeOff": [(constofCareItem?.writeOff && this.checkDecimalValues(constofCareItem?.writeOff)) || null],
      "currentCredits": [(constofCareItem?.currentCredits && this.checkDecimalValues(constofCareItem?.currentCredits)) || null],
      "refund": [(constofCareItem?.refund && this.checkDecimalValues(constofCareItem?.refund)) || null],
      // "adjReceivable": [(constofCareItem?.adjReceivable && this.checkDecimalValues(constofCareItem?.adjReceivable)) || null],
      // "adjPayable": [(constofCareItem?.adjPayable && this.checkDecimalValues(constofCareItem?.adjPayable)) || null],
      "adjFacility": [ajFacility > 0 ? ajFacility : ajFacility * -1 || null],
      "adjFacilityRadio": [(constofCareItem?.adjFacility ? (constofCareItem?.adjFacility < 0 ? 'minus' : 'plus') : null) || null]
    });
  }

  private checkDecimalValues(value: any): number {
    if (value && value % 1 != 0) {
      value?.toFixed(2)
    }
    return value;
  }

  // get appointment cost of care
  public getAppointmentCost(): void {
    const action = `appointment/registration/retrieveApptCostofCare?appointmentId=${this.appointmentId}`;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.appointmentCostOfCareDetails = data?.body?.responseObject?.apptCostofCare || [];

        const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
        this.httpService?.clearFormArray(costofCareArray);

        this.appointmentCostOfCareDetails?.forEach((item: any) => {
          // if (item?.patientResponsibility === 0 && item?.totalVisitCharges > 0) {
          //   const patientResponsibilityAmount = (item?.totalVisitCharges || 0) - ((item?.reimburseAmount || 0) + (item?.coPay || 0) + (item?.discount || 0) + (item?.nonPayment || 0));
          //   item.patientResponsibility = patientResponsibilityAmount;
          // }

          // if (item?.approvedAmount > 0) {
          //   if (item?.totalVisitCharges > 0) {
          //     item.discount = item?.approvedAmount - item?.totalVisitCharges;
          //     item.discount = item?.discount < 0 ? item?.discount * -1 : item?.discount;
          //   }
          //   const servicesChanges = data?.body?.responseObject?.itemizedPayments?.filter(serItem => serItem?.serviceType !== 'Co-Pay' && serItem?.serviceType !== 'Past Visit Charges') || [];
          //   let itemizedPayment: number = 0;
          //   servicesChanges?.forEach((amountItem: any) => {
          //     itemizedPayment = itemizedPayment + amountItem?.amount;
          //   });
          //   const prCalc = (item?.approvedAmount + itemizedPayment) - item?.reimburseAmount - item?.coPay;
          //   if (prCalc > 0) {
          //     item.patientResponsibility = prCalc;
          //   } else if (prCalc < 0) {
          //     item.credits = prCalc * -1;
          //   }
          // }
          if (item?.claimProcessorType === "Primary") {
            this.appointmentCostOfCare?.get('writeOffReason')?.setValue(item?.writeOffReason);
            this.appointmentCostOfCare?.get('writeOffNotes')?.setValue(item?.notes);
          }
          costofCareArray?.push(this.apptCostofCareGroup(item));
        });

        if (!this.appointmentCostOfCareDetails || this.appointmentCostOfCareDetails?.length === 0) {
          costofCareArray?.push(this.apptCostofCareGroup());
        }

        // this.appointmentCostOfCare?.patchValue(data?.body?.responseObject);
        this.appointmentCostOfCare?.get('itemizedPayments')?.setValue(data?.body?.responseObject?.itemizedPayments);
        this.appointmentCostOfCare?.get('paymentStatus')?.setValue(data?.body?.responseObject?.paymentStatus);
        this.appointmentCostOfCare?.get('payments')?.setValue(data?.body?.responseObject?.payments);
      }
      this.loadSpinner = false;
    }, (error) => {
      this.loadSpinner = false;
    });
  }

  // change paymeCore
  public changeFormPaymentChanges(): void {
    this.appointmentCostOfCare?.get('reimburseAmount')?.valueChanges?.subscribe(value => {
      // if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
      //   const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((value || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (this.appointmentCostOfCare?.get('discount')?.value || 0) + (this.appointmentCostOfCare?.get('nonPayment')?.value || 0));
      //   this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      // }
    });
    this.appointmentCostOfCare?.get('discount')?.valueChanges?.subscribe(value => {

    });
    this.appointmentCostOfCare?.get('nonPayment')?.valueChanges?.subscribe(value => {

    });
  }

  // If Reimbursed Amount value changes
  public reimburseAmountChanges(claimFormGroup?: FormGroup): void {
    const value = claimFormGroup?.get('reimburseAmount')?.value;
    if (claimFormGroup?.get('patientResponsibility')?.value === 0 && claimFormGroup?.value?.totalVisitCharges > 0) {
      const patientResponsibilityAmount = (claimFormGroup?.value?.totalVisitCharges || 0) - ((value || 0) + (claimFormGroup?.value?.coPay || 0) + (claimFormGroup?.get('discount')?.value || 0) + (claimFormGroup?.get('nonPayment')?.value || 0));
      claimFormGroup?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
    }
  }

  // If Reimbursed Amount value changes
  public discountAmountChanges(claimFormGroup?: FormGroup): void {
    const value = claimFormGroup?.get('discount')?.value;
    if (claimFormGroup?.get('patientResponsibility')?.value === 0 && claimFormGroup?.value?.totalVisitCharges > 0) {
      const patientResponsibilityAmount = (claimFormGroup?.value?.totalVisitCharges || 0) - ((claimFormGroup?.get('reimburseAmount')?.value || 0) + (claimFormGroup?.value?.coPay || 0) + (value || 0) + (claimFormGroup?.get('nonPayment')?.value || 0));
      claimFormGroup?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
    }
  }

  // If Non Payment value Changes
  public changeNonPayment(claimFormGroup?: FormGroup): void {
    const value = claimFormGroup?.get('nonPayment')?.value;
    if (claimFormGroup?.get('patientResponsibility')?.value === 0 && claimFormGroup?.value?.totalVisitCharges > 0) {
      const patientResponsibilityAmount = (claimFormGroup?.value?.totalVisitCharges || 0) - ((claimFormGroup?.get('reimburseAmount')?.value || 0) + ((claimFormGroup?.value?.coPay || 0) || 0) + (claimFormGroup?.get('discount')?.value || 0) + (value || 0));
      claimFormGroup?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
    }
  }

  // If Approved Amount Change
  public approvedReimburseAmountChange(claimFormGroup?: FormGroup): void {
    if (claimFormGroup?.value?.approvedAmount > 0) {

      if (claimFormGroup?.get('totalVisitCharges')?.value > 0) {
        const value = claimFormGroup?.get('approvedAmount')?.value - claimFormGroup?.get('totalVisitCharges')?.value;
        claimFormGroup?.get('discount')?.setValue(value < 0 ? value * -1 : value);
      }

      const servicesChanges = this.appointmentCostOfCare?.value?.itemizedPayments?.filter(serItem => serItem?.serviceType !== 'Co-Pay') || [];
      let itemizedPayment: number = 0;
      servicesChanges?.forEach((amountItem: any) => {
        itemizedPayment = itemizedPayment + amountItem?.amount;
      });
      const prCalc = (claimFormGroup?.get('approvedAmount')?.value + itemizedPayment) - claimFormGroup?.get('reimburseAmount')?.value - claimFormGroup?.get('coPay')?.value;
      if (prCalc >= 0) {
        claimFormGroup?.get('patientResponsibility')?.setValue(prCalc);
      } else if (prCalc < 0) {
        claimFormGroup?.get('credits')?.setValue(prCalc * -1);
      }
    }
  }

  // submit the pay of const of care for appointment
  public submitPayCost(checkEobDocs?: any[]): void {
    const action = `appointment/registration/saveApptCostofCare`;
    const params = {
      "appointmentId": this.appointmentId,
      "listApptCostofCares": this.appointmentCostOfCare?.get('apptCostofCare')?.value || []
    }

    params?.listApptCostofCares?.forEach((wItem: any) => {
      if (wItem?.claimProcessorType === "Primary") {
        wItem.writeOffReason = this.appointmentCostOfCare?.get('writeOffReason')?.value;
        wItem.notes = this.appointmentCostOfCare?.get('writeOffNotes')?.value;
      }
      if (wItem?.adjFacilityRadio) {
        wItem.adjFacility = wItem?.adjFacilityRadio === 'minus' ? wItem?.adjFacility * -1 : wItem?.adjFacility;
      }
    });

    this.cardRefresh?.cardRefreshShow();
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      this.cardRefresh?.cardRefreshHide();
      if (data?.body?.status === 'SUCCESS') {
        if (checkEobDocs && checkEobDocs?.length > 0) {
          this.notifyText = 'Payment details have been updated successfully.';
        } else {
          this.notifyText = 'Payment details have been updated successfully. If the EOB document is available, please upload.';
        }
        this.isSuccessNotify = true;
        if (this.mainExceptionModal) {
          this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
        } else {
          this.exceptionalModal?.modalShow();
        }
        this.saveComplete = false;
        this.getAppointmentCost();
        this.callPendingPayments?.emit(true);
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        if (this.mainExceptionModal) {
          this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
        } else {
          this.exceptionalModal?.modalShow();
        }
      }
      this.loadSpinner = false;
    }, (error) => {
      this.loadSpinner = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      if (this.mainExceptionModal) {
        this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
      } else {
        this.exceptionalModal?.modalShow();
      }
      this.cardRefresh?.cardRefreshHide();
    });
  }

  public responseChange(change?: any) {
    this.saveComplete = true;
  }

  // open card payment 
  public async openPayment() {
    if (this.saveComplete) {
      this.notifyText = 'Please Save befor payment';
      this.isSuccessNotify = false;
      if (this.mainExceptionModal) {
        this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
      } else {
        this.exceptionalModal?.modalShow();
      }
      return;
    }
    this.cardRefresh?.cardRefreshShow();
    let appointmentInfoDetails: any
    try {
      const action = `appointment/registration/getAppointmentById?id=${this.appointmentId}`;
      appointmentInfoDetails = await this.httpService?.makeGetRequest(action, '')?.toPromise();
    } catch (error) {
      this.cardRefresh?.cardRefreshHide();
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      if (this.mainExceptionModal) {
        this.mainExceptionModal?.modalShow(this.notifyText, this.isSuccessNotify);
      } else {
        this.exceptionalModal?.modalShow();
      }
      return;
    }
    this.cardRefresh?.cardRefreshHide();
    if (this.isPostChargesPage || this.isClaimPage) {
      this.paymentCall?.emit(appointmentInfoDetails?.responseObject);
    } else {
      this.payComp?.openModal(appointmentInfoDetails?.responseObject, true);
    }
    // this.payComp?.enableCardPay();
  }

  // Calculate by Column
  public calculateTotalAccountBal(controlsName: string): any {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    let sum: number;
    if (costofCareArray?.controls?.length > 0 && controlsName !== 'adjFacility') {
      sum = costofCareArray?.value?.map(item => item[controlsName] || 0)?.reduce((prev, curr) => prev + curr);
    }
    if (costofCareArray?.controls?.length > 0 && controlsName === 'adjFacility') {
      sum = costofCareArray?.value?.map(item => (item?.adjFacilityRadio === 'minus' ? item?.adjFacility * -1 : item?.adjFacility) || 0)?.reduce((prev, curr) => prev + curr);
    }
    return sum || 0;
  }

  public loadPaymentInfo() {
    this.paymentReloadAccBalance?.emit(true);
    this.getAppointmentCost();
    this.billingcodesComp?.getBillingCodesList();
  }

  // check Write off value should be less than or equal to Patient Responsibilty
  public checkwithArPatient(costofCareItem?: FormGroup): void {
    if (costofCareItem?.get('patientResponsibility')?.value < costofCareItem?.get('writeOff')?.value) {
      costofCareItem?.get('writeOff')?.setValue(null);
      this.erroMsg('Write Off value should not be greater than AR Patient.');
    }
  }

  // Check if any enetered value should not be great than the TotalVisit Charges
  public checkTotalCharges(formGroup: FormGroup, controlName: string): void {
    if (!formGroup?.get('totalVisitCharges')?.value) {
      return;
    }
    if (formGroup?.get('totalVisitCharges')?.value < formGroup?.get(controlName)?.value) {
      formGroup?.get(controlName)?.setValue(null);
      this.erroMsg('The entered value must not exceed the Total Visit Charges.');
    }
  }

  public erroMsg(msg): void {
    this.errorMessageShown = msg;
    setTimeout(() => {
      this.errorMessageShown = null;
    }, 3000);
  }

  public successMsg(msg): void {
    this.successMessageShown = msg;
    setTimeout(() => {
      this.successMessageShown = null;
    }, 3000);
  }

  // Calculate by Column
  public calculateTotalWriteOff(): boolean {
    const costofCareArray = this.appointmentCostOfCare?.get('apptCostofCare') as FormArray;
    let sum: number;
    if (costofCareArray?.controls?.length > 0) {
      sum = costofCareArray?.value?.map(item => item?.writeOff || 0)?.reduce((prev, curr) => prev + curr);
    }
    return sum > 0 ? true : false;
  }

  // get claim config code
  public getClaimConfigCodes(): void {
    this.apiService?.getBillingClaimCofigCodes('Claim Status Reason')?.subscribe((data?: any) => {
      if (data?.status === 'SUCCESS') {
        this.allClaimWriteoffCodes = data?.responseObject || [];
      }
    }, error => {

    });
  }

  // customize the Appointment Date time
  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

  public checkradioSelection(eleId: string, accountBalance: FormGroup, optValue: any): void {
    const selectedValue = accountBalance?.get('adjFacilityRadio')?.value;
    const element = document?.getElementById(`${optValue}-${eleId}`) as HTMLInputElement;
    if (optValue === selectedValue) {
      element.checked = false;
      accountBalance?.get('adjFacilityRadio')?.reset();
      accountBalance?.get('adjFacility')?.reset();
    }
  }

}
