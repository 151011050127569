import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from '../../services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { ApisService } from '../../services/apis.services';
import { ClaimsService } from '../../services/claims.service';


@Component({
  selector: 'app-move-claims-queue',
  templateUrl: './move-claims-queue.component.html',
  styleUrls: ['./move-claims-queue.component.scss']
})
export class MoveClaimsQueueComponent implements OnInit {

  @ViewChild('claimsMoveToOtherQueues') claimsMoveToOtherQueues: UiModalComponent;
  public allMoveQueues = AppConstantsListConfig?.allClaimsQueues;
  public allMoveToQueues = AppConstantsListConfig?.allClaimsQueues;
  public selectedClaimDetails: any;
  @Input() currentClaimQueue: any
  public moveClaimForm: FormGroup;
  public loadSpinner: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalComp: ExceptionModalComponent;
  public notifyText: string;
  @Output() onClaimMove = new EventEmitter();
  allClaimStatusCodes: any;
  isSuccessNotify: boolean;
  public loginDetails: any;
  public claimAssigneeList: any[] = [];
  public assigneePriorityList = [
    { desc: 'Low' },
    { desc: 'Medium' },
    { desc: 'High' }

  ]
  public isArCall: boolean;
  public selectedAsigneeUsername: any;
  public selectedAsigneeUserId: any;

  constructor(private httpService: HttpService, private _fb: FormBuilder, private apiService: ApisService, private claimsService: ClaimsService) {
    this.moveClaimForm = _fb?.group({
      'fromStatus': [null, Validators?.required],
      'toStatus': [null, Validators?.required],
      'notes': [null, Validators?.required],
      //'reason': [null],
      'userId': [null],
      'priority': [null]
    });
  }

  ngOnInit(): void {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.getClaimConfigCodes();
    this.getClaimAssigneeList();
  }

  public openClaimsQueueMove(claimDetails: any): void {
    //this.allMoveQueues = AppConstantsListConfig?.allClaimsQueues;
    //this.allMoveQueues = this.allMoveQueues?.filter(item => item?.name === 'AR Calls') || [];

    this.allMoveToQueues = AppConstantsListConfig?.allClaimsQueues;
    
    this.selectedClaimDetails = claimDetails;
    this.claimsMoveToOtherQueues?.show();
    this.moveClaimForm?.get('fromStatus')?.setValue(this.currentClaimQueue?.name === 'Advanced Search' ? (this.selectedClaimDetails?.status || this.currentClaimQueue?.apiStatus) : this.currentClaimQueue?.apiStatus);
    // this.allMoveToQueues = this.allMoveToQueues?.filter(item => item?.name !== 'AR Calls') || [];

    if (this.currentClaimQueue?.name === 'AR Patient' || this.currentClaimQueue?.name === 'AR Insurance' || this.currentClaimQueue?.name === 'Pending EOB/ERA' || this.currentClaimQueue?.name === 'Closed' || this.currentClaimQueue?.name === 'Collection') {
      this.allMoveToQueues = this.allMoveToQueues?.filter(item => item?.name !== 'AR Calls') || [];
    }


    if (this.currentClaimQueue?.name === 'AR Patient') {
      this.allMoveToQueues = this.allMoveToQueues?.filter(item => item?.name !== 'Pending Sign off' && item?.name !== 'Ready for Submission' && item?.name !== 'Self-Pay' && item?.name !== 'Advanced Search' && item?.name !== this.currentClaimQueue?.name) || [];
      
    }
    else if (this.currentClaimQueue?.name === 'Collection') {
      this.allMoveToQueues = this.allMoveToQueues?.filter(item => item?.name === 'Closed') || [];

    }
    else if (this.currentClaimQueue?.name === 'AR Calls') {
      this.allMoveToQueues = this.allMoveToQueues?.filter(item => item?.name !== 'Collection' && item?.name !== 'Pending Sign off' && item?.name !== 'Ready for Submission' && item?.name !== 'Self-Pay' && item?.name !== 'Advanced Search') || [];
    
    }
    else {
      this.allMoveToQueues = this.allMoveToQueues?.filter(item => item?.name !== 'Collection' && item?.name !== 'Pending Sign off' && item?.name !== 'Ready for Submission' && item?.name !== 'Self-Pay' && item?.name !== 'Advanced Search' && item?.name !== this.currentClaimQueue?.name) || [];
      
    }

    if (this.currentClaimQueue?.name === 'Advanced Search') {
      const selectedClaimStatus = this.selectedClaimDetails?.status || this.currentClaimQueue?.apiStatus;
      this.allMoveToQueues = this.allMoveToQueues?.filter((fItem: any) => selectedClaimStatus !== fItem?.apiStatus);
    }
    
  }

  public getClaimAssigneeList() {

    const action = `claim/getClaimAssigneeList`;
    this.claimsService?.makeGetRequest(action, '').subscribe((data: any) => {
      this.claimAssigneeList = (data.responseObject) || [];

    },
      error => {

      })
  }
  public getAssigneeUserName(event) {
    //console.log(event);
    const userId = event.userId;
    const userName = event.userName;
    this.selectedAsigneeUsername = event.userName;
    this.selectedAsigneeUserId = event.userId;
    //this.selectedAsigneeUsername   = userName.replace(/,/, '');

  }

  public checkIfArCall(event) {
   // console.log(event);
    const apiStatus = event.apiStatus;
    if (apiStatus === 'AR Call') {
      this.isArCall = true;
    //  this.moveClaimForm?.get('reason')?.setValidators([Validators?.required]);
      this.moveClaimForm?.get('userId')?.setValidators([Validators?.required]);

    }
    else {
      this.isArCall = false;
     // this.moveClaimForm?.get('reason')?.setValidators(null);
      this.moveClaimForm?.get('userId')?.setValidators(null);
    }

  //  this.moveClaimForm?.get('reason')?.updateValueAndValidity();
    this.moveClaimForm?.get('userId')?.updateValueAndValidity();
  }

  // Move Claim from one queue to another queue - api call
  public claimMove(claimsMoveToOtherQueues?: UiModalComponent): void {
    if (this.moveClaimForm?.invalid) {
      this.moveClaimForm?.markAllAsTouched();
      return;
    }
    const priority = this.moveClaimForm?.value?.priority;
    //const reason = this.moveClaimForm?.value?.reason;
    const reason = null; //removed reason from claim move
    const claimuserId = this.selectedAsigneeUserId;
    const claimuserName = this.selectedAsigneeUsername;
    const action = `claim/updateClaimStatus`;
    const request = {
      // "appointmentId": this.selectedClaimDetails?.appointmentId,
      "fromStatus": this.moveClaimForm?.value?.fromStatus,
      "id": this.selectedClaimDetails?.id || null,
      "notes": this.moveClaimForm?.value?.notes,
      "toStatus": this.moveClaimForm?.value?.toStatus,
      "userId": this.loginDetails.userId,
     // "reason": this.moveClaimForm?.value?.reason,
     "reason": null,
      "claimAssignment": { "priority": priority, "reason": reason, "userId": claimuserId, "userName": claimuserName }
    };
    this.loadSpinner = true;
    this.claimsService?.makePutRequest(action, request)?.subscribe((data: any) => {
      claimsMoveToOtherQueues?.hide();
      this.isSuccessNotify = true;
      this.notifyText = data?.msg || data?.message;
      this.exceptionalComp?.modalShow();
      this.loadSpinner = false;
      this.moveClaimForm?.reset();
      this.onClaimMove?.emit(true);
      this.resetIsArCall();
    }, error => {
      claimsMoveToOtherQueues?.hide();
      this.loadSpinner = false;
      this.isSuccessNotify = false;
      this.notifyText = error?.msg || error?.message;
      this.exceptionalComp?.modalShow();
      this.moveClaimForm?.reset();
    });
  }

  // get claim config code
  public getClaimConfigCodes(): void {
    this.apiService?.getBillingClaimCofigCodes('Claim Status Reason')?.subscribe((data?: any) => {
      if (data?.status === 'SUCCESS') {
        this.allClaimStatusCodes = data?.responseObject || [];
      }
    }, error => {

    });
  }

  public resetIsArCall() {
    this.isArCall = false;
   // this.moveClaimForm?.get('reason')?.setValidators(null);
    this.moveClaimForm?.get('userId')?.setValidators(null);
    this.moveClaimForm?.get('reason')?.updateValueAndValidity();
  }

}
