<!-- Clain Post Charges modal  -->
<app-ui-modal #claimPostChargesModal [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="claimPostChargesModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Visit Charges Summary</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="checkGuarding(claimPostChargesModal, postChargesUpdateGuardingModal)"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <ng-container *ngIf="claimAppointmentId">
            <app-account-balance-new [appointmentId]="claimAppointmentId" [cardRefresh]="claimsStatusCard"
                [noPayOption]="noPayOption" [noSaveButton]="noSaveButton" [patientID]="patientID"
                [isClaimPage]="isClaimPage" [paymentConfigDetails]="paymentConfigDetails"
                (paymentCall)="openPayment($event)" (callPendingPayments)="loadPaymentInfo()" [isPostChargesPage]="true"
                [mainExceptionModal]="mainExceptionModal" [accountBalancePaymentStatus]="accountBalancePaymentStatus"
                [postChargesUserDetails]="postChargesUserDetails"
                (claimNumberEmit)="setClaimNumber($event)" (updateCostOfCareItems)="updateAllCostOfCares($event)" (openEobModal)="openEobCheckModal()" (eobDocuments)="allEobDocuments = $event"></app-account-balance-new>
        </ng-container>
    </div>
    <div class="app-modal-footer">
        <div class="verify-stick-menu1">
            <a href="javascript:;" class="verify-prev">
                <span (click)="callNextPrev('prev')">
                    &lt; &nbsp; Previous
                </span>
            </a>
            <ul class="stick-menu-list">
                <li class="menu-select" *ngIf="showUploadEobDetails">
                    <a class="btn btn-primary" (click)="openEobCheckModal();" id="upload-eob">
                        <i class="las la-file-upload"></i> Edit EOB
                    </a>
                </li>
                <!-- <li class="menu-select">
                    <a class="btn btn-primary" (click)="viewDonloadEobDocu('download', viewDownloadEobEncounterDoc)">
                        <i class="las la-file-download"></i> Download
                    </a>
                </li> -->
                <!-- <li class="menu-select" *ngIf="allEobDocuments.length !== 0">
                    <a class="btn btn-primary" (click)="viewDonloadEobDocu('view', viewDownloadEobEncounterDoc)"
                        id="view-eob">
                        <i class="las la-eye"></i> View EOB
                    </a>
                </li>
                <li class="menu-select" *ngIf="allEobDocuments.length === 0">
                    <a class="btn btn-primary" (click)="downloadERAPDF(viewEobPostCharge)" id="view-eob2">
                        <i class="las la-eye"></i> View EOB
                    </a>
                </li> -->
                <!-- <i class="las la-check-double"></i> -->
                <!-- <li class="menu-select">
                    <a class="btn btn-primary" (click)="openCheckDetailsModal()" data-dismiss="modal"
                        id="postChargesUpdate_cancel"><i class="las la-money-check-alt"></i> EOB Check
                    </a>
                </li> -->
                <li class="menu-select">
                    <a class="btn btn-primary" (click)="callSaveApptCostofCare();callSaveServiceLineCharges()"
                        id="update">
                        <i class="las la-save">
                        </i> Update
                    </a>
                </li>
                <li class="menu-select">
                    <a class="btn btn-primary" (click)="openAccBalancePayment()" id="postChargesUpdate_pay">
                        <i class="las la-credit-card"></i> Pay
                    </a>
                </li>
                <li class="menu-select">
                    <a class="btn btn-primary" (click)="claimPostChargesModal?.hide();resetAppntCostCare()"
                        data-dismiss="modal" id="postChargesUpdate_cancel">
                        <i class="las la-window-close"></i> Cancel
                    </a>
                </li>
            </ul>
            <a href="javascript:;" class="verify-next">
                <span (click)="callNextPrev('next')">
                    Next &nbsp; &gt;
                </span>
            </a>
        </div>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Square Payment Card -->
<!-- <app-payments-common-new [paymentConfigDetails]="paymentConfigDetails" (checklistPayment)="loadPaymentInfo()" [recieptID]="'post-charges-reciept-id'"></app-payments-common-new> -->
<app-payments-refactor [paymentConfigDetails]="paymentConfigDetails" (checklistPayment)="loadPaymentInfo()"
    [recieptID]="recieptAbId || 'post-charges-reciept-id'"></app-payments-refactor>

<!-- Upload additonal documents -->
<!-- <app-upload-additional-documents [collection]="collectionName" (onDocumentUploadChange)="getAllEobDocs($event)"
    [documentSelected]="additionalDocName">
</app-upload-additional-documents> -->
<app-eob-document-check-update [collection]="collectionName" (onDocumentUploadChange)="getAllEobDocs($event)"
    [documentSelected]="additionalDocName" [dupAppntCostOfCare]="allCostOfCareItems" (refreshAppntCostOfCare)="getAppntCostOfCare($event)"></app-eob-document-check-update>

<!-- View /Download Documents -->
<app-ui-modal #viewDownloadEobEncounterDoc [hideHeader]="false" [modalCentered]="true" id="viewDownloadEobEncounterDoc">
    <div class="app-modal-header">
        <h5 class="modal-title">EOB Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="viewDownloadEobEncounterDoc.hide();clearIframeSrc()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="documents mb-3">
            <ng-container *ngFor="let document of allEobDocuments; let i = 'index+1'">
                <button class="icon-queue-events signoff-action-bg" placement="right"
                    (click)="viewDownloadDocument(document, 'view', viewDownloadEobEncounterDoc)"
                    [ngbTooltip]="'EOB Document ' + i"><i style="color: #5ca6db;" class="fa fa-file-pdf"></i></button>
            </ng-container>
        </div>
        <div class="consent-pdf">
            <iframe id="view-download-eob-doc-post-charges" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="postChargesUpdate_ok1"
            (click)="viewDownloadEobEncounterDoc.hide();clearIframeSrc()">Ok</button>
    </div>
</app-ui-modal>

<!-- Download EOB -->

<app-ui-modal #viewEobPostCharge [hideHeader]="false" [modalCentered]="true" id="viewEobPostCharge"
    class="common-modal">
    <div class="app-modal-header">
        <h5 class="modal-title">Explanation of Benefits (EOB)</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="viewEobPostCharge?.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <iframe id="eob-pdf-postcharge-post-charges" frameBorder="0" width="100%" height="500px"></iframe>

    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="postChargesUpdate_ok2" (click)="viewEobPostCharge?.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- confirmation modal for guarding the payments -->
<app-ui-modal #postChargesUpdateGuardingModal [hideHeader]="false" [modalCentered]="true"
    id="postChargesUpdateGuardingModal">
    <div class="app-modal-header">
        <div class="row d-flex-ver-center">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 30px; margin: 0px;">
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left;">You made changes, if you close you lost the data. Are
                    you want to save the details?</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" id="postChargesUpdateGuardingModal_no"
            style="margin-right: 15px;" data-dismiss="modal"
            (click)="postChargesUpdateGuardingModal.hide();hidePostChargesModal(claimPostChargesModal)">No</button>
        <button type="button" class="btn btn-primary dash-actions" id="postChargesUpdateGuardingModal_yes"
            style="margin-right: 15px;" data-dismiss="modal"
            (click)="postChargesUpdateGuardingModal.hide()">Yes</button>
    </div>
</app-ui-modal>