<app-ui-modal #cancelModal [hideHeader]="false" [modalCentered]="true">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title"></h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="cancelModal.hide()"><span aria-hidden="true">&times;</span></button>
        <!-- <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="cancelModal.hide(); cancelForm?.reset()"><span aria-cancelFormhidden="true">&times;</span></button> -->
    </div>
    <div class="app-modal-body" [formGroup]="cancelForm">
        <div class="form-group">
            <label class="form-label">{{!isDeleteAppointment ? 'Cancellation' : 'Delete'}} Reason <span class="required">*</span></label>
            <textarea class="form-control" formControlName="cancellationReason" name="validation-required" id="cancelApp_reason"
                placeholder="Enter {{!isDeleteAppointment ? 'Cancellation' : 'Delete'}} Reason"></textarea>
            <label class="required"
                *ngIf="cancelForm.controls['cancellationReason'].hasError('required') && cancelForm.controls['cancellationReason'].touched">{{!isDeleteAppointment ? 'Cancellation' : 'Delete'}}
                Reason is required</label>
            <label class="required"
                *ngIf="cancelForm.controls['cancellationReason'].invalid && !cancelForm.controls['cancellationReason'].hasError('required')">Please
                enter a valid {{!isDeleteAppointment ? 'Cancellation' : 'Delete'}} Reason</label>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal" id="cancelApp_close"
            (click)="cancelModal.hide(); cancelForm?.reset()">Close</button>
        <!-- <button type="button" class="btn btn-primary" (click)="canceltheRequest(cancelModal, notifyTextModal)">Submit</button> -->

        <button class="btn btn-primary event-btn dash-actions" style="margin-right: 15px;" type="button" id="cancelApp_submit"
            (click)="canceltheRequest(cancelModal, notifyTextModal)"
            [disabled]="cancelRequestSubmit">
            <span *ngIf="this.cancelRequestSubmit" class="spinner-border spinner-border-sm" role="status"></span>
            <span *ngIf="this.cancelRequestSubmit" class="load-text"> Submit...</span>
            <span *ngIf="!this.cancelRequestSubmit" class="btn-text">Submit</span>
        </button>

    </div>
</app-ui-modal>

<!-- notify popup -->
<app-ui-modal #notifyTextModal [hideHeader]="false" id="notifyTextModal">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">{{notifyText}}</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="notifyTextModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
      
              <h5 *ngIf="isSuccessNotify" class="modal-title text-success text-center" style="font-size: 50px;"><i class="fa fa-check-circle" aria-hidden="true"></i>
              </h5>
              <h5 *ngIf="!isSuccessNotify" class="modal-title text-danger text-center" style="font-size: 40px;">
                <i class="las la-minus-circle"></i>
              </h5>
                
      
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">
      
              <h5 class="modal-title wordAdjustment">{{notifyText}}</h5>
      
            </div>
        </div>
        
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="cancelApp_ok"
            (click)="notifyTextModal.hide()">Ok</button>
    </div>
</app-ui-modal>