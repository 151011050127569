import { AfterViewInit, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationItem } from '../navigation';
import { NextConfig } from '../../../../../app-config';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit, AfterViewInit {
  public flatConfig: any;
  public navigation: any;
  public prevDisabled: string;
  public nextDisabled: string;
  public contentWidth: number;
  public wrapperWidth: any;
  public scrollWidth: any;
  public windowWidth: number;
  public isNavProfile: boolean;

  @Output() onNavMobCollapse = new EventEmitter();

  @ViewChild('navbarContent') navbarContent: ElementRef;
  @ViewChild('navbarWrapper') navbarWrapper: ElementRef;
  public loginUserName: any;
  public loginUserType: string;

  constructor(public nav: NavigationItem, private zone: NgZone, private location: Location,
    public router: Router) {
    this.flatConfig = NextConfig.config;
    this.windowWidth = window.innerWidth;

    this.navigation = this.nav.get();
    this.prevDisabled = 'disabled';
    this.nextDisabled = '';
    this.scrollWidth = 0;
    this.contentWidth = 0;

    this.isNavProfile = false;
  }

  ngOnInit() {
    if (this.windowWidth < 992) {
      this.flatConfig['layout'] = 'vertical';
      setTimeout(() => {
        document.querySelector('.pcoded-navbar').classList.add('menupos-static');
        (document.querySelector('#nav-ps-flat-able') as HTMLElement).style.maxHeight = '100%';
      }, 500);
    }

    const sessionUser = JSON.parse(sessionStorage.getItem('userDetails'));
    this.loginUserName = `${sessionUser.lastName} ${sessionUser.firstName }`;
    this.loginUserType = `${sessionUser.userType}`;

    // if(this.loginUserType.toUpperCase() == 'DOCTOR')
    // this.navigation = this.nav.get();
    // else if(this.loginUserType.toUpperCase() == 'BUSINESSADMIN')
    // this.navigation = this.nav.getBusinessAdminNavigation();
    const getUINavItemsList = sessionUser?.uiFeatureGroups || [];
    const isAvailableList = [];
    this.navigation[0]?.children?.map(element => {
      getUINavItemsList?.map(uiNav => { 
        if(element?.title === uiNav?.coreFeature || element?.id === uiNav?.coreFeature){
          //collapse items children display
          if(element?.children?.length > 0){
            element.children = element?.children?.filter(iChild => uiNav?.subFeature?.includes(iChild?.id));
          }
          isAvailableList?.push(element);
        }
      });
    });
    this.navigation[0].children = isAvailableList || [];

    if(sessionUser && sessionUser.facilityId === 81 && sessionUser.facilityBrand === 'P1'){
      this.navigation[0].children = this.navigation[0].children.filter(element => element.isShown === 'P1');
    }

    // Navigate if not flow baord as first eleement
    if (this.navigation && this.navigation[0] && this.navigation[0]?.children && this.navigation[0]?.children?.length > 0 && window?.location?.href?.includes('hosp-dashboard')) {
      const getFirstNavItem = this.navigation[0]?.children[0];
      if (getFirstNavItem?.type === 'item' && getFirstNavItem?.url) {
        this.router?.navigate([getFirstNavItem?.url])
      } else if (getFirstNavItem?.type === 'collapse' && getFirstNavItem?.url) {
        this.router?.navigate([getFirstNavItem?.url]);
      } else if (getFirstNavItem?.type === 'collapse' && getFirstNavItem?.children && getFirstNavItem?.children?.length > 0) {
        const getSubNavItem = getFirstNavItem?.children[0];
        if (getSubNavItem?.type === 'item' && getSubNavItem?.url) {
          this.router?.navigate([getSubNavItem?.url]);
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.flatConfig['layout'] === 'horizontal') {
      this.contentWidth = this.navbarContent.nativeElement.clientWidth;
      this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
    }
  }

  scrollPlus() {
    this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 80);
    if (this.scrollWidth > (this.contentWidth - this.wrapperWidth)) {
      this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
      this.nextDisabled = 'disabled';
    }
    this.prevDisabled = '';
    if(this.flatConfig.rtlLayout) {
      (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
    } else {
      (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
    }
  }

  scrollMinus() {
    this.scrollWidth = this.scrollWidth - this.wrapperWidth;
    if (this.scrollWidth < 0) {
      this.scrollWidth = 0;
      this.prevDisabled = 'disabled';
    }
    this.nextDisabled = '';
    if(this.flatConfig.rtlLayout) {
      (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
    } else {
      (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
    }

  }

  fireLeave() {
    const sections = document.querySelectorAll('.pcoded-hasmenu');
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove('active');
      sections[i].classList.remove('pcoded-trigger');
    }

    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        parent.classList.add('active');
      } else if(up_parent.classList.contains('pcoded-hasmenu')) {
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        last_parent.classList.add('active');
      }
    }
  }

  navMob() {
    if (this.windowWidth < 992 && document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
      this.onNavMobCollapse.emit();
    }
  }

  fireOutClick() {
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.flatConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if(up_parent.classList.contains('pcoded-hasmenu')) {
        if (this.flatConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        if (this.flatConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

  //Logout
  public logout(): void{
    sessionStorage.clear();
    this.router.navigate(['auth/signin']);
  }

}
