<div class="add-app-body add-appointment-sectons" [formGroup]="addNewPatientForm">

    <div class="form-group" *ngIf="!isUrgentCare">
        <label>Patient Type <span class="required">*</span></label><br />
        <label class="radio-inline" *ngFor="let item of addPatientTypeOfPatient;let i = index">
            <input formControlName="PATIENT_TYPE" id="visit-info-patient-type" (change)="changePatientType('change')"
                type="radio" name="PATIENT_TYPE" [value]="item.value"><span class="desc">{{item.desc}}</span></label>
        <br />
        <span class="required"
            *ngIf="addNewPatientForm.get('PATIENT_TYPE').errors?.required && addNewPatientForm.get('PATIENT_TYPE').touched">Type
            of Patient is Required</span>
    </div>

    <div class="row">
        <div class="col-md-3">
            <div class="form-group" *ngIf="addNewPatientForm.get('PATIENT_TYPE').value === 'Old'">
                <label>Patient <span class="required">*</span></label><br />
                <input type="text" id="visit-info-existing-patient-search" (click)="contactSearchModal()"
                    class="form-control input-text" readonly formControlName="EXISTING_PATIENT_SEARCH"
                    name="validation-required" placeholder="Click to select Established Patient">
                <span class="required"
                    *ngIf="addNewPatientForm.get('EXISTING_PATIENT_SEARCH').errors?.required && addNewPatientForm.get('EXISTING_PATIENT_SEARCH').touched">Existing
                    Patient is Required</span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label class="form-label">Purpose of Visit <span class="required">*</span></label>

                <ng-select placeholder="Choose Purpose of Visit" id="visit-info-purpose-visit"
                    [items]="addPatientPurposeVisit" (change)="getDoctorsList()"
                    formControlName="PURPOSE_OF_VISIT"></ng-select>

                <!-- <select class="form-control input-text" (change)="getDoctorsList()" formControlName="PURPOSE_OF_VISIT"
                    name="validation-cmbGear">
                    <option [value]="null" [selected]='true' [disabled]="true">Select Purpose of Visit</option>
                    <option *ngFor="let purpose of addPatientPurposeVisit" value="{{purpose}}">{{purpose}}</option>
                </select> -->
                <label class="required"
                    *ngIf="addNewPatientForm.controls['PURPOSE_OF_VISIT'].hasError('required') && addNewPatientForm.controls['PURPOSE_OF_VISIT'].touched">Purpose
                    of Visit should be required</label>
            </div>
        </div>

        <div class="col-md-3">
            <div class="form-group" *ngIf="!isUrgentCare">
                <label class="form-label">Service Provider <span class="required">*</span></label>

                <ng-select placeholder="Choose Service Provider" id="addApp_service-provider"
                    (change)="doctorChange($event)" [items]="adPatientAllDoctors" formControlName="DOCTOR"
                    bindLabel="doctorName" bindValue="npi"></ng-select>

                <!-- <select class="form-control input-text" formControlName="DOCTOR" (change)="doctorChange()" name="validation-cmbGear">
                    <option [value]="null" [selected]='true' [disabled]="true">Select Doctor</option>
                    <option *ngFor="let doctor of adPatientAllDoctors" value="{{doctor?.npi}}">{{doctor?.doctorName}}</option>
                </select> -->
                <label class="required"
                    *ngIf="addNewPatientForm.controls['DOCTOR'].hasError('required') && addNewPatientForm.controls['DOCTOR'].touched">Service
                    Provider
                    should be required</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 " id="cf-text-box">
            <div class="form-group">
                <label class="form-label">Chief Complaints</label>

                <ng-select [multiple]="true" placeholder="Choose Chief Complaints" id="visit-info-chif-complaints"
                    [items]="chiefCompArray" bindLabel="actualValue" bindValue="actualValue"
                    (change)="selectedChiefCompients($event)" [closeOnSelect]="false" formControlName="CHIEF_COMPLAINTS">

                </ng-select>

                <!-- <select class="form-control input-text" (change)="getDoctorsList()" formControlName="PURPOSE_OF_VISIT"
                    name="validation-cmbGear">
                    <option [value]="null" [selected]='true' [disabled]="true">Select Purpose of Visit</option>
                    <option *ngFor="let purpose of addPatientPurposeVisit" value="{{purpose}}">{{purpose}}</option>
                </select> -->
                <!-- <label class="required"
                    *ngIf="addNewPatientForm.controls['PURPOSE_OF_VISIT'].hasError('required') && addNewPatientForm.controls['PURPOSE_OF_VISIT'].touched">Purpose
                    of Visit should be required</label> -->


            </div>
        </div>

        <div class="col-md-3"> 
            <div class="form-group">
                <label class="form-label"></label>
                <ng-container *ngIf="isOtherComplaintSelected">
                    
                        <textarea rows="2" class="form-control" (keyup)="updateOtherNotes($event)" type="text" style="margin-top: 6px;"
                            placeholder="Enter Other Complaints If Any..." id=questionAdditonalText></textarea>
                    
                </ng-container>
            </div>
            
        </div>

    </div>

    
    <!-- check covid Dosage -->
    <div *ngIf="addNewPatientForm.get('PURPOSE_OF_VISIT').value === 'Covid19 Vaccination'" class="form-group">
        <label>Vaccine Dosage (Moderna) <span class="required">*</span></label><br />
        <label class="radio-inline" *ngFor="let item of covidVaccineDosage;let i = index">
            <input formControlName="COVID_VACCINE_DOSAGE" id="visit-info-vaccine-dosage" type="radio"
                name="COVID_VACCINE_DOSAGE" [value]="item.value"><span class="desc">{{item.desc}}</span></label>
        <br />
        <span
            *ngIf="addNewPatientForm.get('COVID_VACCINE_DOSAGE').errors?.required && addNewPatientForm.get('COVID_VACCINE_DOSAGE').touched"
            class="required">Vaccine Dosage Sequence is Required</span>
    </div>

    <div class="form-group">
        <label>Type of Service <span class="required">*</span></label><br />
        <label class="radio-inline" *ngFor="let item of addPatientTypeOfService;let i = index">
            <input formControlName="SERVICE_TYPE" type="radio" id="visit-info-type-of-service" name="SERVICE_TYPE"
                [value]="item.value"><span class="desc">{{item.desc}}</span></label>
        <br />
        <span class="required"
            *ngIf="addNewPatientForm.get('SERVICE_TYPE').errors?.required && addNewPatientForm.get('SERVICE_TYPE').touched">Type
            of Service is Required</span>
        <span class="info-message" *ngIf="checkCovidVisit">Type of service should be <b>Regular Visit</b> beacuse you
            have selected {{addNewPatientForm.controls['PURPOSE_OF_VISIT'].value}} </span>
    </div>

    <div class="form-group">
        <label>Schedule Type <span class="required">*</span></label><br />
        <label class="radio-inline" *ngFor="let item of addPatientScheduleType;let i = index">
            <input formControlName="SCHEDULE_TYPE" type="radio" id="visit-info-schedule-type"
                (change)="changeScheduleType()" name="SCHEDULE_TYPE" [value]="item.value"><span
                class="desc">{{item.desc}}</span></label>
        <br />
        <span class="required"
            *ngIf="addNewPatientForm.get('SCHEDULE_TYPE').errors?.required && addNewPatientForm.get('SCHEDULE_TYPE').touched">Schedule
            Type is Required</span>
    </div>

    <!-- === 'Future Appointment' -->
    <ng-container *ngIf="addNewPatientForm.get('SCHEDULE_TYPE').value === 'Future Appointment'">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <input class="form-check-input" style="margin-right: 15px;margin-left: 0px !important;"
                        type="checkbox" id="selectCheckbox" formControlName="overBooking" (click)="reSetDate()">
                    <label class="form-check-label">
                        Enable Overbooking
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label>Visit Date <span class="required">*</span></label><br />
                    <input type="date" id="visit-info-visit-date" [min]="minAppointmentDate" 
                        (change)="getAppointmentSlots(notifyTextModal)" name="datemin" 
                        class="form-control input-text" id="visit-date" formControlName="VISIT_DATE" placeholder="Visit Date">
                    <span class="required"
                        *ngIf="addNewPatientForm.get('VISIT_DATE').errors?.required && addNewPatientForm.get('VISIT_DATE').touched">Visit
                        Date is Required</span>
        
                    <span class="required"
                        *ngIf="addNewPatientForm.get('VISIT_DATE').errors?.minDate  && addNewPatientForm.get('VISIT_DATE').touched">
                        Please select a date that is not in the past.
                    </span>
         
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="form-label">Available Slot <span class="required">*</span></label>

                    <ng-select placeholder="Choose Slot" [items]="getAppointmentSlotsAvailable"
                        (change)="changeSlotTime()" formControlName="AVAILABLE_SLOT" id="visit-info-available-slot"
                        bindLabel="descStartTime" bindValue="startTime"></ng-select>

                    <!-- <select class="form-control input-text" formControlName="AVAILABLE_SLOT" name="validation-cmbGear">
                        <option [value]="null" [selected]='true' [disabled]="true">Select Slot</option>
                        <option *ngFor="let slot of getAppointmentSlotsAvailable" value="{{slot?.startTime}}">
                            {{slot?.descStartTime}}</option>
                    </select> -->
                    <label class="required"
                        *ngIf="addNewPatientForm.controls['AVAILABLE_SLOT'].hasError('required') && addNewPatientForm.controls['AVAILABLE_SLOT'].touched">Available
                        Slot should be required</label>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="row" *ngIf="isFutureAppt">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <input type="checkbox" name="waitList" id="waitList" formControlName="waitList">
                <label for="checkbox-fill-a1" class="cr p-2 save-credn-label"> Earliest Availability Waitlist</label>
            </div>
        </div>
    </div>


    <!-- <div class="form-group chief-compt-questions">
        <label>Chief Complaints </label><br />
		
		<ng-container *ngFor="let node of chiefCompNode;let i=index">
                <ng-container
                    *ngIf="node?.sectionMetadata?.gender === 'All' || node?.sectionMetadata?.gender === gender">
                   <div class="row">
                                    <div *ngFor="let sections of node?.sections;let j=index" class="col-sm-12">
                                         <div class="row">
                                            <div *ngFor="let question of sections?.questions"
                                                class="col-lg-12 col-md-12 col-sm-12">


                                                <div class="categories">
                                                    <h5><u>{{question?.label}}</u></h5>
                                                    <div class="row">
                                                        <ng-container
                                                            *ngIf="question?.properties?.inPutType==='checkBox'">
                                                            <ng-container
                                                                *ngFor="let item of question?.values;let checki = index">
                                                                <div
                                                                    class="col-lg-4 col-md-4 col-sm-12 form-group text-left">
                                                                    <div class="checkbox checkbox-primary d-flex">

                                                                        <input class="check-comp" type="checkbox"
                                                                             [value]="item"
                                                                            
                                                                            id="{{item[checki]?.actualValue}}{{question.label}}{{item?.actualValue}}"
                                                                            (change)="checkChiefComp($event, item.actualValue)">
                                                                        <label
                                                                            for="{{item[checki]?.actualValue}}{{question.label}}{{item?.actualValue}}"
                                                                            class="cr list-name-label labelname">{{item?.actualValue}}</label>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="sections?.name === 'Chief Complaints' && isOtherComplaintSelected">
                                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                                <textarea rows="2" class="form-control"
                                                                    (keyup)="updateOtherNotes($event)"
                                                                     type="text"
                                                                    placeholder="Enter Other Complaints If Any..."
                                                                    id=questionAdditonalText></textarea>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="question?.properties?.inPutType === 'textArea'">
                                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                                <textarea rows="2" class="form-control"
                                                                    
                                                                    placeholder={{question?.values[0]?.actualValue}}
                                                                    id={{question?.values[0]?.actualValue}}></textarea>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                           
                       

                </ng-container>

                <ng-container *ngIf="node?.additionalText">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label>Additional Notes</label>
                            <textarea rows="4" class="form-control" 
                                (keyup)="updateAdditionalNotes($event)" placeholder="Enter Additional Notes"
                                id="enterAddlNotes"></textarea>
                        </div>
                    </div>
                </ng-container>

            </ng-container>
                                                      
       
        
    </div> -->


</div>

<app-existing-patient-search [demographicForm]="demographicForm" [addNewPatientForm]="addNewPatientForm"
    [screenName]="'newAppnt'" [exceptionModal]="exceptionModal" [insuranceForm]="insuranceForm"
    id="existing-patient-search"></app-existing-patient-search>

<!-- notify popup -->
<app-ui-modal #notifyTextModal [hideHeader]="false" id="notifyTextModal">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">{{notifyText}}</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="notifyTextModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">

                <h5 *ngIf="isSuccessNotify" class="modal-title text-success text-center" style="font-size: 50px;"><i
                        class="fa fa-check-circle" aria-hidden="true"></i>
                </h5>
                <h5 *ngIf="!isSuccessNotify" class="modal-title text-danger text-center" style="font-size: 40px;">
                    <i class="las la-minus-circle"></i>
                </h5>


            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">

                <h5 class="modal-title wordAdjustment">{{notifyText}}</h5>

            </div>
        </div>

    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="ok1"
            (click)="notifyTextModal.hide()">Ok</button>
    </div>
</app-ui-modal>