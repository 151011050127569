<!-- Display the Order Details -->
<app-ui-modal #hl7DetailsLab [hideHeader]="false" [modalCentered]="true" id="hl7DetailsLab">
    <div class="app-modal-header">
        <h5 class="modal-title text-center">{{orderType}} Results ({{selectedOrderDetails?.patientName}})</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="hl7DetailsLab.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="report-pdf table-responsive new-designed-table">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <th>Test Name</th>
                    <th>Obeserved Value</th>
                    <th>Reference Range</th>
                    <th>Units</th>
                    <th>Abnormalcy</th>
                    <th>Comments</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let test of showOrderDetails">
                        <tr>
                            <td class="text-center" colspan="6"><b>{{test?.testName}}</b></td>
                        </tr>
                        <tr *ngFor="let hl7data of test?.hl7subdetails">
                            <td>{{hl7data?.testName || '--'}}</td>
                            <td>{{hl7data?.observedValue || '--'}}</td>
                            <td>{{hl7data?.referenceRange || '--'}}</td>
                            <td>{{hl7data?.units || '--'}}</td>
                            <td>{{hl7data?.abnormalcy || '--'}}</td>
                            <td>{{hl7data?.comments || '--'}}</td>
                        </tr>
                    </ng-container>

                    <tr
                        *ngIf="!showOrderDetails && (!showOrderDetails || showOrderDetails?.lengh == 0)">
                        <td colspan="6">Details for Care Gaps are unavailable.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="hl7DetailsLab.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>