import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { NewTaskComponent } from 'src/app/theme/shared/hop-common-components/new-task/new-task.component';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';


@Component({
  selector: 'app-pending-signoff-list',
  templateUrl: './pending-signoff-list.component.html',
  styleUrls: ['./pending-signoff-list.component.scss']
})
export class PendingSignoffListComponent implements OnInit {

  public active = 1;
  @ViewChild(DataTableDirective, { static: false })
  dtElementClaimsStatus: DataTableDirective;
  
  
  public dtOptionsPendingSignoffAppnts: DataTables.Settings = {};
  public dtTriggerPendingSignoffAppnts: Subject<any> = new Subject<any>();
  
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalComp: ExceptionModalComponent;
  
  @Input() claimCard: CardComponent;
  
  public claimStatus: any;
  public allTasks = [];
  public allClaims = [];

  public eraStatus = {
    "claimFilingIndicatorCode": "12",
    "claimFrequencyCode": "1",
    "claimPaymentAmount": "500",
    "claimStatusCode": "1",
    "facilityTypeCode": "11",
    "patientControlNumber": "5554555444",
    "patientResponsibilityAmount": "300",
    "payerClaimControlNumber": "94060555410000",
    "totalClaimChargeAmount": "800"
  };
  public setServiceDate: string;
  selectedViewAppointmentId: any;
  @ViewChild(NewTaskComponent) newTakComp: NewTaskComponent;
  public loadSpinner: boolean;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  
  constructor(private httpService: HttpService, private router: Router) { }

  ngOnInit() {
    this.dtOptionsPendingSignoffAppnts = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[1, "desc"]],
      columnDefs: [{ targets: [1], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
        },
    };
  }

  ngAfterViewInit(): void {
   
	this.getClaimList();
    this.dataTableFilter();
  }
  
   public rerender(): void {
    
	 this.dtElementClaimsStatus?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }
  
  
  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

  public dataTableFilter(): void {
    this.dtElementClaimsStatus?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that
              .search(this['value'])
              .draw();
          }
        });
      });
    });
  }

  public changeServiceDate(serviceDate: string): void {
    if (serviceDate) {
      this.setServiceDate = new Date(serviceDate)?.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
    } else {
      this.setServiceDate || null;
    }
  }

 // pending signoff list
  public getClaimList(): void {
 // this.rerender();
    const taskType = 'Pending Signoff';
    const action = `tasks/listTasks?taskType=${taskType}`;
    this.claimCard?.cardRefreshShow();
	this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
	this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.allClaims = data?.responseObject || [];
      } else {
        this.notifyText = data?.message || AppConstantsListConfig.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalComp?.modalShow();
      }
      this.dtTriggerPendingSignoffAppnts?.next();
     this.dataTableFilter();
      this.claimCard?.cardRefreshHide();
    },
      (error) => {
	  this.loadSpinner = false;
        this.notifyText = error?.message || AppConstantsListConfig.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalComp?.modalShow();
        this.claimCard?.cardRefreshHide();
      });
  }
  
  public rerenderKiosk(): void {
    this.dtElementClaimsStatus?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }


  public formateStatusDate(date: string): any {
    return this.httpService?.formatDobTimeZoneWithDisplay(date);
  }

  public formateServiceDate(date: string): void {
    return this.httpService?.yymmddDateFormat(date);
  }

  public formatPrimaryPhone(primaryPhone: string): string {
    return primaryPhone?.includes('+1') ? primaryPhone?.replace('+1', '') : primaryPhone;
  }

   public openTaskModal(appointment: any): void {

    this.newTakComp?.showTaskModal(appointment);

  }


  public viewAppointmentDetails(id: any, viewDetailsModal: UiModalComponent) {
    this.httpService.clearTreatmentFlowSession();
    this.selectedViewAppointmentId = id;
    viewDetailsModal?.show();
    // this.router?.navigate([`/hospital/past-visit/${id}`]);
  }
  // re claims submit
  public reclaimSubmit(claimsItem: any): void {
    this.router?.navigate([`hospital/claim-submission-new/${claimsItem?.appointmentId}`]);
  }

  public awaitingSignOffNavigate(claim: any): void {
    sessionStorage?.setItem('navigateClaim', 'pendingSignoff');
    sessionStorage.setItem('careflowPath', 'appointment');
    this.router?.navigate([`hospital/hosp-patientdetails/${claim?.appointmentId}`]);
  }

  public sendNotification(appointment?: any): void {
    this.sendNotifyComp?.showNotifyModalForEncounter(appointment, 'user');
  }

  ngOnDestroy(): void {
    this.dtTriggerPendingSignoffAppnts?.unsubscribe();
  }

}
