<app-ui-modal #followupNotes [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner" id="followupNotes">
  <div class="app-modal-header">
    <h5 class="modal-title">Follow-Up Notes</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="followupNotes.hide()">
      <span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body add-app-body" [formGroup]="scheduleApptForm">

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">

        <h5>Previous Notes</h5>
        <div id="followup-notes-table">
          <table class="table  table-bordered">
            <thead>
              <tr>
                <th>Notes</th>
                <th>Date</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let notes of notesInfo; let i=index">
                <td class="tdbreak">{{notes?.notes}}</td>
                <td >{{getAppointmentTime(notes?.updatedDate)}}</td>
              </tr>

            </tbody>
          </table>

        </div>


      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">

        <h5>Add New Notes <span class="text-danger">*</span></h5>
        <textarea placeholder="Write Message....." id="notificationMessage" formControlName="notes" cols="56" rows="8"
          placeholder="Add New Notes"></textarea>
        <label class="required"
          *ngIf="scheduleApptForm.controls['notes'].hasError('required') && scheduleApptForm.controls['notes'].touched">
          Follow-up Reason is required</label>

      </div>
    </div>

  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary" (click)="scheduleAppointment();"> &nbsp;SAVE</button>
  </div>
</app-ui-modal>


<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>