<app-ui-modal #scheduleFollowup [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
    id="scheduleFollowup">

    <div class="app-modal-header">
        <h5 class="modal-title">Follow-up Reminder</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="scheduleFollowup.hide()">
            <span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body" [formGroup]="scheduleApptForm">
        <!-- <p>schedule-followup-appointment works!</p> -->
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Patient’s Phone Number<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" id="phonenumber" readonly
                                formControlName="patientPrimaryPhone" placeholder="{{dtPatientDetails?.patientPrimaryPhone}}">
                            <label class="required"
                                *ngIf="scheduleApptForm.controls['patientPrimaryPhone'].hasError('required') && scheduleApptForm.controls['patientPrimaryPhone'].touched">
                                Patient’s Phone Number is required</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Purpose Of Visit<span class="text-danger">*</span></label>
                            <ng-select placeholder="Choose Purpose of Visit" id="visit-info-purpose-visit"
                                [items]="purposeOfVisitList" formControlName="purposeOfVisit"></ng-select>

                            <!-- <select class="form-control" formControlName="purposeOfVisit" name="purposeOfVisit">
                                    <option value="" [selected]='true'>Select Purpose Of Visit</option>
                                    <option *ngFor="let purposeofVisit of purposeOfVisitList" value="{{purposeofVisit}}">{{purposeofVisit}}</option>
                                </select>-->
                            <label class="required"
                                *ngIf="scheduleApptForm.controls['purposeOfVisit'].hasError('required') && scheduleApptForm.controls['purposeOfVisit'].touched">Purpose
                                of Visit should be required</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Follow-up Date<span class="text-danger">*</span></label>
                            <input type="date" class="form-control" formControlName="followupDate"  id="schedule-followup-appointment_date"
                                [min]="minAppointmentDate" (change)="changeAppntDateWithValidation(scheduleApptForm, 'followupDate')"
                                (input)="inputAppntDateValidation($event, scheduleApptForm, 'followupDate')">
                            <label class="required"
                                *ngIf="scheduleApptForm.controls['followupDate'].hasError('required') && scheduleApptForm.controls['followupDate'].touched">
                                Follow-up Date is required</label>
                                <span
                                *ngIf="scheduleApptForm?.get('followupDate').errors?.invalidDate && scheduleApptForm?.get('followupDate').touched"
                                class="text-danger">
                                Invalid date
                            </span>
                        
                        </div>
                    </div>
                </div>

               <!-- <div class="row">


                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Cohort Panel</label>
                            <ng-select placeholder="Choose Cohort Panel" id="cohort-list" [items]="cohortsList"
                                (change)="getCaregaps($event)" formControlName="cohortDescription"></ng-select>


                        </div>

                    </div>
                    <div class="col-md-6">

                        <div class="form-group">
                            <label>Pathways</label>
                            <ng-select placeholder="Choose Pathways" id="caregap-list" [multiple]="true"
                                [items]="caregapList" formControlName="careGaps"
                                [readonly]="!cohortSelected"></ng-select>
                        </div>

                    </div>
                </div>-->



                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Follow-up Reason<span class="text-danger">*</span></label>
                            <textarea class="form-control" placeholder="Write Message....." id="notificationMessage"
                                formControlName="notes" style="height: 100px"></textarea>
                            <label class="required"
                                *ngIf="scheduleApptForm.controls['notes'].hasError('required') && scheduleApptForm.controls['notes'].touched">
                                Follow-up Reason is required</label>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="app-modal-footer">
        <button type="button" class=" btn btn-primary" id="schedule-followup-appointment_submit" (click)="scheduleAppointment();"> &nbsp;SUBMIT</button>
    </div>


</app-ui-modal>

<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>