<div id="account-balance-comp">

    <div class="past-visit mb-3" id="mainDetails" #mainDetails *ngIf="appointmentDetails">
        <div class="patient-info-sec row">
            <div class="patient-name" *ngIf="isClaimPage">
                <div class="name mr-2"><b>{{appointmentDetails?.lastName}},
                        {{appointmentDetails?.firstName }}
                        {{appointmentDetails?.middleName}}
                        </b></div>
            </div>
            <div class="info-details">
                <div class="patient-appnt-info">
                    <span class="name">{{appointmentDetails?.appointment?.purposeOfVisit}} Visit
                        &nbsp;<b>|</b>&nbsp;</span>
                    <span class="gender-age">
                        {{getAppointmentTime(appointmentDetails?.appointment?.dateTime)}} </span> &nbsp; <b>|</b>&nbsp;

                    <span *ngIf="patientID">Patient ID: &nbsp;{{patientID}}&nbsp;<b>|</b>&nbsp;</span>
                    <!-- <span> Amount Paid:
                        $ 100 &nbsp;<b>|</b>&nbsp;</span> -->
                    <span> &nbsp;Status: &nbsp;<span class="orange-label">
                            {{appointmentCostOfCare?.value?.paymentStatus}}</span>
                    </span>
                </div>
                <div class="claims-appnt-itemized">
                    <span class="name"> <b>Itemized: </b></span>
                    <ng-container
                        *ngFor="let service of appointmentCostOfCare?.value?.itemizedPayments;let last = last">
                        <span><b>{{service?.serviceType}}:</b>&nbsp;${{service?.amount}}({{service?.modeOfPayment}})<span
                                *ngIf="!last">&nbsp;<b>|</b>&nbsp;</span></span>
                    </ng-container>
                    <ng-container
                        *ngIf="!appointmentCostOfCare?.value?.itemizedPayments || appointmentCostOfCare?.value?.itemizedPayments?.length === 0">
                        <span>N/A</span>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="past-visit mb-3" id="mainDetails" #mainDetails *ngIf="appointmentDetails">
        <div class="patient-info-sec">
            <span class="name" *ngIf="isClaimPage"><b>{{appointmentDetails?.firstName}}
                    {{appointmentDetails?.middleName}}
                    {{appointmentDetails?.lastName}}&nbsp;&nbsp;&nbsp;</b></span>
            <span class="name">{{appointmentDetails?.appointment?.purposeOfVisit}} Visit</span>
            <span class="gender-age"> &nbsp;&nbsp;
                &nbsp;&nbsp;{{getAppointmentTime(appointmentDetails?.appointment?.dateTime)}}</span>
            <span *ngIf="patientID"> &nbsp;&nbsp;Patient ID:
                &nbsp;&nbsp;{{patientID}}</span>
        </div>
    </div> -->

    <div class="account-balance-check" [formGroup]="appointmentCostOfCare">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12">
                <ng-container *ngIf="appointmentCostOfCare?.get('apptCostofCare')?.controls?.length < 3">
                    <a type="button" id="add-account-claims-charges" placement="right" ngbTooltip="Add Claim Processor"
                        (click)="addClaimsCharges()">
                        <i class="las la-plus-circle" style="font-size: 30px; color: #5CA6DB; cursor: pointer;"></i>
                    </a>
                </ng-container>
            </div>

            <!-- <div class="col-lg-2 col-md-2 col-sm-12">
                <div class="tooltip-show payment-tb">
                    <b>Itemized:</b>
                    <ng-container
                        *ngIf="appointmentCostOfCare?.value?.itemizedPayments && appointmentCostOfCare?.value?.itemizedPayments?.length > 0">
                        <button type="button" class="btn btn-outline btn-popup" data-toggle="tooltip"
                            data-placement="top">
                        </button>
                        <div class="show-text itemized-text-show">
                            <ng-container *ngFor="let service of appointmentCostOfCare?.value?.itemizedPayments">
                                <div class="service-charges">
                                    <span *ngIf="service?.paymentDate">{{service?.paymentDate}} - </span>
                                    <span>{{service?.serviceType}}:</span>
                                    <span><b> ${{service?.amount}}</b></span>
                                    <span> ({{service?.modeOfPayment}})</span>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="!appointmentCostOfCare?.value?.itemizedPayments || appointmentCostOfCare?.value?.itemizedPayments?.length === 0">
                        N/A
                    </ng-container>
                </div>
            </div>

            <div class="col-lg-8 col-md-8 col-sm-12">
                <div class="tooltip-show payment-tb">
                    <b>Status:</b>
                    <span class="orange-label"> {{appointmentCostOfCare?.value?.paymentStatus}}</span>
                    <ng-container *ngIf="!appointmentCostOfCare?.value?.paymentStatus">
                        N/A
                    </ng-container>
                </div>
            </div> -->

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="table-responsive new-designed-table" formArrayName="apptCostofCare">
                    <table class="table table-striped table-bordered table-hover custom-table account-bal">
                        <thead>
                            <tr>
                                <th>Claim<br /> Processor</th>
                                <th>Payor <br />Name</th>
                                <th>Amount <br />Paid<span class="pay-sec-th"></span></th>
                                <!-- <th>Itemized</th> -->
                                <th>Total Visit<br /> Charges<span class="pay-sec-th"></span></th>
                                <th>Approved<br /> Amount<span class="pay-sec-th"></span></th>
                                <th>AR Insurance</th>
                                <th>Discount/<br />Adjusted<span class="pay-sec-th"></span></th>
                                <th>Non-Payment</th>
                                <th>AR Patient<span class="pay-sec-th"></span></th>
                                <!-- <th>Coverage<br /> Cost<span class="pay-sec-th"></span></th> -->
                                <th>Credits <span class="pay-sec-th"></span></th>
                                <!-- <span class="pay-sec-th"></span> -->
                                <th>Refund <span class="pay-sec-th"></span></th>
                                <th>Write Off <span class="pay-sec-th"></span></th>
                                <th>Facility Adjustment <span class="pay-sec-th"></span></th>
                                <!-- <th>Payable<br/> Adjustment  <span class="pay-sec-th"></span></th> -->
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let costofCareItem of appointmentCostOfCare?.get('apptCostofCare')?.controls;let costI = index">
                                <tr [formGroupName]="costI">
                                    <td>{{costofCareItem?.value?.claimProcessorType || '--'}}</td>
                                    <td>{{costofCareItem?.value?.insuranceName || 'NA'}}</td>
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <div class="form-control disabled">
                                                {{costofCareItem?.value?.coPay}}
                                            </div>
                                        </div>
                                    </td>
                                    <!-- Total Visit Charges -->
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <div class="form-control disabled">
                                                {{costofCareItem?.value?.totalVisitCharges}}
                                            </div>
                                        </div>
                                    </td>

                                    <!-- <td>Approved Amount</td> (input)="approvedReimburseAmountChange(costofCareItem)"-->
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="Approved Amount"
                                                (input)="checkTotalCharges(costofCareItem, 'approvedAmount')"
                                                formControlName="approvedAmount" id="approvedAmount" />
                                        </div>
                                    </td>

                                    <!-- <td>Reimbursed Amount</td> (input)="approvedReimburseAmountChange(costofCareItem)" -->
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="AR Insurance"
                                                (input)="checkTotalCharges(costofCareItem, 'reimburseAmount')"
                                                formControlName="reimburseAmount" id="reimburseAmount" />
                                        </div>
                                    </td>

                                    <!-- <td>Discount/Adjusted</td> (input)="discountAmountChanges(costofCareItem)"-->
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="Discount/Adjusted"
                                                (input)="checkTotalCharges(costofCareItem, 'discount')"
                                                formControlName="discount" id="discount" />
                                        </div>
                                    </td>

                                    <!-- <td>Non-Payment<span class="pay-sec-th"></span></td> (input)="changeNonPayment(costofCareItem)"-->
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="Non-Payment"
                                                (input)="checkTotalCharges(costofCareItem, 'nonPayment')"
                                                formControlName="nonPayment" id="nonPayment" />
                                        </div>
                                    </td>

                                    <!-- <td>Patient Responsibility</td> -->
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="AR Patient" formControlName="patientResponsibility"
                                                readonly id="patientResponsibility" (change)="responseChange()" />
                                        </div>
                                    </td>

                                    <!-- <td>Coverage Cost<span class="pay-sec-th"></span></td> -->
                                    <!-- <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="Coverage Cost" formControlName="coverageCost"
                                                id="coverageCost" />
                                        </div>
                                    </td> -->

                                    <!-- <td>Credits <span class="pay-sec-th"></span></td> readonly -->
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="Credits"
                                                (input)="checkTotalCharges(costofCareItem, 'credits')"
                                                formControlName="credits" id="credits" />
                                        </div>
                                    </td>
                                    <!-- Refund -->
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="Refund"
                                                (input)="checkTotalCharges(costofCareItem, 'refund')"
                                                formControlName="refund" id="refund" />
                                        </div>
                                    </td>
                                    <!-- WriteOff -->
                                    <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="Write Off"
                                                (input)="checkTotalCharges(costofCareItem, 'writeOff')"
                                                formControlName="writeOff" id="writeOff" />
                                        </div>
                                    </td>
                                    <!-- Facility adjustment -->
                                    <td class="d-flex">
                                        <div class="facility-adjust-label">
                                            <input type="radio" id="plus-{{costI}}" value="plus"
                                                formControlName="adjFacilityRadio"
                                                (click)="checkradioSelection(costI, costofCareItem, 'plus')" />
                                            <label for="plus-{{costI}}" id="plusLable"><span
                                                    class="material-symbols-outlined">add</span></label>
                                            <input type="radio" id="minus-{{costI}}" value="minus"
                                                formControlName="adjFacilityRadio"
                                                (click)="checkradioSelection(costI, costofCareItem, 'minus')" />
                                            <label for="minus-{{costI}}" id="minusLabel"
                                                style="border-left: none;"><span
                                                    class="material-symbols-outlined">remove</span></label>
                                        </div>

                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number"
                                                placeholder="Facility Adjustment"
                                                (input)="checkTotalCharges(costofCareItem, 'adjFacility')"
                                                formControlName="adjFacility" id="adjFacility"
                                                [readonly]="!costofCareItem?.get('adjFacilityRadio')?.value" />
                                        </div>
                                    </td>
                                    <!-- Payable adjustment -->
                                    <!-- <td>
                                        <div class="form-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input class="form-control input-text" step="0.01" type="number" placeholder="Payable Adjustment "
                                                formControlName="adjPayable" id="adjPayable" />
                                        </div>
                                    </td> -->
                                    <td>
                                        <div *ngIf="costofCareItem?.value?.claimProcessorType !== 'Primary' && appointmentCostOfCare?.get('apptCostofCare')?.controls?.length > 1"
                                            class="delete-addlBp">
                                            <i (click)="deleteClaimAppCostOfCare(costI)"
                                                style="font-size: 30px;color: red;margin-top: 5px;cursor: pointer;"
                                                class="lar la-trash-alt"></i>
                                        </div>
                                        <div class="actions-payment">
                                            <!-- <button style="margin-left: 10px;" *ngIf="!noPayOption" [disabled]="totalAmountCount() === 0" placement="bottom" ngbTooltip="Make Payment" class="icon-queue-events" (click)="openPayment()"><i class="las la-credit-card"></i>
                                            </button> -->
                                            <!-- <button style="margin-left: 10px;" placement="bottom" ngbTooltip="Make Payment" class="icon-queue-events"><i
                                                    class="las la-credit-card"></i>
                                            </button> -->
                                        </div>
                                    </td>

                                </tr>
                            </ng-container>
                            <ng-container *ngIf="appointmentCostOfCare?.get('apptCostofCare')?.controls?.length > 1">
                                <tr>
                                    <td colspan="2">
                                        <h5 class="text-center">Total</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('coPay')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('totalVisitCharges')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('approvedAmount')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('reimburseAmount')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('discount')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('nonPayment')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('patientResponsibility')}}
                                        </h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('coverageCost')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('credits')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('refund')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('writeOff')}}</h5>
                                    </td>
                                    <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('adjFacility')}}</h5>
                                    </td>
                                    <!-- <td>
                                        <h5 class="text-center">${{calculateTotalAccountBal('writeOff')}}</h5>
                                    </td> -->
                                    <td>NA</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 mt-3" *ngIf="errorMessageShown">
                <div class="error required">{{errorMessageShown}}</div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mt-3" *ngIf="successMessageShown">
                <div class="success">{{successMessageShown}}</div>
            </div>

            <!-- Write off reason and Notes -->
            <div class="col-lg-7 col-md-7 col-sm-7 mt-4">
                <div class="row" *ngIf="calculateTotalWriteOff()">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label class="form-label">Write Off Reason<span class="required">*</span></label>
                            <ng-select placeholder="Add or Choose Reason" bindLabel="description"
                                bindValue="description" [items]="allClaimWriteoffCodes" formControlName="writeOffReason"
                                [addTag]="true"></ng-select>
                            <label class="required"
                                *ngIf="appointmentCostOfCare?.controls['writeOffReason'].hasError('required') && appointmentCostOfCare?.controls['writeOffReason'].touched">Reason
                                is required</label>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label class="form-label">Comments/Notes <span class="required">*</span></label>
                            <textarea rows="3" class="form-control" formControlName="writeOffNotes"
                                placeholder="Comments/Notes"></textarea>
                            <label class="required"
                                *ngIf="appointmentCostOfCare?.controls['writeOffNotes'].hasError('required') && appointmentCostOfCare?.controls['writeOffNotes'].touched">Comments/Notes
                                is required</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-5 col-sm-5 text-right mt-3 actions-payment">
                <!-- [disabled]="!appointmentCostOfCare?.get('patientResponsibility')?.value" -->
                <button class="icon-queue-events" *ngIf="!noSaveButton" placement="bottom"
                    ngbTooltip="Save Payment Details" (click)="submitPayCost()"><i class="lar la-save"></i></button>
                <button style="margin-left: 10px;" *ngIf="!noPayOption" [disabled]="totalAmountCount() === 0"
                    placement="bottom" ngbTooltip="Make Payment" class="icon-queue-events" (click)="openPayment()"><i
                        class="las la-credit-card"></i>
                </button>
            </div>
        </div>
    </div>
    <!-- Spinner -->
    <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Square Payment Card -->
<!-- <app-payments-common-new [paymentConfigDetails]="paymentConfigDetails" [recieptID]="'account-balance-reciept-id'"
    (checklistPayment)="loadPaymentInfo()"></app-payments-common-new> -->

<app-payments-refactor [paymentConfigDetails]="paymentConfigDetails" [recieptID]="'account-balance-reciept-id'"
(checklistPayment)="loadPaymentInfo()"></app-payments-refactor>

<!-- This is old logic and table view, just for backup -->
<!-- <div class="row mt-4" [formGroup]="appointmentCostOfCare">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="table-responsive new-designed-table">
            <table class="table table-striped table-bordered table-hover custom-table account-bal">
                 <thead>
                    <tr>
                        <th>Payments</th>
                        <th>Itemized</th>
                        <th>Total Visit Charges</th>
                        <th>Reimbursed Amount</th>
                        <th>Approved Amount</th>
                        <th>Discount/Adjusted</th>
                        <th>Non-Payment<span class="pay-sec-th"></span></th>
                        <th>Patient Responsibility</th>
                        <th>Coverage Cost<span class="pay-sec-th"></span></th>
                        <th>Credits <span class="pay-sec-th"></span></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><span placement="bottom" ngbTooltip="Co-Pay/Co-Insurance"><b>Amount Paid</b>
                            </span></td>
                        <td>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <div class="form-control disabled">
                                    {{costofCareItem?.value?.coPay}}
                                </div>
                            </div>


                        </td>

                    </tr>
                    <tr>
                        <td class="tooltip-show"><b>Itemized</b>
                            <button type="button" class="btn btn-outline btn-popup"
                                data-toggle="tooltip" data-placement="top"
                                *ngIf="appointmentCostOfCareDetails?.itemizedPayments">

                            </button>
                            <div class="show-text">
                                <ng-container
                                    *ngFor="let service of appointmentCostOfCareDetails?.itemizedPayments">
                                    <div class="service-charges">
                                        <span>{{service?.serviceType}}:</span>
                                        <span><b> ${{service?.amount}}</b></span>
                                        <span> ({{service?.modeOfPayment}})</span>
                                    </div>
                                </ng-container>
                            </div>
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td class="tooltip-show"><b>Total Visit Charges</b>
                            <button type="button" class="btn btn-outline btn-popup"
                                data-toggle="tooltip" data-placement="top"
                                *ngIf="appointmentCostOfCareDetails?.paymentStatus">

                            </button>
                            <div class="show-text text-2">

                                <span> <b> Status: </b>{{appointmentCostOfCareDetails?.paymentStatus}}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <div class="form-control disabled">
                                    {{costofCareItem?.value?.totalVisitCharges}}
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td>Reimbursed Amount</td>
                        <td>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input class="form-control input-text" type="number"
                                    placeholder="Reimbursed Amount" formControlName="reimburseAmount"
                                    id="reimburseAmount" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Approved Amount</td>
                        <td>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input class="form-control input-text" type="number"
                                    placeholder="Approved Amount" formControlName="approvedAmount"
                                    id="approvedAmount" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Discount/Adjusted</td>
                        <td>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input class="form-control input-text" type="number"
                                    placeholder="Discount/Adjusted" formControlName="discount"
                                    id="discount" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Non-Payment<span class="pay-sec-th"></span></td>
                        <td>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input class="form-control input-text" type="number"
                                    placeholder="Non-Payment" formControlName="nonPayment"
                                    id="nonPayment" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Patient Responsibility</td>
                        <td>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input class="form-control input-text" type="number"
                                    placeholder="Patient Responsibility"
                                    formControlName="patientResponsibility" id="patientResponsibility"
                                    (change)="responseChange(appointmentCostOfCare.get('patientResponsibility').dirty)" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Coverage Cost<span class="pay-sec-th"></span></td>
                        <td>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input class="form-control input-text" type="number"
                                    placeholder="Coverage Cost" formControlName="coverageCost"
                                    id="coverageCost" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Credits <span class="pay-sec-th"></span></td>
                        <td>
                            <div class="form-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input class="form-control input-text" type="number"
                                    placeholder="Credits" formControlName="credits" id="credits" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Actions</td>
                        <td class="actions-payment">
                            <button class="icon-queue-events" (click)="submitPayCost()"><i
                                    class="lar la-save"></i></button>
                            <button style="margin-left: 10px;"
                                [disabled]="!appointmentCostOfCare?.get('patientResponsibility')?.value"
                                class="icon-queue-events" (click)="openPayment()"><i
                                    class="las la-credit-card"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->

<!-- <table class="table table-striped table-bordered table-hover custom-table account-bal">
    <tbody>

        <ng-container
            *ngFor="let costofCareItem of appointmentCostOfCare?.get('apptCostofCare')?.controls;let costI = index">
            <tr [formGroupName]="costI">
                <td colspan="4"><h5>{{costofCareItem?.value?.claimProcessorType || '--'}}</h5></td>
            </tr>

            <tr [formGroupName]="costI">
                <td colspan="3"><span placement="bottom" ngbTooltip="Co-Pay/Co-Insurance"><b>Amount
                            Paid</b>
                    </span></td>
                <td>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <div class="form-control disabled">
                            {{costofCareItem?.value?.coPay}}
                        </div>
                    </div>
                </td>
            </tr>
            <tr [formGroupName]="costI">
                <td colspan="4" class="tooltip-show"><b>Itemized</b>
                    <button type="button" class="btn btn-outline btn-popup" data-toggle="tooltip"
                        data-placement="top" *ngIf="appointmentCostOfCare?.value?.itemizedPayments">

                    </button>
                    <div class="show-text">
                        <ng-container
                            *ngFor="let service of appointmentCostOfCare?.value?.itemizedPayments">
                            <div class="service-charges">
                                <span>{{service?.serviceType}}:</span>
                                <span><b> ${{service?.amount}}</b></span>
                                <span> ({{service?.modeOfPayment}})</span>
                            </div>
                        </ng-container>
                    </div>
                </td>
            </tr>
           
            <tr [formGroupName]="costI">
                <td colspan="3" class="tooltip-show"><b>Total Visit Charges</b>
                    <button type="button" class="btn btn-outline btn-popup" data-toggle="tooltip"
                        data-placement="top" *ngIf="appointmentCostOfCare?.value?.paymentStatus">

                    </button>
                    <div class="show-text text-2">

                        <span> <b> Status:
                            </b>{{appointmentCostOfCare?.value?.paymentStatus}}
                        </span>
                    </div>
                </td>
                <td>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <div class="form-control disabled">
                            {{costofCareItem?.value?.totalVisitCharges}}
                        </div>
                    </div>

                </td>
            </tr>
            <tr [formGroupName]="costI">
                <td>Reimbursed Amount</td>
                <td>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input class="form-control input-text" type="number"
                            placeholder="Reimbursed Amount" formControlName="reimburseAmount"
                            id="reimburseAmount" />
                    </div>
                </td>
                <td>Approved Amount</td>
                <td>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input class="form-control input-text" type="number"
                            placeholder="Approved Amount" formControlName="approvedAmount"
                            id="approvedAmount" />
                    </div>
                </td>
            </tr>
            <tr [formGroupName]="costI">
                <td>Discount/Adjusted</td>
                <td>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input class="form-control input-text" type="number"
                            placeholder="Discount/Adjusted" formControlName="discount"
                            id="discount" />
                    </div>
                </td>
                <td>Non-Payment<span class="pay-sec-th"></span></td>
                <td>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input class="form-control input-text" type="number"
                            placeholder="Non-Payment" formControlName="nonPayment"
                            id="nonPayment" />
                    </div>
                </td>
            </tr>
            <tr [formGroupName]="costI">
                <td>Patient Responsibility</td>
                <td>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input class="form-control input-text" type="number"
                            placeholder="Patient Responsibility"
                            formControlName="patientResponsibility" id="patientResponsibility"
                            (change)="responseChange()" />
                    </div>
                </td>
                <td>Coverage Cost<span class="pay-sec-th"></span></td>
                <td>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input class="form-control input-text" type="number"
                            placeholder="Coverage Cost" formControlName="coverageCost"
                            id="coverageCost" />
                    </div>
                </td>
            </tr>
            <tr [formGroupName]="costI">
                <td>Credits <span class="pay-sec-th"></span></td>
                <td>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input class="form-control input-text" type="number" placeholder="Credits"
                            formControlName="credits" id="credits" />
                    </div>
                </td>
            </tr>
            <tr>
            <td>Actions</td>
            <td class="actions-payment">
                <button class="icon-queue-events" (click)="submitPayCost()"><i
                        class="lar la-save"></i></button>
                <button style="margin-left: 10px;"
                    [disabled]="!appointmentCostOfCare?.get('patientResponsibility')?.value"
                    class="icon-queue-events" (click)="openPayment()"><i
                        class="las la-credit-card"></i>
                </button>
            </td>
        </tr>
        </ng-container>
    </tbody>
</table> -->