<app-ui-modal #notifyTextModal [hideHeader]="false" id="notifyTextModal" class="custom-modal">
  <div class="app-modal-header">
    <!-- <h5 class="modal-title" [innerHTML]="notifyText"></h5> -->
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="notifyTextModal.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">

    <div class="row d-flex-ver-center">
      <div class="col-lg-2 col-md-2 col-sm-2">
        <h5 *ngIf="isSuccessNotify === true" class="modal-title text-success text-center"
          style="font-size: 40px; margin: 0px;">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </h5>
        <h5 *ngIf="isSuccessNotify === false" class="modal-title text-danger text-center"
          style="font-size: 40px; margin: 0px;">
          <i class="las la-minus-circle"></i>
        </h5>
        <h5 *ngIf="isSuccessNotify === 'exclamation'" class="modal-title text-info text-center"
          style="font-size: 30px; margin: 0px;">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
        </h5>
      </div>
      <div class="col-lg-10 col-md-10 col-sm-10">
        <h5 class="modal-title wordAdjustment" [innerHTML]="notifyText"></h5>

       <!-- <h5 *ngIf="claimSubmitNotifyText" class="modal-title" style=" text-align: left; " [innerHTML]="claimSubmitNotifyText"></h5>-->
        
      </div>
    </div>

    <!-- to Show required fields -->
    <div class="row" *ngIf="requiredFields && requiredFields?.length > 0">
      <div class="col-lg-2 col-md-2 col-sm-2"></div>
      <div class="col-lg-10 col-md-10 col-sm-10">
        <ul>
          <ng-container *ngFor="let field of requiredFields">
            <li *ngIf="field?.fieldName && field?.fieldName !== ''">{{field?.fieldName}}</li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="ok1"
      (click)="notifyTextModal.hide(); redirecttoFlowboard(); navigateBack()">Ok</button>
  </div>
</app-ui-modal>