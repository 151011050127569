import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { UploadAdditionalDocumentsComponent } from 'src/app/theme/shared/hop-common-components/upload-additional-documents/upload-additional-documents.component';
import { CardComponent } from '../../../../theme/shared/components/card/card.component';
import { ExceptionModalComponent } from '../../../../theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../../../theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../../../theme/shared/litterals/app.constants';
import { HttpService } from '../../../../theme/shared/services/http.service';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';

@Component({
  selector: 'app-common-insurance-addl',
  templateUrl: './common-insurance-addl.component.html',
  styleUrls: ['./common-insurance-addl.component.scss']
})
export class CommonInsuranceAddlComponent implements OnInit {
  @Input() demographicFormDt: FormGroup;
  @Input() userInfoDetailsForm: FormGroup;
  @Input() userInfoDetails: any;

  public allInsuranceList: Observable<any>;
  public insuranceLoading = false;
  public insuranceInput = new Subject<string>();
  public inActiveOrDeletedInsurances: any[] = [];
  public insuranceDetails: FormArray;
  public insuranceSequenceList = AppConstantsListConfig?.insuranceSequenceList;
  public insuranceClassList = AppConstantsListConfig?.insuranceClassList;
  public insurancePrimarySubscriber = AppConstantsListConfig?.insurancePrimarySubscriber;
  public isSelfPay = AppConstantsListConfig?.isSelfPay;
  public insuranceType = AppConstantsListConfig?.insuranceType;
  public genderList = AppConstantsListConfig?.genderList;
  public insuranceRelationship = AppConstantsListConfig?.insuranceRelationship;
  public relationshipList = AppConstantsListConfig?.relationshipList;
  public maritalStatusList = AppConstantsListConfig?.maritalStatusList;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalModal: ExceptionModalComponent;
  public insuranceCardFile: any;

  public selectedInsuranceForDelete: any;
  public insuDeletedIndex: number;

  @Input() updateUserdataCard: CardComponent;
  @ViewChild('addInsuranceFile') addInsuranceFile: ElementRef;
  public loginDetails: any;
  public maxAppointmentDate: Date;
  public minDOB = this.httpService.convertDate(new Date('01 Jan 1910'));


  @ViewChild(UploadAdditionalDocumentsComponent) public uploadDocument: UploadAdditionalDocumentsComponent;
  public insuranceDcoumentInfoData: any;
  public selectedMedicalRecordForDelete: any;
  public additionalDocName: any;
  public collectionName: any;
  public hideInsurance: boolean = false;
  public idDcoumentInfoData: any;
  @Output() callDocIframeUpdate = new EventEmitter<any>();

  public existingS3Key: any;
  public loadSpinner: boolean;
  public downloadedFileType: any;
  @Input() checkUnSavedDataForm: FormControl;
  public newInsuranceFormGroup: FormGroup;
  indexViewEditInsurance: number;
  public selectedInsuranceDt: any;
  public selectedInsuranceFormGroup: FormGroup;
  @Output() reloaddemographics = new EventEmitter<any>();
  @Input() pagename: null;
  isNoAppntReg: string;
  @Input() selectedPatientFlowBoard: any;
  public insurancePrimarySelected: any;
  @Input() idType: null;
  public isEditMode: boolean;

  public openActions: boolean = false;
  public enableActions: boolean = false;
  public selectedActionRow: any;
  public editInsuranceEnable: boolean;

  @HostListener('document:click', ['$event'])
  clickInside(event) {

    const eventId = (event.target as HTMLInputElement).id;
    if (!this.enableActions && eventId !== 'claimActions_btn') {
      this.openActions = false;
    }
  }

  constructor(private httpService: HttpService, private formBuilder: FormBuilder) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
    //this.newInsuranceFormGroup = this.insuranceDetailsArray() || formBuilder?.group({});
    this.newInsuranceFormGroup = this.insuranceDetailsArray() || formBuilder?.group({});
  }

  ngOnInit(): void {
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
    this.getInsuranceAutoComplete();
    this.collectionName = "Account";
    this.additionalDocName = "Insurance";
    // this.getAllInsurancesByUserInfoId();
  }

  ngAfterViewInit(): void {
    this.isNoAppntReg = sessionStorage?.getItem('isNoAppnt');
    if (this.isNoAppntReg === 'No') {
      this.userInfoDetailsForm?.get('selfPay')?.setValue('Insurance');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.userInfoDetails) {
        let userInfoData: any = {};
        const employerAddress = {
          "city": null,
          "country": null,
          "state": null,
          "street": null,
          "zipCode": null
        }
        if (this.userInfoDetails?.insuranceDetails && this.userInfoDetails?.insuranceDetails?.length > 0) {
          this.insuranceDetails = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;

          this.clearFormArray(this.insuranceDetails);
          this.userInfoDetails?.insuranceDetails?.forEach((element: any, index: number) => {
            if (element?.active) {
              element.employerAddress = element?.employerAddress || employerAddress;

              this.insuranceDetails?.push(this.insuranceDetailsArray(element));
              // this.previewInsuranceImage(element.insuranceCardImageId, "edit", index);
            }
          });
          this.inActiveOrDeletedInsurances = this.userInfoDetails?.insuranceDetails?.filter(item => item?.active === false) || [];
        } else if (this.pagename === 'demographics') {
          this.selectedPatientFlowBoard = this.selectedPatientFlowBoard || JSON?.parse(sessionStorage?.getItem('establishedUserInfo'));
          this.insuranceDetails = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray || this.selectedPatientFlowBoard?.insuranceDetails as FormArray;

          this.clearFormArray(this.insuranceDetails);


          this.selectedPatientFlowBoard?.insuranceDetails?.forEach((element: any, index: number) => {
            if (element?.active) {
              element.employerAddress = element?.employerAddress || employerAddress;

              this.insuranceDetails?.push(this.insuranceDetailsArray(element));
              // this.previewInsuranceImage(element.insuranceCardImageId, "edit", index);
            }
          });
          this.inActiveOrDeletedInsurances = this.selectedPatientFlowBoard?.insuranceDetails?.filter(item => item?.active === false) || [];
        } else {
          userInfoData.insuranceDetails = this.userInfoDetails?.insuranceDetails || [];
          this.insuranceDetails = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
          this.clearFormArray(this.insuranceDetails);
          // this.insuranceDetails?.push(this.insuranceDetailsArray());
        }
        this.userInfoDetailsForm?.patchValue(userInfoData);
        // this.getAllInsurancesByUserID();
      }
    }
  }

  public subscriberDobFormatChange(subscriberDob: string): string {
    let dob = subscriberDob?.includes(':') ? subscriberDob?.split(' ')[0] : subscriberDob;
    if (dob?.includes('/')) {
      return this.httpService?.mmddyyyyFormatSplit(dob);
    }
    return dob;
  }

  // insurance autocomplete
  private getInsuranceAutoComplete() {
    this.allInsuranceList = concat(
      of([]), // default items
      this.insuranceInput.pipe(
        distinctUntilChanged(),
        tap(() => this.insuranceLoading = true),
        switchMap(term => this.httpService?.getInsuranceAutoComplete(term)?.pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.insuranceLoading = false)
        ))
      )
    );
  };

  public trackByFn(item: any) {
    return item?.name;
  }

  public getAllInsurancesByUserInfoId(): void {
    this.httpService?.getInsuranceDetailsDocuments(this.loginDetails?.facilityId, this.userInfoDetails?.id || this.userInfoDetails?.USERINFO_ID, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        let userInfoData: any = {};
        const employerAddress = {
          "city": null,
          "country": null,
          "state": null,
          "street": null,
          "zipCode": null
        }
        if (data?.responseObject && data?.responseObject?.length > 0) {
          this.insuranceDetails = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
          this.clearFormArray(this.insuranceDetails);
          data?.responseObject?.forEach(element => {
            element.employerAddress = element?.employerAddress || employerAddress;
            this.insuranceDetails?.push(this.insuranceDetailsArray(element));
          });
        } else {
          userInfoData.insuranceDetails = data?.responseObject || [];
          this.insuranceDetails = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
          this.clearFormArray(this.insuranceDetails);
          // this.insuranceDetails?.push(this.insuranceDetailsArray());
        }
        this.userInfoDetailsForm?.patchValue(userInfoData);
      }
    })
  }

  // clear the Form Array
  public clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  // add insuranaced details
  public insuranceDetailsArray(insurance?: any): FormGroup {
    return this.formBuilder?.group({
      "active": [insurance?.active === false ? false : true],
      "claimPayerId": [insurance?.claimPayerId || null],
      "eligibilityPortalID": [insurance?.eligibilityPortalID || null],
      "eligibilityRealtimePayerID": [insurance?.eligibilityRealtimePayerID || null],
      "erCost": [insurance?.erCost || null],
      "groupNumber": [insurance?.groupNumber || null],
      "healthPlan": [insurance?.healthPlan || null],
      "insuranceAddDate": [insurance?.insuranceAddDate || null],
      "insuranceCardImageId": [insurance?.insuranceCardImageId || null],
      "insuranceClass": [insurance?.insuranceClass || null, Validators?.required],
      "insurancePrimarySubscriber": [insurance?.insurancePrimarySubscriber || null, Validators?.required],
      "insuranceholderName": [insurance?.insuranceholderName || null],
      "medicareFlag": [insurance?.medicareFlag === true ? true : false, Validators?.required],
      "medicareStartDate": [insurance?.medicareStartDate || null, [this.httpService.yearValidator.bind(this)]],
      "memberId": [insurance?.memberId || null, Validators?.required],
      "name": [insurance?.name || null, Validators?.required],
      "officeVisitCost": [insurance?.officeVisitCost || null],
      "payerId": [insurance?.payerId || null, Validators?.required],
      "priority": [insurance?.priority || null, Validators?.required],
      "relationToSubscriber": [insurance?.relationToSubscriber || null],
      "specialityCost": [insurance?.specialityCost || null],
      // (insurance?.subscriberdateOfBirth?.includes(':') ? insurance?.subscriberdateOfBirth?.split(' ')[0] : insurance?.subscriberdateOfBirth) || null
      "subscriberdateOfBirth": [insurance?.dob ? this.subscriberDobFormatChange(insurance?.dob) : insurance?.subscriberdateOfBirth ? this.subscriberDobFormatChange(insurance?.subscriberdateOfBirth) : null, Validators?.required],
      "subscriberfirstname": [insurance?.subscriberfirstname || null, Validators?.required],
      "subscribergender": [insurance?.subscribergender || null, Validators?.required],
      "subscriberlastname": [insurance?.subscriberlastname || null, Validators?.required],
      "urgentCareCost": [insurance?.urgentCareCost || null],
      'docType': [insurance?.docType || null],
      "employerName": [insurance?.employerName || null],
      'employerAddress': this.formBuilder?.group({
        "city": [insurance?.employerAddress?.city || null],
        "country": [insurance?.employerAddress?.country || null],
        "state": [insurance?.employerAddress?.state || null],
        "street": [insurance?.employerAddress?.street || null],
        "zipCode": [insurance?.employerAddress?.zipCode || null]
      }),
    });
  }
  // set the insurance related values
  public selectedInsurance(event: any, index?: number): void {
    this.newInsuranceFormGroup?.get('name')?.setValue(event?.name || null);


    const formArray = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
    let getFormGrpIndex: FormGroup;
    if ((this.indexViewEditInsurance || this.indexViewEditInsurance === 0)) {
      getFormGrpIndex = formArray?.controls[this.indexViewEditInsurance] as FormGroup;
    }

    const selectedInsuranceCheck = formArray?.value?.find(item => item?.name === event?.name);
    if (selectedInsuranceCheck && selectedInsuranceCheck?.name === event?.name && !this.editInsuranceEnable) {
      this.notifyText = `You have already selected ${event?.name} Insurance. Please select any other  Insurance.`;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.newInsuranceFormGroup?.get('name')?.setValue(null);
    }
    else {
      this.newInsuranceFormGroup?.get('payerId')?.setValue(event?.payorid || null);
      this.newInsuranceFormGroup?.get('eligibilityRealtimePayerID')?.setValue(event?.eligibilityRealtimePayerID || null);
      this.newInsuranceFormGroup?.get('claimPayerId')?.setValue(event?.cpid || null);
      this.newInsuranceFormGroup?.get('eligibilityPortalID')?.setValue(event?.eligibilityPortalID || null);

    }


  }

  // confirm insurance delete
  public deleteInsuranceConfirm(index: number, insurance: any, confirmDeleteModal: UiModalComponent): void {

    /*const insuranceValue = this.userInfoDetailsForm?.get('insuranceDetails')?.value;
    const insuranceLength = insuranceValue.length;
    if(insuranceLength === 1){
          this.notifyText = "Atleast one insurance should be on file ";
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
          return;
    
    }
    else{
      confirmDeleteModal?.show();
      this.selectedInsuranceForDelete = insurance;
      this.insuDeletedIndex = index;
    }*/
    confirmDeleteModal?.show();

    this.selectedInsuranceForDelete = insurance;
    this.insuDeletedIndex = index;
  }

  public deleteInsuranceFromList(index: number, insurance: any): void {
    if (!insurance?.value?.name && !insurance?.value?.memberId && !insurance?.value?.groupNumber && !insurance?.value?.payerId && !insurance?.value?.priority && !insurance?.value?.insuranceClass) {
      this.deleteInsurances(index, insurance as FormGroup);
      return;
    };
  }

  // primary subscriber check
  public checkPrimarySubscriber(mode: string, insurance?: any): void {
    const insurancePrimary = this.newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value || null;
    this.insurancePrimarySelected = insurancePrimary;
    if (insurancePrimary === 'Self') {
      this.newInsuranceFormGroup?.get('subscriberfirstname')?.clearValidators();
      this.newInsuranceFormGroup?.get('subscriberlastname')?.clearValidators();
      this.newInsuranceFormGroup?.get('subscriberfirstname')?.setValidators([Validators?.required]);
      this.newInsuranceFormGroup?.get('subscriberlastname')?.setValidators([Validators?.required]);
      this.newInsuranceFormGroup?.get('subscriberfirstname').setValue(this.demographicFormDt?.value?.FIRST_NAME || this.userInfoDetailsForm?.value?.FIRST_NAME || this.selectedPatientFlowBoard?.firstName || insurance?.subscriberfirstname || this.userInfoDetails?.firstName || this.userInfoDetails?.FIRST_NAME || null);
      this.newInsuranceFormGroup?.get('subscriberlastname').setValue(this.demographicFormDt?.value?.LAST_NAME || this.userInfoDetailsForm?.value?.LAST_NAME || this.selectedPatientFlowBoard?.lastName || insurance?.subscriberlastname || this.userInfoDetails?.lastName || this.userInfoDetails?.LAST_NAME || null);
      this.newInsuranceFormGroup?.get('subscribergender').setValue(this.demographicFormDt?.value?.GENDER || this.userInfoDetailsForm?.value?.GENDER || this.selectedPatientFlowBoard?.gender || insurance?.subscribergender || this.userInfoDetails?.gender || this.userInfoDetails?.GENDER || null);
      this.newInsuranceFormGroup?.get('subscriberdateOfBirth').setValue(this.demographicFormDt?.value?.DATE_OF_BIRTH || this.userInfoDetailsForm?.value?.DATE_OF_BIRTH || (this.selectedPatientFlowBoard?.dob ? this.httpService?.formatDob(this.selectedPatientFlowBoard?.dob) : null) || insurance?.subscriberdateOfBirth || this.userInfoDetails?.dob || this.userInfoDetails?.DATE_OF_BIRTH || (this.userInfoDetails?.usrDateOfBirth ? this.httpService?.formatDob(this.userInfoDetails?.usrDateOfBirth) : null) || null);
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValidators([this.httpService.yearValidator.bind(this)]);
      this.newInsuranceFormGroup?.get('relationToSubscriber')?.reset();
      this.newInsuranceFormGroup?.get('relationToSubscriber')?.setValue(null);
      this.newInsuranceFormGroup?.get('relationToSubscriber')?.disable();
    } else if (insurancePrimary === 'Other') {
      let insurance = null;
      if (this.isEditMode) {
        insurance = this.selectedInsuranceDt;
      }
      else {
        insurance = insurance;
      }
      this.newInsuranceFormGroup?.get('subscriberfirstname').setValue(insurance?.subscriberfirstname || null);
      this.newInsuranceFormGroup?.get('subscriberlastname').setValue(insurance?.subscriberlastname || null);
      this.newInsuranceFormGroup?.get('subscribergender').setValue(insurance?.subscribergender || null);
      this.newInsuranceFormGroup?.get('subscriberdateOfBirth').setValue(insurance?.subscriberdateOfBirth || null);
      this.newInsuranceFormGroup?.get('relationToSubscriber').setValue(insurance?.relationToSubscriber || null);

      this.newInsuranceFormGroup?.get('relationToSubscriber')?.enable();

      this.newInsuranceFormGroup?.get('subscriberfirstname')?.setValidators([Validators?.required, Validators.pattern(AppRegExpressionsConfig.alphabetonly)]);
      this.newInsuranceFormGroup?.get('subscriberlastname')?.setValidators([Validators?.required, Validators.pattern(AppRegExpressionsConfig.alphabetonly)]);
      this.newInsuranceFormGroup?.get('subscribergender')?.setValidators([Validators?.required]);
      this.newInsuranceFormGroup?.get('subscriberdateOfBirth')?.setValidators([Validators?.required,this.httpService.dateValidator.bind(this)]);
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValidators([this.httpService.yearValidator.bind(this)]);
      this.newInsuranceFormGroup?.get('relationToSubscriber')?.setValidators([Validators?.required]);
    }
    // this.newInsuranceFormGroup?.get('subscriberfirstname')?.updateValueAndValidity();
    // this.newInsuranceFormGroup?.get('subscriberlastname')?.updateValueAndValidity();
    this.newInsuranceFormGroup?.get('subscribergender')?.updateValueAndValidity();
    this.newInsuranceFormGroup?.get('subscriberdateOfBirth')?.updateValueAndValidity();
    this.newInsuranceFormGroup?.get('relationToSubscriber')?.updateValueAndValidity();
    this.newInsuranceFormGroup?.get('medicareStartDate')?.updateValueAndValidity();
  } 
  

  // medicare flag change in inusrance section
  public medicareFlagChange(): void {
    const medicarStartDate = this.newInsuranceFormGroup?.get('medicareStartDate').value;
    const insurancePrimary = this.newInsuranceFormGroup?.get('medicareFlag').value;
    if (insurancePrimary === true) {
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValidators([Validators?.required, this.httpService.yearValidator.bind(this)]);
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValue(medicarStartDate);
    } else {
      //this.newInsuranceFormGroup?.get('medicareStartDate')?.reset();
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValidators(null);
    }
    this.newInsuranceFormGroup?.get('medicareStartDate')?.updateValueAndValidity();
  }

  // Insurance / DL Image Card Download
  public downloadInsuranceCard(cardId: string, insuraceCardModal: UiModalComponent): void {
    const action = `document/user/getInsuranceCard/${cardId}`;
    this.updateUserdataCard?.cardRefreshShow();
    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // created dynamic iframe SRC attribute and assigned lookup url
      // window.open(fileURL);
      insuraceCardModal?.show();
      this.updateUserdataCard?.cardRefreshHide();
      const iframeEle = document.getElementById('chart-insurance-card-multiple') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    },
      (error: any) => {
        this.updateUserdataCard?.cardRefreshHide();
      });
  }


  public async previewInsuranceImage(additionalKey: string, viewmode: any, index?: number) {
    this.updateUserdataCard?.cardRefreshShow();
    try {
      const documentType = "Insurance";
      let userInfoId = null;
      if (this.idType === 'userId') {
        userInfoId = this.userInfoDetails?.id;
      }
      else if (this.idType === 'appointmentId') {
        userInfoId = this.userInfoDetails?.userInfoId || this.userInfoDetails?.USERINFO_ID || this.selectedPatientFlowBoard?.userInfoId;
      }
      const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
      this.loadSpinner = true;
      const getIndDoc: any = await this.httpService?.makeGetRequest(action, '')?.toPromise();
      this.loadSpinner = false;
      if (getIndDoc?.responseObject == null || getIndDoc?.responseObject == '') {
        this.hideInsurance = false;
        this.insuranceImagewithoutuserinfoId(additionalKey, viewmode, index);
      }
      else {
        this.hideInsurance = true;
        const s3Key = getIndDoc?.responseObject?.s3Key;
        const s3Path = getIndDoc?.responseObject?.s3Path;
        const additionalKey = getIndDoc?.responseObject?.additionalKey;
        const fileType = getIndDoc?.responseObject?.fileType;
        this.downloadedFileType = fileType;
        this.newInsuranceFormGroup?.get('docType')?.setValue(fileType);
        if (this.selectedInsuranceDt) {
          this.selectedInsuranceDt.docType = fileType;
        }
        this.downloadIdFilePreview(this.userInfoDetails?.id || this.userInfoDetails?.USERINFO_ID, s3Key, s3Path, additionalKey, fileType, viewmode);
      }
      this.updateUserdataCard?.cardRefreshHide();
    } catch (error) {
      this.loadSpinner = false;
      this.updateUserdataCard?.cardRefreshHide();
    }
  }

  public insuranceImagewithoutuserinfoId(additionalKey: string, viewmode: any, index?: number) {

    const documentType = "Insurance";
    const userInfoId = null;

    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data.responseObject !== null) {
        const s3Key = data?.responseObject?.s3Key;
        const s3Path = data?.responseObject?.s3Path;
        const additionalKey = data?.responseObject?.additionalKey;
        const fileType = data?.responseObject?.fileType;
        this.downloadedFileType = fileType;
        this.newInsuranceFormGroup?.get('docType')?.setValue(fileType);
        if (this.selectedInsuranceDt) {
          this.selectedInsuranceDt.docType = fileType;
        }
        this.downloadIdFilePreview(this.userInfoDetails?.id || this.userInfoDetails?.USERINFO_ID, s3Key, s3Path, additionalKey, fileType, viewmode);
      }
      this.loadSpinner = false;
    }, (error) => {
      this.loadSpinner = false;
      this.updateUserdataCard?.cardRefreshHide();
    });
  }

  public downloadIdFilePreview(userInfoId, s3Key, s3Path, additionalKey, fileType, viewMode) {
    if (!s3Key || !s3Path) {
      return;
    }
    this.loadSpinner = true;
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if (!dData) {
        this.loadSpinner = false;
        return;
      }
      this.loadSpinner = false;
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      console.log("insuranceURL", fileURL);
      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }
      this.insuranceDcoumentInfoData = fileObj;
      let iframeEle = null;
      if (viewMode === 'view') {
        iframeEle = document.getElementById(additionalKey + '_chart_insu_doc_view') as HTMLElement;
      }
      else {
        iframeEle = document.getElementById(additionalKey + '_chart_insu_doc') as HTMLElement;
      }
      iframeEle.setAttribute('src', fileURL);
    }, (error) => {
      this.loadSpinner = false;
      this.updateUserdataCard?.cardRefreshHide();
    });
  }

  public downloadIdentificationCardNouserInfo(isViewDownload, additionalKey: string, insuraceCardModal: UiModalComponent) {

    const documentType = "Insurance";
    const userInfoId = null;

    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data.responseObject !== null) {
        this.hideInsurance = true;
        const s3Key = data?.responseObject?.s3Key;
        const s3Path = data?.responseObject?.s3Path;
        const additionalKey = data?.responseObject?.additionalKey;
        const fileType = data?.responseObject?.fileType;
        this.downloadIdFile(isViewDownload, this.userInfoDetails?.id || this.userInfoDetails?.USERINFO_ID, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
      }
      this.loadSpinner = false;
    }, (error) => {
      this.loadSpinner = false;
      // this.updateUserdataCard?.cardRefreshHide();
    });
  }

  public downloadIdentificationCard(isViewDownload, additionalKey: string, insuraceCardModal: UiModalComponent) {
    const documentType = "Insurance";
    let userInfoId = null;
    if (this.idType === 'userId') {
      userInfoId = this.userInfoDetails?.id;
    }
    else if (this.idType === 'appointmentId') {
      userInfoId = this.userInfoDetails?.userInfoId || this.userInfoDetails?.USERINFO_ID || this.selectedPatientFlowBoard?.userInfoId;
    }

    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;
    this.updateUserdataCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.updateUserdataCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {
          this.hideInsurance = false;
          this.downloadIdentificationCardNouserInfo(isViewDownload, additionalKey, insuraceCardModal);
        }
        else {
          this.hideInsurance = true;
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          const fileType = data?.responseObject?.fileType;

          this.downloadIdFile(isViewDownload, this.userInfoDetails?.id || this.userInfoDetails?.USERINFO_ID, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
        }
      }
      this.loadSpinner = false;
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.loadSpinner = false;
      this.updateUserdataCard?.cardRefreshHide();
    });
  }

  public downloadIdFile(isViewDownload: string, userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal: UiModalComponent) {
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;

    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if (!dData) {
        this.loadSpinner = false;
        this.updateUserdataCard?.cardRefreshHide();
        return;
      }
      this.hideInsurance = true;

      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);

      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }
      this.insuranceDcoumentInfoData = fileObj;
      this.updateUserdataCard?.cardRefreshHide();
      if (isViewDownload === 'view') {
        insuraceCardModal?.show();
        const iframeEle = document.getElementById('chart-insurance-card-multiple') as HTMLElement;
        iframeEle?.setAttribute('src', fileURL);
      } else {
        let a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = additionalKey;
        document.body.appendChild(a);
        a.click();
      }
    }, (error) => {
      this.updateUserdataCard?.cardRefreshHide();
    });
  }


  // public getInsurance(): FormGroup{
  //   const formArray = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
  //   return formArray.at(i) as FormGroup;
  // }

  // public checkInsuranceDetails(i): void{

  // }
  // it will call when file upload
  public insuranceFileChanged(event: any): void {
    if (!this.newInsuranceFormGroup?.value || !this.newInsuranceFormGroup?.value?.memberId || !this.newInsuranceFormGroup?.value?.payerId || !this.newInsuranceFormGroup?.value?.name) {
      this.notifyText = 'Please add the Insurance Details to upload the Insurance Card.';
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.clearFileUpload();
      return;
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg') {
        this.uploadInsuranceImage(file);
      }
      else {

        this.notifyText = "Supports only PDF, PNG, JPEG files ";
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
        this.clearFileUpload();
        return;
      }
    }
  }

  public uploadInsuranceImage(file: any, index?: number): void {
    const isConnect = true;
    this.loadSpinner = true;
    let userInfoId = null;
    if (this.idType === 'userId') {
      userInfoId = this.userInfoDetails?.id;
    }
    else if (this.idType === 'appointmentId') {
      userInfoId = this.userInfoDetails?.userInfoId || this.userInfoDetails?.USERINFO_ID;
    }
    let action: any = `document/user/uploadInsuranceImage?userInfoId=${userInfoId}&isConnect=${isConnect}`;
    // convert to binary for data
    const formData = new FormData();
    formData.append('idFile', file);
    this.updateUserdataCard?.cardRefreshShow();
    this.httpService.makePostWithFormData(action, formData)
      .subscribe((data: any) => {
        this.updateUserdataCard?.cardRefreshHide();
        this.loadSpinner = false;
        if (data?.status === 'SUCCESS') {
          //this.insuranceCardFile = data?.fileName || null;
          this.insuranceCardFile = data?.keyName || null;
          const additionalKey = data?.keyName || null;
          this.newInsuranceFormGroup?.get('insuranceCardImageId')?.setValue(this.insuranceCardFile);
          const memberId = this.newInsuranceFormGroup?.get('memberId')?.value;

          this.updateInsuranceCardKeys('', data?.keyName, memberId, this.userInfoDetails?.userInfoId || this.userInfoDetails?.id);

          this.notifyText = 'Insurance Card has been uploaded successfully.';
          this.isSuccessNotify = true;
          this.exceptionalModal?.modalShow();
          this.userInfoDetailsForm?.markAsDirty();
          this.checkUnSavedDataForm?.setValue(true);
          if (this.insuranceCardFile != '') {
            //this.updateInsuranceIdentityImageId('insuCard',additionalKey);
            this.previewInsuranceImage(additionalKey, "edit", index);
          }
        } else {
          this.notifyText = data?.status || data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
        }
        this.clearFileUpload();
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
          this.updateUserdataCard?.cardRefreshHide();
          this.clearFileUpload();
        });
  }

  public clearFileUpload(): void {
    this.addInsuranceFile.nativeElement.value = "";
    // const element = document?.getElementById('user-insurance-license-upload') as HTMLElement;
    // element?.setAttribute('value', '');
  }

  public addMoreInsurances(insuranceDetails?: any): void {
    this.insuranceDetails = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
    this.insuranceDetails?.push(this.insuranceDetailsArray(insuranceDetails));
  }

  public addMoreInsurancesClick() {
    this.insuranceDetails = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
    this.insuranceDetails?.push(this.insuranceDetailsArray());
    this.userInfoDetailsForm?.markAsDirty();
    this.checkUnSavedDataForm?.setValue(true);
  }

  public deleteInsurances(index: number, insurance: FormGroup): void {
    this.insuranceDetails = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
    this.insuranceDetails?.removeAt(index);
    const insurances = this.userInfoDetails?.insuranceDetails || [];
    let filterInsurance = insurances?.find((item: any) => insurance?.value?.memberId === item?.memberId) || null;
    if (filterInsurance) {
      filterInsurance.active = false;
      this.inActiveOrDeletedInsurances = [...this.inActiveOrDeletedInsurances, ...[filterInsurance]];
    }
    this.userInfoDetailsForm?.markAsDirty();
    this.checkUnSavedDataForm?.setValue(true);
  }

  public changeCoverageSequence(selectValue: any, index?: number): void {
    if (!selectValue) {
      return;
    }
    const formArray = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
    let getFormGrpIndex: FormGroup;
    if ((this.indexViewEditInsurance || this.indexViewEditInsurance === 0)) {
      getFormGrpIndex = formArray?.controls[this.indexViewEditInsurance] as FormGroup;
    }
    // (this.newInsuranceFormGroup?.value?.memberId === getFormGrpIndex?.value?.memberId && this.newInsuranceFormGroup?.value?.name === getFormGrpIndex?.value?.name)
    if (formArray?.controls?.length === 0 || formArray?.controls?.length == 1) {
      const checkIndex = formArray?.controls?.length === 0 ? true : (this.indexViewEditInsurance || this.indexViewEditInsurance === 0);
      if (selectValue?.value !== 'Primary' && checkIndex) {
        this.notifyText = 'Please select the Primary Coverage initially.';
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
        this.newInsuranceFormGroup?.get('priority')?.setValue(null);
        getFormGrpIndex?.get('priority')?.setValue(null);
        return;
      } else if (selectValue?.value === 'Tertiary' && formArray?.controls?.length < 2) {
        this.notifyText = 'Please select the Secondary Coverage initially.';
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
        this.newInsuranceFormGroup?.get('priority')?.setValue(null);
        getFormGrpIndex?.get('priority')?.setValue(null);
        return;
      }
    }

    const findPrimaryCheck = formArray?.value?.find(item => item?.priority === 'Primary') || null;
    const findSecondaryCheck = formArray?.value?.find(item => item?.priority === 'Secondary') || null;
    const findTertiaryCheck = formArray?.value?.find(item => item?.priority === 'Tertiary') || null;

    const prmaryIndex = formArray?.value?.findIndex(x => x.priority === "Primary");
    const secondaryIndex = formArray?.value?.findIndex(x => x.priority === "Secondary");
    const teritoryIndex = formArray?.value?.findIndex(x => x.priority === "Tertiary");

    // getFormGrpIndex?.get('priority')?.setValue(selectValue?.value);

    if ((findPrimaryCheck && findPrimaryCheck?.priority === selectValue?.value) || (findSecondaryCheck && findSecondaryCheck?.priority === selectValue?.value) || (findTertiaryCheck && findTertiaryCheck?.priority === selectValue?.value)) {
      this.notifyText = `You have already selected ${selectValue?.value} Coverage. Please select other sequence for this Insurance Details.`;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.newInsuranceFormGroup?.get('priority')?.setValue(null);
    } else {
      getFormGrpIndex?.get('priority')?.setValue(selectValue?.value);
    }
  }

  // confirm the delet Insurance
  public confirmDelete(existingS3Key, insurance: any, deleteConfirmModal: UiModalComponent): void {
    this.existingS3Key = existingS3Key;
    this.selectedInsuranceDt = insurance;
    this.selectedMedicalRecordForDelete = insurance;
    deleteConfirmModal?.show();
  }

  // delete insurance card by appointment id
  public deleteInsuranceImageByUser(insurance: FormGroup): void {
    let userInfoId = null;
    if (this.idType === 'userId') {
      userInfoId = this.userInfoDetails?.id;
    }
    else if (this.idType === 'appointmentId') {
      userInfoId = this.userInfoDetails?.userInfoId || this.userInfoDetails?.USERINFO_ID;
    }

    const action = `treatmentFlow/softDeleteByUser?userInfoId=${userInfoId}&insuranceCardImageId=${insurance?.value?.insuranceCardImageId}`;
    this.updateUserdataCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      insurance?.get('insuranceCardImageId')?.setValue(null);
      this.notifyText = 'Insurance Image Deleted Successfully';
      this.isSuccessNotify = true;
      this.exceptionalModal?.modalShow();
      this.updateUserdataCard?.cardRefreshHide();
    }, error => {
      this.updateUserdataCard?.cardRefreshHide();
      this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
    });
  }

  public uploadInsuranceDocs(userInfoData: any): void {

    //this.uploadDocument?.openUploadDocument(userInfoData);
    this.uploadDocument?.openUploadDocument(userInfoData, 'Account', 'Insurance');
  }

  public deleteInsuranceDocument(existingS3Key: any, insurance: FormGroup, deletConfirmDocumentModal: UiModalComponent): void {
    this.loadSpinner = true;
    let userInfoId = null;
    if (this.idType === 'userId') {
      userInfoId = this.userInfoDetails?.id;
    }
    else if (this.idType === 'appointmentId') {
      userInfoId = this.userInfoDetails?.userInfoId || this.userInfoDetails?.USERINFO_ID || this.userInfoDetails?.id;
    }

    const action = `record/deleteDocument?s3Key=${existingS3Key}&userInfoId=${userInfoId}`;
    this.additionalDocName = "Insurance";
    // const action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.userInfoId}&additionalKey=${medicalRecord?.additionalKey}`;
    this.updateUserdataCard?.cardRefreshShow();
    deletConfirmDocumentModal.hide();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      this.updateUserdataCard?.cardRefreshHide();
      const memberId = insurance?.value?.memberId;
      this.updateInsuranceCardKeys(this.existingS3Key, '', memberId, this.userInfoDetails?.userInfoId || this.userInfoDetails?.id);

      /*const insArray = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;
      const insGroup = insArray.controls[this.indexViewEditInsurance] as FormGroup;
      insGroup?.get('insuranceCardImageId')?.setValue(null);
      insGroup?.patchValue(this.newInsuranceFormGroup?.value);*/
      this.notifyText = data?.body?.msg || data?.body?.message || 'Record Deleted Successfully';
      this.isSuccessNotify = true;
      this.exceptionalModal?.modalShow();
      this.newInsuranceFormGroup?.get('insuranceCardImageId')?.setValue(null);
      this.userInfoDetailsForm?.markAsDirty();
      const isViewDownload = "view";
      this.downloadIdentificationCard(isViewDownload, this.additionalDocName, this.userInfoDetails?.id);
      this.checkUnSavedDataForm?.setValue(true);
    }, error => {
      this.loadSpinner = false;
      this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.updateUserdataCard?.cardRefreshHide();
    })
  }

  public updateFileUpload(event: any): void {
    this.callDocIframeUpdate?.emit(event);
    this.insuranceDcoumentInfoData = event;
    //this.drivingLicenseFile = true;
    this.hideInsurance = true;
    setTimeout(() => {
      const iframeEle = document.getElementById('dl-insurance-card') as HTMLElement;
      iframeEle.setAttribute('src', event.fileURL);
    }, 500);
  }

  public deleteIDRecordOpen(insurance: any, document: any, deletConfirmDocument: UiModalComponent): void {
    this.selectedMedicalRecordForDelete = document;
    this.selectedInsuranceForDelete = insurance;
    deletConfirmDocument?.show();
  }

  public updateInsuranceCardKeys(existingS3Key: any, news3Key: any, memberId: any, userInfoId: any): void {
    let action = '';
    if (news3Key !== '') {
      action = `record/updateInsuranceImageKey?existingS3Key=${existingS3Key}&news3Key=${news3Key}&userInfoId=${userInfoId}`;
    } else {
      action = `record/updateInsuranceImageKey?existingS3Key=${existingS3Key}&userInfoId=${userInfoId}`;
    }
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data?.body?.status === 'SUCCESS') {
          this.userInfoDetailsForm?.markAsDirty();

        } else {

        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
        });
  }

  public opennewInsurance(addNewInsurance: UiModalComponent) {
    this.isEditMode = false;
    //this.newInsuranceFormGroup?.disable();
    this.indexViewEditInsurance = null;
    this.newInsuranceFormGroup?.reset();
    addNewInsurance?.show();
  }

  // View or Edit the Insurnace Details
  public editViewInsurnaceDetails(insurance: any, index: number, addNewInsurance: UiModalComponent, isEdit?: boolean): void {
    if (!isEdit) {
      this.newInsuranceFormGroup?.disable();
    }
    this.editInsuranceEnable = isEdit ? true : false;
    this.isEditMode = true;
    this.selectedInsuranceDt = insurance;
    this.newInsuranceFormGroup?.patchValue(insurance);
    addNewInsurance?.show();
    this.checkPrimarySubscriber('edit', insurance);
    this.indexViewEditInsurance = index;
    this.previewInsuranceImage(insurance.insuranceCardImageId, "edit", index);
  }

  public viewInsurnaceDetails(insurance: any, index: number, viewInsurance: UiModalComponent, isEdit?: boolean): void {
    this.isEditMode = false;
    this.selectedInsuranceDt = insurance;
    this.previewInsuranceImage(insurance.insuranceCardImageId, "view", index);
    viewInsurance?.show();
  }

  // Save the newly added Details
  public saveUpdateInsuranceDetails(addNewInsurance: UiModalComponent): void {
    if (this.newInsuranceFormGroup?.invalid) {
      this.newInsuranceFormGroup?.markAllAsTouched();
      return;
    }
    // const insArray = this.userInfoDetailsForm?.get('insuranceDetails').value as FormArray;
    const insEditArray = this.userInfoDetailsForm?.get('insuranceDetails') as FormArray;

    //let insGroup = FormGroup;
    let insGroup = null;
    if (this.indexViewEditInsurance || this.indexViewEditInsurance === 0) {
      insGroup = insEditArray?.controls[this.indexViewEditInsurance] as FormGroup;
      insGroup?.patchValue(this.newInsuranceFormGroup?.value);
    } else {
      this.newInsuranceFormGroup?.get('active')?.setValue(true);
      insEditArray?.push(this.insuranceDetailsArray(this.newInsuranceFormGroup?.value));
    }

    if (this.pagename === 'demographics') {
      this.reloaddemographics?.emit(this.userInfoDetailsForm);
    }
    this.userInfoDetailsForm?.markAsDirty();
    addNewInsurance?.hide();
    this.editInsuranceEnable = false;
    this.closeEditViewNewInsurance();
  }

  // Close the Insurnace Edit View or New add insurnace
  public closeEditViewNewInsurance(): void {

    this.newInsuranceFormGroup?.enable();
    // this.newInsuranceFormGroup?.reset();
    this.indexViewEditInsurance = null;

  }

  public formatDobTimeZone(dateTime: any): any {
    return this.httpService?.formatDobTimeZoneWithDisplay(dateTime);
  }

  public addPrefixZipCode(addressZipCode: any, valid: any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
    return prefixZipcode || addressZipCode || '';
  }

  public openUniversalActions(event: any, row) {
    if (this.selectedActionRow === row) {
      this.openActions = !this.openActions;
    }
    else {
      this.openActions = true;
    }

    this.selectedActionRow = row;

  }
  public closeUniversalActions() {

    this.openActions = false;
  }

  public mouseIn() {
    this.enableActions = true;

  }

  public mouseOut() {
    this.enableActions = false;

  }


}
