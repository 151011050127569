<div class="legacy-medical-record-summary">
    <div id="DiagnosisDetails">
        <div class="row">
            <div class="col-sm-12">
                <h5>Diagnosis Details</h5>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="assesment-sec">
                    <ng-container *ngFor="let assessment of assessmentValues; let i = index">
                        <ul>
                            <li>{{assessment?.code}} - {{assessment?.codeDescription}}</li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="assessmentValues?.length === 0">
                        <h5>Diagnosis are unavailable for this patient.</h5>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!-- Medication details -->
    <div id="MedicationDetails">
        <div class="row">
            <div class="col-sm-12">
                <h5>Medication Details</h5>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 rx-order-details">
                <!-- datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders" -->
                <div class="table-responsive">
                    <table datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders"
                        class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Order Date</th>
                                <th>Drug Name</th>
                                <th>Strength</th>
                                <th>Qty</th>
                                <th>DAYS SUPPLY</th>
                                <th>Duration</th>
                                <th>Problem Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let rxOrder of allRxOrderDetails; let i = index">
                                <tr>
                                    <td>{{rxOrder?.creation_date || '--'}}</td>
                                    <td>{{rxOrder?.drug_name || '--'}}</td>
                                    <td>{{rxOrder?.drug_label_name || '--'}}</td>
                                    <td>{{rxOrder?.units_of_measure || '--'}}</td>
                                    <td>{{rxOrder?.quantity || '--'}}</td>
                                    <td>{{rxOrder?.days_supply || '--'}}</td>
                                    <td>{{rxOrder?.pharmacist_comments || '--'}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="allRxOrderDetails?.length === 0">
                                <tr>
                                    <td colspan="8" style="text-align: center;">Medications are unavailable for
                                        this patient.
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Lab Order Deatils -->
    <div id="LabOrdersResults">
        <div class="row">
            <div class="col-sm-12">
                <h5>Lab Orders Results</h5>
                <hr>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="table-responsive new-designed-table">
                    <table datatable [dtOptions]="dtOptionsLabOrders" [dtTrigger]="dtTriggerLabOrders"
                        class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Visit Date</th>
                                <th>Order Name</th>
                                <th>Lab Attribute Name</th>
                                <th>Lab Attribute Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let labResult of legacyLabOrderResults; let i = index">
                                <tr>
                                    <td>{{labResult?.lastvisitdate || '--'}}</td>
                                    <td>{{labResult?.orderName || '--'}}</td>
                                    <td>{{labResult?.labAttribute || '--'}}</td>
                                    <td>{{labResult?.labAttributeValue || '--'}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!legacyLabOrderResults && legacyLabOrderResults?.length === 0">
                                <tr>
                                    <td colspan="4" style="text-align: center;">Lab Orders Results are
                                        unavailable for this patient.</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Immunization details -->
    <div id="ImmunizationDetails">
        <div class="row">
            <div class="col-sm-12">
                <h5>Immunization Details</h5>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 rx-order-details">
                <!-- datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders" -->
                <div class="table-responsive">
                    <table datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders"
                        class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Lot Number</th>
                                <th>Administered Date</th>
                                <th>Vaccine Name</th>
                                <th>Vaccine Type</th>
                                <th>Dosage</th>
                                <th>Provider Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let immunization of allCareFlowData?.immunizationDetails; let i = index">
                                <tr>
                                    <td>{{immunization?.lotNumber || '--'}}</td>
                                    <td>{{immunization?.administeredDate || '--'}}</td>
                                    <td>{{immunization?.vaccineName || '--'}}</td>
                                    <td>{{immunization?.vaccineType || '--'}}</td>
                                    <td>{{immunization?.dosage || '--'}}</td>
                                    <td>{{immunization?.serviceProviderName || '--'}}</td>
                                    <td>{{immunization?.status || '--'}}</td>
                                </tr>
                            </ng-container>
                            <ng-container
                                *ngIf="!allCareFlowData?.immunizationDetails && allCareFlowData?.immunizationDetails?.length === 0">
                                <tr>
                                    <td colspan="7" style="text-align: center;">Immunization Details are
                                        unavailable for this patient.</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Last Service details -->
    <div class="row">
        <div class="col-sm-12">
            <h5>Last Service Provider Details</h5>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Doctor Name</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{allCareFlowData?.lastServiceProviderDetails?.name || '--'}} </label>
                </div>
            </div>
        </div>
    </div>

    <!-- Last billing PRovider -->
    <div id="LastBillingProviderDetails">
        <div class="row">
            <div class="col-sm-12">
                <h5>Last Billing Provider Details</h5>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Doctor Name</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{allCareFlowData?.lastBillingProviderDetails?.name || '--'}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>