import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { HttpService } from '../../services/http.service';
import { CardComponent } from '../../components/card/card.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-encounters-medical-records',
  templateUrl: './encounters-medical-records.component.html',
  styleUrls: ['./encounters-medical-records.component.scss']
})
export class EncountersMedicalRecordsComponent implements OnInit {
  public notifyText: any;
  public allEncountersListUser: any[];

  public dtOptionsAppointmentsList: DataTables.Settings = {};
  public dtTriggerAppointmentsList: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElementAppointmentsList: DataTableDirective;
  @ViewChild(ExceptionModalComponent) exceptionalComp: ExceptionModalComponent;
  @Input() claimsStatusCard: CardComponent;
  @ViewChild('medicalRecordsView') medicalRecordsView: UiModalComponent;
  public loadSpinner: boolean;
  public signatureForm: FormGroup;
  selectedappointmentId: any;
  providerFirstName: any;

  signaturePad!: SignaturePad;
  @ViewChild('canvas')
  canvasEl!: ElementRef;
  signatureImg!: string;
  signatureImgString!: string;
  providerLastName: any;

  constructor(private httpService: HttpService, private formBuilder: FormBuilder) {
    this.signatureForm = formBuilder?.group({
      'signature': [null, Validators?.required]
    });
  }

  ngOnInit(): void {
    this.dtOptionsAppointmentsList = {
      pagingType: 'full_numbers',
      pageLength: 10,
      //lengthChange: false,
      autoWidth: true,
      order: [[0, "desc"]],
      columnDefs: [{ targets: [0], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
  }

  // dsiplay appointment date time format
  public getAppointmentTime(date: any) {
    return this.httpService?.getAppointmentTime(date);
  }

  // to get list of Appointments by userinfo id
  public getEncountersByUserId(userInfoId?: string): void {
    if (!userInfoId) {
      this.notifyText = 'Patient is not registered, please complete the patient registration';
      this.exceptionalComp?.modalShow();
      return;
    }
    this.medicalRecordsView?.show();
    this.allEncountersListUser = [];
    this.rerender();
    this.loadSpinner = true;
    const action = `appointment/registration/completedAppointmentsByUserId?userInfoId=${userInfoId}`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const appointmentsList = (data && data.responseObject) || [];
        this.allEncountersListUser = appointmentsList?.filter(item => item?.appointmentStatus?.currentStatus === 'CHECKOUT') || [];
        // if (this.allEncountersListUser && this.allEncountersListUser?.length === 0) {
        //   this.notifyText = 'Patient is not registered, please complete the patient registration';
        //   this.exceptionalComp?.modalShow();
        // } else {
        //   this.medicalRecordsView?.show();
        // }
      } else {
        this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionalComp?.modalShow();
      }
      this.dtTriggerAppointmentsList?.next();
      this.loadSpinner = false;
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.loadSpinner = false;
      this.exceptionalComp?.modalShow();
      this.dtTriggerAppointmentsList?.next();
    });
  }

  // re render the encounters list medcal records table
  public rerender(): void {
    this.dtElementAppointmentsList?.dtInstance?.then((dtInstanceFutureAppnt: DataTables.Api) => {
      dtInstanceFutureAppnt?.destroy();
      //  this.dtTriggerFutureAppnt.next(); 
    });
  }

  public getStatus(status: string, appointment: any): string {
    return this.httpService?.getStatus(status, appointment);
  }

  public openSignatureModal(signatureModal: UiModalComponent, userid: any, firstName: any, lastName: any): void {
    this.signatureForm.reset();
    this.selectedappointmentId = userid;
    this.providerFirstName = firstName;
    this.providerLastName = lastName;
    const providername = lastName + ", " + firstName;

    const canvas = <HTMLCanvasElement>document.getElementById('textCanvas');
    const tCtx = canvas.getContext('2d');

    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://fonts.googleapis.com/css?family=Open+Sans|Rock+Salt|Shadows+Into+Light|Cedarville+Cursive';
    document.getElementsByTagName('head')[0].appendChild(link);

    //tCtx.canvas.width = tCtx.measureText(providername).width*2;
    tCtx.font = 'bold 1.5em "Rock Salt",cursive';
    tCtx.textBaseline = 'top';
    tCtx.fillText(providername, 0, 100);
    this.signatureImg = tCtx.canvas.toDataURL();
    this.signatureForm?.get('signature')?.setValue(this.signatureImg);
    signatureModal?.show();
  }

  public clearPad(): void {
    const canvas = <HTMLCanvasElement>document.getElementById('textCanvas');
    const tCtx = canvas.getContext('2d');
    tCtx.clearRect(0, 0, canvas.width, canvas.height);
    this.signatureImg = '';
    this.signatureImgString = '';
    this.signatureForm?.get('signature')?.setValue('');
  }

  public savePad(): void {
    if (this.signaturePad.isEmpty()) {
      return;
    }
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.signatureImgString = "Signature saved"
    this.signatureForm?.get('signature')?.setValue(this.signatureImg);
  }

  // Download Medical Records
  public downloadMedicalRecord(appointmentId?: string, signatureModal?: UiModalComponent, medicalRecordModal?: UiModalComponent): void {

    if (this.signatureForm?.invalid) {
      this.signatureForm?.markAllAsTouched();
      return;
    }
    const params = this.signatureForm.value;
    params.appointmentId = appointmentId;

    signatureModal?.hide();
    this.clearPad();

    // const action = `pdf/generateMedicalHistory?appointmentid=${appointmentId}`;
    const action = `pdf/generateMedicalHistory`;
    this.claimsStatusCard?.cardRefreshShow();
    this.httpService.downloadS3Image(action, params).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' });
      const blobToFile = new File([file], `${appointmentId}_Referral.pdf`, {
        type: file?.type,
      });
      var fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('medical-record-pdf') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
      if (medicalRecordModal) {
        medicalRecordModal?.show();
      }
      // let a = document.createElement('a');
      // a.href = fileURL;
      // a.target = '_blank';
      // a.download = appointmentId;
      // document.body.appendChild(a);
      // a.click();

      this.claimsStatusCard?.cardRefreshHide();
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionalComp?.modalShow();
        this.claimsStatusCard?.cardRefreshHide();
      });
  }

}
