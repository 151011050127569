import { Component, OnInit, Output, ViewChild, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from '../../services/http.service';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';


@Component({
  selector: 'app-re-schedule-appointment',
  templateUrl: './re-schedule-appointment.component.html',
  styleUrls: ['./re-schedule-appointment.component.scss']
})
export class ReScheduleAppointmentComponent implements OnInit, OnChanges {

  public rescheduleForm: FormGroup;
  public getAppointmentSlotsAvailable: any[];
  public rescheduleAppntData: any;
  public loginDetails: any;
  public getAppointmentTimeReschedule: any;
  public rescheduleAppntClick: boolean;
  public notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild('rescheduleAppointmentModal') rescheduleAppointmentModal: UiModalComponent;
  public minAppointmentDate: Date;
  public loadCard: boolean;
  @Output() onReScheduleUpdateChange = new EventEmitter<boolean>();
  @ViewChild(UiModalComponent) modalComp: UiModalComponent;
  @Input() exceptionModal: ExceptionModalComponent;
  @ViewChild(ExceptionModalComponent) private reScheduleExceptionModal: ExceptionModalComponent;
  @Input() followUp: boolean;
  // follow up emitter
  @Output() followUpCheck = new EventEmitter<boolean>();
  public redirectFlag:  boolean;
  public addPatientPurposeVisit: any[] = [];
  public isUrgentCare: boolean;
  allServiceProvidersList: any[];
  public chiefComplaintsSavedData: any;
  public chiefComplaintsForm!: FormGroup;
  public selectedChiefComp = [];
  public ROSNode: any[] = [];
  public reviewOfSystemsForm: FormGroup;
  rosSavedDataCheck: any;
  public gender: any;
  public purposeOfVisit: string;
  additonalNotes: string;
  public mainAllNodes: any;
  public moduleSummary: boolean = false;
  public allRosResoureTypes: any[] = [];
  public allDefaultResourceTypes: any[] = [];
  public allSubSectionsSelected: any[] = [];
  public addedResourcesFil: any[] = [];
  public annualWelnessAdditonalNotes: null;
  
  constructor(private _fb: FormBuilder, private httpService: HttpService) {
    this.rescheduleForm = this._fb.group({
      'APPOINTMENT_DATE': [null, [Validators?.required,this.httpService.yearValidator.bind(this)]],
      'APPOINTMENT_TIME': [null, Validators.required],
      'END_TIME': [null],
      'PURPOSE_OF_VISIT': [null, Validators?.required],
      'DOCTOR': [null, Validators?.required],
      'doctor_first_name': [null],
      'doctor_last_name': [null]
    });

    this.chiefComplaintsForm = this._fb.group({
      'additionalNotes': [null],
      'otherComplaints': [null],
    });

    this.reviewOfSystemsForm = this._fb?.group({
      'allSectionsModules': this._fb?.array([])
    });
  }

  ngOnInit(): void {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.minAppointmentDate = this.httpService.convertDate(new Date());
    this.addPatientPurposeVisit = JSON.parse(sessionStorage.getItem('waitTimeDetails'))?.purposeOfVisit || [];
    this.isUrgentCare = JSON.parse(sessionStorage.getItem('waitTimeDetails'))?.urgentcare || false;
    if (this.isUrgentCare) {
      this.rescheduleForm?.get('DOCTOR')?.setValidators(null);
      this.rescheduleForm?.get('DOCTOR')?.updateValueAndValidity();
    }

    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.followUp) {

      }
    }
  }

  // opem popup and set the sellected appointment details
  public openSetAppointment(appointment: any): void {
    console.log("rescheduledAppt",appointment);
    this.addPatientPurposeVisit = JSON.parse(sessionStorage.getItem('waitTimeDetails'))?.purposeOfVisit || [];
    this.isUrgentCare = JSON.parse(sessionStorage.getItem('waitTimeDetails'))?.urgentcare || false;
    this.rescheduleAppointmentModal.show();
    this.rescheduleAppntData = appointment;
    if (appointment && (appointment.appointment.dateTime || appointment.followupDate) ) {
      const splitDate = appointment.appointment.dateTime.toString().split('T');
      const timeSplit = splitDate[1].split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const formateDate = splitDate && splitDate[0];
      // this.rescheduleForm.get('APPOINTMENT_DATE').setValue(formateDate);
      // this.rescheduleForm.get('APPOINTMENT_TIME').setValue(formateDate);
    }
    let isPurposeCheck = this.addPatientPurposeVisit?.find((item: any) => item === appointment?.appointment?.purposeOfVisit) || null;
    if (this.followUp) {
      isPurposeCheck = this.addPatientPurposeVisit?.find((item: any) => item === 'Follow-Up') || null;
      if(!isPurposeCheck) {
        isPurposeCheck = this.addPatientPurposeVisit?.find((item: any) => item === 'Follow Up') || null;
      }
    }
    if(isPurposeCheck) {
      this.rescheduleForm?.get('PURPOSE_OF_VISIT')?.setValue(isPurposeCheck || appointment?.appointment?.purposeOfVisit || null);
      this.getDoctorsList();
      setTimeout(() => {
        this.rescheduleForm?.get('DOCTOR')?.setValue(appointment?.appointment?.doctorNpi || null);
        this.rescheduleForm?.get('doctor_first_name')?.setValue(appointment?.appointment?.authorized_official_first_name || null);
        this.rescheduleForm?.get('doctor_last_name')?.setValue(appointment?.appointment?.authorized_official_last_name || null);
      }, 1000);
    }else{
      this.rescheduleForm?.get('PURPOSE_OF_VISIT')?.setValue(null);
    }
    // this.getAppointmentSlotsReschedule();
    this.purposeOfVisit = appointment?.purposeOfVisit;
    this.gender = appointment?.gender;
    this.readChiefComplaintsData(appointment?.id, appointment?.userInfoId);
    this.getROSModuleInfo(appointment?.id, appointment?.userInfoId);
   // this.readROSData(appointment?.id, appointment?.userInfoId);
  }

  // change slot time
  public changeSlotTime(): void {
    const startTime = this.rescheduleForm?.get('APPOINTMENT_TIME')?.value;
    const timeObj = this.getAppointmentTimeReschedule?.find(item => item?.startTime === startTime);
    this.rescheduleForm?.get('END_TIME')?.setValue(timeObj?.endTime);
  }

  // get the reschedule slots
  public getAppointmentSlotsReschedule(): void {
    const appointmentDate = this.rescheduleForm.value.APPOINTMENT_DATE;
    this.rescheduleForm?.get('APPOINTMENT_DATE')?.setValidators([Validators?.required,this.httpService.yearValidator.bind(this)]);
    if(!appointmentDate || appointmentDate === ""){
      this.rescheduleForm?.get('APPOINTMENT_DATE')?.setValue(null);
      this.rescheduleForm?.get('APPOINTMENT_TIME')?.disable();
      this.rescheduleForm?.get('APPOINTMENT_TIME')?.setValue(null);
      return;
    }
    if(this.rescheduleForm?.get('PURPOSE_OF_VISIT')?.invalid || this.rescheduleForm?.get('DOCTOR')?.invalid) {
      this.rescheduleForm?.get('PURPOSE_OF_VISIT')?.markAsTouched();
      this.rescheduleForm?.get('DOCTOR')?.markAsTouched();
      this.rescheduleForm?.get('APPOINTMENT_DATE')?.setValue(null);
      return;
    }
    this.loadCard = true;
    this.getAppointmentSlotsAvailable = [];
    // this.rescheduleForm.get('APPOINTMENT_TIME').disable();
    this.rescheduleForm.get('APPOINTMENT_TIME').setValue(null);
    let action: any;
    
    const doctorNpi = this.rescheduleForm?.get('DOCTOR')?.value || (this.rescheduleAppntData && this.rescheduleAppntData.appointment && this.rescheduleAppntData.appointment.doctorNpi) || null;
    const isNewPatient = (this.rescheduleAppntData && this.rescheduleAppntData.newPatient);
    const typeOfService = (this.rescheduleAppntData && this.rescheduleAppntData.appointment && this.rescheduleAppntData.appointment.typeOfService) || null;
    const purposeOfVisit = this.rescheduleForm?.get('PURPOSE_OF_VISIT')?.value || (this.rescheduleAppntData && this.rescheduleAppntData.appointment && this.rescheduleAppntData.appointment.purposeOfVisit) || null;

    action = `appointment/registration/getAvailableSlotsForDay`;
    let params: any =
    {
      'doctorNpi': doctorNpi || this.rescheduleAppntData?.doctorNpi,
      'docFirstName': this.rescheduleForm?.get('doctor_first_name')?.value || this.rescheduleAppntData?.authorized_official_first_name || this.rescheduleAppntData?.appointment?.authorized_official_first_name,
      'docLastName': this.rescheduleForm?.get('doctor_last_name')?.value || this.rescheduleAppntData?.authorized_official_last_name|| this.rescheduleAppntData?.appointment?.authorized_official_last_name,
      'facilityDate': appointmentDate,
      'facilityId': this.loginDetails.facilityId,
      'newPatient': isNewPatient,
      // 'purposeOfVisit': (this.followUp ? 'Follow-Up' : purposeOfVisit) || this.rescheduleAppntData?.purposeOfVisit,
      'purposeOfVisit': purposeOfVisit || this.rescheduleAppntData?.purposeOfVisit,
      'typeOfService': typeOfService ||  this.rescheduleAppntData?.typeOfService
    }
    
    
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data?.body.status === 'SUCCESS') {
          const allSlots = (data && data?.body?.resultList) || [];
          allSlots?.forEach(element => {
            const split = element?.startTime.split(':');
            const array = split?.splice(2, 1);
            element.startTime = split?.join(':');
            element.descStartTime = this.httpService?.timeConvert(element?.startTime);
          });
          this.getAppointmentTimeReschedule = allSlots || [];
          if (this.getAppointmentTimeReschedule?.length === 0) {
            this.notifyText = 'No slots are available for selected date. Please choose other date for re-schedule your appointment.';
            this.isSuccessNotify = false;
            this.reScheduleExceptionModal?.modalShow();
          }
          // this.rescheduleForm?.get('APPOINTMENT_TIME')?.enable();
          this.loadCard = false;
        } else {
          this.rescheduleForm?.get('APPOINTMENT_TIME')?.setValue(null);
          this.getAppointmentTimeReschedule = [];
          this.notifyText = data?.body?.message || 'No slots are available for selected date. Please choose other date.';
          this.isSuccessNotify = false;
          this.reScheduleExceptionModal?.modalShow();
          this.loadCard = false;
        }
      },
        error => {
          this.rescheduleForm?.get('APPOINTMENT_TIME')?.setValue(null);
          this.getAppointmentTimeReschedule = [];
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.reScheduleExceptionModal?.modalShow();
          this.loadCard = false;
        });
  }
  
  // re-schedule update
  public reschduleUpdate(rescheduleModal: any): void {
    if (this.rescheduleForm?.invalid || !this.rescheduleForm?.value?.APPOINTMENT_TIME) {
      this.rescheduleForm?.markAllAsTouched();
      return;
    }
    const selectedrescheduleAppDetails = { ...this.rescheduleAppntData };
    // this.cancelAppointmentOnReSchedule(this.rescheduleAppntData?.webToken);
    const chiefComplaints = this.rescheduleAppntData?.chiefComplaints;
    const action = 'appointment/registration/webAppointment';
    delete this.rescheduleAppntData?.id;
    delete this.rescheduleAppntData?.appointmentStatus;
    delete this.rescheduleAppntData?.webToken;
    delete this.rescheduleAppntData?.qrToken;
    const params = this.rescheduleAppntData;
    const dateTime = `${this.rescheduleForm?.value?.APPOINTMENT_DATE}T${this.rescheduleForm?.value?.APPOINTMENT_TIME}`;
    const endTime = `${this.rescheduleForm?.value?.APPOINTMENT_DATE}T${this.rescheduleForm?.value?.END_TIME}`;
    const appointment = {
      "dateTime": dateTime,
      "doctorNpi": this.rescheduleForm?.value?.DOCTOR || this.rescheduleAppntData?.appointment?.doctorNpi,
      // "purposeOfVisit": this.followUp ? 'Follow-Up' : this.rescheduleForm?.value?.PURPOSE_OF_VISIT || this.rescheduleAppntData?.appointment?.purposeOfVisit,
      "purposeOfVisit": this.rescheduleForm?.value?.PURPOSE_OF_VISIT || this.rescheduleAppntData?.appointment?.purposeOfVisit,
      "typeOfService": this.rescheduleAppntData?.appointment?.typeOfService,
      "endTime": endTime || null,
      "authorized_official_first_name": this.rescheduleForm?.value?.doctor_first_name || null,
      "authorized_official_last_name": this.rescheduleForm?.value?.doctor_last_name || null
    }
    if (!this.rescheduleAppntData?.patientPrimaryPhone?.includes('+1')) {
      this.rescheduleAppntData.patientPrimaryPhone = `+1${this.rescheduleAppntData?.patientPrimaryPhone}` || null;
    }
    params.appointment = appointment;
    params.appointment['scheduledAppt'] = true;
    this.rescheduleAppntClick = true;
    this.loadCard = true;
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data?.body?.status === 'SUCCESS') {
          const userInfoId = data?.body?.responseObject?.userInfoId;
          const appointmentId = data?.body?.responseObject?.id;
         if(chiefComplaints !== null){
          this.saveChiefComplaintsData(appointmentId, userInfoId);
         }
         this.saveReviewOfSystemsData(appointmentId, userInfoId);
          
          
          if(!data?.body?.responseObject) {
            rescheduleModal.hide();
            this.rescheduleForm.reset();
            this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
            this.reScheduleExceptionModal?.modalShow();
            this.onReScheduleUpdateChange.emit(true);
            this.rescheduleAppntClick = false;
            this.loadCard = false;
            return;
          }
          if (!this.followUp) {
            this.statusUpdate(selectedrescheduleAppDetails?.id, 're-schedule');
          }else{
            this.onReScheduleUpdateChange.emit(true);
          }
          rescheduleModal.hide();
          this.rescheduleForm.reset();
          this.isSuccessNotify = true;
          this.notifyText = 'Appointment has been Re-scheduled successfully.';
          if (this.followUp) {
            this.notifyText = 'Follow-Up Appointment has been scheduled successfully.';
            this.redirectFlag = true;
          }
          this.reScheduleExceptionModal?.modalShow();
        } else if (data?.body?.status === 'FAILED') {
          this.notifyText = data.body.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.reScheduleExceptionModal?.modalShow();
        }
        this.rescheduleAppntClick = false;
        this.loadCard = false;
        this.followUpEmitter(false);
        // this.redirectFlag = false;
      },
        error => {
          this.rescheduleAppntClick = false;
          this.loadCard = false;
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.reScheduleExceptionModal?.modalShow();
          this.followUpEmitter(true);
          this.redirectFlag = false;
        });
  }

  // This function is for to update the patient status
  public statusUpdate(appId: any, status: any): void {
    let action: any = 'appointment/registration/updateStatus';
    let params: any = {
      "apiName": "",
      "appointmentId": appId,
      "buttonClick": status,
      "cancellationReason": "",
      "email": this.loginDetails.userId,
      "facilityID": this.loginDetails.facilityId
    }
    if (status === 're-schedule') {
      params.cancellationReason = 're-schedule',
        params.buttonClick = 'cancel';
    }
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data.body.status == 'SUCCESS') {
          this.onReScheduleUpdateChange.emit(true);
          // this.getPatientsByFacilityId(this.loginDetails.facilityId);
        }
      },
        error => {
          this.reScheduleExceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.reScheduleExceptionModal.modalShow();
        });
  }

  // cancel appointment on reschedule
  public cancelAppointmentOnReSchedule(webToken: any): void {
    let action: any = `appointment/registration/cancel?id=${webToken}&reason=re-schedule`;
    this.httpService.makeRequestWithAction(action, 're-schedule')
      .subscribe((data: any) => {

      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.reScheduleExceptionModal?.modalShow();
        })
  }

  // follow up appointment emitter to parent
  public followUpEmitter(status: boolean): void {
    if(this.followUp){
      this.followUpCheck?.next(status);
    }
  }

  // get service providers based on purpose of visit selection
  public getDoctorsList(): void {
    this.allServiceProvidersList = [];
    this.rescheduleForm?.get('DOCTOR')?.setValue(null);
    this.rescheduleForm?.get('APPOINTMENT_DATE')?.setValue(null);
    this.rescheduleForm?.get('APPOINTMENT_TIME')?.setValue(null);
    const purposeOfVisit = this.rescheduleForm?.value?.PURPOSE_OF_VISIT || '';
    const action = `npi/getvalidNPIforvisit?facilityId=${this.loginDetails?.facilityId}&newPatient=${this.rescheduleAppntData?.newPatient}&purposeOfVisit=${purposeOfVisit}`;
    this.loadCard = true;
    this.httpService.makeGetRequest(action, '').subscribe(
      (data: any) => {
        // to get first name and last name of doctors
        const doctors =
          JSON?.parse(
            sessionStorage?.getItem('waitTimeDetails')
          )?.eligibilityDetails?.doctor?.map((doctorItem: any) => {
            return {
              firstName:
                doctorItem?.basic?.authorized_official_first_name || '',
              lastName: doctorItem?.basic?.authorized_official_last_name || '',
              npi: doctorItem?.number,
              fullName: `${doctorItem?.basic?.authorized_official_first_name || ''
                } ${doctorItem?.basic?.authorized_official_last_name || ''}`,
            };
          }) || [];

        data?.npiDoctorList?.forEach((item) => {
          const getDoctor = doctors?.find(
            (subDoc) =>
              subDoc?.npi === item.npi &&
              item?.doctorName?.includes(subDoc?.firstName || subDoc?.lastName)
          );
          item.firstName = getDoctor?.firstName;
          item.lastName = getDoctor?.lastName;
        });

        this.allServiceProvidersList = data?.npiDoctorList || [];
        this.loadCard = false;
      },
      (error) => {
        this.exceptionModal.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal.modalShow();
        this.loadCard = false;
      }
    );
  }

   // when we change the doctor select
   public ifServiceProviderChange(event?: any): void {
    this.rescheduleForm?.get('doctor_first_name')?.setValue(event?.firstName);
    this.rescheduleForm?.get('doctor_last_name')?.setValue(event?.lastName);
  }

  public readChiefComplaintsData(appointmentId: any, userInfoId: any): void {
    const action = `careFlow/readCareFlowModuleInfo?facilityId=${this.loginDetails.facilityId}&appointmentId=${appointmentId}&moduleName=Chief Complaints&userInfoId=${userInfoId}`;
    this.httpService.makeGetRequest(action, '').subscribe(
      (data: any) => {
        
        if (data?.status === 'SUCCESS') {
          this.chiefComplaintsSavedData = data?.responseObject || null;
          const mainObjtoPatch = {};
          data?.responseObject?.forEach((item, index) => {
            this.chiefComplaintsForm?.get('additionalNotes')?.setValue(item?.otherNotes || null);
            item?.sections.forEach((item2, index2) => {
              item2?.answers?.forEach((item3, index3) => {
                mainObjtoPatch[item3?.actualValue[0]?.answer] = item3?.actualValue[0]?.answer;
                this.selectedChiefComp?.push(item3?.actualValue[0]?.answer);
                if (item3?.actualValue && item3?.actualValue[0]?.notes) {
                  this.chiefComplaintsForm?.get('otherComplaints')?.setValue(item3?.actualValue[0]?.notes);
                }
              });
            });
          });
         
          this.chiefComplaintsForm?.patchValue(mainObjtoPatch);
        }
      },
      (error) => {
        
      }
    );
  }

  public saveChiefComplaintsData(appointmentId: any, userInfoId: any): void {
    if (this.chiefComplaintsSavedData?.length === 0) {
      return;
    }
    
    const action = `careFlow/saveCareFlowModuleInfo`;
    let arr = []
    this.selectedChiefComp.forEach((item, index) => {
      if (item === 'Other') {
        arr.push({ actualValue: [{ answer: item, notes: this.chiefComplaintsForm?.get('otherComplaints')?.value || null }] });
      } else {
        arr.push({ actualValue: [{ answer: item }] });
      }
    });

    
    const params = {
      appointmentId: appointmentId,
      facilityId: this.loginDetails.facilityId,
      userInfoId: userInfoId,
      moduleName: 'Chief Complaints',
      careFlowModule: [
        {
          auditInfo: {
            "createdBy": "",
            "createdDate": "",
            "updatedBy": "",
            "updatedDate": ""
          },
          completed: true,
          module: 'Chief Complaints',
          otherNotes: this.chiefComplaintsForm?.get('additionalNotes')?.value || null,
		  resourceType: 'Chief Complaints',
          sections: [
            {
              answers: arr,
              name: "Chief Complaints",
            },
          ],
        },
      ],
    };
   
    this.httpService?.makeRequestWithAction(action, params)?.subscribe(
      (data: any) => {
        if (data?.body?.status === 'SUCCESS') {
		 sessionStorage?.setItem('allChiefComplaints', null);
        }
        
        
      },
      (error) => {
        
      }
    );
  }

  public checkGenderPurposeVisit(modeMetaData: any): boolean {
    if(!modeMetaData) {
      return true;
    }
    if ((modeMetaData?.gender === 'All' || modeMetaData?.gender === this.gender) && (modeMetaData?.purposeOfVisit?.includes('All') || modeMetaData?.purposeOfVisit?.includes(this.purposeOfVisit))) {
      return true;
    }
    return false;
  }

  public getROSModuleInfo(appointmentId: any, userInfoId: any): void {
    
    // this.httpService?.clearFormArray(this.reviewOfSystemsForm?.get('allSectionsModules') as FormArray);
    const action = `careFlow/getCareFlowModuleAllInfo?facilityId=${this.loginDetails.facilityId}&module=ROS&name=CareFlow-ModuleDetails`;
    
    this.httpService.makeGetRequest(action, '').subscribe(
      (data: any) => {
        
        if (data?.status === 'SUCCESS') {
          // this.mainAllNodes = data?.responseObject?.resourceType || [];

          this.mainAllNodes = JSON.parse(JSON.stringify(data?.responseObject?.resourceType || []));
          this.moduleSummary = data?.responseObject?.moduleSummary;
          // loop and created dynamic formGroups
          const rosNodeSections = JSON.parse(JSON?.stringify(this.mainAllNodes)) || [];

          rosNodeSections?.forEach((rosFormItem: any) => {

            if (rosFormItem?.sectionMetadata && !this.checkGenderPurposeVisit(rosFormItem?.sectionMetadata)) {
              return;
            }

            let sectionsForm: FormGroup;
            sectionsForm = this._fb?.group({
              'module': ['ROS' || null],
              'resourceType': [rosFormItem?.name || null],
              'sections': this._fb?.array([]),
              'additionalText': [rosFormItem?.additionalText || null],
              'default': [rosFormItem?.default || null]
            });

            if (rosFormItem?.additionalText) {
              sectionsForm?.addControl('otherNotes', new FormControl(null));
            }

            // looping all sections to craete parent Form Groups
            rosFormItem?.sections?.forEach((secItem: any) => {

              let formControls: FormGroup;
              formControls = this._fb?.group({
                'name': [secItem?.name, null],
                'answers': this._fb?.array([]),
                'additionalText': [secItem?.additionalText || null],
              });
              if (secItem?.additionalText) {
                formControls?.addControl('otherNotes', new FormControl(null));
              }

              const subControlsFormArray = formControls?.get('answers') as FormArray;

              // formControls?.get('name')?.setValue(secItem?.name);
              this.httpService?.clearFormArray(subControlsFormArray as FormArray);

              this.allRosResoureTypes = [...this.allRosResoureTypes, ...secItem?.questions?.map(qItem => qItem?.label)];
              const checkAllDefault = secItem?.questions?.map(qItem => qItem?.default && qItem?.label) || [];
              this.allDefaultResourceTypes = checkAllDefault?.filter(item => item);
              // looping the questions or sub sections
              secItem?.questions?.forEach((queItem: any) => {
                // creating the Form Array if have multiselect with Sub Sections
                if (queItem?.subSections && queItem?.subSections?.length > 0) {

                  let subControls: FormGroup = this._fb?.group({
                    'label': [queItem?.label || null],
                    'actualValue': [null],
                    'additionalText': [queItem?.additionalText],
                    'properties': [queItem?.properties || null],
                    'values': [queItem?.values || null],
                    'subSections': this._fb?.array([]),
                    'selectedSubValues': this._fb?.array([]),
                    'default': [queItem?.default || null]
                  });
                  if (queItem?.additionalText) {
                    subControls?.addControl('otherNotes', new FormControl(null));
                  }
                  const subItemArrayForm = subControls?.get('subSections') as FormArray;
                  // This is Sub sections Form Group

                  queItem?.subSections?.forEach((arrayItem: any) => {
                    const subItemGroup: FormGroup = this._fb?.group({
                      'label': [arrayItem?.label || null],
                      'additionalText': [arrayItem?.additionalText],
                      'properties': [arrayItem?.properties || null],
                      'values': [arrayItem?.values || null],
                    });
                    if (arrayItem?.additionalText) {
                      subItemGroup?.addControl('otherNotes', new FormControl(null));
                    }
                    let controlAdd: string;
                    controlAdd = arrayItem?.label;
                    if (arrayItem?.label?.includes('Duration')) {
                      controlAdd = 'duration';
                    }
                    if (arrayItem?.label?.includes('Severity')) {
                      controlAdd = 'severityStatus';
                    }
                    subItemGroup?.addControl(controlAdd, new FormControl(null));
                    subItemArrayForm?.push(subItemGroup);
                  });
                  subControlsFormArray?.push(subControls);

                } else if (!queItem?.subSections) {
                  let subControls: FormGroup = this._fb?.group({
                    'label': [queItem?.label || null],
                    'actualValue': [null],
                    'additionalText': [queItem?.additionalText || null],
                    'properties': [queItem?.properties || null],
                    'values': [queItem?.values || null],
                    'default': [queItem?.default || null]
                  });
                  if (queItem?.additionalText) {
                    subControls?.addControl('otherNotes', new FormControl(null));
                  }
                  subControlsFormArray?.push(subControls);
                }
              });

              const sectionsFormArray = sectionsForm?.get('sections') as FormArray;
              sectionsFormArray?.push(formControls);
            });

            const allGroups = this.reviewOfSystemsForm?.get('allSectionsModules') as FormArray;
            allGroups?.push(sectionsForm);
          });
          }
        this.readROSData(appointmentId, userInfoId);
      },
      (error) => {
        
      }
    );
  }

  public readROSData(appointmentId: any, userInfoId: any): void {
    const action = `careFlow/readCareFlowModuleInfo?facilityId=${this.loginDetails.facilityId}&appointmentId=${appointmentId}&moduleName=ROS&userInfoId=${userInfoId}`;
     this.httpService.makeGetRequest(action, '').subscribe(
      (data: any) => {
       
        if (data?.status === 'SUCCESS') {
          

          if (!data?.responseObject) {
            this.allDefaultResourceTypes?.forEach((addDefaultItem: any) => {
              this.autoCompleteAdd(addDefaultItem);
            });
            // this.allSubSectionsSelected = JSON.parse(JSON?.stringify(this.allDefaultResourceTypes || []));
            this.addedResourcesFil = JSON.parse(JSON?.stringify(this.allDefaultResourceTypes || []));
            return
          }

          this.rosSavedDataCheck = data?.responseObject || null;
          const rosFormArray = this.reviewOfSystemsForm?.get('allSectionsModules') as FormArray;

          let allData = JSON?.parse(JSON.stringify(data?.responseObject || []));
          const filetredData = [];
          allData?.forEach((item: any) => {
            const getNodeResourceType = this.mainAllNodes?.find((nodeItem: any) => nodeItem?.name === item?.resourceType);
            if (this.checkGenderPurposeVisit(getNodeResourceType?.sectionMetadata)) {
              filetredData?.push(item);
            }
          });

          filetredData?.forEach((item: any) => {
            if (item?.otherNotes && item?.otherNotes !== "" && item?.resourceType === 'Review of Systems') {
              this.additonalNotes = item?.otherNotes || '';
            }
            if (item?.otherNotes && item?.otherNotes !== "" && item?.resourceType === 'Annual Wellness Visit') {
              this.annualWelnessAdditonalNotes = item?.otherNotes || '';
            }
            const getMainNode = this.mainAllNodes?.find((nodeItem: any) => nodeItem?.name === item?.resourceType);

            item?.sections?.forEach((secItem: any, index: number) => {
              const getSection = getMainNode?.sections?.find((nodeSecItem: any) => nodeSecItem?.name === secItem?.name);

              secItem?.answers?.forEach((ansItem: any) => {

                // Updating as Checked values
                const getAnswerSec = getSection?.questions?.find((nodeAnsItem: any) => nodeAnsItem?.label === ansItem?.label);

                if (Array?.isArray(ansItem.actualValue)) {
                  const dupValues = JSON.parse(JSON?.stringify(ansItem?.actualValue));
                  ansItem.actualValue = dupValues?.map((mapItem: any) => mapItem?.answer || mapItem?.label);

                  // if (ansItem.actualValue?.includes('Yes') || ansItem.actualValue?.includes('No')) {
                  //   ansItem.actualValue = ansItem?.actualValue?.toString();
                  // }
                  ansItem.selectedSubValues = dupValues;

                  const formGroupResourceType = rosFormArray?.controls?.find((group: FormGroup) => group?.value?.resourceType === item?.resourceType);
                  const getSecArray = formGroupResourceType?.get('sections') as FormArray;
                  const getSecGroup = getSecArray?.controls?.find((secGroup: FormGroup) => secGroup?.value?.name === secItem?.name);
                  const answerArray = getSecGroup?.get('answers') as FormArray;
                  const ansGroupItem = answerArray?.controls?.find((ansGroup: FormGroup) => ansGroup?.value?.label === ansItem?.label);
                  // dupValues?.forEach((ansItemPatchItem: any) => {
                  //   this.addaddlBloodPressure(ansGroupItem as FormGroup, ansItemPatchItem);
                  // });

                } else {
                  ansItem.actualValue = ansItem?.actualValue?.toString();
                }

                if (ansItem?.actualValue && ansItem?.actualValue?.length > 0) {
                  ansItem?.actualValue?.forEach((checkedItems: any) => {
                    const getCheckedLabel = getAnswerSec?.values?.find((nodeAnsCheckedItem: any) => checkedItems === nodeAnsCheckedItem?.actualValue);
                    if (getCheckedLabel) {
                      getCheckedLabel.isChecked = true;
                    }
                  });
                  this.allSubSectionsSelected?.push(ansItem?.label);
                }

              });
            });
          });

          rosFormArray?.controls?.forEach((formGroupItem: FormGroup) => {
            const getModule = this.rosSavedDataCheck?.find((dataItem: any) => dataItem?.resourceType === formGroupItem?.value?.resourceType) || null;
            const sectionArray = formGroupItem?.get('sections') as FormArray;
            formGroupItem?.get('otherNotes')?.setValue(getModule?.otherNotes || "");

            sectionArray?.controls?.forEach((secFormGroup: FormGroup) => {
              const getAnswersArray = secFormGroup?.get('answers') as FormArray;

              const readSecData = getModule?.sections?.find((secItemData: any) => secItemData?.name === secFormGroup?.value?.name);

              if (readSecData) {
                getAnswersArray?.controls?.forEach((ansForm: FormGroup) => {
                  let ansItemData = readSecData?.answers?.find((ansDataItem: any) => ansDataItem?.label === ansForm?.value?.label);
                  if (ansItemData) {
                    ansItemData.actualValue = ansItemData?.actualValue?.map(acIt => acIt?.answer) || [];
                    ansForm?.patchValue(ansItemData);
                  }
                });
              }

            });
          });

          this.ROSNode = this.mainAllNodes?.filter(checkSavedItem => filetredData?.find(formItem => formItem?.resourceType === checkSavedItem?.name));

          this.ROSNode = JSON?.parse(JSON?.stringify(this.ROSNode))

          this.ROSNode?.forEach((mainNode: any) => {
            mainNode.isShown = null;
            if (mainNode?.sections && mainNode?.sections) {
              mainNode?.sections?.forEach((secNode: any) => {
                if (secNode?.questions && secNode?.questions) {
                  let questionsCheck = [];
                  secNode?.questions?.forEach((qItem: any) => {
                    let checkedValues = [];
                    qItem?.values?.forEach((checkQVal: any) => {
                      if (checkQVal?.isChecked) {
                        checkedValues?.push(checkQVal);
                      }
                    });
                    if (checkedValues?.length > 0) {
                      questionsCheck?.push(qItem)
                    }
                  });
                  secNode.questions = questionsCheck;
                };
              });
            };
          });
          
         
        }
        
      },
      (error) => {
        
      }
    );
  }


  public saveReviewOfSystemsData(appointmentId: any, userInfoId: any): void {
    if (this.mainAllNodes?.length === 0) {
         return;
    }
    const action = `careFlow/saveCareFlowModuleInfo`;

    let requestPayload = JSON?.parse(JSON?.stringify(this.reviewOfSystemsForm?.get('allSectionsModules')?.value || []));
   
    requestPayload?.forEach((item: any) => {
      item.otherNotes = item?.resourceType === 'Review of Systems' ? this.additonalNotes : this.annualWelnessAdditonalNotes;
      item.completed = true;
      item.auditInfo = {
        "createdBy": "",
        "createdDate": "",
        "updatedBy": "",
        "updatedDate": ""
      }
      item?.sections?.forEach((secItem: any) => {
        secItem?.answers?.forEach((ansItem: any) => {
          if (Array?.isArray(ansItem.actualValue)) {
            ansItem.actualValue = ansItem?.actualValue?.map(mapItem => {
              return {
                answer: mapItem?.actualValue || mapItem
              }
            });
          } else {
            ansItem.actualValue = ansItem?.actualValue ? [{ answer: ansItem?.actualValue }] : [];
          }
          delete ansItem?.additionalText;
          delete ansItem?.properties;
          delete ansItem?.subSections;
          delete ansItem?.values;
          delete ansItem?.selectedSubValues;
        })
      });
    });

    requestPayload = requestPayload?.filter(resItem => resItem?.resourceType !== 'Additional Notes') || [];
    const onlyDataAvilableResourceTypes: any = this.httpService?.sendOnlyDataGivenResourceTypes(requestPayload) || [];

    if (onlyDataAvilableResourceTypes && onlyDataAvilableResourceTypes?.length > 0 && !this.rosSavedDataCheck) {
      const allSections = [];
      onlyDataAvilableResourceTypes?.forEach((checkItem: any) => {
        if (checkItem && (checkItem?.sections?.length > 0 || checkItem?.otherNotes)) {
          allSections?.push(checkItem);
        }
      });
      
    };
   
    const params = {
      appointmentId: appointmentId,
      facilityId: this.loginDetails.facilityId,
      userInfoId: userInfoId,
      moduleName: 'ROS',
      careFlowModule: onlyDataAvilableResourceTypes || [],
    };
    
    this.httpService?.makeRequestWithAction(action, params)?.subscribe(
      (data: any) => {
        if (data?.body?.status === 'SUCCESS') {
        }
       
        this.updateAppointmentQuestionier(appointmentId);
      },
      (error) => {
        
      }
    );
  }

  // Update Appointment Questionier
  public updateAppointmentQuestionier(appointmentId): void {
    const action = `appointment/registration/updateAppointmentQuestionnaire?appointmentId=${appointmentId}`;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {

    },
      error => {

      });
  }

  public autoCompleteAdd(event: any): void {
    const existingResources = this.reviewOfSystemsForm?.get('allSectionsModules') as FormArray;
    const selectedResource = existingResources?.controls?.find((item: FormGroup) => item?.value?.resourceType === event) as FormGroup;
    const getEventResource = this.mainAllNodes?.find(item => item?.name === event);
    const mainDupAllNodes = JSON?.parse(JSON?.stringify(this.mainAllNodes));

    let mainNodeName: string;
    if (!this.allSubSectionsSelected?.find(checkit => checkit === event)) {

      this.allSubSectionsSelected?.push(event);

      mainDupAllNodes?.forEach((mainNode: any) => {
        const selectedNode = this.ROSNode?.find(selNode => selNode?.name === mainNode?.name);
        if (mainNode?.sections && mainNode?.sections) {
          mainNode?.sections?.forEach((secNode: any) => {
            const sectionsSel = selectedNode?.sections?.find(selSecItem => selSecItem?.name === secNode?.name);
            if (secNode?.questions && secNode?.questions) {
              const getSelQue = secNode?.questions?.find(qItem => qItem?.label === event);

              const allResourceTypeArray = this.reviewOfSystemsForm?.get('allSectionsModules') as FormArray;
              let getFormGroup = allResourceTypeArray?.controls?.find((groupItem: FormGroup) => mainNode?.name === groupItem?.value?.resourceType) as FormGroup;

              const sectionsArray = getFormGroup?.get('sections') as FormArray;
              const secFormGroup = sectionsArray?.controls?.find((secItem: FormGroup) => mainNode?.name === secItem?.value?.name) as FormGroup;

              const answersArray = secFormGroup?.get('answers') as FormArray;
              const getAnsFormGroup = answersArray?.controls?.find((ansItem: FormGroup) => getSelQue?.label === ansItem?.value?.label) as FormGroup;

              if (getSelQue) {
                getSelQue?.values?.forEach((checkValItem: any) => {
                  // if (checkValItem.actualValue === 'Normal' || checkValItem.actualValue === 'None') {
                  //   checkValItem.isChecked = true;
                  // }
                  if (checkValItem?.normal) {
                    checkValItem.isChecked = true;
                    let value = JSON.parse(JSON?.stringify(getAnsFormGroup?.get('actualValue')?.value || []));
                    value?.push(checkValItem?.actualValue);
                    getAnsFormGroup?.get('actualValue')?.setValue(value);
                  } else {
                    checkValItem.isChecked = false;
                  }
                });
                sectionsSel?.questions?.push(getSelQue);
                mainNodeName = mainNode?.name;
              }
              secNode.questions = secNode?.questions?.filter(qItem => this.allSubSectionsSelected?.includes(qItem?.label));
            };
          });
        };
        if ((!this.ROSNode?.find(nodeCheck => nodeCheck?.name === mainNode?.name) && mainNodeName) && mainNodeName === mainNode?.name) {
          this.ROSNode?.push(mainNode);
        }
        if (selectedNode && selectedNode?.name === mainNodeName) {
          selectedNode.isShown = null;
        }
        //this.getSelectedRos();
      });

      
    }
  }

  // Input Appnt date validation 
  public inputAppntDateValidation($event: any, rescheduleForm: FormGroup, contrlName?: string): void {
    this.httpService?.validateAppointmentDate($event, rescheduleForm, contrlName);
  }

  // Change appnt date with validation
  public changeAppntDateWithValidation(rescheduleForm?: FormGroup, controlName?: string): void {
    const currentYear = new Date().getFullYear()
    // const enteredDate = new Date(this.rescheduleForm?.get('APPOINTMENT_DATE')?.value);
    const enteredDate = new Date(this.rescheduleForm?.get('APPOINTMENT_DATE')?.value);
    
    if (enteredDate?.getFullYear() <= 1900 || enteredDate?.getFullYear() > currentYear) {
      
      return;
    }
    // if (enteredDate?.getFullYear() < this.currentYear) {
    //   return;
    // }
    if (this.httpService?.changeAppntDateWithValValidation(this.rescheduleForm?.get('APPOINTMENT_DATE')?.value)) {
      this.rescheduleForm?.get('APPOINTMENT_DATE')?.setValue(null);
      return;
    }
    // if (!this.httpService?.changeAppntDateWithValValidation(this.rescheduleForm?.get('APPOINTMENT_DATE')?.value, rescheduleForm, controlName)) {
    //   this.rescheduleForm?.get('APPOINTMENT_DATE')?.setValue(null);
    //   return;
    // }
    this.getAppointmentSlotsReschedule();
  }

}
