
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
//import { FarmInsuranceDetailsComponent } from 'src/app/demo/panels/hospital/user-data-update/farm-insurance-details/farm-insurance-details.component';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AdditionalInsurancesComponent } from 'src/app/theme/shared/hop-common-components/additional-insurances/additional-insurances.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { AppRegExpressionsConfig } from 'src/app/theme/shared/litterals/app.regularexpressions';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { CommonInsuranceAddlComponent } from '../common-insurance-addl/common-insurance-addl.component';

@Component({
  selector: 'app-hosp-demographics-update',
  templateUrl: './hosp-demographics-update.component.html',
  styleUrls: ['./hosp-demographics-update.component.scss']
})
export class HospDemographicsUpdateComponent implements OnInit {

  public demographicsForm: FormGroup;
  allInsurances: any;
  displayCoverageEffectiveDate: boolean;
  public maxAppointmentDate: Date;
  public minDOB = this.httpService.convertDate(new Date('01 Jan 1910'));
  public currentYear = new Date().getFullYear();
  public currentMonth = new Date().getMonth();
  public currentDate = new Date().getDate();

  latitude: number;
  longitude: number;
  /*public isSelfPay = [
    { value: false, desc: 'Insurance' },
    { value: true, desc: 'Self-Pay' },
    { value: 'Un-Insured', desc: 'Un-Insured' },
    { value: 'healthPass', desc: 'Health Pass' },
  ]*/

  public insurancePrimarySubscriber = [
    { value: 'Self', desc: 'Self' },
    { value: 'Other', desc: 'Other' }
  ]

  public insuranceType = [
    { value: true, desc: 'Medicare' },
    { value: false, desc: 'Other' }
  ];
  @Input() patientDetailsInfo: any;

  @ViewChild("searchStreet") public searchElementRef: ElementRef;
  currentLocation: string;
  public notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;

  public genderList = [
    { value: 'Male', desc: 'Male' },
    { value: 'Female', desc: 'Female' },
    { value: "Trans Female", desc: "Trans Female" },
    { value: "Trans Male", desc: "Trans Male" },
    { value: "Non-binary", desc: "Non-binary" },
    { value: 'Other', desc: 'Other' },
    { value: "Unknown", desc: "Unknown" }
  ];
  public insuranceRelationship = [
    /*{ value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' }*/
    { value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' },
    { value: 'Siblings', desc: 'Siblings' },
    { value: 'Friend', desc: 'Friend' },
    { value: 'Guardian', desc: 'Guardian' },
    { value: 'Son', desc: 'Son' },
    { value: 'Daughter', desc: 'Daughter' },
    { value: 'Partner', desc: 'Partner' },
    { value: 'Child', desc: 'Child' }
  ];
  public relationshipList = [
    { value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' },
    { value: 'Siblings', desc: 'Siblings' },
    { value: 'Friend', desc: 'Friend' },
    { value: 'Guardian', desc: 'Guardian' },
    { value: 'Son', desc: 'Son' },
    { value: 'Daughter', desc: 'Daughter' },
    { value: 'Partner', desc: 'Partner' },
    { value: 'Child', desc: 'Child' }
  ];
  public maritalStatusList = [
    { value: 'Single', desc: 'Single' },
    { value: "Partnered", desc: "Partnered" },
    { value: 'Married', desc: 'Married' },
    { value: "Separated", desc: "Separated" },
    { value: "Divorced", desc: "Divorced" },
    { value: "Widowed", desc: "Widowed" },
    { value: 'Other', desc: 'Other' },
    { value: "Unknown", desc: "Unknown" }
  ];

  public raceList = [
    { value: 'American Indian or Alaska Native', desc: 'American Indian or Alaska Native' },
    { value: 'Black or African American', desc: 'Black or African American' },
    { value: 'White', desc: 'White' },
    { value: 'Asian', desc: 'Asian' },
    { value: 'Native Hawaiian or Other pacific Islander', desc: 'Native Hawaiian or Other pacific Islander' },
    { value: 'Other Race/Multiracial', desc: 'Other Race/Multiracial' },
    { value: 'Unknown', desc: 'Unknown' },
    { value: 'I don’t want to specify', desc: 'I don’t want to specify' }
  ];
  public ethnicityList = [
    { value: 'Hispanic', desc: 'Hispanic' },
    { value: 'Not Hispanic', desc: 'Not Hispanic' },
    { value: 'Unknown', desc: 'Unknown' },
    { value: 'I don’t want to specify', desc: 'I don’t want to specify' }
  ];

  public phoneTypeList = [
    { value: true, desc: 'Mobile Phone' },
    { value: false, desc: 'Landline Phone' }
  ]
  public ssnShow: boolean = true;
  @Input() PatientDetailsCardReload: CardComponent;
  // @ViewChild(AdditionalInsurancesComponent) additionalInsuComp: AdditionalInsurancesComponent;
  @Output() reloadAppointmentDetails = new EventEmitter();
  public allConsentFormDocs: any[] = [];
  @ViewChild('consentFromUpload') public consentFromUpload: ElementRef;
  public consentUploadedFileName: any;
  public billingAddressDetails = [
    { value: 'Same', desc: 'Same' },
    { value: 'Other', desc: 'Other' },
  ];
  @Input() checkUnSavedDataForm: FormControl;
  //@ViewChild(FarmInsuranceDetailsComponent) public farmAdditionalInsuranceComp: FarmInsuranceDetailsComponent;
  @ViewChild(CommonInsuranceAddlComponent) public commonInsurance: CommonInsuranceAddlComponent;
  
  public isSelfPay = [
    { value: 'Insurance', desc: 'Insurance' },
    { value: 'Self-Pay', desc: 'Self-Pay' },
    { value: 'healthPass', desc: 'Health Pass' },
    // {value: 'Un-Insured', desc: 'Un-Insured'}
  ];
  public loginDetails :any;
  public facilityId:any; 
  public validationRequired: boolean = true;
  public maritaMandatory:boolean = false;
  
  public ssnInfo: any;
  public languageList = AppConstantsListConfig?.languageList;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private ngZone: NgZone,) {
    this.demographicsForm = this.formBuilder.group({
      'firstName': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
      'middleName': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
      'lastName': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)])],
      'dob': [null, Validators.required],
      'gender': [null, Validators.required],
      'age': [null],
      'maritalStatus': [null, Validators.required],
      'race': [null, Validators.required],
      'ethnicity': [null, Validators.required],

      'address': this.formBuilder.group({
        'street': [null, Validators.required],
        'city': [null, Validators.required],
        'state': [null, Validators.required],
        'country': [null],
        'zipCode': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.pincode)])],
      }),

      'patientPrimaryPhone': [null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.mobile)])],
      'alternativePhoneType': [null],
      'primaryPhoneType': [null, Validators.required],
      'alternativePhoneNumber': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.mobile)])],
      'ssn': [null, Validators.required],
      'email': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.email)])],
      'emergencyDetails': this.formBuilder?.array([]),
      'selfPay': [null, Validators.required],
      'billingAddressSameAsMailing': [null],
      'billingAddress': this.formBuilder.group({
        'street': [null],
        'city': [null],
        'state': [null],
        'country': [null],
        'zipCode': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.pincode)])],
      }),
      'employerName': [null],
      'employerAddress': this.formBuilder?.group({
        'street': [null],
        'city': [null],
        'state': [null],
        'country': [null],
        'zipCode': [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig.pincode)])]
      }),
      'insuranceDetails': this.formBuilder?.array([]),
      'idDocument': this.formBuilder?.group({
        'documentId': [null],
        'documentType': [null],
        'expiryDate': [null],
        'identityImageId': [null],
        'issueDate': [null]
      }),
      "pharmacy": this.formBuilder?.group({
        "address": this.formBuilder?.group({
          "city": [null],
          "country": [null],
          "state": [null],
          "street": [null],
          "zipCode": [null]
        }),
        "name": [null],
        "pharmacyholderName": [null],
        "fax": [null],
        "phoneNumber": [null],
      }),
      'insurancePrimarySubscriber': [null],
      'subscriberfirstname': [null],
      'subscriberlastname': [null],
      'subscribergender': [null],
      'subscriberdateOfBirth': [null],
      'relationToSubscriber': [null],
      'medicareFlag': [null],
      'medicareStartDate': [null],
      'healthPass': [null],
      'preferredLanguage': [null]
    })
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.facilityId = this.loginDetails.facilityId;
    this.validationRequired = true;
    if(this.facilityId && this.facilityId == 86){
      this.validationRequired = false;
      this.demographicsForm.get('maritalStatus').clearValidators();
      this.demographicsForm.get('race').clearValidators();
      this.demographicsForm.get('ethnicity').clearValidators();
      this.isSelfPay = this.isSelfPay.filter(option => option.value !== 'healthPass');
    }
    let providerStatus = JSON.parse(sessionStorage.getItem('providerConfiguration'));
    if(providerStatus?.maritalStatus){
      this.maritaMandatory = true;
    }else{
      this.maritaMandatory = false;
      this.demographicsForm.get('maritalStatus').clearValidators();
    }
  }

  ngOnInit(): void {
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
    this.billingAddressChanges();
    this.demographicsValueChanges();
	
	
	}

  // if value changes in parent will trigger this
  ngOnChanges(): void {
  
    if (this.patientDetailsInfo) {
	
	 
	  if(this.patientDetailsInfo?.address?.zipCode){
	  this.patientDetailsInfo.address.zipCode = this.addPrefixZipCode(this.patientDetailsInfo?.address?.zipCode,'');
	  }
	  if(this.patientDetailsInfo?.billingAddress) {
	  this.patientDetailsInfo.billingAddress.zipCode = this.addPrefixZipCode(this.patientDetailsInfo?.billingAddress?.zipCode,'');
	  }
	  if(this.patientDetailsInfo?.pharmacy) {
	  this.patientDetailsInfo.pharmacy.address.zipCode = this.addPrefixZipCode(this.patientDetailsInfo?.pharmacy?.address?.zipCode,'');
	  }
	  
      if (this.patientDetailsInfo?.patientPrimaryPhone?.includes('+1')) {
        this.patientDetailsInfo.patientPrimaryPhone = this.patientDetailsInfo?.patientPrimaryPhone?.replace('+1', '');
      }
      if (this.patientDetailsInfo?.alternativePhoneNumber?.includes('+1')) {
        this.patientDetailsInfo.alternativePhoneNumber = this.patientDetailsInfo?.alternativePhoneNumber?.replace('+1', '');
      }

      if (this.patientDetailsInfo?.alternativePhoneNumber === null || this.patientDetailsInfo?.alternativePhoneNumber === '' ) {
        this.demographicsForm?.get('alternativePhoneType')?.setValue(null);
        this.patientDetailsInfo.alternativePhoneType = null;
        
      }

      // if have id document as null
      const idDocument = {
        'documentId': null,
        'documentType': null,
        'expiryDate': null,
        'identityImageId': null,
        'issueDate': null
      }
      if (this.patientDetailsInfo?.idDocument === null) {
        this.patientDetailsInfo.idDocument = idDocument;
      }
      const employerAddress = {
        "city": null,
        "zipCode": null,
        "state": null,
        "street": null,
      }
      this.patientDetailsInfo['employerName'] = this.patientDetailsInfo?.insuranceDetails?.length > 0 ? this.patientDetailsInfo?.insuranceDetails[0]?.employerName : null;
      this.patientDetailsInfo['employerAddress'] = this.patientDetailsInfo?.insuranceDetails?.length > 0 ? (this.patientDetailsInfo?.insuranceDetails[0]?.employerAddress || employerAddress) : employerAddress;

      // this.patientDetailsInfo.dob = this.httpService?.formatDobTimeZone(this.patientDetailsInfo?.dob);
      if (this.patientDetailsInfo?.selfPay === false && this.patientDetailsInfo?.unInsured === false) {
       if (this.patientDetailsInfo?.insuranceDetails && this.patientDetailsInfo?.insuranceDetails?.length > 0) {
          this.patientDetailsInfo.subscriberfirstname = this.patientDetailsInfo?.insuranceDetails[0]?.subscriberfirstname || null;
          this.patientDetailsInfo.subscriberlastname = this.patientDetailsInfo?.insuranceDetails[0]?.subscriberlastname || null;
          this.patientDetailsInfo.subscribergender = this.patientDetailsInfo?.insuranceDetails[0]?.subscribergender || null;
          this.patientDetailsInfo.subscriberdateOfBirth = this.patientDetailsInfo?.insuranceDetails[0]?.subscriberdateOfBirth || null;
          this.patientDetailsInfo.relationToSubscriber = this.patientDetailsInfo?.insuranceDetails[0]?.relationToSubscriber || null;
          this.patientDetailsInfo.insurancePrimarySubscriber = this.patientDetailsInfo?.insuranceDetails[0]?.insurancePrimarySubscriber || null;
          this.patientDetailsInfo.medicareFlag = this.patientDetailsInfo?.insuranceDetails[0]?.medicareFlag || null;
        }
      }
      if (this.patientDetailsInfo?.unInsured) {
        this.demographicsForm?.get('selfPay')?.setValue('Un-Insured');
        this.patientDetailsInfo.selfPay = 'Un-Insured';
      }
      if (this.patientDetailsInfo?.selfPay) {
        this.demographicsForm?.get('selfPay')?.setValue('Self-Pay');
        this.patientDetailsInfo.selfPay = 'Self-Pay';
      }
      if (!this.patientDetailsInfo?.unInsured && !this.patientDetailsInfo?.selfPay) {
        this.demographicsForm?.get('selfPay')?.setValue('Insurance');
        this.patientDetailsInfo.selfPay = 'Insurance';
      }
      if (this.patientDetailsInfo?.healthPass) {
        this.demographicsForm?.get('selfPay')?.setValue('healthPass');
        this.patientDetailsInfo.selfPay = 'healthPass';
      }
      // Pharmacy Details patch
      const pharmacySample = {
        "address": {
          "city": null,
          "country": null,
          "state": null,
          "street": null,
          "zipCode": null
        },
        "name": null,
        "pharmacyholderName": null,
        "fax": null,
        "phoneNumber": null,
      }

      if (!this.patientDetailsInfo?.address) {
        this.patientDetailsInfo.address = {
          "city": null,
          "country": null,
          "state": null,
          "street": null,
          "zipCode": null
        }
      }

      if (!this.patientDetailsInfo?.billingAddress) {
        this.patientDetailsInfo.billingAddress = {
          "city": null,
          "country": null,
          "state": null,
          "street": null,
          "zipCode": null
        }
      }

      this.patientDetailsInfo.pharmacy = this.patientDetailsInfo?.pharmacy || pharmacySample;
      this.patientDetailsInfo.billingAddressSameAsMailing = this.patientDetailsInfo.billingAddressSameAsMailing ? 'Same' : 'Other';
      if (this.demographicsForm) {
        //this.patientDetailsInfo.insuranceDetails = this.patientDetailsInfo?.insuranceDetails || [];
        this.patientDetailsInfo.emergencyDetails = this.patientDetailsInfo?.emergencyDetails || [];
        this.demographicsForm?.patchValue(this.patientDetailsInfo);
      }
      // this.checkPaymentType();
      // this.medicareFlagChange();
      this.getCommonConsentFormDocs();
	  this. getDefaultSSN();
    }
    this.demographicsForm?.get('patientPrimaryPhone')?.setValue(this.demographicsForm?.get('patientPrimaryPhone')?.value?.replace('+1', ''),)
  }

  ngAfterViewInit() {
    
  }

  public getCommonConsentFormDocs(): void {
    /*this.httpService?.getConsentFormsDocuments(this.patientDetailsInfo?.facilityId, this.patientDetailsInfo?.userInfoId, this.patientDetailsInfo?.id)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.allConsentFormDocs = data?.responseObject || [];
        this.allConsentFormDocs = this.allConsentFormDocs?.filter(item => item?.status === 'SIGNED') || [];
      } else {
        this.allConsentFormDocs = data?.responseObject || [];
  	
      }
    })*/
    const documentType = 'Consent Form';
    const action = `record/listDocuments?appointmentId=${this.patientDetailsInfo?.id}&documentType=${documentType}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.PatientDetailsCardReload?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {

        this.allConsentFormDocs = data?.responseObject || [];
        //this.allConsentFormDocs = this.allConsentFormDocs?.filter((listItem: any) => listItem?.documentType === 'Consent Form') || [];
        /* this.allConsentFormDocs?.forEach((formList: any, index: number) => {
 
           if (formList == 'Consent Form') {
             this.allConsentFormDocs = this.allConsentFormDocs?.filter((listItem: any) => listItem !== 'Consent Form') || [];
           }
 
         });*/


      }
      else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.PatientDetailsCardReload?.cardRefreshHide();
      this.isSuccessNotify = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.exceptionModal?.modalShow();
    });
  }



  // change unsurance type
  public changeInsuranceType(insuranceType: string): void {
    this.displayCoverageEffectiveDate = false;
    if (insuranceType == 'Medicare') {
      this.displayCoverageEffectiveDate = true;
    }

  }
  //age calculate
  public dobChange(e: any): void {
    const age = this.httpService.calculateAge(e.target.value);
    this.demographicsForm.get('age').setValue(age);
  }

  // change the payment options
  public checkPaymentType(): void {
    const isSelfPay = this.demographicsForm.get('selfPay').value;
    this.patientDetailsInfo.selfPay = isSelfPay;
    if (isSelfPay === 'Insurance') {
      this.demographicsForm?.addControl('insurancePrimarySubscriber', new FormControl(null));
      this.demographicsForm?.addControl('subscriberfirstname', new FormControl(null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)])));
      this.demographicsForm?.addControl('subscriberlastname', new FormControl(null, Validators.compose([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)])));
      this.demographicsForm?.addControl('subscribergender', new FormControl(null, Validators.required));
      this.demographicsForm?.addControl('subscriberdateOfBirth', new FormControl(null, Validators.required));
      this.demographicsForm?.addControl('relationToSubscriber', new FormControl(null));
      this.demographicsForm?.addControl('medicareFlag', new FormControl(null));
      if (this.patientDetailsInfo?.insurancePrimarySubscriber === 'Self') {
        this.demographicsForm?.get('relationToSubscriber')?.disable();
      } else {
        this.demographicsForm?.get('relationToSubscriber')?.enable();
      }
      this.demographicsForm.patchValue(this.patientDetailsInfo);
    } else {
      this.demographicsForm?.removeControl('insurancePrimarySubscriber');
      this.demographicsForm?.removeControl('subscriberfirstname');
      this.demographicsForm?.removeControl('subscriberlastname');
      this.demographicsForm?.removeControl('subscribergender');
      this.demographicsForm?.removeControl('subscriberdateOfBirth');
      this.demographicsForm?.removeControl('relationToSubscriber');
      this.demographicsForm?.removeControl('medicareFlag');
    }
    this.demographicsForm?.get('relationToSubscriber')?.updateValueAndValidity();
    this.demographicsForm.updateValueAndValidity();
  }


  // medicare flag change in inusrance section
  public medicareFlagChange(): void {
    this.demographicsForm?.addControl('medicareFlag', new FormControl(null));
    this.demographicsForm?.get('medicareFlag')?.setValue(this.patientDetailsInfo?.insuranceDetails[0]?.medicareFlag);
    const insurancePrimary = this.demographicsForm?.get('medicareFlag').value;
    if (insurancePrimary === true) {
      this.demographicsForm?.addControl('medicareStartDate', new FormControl(null, Validators.required));
      this.demographicsForm?.get('medicareStartDate')?.setValue(this.patientDetailsInfo?.insuranceDetails[0]?.medicareStartDate || null);
    } else {
      this.demographicsForm?.removeControl('medicareStartDate');
    }
  }

  // primary subscriber check
  public checkPrimarySubscriber(): void {
    const insurancePrimary = this.demographicsForm?.get('insuranceDetails')?.get('insurancePrimarySubscriber').value;
    if (insurancePrimary === 'Self') {
      this.demographicsForm?.get('insuranceDetails')?.get('subscriberfirstname').setValue(this.patientDetailsInfo?.firstName);
      this.demographicsForm?.get('insuranceDetails')?.get('subscriberlastname').setValue(this.patientDetailsInfo?.lastName);
      this.demographicsForm?.get('insuranceDetails')?.get('subscribergender').setValue(this.patientDetailsInfo?.gender);
      this.demographicsForm?.get('insuranceDetails')?.get('subscriberdateOfBirth').setValue(this.patientDetailsInfo?.dob);
      this.demographicsForm?.get('insuranceDetails')?.get('relationToSubscriber')?.setValue(null);
      this.demographicsForm?.get('insuranceDetails')?.get('relationToSubscriber')?.disable();
    } else {
      this.demographicsForm?.get('insuranceDetails')?.get('subscriberfirstname').setValue(null);
      this.demographicsForm?.get('insuranceDetails')?.get('subscriberlastname').setValue(null);
      this.demographicsForm?.get('insuranceDetails')?.get('subscribergender').setValue(null);
      this.demographicsForm?.get('insuranceDetails')?.get('subscriberdateOfBirth').setValue(null);
      this.demographicsForm?.get('insuranceDetails')?.get('relationToSubscriber')?.enable();
    }
  }

  public updateDemographics(event: any){
  console.log(event);
  this.demographicsForm = event;
 }

  // get the demographiocsform in parent component
  public demographicsDetails(): any {
    if (this.demographicsForm?.invalid) {
      this.demographicsForm?.markAllAsTouched();
      return null;
    }
    const selectedAppointment = this.patientDetailsInfo;
    // selectedAppointment.insuranceDetails = this.demographicsForm?.get('insuranceDetails')?.value || {};
    // //emplyer patch 
    // selectedAppointment.insuranceDetails.employerName = this.demographicsForm?.get('employerName')?.value;
    // selectedAppointment.insuranceDetails.employerAddress = this.demographicsForm?.get('employerAddress')?.value;
   
    if (this.demographicsForm?.get('selfPay').value === 'Insurance') {
      let allInsuranceItems = [];
      const allInsurances = JSON.parse(JSON?.stringify(this.demographicsForm?.value?.insuranceDetails));
     
       allInsurances?.forEach((item: any) => {
        const employerAddress = {
          "city": null,
          "zipCode": null,
          "state": null,
          "street": null,
        };
       /* item.employerName = this.demographicsForm?.value?.employerName || null;
        item["employerAddress"] = this.demographicsForm?.value?.employerAddress || employerAddress;
        
        item.subscriberfirstname = this.demographicsForm?.value?.subscriberfirstname?.trim() || null;
        item.subscriberlastname = this.demographicsForm?.value?.subscriberlastname?.trim() || null;
        item.subscribergender = this.demographicsForm?.value?.subscribergender || null;
        item.subscriberdateOfBirth = this.demographicsForm?.value?.subscriberdateOfBirth || null;
        item.relationToSubscriber = this.demographicsForm?.value?.relationToSubscriber || null;
        item.insurancePrimarySubscriber = this.demographicsForm?.value?.insurancePrimarySubscriber || null;
        item.medicareFlag = this.demographicsForm?.value?.medicareFlag;
        item.medicareStartDate = this.demographicsForm?.value?.medicareStartDate;
        delete item?.docType;*/
        allInsuranceItems?.push(item);
      });
      //allInsuranceItems = [...allInsuranceItems, ...this.commonInsurance?.inActiveOrDeletedInsurances];
      
      selectedAppointment['insuranceDetails'] = allInsuranceItems;
     // selectedAppointment['insuranceDetails'] = allInsurances;
     
    }

    selectedAppointment.firstName = this.demographicsForm?.get('firstName')?.value?.trim();
    selectedAppointment.lastName = this.demographicsForm?.get('lastName')?.value?.trim();
    selectedAppointment.middleName = this.demographicsForm?.get('middleName')?.value;
    selectedAppointment.dob = this.demographicsForm?.get('dob')?.value;
    selectedAppointment.gender = this.demographicsForm?.get('gender')?.value;
    selectedAppointment.age = this.demographicsForm?.get('age')?.value;
    selectedAppointment.maritalStatus = this.demographicsForm?.get('maritalStatus')?.value;
    selectedAppointment.race = this.demographicsForm?.get('race')?.value;
    selectedAppointment.ethnicity = this.demographicsForm?.get('ethnicity')?.value;
    selectedAppointment.address = this.demographicsForm?.get('address')?.value;
    // new fields phone types and alternate phone number
    if(selectedAppointment.alternativePhoneNumber === null || selectedAppointment.alternativePhoneNumber === ''){
      selectedAppointment.alternativePhoneType = null;
    }
    else {
      selectedAppointment.alternativePhoneType = this.demographicsForm?.get('alternativePhoneType')?.value;
    }

    
    selectedAppointment.primaryPhoneType = this.demographicsForm?.get('primaryPhoneType')?.value;
    selectedAppointment.alternativePhoneNumber = this.demographicsForm?.get('alternativePhoneNumber')?.value ? `+1${this.demographicsForm?.get('alternativePhoneNumber')?.value}` : null;
    selectedAppointment.patientPrimaryPhone = `+1${this.demographicsForm?.get('patientPrimaryPhone')?.value}`;
	/*const ssnValue = this.demographicsForm?.get('ssn')?.value;
	console.log("demographicSSN",ssnValue);
	if((ssnValue === null || ssnValue === '') && this.facilityId === 201 && this.ssnInfo !== null){
	 selectedAppointment.ssn = this.ssnInfo;
	}
	else{
    selectedAppointment.ssn = this.demographicsForm?.get('ssn')?.value;
	}*/
	
	selectedAppointment.ssn = this.demographicsForm?.get('ssn')?.value;

    // id Document 
    selectedAppointment.idDocument = this.demographicsForm?.get('idDocument')?.value || null;

    selectedAppointment.email = this.demographicsForm?.get('email')?.value;
    // Emergency Contact Details Map
    const emergencyDetails = JSON.parse(JSON.stringify(this.demographicsForm?.get('emergencyDetails')?.value || []));
    emergencyDetails?.forEach((item: any) => {
      if (item?.phone && item?.phone !== '') {
        item.phone = `+1${item?.phone}`;
      }
    });
    selectedAppointment.emergencyDetails = emergencyDetails || [];
    selectedAppointment.preferredLanguage = this.demographicsForm?.get('preferredLanguage')?.value;

    if (this.demographicsForm?.get('selfPay').value === 'Insurance') {
      selectedAppointment['selfPay'] = false;
      selectedAppointment['unInsured'] = false;
      selectedAppointment['healthPass'] = false;
    } else if (this.demographicsForm?.get('selfPay')?.value === 'Un-Insured') {
      selectedAppointment['selfPay'] = false;
      selectedAppointment['unInsured'] = true;
      selectedAppointment['insuranceDetails'] = null;
      selectedAppointment['healthPass'] = false;
    } else if (this.demographicsForm?.get('selfPay')?.value === 'Self-Pay') {
      selectedAppointment['selfPay'] = true;
      selectedAppointment['unInsured'] = false;
      // selectedAppointment.insuranceDetails['Self-pay'] = 'Self-pay';
      selectedAppointment['insuranceDetails'] = null;
      selectedAppointment['healthPass'] = false;
    }
    else if (this.demographicsForm?.get('selfPay')?.value === 'healthPass') {
      selectedAppointment['selfPay'] = false;
      selectedAppointment['unInsured'] = false;
      selectedAppointment['healthPass'] = true;
      selectedAppointment['insuranceDetails'] = null;
    }

    selectedAppointment.pharmacy = this.demographicsForm?.get('pharmacy')?.value || null;

    delete selectedAppointment?.subscriberfirstname;
    delete selectedAppointment?.subscriberlastname;
    delete selectedAppointment?.subscribergender;
    delete selectedAppointment?.subscriberdateOfBirth;
    delete selectedAppointment?.relationToSubscriber;
    delete selectedAppointment?.insurancePrimarySubscriber;
    delete selectedAppointment.employerName;
    delete selectedAppointment.employerAddress;
    delete selectedAppointment?.medicareFlag
    delete selectedAppointment?.medicareStartDate

    selectedAppointment.billingAddressSameAsMailing = this.demographicsForm?.get('billingAddressSameAsMailing')?.value === 'Same' ? true : false;
    selectedAppointment.billingAddress = this.demographicsForm?.get('billingAddress')?.value;
  
    return selectedAppointment;
  }

  //This method is to validate manually entered date value
  public validateManualEnterdob(e: any): void {
    if (e.target.value != '' && e.target.value != null) {
      if (this.httpService?.validateManualEnterdob(e.target.value, this.currentYear, this.currentMonth + 1, this.currentDate)) {
        // this.demographicsForm?.get('dob')?.setValue(null);
        // this.demographicsForm?.get('age')?.setValue(0);
       // this.demographicsForm.get('dob').setValue(null);
        const passwordControl = this.demographicsForm.get('dob');
        passwordControl.setErrors({ invalidDate: true });
      } else {
        const insuFormArray = this.demographicsForm?.get('insuranceDetails') as FormArray;
        if(insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscriberdateOfBirth')?.setValue(this.demographicsForm.get('dob')?.value);
            }
          });
        }
      }
    }
  }

  // is billing address same
  public isBillingAddressSame(): void {
    const check = this.demographicsForm?.get('billingAddressSameAsMailing')?.value;
    if (check !== 'Same') {
      this.demographicsForm?.get('billingAddress')?.reset();
    } else {
      this.demographicsForm?.get('billingAddress')?.patchValue(this.demographicsForm.get('address')?.value || this.patientDetailsInfo?.address);
    }
  }

  public findInvalidControls(_input: AbstractControl, _invalidControls: any[]): any[] {
    if (!_invalidControls) _invalidControls = [];
    if (_input instanceof FormControl) {
      if (_input.invalid) _invalidControls.push(_input);
      return _invalidControls;
    }

    if (!(_input instanceof FormArray) && !(_input instanceof FormGroup)) return _invalidControls;

    const controls = _input.controls;
    for (const name in controls) {
      let control = controls[name];
      const obj = control instanceof FormControl ? { name, type: 'control' } : { name, type: 'group' };
      if (control.invalid) _invalidControls.push(obj);
      switch (control.constructor.name) {
        // case 'FormArray':
        //   (<FormArray>control).controls.forEach(_control => _invalidControls = this.findInvalidControls(_control, _invalidControls));
        //   break;
        case 'FormGroup':
          _invalidControls = this.findInvalidControls(control, _invalidControls);
          break;
      }
    }

    return _invalidControls || [];
  }

  // Show Consent Forms
  public downloadConsentForm(form: any, pdfConsentModal: UiModalComponent) {
    let action: string;
    if (form?.agreementId) {
      action = `SignForm/getSignedDoc?agreementId=${form?.agreementId}`;
    } else if (form?.s3Key) {
      action = `SignForm/getSignedConsentForm?consentFormKey=${form?.s3Key}`;
    }
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('consent-pdf') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      pdfConsentModal?.show();
      this.PatientDetailsCardReload?.cardRefreshHide();
    },
      (error: any) => {
        this.notifyText = error?.message || 'User does not have privileges to perform action';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.PatientDetailsCardReload?.cardRefreshHide();
      });
  }

  public ssnShowToggle(): void {
    const value = this.demographicsForm?.get('ssn')?.value;
    this.demographicsForm?.get('ssn')?.setValue(value);
    this.ssnShow = !this.ssnShow;
  }

  // to change alternate phone type
  public changeAlternatePhoneType(): void {
    // this.demographicForm?.get('alternativePhoneNumber')?.setValue(null);
    if (this.demographicsForm?.get('alternativePhoneType')?.value === true || this.demographicsForm?.get('alternativePhoneType')?.value === false) {
      this.demographicsForm?.get('alternativePhoneNumber')?.setValidators(Validators.compose([Validators?.required, Validators.pattern(AppRegExpressionsConfig.mobile)]));
    } else {
      this.demographicsForm?.get('alternativePhoneNumber')?.setValidators(Validators.compose([Validators.pattern(AppRegExpressionsConfig.mobile)]));
      this.demographicsForm?.get('alternativePhoneNumber')?.setValue(null);
    }
    this.demographicsForm?.get('alternativePhoneNumber')?.updateValueAndValidity();
  }

  get checkAlternatePhoneNUmber(): boolean {
    let value: boolean;
    if (this.demographicsForm?.get('alternativePhoneNumber')?.value) {
      this.demographicsForm?.get('alternativePhoneType')?.setValidators([Validators.required]);
      value = true;
    } else {
      this.demographicsForm?.get('alternativePhoneType')?.clearValidators();
      // this.demographicsForm?.get('alternativePhoneType')?.setValue(null);
      value = false
    }
    this.demographicsForm?.get('alternativePhoneType')?.updateValueAndValidity();
    return value;
  }

  // upload consent form
  public selectConsentForm(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      if (fileSize <= 25 && file.type !== 'application/pdf' && file.type !== 'image' && file.type !== 'image/jpeg') {
        this.consentUploadedFileName?.get('multipartFile')?.setValue(null);
        this.notifyText = "Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded ";
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        return;
      }
      this.consentUploadedFileName = file?.name || '';
      this.uploadConsentForm(file);
    }
  }
  public getCurrentTimeStamp() {
    const current = new Date();
    current.setHours(0)
    current.setMinutes(0)
    current.setSeconds(0)
    current.setMilliseconds(0)
    const timestamp = current.getTime();
    return timestamp;
  }
  private uploadConsentForm(file: any): void {
    const fileName = file?.name?.replaceAll('.', '');
    const checkTemplateName = this.allConsentFormDocs?.find(item => item?.templateName === fileName) || null;
    if (checkTemplateName) {
      this.notifyText = 'Uploded document name should be different with existing documents name.';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.consentFromUpload.nativeElement.value = "";
      this.consentUploadedFileName = null;
      return;
    }
    const timestamp = this.getCurrentTimeStamp();
    const documentType = "Consent Form";
    const documentName = documentType + "_" + timestamp + "_" + fileName;
    const isFile = true;
    const fileType = file?.type;
    let action: any = `SignForm/uploadSignedConsentForm?appointmentId=${this.patientDetailsInfo?.id}&documentName=${documentName}`;

    // let action: any = `record/addDocument?appointmentId=${this.patientDetailsInfo?.id}&documentName=${documentName}&documentType=${documentType}&isFile=${isFile}&fileType=${fileType}`;



    // convert to binary for data
    const formData = new FormData();
    formData?.append('file', file);
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.notifyText = data?.msg || data?.message || 'Document uploaded successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.reloadAppointmentDetails?.emit(true);
      } else {
        this.notifyText = data?.status || data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.consentFromUpload.nativeElement.value = "";
      this.consentUploadedFileName = null;
      this.PatientDetailsCardReload?.cardRefreshHide();
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.PatientDetailsCardReload?.cardRefreshHide();
        this.consentFromUpload.nativeElement.value = "";
        this.consentUploadedFileName = null;
      });
  }

  // billing address changes 
  private billingAddressChanges(): void {
    this.demographicsForm.get('address')?.valueChanges?.subscribe(data => {
      const check = this.demographicsForm?.get('billingAddressSameAsMailing')?.value;
      if (data && check === 'Same') {
        this.isBillingAddressSame();
      }
    })
  }

  // demographics first name and last name
  private demographicsValueChanges(): void {
  console.log(this.ssnInfo);
    this.demographicsForm?.get("firstName")?.valueChanges?.subscribe(selectedValue => {
      const isSelfPay = this.demographicsForm?.get('selfPay')?.value;
      if (isSelfPay === 'Insurance' && selectedValue) {
        const insuFormArray = this.demographicsForm?.get('insuranceDetails') as FormArray;
        if(insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscriberfirstname')?.setValue(selectedValue || null);
            }
          });
        }
        // this.demographicsForm?.get('subscriberfirstname')?.setValue(selectedValue || null);
      }
    });

    this.demographicsForm?.get("lastName")?.valueChanges?.subscribe(selectedValue => {
      const isSelfPay = this.demographicsForm?.get('selfPay')?.value;
      if (isSelfPay === 'Insurance' && selectedValue) {
        const insuFormArray = this.demographicsForm?.get('insuranceDetails') as FormArray;
        if(insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscriberlastname')?.setValue(selectedValue || null);
            }
          });
        }
        // this.demographicsForm?.get('subscriberlastname')?.setValue(selectedValue || null);
      }
    });

    this.demographicsForm?.get("dob")?.valueChanges?.subscribe(selectedValue => {
      const isSelfPay = this.demographicsForm?.get('selfPay')?.value;
      if (isSelfPay === 'Insurance' && selectedValue) {
        const insuFormArray = this.demographicsForm?.get('insuranceDetails') as FormArray;
        if(insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscriberdateOfBirth')?.setValue(selectedValue || null);
            }
          });
        }
        // this.demographicsForm?.get('subscriberdateOfBirth')?.setValue(selectedValue || null);
      }
    });

    this.demographicsForm?.get("gender")?.valueChanges?.subscribe(selectedValue => {
      const isSelfPay = this.demographicsForm?.get('selfPay')?.value;
      if (isSelfPay === 'Insurance' && selectedValue) {
        const insuFormArray = this.demographicsForm?.get('insuranceDetails') as FormArray;
        if(insuFormArray && insuFormArray?.controls?.length > 0) {
          insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
            if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
              insuItemForm?.get('subscribergender')?.setValue(selectedValue || null);
            }
          });
        }
        // this.demographicsForm?.get('subscribergender')?.setValue(selectedValue || null);
      }
    });
  }
  public addPrefixZipCode(addressZipCode: any,valid:any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
   
    return prefixZipcode || addressZipCode || '';
  }

  public toggleInsuranceSection(event: any){
    console.log(event);
   this.demographicsForm?.get('selfPay').setValue(event);
  
  }
  
  public getDefaultSSN(): void {
   
    const action = `providerConfiguration/getGeneralConfig`;
	this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
   
      if (data?.status === 'SUCCESS') {
        this.ssnInfo = data?.responseObject?.defaultSsn || null;
        
        const ssnValue = this.demographicsForm?.get('ssn')?.value;
		
		if((ssnValue === null || ssnValue === '') && this.facilityId === 201 && this.ssnInfo !== null){
		this.demographicsForm?.get('ssn')?.setValue(this.ssnInfo);
		}
		else{
		this.demographicsForm?.get('ssn')?.setValue(ssnValue);
		}
	
        
      }
    },
      (error) => {
      });
  }
  
  

}
