<!-- <div class="plan-orders">
    <div class="row">
        <div class="form-group col-lg-12 col-md-12 col-sm-12" [formGroup]="rxLabOrderForm">
            <label class="radio-inline" *ngFor="let item of orderTypeSelection">
                <input type="radio" (change)="onRXLabRadRadioButtonChange(item?.value)" formControlName="orderSelection" name="orderSelection" [value]="item?.value"><span class="desc">{{item?.desc}}</span>
            </label>
        </div>
        <div class="form-group col-lg-12 col-md-12 col-sm-12">
            <iframe width="100%" height="800px" frameBorder="0" id="iframe-plan"></iframe>
        </div>
        <div class="form-group col-lg-12 col-md-12 col-sm-12 mt-3">
            <button type="button" class="btn btn-primary dash-actions float-right" (click)="saveRxLabOrders(rxLabOrderNotify)">Save Rx/Lab/Rad Order</button>
        </div>
    </div>
</div> -->
<app-card #OrdersCard [hidHeader]="true" class="new-designed-card custom-nav-tabs" cardClass="card-datatable"
    [options]="false">

    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs new-designed-navbar-tabs">

        <li [ngbNavItem]="1">
            <a ngbNavLink (click)="onRXLabRadRadioButtonChange('Rx', 'rxorder')">Rx</a>
            <ng-template ngbNavContent>
                <div class="row" *ngIf="isCareFlow">
                    <h5 class="col-12">Rx Order</h5>
                    <hr />
                </div>
                <div class="form-group mt-3">
                    <iframe width="100%" height="800px" frameBorder="0" id="rxorder-iframe-plan"></iframe>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink (click)="onRXLabRadRadioButtonChange('Order', 'labrad')">Lab/Rad</a>
            <ng-template ngbNavContent>
                <div class="row" *ngIf="isCareFlow">
                    <h5 class="col-12">Lab/Rad Order</h5>
                    <hr />
                </div>
                <div class="form-group mt-3">
                    <iframe width="100%" height="800px" frameBorder="0" id="labrad-iframe-plan"></iframe>
                </div>
            </ng-template>
        </li>

        <li [ngbNavItem]="3">
            <a ngbNavLink (click)="makeAdhocLabOrdersChangeHealth()">Requisition Details</a>
            <ng-template ngbNavContent>
                <div class="row" *ngIf="isCareFlow">
                    <h5 class="col-12">Requisition Details</h5>
                    <hr />
                </div>
                <div class="table-responsive new-designed-table mt-3">
                    <table class="table table-striped table-bordered table-hover custom-table">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>type</th>
                                <th>Requisition date</th>
                                <th>Status</th>
                                <th>lab name</th>
                                <th>Results</th>
                                <th class="no-sort">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let labOrder of alllabRadOrderDetails; let i = index">
                                <tr class="no-bottom-pad">
                                    <td>
                                        <div class="d-flex">
                                            <ng-container>
                                                <a class="order-num" href="javascript:;" placement="right" ngbTooltip="Order Requisition"
                                                    (click)="downloadLabRequisition(labOrder?.externalOrderNumber)">{{labOrder?.orderNumber
                                                    || '--'}}</a>
                                            </ng-container>
                                        
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <a class="order-num" href="javascript:;" placement="right" ngbTooltip="Order Details"
                                                (click)="getOrderDetails(labOrder)"><span class="material-symbols-outlined">info</span></a>
                                        </div>

                                        <ng-container *ngIf="labOrder?.requestDetails && labOrder?.requestDetails?.length > 0">
                                            <table>
                                                <tr class="no-top-pad">
                                                    <td></td>
                                                    <td colspan="7">
                                                        <span class="tests-ordered" style="font-size: 12px;
                                                        font-weight: bold;" *ngFor="let test of labOrder?.requestDetails; let last = last">
                                                            {{test?.testDescription || '--'}}
                                                            <ng-container *ngIf="test?.diagnosisCodes">
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                (<span *ngFor="let code of test?.diagnosisCodes">{{code?.code}} &nbsp;&nbsp;</span>)
                                                            </ng-container>
                                                            <span *ngIf="!last">,&nbsp;&nbsp;</span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </ng-container>
                                    </td>
                                    <td>{{labOrder?.orderType || '--'}}</td>
                                    <td>{{labOrder?.requestDate || '--'}}</td>
                                    <td>{{statusCodeDesc(labOrder?.orderStatus) ||
                                        '--'}}</td>
                                    <td>{{labOrder?.labName || '--'}}</td>
                                    <td>
                                        <ng-container>
                                            <button class="icon-queue-events btn-status-success" placement="left"
                                                (click)="showHl7Details(labOrder)" ngbTooltip="View Results"><span
                                                    class="material-symbols-outlined">
                                                    visibility
                                                </span></button>
                                        </ng-container>
                                        <ng-container *ngIf="labOrder?.reportKey">
                                            <button class="icon-queue-events btn-status-success" placement="left"
                                                (click)="getReportsAvailable(labOrder)" ngbTooltip="PDF Report"><i
                                                    class="fa fa-file-pdf"></i></button>
                                        </ng-container>
                                        <ng-container *ngIf="!labOrder?.reportKey">
                                            <button class="icon-queue-events" placement="left"
                                                ngbTooltip="Report Not Available"><i
                                                    class="fa fa-file-pdf disabled"></i></button>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <div class="dropdown-actions">
                                            <button class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}" id="claimActions_btn" (click)="openUniversalActions($event, i)">...</button>
                                <div class="dropdown-content-actions" *ngIf="openActions && selectedActionRow === i">
                                                <a href="javascript:"
                                                    (click)="printLabel(labOrder,printPreviewModal);closeUniversalActions();"><span
                                                        class="material-symbols-outlined">
                                                        print
                                                    </span>Generate Label</a>
                                                <a href="javascript:" (click)="openNotifications();closeUniversalActions();"><span
                                                        class="material-symbols-outlined">
                                                        send
                                                    </span>Notify</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <!-- <tr class="no-bottom-pad">
                                    <td>
                                        <div>
                                            <ng-container>
                                                <a class="order-num" href="javascript:;" placement="right"
                                                    ngbTooltip="Order Requisition"
                                                    (click)="showLabRequisition(labOrder?.externalOrderNumber)">{{labOrder?.orderNumber
                                                    || '--'}}</a>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>{{labOrder?.orderType || '--'}}</td>
                                    <td>{{labOrder?.requestDate || '--'}}</td>
                                    <td>{{statusCodeDesc(labOrder?.orderStatus) || '--'}}</td>
                                    <td>{{labOrder?.labName || '--'}}</td>
                                    <td>
                                        <div class="dropdown-actions">
                                            <button class="icon-queue-events more-actions-hover-icon"><i
                                                    class="las la-ellipsis-h"></i></button>
                                            <div class="dropdown-content-actions">
                                                <a href="javascript:"
                                                    (click)="printLabel(labOrder,printPreviewModal)"><span
                                                        class="material-symbols-outlined">
                                                        print
                                                    </span>Generate Label
                                                    Details</a>
                                                <a href="javascript:" (click)="openNotifications()"><span
                                                        class="material-symbols-outlined">
                                                        send
                                                    </span>Notify
                                                    Details</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr> -->

                            </ng-container>
                            <ng-container *ngIf="alllabRadOrderDetails?.length === 0">
                                <tr>
                                    <td colspan="7" style="text-align: center;">Active Lab/ Rad
                                        Orders are unavailable for this patient.
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="new-designed-navbar"></div>
</app-card>


<!-- <div class="plan-orders mt-4" id="mainOrdersAccordian">
    <div class="order-accordion">
        <div class="panel-group">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title" (click)="onRXLabRadRadioButtonChange('Rx', 'rxorder')">
                        <a data-toggle="collapse" id="rxOrderClick" href="#rxOrdersCollapse">Rx</a>
                        <span class="expand">
                            <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                        </span>
                    </h4>
                </div>
                <div id="rxOrdersCollapse" class="panel-collapse collapse in" data-parent="#mainOrdersAccordian">
                    <div class="panel-body">
                        <div class="form-group">
                            <iframe width="100%" height="800px" frameBorder="0" id="rxorder-iframe-plan"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-group">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title" (click)="onRXLabRadRadioButtonChange('Order', 'labrad')">
                        <a data-toggle="collapse" id="labOrderClick" href="#labRadOrdersCollapse">Lab/Rad</a>
                    </h4>
                </div>
                <div id="labRadOrdersCollapse" class="panel-collapse collapse" data-parent="#mainOrdersAccordian">
                    <div class="panel-body" id="subLabRadOrdersAccordion">
                        <div class="panel-group">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title" (click)="makeAdhocLabOrdersChangeHealth()">
                                        <a data-toggle="collapse" href="#order1">Requisitions</a>
                                        <span class="expand">
                                            <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                        </span>
                                    </h4>
                                </div>
                                <div id="order1" class="panel-collapse collapse in"
                                    data-parent="#subLabRadOrdersAccordion">
                                    <div class="panel-body">
                                        <div class="table-responsive new-designed-table">
                                            <table class="table table-striped table-bordered table-hover custom-table">
                                                <thead>
                                                    <tr>
                                                        <th>Number</th>
                                                        <th>type</th>
                                                        <th>Requisition date</th>
                                                        <th>Status</th>
                                                        <th>lab name</th>
                                                        <th class="no-sort">ACTIONS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <ng-container
                                                        *ngFor="let labOrder of alllabRadOrderDetails; let i = index">
                                                        <tr class="no-bottom-pad">
                                                            <td>
                                                                <div>
                                                                    <ng-container>
                                                                        <a class="order-num" href="javascript:;"
                                                                            placement="right"
                                                                            ngbTooltip="Order Requisition"
                                                                            (click)="showLabRequisition(labOrder?.externalOrderNumber)">{{labOrder?.orderNumber
                                                                            || '--'}}</a>
                                                                    </ng-container>
                                                                </div>
                                                            </td>
                                                            <td>{{labOrder?.orderType || '--'}}</td>
                                                            <td>{{labOrder?.requestDate || '--'}}</td>
                                                            <td>{{statusCodeDesc(labOrder?.orderStatus) || '--'}}</td>
                                                            <td>{{labOrder?.labName || '--'}}</td>
                                                            <td>
                                                                <div class="dropdown-actions">
                                                                    <button
                                                                        class="icon-queue-events more-actions-hover-icon"><i
                                                                            class="las la-ellipsis-h"></i></button>
                                                                    <div class="dropdown-content-actions">
                                                                        <a href="javascript:"
                                                                            (click)="printLabel(labOrder,printPreviewModal)"><span
                                                                                class="material-symbols-outlined">
                                                                                print
                                                                            </span>Generate Label
                                                                            Details</a>
                                                                        <a href="javascript:"
                                                                            (click)="openNotifications()"><span
                                                                                class="material-symbols-outlined">
                                                                                send
                                                                            </span>Notify
                                                                            Details</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </ng-container>
                                                    <ng-container *ngIf="alllabRadOrderDetails?.length === 0">
                                                        <tr>
                                                            <td colspan="6" style="text-align: center;">Active Lab/ Rad
                                                                Orders are unavailable for this patient.
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-group">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" href="#createLabRadOrder">Create
                                            Order</a>
                                    </h4>
                                </div>
                                <div id="createLabRadOrder" class="panel-collapse collapse show"
                                    data-parent="#subLabRadOrdersAccordion">
                                    <div class="panel-body">
                                        <div class="form-group">
                                            <iframe width="100%" height="800px" frameBorder="0"
                                                id="labrad-iframe-plan"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- this is for adhoc rx or lab order save -->
<div class="row" *ngIf="!hideSaveRxOrderButton">
    <div class="form-group col-lg-12 col-md-12 col-sm-12 mt-3">
        <button type="button" class="btn btn-primary dash-actions float-right"
            (click)="saveRxLabOrders(rxLabOrderNotify)">Save Rx/Lab/Rad Order</button>
    </div>
</div>

<!-- notify popup -->
<app-ui-modal #rxLabOrderNotify [hideHeader]="false" [modalCentered]="true" id="rxLabOrderNotify">
    <div class="app-modal-header" style="width: 100%;">
        <h5 class="modal-title text-center">{{notifyText}}</h5>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="ok1"
            (click)="redirectLocation(rxLabOrderNotify)">Ok</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Lab RAD Resulrs modal -->
<app-lab-rad-results-modal [labRadOrdersCard]="cardRefresh"></app-lab-rad-results-modal>

<!-- Display the Order Details -->
<app-lab-rad-order-details-modal [cardComp]="cardRefresh"></app-lab-rad-order-details-modal>

<!-- Display the HL7 message details --> 
<app-hl7-details [cardComp]="cardRefresh"></app-hl7-details>

<!-- Notification SMS popup -->
<app-send-sms-notify [noDelay]="true" [pageType]="'Requisition Orders'"></app-send-sms-notify>
<!-- Model for print preview -->
<app-ui-modal #printPreviewModal [hideHeader]="false" [modalCentered]="true" id="printPreviewModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Label Preview</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="printPreviewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div id="printableArea">
            <Span>Req# &nbsp;{{ordeNum}}</Span><br />
            <Span>Acct# &nbsp; {{orderAccountNum}}</Span><br />
            <Span>{{nameOnLabel}}</Span><br />
            <ngx-barcode [bc-value]="value" [bc-width]="1.5" [bc-height]="30"></ngx-barcode>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="printPreviewModal.hide()">Close</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="printLabelView()">Print</button>
    </div>
</app-ui-modal>