<app-ui-modal #patientResponsibilityModal [modalCentered]="true" id="paymentDailogue">
    <div class="app-modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="app-modal-body">
        <ng-container *ngIf="!displayPaymentSection">
            <div class="check-payment-type row" [formGroup]="servicesForm">
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <label>Service Date</label><br />
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <label>Patient Responsibility</label><br />
                </div>

                <ng-container *ngFor="let patientRespCredit of patientResp;let i=index">
                    <div class="form-group col-lg-6 col-md-6 col-sm-6">
                        <div class="checkbox checkbox-primary d-inline">
                            <input type="checkbox" 
                                (change)="calculateTotal($event,patientRespCredit)">
                            <label class="cr list-name-label ml-4" for="">{{ patientRespCredit?.serviceDate | date:'MM/dd/yyyy' }}</label>
                        </div>
                    </div>
                    <div class="form-group col-lg-6 col-md-6 col-sm-6 input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input type="number"  name="card-pay"
                            class="form-control input-text" [value]=patientRespCredit?.patientResponsibility placeholder="Enter Co-Pay Amount" readonly />
                    </div>
                </ng-container>
                <ng-container *ngIf="patientResp?.length === 0">
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 text-center">
                        <label><b>No Pending Payments Found</b></label><br />
                    </div>
                </ng-container>

                <!-- Co PAy Amount -->


                <!--  total amount -->
                <hr style="width:100%;text-align:left;margin-left:0">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <span class="required error" *ngIf="totalMinimumAmountError">Amount should be minimum of $1 to make
                        payment.</span>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <label>
                        <h5>Total</h5>
                    </label><br />
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <label class="float-right">
                        <h5>$ {{totalAmount}}
                        </h5>
                    </label><br />
                </div>
            </div>
        </ng-container>

        <div class="check-payment-type" *ngIf="!displayPaymentSection && patientResp?.length !== 0">
            <div class="form-group">
                <label>Payment Type</label><br />
                <ng-select placeholder="Choose Payment Type" (change)="changePayType()" [items]="paymentType" [(ngModel)]="selectedPaymentType" bindLabel="desc" bindValue="value"></ng-select>
                <!-- <label class="radio-inline pl-2" *ngFor="let item of paymentType">
                    <input type="radio" [(ngModel)]="selectedPaymentType" (change)="changePayType()" name="paymentType2"
                        [value]="item?.value"><span class="desc">{{item.desc}}</span></label> -->
            </div>
            <!-- [disabled]="servicesForm?.get('coPayAmount')?.value <= 0 && servicesForm?.get('inHouseLabsAmount')?.value <= 0 && servicesForm?.get('consultationAmount')?.value <= 0 &&  servicesForm?.get('externalLabsAmount')?.value <= 0 &&  servicesForm?.get('otherAmount')?.value <= 0" -->
            <div class="form-group" style=" text-align: center;">
                <button *ngIf="selectedPaymentType != null && selectedPaymentType != ''" id="terminal-pay"
                    class="btn btn-primary dash-actions mt-4" type="button" (click)="makePayment();"> Make
                    Payment</button>
            </div>
        </div>

        <!-- With Termal Devices -->
        <div class="with-terminal" *ngIf="displayPaymentSection  && selectedPaymentType === 'terminal'">
            <div class="enter-amount" [formGroup]="amountForm">
                <div class="payment-section">
                    <span class="text-left payment-desc">Amount</span>
                    <span class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                </div>
            </div>
            <div class="form-group" style=" text-align: center;">
                <button *ngIf="!showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button"
                    (click)="createPosPayment()">Pay ${{amountForm?.controls['amount']?.value}}</button>

                <button *ngIf="showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button"
                    (click)="cancelPosPayment()">Cancel Payment</button>
            </div>

        </div>

        <!-- Without Terminal Device -->
        <div class="payment-body" *ngIf="displayPaymentSection && selectedPaymentType === 'noTerminal'">
            <div class="check-payment-type">
                <div class="form-group">
                    <label class="radio-inline" *ngFor="let item of paymentMode">
                        <input type="radio" [(ngModel)]="selectedPaymentMode" (change)="changePaymentMode()"
                            name="paymentMode" [value]="item?.value"><span class="desc">{{item.desc}}</span></label>
                </div>
            </div>
            <ng-continer *ngIf="selectedPaymentMode === 'Credit Card Payment'">
                <form id="payment-form">
                    <div class="enter-amount mb-4" [formGroup]="amountForm">
                        <div class="payment-section">
                            <span class="text-left payment-desc">Amount</span>
                            <span
                                class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                        </div>
                    </div>
                    <!-- card details enter -->
                    <div id="card-container"></div>
                    <div class="credit-card-btns">
                        <div class="form-group" style=" text-align: center;">
                            <button *ngIf="!enableNonPosCancelPayment" id="card-button"
                                class="btn btn-primary dash-actions" type="button">Pay ${{totalAmount}}</button>
                            <button *ngIf="enableNonPosCancelPayment" id="terminal-pay"
                                class="btn btn-primary dash-actions" type="button" (click)="cancelPayment()">Cancel
                                Payment</button>
                        </div>
                    </div>
                </form>
            </ng-continer>

            <form *ngIf="selectedPaymentMode === 'ACH Payment (Pay with Bank Account)'">
                <div class="enter-amount mb-4" [formGroup]="amountForm">
                    <div class="payment-section">
                        <span class="text-left payment-desc">Amount</span>
                        <span class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                    </div>
                </div>
                <div class="form-group" style=" text-align: center;">
                    <button id="ach-button" (click)="achPayment()" class="btn btn-primary dash-actions"
                        type="button">Pay with Bank Account</button>
                </div>
            </form>

            <div *ngIf="selectedPaymentMode === 'Digital wallets'">
                <div class="enter-amount mb-4" [formGroup]="amountForm">
                    <div class="payment-section">
                        <span class="text-left payment-desc">Amount</span>
                        <span class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                    </div>
                </div>
                <div class="row" style="text-align: center;">
                    <form style="width: 45%;">
                        <button id="apple-pay-button" (click)="applePayOpen();"
                            class="btn btn-primary dash-actions">Apple
                            Pay</button>
                    </form>
                    <br />
                    <form style="width: 45%;">
                        <button (click)="googlePayOpen()" class="btn btn-primary dash-actions mb-3">Google Pay</button>
                        <br />
                        <div id="google-pay-button"></div>
                    </form>
                </div>
            </div>
        </div>

        <!-- With Termal Devices -->
        <div class="with-terminal" *ngIf="displayPaymentSection  && (selectedPaymentType === 'cashPayment' || selectedPaymentType === 'chequePayment' || selectedPaymentType === 'onlinePayment')">
            <div class="enter-amount" [formGroup]="amountForm">
                <div class="payment-section">
                    <span class="text-left payment-desc">Amount</span>
                    <span class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                </div>
            </div>
            <div class="form-group" style=" text-align: center;">
                <button *ngIf="!showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button"
                    (click)="createCashPayment()">Pay ${{amountForm.controls['amount']?.value}}</button>
            </div>

            <!-- <button *ngIf="showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button"
                (click)="cancelPosPayment()">Cancel Payment</button> -->
        </div>
        <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <!-- <button type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="paymentModal.hide(); loadSpinner = false">Close</button> -->
    </div>
</app-ui-modal>


<!-- Success -->
<app-ui-modal #paySuccessModal [hideHeader]="true" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="paymentSuccessDailogue">
    <div class="app-modal-body">
        <div class="payment">
            <div class="payment_header">
                <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
            </div>
            <div class="content">
                <h1>Payment Success !</h1>
                <!-- <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. </p> -->
                <div class="payment-reciept" *ngIf="successPayDetails && successPayDetails?.paymentId"
                    (click)="viewPaymentReciept(successPayDetails?.paymentId, payInvoiceModal)">View/Print Payment
                    Receipt</div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="paySuccessModal.hide(); loadSpinner = false">Close</button>
    </div>
</app-ui-modal>

<!-- Payment Invoice -->
<app-ui-modal #payInvoiceModal [hideHeader]="false" [modalCentered]="true" id="payInvoiceModal">
    <div class="app-modal-body">
        <h5 class="modal-title">Payment Receipt</h5>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="payment-reciept">
            <iframe id="patient-resp-payment-reciept-embed" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="closeModal(); payInvoiceModal.hide(); loadSpinner = false">Close</button>
    </div>
</app-ui-modal>


<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>