<div id="manage-access">
    <!--<div class="row" style="width: 100%; display: table;">
    <div style="display: table-row; ">
        <div class="col-lg-6  col-md-6 col-sm-12" style="width: 50%; display: table-cell; ">
            
            <textarea [(ngModel)]="value" (keyup)="inputChange()" (keydown.tab)="tabKeyPressed()" (keydown.enter)="tabKeyPressed()"></textarea>
            <textarea class="autocomplete" disabled  [(ngModel)]="placeholderValue"></textarea>
        </div>
        <div class="col-lg-6  col-md-6 col-sm-12" style="display: table-cell; "> 
              
            <textarea [(ngModel)]="value1" (keyup)="inputChange1()" (keydown.tab)="tabKeyPressed1()" (keyup.enter)="tabKeyPressed1()"></textarea>
            <textarea class="autocomplete" disabled  [(ngModel)]="placeholderValue1"></textarea>
        </div>
    </div>
</div>-->

    <!-- <div class="row" style="width: 100%; display: table;">
    <div style="display: table-row; ">
        <div class="col-lg-6  col-md-6 col-sm-12" style="width: 50%; display: table-cell; ">
            
            <input type="text" [(ngModel)]="value" (input)="inputChange()" (keydown.tab)="tabKeyPressed()" (keyup.enter)="tabKeyPressed()"/>
        <input type="text" class="autocomplete" disabled  [(ngModel)]="placeholderValue" />
        </div>
        <div class="col-lg-6  col-md-6 col-sm-12" style="display: table-cell; "> 
              
            <input type="text" [(ngModel)]="value1" (input)="inputChange1()" (keydown.tab)="tabKeyPressed1()" (keyup.enter)="tabKeyPressed1()"/>
            <input type="text" class="autocomplete" disabled  [(ngModel)]="placeholderValue1" />
        </div>
    </div>
</div> -->
</div>


<div class="container">
    <!-- <div class="header">Power BI Embedded Angular Component Demo</div> -->
    <!-- Embed Report -->
    <ng-container *ngIf="allPatientsEmbedReports && allPatientsEmbedReports?.length > 0">
        <ng-container *ngFor="let report of allPatientsEmbedReports; let i = index">
            <powerbi-report [embedConfig]="reportEmbedUi(report)" [cssClassName]="'report-container'" [phasedEmbedding]="false"
            [eventHandlers]="eventHandlersMap"></powerbi-report>
        </ng-container>
    </ng-container>
    

    <!-- Dashboard -->
    <!-- <powerbi-dashboard [embedConfig]="reportConfig" [cssClassName]="'dashboard-container'"
    [eventHandlers]="eventHandlersMap">
    </powerbi-dashboard> -->

    <!-- IFrame Report -->
    <!-- <iframe title="visitssample" width="100%" height="100%" src="
https://app.powerbi.com/reportEmbed?reportId=0e254ce5-0254-4faf-a530-70d6ce140bc9&autoAuth=true&embeddedDemo=false"
        frameborder="0" allowFullScreen="true"></iframe> -->
        <br/>
        <br/>
        <!-- Dashboard -->
        <!-- <iframe title="visitssample" width="100%" height="100%" src="
        https://app.powerbi.com/reportEmbed?reportId=b43f3ec0-529d-4d5c-a4d8-5c71c9e66974&autoAuth=true&ctid=8ba5d408-116d-4a0f-88a0-427e275972b4"
        frameborder="0" allowFullScreen="true"></iframe> -->

    <!-- <iframe title="visitssample" width="100%" height="100%"
        src="
https://app.powerbi.com/view?r=eyJrIjoiZDA2ZWQwYTItMWRkZS00Njk3LTg4ODMtN2MwN2E1YzA0ZTRjIiwidCI6IjhiYTVkNDA4LTExNmQtNGEwZi04OGEwLTQyN2UyNzU5NzJiNCJ9" frameborder="0"
        allowFullScreen="true"></iframe> -->

        <!-- <iframe title="visitssample" width="100%" height="100%" src="
        https://app.powerbi.com/groups/9a97c560-9ac9-4be8-bd0e-6b1fdb4c4c3a/list?language=en-US&experience=power-bi"
        frameborder="0" allowFullScreen="true"></iframe> -->
</div>

<!--sales -  82d94879-fdb9-46db-a340-e44b7795c3d3 -->

<!-- a1e6a282-486c-4681-b257-26e924bca25d -->

<!-- [19:00] Raj Nandarapu
3d9d149e-fd49-4fc9-859a-72d3b792a228 -->

<!-- https://app.powerbi.com/groups/me/apps/0e254ce5-0254-4faf-a530-70d6ce140bc9/dashboards/1bf1d974-08c2-490d-a792-66f1624bc6a6?experience=power-bi -->

<!-- https://app.powerbi.com/groups/me/apps/0e254ce5-0254-4faf-a530-70d6ce140bc9/dashboards/1bf1d974-08c2-490d-a792-66f1624bc6a6?experience=power-bi -->

<!-- https://app.powerbi.com/groups/9a97c560-9ac9-4be8-bd0e-6b1fdb4c4c3a/reports/82d94879-fdb9-46db-a340-e44b7795c3d3/ReportSectionc8123b10aa0d00acb9e3?experience=power-bi -->