<div class="followup-queue-total">
    <app-card #caregapCard cardClass="card-datatable" class="new-designed-card" [options]="false">
        <!-- <div class="row" style="padding-bottom: 20px; padding-top:15px;">

            <div class="col-md-12 pull-right">
                <button class="flow-board-main-actions new-appointment-action" (click)="openFilterSection();">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                </button>
            </div>
        </div> -->
        <div class=" table-responsive new-designed-table">
            <table datatable [dtOptions]="dtOptionsCaregap" [dtTrigger]="dtTriggerCaregap" id="report-table"
                class="table table-striped table-hover custom-table">
                <thead>
                    <tr>
                        <th>Patient Name</th>
                        <th>Primary Contact</th>
                        <th>Program</th>
                        <th>Chronic Condition</th>
                        <th class="text-center no-sort">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="appointment-tr-queue" *ngFor="let caregap of caregapList; let i=index">
                        <td>{{caregap?.patientName | titlecase}}</td>
                        <td><span>{{caregap?.patientPrimaryPhone || '--'}}</span></td>
                        <td>{{caregap?.careGap || '--'}}</td>
                        <!--<td>{{displaycareGap(caregap?.careGap) || '--'}}</td>-->
                        <td class="condition-dropdown">

                            <!--<span *ngIf="caregap?.conditions"> {{getConditionslist(caregap?.conditions) || '--'}}</span>
                                <span *ngIf="!caregap?.conditions">--</span> -->
                            <div class="form-group">
                                <ng-container>
                                    <ng-select #conditionsList placeholder="Chronic Condition" id="conditions_{{i}}"
                                        [items]="chronicConditionsList" (change)="listConditions($event)" (blur)="updateConditions($event, caregap)"
                                        [(ngModel)]="caregap.conditions" [multiple]="true" [closeOnSelect]="false"> </ng-select>
                                </ng-container>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="dropdown-actions">
                                <button
                                    class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}"
                                    id="claimActions_btn" (click)="openUniversalActions($event, i);">...</button>
                                <div class="dropdown-content-actions" *ngIf="openActions && selectedActionRow === i">
                                    <a (click)="openNotify(caregap?.masterPatientId);closeUniversalActions();"
                                        style="cursor: pointer;"><i class="lab la-telegram"></i>Notify</a>
                                    <a (click)="openSchedule(caregap?.masterPatientId);closeUniversalActions();"
                                        style="cursor: pointer;"><span class="material-symbols-outlined">history</span>
                                        Schedule</a>
                                    <a (click)="openEnrollPatient(enrollPatient,caregap);closeUniversalActions();"
                                        style="cursor: pointer;"><span class="material-symbols-outlined">
                                            clinical_notes
                                        </span> Enroll Patient</a>
                                    <a (click)="openUnenroll(unEnrollPatient,caregap);closeUniversalActions();"
                                        style="cursor: pointer;"><span class="material-symbols-outlined">
                                            account_circle_off
                                        </span>Cancel Enrollment</a>
                                    <!--<a (click)="openNotes();closeUniversalActions();"><i class="las la-tasks"></i> Notes</a>-->
                                </div>
                            </div>

                        </td>
                    </tr>


                </tbody>
            </table>
        </div>
        <div class="summary-card" style="margin-top: 20px; padding-bottom: 50px;">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <h4 class="Summary" style="padding-top: 0px;">Summary</h4>
                            <div class="vl"></div>
                        </div>
                        <div class="col-md-10">
                            <div class="Count">{{totalEligiblePatientsCount}}</div>
                            <div class="summary-name">Total Eligible Patients</div>

                        </div>

                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="filters-col" *ngIf="openFilters">
            <h5>Filters <span class="close pull-right" (click)="closeFiltersection()">&times;</span></h5>
            <div class="form-group">
                <label>Type</label>
                <ng-select placeholder="Choose Purpose of Visit" id="type-list" [items]="typeList"></ng-select>


            </div>
            <div class="form-group">
                <label>Purpose of Visit</label>

                <ng-select placeholder="Choose Purpose of Visit" id="visit-info-purpose-visit"
                    [items]="purposeOfVisitList"></ng-select>
            </div>
            <div class="form-group text-center">
                <button class="btn btn-primary">Submit</button>
            </div>
        </div>-->

    </app-card>
</div>



<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>
<app-schedule-followup-appointment [appointmentId]="selectedAppointmentId"></app-schedule-followup-appointment>
<app-followup-notification></app-followup-notification>
<app-send-sms-notify [pageType]="'Chart'"></app-send-sms-notify>

<!-- Enroll Patient -->
<app-ui-modal #enrollPatient [hideHeader]="false" [modalCentered]="true" id="enrollPatient">
    <div class="app-modal-header">
        <h5>New Patient Consent</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="enrollPatient?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" [formGroup]="enrollPatientForm">

        <h6>By signing below you are enrolling in CCM and agreeing to the following:</h6>
        <ol>
            <li>Only one provider can furnish CCM Services to you during a calendar month.</li>
            <li>Your medical information will be shared electronically with your active providers as part of
                coordination your care.</li>
            <li>Depending on supplemental coverage all CCM Care Coordination support and does not include a face-to-face
                meeting with the provider.</li>
            <li>You may stop CCM services at any time, effective at the end of the calendar month, by notifying Care
                Harmony directly.</li>
        </ol>
        <div class="form-group text-left">
            <div class="checkbox checkbox-primary d-inline">
                <input type="checkbox" (change)="toggleConsent($event, 'verbalConsent')"
                    [checked]="isVerbalConsent === true" name="consent-form" id="consentCheck1"
                    formControlName="verbalConsent">
                <label class="consent-label"> Verbal consent by the patient.</label>
            </div>
        </div>
        <div class="form-group text-left">
            <div class="checkbox checkbox-primary d-inline">
                <input type="checkbox" (change)="toggleConsent($event, 'physicalConsent')"
                    [checked]="isPhysicalConsent === true" name="consent-form" id="consentCheck2"
                    formControlName="physicalConsent">
                <label class="consent-label">The patient's consent has already been obtained.</label>
            </div>
        </div>
        <div *ngIf="consentNotSelected" class="text-danger">Please select consent</div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="caregapListEnroll_ok" (click)="enrollpatient(enrollPatient);">Enroll Patient</button>
    </div>
</app-ui-modal>

<!-- Un enroll -->
<app-ui-modal #unEnrollPatient [hideHeader]="false" [modalCentered]="true" id="unEnrollPatient">
    <div class="app-modal-header">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="unEnrollPatient?.hide();resetUnenrollForm();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" [formGroup]="unenrollPatientForm">
        <div class="form-group text-left">
            <div class="checkbox checkbox-primary d-inline">
                <input type="checkbox" name="consent-form" id="consentCheck2" class="disabled"
                    formControlName="notQualified">
                <label class="consent-label"> Not Qualified.</label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label>Reason <span class="text-danger">*</span></label>
                    <textarea class="form-control input-text" type="text" formControlName="reason"
                        placeholder="Write Reason" id="reason"></textarea>
                    <label class="text-danger"
                        *ngIf="unenrollPatientForm.controls['reason'].hasError('required') && unenrollPatientForm.controls['reason'].touched">
                        Reason is required</label>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="caregapListUnEnroll_ok" (click)="notqualifiyPatient(unEnrollPatient)">Un-Enroll Patient</button>
    </div>
</app-ui-modal>