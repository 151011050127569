import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { AuthGuard } from './theme/shared/auth/auth.guard';
import { KioskGuard } from './theme/shared/auth/kiosk.guard';
import { LogoutGuard } from './theme/shared/auth/logout.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/auth/signin',
        pathMatch: 'full'
      },
      {
        path: 'users',
        loadChildren: () => import('./demo/users/users.module').then(module => module.UsersModule)
      },
      {
        path: 'hospital', canActivate: [AuthGuard], 
        loadChildren: () => import('./demo/panels/hospital/hospital.module').then(module => module.HospitalModule)
      },
      {
        path: 'email',
        loadChildren: () => import('./demo/application/email/email.module').then(module => module.EmailModule)
      },
      {
        path: 'helpdesk', 
        loadChildren: () => import('./demo/application/helpdesk/helpdesk.module').then(module => module.HelpdeskModule)
      },
      {
        path: 'full-calendar', canActivate: [AuthGuard, KioskGuard],
        loadChildren: () => import('./demo/extension/full-event-calendar/full-event-calendar.module')
          .then(module => module.FullEventCalendarModule)
      } 
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth', canActivate: [LogoutGuard],
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
