<div class="toggle-icon" *ngIf="showExpand" (click)="onAddClass()"></div>
<div class="main details" #mainDetails></div>
<app-card #pastVisitCard class="new-designed-card" [hidHeader]="true" [options]="false">
    <div class="past-visit">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 patient-info-sec">
                <span class="name"
                    *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">{{appointmentInfoDetails?.appointment?.purposeOfVisit}}
                    Visit </span>
                <span class="name"
                    *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'">{{appointmentInfoDetails?.appointment?.purposeOfVisit}}</span>
                <span class="gender-age"
                    *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">{{getAppointmentTime(appointmentInfoDetails?.appointment?.dateTime)}}</span>

               | <span class="gender-age"><b>Coverage Type:</b> &nbsp;
                    <label *ngIf="appointmentInfoDetails?.unInsured === true">Un-Insured</label>
                    <label *ngIf="appointmentInfoDetails?.selfPay === true" class="name">Self-Pay</label>
                    <label *ngIf="appointmentInfoDetails?.healthPass === true" class="name">Health pass</label>
                    <label
                        *ngIf="appointmentInfoDetails?.unInsured === false && appointmentInfoDetails?.selfPay === false && appointmentInfoDetails?.healthPass === false">Insurance</label>
                    <label class="radio-inline"><input type="radio" name="Health Pass"></label>
                </span>
            <!-- </div>
            <div class="col-lg-12 col-md-12 col-sm-12 patient-info-sec mt-2"> -->
            | &nbsp;&nbsp; <span *ngIf="appointmentCostOfCareDetails?.apptCostofCare?.patientId">Patient ID:
                    &nbsp;&nbsp;<b>{{appointmentCostOfCareDetails?.apptCostofCare?.patientId}}</b></span>
                <span *ngIf="appointmentCostOfCareDetails?.apptCostofCare?.claimId">&nbsp;&nbsp;| &nbsp;&nbsp;Claim ID:
                    &nbsp;&nbsp;<b>{{appointmentCostOfCareDetails?.apptCostofCare?.claimId}}</b></span>
			<span 
                    *ngIf="appointmentInfoDetails?.selfPay === true">| <b>Amount Paid :</b> ${{appointmentPiadAmount}}</span>
            </div>
        </div>
    </div>

    <aw-wizard #pastVisitDetailsWizard class="arc-wizard new-designed-left-arcs" [class.hideLeftNavigation]="isCareFlowView" id="pastVisitsDetailsWizard"
        [defaultStepIndex]="defaultStepIndex" navBarLocation="left" [awNavigationMode] navigateBackward="allow"
        navigateForward="allow">

        <aw-wizard-step stepTitle="Patient Info" awOptionalStep (stepEnter)="navigateToTop(mainDetails)" id="commonPastVisit_PatientInfo">

            <h5 class="header">Demographics</h5>
            <hr />
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle"> First Name </label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label> {{ appointmentInfoDetails?.firstName || '--' | titlecase}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle"> Middle Name </label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label> {{ appointmentInfoDetails?.middleName || '--' | titlecase}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle"> Last Name </label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label> {{ appointmentInfoDetails?.lastName || '--' | titlecase}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle"> DOB </label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label> {{ appointmentInfoDetails?.dob || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle"> Gender </label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label> {{ appointmentInfoDetails?.gender || '--'}}</label>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle"> Age </label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.age}}</label>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle"> Marital Status </label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.maritalStatus || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Race</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.race || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Ethnicity</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.ethnicity || '--'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.address?.street || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.address?.city || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.address?.state || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>
							{{addPrefixZipCode(appointmentInfoDetails?.address?.zipCode,'') || '--'}}
							</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Phone Number</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.patientPrimaryPhone || '--'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Email</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.email || '--'}}</label>
                        </div>
                    </div>
                </div>
            </div>
			<!-- ID Card details -->
			<h5 class="header">ID Documents</h5>
			<hr />
			<div class="row">
				
				<div class="col-lg-10 col-md-10 col-sm-12">
					<ng-container *ngIf="checkCommonIdDocument">

                        <div class="row">
                            <div class="col-lg-10 col-md-10 col-sm-12">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-sm-5 text-center">
                                        <iframe id="dl-past-visit-card" width="100%" height="180px" frameBorder="0" style="overflow: none;"></iframe>
                                    </div>
                                </div>
            
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-sm-5 text-center  mb-4">
                                        <button class="icon-queue-events signoff-action-bg clearfix" placement="bottom" ngbTooltip="Full View">
                                            <i class="las la-expand " (click)="downloaFullview(checkCommonIdDocument, insuraceCardModal)"></i>
                                        </button>                        </div>
                                </div>
                            </div>
                        </div>
						<!-- <div class="col-lg-12 col-md-12 col-sm-12 text-center mt-2">
							<iframe id="dl-past-visit-card" frameBorder="0" width="auto" height="auto"></iframe>
							
						</div>
						
						<div class="col-lg-12 col-md-12 col-sm-12 text-center mt-2">
							<button class="icon-queue-events signoff-action-bg clearfix" placement="bottom" ngbTooltip="Full View">
								<i class="las la-expand " (click)="downloaFullview(checkCommonIdDocument, insuraceCardModal)"></i>
							</button>
                            
						</div> -->
					</ng-container>
					<ng-container *ngIf="!checkCommonIdDocument">
						<div class="mb-4">
							ID Document is not available.
						</div>
					</ng-container>
				</div>
			</div>
            <!-- Insurance Details -->
            <h5 class="header">Insurance Details</h5>
            <hr />
			<!--<div class="row">
			</ng-container>
			<app-common-insurance-addl [idType]="'appointmentId'" [pagename]="'viewdetails'" [userInfoDetails]="appointmentInfoDetails"></app-common-insurance-addl>
       </ng-container>
	   </div>-->
          <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Coverage Type</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label *ngIf="appointmentInfoDetails?.unInsured === true">Un-Insured</label>
                            <label *ngIf="appointmentInfoDetails?.selfPay === true">Self-Pay</label>
                            <label *ngIf="appointmentInfoDetails?.healthPass === true">Health Pass</label>
                            <label
                                *ngIf="appointmentInfoDetails?.unInsured === false && appointmentInfoDetails?.selfPay === false && appointmentInfoDetails?.healthPass === false">Insurance</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row insurance-items"
                *ngFor="let insurance of appointmentInfoDetails?.insuranceDetails; let i = index">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h5 style="float: right;color: #296746;" *ngIf="insurance?.active">Active</h5>
                    <h5 style="float: right;color: red" *ngIf="!insurance?.active">Inactive</h5>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Insurance Name</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{insurance?.name || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Member ID / Subscriber ID</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{insurance?.memberId || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Payor ID</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{insurance?.payerId || '--'}} </label>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Group Number</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{insurance?.groupNumber || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Coverage Sequence</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{insurance?.priority || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Insurance Class</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{insurance?.insuranceClass || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="download-insurance-card mt-2" *ngIf="insurance?.insuranceCardImageId">
					
                        <!--<h5 class="download-insurance">
                            <span
                                (click)="downloadInsuranceCard(insurance?.insuranceCardImageId, insuraceCardPastVisitViewModal)">View/Download
                                Insurance Card <br /> <i class="las la-cloud-download-alt"></i></span>
                        </h5>-->
						
						<h5 class="download-insurance">
                                

                                <ng-container>
                                    <div class="row">

                                       <div class="col-lg-12 col-md-12 col-sm-12  text-center">
                                            <!--<ng-container *ngIf="insurance?.docType === 'application/pdf'">
                                                <iframe
                                                    id="{{insurance?.insuranceCardImageId}}_add_insu_card_display_wizard"
                                                    frameBorder="0" width="auto" height="auto"></iframe>
                                            </ng-container>
                                            <ng-container *ngIf="insurance?.docType !== 'application/pdf'">
                                                <img style="width: 100%;" name="Image Shown"
                                                    id="{{insurance?.insuranceCardImageId}}_add_insu_card_display_wizard"  />
                                            </ng-container>-->
											<iframe
                                                    id="{{insurance?.insuranceCardImageId}}_add_insu_card_display_wizard"
                                                    frameBorder="0" width="auto" height="auto"></iframe>
                                        </div>

                                        <div class="col-sm-12 text-center">
                                            <button class="icon-queue-events signoff-action-bg clearfix" placement="bottom" ngbTooltip="Full View">
                                                <i class="las la-expand " id="expand-full-view"
                                                    (click)="viewInsuranceCard(insurance?.insuranceCardImageId, insuraceCardPastVisitViewModal)"></i>
                                            </button>
                                            

                                        </div>
                                    </div>

                                </ng-container>
                            </h5>
						
						
						
						
                    </div>
                    <div class="download-insurance-card mt-2" *ngIf="!insurance?.insuranceCardImageId">
                        <h5 class="download-insurance">Insurance card not available</h5>
                    </div>
                </div>

            </div>

            <ng-container *ngIf="!appointmentInfoDetails?.unInsured && !appointmentInfoDetails?.selfPay">
                <h5 class="header">Primary Subscriber Details</h5>
                <hr />
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">First Name</label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{subscriberDetails?.subscriberfirstname || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Last Name</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{subscriberDetails?.subscriberlastname || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">DOB</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{subscriberDetails?.subscriberdateOfBirth || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Gender</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{subscriberDetails?.subscribergender || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Relationship to Subscriber</label> <span class="label-colon">
                                    : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{subscriberDetails?.relationToSubscriber || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Emergency Contact Details section -->

            <h5 class="header">Emergency Contact Details</h5>
            <hr />
            <div class="row insurance-items" *ngFor="let contact of appointmentInfoDetails?.emergencyDetails">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Name</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label> {{contact?.name || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Relationship</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label> {{contact?.relationship || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Emergency Contact</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label> {{contact?.phone || '--'}} </label>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!appointmentInfoDetails?.emergencyDetails || appointmentInfoDetails?.emergencyDetails?.length === 0">
                <span>Emergency Contact Details are unavailable for this patient</span>
            </ng-container>
            <h5 class="header" style="margin-top: 16px;">Employer Details</h5>
            <hr />
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-3 col-md-3 col-sm-6">
                            <label class="labelStyle">Employer Name</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-6">
                            <label>{{subscriberDetails?.employerName || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{subscriberDetails?.employerAddress?.street || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{subscriberDetails?.employerAddress?.city || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{subscriberDetails?.employerAddress?.state || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>
							{{addPrefixZipCode(subscriberDetails?.employerAddress?.zipCode,'') || '--'}}
							
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="header">Billing Address Details</h5>
            <hr />
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.billingAddress?.street || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.billingAddress?.city || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.billingAddress?.state || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Zipcode</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>
							{{addPrefixZipCode(appointmentInfoDetails?.billingAddress?.zipCode,'') || '--'}}
							</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Preferred Pharmacy -->
            <h5 class="header">Preferred Pharmacy</h5>
            <hr />
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Pharmacy Name</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.pharmacy?.name || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Phone Number</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.pharmacy?.phoneNumber || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Fax</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.pharmacy?.fax || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.pharmacy?.address?.street || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.pharmacy?.address?.city || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.pharmacy?.address?.state || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>
							{{addPrefixZipCode(appointmentInfoDetails?.pharmacy?.address?.zipCode,'') || '--'}}
							
                            </label>
                        </div>
                    </div>
                </div>
            </div>
			<h5 class="header">Preferred Language</h5>
            <hr />
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Language for communication</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{appointmentInfoDetails?.preferredLanguage || '--'}} </label>
                        </div>
                    </div>
                </div>
				</div>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Consent Forms" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Consent Forms</h5>
                    <hr>
                </div>
            </div>
            <app-view-consent-forms [allConsentFormDocs]="allConsentFormDocs" [cardReload]="pastVisitCard">
            </app-view-consent-forms>
        </aw-wizard-step>

       <!-- <aw-wizard-step stepTitle="ID Documents" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>ID Documents</h5>
                    <hr>
                </div>
            </div>
            <div class="row images-sec">
                <ng-container *ngIf="checkCommonIdDocument">
                    <div class="col-lg-10 col-md-10 col-sm-12">
                       
                        <iframe id="dl-past-visit-card" frameBorder="0" width="100%" height="650px"></iframe>
                    </div>
                </ng-container>
                <ng-container *ngIf="!checkCommonIdDocument">
                    <div class="col-lg-10 col-md-10 col-sm-12">
                        <h5>ID Document is not available.</h5>
                    </div>
                </ng-container>
            </div>
        </aw-wizard-step>-->

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allCareFlowData?.chiefComplaints && checkIfSavedSections(allCareFlowData?.chiefComplaints)"
            stepTitle="Chief Complaints" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Chief Complaints</h5>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <ng-container *ngFor="let category of allCareFlowData?.chiefComplaints; let cati = index">
                        <ng-contaier *ngFor="let section of category?.sections">
                            <ng-contaier *ngFor="let answers of section?.answers">
                                <ul>
                                    <ng-container *ngFor="let value of answers?.actualValue; let listi = index">
                                        <li>{{value?.answer}} <span *ngIf="value?.answer === 'Other' && value?.notes">&nbsp;&nbsp;-&nbsp;&nbsp;<b>{{value?.notes}}</b></span></li>
                                    </ng-container>
                                </ul>
                            </ng-contaier>
                            <ng-container *ngIf="!section?.answers || section?.answers?.length === 0">
                                <label>No option has been selected under Chief Complaints.</label>
                            </ng-container>
                        </ng-contaier>
                        <ng-contaier *ngIf="category?.otherNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Additional Notes</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{category?.otherNotes || '--'}}</label>
                                </div>
                            </div>
                        </ng-contaier>
                    </ng-container>
                    <ng-container
                        *ngIf="!allCareFlowData?.chiefComplaints || allCareFlowData?.chiefComplaints?.length === 0">
                        <span>No option has been selected under Chief Complaints.</span>
                    </ng-container>
                </div>
            </div>
        </aw-wizard-step>

        <!-- History of Present Illness section -->
        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allCareFlowData?.hpi && allCareFlowData?.hpi?.length > 0 && checkIfSavedSections(allCareFlowData?.hpi)"
            stepTitle="History of Present Illness" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>History of Present Illness</h5>
                    <hr>
                </div>
            </div>
            <app-review-hpi [hpiSummaryData]="allCareFlowData?.hpi"
                [pastVisitMedications]="pastVisitMedications"></app-review-hpi>
        </aw-wizard-step>
        <!-- End HPI -->

        <!-- Vitals section -->
        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allCareFlowData?.vitals && allCareFlowData?.vitals?.length > 0 && checkIfSavedSections(allCareFlowData?.vitals)"
            stepTitle="Vitals" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Vitals</h5>
                    <hr>
                </div>
            </div>

            <ng-container *ngFor="let category of allCareFlowData?.vitals; let cati = index">
                <h5 *ngIf="category?.resourceType !== 'Vitals' || cati !== 0">{{category?.resourceType}}</h5>
                <ng-contaier *ngFor="let section of category?.sections">
                    <!-- <h6><u>{{section?.name}}</u></h6> -->
                    <ng-contaier>
                        <div class="row">

                            <div class="col-lg-6 col-md-6 col-sm-12" *ngFor="let answer of section?.answers">

                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">{{answer?.label}}</label> <span
                                            class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <ul class="no-list-style" *ngIf="answer?.label !== 'Blood Pressure (Systolic/Diastolic)' && answer?.label !== 'Temperature (In *F)' && answer?.label !== 'Oxygen Saturation %'">
                                            <ng-container
                                                *ngFor="let value of answer?.actualValue; let listi = index">
                                                <li>{{value?.answer || '--'}}</li>
                                            </ng-container>
                                        </ul>
                                        <ul class="no-list-style" *ngIf="answer?.label === 'Blood Pressure (Systolic/Diastolic)'">
                                            <ng-container
                                                *ngFor="let value of answer?.actualValue; let listi = index">
                                                <li>Reading {{listi+1}}:&nbsp;<b>{{value?.vitalSignName}} &nbsp; {{value?.systolic || '--'}} / {{value?.diastolic || '--'}}</b></li>
                                            </ng-container>
                                        </ul>
                                        <!-- Temperature -->
                                        <ul class="no-list-style" *ngIf="answer?.label === 'Temperature (In *F)'">
                                            <ng-container
                                                *ngFor="let value of answer?.actualValue; let listi = index">
                                                <li>Reading {{listi+1}}:&nbsp;<b>{{value?.temperature || value?.answer || '--'}}</b></li>
                                            </ng-container>
                                        </ul>
                                        <!-- Oxygen Saturation -->
                                        <ul class="no-list-style" *ngIf="answer?.label === 'Oxygen Saturation %'">
                                            <ng-container
                                                *ngFor="let value of answer?.actualValue; let listi = index">
                                                <li>Reading {{listi+1}}:&nbsp;<span *ngIf="value?.vitalSignName"><b>{{value?.vitalSignName}}&nbsp;-&nbsp;</b></span><b>{{value?.oxygenSaturation || value?.answer || '--'}}</b></li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>

                                <ng-container *ngIf="answer?.otherNotes">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">{{answer?.label}} Additional Notes</label> <span class="label-colon">
                                                :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{answer?.otherNotes || '--'}}</label>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </ng-contaier>
                </ng-contaier>

                <ng-container *ngIf="!category?.sections || category?.sections?.length === 0">
                    <span>No Vitals are captured for this patient.</span>
                </ng-container>

                <ng-container *ngIf="category?.otherNotes">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Additional Notes</label> <span
                                class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{category?.otherNotes || '--'}}</label>
                        </div>
                    </div>
                </ng-container>

            </ng-container>
        </aw-wizard-step>

        <!-- Review of System section -->
        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allCareFlowData?.reviewOfsystems && checkIfSavedSections(allCareFlowData?.reviewOfsystems)"
            stepTitle="Review of Systems" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Review of Systems</h5>
                    <hr>
                </div>
            </div>
            <ng-container *ngFor="let category of allCareFlowData?.reviewOfsystems; let cati = index;let last = last">
                <h5 *ngIf="category?.resourceType !== 'Review of Systems'">{{category?.resourceType}}</h5>
                <ng-contaier *ngFor="let section of category?.sections">
                    <h5 *ngIf="category?.resourceType !== section?.name">{{section?.name}}</h5>
                    <ng-contaier *ngFor="let answers of section?.answers">
                        <ng-container *ngIf="answers?.actualValue?.length > 0">
                            <h6>{{answers?.label}}</h6>
                            <ul>
                                <ng-container *ngFor="let value of answers?.actualValue; let listi = index">
                                    <li>{{value?.answer}}</li>
                                </ng-container>
                            </ul>
                        </ng-container>
                    </ng-contaier>
                </ng-contaier>
                <ng-container *ngIf="!category?.sections || category?.sections?.length === 0">
                    <span>No option has been selected under Review of Systems.</span>
                </ng-container>
                <ng-contaier *ngIf="category?.otherNotes && last">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Additional Notes</label> <span
                                class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{category?.otherNotes || '--'}}</label>
                        </div>
                    </div>
                </ng-contaier>
            </ng-container>

            <ng-container *ngIf="!allCareFlowData?.reviewOfsystems || allCareFlowData?.reviewOfsystems?.length === 0">
                <span>No option has been selected under Review of Systems.</span>
            </ng-container>

        </aw-wizard-step>

        <!-- Review of System section -->
        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && appointmentInfoDetails?.facilityId === 83"
            stepTitle="Bone Mineral Density Scan" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Bone Mineral Density Scan</h5>
                    <hr>
                </div>
            </div>
            <ng-container *ngFor="let category of allCareFlowData?.boneMineralDensityScan; let cati = index;let last = last">
                <h5 *ngIf="category?.resourceType !== 'Bone Mineral Density Scan'">{{category?.resourceType}}</h5>
                <ng-contaier *ngFor="let section of category?.sections">
                    <h5 *ngIf="category?.resourceType !== section?.name">{{section?.name}}</h5>
                    <ng-contaier *ngFor="let answers of section?.answers">
                        <ng-container *ngIf="answers?.actualValue?.length > 0">
                            <h6>{{answers?.label}}</h6>
                            <ul>
                                <ng-container *ngFor="let value of answers?.actualValue; let listi = index">
                                    <li>{{value?.answer}}</li>
                                </ng-container>
                            </ul>
                        </ng-container>
                    </ng-contaier>
                </ng-contaier>
                <ng-container *ngIf="!category?.sections || category?.sections?.length === 0">
                    <span>No option has been selected under Bone Mineral Density Scan.</span>
                </ng-container>
                <ng-contaier *ngIf="category?.otherNotes && last">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Additional Notes</label> <span
                                class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{category?.otherNotes || '--'}}</label>
                        </div>
                    </div>
                </ng-contaier>
            </ng-container>

            <ng-container *ngIf="!allCareFlowData?.boneMineralDensityScan || allCareFlowData?.boneMineralDensityScan?.length === 0">
                <span>No option has been selected under Bone Mineral Density Scan.</span>
            </ng-container>

        </aw-wizard-step>

        <!-- Medical History section -->
        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && appointmentInfoDetails?.facilityId === 83"
            stepTitle="Medical History" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Medical History</h5>
                    <hr>
                </div>
            </div>
            <ng-container *ngFor="let category of allCareFlowData?.medicalHistory; let cati = index;let last = last">
                <h5 *ngIf="category?.resourceType !== 'Medical History'">{{category?.resourceType}}</h5>
                <ng-contaier *ngFor="let section of category?.sections">
                    <h5 *ngIf="category?.resourceType !== section?.name">{{section?.name}}</h5>
                    <ng-contaier *ngFor="let answers of section?.answers">
                        <ng-container *ngIf="answers?.actualValue?.length > 0">
                            <h6>{{answers?.label}}</h6>
                            <ul>
                                <ng-container *ngFor="let value of answers?.actualValue; let listi = index">
                                    <li>{{value?.answer}}</li>
                                </ng-container>
                            </ul>
                        </ng-container>
                    </ng-contaier>
                </ng-contaier>
                <ng-container *ngIf="!category?.sections || category?.sections?.length === 0">
                    <span>No option has been selected under Medical History.</span>
                </ng-container>
                <ng-contaier *ngIf="category?.otherNotes && last">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Additional Notes</label> <span
                                class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{category?.otherNotes || '--'}}</label>
                        </div>
                    </div>
                </ng-contaier>
            </ng-container>

            <ng-container *ngIf="!allCareFlowData?.medicalHistory || allCareFlowData?.medicalHistory?.length === 0">
                <span>No option has been selected under Medical History.</span>
            </ng-container>

        </aw-wizard-step>


        <!-- Behavioral Screening section -->
        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allCareFlowData?.behavioralScreening && checkIfSavedSections(allCareFlowData?.behavioralScreening)"
            stepTitle="Behavioral Screening" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Behavioral Screening</h5>
                    <hr>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <!-- <th>Type</th> -->
                            <th style="width: 70% !important;">Question</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="mainSectionDisplay(allCareFlowData?.behavioralScreening)">
                            <ng-container *ngFor="let item of allCareFlowData?.behavioralScreening; let i = index">
                                <tr *ngIf="checkBehaviouralScreenigDisplay(item?.sections)">
                                    <td colspan="2">
                                        <h5 class="behaviour-screening-type"
                                            *ngIf="checkCountsSectionsDisplay(item?.resourceType, 'Alcohol')">
                                            {{item?.resourceType}}
                                            <div class="calculation result">
                                                <ng-container>
                                                    <div class="negative">AUDIT-C Score<span
                                                            class="pointsCount">{{pointsCount(item?.sections)}}
                                                            points</span></div>
                                                </ng-container>
                                            </div>
                                        </h5>
                                        <h5 class="behaviour-screening-type"
                                            *ngIf="checkCountsSectionsDisplay(item?.resourceType, 'GAD')">
                                            {{item?.resourceType}}
                                            <div class="calculation result">
                                                <ng-container *ngIf="pointsCount(item?.sections) <= 2">
                                                    <div class="negative">Negative<span
                                                            class="pointsCount">{{pointsCount(item?.sections)}}
                                                            points</span></div>
                                                </ng-container>
                                                <ng-container *ngIf="pointsCount(item?.sections) > 2">
                                                    <div class="positive">Positive<span
                                                            class="pointsCount">{{pointsCount(item?.sections)}}
                                                            points</span></div>
                                                </ng-container>
                                            </div>
                                        </h5>

                                        <h5 class="behaviour-screening-type"
                                            *ngIf="checkCountsSectionsDisplay(item?.resourceType, 'PHQ9')">
                                            {{item?.resourceType}}
                                            <div class="calculation result">
                                                <ng-container *ngIf="pointsCount(item?.sections) <= 2">
                                                    <div class="negative">Negative<span
                                                            class="pointsCount">{{pointsCount(item?.sections)}}
                                                            points</span></div>
                                                </ng-container>
                                                <ng-container *ngIf="pointsCount(item?.sections) > 2">
                                                    <div class="positive">Positive.<span
                                                            class="pointsCount">{{pointsCount(item?.sections)}}
                                                            points</span></div>
                                                </ng-container>
                                            </div>
                                        </h5>
                                        <h5 class="behaviour-screening-type"
                                            *ngIf="checkCountsSectionsDisplay(item?.resourceType, 'PHQ2')">
                                            {{item?.resourceType}}
                                            <div class="calculation result">
                                                <ng-container *ngIf="pointsCount(item?.sections) <= 2">
                                                    <div class="negative">Negative<span
                                                            class="pointsCount">{{pointsCount(item?.sections)}}
                                                            points</span></div>
                                                </ng-container>
                                                <ng-container *ngIf="pointsCount(item?.sections) > 2">
                                                    <div class="positive">Positive. Use PHQ-9 for further
                                                        evaluation<span
                                                            class="pointsCount">{{pointsCount(item?.sections)}}
                                                            points</span></div>
                                                </ng-container>
                                            </div>
                                        </h5>
                                    </td>
                                </tr>
                                
                                <!--  *ngIf="questioValue?.value !== null" -->
                                <ng-container *ngFor="let questioValue of item?.sections">
                                    <ng-container *ngFor="let answersValue of questioValue?.answers">
                                        <ng-container *ngFor="let actualValue of answersValue?.actualValue">
                                            <tr *ngIf="actualValue?.answer">
                                                <!-- <td></td> -->
                                                <td style="white-space: break-spaces;">{{actualValue?.question}}</td>
                                                <td>{{actualValue?.answer === null ? '--' : actualValue?.answer}}</td>
                                            </tr>
                                        </ng-container>
                                        
                                    </ng-container>
                                </ng-container>

                                <tr *ngIf="item?.otherNotes && item?.otherNotes != ''">
                                    <td colspan="2">Additional Notes: &nbsp;&nbsp;{{item?.otherNotes}}</td>
                                </tr>

                            </ng-container>
                        </ng-container>

                        <ng-container
                            *ngIf="!allCareFlowData?.behavioralScreening || allCareFlowData?.behavioralScreening?.length === 0 || !mainSectionDisplay(allCareFlowData?.behavioralScreening)">
                            <tr>
                                <td colspan="2" style="text-align: center;">Behavioral Screening has not been documented.</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </aw-wizard-step>

        <!-- In House -Labs Section -->
        <aw-wizard-step
            *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allInternalLabOrders?.length > 0"
            stepTitle="InHouse Labs" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div id="inhouselabs">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>InHouse Labs</h5>
                        <hr>
                    </div>
                </div>
                <div class="in-house-labs-sec">
                    <ng-container *ngFor="let internalOrder of allInternalLabOrders">
                        <!-- Urine Analysis Order -->
                        <ng-container *ngIf="internalOrder?.testName === 'Urine Analysis'">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Urine Analysis ({{internalOrder?.urinAnalysisReportForm?.type}}) -
                                            {{internalOrder?.status}}:</h5>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5><u>Physical Examination</u></h5>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Appearance</label> <span class="label-colon">
                                                    :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.appearance
                                                    || '--'}}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Color</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.color
                                                    ||
                                                    '--'}} </label>
                                            </div>
                                        </div>
                                        <!-- <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Physical Examination Notes</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.notes ||
                                                    '--'}} </label>
                                            </div>
                                        </div> -->
                                    </div>

                                    <!-- Chemical Examination -->
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5><u>Chemical Examination</u></h5>
                                        <div class="row">
                                            <ng-container
                                                *ngFor="let chemicalExam of internalOrder?.urinAnalysisReportForm?.chemicalExamination; let i = index">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <label>{{chemicalExam?.examName}}&nbsp;&nbsp;:</label>
                                                    <label class="text-center">&nbsp;&nbsp;{{chemicalExam?.value ||
                                                        '--'}}</label>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Urine Analysis Notes</label> <span
                                            class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.urinAnalysisReportForm?.notes || '--'}} </label>
                                    </div>

                                </div>
                            </div>
                        </ng-container>

                        <!-- Pregnancy Test Order -->
                        <ng-container *ngIf="internalOrder?.testName === 'Pregnancy Test'">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Pregnancy Test - {{internalOrder?.status}}:</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">HCG</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.pregnancyTest?.result || '--'}} </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Result</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{getHcgResult(internalOrder?.pregnancyTest?.result) || '--'}} </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.pregnancyTest?.notes || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Drug Screening Order -->
                        <ng-container *ngIf="internalOrder?.testName === 'Drug Screening'">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Drug Screening - {{internalOrder?.status}}:</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <ng-container
                                        *ngFor="let drugItem of internalOrder?.drugScreening?.drugs; let i = index">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{drugItem?.drugName}} -
                                                {{drugItem?.deviceCode}}&nbsp;&nbsp;:</label>
                                            <label class="text-center">&nbsp;&nbsp;{{drugItem?.result || '--'}}</label>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.drugScreening?.notes || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Rapid A Strep Test Order -->
                        <ng-container *ngIf="internalOrder?.testName === 'Rapid A Strep Test'">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Rapid A Strep Test - {{internalOrder?.status}}:</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Result</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.strepTest?.result || '--'}} </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h6>Diagnosis Values:</h6>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <ul>
                                            <ng-container
                                                *ngFor="let diagnosis of internalOrder?.strepTest?.diagnosis; let i = index">
                                                <li>{{diagnosis}}</li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.strepTest?.notes || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Anticoagulant Test Order -->
                        <ng-container *ngIf="internalOrder?.testName === 'Anticoagulant Test'">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Anticoagulant Test - {{internalOrder?.status}}:</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Result Range</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.anticoagulantTestResults?.range || '--'}} </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Units</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.anticoagulantTestResults?.units || '--'}} </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.anticoagulantTestResults?.notes || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Blood Glucose Order -->
                        <ng-container *ngIf="internalOrder?.testName === 'Blood Glucose'">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Blood Glucose - {{internalOrder?.status}}:</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Blood Glucose (With Fasting)</label> <span
                                            class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.bloodGlucoseResults?.bloodSugarWithFasting || '--'}}
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Blood Glucose (Without Fasting)</label> <span
                                            class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.bloodGlucoseResults?.bloodSugarWithoutFasting || '--'}}
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">A1C Value</label> <span
                                            class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.bloodGlucoseResults?.a1CValue || '--'}} </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.bloodGlucoseResults?.notes || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Covid-19 Test Order -->
                        <ng-container *ngIf="internalOrder?.testName === 'Covid-19 Test'">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Covid-19 Test - {{internalOrder?.status}}:</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Test Type</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.covidTestResults?.type || '--'}} </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Test Result</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.covidTestResults?.result || '--'}} </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.covidTestResults?.notes || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Influenza Test Order -->
                        <ng-container *ngIf="internalOrder?.testName === 'Influenza Test'">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Influenza Test - {{internalOrder?.status}}:</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <ng-container
                                        *ngFor="let influTest of internalOrder?.influenzaTest?.tests; let i = index">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <label>{{influTest?.fluType}}&nbsp;&nbsp;:</label>
                                            <label class="text-center">&nbsp;&nbsp;{{influTest?.result || '--'}}</label>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.influenzaTest?.notes || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Cologuard Test Order -->
                        <ng-container *ngIf="internalOrder?.testName === 'Cologuard Test'">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Cologuard Test - {{internalOrder?.status}}:</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Requisition Date</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.requisitionDate ?
                                            httpService?.formatDobTimeZoneWithDisplay(internalOrder?.requisitionDate) :
                                            '--'}} </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Collection Date</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{internalOrder?.collectionDate ?
                                            httpService?.formatDobTimeZoneWithDisplay(internalOrder?.collectionDate) :
                                            '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </ng-container>
                    <ng-container *ngIf="allInternalLabOrders?.length === 0">
                        <span>Inhouse Labs are not applicable for this patient’s record.</span>
                    </ng-container>
                </div>
            </div>
        </aw-wizard-step>

        <!-- In House - Injections Section -->
        <aw-wizard-step
            *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allInternalVaccineDetails && allInternalVaccineDetails?.length > 0"
            stepTitle="Immunizations" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div id="inhouselabsInjections">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Immunizations</h5>
                        <hr>
                    </div>
                </div>
                <div class="vaccination-sec">
                    <ng-conatiner *ngFor="let vaccine of allInternalVaccineDetails">
                        <div class="inhouseLabs-bg">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Order Name</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.orderName || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Order Type</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.orderType || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Vaccine Date Time</label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.vaccineDateTime ?
                                                httpService?.getAppointmentTime(vaccine?.vaccineDateTime) : '--' ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Lot #</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.lot || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Vaccine Expiry Time</label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.expiryDate ?
                                                httpService?.formatDobTimeZoneWithDisplay(vaccine?.administrationDetails?.expiryDate)
                                                : '--' || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">NDC ID</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.ndcId || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">CPT Code</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.cptCode || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Admin Code</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.adminCode || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Strength</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.strength || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Measurement</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.measurement || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Dose</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.dose || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Units</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.units || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Route</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.route || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Site</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.site || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Vaccine Priority</label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.vaccinePriority || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Registery</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.registry || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <!-- Administartion Details -->
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Administration Details</h5>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h6 class="mt-2"><b>With NPI</b></h6>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.npiDetails?.performedBy ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.npiDetails?.dateTime ?
                                                httpService?.getAppointmentTime(vaccine?.administrationDetails?.npiDetails?.dateTime)
                                                : '--' || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.npiDetails?.reason ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h6 class="mt-2"><b>Consent Documented</b></h6>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.consentDetails?.performedBy ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.consentDetails?.dateTime ?
                                                httpService?.getAppointmentTime(vaccine?.administrationDetails?.consentDetails?.dateTime)
                                                : '--' || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.consentDetails?.reason ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h6 class="mt-2"><b>Education Materials Provided</b></h6>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.educationDetails?.performedBy ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.educationDetails?.dateTime ?
                                                httpService?.getAppointmentTime(vaccine?.administrationDetails?.educationDetails?.dateTime)
                                                : '--' || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.educationDetails?.reason ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-3 col-md-3 col-sm-6">
                                            <label class="labelStyle"><b>Comments/Notes</b></label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-9 col-md-9 col-sm-6">
                                            <label>{{vaccine?.administrationDetails?.administrationNote ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-conatiner>
                    <ng-conatiner *ngIf="!allInternalVaccineDetails || allInternalVaccineDetails?.length === 0">
                        <span>Inhouse Injections are not applicable for this patient’s record.</span>
                    </ng-conatiner>
                </div>
                <div class="inhouseLabs-bg">
                    <!-- Vaccine Allergies -->
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Allergies</h5>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Reaction</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="allergiesFromVaccine?.length > 0">
                                            <tr *ngFor="let allergy of allergiesFromVaccine">
                                                <td>{{allergy?.name || '--'}}</td>
                                                <td>{{allergy?.reaction || '--'}}</td>
                                                <td>{{allergy?.documentedDate ?
                                                    httpService?.formatDobTimeZoneWithDisplay(allergy?.documentedDate) :
                                                    '--'}}</td>
                                            </tr>
                                        </ng-container>

                                        <ng-container *ngIf="allergiesFromVaccine?.length === 0">
                                            <tr>
                                                <td colspan="3" class="text-center">No allergies have been entered on this patient's record.</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aw-wizard-step>

        <!-- In House - Procedures Section -->
        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allInHouseProcedures && allInHouseProcedures?.length > 0"
            stepTitle="Procedures" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div id="inhouselabsProcedures">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Procedures</h5>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="vaccination-sec">
                <ng-conatiner *ngFor="let procedure of allInHouseProcedures">
                    <div class="inhouseLabs-bg">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Procedure Name</label> <span class="label-colon">
                                            : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.procedureName || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName === 'Biopsies'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Biopsy Type</label> <span class="label-colon">
                                            : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.biospyType || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName === 'Corneal Abrasion'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Encounter Type</label> <span class="label-colon">
                                            : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.encounterType || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Laceration'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Procedure Type</label> <span class="label-colon">
                                            : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.procedureType || '--'}}</label>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">

                                        <label class="labelStyle"
                                            *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">Procedure
                                            Ordered Date</label>
                                        <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.procedureOrderedDate ?
                                            httpService?.getAppointmentTime(procedure?.procedureOrderedDate) : '--'
                                            || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName === 'Nebulizer Treatment' || procedure?.procedureName === 'Electromyography Diagnostic' || procedure?.procedureName === 'EMG'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Performed Date</label>

                                        <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.procedurePerformedDate ?
                                            httpService?.formatDobTimeZoneWithDisplay(procedure?.procedurePerformedDate)
                                            : '--'
                                            || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <ng-container *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Laceration'">
                                            <label class="labelStyle"
                                            >Created Date</label> <span class="label-colon"> : </span>
                                        </ng-container>
                                        <ng-container *ngIf="procedure?.procedureName === 'ECG' || procedure?.procedureName === 'Laceration'">
                                            <label class="labelStyle"
                                            >Performed
                                            Date</label> <span class="label-colon"> : </span>
                                        </ng-container>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.createdDate ?
                                            httpService?.formatDobTimeZoneWithDisplay(procedure?.administeredDetails?.createdDate)
                                            : '--' || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Medication</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.medication || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Order Type</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.orderType === null ? '--' :
                                            procedure?.orderType[0]}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Nebulizer Time</label> <span class="label-colon">
                                            : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.duration.value || '--'}} <span
                                                *ngIf="procedure?.duration.value">mins</span></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">CPT Code</label> <span class="label-colon">
                                            : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.adminCode || '--'}}</label>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Count</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.count || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Position</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.position || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Size</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.size || '--'}}</label>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Admin Code</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.adminCode || '--'}}</label>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName === 'ECG'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Indication For ECG</label> <span
                                            class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.indicationForECG || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'ECG'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">CPT Code</label> <span class="label-colon">
                                            : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.adminCode || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Vitals -->

                        <div class="row" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5>Pulse Oximetry</h5>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Stage</th>
                                                <th>Pulse</th>
                                                <th>Oxygen</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="procedure?.vitals?.length > 0">
                                                <tr *ngFor="let vital of procedure?.vitals">
                                                    <td>{{vital?.stage || '--'}}</td>
                                                    <td>{{vital?.pulse || '--'}}</td>
                                                    <td>{{vital?.oxygen || '--'}}</td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="procedure?.vitals?.length === 0">
                                                <tr>
                                                    <td colspan="3" class="text-center">Pulse Oximetry details are unavailable.</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- EMG - Nerve Condiiton -->
                        <div class="row" *ngIf="procedure?.procedureName === 'Electromyography Diagnostic' || procedure?.procedureName === 'EMG'">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5>Nerve Condition Study Details</h5>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Scope</th>
                                                <th>NCS Units</th>
                                                <th>NCS CPT/Procedure Code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="procedure?.nerveConditionStudy?.length > 0">
                                                <tr *ngFor="let vital of procedure?.nerveConditionStudy">
                                                    <td>{{vital?.scope || '--'}}</td>
                                                    <td>{{vital?.ncsUnit || '--'}}</td>
                                                    <td>{{vital?.ncsUnitCptCode || '--'}}</td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="procedure?.nerveConditionStudy?.length === 0">
                                                <tr>
                                                    <td colspan="3" class="text-center">Nerve Condition Study Details are unavailable.</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- Body Site -->
                        <div class="row"
                            *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Nebulizer Treatment' && procedure?.procedureName !== 'Electromyography Diagnostic' && procedure?.procedureName !== 'EMG'">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5>Body Site</h5>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Procedure Type</th>
                                                <th *ngIf="procedure?.procedureName === 'Injections and aspirations'">Joint Type</th>
                                                <th>Name</th>
                                                <th *ngIf="procedure?.procedureName === 'Incision and Drainage'">Nature of Lesion</th>
                                                <th *ngIf="procedure?.procedureName !== 'Trigger Point Injections' && procedure?.procedureName !== 'Injections and aspirations'">Depth</th>
                                                <th *ngIf="procedure?.procedureName !== 'Trigger Point Injections' && procedure?.procedureName !== 'Injections and aspirations' && procedure?.procedureName !== 'Corneal Abrasion'">Size</th>
                                                <th>Count/Units</th>
                                                <th>CPT/Procedure Code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="procedure?.bodySite?.length > 0">
                                                <tr *ngFor="let site of procedure?.bodySite">
                                                    <td>{{site?.procedureType || '--'}}</td>
                                                    <td *ngIf="procedure?.procedureName === 'Injections and aspirations'">{{site?.jointType || '--'}}</td>
                                                    <td>{{site?.name || '--'}}</td>
                                                    <td *ngIf="procedure?.procedureName === 'Incision and Drainage'">{{site?.natureOfLesion || '--'}}</td>
                                                    <td
                                                        *ngIf="procedure?.procedureName !== 'Trigger Point Injections' && procedure?.procedureName !== 'Injections and aspirations'">
                                                        {{site?.depth || '--'}}</td>
                                                    <td
                                                        *ngIf="procedure?.procedureName !== 'Trigger Point Injections' && procedure?.procedureName !== 'Injections and aspirations' && procedure?.procedureName !== 'Corneal Abrasion'">
                                                        {{site?.revisedSize || site?.size || '--'}}</td>
                                                    <td>{{site?.count || '--'}}</td>
                                                    <td>{{site?.cptCode || '--'}}</td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="procedure?.bodySite?.length === 0">
                                                <tr>
                                                    <td colspan="8" class="text-center">Body site details are unavailable.
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- ECG Interpretation -->
                        <div class="row" *ngIf="procedure?.procedureName === 'ECG'">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5>Interpretation of ECG</h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Rate</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.ecgInterpretation?.rate || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Rhythm</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.ecgInterpretation?.rhythm || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Axis</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.ecgInterpretation?.axis || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">P Waves</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.ecgInterpretation?.pwaves || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">PR Interval</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.ecgInterpretation?.printerval || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">QRS Complex</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.ecgInterpretation?.qrscomplex || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">QT Interval</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.ecgInterpretation?.qtinterval || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">ST-Segment</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.ecgInterpretation?.stsegment || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">T Waves</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.ecgInterpretation?.twaves || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                        <label class="labelStyle">Impression</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-9">
                                        <label>{{procedure?.ecgimpression || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                        <label class="labelStyle">Comments/Notes</label> <span class="label-colon">
                                            : </span>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-9">
                                        <label>{{procedure?.administeredDetails?.administrationNotes ||
                                            '--'}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Administartion Details -->
                        <div class="row" *ngIf="procedure?.procedureName !== 'ECG'">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5>Administration Details</h5>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h6 class="mt-2"><b>With NPI</b></h6>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.npiDetails?.performedBy ||
                                            '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.npiDetails?.dateTime ?
                                            httpService?.getAppointmentTime(procedure?.administeredDetails?.npiDetails?.dateTime)
                                            : '--' || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.npiDetails?.reason ||
                                            '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h6 class="mt-2"><b>Consent Documented</b></h6>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.consentDetails?.performedBy ||
                                            '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.consentDetails?.dateTime ?
                                            httpService?.getAppointmentTime(procedure?.administeredDetails?.consentDetails?.dateTime)
                                            : '--' || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.consentDetails?.reason ||
                                            '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12"
                                *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                <h6 class="mt-2"><b>Education Materials Provided</b></h6>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.educationDetails?.performedBy ||
                                            '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.educationDetails?.dateTime ?
                                            httpService?.getAppointmentTime(procedure?.administeredDetails?.educationDetails?.dateTime)
                                            : '--' || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12"
                                *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.educationDetails?.reason ||
                                            '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <label class="labelStyle"><b>Comments/Notes</b></label> <span
                                            class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.administrationNotes ||
                                            '--'}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-conatiner>
                <ng-conatiner
                    *ngIf="!allInHouseProcedures || allInHouseProcedures?.length === 0">
                    <span>Inhouse Procedures do not apply to this patient’s record.</span>
                </ng-conatiner>
            </div>
        </aw-wizard-step>

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allCareFlowData?.exam && checkIfSavedSections(allCareFlowData?.exam)"
            stepTitle="Exam" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Exam</h5>
                    <hr>
                </div>
            </div>
            <div class="exam-sec">
                <ng-container *ngFor="let exam of allCareFlowData?.exam; let exami = index">
                    <ng-container *ngFor="let examSec of exam?.sections">
                        <ng-container *ngIf="checkIsValuesPresent(examSec)">
                            <h5><u>{{examSec?.name}}</u></h5>
                            <ng-container *ngFor="let answer of examSec?.answers">
                                <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                                    <ul>
                                        <li>{{value?.answer || '--'}}</li>
                                    </ul>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="exam?.sections?.length === 0">
                        <span>No exams are chosen for this patient.</span>
                    </ng-container>

                    <ng-contaier *ngIf="exam?.otherNotes">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Additional Notes</label> <span
                                    class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{exam?.otherNotes || '--'}}</label>
                            </div>
                        </div>
                    </ng-contaier>

                </ng-container>

                <ng-container *ngIf="!allCareFlowData?.exam || allCareFlowData?.exam?.length === 0">
                    <span>No exams are chosen for this patient.</span>
                </ng-container>
            </div>
        </aw-wizard-step>

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && (allRxOrderDetails?.length > 0 || alllabRadOrderDetails?.length > 0)"
            stepTitle="Orders" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Orders</h5>
                    <hr>
                </div>
            </div>
            <div class="rx-order-details">
                <h5>Rx Order Details</h5>
                <!-- datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders" -->
                <div class="table-responsive" style="overflow-x: scroll;">
                    <table class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>SI.NO</th>
                                <th>Drug</th>
                                <th>Qty</th>
                                <th>DAYS SUPPLY</th>
                                <th>Rx Order Type</th>
                                <th>Refills</th>
                                <th>Order Date</th>
                                <th>Order Status</th>
                                <th>Pharmacy Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let rxOrder of allRxOrderDetails; let i = index">
                                <tr>
                                    <td>{{i+1}}</td>
                                    <td>{{rxOrder?.drug_name || '--'}}</td>
                                    <td>{{rxOrder?.quantity || '--'}}</td>
                                    <td>{{rxOrder?.days_supply || '--'}}</td>
                                    <td>{{rxOrder?.rx_type || '--'}}</td>
                                    <td>{{rxOrder?.refills || '--'}}</td>
                                    <td>{{rxOrder?.transmission_date || '--'}}</td>
                                    <td>{{rxOrder?.transmittal_status || '--'}}</td>
                                    <td>
                                        <a href="javascript:" placement="bottom"
                                            ngbTooltip="{{rxOrder?.ph_address_1}}, {{rxOrder?.ph_state}}, {{rxOrder?.ph_zip}} - {{rxOrder?.ph_phone}}">{{rxOrder?.ph_name
                                            || '--'}}</a>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="allRxOrderDetails?.length === 0">
                                <tr>
                                    <td colspan="8" style="text-align: center;">Rx Orders are unavailable for this patient.</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Lab Rad Details -->
            <div class="leb-order-details">
                <h5>Lab/Rad Order Details</h5>
                <!-- datatable [dtOptions]="dtOptionsLabOrders" [dtTrigger]="dtTriggerLabOrders" -->
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>SI.NO</th>
                                <th>Order Number</th>
                                <th>Order Type</th>
                                <th>Order Date</th>
                                <th>Order Collection Date</th>
                                <th>Order Transmission Date</th>
                                <th>Order Status</th>
                                <th>Lab Name</th>
                                <th>Results</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let labOrder of alllabRadOrderDetails; let i = index">
                                <tr>
                                    <td>{{i+1}}</td>
                                    <td>
                                        <div class="d-flex">
                                            <ng-container>
                                                <a class="order-num" id="commonPastVisit_orderReq" href="javascript:;" placement="right" ngbTooltip="Order Requisition"
                                                    (click)="downloadLabRequisition(labOrder?.externalOrderNumber)">{{labOrder?.orderNumber
                                                    || '--'}}</a>
                                            </ng-container>
                                        
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <a class="order-num" href="javascript:;" placement="right" ngbTooltip="Order Details"
                                                (click)="getOrderDetails(labOrder)" id="commonPastVisit_info"><span class="material-symbols-outlined">info</span></a>
                                        </div>
                                        <ng-container *ngIf="labOrder?.requestDetails && labOrder?.requestDetails?.length > 0">
                                            <table>
                                                <tr class="no-top-pad">
                                                    <td></td>
                                                    <td colspan="7">
                                                        <span class="tests-ordered" style="font-size: 12px;
                                                        font-weight: bold;" *ngFor="let test of labOrder?.requestDetails; let last = last">
                                                            {{test?.testDescription || '--'}}
                                                            <ng-container *ngIf="test?.diagnosisCodes">
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                (<span *ngFor="let code of test?.diagnosisCodes">{{code?.code}} &nbsp;&nbsp;</span>)
                                                            </ng-container>
                                                            <span *ngIf="!last">,&nbsp;&nbsp;</span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </ng-container>
                                    </td>

                                    <td>{{labOrder?.orderType || '--'}}</td>
                                    <td>{{labOrder?.requestDate || '--'}}</td>
                                    <td>{{labOrder?.collectionDate || '--'}}</td>
                                    <td>{{(labOrder?.transmissionDate | date:'MM/dd/yyyy hh:mm a') || '--'}}</td>
                                    <td>{{statusCodeDesc(labOrder?.orderStatus) || '--'}}</td>
                                    <td>{{labOrder?.labName || '--'}}</td>
                                    <td>
                                        <ng-container>
                                            <button class="icon-queue-events btn-status-success" placement="left" id="commonPastVisit_visiblity"
                                                (click)="showHl7Details(labOrder)" ngbTooltip="View Results"><span
                                                    class="material-symbols-outlined">
                                                    visibility
                                                </span></button>
                                        </ng-container>
                                        <ng-container *ngIf="labOrder?.reportKey">
                                            <button class="icon-queue-events-file" placement="left" id="commonPastVisit_pdf"
                                                (click)="getReportsAvailable(labOrder)" ngbTooltip="PDF Report"><i
                                                    class="fa fa-file-pdf"></i></button>
                                        </ng-container>
                                        <ng-container *ngIf="!labOrder?.reportKey">
                                            <button class="icon-queue-events-file" placement="left" id="commonPastVisit_pdfDisable"
                                                ngbTooltip="Report Not Available"><i
                                                    class="fa fa-file-pdf disabled"></i></button>
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="alllabRadOrderDetails?.length === 0">
                                <tr>
                                    <td colspan="9" style="text-align: center;">Lab Orders are unavailable for this patient.</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{treatmentFlowAddNotes?.planNotes || '--'}} </label>
                        </div>
                    </div>
                </div>
            </div>
        </aw-wizard-step>

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && allCareFlowData?.carePlan && checkIfSavedSections(allCareFlowData?.carePlan)"
            stepTitle="Care Plan" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row mb-3">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h5>Care Plan</h5>
                    <hr />
                </div>
            </div>

            <ng-container *ngFor="let category of allCareFlowData?.carePlan; let cati = index">
                <h5>{{category?.resourceType}}</h5>
                <ng-contaier *ngFor="let section of category?.sections">
                    <!-- *ngIf="section?.name !== 'New Orders'" -->
                    <ng-contaier>
                        <h6 *ngIf="section?.name !== 'New Orders'"><u>{{section?.name}}</u></h6>
                        <div class="row">

                            <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let answer of section?.answers">

                                <div class="form-group row"
                                    *ngIf="section?.name !== 'Assessment' && category?.resourceType !== 'Billing Codes' && answer?.label !== 'Billing / Procedure/ HCPIC Codes' && answer?.label !== 'Prescriptions' && answer?.label !== 'New Lab/Rad Orders'">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">{{answer?.label}}</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <ul class="no-list-style" class="carePlan{{answer?.label}}">
                                            <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                                                <li>{{value?.answer || value?.duration || value?.units || '--'}}&nbsp;
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf="answer?.actualValue?.length === 0">
                                                <li>--</li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>

                                <ng-container *ngIf="answer?.otherNotes">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">{{answer?.label}} Additional Notes</label> <span class="label-colon">
                                                :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{answer?.otherNotes || '--'}}</label>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-contaier *ngIf="section?.name === 'Assessment'">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="table-responsive">
                                                <table class="table table-striped table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>ICD Code</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let tbody of answer?.actualValue; let listi = index">
                                                            <td>{{tbody?.code}}</td>
                                                            <td>{{tbody?.description || cmpareCodesDesc(tbody?.code)}}</td>
                                                        </tr>
                                                        <tr *ngIf="answer?.actualValue?.length === 0">
                                                            <td colspan="2" class="text-center">ICD Codes are unavailable for this patient.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </ng-contaier>

                                <!-- Medications -->
                                <ng-contaier
                                    *ngIf="category?.resourceType === 'Billing Codes' && answer?.label === 'Billing / Procedure/ HCPIC Codes'">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="table-responsive">
                                                <table class="table table-striped table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>CPT Code</th>
                                                            <th>Description</th>
                                                            <th>MODIFIER</th>
                                                            <th>DOSAGE</th>
                                                            <!-- <th>Strength</th> -->
                                                            <!-- <th>Measurement Unit</th> -->
                                                            <th>DAYS/UNITS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngFor="let tbody of answer?.actualValue; let listi = index">
                                                            <tr *ngIf="tbody?.codeDescription !== 'DELETED'">
                                                                <td>{{tbody?.cptCode}}</td>
                                                                <td style="white-space: normal;">{{tbody?.codeDescription}}</td>
                                                                <td>{{tbody?.modifier || '--'}}</td>
                                                                <td>{{tbody?.dosage || '--'}}</td>
                                                                <!-- <td>{{tbody?.strength || '--'}}</td> -->
                                                                <!-- <td>{{tbody?.measurementUnitCode || '--'}}</td> -->
                                                                <td>{{tbody?.daysUnits || '--'}}</td>
                                                            </tr>
                                                        </ng-container>

                                                        <tr *ngIf="answer?.actualValue?.length === 0">
                                                            <td colspan="5" class="text-center">Billing codes are unavailable for this patient.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </ng-contaier>
                            </div>
                        </div>
                    </ng-contaier>
                </ng-contaier>

                <ng-container *ngIf="category?.otherNotes">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Additional Notes</label> <span
                                class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{category?.otherNotes || '--'}}</label>
                        </div>
                    </div>
                </ng-container>
                
            </ng-container>
        </aw-wizard-step>

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
            stepTitle="Progress Notes" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Progress Notes</h5>
                    <hr>
                </div>
            </div>

            <div class="journal-notes-sec">

                <div class="table-responsive new-designed-table">
                    <table class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Notes</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let notes of allCareFlowData?.progressNotes; let i = index">
                                <tr>
                                    <td>{{notes?.notes}}</td>
                                    <td>{{notes?.auditInfo?.createdDate | date: 'MM/dd/yyyy hh:mm a'}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!allCareFlowData?.progressNotes || allCareFlowData?.progressNotes?.length === 0">
                                <tr>
                                    <td class="text-center" colspan="4">
                                        <h5>Progress Notes are unavailable for this patient.</h5>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>

                <!-- <ng-container *ngFor="let notes of allCareFlowData?.progressNotes; let i = index">
                    <ul>
                        <li *ngIf="notes?.notes"><b>({{notes?.auditInfo?.createdDate | date: 'MM/dd/yyyy hh:mm a'}})</b> -
                            {{notes?.notes}}</li>
                    </ul>
                </ng-container> -->
                <!-- <ng-container
                    *ngIf="!allCareFlowData?.progressNotes || allCareFlowData?.progressNotes?.length === 0">
                    <h5>Progress Notes are unavailable for this patient.</h5>
                </ng-container> -->
            </div>
        </aw-wizard-step>

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
            stepTitle="Addendum" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Addendum</h5>
                    <hr>
                </div>
            </div>

            <div class="journal-notes-sec">
                <div class="table-responsive new-designed-table">
                    <table class="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Reason</th>
                                <th>Initials</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let addendum of allCareFlowData?.addendum; let i = index">
                                <tr>
                                    <td>{{addendum?.auditInfo?.lastUpdatedDateTime | date: 'MM/dd/yyyy hh:mm a'}}</td>
                                    <td>{{addendum?.reason || '--'}}</td>
                                    <td>{{addendum?.initials}}</td>
                                    <td>{{addendum?.notes}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!allCareFlowData?.addendum || allCareFlowData?.addendum?.length === 0">
                                <tr>
                                    <td class="text-center" colspan="4">
                                        <h5>Addendum is unavailable for this patient.</h5>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </aw-wizard-step>

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
            stepTitle="Post-Visit Summary" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Post-Visit Summary</h5>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <span>Summary: <b>{{allCareFlowData?.dischargeInstructions?.notes || 'N/A'}}</b></span>
                </div>
            </div>
        </aw-wizard-step>
        <!-- Payments -->
        <aw-wizard-step stepId="accountBalance" stepTitle="Account Balance" *ngIf="!isCareFlowView && !noAccountBalanace" awOptionalStep (stepEnter)="navigateToTop(mainDetails); loadAccountbalance()">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Account Balance</h5>
                    <!-- <button class="btn btn-primary payment-btn" (click)="openPayment()"><i class="las la-credit-card"></i>
                        &nbsp;Pay</button> -->
                    <hr>
                </div>
            </div>
            <app-account-balance-new [appointmentId]="appointmentId" [cardRefresh]="pastVisitCard"  (paymentReloadAccBalance)="getAmountPaidForAppointment();"></app-account-balance-new>
        </aw-wizard-step>


        <aw-wizard-step stepId="clinicalDocuments" *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
            stepTitle="Clinical Documents" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div class="row">
                <div class="col-sm-12">
                    <h5>Clinical Documents</h5>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="table-responsive new-designed-table">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Document Name</th>
                                    <th>Document Type</th>
                                    <th>Reviewed Date</th>
                                    <th>Reviewed Notes</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="lab-tds" *ngFor="let document of allAdditonalDocuments">
                                    <td>{{document?.documentName || '--'}}</td>
                                    <td>{{document?.documentType || '--'}}</td>
                                    <td>{{document?.reviewNotes?.updatedDate | date: 'MM/dd/yyyy hh:mm a' || '--'}}</td>
                                    <td style=" word-wrap: break-all; white-space: normal;">{{document?.reviewNotes?.notes || '--'}}</td>
                                    <td class="edit-delet-actions text-center">

                                        <button class="icon-queue-events signoff-action-bg clearfix" placement="bottom"
                                            ngbTooltip="View Document"
                                            (click)="downloadDocument(document, additionalDocsViewModal)"><span
                                                class="material-symbols-outlined">visibility</span></button>
                                        <!-- *ngIf="document?.documentType === 'Lab Results' || document?.documentType === 'Radiology Results'" -->
                                        <ng-container>
                                            <!-- <button class="icon-queue-events signoff-action-bg clearfix"
                                                placement="bottom" ngbTooltip="Send Response"
                                                (click)="navigatePatientJournal()"><i class="las la-reply"></i></button> -->
                                                
                                                <!-- <button class="icon-queue-events resubmit-action-bg" placement="bottom" ngbTooltip="Notify"
                    (click)="lateNotify()"><i class="lab la-telegram"></i></button> -->
                                        </ng-container>
                                        <!-- <button class="icon-queue-events signoff-action-bg clearfix" placement="bottom"
                                            ngbTooltip="Review Notes"
                                            (click)="updateRecordReview(document,reviewLabRecord)">
                                            <span _ngcontent-hmi-c289="" class="material-symbols-outlined">sync_saved_locally</span></button> -->


                                        <!-- <button class="icon-queue-events delete-action-bg" (click)="deleteMedicalRecordOpen(document, deletConfirmPatientMedical)" placement="bottom" ngbTooltip="Delete"><span
                                            class="material-symbols-outlined">delete</span></button> -->
                                    </td>
                                </tr>
                                <tr *ngIf="allAdditonalDocuments?.length === 0">
                                    <td colspan="4" class="text-center">Documents are not available</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </aw-wizard-step>

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
            stepTitle="Diagnosis Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div id="DiagnosisDetails">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Diagnosis Details</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="assesment-sec">
                            <ng-container *ngFor="let assessment of assessmentValues; let i = index">
                                <ul>
                                    <li>{{assessment?.code}} - {{assessment?.codeDescription}}</li>
                                </ul>
                            </ng-container>
                            <ng-container *ngIf="assessmentValues?.length === 0">
                                <h5>Diagnosis are unavailable for this patient.</h5>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </aw-wizard-step>

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
            stepTitle="Medication Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div id="MedicationDetails">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Medication Details</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 rx-order-details">
                        <!-- datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders" -->
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders"
                                class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Order Date</th>
                                        <th>Drug Name</th>
                                        <th>Strength</th>
                                        <th>Qty</th>
                                        <th>DAYS SUPPLY</th>
                                        <th>Duration</th>
                                        <th>Problem Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let rxOrder of allRxOrderDetails; let i = index">
                                        <tr>
                                            <td>{{rxOrder?.creation_date || '--'}}</td>
                                            <td>{{rxOrder?.drug_name || '--'}}</td>
                                            <td>{{rxOrder?.drug_label_name || '--'}}</td>
                                            <td>{{rxOrder?.units_of_measure || '--'}}</td>
                                            <td>{{rxOrder?.quantity || '--'}}</td>
                                            <td>{{rxOrder?.days_supply || '--'}}</td>
                                            <td>{{rxOrder?.pharmacist_comments || '--'}}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="allRxOrderDetails?.length === 0">
                                        <tr>
                                            <td colspan="8" style="text-align: center;">Medications are unavailable for this patient.
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </aw-wizard-step>
        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
            stepTitle="Lab Orders Results" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div id="LabOrdersResults">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Lab Orders Results</h5>
                        <hr>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive new-designed-table">
                            <table datatable [dtOptions]="dtOptionsLabOrders" [dtTrigger]="dtTriggerLabOrders"
                                class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Visit Date</th>
                                        <th>Order Name</th>
                                        <th>Lab Attribute Name</th>
                                        <th>Lab Attribute Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let labResult of legacyLabOrderResults; let i = index">
                                        <tr>
                                            <td>{{labResult?.lastvisitdate || '--'}}</td>
                                            <td>{{labResult?.orderName || '--'}}</td>
                                            <td>{{labResult?.labAttribute || '--'}}</td>
                                            <td>{{labResult?.labAttributeValue || '--'}}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!legacyLabOrderResults && legacyLabOrderResults?.length === 0">
                                        <tr>
                                            <td colspan="4" style="text-align: center;">Lab Orders Results are unavailable for this patient.</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </aw-wizard-step>

        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
            stepTitle="Immunization Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div id="ImmunizationDetails">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Immunization Details</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 rx-order-details">
                        <!-- datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders" -->
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders"
                                class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Lot Number</th>
                                        <th>Administered Date</th>
                                        <th>Vaccine Name</th>
                                        <th>Vaccine Type</th>
                                        <th>Dosage</th>
                                        <th>Provider Name</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let immunization of allCareFlowData?.immunizationDetails; let i = index">
                                        <tr>
                                            <td>{{immunization?.lotNumber || '--'}}</td>
                                            <td>{{immunization?.administeredDate || '--'}}</td>
                                            <td>{{immunization?.vaccineName || '--'}}</td>
                                            <td>{{immunization?.vaccineType || '--'}}</td>
                                            <td>{{immunization?.dosage || '--'}}</td>
                                            <td>{{immunization?.serviceProviderName || '--'}}</td>
                                            <td>{{immunization?.status || '--'}}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!allCareFlowData?.immunizationDetails && allCareFlowData?.immunizationDetails?.length === 0">
                                        <tr>
                                            <td colspan="7" style="text-align: center;">Immunization Details are unavailable for this patient.</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Referral Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)"
            *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">
            <div id="referral-details">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Referral Details</h5>
                        <hr>
                    </div>
                </div>
                <div class="row insurance-items" *ngFor="let referral of allCareFlowData?.referralDetails">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Referral Date</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{referral?.auditInfo?.lastUpdatedDateTime ?
                                    getAppointmentTime(referral?.auditInfo?.lastUpdatedDateTime)
                                    : ''}}</label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-6 col-sm-12"></div> -->
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Referring Provider</label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{referral?.referringProvider || ''}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Reciever/Referral Provider</label> <span class="label-colon">
                                    :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{referral?.referralProvider || ''}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Reason</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{referral?.reason || ''}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Last Service Provider Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)" >
            <div class="row" *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Doctor Name</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{allCareFlowData?.lastServiceProviderDetails?.name || '--'}} </label>
                        </div>
                    </div>
                </div>
            </div>
            <div id="LastServiceProviderDetailsPastVisits"
                *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Electronically Signed by {{allCareFlowData?.signOff?.doctorName}}</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">E-Signature</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="signature">
                                    <h5><i>{{allCareFlowData?.signOff?.doctorName || ''}}</i></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Initials</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="signature">
                                    <h5><i>{{allCareFlowData?.signOff?.doctorInitials || ''}}</i></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aw-wizard-step>
        <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
            stepTitle="Last Billing Provider Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div id="LastBillingProviderDetails">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Last Billing Provider Details</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="treatmentFlowAddNotes">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Doctor Name</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{allCareFlowData?.lastBillingProviderDetails?.name || '--'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aw-wizard-step>

        <aw-wizard-step stepId="finalCheckList" *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record' && isFollowUpFinalCheckLIstView"
            stepTitle="Final Check List" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
            <div id="finalcheckLIst">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Final Check List</h5>
                        <hr>
                    </div>
                </div>
                <app-final-check-list [patientDetailsInfo]="followUpAppointmentDetails"
                    [PatientDetailsCardReload]="pastVisitCard" [treatmentFlowData]="allCareFlowData"
                    [appointmentId]="appointmentId" [selfpayCheck]="appointmentInfoDetails?.selfPay" [paidAmount]="appointmentPiadAmount" (paymentInfoReload)="getAmountPaidForAppointment();"></app-final-check-list>
            </div>
        </aw-wizard-step>

    </aw-wizard>

</app-card>

<div class="custom-loader justify-content-center" *ngIf="loadSpinner">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [claimSubmitNotifyText]="claimSubmitNotifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Display the Consent Form PDF -->
<app-ui-modal #additionalDocsViewModal [hideHeader]="false" [modalCentered]="true" id="additionalDocsViewModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Documents</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="additionalDocsViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="additonal-doc-pdf-view" frameBorder="0" width="800px" height="425px"></iframe>
        </div>
    </div>
      
    <!-- <div class="app-modal-body" *ngIf="!confirmation">
        <div class="form-group" [formGroup]="progressNotesForm">
            <label for="notes">Review<span class="text-danger">*</span></label>
            <textarea class="form-control" rows="5" placeholder="Edit Review/Notes" id="reviewNotes"
                formControlName="reviewNotes"></textarea>
            <span class="text-danger"
                *ngIf="progressNotesForm?.get('reviewNotes')?.errors?.required && progressNotesForm?.get('reviewNotes')?.touched">Review
                Comments
                is Required</span>
        </div>
    </div>
    <div class="app-modal-body" *ngIf="confirmation">
        <h5 class="modal-title text-center">{{notifyText}}</h5>
        <h5 class="modal-title text-center">Would you like to assign a task to the nurse or staff to inform the patient?</h5>

    </div>
    <div class="app-modal-footer" *ngIf="confirmation"> <button type="button" class="btn btn-primary dash-actions"
            style="margin-right: 15px;" data-dismiss="modal" (click)="additionalDocsViewModal.hide();resetVal()">No</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="additionalDocsViewModal.hide();openTaskModal();">Yes</button>
    </div>

    <div class="app-modal-footer" *ngIf="!confirmation">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="additionalDocsViewModal.hide()">Cancel</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="updateJournalNotes(additionalDocsViewModal)">Submit</button>
    </div> -->
  


    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="commonPastVisit_ok1"
            (click)="additionalDocsViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- Square Payment Card -->
<!-- <app-payments-common-new [paymentConfigDetails]="paymentConfigDetails" [recieptID]="'common-past-visit-reciept-id'"></app-payments-common-new> -->
<app-payments-refactor [paymentConfigDetails]="paymentConfigDetails" [recieptID]="'common-past-visit-reciept-id'"></app-payments-refactor>

<app-ui-modal #insuraceCardPastVisitViewModal [hideHeader]="false" [modalCentered]="true"
    id="insuraceCardPastVisitViewModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Insurance Card</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardPastVisitViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="insurance-card-past-visit-multiple" frameBorder="0" width="100%" height="100%"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" data-dismiss="modal" id="commonPastVisit_ok2"
            (click)="insuraceCardPastVisitViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- Lab RAD Resulrs modal -->
<app-lab-rad-results-modal [labRadOrdersCard]="pastVisitCard"></app-lab-rad-results-modal>

<!-- Display the Order Details -->
<app-lab-rad-order-details-modal [cardComp]="pastVisitCard"></app-lab-rad-order-details-modal>

<app-hl7-details [cardComp]="pastVisitCard"></app-hl7-details>
<!-- Update Lab/Rad Progress Notes -->
<app-document-review (documentReviewUpdate)="refresh()"></app-document-review>
<app-new-task [idType]="'appointmentId'"></app-new-task>
<!-- Late Notify PopUp -->
<!-- <app-send-sms-notify></app-send-sms-notify> -->

<!-- insurance image shown -->
<app-ui-modal #insuraceCardModal [hideHeader]="false" [modalCentered]="true" id="insuraceCardModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Identity Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="uplolad-insurance-card-multiple-common" width="100%" height="400px" frameBorder="0"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" data-dismiss="modal" id="commonPastVisit_ok3"
            (click)="insuraceCardModal.hide()">Ok</button>
    </div>
</app-ui-modal>