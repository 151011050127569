<app-ui-modal id="createTaskModal" #createTaskModal [modalCentered]="true" [loadSpinner]="lateNotifySubmitClick"
    [hideHeader]="false" class="taskModal">
    <div class="app-modal-header">
        <h5 class="modal-title">{{isClaim ? 'Facility Action' : 'Tasks'}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="createTaskModal.hide();resetTask();closeTaskModal(createTaskModal)"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body" [formGroup]="NewTaskForm">
        <div id="taskDetails" *ngIf="displayTable">
            <app-card #taskCard cardClass="card-datatable" class="new-designed-card" [options]="false">
                <div class="table-responsive table-enc">
                    <table datatable [dtOptions]="dtOptionsTasksStatus" [dtTrigger]="dtTriggerTasksStatus"
                        class="table table-striped table-hover custom-table">
                        <thead>
                            <tr>
                                <th id="category_0" class="sortrow" (click)="sortClaimData($event)">CATEGORY</th>
                                <th id="createdBy_1" class="sortrow" (click)="sortClaimData($event)">OWNER</th>
                                <th id="assignee_2" class="sortrow" (click)="sortClaimData($event)">ASSIGNEE</th>
                                <th id="description_3" class="sortrow" (click)="sortClaimData($event)">DESCRIPTION</th>
                                <th id="status_4" class="sortrow" (click)="sortClaimData($event)">STATUS</th>
                                <th id="priority_5" class="sortrow" (click)="sortClaimData($event)">PRIORITY</th>
                                <th class="no-sort">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let task of allTasks; let i = index">
                                <td class="colWidthTask" placement="bottom"
                                    title="{{(task?.category?.length > 20) ? task?.category : ''}}">{{task?.category}}
                                </td>
                                <td>{{task?.createdBy}}</td>
                                <td>{{task?.assignee}}</td>
                                <td class="colWidthTask" placement="bottom"
                                    title="{{(task?.description?.length > 20) ? task?.description : ''}}">
                                    {{task?.description}}</td>
                                <td>{{task?.status}}</td>
                                <td>{{task?.priority}}</td>
                                <!--<td class="colWidthTask" placement="bottom"
                                    title="{{(task?.resolution?.length > 20) ? task?.resolution : ''}}">
                                    {{task?.resolution || '--'}}</td>-->

                                <td class="edit-delet-actions text-center">
                                    <button class="icon-queue-events signoff-action-bg" placement="bottom"
                                        *ngIf="task.status !== 'Completed'" id="update_{{task.taskId}}"
                                        ngbTooltip="Edit" (click)="openUpdatetask(task);navigateToTop(newtaskSection)"
                                        [ngClass]="task.taskId === selectedTask ? 'selected' : ''">
                                        <i class="las la-pencil-alt" aria-hidden="true"
                                            [ngClass]="task.taskId === selectedTask ? 'selected' : ''"></i>
                                    </button>
                                    <button class="icon-queue-events signoff-action-bg" placement="bottom"
                                        *ngIf="task.status === 'Completed'" id="update_{{task.taskId}}"
                                        ngbTooltip="View" (click)="openUpdatetask(task);navigateToTop(newtaskSection)"
                                        [ngClass]="task.taskId === selectedTask ? 'selected' : ''">

                                        <i class="las la-eye" aria-hidden="true"
                                            [ngClass]="task.taskId === selectedTask ? 'selected' : ''"></i>
                                    </button>
                                    <!-- <button class="icon-queue-events delete-action-bg" placement="bottom"
                                        ngbTooltip="Delete"> <i class="las la-trash-alt" aria-hidden="true"></i></button>-->
                                </td>
                            </tr>

                            <!--<tr  *ngIf="allTasks.length === 0">
								<td colspan="7" class="text-center">Data is unavailable.</td>
							  </tr>-->

                        </tbody>
                    </table>
                </div>
                <!-- pagination -->
                <div class="row d-flex-ver-center">
                    <div class="col-lg-6 col-md-6 col-sm-12">

                        <div class="test-details">
                            Showing {{this.stNumber}} to {{this.endNumber}} of {{this.totalRecords}} Entries
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 text-right">
                        <div class="test-details pagination">
                            <a *ngIf="this.pageNumber > 0" (click)="pageNumberChange(this.pageNumber, 'prev')">Prev</a>
                            <span *ngFor="let page of pagesArr ;let j=index">
                                <a style="cursor:auto" *ngIf="page === '...'">{{page}}</a>

                                <a *ngIf="page !== '...'" [ngClass]="page === (this.pageNumber + 1)  ? 'active' : ''"
                                    (click)="pageNumberChange(page, 'same')">{{page}}</a>

                            </span>
                            <!-- *ngIf="pagesArr && pagesArr?.length > 1" -->
                            <a *ngIf="this.totalPages > (this.pageNumber + 1) && this.pageNumber >= 0 && allTasks.length > 0"
                                (click)="pageNumberChange(this.pageNumber, 'next')">Next</a>
                        </div>
                    </div>
                </div>
                <!-- pagination -->
            </app-card>


            <!-- Spinner -->
            <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <hr>
        <div class="row" #newtaskSection id="newtaskSection">
            <div [ngClass]="!isUpdate ? 'col-md-12' : 'col-md-7'">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Category<span class="required">*</span></label>

                            <ng-select placeholder="Add or Choose Category" (change)="getTaskTypeList($event)"
                                id="newTask_category" [items]="categoriesList" [addTag]="addTag?.bind(this)"
                                formControlName="category" bindLabel="desc" bindValue="value"
                                [readonly]="disableCompleteField"></ng-select>


                            <label class="required"
                                *ngIf="NewTaskForm.controls['category'].hasError('required') && NewTaskForm.controls['category'].touched">Category
                                should be required</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Task Type <span class="required">*</span></label>
                            <input type="text" formControlName="taskType" class="form-control input-text"
                                id="newTask_TaskType" placeholder="Task Type" [readonly]="isClaim ? false : true">

                            <label class="required"
                                *ngIf="NewTaskForm.controls['taskType'].hasError('required') && NewTaskForm.controls['taskType'].touched">Task
                                Type should be required</label>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="isUpdate">
                        <div class="form-group">
                            <label class="form-label">Owner</label>
                            <input type="text" class="mb-3 form-control" id="newTask_owner" [readonly]="true"
                                formControlName="owner">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Assignee <span class="required">*</span></label>
                            <ng-select placeholder="Choose Asignee" id="newTask_assignee" [items]="asigneeList"
                                formControlName="assignee" bindLabel="name" bindValue="asssigneeUserId"
                                (change)="selectRoleType($event)" [readonly]="disableCompleteField"></ng-select>

                            <!--<select class="mb-3 form-control" formControlName="assignee" name="validation-doctor">
                <option *ngFor="let assignee of asigneeList" value="{{assignee?.firstName}} {{assignee?.lastName}}">{{assignee?.firstName}} {{assignee?.lastName}}</option>
      </select>-->

                            <label class="required"
                                *ngIf="NewTaskForm.controls['assignee'].hasError('required') && NewTaskForm.controls['assignee'].touched">Assignee
                                should be required</label>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="!isUpdate">
                        <div class="form-group">
                            <label class="form-label">Priority <span class="required">*</span></label>
                            <ng-select placeholder="Choose Priority" id="newTask_priority" [readonly]="disableField"
                                [items]="priorityList" formControlName="priority" bindLabel="desc"
                                bindValue="value"></ng-select>

                            <label class="required"
                                *ngIf="NewTaskForm.controls['priority'].hasError('required') && NewTaskForm.controls['priority'].touched">Priority
                                should be required</label>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Status <span class="required">*</span></label>
                            <ng-select placeholder="Choose Status" id="newTask_status" [items]="statusList"
                                formControlName="status" bindLabel="desc" bindValue="value"
                                [readonly]="disableCompleteField"></ng-select>

                            <label class="required"
                                *ngIf="NewTaskForm.controls['status'].hasError('required') && NewTaskForm.controls['status'].touched">Status
                                should be required</label>
                        </div>
                    </div>
                </div>


                <!-- <div class="row">

                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Status <span class="required">*</span></label>
                            <ng-select placeholder="Choose status" [items]="statusList" formControlName="status"
                                bindLabel="desc" bindValue="value" [readonly]="disableCompleteField"></ng-select>

                            <label class="required"
                                *ngIf="NewTaskForm.controls['status'].hasError('required') && NewTaskForm.controls['status'].touched">Status
                                should be required</label>
                        </div>
                    </div>
                </div> -->

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Comment <span class="required">*</span></label>

                            <textarea rows="3" class="form-control" id="newTask_comment" formControlName="description"
                                placeholder="Comments..." [readonly]="disableCompleteField"></textarea>
                            <label class="required"
                                *ngIf="NewTaskForm.controls['description'].hasError('required') && NewTaskForm.controls['description'].touched">Description
                                Notes should be required</label>
                        </div>
                    </div>
                </div>
                <!-- 	<div class="row">

          <div class="col-md-12" *ngIf="isUpdate">
                <div class="form-group">
                    <label class="form-label">Comment <span class="required" >*</span></label>
                    <textarea rows="3" class="form-control" formControlName="resolution"
                        placeholder="Resolution..."></textarea>
                    <label class="required"
                        *ngIf="NewTaskForm.controls['resolution'].hasError('required') && NewTaskForm.controls['resolution'].touched">Closure of a Task Required Resolution Details</label>
                </div>
            </div>
		-->
            </div>
            <div class="col-md-5" *ngIf="isUpdate" id="commentsection">
                <h5>Comments</h5>
                <div class="comments-container">
                    <div class="comments-list" *ngFor="let task of taskInfo?.taskDetails;">
                        <div class="task-comments">
                            <span class="task-title"
                                *ngIf="task?.auditUpdatedBy!== null">{{task?.auditUpdatedBy}}</span>
                            <span class="task-title"
                                *ngIf="task?.auditUpdatedBy == null">{{task?.auditCreatedBy}}</span>
                            <span class="date-time"> {{getAppointmentTime(task?.createdDateTime)}}</span>
                        </div>
                        <span class="">{{task?.description}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="app-modal-footer">
        <button class="btn btn-secondary" type="button" style="margin-right: 15px;" id="newTask_cancel"
            (click)="createTaskModal.hide();resetTask(); closeTaskModal(createTaskModal)">
            <span class="btn-text">Cancel</span>
        </button>
        <button *ngIf="!isUpdate" id="newTask_submit" class="btn btn-primary dash-actions" style="margin-right: 15px;"
            type="button" [disabled]="disableCompleteField" (click)="addTask()">
            <span class="btn-text">Submit</span>
        </button>

        <button *ngIf="isUpdate && !disableCompleteField" class="btn btn-primary dash-actions" type="button"
            id="newTask_update" (click)="updateTask()" [disabled]="disableCompleteField">
            <span class="btn-text">Update</span>
        </button>&nbsp;

        <!--<button *ngIf="isUpdate && !disableCompleteField" class="btn btn-primary dash-actions" type="button"
            (click)="resetTask(); closeTaskModal(createTaskModal)" [disabled]="disableCompleteField">
            <span class="btn-text">Cancel</span>
        </button>-->



    </div>
</app-ui-modal>


<div *ngIf="isfromClaim" id="createTaskModal">
    <div class="app-modal-body add-app-body" [formGroup]="NewTaskForm">
        <div id="taskDetails" *ngIf="displayTable">
            <app-card #taskCard cardClass="card-datatable" class="new-designed-card" [options]="false">
                <div class="table-responsive table-enc">
                    <table datatable class="table table-striped table-hover custom-table">
                        <thead>
                            <tr>
                                <th id="category_0" class="sortrow" (click)="sortClaimData($event)">CATEGORY</th>
                                <th id="createdBy_1" class="sortrow" (click)="sortClaimData($event)">OWNER</th>
                                <th id="assignee_2" class="sortrow" (click)="sortClaimData($event)">ASSIGNEE</th>
                                <th id="description_3" class="sortrow" (click)="sortClaimData($event)">DESCRIPTION</th>
                                <th id="status_4" class="sortrow" (click)="sortClaimData($event)">STATUS</th>
                                <th id="priority_5" class="sortrow" (click)="sortClaimData($event)">PRIORITY</th>
                                <th class="no-sort">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let task of allTasks; let i = index">
                                <td class="colWidthTask" placement="bottom"
                                    title="{{(task?.category?.length > 20) ? task?.category : ''}}">{{task?.category}}
                                </td>
                                <td>{{task?.createdBy}}</td>
                                <td>{{task?.assignee}}</td>
                                <td class="colWidthTask" placement="bottom"
                                    title="{{(task?.description?.length > 20) ? task?.description : ''}}">
                                    {{task?.description}}</td>
                                <td>{{task?.status}}</td>
                                <td>{{task?.priority}}</td>
                                <!--<td class="colWidthTask" placement="bottom"
                                    title="{{(task?.resolution?.length > 20) ? task?.resolution : ''}}">
                                    {{task?.resolution || '--'}}</td>-->

                                <td class="edit-delet-actions text-center">
                                    <button class="icon-queue-events signoff-action-bg" placement="bottom"
                                        *ngIf="task.status !== 'Completed'" id="update_{{task.taskId}}"
                                        ngbTooltip="Edit" (click)="openUpdatetask(task);navigateToTop(newtaskSection)"
                                        [ngClass]="task.taskId === selectedTask ? 'selected' : ''">
                                        <i class="las la-pencil-alt" aria-hidden="true"
                                            [ngClass]="task.taskId === selectedTask ? 'selected' : ''"></i>
                                    </button>
                                    <button class="icon-queue-events signoff-action-bg" placement="bottom"
                                        *ngIf="task.status === 'Completed'" id="update_{{task.taskId}}"
                                        ngbTooltip="View" (click)="openUpdatetask(task);navigateToTop(newtaskSection)"
                                        [ngClass]="task.taskId === selectedTask ? 'selected' : ''">

                                        <i class="las la-eye" aria-hidden="true"
                                            [ngClass]="task.taskId === selectedTask ? 'selected' : ''"></i>
                                    </button>
                                    <!-- <button class="icon-queue-events delete-action-bg" placement="bottom"
                                        ngbTooltip="Delete"> <i class="las la-trash-alt" aria-hidden="true"></i></button>-->
                                </td>
                            </tr>

                            <!--<tr  *ngIf="allTasks.length === 0">
								<td colspan="7" class="text-center">Data is unavailable.</td>
							  </tr>-->

                        </tbody>
                    </table>
                </div>
                <!-- pagination -->
                <div class="row d-flex-ver-center">
                    <div class="col-lg-6 col-md-6 col-sm-12">

                        <div class="test-details">
                            Showing {{this.stNumber}} to {{this.endNumber}} of {{this.totalRecords}} Entries
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 text-right">
                        <div class="test-details pagination">
                            <a *ngIf="this.pageNumber > 0" (click)="pageNumberChange(this.pageNumber, 'prev')">Prev</a>
                            <span *ngFor="let page of pagesArr ;let j=index">
                                <a style="cursor:auto" *ngIf="page === '...'">{{page}}</a>

                                <a *ngIf="page !== '...'" [ngClass]="page === (this.pageNumber + 1)  ? 'active' : ''"
                                    (click)="pageNumberChange(page, 'same')">{{page}}</a>

                            </span>
                            <!-- *ngIf="pagesArr && pagesArr?.length > 1" -->
                            <a *ngIf="this.totalPages > (this.pageNumber + 1) && this.pageNumber >= 0 && allTasks.length > 0"
                                (click)="pageNumberChange(this.pageNumber, 'next')">Next</a>
                        </div>
                    </div>
                </div>
                <!-- pagination -->
            </app-card>


            <!-- Spinner -->
            <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <hr>
        <div class="row" #newtaskSection id="newtaskSection">
            <div [ngClass]="!isUpdate ? 'col-md-12' : 'col-md-7'">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Category<span class="required">*</span></label>

                            <ng-select placeholder="Add or Choose Category" (change)="getTaskTypeList($event)"
                                id="newTask_category2" [items]="categoriesList" [addTag]="addTag?.bind(this)"
                                formControlName="category" bindLabel="desc" bindValue="value"
                                [readonly]="disableCompleteField"></ng-select>


                            <label class="required"
                                *ngIf="NewTaskForm.controls['category'].hasError('required') && NewTaskForm.controls['category'].touched">Category
                                should be required</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Task Type <span class="required">*</span></label>
                            <input type="text" formControlName="taskType" class="form-control input-text"
                                id="newTask_type2" placeholder="Task Type" [readonly]="isClaim ? false : true">

                            <label class="required"
                                *ngIf="NewTaskForm.controls['taskType'].hasError('required') && NewTaskForm.controls['taskType'].touched">Task
                                Type should be required</label>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="isUpdate">
                        <div class="form-group">
                            <label class="form-label">Owner</label>
                            <input type="text" class="mb-3 form-control" id="newTask_owner2" [readonly]="true"
                                formControlName="owner">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Assignee <span class="required">*</span></label>
                            <ng-select placeholder="Choose Asignee" id="newTask_assignee2" [items]="asigneeList"
                                formControlName="assignee" bindLabel="name" bindValue="asssigneeUserId"
                                (change)="selectRoleType($event)" [readonly]="disableCompleteField"></ng-select>

                            <!--<select class="mb-3 form-control" formControlName="assignee" name="validation-doctor">
                <option *ngFor="let assignee of asigneeList" value="{{assignee?.firstName}} {{assignee?.lastName}}">{{assignee?.firstName}} {{assignee?.lastName}}</option>
      </select>-->

                            <label class="required"
                                *ngIf="NewTaskForm.controls['assignee'].hasError('required') && NewTaskForm.controls['assignee'].touched">Assignee
                                should be required</label>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="!isUpdate">
                        <div class="form-group">
                            <label class="form-label">Priority <span class="required">*</span></label>
                            <ng-select placeholder="Choose Priority" id="newTask_priority2" [readonly]="disableField"
                                [items]="priorityList" formControlName="priority" bindLabel="desc"
                                bindValue="value"></ng-select>

                            <label class="required"
                                *ngIf="NewTaskForm.controls['priority'].hasError('required') && NewTaskForm.controls['priority'].touched">Priority
                                should be required</label>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Status <span class="required">*</span></label>
                            <ng-select placeholder="Choose Status" id="newTask_status2" [items]="statusList"
                                formControlName="status" bindLabel="desc" bindValue="value"
                                [readonly]="disableCompleteField"></ng-select>

                            <label class="required"
                                *ngIf="NewTaskForm.controls['status'].hasError('required') && NewTaskForm.controls['status'].touched">Status
                                should be required</label>
                        </div>
                    </div>
                </div>


                <!-- <div class="row">

                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Status <span class="required">*</span></label>
                            <ng-select placeholder="Choose status" [items]="statusList" formControlName="status"
                                bindLabel="desc" bindValue="value" [readonly]="disableCompleteField"></ng-select>

                            <label class="required"
                                *ngIf="NewTaskForm.controls['status'].hasError('required') && NewTaskForm.controls['status'].touched">Status
                                should be required</label>
                        </div>
                    </div>
                </div> -->

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Comment <span class="required">*</span></label>

                            <textarea rows="3" class="form-control" id="newTask_comment2" formControlName="description"
                                placeholder="Comments..." [readonly]="disableCompleteField"></textarea>
                            <label class="required"
                                *ngIf="NewTaskForm.controls['description'].hasError('required') && NewTaskForm.controls['description'].touched">Description
                                Notes should be required</label>
                        </div>
                    </div>
                </div>
                <!-- 	<div class="row">

          <div class="col-md-12" *ngIf="isUpdate">
                <div class="form-group">
                    <label class="form-label">Comment <span class="required" >*</span></label>
                    <textarea rows="3" class="form-control" formControlName="resolution"
                        placeholder="Resolution..."></textarea>
                    <label class="required"
                        *ngIf="NewTaskForm.controls['resolution'].hasError('required') && NewTaskForm.controls['resolution'].touched">Closure of a Task Required Resolution Details</label>
                </div>
            </div>
		-->
            </div>
            <div class="col-md-5" *ngIf="isUpdate" id="commentsection">
                <h5>Comments</h5>
                <div class="comments-container">
                    <div class="comments-list" *ngFor="let task of taskInfo?.taskDetails;">
                        <div class="task-comments">
                            <span class="task-title"
                                *ngIf="task?.auditUpdatedBy!== null">{{task?.auditUpdatedBy}}</span>
                            <span class="task-title"
                                *ngIf="task?.auditUpdatedBy == null">{{task?.auditCreatedBy}}</span>
                            <span class="date-time"> {{getAppointmentTime(task?.createdDateTime)}}</span>
                        </div>
                        <span class="">{{task?.description}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="app-modal-footer" style="text-align:center">
    <button class="btn btn-primary dash-actions" type="button" id="addtask-cancel"
            (click)="resetTask();">
            <span class="btn-text">Cancel</span>
        </button>&nbsp;
     <button *ngIf="!isUpdate" class="btn btn-primary dash-actions" style="margin-right: 15px;" type="button"
            id="addtask-submit" [disabled]="disableCompleteField" (click)="addTask()">
            <span class="btn-text">Submit</span>
        </button>

        <button *ngIf="isUpdate && !disableCompleteField" class="btn btn-primary dash-actions" type="button"
            id="addtask-update" (click)="updateTask()" [disabled]="disableCompleteField">
            <span class="btn-text">Update</span>
        </button>&nbsp;

        <!--<button *ngIf="isUpdate && !disableCompleteField" class="btn btn-primary dash-actions" type="button"
            (click)="resetTask(); closeTaskModal(createTaskModal)" [disabled]="disableCompleteField">
            <span class="btn-text">Cancel</span>
        </button>-->

        

    </div>


</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>