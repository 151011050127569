import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';



@Component({
  selector: 'app-hosp-initiate-referral',
  templateUrl: './hosp-initiate-referral.component.html',
  styleUrls: ['./hosp-initiate-referral.component.scss']
})
export class HospInitiateReferralComponent implements OnInit { 

  public initiateReferral: FormGroup;
  public getAppointmentSlotsAvailable: any[];
  public rescheduleAppntData: any;
  public loginDetails: any;
  public getAppointmentTimeReschedule: any;
  public rescheduleAppntClick: boolean;
  public notifyText: any;
  @ViewChild('initiateReferralModal') initiateReferralModal: UiModalComponent;
  public minAppointmentDate: Date;
  public loadCard: boolean; 
  @ViewChild(UiModalComponent) modalComp: UiModalComponent;
  public initiateReferralClick: boolean;
  @Output() onSaveinitiateReferral = new EventEmitter<boolean>(); 
  @Input() exceptionModal: ExceptionModalComponent;


  constructor(private _fb: FormBuilder, private httpService: HttpService) {
    this.initiateReferral = this._fb.group({
      'APPOINTMENT_START_DATE': [null, Validators.required],
      'APPOINTMENT_END_TIME': [null, Validators.required],
      'PREFERRED_REFERRAL_CHANNEL': [null, Validators.required],
    })
  }


  public preferredReferralChannelValues = [
    {value: 'Fax', desc: 'Fax'},
    {value: 'Email', desc: 'Email'}
  ]

  ngOnInit(): void {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.minAppointmentDate = this.httpService.convertDate(new Date());
  }

  
  // open search modal
  public openSearchModal(): void{
    this.initiateReferralModal.show();
  }
  

  // opem popup and set the sellected appointment details
  public openSetAppointment(appointment: any): void{
    this.initiateReferralModal.show();
    this.rescheduleAppntData = appointment;
    if (appointment && appointment.appointment.dateTime) {
      const splitDate = appointment.appointment.dateTime.toString().split('T');
      const timeSplit = splitDate[1].split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const formateDate = splitDate && splitDate[0];
      this.initiateReferral.get('APPOINTMENT_DATE').setValue(formateDate);
      this.initiateReferral.get('APPOINTMENT_TIME').setValue(formateDate);
    } 
  } 

  // re-schedule update
  public reschduleUpdate(rescheduleModal: any, notifyModal: any): void {
    this.statusUpdate(this.rescheduleAppntData.id, 're-schedule');
    this.cancelAppointmentOnReSchedule(this.rescheduleAppntData.webToken);
    const action = 'appointment/registration/webAppointment';
    delete this.rescheduleAppntData.id;
    delete this.rescheduleAppntData.appointmentStatus;
    delete this.rescheduleAppntData.webToken;
    delete this.rescheduleAppntData.qrToken;
    const params = this.rescheduleAppntData;
    const dateTime = `${this.initiateReferral.value.APPOINTMENT_DATE}T${this.initiateReferral.value.APPOINTMENT_TIME}`;
    const appointment = {
      "dateTime": dateTime,
      "doctorNpi": this.rescheduleAppntData.appointment.doctorNpi,
      "purposeOfVisit": this.rescheduleAppntData.appointment.purposeOfVisit,
      "typeOfService": this.rescheduleAppntData.appointment.typeOfService,
    }
    params.appointment = appointment;
    this.rescheduleAppntClick = true;
    this.loadCard = true;
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data.body.status === 'SUCCESS') { 
          this.notifyText = 'Appointment has been Re-scheduled successfully.' || data.body.message || '';
          notifyModal.show();
          rescheduleModal.hide();
          this.initiateReferral.reset();
        } else if (data.body.status === 'FAILED') {
          this.notifyText = data.body.message || AppConstantsListConfig?.uiErrorException;;
          notifyModal.show();
        }
        this.rescheduleAppntClick = false;
        this.loadCard = false;
      },
        error => {
          // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          // notifyModal.show();  
          this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.exceptionModal.modalShow();
       
          this.rescheduleAppntClick = false;
          this.loadCard = false;
        });
  }

  // This function is for to update the patient status
  public statusUpdate(appId: any, status: any): void {
    let action: any = 'appointment/registration/updateStatus';
    let params: any = {
      "apiName": "",
      "appointmentId": appId,
      "buttonClick": status,
      "cancellationReason": "",
      "email": this.loginDetails.userId,
      "facilityID": this.loginDetails.facilityId
    }
    if (status === 're-schedule') {
      params.cancellationReason = 're-schedule',
      params.buttonClick = 'cancel';
    }
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data.body.status == 'SUCCESS') {  
        }
      },
        error => {  
          this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal.modalShow();
     
        });
  }

  // cancel appointment on reschedule
  public cancelAppointmentOnReSchedule(webToken: any): void {
    let action: any = `appointment/registration/cancel?id=${webToken}&reason=re-schedule`;

    this.httpService.makeRequestWithAction(action, 're-schedule')
      .subscribe((data: any) => {

      },
        error => {
          let toastrData = "Server Error";
          this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal.modalShow();
        })
  }

  //reset the search form
  public resetSearchForm(): void{
    this.initiateReferral.reset();
  }
  
  public saveinitiateReferral() : void{
    this.initiateReferralModal.hide();
    this.onSaveinitiateReferral.emit(true);
  }
}
