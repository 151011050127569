<!-- Notifications Request -->
<app-ui-modal #notificationRequestModal [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
    id="notificationRequestModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="notificationRequestModal?.hide();resetPatientJournalNotesForm()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="patient-journal-notes-sec" [formGroup]="patientNotificationRequestForm">
            <div class="row">
            
               <input type="hidden" formControlName="channel">
			   <input type="hidden" formControlName="notes">
                

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">Does the Provider/Staff need to be notified?<span
                                class="text-danger">*</span></label><br />
                        <label class="radio-inline" *ngFor="let type of yesOrNoString">
                            <input type="radio" (change)="changeNotifyResponder($event)" name="notifyResponder" formControlName="notifyResponder"
                                [value]="type?.value"><span class="desc">{{type?.desc}}</span>
                        </label><br />
                        <span class="text-danger"
                            *ngIf="patientNotificationRequestForm?.get('notifyResponder')?.errors?.required && patientNotificationRequestForm?.get('notifyResponder')?.touched">Notify Provider/Staff is Required</span>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="patientNotificationRequestForm?.get('notifyResponder')?.value === 'Yes'">
                    <div class="form-group">
                        <label for="responderNPI">Responder<span class="text-danger">*</span></label>
                        <ng-select placeholder="Choose Responder" [addTag]="true" bindLabel="name" bindValue="npi"
                            (change)="changeProviderDetails($event)" formControlName="responderNPI" id="documentNotifyRequestResponse_responder"
                            [items]="allProviderDetails"></ng-select>
                        <span class="text-danger"
                            *ngIf="patientNotificationRequestForm?.get('responderNPI')?.errors?.required && patientNotificationRequestForm?.get('responderNPI')?.touched">Responder
                            is Required</span>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="documentNotifyRequestResponse_submit"
            (click)="savePatientJournalNotesRequest(notificationRequestModal)">Submit</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>