import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-hpi',
  templateUrl: './review-hpi.component.html',
  styleUrls: ['./review-hpi.component.scss']
})
export class ReviewHpiComponent {

  @Input() hpiSummaryData: any;
  @Input() pastVisitMedications: any;

}
