<div class="ros-summary-data">
    <ng-container *ngFor="let category of rosData; let cati = index; let last = last">
        <h5 *ngIf="category?.resourceType !== 'Review of Systems'">{{category?.resourceType}}</h5>
        <ng-contaier *ngFor="let section of category?.sections">
            <h5 *ngIf="category?.resourceType !== section?.name">{{section?.name}}</h5>
            <ng-contaier *ngFor="let answers of section?.answers">
                <ng-container *ngIf="answers?.actualValue?.length > 0">
                    <h6><b>{{answers?.label}}</b></h6>
                    <ul class="inline-list">
                        <ng-container *ngFor="let value of answers?.actualValue; let listi = index">
                            <li>{{value?.answer}} &nbsp; &nbsp;&nbsp;</li>
                        </ng-container>
                    </ul>
                </ng-container>
            </ng-contaier>
        </ng-contaier>
        <ng-container *ngIf="!category?.sections || category?.sections?.length === 0">
            <span>No option has been selected under {{category?.resourceType}}.</span>
        </ng-container>
        <ng-contaier *ngIf="category?.otherNotes && last">
            <div class="form-group row mb-0 inline-form">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                    </span>
                    <!-- </div>
                <div class="col-lg-6 col-md-6 col-sm-6"> -->
                    <label>{{category?.otherNotes || '--'}}</label>
                </div>
            </div>
        </ng-contaier>
    </ng-container>

    <ng-container *ngIf="!rosData || rosData?.length === 0">
        <span>No option has been selected under Review of Systems.</span>
    </ng-container>
</div>