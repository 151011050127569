<app-ui-modal #paymentsRefNewModal [modalCentered]="true" [loadSpinner]="loadSpinner" [spinnerMessageToUser]="spinnerMessageToUser" id="paymentsRefNewModal">
    <div class="app-modal-header" [formGroup]="switchArPatientInsurancePayForm">
        <h5 class="modal-title">Payments</h5>
        <div class="switch-sec" *ngIf="!accountLevelPay && pageName !== 'Healthpass'">
            AR Patient
            <label class="switch">
                <input type="checkbox" id="paymentRefactor_toggle" formControlName="switchCheck" (change)="checkIsARInsurancePatient($event)">
                <span class="slider round"></span>
            </label>
            AR Insurance
        </div>
        <div class="add-service-sec">
            <!-- && !accountLevelPay -->
            <ng-container
                *ngIf="pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                <button type="button" id="paymentRefactor_addService0" class="btn btn-secondary add-service" (click)="addPaymentServices()">Add
                    Service</button>
            </ng-container>
            <ng-container
                *ngIf="pageName !== 'Healthpass' && switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                <button type="button" id="paymentRefactor_addService1" class="btn btn-secondary add-service" (click)="addArInsurance()">Add
                    Service</button>
            </ng-container>

            <ng-container *ngIf="selectedUserInfoId || accoutLevelUserInfoId || selectedPayAppointment?.userInfoId">
                <button class="icon-queue-events user-service float-right" id="paymentRefactor_chartAccBal" placement="bottom"
                    ngbTooltip="Chart - Account Balance"
                    (click)="paymentsRefNewModal?.hide();navigateUserAccountBalance();closeModal()"><i
                        class="las la-user-circle"></i></button>
            </ng-container>
        </div>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="paymentsRefNewModal?.hide();closeModal()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row patient-basic-demographics" *ngIf="selectedPayAppointment">
            <div class="col-12 patient-info-sec">
                <h5 class="full-name mt-3" *ngIf="selectedPayAppointment?.lastName && selectedPayAppointment?.firstName"><b>
                    {{selectedPayAppointment?.lastName | titlecase}}, {{selectedPayAppointment?.firstName | titlecase}}
                     {{selectedPayAppointment?.middleName | titlecase}}</b>
                    <span class="gender-age" *ngIf="selectedPayAppointment?.gender">{{selectedPayAppointment?.gender}}</span>
                    <span class="gender-age"
                        *ngIf="selectedPayAppointment?.usrDateOfBirth || selectedPayAppointment?.dob || selectedPayAppointment?.age">{{selectedPayAppointment?.age || ageCalculate(selectedPayAppointment?.dob
                        || selectedPayAppointment?.usrDateOfBirth)}}</span>
                    <span class="gender-age"
                        *ngIf="selectedPayAppointment?.usrDateOfBirth || selectedPayAppointment?.dob">{{formatDob(selectedPayAppointment?.dob
                        || selectedPayAppointment?.usrDateOfBirth)}}</span>
                    <span *ngIf="selectedPayAppointment?.primaryPhone || selectedPayAppointment?.patientPrimaryPhone">
                        &nbsp;&nbsp; | &nbsp;&nbsp; <b><i
                                class="las la-phone"></i>&nbsp;{{selectedPayAppointment?.patientPrimaryPhone ||
                            selectedPayAppointment?.primaryPhone}}</b></span>
                </h5>
            </div>
        </div>
        <form class="paymentform">
            <ng-container *ngIf="!switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                <div class="ar-patient" [formGroup]="newPaymentServicesForm">
                    <div class="form-array-sec mt-2" formArrayName="paymentServicesForm">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <h6 class="mt-2"
                                    *ngIf="newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 0">
                                    <b>Services</b>
                                </h6>
                                <ng-container *ngIf="isSelectedServiceIsPresent">
                                    <h4 class="required error">{{isSelectedServiceIsPresent}}</h4>
                                </ng-container>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 ">
                                <h6 class="mt-2"
                                    *ngIf="newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 0">
                                    <b>Amount</b>
                                </h6>
                            </div>
                            
                            <div class="col-lg-3 col-md-3 col-sm-12 selfpay">
                                <h6 class="mt-2"
                                    *ngIf="newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 0 && copaySelected">
                                    <b>Service Date</b>
                                </h6>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-12 selfpay" style="padding-right: 0px;">
                                <h6 class="mt-2"
                                    *ngIf="newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 0 && !accountLevelPay">
                                    <b>Self-Pay</b>
                                </h6>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <h6 class="mt-2"
                                    *ngIf="newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 0">
                                    <b></b>
                                </h6>
                            </div>
                        </div>

                        <div class="row"
                            *ngFor="let service of newPaymentServicesForm?.get('paymentServicesForm')?.controls; let servI = index"
                            [formGroupName]="servI">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                    <ng-select id="servicesTypeSel" [addTag]="true" (change)="changeServiceTypeCheck(service, servI)"
                                        [readonly]="service?.get('serviceType')?.value === 'Credit' || isHealthpassReadinly || pageName === 'Healthpass' || switchArPatientInsurancePayForm?.get('switchCheck')?.value"
                                        placeholder="Add or Choose Service" [items]="paymentServices"
                                        [clearable]="false" formControlName="serviceType">
                                    </ng-select>
                                    <label class="required error"
                                        *ngIf="service?.get('serviceType')?.errors?.required && service?.get('serviceType')?.touched">Service
                                        Type is required</label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <div class="input-group input-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input type="text" id="paymentRefactor_amount" mask="separator.2" class="form-control amount-form-contrl"
                                        [readonly]="service?.get('serviceType')?.value === 'Credit' || isHealthpassReadinly || service?.get('serviceType')?.value === 'Past Visit Charges'"
                                        [attr.disabled]="pageName === 'Healthpass' ? 'true' : null"
                                        (keydown.enter)="preventdefault()" formControlName="amount"
                                        placeholder="0.00">
                                </div>
                                <label class="required error"
                                    *ngIf="service?.get('amount')?.errors?.required && service?.get('amount')?.touched">Amount
                                    is required</label>
                            </div>
                            
                            <div class="col-lg-3 col-md-3 col-sm-12" *ngIf="accountLevelPay"  [ngStyle]="service.get('serviceType').value === 'Co-Pay' ? {'visibility': 'visible'} : {'visibility': 'hidden'} ">
                                <div class="form-group">  
                                    <input type="date" id="payment-online-date"  [min]="maxStartDate" [max]="maxStartDate" name="serviceDate"
                                class="form-control input-text test-date" formControlName="serviceDate" [attr.disabled]="copaySelected ? 'true' : null" placeholder="Date" style="height: 36px !important;min-height: 36px !important;">
                                    <label class="required error"
                                        *ngIf="service?.get('serviceDate')?.errors?.required && service?.get('serviceDate')?.touched">
                                        Date is required</label>
                                </div>
                            </div>
                            <!-- <div class="col-lg-2 col-md-5 col-sm-12 selfpay">
                                <div class="checkbox checkbox-primary d-inline" *ngIf="!accountLevelPay">
                                    <input type="checkbox" formControlName="selfPay" name="selfPay" id="paymentRefactor_selfPayCheck">
                                </div>
                            </div> -->
                            <div class="col-lg-3 col-md-3 col-sm-12" *ngIf="pendinglevelcoPay"  [ngStyle]="service.get('serviceType').value === 'Co-Pay' ? {'visibility': 'visible'} : {'visibility': 'hidden'} ">
                                <div class="form-group">  
                                    <input type="date" id="payment-online-date" [min]="maxStartDate" [max]="maxStartDate"  name="serviceDate"
                                class="form-control input-text" formControlName="serviceDate" placeholder="Date" style="height: 36px !important;min-height: 36px !important;">
                                    <label class="required error"
                                        *ngIf="service?.get('serviceDate')?.errors?.required && service?.get('serviceDate')?.touched">
                                        Date is required</label>
                                </div>
                            </div>


                            <div class="col-lg-3 col-md-3 col-sm-12" *ngIf="!accountLevelPay && !pendinglevelcoPay"  [ngStyle]="service.get('serviceType').value === 'Co-Pay' ? {'visibility': 'visible'} : {'visibility': 'hidden'} ">
                                <div class="form-group" style="margin-top: 5px;">  
                                   <p> {{httpService?.formatDobTimeZoneWithDisplay(service.get('serviceDate').value)}} </p>
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-12 selfpay">
                                <div class="checkbox checkbox-primary d-inline user-avatar" *ngIf="!accountLevelPay">
                                    <input type="checkbox" formControlName="selfPay" name="selfPay" id="selfPayCheck">
                                    <div class="profile-tooltip">
                                        
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <i class="fa fa-exclamation-triangle exclamation-icon" aria-hidden="true"></i>
                                                </div>
                                                <div class="col-md-10" style="padding-left: 8px;">
                                                    <p>
                                                        If chosen, the charges will not be submitted with the claim and will instead be regarded as self-pay charges.
                                                    </p>
                                                </div>
                                            </div>
                                        
                                        <span class="material-symbols-outlined arrow">
                                          arrow_drop_up
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- && service?.get('serviceType')?.value !== 'Past Visit Charges' && !accountLevelPay -->
                            <div class="col-lg-1 col-md-1 col-sm-12 text-right"
                                *ngIf="service?.get('serviceType')?.value !== 'Credit' && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                                <button class="icon-queue-events delete-service  delete-action-bg" id="paymentRefactor_delete"
                                    (click)="deletePaymentServices(servI)" placement="bottom" ngbTooltip="Delete">
                                    <i class="lar la-trash-alt"
                                        style="font-size: 20px; color: black; margin-top: 10x; cursor: pointer;"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- AR Insurnace related Rows -->
            <ng-container *ngIf="switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                <div class="ar-insurnace-sec" [formGroup]="arInsuranceFormGroup">
                    <div class="fomr-array-sec" formArrayName="arInsuranceTypes">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <h6 class="mt-2"><b>Service Date</b></h6>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 ">
                                <h6 class="mt-2"><b>Claim Processor</b></h6>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 ">
                                <h6 class="mt-2"><b>Amount</b></h6>
                            </div>
                        </div>

                        <div class="row"
                            *ngFor="let arService of arInsuranceFormGroup?.get('arInsuranceTypes')?.controls; let arInsuI = index"
                            [formGroupName]="arInsuI">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                    <ng-select [addTag]="true" id="paymentRefactor_serviceDate" (change)="changeServiceDate(arService, arInsuI)"
                                        placeholder="Choose Service Date" bindLabel="appointmentTime"
                                        bindValue="appointmentId" [items]="allActiveEncounters" [clearable]="false"
                                        [hideSelected]="true" formControlName="appointmentId">
                                    </ng-select>
                                    <label class="required error"
                                        *ngIf="arService?.get('appointmentId')?.errors?.required && arService?.get('appointmentId')?.touched">Service
                                        Date is required</label>
                                </div>
                            </div>

                            <!-- Claim Processor Type -->
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                    <ng-select [addTag]="true" id="paymentRefactor_claimProcessor" placeholder="Choose Claim Processor"
                                        [items]="allClaimProcessorTypes" [clearable]="true"
                                        formControlName="claimProcessorType"></ng-select>
                                    <label class="required error"
                                        *ngIf="arService?.get('claimProcessorType')?.errors?.required && arService?.get('claimProcessorType')?.touched">Claim
                                        Processor is required</label>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <div class="input-group input-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input type="text" id="paymentRefactor_amount1" mask="separator.2" class="form-control amount-form-contrl" formControlName="amount"
                                        placeholder="0.00">
                                </div>
                                <label class="required error"
                                    *ngIf="arService?.get('amount')?.errors?.required && arService?.get('amount')?.touched">Amount
                                    is
                                    required</label>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-12 text-right">
                                <button class="icon-queue-events delete-action-bg" id="paymentRefactor_delete1" (click)="deleteArInsurance(arInsuI)"
                                    placement="bottom" ngbTooltip="Delete"><span
                                        class="material-symbols-outlined">delete</span></button>
                            </div>

                        </div>
                        <ng-container *ngIf="isSelectedServiceIsPresent">
                            <h4 class="required error">{{isSelectedServiceIsPresent}}</h4>
                        </ng-container>
                    </div>

                </div>
            </ng-container>

            <!-- Previous Visits Pending Patient Responsibility -->
            <ng-container
                *ngIf="patientResponsibilityResponse?.patientRespNCredits?.length > 0  && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value && totalPatientRespCharges() > 0">
                <hr>
                <h5>Previous Visits</h5>
                <div class="patient-resp-items" [formGroup]="patientResponsibilityForm">
                    <div class="patient-sub-reps-items" formArrayName="pastVisitPendingPayments">
                        <div class="row row-cols-4">
                            <!-- [class.isPartialPay]="partialPaymentsForm?.get('isPartialPayment')?.value" -->
                            <div class="col">
                                <h6 class="mt-2">
                                    <b>Service Date</b>
                                </h6>
                            </div>
                            <div class="col mt-2">
                                <h6 class="balance"><b>Balance</b></h6>
                            </div>
                            <div class="col mt-2">
                                <h6 class="ar-type"><b>AR</b></h6>
                            </div>
                            <div class="col mt-2">
                                <h6 class="claim-processor-type"><b>Claim Processor</b></h6>
                            </div>
                            <!-- <div class="col-lg-3 col-md-3 col-sm-12 mt-2" *ngIf="pentResp?.get('arType')?.value !== 'AR Insurance'">
                            <h6 class="claim-processor-type text-center"><b>Credits</b></h6>
                        </div> -->
                        </div>
                        <div class="row row-cols-4 mb-2"
                            *ngFor="let pentResp of patientResponsibilityForm?.get('pastVisitPendingPayments')?.controls; let in = index;"
                            [formGroupName]="in">
                            <ng-container *ngIf="pentResp?.get('paymentAmount')?.value > 0">
                                <div class="col" *ngIf="partialPaymentsForm?.get('isPartialPayment')?.value">
                                    <div class="form-group">
                                        <div class="checkbox checkbox-primary d-inline">
                                            <input type="checkbox" id="paymentRefactor_checkbox" formControlName="isPartialPaymentCheck">
                                            <label class="cr list-name-label ml-4" style="margin-left: 20px !important;"
                                                for="">{{pentResp?.get('serviceDate')?.value ?
                                                httpService?.formatDobTimeZoneWithDisplay(pentResp?.get('serviceDate')?.value)
                                                : '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col"
                                    *ngIf="!partialPaymentsForm?.get('isPartialPayment')?.value">
                                    <!-- <h6 class="mt-2">{{ pentResp?.get('serviceDate')?.value | date:'MM/dd/yyyy' }}
                                    </h6> -->
                                    <h6 class="mt-2">{{pentResp?.get('serviceDate')?.value ?
                                        httpService?.formatDobTimeZoneWithDisplay(pentResp?.get('serviceDate')?.value)
                                        : '--'}}</h6>
                                </div>
                                <div class="col">
                                    <div class="input-group" id="priorchanges">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input type="text" id="paymentRefactor_amount2" mask="separator.2" class="form-control amount-form-contrl" formControlName="paymentAmount"
                                            readonly placeholder="0.00">
                                    </div>
                                </div>
                                <!-- AR Type -->
                                <div class="col">
                                    <div class="form-group">
                                        <ng-select [addTag]="true" id="paymentRefactor_ar" placeholder="Choose AR" [items]="allArTypes"
                                            [readonly]="!switchArPatientInsurancePayForm?.get('switchCheck')?.value"
                                            [clearable]="true" formControlName="arType">
                                        </ng-select>
                                        <!-- <label class="required error" *ngIf="service?.get('serviceType')?.errors?.required && service?.get('serviceType')?.touched">Service Type is required</label> -->
                                    </div>
                                </div>
                                <!-- Claim Processor Type -->
                                <div class="col">
                                    <div class="form-group">
                                        <span class="mt-2">{{pentResp?.get('claimProcessorType')?.value || 'NA'}}</span>
                                        <!-- <ng-select [addTag]="true" placeholder="Choose Claim Processor"
                                            [items]="allClaimProcessorTypes" [clearable]="true"
                                            formControlName="claimProcessorType"></ng-select> -->
                                        <!-- <label class="required error" *ngIf="service?.get('serviceType')?.errors?.required && service?.get('serviceType')?.touched">Service Type is required</label> -->
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-md-3 col-sm-12"
                                    *ngIf="pentResp?.get('arType')?.value !== 'AR Insurance'">
                                    <div class="form-group text-center">
                                        <span class="">NA</span>
                                    </div>
                                </div> -->
                            </ng-container>
                        </div>
                        <div class="row" *ngIf="showArInsuranceMessage">
                            <div class="col-12">
                                <h6 class="required error">We can't submit AR Insurance with AR Patient. Please
                                    make payment of AR Insurance individually.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Credits Available  && disableCredit-->
            <ng-container
                *ngIf="totalCredits > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                <hr>
                <div class="row mb-2">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <h4 class="mt-2"><b>Credits Available</b></h4>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <div class="input-group" id="visitcharges">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <div class="payments-common-showdue">{{totalCredits | number : '1.2-2'}}</div>
                            <!-- <input type="number"  class="form-control" readonly value="{{totalCredits | number : '1.2-2'}}" placeholder="Credits"> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12"></div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="totalCharges() > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                <hr class="service-hr">
                <div class="row" [formGroup]="partialPaymentsForm">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group text-left list-group">
                            <div class="checkbox checkbox-primary d-inline">
                                <input type="checkbox" name="payment-check" (change)="makePartialPayment($event)"
                                    id="payment-check" formControlName="isPartialPayment">
                                <label for="payment-check" class="cr list-name-label ml-2">Partial
                                    Payment?</label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-2 col-md-2 col-sm-12"></div> -->
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <!-- <label for="priorchanges"></label> input-sm-text-1-->
                        <div class="input-group" id="priorchanges">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="text" id="paymentRefactor_amount3" mask="separator.2" class="form-control amount-form-contrl"
                                [readonly]="!partialPaymentsForm?.get('isPartialPayment')?.value"
                                formControlName="partialAmount" placeholder="0.00">
                        </div>
                        <div class="form-group error-message">
                            <label class="required error"
                                *ngIf="partialPaymentsForm?.get('partialAmount')?.errors?.required && partialPaymentsForm?.get('partialAmount')?.touched">Partial
                                Payment is required to make payment</label>
                            <label class="required error"
                                *ngIf="partialPaymentsForm?.get('isPartialPayment')?.value && partialPaymentsForm?.get('partialAmount')?.value > totalCharges()">Partial
                                Amount should not be greater than Total Charges</label>
                            <label class="required error"
                                *ngIf="!partialPaymentsForm?.get('partialAmount')?.errors?.required && totalCharges() > 0 && partialPaymentsForm?.get('isPartialPayment')?.value && (partialPaymentsForm?.get('partialAmount')?.value === 0 || partialPaymentsForm?.get('partialAmount')?.value < 1)">
                                Partial Amount should be minimum of $1 to make payment
                            </label>

                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12"></div>
                </div>
            </ng-container>

            <ng-container>
                <hr>
                <h4 class="summary">Summary</h4>

                <div class="row" *ngIf="!switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                    <div class="col-md-4">
                        <label class="text-label" *ngIf="pageName === 'Healthpass'">Plan Charges</label>
                        <label class="text-label" *ngIf="pageName !== 'Healthpass'">Current Visit
                            Charges</label>
                    </div>
                    <div class="col-md-8 text-right">
                        <label class="text-form-control">$ {{(getTotalVisitCharges() || 0) | number :
                            '1.2-2'}}</label>
                    </div>
                </div>

                <div class="row"
                    *ngIf="totalPatientRespCharges() > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                    <div class="col-md-4">
                        <label class="text-label">Past Visit Charges</label>
                    </div>
                    <div class="col-md-8 text-right">
                        <label class="text-form-control">$ {{(totalPatientRespCharges() || 0) | number :
                            '1.2-2'}}</label>
                    </div>
                </div>
                <!--  && disableCredit -->
                <div class="row"
                    *ngIf="totalCredits > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                    <div class="col-md-4">
                        <label class="text-label">Credits Applied</label>
                    </div>
                    <div class="col-md-8 text-right">
                        <label *ngIf="(totalPatientRespCharges() + totalChargesCount()) > 0" class="text-form-control">$
                            -{{ totalCredits | number : '1.2-2'}}</label>
                        <label *ngIf="(totalPatientRespCharges() + totalChargesCount()) <= 0"
                            class="text-form-control">$ {{0 | number : '1.2-2'}}</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <label class="text-label">Total Charges</label>
                    </div>
                    <div class="col-md-8 text-right">
                        <label class="text-form-control">$ {{totalCharges() | number :
                            '1.2-2'}}</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <label class="amount-payable text-label">Payment Amount</label>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 text-right"
                        *ngIf="!switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                        <label class="text-form-control"
                            *ngIf="grandTotalCharges() < 0 || grandTotalCharges() === 0"><b>$ {{0 | number
                                : '1.2-2'}}</b></label>
                        <label class="text-form-control"
                            *ngIf="grandTotalCharges() > 0 && grandTotalCharges() !== 0"><b>$
                                {{(grandTotalCharges() || 0) | number : '1.2-2'}}</b></label>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 text-right"
                        *ngIf="switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                        <label class="text-form-control"
                            *ngIf="grandTotalCharges() < 0 || grandTotalCharges() === 0"><b>$ {{0 | number
                                : '1.2-2'}}</b></label>
                        <label class="text-form-control"
                            *ngIf="grandTotalCharges() > 0 && grandTotalCharges() !== 0"><b>$
                                {{(grandTotalCharges() || 0) | number : '1.2-2'}}</b></label>
                    </div>

                </div>
                <!-- && disableCredit -->
                <div class="row"
                    *ngIf="(grandTotalCharges() < 0 || grandTotalCharges() === 0) && totalCredits > 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <label class="error-msg error">Credits Balance</label>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 text-right">
                        <label class="text-form-control">$ {{((grandTotalCharges() || 0) * -1) | number :
                            '1.2-2'}}</label>
                    </div>
                </div>

                <div class="row"
                    *ngIf="partialPaymentsForm?.get('isPartialPayment')?.value && partialPaymentsForm?.get('partialAmount')?.value > 0 && totalBalanceDue() >= 0 && pageName !== 'Healthpass' && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <label class="error-msg error text-label amount-payable">Remaining Balance</label>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 text-right">
                        <label class="text-form-control error">$ {{totalBalanceDue() | number : '1.2-2'}}</label>
                    </div>
                </div>
                <ng-container *ngIf="totalChargesPayingCheck && pageName !== 'Healthpass'">
                    <h5 class="error">{{totalChargesPayingCheck}}</h5>
                </ng-container>
            </ng-container>
            <hr>

            <!-- Payment Category and Description -->
            <!-- <div class="row" [formGroup]="paymentCategoryDescription">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <h6><b>Category</b></h6>
                    <div class="form-group" style="width: 266px;">
                        <ng-select formControlName="category" placeholder="Choose Category"
                            [items]="paymentCategories"></ng-select>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <h6><b>Description</b></h6>
                    <div class="form-group" style="width: 266px;">
                        <ng-select formControlName="description" placeholder="Choose Description"
                            [items]="paymentDescriptions"></ng-select>
                    </div>
                </div>
            </div> -->
            <!-- *ngIf="grandTotalCharges() > 0" -->
            <div class="row mt-2" *ngIf="switchArPatientInsurancePayForm?.get('switchCheck')?.value ? true : (grandTotalCharges() > 0 || totalCredits === 0)">
                <div class="col-lg-4 col-md-4 col-sm-12" [formGroup]="paymentTypeSelectionForm">
                    <!-- <h5 class="payment-type">Payment Type:</h5> -->
                    <h6 class="mt-2"><b>Payment Type</b></h6>
                    <div class="form-group" style="width: 266px;">
                        <ng-select id="paymentTypeSel" (change)="changePayType($event)" formControlName="paymentType"
                            placeholder="Choose Payment Type" [items]="paymentType"></ng-select>
                        <label class="required error"
                            *ngIf="paymentTypeSelectionForm?.get('paymentType')?.errors?.required && paymentTypeSelectionForm?.get('paymentType')?.touched">Payment
                            Type is required</label>
                    </div>
                    <!-- this is only for AR Insurane Payments Types  *ngIf="!switchArPatientInsurancePayForm?.get('switchCheck')?.value"-->
                    <!-- <div class="form-group" *ngIf="switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                        <ng-select (change)="changePayType($event)" formControlName="paymentType"
                            placeholder="Choose Payment Type" [items]="arInsurancePaymentType"></ng-select>
                        <label class="required error"
                            *ngIf="paymentTypeSelectionForm?.get('paymentType')?.errors?.required && paymentTypeSelectionForm?.get('paymentType')?.touched">Payment
                            Type is required</label>
                    </div> -->
                </div>
                <!-- switchArPatientInsurancePayForm?.get('switchCheck')?.value && -->
                <!-- Online mode Payment date capture -->
                <div class="col-lg-7 col-md-7 col-sm-12" *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit'">
                    <div class="col-6" [formGroup]="paymentTypeSelectionForm">
                        <div class="form-group">
                            <h6 class="mt-2"><b>Payment Date</b></h6>
                            <input type="date" id="paymentRefactor_paymentDate" [max]="maxStartDate" name="onlineDate"
                                class="form-control input-text" formControlName="onlineDate" placeholder="Payment Date">
                            <label class="required error"
                                *ngIf="paymentTypeSelectionForm?.get('onlineDate')?.errors?.required && paymentTypeSelectionForm?.get('onlineDate')?.touched">Payment
                                Date is required</label>
                            <label class="required error"
                                *ngIf="paymentTypeSelectionForm?.get('onlineDate')?.errors?.invalidDate && paymentTypeSelectionForm?.get('onlineDate')?.touched && !paymentTypeSelectionForm?.get('onlineDate')?.errors?.required">Invalid
                                Payment Date</label>
                        </div>
                    </div>
                </div>
                <!-- CHECK Mode payment related check details -->
                <div class="col-lg-7 col-md-7 col-sm-12" *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Check'">
                    <div class="row" [formGroup]="checkNumberDateForm">
                        <div class="col-6">
                            <div class="form-group">
                                <h6 class="mt-2"><b>Check Number</b></h6>
                                <input class="form-control input-text" type="text" placeholder="Check Number" id="paymentRefactor_checkNumber"
                                    formControlName="checkNumber" />
                                <label class="required error"
                                    *ngIf="checkNumberDateForm?.get('checkNumber')?.errors?.required && checkNumberDateForm?.get('checkNumber')?.touched">Check
                                    Number is Required</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <h6 class="mt-2"><b>Check Date</b></h6>
                                <input type="date" [max]="maxStartDate" name="checkDate" class="form-control input-text"
                                    id="paymentRefactor_checkDate" formControlName="checkDate" placeholder="Check Date">
                                <label class="required error"
                                    *ngIf="checkNumberDateForm?.get('checkDate')?.errors?.required && checkNumberDateForm?.get('checkDate')?.touched">Check
                                    Date is required</label>
                                <label class="required error"
                                    *ngIf="checkNumberDateForm?.get('checkDate')?.errors?.invalidDate && checkNumberDateForm?.get('checkDate')?.touched && !checkNumberDateForm?.get('checkDate')?.errors?.required">Invalid Check Date</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-12"
                    *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS' && terminalTypes?.length > 1"
                    [formGroup]="paymentTypeSelectionForm">
                    <!-- <h5 class="mt-2">Terminal:</h5> -->
                    <h6 class="mt-2"><b>Terminal</b></h6>
                    <div class="form-group">
                        <ng-select formControlName="terminalType" id="paymentRefactor_terminal" placeholder="Choose Terminal" bindLabel="name"
                            [items]="terminalTypes"></ng-select>
                        <label class="required error"
                            *ngIf="paymentTypeSelectionForm?.get('terminalType')?.errors?.required && paymentTypeSelectionForm?.get('terminalType')?.touched">Terminal
                            is required</label>
                    </div>
                </div>
                <div
                    [ngClass]="(paymentTypeSelectionForm?.get('paymentType')?.value === 'POS' && terminalTypes?.length > 1) || (paymentTypeSelectionForm?.get('paymentType')?.value === 'Check' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit') ? 'col-lg-1 col-md-1 col-sm-12' : 'col-lg-8 col-md-8 col-sm-12'">
                    <button class="icon-queue-events float-right" id="paymentRefactor_notifyAccBal"
                        style="background-color: #5ca6db;color: #fff;margin-right: 0px;margin-top: 30px;" placement="left"
                        ngbTooltip="Notify - Account Balance" (click)="sendAccountBalanceNotify(accountBalanceNotifyConfirm, 'validate')"><i
                            class="lab la-telegram"></i></button>
                </div>
            </div>

            <!-- POS Terminal Payment -->
            <ng-container *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS' && (switchArPatientInsurancePayForm?.get('switchCheck')?.value ? true : (grandTotalCharges() > 0 || totalCredits === 0))">
                <div class="accordion" id="paymentAccordion">

                    <div class="card">
                        <div class="card-header" id="posTerminalPayment">
                            <h2 class="text-left collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                <span class="pull-left">
                                    <form>
                                        <div class="custom-control">
                                            <label class="payment-label-name">POS Payment</label>
                                        </div>
                                    </form>
                                </span>
                                <span class="pull-left">
                                    Click on Submit Payment to open payment in Terminal Device.
                                </span>
                                <span class="pull-right text-right">
                                    <img src="../../../../../assets/images/pay/pos.png"
                                        style="height: 50px; width: 50px;">
                                </span>
                            </h2>
                        </div>

                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Non POS'">
                <div id="paymentAccordion">
                    <!-- Credit Card Payment collapsed-->
                    <div class="card card-non-pos">
                        <div class="card-header" id="headingThree"> 
                            <h2 class="text-left" type="button" (click)="cardPayment()" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="pull-left">
                                    <form>
                                        <div class="custom-control">
                                            <!-- <input type="radio" class="custom-control-input" id="creditcardadio"
                                                name="nonPosPaymentsRadio" value="creditcardadio"> -->
                                            <label class="payment-label-name">Pay with Credit/Debit
                                                Card</label>
                                        </div>
                                    </form>
                                </span>
                                <span class="pull-left dnone">
                                    will be redirected to the ACH Payments website
                                    after submitting your order
                                </span>
                                <span class="pull-right text-right">
                                    <img src="../../../../../assets/images/pay/visa2.png">
                                    <img src="../../../../../assets/images/pay/discover2.png">
                                    <img src="../../../../../assets/images/pay/american-express.png">
                                    <img src="../../../../../assets/images/pay/mastercard2.png">

                                </span>
                            </h2>
                        </div>

                        <!-- <div class="card-body" style="padding-top: 0px; padding-bottom: 0px;">
                            <ul class="info-details2">

                                <li class="box">
                                    <p class="visit-title">Card Number</p>
                                    <span>
                                        1234 5678 9101 3456
                                    </span>
                                    <span class="icon-check2">
                                        <i class="las la-check-circle"></i>
                                    </span>

                                </li>
                                <li class="box">
                                    <p class="visit-title">Expiration Date</p>
                                    <input type="text" placeholder="MM/YY" class="form-control">

                                </li>

                                <li class="box">
                                    <p class="visit-title">Card Security Code</p>
                                    <span>***</span>
                                </li>
                                <span class="box-que">
                                    What is this?
                                </span>
                            </ul>
                        </div> -->

                        <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
                            data-parent="#paymentAccordion">
                            <div class="card-body">
                                <form id="payment-form">
                                    <div id="card-container"></div>
                                    <div class="credit-card-btns">
                                        <div class="form-group" style=" text-align: center;">
                                            <button *ngIf="!enableNonPosCancelPayment" id="card-button"
                                                class="btn btn-primary dash-actions" type="button"><i
                                                    class="las la-check-double"></i>&nbsp; Submit Payment</button>
                                            <button *ngIf="enableNonPosCancelPayment" id="paymentRefactor_cancelPay"
                                                class="btn btn-primary dash-actions" type="button"
                                                (click)="cancelPayment()">Cancel Payment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- Digital Wallets -->
                    <div class="card">
                        <div class="card-header" id="paycreditcard1">
                            <h2 class="text-left collapsed" type="button" (click)="applePayOpen();googlePayOpen()"
                                data-toggle="collapse" data-target="#paycreditcard" aria-expanded="false"
                                aria-controls="collapseThree">
                                <span class="pull-left">
                                    <form>
                                        <div class="custom-control">
                                            <!-- <input type="radio" class="custom-control-input" id="digitalwallet" name="nonPosPaymentsRadio"
                                                value="digitalwallet"> -->
                                            <label class="payment-label-name">Digital
                                                Wallets</label>
                                        </div>
                                    </form>
                                </span>
                                <span class="pull-left">
                                    You will be redirected to the Google pay
                                    & Apple Pay after submitting your order
                                </span>
                                <span class="pull-right text-right">

                                    <img src="../../../../../assets/images/pay/apple-pay.png">
                                    <img src="../../../../../assets/images/pay/google-pay.png">

                                </span>

                            </h2>
                        </div>
                        <div id="paycreditcard" class="collapse" aria-labelledby="paycreditcard1"
                            data-parent="#paymentAccordion">
                            <div class="card-body">
                                <div class="row" style="text-align: center;">
                                    <form style="width: 45%;">
                                        <button id="apple-pay-button" (click)="applePayOpen();"
                                            class="btn btn-primary dash-actions">Apple
                                            Pay</button>
                                    </form>
                                    <br />
                                    <form style="width: 45%;">
                                        <button (click)="googlePayOpen()" id="paymentRefactor_googlePay"
                                            class="btn btn-primary dash-actions mb-3">Google Pay</button>
                                        <br />
                                        <div id="google-pay-button"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Check' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit' ">
                <div id="paymentAccordion">

                    <div class="card">
                        <div class="card-header" id="cashChecqueOtherPayments">
                            <h2 class="text-left collapsed" data-toggle="collapse" data-target="#cashChequePayments"
                                aria-expanded="false" aria-controls="cashChequePayments">
                                <span class="pull-left">
                                    <form>
                                        <div class="custom-control">
                                            <!-- <input type="radio" class="custom-control-input" id="achradio" name="nonPosPaymentsRadio"
                                                value="customEx"> -->
                                            <label
                                                class="payment-label-name">{{paymentTypeSelectionForm?.get('paymentType')?.value}}</label>
                                        </div>
                                    </form>
                                </span>
                                <span class="pull-left">
                                    <!-- Click on Make Payment to open payment in Terminal Device. -->
                                </span>
                                <span class="pull-right text-right">
                                    <!-- <img src="../../../../../assets/images/pay/Ach.png"> -->
                                </span>
                            </h2>
                        </div>

                    </div>
                </div>

            </ng-container>
        </form>
 
    </div>

    <div class="app-modal-footer">
        <!-- Submit Payment Actions -->
        <div class="row mt-4">
            <div class="col-12">
                <div class="credit-card-btns text-center mt-4 mb-4">
                    <!-- If not selected any payment type -->
                    <ng-container *ngIf="!paymentTypeSelectionForm?.get('paymentType')?.value && (grandTotalCharges() > 0 || totalCredits === 0)">
                        <button type="button" id="paymentRefactor_submitPay0" disabled class="btn btn-primary dash-actions no-submit"><i class="las la-check-double"></i>&nbsp;Submit Payment</button>
                    </ng-container>
                    <ng-container *ngIf="grandTotalCharges() > 0 || totalCredits === 0">
                        <ng-container *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS'">
                            <!-- <button *ngIf="showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button" (click)="cancelPosPayment()">Cancel Payment</button> -->
                            <button type="button" id="paymentRefactor_submitPay1" (click)="createPosPayment()" class="btn btn-primary dash-actions pos-submit"><i
                                    class="las la-check-double"></i>&nbsp;
                                Submit Payment</button>
                        </ng-container>
                        <ng-container
                            *ngIf="(paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Check' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit') && !switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                            <button type="button" id="paymentRefactor_submitPay2" class="btn btn-primary dash-actions cash-submit" (click)="createCashPayment()"><i
                                    class="las la-check-double"></i>&nbsp; Submit
                                Payment</button>
                        </ng-container>
                    </ng-container>
                    <ng-container
                    *ngIf="(paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Check' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit') && switchArPatientInsurancePayForm?.get('switchCheck')?.value">
                    <button type="button" id="paymentRefactor_submitPay3" class="btn btn-primary dash-actions cash-ar-submit"
                        (click)="createArInsurancePayment()"><i class="las la-check-double"></i>&nbsp;
                        Submit Payment</button>
                    </ng-container>
                    <ng-container *ngIf="(grandTotalCharges() < 0 || grandTotalCharges() === 0) && totalCredits > 0">
                        <button type="button" id="paymentRefactor_makeCredPay" class="btn btn-primary dash-actions" (click)="createCreditPayment()"><i
                                class="las la-check-double"></i>&nbsp;Make
                            Credits Payment</button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="app-modal-footer">
        <div class="credit-card-btns">
            <div class="form-group" style=" text-align: center;">
                <button *ngIf="!enableNonPosCancelPayment" id="card-button" class="btn btn-primary dash-actions"
                    type="button"><i class="las la-check-double"></i>&nbsp; Submit Payment</button>
                <button *ngIf="enableNonPosCancelPayment" id="terminal-pay" class="btn btn-primary dash-actions"
                    type="button" (click)="cancelPayment()">Cancel Payment</button>
            </div>
        </div>
    </div> -->
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Success -->
<app-ui-modal #paySuccessModal [hideHeader]="true" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="paymentSuccessDailogue">
    <div class="app-modal-body">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="paySuccessModal?.hide();clearDupVarsForReceipt(); clearSession(); loadPaymentInfo(); futureBalnceUpdate();"><span
                aria-hidden="true">&times;</span></button>

        <div class="success-container">
            <div class="row">
                <div class="col-md-12 text-success">
                    <i class="fa fa-check-circle" style="font-size: 50px;"></i>
                </div>
                <div class="col-md-12">
                    <h4>Payment Successful!</h4>
                </div>
                <div class="col-md-12">
                    <p>Transaction Number: {{successPayDetails?.paymentId}}</p>
                </div>
                <hr>
                <div class="col-md-12">
                    <p>
                        <span class="float-left">Amount Paid: </span>
                        <span class="float-right">$ {{successPayDetails?.amount | number : '1.2-2'}}</span>
                    </p>
                    <div class="link-text" id="paymentRefactor_ViewPrintPayReceipt0"
                        *ngIf="successPayDetails && successPayDetails?.paymentId && pageName !== 'Healthpass'"
                        (click)="viewPaymentReciept(successPayDetails?.paymentId, commonPaymentInvoiceModal); futureBalnceUpdate();">
                        View/Print
                        Payment Receipt</div>

                    <div class="link-text" *ngIf="pageName === 'Healthpass'">
                        <div class="link-text-sub" id="paymentRefactor_ViewPrintPayReceipt1"
                            (click)="viewPaymentReciept(successPayDetails?.paymentId, commonPaymentInvoiceModal)">
                            View/Print Payment Receipt</div><br>
                        <div class="link-text-sub" id="paymentRefactor_ViewPrintPlanSummary"
                            (click)="generateHealthpassPlanReceipt(planInvoiceModal); loadSpinner = false">View/Print
                            Plan Summary</div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" id="paymentRefactor_close0" class="btn btn-primary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="paySuccessModal.hide();clearDupVarsForReceipt(); clearSession(); loadPaymentInfo(); futureBalnceUpdate(); loadSpinner = false">Close</button>
    </div>
</app-ui-modal>

<!-- Payment Invoice -->
<app-ui-modal #commonPaymentInvoiceModal [hideHeader]="false" [modalCentered]="true" id="commonPaymentInvoiceModal">

    <div class="app-modal-header">
        <h5 class="modal-title">Payment Receipt</h5>

        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="closeModal();clearDupVarsForReceipt();commonPaymentInvoiceModal?.hide();loadPaymentInfo(); loadSpinner = false "><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="payment-reciept">
            <iframe id="{{recieptID || 'common-new-payment-reciept-embed'}}" frameBorder="0" width="100%"
                height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" id="paymentRefactor_ok"
            (click)="closeModal();clearDupVarsForReceipt();commonPaymentInvoiceModal?.hide();loadPaymentInfo(); loadSpinner = false ">Ok</button>
        <button *ngIf="pageName === 'Healthpass'" type="button" class="btn btn-primary" id="paymentRefactor_close1" data-dismiss="modal"
            (click)="closeModal();clearDupVarsForReceipt();commonPaymentInvoiceModal?.hide(); loadSpinner = false;loadPaymentInfo();">Close</button>
    </div>
</app-ui-modal>


<!-- Payment Invoice -->
<app-ui-modal #planInvoiceModal [hideHeader]="false" [modalCentered]="true" id="planInvoiceModal">

    <div class="app-modal-header">

        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="planInvoiceModal?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <h5 class="modal-title">Plan Summary</h5>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="payment-reciept">

            <iframe id="healthpass-receipt" frameBorder="0" width="100%" height="500px"></iframe>

        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" id="paymentRefactor_close2" class="btn btn-primary" data-dismiss="modal"
            (click)="planInvoiceModal?.hide();">Close</button>
    </div>
</app-ui-modal>
<div class="custom-loader justify-content-center" *ngIf="loadSpinner">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<!-- Late Notify PopUp -->
<app-send-sms-notify [pageType]="'Claim AR'"></app-send-sms-notify>

<!-- confirm the delete Patient Additional  Document -->
<app-ui-modal #accountBalanceNotifyConfirm [hideHeader]="false" [modalCentered]="true" id="accountBalanceNotifyConfirm">
    <div class="app-modal-header">
        <div class="row d-flex-ver-center">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 30px; margin: 0px;">
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left;">No patient dues, do you still want to send
                    notification?</h5>
            </div>
        </div>
        <!-- <h5 class="modal-title text-center">No patient dues, do you still want to send notification?</h5> -->
        <!-- <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="accountBalanceNotifyConfirm?.hide();"><span aria-hidden="true">&times;</span></button> -->
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" id="paymentRefactor_no"
            style="margin-right: 15px;" data-dismiss="modal" (click)="accountBalanceNotifyConfirm.hide()">No</button>
        <button type="button" class="btn btn-primary dash-actions" id="paymentRefactor_yes"
            style="margin-right: 15px;" data-dismiss="modal"
            (click)="sendAccountBalanceNotify(accountBalanceNotifyConfirm)">Yes</button>
    </div>
</app-ui-modal>