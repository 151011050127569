<div class="claim-status">
           <app-card #claimsStatusGrid [hidHeader]="true" cardClass="card-datatable" class="new-designed-card">
        <div class="table-responsive new-designed-table">
            <table datatable [dtOptions]="dtOptionsPendingSignoffAppnts"  id="report-table"
                class="table table-bordered table-striped mb-0">
                
                <thead>
                    <tr>
                        <th>Patient Name</th>
                        <th>Service Date</th>
                        <th>Service Provider</th>
                        <!-- <th>Payer Name</th> -->
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let claim of allClaims">
                        <tr class="appointment-tr-queue">
                            <td>{{claim?.patientLastName}}, {{claim?.patientFirstName }}</td>
                            <td>{{getAppointmentTime(claim?.createdDate || '--')}}</td>
                            <td>{{claim?.createdBy || '--'}}</td>
                            <!-- <td>{{claim?.payerName || '--'}}</td> -->
                            <td class="text-center">

                                <div class="dropdown-actions">
                                    <button class="icon-queue-events more-actions-hover-icon"><i
                                            class="las la-ellipsis-h"></i></button>
                                    <div class="dropdown-content-actions">

                                        <a href="javascript:"
                                            (click)="viewAppointmentDetails(claim?.appointmentId, viewCommonAppointmentDetails)"><span
                                                class="material-symbols-outlined">visibility</span>View Appointment
                                            Details</a>

                                        <a href="javascript:" (click)="awaitingSignOffNavigate(claim)"><span
                                                class="material-symbols-outlined">logout</span>Pending SignOff</a>

                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </app-card>
        </div>



<!-- Spinner -->
<div class="custom-loader justify-content-center" *ngIf="loadSpinner">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- View Appointment Details -->
<app-ui-modal #viewCommonAppointmentDetails [hideHeader]="false" [modalCentered]="true"
  id="viewCommonAppointmentDetails">
  <div class="app-modal-header">
    <h5 class="modal-title">Appointment Details</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="viewCommonAppointmentDetails.hide();selectedViewAppointmentId=null"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body add-app-body">
    <ng-container *ngIf="selectedViewAppointmentId">
        <app-commom-past-visit-details-wizard [appointmentId]="selectedViewAppointmentId" [noAccountBalanace]="true" (openNotifyModal)="sendNotification($event)"></app-commom-past-visit-details-wizard>
      <!-- <app-view-common-appointment-details [appointmentId]="selectedViewAppointmentId">
      </app-view-common-appointment-details> -->
    </ng-container>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
      (click)="viewCommonAppointmentDetails.hide();selectedViewAppointmentId=null">Ok</button>
  </div>
</app-ui-modal>


<!-- create task -->
<app-new-task [idType]="'userId'" [pageId]="'tasks'"></app-new-task>

<!-- Late Notify PopUp -->
<app-send-sms-notify [pageType]="'Chart'"></app-send-sms-notify>