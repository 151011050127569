import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { SendSmsNotifyComponent } from 'src/app/theme/shared/hop-common-components/send-sms-notify/send-sms-notify.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { CardComponent } from '../../components/card/card.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';


@Component({
  selector: 'app-upload-additional-documents',
  templateUrl: './upload-additional-documents.component.html',
  styleUrls: ['./upload-additional-documents.component.scss']
})
export class UploadAdditionalDocumentsComponent implements OnInit, AfterViewInit {

  public allAppntHistory = [];
  public loginDetails: any;
  public waitTimeDetails: any;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild('fileUpload') public fileUpload: ElementRef;
  @ViewChild('updateUserInfoData') public updateUserdataCard?: CardComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public loadSpinner: boolean;
  public selectedAppointmentUser: any;
  @ViewChild('files') public files: ElementRef;
  public documentForm: FormGroup;
  @ViewChild('uploadDocuments') uploadDocumentsModal: UiModalComponent;
  public allDocumentTypes = ["Lab Results", "Radiology Results", "Referral", "Wellness Forms", "Other"];
  @Output() notifyDocUpload = new EventEmitter<any>();
  @Output() DocIframeUpdate = new EventEmitter<any>();

  @ViewChild('documentUploadNotify') documentUploadNotify: UiModalComponent;
  public addCollectionTypes = [
    { value: 'Encounter', desc: 'Encounter' },
    { value: 'Account', desc: 'Account' },
    { value: 'Facility', desc: 'Facility' }
  ];
  public addDocumentTypes = [];
  public uploadAdditioinalDocsForm: FormGroup;
  public isReadonly: boolean = false;
  @Input() collection: any;
  @Input() documentSelected: any;

  @Input() hideInsurance: boolean;
  @Input() userInfoDetailsForm: FormGroup;
  @Input() uploadIdForm: FormGroup;
  @Input() patientDetailsInfo: any;
  @Input() userdataupdate: boolean;
  @Input() PatientDetailsCardReload: CardComponent;
  public drivingLicenseFile: string;
  public insuranceCardFile: string;
  public getSelectedInsuValue: any;
  @Input() insuranceDetails: FormGroup;
  public documentdetails = [];
  public insuranceCardDetails: FormArray;
  public UploadedFiletype: any;
  public documentsList: any;
  public isFileUploaded: boolean = false;
  public uploadedFileInformation: any;
  @Output() onDocumentUploadChange = new EventEmitter<boolean>();
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  public lateNotifyPatient: any;
  @Output() docUplaod = new EventEmitter<any>();
  @Output() docUplaodClose = new EventEmitter<any>();
  @Output() invoiceUsersDocs = new EventEmitter<any>();
  isDocsUploadUser: boolean;

  constructor(private httpService: HttpService, private router: Router, private formBuilder: FormBuilder, private activateRoute: ActivatedRoute) {
    this.documentForm = formBuilder?.group({
      'documentType': [null, Validators?.required],
      'documentName': [null, Validators?.required],
      'multipartFile': [null, Validators?.required],
    });

    this.uploadAdditioinalDocsForm = this.formBuilder?.group({
      'documentType': [null, Validators?.required],
      'documentName': [null, Validators?.required],
      'multipartFile': [null, Validators?.required],
      'collectionType': [null],
    });

  }

  ngOnInit(): void {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.uploadAdditioinalDocsForm?.get('collectionType')?.setValue(this.collection);
    if (this.documentSelected !== '') {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue(this.documentSelected);
      this.isReadonly = true;
    }
    //this.getDocumenttypeList();
    //this.myFormValues();

  }

  ngAfterViewInit(): void {
    //this.resetDocumentsPopup();
  }

  /*  private myFormValues() {
    this.uploadAdditioinalDocsForm = this.formBuilder?.group({
        'documentType': [null, Validators?.required],
        'documentName': [null, Validators?.required],
        'multipartFile': [null, Validators?.required],
        'collectionType': [null],
      });
  }*/

  // Invoice level documents open
  public invoiceDocumentsOpen(user: any, collection: any, documentSelected: any): void {
    this.isDocsUploadUser = true;
    this.openUploadDocument(user, collection, documentSelected);
  }

  public openUploadDocument(user: any, collection: any, documentSelected: any): void {
    this.resetDocumentsPopup();
    this.collection = collection;
    this.documentSelected = documentSelected;
    this.uploadAdditioinalDocsForm?.get('collectionType')?.setValue(this.collection);

    if (this.documentSelected !== '') {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue(this.documentSelected);
      this.isReadonly = true;
    }
    else {
      this.uploadAdditioinalDocsForm?.get('documentType')?.setValue(null);
      this.isReadonly = false;
    }

    this.selectedAppointmentUser = user;
    this.getDocumenttypeList(this.isDocsUploadUser);
    this.uploadDocumentsModal?.show();
  }

  public openNotificationJournalNotesAdd(): void {
    const uploadObj = {
      id: this.collection === 'Encounter' ? this.selectedAppointmentUser?.id : null,
      documentType: this.uploadAdditioinalDocsForm?.value?.documentType
    }
    //console.log(uploadObj);
    this.notifyDocUpload?.emit(uploadObj);
    this.resetDocumentsPopup();
  }

  public resetDocumentsPopup() {
    this.uploadAdditioinalDocsForm?.reset();
    this.uploadAdditioinalDocsForm?.get('multipartFile')?.setValue(null);
    this.fileUpload.nativeElement.value = null;
    this.documentsList = null;
  }

  public getDocumenttypeList(isDocsUploadUser?: any) {
    // const collectionType = event.value;
    const collectionType = this.uploadAdditioinalDocsForm.get('collectionType').value;
    const action = `record/listDocumentTypes?collectionType=${collectionType}`;
    this.addDocumentTypes = [];
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.addDocumentTypes = data?.responseObject;
        if(isDocsUploadUser) {
          this.addDocumentTypes = this.addDocumentTypes?.filter((docItem: any) => docItem === 'Clinical Documents' || docItem === 'Referral' || docItem === 'Other' || docItem === 'Invoice') || [];
          return;
        }
        if (collectionType == 'Account' && this.documentSelected == '') {
          this.addDocumentTypes?.forEach((formList: any, index: number) => {

            /* if (formList?.documentType == 'Identification Record') {
               this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem?.documentType !== 'Identification Record') || [];
             }
             else if (formList?.documentType == 'Image') {
               this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem?.documentType !== 'Image') || [];
             }
             else if (formList?.documentType == 'Insurance') {
               this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem?.documentType !== 'Insurance') || [];
             }
             else if (formList?.documentType == 'Vaccination Record') {
               this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem?.documentType !== 'Vaccination Record') || [];
             }*/


            if (formList == 'Image') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Image') || [];
            }
            else if (formList == 'Identification Record') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Identification Record') || [];
            }

            else if (formList == 'Insurance') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Insurance') || [];
            }
            else if (formList == 'Vaccination Record') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Vaccination Record') || [];
            }


          });
        }
        else if (collectionType == 'Encounter' && this.documentSelected == '') {
          this.addDocumentTypes?.forEach((formList: any, index: number) => {

            if (formList == 'Claim Journal') {
              this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Claim Journal') || [];
            }


          });
        }

      }

    },
      error => {
        this.notifyText = error?.message || error?.msg || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.loadSpinner = false;
        this.exceptionModal?.modalShow();
      })
  }

  public checkDocumentType(): void {
    if (this.uploadAdditioinalDocsForm?.get('documentType')?.invalid) {
      this.uploadAdditioinalDocsForm?.markAllAsTouched();
      this.notifyText = 'Document Type is required to upload document';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.fileUpload.nativeElement.value = "";
      return;
    }
  }

  // Delete  Uploaded File
  public deleteSelectedFile(index: number): void {
    this.documentsList = null;
    this.clearFileUpload();
  }

  // on upload get the file
  public uploadFileSelect(event: any): void {
    this.documentsList = null;
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      const fileSize = files.size / 1024 / 1024;
      this.documentsList = files;
      if (fileSize <= 25 && (files.type === 'application/pdf' || files.type === 'image/png' || files.type === 'image/jpeg')) {
        this.uploadAdditioinalDocsForm?.get('documentName')?.setValue(files.name);
        this.uploadAdditioinalDocsForm?.get('multipartFile')?.setValue(files);
        this.UploadedFiletype = files.type;
      }
      else {
        this.uploadAdditioinalDocsForm?.get('multipartFile')?.setValue(null);
        this.fileUpload.nativeElement.value = null;
        this.notifyText = "Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded";
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    }
    this.clearFileUpload();
  }

  // Clear File Upload
  public clearFileUpload(): void {
    this.fileUpload.nativeElement.value = null;
  }

  public getCurrentTimeStamp() {
    const current = new Date();
    current.setHours(0)
    current.setMinutes(0)
    current.setSeconds(0)
    current.setMilliseconds(0)
    const timestamp = current.getTime();
    return timestamp;
  }

  public uploadAdditionalDocuments(documentUpload?: UiModalComponent): void {
    if (this.uploadAdditioinalDocsForm?.invalid) {
      this.uploadAdditioinalDocsForm?.markAllAsTouched();
      return;
    }
    const formData = new FormData();


    const timestamp = this.getCurrentTimeStamp();
    const documentType = this.uploadAdditioinalDocsForm?.get('documentType')?.value;
    const nameValue = this.uploadAdditioinalDocsForm?.get('documentName')?.value;

    const isFile = true;
    const documentName = documentType + "_" + timestamp + "_" + nameValue;
    this.uploadAdditioinalDocsForm?.get('documentName')?.setValue(documentName);
    let actionAppend = '';
    // formData.append('idFile', this.uploadAdditioinalDocsForm?.value?.multipartFile);
    if (this.collection == 'Encounter') {
      if (documentType == 'Consent Form') {
        formData.append('file', this.uploadAdditioinalDocsForm?.value?.multipartFile);
        actionAppend = `SignForm/uploadSignedConsentForm?appointmentId=${this.selectedAppointmentUser?.id}&documentName=${documentName}`;
      }
      else {
        formData.append('idFile', this.uploadAdditioinalDocsForm?.value?.multipartFile);
        actionAppend = `record/addDocument?appointmentId=${this.selectedAppointmentUser?.id}&documentName=${documentName}&documentType=${this.uploadAdditioinalDocsForm?.value?.documentType}&isFile=${isFile}&fileType=${this.UploadedFiletype}`;

      }
    }
    else if (this.collection == 'Account') {
      formData.append('idFile', this.uploadAdditioinalDocsForm?.value?.multipartFile);
      actionAppend = `record/addDocument?userInfoId=${this.selectedAppointmentUser?.id}&documentName=${documentName}&documentType=${this.uploadAdditioinalDocsForm?.value?.documentType}&isFile=${isFile}&fileType=${this.UploadedFiletype}`;
    }

    const action = actionAppend;
    this.loadSpinner = true;
    // const action = `record/addDocument?documentName=${documentName}&documentType=${this.uploadAdditioinalDocsForm?.value?.documentType}&appointmentId=${this.selectedAppointmentUser?.id}&isFile=${isFile}`;
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.isFileUploaded = true;
        this.uploadedFileInformation = data?.fileName || null;
        // this.resetDocumentsPopup();
        documentUpload.hide();
        this.notifyText = data?.msg || data?.message || 'Document uploaded successfully.';
        this.isSuccessNotify = true;
        this.documentUploadNotify?.show();
        this.onDocumentUploadChange?.emit(true);
        data.documentType = this.uploadAdditioinalDocsForm?.value?.documentType;
        data.userInfoId = this.selectedAppointmentUser?.id;
        this.invoiceUsersDocs?.emit(data);
        //this.rerender();
        // this.additionalDocsUpload?.getAllDocumentsByUser();
        if(this.collection == 'Encounter' && documentType != 'Consent Form'){
          this.updateDocStatus(data?.keyName);
        }
        this.docUplaod?.emit('docUpdated');
      } else {
        this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.updateUserdataCard?.cardRefreshHide();
    },
      (error) => {
        this.resetDocumentsPopup();
        documentUpload.hide();
        this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.updateUserdataCard?.cardRefreshHide();
        this.loadSpinner = false;
      });
      
  }

  // send Notifications
  public lateNotify(patient: any): void {
    this.lateNotifyPatient = patient || {};
    this.sendNotifyComp?.showNotifyModal(patient);
  }

  /*
  
  public downloadInsuranceCard(cardId: string, insuraceCardModal: UiModalComponent): void {
    const action = `document/user/getInsuranceCard/${cardId}`;
    this.updateUserdataCard?.cardRefreshShow();
    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // created dynamic iframe SRC attribute and assigned lookup url
      // window.open(fileURL);
      insuraceCardModal?.show();
      this.updateUserdataCard?.cardRefreshHide();
      const iframeEle = document.getElementById('insurance-card-multiple') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    },
      (error: any) => {
        this.updateUserdataCard?.cardRefreshHide();
      });
  }
  

  public downloadIdentificationCard(documentType, userInfoId) {
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}`;

    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.updateUserdataCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        this.hideInsurance = true;

        const s3Key = data?.responseObject?.s3Key;
        const s3Path = data?.responseObject?.s3Path;
        const additionalKey = data?.responseObject?.additionalKey;
     const fileType = data?.responseObject?.fileType;
        this.downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType);
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {

    });
  }

  public downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType) {
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;

    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      //const iframeEle = document.getElementById('dl-card') as HTMLElement;
      //iframeEle.setAttribute('src', fileURL);

      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }


      this.DocIframeUpdate?.emit(fileObj);
    }, (error) => {

    });
  }

  public downloadDlCard(cardId: string): void {
    const action = `document/user/getIdCard/${cardId}`;

    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // window.open(fileURL);
      const iframeEle = document.getElementById('dl-card') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    },
      (error: any) => {
      });
  }

  public updateInsuranceIdentityImageId(cardType: string): void {
    const insuranceCardFile = this.insuranceCardFile || '';
    const dlCardFile = this.drivingLicenseFile || '';

    let action: string = `document/uploadInsuranceimageforAppointment?appointmentId=${this.patientDetailsInfo?.id}&insImageName=${insuranceCardFile}&memberId=${this.getSelectedInsuValue?.memberId}`;
    if (cardType === 'dlCard') {
      action = `document/uploadIDimageforAppointment?appointmentId=${this.patientDetailsInfo?.id}&idImageName=${dlCardFile}`;
    }
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data?.body?.status === 'SUCCESS') {
          this.notifyText = data?.msg || data?.message || 'File uploaded successfully.';
          this.exceptionModal?.modalShow();
          if (cardType === 'insuCard') {
            this.insuranceCardDetails?.get('insuranceCardImageId')?.setValue(insuranceCardFile);
            // this.downloadInsuranceCard(insuranceCardFile);
          } else {
            this.uploadIdForm?.get('identityImageId')?.setValue(dlCardFile || null);
            this.downloadDlCard(dlCardFile);
          }
        } else {
          this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
          this.exceptionModal?.modalShow();
        }
        this.PatientDetailsCardReload?.cardRefreshHide();
      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.exceptionModal?.modalShow();
          this.PatientDetailsCardReload?.cardRefreshHide();
        });
  }

  public getCommonIdDocument(documentSelected, userInfoid): void {

    this.downloadIdentificationCard(documentSelected, userInfoid);

  }
  */
 public updateDocStatus(docName:any){
  const action = `changeHealth/updateReviewNotes?appointmentId=${this.selectedAppointmentUser?.id}&notes=&providerName=${this.loginDetails?.firstName + " " + this.loginDetails?.lastName || null}&reportS3Key=${docName}&type=${this.uploadAdditioinalDocsForm?.value?.documentType}&addToProgressNotes=false`;
  //&reportId=${this.userDetails?.id}
  this.loadSpinner = true;
  this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
    this.loadSpinner = false;
    if (data?.body?.status === 'SUCCESS') {
      //reviewLabRecord?.hide();
    //  this.notifyText = data?.body?.message || data?.body?.msg || 'Selected Patient Review Notes has been updated successfully.';
      // this.exceptionModal?.modalShow();    
    } else {
      // this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
      // this.exceptionModal?.modalShow();

    }
  }, (error) => {
    // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
    // this.exceptionModal?.modalShow();
    // this.loadSpinner = false;
  })
 }
 redirectFlowBoard(){
   this.docUplaodClose?.emit('docUplaodClose');
 }
}
