<div class="toggle-icon" *ngIf="showExpand" (click)="onAddClass()"></div>
<div class="main details" #mainDetails></div>
<app-card #accordianVisitCard class="new-designed-card" [hidHeader]="true" [options]="false">
    <div class="past-visit" id="careplan_info" #careplan_info>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 mt-3 patient-info-sec">
                <button type="button" class="close basic-close history-close" (click)="closeHistoryViewMore()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span class="name"
                    *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">{{appointmentInfoDetails?.appointment?.purposeOfVisit}}
                    Visit</span>
                <span class="name"
                    *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'">{{appointmentInfoDetails?.appointment?.purposeOfVisit}}</span>
                <span class="gender-age"
                    *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">{{getAppointmentTime(appointmentInfoDetails?.appointment?.dateTime)}}</span>

                | <span class="gender-age"><b>Coverage Type:</b> &nbsp;
                    <label *ngIf="appointmentInfoDetails?.unInsured === true">Un-Insured</label>
                    <label *ngIf="appointmentInfoDetails?.selfPay === true" class="name">Self-Pay</label>
                    <label *ngIf="appointmentInfoDetails?.healthPass === true" class="name">Health pass</label>
                    <label
                        *ngIf="appointmentInfoDetails?.unInsured === false && appointmentInfoDetails?.selfPay === false && appointmentInfoDetails?.healthPass === false">Insurance</label>
                    <label class="radio-inline"><input type="radio" name="Health Pass"></label>
                </span>
                <!-- </div>
            <div class="col-lg-12 col-md-12 col-sm-12 patient-info-sec mt-2"> -->
                | &nbsp;&nbsp; <span *ngIf="appointmentCostOfCareDetails?.apptCostofCare?.patientId">Patient ID:
                    &nbsp;&nbsp;<b>{{appointmentCostOfCareDetails?.apptCostofCare?.patientId}}</b></span>
                <span *ngIf="appointmentCostOfCareDetails?.apptCostofCare?.claimId">&nbsp;&nbsp;| &nbsp;&nbsp;Claim ID:
                    &nbsp;&nbsp;<b>{{appointmentCostOfCareDetails?.apptCostofCare?.claimId}}</b></span>
                <span *ngIf="appointmentInfoDetails?.selfPay === true">| <b>Amount Paid :</b>
                    ${{appointmentPiadAmount}}</span>
            </div>
        </div>
    </div>
    <hr>
    <div class="accord-container">
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="expand-collapse">
                    <span>Expand All</span>
                    <span>Collapse All</span>
                    <i class="las la-expand-arrows-alt"></i>
                    <i class="las la-compress-arrows-alt"></i> 
                </div>
            </div>
        </div>  -->
        <div class="panel-group" id="accordion">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" id="careplan_link" href="#care-plan"
                            aria-expanded="accordianExpaned">Care
                            Plan</a>
                    </h4>
                </div>
                <div id="care-plan" class="panel-collapse collapse " [ngClass]="careplandefaultShow ? 'show' : 'in'">
                    <div class="panel-body">
                        <app-review-assessment [carePlanData]="allCareFlowData?.carePlan"
                            [codeMissingAssessments]="codeMissingAssessments"></app-review-assessment>
                        <app-review-careplan [planData]="allCareFlowData?.carePlan"></app-review-careplan>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#orders">Orders</a>
                    </h4>
                </div>
                <div id="orders" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-orders [allRxOrderDetails]="allRxOrderDetails"
                            [alllabRadOrderDetails]="alllabRadOrderDetails"
                            [PatientDetailsCardReload]="accordianVisitCard" [notifyText]="notifyText"
                            [exceptionModal]="exceptionModal"></app-review-orders>
                    </div>
                </div>
            </div>
            <div class="panel panel-default"
                *ngIf="allCareFlowData?.exam && checkIfSavedSections(allCareFlowData?.exam)">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#exam">Exam</a>
                    </h4>
                </div>
                <div id="exam" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-exam [examData]="allCareFlowData?.exam"></app-review-exam>
                    </div>
                </div>
            </div>
            <div class="panel panel-default"
                *ngIf="allCareFlowData?.vitals && allCareFlowData?.vitals?.length > 0 && checkIfSavedSections(allCareFlowData?.vitals)">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#vitals">Vitals</a>
                    </h4>
                </div>
                <div id="vitals" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-vitals [vitalsData]="allCareFlowData?.vitals"></app-review-vitals>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#demographics">Demographics</a>
                    </h4>
                </div>
                <div id="demographics" class="panel-collapse collapse in">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle"> First Name </label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label> {{ appointmentInfoDetails?.firstName || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle"> Middle Name </label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label> {{ appointmentInfoDetails?.middleName || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle"> Last Name </label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label> {{ appointmentInfoDetails?.lastName || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle"> DOB </label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label> {{ appointmentInfoDetails?.dob || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle"> Gender </label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label> {{ appointmentInfoDetails?.gender || '--'}}</label>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle"> Age </label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.age}}</label>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle"> Marital Status </label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.maritalStatus || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Race</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.race || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Ethnicity</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.ethnicity || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.address?.street || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.address?.city || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.address?.state || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            {{addPrefixZipCode(appointmentInfoDetails?.address?.zipCode,'') || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Phone Number</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.patientPrimaryPhone || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Email</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.email || '--'}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#insurance-details">Insurance
                            Details</a>
                    </h4>
                </div>
                <div id="insurance-details" class="panel-collapse collapse">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Coverage Type</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label *ngIf="appointmentInfoDetails?.unInsured === true">Un-Insured</label>
                                        <label *ngIf="appointmentInfoDetails?.selfPay === true">Self-Pay</label>
                                        <label *ngIf="appointmentInfoDetails?.healthPass === true">Health Pass</label>
                                        <label
                                            *ngIf="appointmentInfoDetails?.unInsured === false && appointmentInfoDetails?.selfPay === false && appointmentInfoDetails?.healthPass === false">Insurance</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row insurance-items"
                            *ngFor="let insurance of appointmentInfoDetails?.insuranceDetails; let i = index">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5 style="float: right;color: #296746;" *ngIf="insurance?.active">Active</h5>
                                <h5 style="float: right;color: red" *ngIf="!insurance?.active">Inactive</h5>
                            </div>
                            <div class="col-lg-10 col-md-10 col-sm-10">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Insurance Name</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{insurance?.name || '--'}} </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Member ID / Subscriber ID</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{insurance?.memberId || '--'}} </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Payor ID</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{insurance?.payerId || '--'}} </label>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Group Number</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{insurance?.groupNumber || '--'}} </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Coverage Sequence</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{insurance?.priority || '--'}} </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Insurance Class</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{insurance?.insuranceClass || '--'}} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="download-insurance-card mt-2" *ngIf="insurance?.insuranceCardImageId">
                                    <h5 class="download-insurance">
                                        <span
                                            (click)="downloadInsuranceCard(insurance?.insuranceCardImageId, insuraceCardPastVisitViewModal)">View/Download
                                            Insurance Card <br /> <i class="las la-cloud-download-alt"></i></span>
                                    </h5>
                                </div>
                                <div class="download-insurance-card mt-2" *ngIf="!insurance?.insuranceCardImageId">
                                    <h5 class="download-insurance">Insurance card not available</h5>
                                </div>
                            </div>

                        </div>

                        <ng-container *ngIf="!appointmentInfoDetails?.unInsured && !appointmentInfoDetails?.selfPay">
                            <h5 class="header">Primary Subscriber Details</h5>
                            <hr />
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">First Name</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{subscriberDetails?.subscriberfirstname || '--'}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Last Name</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{subscriberDetails?.subscriberlastname || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">DOB</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{subscriberDetails?.subscriberdateOfBirth || '--'}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Gender</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{subscriberDetails?.subscribergender || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Relationship to Subscriber</label> <span
                                                class="label-colon">
                                                : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{subscriberDetails?.relationToSubscriber || '--'}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Emergency Contact Details section -->

                        <h5 class="header">Emergency Contact Details</h5>
                        <hr />
                        <div class="row insurance-items"
                            *ngFor="let contact of appointmentInfoDetails?.emergencyDetails">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Name</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label> {{contact?.name || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Relationship</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label> {{contact?.relationship || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Emergency Contact</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label> {{contact?.phone || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container
                            *ngIf="!appointmentInfoDetails?.emergencyDetails || appointmentInfoDetails?.emergencyDetails?.length === 0">
                            <span>Emergency Contact Details are unavailable for this patient</span>
                        </ng-container>
                        <h5 class="header">Employer Details</h5>
                        <hr />
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <label class="labelStyle">Employer Name</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-6">
                                        <label>{{subscriberDetails?.employerName || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{subscriberDetails?.employerAddress?.street || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{subscriberDetails?.employerAddress?.city || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{subscriberDetails?.employerAddress?.state || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            {{addPrefixZipCode(subscriberDetails?.employerAddress?.zipCode,'') || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="header">Billing Address Details</h5>
                        <hr />
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.billingAddress?.street || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.billingAddress?.city || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.billingAddress?.state || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Zipcode</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{addPrefixZipCode(appointmentInfoDetails?.billingAddress?.zipCode,'') ||
                                            '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Preferred Pharmacy -->
                        <h5 class="header">Preferred Pharmacy</h5>
                        <hr />
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Pharmacy Name</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.pharmacy?.name || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Phone Number</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.pharmacy?.phoneNumber || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Fax</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.pharmacy?.fax || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.pharmacy?.address?.street || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.pharmacy?.address?.city || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{appointmentInfoDetails?.pharmacy?.address?.state || '--'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            {{addPrefixZipCode(appointmentInfoDetails?.pharmacy?.address?.zipCode,'') ||
                                            '--'}}

                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#consent-forms">Consent Forms</a>
                    </h4>
                </div>
                <div id="consent-forms" class="panel-collapse collapse">
                    <div class="panel-body">
                        <!--<app-view-consent-forms [allConsentFormDocs]="allConsentFormDocs" [cardReload]="accordianVisitCard">
                </app-view-consent-forms>-->


                        <div class="table-responsive new-designed-table">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Document Name</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let form of allConsentFormDocs; let i = index">
                                        <tr>
                                            <td>{{form?.documentName}}</td>
                                            <td class="edit-delet-actions text-center">
                                                <a class="edit icon"
                                                    (click)="downloadConsentForm(form, accordianConsentFormViewModal)"
                                                    style="cursor:pointer"><i class="las la-file-download"></i>&nbsp;
                                                    Download</a>

                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="allConsentFormDocs?.length === 0">
                                        <tr>
                                            <td colspan="2">
                                                <h6 class="text-center">Consent Forms are unavailable for this patient.
                                                </h6>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            <!-- <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#id-documents">ID Documents</a>
                    </h4>
                </div>
                <div id="id-documents" class="panel-collapse collapse">
                    <div class="panel-body">
                        <div class="row images-sec">
                            <ng-container *ngIf="checkCommonIdDocument">
                                <div class="col-lg-10 col-md-10 col-sm-12">
                                    <h5 class="head-color">Driving Licence Card</h5>
                                    <iframe id="dl-past-visit-card" frameBorder="0" width="100%"
                                        height="650px"></iframe>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!checkCommonIdDocument">
                                <div class="col-lg-10 col-md-10 col-sm-12">
                                    <h5>ID Document is not available.</h5>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="panel panel-default"
                *ngIf="allCareFlowData?.chiefComplaints && checkIfSavedSections(allCareFlowData?.chiefComplaints)">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#chief-complaints">Chief Complaints</a>
                    </h4>
                </div>
                <div id="chief-complaints" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-cc [ccData]="allCareFlowData?.chiefComplaints"></app-review-cc>
                    </div>
                </div>
            </div>
            <div class="panel panel-default"
                *ngIf="allCareFlowData?.hpi && allCareFlowData?.hpi?.length > 0 && checkIfSavedSections(allCareFlowData?.hpi)">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#history">History of Present
                            Illness</a>
                    </h4>
                </div>
                <div id="history" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-hpi [hpiSummaryData]="allCareFlowData?.hpi"
                            [pastVisitMedications]="pastVisitMedications"></app-review-hpi>
                    </div>
                </div>
            </div>

            <div class="panel panel-default"
                *ngIf="allCareFlowData?.reviewOfsystems && checkIfSavedSections(allCareFlowData?.reviewOfsystems)">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#ros">Review Of Systems</a>
                    </h4>
                </div>
                <div id="ros" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-ros [rosData]="allCareFlowData?.reviewOfsystems"></app-review-ros>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#medical-history">Medical History</a>
                    </h4>
                </div>
                <div id="medical-history" class="panel-collapse collapse">
                    <div class="panel-body">
                        <ng-container
                            *ngFor="let category of allCareFlowData?.medicalHistory; let cati = index;let last = last">
                            <h5 *ngIf="category?.resourceType !== 'Medical History'">{{category?.resourceType}}</h5>
                            <ng-contaier *ngFor="let section of category?.sections">
                                <h5 *ngIf="category?.resourceType !== section?.name">{{section?.name}}</h5>
                                <ng-contaier *ngFor="let answers of section?.answers">
                                    <ng-container *ngIf="answers?.actualValue?.length > 0">
                                        <h6>{{answers?.label}}</h6>
                                        <ul>
                                            <ng-container *ngFor="let value of answers?.actualValue; let listi = index">
                                                <li>{{value?.answer}}</li>
                                            </ng-container>
                                        </ul>
                                    </ng-container>
                                </ng-contaier>
                            </ng-contaier>
                            <ng-container *ngIf="!category?.sections || category?.sections?.length === 0">
                                <span>No option has been selected under Medical History.</span>
                            </ng-container>
                            <ng-contaier *ngIf="category?.otherNotes && last">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{category?.otherNotes || '--'}}</label>
                                    </div>
                                </div>
                            </ng-contaier>
                        </ng-container>

                        <ng-container
                            *ngIf="!allCareFlowData?.medicalHistory || allCareFlowData?.medicalHistory?.length === 0">
                            <span>No option has been selected under Medical History.</span>
                        </ng-container>

                    </div>
                </div>
            </div>
            <div class="panel panel-default"
                *ngIf="allCareFlowData?.behavioralScreening && checkIfSavedSections(allCareFlowData?.behavioralScreening)">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#behavioral-screening">Behavioral
                            Screening</a>
                    </h4>
                </div>
                <div id="behavioral-screening" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-behaviousscreening
                            [behaviourScreeningData]="allCareFlowData?.behavioralScreening"></app-review-behaviousscreening>
                    </div>
                </div>
            </div>
            <div class="panel panel-default" *ngIf="allInternalLabOrders && allInternalLabOrders?.length > 0">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#inHouse-labs">InHouse Labs</a>
                    </h4>
                </div>
                <div id="inHouse-labs" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-inhouselabs [inhouseLabsData]="allInternalLabOrders"></app-review-inhouselabs>
                    </div>
                </div>
            </div>
            <div class="panel panel-default" *ngIf="allInternalVaccineDetails && allInternalVaccineDetails?.length > 0">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#inHouse-injection">Immunizations</a>
                    </h4>
                </div>
                <div id="inHouse-injection" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-immunizations [immunizationData]="allInternalVaccineDetails"
                            [allergiesFromVaccine]="allergiesFromVaccine"></app-review-immunizations>
                    </div>
                </div>
            </div>
            <div class="panel panel-default" *ngIf="allInHouseProcedures && allInHouseProcedures?.length > 0">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#inhouse-procedure">Procedures</a>
                    </h4>
                </div>
                <div id="inhouse-procedure" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-review-inhouse-procedures
                            [inhouseProceduresData]="allInHouseProcedures"></app-review-inhouse-procedures>
                    </div>
                </div>
            </div>


            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#progress-notes">Progress Notes</a>
                    </h4>
                </div>
                <div id="progress-notes" class="panel-collapse collapse">
                    <div class="panel-body">
                        <div class="journal-notes-sec">
                            <div class="table-responsive new-designed-table">
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Notes</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let notes of allCareFlowData?.progressNotes; let i = index">
                                            <tr>
                                                <td>{{notes?.notes}}</td>
                                                <td>{{notes?.auditInfo?.createdDate | date: 'MM/dd/yyyy hh:mm a'}}</td>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!allCareFlowData?.progressNotes || allCareFlowData?.progressNotes?.length === 0">
                                            <tr>
                                                <td class="text-center" colspan="4">
                                                    Progress Notes are unavailable for this patient.
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <ng-container *ngFor="let notes of allCareFlowData?.progressNotes; let i = index">
                                <ul>
                                    <li *ngIf="notes?.notes"><b>({{notes?.auditInfo?.createdDate | date: 'MM/dd/yyyy
                                            hh:mm a'}})</b> -
                                        {{notes?.notes}}</li>
                                </ul>
                            </ng-container>
                            <ng-container
                                *ngIf="!allCareFlowData?.progressNotes || allCareFlowData?.progressNotes?.length === 0">
                                <h5>Progress Notes are unavailable for this patient.</h5>
                            </ng-container> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#addendum">Addendum</a>
                    </h4>
                </div>
                <div id="addendum" class="panel-collapse collapse">
                    <div class="panel-body">
                        <div class="journal-notes-sec">
                            <div class="table-responsive new-designed-table">
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Reason</th>
                                            <th>Initials</th>
                                            <th>Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let addendum of allCareFlowData?.addendum; let i = index">
                                            <tr>
                                                <td>{{addendum?.auditInfo?.lastUpdatedDateTime | date: 'MM/dd/yyyy hh:mm
                                                    a'}}</td>
                                                <td>{{addendum?.reason || '--'}}</td>
                                                <td>{{addendum?.initials}}</td>
                                                <td>{{addendum?.notes}}</td>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!allCareFlowData?.addendum || allCareFlowData?.addendum?.length === 0">
                                            <tr>
                                                <td class="text-center" colspan="4">
                                                    Addendum is unavailable for this patient.
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#post-visit">Post Visit Summary</a>
                    </h4>
                </div>
                <div id="post-visit" class="panel-collapse collapse">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-6">
                                <span>Summary: <b>{{allCareFlowData?.dischargeInstructions?.notes || 'N/A'}}</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#account-bal">Account Balance</a>
                    </h4>
                </div>
                <div id="account-bal" class="panel-collapse collapse">
                    <div class="panel-body">
                        <app-account-balance [appointmentId]="appointmentId"
                            [cardRefresh]="accordianVisitCard"></app-account-balance>

                    </div>
                </div>
            </div> -->
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#documents">Documents</a>
                    </h4>
                </div>
                <div id="documents" class="panel-collapse collapse">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="table-responsive new-designed-table">
                                    <table class="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Document Name</th>
                                                <th>Document Type</th>
                                                <th>Reviewed Date</th>
                                                <th class="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="lab-tds" *ngFor="let document of allAdditonalDocuments">
                                                <td>{{document?.documentName || '--'}}</td>
                                                <td>{{document?.documentType || '--'}}</td>
                                                <td>{{document?.reviewNotes?.updatedDate || '--'}}</td>
                                                <td class="edit-delet-actions text-center">

                                                    <button class="icon-queue-events signoff-action-bg clearfix"
                                                        placement="bottom" ngbTooltip="View Document"
                                                        (click)="downloadDocument(document, additionalDocsViewModal)"><span
                                                            class="material-symbols-outlined">visibility</span></button>
                                                    <!-- <ng-container>
                                                <button class="icon-queue-events signoff-action-bg clearfix"
                                                    placement="bottom" ngbTooltip="Send Response"
                                                    (click)="navigatePatientJournal()"><i class="las la-reply"></i></button>
                                            </ng-container>
                                            <button class="icon-queue-events signoff-action-bg clearfix" placement="bottom"
                                                ngbTooltip="Review Notes"
                                                (click)="updateRecordReview(document,reviewLabRecord)">
                                                <span _ngcontent-hmi-c289="" class="material-symbols-outlined">sync_saved_locally</span></button>
    
                                            -->

                                                </td>
                                            </tr>
                                            <tr *ngIf="allAdditonalDocuments?.length === 0">
                                                <td colspan="4" class="text-center">Documents are not available</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#refferal">Referral Details</a>
                    </h4>
                </div>
                <div id="refferal" class="panel-collapse collapse">
                    <div class="panel-body">
                        <div class="row insurance-items" *ngFor="let referral of allCareFlowData?.referralDetails">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Referral Date</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{referral?.auditInfo?.lastUpdatedDateTime ?
                                            getAppointmentTime(referral?.auditInfo?.lastUpdatedDateTime)
                                            : ''}}</label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6 col-sm-12"></div> -->
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Referring Provider</label> <span class="label-colon">
                                            :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{referral?.referringProvider || ''}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Reciever/Referral Provider</label> <span
                                            class="label-colon">
                                            :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{referral?.referralProvider || ''}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Reason</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{referral?.reason || ''}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">
                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#last-service">Last Service Provider
                            Details</a>
                    </h4>
                </div>
                <div id="last-service" class="panel-collapse collapse">
                    <div class="panel-body">
                        <div class="row"
                            *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Doctor Name</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{allCareFlowData?.lastServiceProviderDetails?.name || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="LastServiceProviderDetailsPastVisits"
                            *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h5>Electronically Signed by {{allCareFlowData?.signOff?.doctorName}}</h5>
                                    <hr>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">E-Signature</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="signature">
                                                <h5><i>{{allCareFlowData?.signOff?.doctorName || ''}}</i></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Initials</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="signature">
                                                <h5><i>{{allCareFlowData?.signOff?.doctorInitials || ''}}</i></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-card>
<!-- <button id="myBtn"><a href="#top" style="color: white">Top</a></button> -->
<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Display the Consent Form PDF -->
<app-ui-modal #additionalDocsViewModal [hideHeader]="false" [modalCentered]="true" id="additionalDocsViewModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Documents</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="additionalDocsViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="additonal-doc-pdf-view" frameBorder="0" width="100%" height="425px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="additionalDocsViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- Square Payment Card -->
<!-- <app-payments-common-new [paymentConfigDetails]="paymentConfigDetails"
    [recieptID]="'past-visit-details-accordian-reciept-id'"></app-payments-common-new> -->

<app-payments-refactor [paymentConfigDetails]="paymentConfigDetails" [recieptID]="'past-visit-details-accordian-reciept-id'"></app-payments-refactor>

<app-ui-modal #insuraceCardPastVisitViewModal [hideHeader]="false" [modalCentered]="true"
    id="insuraceCardPastVisitViewModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Insurance Card</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardPastVisitViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="insurance-card-past-visit-multiple" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" id="ok2"
            data-dismiss="modal" (click)="insuraceCardPastVisitViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- Lab RAD Resulrs modal -->
<app-lab-rad-results-modal [labRadOrdersCard]="accordianVisitCard"></app-lab-rad-results-modal>

<!-- Display the Order Details -->
<app-lab-rad-order-details-modal [cardComp]="accordianVisitCard"></app-lab-rad-order-details-modal>

<app-hl7-details [cardComp]="accordianVisitCard"></app-hl7-details>
<!-- Update Lab/Rad Progress Notes -->
<app-document-review (documentReviewUpdate)="refresh()"></app-document-review>

<app-ui-modal #accordianConsentFormViewModal [hideHeader]="false" [modalCentered]="true"
    id="accordianConsentFormViewModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Consent Form</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="accordianConsentFormViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="consent-form-view-pdf-accordian" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok3"
            (click)="accordianConsentFormViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>