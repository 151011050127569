import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { SwPush } from '@angular/service-worker';
import { title } from 'process';
@Component({
  selector: 'app-notifications-view',
  templateUrl: './notifications-view.component.html',
  styleUrls: ['./notifications-view.component.scss']
})
export class NotificationsViewComponent implements OnInit, OnDestroy {

  public allNotificationsList = [];
  public loginUserDetails: any;
  public todayNotifications: any[] = [];
  public olderNotifications: any[] = [];
  @Output() onNotificationsRecieved = new EventEmitter();
  public notifySubscription: Subscription;
  public allDocumentTypes = ["Lab Results", "Radiology Results", "Referral", "Wellness Forms", "Other"];
  @Input() notificationDropToggle: NgbDropdown
  
  // Push Notifications Key generate
  readonly VAPID_PUBLIC_KEY = { "publicKey": "BNUNBQ_aDl7oArCt4YM_xSAEFvWUK1lrYXdeFQLfwfNmKERaRVwpf0RQyY3WlXNuF_6joWnxgWRu5O4QE9URo3o", "privateKey": "uukM9MYm0HPt0JdMbk3FJuj3fAbzcWuzTWGdL9OiTpc" };
  public notificationSubscription: PushSubscription;
  public serviceWorkerRegistration: ServiceWorkerRegistration;
  loadSpinner: boolean;
  public isShowUnreadSelected: boolean;
  public isShowFaxSelected: boolean;
  public categoriesList = [
    { value: 'All', desc: 'All' },
    { value: 'Fax', desc: 'Fax' },
	{ value: 'Lab Results', desc: 'Lab Results' },
	{ value: 'Patient Journal', desc: 'Patient Journal' },
    { value: 'Referral', desc: 'Referral' },
    { value: 'Tasks', desc: 'Tasks' },
  
  ];

  public selectedCategory: any;
 
  @ViewChild('toggleChk') unreadToggle;
 

  constructor(private httpService: HttpService, private router: Router, readonly swPush: SwPush) { }

  ngOnInit(): void {
    this.loginUserDetails = JSON?.parse(sessionStorage?.getItem('userDetails')) || {};
    //call refersh function for every 30 secs
    const source = interval(30000);
    this.notifySubscription = source.subscribe(val => { this.getAllNotifications() });
	this.selectedCategory = 'Tasks';
	
	this.getAllNotifications();
	
	
	
  }
  public resetNotifications(): void { 
  
	this.unreadToggle.nativeElement.checked = false; 
	this.selectedCategory = 'Tasks';
    this.isShowUnreadSelected = false;
    this.filterNotifications(this.selectedCategory);
  }

  async subscribeToNotifications(title: string, options: any) {
    try {
      await this.swPush?.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY?.publicKey,
      })?.then(async (sub: PushSubscription) => {
        if (sub?.endpoint) {
          navigator.serviceWorker.register('ngsw-worker.js')
            .then((swReg: ServiceWorkerRegistration) => {
              this.serviceWorkerRegistration = swReg;
              this.subscribeUser(swReg, this.VAPID_PUBLIC_KEY?.publicKey, title, options);
            })
            .catch((error) => {
              console.error('Service Worker Error', error);
            });

        }
      });
    } catch (err) {
      console.error('Could not subscribe due to:', err);
    }
  }

  public async subscribeUser(swRegistration: ServiceWorkerRegistration, applicationServerKey, title?: string, options?: any) {
    await swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    })
      .then(async (subscription) => {
        // const title = 'Push Codelab';
        // const options = {"title": "message title", "body": "message body"};
        await swRegistration?.showNotification(title, options);
      })
      .catch((error) => {
        console.error('Failed to subscribe the user: ', error);
      });
  }

  public getAllNotifications(): void {
    if(!this.loginUserDetails) {
      return;
    }
    const responderRole = (this.loginUserDetails?.roleType === 'Admin' || this.loginUserDetails?.roleType === 'Practice Admin' || this.loginUserDetails?.roleType === 'Super Admin') ? 'All' : this.loginUserDetails?.roleType;
    // const responderRole = 'All';
    this.loadSpinner = true;
    const name = (`${this.loginUserDetails?.lastName} ${this.loginUserDetails?.firstName} `)?.trim()
    const action = `notification/readNotificationsFromHub?facilityId=${this.loginUserDetails?.facilityId}&responderRole=${this.loginUserDetails?.roleType || responderRole}&assignee=${name}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.allNotificationsList = data?.responseObject || [];
        // this.loginUserDetails?.providerNPI && 
        // if ((this.loginUserDetails?.roleType !== 'Admin' && this.loginUserDetails?.roleType !== 'Practice Admin' && this.loginUserDetails?.roleType !== 'Super Admin')) {
        //   const loginNpi = parseInt(this.loginUserDetails?.providerNPI);

        //   this.allNotificationsList = this.allNotificationsList?.filter((nameFilter: any) => (`${this.loginUserDetails?.firstName} ${this.loginUserDetails?.lastName}`)?.trim() === nameFilter?.notificationInfo?.responderName);

        //   // if (this.loginUserDetails?.roleType === 'Doctor') {
        //   //   this.allNotificationsList = this.allNotificationsList?.filter(item => (item?.notificationInfo?.responderRole === 'Doctor' || item?.notificationInfo?.responderRole === 'Clinician') && (parseInt(item?.notificationInfo?.responderNPI) === loginNpi || !item?.notificationInfo?.responderNPI)) || [];
        //   // }

        //   // if (this.loginUserDetails?.roleType !== 'Doctor') {
        //   //   this.allNotificationsList = this.allNotificationsList?.filter(item => (parseInt(item?.notificationInfo?.responderNPI) === loginNpi || !item?.notificationInfo?.responderNPI)) || [];
        //   // }

        // }
        let filterNotifications = JSON.parse(JSON?.stringify(this.allNotificationsList));
        filterNotifications = filterNotifications?.filter(item => item?.notificationInfo?.status) || [];
        //filterNotifications = filterNotifications?.filter((item: any) => item?.documentInfo?.type !== 'Inbound Fax' && item?.documentInfo?.type !== 'Outbound Fax' &&  item?.notificationInfo?.status)
        this.onNotificationsRecieved?.emit(filterNotifications);
        let allNotifications = JSON.parse(JSON?.stringify(this.allNotificationsList || []));


        /* if (this.isShowUnreadSelected) {
           allNotifications = allNotifications?.filter(item => item?.notificationInfo?.status) || [];
 
         }*/


        // [...data?.responseObject || []];

        //this.makeNotificationsFormat(allNotifications);

        this.filterNotifications(this.selectedCategory);
      }
      this.loadSpinner = false;
    },
      error => {
        this.loadSpinner = false;
      });
  }

  // update notification as read
  public updateNotificationsRead(notifyItem: any): void {
    if (!notifyItem?.notificationInfo?.status) {
      return;
    }
    const action = `notification/updateNotificationsHub?facilityId=${notifyItem?.facilityId}&id=${notifyItem?.id}`;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.body?.status === 'SUCCESS') {
        // this.allNotificationsList = data?.responseObject || [];
        // this.onNotificationsRecieved?.emit(this.allNotificationsList);
        // const allNotifications = [...data?.responseObject || []];
        // this.makeNotificationsFormat(allNotifications);
        this.getAllNotifications();
      }
    },
      error => {
        this.loadSpinner = false;
      });
  }

  public makeNotificationsFormat(allNotifications: any): void {
    this.todayNotifications = [];
    this.olderNotifications = [];
    allNotifications?.sort((a: any, b: any) => {
      const bDate: any = new Date(b?.auditInfo?.createdDate);
      const aDate: any = new Date(a?.auditInfo?.createdDate);
      return bDate - aDate;
    });
    allNotifications?.forEach((item: any) => {
      const newDate = new Date()?.toISOString()?.split('T')[0];
      const formatDate = new Date(newDate);
      const splitDate = item?.auditInfo?.createdDate?.split('T')[0]
      const notifyDate = new Date(splitDate);
      if (formatDate?.getTime() === notifyDate?.getTime()) {
        this.todayNotifications?.push(item);
        const firstNameCheck = item?.patientInfo?.firstName || '';
        const lastNameCheck = item?.patientInfo?.lastName || '';
        const title = `${firstNameCheck} ${lastNameCheck}`;
        const options = {
          "title": `${firstNameCheck} ${lastNameCheck}`,
          "body": item?.documentInfo?.notes ? `${item?.documentInfo?.type} - ${item?.documentInfo?.notes}` : `${item?.documentInfo?.type}`,
          "icon": "assets/images/careeco-logo.jpg",
          "vibrate": [100, 50, 100],
          "data": {
            "dateOfArrival": Date.now(),
            "primaryKey": 1
          },
        };
        if (item && item?.latest) {
          this.subscribeToNotifications(title, options)
        }
      } else {
        this.olderNotifications?.push(item);
      }
    });
  }

  // calculate time
  public calculateNotificationTimer(notifyDateTime: string): string {
    // get total seconds between the times
    const newDate: any = new Date();
    const notificationDate: any = new Date(notifyDateTime)
    let getSeconds = Math.floor(((newDate) - notificationDate) / 1000);
    let getminutes = Math.floor(getSeconds / 60);
    let gethours = Math.floor(getminutes / 60);
    let days = Math.floor(gethours / 24);

    gethours = gethours - (days * 24);
    getminutes = getminutes - (days * 24 * 60) - (gethours * 60);
    getSeconds = getSeconds - (days * 24 * 60 * 60) - (gethours * 60 * 60) - (getminutes * 60);

    let displayTime: string;
    if (days > 0) {
      displayTime = days + ' days ago';
    }
    if (days === 0 && gethours < 60) {
      displayTime = gethours + ' hours ago';
    }
    if (days === 0 && gethours > 60 && getminutes < 60) {
      displayTime = getminutes + ' minutes ago';
    }
    return displayTime;
  }

  // mark to show only un read
  public onlyShowUnRead(event: any): void {
    let filterNotifications: any[] = [];
    filterNotifications = JSON.parse(JSON.stringify(this.allNotificationsList));

    if (event.target.checked) {
      this.isShowUnreadSelected = true;
      // filterNotifications = filterNotifications?.filter(item => item?.notificationInfo?.status) || [];

    } else {
      this.isShowUnreadSelected = false;
      // filterNotifications = JSON.parse(JSON.stringify(this.allNotificationsList));
    }


    // this.makeNotificationsFormat(filterNotifications);
    this.filterNotifications(this.selectedCategory);
  }

  public navigateNotifications(): void {
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree(['hospital/notifications'])
    // );
    // window.open(url, '_blank');
    this.router?.navigate(['hospital/notifications']);
    // window?.location?.reload();
  }

  // navigation based on type
  public navigation(notifyItem: any): void {

    if (notifyItem?.notificationInfo?.type === 'Tasks' || notifyItem?.documentInfo?.type?.includes('Tasks')) {
      let typeCheck: string;
      if (notifyItem?.documentInfo?.type) {
        const splitTaskType = notifyItem?.documentInfo?.type?.split(' - ');
        typeCheck = splitTaskType && splitTaskType?.length > 0 && splitTaskType[1];
      }
      setTimeout(() => {
        this.router?.navigate([`/hospital/tasks`], { queryParams: { type: typeCheck || null } });
      }, 500);
      this.notificationDropToggle?.close();
      this.updateNotificationsRead(notifyItem);
      return;
    };

    switch (notifyItem?.documentInfo?.type) {
      case 'Patient Query':
      case 'Patient Journal Notes':
      case 'Patient Journal':
        this.updateNotificationsRead(notifyItem);
        this.router.navigate([], {
          queryParams: {
            'patientJournalNotes': null,
            'documentsUpload': null,
            'labresults': null,
          },
          queryParamsHandling: 'merge'
        });
        setTimeout(() => {
          this.router?.navigate([`/hospital/user-data-update/${notifyItem?.patientInfo?.userInfoId}`], { queryParams: { patientJournalNotes: 'yes' } });
        }, 500)
        this.notificationDropToggle?.close();
        break;
      case 'Lab Documents':
      case "Lab Results":
      case "Radiology Results":
      case "Referral":
      case "Wellness Forms":
      case "Other":
      case "Wellness Form":
      case "Request Form":
      case "PCP Override Form":
      case 'Past Medical Record':
      case 'Patient Medical Record':
      case 'Vaccination Record':
      case 'Statements':
      case 'Abnormal Lab Results':
      case 'Consent Form':
        this.updateNotificationsRead(notifyItem);
        if (notifyItem?.serviceInfo?.appointmentId && notifyItem?.serviceInfo?.appointmentId !== "" && notifyItem?.serviceInfo?.appointmentId !== " ") {
          this.router.navigate([], {
            queryParams: {
              'labresults': null,
              'patientJournalNotes': null,
              'documentsUpload': null
            },
            queryParamsHandling: 'merge'
          });

          if (notifyItem?.notificationInfo?.responderRole === 'Front Office') {
            setTimeout(() => {
              this.router?.navigate([`/hospital/user-data-update/${notifyItem?.patientInfo?.userInfoId}`], { queryParams: { patientJournalNotes: 'yes' } });
            }, 500);
          }

          if (notifyItem?.notificationInfo?.responderRole !== 'Front Office') {
            setTimeout(() => {
              this.router?.navigate([`/hospital/past-visit/${notifyItem?.serviceInfo?.appointmentId}`], { queryParams: { labresults: 'yes' } });
            }, 500);
          }

        } else {
          this.router.navigate([], {
            queryParams: {
              'patientJournalNotes': null,
              'documentsUpload': null
            },
            queryParamsHandling: 'merge'
          });

          if (notifyItem?.notificationInfo?.responderRole === 'Front Office') {
            setTimeout(() => {
              this.router?.navigate([`/hospital/user-data-update/${notifyItem?.patientInfo?.userInfoId}`], { queryParams: { patientJournalNotes: 'yes' } });
            }, 500);
          }

          if (notifyItem?.notificationInfo?.responderRole !== 'Front Office') {
            setTimeout(() => {
              this.router?.navigate([`/hospital/user-data-update/${notifyItem?.patientInfo?.userInfoId}`], { queryParams: { documentsUpload: 'yes' } });
            }, 500);
          }
        }
        this.notificationDropToggle?.close();
        break;
      case 'Inbound Fax':
      case 'Outbound Fax':
      case notifyItem?.documentInfo?.type?.includes('Inbound' || 'Outbound'):
        this.updateNotificationsRead(notifyItem);
        this.router?.navigate([`/hospital/hosp-e-fax`], { queryParams: { faxNotify: 'yes' } });
        this.notificationDropToggle?.close();
        break;
      case 'Tasks':
        if (notifyItem?.notificationInfo?.type === 'Tasks' || notifyItem?.documentInfo?.type?.includes('Tasks')) {
          let typeCheck: string;
          if (notifyItem?.documentInfo?.type) {
            const splitTaskType = notifyItem?.documentInfo?.type?.split(' - ');
            typeCheck = splitTaskType && splitTaskType?.length > 0 && splitTaskType[1];
          }
          setTimeout(() => {
            this.router?.navigate([`/hospital/tasks`], { queryParams: { status: 'Open', type: typeCheck || null } });
          }, 500);
        }
        this.notificationDropToggle?.close();
        break;
    }
  }

  get checkNotificationsExpand(): boolean {
    if (location?.href?.includes('hospital/notifications')) {
      return false;
    }
    return true;
  }

  /* public onlyShowFax(event: any) {
     let filterNotifications: any[] = [];
     if (!event.target.checked) {
       this.isShowFaxSelected = true;
       filterNotifications = JSON.parse(JSON.stringify(this.allNotificationsList));
       // filterNotifications = filterNotifications?.filter(item => item?.notificationInfo?.status) || [];
 
       if (this.isShowUnreadSelected) {
         filterNotifications = filterNotifications?.filter(item => item?.notificationInfo?.status) || [];
       }
       else {
         filterNotifications = JSON.parse(JSON.stringify(this.allNotificationsList));
       }
 
 
     } else {
       this.isShowFaxSelected = false;
       filterNotifications = JSON.parse(JSON.stringify(this.allNotificationsList));
       //filterNotifications = filterNotifications?.filter((item: any) => item?.documentInfo?.type !== 'Inbound Fax' && item?.documentInfo?.type !== 'Outbound Fax' && item?.notificationInfo?.status) || [];
 
 
       if (this.isShowUnreadSelected) {
         filterNotifications = filterNotifications?.filter((item: any) => item?.documentInfo?.type !== 'Inbound Fax' && item?.documentInfo?.type !== 'Outbound Fax' && item?.notificationInfo?.status) || [];
 
       }
       else {
         filterNotifications = filterNotifications?.filter((item: any) => item?.documentInfo?.type !== 'Inbound Fax' && item?.documentInfo?.type !== 'Outbound Fax') || [];
 
       }
 
     }
 
     // this.onNotificationsRecieved?.emit(filterNotifications);
     this.makeNotificationsFormat(filterNotifications);
     //this.onlyShowUnRead(event);
 
   }*/
  public filterNotifications(event) {

    let allNotifications: any[] = this.allNotificationsList;
    this.selectedCategory = event;


    if (this.selectedCategory === 'Fax') {
      // allNotifications = allNotifications?.filter(item => item?.notificationInfo?.status) || [];
      allNotifications = allNotifications?.filter((item: any) => item?.documentInfo?.type === 'Inbound Fax' || item?.documentInfo?.type === 'Outbound Fax') || [];
    }
    else if (this.selectedCategory === 'Tasks') {
      allNotifications = allNotifications?.filter((item: any) => item?.notificationInfo?.type === 'Tasks' || item?.documentInfo?.type === 'Tasks') || [];
    }
    else if (this.selectedCategory === 'Referral') {
      allNotifications = allNotifications?.filter((item: any) => item?.documentInfo?.type === 'Referral' || item?.documentInfo?.type?.includes('Referral')) || [];
    }
    else if (this.selectedCategory === 'Lab Results') {
      allNotifications = allNotifications?.filter((item: any) => item?.documentInfo?.type === 'Lab Documents' || item?.documentInfo?.type === 'Lab Results'
        || item?.documentInfo?.type === 'Radiology Results' || item?.documentInfo?.type === 'Wellness Forms'
        || item?.documentInfo?.type === 'PCP Override Form' || item?.documentInfo?.type === 'Patient Medical Record'
        || item?.documentInfo?.type === 'Past Medical Record' || item?.documentInfo?.type === 'Statements' || item?.documentInfo?.type === 'Abnormal Lab Results'
        || item?.documentInfo?.type === 'Consent Form' || item?.documentInfo?.type === 'Other') || [];
    }
    else if (this.selectedCategory === 'Patient Journal') {
      allNotifications = allNotifications?.filter((item: any) => item?.documentInfo?.type === 'Patient Query' || item?.documentInfo?.type === 'Patient Journal Notes' || item?.documentInfo?.type === 'Patient Journal' || item?.documentInfo?.type?.includes('Patient Communication')) || [];
    }
    else if (this.selectedCategory === 'All') {
      allNotifications = JSON.parse(JSON.stringify(this.allNotificationsList));
    }
    else {
      allNotifications = JSON.parse(JSON.stringify(this.allNotificationsList));
	 // allNotifications = allNotifications?.filter((item: any) => item?.documentInfo?.type !== 'Inbound Fax' && item?.documentInfo?.type !== 'Outbound Fax') || [];
    }


    const notificationList = this.filterUnReadChk(allNotifications);

    this.makeNotificationsFormat(notificationList);


  }

  public filterUnReadChk(allNotifications: any): void {
  
    if (this.isShowUnreadSelected) {
      allNotifications = allNotifications?.filter(item => item?.notificationInfo?.status) || [];
    }
    return allNotifications;
  }

  ngOnDestroy(): void {
    this.notifySubscription?.unsubscribe();
  }


}
