import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-emergency-contact-details',
  templateUrl: './emergency-contact-details.component.html',
  styleUrls: ['./emergency-contact-details.component.scss']
})
export class EmergencyContactDetailsComponent implements OnInit, OnChanges {

  @Input() emergencyDetailsForm: FormGroup;
  @Input() appointmentDetails: any;
  @Input() screenName: boolean;

  public relationshipList = [
    { value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' },
    { value: 'Siblings', desc: 'Siblings' },
    { value: 'Friend', desc: 'Friend' },
    { value: 'Guardian', desc: 'Guardian' },
    { value: 'Son', desc: 'Son' },
    { value: 'Daughter', desc: 'Daughter' },
    { value: 'Partner', desc: 'Partner' },
    { value: 'Child', desc: 'Child' }
  ];
  contactDeleteIndex: number;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService) { }

  ngOnInit(): void {

    const formdetails = JSON.parse(sessionStorage.getItem('healthpassDemographics'));
    if (formdetails) {

      const emergencyDetailsArray = this.emergencyDetailsForm?.get('emergencyDetails') as FormArray;
      formdetails.emergencyDetails?.forEach((item: any) => {
        //console.log(item);
        if (item?.phone && item?.phone?.includes('+1')) {
          item.phone = item?.phone?.replaceAll('+1', '');
        }
        emergencyDetailsArray?.push(this.emergencyFormGroup(item));

        // console.log(emergencyDetailsArray);
        //this.addMember();
      });


    }
    //this.addMember();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const formdetails = JSON.parse(sessionStorage.getItem('healthpassDemographics'));
      if (this.appointmentDetails) {
        // console.log(this.emergencyDetailsForm);
        const emergencyDetailsArray = this.emergencyDetailsForm?.get('emergencyDetails') as FormArray;

        this.httpService?.clearFormArray(emergencyDetailsArray);
        this.appointmentDetails?.emergencyDetails?.forEach((item: any) => {
          if (item?.phone && item?.phone?.includes('+1')) {
            item.phone = item?.phone?.replaceAll('+1', '');
          }
          emergencyDetailsArray?.push(this.emergencyFormGroup(item));
        });
        if ((!this.appointmentDetails?.emergencyDetails || this.appointmentDetails?.emergencyDetails?.length === 0) && (formdetails && formdetails.emergencyDetails.length === 0)) {
          this.addEmergencyContact();
        }
      }
    }
  }

  public emergencyFormGroup(contactItem?: any): FormGroup {
    return this.formBuilder.group({
      "phone": [contactItem?.phone || null, Validators?.compose([Validators.pattern(AppRegExpressionsConfig.mobile)])],
      "name": [contactItem?.name || null],
      "relationship": [contactItem?.relationship || null]
    });
  }



  public openDeleteContactConfirm(i: number, confirmContactCardDelete: UiModalComponent): void {
    this.contactDeleteIndex = i;
    confirmContactCardDelete?.show();
  }

  public addEmergencyContact(): void {
    console.log(this.emergencyDetailsForm);
    const emergencyDetailsArray = this.emergencyDetailsForm?.get('emergencyDetails') as FormArray;
    emergencyDetailsArray?.push(this.emergencyFormGroup());
  }

  public removeContactDetails(removeIndex: number): void {
    const emergencyDetailsArray = this.emergencyDetailsForm?.get('emergencyDetails') as FormArray;
    emergencyDetailsArray?.removeAt(removeIndex);
    this.emergencyDetailsForm?.markAsDirty();
  }

  public onEmergencyDetailsChange(contactForm: FormGroup): void {
    if (
      (contactForm?.get('name').value != null && contactForm?.get('name').value != '') ||
      (contactForm?.get('phone').value != null && contactForm?.get('phone').value != '') ||
      (contactForm?.get('relationship').value != null && contactForm?.get('relationship').value != 'Select')) {
      contactForm?.get("name").setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
      contactForm?.get("phone").setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.mobile)]);
      contactForm?.get("relationship").setValidators(Validators.required);
    }
    else {
      contactForm?.get("name").clearValidators();
      contactForm?.get("relationship").clearValidators();
      contactForm?.get("phone").clearValidators();
    }
    contactForm?.get("phone").updateValueAndValidity();
    contactForm?.get("name").updateValueAndValidity();
    contactForm?.get("relationship").updateValueAndValidity();
  }

}
