import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { IHttpPostMessageResponse } from 'http-post-message';
import 'powerbi-report-authoring';
import { HttpService } from '../../services/http.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ReportsService } from '../../services/reports.service';

// Handles the embed config response for embedding
export interface ConfigResponse {
  Id: string;
  EmbedUrl: string;
  EmbedToken: {
    Token: string;
  };
}

@Component({
  selector: 'app-short-words',
  templateUrl: './short-words.component.html',
  styleUrls: ['./short-words.component.scss']
})
export class ShortWordsComponent {
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    // embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=a2ff6343-972d-4c88-b8c5-fd5fe8e215bb&autoAuth=true&ctid=8ba5d408-116d-4a0f-88a0-427e275972b4',
    //embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=7aa6037c-f463-4632-966c-84553f5fc6b1&groupId=b137ac70-7af5-41e6-a34e-664b84a8b2c1&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQi1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19',
    tokenType: models.TokenType.Embed,
    //accessToken: 'H4sIAAAAAAAEAC2Ux66DRgBF_-VtiWSKaZGywNShV-NhN2BsegczjvLveZGyuaujuzjSvX__-Ah3I3r-_PmD921Zdd3zX3b5jZAzrsnrEL0j7--MZOvrvUTDO-BLXYhoZbnzhdKr8MFerY05GFnPy3iZZ7aXuyz1dE1bCLw0XiO2t46zvYk2Jm26BBLd5p3JuGYjG-y56mtU3eYKnYSgaFcw225lL3CKNCmz_D3J8EgScn_iFjRXsyQq1bnZznAtgDMAypxfSLKERxBDhWveZ_6b3R6T1NFExTQ23iHtAaikvfc4nRnow7BPBzAxyFkJuvLV5_y3wxvsC_p4b5j65mblW-y-AMz3D-icnvbvgIqrUhkzF4BmWD5C_rBN5n3_gpFD_tUIwRJY4RupyKyPnuGnjuFHK00G99NwHxT40SORGg6pe_KhFa253w9N5g4bnSkL2_daNtFzUQ1DvynsfFpSIi2IaBo8Utg2921LECyBa-UQERVu2yugvs_r1giOtVS1cslHSPqrJNGVJIixvqetOgjacZewxlTsGcRjLxj9xJ5mdfZUpvatJWdfuN_FL_3knKwlYr5MH0mYxig3UpNq_F5LJTrQlBEfXFjiDdU0lmGkGIHu4ksUjSRFkr365WwrRjRV6otGvU-lAt1nQZtTRlePZo9tjztpD-lhXl9a1_jCrLrNzeSHnOcjWy-mh9FNoz6iADddFNRLcl7mhwWvZUlD9VlQPfZ8OZztBjTEoRzMk6WkwrHsMlWTvIFGmtri5LKIoIoavMJm_t6iIg-iFniWOeu3pb1MsWfUdvaUsqp1o5SMteShwTeJuATilE3vymgviRyfrVS_b12YdnmgfDU22gv9sdD88Jj4V7mJNepdO6fWC7PfQe9P66rspintAgOqVSMvvEHtz9Jaf_74kRc8baNV4t859SlWyMwlPrtK7DhWho-YE1mjairffgfcLghPiVQ7aCH5QuZfL-DsMpn4AVp24aQd_5idzeNPWg-fdAUjUXJPgjg_amd_0lCGicG-08ppqQqugpx5t7AWqVa4LN9nnbHzoerOVNTqKqo3wSC5MBpwILNjqWeSxLHMd7LNw8gfrhlerolEg0ZY7C-2FtycMGUmb7WuAPM300nZ6dVYO6yLlfV9TQoNcp5oL5zBQ-bEw1NFVqleHuaElSqegAFtMXnhlE8-wynphjJeLK6MjdX4wV0_ONto4O37NyS3zTF0DIZFtAIKIMs-O_CuuCjSQ4G6QX3iWWcrL1wocHD5iA93DIO__vpPM56qcgH3X8tJcKkaOtjVONXm4DwSAYL_qah-D2jbl_IXM_UTlAMDXAnez3SJ9lVCgCaoPWdbxOYvwnt0pOp61MzXH0VlOuES_lb1onR4Oj3Pzeb1NG87-QfQTyucc0mELj3XA7xplul4pWrR6b06BdSoIX5xqeme2dftiKERrvqs18znxefX8t1x8tTUH2jTS1E_EiENlkzl5l0WZ5PJ2EHFkFzied7pntoKUuFV60IuxJcdkrQU7nFOwq4Iym0dOsL8zDSp96_dDhZ2RzJ-3-Ks-KY-w_KJ0ULjti036EZ7h7MgJ2A-RhNEfoJQ7jTy7DHw4rQ1_2bmDmq745u1jB1BoO58hR_97xmMb3uZWZZuazisQl6tDaVsndOEzxVxRPz-1fzPv-gZySpCBgAA.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQi1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZXhwIjoxNzE1MjQ1NTY2LCJhbGxvd0FjY2Vzc092ZXJQdWJsaWNJbnRlcm5ldCI6dHJ1ZX0=',
    // id: 'a2ff6343-972d-4c88-b8c5-fd5fe8e215bb,
    //id: '7aa6037c-f463-4632-966c-84553f5fc6b1',
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false
        }
      },
      background: models.BackgroundType.Transparent,
    }
  };

  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;
  eventHandlersMap = new Map([
    [
      'loaded',
      () => {
        const report = this.reportObj.getReport();
        report.setComponentTitle('Embedded report');
      },
    ],
    ['rendered', () => console.log('Report has rendered')],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => ''],
  ]) as Map<
    string,
    (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null
  >;
  allPatientsEmbedReports: any[];

  constructor(public http: HttpClient, private reportService: ReportsService, private httpService: HttpService) { }

  ngOnInit() {
    // this.generateToken();
    this.reportEmbed();
  }

  public reportEmbed(): void {
    const action = 'pbi/getEmbedInfo';
    this.allPatientsEmbedReports = [];
    const qReportName = 'Utilization Insights Aging';
    const qParams = `?reportName=${qReportName}`
    this.reportService?.makeGetRequest(action, qParams)?.subscribe((data: any) => {
      if (data && data?.responseObject && data?.responseObject?.embedReports) {
        data?.responseObject?.embedReports?.forEach(element => {
          element.accessToken = data?.responseObject?.embedToken;
        });
        this.allPatientsEmbedReports = data?.responseObject?.embedReports || [];
        // this.reportConfig = {
        //   ...this.reportConfig,
        //   embedUrl: data?.embedReports[0]?.embedUrl,
        //   accessToken: data?.embedToken,
        //   id: data?.embedReports[0]?.id
        // }
        console.log(this.allPatientsEmbedReports);
      }
    });
  }

  // get the resport
  public reportEmbedUi(report: any): IReportEmbedConfiguration {
    return this.reportConfig = { ...report, ...this.reportConfig };
  }

  // Ganerate token
  public generateToken(): void {
    //     clientID: 116ee5ca-7406-4c45-8f98-e5bb462cc58c
    // tenantId: 8ba5d408-116d-4a0f-88a0-427e275972b4
    // client Secret: 2e358a07-919d-44ad-b921-988720eb92f0
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    }
    const params = new URLSearchParams();
    params.set('client_id', 'ef2d63e2-74b7-4af0-a4ba-fa5364c946b0');
    params.set('scope', 'https://analysis.windows.net/powerbi/api/.default');
    params?.set('client_secret', '3Kn8Q~loQawI6uewgRaJgNXRKrklZmYS.SIz9c7d');
    params?.set('grant_type', 'client_credentials');
    // const paramsBody = {
    //   client_id: '116ee5ca-7406-4c45-8f98-e5bb462cc58c',
    //   scope: 'https://analysis.windows.net/powerbi/api/.default',
    //   client_secret: '2e358a07-919d-44ad-b921-988720eb92f0',
    //   grant_type: 'client_credentials'
    // }
    const action = `https://login.microsoftonline.com/8ba5d408-116d-4a0f-88a0-427e275972b4/oauth2/token`
    this.http.post(action, params, headers)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      )?.subscribe((data: any) => {
        console.log(data);
      }, error => {
        console.log(error);
      })
  }
}
