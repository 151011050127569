import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-exam',
  templateUrl: './review-exam.component.html',
  styleUrls: ['./review-exam.component.scss']
})
export class ReviewExamComponent {

  @Input() examData: any;

  public checkIsValuesPresent(examSec: any): boolean {
    let isValueCheck: boolean = false;
    examSec?.answers?.forEach((answerItem: any) => {
      if (answerItem?.actualValue && answerItem?.actualValue?.length > 0) {
        isValueCheck = true;
      }
    });
    return isValueCheck;
  }

}
