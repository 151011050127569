<app-ui-modal #eligibilityCheck [hideHeader]="false" id="eligibility-check-info">
    <div class="app-modal-header">
        <h5 class="modal-title">{{memberInfo?.lastName}}, {{memberInfo?.firstName }} Eligibility Check</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="eligibilityCheck.hide()"><span aria-cancelFormhidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="eligibility-check">
            <!-- <table>
                <thead>
                    <th>Member Information</th>
                    <th>Status</th>
                    <th>Plan Information</th>
                    <th>Co-Pay</th>
                </thead>
                <tbody>
                    <td>
                        <div class="memeber-info">
                            <p><b>Name</b>: {{memberInfo?.firstName}} {{memberInfo?.middleName}} {{memberInfo?.lastName}}</p>
                            <p><b>Member ID</b>: {{memberInfo?.memberId}}</p>
                            <p><b>DOB</b>: {{memberInfo?.dateOfBirth}}</p>
                            <p><b>Relationship</b>: {{memberInfo?.entityIdentifier}}</p>
                        </div>
                    </td>
                    <td>{{planInfo?.coverageStatus}}</td>
                    <td>
                        <div class="plan-info">
                            <p><b>Plan Name</b>: {{planInfo?.planname}}</p>
                            <p><b>Plan Type</b>: {{planInfo?.planType || 'N/A'}}</p>
                            <p><b>Plan Duration</b>: {{planInfo?.coverageBegin}} - {{planInfo?.coverageEnd}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="co-pay">
                            <p *ngIf="planInfo?.benefits?.length === 0" class="mb-2"><b>No Co-pay charges for the Patient Insurance</b></p>
                        </div>
                    </td>
                </tbody>
            </table> -->
            
            <!-- <hr /> -->
            <div class="row">
                <div class="memeber-info col-lg-12 col-md-12 col-sm-12">
                    <h5 class="mb-2 head-color">Member Information</h5>
                    <div><b>Name</b>: {{memberInfo?.lastName}}, {{memberInfo?.firstName }} {{memberInfo?.middleName}}</div>
                    <div><b>Member ID</b>: {{memberInfo?.memberId}}</div>
                    <div><b>DOB</b>: {{dateFormate(memberInfo?.dateOfBirth) || memberInfo?.dateOfBirth}}</div>
                    <div><b>Relationship</b>: {{memberInfo?.entityIdentifier}}</div>
                </div>
            </div>
            
            <hr />
            <div class="row">
                <div class="plan-info col-lg-12 col-md-12 col-sm-12">
                    <h5 class="mb-2 head-color">Plan Information</h5>
                </div>
            </div>
            
            <ng-container *ngFor="let plan of planInfo">

                <div class="row">
                    <div class="plan-info col-lg-12 col-md-12 col-sm-12">
                        <div><b>Plan Name</b>: {{plan?.planname || 'N/A'}}</div>
                        <!-- <div><b>Plan Type</b>: {{plan?.planType || 'N/A'}}</div> -->
                        <div><b>Plan Duration</b>: {{dateFormate(plan?.coverageBegin) || plan?.coverageBegin}} - {{dateFormate(plan?.coverageEnd) || plan?.coverageEnd}}</div>
                    </div>
                    <div class="status mt-2 col-lg-12 col-md-12 col-sm-12">
                        <h5 class="mb-2 head-color">Eligibility Status</h5>
                        {{plan?.coverageStatus}}
                    </div>
                </div>

                <hr />
                <div class="row">
                    <div class="co-pay col-lg-12 col-md-12 col-sm-12">
                        <h5 class="mb-2 head-color">Co-Insurance Details</h5>
                        <div *ngIf="getBenefitsByCode('A', plan?.benefits)?.length === 0" class="mb-2"><b>No Co-Insurance for the Patient Insurance</b></div>
                        <!-- If have Benifits -->
                        <div class="table" *ngIf="getBenefitsByCode('A', plan?.benefits)?.length > 0">
                            <div class="table-responsive">
                                <table 
                                    class="table table-striped mb-2 table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <!-- <th>Code</th> -->
                                            <th>Type</th>
                                            <th>Coverage Level</th>
                                            <!-- <th>Coverage Level Code</th> -->
                                            <th>Time Qualifier Code</th>
                                            <th>Benefit Percent / Amount</th>
                                            <!-- <th></th> -->
                                            <th>Network Indicator</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let benefit of getBenefitsByCode('A', plan?.benefits)">
                                            <tr>
                                                <!-- <td>{{benefit?.code || '--'}}</td> -->
                                                <td>{{benefit?.name || '--'}}</td>
                                                <td>{{benefit?.coverageLevel || '--'}}</td>
                                                <!-- <td>{{benefit?.coverageLevelCode || '--'}}</td> -->
                                                <td>{{benefit?.timeQualifier || '--'}}</td>
                                                <!-- benefit percent -->
                                                <td>
                                                    <ng-container *ngIf="benefit?.benefitPercent">
                                                        {{benefit?.benefitPercent * 100}} %
                                                    </ng-container>
                                                    <ng-container *ngIf="benefit?.benefitAmount">
                                                        ${{benefit?.benefitAmount}}
                                                    </ng-container>
                                                </td>
                                                <!-- network indicator -->
                                                <td>{{(benefit?.inPlanNetworkIndicator === 'Yes' ? 'In Plan Network' : 'Out of Network') || '--'}}</td>
                                                
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Co-Pay -->
                <div class="row mt-3">
                    <div class="co-pay col-lg-12 col-md-12 col-sm-12">
                        <h5 class="mb-2 head-color">Co-Pay Details</h5>
                        <div *ngIf="getBenefitsByCode('B', plan?.benefits)?.length === 0" class="mb-2"><b>No Co-Pay for the Patient Insurance</b></div>
                        <!-- If have Benifits -->
                        <div class="table" *ngIf="getBenefitsByCode('B', plan?.benefits)?.length > 0">
                            <div class="table-responsive">
                                <table 
                                    class="table table-striped mb-2 table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <!-- <th>Code</th> -->
                                            <th>Type</th>
                                            <th>Coverage Level</th>
                                            <!-- <th>Coverage Level Code</th> -->
                                            <th>Time Qualifier Code</th>
                                            <th>Benefit Percent / Amount</th>
                                            <!-- <th></th> -->
                                            <th>Network Indicator</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let benefit of getBenefitsByCode('B', plan?.benefits)">
                                            <tr>
                                                <!-- <td>{{benefit?.code || '--'}}</td> -->
                                                <td>{{benefit?.name || '--'}}</td>
                                                <td>{{benefit?.coverageLevel || '--'}}</td>
                                                <!-- <td>{{benefit?.coverageLevelCode || '--'}}</td> -->
                                                <td>{{benefit?.timeQualifier || '--'}}</td>
                                                <!-- benefit percent -->
                                                <td>
                                                    <ng-container *ngIf="benefit?.benefitPercent">
                                                        {{benefit?.benefitPercent * 100}} %
                                                    </ng-container>
                                                    <ng-container *ngIf="benefit?.benefitAmount">
                                                        ${{benefit?.benefitAmount}}
                                                    </ng-container>
                                                </td>
                                                <!-- network indicator -->
                                                <td>{{(benefit?.inPlanNetworkIndicator === 'Yes' ? 'In Plan Network' : 'Out of Network') || '--'}}</td>
                                                
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Deductible -->
                <div class="row mt-3">
                    <div class="co-pay col-lg-12 col-md-12 col-sm-12">
                        <h5 class="mb-2 head-color">Deductible Details</h5>
                        <div *ngIf="getBenefitsByCode('C', plan?.benefits)?.length === 0" class="mb-2"><b>No Deductible for the Patient Insurance</b></div>
                        <!-- If have Benifits -->
                        <div class="table" *ngIf="getBenefitsByCode('C', plan?.benefits)?.length > 0">
                            <div class="table-responsive">
                                <table 
                                    class="table table-striped mb-2 table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <!-- <th>Code</th> -->
                                            <th>Type</th>
                                            <th>Coverage Level</th>
                                            <!-- <th>Coverage Level Code</th> -->
                                            <th>Time Qualifier Code</th>
                                            <th>Benefit Percent / Amount</th>
                                            <!-- <th></th> -->
                                            <th>Network Indicator</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let benefit of getBenefitsByCode('C', plan?.benefits)">
                                            <tr>
                                                <!-- <td>{{benefit?.code || '--'}}</td> -->
                                                <td>{{benefit?.name || '--'}}</td>
                                                <td>{{benefit?.coverageLevel || '--'}}</td>
                                                <!-- <td>{{benefit?.coverageLevelCode || '--'}}</td> -->
                                                <td>{{benefit?.timeQualifier || '--'}}</td>
                                                <!-- benefit percent -->
                                                <td>
                                                    <ng-container *ngIf="benefit?.benefitPercent">
                                                        {{benefit?.benefitPercent * 100}} %
                                                    </ng-container>
                                                    <ng-container *ngIf="benefit?.benefitAmount">
                                                        ${{benefit?.benefitAmount}}
                                                    </ng-container>
                                                </td>
                                                <!-- network indicator -->
                                                <td>{{(benefit?.inPlanNetworkIndicator === 'Yes' ? 'In Plan Network' : 'Out of Network') || '--'}}</td>
                                                
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Out of Pocket (Stop Loss) -->
                <div class="row mt-3 mb-4">
                    <div class="co-pay col-lg-12 col-md-12 col-sm-12">
                        <h5 class="mb-2 head-color">Out of Pocket (Stop Loss) Details</h5>
                        <div *ngIf="getBenefitsByCode('G', plan?.benefits)?.length === 0" class="mb-2"><b>No Out of Pocket (Stop Loss) for the Patient Insurance</b>
                        <hr>
                        </div>
                        <!-- If have Benifits -->
                        <div class="table" *ngIf="getBenefitsByCode('G', plan?.benefits)?.length > 0">
                            <div class="table-responsive">
                                <table 
                                    class="table table-striped mb-2 table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <!-- <th>Code</th> -->
                                            <th>Type</th>
                                            <th>Coverage Level</th>
                                            <!-- <th>Coverage Level Code</th> -->
                                            <th>Time Qualifier Code</th>
                                            <th>Benefit Percent / Amount</th>
                                            <!-- <th></th> -->
                                            <th>Network Indicator</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let benefit of getBenefitsByCode('G', plan?.benefits)">
                                            <tr>
                                                <!-- <td>{{benefit?.code || '--'}}</td> -->
                                                <td>{{benefit?.name || '--'}}</td>
                                                <td>{{benefit?.coverageLevel || '--'}}</td>
                                                <!-- <td>{{benefit?.coverageLevelCode || '--'}}</td> -->
                                                <td>{{benefit?.timeQualifier || '--'}}</td>
                                                <!-- benefit percent -->
                                                <td>
                                                    <ng-container *ngIf="benefit?.benefitPercent">
                                                        {{benefit?.benefitPercent * 100}} %
                                                    </ng-container>
                                                    <ng-container *ngIf="benefit?.benefitAmount">
                                                        ${{benefit?.benefitAmount}}
                                                    </ng-container>
                                                </td>
                                                <!-- network indicator -->
                                                <td>{{(benefit?.inPlanNetworkIndicator === 'Yes' ? 'In Plan Network' : 'Out of Network') || '--'}}</td>
                                                
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>
            
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="eligibilityCheck.hide()" id="ok1">Ok</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>