import { AfterViewInit, Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { ViewportScroller, formatDate, DatePipe } from "@angular/common";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ScheduleFollowupAppointmentComponent } from '../schedule-followup-appointment/schedule-followup-appointment.component';
import { FollowupNotificationComponent } from '../followup-notification/followup-notification.component';
import { FollowupNotesComponent } from '../followup-notes/followup-notes.component';
import { ReScheduleAppointmentComponent } from '../re-schedule-appointment/re-schedule-appointment.component';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-followupqueue-list',
  templateUrl: './followupqueue-list.component.html',
  styleUrls: ['./followupqueue-list.component.scss']
})
export class FollowupqueueListComponent implements OnInit {

  @ViewChild(ScheduleFollowupAppointmentComponent) public scheduleFollowup: ScheduleFollowupAppointmentComponent;
  @ViewChild(FollowupNotificationComponent) public followupNotify: FollowupNotificationComponent;
  @ViewChild(FollowupNotesComponent) public followupNotes: FollowupNotesComponent;
  @ViewChild(ReScheduleAppointmentComponent) reScheduleComp: ReScheduleAppointmentComponent;
  public followupList = [];
  public openFilters: boolean = false;

  public notifyText: string;
  @ViewChild(ExceptionModalComponent) public exceptionModal: ExceptionModalComponent;
   @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;

  @ViewChild(DataTableDirective, { static: false })
  dtElementFollowup: DataTableDirective;
  public dtOptionsFollowup: DataTables.Settings = {};
  public dtTriggerFollowup: Subject<any> = new Subject<any>();
  public loginDetails: any;
  public purposeOfVisitList = [];
  public typeList = ["Wait List", "Follow Up"];
  public scheduleType: any;
  public selectedAppointmentInfo: any;
  public selectedMode: any;
  public followupQueueAppt: boolean = true; 
  public filterEnabled: boolean = false;
  public counterInfo: any;

  @ViewChild('followupCard') followupCard: CardComponent;
  @Input() queueCard: CardComponent;
  public selectedAppointmentId: any;
  public filtersForm: FormGroup;
    
  public openActions: boolean = false;
  public enableActions: boolean = false;
  public selectedActionRow: any;
  
   @HostListener('document:click', ['$event'])
    clickInside(event) {
	
     const eventId = (event.target as HTMLInputElement).id;
	 if(!this.enableActions && eventId !== 'claimActions_btn'){
	     this.openActions = false;
	  }	
  }

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router, private activateRoute: ActivatedRoute) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.getPurposeOfVisit(this.loginDetails && this.loginDetails.facilityId);
	
	 this.filtersForm = this.formBuilder.group({
      'purposeOfVisit': [null],
	  'sourceType': [null],
	  'followupDate': [null]
    });

  }
  
   callQueryParams(): void {

    this.activateRoute?.queryParams?.subscribe(
      (params: Params) => {
        if (params && params['type']) {
          this.filtersForm?.get('sourceType')?.setValue(params['type']);
		  this.openFilters = true;
          
        }
	 

      });
    this.getSearchList();
  }

  ngOnInit(): void {

    this.dtOptionsFollowup = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      //lengthChange: false,
      lengthMenu: [10, 25, 50, 75, 100],
      autoWidth: true,
      order: [[5, "desc"]],
       columnDefs: [{ targets: [5], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
	
	this.followupQueueAppt = true;


  }

  ngAfterViewInit(): void {
   // this.getFollowupQueueList();
   this.callQueryParams();
   this.getCareGapsCounters();
  }

  public rerenderFollowup(): void {
    this.dtElementFollowup?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }
	public getUserInfoDetails(userInfoId): void {
		const action = `common/getChartInfo?userInfoId=${userInfoId}&chartModuleName=UserInfo`;
		this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
		  if (data?.status === 'SUCCESS') {
			const userInfo = data?.responseObject?.userInfo || null;
			sessionStorage.setItem('establishedUserInfo', JSON.stringify(userInfo));
			this.router.navigate(['/hospital/addAppt']);
		  }
		},
		(error) => {
		   
		});
    }

  public openScheduleFollowup(existingAppnt: any) {
  const userInfoId = existingAppnt?.userInfoId;
  this.getUserInfoDetails(userInfoId);
  }

  public openNotifyFollowup(appointment: any) {
   /* this.selectedAppointmentId = appointmentId;
	this.selectedMode = "notify";
	this.getAppointmentById(this.selectedMode);*/
	this.sendNotifyComp?.showNotifyModal(appointment);

  }
  
  public openRescheduleFollowup(appointmentId: any): void {
    
	this.selectedAppointmentId = appointmentId;
	this.selectedMode = "reschedule";
	this.getAppointmentById(this.selectedMode);
  
  }
  public getAppointmentById(mode): void { 
    if (!this.selectedAppointmentId) {
      return;
    }
    let action: any = 'appointment/registration/getAppointmentById?id=';
    let params: any = {
      id: this.selectedAppointmentId,
    };
   
    
    this.httpService.getPatientsByFacilityId(action, params.id).subscribe(
      (data: any) => {
        if (data.status == 'SUCCESS') {
          
          this.selectedAppointmentInfo = { ...data?.responseObject } || null;
		   this.reScheduleComp.openSetAppointment(this.selectedAppointmentInfo);
		  
		  if(mode === 'reschedule'){ 
		     this.reScheduleComp.openSetAppointment(this.selectedAppointmentInfo);
		  }
		  /*else if(mode === 'notify')
		  { 
		  this.sendNotifyComp?.showNotifyModal(this.selectedAppointmentInfo);
		  }
		  */
          
        }
        
      },
      (error) => {
        
      }
    );
  }

  public openNotesFollowup(followup) {
  this.selectedAppointmentInfo = followup;
    this.followupNotes?.openModal(followup);
	
  }

  public openFilterSection() {
    this.openFilters = !this.openFilters;
  }
  
  public enableFilters(event: any){
  
  if(event !== null && event !== ''){
     this.filterEnabled = true;
  }
  else{
    this.filterEnabled = false;
  }
  
  }

  public closeFiltersection() {
    this.openFilters = false;
  }
  
  public clearFilters() {
  
  this.filtersForm.reset();
  /*this.filterEnabled = false;
  this.refresh();
  this.closeFiltersection();*/
  
  
  }

  public getPurposeOfVisit(facilityId: any): void {
    const action = `waitTime/`;
    this.httpService.makeGetRequest(action, facilityId).subscribe((data: any) => {

      this.purposeOfVisitList = (data && data.purposeOfVisit) || [];

    },
      error => {
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();
      })
  }
  
  public refresh(): void {
    
    //this.followupList = [];
   // this.rerenderFollowup();
   

	//   this.getFollowupQueueList();
	this.getSearchList();
    
  }


  public getFollowupQueueList() {

    const action = `careGaps/searchCaregapsTransactions`;
    this.rerenderFollowup();
    this.queueCard?.cardRefreshShow();

    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      console.log(data)
      this.followupList = (data && data.responseObject) || [];
      this.dtTriggerFollowup?.next();
      this.queueCard?.cardRefreshHide();
    },
      error => {
        this.queueCard?.cardRefreshHide();
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.dtTriggerFollowup?.next();
        this.exceptionModal.modalShow();

      })

  }
  
  public getSearchList() {
  const sourceType = this.filtersForm?.get('sourceType')?.value;
  const purposeOfVisit = this.filtersForm?.get('purposeOfVisit')?.value;
	
	const params = new URLSearchParams();
    const formValue = this.filtersForm.value; // this.form should be a FormGroup
	for (const key in formValue) {
      if (formValue[key] !== null && formValue[key] !== 'null' && formValue[key] !== '') {
        params.append(key, formValue[key]);
      }
    }
	let action = null;
	if (params) {
      action = `careGaps/searchCaregapsTransactions?${params}`;
    }
    else {
      action = `careGaps/searchCaregapsTransactions`;
    }
	
    this.rerenderFollowup();
    this.queueCard?.cardRefreshShow();

    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      console.log(data)
      this.followupList = (data && data.responseObject) || [];
      this.dtTriggerFollowup?.next();
      this.queueCard?.cardRefreshHide();
    },
      error => {
        this.queueCard?.cardRefreshHide();
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.dtTriggerFollowup?.next();
        this.exceptionModal.modalShow();

      })

  }


public getAppointmentTime(date: any): string {
    return this.httpService.getFolowupDate(date);
  }
   
  public displayDate(date: any): string {
  
	//return formatDate(date, 'MM/dd/yyyy', 'en_US');
	const dateArray = date?.split('T');
	const dateVal = dateArray[0];
	const splitDate = dateVal?.split('-');
	const dateConvert = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
    return `${dateConvert}`;
	
  }
  
 public getAppointmentTimeString(date: any): string {
  return this.httpService.getFolowupTime(date);
  }
  
  public getCareGapsCounters(){
   
   const action = `counter/getCareGapsCounters`;
   this.httpService.makeGetRequest(action,'').subscribe((data: any) => {
   this.counterInfo = (data) || null;
       
     },
       error => {
         
       })
   }
   
    public openUniversalActions(event: any, row) {
     if(this.selectedActionRow === row){
	 this.openActions = !this.openActions;
	 }
	 else{
	 this.openActions = true;
	 }
     
	 this.selectedActionRow = row;
	 
    }
    public closeUniversalActions() {
   
     this.openActions = false;
    }
  
    public mouseIn() {
      this.enableActions = true;
  
   }
  
   public mouseOut() {
	this.enableActions = false; 
	
  }

  
}
