<div id="patient-info-details">

    <h5 class="header">Demographics</h5>
    <hr />
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle"> First Name </label> <span class="label-colon"> :
                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label> {{ appointmentInfoDetails?.firstName || '--' |titlecase}}</label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle"> Middle Name </label> <span class="label-colon"> :
                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label> {{ appointmentInfoDetails?.middleName || '--' | titlecase}}</label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle"> Last Name </label> <span class="label-colon"> :
                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label> {{ appointmentInfoDetails?.lastName || '--' | titlecase}}</label>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle"> DOB </label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label> {{ appointmentInfoDetails?.dob || '--'}}</label>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle"> Gender </label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label> {{ appointmentInfoDetails?.gender || '--'}}</label>
                </div>
            </div>
        </div>


        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle"> Age </label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.age}}</label>
                </div>
            </div>
        </div>


        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle"> Marital Status </label> <span class="label-colon"> :
                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.maritalStatus || '--'}}</label>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Race</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.race || '--'}}</label>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Ethnicity</label> <span class="label-colon"> :
                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.ethnicity || '--'}}</label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.address?.street || '--'}}</label>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.address?.city || '--'}}</label>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.address?.state || '--'}}</label>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>
                        {{addPrefixZipCode(appointmentInfoDetails?.address?.zipCode,'') || '--'}}
                    </label>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Phone Number</label> <span class="label-colon"> :
                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.patientPrimaryPhone || '--'}}</label>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Email</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.email || '--'}}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- ID Documents -->
    <div class="row">
        <div class="col-sm-12">
            <h5>ID Documents</h5>
            <hr>
        </div>
    </div>
    <div class="row images-sec">
        <div class="col-lg-10 col-md-10 col-sm-12">
            <ng-container *ngIf="checkCommonIdDocument">
                <div class="row">
                    <div class="col-lg-10 col-md-10 col-sm-12">
                        <div class="row">
                            <div class="col-lg-5 col-md-5 col-sm-5 text-center">
                                <iframe id="dl-past-visit-card" width="100%" height="180px" frameBorder="0"></iframe>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="col-lg-5 col-md-5 col-sm-5 text-center mt-2 mb-3">
                                <button class="icon-queue-events signoff-action-bg clearfix" placement="bottom" ngbTooltip="Full View">
                                    <i class="las la-expand " (click)="downloaFullview(checkCommonIdDocument, insuraceCardModal)"></i>
                                </button>                        </div>
                        </div>
                    </div>
                </div>
                            
            </ng-container>
            <ng-container *ngIf="!checkCommonIdDocument">
                <div class="col-lg-10 col-md-10 col-sm-12">
                    <h5>ID Document is not available.</h5>
                </div>
            </ng-container>

        </div>
       
		
		<!--<app-upload-insurance-driving-docs [hideInsurance]="true" [patientDetailsInfo]="userInfoData"></app-upload-insurance-driving-docs>-->
    </div>

    <!-- Insurance Details -->
    <h5 class="header">Insurance Details</h5>
    <hr />
	<!--<div class="row">
	<app-common-insurance-addl  [idType]="'userId'" [userInfoDetails]="userInfoData"></app-common-insurance-addl>
	
	</div>-->
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6" style="padding-right: 0px;">
                    <label class="labelStyle">Coverage Type</label> <span class="label-colon"> :
                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label *ngIf="appointmentInfoDetails?.unInsured === true">Un-Insured</label>
                    <label *ngIf="appointmentInfoDetails?.selfPay === true">Self-Pay</label>
                    <label *ngIf="appointmentInfoDetails?.healthPass === true">Health Pass</label>
                    <label
                        *ngIf="appointmentInfoDetails?.unInsured === false && appointmentInfoDetails?.selfPay === false && appointmentInfoDetails?.healthPass === false">Insurance</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row insurance-items" *ngFor="let insurance of appointmentInfoDetails?.insuranceDetails; let i = index">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <h5 style="float: right;color: #296746;" *ngIf="insurance?.active">Active</h5>
            <h5 style="float: right;color: red" *ngIf="!insurance?.active">Inactive</h5>
        </div>
         <div class="col-lg-8 col-md-8 col-sm-8">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6"  style="padding-right: 0px;">
                            <label class="labelStyle">Insurance Name</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{insurance?.name || '--'}} </label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6"  style="padding-right: 0px;">
                            <label class="labelStyle">Member ID / Subscriber ID </label> 
                            <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6" >
                            <label>{{insurance?.memberId || '--'}} </label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6" style="padding-right: 0px;">
                            <label class="labelStyle">Payor ID</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{insurance?.payerId || '--'}} </label>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6"  style="padding-right: 0px;">
                            <label class="labelStyle">Group Number</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{insurance?.groupNumber || '--'}} </label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6"  style="padding-right: 0px;">
                            <label class="labelStyle">Coverage Sequence </label> 
                            <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6" >
                            <label>{{insurance?.priority || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6"  style="padding-right: 0px;">
                            <label class="labelStyle">Insurance Class</label> <span class="label-colon"> :
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{insurance?.insuranceClass || '--'}} </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="download-insurance-card mt-2" *ngIf="insurance?.insuranceCardImageId">
                <h5 class="download-insurance">
                   <!-- <span
                        (click)="downloadInsuranceCard(insurance?.insuranceCardImageId, insuraceCardPastVisitViewModal)">View/Download
                        Insurance Card <br /> <i class="las la-cloud-download-alt"></i></span>-->
					<ng-container>
                                    <div class="row">

                                       <div class="col-lg-12 col-md-12 col-sm-12  text-center">
                                            
											<iframe
                                                    id="{{insurance?.insuranceCardImageId}}_add_insu_card_display"
                                                    frameBorder="0" width="auto" height="auto"></iframe>
                                        </div>

                                        <div class="col-sm-12 text-center">
                                            <button class="icon-queue-events signoff-action-bg clearfix" placement="bottom" ngbTooltip="Full View">
                                                <i class="las la-expand " id="expand-full-view"
                                                    (click)="viewInsuranceCard(insurance?.insuranceCardImageId, insuraceCardPastVisitViewModal)"></i>
                                            </button>
                                            

                                        </div>
                                    </div>

                                </ng-container>
                </h5>
            </div>
            <div class="download-insurance-card mt-2" *ngIf="!insurance?.insuranceCardImageId">
                <h5 class="download-insurance">Insurance card not available</h5>
            </div>
        </div>
      
        <div class="row col-md-12">
            <div class="col-md-12">
                <h5 class="header">Subscriber Details</h5>       
                <hr />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">First Name</label> <span class="label-colon"> :
                        </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{insurance?.subscriberfirstname || '--' | titlecase}}
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Last Name</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{insurance?.subscriberlastname || '--' | titlecase}} </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">DOB</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{insurance?.subscriberdateOfBirth || '--'}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Gender</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{insurance?.subscribergender || '--'}} </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Relationship to <br> Subscriber</label> <span class="label-colon">
                            : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{insurance?.relationToSubscriber || '--'}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12">
            <div class="col-md-12">
                <h5 class="header" style="margin-top: 16px;">Employer Details</h5>
                <hr />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-3 col-md-3 col-sm-6">
                        <label class="labelStyle">Employer Name</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-6">
                        <label>{{insurance?.employerName || '--'}} </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{insurance?.employerAddress?.street || '--'}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{insurance?.employerAddress?.city || '--'}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{insurance?.employerAddress?.state || '--'}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>
                            {{addPrefixZipCode(insurance?.employerAddress?.zipCode,'') || '--'}}
        
                        </label>
                    </div>
                </div>
            </div>
        </div>

    </div>


    <!-- Emergency Contact Details section -->
    <div class="row">
        <div class="col-md-12">
            <h5 class="header">Emergency Contact Details</h5>
                <hr />
        </div>
    </div>
        
    <div class="row insurance-items" *ngFor="let contact of appointmentInfoDetails?.emergencyDetails">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Name</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label> {{contact?.name || '--'}} </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Relationship</label> <span class="label-colon"> :
                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label> {{contact?.relationship || '--'}} </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Emergency Contact</label> <span class="label-colon"> :
                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label> {{contact?.phone || '--'}} </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-4" *ngIf="!appointmentInfoDetails?.emergencyDetails || appointmentInfoDetails?.emergencyDetails?.length === 0">
        <div class="col-md-12">
            <span>Emergency Contact Details are unavailable for this patient</span>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h5 class="header">Billing Address Details</h5>
            <hr />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.billingAddress?.street || '--'}} </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.billingAddress?.city || '--'}} </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.billingAddress?.state || '--'}} </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Zipcode</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>
                        {{addPrefixZipCode(appointmentInfoDetails?.billingAddress?.zipCode,'') || '--'}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <!-- Preferred Pharmacy -->   
    <div class="row">
        <div class="col-md-12">
            <h5 class="header">Preferred Pharmacy  </h5>
            <hr />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Pharmacy Name</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.pharmacy?.name || '--'}} </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Phone Number</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.pharmacy?.phoneNumber || '--'}} </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Fax</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.pharmacy?.fax || '--'}} </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.pharmacy?.address?.street || '--'}}
                    </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.pharmacy?.address?.city || '--'}}
                    </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.pharmacy?.address?.state || '--'}}
                    </label>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>
                        {{addPrefixZipCode(appointmentInfoDetails?.pharmacy?.address?.zipCode,'') || '--'}}

                    </label>
                </div>
            </div>
        </div>
    </div>
	 
		
	<div class="row">
        <div class="col-md-12">
            <h5 class="header">Preferred Language  </h5>
            <hr />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="labelStyle">Language for communication </label> <span class="label-colon"> : </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{appointmentInfoDetails?.preferredLanguage || '--'}} </label>
                </div>
            </div>
        </div>
</div>

<!-- Insurance Card -->
<app-ui-modal #insuraceCardPastVisitViewModal [hideHeader]="false" [modalCentered]="true"
    id="insuraceCardPastVisitViewModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Insurance Card</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardPastVisitViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="insurance-card-past-visit-multiple" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" id="ok1"
            data-dismiss="modal" (click)="insuraceCardPastVisitViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- insurance image shown -->
<app-ui-modal #insuraceCardModal [hideHeader]="false" [modalCentered]="true" id="insuraceCardModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Identity Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="uplolad-insurance-card-multiple" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" data-dismiss="modal" id="ok2"
            (click)="insuraceCardModal.hide()">Ok</button>
    </div>
</app-ui-modal>