import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb-custom',
  templateUrl: './breadcrumb-custom.component.html',
  styleUrls: ['./breadcrumb-custom.component.scss']
})
export class BreadcrumbCustomComponent implements OnInit {

  @Input() navigationList: any;
  @Input() noFlowBoardHome: boolean;
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public rouetFlowBoard(): void {
    this.router?.navigate(['/hospital/hosp-dashboard']);
  }

  public navigateBreadCrumb(routerPath: string,paramVal:object): void {
    if(!routerPath) {
      return;
    }
    this.router?.navigate([routerPath],{ queryParams: paramVal || null});
  }

}
