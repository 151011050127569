<app-card class="new-designed-card claim-submission" #claimSubmissionCard
    cardTitle="{{claimProcessingFlag}} Claim Submission" [options]="false">
    <h5 class="card-claims-info"><span class="Control-Number" *ngIf="correctVoidedClaim">Control Number:
            {{payerClaimControlNumber}}</span> &nbsp;&nbsp;<span *ngIf="correctVoidedClaim">({{this.correctVoidedClaim
            === 'corrected' ? 'Corrected' : 'Voided'}} Claim)</span></h5>
    <div class="claim-appointment-view" (click)="viewAppointmentDetails(viewCommonAppointmentDetails)"><i
            class="las la-eye"></i>&nbsp;View Encounter</div>
    <aw-wizard #wizard class="arc-wizard" navBarLocation="top" navBarLayout="large-filled-symbols">
        <!--  View Encounter -->
        <aw-wizard-step stepTitle="Patient Information">
            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep?.completed && !wizardStep?.selected">1</span>
                <i *ngIf="!wizardStep?.completed && wizardStep?.selected"
                    style="color:#fff;font-size: 20px;line-height: 42px;" class="las la-pen"></i>
                <i *ngIf="wizardStep?.completed" style="color: #fff;font-size: 20px;line-height: 42px;"
                    class="las la-check"></i>
            </ng-template>
            <div class="view-encounter">
                <app-encounter-view-insurance-edit [appointmentId]="appointmentId" [patientInformation]="claimAppointmentDetails" [claimsSubmissionCard]="claimSubCard" (callPrepareClaims)="getBillingProviderData()"></app-encounter-view-insurance-edit>
            </div>
            <div class="row">
                <div class="col-md-12 text-right">
                    <button class="btn btn-primary" awNextStep>Save & Continue</button>
                </div>
            </div>
        </aw-wizard-step>

        <!--  Provider Info -->
        <aw-wizard-step stepTitle="Provider Information">

            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep?.completed && !wizardStep?.selected">2</span>
                <i *ngIf="!wizardStep?.completed && wizardStep?.selected"
                    style="color:#fff;font-size: 20px;line-height: 42px;" class="las la-pen"></i>
                <i *ngIf="wizardStep?.completed" style="color: #fff;font-size: 20px;line-height: 42px;"
                    class="las la-check"></i>
            </ng-template>

            <div class="row" [formGroup]="billingProviderForm">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="form-group">
                        <label>Provider Type <span class="text-danger">*</span></label>
                        <ng-select placeholder="Add or Choose Provider Type" (change)="changeProviderType()"
                            [items]="providersList" formControlName="providerType" [addTag]="true"
                            bindLabel="providerType" bindValue="providerType"></ng-select>
                        <label class="required"
                            *ngIf="billingProviderForm?.get('providerType')?.hasError('required') && billingProviderForm?.get('providerType')?.touched">Provider
                            Type is required</label>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="provider-actions mt-4">
                        <button class="icon-queue-events edit-action-bg" (click)="addNewProviderType()"
                            placement="bottom" ngbTooltip="Add New Provider"><i class="las la-plus"></i></button>
                        <button class="icon-queue-events delete-action-bg"
                            (click)="deleteProviderType(billingProviderForm?.get('providerType')?.value)"
                            placement="bottom" ngbTooltip="Delete Selected Provider"><span
                                class="material-symbols-outlined">delete</span></button>
                    </div>
                </div>
            </div>
            <!-- *ngIf="billingProviderForm?.get('providerType')?.value" -->
            <ng-container>
                <div class="row">
                    <div class="col-sm-12">
                        <h5 class="title">Provider Information</h5>
                        <!-- <hr> -->
                    </div>
                </div>

                <div class="billing-provider">
                    <form [formGroup]="billingProviderForm">
                        <div class="row row-cols-5">
                            <div class="col">
                                <div class="form-group">
                                    <label>First Name <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="firstName"
                                        id="providerFirstName" placeholder="First Name">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('firstName')?.hasError('required') && billingProviderForm?.get('firstName')?.touched">First
                                        Name
                                        is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Last Name <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="lastName"
                                        id="providerLastName" placeholder="Last Name">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('lastName')?.hasError('required') && billingProviderForm?.get('lastName')?.touched">Last
                                        Name
                                        is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>NPI <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="npi"
                                        id="provider-npi" placeholder="Provider NPI">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('npi')?.hasError('required') && billingProviderForm?.get('npi')?.touched">NPI
                                        is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>State License Number <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text"
                                        formControlName="stateLicenseNumber" id="stateLicenseNumber"
                                        placeholder="State License Number">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('stateLicenseNumber')?.hasError('required') && billingProviderForm?.get('stateLicenseNumber')?.touched">State
                                        License Number
                                        is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Taxonomy Code <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="taxonomyCode"
                                        id="taxonomy-code" placeholder="Taxonomy Code">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('taxonomyCode')?.hasError('required') && billingProviderForm?.get('taxonomyCode')?.touched">Taxonomy
                                        Code
                                        is required</label>
                                </div>
                            </div>
                        </div>
                        <div class="contactInformationform row row-cols-5"
                            [formGroup]="billingProviderForm?.get('contactInformation')">
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <h5 class="title">Provider Contact Information</h5>
                                <!-- <hr /> -->
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Fax Number <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="faxNumber"
                                        id="faxNumber" mask="000-000-0000" placeholder="xxx-xxx-xxxx">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('contactInformation')?.get('faxNumber')?.hasError('required') && billingProviderForm?.get('contactInformation')?.get('faxNumber')?.touched">Fax
                                        Number is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Phone Number <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="phoneNumber"
                                        id="phoneNumber" mask="000-000-0000" placeholder="xxx-xxx-xxxx">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('contactInformation')?.get('phoneNumber')?.hasError('required') && billingProviderForm?.get('contactInformation')?.get('phoneNumber')?.touched">Phone
                                        Number
                                        is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="text" class="form-control input-text" formControlName="email"
                                        id="email" placeholder="Email">
                                    <span class="text-danger"
                                        *ngIf="billingProviderForm?.get('contactInformation')?.get('email')?.invalid && !billingProviderForm?.get('contactInformation')?.get('email').errors?.required">Please
                                        enter a valid Email</span>
                                </div>
                            </div>
                        </div>
                        <div class="addressform row row-cols-5" [formGroup]="billingProviderForm?.get('address')">
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <h5 class="title">Provider Address</h5>
                                <!-- <hr /> -->
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Service Location Address 1 <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" 
                                    autocorrect="off" autocapitalize="off" spellcheck="off" #searchClaimStreet formControlName="address1"
                                        id="Service-Location-Address1" placeholder="Service Location Address 1">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('address')?.get('address1')?.hasError('required') && billingProviderForm?.get('address')?.get('address1')?.touched">Service
                                        Location Address 1
                                        is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Service Location Address 2</label>
                                    <input type="text" class="form-control input-text" formControlName="address2"
                                        id="ServiceLocationAddress2" placeholder="Service Location Address 2">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('address')?.get('address2')?.hasError('required') && billingProviderForm?.get('address')?.get('address2')?.touched">ervice
                                        Location Address 2
                                        is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>City <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="city" id="city"
                                        placeholder="City">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('address')?.get('city')?.hasError('required') && billingProviderForm?.get('address')?.get('city')?.touched">City
                                        is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>State <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="state"
                                        id="state" placeholder="State">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('address')?.get('state')?.hasError('required') && billingProviderForm?.get('address')?.get('state')?.touched">State
                                        is required</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>ZIP Code <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="postalCode"
                                        id="zipcode" placeholder="ZIP Code">
                                    <label class="required"
                                        *ngIf="billingProviderForm?.get('address')?.get('postalCode')?.hasError('required') && billingProviderForm?.get('address')?.get('postalCode')?.touched">ZIP
                                        Code
                                        is required</label>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </ng-container>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <button type="button" class="btn btn-primary dash-actions" awPreviousStep><i
                        class="las la-angle-left"></i> &nbsp;Previous</button>
                    <div class="btn-group mt-10 float-right">
                        <button type="button" class="btn btn-primary dash-actions"
                            (click)="checkBillingProviderValid(showDependentModal)" awNextStep>Save & Continue</button>
                    </div>
                </div>
            </div>

        </aw-wizard-step>

        <!-- Subscriber Info Details -->
        <aw-wizard-step stepTitle="Subscriber Information" [canEnter]="billingProviderForm?.valid">
            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep?.completed && !wizardStep?.selected">3</span>
                <i *ngIf="!wizardStep?.completed && wizardStep?.selected"
                    style="color: #fff;font-size: 20px;line-height: 42px;" class="las la-pen"></i>
                <i *ngIf="wizardStep?.completed" style="color: #fff;font-size: 20px;line-height: 42px;"
                    class="las la-check"></i>
            </ng-template>
            <div class="row">
                <div class="col-sm-12">
                    <h5 class="title">Subscriber Details</h5>
                    <!-- <hr> -->
                </div>
            </div>
            <div class="billing-provider">
                <form [formGroup]="memberDetailsForm">
                    <div class="row row-cols-5">
                        <!-- <div class="col-lg-10 col-md-10 col-sm-10">
                            <div class="form-group">
                                <label>Enter Member Card ID <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" id="member-card-id"
                                    placeholder="Member Card ID">
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2">
                            <div class="form-group">
                                <button type="button"
                                    class="btn btn-primary dash-actions member-id-serach">Search</button>
                            </div>
                        </div> -->
                        <div class="col">
                            <div class="form-group">
                                <label>First Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" formControlName="firstName"
                                    id="first-name" placeholder="First Name">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('firstName')?.hasError('required') && memberDetailsForm?.get('firstName')?.touched">First
                                    Name is required</label>
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('firstName')?.invalid && !memberDetailsForm?.get('firstName')?.errors?.required">
                                    Please enter a valid First Name
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Last Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" formControlName="lastName"
                                    id="Last-name" placeholder="Last Name">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('lastName')?.hasError('required') && memberDetailsForm?.get('lastName')?.touched">Last
                                    Name is required</label>
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('lastName')?.invalid && !memberDetailsForm?.get('lastName')?.errors?.required">
                                    Please enter a valid Last Name</label>
                            </div>
                        </div>


                        <div class="col" [formGroup]="memberDetailsForm?.get('receiver')">
                            <div class="form-group">
                                <label>Organization Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" id="organizationName"
                                    formControlName="organizationName" placeholder="Organization Name">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('receiver')?.get('organizationName')?.hasError('required') && memberDetailsForm?.get('receiver')?.get('organizationName')?.touched">Organization
                                    Name
                                    is required</label>
                            </div>
                        </div>
                        <div class="col" [formGroup]="memberDetailsForm?.get('receiver')">
                            <div class="form-group">
                                <label>Claim Payor Id <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" id="zipcode"
                                    formControlName="tradingPartnerServiceId" placeholder="Claim Payor Id">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('receiver')?.get('tradingPartnerServiceId')?.hasError('required') && memberDetailsForm?.get('receiver')?.get('tradingPartnerServiceId')?.touched">Trading
                                    Partner Service Id
                                    is required</label>
                            </div>
                        </div>


                        <div class="col">
                            <div class="form-group">
                                <label>Member ID <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" formControlName="memberId"
                                    id="member-card-id" placeholder="Member ID">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('memberId')?.hasError('required') && memberDetailsForm?.get('memberId')?.touched">Member
                                    ID is required</label>
                            </div>
                        </div>
                        <!-- <div class="col">
                            <div class="form-group mt-4">
                                <label>Patient Account Number</label>
                                <input type="text" class="form-control input-text"
                                    formControlName="patientAccountNumber" id="PatientAccountNumber"
                                    placeholder="Patient Account Number">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('PatientAccountNumber')?.hasError('required') && memberDetailsForm?.get('PatientAccountNumber')?.touched">atient
                                    Account Number is required</label>
                            </div>
                        </div> -->
                        <div class="col">
                            <div class="form-group">
                                <label>Group Number</label>
                                <input type="text" class="form-control input-text" formControlName="groupNumber"
                                    id="groupNumber" placeholder="Group Number">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('groupNumber')?.hasError('required') && memberDetailsForm?.get('groupNumber')?.touched">Group
                                    Number is required</label>
                            </div>
                        </div>

                        <!-- <div class="col">
                            <div class="form-group">
                                <label>Policy Number</label>
                                <input type="text" class="form-control input-text" formControlName="policyNumber"
                                    id="policyNumber" placeholder="Policy Number">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('policyNumber')?.hasError('required') && memberDetailsForm?.get('policyNumber')?.touched">Policy
                                    Number is required</label>
                            </div>
                        </div> -->

                        <!-- <div class="col">
                            <div class="form-group">
                                <label>Middle Name</label>
                                <input type="text" class="form-control input-text" formControlName="middleName"
                                    id="Middle-name" placeholder="Middle Name">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('middleName')?.hasError('required') && memberDetailsForm?.get('middleName')?.touched">Middle
                                    Name is required</label>
                            </div>
                        </div> -->


                        <div class="col">
                            <div class="form-group">
                                <label>Date of Birth <span class="text-danger">*</span></label>
                                <input type="date" class="form-control input-text" id="dateOfBirth"
                                    formControlName="dateOfBirth" [min]="minDOB"
                                    [max]="maxAppointmentDate" (keyup.enter)="validateManualEnterdob($event, memberDetailsForm)" (mouseout)="validateManualEnterdob($event, memberDetailsForm)"
                                    (blur)="validateManualEnterdob($event, memberDetailsForm)" placeholder="Date of Birth">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('dateOfBirth')?.hasError('required') && memberDetailsForm?.get('dateOfBirth')?.touched">Date
                                    of Birth is required</label>
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('dateOfBirth')?.errors?.invalidDate && memberDetailsForm?.get('dateOfBirth')?.touched">Please enter valid Date Of Birth</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Gender <span class="text-danger">*</span></label>
                                <ng-select placeholder="Gender" [items]="claimSubmissionGenders"
                                    formControlName="gender" bindLabel="desc" autofocus bindValue="value">
                                </ng-select>
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('gender')?.hasError('required') && memberDetailsForm?.get('gender')?.touched">Gender
                                    is required</label>
                            </div>
                        </div>
                    </div>
                    <!-- Paper Claims related Payor address -->
                    <div class="payor-address row" [formGroup]="paperClaimsPayerAddress">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="checkbox checkbox-primary d-inline">
                                    <input class="form-check-input ml-1" type="checkbox" id="isPaperClaim"
                                        formControlName="isPaperClaim" (change)="paperClaimPayorAdress($event)">
                                    <label class="cr list-name-label ml-2" for="isPaperClaim">Paper Claim</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="paperClaimsPayerAddress?.get('isPaperClaim')?.value" class="addressForm row"
                        [formGroup]="paperClaimsPayerAddress">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5 class="title">Payor Address</h5>
                            <!-- <hr /> -->
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <app-claim-address [addressForm]="paperClaimsPayerAddress"></app-claim-address>
                        </div>
                    </div>

                    <div class="addressForm row" [formGroup]="memberDetailsForm?.get('address')">
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                            <h5 class="title">Subscriber Address</h5>
                            <!-- <hr /> -->
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <app-claim-address [addressForm]="memberDetailsForm?.get('address')"></app-claim-address>
                        </div>
                        <!-- <div class="col-lg-3 col-md-3 col-sm-12">
                            <div class="form-group">
                                <label>Street <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" formControlName="address1"
                                    id="Mailing-Address1" placeholder="Street">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('address')?.get('address1')?.hasError('required') && memberDetailsForm?.get('address')?.get('address1')?.touched">Address
                                    is required</label>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12">
                            <div class="form-group">
                                <label>City <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" id="city" formControlName="city"
                                    placeholder="City">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('address')?.get('city')?.hasError('required') && memberDetailsForm?.get('address')?.get('city')?.touched">City
                                    is required</label>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12">
                            <div class="form-group">
                                <label>State <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" id="state" formControlName="state"
                                    placeholder="State">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('address')?.get('state')?.hasError('required') && memberDetailsForm?.get('address')?.get('state')?.touched">State
                                    is required</label>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12">
                            <div class="form-group">
                                <label>ZIP Code <span class="text-danger">*</span></label>
                                <input type="text" class="form-control input-text" id="zipcode"
                                    formControlName="postalCode" placeholder="ZIP Code">
                                <label class="required"
                                    *ngIf="memberDetailsForm?.get('address')?.get('postalCode')?.hasError('required') && memberDetailsForm?.get('address')?.get('postalCode')?.touched">ZIP
                                    Code
                                    is required</label>
                            </div>
                        </div> -->
                    </div>

                    <div class="addressForm dependant-details" [formGroup]="dependantDetailsForm">
                        <div class="mt-2">
                            <h5 class="title">Dependent Details</h5>
                            <!-- <hr /> -->
                        </div>
                        <ng-container *ngIf="prepareClaimSubmissionData?.dependent">
                            <!-- Not a dependant check -->
                            <div class="payor-address row" [formGroup]="dependantCheckForm">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="checkbox checkbox-primary d-inline">
                                            <input class="form-check-input ml-1" (change)="updateNotaDependant($event)" type="checkbox" id="notDependent"
                                                formControlName="notDependent">
                                            <label class="cr list-name-label ml-2" for="notDependent">Not a Dependent</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- *ngIf="!dependantCheckForm?.get('notDependent')?.value" -->
                            <ng-container>
                                <div class="row row-cols-5">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>First Name <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control input-text" formControlName="firstName"
                                                id="first-name-dependent" placeholder="First Name">
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('firstName')?.hasError('required') && dependantDetailsForm?.get('firstName')?.touched">First
                                                Name is required</label>
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('firstName')?.invalid && !dependantDetailsForm?.get('firstName')?.errors?.required">
                                                Please enter a valid First Name</label>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Last Name <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control input-text" formControlName="lastName"
                                                id="Last-name-dependent" placeholder="Last Name">
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('lastName')?.hasError('required') && dependantDetailsForm?.get('lastName')?.touched">Last
                                                Name is required</label>
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('lastName')?.invalid && !dependantDetailsForm?.get('lastName')?.errors?.required">
                                                Please enter a valid Last Name</label>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Date of Birth <span class="text-danger">*</span></label>
                                            <input type="date" class="form-control input-text" id="dateOfBirth-dependent"
                                                formControlName="dateOfBirth" [min]="minDOB"
                                                [max]="maxAppointmentDate" (keyup.enter)="validateManualEnterdob($event, dependantDetailsForm)" (mouseout)="validateManualEnterdob($event, dependantDetailsForm)"
                                                (blur)="validateManualEnterdob($event, dependantDetailsForm)" placeholder="Date of Birth">
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('dateOfBirth')?.hasError('required') && dependantDetailsForm?.get('dateOfBirth')?.touched">Date
                                                of Birth is required</label>
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('dateOfBirth')?.errors?.invalidDate && dependantDetailsForm?.get('dateOfBirth')?.touched">Please enter valid Date Of Birth</label>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Gender <span class="text-danger">*</span></label>
                                            <ng-select placeholder="Gender" [items]="claimSubmissionGenders" formControlName="gender"
                                                bindLabel="desc" autofocus bindValue="value">
                                            </ng-select>
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('gender')?.hasError('required') && dependantDetailsForm?.get('gender')?.touched">Gender
                                                is required</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="addressForm row" [formGroup]="dependantDetailsForm?.get('address')">
                                    <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                        <h5 class="title">Dependent Address</h5>
                                        <!-- <hr /> -->
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <app-claim-address [addressForm]="dependantDetailsForm?.get('address')"></app-claim-address>
                                    </div>
                                    <!-- <div class="col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Street <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control input-text" formControlName="address1"
                                                id="Mailing-Address1-dependent" placeholder="Street">
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('address')?.get('address1')?.hasError('required') && dependantDetailsForm?.get('address')?.get('address1')?.touched">Address
                                                is required</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>City <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control input-text" id="city-dependent" formControlName="city"
                                                placeholder="City">
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('address')?.get('city')?.hasError('required') && dependantDetailsForm?.get('address')?.get('city')?.touched">City
                                                is required</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>State <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control input-text" id="state-dependent" formControlName="state"
                                                placeholder="State">
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('address')?.get('state')?.hasError('required') && dependantDetailsForm?.get('address')?.get('state')?.touched">State
                                                is required</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>ZIP Code <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control input-text" id="zipcode-dependent"
                                                formControlName="postalCode" placeholder="ZIP Code">
                                            <label class="required"
                                                *ngIf="dependantDetailsForm?.get('address')?.get('postalCode')?.hasError('required') && dependantDetailsForm?.get('address')?.get('postalCode')?.touched">ZIP
                                                Code
                                                is required</label>
                                        </div>
                                    </div> -->
                                </div>
                            </ng-container>
                        </ng-container>
                        <!-- <ng-container *ngIf="prepareClaimSubmissionData?.dependent">
                                                <div class="col-lg-3 col-md-3 col-sm-12">
                                                    <div class="form-group row">
                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                            <label class="labelStyle">First Name</label> <span class="label-colon"> :
                                                            </span>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                            <label>{{prepareClaimSubmissionData?.dependent?.firstName || '--'}} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-12">
                                                    <div class="form-group row">
                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                            <label class="labelStyle">Last Name</label> <span class="label-colon"> : </span>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                            <label>{{prepareClaimSubmissionData?.dependent?.lastName || '--'}} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-12">
                                                    <div class="form-group row">
                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                            <label class="labelStyle">Date of Birth</label> <span class="label-colon"> :
                                                            </span>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                            <label>{{dateFormate(prepareClaimSubmissionData?.dependent?.dateOfBirth) ||
                                                                '--'}} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-12">
                                                    <div class="form-group row">
                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                            <label class="labelStyle">Gender</label> <span class="label-colon"> : </span>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                            <label
                                                                *ngIf="prepareClaimSubmissionData?.dependent?.gender === 'M'">Male</label>
                                                            <label *ngIf="prepareClaimSubmissionData?.dependent?.gender === 'F'">Female
                                                            </label>
                                                            <label
                                                                *ngIf="prepareClaimSubmissionData?.dependent?.gender === 'U'">Other</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-9">
                                                    <div class="form-group row">
                                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                                            <label class="labelStyle">Address</label> <span class="label-colon"> : </span>
                                                        </div>
                                                        <div class="col-lg-10 col-md-10 col-sm-10">
                                                            <label>{{prepareClaimSubmissionData?.dependent?.address?.address1}},
                                                                {{prepareClaimSubmissionData?.dependent?.address?.address2}},
                                                                {{prepareClaimSubmissionData?.dependent?.address?.city}},
                                                                {{prepareClaimSubmissionData?.dependent?.address?.state}} -
                                                                {{prepareClaimSubmissionData?.dependent?.address?.postalCode}} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container> -->
                        <ng-container *ngIf="!prepareClaimSubmissionData?.dependent">
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <label class="text-center">No Dependent data available</label>
                            </div>
                        </ng-container>
                    </div>



                </form>
            </div>

            <div class="row mt-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <button type="button" class="btn btn-primary dash-actions" awPreviousStep><i
                            class="las la-angle-left"></i> &nbsp;Previous</button>
                    <div class="btn-group mt-10 float-right">
                        <button type="button" class="btn btn-primary dash-actions" (click)="checkValidMember()"
                            awNextStep>Save & Continue</button>
                    </div>
                </div>
            </div>
        </aw-wizard-step>

        <!-- Claim Info Codes -->
        <aw-wizard-step stepTitle="Claim Information"
            [canEnter]="memberDetailsForm?.valid && paperClaimsPayerAddress?.valid && (prepareClaimSubmissionData?.dependent && !dependantCheckForm?.get('notDependent')?.value ? dependantDetailsForm?.valid : true)">
            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep?.completed && !wizardStep?.selected">4</span>
                <i *ngIf="!wizardStep?.completed && wizardStep?.selected"
                    style="color: #fff;font-size: 20px;line-height: 42px;" class="las la-pen"></i>

                <i *ngIf="wizardStep?.completed" style="color: #fff;font-size: 20px;line-height: 42px;"
                    class="las la-check"></i>
            </ng-template>
            <div class="row primary-ins" *ngFor="let clmInfo of claimedInfo; let clmI = index">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h5><span *ngIf="clmInfo?.claimProcessorType">{{clmInfo?.claimProcessorType}}</span> Insurance Reimbursements
                    </h5>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 primary-ins-charges">
                    <span><b>Billed Charges :</b>
                        $ {{clmInfo?.costofCare?.totalVisitCharges | number : '1.2-2'}}&nbsp;&nbsp;<b>|</b>
                    </span>&nbsp;&nbsp;
                    <span> <b>Approved Amount :</b>
                        $ {{clmInfo?.costofCare?.allowedAmount | number : '1.2-2'}}&nbsp;&nbsp;<b>|</b>
                    </span>&nbsp;&nbsp;
                    <span> <b>Payor Payments :</b>
                        $ {{clmInfo?.costofCare?.payerPayment | number : '1.2-2'}}&nbsp;&nbsp;<b>|</b>
                    </span>&nbsp;&nbsp;
                    <span> <b>Co-Pay :</b>
                        $ {{clmInfo?.costofCare?.coPay | number : '1.2-2'}}
                    </span>
                </div>
            </div>

            <!-- Diagnosis Pointers -->
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-9">
                    <h5>Diagnosis Codes (ICD 10)</h5>
                    <!-- <hr> -->
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <button type="button" class="btn btn-primary blue-btn float-right" (click)="addDiagnosisCodes()">+
                        Add</button>
                </div>
            </div>

            <div class="diagnosis-codes mb-4">
                <form [formGroup]="claimSubmissionFrom">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 icdCode-Table">
                            <div class="new-designed-table" formArrayName="healthCareCodeInformation">
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Diagnosis Pointer</th>
                                            <th style="width: 35%;">Diagnosis Code</th>
                                            <th>Diagnosis Description</th>
                                            <th class="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let item of claimSubmissionFrom?.get('healthCareCodeInformation')?.controls; let diagnosisIndex = index;">
                                            <tr [formGroupName]="diagnosisIndex">
                                                <td class="text-center">
                                                    {{diagnosisIndex + 1}}
                                                </td>
                                                <td>

                                                    <ng-select
                                                        [class.validCheck]="item?.get('diagnosisCode')?.touched && item?.get('diagnosisCode')?.hasError('required')"
                                                        placeholder="Search Diagnosis Codes"
                                                        [items]="(allIcdCodesObservebale | async)?.responseObject"
                                                        bindLabel="description" [addTag]="false" [clearable]="false"
                                                        bindLabel="code" [multiple]="false" [hideSelected]="true"
                                                        [trackByFn]="trackByFn" [minTermLength]="3"
                                                        [closeOnSelect]="true" [loading]="icdCodesLoading"
                                                        typeToSearchText="Please enter 3 or more characters"
                                                        [typeahead]="diagnosisInput" formControlName="diagnosisCode"
                                                        (change)="diagnosisCodesValues($event, diagnosisIndex)"
                                                        clearAllText="Clear">

                                                        <!-- <ng-template ng-label-tmp let-item="item">
                                                            <span>{{item?.icdCodes?.code}}</span>
                                                        </ng-template> -->

                                                        <ng-template ng-option-tmp let-item="item" let-index="index"
                                                            let-search="searchTerm">
                                                            <div title="Select the option you want to add to the chart">
                                                                {{item?.code}} - {{item?.description}}</div>
                                                        </ng-template>

                                                        <!-- <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                            <div class="ng-value" *ngFor="let item of items | slice:0:2">
                                                                <span class="ng-value-label">{{item?.icdCodes?.code}} - {{item?.icdCodes?.description}}</span>
                                                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                                            </div>
                                                            <div class="ng-value" *ngIf="items.length > 2">
                                                                <span class="ng-value-label">{{items.length - 2}} more...</span>
                                                            </div>
                                                        </ng-template> -->

                                                    </ng-select>

                                                    <!-- <div class="input-group">
                                                        <input class="form-control py-2 border-right-0 border input-text" type="search" placeholder="Diagnosis Code"
                                                            formControlName="diagnosisCode" id="example-search-input">
                                                        <span class="input-group-append">
                                                            <button style="padding: 0px 10px; color: #5CA6DB;"
                                                                class="btn btn-outline-secondary border-left-0 border input-text" type="button">
                                                                <i class="fa fa-search"></i>
                                                            </button>
                                                        </span>
                                                    </div> -->
                                                    <!-- <input type="number" class="form-control input-text" id="DiagnosisCode"
                                                        placeholder="Diagnosis Code" formControlName="diagnosisCode"> -->
                                                </td>
                                                <td style="width: 32%;white-space: inherit;">
                                                    {{item?.value?.description}}
                                                    <!-- <input type="text" class="form-control input-text"
                                                        id="Diagnosis Description" placeholder="Diagnosis Description"
                                                        readonly formControlName="description"> -->
                                                </td>
                                                <td class="edit-delet-actions">
                                                    <button class="icon-queue-events delete-action-bg"
                                                        (click)="deleteDiagnosisCode(diagnosisIndex, item?.get('diagnosisCode')?.value)"
                                                        placement="bottom" ngbTooltip="Delete"><span
                                                            class="material-symbols-outlined">delete</span></button>

                                                    <!-- <div class="delete icon"
                                                        (click)="deleteDiagnosisCode(diagnosisIndex, item?.get('diagnosisCode')?.value)">
                                                        <i class="fa fa-trash-alt" aria-hidden="true"></i>&nbsp; Delete
                                                    </div> -->
                                                </td>
                                            </tr>
                                        </ng-container>

                                        <tr
                                            *ngIf="claimSubmissionFrom?.get('healthCareCodeInformation')?.value?.length === 0">
                                            <td class="text-center" colspan="8">No Diagnosis Codes are available. Please
                                                add at
                                                least one code for Claim Submission</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-9">
                    <h5>Claim Information</h5>
                    <!-- <hr> -->
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <button type="button" class="btn btn-primary blue-btn float-right" (click)="addCodes()">+
                        Add</button>
                </div>
            </div>

            <div class="diagnosis-codes mb-4">
                <form [formGroup]="claimSubmissionFrom">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="table-responsive new-designed-table" formArrayName="claimInformation">
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Diagnosis Pointer</th>
                                            <th>CPT/HCPCS Code<span class="make-space"></span></th>
                                            <th>CPT <br />Description</th>
                                            <th>Charges</th>
                                            <th>Place of <br />Service</th>
                                            <th>Days / Units</th>
                                            <th>Service<br /> Date</th>
                                            <th>IDENTIFIER</th>
                                            <th>Modifier</th>
                                            <th class="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let item of claimSubmissionFrom?.get('claimInformation')?.controls; let formi = index;">
                                            <tr [formGroupName]="formi">
                                                <!-- Diagnosis Pointer -->
                                                <td formGroupName="professionalService">
                                                    <!-- <input type="text" class="form-control input-text"
                                                        id="claimInformation" formControlName="diagnosisCodePointers"
                                                        placeholder="Diagnosis Pointer"> -->
                                                    <div class="pointer-group"
                                                        formGroupName="compositeDiagnosisCodePointers">
                                                        <ng-select
                                                            [class.validCheck]="item?.get('professionalService')?.get('compositeDiagnosisCodePointers')?.get('diagnosisCodePointers')?.touched && item?.get('professionalService')?.get('compositeDiagnosisCodePointers')?.get('diagnosisCodePointers')?.hasError('required')"
                                                            placeholder="Pointers" [multiple]="true"
                                                            formControlName="diagnosisCodePointers"
                                                            [items]="diagnosisPointersList" [closeOnSelect]="false"
                                                            clearAllText="Clear"></ng-select>
                                                    </div>

                                                </td>
                                                <!-- CPT code -->
                                                <td formGroupName="professionalService">
                                                    <ng-select
                                                        [class.validCheck]="item?.get('professionalService')?.get('procedureCode')?.touched && item?.get('professionalService')?.get('procedureCode')?.hasError('required')"
                                                        placeholder="Search CPT Codes/Description"
                                                        [items]="(allBillingCodes | async)?.responseObject"
                                                        [addTag]="false" bindLabel="cptCode" [multiple]="false"
                                                        [hideSelected]="true" [trackByFn]="trackByFnCptCodes"
                                                        [minTermLength]="2" [closeOnSelect]="true"
                                                        [loading]="billingCodesLoading"
                                                        typeToSearchText="Please enter 2 or more characters"
                                                        [typeahead]="billingInput" formControlName="procedureCode"
                                                        (change)="changeCptCodes($event, formi)" clearAllText="Clear">

                                                        <ng-template ng-option-tmp let-item="item" let-index="index"
                                                            let-search="searchTerm">
                                                            <div title="Select the option you want to add to the chart">
                                                                {{item?.cptCode}} - {{item?.codeDescription}}</div>
                                                        </ng-template>

                                                    </ng-select>

                                                    <!-- {{item?.get('professionalService')?.get('procedureCode')?.value}} -->

                                                    <!-- <div class="input-group">
                                                        <input
                                                            class="form-control py-2 border-right-0 border input-text"
                                                            type="search" placeholder="CPT" formControlName="procedureCode" id="example-search-input">
                                                        <span class="input-group-append">
                                                            <button style="padding: 0px 10px; color: #5CA6DB;"
                                                                class="btn btn-outline-secondary border-left-0 border input-text"
                                                                type="button">
                                                                <i class="fa fa-search"></i>
                                                            </button>
                                                        </span>
                                                    </div> -->
                                                </td>
                                                <!-- CPT description -->
                                                <td style="white-space: inherit;">
                                                    {{item?.get('professionalService')?.value?.description}}
                                                </td>

                                                <!-- Charges -->
                                                <td formGroupName="professionalService">
                                                    <div class="input-group" style="width: 160px;">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text input-text">$</span>
                                                        </div>
                                                        <input type="text" mask="separator.2"
                                                            [class.validCheck]="item?.get('professionalService')?.get('lineItemChargeAmount')?.touched && item?.get('professionalService')?.get('lineItemChargeAmount')?.hasError('required')"
                                                            class="form-control input-text" id="charges"
                                                            placeholder="Charges"
                                                            formControlName="lineItemChargeAmount" />
                                                    </div>

                                                    <!-- <input type="number" class="form-control input-text" id="charges"
                                                        placeholder="Charges" formControlName="lineItemChargeAmount"> -->
                                                </td>

                                                <!-- POS -->
                                                <td formGroupName="professionalService">
                                                    <!-- <ng-select placeholder="POS" formControlName="pos" [items]="allPos" bindLabel="value"
                                                        bindValue="value">
                                                    </ng-select> -->
                                                    <input type="text"
                                                        [class.validCheck]="item?.get('professionalService')?.get('placeOfServiceCode')?.touched && item?.get('professionalService')?.get('placeOfServiceCode')?.hasError('required')"
                                                        class="form-control input-text"
                                                        formControlName="placeOfServiceCode" id="POS" placeholder="POS">
                                                </td>
                                                <!-- days or units -->
                                                <td formGroupName="professionalService">
                                                    <input type="text"
                                                        [class.validCheck]="item?.get('professionalService')?.get('serviceUnitCount')?.touched && item?.get('professionalService')?.get('serviceUnitCount')?.hasError('required')"
                                                        class="form-control input-text" id="DaysorUnits"
                                                        placeholder="Days/Units" formControlName="serviceUnitCount">
                                                </td>
                                                <!-- Service Date -->
                                                <td class="date from-date">
                                                    <ng-container>
                                                        <input style="width: 170px;"
                                                            [class.validCheck]="item?.get('serviceDate')?.touched && item?.get('serviceDate')?.hasError('required')"
                                                            type="date" class="form-control input-text"
                                                            formControlName="serviceDate" id="to-date">
                                                    </ng-container>
                                                    <!-- <ng-container *ngIf="item?.value?.serviceDate">
                                                        {{dateFormate(item?.value?.serviceDate)}}
                                                    </ng-container> -->

                                                </td>
                                                <!-- <td>
                                                    <input type="date" class="form-control input-text" id="to-date">
                                                </td> -->

                                                <!-- Indentifier IDENTIFIER -->
                                                <td formGroupName="professionalService">
                                                    <input type="text"
                                                        [class.validCheck]="item?.get('professionalService')?.get('procedureIdentifier')?.touched && item?.get('professionalService')?.get('procedureIdentifier')?.hasError('required')"
                                                        class="form-control input-text" id="Identifier"
                                                        placeholder="Identifier" formControlName="procedureIdentifier">
                                                </td>

                                                <!-- Modifier -->
                                                <td formGroupName="professionalService">
                                                    <input type="text"
                                                        [class.validCheck]="item?.get('professionalService')?.get('procedureModifiers')?.touched && item?.get('professionalService')?.get('procedureModifiers')?.hasError('required')"
                                                        class="form-control input-text" id="Modifier"
                                                        placeholder="Modifier" formControlName="procedureModifiers">
                                                </td>

                                                <td class="edit-delet-actions">
                                                    <!-- <div class="delete icon" (click)="deleteCode(formi)"><i
                                                            class="fa fa-trash-alt" aria-hidden="true"></i>&nbsp; Delete
                                                    </div> -->
                                                    <button class="icon-queue-events edit-action-bg"
                                                        (click)="addDrugIdentification(drugIdentificationDetails, item)"
                                                        placement="bottom" ngbTooltip="NDC"><span
                                                            class="material-symbols-outlined">
                                                            medication
                                                        </span></button>

                                                    <button class="icon-queue-events delete-action-bg"
                                                        (click)="deleteCode(formi)" placement="bottom"
                                                        ngbTooltip="Delete"><span
                                                            class="material-symbols-outlined">delete</span></button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <tr *ngIf="claimSubmissionFrom?.get('claimInformation')?.value?.length > 0">
                                            <td class="text-right" colspan="4">
                                                <div class="input-group float-right" style="width: 42%;">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text input-text">Total Charges &nbsp;
                                                            &nbsp;$</span>
                                                    </div>
                                                    <input type="number" name="total-charges" id="totalcharges"
                                                        class="form-control input-text" value="{{totalChargesCount()}}"
                                                        disabled placeholder="0.00" />
                                                </div>
                                                <!-- <input type="text" class="form-control float-right input-text"
                                                    style="width: auto;" id="totalcharges" value="$ {{totalChargesCount()}}" disabled></td> -->
                                        </tr>
                                        <tr *ngIf="claimSubmissionFrom?.get('claimInformation')?.value?.length === 0">
                                            <td class="text-center" colspan="10">No Codes are available. Please add at
                                                least one code for Claim Submission</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-4" [formGroup]="claimAdditonalNotesForm">
                    <div class="form-group">
                        <label class="additional-notes">Additional Notes</label>
                        <textarea type="text" class="form-control" formControlName="additionalInformation"
                            id="additionalNotes" placeholder="Additional Notes"></textarea>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;"
                        awPreviousStep><i class="las la-angle-left"></i> &nbsp; Previous</button>
                    <!-- Normal Claims validation  and Submit -->
                    <div class="btn-group mt-10 float-right" *ngIf="!isExternalClaims">
                        <button type="button" style="margin-left: 45px;margin-right: 15px;"
                            class="btn btn-primary dash-actions"
                            (click)="claimsValidationCalls(claimValidationModal, false)">Claim Validation</button>
                        <button type="button" class="btn btn-primary dash-actions"
                            (click)="claimsSubsmissionCalls(claimSubmitModal)"><i class="las la-check-double"></i>&nbsp;Submit
                            Claim</button>
                    </div>
                    <!-- For External Cliam sValidation -->
                    <div class="btn-group mt-10 float-right" *ngIf="isExternalClaims">
                        <button type="button" class="btn btn-primary dash-actions"
                            (click)="claimsValidationCalls(claimValidationModal, true)"><i
                                class="las la-check-double"></i>&nbsp;Submit Claim</button>
                    </div>
                </div>
            </div>
        </aw-wizard-step>
    </aw-wizard>
</app-card>
<!-- awResetWizard (finalize)="finalizeReset()" -->
<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"
    [requiredFields]="allClaimInformationInvalidaFields"></app-exception-modal>

<!-- notify popup -->
<app-ui-modal #claimSubmitModal [hideHeader]="false" [modalCentered]="true" id="claimSubmitModal">
    <div class="app-modal-header" style="width: 100%;">
        <h5 class="modal-title text-center wordAdjustmentClaimSubmit">{{claimSubmitMessage}}</h5>
        <!-- <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="redirectHome(claimSubmitModal)"><span aria-hidden="true">&times;</span></button> -->
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="redirectHome(claimSubmitModal)">Ok</button>
    </div>
</app-ui-modal>

<!-- validation notify popup -->
<app-ui-modal #claimValidationModal [hideHeader]="false" [modalCentered]="true" id="claimValidationModal">
    <div class="app-modal-header" style="width: 100%;">
        <h5 class="modal-title text-center wordAdjustment">{{claimSubmitMessage}}</h5>
    </div>
    <div class="app-modal-body">
        <ul class="unstyled error-msgs-list">
            <li *ngFor="let error of claimErrorValidation">
                <span>{{error?.field}}</span>
                <span class="error-val"><i class="material-symbols-outlined">cancel</i>{{error?.description}}</span>
                <!-- <span class="warning-val"><i class="material-symbols-outlined">error</i> Item must not be blank value.</span> -->
            </li>
        </ul>
        <!-- <ul>
            <li class="mb-2" *ngFor="let error of claimErrorValidation">{{error?.field}} - <b>{{error?.description}}</b></li>
        </ul> -->
    </div>
    <div class="app-modal-footer">
        <button type="button" *ngIf="!isExternalClaims" class="btn btn-primary dash-actions" style="margin-right: 15px;"
            data-dismiss="modal" (click)="claimValidationModal?.hide();">Ok</button>
        <button type="button" *ngIf="isExternalClaims" class="btn btn-primary dash-actions" style="margin-right: 15px;"
            data-dismiss="modal" (click)="redirectHome(claimValidationModal)">Ok</button>
    </div>
</app-ui-modal>

<!-- View Appointment Details -->
<app-ui-modal #viewCommonAppointmentDetails [hideHeader]="false" [modalCentered]="true"
    id="viewCommonAppointmentDetails">
    <div class="app-modal-header">
        <h5 class="modal-title">Appointment Details</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="viewCommonAppointmentDetails.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <ng-container *ngIf="selectedViewAppointmentId">
            <app-commom-past-visit-details-wizard [appointmentId]="selectedViewAppointmentId" [noAccountBalanace]="true"
                (openNotifyModal)="sendNotification($event)"></app-commom-past-visit-details-wizard>
        </ng-container>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
            (click)="viewCommonAppointmentDetails.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- Drug Identification Details -->
<app-ui-modal #drugIdentificationDetails [hideHeader]="false" [modalCentered]="true" id="drugIdentificationDetails">
    <div class="app-modal-header">
        <h5 class="modal-title">Drug Identification</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="drugIdentificationDetails?.hide();removeValidations()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <ng-container *ngIf="drugIdentificationFormGroup">
            <div class="row" [formGroup]="drugIdentificationFormGroup">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">Service ID Qualifier <span class="text-danger">*</span></label>
                        <input class="form-control input-text" readonly type="text" placeholder="Service ID Qualifier"
                            id="first-name" formControlName="serviceIdQualifier" />
                        <span class="text-danger"
                            *ngIf="drugIdentificationFormGroup?.get('serviceIdQualifier')?.errors?.required && drugIdentificationFormGroup?.get('serviceIdQualifier')?.touched">Service
                            ID Qualifier is Required</span>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">National Drug Code <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" maxlength="11"
                            placeholder="National Drug Code" id="first-name" formControlName="nationalDrugCode" />
                        <span class="text-danger"
                            *ngIf="drugIdentificationFormGroup?.get('nationalDrugCode')?.errors?.required && drugIdentificationFormGroup?.get('nationalDrugCode')?.touched">National
                            Drug Code is Required</span>
                        <span class="text-danger"
                            *ngIf="drugIdentificationFormGroup?.get('nationalDrugCode')?.invalid && !drugIdentificationFormGroup?.get('nationalDrugCode')?.errors?.required">
                            National Drug Code accepts only 11 digit numeric value
                        </span>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- <div class="form-group">
                        <label for="first-name">Strength <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Strength" id="first-name"
                            formControlName="strength" />
                        <span class="text-danger"
                            *ngIf="drugIdentificationFormGroup?.get('strength')?.errors?.required && drugIdentificationFormGroup?.get('strength')?.touched">Strength
                            is Required</span>
                    </div> -->
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">Measurement</label>
                        <ng-select placeholder="Add or Choose Measurement" [items]="unitOfMeasureList"
                            formControlName="measurementUnitCode" bindLabel="desc" [addTag]="addTag?.bind(this)"
                            bindValue="value"></ng-select>
                    </div>
                    
                </div>
                <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">Measurement <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Measurement" id="first-name"
                            formControlName="measurement" />
                        <span class="text-danger"
                            *ngIf="drugIdentificationFormGroup?.get('measurement')?.errors?.required && drugIdentificationFormGroup?.get('measurement')?.touched">Measurement is Required</span>
                    </div>
                </div> -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">Dosage <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="number" placeholder="Dosage" id="first-name"
                            formControlName="nationalDrugUnitCount" />
                        <span class="text-danger"
                            *ngIf="drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.errors?.required && drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.touched">Dosage
                            is Required</span>
                        <span class="text-danger"
                            *ngIf="drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.invalid && !drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.errors?.required">
                            Dosage accepts only numeric value
                        </span>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">Days/Units <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Days/Units" id="first-name"
                            formControlName="daysUnits" />
                        <span class="text-danger"
                            *ngIf="drugIdentificationFormGroup?.get('daysUnits')?.errors?.required && drugIdentificationFormGroup?.get('daysUnits')?.touched">Days/Units
                            is Required</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="app-modal-footer">
        <button type="button" style="margin-right: 15px;" class="btn btn-primary delete-action-bg" data-dismiss="modal"
            (click)="drugIdentificationDetails?.hide(); deleteSelectedNDC()">Delete</button>

        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
            (click)="saveNdc(drugIdentificationDetails)">Save</button>
    </div>
</app-ui-modal>

<!-- Claim Submission Confirmation -->
<app-ui-modal #showDependentModal [hideHeader]="false" [modalCentered]="true" id="showDependentModal">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Appointment Details</h5> -->
    </div>
    <div class="app-modal-body add-app-body">
        <h5 class="text-center">Claim being submitted for dependent as patient. Please validate whether the dependent
            details are correct and not the same as primary subscriber. Click OK to proceed with the submission</h5>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
            (click)="showDependentModal?.hide();">Ok</button>
    </div>
</app-ui-modal>

<!-- Late Notify PopUp -->
<app-send-sms-notify></app-send-sms-notify>