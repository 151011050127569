import { AfterViewInit, Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ScheduleFollowupAppointmentComponent } from '../schedule-followup-appointment/schedule-followup-appointment.component';
import { FollowupNotificationComponent } from '../followup-notification/followup-notification.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';
@Component({
  selector: 'app-carepathways-list',
  templateUrl: './carepathways-list.component.html',
  styleUrls: ['./carepathways-list.component.scss']
})
export class carepathwaysListComponent implements OnInit {

  public caregapList = [];
  public caregapItems = [];
  public cohortsList = [];
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  loadSpinner: boolean;
  public notifyText: string;
  @ViewChild('caregapCard') caregapCard: CardComponent;
  @Input() queueCard: CardComponent;
  @ViewChild(DataTableDirective, { static: false })
  dtElementCarepath: DataTableDirective;
  public dtOptionsCarepath: DataTables.Settings = {};
  public dtTriggerCarepath: Subject<any> = new Subject<any>();
  public openFilters: boolean = false;
  public loginDetails: any;
  public purposeOfVisitList = [];
  public typeList = ["Wait List", "Follow Up"];
  public statusList = ["Paused", "Cancelled"];
  public genderList = AppConstantsListConfig?.genderList;
  public selectedAppointmentId: any;
  public counterInfo: any;
  @ViewChild(ScheduleFollowupAppointmentComponent) private scheduleFollowup: ScheduleFollowupAppointmentComponent;
  @ViewChild(FollowupNotificationComponent) private followupNotify: FollowupNotificationComponent;
  public filtersForm: FormGroup;
  public cohortSelected: boolean;
  public facilityId: any;
  public filterEnabled: boolean = false;

  public openActions: boolean = false;
  public enableActions: boolean = false;
  public selectedActionRow: any;
  isSuccessNotify: boolean;

  public careProgramsList: any;
  public cancelledCount = 0;
  public enRolledCount = 0;
  public pausedCount = 0;
  public selectedCareProgramm: any;
  public ccmStatusList = [
    { value: 'Enrolled', desc: 'Active' },
    { value: 'Paused', desc: 'Paused' },
    { value: 'Cancelled', desc: 'Cancelled' }
  ];
  public programChangeStatusList = [
    //{ value: 'Enrolled', desc: 'Active' },
    { value: 'Paused', desc: 'Paused' },
    { value: 'Cancelled', desc: 'Cancelled' }
  ];
  public chronicConditionsList = [
    { value: 'Hypertension', desc: 'Hypertension' },
    { value: 'Diabetes', desc: 'Diabetes' },
    { value: 'Heart Disease', desc: 'Heart Disease' },
    { value: 'Chronic Obstructive Pulmonary Disease (COPD)', desc: 'Chronic Obstructive Pulmonary Disease (COPD)' },
    { value: 'Asthma', desc: 'Asthma' },
    { value: 'Arthritis', desc: 'Arthritis' },
    { value: 'Chronic Kidney Disease (CKD)', desc: 'Chronic Kidney Disease (CKD)' },
    { value: 'Mental Health Conditions', desc: 'Mental Health Conditions' }
  ];


  public conditionsForm: FormGroup;
  public updateCareProgramForm: FormGroup;
  public masterPatientDetails: any;

  @HostListener('document:click', ['$event'])
  clickInside(event) {

    const eventId = (event.target as HTMLInputElement).id;
    if (!this.enableActions && eventId !== 'claimActions_btn') {
      this.openActions = false;
    }
  }

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.facilityId = this.loginDetails.facilityId;
    this.getPurposeOfVisit(this.loginDetails && this.loginDetails.facilityId);
    this.filtersForm = this.formBuilder.group({
      'careGaps': [null, Validators?.required],
      'cohort': [null],
      "status": [null],
      "gender": [null],
      "enrollDateStart": [null],
      "enrollDateEnd": [null]
      // 'lastVisitDate': [null]

    });
    this.caregapItems = ['RPM', 'Chronic Care'];
    const navigationDAta = {
      breadcrumbs: true,
      title: "Care Pathways",
      type: "item",
      url: '/hospital/enrolled-patients'
    };

    this.conditionsForm = this.formBuilder?.group({
      'conditions': [null]
    });

    this.updateCareProgramForm = this.formBuilder?.group({
      'status': [null, Validators?.required],
      'reason': [null, Validators?.required]
    });

    sessionStorage.setItem('navigationPath', JSON.stringify(navigationDAta));
  }

  ngOnInit(): void {

    this.dtOptionsCarepath = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      autoWidth: true,
      lengthMenu: [10, 25, 50, 75, 100],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };

  }
  ngAfterViewInit(): void {
    this.getCareGapsCounters();
    this.getCaregapList();
  }

  public rerenderCarepath(): void {
    this.dtElementCarepath?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }

  public openNotify(masterPatientId: any) {
    //console.log(appointment);

    //this.sendNotifyComp?.showNotifyModal(appointment);
    this.getMasterPatientDetails(masterPatientId);
    setTimeout(() => {
      this.sendNotifyComp?.showNotifyModal(this.masterPatientDetails);
    }, 1000);


  }

  public openSchedule(masterPatientId: any) {
    
   /* const userInfoId = existingAppnt?.userInfoId;
    this.getUserInfoDetails(userInfoId);*/

    this.getMasterPatientDetails(masterPatientId);
    setTimeout(() => {
      const existingAppnt = this.masterPatientDetails;
      sessionStorage?.removeItem('selectedPatientFlowBoard');
      sessionStorage?.removeItem('establishedUserInfo');
      const establishedUserInfo = [];
      const userinfo = {
        "address": existingAppnt?.address,
        "age": existingAppnt?.age,
        "email": existingAppnt?.email,
        "dob": existingAppnt?.dob,
        "firstName": existingAppnt?.firstName,
        "gender": existingAppnt?.gender,
        "lastName": existingAppnt?.lastName,
        "patientPrimaryPhone": existingAppnt?.patientPrimaryPhone,
        "emergencyDetails": [],
        "ethnicity": existingAppnt?.ethnicity,
        "race": existingAppnt?.race,
        "idDetails": [],
        "insuranceDetails": [],
        "maritalStatus": existingAppnt?.maritalStatus,
        "middleName": existingAppnt?.middleName
      }
      establishedUserInfo.push(userinfo);
      // console.log(this.masterPatientDetails);
      sessionStorage.setItem('establishedUserInfo', JSON.stringify(establishedUserInfo[0]));
      this.router.navigate(['/hospital/addAppt']);
      // this.mapUserToGetUserInfo(existingAppnt);

    }, 1000);


  }

  public getUserInfoDetails(userInfoId): void {
    const action = `common/getChartInfo?userInfoId=${userInfoId}&chartModuleName=UserInfo`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const userInfo = data?.responseObject?.userInfo || null;
        sessionStorage.setItem('establishedUserInfo', JSON.stringify(userInfo));
        this.router.navigate(['/hospital/addAppt']);
      }
    },
      (error) => {

      });
  }


  public openFilterSection() {
    this.openFilters = !this.openFilters;
    this.listCohorts(this.facilityId);
  }

  public closeFiltersection() {
    this.openFilters = false;
  }
  public getPurposeOfVisit(facilityId: any): void {
    const action = `waitTime/`;
    this.httpService.makeGetRequest(action, facilityId).subscribe((data: any) => {

      this.purposeOfVisitList = (data && data.purposeOfVisit) || [];

    },
      error => {
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();
      })
  }


  public getCaregapList() {

    const caregapDto = {};
    //const action = `careGaps/searchMasterPatientCarepathways`;
   // const action = `careGaps/listMasterPatientCareprograms`;
    const action = `careGaps/careProgramsEnrolledList`;
    

    this.rerenderCarepath();
    this.queueCard?.cardRefreshShow();
    this.httpService.makeRequestWithAction(action, caregapDto).subscribe((data: any) => {
      //console.log(data);
      //console.log(data.responseObject);
      if (data?.body?.status === 'SUCCESS') {

        if (data?.body?.responseObject?.careProgramsList) {

          this.cancelledCount = data?.body?.responseObject?.cancelledCount;
          this.enRolledCount = data?.body?.responseObject?.enrolledCount;
          this.pausedCount = data?.body?.responseObject?.pausedCount;
          const careProgramsList = data?.body?.responseObject?.careProgramsList || [];
          this.caregapList = data?.body?.responseObject?.careProgramsList || [];
          // this.caregapList = data?.body?.responseObject?.careProgramsList[0]?.carePrograms || [];
          /*const caregaps = [];
          careProgramsList?.forEach((item: any) => {
            if (item?.carePrograms?.length > 0) {
              const obj = item;
              
              const cGapList = item?.carePrograms;
              //var mainArr = delete(item.carePrograms);

              cGapList?.forEach((dt: any) => {
                dt.careProgramInfo = obj;

              });
              // cGapList?.push(obj);

              this.caregapList = [...this.caregapList, ...cGapList];
            }


          });*/


        }
        //  console.log(this.caregapList);

        //console.log(this.caregapList);

      }
      else {
        this.caregapList = [];
      }
      this.dtTriggerCarepath?.next();
      this.queueCard?.cardRefreshHide();


    },
      error => {
        this.dtTriggerCarepath?.next();
        this.queueCard?.cardRefreshHide();
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();

      })

  }
  public displaycareGap(caregap: any) {
    if (caregap !== null) {

      const caregapData = [];
      caregap?.forEach((item: any) => {

        caregapData?.push(item.name);

      });
      const uniqueArray = caregapData.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      const stringValue = uniqueArray.join(', ');
      return stringValue;

    }

  }
  public getLastServiceDate(caregap: any) {
    if (caregap !== null) {
      const caregapData = [];
      caregap?.forEach((item: any) => {

        caregapData?.push(item.lastServiceDate);

      });

      const lastIndex = caregapData.length - 1;
      let caregapDate = null;
      if (caregapData[lastIndex] !== null) {
        caregapDate = this.getAppointmentTime(caregapData[lastIndex]) || '--';
      }
      else {
        caregapDate = "--";

      }

      return caregapDate;

    }
  }
  public getCareGapsCounters() {

    const action = `counter/getCareGapsCounters`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      this.counterInfo = (data) || null;

    },
      error => {

      })
  }

  public getAppointmentTime(date: any): string {
    return this.httpService.getFolowupDate(date);
  }

  public getAppointmentTimeString(date: any): string {
    return this.httpService.getFolowupTime(date);
  }

  public listCohorts(facilityId: any): void {
    const action = `careGaps/listCohorts/`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      this.cohortsList = (data && data.responseObject) || [];

    },
      error => {
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();
      })
  }

  public getCaregaps(event: any): void {
    this.cohortSelected = false;
    // this.filtersForm.get('careGaps')?.setValue(null);
    this.enableFilters(event);
    const cohortDescription = event;

    const action = `careGaps/listCareGaps?cohortDescription=${cohortDescription}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.cohortSelected = true;

      this.caregapItems = (data && data.responseObject) || [];

    },
      error => {
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();
      });
  }

  public enableFilters(event: any) {

    if (event !== null && event !== '') {
      this.filterEnabled = true;
    }
    else {
      this.filterEnabled = false;
    }

  }
  public clearFilters() {
    this.caregapList = [];
    this.filtersForm.reset();

    this.getCaregapList();

  }

  public getSearchList() {
    if (this.filtersForm?.invalid) {
      this.filtersForm?.markAllAsTouched();
      return;
    }
    // console.log('gender',this.filtersForm?.get('gender')?.value);
    const careGaps = this.filtersForm?.get('careGaps')?.value;
    const lastVisitDate = this.filtersForm?.get('lastVisitDate')?.value;
    const enrollDateStart = this.filtersForm?.get('enrollDateStart')?.value;
    const enrollDateEnd = this.filtersForm?.get('enrollDateEnd')?.value;
    const gender = this.filtersForm?.get('gender')?.value;
    const status = this.filtersForm?.get('status')?.value;
    let filterDto = {};
    // filterDto = { "lastVisitDate": lastVisitDate };


    if (careGaps) {

      filterDto = {
        "enrollDateStart": enrollDateStart,
        "enrollDateEnd": enrollDateEnd,
        "status": status,
        "gender": gender,
        "careGaps": careGaps,
        "firstName": null,
        "lastName": null,
        "patientPrimaryPhone": null,
      };
    }

    // const action = `careGaps/searchCarepathwaysTransactions`;
    //const action = `careGaps/filterMasterPatientCareprograms`;
    const action = `careGaps/careProgramsEnrolledList`;

    this.rerenderCarepath();
    this.queueCard?.cardRefreshShow();
    this.httpService.makeRequestWithAction(action, filterDto).subscribe((data: any) => {
      this.caregapList = data?.responseObject || [];
      //.log('filteredlist',this.caregapList);
      if (data?.body?.status === 'SUCCESS') {
        // console.log(data?.body?.responseObject?.careProgramsList);
        if (data?.body?.responseObject?.careProgramsList) {

          this.cancelledCount = data?.body?.responseObject?.cancelledCount;
          this.enRolledCount = data?.body?.responseObject?.enrolledCount;
          this.pausedCount = data?.body?.responseObject?.pausedCount;
          const careProgramsList = data?.body?.responseObject?.careProgramsList || [];
          this.caregapList = data?.body?.responseObject?.careProgramsList || [];
         
          // this.caregapList = data?.body?.responseObject?.careProgramsList[0]?.carePrograms || [];
          /*const caregaps = [];
          careProgramsList?.forEach((item: any) => {
            if (item?.carePrograms?.length > 0) {
              const obj = item;

              const cGapList = item?.carePrograms;
              //var mainArr = delete(item.carePrograms);

              cGapList?.forEach((dt: any) => {
                dt.careProgramInfo = obj;

              });
              // cGapList?.push(obj);

              this.caregapList = [...this.caregapList, ...cGapList];
            }


          });*/


        }
        // console.log(this.caregapList);      

      }
      else {
        this.caregapList = [];
      }
      this.dtTriggerCarepath?.next();
      this.queueCard?.cardRefreshHide();
    },
      error => {
        this.queueCard?.cardRefreshHide();
        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.dtTriggerCarepath?.next();
        this.exceptionModal.modalShow();

      })



    /*const params = new URLSearchParams();
      const formValue = this.filtersForm.value; // this.form should be a FormGroup
    for (const key in formValue) {
        if (formValue[key] !== null && formValue[key] !== 'null' && formValue[key] !== '') {
          params.append(key, formValue[key]);
        }
      }
    const action = `careGaps/searchCarepathwaysTransactions?${params}`;
    	
      this.rerenderCarepath();
      this.queueCard?.cardRefreshShow();
  
      this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
        console.log(data)
        this.caregapList = (data && data.responseObject) || [];
        this.dtTriggerCarepath?.next();
        this.queueCard?.cardRefreshHide();
      },
        error => {
          this.queueCard?.cardRefreshHide();
          this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
          this.dtTriggerCarepath?.next();
          this.exceptionModal.modalShow();
  
      })*/

  }

  public openUniversalActions(event: any, row) {
    if (this.selectedActionRow === row) {
      this.openActions = !this.openActions;
    }
    else {
      this.openActions = true;
    }

    this.selectedActionRow = row;

  }
  public closeUniversalActions() {

    this.openActions = false;
  }

  public mouseIn() {
    this.enableActions = true;

  }

  public mouseOut() {
    this.enableActions = false;

  }
  public getCount(data: any) {
    var countVal = 0
    if (data && data.length > 0) {
      countVal = data.length;
    }
    return countVal;
  }

  public getChiefComplaintsSectionHeight(cc: any) {
    let height = null;
    if (cc === null || cc === undefined) {
      return;
    }

    if (cc.length === 1) {
      height = '47px';
    }
    else if (cc.length === 2) {
      height = '68px';
    }
    else if (cc.length === 3) {
      height = '90px';
    }
    else if (cc.length > 3) {
      height = '105px';
    }

    return height;

  }
  public visitDateFormat(visitDate: string): string {
    /* let dt = visitDate?.includes(':') ? visitDate?.split(' ')[0] : visitDate;
     if (dt?.includes('/')) {
       return this.httpService?.getCareflowDate(dt);
     }
     return dt;
   */
    return this.httpService?.getCareflowDate(visitDate);
  }

  public resetForm(){
    this.updateCareProgramForm.reset();
  }

  public modifyUserData(appointment: any): void {
    // console.log("appointment",appointment);
    if (appointment?.userInfoId) {
      this.router?.navigate([`/hospital/user-data-update/${appointment?.userInfoId}`]);
    } else {

      alert("User Info details are not avialable for selected patient");
    }
  }

  public openProgramChange(programChange: UiModalComponent, careProgram: any) {
    this.selectedCareProgramm = careProgram;
     console.log(this.selectedCareProgramm);
    const status = careProgram?.careProgram?.status;
    if (status !== 'Enrolled') {
      this.updateCareProgramForm?.get('status')?.setValue(status);
    }
    else {
      this.updateCareProgramForm?.get('status')?.setValue(null);
    }
    //this.updateCareProgramForm?.get('status')?.setValue(status);
    programChange?.show();

  }

  public updateProgram(programChange: UiModalComponent) {
    if (this.updateCareProgramForm?.invalid) {
      this.updateCareProgramForm?.markAllAsTouched();
      return;
    }
    const action = `careGaps/updateCareProgram`;

    // const action = `careGaps/updateMasterPatientCaregaps`;
    const careProgramDto = {
      "conditions": this.selectedCareProgramm?.careProgram?.conditions,
      // "consentType": '',
      "name": this.selectedCareProgramm?.careProgram?.name,
      "notQualified": null,
      "patientEmrId": this.selectedCareProgramm?.patientEmrId,
      "reason": this.updateCareProgramForm?.get('reason')?.value,
      "status": this.updateCareProgramForm?.get('status')?.value,
      "userInfoId": this.selectedCareProgramm?.userInfoId
    };

    //console.log('enroll', careProgramDto);
    //return;

    this.httpService?.makeRequestWithAction(action, careProgramDto)?.subscribe((data: any) => {

      if (data?.body?.status == 'SUCCESS') {
        this.updateCareProgramForm.reset();
        this.caregapList = [];
        this.getCaregapList();
        programChange?.hide();
        this.isSuccessNotify = true;
        this.notifyText = data?.body?.msg || data?.body?.message;
        this.exceptionModal?.modalShow();
      }

    },
      (error) => {

      })

  }

  public getConditionslist(conditions: any) {
    return conditions.join(',');
  }

  public getMasterPatientDetails(masterPatientId: any) {
    const action = `mpp/getMasterPatientPanelDetails?masterPatientPanelId=${masterPatientId}`;

    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {

      if (data?.status === 'SUCCESS') {
        this.masterPatientDetails = data?.responseObject || [];
        //console.log(this.masterPatientDetails);

      }
      else {
        this.masterPatientDetails = [];
      }


    },
      error => {


      })
  }

}
