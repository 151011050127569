import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-hl7-details',
  templateUrl: './hl7-details.component.html',
  styleUrls: ['./hl7-details.component.scss']
})
export class Hl7DetailsComponent implements OnInit {

  @ViewChild('hl7DetailsLab') public hl7DetailsLabModal: UiModalComponent;

  @Input() orderType: string;
  @Input() selectedOrderDetails: any;
  @Input() cardComp: CardComponent;
  @Input() showOrderDetails: any;
  notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalModal: ExceptionModalComponent;

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {

    }
  }

  public openOrderDetailsPopup(orderType: string, showOrderDetails: any, selectedOrderDetails?: any): void {
    this.orderType = orderType;
    this.selectedOrderDetails = selectedOrderDetails || null;
    this.showOrderDetails = showOrderDetails || null
    if (this.showOrderDetails) {
      this.hl7DetailsLabModal?.show();
    } else {
      this.getOrderDetails();
    }
  }

  // to get Internal Lab Reults 
  public getOrderDetails(): void {
    const action = `changeHealth/getHl7messageDetails?appointmentId=${this.selectedOrderDetails?.appointmentId}`;
    this.cardComp?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.showOrderDetails = data?.responseObject || [];
        this.hl7DetailsLabModal?.show();
      }else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
      }
      this.cardComp?.cardRefreshHide();
    },
      (error) => {
        this.cardComp?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
      })
  }

}
