<div class="summary-assessment-data">
    <ng-container *ngFor="let category of carePlanData; let cati = index">
        <!-- <h5 *ngIf="category?.resourceType !== 'General'">{{category?.resourceType}}</h5> -->
        <ng-contaier *ngFor="let section of category?.sections">
            <!-- *ngIf="section?.name !== 'New Orders'" -->
            <ng-contaier>
                <!-- <h5 *ngIf="section?.name !== 'New Orders'">{{section?.name}}</h5> -->
                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let answer of section?.answers">
                        <ng-contaier *ngIf="section?.name === 'Assessment'">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>ICD Code</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let tbody of answer?.actualValue; let listi = index">
                                                    <td>{{tbody?.code}}</td>
                                                    <td>{{tbody?.description || cmpareCodesDesc(tbody?.code)}}</td>
                                                </tr>
                                                <tr *ngIf="answer?.actualValue?.length === 0">
                                                    <td colspan="2" class="text-center">ICD Codes are unavailable for
                                                        this patient.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ng-contaier>
                    </div>
                </div>
            </ng-contaier>
        </ng-contaier>
    </ng-container>
</div>