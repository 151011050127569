import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ViewportScroller } from "@angular/common";
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { CardComponent } from '../../components/card/card.component';

import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-common-profile-pic-upload',
  templateUrl: './common-profile-pic-upload.component.html',
  styleUrls: ['./common-profile-pic-upload.component.scss']
})
export class CommonProfilePicUploadComponent {


  @Input() userInfoId: any;
  @Input() updateUserdataCard: CardComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  public loadSpinner: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalModal: ExceptionModalComponent;
  @ViewChild('profilepicUploadModal') profilePicContent: UiModalComponent;
  @ViewChild('deleteConfirmation') deletePicContent: UiModalComponent;
  @ViewChild('fullView') fullViewContent: UiModalComponent;
  



  public showWebcam = false;
  public showInsuranceWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId?: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamInsuranceImage = null as unknown as WebcamImage;
  public webcamInsuranceImages: WebcamImage[] = [];
  public insuranceImagePDFFile = new jsPDF();
  public formData: any = new FormData();
  public aplhaNumericHyphenWithSpace: RegExp = /^[a-zA-Z0-9-]+[a-zA-Z0-9-\s]*$/;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();

  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  public displayInsuranceImages: boolean = false;
  public insuranceImagesCount: number = 0;

  public userProfileImage = null;
  public profiles3Key = null;
  public capturedImage: any;


  constructor(private httpService: HttpService, private router: Router, private formBuilder: FormBuilder, private activateRoute: ActivatedRoute, private scroller: ViewportScroller) {

  }
  ngOnInit(): void {


    this.webcamInsuranceImage = {} as WebcamImage;
    this.webcamInsuranceImages = [];

    this.insuranceImagePDFFile = new jsPDF();
	const viewType = 'thumbnail';
    this.readProfilePic(viewType);


  }
  
  ngAfterViewInit(): void {
    
  }

  public getCurrentTimeStamp() {
    const current = new Date();
    current.setHours(0)
    current.setMinutes(0)
    current.setSeconds(0)
    current.setMilliseconds(0)
    const timestamp = current.getTime();
    return timestamp;
  }

  public triggerInsuranceSnapshot(): void {
    this.trigger.next();
    this.displayInsuranceImages = true;
    this.webcamInsuranceImage = this.webcamInsuranceImages[0];
    if (this.showInsuranceWebcam && this.webcamInsuranceImages?.length === 1) {
     // this.closeInsuranceCamera
	  this.showInsuranceWebcam = false;
    }
  }

  public openInsuranceCamera(): void {
  
    this.webcamInsuranceImages = [];
    this.profilePicContent?.show();
    this.showInsuranceWebcam = true;
  }
  
  public deleteImage(): void {
    this.deletePicContent?.show();
  }

  public closeInsuranceCamera(): void {
    this.profilePicContent?.hide();
    this.showInsuranceWebcam = false;
  }

  public toggleInsuranceWebcam(): void {
    this.showInsuranceWebcam = !this.showInsuranceWebcam;
  }


  public clearInsuranceSnapshot(removeIndex: any): void {
    this.webcamInsuranceImages.splice(removeIndex, 1);
    //this.webcamInsuranceImages = [];
    this.insuranceImagesCount = this.webcamInsuranceImages.length;
    this.webcamInsuranceImage = {} as WebcamImage;
    this.insuranceImagePDFFile = new jsPDF();
	this.showInsuranceWebcam = true;
  }


  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleInsuranceImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
	this.capturedImage = webcamImage!.imageAsDataUrl;
    console.info('got webcam image', this.capturedImage);

    this.insuranceImagesCount = this.insuranceImagesCount + 1;
    this.webcamInsuranceImages?.push(webcamImage);
    this.insuranceImagePDFFile?.addImage(webcamImage?.imageAsDataUrl, 'JPEG', 10, 50, 150, 150);
    if (this.insuranceImagesCount === 1) {
      this.insuranceImagePDFFile?.addPage();
    }
    //this.insuranceImagePDFFile.save('file.pdf'); 
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }



  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
   
  public downloadIdProfilePreview(userInfoId, s3Key, s3Path, fileType, viewType) {
    if (!s3Key || !s3Path) {
      return;
    }
    this.loadSpinner = true;
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.httpService.downloadProfileImage(downloadaction).subscribe((dData: any) => {
      if(!dData) {
        this.loadSpinner = false;
        return;
      }
      this.profilePicContent?.hide();
      
     this.loadSpinner = false;
      
      let iframeEle = null;
	   if(viewType === 'fullview'){
	   this.fullViewContent.show();
	     iframeEle = document.getElementById('profile_picture_fullview') as HTMLElement;
	   }
	   else{
	     iframeEle = document.getElementById('profile_picture_display') as HTMLElement;
	   }
	 

      iframeEle?.setAttribute('src', dData);
	  
	 
    }, (error) => {
      this.loadSpinner = false;
      
    })	
	
  }
  
 public readProfilePic(viewType:any) {
    const documentType = 'Profile Picture';
    const userInfoId = this.userInfoId;
    const action = `record/readUserImage?userInfoId=${userInfoId}&documentType=${documentType}`;
    this.loadSpinner = true;

    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      const resultDt = data?.responseObject || [];
      if (resultDt) {
       
        const userInfoId = resultDt?.userInfoId;
        const s3Key = resultDt?.s3Key;
        const s3Path = resultDt?.s3Path;
        const fileType = resultDt?.fileType;
       // const imageData = resultDt?.imageData;
        this.userProfileImage = s3Key;
        this.profiles3Key = s3Key;

        this.showInsuranceWebcam = false;
        this.showWebcam = false;


    this.downloadIdProfilePreview(userInfoId, s3Key, s3Path, fileType, viewType);
	
	
	/*window.setTimeout(function() {
     this.profilePicContent?.hide();
     
      let iframeEle = null;
	   if(viewType === 'fullview'){
	     this.fullViewContent.show();
	     iframeEle = document.getElementById('profile_picture_fullview') as HTMLElement;
	   }
	   else{
	     iframeEle = document.getElementById('profile_picture_display') as HTMLElement;
	   }
    iframeEle.setAttribute('src', imageData);
    }, 500);
	*/
	 this.loadSpinner = false;
	  
	  
	}

    }, error => {
      this.loadSpinner = false;
    });
  }

  public deleteProfilePic(delType: any, s3Key: any) {
  this.deletePicContent?.hide();
    const userInfoId = this.userInfoId;

    const action = `record/deleteUserImage?userInfoId=${userInfoId}&s3Key=${s3Key}`;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      console.log(data);
      this.userProfileImage = null;
      this.loadSpinner = false;
      if (delType === 'delete') {
        this.notifyText = 'Profile image deleted successfully.';
        this.isSuccessNotify = true;
        this.exceptionalModal?.modalShow();
      }

    }, error => {
      this.loadSpinner = false;
      if (delType === 'delete') {
        this.notifyText = 'Something went wrong please try again later..!';
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
      }
    });
  }

  public uploadProfilepic() {

    if (this.webcamInsuranceImages && this.webcamInsuranceImages?.length === 0) {
      return;
    }

    if (this.userProfileImage !== '' && this.userProfileImage !== null) {
      const delType = 'update';

      this.deleteProfilePic(delType, this.profiles3Key);
    }

    const documentType = 'Profile Picture';
    const fileData = '';
    const fileType = 'image/jpeg';
    const isFile = true;
    const userInfoId = this.userInfoId;
    const timestamp = this.getCurrentTimeStamp();
    const documentName = documentType + "_" + timestamp + "_" + userInfoId;

    this.formData = new FormData();
   // var insurancePDF = this.insuranceImagePDFFile.output('blob');
   // this.formData.append('idFile', insurancePDF, 'InsuranceImage.png');
	var blobdta = new Blob([this.capturedImage], {type : fileType});
	this.formData.append('idFile', blobdta);
    
   const action = `record/uploadUserImage?userInfoId=${userInfoId}&isFile=${isFile}&fileType=${fileType}&documentType=${documentType}&documentName=${documentName}`;
    this.loadSpinner = true;
    this.httpService.makePostWithFormData(action, this.formData)
      .subscribe((data: any) => {
        this.profilePicContent?.hide();
        if (data.status === 'SUCCESS') {
          this.loadSpinner = false;
          this.notifyText = 'Profile image uploaded successfully.';
          this.isSuccessNotify = true;
          this.exceptionalModal?.modalShow();
          const viewType = 'thumbnail';
         this.readProfilePic(viewType);
		 

        } else {

          this.notifyText = 'Something went wrong please try again later..!';
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();

          this.loadSpinner = false;
        }
      },
        error => {
          this.loadSpinner = false;
          this.isSuccessNotify = false;
          this.notifyText = error?.message || 'Something went wrong please try again later..!';
          this.exceptionalModal?.modalShow();
        });



  }


}


