import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommomPastVisitDetailsWizardComponent } from '../commom-past-visit-details-wizard/commom-past-visit-details-wizard.component';
@Component({
  selector: 'app-view-appointment-details',
  templateUrl: './view-appointment-details.component.html',
  styleUrls: ['./view-appointment-details.component.scss']
})
export class ViewAppointmentDetailsComponent implements OnInit {

  @ViewChild(CommomPastVisitDetailsWizardComponent) public pastVisitComp: CommomPastVisitDetailsWizardComponent;
 

  constructor() { }

  ngOnInit(): void {
  }

}
