import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { HttpService } from '../../services/http.service';
import { AppConstantsListConfig } from '../../litterals/app.constants';

@Component({
  selector: 'app-common-upload-doc',
  templateUrl: './common-upload-doc.component.html',
  styleUrls: ['./common-upload-doc.component.scss']
})
export class CommonUploadDocComponent implements OnInit {
  allUploadedFilesList: any;
  @ViewChild('newFileUpload') public newFileUpload: ElementRef;
  public uploadForm: FormGroup;
  public notifyText: string;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  UploadedFiletype: any;
  @Input() uploadKeyForm: any;
  @Input() encounterUserDetails: any;
  errorMessageShown: any;
  successMessageShown: string;
  @Output() loadSpinnerShown = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
    this.uploadForm = this.formBuilder?.group({
      'documentType': [null, Validators?.required],
      'documentName': [null, Validators?.required],
      'multipartFile': [null, Validators?.required],
      'collectionType': [null],
    });
  }

  ngOnInit(): void {
  }

  // on upload get the file
  public uploadFileSelect(event: any): void {
    this.allUploadedFilesList = null;
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      const fileSize = files.size / 1024 / 1024;
      this.allUploadedFilesList = files;
      if (fileSize <= 25 && (files.type === 'application/pdf' || files.type === 'image/png' || files.type === 'image/jpeg')) {
        this.uploadForm?.get('documentName')?.setValue(files.name);
        this.uploadForm?.get('multipartFile')?.setValue(files);
        this.UploadedFiletype = files.type;
      }
      else {
        this.uploadForm?.get('multipartFile')?.setValue(null);
        this.newFileUpload.nativeElement.value = null;
        this.notifyText = "Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded";
        this.errorMsgShown(this.notifyText);
        // this.exceptionModal?.modalShow();
      }
    }
    this.uploadAdditionalDocuments();
    this.clearFileUpload();
  }

  // to get current time stamp
  public getCurrentTimeStamp() {
    const current = new Date();
    current.setHours(0)
    current.setMinutes(0)
    current.setSeconds(0)
    current.setMilliseconds(0)
    const timestamp = current.getTime();
    return timestamp;
  }

  public uploadAdditionalDocuments(): void {
    const formData = new FormData();

    const timestamp = this.getCurrentTimeStamp();
    const documentType = this.uploadForm?.get('documentType')?.value || 'Claim Journal';
    const nameValue = this.uploadForm?.get('documentName')?.value;

    const isFile = true;
    const documentName = documentType + "_" + timestamp + "_" + nameValue;
    this.uploadForm?.get('documentName')?.setValue(documentName);
    let actionAppend = `record/addDocument?appointmentId=${this.encounterUserDetails?.appointmentId}&documentName=${documentName}&documentType=${this.uploadForm?.value?.documentType || 'Claim Journal'}&isFile=${isFile}&fileType=${this.UploadedFiletype}`;

    formData.append('idFile', this.uploadForm?.value?.multipartFile);

    const action = actionAppend;
    this.loadSpinnerShown?.emit(true);
    // const action = `record/addDocument?documentName=${documentName}&documentType=${this.uploadAdditioinalDocsForm?.value?.documentType}&appointmentId=${this.selectedAppointmentUser?.id}&isFile=${isFile}`;
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.uploadKeyForm?.get('s3Key')?.setValue(data?.keyName);
        this.uploadKeyForm?.get('documentName')?.setValue(this.uploadForm?.get('documentName')?.value);
        this.successMsgShown(data?.msg || data?.message || 'Document added successfully.');
      } else {
        this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
        this.errorMsgShown(this.notifyText);
        this.allUploadedFilesList = null;
        // this.exceptionModal?.modalShow();
      }
      this.loadSpinnerShown?.emit(false);
    },
      (error) => {
        this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
        this.errorMsgShown(this.notifyText);
        this.allUploadedFilesList = null;
        this.loadSpinnerShown?.emit(false);
        // this.exceptionModal?.modalShow();
      });
  }

  // Delete  Uploaded File
  public deleteSelectedFile(index: number): void {
    this.allUploadedFilesList = null;
    this.clearFileUpload();
  }

  // Clear File Upload
  public clearFileUpload(): void {
    this.newFileUpload.nativeElement.value = null;
  }

  // error or Success message shown
  public errorMsgShown(msg: string): void {
    this.errorMessageShown = msg;
    setTimeout(() => {
      this.errorMessageShown = null;
    }, 3000)
  }

  public successMsgShown(msg: string): void {
    this.successMessageShown = msg;
    setTimeout(() => {
      this.successMessageShown = null;
    }, 3000)
  }

}
