<div class="orders-summary">
    <div class="rx-order-details">
        <h5>Rx Order Details</h5>
        <!-- datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders" -->
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th>SI.NO</th>
                        <th>Drug</th>
                        <th>Qty</th>
                        <th>DAYS SUPPLY</th>
                        <th>Rx Order Type</th>
                        <th>Refills</th>
                        <th>Order Date</th>
                        <th>Order Status</th>
                        <th>Pharmacy Name</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let rxOrder of allRxOrderDetails; let i = index">
                        <tr>
                            <td>{{i+1}}</td>
                            <td>{{rxOrder?.drug_name || '--'}}</td>
                            <td>{{rxOrder?.quantity || '--'}}</td>
                            <td>{{rxOrder?.days_supply || '--'}}</td>
                            <td>{{rxOrder?.rx_type || '--'}}</td>
                            <td>{{rxOrder?.refills || '--'}}</td>
                            <td>{{rxOrder?.transmission_date || '--'}}</td>
                            <td>{{rxOrder?.transmittal_status || '--'}}</td>
                            <td>
                                <a href="javascript:" placement="bottom"
                                    ngbTooltip="{{rxOrder?.ph_address_1}}, {{rxOrder?.ph_state}}, {{rxOrder?.ph_zip}} - {{rxOrder?.ph_phone}}">{{rxOrder?.ph_name
                                    || '--'}}</a>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="allRxOrderDetails?.length === 0">
                        <tr>
                            <td colspan="8" style="text-align: center;">Rx Orders are unavailable for this patient.</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>

    <!-- Lab Rad Details -->
    <div class="leb-order-details">
        <h5 class="mt-2">Lab/Rad Order Details</h5>
        <!-- datatable [dtOptions]="dtOptionsLabOrders" [dtTrigger]="dtTriggerLabOrders" -->
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th>SI.NO</th>
                        <th>Order Number</th>
                        <th>Order Type</th>
                        <th>Order Date</th>
                        <th>Order Collection Date</th>
                        <th>Order Transmission Date</th>
                        <th>Order Status</th>
                        <th>Lab Name</th>
                        <th>Results</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let labOrder of alllabRadOrderDetails; let i = index">
                        <tr>
                            <td>{{i+1}}</td>
                            <td>
                                <div class="d-flex">
                                    <ng-container>
                                        <a class="order-num" href="javascript:;" placement="right"
                                            ngbTooltip="Order Requisition" id="reviewOrders_req"
                                            (click)="downloadLabRequisition(labOrder?.externalOrderNumber)">{{labOrder?.orderNumber
                                            || '--'}}</a>
                                    </ng-container>

                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a class="order-num" href="javascript:;" placement="right" id="reviewOrders_orderNum"
                                        ngbTooltip="Order Details" (click)="getOrderDetails(labOrder)"><span
                                            class="material-symbols-outlined">info</span></a>
                                </div>
                                <ng-container *ngIf="labOrder?.requestDetails && labOrder?.requestDetails?.length > 0">
                                    <table>
                                        <tr class="no-top-pad">
                                            <td></td>
                                            <td colspan="7">
                                                <span class="tests-ordered" style="font-size: 12px;
                                                font-weight: bold;"
                                                    *ngFor="let test of labOrder?.requestDetails; let last = last">
                                                    {{test?.testDescription || '--'}}
                                                    <ng-container *ngIf="test?.diagnosisCodes">
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        (<span *ngFor="let code of test?.diagnosisCodes">{{code?.code}}
                                                            &nbsp;&nbsp;</span>)
                                                    </ng-container>
                                                    <span *ngIf="!last">,&nbsp;&nbsp;</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </ng-container>

                            </td>

                            <td>{{labOrder?.orderType || '--'}}</td>
                            <td>{{labOrder?.requestDate || '--'}}</td>
                            <td>{{labOrder?.collectionDate || '--'}}</td>
                            <td>{{(labOrder?.transmissionDate | date:'MM/dd/yyyy hh:mm a') || '--'}}</td>
                            <td>{{statusCodeDesc(labOrder?.orderStatus) || '--'}}</td>
                            <td>{{labOrder?.labName || '--'}}</td>
                            <td>
                                <ng-container>
                                    <button class="icon-queue-events btn-status-success" placement="left" id="reviewOrders_view"
                                        (click)="showHl7Details(labOrder)" ngbTooltip="View Results"><span
                                            class="material-symbols-outlined">
                                            visibility
                                        </span></button>
                                </ng-container>
                                <ng-container *ngIf="labOrder?.reportKey">
                                    <button class="icon-queue-events btn-status-success" placement="left" id="reviewOrders_pdf"
                                        (click)="getReportsAvailable(labOrder)" ngbTooltip="PDF Report"><i
                                            class="fa fa-file-pdf"></i></button>
                                </ng-container>
                                <ng-container *ngIf="!labOrder?.reportKey">
                                    <button class="icon-queue-events" placement="left" id="reviewOrders_pdf1"
                                        ngbTooltip="Report Not Available"><i
                                            class="fa fa-file-pdf disabled"></i></button>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="alllabRadOrderDetails?.length === 0">
                        <tr>
                            <td colspan="9" style="text-align: center;">Lab Orders are unavailable for this patient.
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Lab RAD Resulrs modal -->
<app-lab-rad-results-modal [labRadOrdersCard]="PatientDetailsCardReload"></app-lab-rad-results-modal>

<!-- Display the Order Details -->
<app-lab-rad-order-details-modal [cardComp]="PatientDetailsCardReload"></app-lab-rad-order-details-modal>

<app-hl7-details [cardComp]="PatientDetailsCardReload"></app-hl7-details>