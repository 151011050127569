<ng-container *ngIf="allConsentFormDocs?.length > 0">
    <!-- <div class="consent-form">
        <ng-container *ngFor="let form of allConsentFormDocs">
            <ul>
                <li>
                    <span class="template-name">{{form?.templateName}}</span>
                    <span class="pdf-icon"><i (click)="downloadConsentForm(form, pdfConsentFormViewModal)"
                            class="fa fa-file-pdf"></i></span>
                </li>
            </ul>
        </ng-container>
    </div> -->
    <div class="table-responsive new-designed-table">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Document Name</th>
                    <th class="text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let form of allConsentFormDocs; let i = index">
                    <tr>
                        <td>{{form?.documentName}}</td>
                        <td class="edit-delet-actions text-center">
                            <div class="edit icon" (click)="downloadConsentForm(form, pdfConsentFormViewModal)" id="viewConsenForm_download"><i
                                    class="las la-file-download"></i>&nbsp; Download</div>
                            <div class="delete icon" *ngIf="showDelete" id="viewConsenForm_delete"
                                (click)="deleteConsentFormConfirmation(form, deletConfirmConsentForm)"><i
                                    class="las la-trash-alt"></i>&nbsp; Delete</div>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="allConsentFormDocs?.length === 0">
                    <tr>
                        <td colspan="2">
                            <h6 class="text-center">Consent Forms are unavailable for this patient.</h6>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-container>
<!-- <ng-container *ngIf="allConsentFormDocs?.length === 0">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <h5 class="head-color">All of the consent forms are not signed</h5>
        </div>
    </div>
</ng-container> -->

<!-- Display the Consent Form PDF -->
<app-ui-modal #pdfConsentFormViewModal [hideHeader]="false" [modalCentered]="true" id="pdfConsentFormViewModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Consent Form</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="pdfConsentFormViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="consent-form-view-pdf" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="viewConsenForm_ok1"
            (click)="pdfConsentFormViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- confirm the delete uploaded document -->
<app-ui-modal #deletConfirmConsentForm [hideHeader]="false" [modalCentered]="true" id="deletConfirmConsentForm">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Do you want to delete the selected consent form ?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="deletConfirmConsentForm?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-2">
    
            <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                <!-- <i class="las la-exclamation-triangle"></i> -->
                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>

            </h5>
    
          </div>
          <div class="col-lg-10 col-md-10 col-sm-10">
    
            <h5 class="modal-title" style=" text-align: left;">Do you want to delete the selected consent form ?</h5>
    
          </div>
        </div>
        
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="viewConsenForm_yes"
            (click)="deletConfirmConsentForm?.hide(); deleteConsentForm(selectedConsentFormDelete);submitDeleteConsentForm(selectedConsentFormDelete)">Yes</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="viewConsenForm_no"
            (click)="deletConfirmConsentForm.hide()">No</button>
        
    </div>
</app-ui-modal>