<app-navigation class="pcoded-navbar" [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.flatConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}" (onNavMobCollapse)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light custome-navbar" (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container">
    <div class="pcoded-content" [ngClass]="{'container': this.flatConfig.layout === 'horizontal' && this.flatConfig.subLayout === 'horizontal-2'}">
      <app-breadcrumb *ngIf="!isHome"></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
    <!-- footer -->
    <div class="careeco-footer">
      <div class="container">
        <h5>CPT only copyright &#169; {{currentYear}} American Medical Association. All rights reserved.</h5>
      </div>
    </div>
</div>
<div class="" *ngIf="examRoom()"></div>
<!-- <app-configuration></app-configuration> -->
