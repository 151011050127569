<app-ui-modal #paymentModal [modalCentered]="true" id="paymentDailogue">
    <div class="app-modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="app-modal-body">
        <ng-container *ngIf="!displayPaymentSection">
            <div class="check-payment-type row" [formGroup]="servicesForm">
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <label>Service</label><br />
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <label>Price</label><br />
                </div>
                <!-- Co PAy Amount -->
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <div class="checkbox checkbox-primary d-inline">
                        <input type="checkbox" formControlName="selectCoPayAmount" (change)="servicesChange('selectCoPayAmount')">
                        <label class="cr list-name-label ml-4" for="">Co-Pay</label>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input type="number" formControlName="coPayAmount" name="card-pay"
                        class="form-control input-text" placeholder="Enter Co-Pay Amount" />
                </div>

                <!-- In house labs amount -->
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <div class="checkbox checkbox-primary d-inline">
                        <input type="checkbox" formControlName="selectInHouseLabsAmount" (change)="servicesChange('selectInHouseLabsAmount')">
                        <label class="cr list-name-label ml-4" for="">In-House Labs</label>
        
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input type="number" formControlName="inHouseLabsAmount" name="card-pay"
                        class="form-control input-text" placeholder="Enter In-House Labs Amount" />
                </div>

                <!-- Consultation Amount -->
                <div class="form-group col-lg-6 col-md-6 col-sm-6 input-group mb-3">
                    <div class="checkbox checkbox-primary d-inline">
                        <input type="checkbox" formControlName="selectConsultationAmount" (change)="servicesChange('selectConsultationAmount')">
                        <label class="cr list-name-label ml-4" for="">Consultation</label>
        
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input type="number" formControlName="consultationAmount" name="card-pay"
                        class="form-control input-text" placeholder="Enter Consultation Amount" />
                </div>

                <!-- External Labs Amount -->
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <div class="checkbox checkbox-primary d-inline">
                        <input type="checkbox" formControlName="selectExternalLabsAmount" (change)="servicesChange('selectExternalLabsAmount')">
                        <label class="cr list-name-label ml-4" for="">External Labs</label>
        
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input type="number" formControlName="externalLabsAmount" name="card-pay"
                        class="form-control input-text" placeholder="Enter External Lab Amount" />
                </div>

                <!-- Other Amount -->
                <div class="form-group col-lg-6 col-md-6 col-sm-6">
                    <div class="checkbox checkbox-primary d-inline">
                        <input type="checkbox" formControlName="selectOtherAmount" (change)="servicesChange('selectOtherAmount')">
                        <label class="cr list-name-label ml-4" for="">Other</label>
        
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input type="number" formControlName="otherAmount" name="card-pay"
                        class="form-control input-text" placeholder="Enter Other Amount" />
                </div>

                 <!--Credits -->
                 <ng-container *ngIf="totalCredits > 0">
                    <div class="form-group col-lg-6 col-md-6 col-sm-6">
                        <div class="checkbox checkbox-primary d-inline">
                            <input type="checkbox" formControlName="selectpastVisitCredits" (change)="servicesChange('selectpastVisitCredits');pastVisitCredits($event)">
                            <label class="cr list-name-label ml-4" for="">Past Visit Credits</label>
                        </div>
                    </div>
                    <div class="form-group col-lg-6 col-md-6 col-sm-6 input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input type="number"  name="card-pay"
                            class="form-control input-text" placeholder="Past Visit Credits" formControlName="pastVisitCredits" readonly />
                    </div>
                 </ng-container>
                 

                <!--  total amount -->
                <hr style="width:100%;text-align:left;margin-left:0">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <span class="required error" *ngIf="totalMinimumAmountError">Amount should be minimum of $1 to make payment.</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>
                        <h5>Visit Charges</h5>
                    </label><br />
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="float-right">
                        <h5>$ {{(servicesForm?.get('coPayAmount')?.value + servicesForm?.get('inHouseLabsAmount')?.value + servicesForm?.get('consultationAmount')?.value + servicesForm?.get('externalLabsAmount')?.value + servicesForm?.get('otherAmount')?.value)}}
                        </h5>
                    </label><br />
                </div>

                <!-- Credits Balance -->
                <ng-conatiner class="col-lg-12 col-md-12 col-sm-12" *ngIf="totalCredits > 0 && selectedPastVisitCreditsTotal">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6" >
                            <label>
                                <h5>Available Credits</h5>
                            </label><br />
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="float-right">
                                <h5>$ {{selectedPastVisitCreditsTotal >= getSelectedItemizedAmount() ? selectedPastVisitCreditsTotal - getSelectedItemizedAmount() : 0}}
                                </h5>
                            </label><br />
                        </div>
                    </div>
                </ng-conatiner>

                <!-- Final Payment to be Paid -->
                <ng-conatiner class="col-lg-12 col-md-12 col-sm-12" *ngIf="totalCredits > 0 && selectedPastVisitCreditsTotal && selectedPastVisitCreditsTotal < getSelectedItemizedAmount()">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>
                                <h5>Final Charges</h5>
                            </label><br />
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="float-right">
                                <h5>$ {{selectedPastVisitCreditsTotal >= getSelectedItemizedAmount() ? selectedPastVisitCreditsTotal - getSelectedItemizedAmount() : getSelectedItemizedAmount() - selectedPastVisitCreditsTotal}}
                                </h5>
                            </label><br />
                        </div>
                    </div>
                </ng-conatiner>

                <!-- Make partial payment -->
                <ng-conatiner class="col-lg-12 col-md-12 col-sm-12" *ngIf="getTotalAmount() > 0">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group text-left">
                                <div class="checkbox checkbox-primary d-inline">
                                    <input type="checkbox" name="Partial Payment" (change)="checkPartialPayment($event)" formControlName="isPartialPayment" id="PartialPaymentCheck">
                                    <label for="PartialPaymentCheck" class="cr check-label ml-2">Partial Payment?</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12 input-group" *ngIf="servicesForm?.get('isPartialPayment')?.value">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="number" formControlName="amountTobePaid" name="AmountPaid"
                                class="form-control input-text" placeholder="Enter Partial Payment" />
                        </div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12 error-message">
                            <label class="required error" *ngIf="servicesForm?.get('amountTobePaid')?.errors?.required && servicesForm?.get('amountTobePaid')?.touched">Partial Payment is required to make payment</label>
                            <label class="required error" *ngIf="servicesForm?.get('amountTobePaid')?.value > this.getTotalAmount()">Partial Payment should not be greater than Visit Charges/Final Charges</label>
                        </div>
                    </div>
                </ng-conatiner>

                <!-- Balance amount -->
                <ng-container class="col-lg-12 col-md-12 col-sm-12" *ngIf="servicesForm?.get('amountTobePaid')?.value && servicesForm?.get('amountTobePaid')?.value > 0 && getTotalAmount() > 0 && !(servicesForm?.get('amountTobePaid')?.value > getTotalAmount()) && servicesForm?.get('amountTobePaid')?.value !== getTotalAmount()">
                    <!-- <div class="row"> -->
                        <div class="form-group col-lg-6 col-md-6 col-sm-6">
                            <label>
                                <h5 style="color: red;">Balance Amount</h5>
                            </label><br />
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-6">
                            <label class="float-right">
                                <h5>$ {{getTotalAmount() - servicesForm?.get('amountTobePaid')?.value}}
                                </h5>
                            </label><br />
                        </div>
                    <!-- </div> -->
                </ng-container>
            
            </div>
        </ng-container>

        <!-- Normal Co Pay Payment -->
        <ng-conatiner *ngIf="getTotalAmount() > 0">
            <div class="check-payment-type" *ngIf="!displayPaymentSection">
                <div class="form-group">
                    <label>Payment Type</label><br />
                    <ng-select placeholder="Choose Payment Type" (change)="changePayType()" [items]="paymentType" [(ngModel)]="selectedPaymentType" bindLabel="desc" bindValue="value"></ng-select>
                    <!-- <label class="radio-inline" *ngFor="let item of paymentType">
                        <input type="radio" [(ngModel)]="selectedPaymentType" (change)="changePayType()" name="paymentType"
                            [value]="item?.value"><span class="desc">{{item.desc}}</span></label> -->
                </div>
                <!-- [disabled]="servicesForm?.get('coPayAmount')?.value <= 0 && servicesForm?.get('inHouseLabsAmount')?.value <= 0 && servicesForm?.get('consultationAmount')?.value <= 0 &&  servicesForm?.get('externalLabsAmount')?.value <= 0 &&  servicesForm?.get('otherAmount')?.value <= 0" -->
                <div class="form-group" style=" text-align: center;">
                    <button *ngIf="selectedPaymentType != null && selectedPaymentType != ''" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button" (click)="makePayment();"> Make Payment</button>
                </div>
            </div>
        </ng-conatiner>

        <!--  If there is any payment from Positive Credit getTotalAmount() <= 0 && -->
        <ng-conatiner *ngIf="totalCredits > 0 && selectedPastVisitCreditsTotal && getTotalAmount() <= 0 && getSelectedItemizedAmount() > 0">
            <div class="form-group" style=" text-align: center;">
                <button id="save-pay" class="btn btn-primary dash-actions mt-4" type="button" (click)="createCreditPayment()"> Make Credit Payment</button>
            </div>
        </ng-conatiner>

        <!-- With Termal Devices -->
        <div class="with-terminal" *ngIf="displayPaymentSection  && selectedPaymentType === 'terminal'">
            <div class="enter-amount" [formGroup]="amountForm">
                <div class="payment-section">
                    <span class="text-left payment-desc">Amount</span>
                    <span class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                </div>
            </div>
            <div class="form-group" style=" text-align: center;">
                <button *ngIf="!showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button"
                    (click)="createPosPayment()">Pay ${{amountForm?.controls['amount']?.value}}</button>

                <button *ngIf="showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button"
                    (click)="cancelPosPayment()">Cancel Payment</button>
            </div>
            
        </div>

        <!-- Without Terminal Device -->
        <div class="payment-body" *ngIf="displayPaymentSection && selectedPaymentType === 'noTerminal'">
            <div class="check-payment-type">
                <div class="form-group">
                    <label class="radio-inline" *ngFor="let item of paymentMode">
                        <input type="radio" [(ngModel)]="selectedPaymentMode" (change)="changePaymentMode()"
                            name="paymentMode" [value]="item?.value"><span class="desc">{{item.desc}}</span></label>
                </div>
            </div>
            <ng-continer *ngIf="selectedPaymentMode === 'Credit Card Payment'">
                <form id="payment-form">
                    <div class="enter-amount mb-4" [formGroup]="amountForm">
                        <div class="payment-section">
                            <span class="text-left payment-desc">Amount</span>
                            <span class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                        </div>
                    </div>
                    <!-- card details enter -->
                    <div id="card-container"></div>
                    <div class="credit-card-btns">
                        <div class="form-group" style=" text-align: center;">
                            <button *ngIf="!enableNonPosCancelPayment" id="card-button" class="btn btn-primary dash-actions" type="button">Pay ${{amountForm?.controls['amount']?.value}}</button>
                            <button *ngIf="enableNonPosCancelPayment" id="terminal-pay" class="btn btn-primary dash-actions" type="button" (click)="cancelPayment()">Cancel Payment</button>
                        </div>
                    </div>
                </form>
            </ng-continer>

            <form *ngIf="selectedPaymentMode === 'ACH Payment (Pay with Bank Account)'">
                <div class="enter-amount mb-4" [formGroup]="amountForm">
                    <div class="payment-section">
                        <span class="text-left payment-desc">Amount</span>
                        <span class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                    </div>
                </div>
                <div class="form-group" style=" text-align: center;">
                    <button id="ach-button" (click)="achPayment()" class="btn btn-primary dash-actions" type="button">Pay with Bank Account</button>
                </div>
            </form>
            
            <div *ngIf="selectedPaymentMode === 'Digital wallets'">
                <div class="enter-amount mb-4" [formGroup]="amountForm">
                    <div class="payment-section">
                        <span class="text-left payment-desc">Amount</span>
                        <span class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                    </div>
                </div>
                <div class="row" style="text-align: center;">
                    <form style="width: 45%;">
                        <button id="apple-pay-button" (click)="applePayOpen();"
                            class="btn btn-primary dash-actions">Apple
                            Pay</button>
                    </form>
                    <br />
                    <form style="width: 45%;">
                        <button (click)="googlePayOpen()" class="btn btn-primary dash-actions mb-3">Google Pay</button>
                        <br />
                        <div id="google-pay-button"></div>
                    </form>
                </div>
            </div>
        </div>

        <!-- With Termal Devices -->
        <div class="with-terminal" *ngIf="displayPaymentSection  && (selectedPaymentType === 'cashPayment' || selectedPaymentType === 'chequePayment' || selectedPaymentType === 'onlinePayment')">
            <div class="enter-amount" [formGroup]="amountForm">
                <div class="payment-section">
                    <span class="text-left payment-desc">Amount</span>
                    <span class="float-right payment-total"><b>${{amountForm?.controls['amount']?.value}}</b></span>
                </div>
            </div>
            <div class="form-group" style=" text-align: center;">
                <button *ngIf="!showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button" (click)="createCashPayment()">Pay ${{amountForm.controls['amount']?.value}}</button>
            </div>

            <!-- <button *ngIf="showPOSCancel" id="terminal-pay" class="btn btn-primary dash-actions mt-4" type="button"
                (click)="cancelPosPayment()">Cancel Payment</button> -->
        </div>
        <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <!-- <button type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="paymentModal.hide(); loadSpinner = false">Close</button> -->
    </div>
</app-ui-modal>


<!-- Success -->
<app-ui-modal #paySuccessModal [hideHeader]="true" [loadSpinner]="loadSpinner" [modalCentered]="true" id="paymentSuccessDailogue">
    <div class="app-modal-body">
        <div class="payment">
            <div class="payment_header">
                <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
            </div>
            <div class="content">
                <h1>Payment Success !</h1>
                <!-- <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. </p> -->
                <div class="payment-reciept" *ngIf="successPayDetails && successPayDetails?.paymentId" (click)="viewPaymentReciept(successPayDetails?.paymentId, payInvoiceModal)">View/Print Payment Reciept</div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="paySuccessModal.hide(); loadSpinner = false">Close</button>
    </div>
</app-ui-modal>

<!-- Payment Invoice -->
<app-ui-modal #payInvoiceModal [hideHeader]="false" [modalCentered]="true" id="payInvoiceModal">
    <div class="app-modal-body">
        <h5 class="modal-title">Payment Receipt</h5>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="payment-reciept">
            <iframe id="payment-reciept-embed" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="closeModal(); payInvoiceModal.hide(); loadSpinner = false">Close</button>
    </div>
</app-ui-modal>


<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>