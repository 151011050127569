import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';

@Component({
  selector: 'app-past-visits',
  templateUrl: './past-visits.component.html',
  styleUrls: ['./past-visits.component.scss']
})
export class PastVisitsComponent implements OnInit {

  @Input() public patientPastVisits: any;
  @Input() public pastVisitsModal: UiModalComponent;
  @Output() viewAppointmentDetails = new EventEmitter<any>();
  public selectedPrevisit: any;
 // public selectedChiefComplaints: any;
  //public codeMissingAssessments: any[] = [];
  

  constructor(private router: Router, private httpService: HttpService) { }

  ngOnInit(): void {
     
  }

  public appointmentPastVisit(pastVisit: any): void {
    this.selectedPrevisit = pastVisit.appointmentId;
    this.viewAppointmentDetails?.emit(pastVisit?.appointmentId);
	
	
	
    // this.pastVisitsModal?.hide();
    // this.router?.navigate([`/hospital/past-visit/${pastVisit?.appointmentId}`]);
  }
  
 /* public getAssessmentCodesDesc(patientPastVisit): void {
    let icdCodeForm = [];
    patientPastVisit?.carePlan?.forEach((formGroupItem: any) => {
      if(formGroupItem?.resourceType === 'General') {

        formGroupItem?.sections?.forEach((secFormGroup: any) => {
          if (secFormGroup && secFormGroup?.name === 'Assessment') {
            secFormGroup?.answers?.forEach((ansForm: any) => {
              if (secFormGroup?.name === 'Assessment' && ansForm?.label === 'Diagnosis Codes (ICD10)') {
                icdCodeForm = ansForm?.actualValue || [];
              }
            });
          }
        });
      }
      
    });

    if (icdCodeForm && icdCodeForm?.length === 0) {
      return;
    }

    if (icdCodeForm && icdCodeForm?.length > 0 && icdCodeForm[0]?.description) {
      return;
    }

    const icdCodes:Array<string> = icdCodeForm?.map(item => item?.code) || [];
    const action = `billingCodes/getICDCodesDesc`;
    this.httpService?.makeRequestWithAction(action,  icdCodes)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        this.codeMissingAssessments = data?.body?.responseObject || [];
      }
    },
      error => {
      });
  };
  
  public cmpareCodesDesc(icdCode: string, patientPastVisit:any): void {
  //this.getAssessmentCodesDesc(patientPastVisit);
    if (icdCode && this.codeMissingAssessments && this.codeMissingAssessments?.length > 0) {
      const codesDesc = this.codeMissingAssessments?.map((item: any) => {
        return {
          code: item?.code,
          description: item?.codeDescription || item?.description
        }
      });
      return this.codeMissingAssessments?.find(item => item?.code === icdCode)?.codeDescription;
    }
  }*/

}
