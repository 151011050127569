<div class="followup-queue-total">
    <app-card #caregapCard cardClass="card-datatable" class="new-designed-card" [options]="false">
        <!-- <div class="row" style="padding-bottom: 20px; padding-top:15px;">

            <div class="col-md-12 pull-right">
                <button class="flow-board-main-actions new-appointment-action" (click)="openFilterSection();">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                </button>
            </div>
        </div>  -->
        <div class="row">

            <div class="col-md-12 pull-right">
                <button class="flow-board-main-actions new-appointment-action" (click)="openFilterSection();">
                    <span class="material-symbols-outlined">
                        filter_alt
                    </span>
                </button>
            </div>
        </div>

        <div [ngClass]="{'col-collapse': openFilters}">


            <div class="table-responsive new-designed-table">
                <table datatable [dtOptions]="dtOptionsCarepath" [dtTrigger]="dtTriggerCarepath" id="report-table"
                    class="table table-striped table-hover custom-table">
                    <thead>
                        <tr>
                            <th>Patient Info</th>
                            <!-- <th>Primary Contact</th> -->
                            <th>Program</th>
                            <th>Conditions</th>
                            <th>Status</th>
                            <th>Enrolled Date</th>
                            <th>Previous Interactions </th>
                            <th>Last Interaction</th>
                            <th>Next Scheduled Date</th>
                            <th class="text-center no-sort">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="appointment-tr-queue" *ngFor="let caregap of caregapList; let i=index">
                            <td>
                                {{caregap?.lastName | titlecase}},
                                {{caregap?.firstName | titlecase}}
                                <div class="gender-patient-type" style="font-size: 12px;">
                                    <span><b>{{caregap?.gender || '--'}}</b></span> &nbsp;&nbsp;
                                    <span><b>{{caregap?.patientPrimaryPhone || '--'}}</b></span>

                                </div>
                            </td>
                           
                            <td>
                                <span *ngIf="caregap?.careProgram?.name === 'Chronic Care'">CCM</span>
                                <span *ngIf="caregap?.careProgram?.name !== 'Chronic Care'"> {{caregap?.careProgram?.name || '--'}}</span>
                            </td>
                            <!-- <td>{{getLastServiceDate(caregap?.patientCareGaps)}}</td> -->
                            <td>
                                <span class="condition" *ngIf="caregap?.careProgram?.conditions">
                                    {{getConditionslist(caregap?.careProgram?.conditions) || '--'}}</span>
                                <span *ngIf="!caregap?.careProgram?.conditions">--</span>

                            </td>
                            <td class="status-clr-code">

                                <span class="status-clr-code-main active"
                                    *ngIf="caregap?.careProgram?.status === 'Enrolled'">Active</span>
                                <span class="status-clr-code-main "
                                    [ngClass]="caregap?.careProgram?.status === 'Cancelled'  ? 'cancelled' : 'paused'"
                                    *ngIf="caregap?.careProgram?.status !== 'Enrolled'">
                                    {{caregap?.careProgram?.status || '--'}}</span>
                            </td>
                            <td>
                                <span *ngIf="caregap?.careProgram?.consentDate">{{ visitDateFormat(caregap?.careProgram?.consentDate) || '--'
                                    }}</span>
                                <span *ngIf="!caregap?.careProgram?.consentDate"> -- </span>
                            </td>
                            <td class="tooltip-show payment-tb">

                                <!-- <div class="tooltip-show"> -->
                                <div *ngIf="caregap?.careProgram?.name !== 'RPM'" class="dropdown">{{getCount(caregap?.visitDates) > 0 ?
                                    getCount(caregap?.visitDates):'--' }}

                                    <button class="dropbtn btn btn-popup" data-toggle="tooltip" style="color: #5CA6DB"
                                        id="error-info-bell" data-placement="top"
                                        *ngIf="getCount(caregap?.visitDates) > 0">
                                        <span class="material-symbols-outlined">info</span>
                                    </button>
                                    <div class="show-text" *ngIf="getCount(caregap?.visitDates) > 0">
                                        <p
                                            style="color: #000; font-weight: 600; margin-bottom: 0px; padding-left: 8px;">
                                            Past Visits</p>
                                        <ul class="chief-complaints-list"
                                            style="white-space: normal; max-height: 60px;">
                                            <li *ngFor="let date of caregap?.visitDates" [innerHTML]="date"></li>
                                        </ul>
                                    </div>

                                </div>
                                <div *ngIf="caregap?.careProgram?.name === 'RPM'">--</div>

                                <!-- </div> -->

                                <!-- <button type="button" class="btn btn-outline btn-popup" data-toggle="tooltip"
                                    data-placement="top">
                                    {{caregap?.visitDates.length || '--'}}
                                </button>
                                <div class="show-text">
                                    <ul class="chief-complaints-list" style="white-space: normal;">
                                        <li *ngFor="let date of caregap?.visitDates" [innerHTML]="date"></li>
                                    </ul>
                                </div> -->


                            </td>
                            <td>
                                <span
                                    *ngIf="caregap?.careProgram?.name !== 'RPM' && caregap?.lastVisitDate">{{visitDateFormat(caregap?.lastVisitDate)}}</span>
                                <span *ngIf="caregap?.careProgram?.name === 'RPM' || !caregap?.lastVisitDate">-- </span>


                            </td>
                            <td>
                                <span
                                    *ngIf="caregap?.careProgram?.name !== 'RPM' && caregap?.nextVisitDate">{{visitDateFormat(caregap?.nextVisitDate)
                                    }}</span>
                                <span *ngIf="caregap?.careProgram?.name === 'RPM' || !caregap?.nextVisitDate">-- </span>

                            </td>

                            <!-- <td>
                                {{caregap?.status || '--'}}    
                            </td> -->
                            <td class="text-center">
                                <div class="dropdown-actions">
                                    <button
                                        class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}"
                                        id="claimActions_btn" (click)="openUniversalActions($event, i);">...</button>
                                    <div class="dropdown-content-actions"
                                        *ngIf="openActions && selectedActionRow === i">
                                        <a (click)="openNotify(caregap?.masterPatientId);closeUniversalActions();"
                                            style="cursor: pointer;"><i class="lab la-telegram"></i>Notify</a>
                                        <a [ngClass]="caregap?.careProgram?.name === 'RPM'   ? 'disabled' : ''"  (click)="openSchedule(caregap?.masterPatientId);closeUniversalActions();"
                                            style="cursor: pointer;"><span
                                                class="material-symbols-outlined">history</span> Schedule</a>
                                        <a (click)="modifyUserData(caregap);closeUniversalActions();"
                                            style="cursor: pointer;"><i class="las la-user-circle"></i>Chart</a>
                                        <a [ngClass]="caregap?.careProgram?.status === 'Cancelled'   ? 'disabled' : ''" (click)="openProgramChange(programChange,caregap);closeUniversalActions();"
                                            style="cursor: pointer;"><span class="material-symbols-outlined">
                                                source_notes
                                            </span>Program
                                            Changes</a>
                                        <!--<a (click)="openNotes()"><i class="las la-tasks"></i> Notes</a>-->
                                    </div>
                                </div>

                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
            <div class="summary-card" style="margin-top: 20px; padding-bottom: 50px;">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <h4 class="Summary" style="padding-top: 0px;">Summary</h4>
                                <div class="vl"></div>
                            </div>
                            <div class="col-md-3">
                                <div class="Count">{{enRolledCount}}</div>
                                <div class="summary-name">Active Enrollments</div>
                                <div class="vl"></div>
                            </div>
                            <div class="col-md-3">
                                <div class="Count">{{pausedCount}}</div>
                                <div class="summary-name">Paused</div>
                                <div class="vl"></div>
                            </div>
                            <div class="col-md-3">
                                <div class="Count">{{cancelledCount}}</div>
                                <div class="summary-name">Cancelled</div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div [formGroup]="filtersForm">

            <div class="filters-col" *ngIf="openFilters">
                <h5>Filters <span class="close pull-right" (click)="closeFiltersection()">&times;</span></h5>
                <!-- <div class="form-group">
                    <label>Cohort Pannel</label>
                    <ng-select placeholder="Choose Cohort Pannel" id="cohort-list" [items]="cohortsList"
                        (change)="getCaregaps($event)" formControlName="cohort"></ng-select>



                </div>
                 -->
                <div class="form-group">
                    <label>CCM Program  <span class="text-danger">*</span></label>

                    <ng-select placeholder="Choose CCM Program" id="caregap-list" [multiple]="true"
                        [items]="caregapItems" formControlName="careGaps" (change)="enableFilters($event)"></ng-select>

                    <label class="text-danger"
                        *ngIf="filtersForm.controls['careGaps'].hasError('required') && filtersForm.controls['careGaps'].touched">
                        CCM Program is required</label>

                </div>
                <div class="form-group">
                    <label>Enrolled Start Date</label>
                    <input type="date" class="form-control input-text" name="enrollDateStart"
                        formControlName="enrollDateStart">
                </div>
                <div class="form-group">
                    <label>Enrolled End Date</label>
                    <input type="date" class="form-control input-text" name="enrollDateEnd"
                        formControlName="enrollDateEnd">
                </div>
                <div class="form-group">
                    <label>Status</label>

                    <ng-select placeholder="Choose Status" id="carepathwaysList_status" [items]="ccmStatusList"
                        formControlName="status" bindLabel="desc" bindValue="value"
                        (change)="enableFilters($event)"></ng-select>

                </div>

                <div class="form-group">
                    <label>Gender</label>

                    <ng-select placeholder="Choose Gender" id="carepathwaysList_gender" [items]="genderList"
                        formControlName="gender" bindLabel="desc" bindValue="value" (change)="enableFilters($event)"></ng-select>
                </div>

                <!-- <div class="form-group">
                    <label>Last Visit Date</label>
                    <input type="date" formControlName="lastVisitDate" class="form-control input-text">

                </div>-->


                <div class="form-group text-center">
                    <button class="btn btn-primary" id="carepathwaysList_submit"
                        (click)="getSearchList()">Submit</button>
                    <button class="btn btn-primary ml-2" id="carepathwaysList_clear" *ngIf="filterEnabled"
                        (click)="clearFilters()">Clear</button>

                </div>
            </div>
        </div>

    </app-card>
</div>



<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>
<app-schedule-followup-appointment [appointmentId]="selectedAppointmentId"></app-schedule-followup-appointment>
<app-followup-notification></app-followup-notification>
<app-send-sms-notify [pageType]="'Chart'"></app-send-sms-notify>


<!-- Parogram change -->
<app-ui-modal #programChange [hideHeader]="false" [modalCentered]="true" id="programChange">
    <div class="app-modal-header">
        <h5>Program Changes</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="programChange?.hide();resetForm();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" [formGroup]="updateCareProgramForm">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label>Status <span class="text-danger">*</span></label>
                    <ng-select placeholder="Choose Status" formControlName="status" id="carepathwaysList_status2"
                        [items]="programChangeStatusList" bindLabel="desc" bindValue="value"></ng-select>
                    <label class="text-danger"
                        *ngIf="updateCareProgramForm.controls['status'].hasError('required') && updateCareProgramForm.controls['status'].touched">
                        Status is required</label>

                </div>
            </div>
            <!--<div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                    <label>Updated Date</label>
                    <input type="date" class="form-control input-text">
                </div>
            </div>-->
        </div>
        <div class="row mt-3">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label>Reason <span class="text-danger">*</span></label>
                    <textarea class="form-control input-text-reason" type="text" formControlName="reason"
                        placeholder="Write Reason" id="reason"></textarea>
                    <label class="text-danger"
                        *ngIf="updateCareProgramForm.controls['reason'].hasError('required') && updateCareProgramForm.controls['reason'].touched">
                        Reason is required</label>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="carepathwaysList_ok" (click)="updateProgram(programChange);">Save</button>
    </div>
</app-ui-modal>