<div class="emergency-contact-details" [formGroup]="emergencyDetailsForm">

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <!-- <button type="button" style="float: right;" (click)="addEmergencyContact()" class="btn btn-primary">+ Add
                Another
                Contact</button> -->
            <div class="header-repeater">
                <h5 class="header">Emergency Contact Details</h5>
                <a type="button" id="emergency-contact-details-add" (click)="addEmergencyContact()"
                    *ngIf="emergencyDetailsForm?.get('emergencyDetails')?.value?.length < 5">
                    <i class="las la-plus-circle" style="font-size: 30px; color: #5CA6DB; cursor: pointer;"></i>
                </a>
            </div>
        </div>
    </div>
    <hr />

    <div class="formarray" formArrayName="emergencyDetails">
        <ng-container
            *ngFor="let contactForm of emergencyDetailsForm?.get('emergencyDetails')?.controls; let i = index">
            <div class="row insurance-items mt-2" [formGroupName]="i">

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- <div class="delete del-icon float-right d-flex" placement="left"
                        ngbTooltip="Delete Contact Details">
                        <i class="las la-trash-alt" id="emergency-contact-details-delete"
                            (click)="openDeleteContactConfirm(i, confirmContactCardDelete)"></i>
                    </div> -->
                    <button class="icon-queue-events delete-action-bg clearfix float-right d-flex" placement="left"
                        ngbTooltip="Delete Emeregency Contact Details">
                        <i class="las la-trash-alt " id="addl-insu-delete-btn"
                            (click)="openDeleteContactConfirm(i, confirmContactCardDelete)"></i>
                    </button>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label for="Name"> Name </label>
                        <input class="form-control input-text" type="text" formControlName="name" placeholder="Name "
                            (blur)="onEmergencyDetailsChange(contactForm)" id="emergency-contact-details-name" />
                        <span class="text-danger"
                            *ngIf="contactForm?.get('name').invalid && !contactForm?.get('name').errors?.required">Please
                            enter a valid Name</span>
                        <span class="text-danger"
                            *ngIf="contactForm?.get('name').errors?.required && contactForm?.get('name').touched">
                            Please enter a Name
                        </span>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label for="Relationship"> Relationship </label>
                        <!-- <div class="input-group mb-0">-->
                        <ng-select placeholder="Select Relationship" id="emergency-contact-details-relationship"
                            formControlName="relationship" [items]="relationshipList" bindLabel="desc" bindValue="value"
                            (change)="onEmergencyDetailsChange(contactForm)"></ng-select>

                        <!-- </div>-->
                        <span class="text-danger"
                            *ngIf="contactForm?.get('relationship').errors?.required && contactForm?.get('relationship').touched">
                            Please select Relationship
                        </span>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label for="EmergencyContact"> Emergency Contact </label>
                        <input class="form-control input-text" type="text" mask="(000) 000-0000"
                            (blur)="onEmergencyDetailsChange(contactForm)" placeholder="(xxx) xxx-xxxx"
                            id="emergency-contact-details-contact" formControlName="phone" />
                        <span class="text-danger"
                            *ngIf="contactForm?.get('phone')?.invalid && !contactForm?.get('phone')?.errors?.required">Please
                            enter a valid Phone number</span>
                        <span class="text-danger"
                            *ngIf="contactForm?.get('phone').errors?.required && contactForm?.get('phone').touched">
                            Please enter Emergency Contact
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- confirm the delete insurance -->
<app-ui-modal #confirmContactCardDelete [hideHeader]="false" [modalCentered]="true" id="confirmContactCardDelete">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Are you sure want to delete Emergency Contact Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="confirmContactCardDelete?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">
                <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete Emergency Contact
                    Details?</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" id="emergency-contact-details-yes" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="confirmContactCardDelete?.hide(); removeContactDetails(contactDeleteIndex)">Yes</button>
        <button type="button" id="emergency-contact-details-no" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="confirmContactCardDelete.hide()">No</button>
    </div>
</app-ui-modal>