import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../../../theme/shared/services/http.service';
import { DataService } from '../../../../theme/shared/services/data-sharing.service';
import { interval, Subject, Subscription } from 'rxjs';
import { UiModalComponent } from '../../../../theme/shared/components/modal/ui-modal/ui-modal.component';
import { DataTableDirective } from 'angular-datatables';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';

@Component({
  selector: 'app-careflow-queue',
  templateUrl: './careflow-queue.component.html',
  styleUrls: ['./careflow-queue.component.scss']
})
export class CareflowQueueComponent implements OnInit, AfterViewInit, OnDestroy {
  public dtPatientDetails: any = [];
  public dtOptions: DataTables.Settings = {};
  public loginDetails: any;
  public dtTrigger: Subject<any> = new Subject<any>();
  public cancelForm: FormGroup;
  @ViewChild(UiModalComponent) modalComponent: UiModalComponent;
  public cancelAppointmentId: any;
  public testResultsForm: FormGroup;
  public testResultsPatient: any;
  public allTestResults: any;
  public testResultsBasedTestName: any;
  public LateNotifyForm: FormGroup;
  public lateNotifyPatient: any;
  public waitTimeDetails: any;
  public qrWebToken: FormGroup;
  public invalidQrToken: boolean;
  public addNewPatientForm: FormGroup;

  getAppointmentSlotsAvailable: any;
  public contactSearchForm: FormGroup;
  contactSearchResults: any[];
  selectedExistingContact: any;
  public demographicForm: FormGroup;
  public insuranceForm: FormGroup;
  public patientsListSubscription: Subscription;
  public rescheduleForm: FormGroup;
  public getAppointmentTimeReschedule: any;
  public rescheduleAppntData: any;
  public testResultsModal: any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public notifyText: any;
  public isSuccessNotify: boolean;
  public delayNotificationTypes = [
    { value: 'General Notification', desc: 'General Notification' },
    { value: 'Delay Notification', desc: 'Delay Notification' },
  ]
  public addAppointmentClick: boolean;
  isUrgentCare: any;
  public minAppointmentDate: Date;
  @ViewChild('TreatmentFlowCard') TreatmentFlowCard: CardComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild('securedEditConfirmation') securedEditConfirmation?: UiModalComponent;
  @ViewChild('removeLockConfirmation') removeLockConfirmation?: UiModalComponent;

  public selectedAppointmentEdit: any;
  @ViewChild('signoffLockEditConfirmation') signoffLockEditConfirmation?: UiModalComponent;
  // public constantFlags = AppConstantsListConfig;
  @ViewChild('lockAppointment') private lockAppointment: UiModalComponent;
  @ViewChild('lockAppointmentEmpty') private lockAppointmentEmpty: UiModalComponent;

  public lockUnlockCheck: boolean;
  allUsersList: any[];
  public selectedViewAppointmentId: any;
  public selectedAppointmentId: any;
  @Input() appointmentInfoDetails: any;
  public showList: boolean = true;
  public removeLockMsg: any;
  public noLockUser: boolean = false;
  public selectedProvider: any;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  public oldAppointmentIdRef : any;
  public roomAssigned: any;
  public roomNumbers: any;
  public statusType: any;
  public updateType: any;
  public ConsultationStatus = [
    { value: 'TRIAGE', desc: 'Triage' },
    { value: 'CONSULTATION', desc: 'Consultation' }
  ] 
  public selectedAppointmentForRoom:any;
  public roomNumberForm: FormGroup;
  public roomStatusForm: FormGroup;
  public statusAssigned:any;
  public loadSpinner: boolean;
  @Output() unlockAllModules = new EventEmitter<any>();  

  constructor(
    public httpService: HttpService,
    public dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public _fb: FormBuilder) {

    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.getAllUsersList();
    this.roomNumbers = JSON.parse(sessionStorage.getItem('roomNumbers')) || [];     
    this.roomNumberForm = this._fb?.group({
      'roomNumber': [null, Validators?.required]
    });    
    this.roomStatusForm = this._fb?.group({
      'examStatus': [null, Validators?.required]
    });   

  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 3,
      autoWidth: true,
      lengthChange: false,
      searching: false,
      order: [[6, "desc"]],
      columnDefs: [{ targets: [6], type: 'num', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    //console.log(this.loginDetails);
    // this.getPatientsByFacilityId(this.loginDetails && this.loginDetails.facilityId);
    // this.getPatientsByCompleted(this.loginDetails && this.loginDetails.facilityId);
    // this.isShowHideElements = this.httpService?.screenItemsShowHide(this.constantFlags?.navUiScreenItems) || {};
    this.minAppointmentDate = this.httpService.convertDate(new Date());

    //call refersh function for every 5 mins
    const source = interval(200000);
    this.patientsListSubscription = source.subscribe(val => { this.refresh() });

    // to get the appointment id from the url
    this.activatedRoute.params.subscribe((data) => {
      this.selectedAppointmentId = data?.id;


    });
    this.roomNumbers = JSON.parse(sessionStorage.getItem('roomNumbers')) || [];

  }

  ngAfterViewInit(): void {
    this.TreatmentFlowCard.cardRefreshShow();
    setTimeout(() => {
      this.getPatientsByFacilityId(this.loginDetails && this.loginDetails.facilityId);
    }, 1000);

    //this.dtTrigger.next();

  }

  get isShowHideElements(): any {
    const allItems = JSON.parse(sessionStorage?.getItem('allProvideLoginNavItems'));
    const getScreenItem = allItems?.find(item => item?.coreFeature === 'Care Flow');
    return getScreenItem?.childFeature || {};
  }

  // this is for get the FacilityID details..
  public getPatientsByFacilityId(facilityid: any): void {
    if (!facilityid || !JSON.parse(sessionStorage.getItem('userDetails'))) {
      return;
    }
    let action: any = `appointment/registration/statusByFacilityId?facilityId=${facilityid}`;
    // let action: any = `appointment/registration/statusByFacilityId?facilityId=${facilityid}&doctorNpi=${this.loginDetails?.providerNPI}`;
    // let params: any = {
    //   'id': facilityid
    // }
    this.TreatmentFlowCard.cardRefreshShow();
    this.httpService.getPatientsByFacilityId(action, '')
      .subscribe((data: any) => {
        if (data.status == 'SUCCESS') {
          //this.dtPatientDetails = data.resultList || [];
          const patientList = data.resultList || [];
          this.dtPatientDetails = patientList?.filter((cItem: any) => cItem?.appointmentStatus?.currentStatus !== 'CANCELLED');

          sessionStorage.setItem('token', JSON.stringify(data));
          this.dtTrigger.next();
        }
        this.TreatmentFlowCard.cardRefreshHide();
      },
        (error) => {
          this.TreatmentFlowCard.cardRefreshHide();
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal.modalShow();
          this.dtTrigger.next();
        });
  }

  // this is for get the all completed FacilityID details..
  public getPatientsByCompleted(facilityid: any): void {
    let action: any = 'appointment/registration/byCompleted?facilityId=';
    let params: any = {
      'id': facilityid
    }
    this.httpService.getPatientsByFacilityId(action, params.id)
      .subscribe((data: any) => {
        if (data.status == 'SUCCESS') {
          // this.facilityData = data;
          this.dtPatientDetails = [...this.dtPatientDetails, ...data.resultList] || [];
          // this.dtOptions.data = this.allAppointments || [];
          sessionStorage.setItem('token', JSON.stringify(data));
        } else {
          let toastrData = AppConstantsListConfig?.uiErrorException;
        }
      }, error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
      });
  }

  public refresh(): void {
    this.dtPatientDetails = [];
    this.rerender();
    this.getPatientsByFacilityId(this.loginDetails && this.loginDetails.facilityId);
    // this.getPatientsByCompleted(this.loginDetails && this.loginDetails.facilityId);
  }

  public rerender(): void {
    this.dtElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
      //  this.dtTrigger.next(); 
    });
  }
  public async redirect(appointment: any) {
    this.selectedAppointmentEdit = appointment;

    if (appointment?.signoffLock) {
      this.securedEditConfirmation?.show();
      return;
    }

    this.httpService.clearTreatmentFlowSession();

    //this.router.navigate([`/hospital/hosp-patientdetails/${appointment?.id}`]);
    sessionStorage.setItem('careflowPath', 'carflowQueue');
    this.router.navigate([`/hospital/hosp-patientdetails/${appointment?.id}`])
      .then(() => {
        window.location.reload();
      });

  }

  public getAppointmentTime(date: any) {
    return this.httpService.getAppointmentTime(date);
  }

  public addApptNavigate(): void {
    this.router.navigate(['/hospital/addAppt']);
    sessionStorage.setItem('navigateAddAppnt', 'treatmentFlow');
  }

  // appointment status showing
  public getStatus(status: string, appointment: any): string {
    return this.httpService.getStatus(status, appointment);
  }

  // due to timezone is added in dob, split and 
  public formatDob(dob: string) {
    let split = dob?.includes('T') ? dob?.toString()?.split('T') : dob;
    split = split && split[0] ? split[0] : split;
    return split || '';
  }

  // get doctor name from list
  public doctorNameWithNpi(appointment: any): string {
    if (appointment?.authorized_official_first_name && appointment?.authorized_official_last_name) {
      return `${appointment?.authorized_official_first_name} ${appointment?.authorized_official_last_name}` || '--';
    }
    return '--'
  }

  // Lock Unlock the record from signoff
  public removelockSignoff(patientDetails?: any, isConfirm?: boolean): void {
    let action: string;
    this.selectedAppointmentEdit = patientDetails;
    action = `treatmentFlow/removeSignoffLock?appointmentId=${patientDetails?.id}`;
    if (!patientDetails?.signoffLock) {
      action = `treatmentFlow/setSignoffLock?appointmentId=${patientDetails?.id}`;
    }
    if (isConfirm) {
      this.securedEditConfirmation?.hide();
	  // this.closeLock(this.oldAppointmentIdRef, false, true);
    }
    this.TreatmentFlowCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.msg || data?.body?.message;
        this.isSuccessNotify = true;
        this.removeLockMsg = data?.body?.msg || data?.body?.message;
        /*if (isConfirm) {
          this.router.navigate([`/hospital/hosp-patientdetails/${patientDetails?.id}`])
            .then(() => {
              window.location.reload();
            });

        } else {
          this.exceptionModal?.modalShow();
        }
        this.refresh();
    */
        this.removeLockConfirmation?.show();
      } else {
        this.notifyText = data?.body?.msg || data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();

      }
      this.TreatmentFlowCard?.cardRefreshHide();
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.TreatmentFlowCard?.cardRefreshHide();
    });
  }

  public ConfirmLockRemove(patientDetails?: any) {
    
    sessionStorage.setItem('careflowPath', 'carflowQueue');
    this.router.navigate([`/hospital/hosp-patientdetails/${patientDetails?.id}`])
      .then(() => {
        window.location.reload();
      });
    this.refresh();

  }

  public getChiefComplaintsSectionHeight(cc: any){
    let height = null;
	 if(cc === null || cc === undefined){
	 return;
	 }
	 
	if(cc.length === 1){
	  height = '47px' ;
	}
	else if(cc.length === 2){
	 height = '68px' ;
	}
	else if(cc.length === 3){
	height = '90px' ;
	}
	else if(cc.length > 3){
	height = '105px' ;
	}
	
	return height;
  
  }

  // Appointment View Details
  public viewAppointment(id: any) {
    this.httpService.clearTreatmentFlowSession();
    this.router?.navigate([`/hospital/past-visit/${id}`]);
  }

  public viewAppointmentDetails(id: any, viewDetailsModal: UiModalComponent) {
    this.httpService.clearTreatmentFlowSession();
    this.selectedViewAppointmentId = id;

    viewDetailsModal?.show();
  }

  public navigateToExam(): void {
    //this.lockUnLocakAppointment(true, false);
    sessionStorage.setItem('careflowPath', 'carflowQueue');
    this.router.navigate([`/hospital/hosp-patientdetails/${this.selectedAppointmentEdit?.id}`])
      .then(() => {
        window.location.reload();
      });


  }


  //lock and unclock the appointmnent while user in Exam room for other user
  public lockUnLocakAppointment(appointment, userLock?: boolean, userUnLock?: boolean): void {
    this.selectedAppointmentEdit = appointment;
    if (appointment?.signoffLock) {
      this.oldAppointmentIdRef = this.selectedAppointmentId;
      this.securedEditConfirmation?.show();
      return;
    }

    this.TreatmentFlowCard?.cardRefreshShow();
    this.unlockAllModules?.emit(true);
    setTimeout(() => {
      sessionStorage.setItem('careflowPath', 'carflowQueue');
      this.router.navigate([`/hospital/hosp-patientdetails/${appointment?.id}`])
        .then(() => {
          window.location.reload();
        });
      this.TreatmentFlowCard?.cardRefreshHide();
    }, 1000);

    // const action = `appointment/registration/lockAppointmentUsage?appointmentId=${this.selectedAppointmentEdit?.id}&lock=${userLock}&unlock=${userUnLock}`;
    // this.TreatmentFlowCard?.cardRefreshShow();
    // this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
    //   this.TreatmentFlowCard?.cardRefreshHide();
    //   this.lockUnlockCheck = data?.body;
    //   if (data?.body === false) {
    //     if (this.selectedAppointmentEdit?.lockUser != null && this.selectedAppointmentEdit?.lockUser != '') {
    //       this.noLockUser = false;
    //       this.lockAppointment?.show();
    //       return;
    //     }
    //     else {
    //       this.noLockUser = true;
    //       this.lockAppointmentEmpty.show();
    //       return;
    //     }
    //   }
    //   else {

    //     this.httpService.clearTreatmentFlowSession();
    //     if (this.selectedAppointmentId !== appointment.id) {
    //       this.closeLock(this.selectedAppointmentId, false, true);
    //     }
    //     //this.router.navigate([`/hospital/hosp-patientdetails/${appointment?.id}`]);
    //     this.TreatmentFlowCard?.cardRefreshShow();
    //     setTimeout(() => {
    //       sessionStorage.setItem('careflowPath', 'carflowQueue');
    //       this.router.navigate([`/hospital/hosp-patientdetails/${appointment?.id}`])
    //         .then(() => {
    //           window.location.reload();
    //         });
    //       this.TreatmentFlowCard?.cardRefreshHide();
    //     }, 1000);
    //   }

    // }, error => {
    //   return false;
    // });
  }

  public getUserName(userId?: string): string {

    let name: string;
    if (userId) {
      return this.allUsersList?.find(item => item?.userId === userId)?.name || userId;
    }
    return name = userId;
  }


  public getAllUsersList(): void {
    const action = `provider/getAllProviders/${this.loginDetails?.facilityId}`;
    this.allUsersList = [];
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const allUsers = data?.responseObject?.filter(item => item?.active) || [];
        const allDoctors = allUsers?.map((doctorItem: any) => {
          const firstName = doctorItem?.firstName || '';
          const lastName = doctorItem?.lastName || '';
          return {
            name: `${firstName} ${lastName}`,
            npi: doctorItem?.providerNPI,
            userId: doctorItem?.emailId,
            role: doctorItem?.roleType
          }
        }) || [];
        this.allUsersList = allDoctors || [];
      }
    },
      (error) => {
      });
  }

  public getUserRole(userId?: string): string {
    let name: string;
    if (userId) {
      return this.allUsersList?.find(item => item?.userId === userId)?.role || userId;
    }
    return name = userId;
  }

  private closeLock(appointmentId: any, userLock?: boolean, userUnLock?: boolean): void {
    const action = `appointment/registration/lockAppointmentUsage?appointmentId=${appointmentId}&lock=${userLock}&unlock=${userUnLock}`;
    //this.TreatmentFlowCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      // this.TreatmentFlowCard?.cardRefreshHide();
      this.lockUnlockCheck = data?.body;


    }, error => {
      //this.TreatmentFlowCard?.cardRefreshHide();

    });
  }

  public showHideList() {
    this.showList = !this.showList;
  }



  public ConvertStringToNumber(input: string) {
    var numeric = Number(input);
    return numeric;
  }

  public refreshQueueList() {
    sessionStorage.setItem('careflowPath', 'carflowQueue');
    this.router.navigate([`/hospital/hosp-patientdetails/${this.selectedAppointmentId}`])
      .then(() => {
        window.location.reload();
      });
  }


  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    if (this.dtTrigger) {
      this.dtTrigger?.unsubscribe();
    }
    if (this.patientsListSubscription) {
      this.patientsListSubscription?.unsubscribe();
    }
    //this.closeLock(this.selectedAppointmentId, false, true);
  }

  public sendNotification(appointment?: any): void {
    this.sendNotifyComp?.showNotifyModalForEncounter(appointment, 'user');
  }

 // update room number
 public openUpdateRoomNumber(appointment: any, updateRoomNumber: UiModalComponent): void {
  this.selectedAppointmentForRoom = appointment;
 const roomVal = appointment?.appointmentStatus?.roomAssigned || null;
 this.roomNumberForm?.get('roomNumber').setValue(roomVal);
  updateRoomNumber?.show();
}
public submitUpdateRoomNumber(roomNumber: UiModalComponent): void {
  // if(!this.roomAssigned) {
  //   this.roomNumberRequired = true;
  //   return;
  // }
  // this.roomNumberRequired = false;
  if (this.roomNumberForm?.invalid) {
    this.roomNumberForm?.markAllAsTouched();
    return;
  }
  this.roomAssigned = this.roomNumberForm?.get('roomNumber')?.value;
  var action = `appointment/registration/updateRoomAssigned?appointmentId=${this.selectedAppointmentForRoom?.id}&roomAssigned=${this.roomAssigned}`;
  if(this.roomAssigned){
    action = action+`&status=TRIAGE`
  }
  this.loadSpinner = true;
  this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
    roomNumber?.hide();
    this.notifyText = data?.body?.message || data?.body?.msg || 'Consultation Room has been updated successfully.';
    this.isSuccessNotify = true;
    this.exceptionModal?.modalShow();
    this.loadSpinner = false;
    this.roomAssigned = null;
    this.roomNumberForm?.reset();
    this.refresh();
  },
    error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
    })
}
public resetroomnum(){
  this.roomNumberForm?.reset();
}
public resetStatus(){
  this.roomStatusForm?.reset();
}
public openUpdateStatus(appointment: any, updateExamStatus: UiModalComponent): void {
  this.selectedAppointmentForRoom = appointment;
 const statusVal = appointment?.appointmentStatus?.currentStatus || null;
 this.roomStatusForm?.get('examStatus').setValue(statusVal);
 updateExamStatus?.show();
}
public submitUpdateStatus(examStatus: UiModalComponent): void {
  // if(!this.roomAssigned) {
  //   this.roomNumberRequired = true;
  //   return;
  // }
  // this.roomNumberRequired = false;
  if (this.roomStatusForm?.invalid) {
    this.roomStatusForm?.markAllAsTouched();
    return;
  }
  this.statusAssigned = this.roomStatusForm?.get('examStatus')?.value;
  var action = `appointment/registration/updateRoomAssigned?appointmentId=${this.selectedAppointmentForRoom?.id}&roomAssigned=${this.selectedAppointmentForRoom.appointmentStatus?.roomAssigned}`;
  if(this.statusAssigned){
    action = action+`&status=${this.statusAssigned}`
  }
  this.loadSpinner = true;
  this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
    examStatus?.hide();
    this.notifyText = 'Exam Status has been updated successfully.';
    this.isSuccessNotify = true;
    this.exceptionModal?.modalShow();
    this.loadSpinner = false;
    this.roomAssigned = null;
    this.roomStatusForm?.reset();
    this.refresh();
  },
    error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
    })
}
}
