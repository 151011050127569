import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventEmitter } from 'protractor';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    public providerSuggestLoaction: any = {};
    public urgentCareCards: any ;
    
    public applicationid: any;
    public loginDetails: any;
    loginStatus: boolean = false;
    facilitydetailsData: any;

    public allData: any = {
        'isLogin' : false,
        'facilitydetails': [],
        'waitTime' : {},
        'allDoctors': [],
        'eligibilityMessageArray': [],
        'allAppointments': [],
        'setPatientHealthData': {},
        'selectedCardTitle': '',
        'selectedEstablishedPatient': {}
    }

    private defaultData = new BehaviorSubject(this.allData);
    currentMessage = this.defaultData.asObservable();
    allAppointments: any;
    public dataTable = new BehaviorSubject(false);

    constructor(public router: Router) { }

    ProviderSuggestLocationSharing(provider) {
        this.providerSuggestLoaction = provider;
    }
    getProviders(){
        return this.providerSuggestLoaction;
    }
    getUrgentCard(){
        return this.urgentCareCards;
    }
    setApllicationid(id:any, page){
        this.applicationid = id;
        this.allData.pageView = page;
        this.defaultData.next(this.allData);
    }
    getApplicationid(){
        return this.applicationid;
    }

    setLoginDetails(logindetails){
        this.loginDetails = logindetails
    }

    getLoginDetails(){
        return this.loginDetails
    }

    public setIsLogin(status: boolean){
        this.allData.isLogin = status;
        this.defaultData?.next(this.allData);
    }

    getIsLogin(){
       return this.loginStatus
    }  

    setFacilityDetails(facility){
        this.allData.facilitydetails = facility;
        this.defaultData.next(this.allData);
        let provider = facility[0];
        let allDoctors = provider.eligibilityDetails && provider.eligibilityDetails.doctor;
        this.setAllDcotors(allDoctors || []);
    }

    getFacilityData(){
        return  this.facilitydetailsData;
    }

    setFacilityWaitTime(waittime){
        this.allData.waitTime = waittime;
        this.defaultData.next(this.allData)
    }
    setAllAppointmrnts(appointmnets){
        this.allAppointments = appointmnets;
        this.allData.allAppointments = appointmnets;
        this.defaultData.next(this.allData)
    }

    setEligibility(flag: Boolean, object: any){
        this.allData.eligibility = flag;

        this.allAppointments.forEach(element => {
            if(element.id === object.appId){
                element.eligibilityStatus = object.message;
            }
        });
        // if(this.allData && this.allData.eligibilityMessageArray && this.allData.eligibilityMessageArray.length > 0){
        //     this.allData.eligibilityMessageArray.forEach(element => {
        //         if(element.appId === object.appId){
        //             element = object;
        //         }else{
        //             let array = this.allData.eligibilityMessageArray || [];
        //             array.push(object);
        //             this.allData.eligibilityMessageArray = array;
        //         }
        //     });
        // }
        this.defaultData.next(this.allData)
    }

    logout(){
        sessionStorage.removeItem('token');
        this.router.navigate(['/home/provider/signin']);
        this.allData.isLogin = null;
        this.defaultData.next(this.allData);
    }

    public setAllDcotors(doctors){
        this.allData.allDoctors = doctors || [];
        this.defaultData.next(this.allData)
    }

    setForPatientHealthData(patientData){
        this.allData.setPatientHealthData = patientData || {};
        this.defaultData.next(this.allData)
    }
    setAllQuestions(ques){
        this.allData.allQuestions = ques || [];
        this.defaultData.next(this.allData)
    }
    setAllQuestionsAnswers(queAns){
        this.allData.allQuestionsAnswers = queAns || [];
        this.defaultData.next(this.allData)
    }

    public setCardTitle(title: string): void{
        this.allData.selectedCardTitle = title;
        this.defaultData.next(this.allData);
    }

    public setEstablishedPatient(patient: any): void{
        this.allData.selectedEstablishedPatient = patient;
        this.defaultData.next(this.allData);
    }
}
