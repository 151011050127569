<div class="doc-upload-com" id="new-common-doc-upload">
    <div class="row">
        <div class="col-md-12 alert alert-success" *ngIf="successMessageShown">
            {{successMessageShown}}
        </div>
        <div class="col-md-12 alert alert-danger" *ngIf="errorMessageShown">
            {{errorMessageShown}}
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
           <!-- <form>
                <div class="item-inner">
                    <div class="item-content">
                        <div class="image-upload">
                            <label style="cursor: pointer;width: 100%;">
                                <img class="img-upload" alt="Document Upload"
                                src="../../../../../../assets/images/ic_image_upload.svg" />
                                <div class="h-100">
                                    <div class="dplay-tbl">
                                        <div class="dplay-tbl-cell">
                                            <h5 class="upload-title"><b>Choose Documents to
                                                    Upload</b></h5>
                                          
                                        </div>
                                    </div>
                                </div>
                                <input type="file" (change)="uploadFileSelect($event)" class="custom-file-input"
                                        id="chart-acc-additonal-documents-upload" #newFileUpload
                                        name="chart-acc-additonal-documents-upload" accept="application/pdf, image/*"
                                        value="">
                                   
                               
                            </label>
                        </div>
                    </div>
                </div>
            </form>-->

            <form class="custom-file-container">
                <div class="custom-file">
                    <div class="">
                        <div class="image-upload">
                            <label style="cursor: pointer;width: 100%;">
                                <i class="material-symbols-outlined">
                                    upload_file
                                </i>
                                <h4><input type="file" (change)="uploadFileSelect($event)" class="custom-file-input"
                                    id="chart-acc-additonal-documents-upload" #newFileUpload
                                    name="chart-acc-additonal-documents-upload" accept="application/pdf, image/*"
                                    value="">
                                    <label class="custom-file-label"></label>
                                </h4>
                                <p> Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded
                                </p>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-lg-12 col-md-12 uploaded-data" *ngIf="allUploadedFilesList?.name">
            <ul class="uploaded-files">
                <li class="success" id="fileName-delete"> {{allUploadedFilesList?.name}} <span
                        class="material-symbols-outlined" (click)="deleteSelectedFile(index)"
                        style="cursor:pointer;">delete</span></li>
            </ul>
        </div>
    </div>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"></app-exception-modal>