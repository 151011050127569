<div class="claim-address-form">
    <div class="row row-cols-5" [formGroup]="addressForm">
        <div class="col">
            <div class="form-group">
                <label>Street <span class="text-danger">*</span></label>
                <input type="text" class="form-control input-text" autocorrect="off" autocapitalize="off"
                spellcheck="off" #searchClaimStreet formControlName="address1" id="claim-payor-address-street"
                placeholder="Street">
                <label class="required"
                    *ngIf="addressForm?.get('address1')?.hasError('required') && addressForm?.get('address1')?.touched">Address
                    is required</label>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>City <span class="text-danger">*</span></label>
                <input type="text" class="form-control input-text" id="claim-payor-city" formControlName="city"
                    placeholder="City">
                <label class="required"
                    *ngIf="addressForm?.get('city')?.hasError('required') && addressForm?.get('city')?.touched">City
                    is required</label>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>State <span class="text-danger">*</span></label>
                <input type="text" class="form-control input-text" id="claim-payor-state" formControlName="state"
                    placeholder="State">
                <label class="required"
                    *ngIf="addressForm?.get('state')?.hasError('required') && addressForm?.get('state')?.touched">State
                    is required</label>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>ZIP Code <span class="text-danger">*</span></label>
                <input type="text" class="form-control input-text" id="claim-payor-zipcode"
                    formControlName="postalCode" [dropSpecialCharacters]="false" mask="000000000" placeholder="ZIP Code">
                <label class="required"
                    *ngIf="addressForm?.get('postalCode')?.hasError('required') && addressForm?.get('postalCode')?.touched">ZIP
                    Code
                    is required</label>
            </div>
        </div>
    </div>
</div>