import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from '../../services/http.service';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { NewTaskComponent } from 'src/app/theme/shared/hop-common-components/new-task/new-task.component';

@Component({
  selector: 'app-document-review',
  templateUrl: './document-review.component.html',
  styleUrls: ['./document-review.component.scss']
})
export class DocumentReviewComponent implements OnInit {
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild('documentUploadNotify') documentUploadNotify: UiModalComponent;
  @ViewChild('reviewLabRecord') private reviewLabRecord: UiModalComponent
  @Output() documentReviewUpdate = new EventEmitter<boolean>();
  public progressNotesForm: FormGroup;
  public loginDetails: any;
  public appointmentIdForReview: any;
  public loadSpinner: boolean;
  public notifyText: string;
  public confirmation: boolean = false;
  public userDetails: any;
  @ViewChild(NewTaskComponent) newTakComp: NewTaskComponent;
  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.progressNotesForm = this.formBuilder?.group({
      "reviewNotes": [null, Validators?.required]
    })
  }

  ngOnInit(): void {

  }
  public openReviewModal(user: any, reviewLabRecord: UiModalComponent, appointmentId: any): void {
    this.confirmation = false;
    this.appointmentIdForReview = appointmentId;
    this.userDetails = user;
    this.reviewLabRecord?.show();
  }
  public updateJournalNotes(reviewLabRecord: UiModalComponent): void {
    if (this.progressNotesForm?.invalid) {
      this.progressNotesForm?.markAllAsTouched();
      return;
    }
    const action = `changeHealth/updateReviewNotes?appointmentId=${this.appointmentIdForReview}&notes=${JSON.parse(JSON?.stringify(this.progressNotesForm?.value?.reviewNotes))}&providerName=${this.loginDetails?.firstName + " " + this.loginDetails?.lastName || null}&reportS3Key=${this.userDetails?.s3Key}`;
    //&reportId=${this.userDetails?.id}
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.body?.status === 'SUCCESS') {
        //reviewLabRecord?.hide();
        this.notifyText = data?.body?.message || data?.body?.msg || 'Selected Patient Review Notes has been updated successfully.';
        // this.exceptionModal?.modalShow();
        this.documentReviewUpdate?.emit(true);
        this.confirmation = true;
      } else {
        this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal?.modalShow();

      }
    }, (error) => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
    })
    this.progressNotesForm?.reset();
  };

  public openTaskModal(appointment: any): void {
  const neObj = {
    'appointmentId': this.appointmentIdForReview
  }
	this.newTakComp?.showTaskModal(neObj);
  
  }

}
