import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';


@Component({
  selector: 'app-followup-notes',
  templateUrl: './followup-notes.component.html',
  styleUrls: ['./followup-notes.component.scss']
})
export class FollowupNotesComponent implements OnInit {

@ViewChild('followupNotes') followupNotes: UiModalComponent;
 @ViewChild(ExceptionModalComponent) public exceptionModal: ExceptionModalComponent;
loadSpinner: boolean;
public selectedAppointmentInfo: any;
public notesInfo = [];
 @Output() refreshFollowup = new EventEmitter();
 
 public scheduleApptForm: FormGroup;
  public loginDetails: any;
  public notifyText: any;
public isSuccessNotify: boolean;

 constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router) {
  this.scheduleApptForm = this.formBuilder.group({
      
      'notes': [null, Validators.required]
    });

    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
   
   
  }

  ngOnInit(): void {
     
  }
  ngAfterViewInit(): void {
  

  }
  
  
   public openModal(appointment) {
   //this.scheduleApptForm?.get('notes')?.setValue(null);
   this.scheduleApptForm.reset();
   this.selectedAppointmentInfo =  appointment;
    
	if(appointment.notes){
	this.notesInfo = appointment.notes;
	}
	else{
	this.notesInfo = [];
	}
    
       
    this.followupNotes.show();
  
  }
  
  public scheduleAppointment() {

    if (this.scheduleApptForm?.invalid) {
      this.scheduleApptForm?.markAllAsTouched();
      return;
    }

    const patientPrimaryPhone = this.selectedAppointmentInfo?.patientPrimaryPhone;
    const purposeOfVisit = this.selectedAppointmentInfo?.purposeOfVisit;
    const followupDate = this.selectedAppointmentInfo?.followupDate;
    

    

    const caregapId = this.selectedAppointmentInfo?.id;
    const careGaps = this.selectedAppointmentInfo?.careGaps;
    const notes = this.scheduleApptForm?.get('notes')?.value;

    const dob = this.selectedAppointmentInfo?.dob;
    const age = this.selectedAppointmentInfo?.age;
    const firstName = this.selectedAppointmentInfo?.firstName;
    const gender = this.selectedAppointmentInfo?.gender;
    const lastName = this.selectedAppointmentInfo?.lastName;
    const sourceType = this.selectedAppointmentInfo?.sourceType;
	const appointmentId = this.selectedAppointmentInfo?.appointmentId;
	const userInfoId =   this.selectedAppointmentInfo?.userInfoId;
    const apptDto =
    {

      "age": age,
      "appointmentId": appointmentId,
      "careGaps": careGaps,
      "caregapId": caregapId,
      "dob": dob,
      "firstName": firstName,
      "followupDate": followupDate,
      "gender": gender,
      "lastName": lastName,
      "notes": notes,
      "patientPrimaryPhone": patientPrimaryPhone,
      "purposeOfVisit": purposeOfVisit,
      "sourceType": sourceType,
      "userInfoId": userInfoId
    };

    console.log(apptDto);


    const action = `careGaps/updateCaregapsTransaction`;
    this.httpService?.makeRequestWithAction(action, apptDto)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
	   this.isSuccessNotify = true;
      // this.notifyText = data.body.message || '';
	  this.notifyText = "Notes have been updated successfully"
        this.exceptionModal.modalShow();
      }
      else {
	   this.isSuccessNotify = false;
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
		this.exceptionModal.modalShow();
      }

      console.log(data);
	  this.followupNotes.hide();
	  this.refreshFollowup?.emit(true);


    }, error => {
	 this.isSuccessNotify = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
      this.exceptionModal.modalShow();

    });

  }
  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }


}
