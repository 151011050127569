import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-claim-primary-secondary-corrected-submission',
  templateUrl: './claim-primary-secondary-corrected-submission.component.html',
  styleUrls: ['./claim-primary-secondary-corrected-submission.component.scss']
})
export class ClaimPrimarySecondaryCorrectedSubmissionComponent implements OnInit, OnChanges {

  @ViewChild('claimPrimarySecondaryCorrectedModal') claimPrimarySecondaryCorrectedModal: UiModalComponent;
  public primarySecondaryClaim: boolean;
  public correctedVoidedClaim: boolean;
  // public primarySecondary = [{desc: 'Primary', value: 'Primary'}, {desc: 'Secondary', value: 'Secondary'}, {desc: 'Tertiary', value: 'Tertiary'}];
  public primarySecondary = ['Primary', 'Secondary', 'Tertiary'];
  public corrctedVoided = [{desc: 'Corrected Claim', value: 'corrected'}, {desc: 'Voided Claim', value: 'voided'}];
  public clamsSubmissionForm: FormGroup;
  submittedClaimDetails: any;
  notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalModal: ExceptionModalComponent;
  claimTypeInsuranceDetails: any;
  loadSpinner: boolean;
  @ViewChild('claimSubmitConfirmationModal') claimSubmitModal: UiModalComponent;
  loginDetails: any;
  @Input() cardRefresh: CardComponent;
  public isreadySubmit: any;
  public resubmitReasons: any;
  public isExternal: any;
  public resubmitReasonsCodes: any;
  
  constructor(private formBuilder: FormBuilder, private router: Router, private httpService: HttpService) { 
    this.clamsSubmissionForm = this.formBuilder?.group({
      'claimProcessorType': [null, Validators?.required],      
      'resubmitReason': [null],
      'resubmitReasonCode': [null],
      'isCorrected': [null],
      'payerClaimControlNumber': [null],
      'isExternal': [null]
    });
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      
    }
  }

  private async updateValidators(readySubmit?: string) {
    // if (this.primarySecondaryClaim) {
    //   this.clamsSubmissionForm?.get('claimProcessorType')?.setValidators([Validators?.required]);
    // } else {
    //   this.clamsSubmissionForm?.get('claimProcessorType')?.clearValidators();
    // }
    this.clamsSubmissionForm?.get('claimProcessorType')?.setValue(this.submittedClaimDetails?.claimProcessorType || 'Primary');
    this.changeClaimProcessorType(this.submittedClaimDetails?.claimProcessorType || 'Primary', readySubmit);
    // Corrcted or voided claim
    if (this.correctedVoidedClaim) {
      this.clamsSubmissionForm?.get('isCorrected')?.setValidators([Validators?.required]);
      this.clamsSubmissionForm?.get('payerClaimControlNumber')?.setValidators([Validators?.required]);
      this.clamsSubmissionForm?.get('isCorrected')?.setValue('corrected');
      this.clamsSubmissionForm?.get('resubmitReason')?.setValidators([Validators?.required]);
      this.clamsSubmissionForm?.get('resubmitReasonCode')?.setValidators([Validators?.required]);
    } else {
      this.clamsSubmissionForm?.get('isCorrected')?.clearValidators();
      this.clamsSubmissionForm?.get('payerClaimControlNumber')?.clearValidators();
      if(!readySubmit && this.isExternal !=='yes'){
        this.clamsSubmissionForm?.get('resubmitReason')?.setValidators([Validators?.required]);
        this.clamsSubmissionForm?.get('resubmitReasonCode')?.setValidators([Validators?.required]);
      }
      else{
        this.clamsSubmissionForm?.get('resubmitReason')?.clearValidators();
        this.clamsSubmissionForm?.get('resubmitReasonCode')?.clearValidators();
      }
    }
    this.clamsSubmissionForm?.get('isCorrected')?.updateValueAndValidity();
    this.clamsSubmissionForm?.get('claimProcessorType')?.updateValueAndValidity();
    this.clamsSubmissionForm?.get('payerClaimControlNumber')?.updateValueAndValidity();
    this.clamsSubmissionForm?.get('resubmitReason')?.updateValueAndValidity();
    this.clamsSubmissionForm?.get('resubmitReasonCode')?.updateValueAndValidity();
    
  //  console.log(this.clamsSubmissionForm);
  }


  // clear form
  clearForm(): void {
    this.clamsSubmissionForm?.reset();
    // this.clamsSubmissionForm?.get('isCorrected')?.setErrors(null);
    // this.clamsSubmissionForm?.get('payerClaimControlNumber')?.setErrors(null);
    this.clamsSubmissionForm?.clearValidators();
    this.clamsSubmissionForm?.updateValueAndValidity();
  }

  public openClaimModal(claimsItem: any, isExternal?: string, isCorrctedVoidedSelect?: boolean, readySubmit?: string): void {
    this.getAllModifiers();
    this.isreadySubmit = readySubmit;
    this.isExternal = isExternal;
    
    console.log('isExternal',this.isExternal);
    console.log('isreadySubmit',this.isreadySubmit);
    this.submittedClaimDetails = claimsItem;
    this.clamsSubmissionForm?.get('isExternal')?.setValue(isExternal);
    this.primarySecondaryClaim = isCorrctedVoidedSelect === false ? true : false;
    this.correctedVoidedClaim = isCorrctedVoidedSelect === true ? true : false;
    this.updateValidators(readySubmit);
    if(!readySubmit) {
      this.claimPrimarySecondaryCorrectedModal?.show();
    }
    
   
    
  }

  // navigate to claim submission
  public navigateClaimSubmission(claimPrimarySecondaryCorrectedModal?: UiModalComponent): void {
    if(this.clamsSubmissionForm?.invalid) {
      this.clamsSubmissionForm?.markAllAsTouched();
      return;
    }
    if(!this.claimTypeInsuranceDetails || this.claimTypeInsuranceDetails?.length === 0) {
      // claimPrimarySecondaryCorrectedModal?.hide();
      return;
    }
    claimPrimarySecondaryCorrectedModal?.hide();
    this.router?.navigate([`hospital/claim-submission-new/${this.submittedClaimDetails?.appointmentId}`], {
      queryParams: {
        external: this.clamsSubmissionForm?.get('isExternal')?.value,
        claimProcessingFlag: this.clamsSubmissionForm?.get('claimProcessorType')?.value,
        corrctedClaim: this.clamsSubmissionForm?.get('isCorrected')?.value,
        payerControlNumber: this.clamsSubmissionForm?.get('payerClaimControlNumber')?.value,
        resubmitReason: this.clamsSubmissionForm?.get('resubmitReason')?.value,
        resubmitReasonCode: this.clamsSubmissionForm?.get('resubmitReasonCode')?.value

      }
    });
  }

  // Claim Processor Type
  public async changeClaimProcessorType(event: any, readySubmit?: string) {
    // const action = `eligibility/getInsuranceDetailsByType?appointmentId=&insuranceType=${event}`;
    // const action = `/eligibility/getInsuranceDetailsByType?appointmentId=${this.submittedClaimDetails?.appointmentId}&insuranceType =${event}`;
    this.loadSpinner = true;
    this.cardRefresh?.cardRefreshShow();
    let appointmentInfoDetails: any;
    try {
      const action = `appointment/registration/getAppointmentById?id=${this.submittedClaimDetails?.appointmentId}`;
      appointmentInfoDetails = await this.httpService?.makeGetRequest(action, '')?.toPromise();
      appointmentInfoDetails = appointmentInfoDetails?.responseObject;
    } catch (error) {
      this.cardRefresh?.cardRefreshHide();
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      return;
    }

    this.httpService?.getInsuranceDetailsDocuments(this.loginDetails?.facilityId, appointmentInfoDetails?.userInfoId, this.submittedClaimDetails?.appointmentId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const insuranceDetails = JSON.parse(JSON?.stringify(data?.responseObject || []));
        const insuranceDetailsActive = insuranceDetails?.filter((insuItem: any) => insuItem?.active) || [];

        const getInsurance = insuranceDetailsActive?.filter((insuItem: any) => insuItem?.priority === event) || [];
        this.claimTypeInsuranceDetails = getInsurance || [];

        if (!getInsurance || getInsurance?.length === 0) {
          this.notifyText = `${event} Insurance Details not found` || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
        }

        // Validate the re-submission reaons
        if (insuranceDetailsActive?.find(item => item?.priority === this.clamsSubmissionForm?.get('claimProcessorType')?.value)) {
          if (this.submittedClaimDetails?.claimProcessorType !== this.clamsSubmissionForm?.get('claimProcessorType')?.value  || this.isExternal ==='yes') {
            this.clamsSubmissionForm?.get('resubmitReason')?.setValidators(null);
            this.clamsSubmissionForm?.get('resubmitReasonCode')?.setValidators(null);
          } else {
            this.clamsSubmissionForm?.get('resubmitReason')?.setValidators([Validators?.required]);
            this.clamsSubmissionForm?.get('resubmitReasonCode')?.setValidators([Validators?.required]);
          }
          this.clamsSubmissionForm?.get('resubmitReason')?.updateValueAndValidity();
          this.clamsSubmissionForm?.get('resubmitReasonCode')?.updateValueAndValidity();
        }

        if (readySubmit && insuranceDetailsActive?.find(item => item?.priority === 'Primary') && (insuranceDetailsActive?.find(item => item?.priority === 'Secondary') || insuranceDetailsActive?.find(item => item?.priority === 'Tertiary'))) {
          this.claimSubmitModal?.show();
        } else if (readySubmit && insuranceDetailsActive?.find(item => item?.priority === 'Primary') && (!insuranceDetailsActive?.find(item => item?.priority === 'Secondary') && !insuranceDetailsActive?.find(item => item?.priority === 'Tertiary'))) {
          this.navigateClaimSubmission();
        }
      } else {
        this.claimTypeInsuranceDetails = [];
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
      }
      this.loadSpinner = false;
      this.cardRefresh?.cardRefreshHide();
    }, error => {
      this.claimTypeInsuranceDetails = [];
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.loadSpinner = false;
      this.cardRefresh?.cardRefreshHide();
    });
  }

  public getAllModifiers(): void {
    const Modifier = 'Resubmit Reason';
    const action = `billingCodes/readClaimConfigCodes?codeType=${Modifier}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if(data?.status === 'SUCCESS') {
        this.resubmitReasonsCodes = data?.responseObject || [];
      }
    },
      error => {

      });
  };

}
