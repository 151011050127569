<div class="table-responsive new-designed-table">
    <table datatable [dtOptions]="dtOptionsFutureAppnt" [dtTrigger]="dtTriggerFutureAppnt"
        class="table table-striped table-bordered table-hover">
        <thead>
            <tr>
                <th>Appointment Time</th>
                <th>Service Provider</th>
                <th>Purpose</th>
                <th>Status</th>
                <th class="text-center">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let appointment of allFutureAppointments; let i=index">
                <td>{{ getAppointmentTime(appointment?.appointment?.dateTime) }}</td>
                <td>{{appointment?.appointment?.authorized_official_last_name }}, 
                    {{appointment?.appointment?.authorized_official_first_name}}</td>
                <td>{{appointment?.appointment?.purposeOfVisit || 'N/A'}}</td>
                <td>{{getStatus(appointment?.appointmentStatus?.currentStatus, appointment) || '--'}}</td>
                <!-- <td class="text-center">
                    <ng-conatiner>
                        <button class="icon-inqueue-actions" (click)="openUploadDocument(appointment)"><i style="color: #5CA6DB;" class="las la-file-upload"></i>&nbsp; Upload</button>
                    </ng-conatiner>
                    <ng-conatiner>
                        <button class="icon-inqueue-actions"
                            [disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                            [class.checkedEligibility]="appointment?.eligibilityFlag"
                            (click)="checkEligibility(appointment)"><i style="color: #5CA6DB;" class="las la-check-circle"></i>&nbsp;
                            Eligibility</button>
                    </ng-conatiner>
                    <ng-container>
                        <button class="icon-inqueue-actions"
                            [disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                            (click)="reScheduleAppointment(appointment)"><i style="color: #5CA6DB;" class="las la-redo-alt"></i> &nbsp;
                            Reschedule</button>
                    </ng-container>
                
                    <ng-container>
                        <button class="icon-inqueue-actions"
                            [disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                            (click)="cancelAppointment(appointment)"><i style="color: #5CA6DB;" class="las la-times"></i>&nbsp; Cancel</button>
                    </ng-container>
                    <ng-container>
                        <button class="icon-inqueue-actions" (click)="viewAppointment(appointment?.id)"><i style="color: #5CA6DB;" class="las la-eye"></i>&nbsp; View</button>
                    </ng-container>
                    <ng-container>
                        <button class="icon-inqueue-actions"
                            [disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                            (click)="editAppointment(appointment)"><i style="color: #5CA6DB;" class="las la-pencil-alt"></i>&nbsp; Edit</button>
                    </ng-container>
                    <ng-container>
                        <button class="icon-inqueue-actions" (click)="lateNotify(appointment)"><i
                                style="color: #5CA6DB;" class="lab la-telegram"></i>&nbsp; Notify </button>
                    </ng-container>
                </td> -->

                <td class="text-center">
                    <button class="icon-queue-events view-action-bg" placement="bottom" ngbTooltip="Clinical Documents"
                    (click)="openUploadDocument(appointment)"><span class="material-symbols-outlined">upload_file</span></button>

                    <button class="icon-queue-events view-action-bg" placement="bottom" ngbTooltip="Eligibility"
                    [disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                    [class.checkedEligibility]="appointment?.eligibilityFlag"
                    (click)="checkEligibility(appointment)"><i class="las la-check-circle"></i></button>

                    <button class="icon-queue-events check-status-action-bg" placement="bottom" ngbTooltip="Re-Schedule"
                    [disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                    (click)="reScheduleAppointment(appointment)"><i class="las la-redo-alt"></i></button>

                    <button class="icon-queue-events delete-action-bg" placement="bottom" ngbTooltip="Cancel"
                            [disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED'"
                            (click)="cancelAppointment(appointment)"><i class="las la-times"></i></button>

                    <button class="icon-queue-events view-action-bg" placement="bottom" ngbTooltip="View" (click)="viewAppointment(appointment?.id)"><span class="material-symbols-outlined">visibility</span></button>
                
                    <button class="icon-queue-events resubmit-action-bg" placement="bottom" ngbTooltip="Edit"
                    [disabled]="appointment?.appointmentStatus?.currentStatus === 'CANCELLED' || appointment?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                    (click)="editAppointment(appointment)"><span class="material-symbols-outlined">edit</span></button>
                
                    <button class="icon-queue-events resubmit-action-bg" placement="bottom" ngbTooltip="Notify"
                    (click)="lateNotify(appointment)"><i class="lab la-telegram"></i></button>
					
					 
					 <button class="icon-queue-events view-action-bg" placement="bottom" ngbTooltip="Tasks"
                    (click)="openTaskModal(appointment)"><i class="las la-tasks"></i></button>
					
					 
                </td>

            </tr>
        </tbody>
    </table>
</div>

<!-- Cancel Appointment compoenent -->
<app-cancel-appointment (onCancelAppntChange)="refresh()"></app-cancel-appointment>

<!-- re-schedule common appointment -->
<app-re-schedule-appointment (onReScheduleUpdateChange)="refresh()" [exceptionModal]="exceptionModal">
</app-re-schedule-appointment>

<!-- notify popup -->
<app-ui-modal #notifyTextModal [hideHeader]="false" id="notifyTextModal">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">{{notifyText}}</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="notifyTextModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
      
              <h5 *ngIf="isSuccessNotify" class="modal-title text-success text-center" style="font-size: 50px;"><i class="fa fa-check-circle" aria-hidden="true"></i>
              </h5>
              <h5 *ngIf="!isSuccessNotify" class="modal-title text-danger text-center" style="font-size: 40px;">
                <i class="las la-minus-circle"></i>
              </h5>
                
      
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">
      
              <h5 class="modal-title wordAdjustment">{{notifyText}}</h5>
      
            </div>
        </div>
        
    </div>
    <!-- <div class="app-modal-body add-app-body" [formGroup]="rescheduleForm">
      <div class="form-group">
       {{}}  
      </div>
    </div> -->
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="ok1"
            (click)="notifyTextModal.hide()">Ok</button>
    </div>
</app-ui-modal>


<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Eligibility check -->
<app-eligibility-check [eligibilityDoctorDetails]="waitTimeDetails?.eligibilityDetails"
    (hideCardReload)="futureAppointments?.cardRefreshHide()"></app-eligibility-check>

<!-- Late Notify PopUp -->
<app-send-sms-notify [pageType]="'Future Appt'"></app-send-sms-notify>

<!-- Upload additonal documents -->
<app-upload-additional-documents></app-upload-additional-documents>

<app-new-task  [idType]="'appointmentId'"></app-new-task>