import { Component, OnInit, Input, Output, OnChanges, ViewChild, EventEmitter } from '@angular/core';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';

@Component({
  selector: 'app-verify-reports',
  templateUrl: './verify-reports.component.html',
  styleUrls: ['./verify-reports.component.scss']
})
export class VerifyReportsComponent implements OnChanges {

  @Input() userAppointmentDataVal: any;
  @Input() typeOfRecord: any;
  @Input() userData: any;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public labRadData: any;
  public loadSpinner?: boolean;
  public notifyText: any;
  public documentType: any;
  public isSuccessNotify: boolean;
  public indexCount: any = 0;
  public resultLength: any;
  public progressNotesForm: FormGroup;
  public s3Key: any;
  public loginDetails: any;
  public labData: any;
  public radData: any;
  public reviewStatus: boolean = false;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  @Output() taskRecord = new EventEmitter<object>();
  @Output() notifyValue = new EventEmitter<object>();
  @Output() refreshEncounter = new EventEmitter<object>();

  constructor(public httpService: HttpService, private formBuilder: FormBuilder) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.progressNotesForm = this.formBuilder?.group({
      "reviewNotes": [null, Validators?.required]
    })
  }

  ngOnChanges(): void {
    this.indexCount = 0;
    this.getLabRadData();
  }

  // ngOnInit(): void {
  //   console.log("appointmentUSerData************",this.userAppointmentDataVal);
  //   this.getLabRadData();
  // }

  getLabRadData() {
    this.progressNotesForm.get('reviewNotes').reset();
    const iframeEle = document.getElementById('lab-rad-medical-record-pdf') as HTMLElement;
    iframeEle.setAttribute('src', '');
    if (this.typeOfRecord == 'LAB') {
      this.resultLength = this.userAppointmentDataVal?.labDetails.length;
      this.labRadData = this.userAppointmentDataVal?.labDetails[this.indexCount];
      this.labRadData.order = this.userAppointmentDataVal?.labOrder;
      this.labRadData.orderStatus = this.userAppointmentDataVal?.labOrderStatus;
      this.documentType = "Lab Results";

      if (this.labRadData?.reviewStatus == 'COMPLETED') {
        this.reviewStatus = true;
        this.progressNotesForm.get('reviewNotes').setValue(this.labRadData?.reviewNotes);
      }
      if (this.userAppointmentDataVal?.labDetails && this.userAppointmentDataVal?.labDetails[this.indexCount] && this.userAppointmentDataVal?.labDetails[this.indexCount]?.additionalKey) {
        this.getS3KeyIndividual();
      } else {
        const s3Details = {
          s3Key: this.userAppointmentDataVal?.labDetails[this.indexCount]?.s3Key,
          s3Path: this.userAppointmentDataVal?.labDetails[this.indexCount]?.s3Path,
          fileType: this.userAppointmentDataVal?.labDetails[this.indexCount]?.fileType
        }
        this.downloadDocument(s3Details);
      }
    } else if (this.typeOfRecord == 'RAD') {
      this.resultLength = this.userAppointmentDataVal?.radDetails.length;
      this.labRadData = this.userAppointmentDataVal?.radDetails[this.indexCount];
      this.labRadData.order = this.userAppointmentDataVal?.radOrder;
      this.labRadData.orderStatus = this.userAppointmentDataVal?.radOrderStatus;
      this.documentType = "Radiology Results";
      if (this.labRadData?.reviewStatus == 'COMPLETED') {
        this.reviewStatus = true;
        this.progressNotesForm.get('reviewNotes').setValue(this.labRadData?.reviewNotes);
      }
      if (this.userAppointmentDataVal?.radDetails && this.userAppointmentDataVal?.radDetails[this.indexCount] && this.userAppointmentDataVal?.radDetails[this.indexCount]?.additionalKey) {
        this.getS3KeyIndividual();
      } else {
        const s3Details = {
          s3Key: this.userAppointmentDataVal?.radDetails[this.indexCount]?.s3Key,
          s3Path: this.userAppointmentDataVal?.radDetails[this.indexCount]?.s3Path,
          fileType: this.userAppointmentDataVal?.radDetails[this.indexCount]?.fileType
        }
        this.downloadDocument(s3Details);
      }
    } else if (this.typeOfRecord == 'EMG') {
      this.resultLength = this.userAppointmentDataVal?.emgDetails.length;
      this.labRadData = this.userAppointmentDataVal?.emgDetails[this.indexCount];
      this.labRadData.order = this.userAppointmentDataVal?.emgOrder;
      this.labRadData.orderStatus = this.userAppointmentDataVal?.radOrderStatus;
      this.documentType = "EMG";
      if (this.labRadData?.reviewStatus == 'COMPLETED') {
        this.reviewStatus = true;
        this.progressNotesForm.get('reviewNotes').setValue(this.labRadData?.reviewNotes);
      }
      if (this.userAppointmentDataVal?.emgDetails && this.userAppointmentDataVal?.emgDetails[this.indexCount] && this.userAppointmentDataVal?.emgDetails[this.indexCount]?.additionalKey) {
        this.getS3KeyIndividual();
      } else {
        const s3Details = {
          s3Key: this.userAppointmentDataVal?.emgDetails[this.indexCount]?.s3Key,
          s3Path: this.userAppointmentDataVal?.emgDetails[this.indexCount]?.s3Path,
          fileType: this.userAppointmentDataVal?.emgDetails[this.indexCount]?.fileType
        }
        this.downloadDocument(s3Details);
      }
    }
  }

  // call list individividual API call to get s3 key
  public getS3KeyIndividual(): void {
    const action = `record/listIndividualDocument?appointmentId=${this.userAppointmentDataVal?.id}&documentType=${this.documentType}&additionalKey=${this.labRadData?.additionalKey}`;
    // const action = `changeHealth/getOrderinfobyOrder?location=${this.selectedOrderDetails?.location}&orderNumber=${this.selectedOrderDetails?.orderNumber}&testType=All4134520868`;
    //this.labRadOrdersCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject && data?.responseObject?.s3Key) {
          this.downloadDocument(data?.responseObject);
          //   reportsAvailableModal?.show();
        } else {
          this.notifyText = 'Document is not available';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
      } else {
        this.notifyText = 'Document is not available';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      //  this.labRadOrdersCard?.cardRefreshHide();
    },
      (error) => {
        //   this.labRadOrdersCard?.cardRefreshHide();
      })
  }

  // Download Document with S3 key and S3 path
  public downloadDocument(s3Details: any): void {
    if (!s3Details?.s3Key || !s3Details?.s3Path) {
      return;
    }
    this.loadSpinner = true;
    this.s3Key = s3Details?.s3Key;
    const action = `record/downloadDocument?s3Key=${s3Details?.s3Key}&s3Path=${s3Details?.s3Path}`;
    this.httpService?.downloadImage(action)?.subscribe((data: any) => {
      if (!data) {
        this.loadSpinner = false;
        return;
      }
      const file = new Blob([data], { type: s3Details?.fileType })
      const fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('lab-rad-medical-record-pdf') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      setTimeout(() => {
        this.loadSpinner = false;
      }, 3000);

    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  public openTask(userAppointment: any) {
    this.taskRecord.emit(userAppointment);
  }

  public openNotify(userAppointment: any) {
    let appointmentDetails = userAppointment;
    appointmentDetails.typeOfRecord = this.typeOfRecord;
    if (this.typeOfRecord === 'LAB') {
      appointmentDetails.labDetails = this.userAppointmentDataVal?.labDetails;
    }
    if (this.typeOfRecord === 'RAD') {
      appointmentDetails.labDetails = this.userAppointmentDataVal?.radDetails;
    }
    if (this.typeOfRecord === 'EMG') {
      appointmentDetails.labDetails = this.userAppointmentDataVal?.emgDetails;
    }

    this.sendNotifyComp?.showNotifyModalForEncounter(appointmentDetails, '', 'DocReview');
    this.sendNotifyComp?.updateReviewNotes(this.progressNotesForm?.get('reviewNotes')?.value || null);
    // this.notifyValue.emit(appointmentDetails);
  }

  public updateIndex(type: any) {
    this.loadSpinner = true;
    if (type == 'add') {
      this.indexCount = this.indexCount + 1;
      this.getLabRadData();
    } else {
      this.indexCount = this.indexCount - 1;
      this.getLabRadData();
    }
  }
  public updateJournalNotes(): void {
    if (this.progressNotesForm?.invalid) {
      this.progressNotesForm?.markAllAsTouched();
      return;
    }
    const action = `changeHealth/updateReviewNotes?appointmentId=${this.userAppointmentDataVal?.id}&notes=${JSON.parse(JSON?.stringify(this.progressNotesForm?.value?.reviewNotes))}&providerName=${this.loginDetails?.firstName + " " + this.loginDetails?.lastName || null}&reportS3Key=${this.s3Key}&type=${this.documentType}&addToProgressNotes=false`;
    //&reportId=${this.userDetails?.id}
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.body?.status === 'SUCCESS') {
        //reviewLabRecord?.hide();
        this.isSuccessNotify = true;
        this.notifyText = data?.body?.message || data?.body?.msg || 'Selected Patient Review Notes has been updated successfully.';
        this.exceptionModal?.modalShow();
        // this.documentReviewUpdate?.emit(true);
        // this.confirmation = true;
        this.labRadData.reviewStatus = "COMPLETED";
        // this.refresh();
      } else {
        //   this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal?.modalShow();
      }
    }, (error) => {
      // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
    })
    //this.progressNotesForm?.reset();
  };

  public refresh() {
    this.refreshEncounter.emit();
  }
}
