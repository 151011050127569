export const environment = {
  production: false,
  endPoint: 'https://careecoapiqa.careeco.net/careeco/',
  careOutreachBaseUrl: 'https://cecareoutreachapiqa.careeco.net/careoutreach/',
  claimsBaseUrl: 'https://ceclaimsexchangeapiqa.careeco.net/claims/',
  externalGateway: 'https://ceexternalgatewayapiqa.careeco.net/externalgw/',
  reportsBaseUrl: 'https://cereportsapidev.careeco.net/reports/',
  userWebUrl: 'https://devme.careeco.net',
  pfSquare: {
    'accessToken': 'EAAAEOJeH6j9Gu19mWFV-usvbWGjpXTVuMTs9IihMFY7M0yTE2VAOlHS3IUkEX3b',
    'locationId': 'LH6KRT5YZNWFR',
    'applicationId': 'sq0idp-bvlfjpsrPwdI-6fGozZiUg'
  },
  windowName: 'ProviderConnectDev',
  faxNumber: 6154122072,
  privacyPolicy: 'https://devme.careeco.net/foot-privacy-policy',
  termsService: 'https://devme.careeco.net/foot-terms-of-service'
};
