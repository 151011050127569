import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiHeader } from './headers';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CommonRestService } from './common-rest.service';
@Injectable({
    providedIn: 'root'
})
export class CareOutreachService {
    public baseUrl: string = environment.careOutreachBaseUrl;
    private accessWithOptions: any
    public userDetails = JSON.parse(sessionStorage?.getItem('userDetails'));
    public allUsersListForFacility: any[] = [];
    public outreachpatientDetails:any = [];
    public careGapname:any;

    constructor(
        public http: HttpClient, private commonRestServices: CommonRestService
        // private apiHeader: ApiHeader
        //test
    ) {
        this.accessWithOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-access-code': sessionStorage.getItem('token'),
            }),
        }
    }


    public makeRequestWithAction(action, params): Observable<any> {
        return this.commonRestServices?.makeRequestWithAction(this.baseUrl, action, params);
    }

    public makeGetRequest(action, request): Observable<any> {
        return this.commonRestServices?.makeGetRequest(this.baseUrl, action, request);
    }

    public savepatientDetails(data){
        this.outreachpatientDetails = data;
    }
    
    public getpatientDetails(){
        return this.outreachpatientDetails;
    }

    public saveCardgroup(name){
         this.careGapname = name;
    }

    public getCardgroup(){
        return this.careGapname;
   }
}
