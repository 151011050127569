import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CancelAppointmentComponent } from 'src/app/theme/shared/hop-common-components/cancel-appointment/cancel-appointment.component';
import { PaymentComponent } from 'src/app/theme/shared/hop-common-components/payment/payment.component';
import { PaymentsCommonNewComponent } from 'src/app/theme/shared/hop-common-components/payments-common-new/payments-common-new.component';
import { ReScheduleAppointmentComponent } from 'src/app/theme/shared/hop-common-components/re-schedule-appointment/re-schedule-appointment.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { environment } from '../../../../../../environments/environment';
import { PaymentsRefactorComponent } from 'src/app/theme/shared/hop-common-components/payments-refactor/payments-refactor.component';

@Component({
  selector: 'app-kiosk-appointments-grid',
  templateUrl: './kiosk-appointments-grid.component.html',
  styleUrls: ['./kiosk-appointments-grid.component.scss']
})
export class KioskAppointmentsGridComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  public checkIsNoAccess: boolean;
  @ViewChild(DataTableDirective, { static: false })
  dtElementKiosk: DataTableDirective;
  public dtOptionsKiosk: DataTables.Settings = {};
  public dtTriggerKiosk: Subject<any> = new Subject<any>();
  // @Input() dtTrigger: any;
  private allAppointments: any;
  public loginDetails: any;
  @Input() waitTimeDetails: any;
  notifyText: any;
  public isSuccessNotify: boolean;

  @ViewChild(ReScheduleAppointmentComponent) reScheduleComp: ReScheduleAppointmentComponent;
  @ViewChild(CancelAppointmentComponent) cancelAppntComp: CancelAppointmentComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  rescheduleAppntData: any;
  cancelAppointmentId: any;
  @ViewChild(PaymentsRefactorComponent) private payComp: PaymentsRefactorComponent;
  @Output() onRefreshEmit = new EventEmitter<boolean>();
  public checkInSumbit: boolean;
  public checkInAppointment: { appId?: any; status?: any; };
  public allKioskAppointments: any[];
  @Input() AppointmentQueueCard: CardComponent;
  mindateofdbirth: any;
  notValidDob: boolean;
  dateofbirthValid: any;
  loadSpinner: boolean;

  constructor(private httpService: HttpService, private router: Router) { }

  ngOnInit(): void {
    this.dtOptionsKiosk = {
      pagingType: 'full_numbers',
      pageLength: 25,
      autoWidth: true,
      order: [[2, "desc"]],
      "language": {
      "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
      "emptyTable": "Data is unavailable.",
      "lengthMenu": "Showing _MENU_ Entries",
      "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.mindateofdbirth = this.httpService.convertDate(new Date());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      // this.getPatientsByFacilityId(this.loginDetails?.facilityId);
    }
  }

  ngAfterViewInit(): void {
    this.getPatientsByFacilityId(this.loginDetails?.facilityId);
  }

  public getPatientsByFacilityId(facilityid: any): void {
    if (!facilityid || !JSON.parse(sessionStorage.getItem('userDetails'))) {
      return;
    }
    let action: any = 'appointment/registration/statusByFacilityId?facilityId=';
    let params: any = {
      'id': facilityid
    }
    this.AppointmentQueueCard.cardRefreshShow();
    this.httpService.getPatientsByFacilityId(action, params.id)
      .subscribe((data: any) => {
        if (data.status == 'SUCCESS') {
          this.allAppointments = data?.resultList;
          this.allKioskAppointments = this.allAppointments?.filter(item => item?.appointmentStatus?.currentStatus !== 'CANCELLED' && item?.appointmentStatus?.currentStatus !== 'CHECKOUT');
        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.dtTriggerKiosk.next();
        this.AppointmentQueueCard?.cardRefreshHide();
      }, error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.dtTriggerKiosk?.next();
        this.AppointmentQueueCard?.cardRefreshHide();
      }
      );
  }

  public refetchKioskData(): void {
    this.allKioskAppointments = [];
    this.rerenderKiosk();
    this.getPatientsByFacilityId(this.loginDetails?.facilityId);
  }

  public rerenderKiosk(): void {
    this.dtElementKiosk?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }

  get isShowHideElements(): any {
    const allItems = JSON.parse(sessionStorage?.getItem('allProvideLoginNavItems'));
    this.checkIsNoAccess = allItems?.length === 0 ? true : false;
    const getScreenItem = allItems?.find(item => item?.coreFeature === 'Flow Board');
    return getScreenItem?.childFeature || {};
  }

  public async redirect(id: any) {
    this.httpService.clearTreatmentFlowSession();
    const validateLock = await this.httpService?.validateLockAppointment(id)?.toPromise();
    // if (!validateLock) {
      sessionStorage.setItem('careflowPath', 'appointment');
      this.router?.navigate([`/hospital/hosp-patientdetails/${id}`]);
    // }
  }

  // navigate to the user web to complete the pre-vsist documents
  public redirectUserWebPreVisitDoc(appointmentId: any): void {
    window.open(`${environment?.userWebUrl}/${this.loginDetails?.facilityId}/appt?previsitId=${appointmentId}&navigate=flowBoard`, 'kioskPrevisitDocumentFill');
  }

  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

  // get doctor name from list
  public doctorNameWithNpi(appointment: any): string {
    if (appointment?.authorized_official_first_name && appointment?.authorized_official_last_name) {
      const firstNameApp = appointment?.authorized_official_first_name || '';
      const lastNameApp = appointment?.authorized_official_last_name || '';
      return `${lastNameApp}, ${ firstNameApp}` || '--';
    } else {
      const doctor = this.waitTimeDetails?.eligibilityDetails?.doctor?.find((item: any) => {
        return appointment?.doctorNpi === item?.number
      });
      const firstName = doctor?.basic?.authorized_official_first_name || '';
      const lastName = doctor?.basic?.authorized_official_last_name || '';
      return `${lastName}, ${ firstName}` || '--';
    }
  }

  // appointment status showing
  public getStatus(status: string, appointment: any): string {
    return this.httpService.getStatus(status, appointment);
  }

  // dobValidationOpen
  public checkInDobValidation(appId: any, status: any, dobValidation: UiModalComponent): void {
    this.notValidDob = false;
    this.dateofbirthValid = '';

    this.checkInAppointment = {
      appId,
      status
    };
    dobValidation?.show();
  }

  // This function is for to update the patient status
  public statusUpdate(validationModal: UiModalComponent): void {
    this.notValidDob = false;
    if (!this.checkInAppointment?.appId?.dob?.includes(this.dateofbirthValid)) {
      this.notValidDob = true;
      return;
    }
    let action: any = 'appointment/registration/updateStatus';
    let params: any = {
      "apiName": "",
      "appointmentId": this.checkInAppointment?.appId?.id,
      "buttonClick": this.checkInAppointment?.status,
      "cancellationReason": "",
      "email": this.loginDetails.userId,
      "facilityID": this.loginDetails.facilityId
    }
    this.checkInSumbit = true;
    this.loadSpinner = true;
    this.httpService.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data?.body?.status == 'SUCCESS') {
          this.checkInSumbit = false;
          // this.onRefreshEmit?.emit(true);
          validationModal?.hide();
          this.loadSpinner = false;
          this.refetchKioskData();
        }
      },
        (error) => {
          this.checkInSumbit = false;
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          validationModal?.hide();
          this.loadSpinner = false;
        });
  }

  public makeRefresh(): void {
    this.refetchKioskData();
    // this.onRefreshEmit?.emit(true);
  }

  // re schedule appointment 
  public reScheduleAppointment(appointment: any): void {
    this.rescheduleAppntData = appointment;
    this.reScheduleComp.openSetAppointment(this.rescheduleAppntData);
  }

  // Cancel Apppintment commom compoent open
  public cancelAppointment(appointment: any): void {
    this.cancelAppointmentId = appointment;
    this.cancelAppntComp.openCancelAppointment(this.cancelAppointmentId);
  }

  // open card payment 
  public openPayment(appointment: any): void {
    this.payComp?.enableCardPay();
    this.payComp?.openModal(appointment);
  }

  ngOnDestroy(): void {
    this.dtTriggerKiosk?.unsubscribe();
  }

}
