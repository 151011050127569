<ng-container>
    <div class="view-encounter-details">
        <div class="row">
            <div class="col-md-12 appoint-col">
                <h5>
                    Appointment Details
                </h5>
                <p>
                    <b>{{patientInformation?.appointment?.purposeOfVisit}} Visit </b>
                    <i>{{getAppointmentTime(patientInformation?.appointment?.dateTime)}}</i> | <b>Coverage Type: </b>
                    <label *ngIf="patientInformation?.unInsured === true">Un-Insured</label>
                    <label *ngIf="patientInformation?.selfPay === true">Self-Pay</label>
                    <label *ngIf="patientInformation?.healthPass === true">Health Pass</label>
                    <label
                        *ngIf="patientInformation?.unInsured === false && patientInformation?.selfPay === false && patientInformation?.healthPass === false">Insurance</label>
                </p>
            </div>
            <div class="col-md-12">
                <h5 class="title">
                    Demographics
                </h5>
                <div class="row enc-form">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        First Name <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.firstName || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        DOB <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{ (patientInformation?.dob | yyymmdd) || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Marital Status <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.maritalStatus || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Street <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.address?.street || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        ZIP Code <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{addPrefixZipCode(patientInformation?.address?.zipCode) || '--'}}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Middle Name<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{ patientInformation?.middleName || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Gender<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{ patientInformation?.gender || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Race<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.race || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        City<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.address?.city || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Phone Number <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.patientPrimaryPhone || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Last Name <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{ patientInformation?.lastName || '--'}}
                                    </label>
                                </div>

                                <div class="form-group">
                                    <label class="label">
                                        Age <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.age || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Ethnicity <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.ethnicity || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        State <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.address?.state || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Email <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.email || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ID Document -->
            <div class="col-md-12">
                <h5 class="title">
                    ID Documents
                </h5>
                <div class="document-col">
                    <ng-container *ngIf="checkCommonIdDocument">
                        <div class="row">
                            <div class="col-lg-10 col-md-10 col-sm-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-12 text-center">
                                        <iframe id="identity-doc-card-view" width="100%" height="120px" frameBorder="0"></iframe>
                                    </div>
                                    <div class="text-center mt-3">
                                        <span class="zoom-ic">
                                            <button placement="bottom" ngbTooltip="Full View" (click)="fullViewIdentityImage(identityDocFullViewClaims)"
                                                class="icon-queue-events mt-4 signoff-action-bg clearfix">
                                                <i class="las la-expand"></i>
                                            </button>
                                        </span>  
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </ng-container>
                    <ng-container *ngIf="!checkCommonIdDocument">
                        <p>ID Document is not available.</p>
                    </ng-container>
                </div>
            </div>

            <!-- Insurance Details -->
            <div class="col-md-12">
                <app-insurance-edit-form [appointmentInfoDetails]="updateAppointmentDetails" [claimsSubmissionCard]="claimsSubmissionCard" (updateAppointment)="updateAppointment($event)"></app-insurance-edit-form>
            </div>

            <div class="col-md-12">
                <h5 class="title">
                    Emergency Contact Details
                </h5>
                <div class="row enc-form" *ngFor="let contact of patientInformation?.emergencyDetails">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="label">
                                Name<span>:</span>
                            </label>
                            <label class="label-val">
                                {{contact?.name || '--'}}
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="label">
                                Relationship<span>:</span>
                            </label>
                            <label class="label-val">
                                {{contact?.relationship || '--'}}
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="label">
                                Emergency Contact<span>:</span>
                            </label>
                            <label class="label-val">
                                {{contact?.phone || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <p *ngIf="!patientInformation?.emergencyDetails || patientInformation?.emergencyDetails?.length === 0">
                    Emergency Contact Details are unavailable for this patient
                </p>
            </div>
            <div class="col-md-12">
                <h5 class="title">
                    Billing Address Details
                </h5>
                <div class="row enc-form">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Street<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.billingAddress?.street || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        ZIP Code<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{addPrefixZipCode(patientInformation?.billingAddress?.zipCode) || '--'}}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        City<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.billingAddress?.city || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        State <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.billingAddress?.state || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <h5 class="title">
                    Preferred Pharmacy
                </h5>
                <div class="row enc-form">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Pharmacy Name <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.pharmacy?.name || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Street<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.pharmacy?.address?.street || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        ZIP Code<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{addPrefixZipCode(patientInformation?.pharmacy?.address?.zipCode) ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Phone Number<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.pharmacy?.phoneNumber || '--'}}
                                    </label>
                                </div>

                                <div class="form-group">
                                    <label class="label">
                                        City<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.pharmacy?.address?.city || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label">
                                    Fax<span>:</span>
                                </label>
                                <label class="label-val">
                                    {{patientInformation?.pharmacy?.fax || '--'}}
                                </label>
                                <div class="form-group">
                                    <label class="label">
                                        State <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.pharmacy?.address?.state || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <h5 class="title">
                    Preferred Language
                </h5>
                <div class="row enc-form">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Language for communication <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{patientInformation?.preferredLanguage || '--'}}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Identity Document full view -->
<app-ui-modal #identityDocFullViewClaims [hideHeader]="false" [modalCentered]="true" id="identityDocFullViewClaimsId">
    <div class="app-modal-header">
        <h5 class="modal-title">Identity Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="identityDocFullViewClaims.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="identity-doc-card-full-view-claims" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;"
            data-dismiss="modal" id="ok2" (click)="identityDocFullViewClaims.hide()">Ok</button>
    </div>
</app-ui-modal>