import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { AdditionalInsurancesComponent } from '../additional-insurances/additional-insurances.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { CommonInsuranceAddlComponent } from '../common-insurance-addl/common-insurance-addl.component';
@Component({
  selector: 'app-hosp-insurance',
  templateUrl: './hosp-insurance.component.html',
  styleUrls: ['./hosp-insurance.component.scss']
})
export class HospInsuranceComponent implements OnInit, OnDestroy {
  @Input() regType: string;
  @Input() insuranceForm: FormGroup;
  @Input() demographicForm: FormGroup;
  @ViewChild("search")
  public searchElementRef: ElementRef;

  @ViewChild("searchBilling")
  public searchBillingElementRef: ElementRef;

  public isSelfPay = AppConstantsListConfig?.isSelfPay;

  public insurancePrimarySubscriber = AppConstantsListConfig?.insurancePrimarySubscriber;

  public billingAddressDetails = AppConstantsListConfig?.billingAddressDetails;
  public allInsurances = [];
  portalId: any;
  latitude: any;
  longitude: any;
  currentLocation: string;
  public maxAppointmentDate: Date;
  public minDOB = this.httpService.convertDate(new Date('01 Jan 1910'));
  public currentYear = new Date()?.getFullYear();
  public currentMonth = new Date()?.getMonth();
  public currentDate = new Date()?.getDate();

  public genderList = AppConstantsListConfig?.genderList;
  public insuranceRelationship = AppConstantsListConfig?.insurancePrimarySubscriber;
  public insuranceDetails: FormArray;
  public insuranceSequenceList = AppConstantsListConfig?.insuranceSequenceList;
  public insuranceClassList = AppConstantsListConfig?.insuranceClassList;

  public allInsuranceList: Observable<any>;
  public insuranceLoading = false;
  public insuranceInput = new Subject<string>();
  public isHealthpassEnabled: string;
  // @ViewChild(AdditionalInsurancesComponent) addInsuranceComp: AdditionalInsurancesComponent;
  @ViewChild(CommonInsuranceAddlComponent) addInsuranceComp: CommonInsuranceAddlComponent;
  public userInfoData: any;
  public userInfoDetails: any;
  @Input() selectedPatientFlowBoard: any;
  public loginUser: any;

  constructor(private httpService: HttpService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private formBuilder: FormBuilder) {
    this.loginUser = JSON.parse(sessionStorage?.getItem('userDetails'));
    if (this.loginUser?.facilityId == 86) {
      this.isSelfPay = this.isSelfPay.filter(option => option.value !== 'healthPass');
    }
  }

  ngOnInit(): void {
    //load Billing Address Places Autocomplete
    this.userInfoData = JSON.parse(JSON?.stringify(this.demographicForm?.value));

    this.mapsAPILoader.load()?.then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchBillingElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.setCurrentPosition('BILLING');
        });
      });
    });
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
  }

  ngAfterViewInit(): void {
    const allDemographics = JSON.parse(JSON?.stringify(this.demographicForm?.value));
    if (!this.selectedPatientFlowBoard) {
      this.selectedPatientFlowBoard = JSON?.parse(sessionStorage?.getItem('establishedUserInfo'));
    }
    allDemographics.insuranceDetails = this.selectedPatientFlowBoard?.insuranceDetails || JSON?.parse(sessionStorage?.getItem('establishedUserInfo'))?.insuranceDetails;
    this.userInfoDetails = allDemographics;
    this.userInfoData = JSON.parse(JSON?.stringify(this.demographicForm?.value));
  }

  // Patch Insurance details fro Existing Patient
  public patchInsuranceData(): void {
    const selfPayValue = this.insuranceForm?.get('selfPay')?.value;
    const checkAppnt = sessionStorage?.getItem('isNoAppnt');
    if (selfPayValue && selfPayValue === 'Self-Pay') {
      return;
    }
    const insuranceValues = this.insuranceForm?.get('insuranceDetails')?.value || [];
    if (insuranceValues && insuranceValues?.length > 0) {
      if (checkAppnt === 'No') {
        this.insuranceForm?.get('BILLING_ADDRESS')?.setValue('Same');
        this.billingAddress();
      }
      return;
    }
    // Inusurance Details check
    const insuDetails = JSON.parse(sessionStorage.getItem('insuranceDetails')) || null;
    if (insuDetails?.length > 0) {
      this.httpService?.clearFormArray(this.insuranceForm?.get('insuranceDetails') as FormArray);
      this.insuranceForm?.get('selfPay')?.setValue('Insurance');
      insuDetails?.forEach(item => {
        this.addInsuranceComp?.addMoreInsurances(item);
      });
      // this.addInsuranceComp?.checkPaymentType(insuDetails[0]);
      const patchEmployerValues = insuDetails[0] || null;
      this.insuranceForm?.get('employerName')?.patchValue(patchEmployerValues?.employerName);
      this.insuranceForm?.get('employerAddress')?.patchValue(patchEmployerValues?.employerAddress);
    }
    if (JSON.parse(sessionStorage.getItem('insuranceDetails'))) {
      if (!insuDetails) {
        this.insuranceForm?.get('selfPay')?.setValue('Self-Pay');
        this.insuranceForm?.get('employerName')?.reset();
        this.insuranceForm?.get('employerAddress')?.reset();
      }
    }
    if (!JSON.parse(sessionStorage.getItem('insuranceDetails'))) {
      this.httpService?.clearFormArray(this.insuranceForm?.get('insuranceDetails') as FormArray);
      this.insuranceForm?.get('selfPay')?.setValue(null);
      this.insuranceForm?.get('employerName')?.reset();
      this.insuranceForm?.get('employerAddress')?.reset();
    }
    this.insuranceForm?.get('BILLING_ADDRESS')?.setValue('Same');
    this.billingAddress();
    this.updateFirstLastDobGnderValues();
  }

  public updateFirstLastDobGnderValues(): void {
    const insuFormArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
    const isSelfPay = this.insuranceForm?.get('selfPay')?.value;
    if (insuFormArray && insuFormArray?.controls?.length > 0 && isSelfPay === 'Insurance') {
      insuFormArray?.controls?.forEach((insuItemForm: FormGroup) => {
        if (insuItemForm?.get('insurancePrimarySubscriber')?.value === 'Self') {
          insuItemForm?.get('subscriberfirstname')?.setValue(this.demographicForm?.get("FIRST_NAME")?.value || null);
          insuItemForm?.get('subscriberlastname')?.setValue(this.demographicForm?.get("LAST_NAME")?.value || null);
          insuItemForm?.get('subscriberdateOfBirth')?.setValue(this.demographicForm?.get("DATE_OF_BIRTH")?.value || null);
          insuItemForm?.get('subscribergender')?.setValue(this.demographicForm?.get("GENDER")?.value || null);
        }
      });
    }
  }

  // get the address with lat/long
  private setCurrentPosition(groupType?: string): void {
    this.mapsAPILoader.load()?.then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng = {
        lat: this.latitude,
        lng: this.longitude
      };
      geocoder.geocode({
        'location': latlng
      }, (results) => {
        if (results[0]) {
          this.currentLocation = results[0].formatted_address;
          this.setZipCode(results, groupType);
        } else {
          console.log('Not found');
        }
      });
    });
  }

  private setZipCode(results: any, groupType: string): void {
    if (groupType === 'EMP') {
      this.insuranceForm?.get(`${groupType}_ADDRESS`)?.setValue(null);
    }
    if (groupType === 'BILLING') {
      this.insuranceForm?.get(`${groupType}_STREET`)?.setValue(null);
    }
    let street = '';
    for (let i = 0; i < results.length; i++) {
      for (var j = 0; j < results[i].address_components.length; j++) {
        for (var k = 0; k < results[i].address_components[j].types.length; k++) {

          if (results[i].address_components[j].types[k] == "street_number") {
            let street_num = results[i].address_components[j].long_name;
            street += `${street_num} `;
          }
          if (groupType === 'EMP') {
            if (this.insuranceForm?.get(`${groupType}_ADDRESS`)?.value === null && results[i].address_components[j].types[k] == "route") {
              let route = results[i].address_components[j].long_name;
              street += `${route}`;
              if (this.insuranceForm?.get(`${groupType}_ADDRESS`)?.value === null) {
                this.insuranceForm?.get(`${groupType}_ADDRESS`)?.setValue(street);
              }
            }
          }
          if (groupType === 'BILLING') {
            if (this.insuranceForm?.get(`${groupType}_STREET`)?.value === null && results[i].address_components[j].types[k] == "route") {
              let route = results[i].address_components[j].long_name;
              street += `${route}`;
              if (this.insuranceForm?.get(`${groupType}_STREET`)?.value === null) {
                this.insuranceForm?.get(`${groupType}_STREET`)?.setValue(street);
              }
            }
          }
          if (results[i].address_components[j].types[k] == "postal_code") {
            const zipCode = results[i].address_components[j].short_name;
            this.insuranceForm?.get(`${groupType}_ZIPCODE`)?.setValue(zipCode);
          }

          if (results[i].address_components[j].types[k] == "country") {
            const country = results[i].address_components[j].long_name;
            this.insuranceForm?.get(`${groupType}_COUNTRY`)?.setValue(country);
          }

          if (results[i].address_components[j].types[k] == "administrative_area_level_1") {
            const state = results[i].address_components[j].short_name;
            this.insuranceForm?.get(`${groupType}_STATE`)?.setValue(state);
          }

          if (results[i].address_components[j].types[k] == "locality") {
            const city = results[i].address_components[j].long_name;
            this.insuranceForm?.get(`${groupType}_CITY`)?.setValue(city);
          }
        }
      }
    }
  }

  // for billing Address
  public billingAddress(): void {
    const isOtherBilling = this.insuranceForm?.get('BILLING_ADDRESS')?.value;
    if (isOtherBilling === 'Same') {
      const street = this.demographicForm?.value?.ADDRESS || null;
      const city = this.demographicForm?.value?.CITY || null;
      const country = this.demographicForm?.value?.COUNTRY || null;
      const state = this.demographicForm?.value?.STATE || null;
      const zipcode = this.demographicForm?.value?.ZIPCODE || null;
      this.disableBillingAddressControls();
      this.insuranceForm?.get('BILLING_STREET')?.setValue(street);
      this.insuranceForm?.get('BILLING_CITY')?.setValue(city);
      this.insuranceForm?.get('BILLING_STATE')?.setValue(state);
      this.insuranceForm?.get('BILLING_COUNTRY')?.setValue(country);
      this.insuranceForm?.get('BILLING_ZIPCODE')?.setValue(zipcode);
    } else {
      this.enableBillingAddressControls();
      this.insuranceForm?.get('BILLING_STREET')?.setValue(null);
      this.insuranceForm?.get('BILLING_CITY')?.setValue(null);
      this.insuranceForm?.get('BILLING_STATE')?.setValue(null);
      this.insuranceForm?.get('BILLING_COUNTRY')?.setValue(null);
      this.insuranceForm?.get('BILLING_ZIPCODE')?.setValue(null);
    }
  }

  // to disable the billing Address
  private disableBillingAddressControls(): void {
    this.insuranceForm?.get('BILLING_STREET')?.disable();
    this.insuranceForm?.get('BILLING_CITY')?.disable();
    this.insuranceForm?.get('BILLING_STATE')?.disable();
    this.insuranceForm?.get('BILLING_COUNTRY')?.disable();
    this.insuranceForm?.get('BILLING_ZIPCODE')?.disable();
  }

  // to enable the billing Address
  private enableBillingAddressControls(): void {
    this.insuranceForm?.get('BILLING_STREET')?.enable();
    this.insuranceForm?.get('BILLING_CITY')?.enable();
    this.insuranceForm?.get('BILLING_STATE')?.enable();
    this.insuranceForm?.get('BILLING_COUNTRY')?.enable();
    this.insuranceForm?.get('BILLING_ZIPCODE')?.enable();
  }

  //This method is to validate manually entered date value
  public validateManualEnterdob(e: any): void {
    if (e.target.value != '' && e.target.value != null) {
      if (this.httpService.validateManualEnterdob(e.target.value, this.currentYear, this.currentMonth + 1, this.currentDate)) {
        // this.insuranceForm?.get('PRIMARY_SUBSCRIBER_DOB')?.setValue(null);
        const passwordControl = this.insuranceForm.get('PRIMARY_SUBSCRIBER_DOB');
        passwordControl.setErrors({ invalidDate: true });
      }
    }
  }

  public getInActiveDeletedInsurances(): any[] {
    return this.addInsuranceComp?.inActiveOrDeletedInsurances || [];
  }

  public updateDemographics(event: any) {
    this.insuranceForm = event;
  }

  public toggleInsuranceSection(event: any) {
    this.insuranceForm?.get('selfPay').setValue(event);
  }

  ngOnDestroy(): void {
    this.insuranceInput?.unsubscribe();
  }
}