import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';


@Component({
  selector: 'app-send-fax-common',
  templateUrl: './send-fax-common.component.html',
  styleUrls: ['./send-fax-common.component.scss']
})
export class SendFaxCommonComponent implements OnInit , OnChanges{

  public sendNewFaxForm: FormGroup;
  loadSpinner: boolean;
  notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @Input() appointmentId: string;
  @ViewChild('sendFaxCommonModal') sendFaxCommonModal: UiModalComponent;
  @Input() facilityId: any;
  @Input() cologuardFax: boolean;
  @ViewChild('cologuardTestModal') cologuardTestModal: UiModalComponent;
  @Input() cardReload: CardComponent;
  
  public signatureForm: FormGroup;
  @Output() cologuardTestFaxSent = new EventEmitter<any>();

  constructor(private httpService: HttpService, private formBuilder: FormBuilder) {
    this.sendNewFaxForm = formBuilder?.group({
      'faxNumber': [null, Validators?.compose([Validators?.required, Validators?.pattern(AppRegExpressionsConfig?.mobile)])],
      'recipientName': [null, Validators?.compose([Validators?.required, Validators?.pattern(AppRegExpressionsConfig?.nameValidator)])],
      'subject': [null, Validators?.required],
      'documentName': [null, Validators?.required],
      'multipartFile': [null],
      'patientName': [null]
    });
	
	this.signatureForm = formBuilder?.group({
     'signature': [null, Validators.required]
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes) {
      if(this.cologuardFax) {
        // this.facilityId === 81 ? 6154122072 : this.facilityId === 201 ? 6297360187 : null
        this.sendNewFaxForm?.get('faxNumber')?.setValue(environment?.faxNumber);
        this.sendNewFaxForm?.get('subject')?.setValue('Cologuard Order');
        this.sendNewFaxForm?.get('documentName')?.setValue('Cologuard Order Requisition');
        this.sendNewFaxForm?.get('recipientName')?.setValue('Exact Sciences');
		
		
      }
    }
  }
  
  

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.sendNewFaxForm?.get('multipartFile')?.setValue(file);
    }
  }

  public openSendFaxCommonModal(signature:any, isClaimsFax?: string, file?: any, appointmentDetails?: any): void {
    if(isClaimsFax) {
      this.sendNewFaxForm?.get('subject')?.setValue('Medical Record');
      this.sendNewFaxForm?.get('documentName')?.setValue(`${appointmentDetails?.firstName} ${appointmentDetails?.lastName} Medical Record`);
      this.sendNewFaxForm?.get('patientName')?.setValue(`${appointmentDetails?.firstName} ${appointmentDetails?.lastName}`);
      this.sendNewFaxForm?.get('multipartFile')?.setValue(file);
      this.sendFaxCommonModal?.show();
      return;
    }
    // this.sendFaxCommonModal?.show();
    if(this.cologuardFax) {
      this.sendNewFaxForm?.get('faxNumber')?.setValue(environment?.faxNumber);
      this.sendNewFaxForm?.get('subject')?.setValue('Cologuard Order');
      this.sendNewFaxForm?.get('documentName')?.setValue('Cologuard Order Requisition');
      this.sendNewFaxForm?.get('recipientName')?.setValue('Exact Sciences');
	  
	  this.signatureForm?.get('signature')?.setValue(signature);
	  
    }
    this.downloadCologuradPdf(this.cologuardTestModal);
  }

  public createdNewFax(sendFaxModal: UiModalComponent): void {
    if (this.sendNewFaxForm?.invalid) {
      this.sendNewFaxForm?.markAllAsTouched();
      return;
    }
    // convert to binary for data
    const formData = new FormData();
    formData.append('file', this.sendNewFaxForm?.value?.multipartFile);

    const faxNumber = '+1' + this.sendNewFaxForm?.value?.faxNumber;
    let action = `sendFax?documentName=${this.sendNewFaxForm?.value?.documentName}&faxNumber=${faxNumber}&recipientName=${this.sendNewFaxForm?.value?.recipientName}&subject=${this.sendNewFaxForm?.value?.subject}&appointmentId=${this.appointmentId}&referrralNotes=""`;
    if(this.cologuardFax) {
      action = action + '&addlInfo=Cologuard Order';
    }
    if(this.sendNewFaxForm?.value?.patientName) {
      action = action + `&patientName=${this.sendNewFaxForm?.value?.patientName}`;
    }
    this.loadSpinner = true;
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.notifyText = data?.message || 'New Fax has been sent successfully..!';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        sendFaxModal?.hide();
        if(this.cologuardFax) {
          this.cologuardTestFaxSent?.emit('sent');
        }
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // download the cologurad data pdf view
  public downloadCologuradPdf(sendFaxModal?: UiModalComponent): void {
    if (this.sendNewFaxForm?.invalid) {
      this.sendNewFaxForm?.markAllAsTouched();
      return;
    }
    this.cardReload?.cardRefreshShow();
    const params = this.signatureForm.value;
    params.appointmentId = this.appointmentId;

    const action = `pdf/generateCologuardTestPDF?appointmentid=${this.appointmentId}`;
    //  const action = `pdf/generateMedicalHistory`;

    this.httpService.downloadS3Image(action, params).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' });
      const blobToFile = new File([file], `${this.appointmentId}_Referral.pdf`, {
        type: file?.type,
      });
      this.sendNewFaxForm?.get('multipartFile')?.setValue(blobToFile);
      var fileURL = URL.createObjectURL(file);

      if (sendFaxModal) {
        sendFaxModal?.show();
        // this.createdNewFax(sendFaxModal);
      }
      const iframeEle = document.getElementById('cologuard-test-pdf') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      this.cardReload?.cardRefreshHide();
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.cardReload?.cardRefreshHide();
      });
  }
  
  

}
