import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { ApisService } from '../../services/apis.services';

@Component({
  selector: 'app-send-sms-notify',
  templateUrl: './send-sms-notify.component.html',
  styleUrls: ['./send-sms-notify.component.scss']
})
export class SendSmsNotifyComponent implements OnInit, OnChanges {

  public LateNotifyForm: FormGroup
  public lateNotifySubmitClick: boolean;
  public lateNotifyPatient: any;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild('lateNotifyModal') lateNotifyModal: UiModalComponent;
  @Input() noDelay: boolean;
  @Input() followupQueueAppt: boolean = false;
  public originalNotify :any ;

  public genericMsgDelay = [
    { value: 'Your Appointment is delayed By', desc: 'Your Appointment is delayed By' }
  ]
  public delayNotificationTypes = AppConstantsListConfig?.notificationTypes || [];
  public genericMsgGeneral = [
    { value: 'Doctor Issued new prescription to your current Pharmacy, Please collect your medications', desc: 'Doctor Issued new prescription to your current Pharmacy, Please collect your medications' },
    { value: 'Doctor suggested to schedule an office visit, due to changes in your medical condition', desc: 'Doctor suggested to schedule an office visit, due to changes in your medical condition' },
  ];
  public loginDetails: any;
  public facilityId: any;
  public showComposedNotifyMessages: boolean;
  selectedIndex: number;
  public isUserLevelNotification: string;
  public showMessage: boolean = false;
  public docNotify: boolean = false;
  public labNotifyType: any;
  @Input() pageType: any;
  noMessageIssue: boolean;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private apiService: ApisService) {
    this.LateNotifyForm = this.formBuilder.group({
      'messageToUsers': [null, Validators.compose([Validators.required])], //, Validators.pattern(AppRegExpressionsConfig?.aplhaNumericComments)
      'typeSelect': [null, Validators.required],
      'genericMessage': [null],
      'secureMessage': [null],
      'phoneNumber':[null, [Validators?.required, Validators.pattern(AppRegExpressionsConfig?.mobile)]]
    });
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }

  ngOnInit(): void {
    this.LateNotifyForm.controls['phoneNumber']?.disable();
  }
  

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.noDelay) {
      // this.delayNotificationTypes = [
      //   { value: 'General Notification', desc: 'General' },
      //   // { value: 'Secure', desc: 'Secure' }
      // ]
      this.LateNotifyForm?.get('typeSelect')?.setValue('General Notification');
      this.LateNotifyForm?.get('secureMessage')?.setValidators(null);
      this.LateNotifyForm?.get('secureMessage')?.updateValueAndValidity();
      // this.pageType = this.pageType || null;
    }
  }

  public resetForm(): void {
    this.LateNotifyForm?.reset();
    this.LateNotifyForm.controls['phoneNumber']?.disable();
    this.showComposedNotifyMessages = false;
  }

  public showNotifyModal(patient: any, patChart?: string): void {
    if (!this.LateNotifyForm?.controls['genericMessage']?.value) {
      // this.LateNotifyForm?.controls['messageToUsers']?.disable();
    }
    this.lateNotifyPatient = patient || {};
    this.originalNotify = this.lateNotifyPatient ;
    this.isUserLevelNotification = patChart || null;
    this.isUserLevelNotification = patChart || null;
    this.showMessage = false;
    if(patient.careGap){
      this.LateNotifyForm?.get('typeSelect')?.setValue('Care Gap');
      this.changeNotificationType();
    }else{
      this.LateNotifyForm?.get('typeSelect')?.setValue('General Notification');
    }
    this.LateNotifyForm?.get('secureMessage')?.setValidators(null);
    this.LateNotifyForm?.get('secureMessage')?.updateValueAndValidity();
    if(this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || this.lateNotifyPatient?.patientPhoneNumber) {
      const primaryPhone: string = this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || this.lateNotifyPatient?.patientPhoneNumber || null;
      if(primaryPhone) {
        const modifyNumber = primaryPhone?.includes('+1') ? primaryPhone?.replace('+1', '') : primaryPhone;
        this.LateNotifyForm?.get('phoneNumber')?.setValue(modifyNumber);
      } else {
        this.LateNotifyForm?.get('phoneNumber')?.enable();
      }
    } else {
      this.LateNotifyForm?.get('phoneNumber')?.enable();
    }
    this.lateNotifyModal?.show();
    this.checkNotifyPageTypes();
  }

  // check notification Type
  public changeNotificationType(): void {
    const notifyType = this.LateNotifyForm?.get('typeSelect')?.value;
    this.LateNotifyForm?.controls['genericMessage']?.reset();
    this.LateNotifyForm?.controls['messageToUsers']?.reset();
    this.LateNotifyForm?.controls['secureMessage']?.reset();
    if (notifyType === 'Appointment Delay') {
      this.LateNotifyForm?.addControl('delayInMins', new FormControl(null, Validators.required));
    }
    else {
      this.LateNotifyForm?.removeControl('delayInMins');
    }
    // validation update for General, appnt delay and reminder
    if (notifyType === 'Diagnostics Results') {
      this.LateNotifyForm?.get('secureMessage')?.setValidators([Validators?.required]);
    } else {
      this.LateNotifyForm?.get('secureMessage')?.setValidators(null);
    }
    this.LateNotifyForm?.get('secureMessage')?.updateValueAndValidity();
    this.LateNotifyForm?.updateValueAndValidity();
    this.selectedIndex = null;
    this.showComposedNotifyMessages = false;
    this.showMessage = false;
    if (notifyType && notifyType !== 'General Notification') {
      this.getMessageBody();
    }
    if (this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || this.lateNotifyPatient?.patientPhoneNumber) {
      const primaryPhone: string = this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || this.lateNotifyPatient?.patientPhoneNumber || null;
      if (primaryPhone) {
        const modifyNumber = primaryPhone?.includes('+1') ? primaryPhone?.replace('+1', '') : primaryPhone;
        this.LateNotifyForm?.get('phoneNumber')?.setValue(modifyNumber);
      }
    }
    // Enabling Phone number for Account Balance selection
    if (notifyType && notifyType === 'Account Balance') {
      // this.lateNotifyPatient = this.originalNotify || {};
      // let phoneNumber = this.lateNotifyPatient?.patientPrimaryPhone?this.lateNotifyPatient?.patientPrimaryPhone:this.lateNotifyPatient?.patientPhoneNumber?this.lateNotifyPatient?.patientPhoneNumber:this.lateNotifyPatient?.primaryPhone
      // if (phoneNumber.includes('+1')) {
      //   phoneNumber = phoneNumber?.replace('+1', '');
      //   this.LateNotifyForm.controls['phoneNumber']?.setValue(phoneNumber);
      // }else{
      //   this.LateNotifyForm.controls['phoneNumber']?.setValue(phoneNumber);
      // }
      this.LateNotifyForm.controls['phoneNumber']?.enable();
    } else {
      // this.lateNotifyPatient = this.originalNotify || {};
      this.LateNotifyForm.controls['phoneNumber']?.disable();
      if(!this.LateNotifyForm?.get('phoneNumber')?.value) {
        this.LateNotifyForm.controls['phoneNumber']?.enable();
      }
    }
    this.LateNotifyForm?.get('phoneNumber')?.updateValueAndValidity();
  }

  public genericMessageChange(index: number): void {
    this.selectedIndex = index;
    // this.LateNotifyForm?.controls['messageToUsers']?.enable();
    const genericMsg = this.LateNotifyForm?.controls['genericMessage']?.value || '';
    if(this.LateNotifyForm?.get('typeSelect')?.value !== 'General Notification') {
      this.LateNotifyForm?.controls['secureMessage']?.setValue(genericMsg);
    } else {
      this.LateNotifyForm?.controls['messageToUsers']?.setValue(genericMsg);
    }
  }

  public lateNotifyRequest(delayNotifyModal: UiModalComponent): void {
    if (this.LateNotifyForm.get('typeSelect').value === 'Appointment Reminder') {
      this.reminderNotification(delayNotifyModal);
      return;
    }
    if (this.LateNotifyForm.get('typeSelect').value === 'Secure') {
      this.secureNotification(delayNotifyModal);
      return;
    }
    if (this.LateNotifyForm?.invalid) {
      this.LateNotifyForm?.markAllAsTouched();
      return;
    }
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.facilityId = this.loginDetails?.facilityId;
    let action = 'notification/sendlate';
    // let params: any = {
    //    "facilityId": this.facilityId,
    //   "userIds": [userid],
    //   "messageToUsers": this.LateNotifyForm.controls['messageToUsers'].value,
    //   "delayInMins": this.LateNotifyForm.controls['delayInMins'].value
    // phoneNumber: phoneNumber,
    // }
    const notifyType = this.LateNotifyForm?.get('typeSelect')?.value;
    // const userIdPhone = this.lateNotifyPatient?.patientPrimaryPhone?.replace('+', '');
    let params = {
      //appointmentId: this.lateNotifyPatient?.id || this.lateNotifyPatient?.appointmentId || null,
      messageToUsers: this.LateNotifyForm?.controls['messageToUsers']?.value,
      // facilityId: this.lateNotifyPatient?.facilityId,
      phoneNumber: this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || this.lateNotifyPatient?.patientPhoneNumber || null,
    }
    if (notifyType === 'General Notification') {
      // params['delayInMins'] = 1;
      params['notificationType'] = 'General';
      params['userInfoId'] = this.lateNotifyPatient?.userInfoId || this.lateNotifyPatient?.userInfo?.id || null;

      if (params?.messageToUsers?.includes('schedule an office visit')) {
        params.messageToUsers = `${params?.messageToUsers} ${environment?.userWebUrl}/${this.facilityId}/bookappt`;
      }
    } else {
      params['notificationType'] = 'Appointment Delay';
      params['delayInMins'] = this.LateNotifyForm?.controls['delayInMins']?.value || 0;
      params.messageToUsers = params.messageToUsers + " " + this.LateNotifyForm?.controls['delayInMins']?.value;
    }
    this.lateNotifySubmitClick = true;
    this.httpService?.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data?.body?.status == 'SUCCESS') {
          delayNotifyModal?.hide();
          this.notifyText = 'Your notification has been sent successfully.';
          this.isSuccessNotify = true;
          this.showComposedNotifyMessages = false;
          this.exceptionModal?.modalShow();
          this.LateNotifyForm?.reset();
        } else {
          this.notifyText = AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.lateNotifySubmitClick = false;
      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.lateNotifySubmitClick = false;
        });
  }

  // call reminder api
  public reminderNotification(delayNotifyModal: UiModalComponent): void {
    //const action = `notification/appointmentRemainder?appointmentId=${this.lateNotifyPatient?.id}`;
    const action = `notification/sendSms?message=${this.LateNotifyForm?.controls['messageToUsers']?.value}&phoneNumber=${this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || null}`;
    // let params = {
    //   "message": this.LateNotifyForm?.controls['messageToUsers']?.value,
    //   "phoneNumber": this.lateNotifyPatient?.patientPrimaryPhone

    // }
    this.lateNotifySubmitClick = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      delayNotifyModal?.hide();
      this.notifyText = 'Your notification has been sent successfully.';
      this.isSuccessNotify = true;
      this.exceptionModal?.modalShow();
      this.LateNotifyForm?.reset();
      this.lateNotifySubmitClick = false;
      this.showComposedNotifyMessages = false;
    },
      (error) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.lateNotifySubmitClick = false;
      })
  }

  public preComposedMessage(): void {
    this.showComposedNotifyMessages = !this.showComposedNotifyMessages;
    if (this.LateNotifyForm?.get('typeSelect')?.value && this.LateNotifyForm?.get('typeSelect')?.value != 'Appointment Reminder') {
      this.showMessage = !this.showMessage;
    }
  }

  public changeRadio(value: string): void {
    const valueRadio = this.LateNotifyForm.controls['genericMessage']?.value;
    const element = document?.getElementById(`notify${value}`) as HTMLInputElement;
    if (value === valueRadio) {
      element.checked = false;
      this.selectedIndex = null;
      this.LateNotifyForm.controls['genericMessage']?.reset();
      if(this.LateNotifyForm?.get('typeSelect')?.value !== 'General Notification') {
        this.LateNotifyForm?.controls['secureMessage']?.setValue(null);
      } else {
        this.LateNotifyForm?.controls['messageToUsers']?.setValue(null);
      }
    }
  }

  // Secure Notifications send
  public secureNotification(delayNotifyModal: UiModalComponent): void {
    if (this.LateNotifyForm?.invalid) {
      this.LateNotifyForm?.markAllAsTouched();
      return;
    }
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.facilityId = this.loginDetails?.facilityId;
    let appointmentId = '';
    if (this.labNotifyType === 'claim') {
      appointmentId = this.lateNotifyPatient?.appointmentId || this.lateNotifyPatient?.id;
    } else {
      appointmentId = !this.isUserLevelNotification ? (this.lateNotifyPatient?.id || this.lateNotifyPatient?.appointmentId) : null
    }
    let action = 'notification/securemessage';
    let params = {
      "appointmentId": appointmentId,
      "messageToUsers": this.LateNotifyForm?.controls['messageToUsers']?.value,
      "secureMessage": this.LateNotifyForm?.controls['secureMessage']?.value,
      "phoneNumber": this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || null,
      "delayInMins": 0,
      "notificationType": "Secure",
      "userInfoId": this.isUserLevelNotification ? this.lateNotifyPatient?.userInfoId || this.lateNotifyPatient?.id : this.lateNotifyPatient?.userInfoId || null,
    }
    if (this.labNotifyType == 'DocReview') {
      action = 'changeHealth/savePatientCareEventsForLabOrder';
      const addParamVal = {
        "patientReferenceDocs": this.lateNotifyPatient?.labDetails,
        "userInfoId": this.lateNotifyPatient.userInfoId,
        "patientReferenceDocType": this.lateNotifyPatient?.typeOfRecord,
      }
      params = { ...params, ...addParamVal }
    }
    this.lateNotifySubmitClick = true;
    this.httpService?.makeRequestWithAction(action, params)
      .subscribe((data: any) => {
        if (data?.body?.status == 'SUCCESS') {
          delayNotifyModal?.hide();
          this.notifyText = 'Your notification has been sent successfully.';
          this.isSuccessNotify = true;
          this.showComposedNotifyMessages = false;
          this.exceptionModal?.modalShow();
          this.LateNotifyForm?.reset();
        } else {
          this.notifyText = AppConstantsListConfig?.uiErrorException;;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.lateNotifySubmitClick = false;
      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.lateNotifySubmitClick = false;
        });
  }
  getReminderMessage() {
    const action = `notification/appointmentRemainder?appointmentId=${this.lateNotifyPatient?.id}`;
    //this.lateNotifySubmitClick = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status == 'SUCCESS') {
        this.LateNotifyForm?.controls['messageToUsers']?.setValue(data?.responseObject);
      }
    },
      (error) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.lateNotifySubmitClick = false;
      })
  }

  public showNotifyModalForEncounter(patient: any, patChart?: string, type?: any): void {
    if (!this.LateNotifyForm?.controls['genericMessage']?.value) {
      // this.LateNotifyForm?.controls['messageToUsers']?.disable();
    }
    this.lateNotifyPatient = patient || {};
    this.isUserLevelNotification = patChart || null;
    this.labNotifyType = type || '';
    this.showMessage = false;
    this.LateNotifyForm?.get('typeSelect')?.setValue('General Notification');
    if(this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || this.lateNotifyPatient?.patientPhoneNumber) {
      const primaryPhone: string = this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || this.lateNotifyPatient?.patientPhoneNumber || null;
      if(primaryPhone) {
        const modifyNumber = primaryPhone?.includes('+1') ? primaryPhone?.replace('+1', '') : primaryPhone;
        this.LateNotifyForm?.get('phoneNumber')?.setValue(modifyNumber);
      } else {
        this.LateNotifyForm?.get('phoneNumber')?.enable();
      }
    } else {
      this.LateNotifyForm?.get('phoneNumber')?.enable();
    }
    this.LateNotifyForm?.get('secureMessage')?.setValidators(null);
    this.LateNotifyForm?.get('secureMessage')?.updateValueAndValidity();
    this.LateNotifyForm?.get('phoneNumber')?.updateValueAndValidity();
    this.lateNotifyModal?.show();
    if (type === 'claim') {
      this.LateNotifyForm?.get('typeSelect')?.setValue('Account Balance');
      this.changeNotificationType();
    }
    if (type == 'DocReview') {
      this.LateNotifyForm?.get('typeSelect')?.setValue('Diagnostics Results');
      this.changeNotificationType();
    }
    this.checkNotifyPageTypes();
  }

  // Update Delay mins in message
  public updateDelayMinsInMsg(): void {
    let message = this.LateNotifyForm?.controls['messageToUsers']?.value || null;
    if (message) {
      const pos = message?.indexOf('is delayed by') + 'is delayed by'?.length;
      let actualDelayVal = message?.substring(pos, message?.indexOf('minutes.', pos));
      actualDelayVal = `is delayed by ${actualDelayVal?.trim()} minutes`;
      message = message?.replace(actualDelayVal, `is delayed by ${this.LateNotifyForm.controls['delayInMins']?.value || 0} minutes`);
      this.LateNotifyForm?.controls['messageToUsers']?.setValue(message);
    }
  }

  // New Outreach Message API calls
  // get notofication message based on type selection
  public getMessageBody(): void {
    let appointmentId = '';
    if (this.labNotifyType === 'claim' && !this.isUserLevelNotification) {
      appointmentId = this.lateNotifyPatient?.appointmentId || this.lateNotifyPatient?.id;
    } else {
      appointmentId = !this.isUserLevelNotification ? (this.lateNotifyPatient?.id || this.lateNotifyPatient?.appointmentId) : null
    }
    const requestParams = {
      "appointmentId": appointmentId,
      "facilityId": this.loginDetails?.facilityId,
      "firstName": this.lateNotifyPatient?.firstName || this.lateNotifyPatient?.patientFirstName,
      "lastName": this.lateNotifyPatient?.lastName || this.lateNotifyPatient?.patientLastName,
      "phoneNumber": this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || this.lateNotifyPatient?.patientPhoneNumber || null,
      "messageType": this.LateNotifyForm?.get('typeSelect')?.value,
      "userInfoId": this.isUserLevelNotification ? this.lateNotifyPatient?.userInfoId || this.lateNotifyPatient?.id : this.lateNotifyPatient?.userInfoId,
    }
    this.lateNotifySubmitClick = true;
    this.noMessageIssue = false;
    this.apiService?.getOutreachConfigMessage(requestParams)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        const notifyType = this.LateNotifyForm?.get('typeSelect')?.value;
        if (notifyType === 'Appointment Delay') {
          let message = data?.body?.responseObject;
          if (message && message?.includes('${timeValue}')) {
            message = message?.replace('${timeValue}', 0)
          }
          this.LateNotifyForm?.controls['messageToUsers']?.setValue(message);
        } else {
          this.LateNotifyForm?.controls['messageToUsers']?.setValue(data?.body?.responseObject || null);
        }
        this.noMessageIssue = false;
      } else {
        this.noMessageIssue = true;
      }
      this.lateNotifySubmitClick = false;
    }, (error) => {
      this.lateNotifySubmitClick = false;
      this.noMessageIssue = true;
    });
  }

  // Post an Notification
  public sendCareOutreachNotification(delayNotifyModal?: UiModalComponent): void {
    if (this.LateNotifyForm?.invalid) {
      this.LateNotifyForm?.markAllAsTouched();
      return;
    }
    if (this.LateNotifyForm?.get('typeSelect')?.value === 'Diagnostics Results' && this.labNotifyType == 'DocReview') {
      this.secureNotification(delayNotifyModal);
      return;
    }
    let appointmentId = '';
    if (this.labNotifyType === 'claim') {
      appointmentId = this.lateNotifyPatient?.appointmentId || this.lateNotifyPatient?.id;
    } else {
      appointmentId = !this.isUserLevelNotification ? (this.lateNotifyPatient?.id || this.lateNotifyPatient?.appointmentId) : null
    }
    const primaryPhone = this.LateNotifyForm?.get('phoneNumber')?.value || this.lateNotifyPatient?.patientPrimaryPhone || this.lateNotifyPatient?.primaryPhone || this.lateNotifyPatient?.patientPhoneNumber || null;
    const modifyNumber = primaryPhone ? (primaryPhone?.includes('+1') ? primaryPhone : `+1${primaryPhone}`) : null;
    const requestParams = {
      "appointmentId": appointmentId,
      "facilityId": this.loginDetails?.facilityId,
      "firstName": this.lateNotifyPatient?.firstName || this.lateNotifyPatient?.patientFirstName,
      "lastName": this.lateNotifyPatient?.lastName || this.lateNotifyPatient?.patientLastName,
      "phoneNumber": modifyNumber || null,
      "messageType": this.LateNotifyForm?.get('typeSelect')?.value,
      "messageBody": this.LateNotifyForm?.controls['messageToUsers']?.value,
      "secureMessage": this.LateNotifyForm?.controls['secureMessage']?.value,
      "userInfoId": this.lateNotifyPatient?.userInfoId || this.lateNotifyPatient?.userInfoId || this.lateNotifyPatient?.id,
    }

    this.lateNotifySubmitClick = true;
    this.apiService?.postNotificationMessage(requestParams)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        delayNotifyModal?.hide();
        this.notifyText = 'Your notification has been sent successfully.';
        this.isSuccessNotify = true;
        this.showComposedNotifyMessages = false;
        this.exceptionModal?.modalShow();
        this.LateNotifyForm?.reset();
        this.resetForm();
      } else {
        this.notifyText = AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.lateNotifySubmitClick = false;
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.lateNotifySubmitClick = false;
      });
  }

  // to make notoifications list diaply in dropdown based on page
  private checkNotifyPageTypes(): void {
    this.delayNotificationTypes = [];
    const notifiyTypesDup = JSON?.parse(JSON?.stringify(AppConstantsListConfig?.notificationTypes || []));
    if (this.pageType) {
      notifiyTypesDup?.forEach(element => {
        if (element?.isShow?.find((isItem: string) => isItem === this.pageType)) {
          this.delayNotificationTypes?.push(element);
        }
      });
    } else {
      this.delayNotificationTypes = notifiyTypesDup || [];
    }
  }

  // update lab rad results review notes
  public updateReviewNotes(reviewNotes: any) {
    this.LateNotifyForm?.controls['secureMessage']?.setValue(reviewNotes || null);
  }

}
