import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor {

  private noNeedHeaders = {
    'https://connect.squareupsandbox.com/v2/payments': true,
    'https://connect.squareup.com/v2/payments': true,
    'https://login.microsoftonline.com': true
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userToken = sessionStorage.getItem('token');
    // .set('Authorization', `Bearer ${userToken}`)

    const user = JSON.parse(sessionStorage.getItem('userDetails'));

    // If Inactive seesion clears disabling the all API calls
    if (!sessionStorage.getItem('isLogin') && !sessionStorage.getItem('userDetails') && !req?.url?.includes('login/provider') && !req?.url?.includes('providerLogin/forgotPasswordProvider') && !req?.url?.includes('providerLogin/resetPasswordProvider')) {
      return EMPTY;
    }

    if (this.noNeedHeaders[req?.url] || req?.url?.includes('https://login.microsoftonline.com')) {
      const modifiedReq = req.clone({
      });
      return next?.handle(modifiedReq);
    }

    let userIdStr = user?.userId;
    if (req?.url?.includes('careFlow/saveCareFlowModuleInfo') && window?.location?.href?.includes('hospital/addAppt')) {
      userIdStr = 'user';
    }
    const modifiedReq = req.clone({
      headers: req.headers.set('id', `${user?.id}`)
        .set('name', `${user?.firstName} ${user?.lastName}`)
        .set('facilityId', `${user?.facilityId}`)
        .set('userId', `${userIdStr || user?.userId}`)
        .set('isAbacEnable', 'true'),
    });
    return next.handle(modifiedReq);
  }
}