import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from '../../services/http.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-facility-action',
  templateUrl: './facility-action.component.html',
  styleUrls: ['./facility-action.component.scss']
})
export class FacilityActionComponent implements OnInit {

  public NewTaskForm: FormGroup;
  public lateNotifySubmitClick: boolean;
  public loadSpinner: boolean;
  public lateNotifyPatient: any;
  public notifyText: string;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild('createTaskModal') createTaskModal: UiModalComponent;
  @Input() noDelay: boolean;

  public loginDetails: any;
  public facilityId: any;
  public showComposedNotifyMessages: boolean;
  selectedIndex: number;
  public isUserLevelNotification: string;
  public categoriesList = [
    { value: 'A1C', desc: 'A1C' },
    { value: 'Blood Work', desc: 'Blood Work' },
    { value: 'ECG', desc: 'ECG' },
    { value: 'Notify Patient On Colon Exam Results', desc: 'Notify Patient On  Colon Exam Results' },
    { value: 'Notify Patient On LAB Results', desc: 'Notify Patient  On LAB Results' },
    { value: 'Notify Patient On Mammogram Results', desc: 'Notify Patient On  Mammogram Results' },
    { value: 'Notify Patient On RAD Results', desc: 'Notify Patient On  RAD Results' },
    { value: 'Notify Patient On Rx Results', desc: 'Notify Patient On  Rx Results' },
    { value: 'Patient Query', desc: 'Patient Query ' },
    { value: 'Referral', desc: 'Referral' },
    { value: 'Urine Analysis', desc: 'Urine Analysis' },
    { value: 'Other', desc: 'Other' }
  ];

  public statusList = [
    { value: 'Open', desc: 'Open' },
    { value: 'In-Progress', desc: 'In-Progress' },
    { value: 'Hold', desc: 'Hold' },
    { value: 'Completed', desc: 'Completed' }
  ];

  public priorityList = [
    { value: 'Low', desc: 'Low' },
    { value: 'Medium', desc: 'Medium' },
    { value: 'High', desc: 'High' }
  ];
  public asigneeList = [];
  public allTasks = [];
  public allExistingUsers = [];
  public taskTypeList = [];
  public loginUser: any;
  public isUpdate: boolean;
  public isUpdateResolution: boolean;
  @Input() idType: any;
  @Input() pageId: any;
  public selectedId: any;
  public displayTable: boolean = false;
  public disableField: boolean;
  public disableDescField: boolean;
  @ViewChild('claimsStatusGrid') taskCard: CardComponent;
  @ViewChild(DataTableDirective, { static: false })
  dtElementTasksStatus: DataTableDirective;
  public dtOptionsTasksStatus: DataTables.Settings = {};
  public dtTriggerTasksStatus: Subject<any> = new Subject<any>();
  public selectedTaskId: any;
  public patientJournalUserId: any;
  public roleType: any;
  public userInformation = [];
  public ownerName: any;
  public taskInfo: any;
  @Output() onTaskUpdate = new EventEmitter();
  public disableCompleteField: boolean
  public selectedTask: string;

  public selectedUserInfoId: null;
  public selectedAppointmentId: null;
  @ViewChild('newtaskSection') newtaskSection: ElementRef


  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router) {
    this.loginUser = JSON.parse(sessionStorage?.getItem('userDetails'));
    this.ownerName = this.loginUser.lastName + ", " + this.loginUser.firstName ;
    this.NewTaskForm = this.formBuilder.group({
      'category': [null, Validators.required],
      'assignee': [null, Validators.required],
      'priority': [null, Validators.required],
      'taskType': [null, Validators.required],
      'status': [null, Validators.required],
      'description': [null, Validators.required],
      //'resolution': [null],
      'role': [null],
      'owner': [null],
    })
  }

  ngOnInit(): void {
    this.displayTable = true;
    this.dtOptionsTasksStatus = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      autoWidth: true,
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    this.loadSpinner = false;
    this.getExistingProvidersByFacility();
    if (!this.isUpdate) {
      this.NewTaskForm?.get('status')?.setValue('Open');
      this.NewTaskForm?.get('status')?.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  public rerender(): void {
    this.dtElementTasksStatus?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }

  public resetForm(): void {
    this.NewTaskForm?.reset();
  }

  // Display the Facility Actions Modal
  public showTaskModal(details): void {
    this.userInformation = details;

    this.createTaskModal?.show();
    //this.selectedId = details?.id || details?.userInfoId;

    if (this.pageId === 'tasks') {
      this.selectedId = details?.id || details?.userInfoId || details?.appointmentId;
      if (details?.userInfoId !== null) {
        this.idType = "userId";
        this.selectedUserInfoId = details?.userInfoId || null;
        this.selectedAppointmentId = details?.appointmentId || null;
      }
      else {
        this.idType = "appointmentId";
        this.selectedAppointmentId = details?.appointmentId || null;
        this.selectedUserInfoId = details?.userInfoId || null;
      }
      this.selectedTask = details.taskId;
      this.openUpdatetask(details);

      setTimeout(() => {
        let buttonElement = document.getElementById('update_' + details?.taskId) as HTMLElement;
        buttonElement.click();
        /* let element = document.getElementById('newtaskSection');
        // this.newtaskSection.nativeElement.scrollTo({behavior: 'smooth'});
         element?.scrollIntoView();*/
      }, 1000)
    }
    else {
      if (this.idType === 'userId') {
        this.patientJournalUserId = details?.id || details?.userInfoId;
        this.selectedId = details?.id || details?.userInfoId;
        this.selectedUserInfoId = details?.userInfoId || null;
        this.selectedAppointmentId = details?.appointmentId || null;
      }
      else if (this.idType === 'appointmentId') {
        this.patientJournalUserId = details?.id || details?.appointmentId;
        this.selectedId = details?.id || details?.appointmentId;
        this.selectedAppointmentId = details?.id || details?.appointmentId || null;
        this.selectedUserInfoId = details?.userInfoId || null;
      }
    }
    this.getTaskList();
    /* this.selectedId = details?.id || details?.userInfoId;
     this.createTaskModal?.show();
     this.getTaskList();*/
  }

  public getExistingProvidersByFacility(): void {
    const action = `provider/getProvidersListforfacility/${this.loginUser?.facilityId}`;
    this.asigneeList = [];
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
     //   const allUsers = data?.responseObject?.filter(item => item?.active) || [];
        const allUsers = data?.responseObject;
        const allDoctors = allUsers?.map((doctorItem: any) => {
          const firstName = doctorItem?.firstName || '';
          const lastName = doctorItem?.lastName || '';
          const asssigneeUserId = doctorItem?.userId;
          return {
            name: `${firstName} ${lastName}`,
            npi: doctorItem?.providerNPI,
            roleType: doctorItem?.roleType,
            asssigneeUserId: asssigneeUserId,
			      userId: doctorItem?.userId
          }
        }) || [];
        this.asigneeList = allDoctors || [];
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  public selectRoleType(event: any): any {
    this.NewTaskForm?.get('role')?.setValue(event?.roleType);
    this.roleType = event?.roleType;
  }

  public getTaskTypeList(event) {
    if (event.value === 'A1C' || event.value === 'Urine Analysis' || event.value === 'Blood Work' || event.value === 'ECG' || event.value === 'Clinical') {
      this.NewTaskForm?.get('taskType')?.setValue('Clinical');
    } else if (event.value === 'Referral') {
      this.NewTaskForm?.get('taskType')?.setValue('Referral');
    } else if (event.value === 'Patient Query' || event.value === 'Notify Patient On LAB Results' || event.value === 'Notify Patient On RAD Results' || event.value === 'Notify Patient On Rx Results' || event.value === 'Notify Patient On Colon Exam Results' || event.value === 'Notify Patient On Mammogram Results') {
      this.NewTaskForm?.get('taskType')?.setValue('Patient Communication');
    } else if (event.value === 'Other') {
      this.NewTaskForm?.get('taskType')?.setValue('Operational');
    }
  }


  public addTask(): void {
    if (this.NewTaskForm?.invalid) {
      this.NewTaskForm?.markAllAsTouched();
      return;
    }
    const category = this.NewTaskForm?.get('category')?.value;
    const taskType = this.NewTaskForm?.get('taskType')?.value;
    const assignee = this.NewTaskForm?.get('assignee')?.value;
    const description = this.NewTaskForm?.get('description')?.value;
    const id = this.selectedId;
    const priority = this.NewTaskForm?.get('priority')?.value;
    const userObj = this.asigneeList?.filter(item => item?.firstName === assignee) || [];

    let roleType = null;
    let asssigneeUserId = null;
    if (userObj.length > 0) {
      roleType = userObj[0].roleType;
      asssigneeUserId = userObj[0].userId;
    }
    else {
      roleType = null;
      asssigneeUserId = null;
    }
     

    let taskDto = null;

    if (this.idType === 'userId') {
      taskDto = {
        "role": roleType,
        "taskDetails": {
          "assignee": assignee,
          "asssigneeUserId": asssigneeUserId,
          "category": category,
          "createdBy": this.ownerName,
          "description": description,
          "priority": priority,
          "status": "Open"
        },
        "taskType": taskType,
        "appointmentId": this.selectedAppointmentId,
        "userInfoId": this.selectedUserInfoId || this.selectedId

      };
      //action = `tasks/createTasks?category=${category}&taskType=${taskType}&assignee=${assignee}&description=${description}&userInfoId=${id}&priority=${priority}&role=${this.NewTaskForm?.get('role')?.value}`;
    }
    else if (this.idType === 'appointmentId') {
      taskDto = {
        "appointmentId": this.selectedAppointmentId,
        "userInfoId": this.selectedUserInfoId,
        "role": roleType,
        "taskDetails": {
          "assignee": assignee,
          "asssigneeUserId": asssigneeUserId,
          "category": category,
          "createdBy": this.ownerName,
          "description": description,
          "priority": priority,
          "status": "Open"
        },
        "taskType": taskType
      };
    }
    const action = `tasks/createTasks`;
    this.httpService?.makeRequestWithAction(action, taskDto)?.subscribe((data: any) => {
      this.resetTask();
      this.displayTable = true;
      this.getTaskList();
    }, error => {

    });
  }

  public getTaskList(): void {
    this.rerender();
    // const action = `tasks/listTasks`;
    const id = this.selectedId;
    let action = '';

    if (this.idType === 'userId') {
      action = `tasks/listTasks?userInfoId=${id}`;
    }
    else if (this.idType === 'appointmentId') {
      action = `tasks/listTasks?appointmentId=${id}`;
    }
    this.taskCard?.cardRefreshShow();
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.allTasks = data?.responseObject || [];
        const tasksList = data?.responseObject || [];
        if (this.allTasks.length !== 0) {
          this.displayTable = true;
        }
        else {
          this.displayTable = false;
        }
      } else {
        this.displayTable = false;
      }
      //this.dataTableFilter();
      this.taskCard?.cardRefreshHide();
      this.dtTriggerTasksStatus?.next();
    },
      (error) => {
        this.loadSpinner = false;

        this.taskCard?.cardRefreshHide();
        this.dtTriggerTasksStatus?.next();
      });
  }

  public openUpdatetask(task: any): void {
    const userObj = this.asigneeList?.filter(item => item?.firstName === task.assignee) || [];
    if (userObj.length > 0) {
      this.roleType = userObj[0].roleType;
    }
    else {
      this.roleType = null;
    }
    this.isUpdate = true;
    this.selectedTask = task.taskId;
    this.selectedAppointmentId = task.appointmentId;
    this.selectedUserInfoId = task.userInfoId;

    this.NewTaskForm?.get('category')?.setValue(task.category);
    this.NewTaskForm?.get('taskType')?.setValue(task.taskType);
    this.NewTaskForm?.get('assignee')?.setValue(task.assignee);
    //this.NewTaskForm?.get('description')?.setValue(task.description);
    this.NewTaskForm?.get('priority')?.setValue(task.priority);
    this.NewTaskForm?.get('status')?.setValue(task.status);
    this.NewTaskForm?.get('owner')?.setValue(task.createdBy);

    this.selectedTaskId = task.taskId;

    //this.NewTaskForm = task as FormGroup;
    this.disableField = true;
    this.NewTaskForm?.get('status')?.enable();
    if (this.isUpdate) {
      this.NewTaskForm?.get('category').disable();
    }
    else {
      this.NewTaskForm?.get('category').enable();
    }

    if (task.status === "Completed") {

      this.disableCompleteField = true;

    }
    else {
      this.disableCompleteField = false;

    }
    if (task?.createdBy === task?.assignee) {
      this.disableDescField = false;
    }
    else {
      this.disableDescField = true;
    }
    this.NewTaskForm.get('status').setValidators([Validators.required]);
    this.getTaskDetailedInfo(this.selectedTaskId);
  }

  public getTaskDetailedInfo(taskId) {
    const action = `tasks/getTaskDetails?taskId=${taskId}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.taskInfo = data?.responseObject || [];
      }
    },
      (error) => {

      });
  }

  public getAppointmentTime(date: any): string {
    return this.httpService?.getAppointmentTime(date);
  }

  public displayResolution(event) {
    const status = this.NewTaskForm?.get('status')?.value;
    if (status === 'Completed') {
      this.isUpdateResolution = true;
      this.NewTaskForm.get('resolution').setValidators([Validators.required]);
    }
    else {
      this.isUpdateResolution = false;
      this.NewTaskForm.get('resolution').setValidators([null]);
    }
  }

  public updateTask(): void {
    if (this.NewTaskForm?.invalid) {
      this.NewTaskForm?.markAllAsTouched();
      return;
    }
    const description = this.NewTaskForm?.get('description')?.value;
    const taskId = this.selectedTaskId;
    const status = this.NewTaskForm?.get('status')?.value;
    //const resolution = this.NewTaskForm?.get('resolution')?.value;
    const category = this.NewTaskForm?.get('category')?.value;
    const taskType = this.NewTaskForm?.get('taskType')?.value;
    const owner = this.NewTaskForm?.get('owner')?.value;
    const assignee = this.NewTaskForm?.get('assignee')?.value;
    const priority = this.NewTaskForm?.get('priority')?.value;

    const userObj = this.asigneeList?.filter(item => item?.firstName === assignee) || [];
    let roleType = null;
    if (userObj.length > 0) {
      roleType = userObj[0].roleType;
    } else {
      roleType = null;
    }

    const taskDto = {
      "role": roleType,
      "taskDetails": {
        "assignee": assignee,
        "category": category,
        "createdBy": owner,
        "description": description,
        "priority": priority,
        "status": status
      },
      "taskId": taskId,
      "taskType": taskType,
      "appointmentId": this.selectedAppointmentId,
      "userInfoId": this.selectedUserInfoId,
    };
    const action = `tasks/updateTask`;
    this.httpService?.makeRequestWithAction(action, taskDto)?.subscribe((data: any) => {
      this.displayTable = true;
      this.resetTask();
      this.getTaskList();
    }, error => {
      // this.taskCard?.cardRefreshHide();  
    });
  }

  public resetTask() {
    if (this.pageId === 'tasks') {
      this.onTaskUpdate?.emit(true);
    }

    this.NewTaskForm.reset();
    this.selectedTask = null;
    this.NewTaskForm.get('status').setValidators([]);
    //this.NewTaskForm.get('resolution').setValidators([]);

    this.NewTaskForm?.get('category')?.setValue(null);
    this.NewTaskForm?.get('taskType')?.setValue(null);
    this.NewTaskForm?.get('assignee')?.setValue(null);
    this.NewTaskForm?.get('description')?.setValue(null);
    this.NewTaskForm?.get('priority')?.setValue(null);
    this.NewTaskForm?.get('status')?.setValue("Open");
    //this.NewTaskForm?.get('resolution')?.setValue(null);
    this.isUpdate = false;
    this.isUpdateResolution = false;
    this.disableDescField = false;
    this.disableField = false;
  }


  public closeTaskModal(createTaskModal: UiModalComponent) {
    if (this.pageId === 'tasks') {
      createTaskModal.hide();
    }
  }

  public updateJournalNotes(userDetails: any, notes: any): void {
    const action = `userInfo/savePatientJournalNotesRequest`;
    const notesRequest = {
      "channel": "SMS",
      "node": null,
      "notes": notes,
      "notifyResponder": "no",
      "responderNPI": null,
      "responderName": null,
      "responderRole": this.roleType,
    }
    const params = {
      "appointmentId": null,
      "facilityId": this.loginUser?.facilityId,
      "notesRequest": notesRequest,
      "notesResponse": null,
      "patientFirstName": userDetails?.firstName,
      "patientLastName": userDetails?.lastName,
      "type": "Patient Journal Notes",
      "uniqueId": null,
      "userInfoId": this.patientJournalUserId
    }
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {

      } else {

      }
    }, (error) => {

    });
  }

  public navigateToTop(el: HTMLElement): void {
    // this.scroller.scrollToAnchor("mainDetails");
    el.scrollIntoView();
  }

  ngOnDestroy(): void {
    if (this.dtTriggerTasksStatus) {
      this.dtTriggerTasksStatus?.unsubscribe();
    }
  }

}
