import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { HttpService } from 'src/app/theme/shared/services/http.service';

@Component({
  selector: 'app-preferred-pharmacy',
  templateUrl: './preferred-pharmacy.component.html',
  styleUrls: ['./preferred-pharmacy.component.scss']
})
export class PreferredPharmacyComponent implements OnInit {

  public searchPharmacyByZipCode: Observable<any>;
  public pharmacyLoading = false;
  public pharmacySearchInput = new Subject<string>();

  @Input() pharmacyFormGroup: FormGroup

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
  
    this.searchCurrentMedicationsDrugs();
  }
 
  private searchCurrentMedicationsDrugs() {
    this.searchPharmacyByZipCode = concat(
      of([]), // default items
      this.pharmacySearchInput.pipe(
        distinctUntilChanged(),
        tap(() => this.pharmacyLoading = true),
        switchMap(term => this.httpService.preferredPharmacy(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.pharmacyLoading = false)
        ))
      )
    );
  }

  public trackByFn(item: any) {
    return item?.name;
  }

  // selected pharmacy
  public selectedPharmacy(event: any): void {
    this.pharmacyFormGroup?.get('name')?.setValue(event?.name);
    // this.pharmacyFormGroup?.get('pharmacyholderName')?.setValue(event?.pharmacyholderName);
    this.pharmacyFormGroup?.get('fax')?.setValue(event?.fax || null);
    this.pharmacyFormGroup?.get('phoneNumber')?.setValue(event?.phone || null);

    this.pharmacyFormGroup?.get('address')?.get('city')?.setValue(event?.city);
    this.pharmacyFormGroup?.get('address')?.get('state')?.setValue(event?.state);
    this.pharmacyFormGroup?.get('address')?.get('street')?.setValue(event?.address_1);
    this.pharmacyFormGroup?.get('address')?.get('zipCode')?.setValue(this.addPrefixZipCode(event?.zip,''));
  }
  
  public addPrefixZipCode(addressZipCode: any,valid:any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
   
    return prefixZipcode || addressZipCode || '';
  }

}
