<div class="basic modal fade" [class.centerModal]="modalCentered" tabindex="-1" role="dialog" aria-hidden="true" [ngClass]="{'in': visibleAnimate}"
  [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0}">
  <div class="modal-dialog modal-dialog-centered" role="document" [class.modal-dialog-centered]="modalCentered" [ngClass]="dialogClass" >
    <div class="modal-content">
      <div *ngIf="!hideHeader" class="modal-header">
        <ng-content select=".app-modal-header"></ng-content>
      </div>
      <div class="modal-body">
        <ng-content select=".app-modal-body"></ng-content>
      </div>
      <div *ngIf="!hideFooter" class="modal-footer">
        <ng-content select=".app-modal-footer"></ng-content>
      </div>
      <!-- Spinner -->
      <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
          <span class="spinnerMsg" *ngIf="spinnerMessageToUser">{{spinnerMessageToUser}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- (click)="onContainerClicked($event)" -->