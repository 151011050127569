<app-ui-modal id="lateNotifyModal" #lateNotifyModal [modalCentered]="true" [loadSpinner]="lateNotifySubmitClick"
    [hideHeader]="false">
    <div class="app-modal-header">
        <h5 class="modal-title">Notification</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="lateNotifyModal.hide();resetForm()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body send-notifications" [formGroup]="LateNotifyForm">

        <!-- <div class="form-group">
            <label>Notification Type <span class="required">*</span></label><br />
            <label class="radio-inline" *ngFor="let item of delayNotificationTypes">
                <input formControlName="typeSelect" type="radio" (change)="changeNotificationType()" name="typeSelect"
                    [value]="item.value"><span class="desc">{{item.desc}}</span></label>
            <br />
            <span class="required"
                *ngIf="LateNotifyForm.get('typeSelect').errors?.required && LateNotifyForm.get('typeSelect').touched">Notification
                Type is Required</span>
        </div> -->
        <div class="row notify-body" [ngClass]="showMessage? 'msg-show': 'msg-hide'">
            <div class="col-md-12">
                <!-- <form class="notify-form"> -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Type <span class="text-danger">*</span></label>
                            <!-- <select class="form-control">
                                <option>General</option>
                                <option>Appointment Delay</option>
                                <option>Reminder</option>
                                <option>Secure</option>
                            </select> -->
                            <ng-select placeholder="Choose Notification Type" [items]="delayNotificationTypes" id="sendSmsNotify_Type"
                                formControlName="typeSelect" name="typeSelect" bindLabel="desc" bindValue="value"
                                (change)="changeNotificationType()"></ng-select>
                           
                            <div class="required"
                                *ngIf="LateNotifyForm.get('typeSelect').errors?.required && LateNotifyForm.get('typeSelect').touched">Notification
                                Type is required</div>
                        </div>
                    </div>
					 <div class="col-md-6">
                        <div class="form-group">
                            <label>Patient’s Phone Number <span class="text-danger">*</span></label>
                            <input type="text" class="form-control input-text"  formControlName="phoneNumber" mask="(000) 000-0000"
                            id="sendSmsNotify_Number" placeholder="(xxx) xxx-xxxx">
                            <div class="required" *ngIf="LateNotifyForm?.get('phoneNumber')?.errors?.required && LateNotifyForm?.get('phoneNumber')?.touched">Phone Number is required</div>
                            <div class="required" *ngIf="LateNotifyForm?.get('phoneNumber')?.invalid && !LateNotifyForm?.get('phoneNumber')?.errors?.required">Please enter a valid Phone number</div>
                        </div>
                    </div>
					
				</div>
				 <div class="row">
                   
                    <div class="col-md-12">
                        <div class="form-group" *ngIf="LateNotifyForm.get('typeSelect').value === 'Appointment Delay'">
                            <label class="form-label">Delay in Minutes <span class="required">*</span></label>
                            <input type="number" class="form-control input-text" (input)="updateDelayMinsInMsg()" formControlName="delayInMins" id="sendSmsNotify_delay"
                                name="validation-required" placeholder="Enter Delay in Minutes">
                            <label class="required error-msg"
                                *ngIf="LateNotifyForm.controls['delayInMins'].hasError('required') && LateNotifyForm.controls['delayInMins'].touched">Appointment Delay
                                in Minutes is required</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Message <span class="required">*</span></label>
                            <label class="right" (click)="preComposedMessage()" *ngIf="LateNotifyForm?.get('typeSelect')?.value !== 'Diagnostics Results'"><span class="pre-msg"><i class="fa fa-envelope"></i></span></label>
                            <!-- <textarea class="form-control" placeholder="">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                            </textarea> -->
                            <textarea class="form-control" rows="3" [attr.disabled]="LateNotifyForm?.get('messageToUsers')?.value && LateNotifyForm?.get('typeSelect')?.value !== 'General Notification' &&  LateNotifyForm?.get('typeSelect')?.value !== 'Care Gap' && !noMessageIssue ? true : null" formControlName="messageToUsers" id="sendSmsNotify_msg"
                                name="validation-required" placeholder="Write Message..."></textarea>
                            <label class="required error-msg"
                                *ngIf="LateNotifyForm.controls['messageToUsers'].hasError('required') && LateNotifyForm.controls['messageToUsers'].touched">
                                Message is required</label>
                            <label class="required"
                                *ngIf="LateNotifyForm.controls['messageToUsers'].invalid && !LateNotifyForm.controls['messageToUsers'].errors?.required">Please
                                enter a valid Message</label>
                        </div>
                    </div>

                    <div class="col-md-12" *ngIf="LateNotifyForm?.get('typeSelect')?.value === 'Diagnostics Results'">
                        <div class="form-group">
                            <label class="form-label">Notes <span class="required">*</span></label>
                            <label class="right" (click)="preComposedMessage()" *ngIf="LateNotifyForm?.get('typeSelect')?.value === 'Diagnostics Results'"><span class="pre-msg"><i
                                class="fa fa-envelope"></i></span></label>
                            <textarea class="form-control" rows="3" formControlName="secureMessage" id="sendSmsNotify_notes"
                                name="validation-required" placeholder="Notes"></textarea>
                            <label class="required error-msg"
                                *ngIf="LateNotifyForm?.get('secureMessage')?.hasError('required') && LateNotifyForm?.get('secureMessage')?.touched">
                                Notes is required</label>
                        </div>
                    </div>
                    
                    <!--   <div class="col-md-12">
                        <div class="form-group">
                          <label>Message Preview<span class="text-danger">*</span></label>
                            <textarea class="form-control" disabled
                                placeholder="">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... </textarea>
                            <label class="required"
                                *ngIf="LateNotifyForm.controls['messageToUsers'].hasError('required') && LateNotifyForm.controls['messageToUsers'].touched">Notification
                                Message is required</label>
                            <label class="required"
                                *ngIf="LateNotifyForm.controls['messageToUsers'].invalid && !LateNotifyForm.controls['messageToUsers'].errors?.required">Please
                                enter a valid Notification Message</label> 
                        </div>
                    </div>-->
                    <div class="col-md-12" *ngIf="LateNotifyForm.get('typeSelect').value == 'Secure'">
                        <div class="form-group">
                            <span class="blueLabel">Message will be delivered through a secure channel</span>                            
                        </div>
                    </div>
                   
                </div>
                <!-- </form> -->
            </div>
            <ng-container
                *ngIf="LateNotifyForm.get('typeSelect').value && LateNotifyForm.get('typeSelect').value !== 'Appointment Reminder' && showComposedNotifyMessages">
                <div class="col-md-5 notify-col">

                    <ng-container 
                        *ngIf="LateNotifyForm.get('typeSelect').value === 'Appointment Delay' && showComposedNotifyMessages">
                        <!-- <ng-select placeholder="Select Generic Message" (change)="genericMessageChange()" formControlName="genericMessage" [items]="genericMsgDelay" bindLabel="desc" bindValue="value"></ng-select> -->
                        <label class="radio-inline pre-select-radio" *ngFor="let item of genericMsgDelay;let i = index"
                            [class.active]="selectedIndex === i">
                            <input formControlName="genericMessage" (click)="changeRadio(item?.value)"
                                (change)="genericMessageChange(i)" type="radio" name="genericMessage"
                                id="notify{{item?.value}}" [value]="item?.value"><span
                                class="desc">{{item?.desc}}</span></label>
                    </ng-container>
                    <ng-container
                        *ngIf="LateNotifyForm.get('typeSelect').value !== 'Appointment Delay' && showComposedNotifyMessages">
                        <!-- <ng-select placeholder="Select Generic Message" (change)="genericMessageChange()" formControlName="genericMessage" [items]="genericMsgGeneral" bindLabel="desc" bindValue="value"></ng-select> -->
                        <label class="radio-inline pre-select-radio"
                            *ngFor="let item of genericMsgGeneral;let i = index" [class.active]="selectedIndex === i">
                            <input formControlName="genericMessage" (click)="changeRadio(item?.value)"
                                (change)="genericMessageChange(i)" type="radio" name="genericMessage"
                                id="notify{{item?.value}}" [value]="item?.value"><span
                                class="desc">{{item?.desc}}</span></label>
                    </ng-container>
                    <label class="required"
                        *ngIf="LateNotifyForm.controls['genericMessage'].hasError('required') && LateNotifyForm.controls['genericMessage'].touched">Generic
                        Meesage should be required</label>
                    <!--   <div class="notify-news">
                    <ul>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet... 
                        </li>
                    </ul> 
                </div>-->
                </div>
            </ng-container>
        </div>

    </div>
    <div class="app-modal-footer">
        <!-- <button type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="lateNotifyModal.hide()">Cancel</button> -->
        <!-- <button type="button" [disabled]="LateNotifyForm.invalid" (click)="lateNotifyRequest(lateNotifyModal, notifyTextModal)" style="margin-right: 15px;" class="btn btn-primary">Submit</button> -->

        <button class="btn btn-primary dash-actions" style="margin-right: 15px;" type="button" (click)="sendCareOutreachNotification(lateNotifyModal)" id="sendSmsNotify_submit" [disabled]="lateNotifySubmitClick">Submit</button>

    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>