<div class="main-visit">
    <div class="toggle-icon" (click)="onAddClass()"></div>
    <div class="past-visit" id="mainDetails" #mainDetails>

        <div class="col-lg-12 col-md-12 col-sm-12 patient-info-sec">
            <span class="name"
                *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">{{appointmentInfoDetails?.appointment?.purposeOfVisit}}
                Visit</span>
            <span class="name"
                *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'">{{appointmentInfoDetails?.appointment?.purposeOfVisit}}</span>
            <span class="gender-age"
                *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">{{getAppointmentTime(appointmentInfoDetails?.appointment?.dateTime)}}</span>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 patient-info-sec mt-2">
            <span *ngIf="appointmentCostOfCareDetails?.apptCostofCare?.patientId">Patient ID:
                &nbsp;&nbsp;<b>{{appointmentCostOfCareDetails?.apptCostofCare?.patientId}}</b></span>
            <span *ngIf="appointmentCostOfCareDetails?.apptCostofCare?.claimId">&nbsp;&nbsp;| &nbsp;&nbsp;Claim ID:
                &nbsp;&nbsp;<b>{{appointmentCostOfCareDetails?.apptCostofCare?.claimId}}</b></span>
        </div>



    </div>

    <app-card #userVisitCard class="new-designed-card" [hidHeader]="true" [options]="false">
        <aw-wizard #wizard class="arc-wizard" class="new-designed-left-arcs" [defaultStepIndex]="defaultStepIndex"
            navBarLocation="left" [awNavigationMode] navigateBackward="allow" navigateForward="allow">

            <aw-wizard-step stepTitle="Patient Info" awOptionalStep>

                <h5 class="header">Demographics</h5>
                <hr />
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle"> First Name </label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label> {{ appointmentInfoDetails?.firstName || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle"> Middle Name </label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label> {{ appointmentInfoDetails?.middleName || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle"> Last Name </label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label> {{ appointmentInfoDetails?.lastName || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle"> DOB </label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label> {{ appointmentInfoDetails?.dob || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle"> Gender </label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label> {{ appointmentInfoDetails?.gender || '--'}}</label>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle"> Age </label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.age}}</label>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle"> Marital Status </label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.maritalStatus || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Race</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.race || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Ethnicity</label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.ethnicity || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.address?.street || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.address?.city || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.address?.state || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{addPrefixZipCode(appointmentInfoDetails?.address?.zipCode,'') || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Phone Number</label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.patientPrimaryPhone || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Email</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.email || '--'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Insurance Details -->
                <h5 class="header">Insurance Details</h5>
                <hr />
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Coverage Type</label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label *ngIf="appointmentInfoDetails?.unInsured === true">Un-Insured</label>
                                <label *ngIf="appointmentInfoDetails?.selfPay === true">Self-Pay</label>
                                <label *ngIf="appointmentInfoDetails?.healthPass === true">Health Pass</label>
                                <label
                                    *ngIf="appointmentInfoDetails?.unInsured === false && appointmentInfoDetails?.selfPay === false && appointmentInfoDetails?.healthPass === false">Insurance</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row insurance-items"
                    *ngFor="let insurance of appointmentInfoDetails?.insuranceDetails; let i = index">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5 style="float: right;color: #296746;" *ngIf="insurance?.active">Active</h5>
                        <h5 style="float: right;color: red" *ngIf="!insurance?.active">Inactive</h5>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-10">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Insurance Name</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{insurance?.name || '--'}} </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Member ID / Subscriber ID</label> <span
                                            class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{insurance?.memberId || '--'}} </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Payor ID</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{insurance?.payerId || '--'}} </label>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Group Number</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{insurance?.groupNumber || '--'}} </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Coverage Sequence</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{insurance?.priority || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Insurance Class</label> <span class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{insurance?.insuranceClass || '--'}} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <div class="download-insurance-card mt-2" *ngIf="insurance?.insuranceCardImageId">
                            <h5 class="download-insurance">
                                <span
                                    (click)="downloadInsuranceCard(insurance?.insuranceCardImageId, insuraceCardPastVisitViewModal)">View/Download
                                    Insurance Card <br /> <i class="las la-cloud-download-alt"></i></span>
                            </h5>
                        </div>
                        <div class="download-insurance-card mt-2" *ngIf="!insurance?.insuranceCardImageId">
                            <h5 class="download-insurance">Insurance card not available</h5>
                        </div>
                    </div>

                </div>

                <ng-container *ngIf="!appointmentInfoDetails?.unInsured && !appointmentInfoDetails?.selfPay">
                    <h5 class="header">Primary Subscriber Details</h5>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">First Name</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{subscriberDetails?.subscriberfirstname || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Name</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{subscriberDetails?.subscriberlastname || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">DOB</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{subscriberDetails?.subscriberdateOfBirth || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Gender</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{subscriberDetails?.subscribergender || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Relationship to Subscriber</label> <span
                                        class="label-colon">
                                        : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{subscriberDetails?.relationToSubscriber || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Emergency Contact Details section -->

                <h5 class="header">Emergency Contact Details</h5>
                <hr />
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Name</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label> {{appointmentInfoDetails?.emergencyDetails?.name || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Relationship</label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label> {{appointmentInfoDetails?.emergencyDetails?.relationship || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Emergency Contact</label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label> {{appointmentInfoDetails?.emergencyDetails?.phone || '--'}} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="header">Employer Details</h5>
                <hr />
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-3 col-md-3 col-sm-6">
                                <label class="labelStyle">Employer Name</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-6">
                                <label>{{subscriberDetails?.employerName || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{subscriberDetails?.employerAddress?.street || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{subscriberDetails?.employerAddress?.city || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{subscriberDetails?.employerAddress?.state || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{addPrefixZipCode(subscriberDetails?.employerAddress?.zipCode,'') || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="header">Billing Address Details</h5>
                <hr />
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.billingAddress?.street || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.billingAddress?.city || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.billingAddress?.state || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Zipcode</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{addPrefixZipCode(appointmentInfoDetails?.billingAddress?.zipCode,'') || '--'}} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Preferred Pharmacy -->
                <h5 class="header">Preferred Pharmacy</h5>
                <hr />
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Pharmacy Name</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.pharmacy?.name || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Phone Number</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.pharmacy?.phoneNumber || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Fax</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.pharmacy?.fax || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.pharmacy?.address?.street || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.pharmacy?.address?.city || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{appointmentInfoDetails?.pharmacy?.address?.state || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{addPrefixZipCode(appointmentInfoDetails?.pharmacy?.address?.zipCode,'') || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <aw-wizard-step stepTitle="Consent Forms" awOptionalStep>
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Consent Forms</h5>
                        <hr>
                    </div>
                </div>
                <app-view-consent-forms [allConsentFormDocs]="allConsentFormDocs" [cardReload]="userVisitCard">
                </app-view-consent-forms>
            </aw-wizard-step>

            <aw-wizard-step stepTitle="ID Documents" awOptionalStep>
                <div class="row">
                    <div class="col-sm-12">
                        <h5>ID Documents</h5>
                        <hr>
                    </div>
                </div>
                <div class="row images-sec">
                    <ng-container *ngIf="checkCommonIdDocument">
                        <div class="col-lg-10 col-md-10 col-sm-12">
                            <!-- <h5 class="head-color">Driving Licence Card</h5> -->
                            <iframe id="dl-past-visit-card" frameBorder="0" width="100%" height="650px"></iframe>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!checkCommonIdDocument">
                        <div class="col-lg-10 col-md-10 col-sm-12">
                            <h5>ID Document is not available.</h5>
                        </div>
                    </ng-container>
                </div>
            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Chief Complaints" awOptionalStep>
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Chief Complaints</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <ng-container *ngIf="chiefComplaints?.length > 0">
                            <ul class="unstyled">
                                <li *ngFor="let value of chiefComplaints; let cati = index">{{value}}</li>
                            </ul>
                            <!-- </div> -->
                        </ng-container>
                        <ng-container *ngIf="chiefComplaints?.length === 0">
                            <h5>Not selected any Chief Complaints..!</h5>
                        </ng-container>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="treatmentFlowAddNotes?.otherNotes">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Other Complaints</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{treatmentFlowAddNotes?.otherNotes || '--'}} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="treatmentFlowAddNotes">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{treatmentFlowAddNotes?.chiefComplaintsNotes || '--'}} </label>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <!-- History of Present Illness section -->
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="History of Present Illness" awOptionalStep>
                <div class="row">
                    <div class="col-sm-12">
                        <h5>History of Present Illness</h5>
                        <hr>
                    </div>
                </div>
                <!-- Pain Section -->
                <div class="pain-sec" *ngIf="checkObjValues(hpiForm?.pain)">
                    <h5><u>Pain</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pain?.location">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Location</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pain?.location || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pain?.quality?.length > 0">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Quality</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label *ngFor="let item of hpiForm?.pain?.quality">{{ item || item?.itemName ||
                                        '--'}},
                                    </label>
                                    <!-- <ul>
                                    <li *ngFor="let item of hpiForm?.pain?.quality">{{ item || item?.itemName || '--'}}</li>
                                </ul> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pain?.severity">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Severity</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pain?.severity || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pain?.duration">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Duration (in number of days)</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pain?.duration || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pain?.context">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Context</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pain?.context || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pain?.modifyingFactors">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Modifying Factors</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pain?.modifyingFactors || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pain?.associatedSignsAndSymptoms">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Associated Signs and Symptoms</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pain?.associatedSignsAndSymptoms || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Diabetes Section -->
                <div class="diabetes-sec" *ngIf="checkObjValues(hpiForm?.diabetes)">
                    <h5><u>Diabetes</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.diabetes?.type">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Type</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.diabetes?.type || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.diabetes?.duration">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Duration (in number of Years)</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.diabetes?.duration || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.diabetes?.anyEpisodesOfLowBloodSugars">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Any episodes of Low Blood Sugars</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.diabetes?.anyEpisodesOfLowBloodSugars === true ? 'Yes' : '--') ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.diabetes?.anyEpisodesOfLowBloodSugars && hpiForm?.diabetes?.lowBloodSugarAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Any episodes of Low Blood Sugar Additional Notes</label>
                                    <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.diabetes?.lowBloodSugarAdditionalNotes || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.diabetes?.takingPrescribedMedications">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Taking Prescribed Medications</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.diabetes?.takingPrescribedMedications === true ? 'Yes' : '--') ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.diabetes?.takingPrescribedMedications && hpiForm?.diabetes?.takingPrescribedMedicationsAddittonalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Taking Prescribed Medications Additional Notes</label>
                                    <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.diabetes?.takingPrescribedMedicationsAddittonalNotes || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Side Effects</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.diabetes?.sideEffects || '--'}} </label>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.diabetes?.diet">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Diet</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.diabetes?.diet === true ? 'Yes' : '--') || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.diabetes?.diet && hpiForm?.diabetes?.dietAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Diet Additional Notes</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.diabetes?.dietAdditionalNotes || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.diabetes?.lastA1CCheck">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last A1C Check</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.diabetes?.lastA1CCheck || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.diabetes?.lastEyeExam">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Eye Exam</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.diabetes?.lastEyeExam || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.diabetes?.cardiovascularComplications">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Cardiovascular Complications</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.diabetes?.cardiovascularComplications || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.diabetes?.acute">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Acute</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.diabetes?.acute === true ? 'Yes' : '--') || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Chronic</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.diabetes?.chronic === true ? 'Yes' : 'No') || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">History of COA</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.diabetes?.historyOfCOA === true ? 'Yes' : 'No') || '--'}} </label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <!-- Hypertension Section -->
                <div class="hypertension-sec" *ngIf="checkObjValues(hpiForm?.hypertension)">
                    <h5><u>Hypertension</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.hypertension?.howLong">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">How Long (in number of Years)</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.hypertension?.howLong || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.hypertension?.anySymptoms">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Any Symptoms</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.hypertension?.anySymptoms || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.hypertension?.checkingBloodPressure">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Checking Blood Pressure</label> <span class="label-colon">
                                        :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.hypertension?.checkingBloodPressure === true ? 'Yes' : '--') ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.hypertension?.checkingBloodPressure && hpiForm?.hypertension?.bpAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Checking Blood Pressure Additional Notes</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.hypertension?.bpAdditionalNotes || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.hypertension?.takingPrescribedMedications">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Taking Prescribed Medications</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.hypertension?.takingPrescribedMedications === true ? 'Yes' :
                                        '--') ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.hypertension?.takingPrescribedMedications && hpiForm?.hypertension?.takingPrescribedMedicationsAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Taking Prescribed Medications Additional Notes</label>
                                    <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.hypertension?.takingPrescribedMedicationsAdditionalNotes ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.hypertension?.acute">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Acute</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.hypertension?.acute === true ? 'Yes' : '--') || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Chronic</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.hypertension?.chronic === true ? 'Yes' : 'No') || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Side Effects</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.hypertension?.sideEffects || '--'}} </label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <!-- High Cholesterol Section -->
                <div class="highcholesterol-sec" *ngIf="checkObjValues(hpiForm?.highCholesterol)">
                    <h5><u>High Cholesterol</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.highCholesterol?.howLong">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">How Long (in number of Years)</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.highCholesterol?.howLong || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.highCholesterol?.takingPrescribedMedications">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Taking Prescribed Medications</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.highCholesterol?.takingPrescribedMedications === true ? 'Yes' :
                                        '--')
                                        || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.highCholesterol?.takingPrescribedMedications && hpiForm?.highCholesterol?.takingPrescribedMedicationsAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Taking Prescribed Medications Additional Notes</label>
                                    <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.highCholesterol?.takingPrescribedMedicationsAdditionalNotes ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.highCholesterol?.sideEffects">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Side Effects</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.highCholesterol?.sideEffects || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- chronic obstructive pulmonary disease (COPD) Section -->
                <div class="copd-sec" *ngIf="checkObjValues(hpiForm?.chronicObstructivePulmonaryDisease)">
                    <h5><u>Chronic obstructive pulmonary disease (COPD)</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.chronicObstructivePulmonaryDisease?.smoking">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Smoking</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.chronicObstructivePulmonaryDisease?.smoking === true ? 'Yes' :
                                        '--')
                                        || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.chronicObstructivePulmonaryDisease?.smoking && hpiForm?.chronicObstructivePulmonaryDisease?.smokingAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Smoking Additional Notes</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.chronicObstructivePulmonaryDisease?.smokingAdditionalNotes ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.chronicObstructivePulmonaryDisease?.shortnessOfBreath">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Shortness of Breath</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.chronicObstructivePulmonaryDisease?.shortnessOfBreath === true ?
                                        'Yes' : '--') || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.chronicObstructivePulmonaryDisease?.smoking && hpiForm?.chronicObstructivePulmonaryDisease?.shortnessOfBreathAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Shortness of Breath Additional Notes</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{
                                        hpiForm?.chronicObstructivePulmonaryDisease?.shortnessOfBreathAdditionalNotes
                                        || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.chronicObstructivePulmonaryDisease?.coughing">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Coughing</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.chronicObstructivePulmonaryDisease?.coughing === true ? 'Yes' :
                                        '--')
                                        || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.chronicObstructivePulmonaryDisease?.coughing && hpiForm?.chronicObstructivePulmonaryDisease?.coughingAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Coughing Additional Notes</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.chronicObstructivePulmonaryDisease?.coughingAdditionalNotes ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.chronicObstructivePulmonaryDisease?.onOxygenAbilityToStandLiftReachStretchAndBend">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">On Oxygen ability to stand, lift, reach, stretch, and
                                        bend</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.chronicObstructivePulmonaryDisease?.onOxygenAbilityToStandLiftReachStretchAndBend
                                        === true ? 'Yes' : '--') || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.chronicObstructivePulmonaryDisease?.onOxygenAbilityToStandLiftReachStretchAndBend && hpiForm?.chronicObstructivePulmonaryDisease?.oxygenAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">On Oxygen ability to stand, lift, reach, stretch, and
                                        bend Additional Notes</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.chronicObstructivePulmonaryDisease?.oxygenAdditionalNotes ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Coronary Artery Disease (CAD) Section -->
                <div class="cad-sec" *ngIf="checkObjValues(hpiForm?.coronaryArteryDisease)">
                    <h5><u>Coronary Artery Disease (CAD)</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.coronaryArteryDisease?.chestPain">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Chest Pain</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.coronaryArteryDisease?.chestPain === true ? 'Yes' : '--') ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.coronaryArteryDisease?.chestPain && hpiForm?.coronaryArteryDisease?.chestPainAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Chest Pain Additional Notes</label> <span
                                        class="label-colon">
                                        : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.coronaryArteryDisease?.chestPainAdditionalNotes || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.coronaryArteryDisease?.shortnessOfBreath">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Shortness of Breath</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.coronaryArteryDisease?.shortnessOfBreath === true ? 'Yes' : '--')
                                        ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.coronaryArteryDisease?.shortnessOfBreath && hpiForm?.coronaryArteryDisease?.shortnessOfBreathAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Shortness of Breath Additional Notes</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.coronaryArteryDisease?.shortnessOfBreathAdditionalNotes || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.coronaryArteryDisease?.useOfNitroglycerin">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Use of Nitroglycerin</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.coronaryArteryDisease?.useOfNitroglycerin === true ? 'Yes' :
                                        '--') ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.coronaryArteryDisease?.useOfNitroglycerin && hpiForm?.coronaryArteryDisease?.useOfNitroglycerinAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Use of Nitroglycerin Additional Notes</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.coronaryArteryDisease?.useOfNitroglycerinAdditionalNotes ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.coronaryArteryDisease?.advanced">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Advanced</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.coronaryArteryDisease?.advanced === true ? 'Yes' : '--') ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.coronaryArteryDisease?.advanced && hpiForm?.coronaryArteryDisease?.advancedAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Advanced Additional Notes</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.coronaryArteryDisease?.advancedAdditionalNotes || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Congestive heart failure (CHF) Section -->
                <div class="chf-sec" *ngIf="checkObjValues(hpiForm?.congestiveHeartFailure)">
                    <h5><u>Congestive heart failure (CHF)</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.congestiveHeartFailure?.shortnessOfBreath">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Shortness of Breath</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.congestiveHeartFailure?.shortnessOfBreath === true ? 'Yes' :
                                        '--') ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.congestiveHeartFailure?.shortnessOfBreath && hpiForm?.congestiveHeartFailure?.shortnessOfBreathAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Shortness of Breath Additional Notes</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.congestiveHeartFailure?.shortnessOfBreathAdditionalNotes ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.congestiveHeartFailure?.diet">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Diet</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.congestiveHeartFailure?.diet === true ? 'Yes' : '--') || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.congestiveHeartFailure?.diet && hpiForm?.congestiveHeartFailure?.dietAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Diet Additional Notes</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.congestiveHeartFailure?.dietAdditionalNotes || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.congestiveHeartFailure?.saltIntake">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Salt Intake</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.congestiveHeartFailure?.saltIntake || '--'}} </label>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.congestiveHeartFailure?.orthopnea">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Orthopnea</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.congestiveHeartFailure?.orthopnea === true ? 'Yes' : '--') ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.congestiveHeartFailure?.orthopnea && hpiForm?.congestiveHeartFailure?.orthopneaAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Orthopnea Additional Notes</label> <span
                                        class="label-colon">
                                        : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.congestiveHeartFailure?.orthopneaAdditionalNotes || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.congestiveHeartFailure?.edema">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Edema</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{(hpiForm?.congestiveHeartFailure?.edema === true ? 'Yes' : '--') ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.congestiveHeartFailure?.edema && hpiForm?.congestiveHeartFailure?.edemaAdditionalNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Edema Additional Notes</label> <span class="label-colon">
                                        :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.congestiveHeartFailure?.edemaAdditionalNotes || '--'}} </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Sick Section -->
                <div class="sick-sec" *ngIf="checkObjValues(hpiForm?.sick) && hpiForm?.sick?.symptoms?.length > 0">
                    <h5><u>Sick</u></h5>
                    <!-- <div class="row pad-sec">
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.sick?.duration">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Duration</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{hpiForm?.sick?.duration || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.sick?.symptoms?.length > 0">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Symptoms</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label *ngFor="let item of hpiForm?.sick?.symptoms">{{item?.itemName || '--'}}, </label>
                            </div>
                        </div>
                    </div>
                </div> -->


                    <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="hpiForm?.sick?.symptoms?.length > 0" id="Sick">
                        <div class="dropdown-container" style="height: auto;width: 100%;overflow: overlay;">
                            <table class="table table-bordered">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>
                                            Selected Symptoms
                                        </th>
                                        <th>
                                            Severity
                                        </th>
                                        <th>
                                            Duration (in number of Days)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of hpiForm?.sick?.symptoms;">
                                        <td>
                                            {{item?.symptomName}}
                                        </td>
                                        <td>
                                            {{item?.severity}}

                                        </td>
                                        <td>
                                            {{item?.duration}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>

                <!-- LIFE_STYLE Section -->
                <div class="LifeStyle-sec" *ngIf="checkObjValues(hpiForm?.lifeStyle)">
                    <h5><u>Lifestyle</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.lifeStyle?.excerciseType">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Exercise Type</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.lifeStyle?.excerciseType || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.lifeStyle?.exercises">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Exercises (in number of times Per Week)</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.lifeStyle?.exercises || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.lifeStyle?.exercisesFor">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Exercises (in number of minutes Per Day)</label> <span
                                        class="label-colon">
                                        : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.lifeStyle?.exercisesFor || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Allergies Section -->
                <div class="LifeStyle-sec" *ngIf="checkObjValues(hpiForm?.allergies)">
                    <h5><u>Allergies</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.allergies?.foodAllergies">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Food Allergies</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.allergies?.foodAllergies || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.allergies?.drugAllergies">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Drug Allergies</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.allergies?.drugAllergies || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.allergies?.otherAllergies">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Other Allergies</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.allergies?.otherAllergies || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Functional Ability Screening -->
                <div class="pain-sec  mt-2" *ngIf="checkObjValues(hpiForm?.functionalAbilityScreening)">

                    <h5><u>Functional Ability Screening</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-12 col-md-12 col-sm-12"
                            *ngIf="hpiForm?.functionalAbilityScreening?.isBasicHelp">
                            <div class="form-group row">
                                <div class="col-lg-9 col-md-9 col-sm-10">
                                    <label class="labelStyle">Do you need help with Phone, transportation, shopping,
                                        preparing meals, housework, laundry, medications or managing money ?</label>
                                    <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-2">
                                    <label>{{hpiForm?.functionalAbilityScreening?.isBasicHelp || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12"
                            *ngIf="hpiForm?.functionalAbilityScreening?.isHomeAmenities">
                            <div class="form-group row">
                                <div class="col-lg-9 col-md-9 col-sm-10">
                                    <label class="labelStyle">Does your home have any rugs in the hallway, lack grab
                                        bars in
                                        the bathroom, lack handrails on the stairs or poor lighting ?</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-2">
                                    <label>{{hpiForm?.functionalAbilityScreening?.isHomeAmenities || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12"
                            *ngIf="hpiForm?.functionalAbilityScreening?.isHearingProblems">
                            <div class="form-group row">
                                <div class="col-lg-9 col-md-9 col-sm-10">
                                    <label class="labelStyle">Have you noticed any hearing difficulties ?</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-2">
                                    <label>{{hpiForm?.functionalAbilityScreening?.isHearingProblems || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12"
                            *ngIf="hpiForm?.functionalAbilityScreening?.isVisionProblems">
                            <div class="form-group row">
                                <div class="col-lg-9 col-md-9 col-sm-10">
                                    <label class="labelStyle">Have you had any difficulties with your vision ?</label>
                                    <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-2">
                                    <label>{{hpiForm?.functionalAbilityScreening?.isVisionProblems || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Wellness Female Exam -->
                <div class="pain-sec  mt-2" *ngIf="checkObjValues(hpiForm?.wellnessFemaleExam)">

                    <h5><u>Wellness Female Exam</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.wellnessFemaleExam?.lastPeriod">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Period</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessFemaleExam?.lastPeriod || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.wellnessFemaleExam?.lastMammogram">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Mammogram</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessFemaleExam?.lastMammogram || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.wellnessFemaleExam?.lastPapSmear">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last PapSmear</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessFemaleExam?.lastPapSmear || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.wellnessFemaleExam?.lastPapSmearResult">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Pap Smear Result</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessFemaleExam?.lastPapSmearResult || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.wellnessFemaleExam?.lastColonCancerScreening">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Colon Cancer Screening</label> <span
                                        class="label-colon">
                                        : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessFemaleExam?.lastColonCancerScreening || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.wellnessFemaleExam?.lastFastingLabs">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Fasting Labs</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessFemaleExam?.lastFastingLabs || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Number of Children Living</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessFemaleExam?.numberChildrenLiving || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Number of Children Deceased</label> <span
                                        class="label-colon">
                                        : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessFemaleExam?.numberChildrenDeceased || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Miscarriages/Abortions</label> <span class="label-colon">
                                        :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessFemaleExam?.numberMisCarriagesAbortions || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- gynHistory -->
                <div class="pain-sec  mt-2" *ngIf="checkObjValues(hpiForm?.gynHistory)">

                    <h5><u>Gyn History</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.gynHistory?.lastPeriod">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Period</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.gynHistory?.lastPeriod || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.gynHistory?.periodsFrequency">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">How often you get Periods</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.gynHistory?.periodsFrequency || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.gynHistory?.regularPeriods">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Are your Periods
                                        Regular?</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label> {{(hpiForm?.gynHistory?.regularPeriods === true ? 'Yes' : 'No') ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.gynHistory?.usualPeriodsDuration">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">How many days do your Periods usually Last</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.gynHistory?.usualPeriodsDuration || '--'}} </label>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.gynHistory?.bloodFlowCond">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Blood Flow</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.gynHistory?.bloodFlowCond || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.gynHistory?.vaginalDischargeCond">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Do you have any vaginal discharge </label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.gynHistory?.vaginalDischargeCond || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.gynHistory?.sexuallyActive !== null">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Are you sexually
                                        active?</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>
                                        {{(hpiForm?.gynHistory?.sexuallyActive === true ? 'Yes' : 'No') ||
                                        '--'}}
                                    </label>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.gynHistory?.sexuallyActive !== null && hpiForm?.gynHistory?.sexuallyActiveAdditionalDetails">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Are you sexually
                                        active Additional Notes</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.gynHistory?.sexuallyActiveAdditionalDetails || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.gynHistory?.havingSexualTransDisease !== null">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Have you ever had a
                                        sexually transmitted disease?</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>
                                        {{(hpiForm?.gynHistory?.havingSexualTransDisease === true ? 'Yes' : 'No') ||
                                        '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.gynHistory?.havingSexualTransDisease !== null && hpiForm?.gynHistory?.sexualTransDiseaseAdditionalDetails">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Sexual Transmitted Disease Additional Notes</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.gynHistory?.sexualTransDiseaseAdditionalDetails || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.gynHistory?.usingBirthControl">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Are you currently using
                                        any birth control?</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>
                                        {{(hpiForm?.gynHistory?.usingBirthControl === true ? 'Yes' : 'No') ||
                                        '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.gynHistory?.usingBirthControl !== null && hpiForm?.gynHistory?.usingBirthControlAdditionalDetails">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Using Birth Control Additional Notes</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.gynHistory?.usingBirthControlAdditionalDetails || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Wellness Male Exam -->
                <div class="pain-sec mt-2" *ngIf="checkObjValues(hpiForm?.wellnessMaleExam)">

                    <h5><u>Wellness Male Exam</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.wellnessMaleExam?.lastFastingLabs">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Fasting Labs</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessMaleExam?.lastFastingLabs|| '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.wellnessMaleExam?.lastColonCancerScreening">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Colon Cancer Screening</label> <span
                                        class="label-colon">
                                        : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessMaleExam?.lastColonCancerScreening || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.wellnessMaleExam?.lastProstateCancerScreening">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Last Prostate Cancer Screening</label> <span
                                        class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.wellnessMaleExam?.lastProstateCancerScreening || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Current Medications -->
                <div class="pain-sec mt-2" *ngIf="checkObjValues(hpiForm?.currentMedicationHistory)">
                    <h5><u>Medication History</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-12 col-md-12 col-sm-12"
                            *ngIf="hpiForm?.currentMedicationHistory?.lastVisitModifications">
                            <div class="form-group row">
                                <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Last Visit Medications:</label>
                            </div> -->
                                <div class="col-lg-12 col-md-12 col-sm-6"
                                    *ngIf="hpiForm?.currentMedicationHistory?.lastVisitModifications?.length === 0">
                                    <label>No Medications are available</label>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12"
                                    *ngIf="hpiForm?.currentMedicationHistory?.lastVisitModifications?.length > 0">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Medication Name</th>
                                                    <th>Dosage/Units</th>
                                                    <th>Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let medication of hpiForm?.currentMedicationHistory?.lastVisitModifications">
                                                    <td style="white-space:nowrap">{{medication?.nonproprietaryname}}
                                                    </td>
                                                    <td>
                                                        <span
                                                            *ngIf="medication?.activeNumeratorStrength || medication?.activeIngredUnit">{{medication?.activeNumeratorStrength}}
                                                            {{medication?.activeIngredUnit}}</span>
                                                        <span
                                                            *ngIf="!medication?.activeNumeratorStrength && !medication?.activeIngredUnit">--</span>
                                                    </td>
                                                    <td style="white-space:nowrap">{{medication?.notes}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Immigration Physicals -->
                <div class="pain-sec mt-2" *ngIf="checkObjValues(hpiForm?.immigrationPhysicals)">
                    <h5><u>Immigration Physicals</u></h5>
                    <h6><b>Vaccination History</b></h6>
                    <div class="row pad-sec">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group row">

                                <div class="col-lg-12 col-md-12 col-sm-12"
                                    *ngIf="hpiForm?.immigrationPhysicals?.vaccinationDetails?.length === 0">
                                    <label>No Vaccination History available</label>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12"
                                    *ngIf="hpiForm?.immigrationPhysicals?.vaccinationDetails?.length > 0">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Vaccine Name</th>
                                                    <th>Last Vaccinated Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let vaccineItem of hpiForm?.immigrationPhysicals?.vaccinationDetails">
                                                    <td>{{vaccineItem?.vaccineName}}</td>
                                                    <td>{{vaccineItem?.lastVaccinatedDate || '--'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h6><b>Past Disease History</b></h6>
                    <div class="row pad-sec">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group row">

                                <div class="col-lg-12 col-md-12 col-sm-12"
                                    *ngIf="hpiForm?.immigrationPhysicals?.pastDiseaseHistory?.length === 0">
                                    <label>No Past Disease History available</label>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12"
                                    *ngIf="hpiForm?.immigrationPhysicals?.pastDiseaseHistory?.length > 0">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Disease Name</th>
                                                    <th>Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let diseaseItem of hpiForm?.immigrationPhysicals?.pastDiseaseHistory">
                                                    <td>{{diseaseItem?.diseaseName}}</td>
                                                    <td>{{diseaseItem?.notes || '--'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Past Family and Social History -->
                <div class="pain-sec mt-2" *ngIf="checkObjValues(hpiForm?.pastFamilyAndSocialHistory)">
                    <h5><u>Past Family and Social History</u></h5>
                    <div class="row pad-sec">
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.pastFamilyAndSocialHistory?.pastMedicalHistory">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Past Medical History</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pastFamilyAndSocialHistory?.pastMedicalHistory || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.pastFamilyAndSocialHistory?.pastSurgicalHistory">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Past Surgical History</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pastFamilyAndSocialHistory?.pastSurgicalHistory || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.pastFamilyAndSocialHistory?.familyHistory">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Family History</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pastFamilyAndSocialHistory?.familyHistory || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.pastFamilyAndSocialHistory?.previousHospitalizations">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Previous Hospitalizations</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pastFamilyAndSocialHistory?.previousHospitalizations || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.pastFamilyAndSocialHistory?.currentProviders">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Current Providers</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{hpiForm?.pastFamilyAndSocialHistory?.currentProviders || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding: 0px 15px;">
                        <div class="col-lg-12 col-md-12 col-sm-12"
                            *ngIf="hpiForm?.pastFamilyAndSocialHistory?.tobacco || hpiForm?.pastFamilyAndSocialHistory?.tobaccoAdditionalDetails || hpiForm?.pastFamilyAndSocialHistory?.alcohol || hpiForm?.pastFamilyAndSocialHistory?.alcoholAdditionalDetails || hpiForm?.pastFamilyAndSocialHistory?.drugUse || hpiForm?.pastFamilyAndSocialHistory?.drugUseAdditionalDetails">
                            <h5>Social History</h5>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pastFamilyAndSocialHistory?.tobacco">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Tobacco</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ (hpiForm?.pastFamilyAndSocialHistory?.tobacco || '--')}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.pastFamilyAndSocialHistory?.tobacco && hpiForm?.pastFamilyAndSocialHistory?.tobaccoAdditionalDetails">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Tobacco Additional Notes</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.pastFamilyAndSocialHistory?.tobaccoAdditionalDetails || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding: 0px 15px;">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pastFamilyAndSocialHistory?.alcohol">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Alcohol</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ (hpiForm?.pastFamilyAndSocialHistory?.alcohol || '--')}} </label>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.pastFamilyAndSocialHistory?.alcohol && hpiForm?.pastFamilyAndSocialHistory?.alcoholAdditionalDetails">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Alcohol Additional Notes</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.pastFamilyAndSocialHistory?.alcoholAdditionalDetails || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding: 0px 15px;">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="hpiForm?.pastFamilyAndSocialHistory?.drugUse">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Drug Use</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ (hpiForm?.pastFamilyAndSocialHistory?.drugUse || '--')}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12"
                            *ngIf="hpiForm?.pastFamilyAndSocialHistory?.drugUse && hpiForm?.pastFamilyAndSocialHistory?.drugUseAdditionalDetails">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Drug Use Additional Notes</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{ hpiForm?.pastFamilyAndSocialHistory?.drugUseAdditionalDetails || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="treatmentFlowAddNotes">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{treatmentFlowAddNotes?.hpiNotes || '--'}} </label>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>
            <!-- End HPI -->

            <!-- Vitals section -->
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Vitals" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Vitals</h5>
                        <hr>
                    </div>
                </div>

                <div class="vitals">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Height (in inches)</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{vitalsForm?.height || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Weight (in pounds)</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{vitalsForm?.weight || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">BMI</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{vitalsForm?.bmi || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Blood Pressure (Systolic/Diastolic)</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6"
                                    *ngIf="vitalsForm?.bpSystolic || vitalsForm?.bpDiastolic">
                                    <!-- <label>{{vitalsForm?.bpSystolic || '--'}} / {{vitalsForm?.bpDiastolic || '--'}}</label> -->
                                    <div>{{vitalsForm?.bpSystolic || '--'}} / {{vitalsForm?.bpDiastolic || '--'}}</div>
                                    <div *ngFor="let bpItem of vitalsForm?.addlBloodPressure">{{bpItem?.bpSystolic ||
                                        bpItem?.bpSystolicaddl || '--'}} / {{bpItem?.bpDiastolic ||
                                        bpItem?.bpDiastolicaddl
                                        || '--'}}</div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6"
                                    *ngIf="!vitalsForm?.bpSystolic && !vitalsForm?.bpDiastolic">
                                    <label>--</label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Blood Pressure (Diastolic)</label> <span class="label-colon">
                                    : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{vitalsForm?.bpDiastolic || '--'}} </label>
                            </div>
                        </div>
                    </div> -->
                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Blood Glucose (With Fasting)</label> <span class="label-colon">
                                    : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{vitalsForm?.bloodSugarWithFasting || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Blood Glucose (Without Fasting)</label> <span
                                    class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{vitalsForm?.bloodSugarWithoutFasting || '--'}} </label>
                            </div>
                        </div>
                    </div> -->
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Pulse</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{vitalsForm?.pulse || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Temperature (in °F)</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{vitalsForm?.temperature || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Respiratory Rate</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{vitalsForm?.respiratoryRate || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Oxygen %</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{vitalsForm?.oxygenPercentage || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <!-- Review of System section -->
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Review of Systems" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Review of Systems</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <ng-container *ngFor="let category of othersReviewSystems; let cati = index">
                        <div class="col-lg-12 col-md-12 col-sm-12 categories"
                            *ngIf="category?.name !== 'Annual Wellness Visit'">
                            <h5><u>{{category?.name}}</u></h5>
                            <h6 *ngIf="category?.question">{{category?.question}}</h6>
                            <ng-container *ngFor="let value of category?.values; let listi = index">
                                <ul class="unstyled">
                                    <li>{{value}}</li>
                                </ul>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="checkAnnulWellnessVisit(othersReviewSystems)?.length > 0">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5><u>Annual Wellness Visit</u></h5>
                        </div>
                        <ng-container *ngFor="let category of othersReviewSystems; let cati = index">
                            <div class="col-lg-12 col-md-12 col-sm-12 categories"
                                *ngIf="category?.name === 'Annual Wellness Visit'">
                                <h6 *ngIf="category?.question"><b>{{category?.question}}</b></h6>
                                <ng-container *ngFor="let value of category?.values; let listi = index">
                                    <ul class="unstyled">
                                        <li>{{value}}</li>
                                    </ul>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="othersReviewSystems?.length === 0">
                        <h5 class="col-lg-12 col-md-12 col-sm-12">Not selected any Review of Systems..!</h5>
                    </ng-container>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="treatmentFlowAddNotes">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{treatmentFlowAddNotes?.rosNotes || '--'}} </label>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>



            <!-- Behavioral Screening section -->
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Behavioral Screening" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Behavioral Screening</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <ng-container>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th style="width: 70% !important;">Question</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of allBehaviourScreening | keyvalue; let i = index">
                                        <tr *ngIf="checkObjBehvaiourScreen(item?.value)">
                                            <td colspan="3">
                                                <h5 class="behaviour-screening-type"
                                                    *ngIf="item?.key === 'alcoholAuditCQuestions' && checkObjBehvaiourScreen(item?.value)">
                                                    Alcohol - Audit-C
                                                    <div class="calculation result">
                                                        <ng-container>
                                                            <div class="negative">AUDIT-C Score<span
                                                                    class="pointsCount">{{pointsCount('alcoholAuditCQuestions')}}
                                                                    points</span></div>
                                                        </ng-container>
                                                    </div>
                                                </h5>
                                                <h5 class="behaviour-screening-type"
                                                    *ngIf="item?.key === 'anxietyGad7Questions' && checkObjBehvaiourScreen(item?.value)">
                                                    Anxiety - GAD7
                                                    <div class="calculation result">
                                                        <ng-container *ngIf="pointsCount('anxietyGad7Questions') <= 2">
                                                            <div class="negative">Negative<span
                                                                    class="pointsCount">{{pointsCount('anxietyGad7Questions')}}
                                                                    points</span></div>
                                                        </ng-container>
                                                        <ng-container *ngIf="pointsCount('anxietyGad7Questions') > 2">
                                                            <div class="positive">Positive<span
                                                                    class="pointsCount">{{pointsCount('anxietyGad7Questions')}}
                                                                    points</span></div>
                                                        </ng-container>
                                                    </div>
                                                </h5>

                                                <h5 class="behaviour-screening-type"
                                                    *ngIf="item?.key === 'depressionPHQ9' && checkObjBehvaiourScreen(item?.value)">
                                                    Patient Health Questionnaire-9 (PHQ-9)
                                                    <div class="calculation result">
                                                        <ng-container *ngIf="pointsCount('depressionPHQ9') <= 2">
                                                            <div class="negative">Negative<span
                                                                    class="pointsCount">{{pointsCount('depressionPHQ9')}}
                                                                    points</span></div>
                                                        </ng-container>
                                                        <ng-container *ngIf="pointsCount('depressionPHQ9') > 2">
                                                            <div class="positive">Positive.<span
                                                                    class="pointsCount">{{pointsCount('depressionPHQ9')}}
                                                                    points</span></div>
                                                        </ng-container>
                                                    </div>
                                                </h5>
                                                <h5 class="behaviour-screening-type"
                                                    *ngIf="item?.key === 'depressionPHQ2' && checkObjBehvaiourScreen(item?.value)">
                                                    Patient Health Questionnaire-2 (PHQ-2)
                                                    <div class="calculation result">
                                                        <ng-container *ngIf="pointsCount('depressionPHQ2') <= 2">
                                                            <div class="negative">Negative<span
                                                                    class="pointsCount">{{pointsCount('depressionPHQ2')}}
                                                                    points</span></div>
                                                        </ng-container>
                                                        <ng-container *ngIf="pointsCount('depressionPHQ2') > 2">
                                                            <div class="positive">Positive. Use PHQ-9 for further
                                                                evaluation<span
                                                                    class="pointsCount">{{pointsCount('depressionPHQ2')}}
                                                                    points</span></div>
                                                        </ng-container>
                                                    </div>
                                                </h5>
                                            </td>
                                        </tr>
                                        <!--  *ngIf="questioValue?.value !== null" -->
                                        <ng-container *ngIf="checkObjBehvaiourScreen(item?.value)">
                                            <tr *ngFor="let questioValue of item?.value">
                                                <td></td>
                                                <td style="white-space: break-spaces;">{{questioValue?.question}}</td>
                                                <td>{{questioValue?.value === null ? '--' : questioValue?.value}}</td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!behaviorScreeningValues()">
                                        <tr>
                                            <td colspan="3" style="text-align: center;">Not selected any values from
                                                Behavioral Screening.</td>
                                        </tr>
                                    </ng-container>

                                    <!-- <ng-container *ngIf="Object.keys(allBehaviourScreening) === 0">
                                    <tr>
                                        <td colspan="2" style="text-align: center;">Not selected any Behavioral Screening.</td>
                                    </tr>
                                </ng-container> -->
                                </tbody>
                            </table>
                        </div>
                    </ng-container>
                </div>

            </aw-wizard-step>

            <!-- In House -Labs Section -->
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="InHouse Labs" awOptionalStep (stepEnter)="navigateToTop(mainDetails);labRadOrderDetails()">
                <div id="inhouselabs">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>InHouse Labs</h5>
                            <hr>
                        </div>
                    </div>
                    <div class="in-house-labs-sec">
                        <ng-container *ngFor="let internalOrder of allInternalLabOrders">
                            <!-- Urine Analysis Order -->
                            <ng-container *ngIf="internalOrder?.testName === 'Urine Analysis'">
                                <div class="inhouseLabs-bg">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5>Urine Analysis ({{internalOrder?.urinAnalysisReportForm?.type}}) -
                                                {{internalOrder?.status}}:</h5>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5><u>Physical Examination</u></h5>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <label class="labelStyle">Appearance</label> <span
                                                        class="label-colon">
                                                        :
                                                    </span>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.appearance
                                                        || '--'}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <label class="labelStyle">Color</label> <span class="label-colon"> :
                                                    </span>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.color
                                                        ||
                                                        '--'}} </label>
                                                </div>
                                            </div>
                                            <!-- <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Physical Examination Notes</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.notes ||
                                                    '--'}} </label>
                                            </div>
                                        </div> -->
                                        </div>

                                        <!-- Chemical Examination -->
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5><u>Chemical Examination</u></h5>
                                            <div class="row">
                                                <ng-container
                                                    *ngFor="let chemicalExam of internalOrder?.urinAnalysisReportForm?.chemicalExamination; let i = index">
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label>{{chemicalExam?.examName}}&nbsp;&nbsp;:</label>
                                                        <label class="text-center">&nbsp;&nbsp;{{chemicalExam?.value ||
                                                            '--'}}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Urine Analysis Notes</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.urinAnalysisReportForm?.notes || '--'}} </label>
                                        </div>

                                    </div>
                                </div>
                            </ng-container>

                            <!-- Pregnancy Test Order -->
                            <ng-container *ngIf="internalOrder?.testName === 'Pregnancy Test'">
                                <div class="inhouseLabs-bg">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5>Pregnancy Test - {{internalOrder?.status}}:</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">HCG</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.pregnancyTest?.result || '--'}} </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Result</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{getHcgResult(internalOrder?.pregnancyTest?.result) || '--'}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Additional Notes</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.pregnancyTest?.notes || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Drug Screening Order -->
                            <ng-container *ngIf="internalOrder?.testName === 'Drug Screening'">
                                <div class="inhouseLabs-bg">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5>Drug Screening - {{internalOrder?.status}}:</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <ng-container
                                            *ngFor="let drugItem of internalOrder?.drugScreening?.drugs; let i = index">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{drugItem?.drugName}} -
                                                    {{drugItem?.deviceCode}}&nbsp;&nbsp;:</label>
                                                <label class="text-center">&nbsp;&nbsp;{{drugItem?.result ||
                                                    '--'}}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Additional Notes</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.drugScreening?.notes || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Rapid A Strep Test Order -->
                            <ng-container *ngIf="internalOrder?.testName === 'Rapid A Strep Test'">
                                <div class="inhouseLabs-bg">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5>Rapid A Strep Test - {{internalOrder?.status}}:</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Result</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.strepTest?.result || '--'}} </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h6>Diagnosis Values:</h6>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <ul>
                                                <ng-container
                                                    *ngFor="let diagnosis of internalOrder?.strepTest?.diagnosis; let i = index">
                                                    <li>{{diagnosis}}</li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Additional Notes</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.strepTest?.notes || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Anticoagulant Test Order -->
                            <ng-container *ngIf="internalOrder?.testName === 'Anticoagulant Test'">
                                <div class="inhouseLabs-bg">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5>Anticoagulant Test - {{internalOrder?.status}}:</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Result Range</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.anticoagulantTestResults?.range || '--'}} </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Units</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.anticoagulantTestResults?.units || '--'}} </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Additional Notes</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.anticoagulantTestResults?.notes || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Blood Glucose Order -->
                            <ng-container *ngIf="internalOrder?.testName === 'Blood Glucose'">
                                <div class="inhouseLabs-bg">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5>Blood Glucose - {{internalOrder?.status}}:</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Blood Glucose (With Fasting)</label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.bloodGlucoseResults?.bloodSugarWithFasting || '--'}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Blood Glucose (Without Fasting)</label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.bloodGlucoseResults?.bloodSugarWithoutFasting ||
                                                '--'}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">A1C Value</label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.bloodGlucoseResults?.a1CValue || '--'}} </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Additional Notes</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.bloodGlucoseResults?.notes || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Covid-19 Test Order -->
                            <ng-container *ngIf="internalOrder?.testName === 'Covid-19 Test'">
                                <div class="inhouseLabs-bg">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5>Covid-19 Test - {{internalOrder?.status}}:</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Test Type</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.covidTestResults?.type || '--'}} </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Test Result</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.covidTestResults?.result || '--'}} </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Additional Notes</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.covidTestResults?.notes || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Influenza Test Order -->
                            <ng-container *ngIf="internalOrder?.testName === 'Influenza Test'">
                                <div class="inhouseLabs-bg">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5>Influenza Test - {{internalOrder?.status}}:</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <ng-container
                                            *ngFor="let influTest of internalOrder?.influenzaTest?.tests; let i = index">
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <label>{{influTest?.fluType}}&nbsp;&nbsp;:</label>
                                                <label class="text-center">&nbsp;&nbsp;{{influTest?.result ||
                                                    '--'}}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Additional Notes</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.influenzaTest?.notes || '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Cologuard Test Order -->
                            <ng-container *ngIf="internalOrder?.testName === 'Cologuard Test'">
                                <div class="inhouseLabs-bg">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h5>Cologuard Test - {{internalOrder?.status}}:</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Requisition Date</label> <span
                                                class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.requisitionDate ?
                                                httpService?.formatDobTimeZoneWithDisplay(internalOrder?.requisitionDate)
                                                :
                                                '--'}} </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Collection Date</label> <span class="label-colon">
                                                :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{internalOrder?.collectionDate ?
                                                httpService?.formatDobTimeZoneWithDisplay(internalOrder?.collectionDate)
                                                :
                                                '--'}} </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                        </ng-container>
                        <ng-container *ngIf="allInternalLabOrders?.length === 0">
                            <h5>No InHouse Labs available</h5>
                        </ng-container>
                    </div>
                </div>
            </aw-wizard-step>

            <!-- In House - Injections Section -->
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="InHouse Injections" awOptionalStep
                (stepEnter)="navigateToTop(mainDetails);getAllInternalVaccinationDetails()">
                <div id="inhouselabsInjections">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>InHouse Injections</h5>
                            <hr>
                        </div>
                    </div>
                    <div class="vaccination-sec">
                        <ng-conatiner *ngFor="let vaccine of allInternalVaccineDetails">
                            <div class="inhouseLabs-bg">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Order Name</label> <span class="label-colon">
                                                    :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.orderName || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Order Type</label> <span class="label-colon">
                                                    :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.orderType || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Vaccine Date Time</label> <span
                                                    class="label-colon"> : </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.vaccineDateTime ?
                                                    httpService?.getAppointmentTime(vaccine?.vaccineDateTime) : '--' ||
                                                    '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Lot #</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.lot || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Vaccine Expiry Time</label> <span
                                                    class="label-colon"> : </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.expiryDate ?
                                                    httpService?.formatDobTimeZoneWithDisplay(vaccine?.administrationDetails?.expiryDate)
                                                    : '--' || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Manufacturer</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.manufacturer || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">NDC ID</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.ndcId || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Strength</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.strength || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Dose</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.dose || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Units</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.units || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Route</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.route || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Site</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.site || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Vaccine Priority</label> <span
                                                    class="label-colon"> : </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.vaccinePriority ||
                                                    '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Registery</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.registry || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Admin Code</label> <span class="label-colon">
                                                    :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.adminCode || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <!-- Administartion Details -->
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h5>Administration Details</h5>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h6 class="mt-2"><b>With NPI</b></h6>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Performed By</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.npiDetails?.performedBy ||
                                                    '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Date</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.npiDetails?.dateTime ?
                                                    httpService?.getAppointmentTime(vaccine?.administrationDetails?.npiDetails?.dateTime)
                                                    : '--' || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.npiDetails?.reason ||
                                                    '--'}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h6 class="mt-2"><b>Consent Documented</b></h6>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Performed By</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.consentDetails?.performedBy ||
                                                    '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Date</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.consentDetails?.dateTime ?
                                                    httpService?.getAppointmentTime(vaccine?.administrationDetails?.consentDetails?.dateTime)
                                                    : '--' || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.consentDetails?.reason ||
                                                    '--'}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h6 class="mt-2"><b>Education Materials Provided</b></h6>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Performed By</label> <span
                                                    class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.educationDetails?.performedBy
                                                    ||
                                                    '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Date</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.educationDetails?.dateTime ?
                                                    httpService?.getAppointmentTime(vaccine?.administrationDetails?.educationDetails?.dateTime)
                                                    : '--' || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                                </span>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.educationDetails?.reason ||
                                                    '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-lg-3 col-md-3 col-sm-6">
                                                <label class="labelStyle"><b>Comments/Notes</b></label> <span
                                                    class="label-colon"> : </span>
                                            </div>
                                            <div class="col-lg-9 col-md-9 col-sm-6">
                                                <label>{{vaccine?.administrationDetails?.administrationNote ||
                                                    '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-conatiner>
                        <ng-conatiner *ngIf="!allInternalVaccineDetails || allInternalVaccineDetails?.length === 0">
                            <span>InHouse Injections are not available</span>
                        </ng-conatiner>
                    </div>
                    <div class="inhouseLabs-bg">
                        <!-- Vaccine Allergies -->
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5>Allergies</h5>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Reaction</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="allergiesFromVaccine?.length > 0">
                                                <tr *ngFor="let allergy of allergiesFromVaccine">
                                                    <td>{{allergy?.name || '--'}}</td>
                                                    <td>{{allergy?.reaction || '--'}}</td>
                                                    <td>{{allergy?.documentedDate ?
                                                        httpService?.formatDobTimeZoneWithDisplay(allergy?.documentedDate)
                                                        :
                                                        '--'}}</td>
                                                </tr>
                                            </ng-container>

                                            <ng-container *ngIf="allergiesFromVaccine?.length === 0">
                                                <tr>
                                                    <td colspan="3" class="text-center">Not available any Allergies</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <!-- In House - Procedures Section -->
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="InHouse Procedures" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div id="inhouselabsProcedures">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>InHouse Procedures</h5>
                            <hr>
                        </div>
                    </div>
                </div>
                <div class="vaccination-sec">
                    <ng-conatiner *ngFor="let procedure of treatmentFlowData?.inHouseProcedures">
                        <div class="inhouseLabs-bg">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Procedure Name</label> <span class="label-colon">
                                                :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.procedureName || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12"
                                    *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Laceration'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Procedure Type</label> <span class="label-colon">
                                                :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.procedureType || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                 <div class="col-lg-6 col-md-6 col-sm-12"  *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                           
                                            <label class="labelStyle"
                                                *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">Procedure Ordered Date</label>
                                            <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.procedureOrderedDate ?
                                                httpService?.getAppointmentTime(procedure?.procedureOrderedDate) : '--'
                                                || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
								
								<div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Performed Date</label>
                                           
                                            <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.procedurePerformedDate ?
                                                httpService?.formatDobTimeZoneWithDisplay(procedure?.procedurePerformedDate) : '--'
                                                || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle"
                                                *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Laceration'">Created
                                                Date</label> <span class="label-colon"> : </span>
                                            <label class="labelStyle"
                                                *ngIf="procedure?.procedureName === 'ECG' || procedure?.procedureName === 'Laceration'">Performed
                                                Date</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.createdDate ?
                                                httpService?.formatDobTimeZoneWithDisplay(procedure?.administeredDetails?.createdDate)
                                                : '--' || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12"
                                    *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Medication</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.medication || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12"
                                    *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Order Type</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                             <label>{{procedure?.orderType === null ? '--' : procedure?.orderType[0]}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12"
                                    *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Nebulizer Time</label> <span class="label-colon">
                                                : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                             <label>{{procedure?.duration.value || '--'}} <span *ngIf="procedure?.duration.value">mins</span></label>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Count</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.count || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Position</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.position || '--'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Size</label> <span class="label-colon"> : </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{procedure?.administeredDetails?.size || '--'}}</label>
                                    </div>
                                </div>
                            </div> -->
                                <div class="col-lg-6 col-md-6 col-sm-12"
                                    *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Admin Code</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.adminCode || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12"
                                    *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Indication For ECG</label> <span
                                                class="label-colon">
                                                : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.indicationForECG || '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!-- Vitals -->

                            <div class="row" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Vitals</h5>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Stage</th>
                                                    <th>Pulse</th>
                                                    <th>Oxygen</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngIf="procedure?.vitals?.length > 0">
                                                    <tr *ngFor="let vital of procedure?.vitals">
                                                        <td>{{vital?.stage || '--'}}</td>
                                                        <td>{{vital?.pulse || '--'}}</td>
                                                        <td>{{vital?.oxygen || '--'}}</td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container *ngIf="procedure?.vitals?.length === 0">
                                                    <tr>
                                                        <td colspan="3" class="text-center">Not Available any Vital
                                                            Information</td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <!-- Body Site -->
                            <div class="row"
                                *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Nebulizer Treatment'">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Body Site</h5>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th *ngIf="procedure?.procedureName === 'Laceration'">Procedure Type
                                                    </th>
                                                    <th>Count</th>
                                                    <th>Size</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngIf="procedure?.bodySite?.length > 0">
                                                    <tr *ngFor="let site of procedure?.bodySite">
                                                        <td>{{site?.name || '--'}}</td>
                                                        <td *ngIf="procedure?.procedureName === 'Laceration'">
                                                            {{site?.procedureType || '--'}}</td>
                                                        <td>{{site?.count || '--'}}</td>
                                                        <td>{{site?.size || '--'}}</td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container *ngIf="procedure?.bodySite?.length === 0">
                                                    <tr>
                                                        <td colspan="3" class="text-center">Not Available any Body Site
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <!-- ECG Interpretation -->
                            <div class="row" *ngIf="procedure?.procedureName === 'ECG'">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Interpretation of ECG</h5>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Rate</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.ecgInterpretation?.rate || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Rhythm</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.ecgInterpretation?.rhythm || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Axis</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.ecgInterpretation?.axis || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">P Waves</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.ecgInterpretation?.pwaves || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">PR Interval</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.ecgInterpretation?.printerval || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">QRS Complex</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.ecgInterpretation?.qrscomplex || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">QT Interval</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.ecgInterpretation?.qtinterval || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">ST-Segment</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.ecgInterpretation?.stsegment || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">T Waves</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.ecgInterpretation?.twaves || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-3 col-md-3 col-sm-3">
                                            <label class="labelStyle">Impression</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-9 col-md-9 col-sm-9">
                                            <label>{{procedure?.ecgimpression || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-3 col-md-3 col-sm-3">
                                            <label class="labelStyle">Comments/Notes</label> <span class="label-colon">
                                                :
                                            </span>
                                        </div>
                                        <div class="col-lg-9 col-md-9 col-sm-9">
                                            <label>{{procedure?.administeredDetails?.administrationNotes ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Administartion Details -->
                            <div class="row" *ngIf="procedure?.procedureName !== 'ECG'">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Administration Details</h5>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h6 class="mt-2"><b>With NPI</b></h6>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.npiDetails?.performedBy ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.npiDetails?.dateTime ?
                                                httpService?.getAppointmentTime(procedure?.administeredDetails?.npiDetails?.dateTime)
                                                : '--' || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.npiDetails?.reason ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h6 class="mt-2"><b>Consent Documented</b></h6>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.consentDetails?.performedBy ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.consentDetails?.dateTime ?
                                                httpService?.getAppointmentTime(procedure?.administeredDetails?.consentDetails?.dateTime)
                                                : '--' || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.consentDetails?.reason ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12"
                                    *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                    <h6 class="mt-2"><b>Education Materials Provided</b></h6>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12"
                                    *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.educationDetails?.performedBy ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12"
                                    *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.educationDetails?.dateTime ?
                                                httpService?.getAppointmentTime(procedure?.administeredDetails?.educationDetails?.dateTime)
                                                : '--' || '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12"
                                    *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.educationDetails?.reason ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-lg-4 col-md-3 col-sm-6">
                                            <label class="labelStyle"><b>Comments/Notes</b></label> <span
                                                class="label-colon"> : </span>
                                        </div>
                                        <div class="col-lg-9 col-md-9 col-sm-6">
                                            <label>{{procedure?.administeredDetails?.administrationNotes ||
                                                '--'}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-conatiner>
                    <ng-conatiner
                        *ngIf="!treatmentFlowData?.inHouseProcedures || treatmentFlowData?.inHouseProcedures?.length === 0">
                        <span>InHouse Procedures are not available</span>
                    </ng-conatiner>
                </div>
            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Exam" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Exam</h5>
                        <hr>
                    </div>
                </div>
                <div class="exam-sec">
                    <ng-container *ngFor="let exam of examValues; let exami = index">
                        <div class="col-lg-12 col-md-12 col-sm-12 exam">
                            <h5><u>{{exam?.name}}</u></h5>
                            <ng-container>
                                <ul class="unstyled">
                                    <li *ngFor="let value of exam?.values; let listi = index">{{value}}</li>
                                </ul>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="examValues?.length === 0">
                        <h5>No Exams have selected..!</h5>
                    </ng-container>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="treatmentFlowAddNotes">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{treatmentFlowAddNotes?.examNotes || '--'}} </label>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Orders" awOptionalStep
                (stepEnter)="navigateToTop(mainDetails);rxOrderDetails();labRadOrderDetails()">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Orders</h5>
                        <hr>
                    </div>
                </div>
                <div class="rx-order-details">
                    <h5>Rx Order Details</h5>
                    <!-- datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders" -->
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>SI.NO</th>
                                    <th>Order Date</th>
                                    <th>Drug Name</th>
                                    <th>Dosage & Units</th>
                                    <th>SIG<span class="sig-text-width"></span></th>
                                    <th>Refills</th>
                                    <th>Quantity</th>
                                    <th>Change Reason</th>
                                    <th>Discontinued?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let rxOrder of allRxOrderDetails; let i = index">
                                    <tr>
                                        <td>{{i+1}}</td>
                                        <td>{{rxOrder?.creation_date || '--'}}</td>
                                        <td>{{rxOrder?.drug_name || '--'}}</td>
                                        <td>{{rxOrder?.activeNumeratorStrength || rxOrder?.change_of_dosage ||
                                            rxOrder?.dosage || '--'}} {{rxOrder?.activeIngredUnit}}</td>
                                        <td>{{rxOrder?.sig || '--'}}</td>
                                        <td>{{rxOrder?.refills || '--'}}</td>

                                        <td>{{rxOrder?.days_supply || '--'}}</td>
                                        <td>{{rxOrder?.change_reason || '--'}}</td>
                                        <td>{{rxOrder?.changeOfMedications ? 'Yes' : 'No'}}</td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="allRxOrderDetails?.length === 0">
                                    <tr>
                                        <td colspan="9" style="text-align: center;">No Rx Orders are Available.</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Lab Rad Details -->
                <div class="leb-order-details mt-4">
                    <h5>Lab/Rad Requisitions</h5>
                    <!-- datatable [dtOptions]="dtOptionsLabOrders" [dtTrigger]="dtTriggerLabOrders" -->
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>SI.NO</th>
                                    <th>Order Number</th>
                                    <th>Order Type</th>
                                    <th>Order Date</th>
                                    <th>Order Collection Date</th>
                                    <th>Order Transmission Date</th>
                                    <th>Order Status</th>
                                    <th>Lab Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let labOrder of alllabRadOrderDetails; let i = index">
                                    <tr>
                                        <td>{{i+1}}</td>
                                        <td>{{labOrder?.orderNumber || '--'}}</td>
                                        <td>{{labOrder?.orderType || '--'}}</td>
                                        <td>{{labOrder?.requestDate || '--'}}</td>
                                        <td>{{labOrder?.collectionDate || '--'}}</td>
                                        <td>{{(labOrder?.transmissionDate | date:'MM/dd/yyyy hh:mm a') || '--'}}</td>
                                        <td>{{statusCodeDesc(labOrder?.orderStatus) || '--'}}</td>
                                        <td>{{labOrder?.labName || '--'}}</td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="alllabRadOrderDetails?.length === 0">
                                    <tr>
                                        <td colspan="8" style="text-align: center;">No Lab Orders are Available.</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="treatmentFlowAddNotes">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{treatmentFlowAddNotes?.planNotes || '--'}} </label>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Care Plan" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">

                <div class="patient-consent">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Patient Consent</h5>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Consent Documented</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.assessment?.patientConsent || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Consent Notes</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.assessment?.consentNotes || '--'}}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Patient Data Evaluation and Management</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <ng-container
                                *ngIf="treatmentFlowData?.carePlan?.patientEvalAndMgmt && treatmentFlowData?.carePlan?.patientEvalAndMgmt?.length > 0">
                                <ul class="unstyled">
                                    <li *ngFor="let dataItem of treatmentFlowData?.carePlan?.patientEvalAndMgmt">
                                        {{dataItem}}</li>
                                </ul>
                            </ng-container>
                            <ng-container
                                *ngIf="!treatmentFlowData?.carePlan?.patientEvalAndMgmt || treatmentFlowData?.carePlan?.patientEvalAndMgmt?.length === 0">
                                <span>Not selected any Patient Data Evaluation and Management..!</span>
                            </ng-container>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <h5>Assessment</h5>
                        <hr>
                    </div>
                </div>

                <div class="assesment-sec">
                    <ng-container *ngFor="let assessment of assessmentValues; let i = index">
                        <ul class="unstyled">
                            <li>{{assessment?.code}} - {{assessment?.codeDescription}}</li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="assessmentValues?.length === 0">
                        <h5>No ICD codes have selected..!</h5>
                    </ng-container>
                </div>
                <!-- <div class="row mt-4">
                <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="treatmentFlowAddNotes">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{treatmentFlowAddNotes?.assessmentNotes || '--'}} </label>
                        </div>
                    </div>
                </div>
            </div> -->

                <div class="patient-consent">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h5>Time spent on Patient Chart</h5>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Duration</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.assessment?.timeSpentDuration || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Units</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.assessment?.timeSpentunits || '--'}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.assessment?.timeSpentNotes || '--'}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Billing Codes section -->
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Billing</h5>
                        <hr>
                    </div>
                </div>
                <div class="assesment-sec">
                    <ng-container *ngFor="let billingValue of billingValues; let i = index">
                        <ul class="unstyled">
                            <li>{{billingValue?.cptCode}} - {{billingValue?.cptCodeDescription}}</li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="billingValues?.length === 0">
                        <h5>No Billing codes have selected..!</h5>
                    </ng-container>
                </div>
            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Progress Notes" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Progress Notes</h5>
                        <hr>
                    </div>
                </div>

                <div class="journal-notes-sec">
                    <ng-container *ngFor="let notes of treatmentFlowData?.journalNotes?.notes; let i = index">
                        <ul class="unstyled">
                            <li *ngIf="notes?.notes"><b>({{notes?.date | date: 'MM/dd/yyyy hh:mm a'}})</b> -
                                {{notes?.notes}}</li>
                        </ul>
                    </ng-container>
                    <ng-container
                        *ngIf="!treatmentFlowData?.journalNotes || treatmentFlowData?.journalNotes?.notes?.length === 0">
                        <h5>No Progress Notes are available.</h5>
                    </ng-container>
                </div>
            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Addendum" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Addendum</h5>
                        <hr>
                    </div>
                </div>

                <div class="journal-notes-sec">
                    <div class="table-responsive new-designed-table">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Reason</th>
                                    <th>Initials</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let addendum of treatmentFlowData?.addendum; let i = index">
                                    <tr>
                                        <td>{{addendum?.auditInfo?.lastUpdatedDateTime | date: 'MM/dd/yyyy hh:mm a'}}
                                        </td>
                                        <td>{{addendum?.reason || '--'}}</td>
                                        <td>{{addendum?.initials}}</td>
                                        <td>{{addendum?.notes}}</td>
                                    </tr>
                                </ng-container>
                                <ng-container
                                    *ngIf="!treatmentFlowData?.addendum || treatmentFlowData?.addendum?.length === 0">
                                    <tr>
                                        <td class="text-center" colspan="4">
                                            <h5>No Addendum are available.</h5>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Post-Visit Summary" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Post-Visit Summary</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <span>Summary: <b>{{treatmentFlowData?.dischargeInstructions?.notes || 'N/A'}}</b></span>
                    </div>
                </div>
            </aw-wizard-step>
            <!-- Payments -->
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Account Balance" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Account Balance</h5>
                        <!-- <button class="btn btn-primary payment-btn" (click)="openPayment()"><i class="las la-credit-card"></i>
                        &nbsp;Pay</button> -->
                        <hr>
                    </div>
                </div>
                <!-- <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <span *ngIf="appointmentInfoDetails?.payments">Co-Pay/Co-Insurance:&nbsp; <b>$
                            {{coPayCoInsurance(appointmentInfoDetails?.payments)}}</b></span>
                    <span *ngIf="!appointmentInfoDetails?.payments">Co-Pay/Co-Insurance:&nbsp; <b>NA</b></span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <span
                        *ngIf="appointmentInfoDetails?.payments && amountPaidSummation(appointmentInfoDetails?.payments) !== 0">Amount
                        Paid:&nbsp; <b>$ {{amountPaidSummation(appointmentInfoDetails?.payments)}}</b></span>
                    <span
                        *ngIf="appointmentInfoDetails?.payments && amountPaidSummation(appointmentInfoDetails?.payments) === 0">Amount
                        Paid:&nbsp; <b>{{amountPaidSummation(appointmentInfoDetails?.payments)}}</b></span>
                    <span *ngIf="!appointmentInfoDetails?.payments">Amount Paid:&nbsp; <b>NA</b></span>
                </div>
            </div> -->
                <div class="row mt-4" [formGroup]="appointmentCostOfCare">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive new-designed-table">
                            <table class="table table-striped table-bordered table-hover custom-table account-bal">
                                <!-- <thead>
                                    <tr>
                                        <th>Payments</th>
                                        <th>Itemized</th>
                                        <th>Total Visit Charges</th>
                                        <th>Reimbursed Amount</th>
                                        <th>Approved Amount</th>
                                        <th>Discount/Adjusted</th>
                                        <th>Non-Payment<span class="pay-sec-th"></span></th>
                                        <th>Patient Responsibility</th>
                                        <th>Coverage Cost<span class="pay-sec-th"></span></th>
                                        <th>Credits <span class="pay-sec-th"></span></th>
                                        <th>Actions</th>
                                    </tr>
                                </thead> -->
                                <tbody>

                                    <tr>
                                        <!-- <td>$ {{appointmentCostOfCareDetails?.apptCostofCare?.coPay}}</td> -->
                                    <tr>
                                        <td><span placement="bottom" ngbTooltip="Co-Pay/Co-Insurance"><b>Amount Paid</b>
                                            </span></td>
                                        <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <div class="form-control disabled">
                                                    {{appointmentCostOfCareDetails?.apptCostofCare?.coPay}}
                                                </div>
                                            </div>


                                        </td>

                                    </tr>
                                    <!-- <td>
                                            <ng-container
                                                *ngFor="let service of appointmentCostOfCareDetails?.itemizedPayments">
                                                <div class="service-charges" *ngIf="service?.amount !== 0">
                                                    <span>{{service?.serviceType}}:</span>
                                                    <span><b> ${{service?.amount}}</b></span>
                                                    <span> ({{service?.modeOfPayment}})</span>
                                                </div>
                                            </ng-container>
                                        </td> -->
                                    <tr>
                                        <td class="tooltip-show"><b>Itemized</b>
                                            <button type="button" class="btn btn-outline btn-popup"
                                                data-toggle="tooltip" data-placement="top"
                                                *ngIf="appointmentCostOfCareDetails?.itemizedPayments">

                                            </button>
                                            <div class="show-text">
                                                <ng-container
                                                    *ngFor="let service of appointmentCostOfCareDetails?.itemizedPayments">
                                                    <div class="service-charges">
                                                        <span>{{service?.serviceType}}:</span>
                                                        <span><b> ${{service?.amount}}</b></span>
                                                        <span> ({{service?.modeOfPayment}})</span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <!-- <td class="text-center">$
                                            {{appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges}}</td> -->
                                    <tr>
                                        <td class="tooltip-show"><b>Total Visit Charges</b>
                                            <button type="button" class="btn btn-outline btn-popup"
                                                data-toggle="tooltip" data-placement="top"
                                                *ngIf="appointmentCostOfCareDetails?.paymentStatus">

                                            </button>
                                            <div class="show-text text-2">

                                                <span> <b> Status: </b>{{appointmentCostOfCareDetails?.paymentStatus}}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <div class="form-control disabled">
                                                    {{appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges}}
                                                </div>
                                            </div>

                                        </td>
                                    </tr>

                                    <!-- <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Reimbursed Amount" formControlName="reimburseAmount"
                                                    id="reimburseAmount" />
                                            </div>
                                        </td> -->
                                    <tr>
                                        <td>Reimbursed Amount</td>
                                        <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Reimbursed Amount" formControlName="reimburseAmount"
                                                    id="reimburseAmount" />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Approved Amount" formControlName="approvedAmount"
                                                    id="approvedAmount" />
                                            </div>
                                        </td> -->
                                    <tr>
                                        <td>Approved Amount</td>
                                        <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Approved Amount" formControlName="approvedAmount"
                                                    id="approvedAmount" />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Discount/Adjusted" formControlName="discount"
                                                    id="discount" />
                                            </div>
                                        </td> -->
                                    <tr>
                                        <td>Discount/Adjusted</td>
                                        <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Discount/Adjusted" formControlName="discount"
                                                    id="discount" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Non-Payment<span class="pay-sec-th"></span></td>
                                        <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Non-Payment" formControlName="nonPayment"
                                                    id="nonPayment" />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Non-Payment" formControlName="nonPayment"
                                                    id="nonPayment" />
                                            </div>
                                        </td> -->
                                    <tr>
                                        <td>Patient Responsibility</td>
                                        <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Patient Responsibility"
                                                    formControlName="patientResponsibility" id="patientResponsibility"
                                                    (change)="responseChange(appointmentCostOfCare.get('patientResponsibility').dirty)" />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Patient Responsibility"
                                                    formControlName="patientResponsibility"
                                                    id="patientResponsibility" />
                                            </div>
                                        </td> -->
                                    <!-- <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Coverage Cost" formControlName="coverageCost"
                                                    id="coverageCost" />
                                            </div>
                                        </td> -->
                                    <tr>
                                        <td>Coverage Cost<span class="pay-sec-th"></span></td>
                                        <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Coverage Cost" formControlName="coverageCost"
                                                    id="coverageCost" />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Credits" formControlName="credits" id="credits" />
                                            </div>
                                        </td> -->
                                    <tr>
                                        <td>Credits <span class="pay-sec-th"></span></td>
                                        <td>
                                            <div class="form-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input class="form-control input-text" type="number"
                                                    placeholder="Credits" formControlName="credits" id="credits" />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <td>
                                            <button class="icon-inqueue-actions" (click)="submitPayCost()"><i
                                                    class="lar la-save"></i> &nbsp;Save</button>
                                            <button style="margin-left: 10px;"
                                                [disabled]="!appointmentCostOfCare?.get('patientResponsibility')?.value"
                                                class="icon-inqueue-actions" (click)="openPayment()"><i
                                                    class="las la-credit-card"></i>
                                                &nbsp;Pay</button>
                                        </td> -->
                                    <tr>
                                        <td>Actions</td>
                                        <td class="actions-payment">
                                            <button class="icon-queue-events" (click)="submitPayCost()"><i
                                                    class="lar la-save"></i></button>
                                            <button style="margin-left: 10px;"
                                                [disabled]="!appointmentCostOfCare?.get('patientResponsibility')?.value"
                                                class="icon-queue-events" (click)="openPayment()"><i
                                                    class="las la-credit-card"></i>
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>


            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                stepTitle="Clinical documents" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Clinical Documents</h5>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive new-designed-table">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Document Name</th>
                                        <th>Document Type</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="lab-tds" *ngFor="let document of allAdditonalDocuments">
                                        <td>{{document?.documentName || '--'}}</td>
                                        <td>{{document?.documentType || '--'}}</td>
                                        <td class="edit-delet-actions text-center">

                                            <button class="icon-queue-events view-action-bg" placement="bottom"
                                                ngbTooltip="View Document"
                                                (click)="downloadDocument(document?.s3Key,document?.s3Path,document?.fileType,additionalDocsViewModal)"><span
                                                    class="material-symbols-outlined">visibility</span></button>

                                            <!-- <button class="icon-queue-events delete-action-bg" (click)="deleteMedicalRecordOpen(document, deletConfirmPatientMedical)" placement="bottom" ngbTooltip="Delete"><span
                                            class="material-symbols-outlined">delete</span></button> -->
                                        </td>
                                    </tr>
                                    <tr *ngIf="allAdditonalDocuments?.length === 0">
                                        <td colspan="3" class="text-center">Clinical Documents are not available</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <ng-container
                *ngIf="allLabResultsDocs?.length > 0 || allRadiologyResultsDocs?.length > 0 || allReferralDocs?.length > 0 || allOtherDocs?.length > 0 || wellnessFormDocs?.length > 0 || otherAddedForms?.length > 0">
        
                <h5 class="header" *ngIf="allLabResultsDocs?.length > 0 ">Lab Results</h5>
                <div class="row consent-form" *ngIf="allLabResultsDocs?.length > 0">
                    <ul>
                        <li *ngFor="let document of allLabResultsDocs">
                            <span class="template-name">{{document?.documentName }}</span>
                            <span class="pdf-icon"><i (click)="downloadDocument(document?.s3Key, additionalDocsViewModal)"
                                    class="fa fa-file-pdf"></i></span>
                        </li>
                    </ul>
                </div>
        
                <h5 class="header" *ngIf="allRadiologyResultsDocs?.length > 0">Radiology Results</h5>
                <div class="row consent-form" *ngIf="allRadiologyResultsDocs?.length > 0">
                    <ul>
                        <li *ngFor="let document of allRadiologyResultsDocs">
                            <span class="template-name">{{document?.documentName }}</span>
                            <span class="pdf-icon"><i (click)="downloadDocument(document?.s3Key, additionalDocsViewModal)"
                                    class="fa fa-file-pdf"></i></span>
                        </li>
                    </ul>
                </div>
        
        
                <h5 class="header" *ngIf="allReferralDocs?.length > 0 ">Referral</h5>
                <div class="row consent-form" *ngIf="allReferralDocs?.length > 0">
                    <ul>
                        <li *ngFor="let document of allReferralDocs">
                            <span class="template-name">{{document?.documentName }}</span>
                            <span class="pdf-icon"><i (click)="downloadDocument(document?.s3Key, additionalDocsViewModal)"
                                    class="fa fa-file-pdf"></i></span>
                        </li>
                    </ul>
                </div>
        
                <h5 class="header" *ngIf="wellnessFormDocs?.length > 0 ">Wellness Forms</h5>
                <div class="row consent-form" *ngIf="wellnessFormDocs?.length > 0">
                    <ul>
                        <li *ngFor="let document of wellnessFormDocs">
                            <span class="template-name">{{document?.documentName }}</span>
                            <span class="pdf-icon"><i (click)="downloadDocument(document?.s3Key, additionalDocsViewModal)"
                                    class="fa fa-file-pdf"></i></span>
                        </li>
                    </ul>
                </div>
        
                <h5 class="header" *ngIf="allOtherDocs?.length > 0 ">Others</h5>
                <div class="row consent-form" *ngIf="allOtherDocs?.length > 0">
                    <ul>
                        <li *ngFor="let document of allOtherDocs">
                            <span class="template-name">{{document?.documentName }}</span>
                            <span class="pdf-icon"><i (click)="downloadDocument(document?.s3Key, additionalDocsViewModal)"
                                    class="fa fa-file-pdf"></i></span>
                        </li>
                    </ul>
                </div>

                <ng-conatiner *ngIf="otherAddedForms?.length > 0">
                    <ng-conatiner *ngFor="let otherAddItem of otherAddedForms">
                        <h5 class="header">{{otherAddItem?.documentType}}</h5>
                        <div class="row consent-form">
                            <ul>
                                <li>
                                    <span class="template-name">{{otherAddItem?.documentName }}</span>
                                    <span class="pdf-icon"><i (click)="downloadDocument(otherAddItem?.s3Key, additionalDocsViewModal)"
                                            class="fa fa-file-pdf"></i></span>
                                </li>
                            </ul>
                        </div>
                    </ng-conatiner>
                </ng-conatiner>
            </ng-container> -->
                <!-- <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <ng-container
                        *ngIf="allLabResultsDocs?.length === 0 &&  allRadiologyResultsDocs?.length === 0 &&  allReferralDocs?.length  === 0 && allOtherDocs?.length  === 0 && wellnessFormDocs?.length === 0 && otherAddedForms?.length === 0">
                        <h5 class="head-color">Clinical Documents not available</h5>
                    </ng-container>
                </div>
            </div> -->

            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                stepTitle="Diagnosis Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div id="DiagnosisDetails">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>Diagnosis Details</h5>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="assesment-sec">
                                <ng-container *ngFor="let assessment of assessmentValues; let i = index">
                                    <ul class="unstyled">
                                        <li>{{assessment?.code}} - {{assessment?.codeDescription}}</li>
                                    </ul>
                                </ng-container>
                                <ng-container *ngIf="assessmentValues?.length === 0">
                                    <h5>No Diagnosis are available.</h5>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                stepTitle="Medication Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div id="MedicationDetails">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>Medication Details</h5>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 rx-order-details">
                            <!-- datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders" -->
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders"
                                    class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Order Date</th>
                                            <th>Drug Name</th>
                                            <th>Strength</th>
                                            <th>Quantity</th>
                                            <th>Duration</th>
                                            <th>Problem Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let rxOrder of allRxOrderDetails; let i = index">
                                            <tr>
                                                <td>{{rxOrder?.creation_date || '--'}}</td>
                                                <td>{{rxOrder?.drug_name || '--'}}</td>
                                                <td>{{rxOrder?.drug_label_name || '--'}}</td>
                                                <td>{{rxOrder?.units_of_measure || '--'}}</td>
                                                <td>{{rxOrder?.days_supply || '--'}}</td>
                                                <td>{{rxOrder?.pharmacist_comments || '--'}}</td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="allRxOrderDetails?.length === 0">
                                            <tr>
                                                <td colspan="8" style="text-align: center;">No Medications are
                                                    Available.
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                stepTitle="Lab Orders Results" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div id="LabOrdersResults">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>Lab Orders Results</h5>
                            <hr>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="table-responsive new-designed-table">
                                <table datatable [dtOptions]="dtOptionsLabOrders" [dtTrigger]="dtTriggerLabOrders"
                                    class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Visit Date</th>
                                            <th>Order Name</th>
                                            <th>Lab Attribute Name</th>
                                            <th>Lab Attribute Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let labResult of legacyLabOrderResults; let i = index">
                                            <tr>
                                                <td>{{labResult?.lastvisitdate || '--'}}</td>
                                                <td>{{labResult?.orderName || '--'}}</td>
                                                <td>{{labResult?.labAttribute || '--'}}</td>
                                                <td>{{labResult?.labAttributeValue || '--'}}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                stepTitle="Immunization Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div id="ImmunizationDetails">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>Immunization Details</h5>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 rx-order-details">
                            <!-- datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders" -->
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptionsRxOrders" [dtTrigger]="dtTriggerRxOrders"
                                    class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Lot Number</th>
                                            <th>Administered Date</th>
                                            <th>Vaccine Name</th>
                                            <th>Vaccine Type</th>
                                            <th>Dosage</th>
                                            <th>Provider Name</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let immunization of treatmentFlowData?.immunizationDetails; let i = index">
                                            <tr>
                                                <td>{{immunization?.lotNumber || '--'}}</td>
                                                <td>{{immunization?.administeredDate || '--'}}</td>
                                                <td>{{immunization?.vaccineName || '--'}}</td>
                                                <td>{{immunization?.vaccineType || '--'}}</td>
                                                <td>{{immunization?.dosage || '--'}}</td>
                                                <td>{{immunization?.serviceProviderName || '--'}}</td>
                                                <td>{{immunization?.status || '--'}}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <aw-wizard-step stepTitle="Referral Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)"
                *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">
                <div id="referral-details">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>Referral Details</h5>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Referral Date</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.referralDetails?.auditInfo?.lastUpdatedDateTime ?
                                        getAppointmentTime(treatmentFlowData?.referralDetails?.auditInfo?.lastUpdatedDateTime)
                                        : ''}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12"></div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Referring Provider</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.referralDetails?.referringProvider || ''}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Reciever/Referral Provider</label> <span
                                        class="label-colon">
                                        :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.referralDetails?.referralProvider || ''}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Reason</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.referralDetails?.reason || ''}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

            <aw-wizard-step stepTitle="Last Service Provider Details" awOptionalStep
                (stepEnter)="navigateToTop(mainDetails)">
                <div class="row"
                    *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Doctor Name</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{treatmentFlowData?.lastServiceProviderDetails?.name || '--'}} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="LastServiceProviderDetailsPastVisits"
                    *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit !== 'Legacy Medical Record'">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>Electronically Signed by {{treatmentFlowData?.signOff?.doctorName}}</h5>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">E-Signature</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="signature">
                                        <h5><i>{{treatmentFlowData?.signOff?.doctorName || ''}}</i></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Initials</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="signature">
                                        <h5><i>{{treatmentFlowData?.signOff?.doctorInitials || ''}}</i></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>
            <aw-wizard-step *ngIf="appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                stepTitle="Last Billing Provider Details" awOptionalStep (stepEnter)="navigateToTop(mainDetails)">
                <div id="LastBillingProviderDetails">
                    <div class="row">
                        <div class="col-sm-12">
                            <h5>Last Billing Provider Details</h5>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="treatmentFlowAddNotes">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Doctor Name</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{treatmentFlowData?.lastBillingProviderDetails?.name || '--'}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>

        </aw-wizard>

    </app-card>
</div>
<br>
<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Display the Consent Form PDF -->
<app-ui-modal #additionalDocsViewModal [hideHeader]="false" [modalCentered]="true" id="additionalDocsViewModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Clinical Documents</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="additionalDocsViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="consent-pdf" frameBorder="0" width="100%" height="425px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="additionalDocsViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- Square Payment Card -->
<!-- <app-payments-common-new [paymentConfigDetails]="paymentConfigDetails"></app-payments-common-new> -->
<app-payments-refactor [paymentConfigDetails]="paymentConfigDetails"></app-payments-refactor>

<app-ui-modal #insuraceCardPastVisitViewModal [hideHeader]="false" [modalCentered]="true"
    id="insuraceCardPastVisitViewModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Insurance Card</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardPastVisitViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="insurance-card-past-visit-multiple" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" data-dismiss="modal" id="ok2"
            (click)="insuraceCardPastVisitViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>