import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../services/data-sharing.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  isLogin: any;

  constructor(public dataService: DataService, public router: Router) {
    this.dataService.currentMessage.subscribe((data) => this.isLogin = data.isLogin)
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isLogin || sessionStorage.getItem('isLogin') === 'true') {
      // this.toastr.getSuccesMessage('Successfully Authenticate!');
      this.dataService?.setIsLogin(true);
      let loginDetails = this.dataService.getLoginDetails() || {};
      // if(loginDetails?.roleType === 'Kiosk'){
      //   this.dataService.logout();
      //   // this.toastr.getSuccesMessage("You are a Kiosk user. Can't login in web!!");
      //   return false;
      // }else{
      return true;
      // }

    } else {
      this.dataService.logout();
      // this.toastr.getErrorMessage('Wrong Authentication! Clear Cache..Try Again')
      this.router.navigate(['/auth/signin']);
      return false;
    }
  }

}
