<app-ui-modal #labRadResultsModal [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="labRadResultsModal">
    <div class="app-modal-header">
        <h5 class="modal-title">{{selectedOrderDetails?.patientName }} Reports List</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="labRadResultsModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="reports">
            <!-- PDF Document -->
            <ng-container *ngFor="let report of selectedOrderDetails?.reportKey; let i = 'index+1'">
                <button class="icon-queue-events" placement="bottom" (click)="getS3KeyIndividual(report)"
                    [ngbTooltip]="'PDF Report ' + i"><i class="fa fa-file-pdf"></i></button>
            </ng-container>
            <!-- HTMl Documents -->
            <ng-container *ngFor="let report of getOrderReportsDetails?.htmlReports; let i = 'index+1'">
                <button class="icon-queue-events" placement="bottom" (click)="openHtmlReport(report)"
                    [ngbTooltip]="'HTML Report ' + i"><i class="fa fa-code"></i></button>
            </ng-container>

        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <ng-container>
                    <div class="report-pdf mt-2">
                        <iframe id="results-pdf-document" frameBorder="0" width="100%" height="500px"></iframe>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="labRadResultsModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>