<app-ui-modal id="createTaskModal" #createTaskModal [modalCentered]="true" [loadSpinner]="lateNotifySubmitClick"
    [hideHeader]="false" class="taskModal">
    <div class="app-modal-header">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="createTaskModal.hide();resetTask();closeTaskModal(createTaskModal)"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body" [formGroup]="NewTaskForm">
        <h5>Facility Action</h5>
        <div id="taskDetails" *ngIf="displayTable">
            <!-- <app-card #taskCard cardClass="card-datatable" class="new-designed-card" [options]="false">
                <div class="table-responsive table-enc">
                    <table datatable [dtOptions]="dtOptionsTasksStatus" [dtTrigger]="dtTriggerTasksStatus"
                        class="table table-striped table-hover custom-table">
                        <thead>
                            <tr>
                                <th>CATEGORY</th>
                                <th>OWNER</th>
                                <th>ASSIGNEE</th>
                                <th>DESCRIPTION</th>
                                <th>STATUS</th>
                                <th>PRIORITY</th>
                                <th class="no-sort">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let task of allTasks; let i = index">
                                <td class="colWidthTask" placement="bottom"
                                    title="{{(task?.category?.length > 20) ? task?.category : ''}}">{{task?.category}}
                                </td>
                                <td>{{task?.createdBy}}</td>
                                <td>{{task?.assignee}}</td>
                                <td class="colWidthTask" placement="bottom"
                                    title="{{(task?.description?.length > 20) ? task?.description : ''}}">
                                    {{task?.description}}</td>
                                <td>{{task?.status}}</td>
                                <td>{{task?.priority}}</td>
                                <td class="edit-delet-actions text-center">
                                    <button class="icon-queue-events signoff-action-bg" placement="bottom"
                                        *ngIf="task.status !== 'Completed'" id="update_{{task.taskId}}"
                                        ngbTooltip="Edit" (click)="openUpdatetask(task);navigateToTop(newtaskSection)"
                                        [ngClass]="task.taskId === selectedTask ? 'selected' : ''">
                                        <i class="las la-pencil-alt" aria-hidden="true"
                                            [ngClass]="task.taskId === selectedTask ? 'selected' : ''"></i>
                                    </button>

                                    <button class="icon-queue-events signoff-action-bg" placement="bottom"
                                        *ngIf="task.status === 'Completed'" id="update_{{task.taskId}}"
                                        ngbTooltip="View" (click)="openUpdatetask(task);navigateToTop(newtaskSection)"
                                        [ngClass]="task.taskId === selectedTask ? 'selected' : ''">

                                        <i class="las la-eye" aria-hidden="true"
                                            [ngClass]="task.taskId === selectedTask ? 'selected' : ''"></i>

                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </app-card> -->


            <!-- Spinner -->
            <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <hr>
        <div class="row" #newtaskSection id="newtaskSection">
            <div class="col-md-6">
                <div class="row">
                    <!-- <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Category<span class="required">*</span></label>

                            <ng-select placeholder="Choose task type" (change)="getTaskTypeList($event)"
                                [items]="categoriesList" formControlName="category" bindLabel="desc" bindValue="value"
                                [readonly]="disableCompleteField"></ng-select>


                            <label class="required"
                                *ngIf="NewTaskForm.controls['category'].hasError('required') && NewTaskForm.controls['category'].touched">Category
                                should be required</label>
                        </div>
                    </div> -->
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Action Type <span class="required">*</span></label>
                            <input type="text" placeholder="Action Type" formControlName="taskType" class="form-control input-text">

                            <label class="required"
                                *ngIf="NewTaskForm.controls['taskType'].hasError('required') && NewTaskForm.controls['taskType'].touched">Action Type is required</label>
                        </div>
                    </div>
                    <!-- <div class="col-md-4" *ngIf="isUpdate">
                        <div class="form-group">
                            <label class="form-label">Owner</label>
                            <input type="text" class="mb-3 form-control" [readonly]="true" formControlName="owner">
                        </div>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Comments/Notes <span class="required">*</span></label>

                            <textarea rows="3" class="form-control" formControlName="description"
                                placeholder="Comments/Notes" [readonly]="disableCompleteField"></textarea>
                            <label class="required"
                                *ngIf="NewTaskForm.controls['description'].hasError('required') && NewTaskForm.controls['description'].touched">Comments/Notes is required</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6" id="commentsection">
                <h5>Comments</h5>
                <div class="comments-container">
                    <div class="comments-list" *ngFor="let task of taskInfo?.taskDetails;">
                        <div class="task-comments">
                            <span class="task-title" *ngIf="task.auditUpdatedBy!== null">{{task.auditUpdatedBy}}</span>
                            <span class="task-title" *ngIf="task.auditUpdatedBy == null">{{task.auditCreatedBy}}</span>
                            <span class="date-time"> {{getAppointmentTime(task.createdDateTime)}}</span>
                        </div>
                        <span class="">{{task.description}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="app-modal-footer">
        <button *ngIf="!isUpdate" class="btn btn-primary dash-actions" style="margin-right: 15px;" type="button"
            [disabled]="disableCompleteField" (click)="addTask()">
            <span class="btn-text">Submit</span>
        </button>
        <button *ngIf="isUpdate && !disableCompleteField" class="btn btn-primary dash-actions" type="button"
            (click)="updateTask()" [disabled]="disableCompleteField">
            <span class="btn-text">Update</span>
        </button>&nbsp;

        <button *ngIf="isUpdate && !disableCompleteField" class="btn btn-primary dash-actions" type="button"
            (click)="resetTask(); closeTaskModal(createTaskModal)" [disabled]="disableCompleteField">
            <span class="btn-text">Cancel</span>
        </button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"></app-exception-modal>