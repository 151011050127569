import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-upload-insurance-driving-docs',
  templateUrl: './upload-insurance-driving-docs.component.html',
  styleUrls: ['./upload-insurance-driving-docs.component.scss']
})
export class UploadInsuranceDrivingDocsComponent implements OnInit, OnChanges {
  url: string | ArrayBuffer;



  public drivingLicenseFile: string;
  public insuranceCardFile: string;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalModal: ExceptionModalComponent;

  @Input() insuranceDetails: FormGroup;
  // @Input() insuranceIndex: number;
  public getSelectedInsuValue: any;


  private loginUserDetails: any;

  @Input() hideInsurance: boolean;
  @Input() userInfoDetailsForm: FormGroup;
  @Input() uploadIdForm: FormGroup;
  @Input() patientDetailsInfo: any;
  @Input() userdataupdate: boolean;
  @Input() PatientDetailsCardReload: CardComponent;
  public selectedIDinfo: any;
  public selectedAdditionalKey: any;
  public selectedS3Key: any;
  @Input() sectionName: any;
  public loadSpinner: boolean;
  public downloadedFileType: any;
  @Input() checkUnSavedDataForm: FormControl;

  constructor(private httpService: HttpService) {
    this.loginUserDetails = JSON?.parse(sessionStorage?.getItem('userDetails')) || {};
  }

  ngOnInit(): void {
    this.selectedAdditionalKey = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {

      // if (this.patientDetailsInfo?.insuranceDetails?.insuranceCardImageId) {
      //   this.insuranceCardFile = this.patientDetailsInfo?.insuranceDetails?.insuranceCardImageId;
      //   this.downloadInsuranceCard(this.patientDetailsInfo?.insuranceDetails?.insuranceCardImageId);
      // }
      //common id document shown
      if (this.patientDetailsInfo) {
        this.getCommonIdDocument();
      }
      // this.getSelectedInsuValue = this.insuranceDetails?.controls[this.insuranceIndex]?.value;
      this.getSelectedInsuValue = this.insuranceDetails?.value;
    }
  }

  private getCommonIdDocument(): void {
    const appointmentId = this.userdataupdate ? '' : this.patientDetailsInfo?.id;
    this.httpService?.getDlIdDocument(this.loginUserDetails?.facilityId, this.patientDetailsInfo?.userInfoId || this.patientDetailsInfo?.id, appointmentId || '')?.subscribe((data: any) => {
      if (data?.responseObject?.identityImageId) {
        this.drivingLicenseFile = data?.responseObject?.identityImageId;
        // this.downloadDlCard(this.drivingLicenseFile);
        let userId = null;
        if (this.sectionName == 'chart') {
          userId = this.patientDetailsInfo.id;
        }
        else if (this.sectionName == 'demographics') {
          userId = this.patientDetailsInfo.userInfoId;
        }
        this.downloadIdentificationCard(this.drivingLicenseFile, "Identification Record", userId);
      }else{
        this.drivingLicenseFile = null;
        this.selectedAdditionalKey = null;
      }
    })
  }

  // Insurance / DL Image Card Download
  public downloadInsuranceCard(cardId: string): void {
    const action = `document/user/getInsuranceCard/${cardId}`;

    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // created dynamic iframe SRC attribute and assigned lookup url
      // window.open(fileURL);
      const iframeEle = document.getElementById('insurance-card') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    },
      (error: any) => {
      });
  }

  // Insurance / DL Image Card Download
  public downloadDlCard(cardId: string): void {
    const action = `document/user/getIdCard/${cardId}`;

    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // window.open(fileURL);
      const iframeEle = document.getElementById('dl-card') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
    },
      (error: any) => {
      });
  }


  public downloadIdentificationCard(additionalKey, documentType, userInfoId) {
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {
          this.hideInsurance = false;
        }
        else {
          this.hideInsurance = true;

          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          this.selectedAdditionalKey = additionalKey;
          this.selectedS3Key = s3Key;
          const fileType = data?.responseObject?.fileType;
          this.downloadedFileType = fileType;
          this.downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.loadSpinner = false;
    });
  }

  public downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType) {
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.loadSpinner = true;
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if(!dData) {
        this.loadSpinner = false;
        return;
      }
      this.hideInsurance = true;
      this.loadSpinner = false;

      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);

      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }
      const iframeEle = document.getElementById('dl-card') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);


    }, (error) => {
      this.loadSpinner = false;
    });
  }


  onFileChanged(event) {
    const files = event.target.files;
    if (files?.length === 0)
      return;

    const mimeType = files[0].type;
    const reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  // it will call when file upload
  public insuranceFileChanged(event: any): void {
    if (!this.getSelectedInsuValue || !this.getSelectedInsuValue?.memberId || !this.getSelectedInsuValue?.payerId || !this.getSelectedInsuValue?.name) {
      this.notifyText = 'Please add the Insurance Details to upload the Insurance Card.';
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      return;
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadInsuranceImage(file);
    }
  }

  // driving licence file upload
  public drivingLicenceFileChanged(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      if (fileSize <= 25 && (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg')) {
        this.uploadLicenseImage(file);
      }
      else {

        this.notifyText = "Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded ";
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
        return;
      }
    }
  }

  public uploadInsuranceImage(file: any): void {
    const isConnect = true;
    let action: any = `document/user/uploadInsuranceImage?userInfoId=${this.patientDetailsInfo.id}&isConnect=${isConnect}`;
    // convert to binary for data
    const formData = new FormData();
    formData.append('idFile', file);
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService.makePostWithFormData(action, formData)
      .subscribe((data: any) => {
        if (data?.status === 'SUCCESS') {
          //this.insuranceCardFile = data?.fileName || null;
          this.insuranceCardFile = data?.keyName || null;
          const additionalKey = data?.keyName || null;

          if (this.insuranceCardFile != '') {
            this.updateInsuranceIdentityImageId('insuCard', additionalKey);
          }
        } else {
          this.notifyText = data?.status || data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
        }
        this.PatientDetailsCardReload?.cardRefreshHide();
      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
          this.PatientDetailsCardReload?.cardRefreshHide();
        });
  }

  public uploadLicenseImage(file: any): void {

    const isConnect = true;
    let action = '';

    let userId = this.patientDetailsInfo.id;
    if (this.sectionName == 'chart') {
      userId = this.patientDetailsInfo.id;
    }
    else if (this.sectionName == 'demographics') {
      userId = this.patientDetailsInfo.userInfoId;
    }
    if (this.selectedAdditionalKey == null) {
      action = `document/user/uploadId?userInfoId=${userId}&isConnect=${isConnect}`;
    }
    else {
      action = `document/user/uploadId?userInfoId=${userId}&isConnect=${isConnect}&deletekey=${this.selectedAdditionalKey}`;
    }

    // convert to binary for data
    const formData = new FormData();
    formData?.append('idFile', file);
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService.makePostWithFormData(action, formData)
      .subscribe((data: any) => {
        if (data?.status === 'SUCCESS') {
          this.drivingLicenseFile = data?.keyName || null;
          const additionalKey = data?.keyName || null;

          if (this.selectedAdditionalKey !== null) {
            this.updateIdCardKeys(this.selectedAdditionalKey, data?.keyName, this.patientDetailsInfo.id);
          }

          if (this.userdataupdate) {
            this.uploadIdForm?.get('imageId')?.setValue(this.drivingLicenseFile || null);
            // this.downloadDlCard(this.drivingLicenseFile);
            const documentType = "Identification Record";
            let userId = null;
            if (this.sectionName == 'chart') {
              userId = this.patientDetailsInfo.id;
            }
            else if (this.sectionName == 'demographics') {
              userId = this.patientDetailsInfo.userInfoId;

            }
            this.downloadIdentificationCard(additionalKey, documentType, userId);
            if (this.userInfoDetailsForm) {
              this.userInfoDetailsForm?.markAsDirty();
              this.checkUnSavedDataForm?.setValue(true);
            }
          } else {
            if (this.drivingLicenseFile != '') {
              this.updateInsuranceIdentityImageId('dlCard', additionalKey);
            }
          }

          if (this.sectionName == 'demographics') {
            this.uploadIdForm?.markAsDirty();
            this.checkUnSavedDataForm?.setValue(true);
          }
         // this.notifyText = data?.msg || data?.message || 'Identity Document has been uploaded successfully.';
         // this.isSuccessNotify = true;
          //this.exceptionalModal?.modalShow();
        } else {
          this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
        }
        this.PatientDetailsCardReload?.cardRefreshHide();
      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
          this.PatientDetailsCardReload?.cardRefreshHide();
        });
  }

  public updateInsuranceIdentityImageId(cardType: string, additionalKey: any): void {
    const insuranceCardFile = this.insuranceCardFile || '';
    const dlCardFile = this.drivingLicenseFile || '';

    let action: string = `document/uploadInsuranceimageforAppointment?appointmentId=${this.patientDetailsInfo.id}&insImageName=${insuranceCardFile}&memberId=${this.getSelectedInsuValue?.memberId}`;
    if (cardType === 'dlCard') {
      action = `document/uploadIDimageforAppointment?appointmentId=${this.patientDetailsInfo.id}&idImageName=${dlCardFile}`;
    }
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data?.body?.status === 'SUCCESS') {
          this.notifyText = data?.msg || data?.message || 'File uploaded successfully.';
          this.isSuccessNotify = true;
          this.exceptionalModal?.modalShow();
          if (cardType === 'insuCard') {
            this.insuranceDetails?.get('insuranceCardImageId')?.setValue(insuranceCardFile);
            // this.downloadInsuranceCard(insuranceCardFile);
          } else {
            this.uploadIdForm?.get('identityImageId')?.setValue(dlCardFile || null);
            // this.downloadDlCard(dlCardFile);
            const documentType = "Identification Record";
            let userId = null;
            if (this.sectionName == 'chart') {
              userId = this.patientDetailsInfo.id;
            }
            else if (this.sectionName == 'demographics') {
              userId = this.patientDetailsInfo.userInfoId;
            }

            this.downloadIdentificationCard(additionalKey, documentType, userId);
          }
        } else {
          this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
        }
        this.PatientDetailsCardReload?.cardRefreshHide();
      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
          this.PatientDetailsCardReload?.cardRefreshHide();
        });
  }

  public deleteIDRecordOpen(info: any, deletConfirmDocument: UiModalComponent): void {
    this.selectedIDinfo = info;
    deletConfirmDocument?.show();
  }

  public deletePatientIDDocument(selectedIDinfo: any, deletConfirmDocument: UiModalComponent): void {
    let userId = null;
    if (this.sectionName == 'chart') {
      userId = this.patientDetailsInfo.id;
    }
    else if (this.sectionName == 'demographics') {
      userId = this.patientDetailsInfo.userInfoId;
    }

    const action = `record/deleteDocument?s3Key=${this.selectedS3Key}&userInfoId=${userId}&additionalKey=${this.selectedAdditionalKey}`;

    this.PatientDetailsCardReload?.cardRefreshShow();
    deletConfirmDocument.hide();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.notifyText = data?.body?.msg || data?.body?.message || 'Identification Document Deleted Successfully';
      this.isSuccessNotify = true;
      this.exceptionalModal?.modalShow();
      this.PatientDetailsCardReload?.cardRefreshHide();
      this.drivingLicenseFile = '';
      this.uploadIdForm?.get('imageId')?.setValue(null);

      this.updateIdCardKeys(this.selectedAdditionalKey, '', userId);
      this.checkUnSavedDataForm?.setValue(true);
      if (this.sectionName == 'demographics') {
        this.uploadIdForm?.get('identityImageId')?.setValue(null);
        this.uploadIdForm?.markAsDirty();
        this.checkUnSavedDataForm?.setValue(true);
      }
      this.userInfoDetailsForm?.markAsDirty();
      this.selectedAdditionalKey = null;
      this.drivingLicenseFile = null;
      //this.downloadIdentificationCard(this.drivingLicenseFile,"Identification Record",this.patientDetailsInfo?.id);

    }, error => {
      this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.PatientDetailsCardReload?.cardRefreshHide();
    })
  }

  public updateIdCardKeys(existingS3Key: any, news3Key: any, userInfoId: any): void {

    let action = '';
    if (news3Key !== '') {
      action = `record/updateIdImageUserInfoKey?existingS3Key=${existingS3Key}&news3Key=${news3Key}&userInfoId=${userInfoId}`;
    }
    else {
      action = `record/updateIdImageUserInfoKey?existingS3Key=${existingS3Key}&userInfoId=${userInfoId}`;
    }
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        this.PatientDetailsCardReload?.cardRefreshHide();
        if (data?.body?.status === 'SUCCESS') {


        } else {

        }

      },
        error => {

        });

  }


  public downloaFullview(additionalKey: string, insuraceCardModal: UiModalComponent) {

    const documentType = "Identification Record";
    let userId = null;
    if (this.sectionName == 'chart') {
      userId = this.patientDetailsInfo.id;
    }
    else if (this.sectionName == 'demographics') {
      userId = this.patientDetailsInfo.userInfoId;
    }

    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      this.PatientDetailsCardReload?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {
          this.hideInsurance = false;
        }
        else {

          this.hideInsurance = true;

          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          const fileType = data?.responseObject?.fileType;
          this.downloaFullviewFile(this.patientDetailsInfo?.id, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {

    });
  }

  public downloaFullviewFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal: UiModalComponent) {
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.loadSpinner = true;
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if(!dData) {
        this.loadSpinner = false;
        return;
      }
      this.hideInsurance = true;
      this.loadSpinner = false;

      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);

      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }

      insuraceCardModal?.show();
      this.PatientDetailsCardReload?.cardRefreshHide();

      const iframeEle = document.getElementById('uplolad-insurance-card-multiple') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    }, (error) => {
      this.PatientDetailsCardReload?.cardRefreshHide();
    });
  }


}
