import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { AccountBalanceComponent } from '../account-balance/account-balance.component';
import { PaymentsCommonNewComponent } from '../payments-common-new/payments-common-new.component';
import { AccountBalanceNewComponent } from '../account-balance-new/account-balance-new.component';
import { UploadAdditionalDocumentsComponent } from '../upload-additional-documents/upload-additional-documents.component';
import { PaymentsRefactorComponent } from '../payments-refactor/payments-refactor.component';
import { ClaimsService } from '../../services/claims.service';
import { ApisService } from '../../services/apis.services';
import { EobDocumentCheckUpdateComponent } from '../eob-document-check-update/eob-document-check-update.component';

@Component({
  selector: 'app-post-charges-update',
  templateUrl: './post-charges-update.component.html',
  styleUrls: ['./post-charges-update.component.scss']
})
export class PostChargesUpdateComponent implements OnInit {

  @ViewChild('claimPostChargesModal') public claimPostChargesModal: UiModalComponent;
  public loadSpinner: boolean;
  appointmentCostOfCare: FormGroup;
  claimAppointmentId: any;
  appointmentCostOfCareDetails: any;
  @Input() claimsStatusCard: CardComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalComp: ExceptionModalComponent;
  @Output() postChargesUpdate = new EventEmitter<boolean>();
  saveComplete: boolean;
  @Input() noSaveButton: boolean;
  @ViewChild(AccountBalanceNewComponent) accountBalanceComp: AccountBalanceNewComponent;
  public selectedClaimIndex: number;
  @Output() callPrevNxt = new EventEmitter<any>();
  @Input() patientID: string;
  @Input() isClaimPage: any;
  @Input() noPayOption: boolean;
  public loginDetails: any;
  paymentConfigDetails: any;
  @ViewChild(PaymentsRefactorComponent) private payComp: PaymentsRefactorComponent;
  @Output() reloadPendingPayments = new EventEmitter<any>();
  @Input() mainExceptionModal: ExceptionModalComponent;
  // @ViewChild(UploadAdditionalDocumentsComponent) public uploadDocument: UploadAdditionalDocumentsComponent;
  @ViewChild(EobDocumentCheckUpdateComponent)public eobDocCheckDetailsComp: EobDocumentCheckUpdateComponent;
  public collectionName = "Encounter";
  public additionalDocName: any;
  appointmentInfoDetails: any;
  public allEobDocuments: any[] = [];
  @Input() selectedClaimId: null;
  public eraDetails: any;
  public manualEob: boolean;
  accountBalancePaymentStatus: any;
  postChargesUserDetails: any;
  
  @Output() claimInfoUpdate = new EventEmitter<any>();
  public allCostOfCareItems: any;
  showUploadEobDetails: boolean;
  @Input() recieptAbId: string;

  constructor(private httpService: HttpService, private router: Router, private formBuilder: FormBuilder, private claimsService: ClaimsService, private apiService: ApisService) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.appointmentCostOfCare = this.formBuilder?.group({
      "appointmentId": [this.claimAppointmentId || null],
      "discount": [null, Validators?.required],
      "nonPayment": [null, Validators?.required],
      "patientResponsibility": [null, Validators?.required],
      "reimburseAmount": [null, Validators?.required],
      "credits": [null, Validators?.required],
      "approvedAmount": [null, Validators?.required],
      "coverageCost": [null, Validators?.required],
    });
  }

  ngOnInit(): void {

   
  }

  public showPostChargesUpdateModal(claimAppID: string, index?: number, paymentDetails?: any, isClaimsQueue?: string): void {
    this.claimAppointmentId = claimAppID;
    this.selectedClaimIndex = index;
    this.accountBalanceComp?.guardControl?.reset();
    this.claimPostChargesModal?.show();
    this.getPaymentConfig();
    // this.getAllEobDocs();
    this.viewEob();
    this.accountBalancePaymentStatus = null;
    if (paymentDetails && !isClaimsQueue) {
      this.accountBalancePaymentStatus = paymentDetails;
    }
    if (isClaimsQueue && paymentDetails && paymentDetails?.userInfoId) {
      this.getPendingPaymentsByUser(paymentDetails?.userInfoId, claimAppID);
    }
    this.postChargesUserDetails = paymentDetails;
    // this.getAppointmentCost(claimAppID);
  }

  public responseChange(change: any) {
    this.saveComplete = false;
  }

  // to get payment config details
  private getPaymentConfig(): any {
    // const action = `providerConfiguration/getPaymentConfig?facilityId=${this.loginDetails?.facilityId}`;
    this.apiService?.getPaymentConfig(this.loginDetails?.facilityId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentConfigDetails = data?.responseObject;
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      });
  }

  // Submit Account Balance
  public callSaveApptCostofCare(): void {
    this.accountBalanceComp?.submitPayCost(this.allEobDocuments);
  }

  public callSaveServiceLineCharges(): void {
    this.accountBalanceComp?.submitServiceLineCharges();
  }

  // reset the appt cost care while closing the popup
  public resetAppntCostCare(): void {
    this.accountBalanceComp?.resetPostCharges();
  }

  // call the Prev and Next
  public callNextPrev(actionType?: string): void {
    this.callPrevNxt?.emit(actionType == 'prev' ? this.selectedClaimIndex - 1 : this.selectedClaimIndex + 1);
  }

  // Payment Open
  public openPayment(appointmentInfoDetails?: any): void {
    this.appointmentInfoDetails = appointmentInfoDetails;
    this.claimPostChargesModal?.hide();
    this.payComp?.openModal(appointmentInfoDetails, true);
  }

  // refresh Payments
  public loadPaymentInfo(): void {
    this.accountBalanceComp?.loadPaymentInfo();
    this.reloadPendingPayments?.emit(true);
    this.claimInfoUpdate?.emit();
  }

  // upload EOB documents
  public openEobCheckModal(): void {
    // this.appointmentInfoDetails.id = this.appointmentInfoDetails?.appointmentId;
    this.eobDocCheckDetailsComp?.openUploadDocument({id:this.claimAppointmentId}, 'Encounter', 'EOB');
  }

  // View or Download the document
  public viewDonloadEobDocu(isView?: string, viewDownloadEobEncounterDoc?: UiModalComponent): void {
    if(this.allEobDocuments && this.allEobDocuments?.length === 0) {
      this.notifyText = 'Explanation of Benefits not received.';
      this.isSuccessNotify = false;
      this.exceptionalComp?.modalShow();
    }else {
      this.viewDownloadDocument(this.allEobDocuments[0], isView, viewDownloadEobEncounterDoc);
    }
  }
  //  to get all the additonal documents
  public getAllEobDocs(isDocUpload?: boolean): void {
    this.accountBalanceComp?.getAllEobDocs(true);
    this.reloadPendingPayments?.emit(true);
    return;
    if(isDocUpload) {
      this.reloadPendingPayments?.emit(true);
    }
    const action = `record/listDocuments?appointmentId=${this.claimAppointmentId}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action, '')
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          const responseObj = data?.responseObject || [];
          this.allEobDocuments = responseObj?.filter((listItem: any) => listItem?.documentType === 'EOB') || [];
        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalComp?.modalShow();
        }
        this.loadSpinner = false;
      }, error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalComp?.modalShow();
        this.loadSpinner = false;
      });
  }

  // Show Documents view popup
  public viewDownloadDocument(eobDocument: any, isViewDownload: string, patientMedicalRecordModal: UiModalComponent) {
    if (!eobDocument?.s3Key || !eobDocument?.s3Path) {
      return;
    }
    const fileType = eobDocument?.fileType;
    this.loadSpinner = true;
    const action = `record/downloadDocument?s3Key=${eobDocument?.s3Key}&s3Path=${eobDocument?.s3Path}`;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      if (!data) {
        this.loadSpinner = false;
        return;
      }
      const file = new Blob([data], { type: fileType })
      const fileURL = URL.createObjectURL(file);
      if (isViewDownload === 'view' || this.allEobDocuments?.length > 1) {
        setTimeout(() => {
          const iframeEle = document.getElementById('view-download-eob-doc-post-charges') as HTMLElement;
          iframeEle.setAttribute('src', fileURL);
        }, 500);
        patientMedicalRecordModal?.show();
      } else {
        let a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = eobDocument?.documentName;
        document.body.appendChild(a);
        a.click();
      }
      this.loadSpinner = false;
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalComp?.modalShow();
        this.loadSpinner = false;
      });
  }

  public downloadERAPDF(viewEobPostCharge?: UiModalComponent): void {
    let action = '';
    if (!this.selectedClaimId) {
      this.notifyText = 'No Claim details are present.';
      this.exceptionalComp?.modalShow();
      return;
    }
    if (this.selectedClaimId) {
      action = `claim/getEraPdf?appointmentId=${this.claimAppointmentId}&claimId=${this.selectedClaimId}`;
    }
    this.loadSpinner = true;
    this.claimsService.downloadS3Image(action, '').subscribe((data: any) => {
      this.loadSpinner = false;
      var file = new Blob([data], { type: 'application/pdf' });
      if (file.size == 0) {
        this.notifyText = 'Explanation of Benefits not received';
        this.exceptionalComp?.modalShow();
      }
      else {
        const blobToFile = new File([file], `${this.selectedClaimId}_EOB.pdf`, {
          type: file?.type,
        });
        var fileURL = URL.createObjectURL(file);
        const iframeEle = document.getElementById('eob-pdf-postcharge-post-charges') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
        if (viewEobPostCharge) {
          viewEobPostCharge?.show();
        }
      }
    }, error => {
      this.loadSpinner = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.exceptionalComp?.modalShow();
    });
  }

  
  public dateFormate(date: string): string {
    let splitDate: any;
    let actDate: any;
    if (date?.includes('T')) {
      splitDate = date?.toString()?.split('T');
      actDate = splitDate && splitDate?.length > 0 && splitDate[0]
    } else {
      actDate = date;
    }
    return actDate || date;
    // return this.httpService?.getAppointmentTime(date) || date;
  }

  public viewEob(): void {
       
    const action = `claim/getEraDetails?appointmentId=${this.claimAppointmentId}`;   
    this.claimsService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {          
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject) {
          this.eraDetails = data?.body?.responseObject;
          console.log(data?.body?.responseObject);        
          this.manualEob = this.eraDetails?.manual;
          console.log(this.manualEob);           
        }
        else{
            this.manualEob = false;
        }     

      }
      else{
        this.manualEob = false;      
      }

    }, error => {
      
     

    })
  }

  public openAccBalancePayment(){
    this.accountBalanceComp?.openPayment();

  }

  // Get Account Balance with userinfo to get the status
  public getPendingPaymentsByUser(userInfoId: string, appntId: string): void {
    this.apiService?.getChartInfoAccountBalance(userInfoId)?.subscribe((data: any) => {
      if (data?.status == 'SUCCESS') {
        if (data?.responseObject && data?.responseObject?.paymentsDetails && data?.responseObject?.paymentsDetails?.length > 0) {
          this.accountBalancePaymentStatus = data?.responseObject?.paymentsDetails?.find((allPayments: any) => allPayments?.appointmentId === appntId) || null;
        }
      }
    }, error => {

    });
  }

  // Get the claim number from accountbalance fro getEraPdf
  public setClaimNumber($event: any): void {
    this.selectedClaimId = $event;
  }

  public updateClaimList(){
    this.claimInfoUpdate?.emit();
  
  }

  // Open check details modal
  public openCheckDetailsModal(): void {
    this.accountBalanceComp?.openCheckDetailsModal();
  }

  public checkGuarding(claimPostChargesModal: UiModalComponent, postChargesUpdateGuardingModal: UiModalComponent): void {
    // if (this.accountBalanceComp?.guardControl?.value) {
    //   postChargesUpdateGuardingModal?.show();
    // } else {
      this.hidePostChargesModal(claimPostChargesModal);
    // }
  }

  public hidePostChargesModal(claimPostChargesModal: UiModalComponent): void {
    claimPostChargesModal?.hide();
    this.resetAppntCostCare();
    this.updateClaimList();
    setTimeout(() => {this.accountBalanceComp?.guardControl?.reset()});
  }

  // Passing the cost of care details after reteive api response
  public updateAllCostOfCares($event): void {
    this.allCostOfCareItems = $event || [];
    const getCostofcareItem: any = this.allCostOfCareItems?.payments?.find((payItem: any) => payItem?.claimProcessorType === 'Primary') || null;
    const getPayment: any = this.allCostOfCareItems?.payments?.find((payItem: any) => payItem?.claimProcessorType === 'Primary' && payItem?.paymentCategory === 'Payer' && payItem?.description === 'Reimbursements' && (payItem?.amount > 0 || payItem?.amountPaid > 0)) || null;

    if (getPayment && (getPayment?.claimInfo?.checkNumber || getPayment?.claimInfo?.checkDate || this.allEobDocuments?.length > 0) && !getCostofcareItem?.selfPay) {
      this.showUploadEobDetails = true;
    } else {
      this.showUploadEobDetails = false;
    }
  }

  // Clear the Iframe src
  public clearIframeSrc(): void {
    const iframeEle = document?.getElementById('view-download-eob-doc-post-charges') as HTMLElement;
    if (iframeEle) {
      iframeEle?.removeAttribute('src');
    }
  }

  public getAppntCostOfCare($event) {
    if($event) {
      this.accountBalanceComp?.getAppointmentCost();
      // this.reloadPendingPayments?.emit(true);
    }
  }

}
