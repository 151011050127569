import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { ReScheduleAppointmentComponent } from '../re-schedule-appointment/re-schedule-appointment.component';
import { forkJoin } from 'rxjs';
import SignaturePad from 'signature_pad';
import { PaymentsCommonNewComponent } from '../payments-common-new/payments-common-new.component';
import { BillingCodesComponent } from '../billing-codes/billing-codes.component';
import { ScheduleFollowupAppointmentComponent } from '../schedule-followup-appointment/schedule-followup-appointment.component';
import { PaymentsRefactorComponent } from '../payments-refactor/payments-refactor.component';
import { ApisService } from '../../services/apis.services';

@Component({
  selector: 'app-final-check-list',
  templateUrl: './final-check-list.component.html',
  styleUrls: ['./final-check-list.component.scss']
})
export class FinalCheckListComponent implements OnInit, OnChanges {

  public finalCheckListForm: FormGroup;
  @Input() selfpayCheck: any;
  @Input() paidAmount: 0;
  @Input() patientDetailsInfo: any;
  @ViewChild(ReScheduleAppointmentComponent) reScheduleComp: ReScheduleAppointmentComponent;
  @ViewChild(ScheduleFollowupAppointmentComponent) scheduleFollowUp: ScheduleFollowupAppointmentComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public dischargeForm: FormGroup;
  @Input() appointmentId: any;
  public loadSpinner: boolean;
  public notifyText: any;
  public isSuccessNotify: boolean;
  @Input() treatmentFlowData: any;
  public minAppointmentDate: Date;
  @Input() PatientDetailsCardReload: CardComponent;
  public documentForm: FormGroup;
  @ViewChild('additionalDocs') additionalDocs: ElementRef;
  public allDocumentTypes = ["Lab Results", "Radiology Results", "Referral", "Wellness Forms", "Other"];
  @ViewChild(PaymentsRefactorComponent) public payComp: PaymentsRefactorComponent;
  public paymentConfigDetails: any;
  public loginDetails: any;
  public documentsList: any[] = [];
  public pastVisitMedications: any[] = [];

  signaturePad!: SignaturePad;
  @ViewChild('canvas')
  canvasEl!: ElementRef;
  signatureImg!: string;
  signatureImgString!: string;
  public signatureForm: FormGroup;
  public dtPatientDetails: any;
  public providerFirstName: any;
  public providerLastName: any;

  public isDocumentDownloaded: boolean = false;
  downloadDocumentErrorMessage: boolean = false;
  public addDocumentTypes = [];
  public UploadedFiletype: any;
  alllabRadOrderDetails: any = [];
  public selectedUserInfoId: string;
  public treatmentFlowAvailable: boolean = false;
 
  @Output() paymentInfoReload = new EventEmitter<any>();
  @Output() summaryEvent = new EventEmitter<string>();


  @ViewChild(BillingCodesComponent) billingcodesComp: BillingCodesComponent;
  public scheduleType: any;
  dupTreatmentFlowData: any;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private apiService: ApisService) {
    this.finalCheckListForm = formBuilder?.group({
      'isFollowUpRequired': [null],
      'followUp': formBuilder?.group({
        'reason': [null, Validators?.required],
        'recommendationDate': [null],
        'followUp': [null]
      }),
      'postVisitSummary': [null]
    });

    this.dischargeForm = formBuilder?.group({
      'notes': [null, Validators?.required]
    });

    this.documentForm = formBuilder?.group({
      'documentType': [null, Validators?.required],
      'documentName': [null, Validators?.required],
      'multipartFile': [null, Validators?.required],
    });

    this.signatureForm = formBuilder?.group({
      'signature': [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.minAppointmentDate = this.httpService.convertDate(new Date());
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.getPaymentConfig();
    this.getAppointmentById();
    this.getDocumenttypeList();
    this.scheduleType = "schedule";

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.treatmentFlowData) {
       
        if (this.treatmentFlowData?.dischargeInstructions?.notes) {
          this.dischargeForm?.get('notes')?.setValue(this.treatmentFlowData?.dischargeInstructions?.notes || null);
          this.finalCheckListForm?.get('postVisitSummary')?.setValue(true);
        }
        if (this.treatmentFlowData?.followUp) {
          if (this.treatmentFlowData?.followUp?.recommendationDate && this.treatmentFlowData?.followUp?.recommendationDate?.includes('T')) {
            this.treatmentFlowData.followUp.recommendationDate = this.httpService?.formatDobTimeZone(this.treatmentFlowData?.followUp?.recommendationDate) || null;
          }
          this.finalCheckListForm?.get('followUp')?.patchValue(this.treatmentFlowData?.followUp);
          this.finalCheckListForm?.get('isFollowUpRequired')?.setValue(true);
        }
        if (this.treatmentFlowData?.chiefComplaints) {
          const mainObjtoPatch = {};
          const allChiefComplaints = [];
          this.treatmentFlowData?.chiefComplaints?.forEach((item, index) => {
            item?.sections.forEach((item2, index2) => {
              item2?.answers?.forEach((item3, index3) => {
                mainObjtoPatch[item3?.actualValue[0]?.answer] = item3?.actualValue[0]?.answer;
                allChiefComplaints?.push(item3?.actualValue[0]?.answer);
              });
            });
          });
          sessionStorage.setItem(
              'allChiefComplaints',
              JSON.stringify(allChiefComplaints)
            );
          
        }
        
        if (this.treatmentFlowData.auditInfo !== null) {

          this.treatmentFlowAvailable = true;

        }
      }
      if (this.appointmentId) {
        this.labRadOrderDetails();
      }
      // console.log(this.patientDetailsInfo);
    }
  }

  // get cheif complaints loop
  get chiefCmpHeader(): any[] {
    const allaChiefComplaints =
      JSON.parse(sessionStorage?.getItem('allChiefComplaints')) || [];
    // return this.chiefComplaintsHeaderShow || [];
    return allaChiefComplaints || [];
  }

  ngAfterViewInit() {
    //this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  public getAppointmentById(): void {
    if (!this.appointmentId) {
      return;
    }
    let action: any = 'appointment/registration/getAppointmentById?id=';
    let params: any = {
      'id': this.appointmentId
    }


    this.httpService.getPatientsByFacilityId(action, params.id)
      .subscribe((data: any) => {
        if (data.status == 'SUCCESS') {
          this.dtPatientDetails = JSON.parse(JSON.stringify(data?.responseObject)) || null;
          if (this.dtPatientDetails) {
           
            this.providerFirstName = this.dtPatientDetails?.appointment?.authorized_official_first_name;
            this.providerLastName = this.dtPatientDetails?.appointment?.authorized_official_last_name;

            this.selfpayCheck = this.dtPatientDetails?.selfPay;
            this.selectedUserInfoId = this.dtPatientDetails?.userInfoId;

          }

        }

      }, error => {

      });
  }


  // to get payment config details
  private getPaymentConfig(): any {
    // const action = `providerConfiguration/getPaymentConfig?facilityId=${this.loginDetails?.facilityId}`;
    this.apiService?.getPaymentConfig(this.loginDetails?.facilityId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentConfigDetails = data?.responseObject;
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      });
  }

  // open follow-up appointment
  public openReSchedule(): void {
    if (this.finalCheckListForm?.invalid) {
      this.finalCheckListForm?.markAllAsTouched();
      return;
    }
    this.saveFollowUp();
    this.reScheduleComp?.openSetAppointment(this.patientDetailsInfo);
  }

  //open openDischargeInstructions
  public openDischargeInstructions(diModal: UiModalComponent): void {
    this.getAllPastVisitsMedications();
    diModal?.show();
    this.dupTreatmentFlowData = JSON?.parse(JSON?.stringify(this.treatmentFlowData))
    let assesmentNotes: any = {};
    this.dupTreatmentFlowData?.carePlan?.forEach((data: any) => {
      if (data?.resourceType === 'General') {
        if (data?.otherNotes && data?.otherNotes !== "") {
          assesmentNotes.otherNotes = data?.otherNotes;
        }
        data?.sections?.forEach((secItem: any) => {
          if (secItem?.name === 'Assessment') {
            secItem?.answers?.forEach((ansItem: any) => {
              assesmentNotes.actualValue = ansItem?.actualValue || [];
            })
          }
        });
      }
    });
    this.dupTreatmentFlowData.carePlan = Object?.keys(assesmentNotes)?.length > 0 ? assesmentNotes : null;
    // this.dupTreatmentFlowData.carePlan = assesmentNotes || null;
  }

  //open openScheduleFollowup

 /* public openScheduleFollowup(diModal: UiModalComponent): void {
    diModal?.show();
  }*/
  public openScheduleFollowup()  {
    this.scheduleFollowUp.openModal();
  }


  public openDocUpload(diModal: UiModalComponent): void {
    diModal?.show();
  }
  
  public clearfileinfo() {
    this.documentsList = [];
  }

  // save Discharge Instrctions
  public saveDischargeInstructions(dischargeModal: UiModalComponent): void {
    if (this.dischargeForm?.invalid) {
      this.dischargeForm?.markAllAsTouched();
      return;
    };
    this.summaryEvent.emit(this.dischargeForm?.value?.notes);
    const action = `treatmentFlow/saveDischargeNotes?appointmentId=${this.appointmentId}`;
    const params = {
      'notes': this.dischargeForm?.value?.notes
    }
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      dischargeModal?.hide();
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.msg || data?.body?.message || "Discharge Instructions has been saved successfully..!";
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.loadSpinner = false;
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      });
  };

  // to check if other select
  get checkIfOtherSelects(): boolean {
    const findObj = this.treatmentFlowData?.chiefComplaints?.values?.find((item: any) => item === 'Other' || item?.item === 'Other')
    if (findObj) {
      return true;
    }
    return false;
  }

  // download the treatment flow data pdf view
  public downLoadTreatmentFlow(signatureModal: UiModalComponent, medicalRecordModal?: UiModalComponent): void {
    if (this.signatureForm?.invalid) {
      this.signatureForm?.markAllAsTouched();
      return;
    }
    const params = this.signatureForm.value;
    params.appointmentId = this.appointmentId;
    // const action = `SignForm/createReferralForm/${this.appointmentId}?documentType=Referral`;
    // const action = `pdf/generateMedicalHistory?appointmentid=${this.appointmentId}`;
    const action = `pdf/generateMedicalHistory`;
    signatureModal?.hide();
    this.clearPad();
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService.downloadS3Image(action, params).subscribe((data: any) => {
      /*var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      a.download = `${this.appointmentId}.pdf`;
      document.body.appendChild(a);
      a.click();*/

      var file = new Blob([data], { type: 'application/pdf' });
      const blobToFile = new File([file], `${this.appointmentId}_Referral.pdf`, {
        type: file?.type,
      });
      var fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('medical-record-pdf') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      if (medicalRecordModal) {
        medicalRecordModal?.show();
      }
      this.isDocumentDownloaded = true;
      this.loadSpinner = false;
      this.downloadDocumentErrorMessage = false;

      this.PatientDetailsCardReload?.cardRefreshHide();
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.PatientDetailsCardReload?.cardRefreshHide();
      });
  }
  public deleteSelectedFile(index) {
    const files = this.documentsList;

    // delete files[index];
    this.documentsList.splice(index, 1);
  }

  public selectDocsToUpload(event: any): void {
    const files = event.target.files;
    this.documentsList = [...this.documentsList, ...Array.from(files)];
    this.clearFileUpload();
  }



  public additionalDocsUpload(uploadDocumentsModal: UiModalComponent): void {
    var files = this.documentsList;
    this.documentsList = files;
    var totalFiles = files.length;
    let requests = [];
    for (var i = 0; i < files.length; i++) {

      const file = files[i];
      //this.documentForm?.get('documentName')?.setValue(file?.name);
      this.documentForm?.get('multipartFile')?.setValue(file);
      var divId = "fileName" + i;

      const timestamp = this.getCurrentTimeStamp();
      const nameValue = file?.name;
      const documentType = this.documentForm?.get('documentType')?.value;

      const documentName = documentType + "_" + timestamp + "_" + nameValue;
      this.documentForm?.get('documentName')?.setValue(documentName);

      const formData = new FormData();
      const isFile = true;
      const fileType = file?.type;

      formData.append('idFile', this.documentForm?.value?.multipartFile);
      // const action = `appointment/registration/uploadAdditionalDocuments?appointmentId=${this.appointmentId}&documentName=${this.documentForm?.value?.documentName}&documentType=${this.documentForm?.value?.documentType}`;
      const action = `record/addDocument?appointmentId=${this.appointmentId}&documentName=${this.documentForm?.value?.documentName}&documentType=${this.documentForm?.value?.documentType}&isFile=${isFile}&fileType=${fileType}`;


      requests?.push(this.httpService?.makePostWithFormData(action, formData))


    }

    forkJoin(requests)
      .subscribe(data => {
        this.documentForm?.reset();
        uploadDocumentsModal?.hide();
        this.clearFileUpload();
        this.PatientDetailsCardReload?.cardRefreshHide();
        this.notifyText = totalFiles + ' Documents uploaded successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
		this.documentsList = [];
      });
  }
  public getCurrentTimeStamp() {
    const current = new Date();
    current.setHours(0)
    current.setMinutes(0)
    current.setSeconds(0)
    current.setMilliseconds(0)
    const timestamp = current.getTime();
    return timestamp;
  }
  public uploadAdditionalDocuments(divId, fileName): void {
    if (this.documentForm?.invalid) {
      this.documentForm?.markAllAsTouched();
      this.clearFileUpload();
      return;
    }
    // convert to binary for data
    const formData = new FormData();
    formData.append('file', this.documentForm?.value?.multipartFile);
    const action = `appointment/registration/uploadAdditionalDocuments?appointmentId=${this.appointmentId}&documentName=${this.documentForm?.value?.documentName}&documentType=${this.documentForm?.value?.documentType}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {

        // this.notifyText = 'Docuemnts uploaded successfully' || data?.msg;
        /*this.exceptionModal?.modalShow();
            this.documentForm?.reset();*/
      } else {
        this.notifyText = data?.msg;
        this.isSuccessNotify = false;
        //this.exceptionModal?.modalShow();
      }

      // document.getElementById(divId).innerHTML(this.notifyText);
      /* this.clearFileUpload();
       this.PatientDetailsCardReload?.cardRefreshHide();*/
    },
      (error) => {
        this.notifyText = error?.msg;
        this.isSuccessNotify = false;
        //document.getElementById(divId).innerHTML(this.notifyText);
        /*this.exceptionModal?.modalShow();
        this.PatientDetailsCardReload?.cardRefreshHide();
        this.clearFileUpload();*/
      });

  }

  public clearFileUpload(): void {
    this.additionalDocs.nativeElement.value = "";
  }

  public saveFollowUp(): void {
    if (this.finalCheckListForm?.invalid) {
      this.finalCheckListForm?.markAllAsTouched();
      return;
    }
    const action = `treatmentFlow/saveFollowUp?appointmentId=${this.appointmentId}`;
    const params = this.finalCheckListForm?.value?.followUp;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {

    },
      error => {

      });
  }

  public checkFollowUp(event: boolean): void {
    this.finalCheckListForm?.get('followUp')?.get('followUp')?.setValue(event);
    this.saveFollowUp();
  }

  public openPayment(): void {
    // this.payComp?.enableCardPay();
    this.payComp?.openModal(this.patientDetailsInfo, true);
  }

  // get all the past visits medicatons and allergies with userInfoId
  private getAllPastVisitsMedications(): void {
    if (!this.patientDetailsInfo?.userInfoId) {
      return;
    }
    //const action = `appointment/history/getPastMedicationDetails?userInfoId=${this.patientDetailsInfo?.userInfoId}`;
    // const action = `common/getChartInfo?userInfoId=${this.patientDetailsInfo?.userInfoId}&chartModuleName=Medications`;
    const action = `common/getMedicationDetails?userInfoId=${this.patientDetailsInfo?.userInfoId}&filterCriterion=Active&current=true`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.pastVisitMedications = data?.orderRxes || [];
      this.pastVisitMedications?.sort((a: any, b: any) => {
        const bDate: any = new Date(b?.creation_date);
        const aDate: any = new Date(a?.creation_date);
        return bDate - aDate;
      });
      this.loadSpinner = false;
    }, error => {
      this.loadSpinner = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
    });
  };

  public statusCodeDesc(charStatus: string): string {
    return this.httpService?.statusCodeDesc(charStatus);
  }

  public openSignatureModal(signatureModal: UiModalComponent): void {
    this.signatureForm.reset();

    const providername = this.providerFirstName + " " + this.providerLastName;


    const canvas = <HTMLCanvasElement>document.getElementById('textCanvas');
    const tCtx = canvas.getContext('2d');

    /*var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://fonts.googleapis.com/css?family=Open+Sans|Rock+Salt|Shadows+Into+Light|Cedarville+Cursive';
    document.getElementsByTagName('head')[0].appendChild(link);*/

    //tCtx.canvas.width = tCtx.measureText(providername).width*2;
    tCtx.font = 'bold 1.5em "Rock Salt",cursive';
    tCtx.textBaseline = 'top';
    tCtx.fillText(providername, 0, 100);


    this.signatureImg = tCtx.canvas.toDataURL();
    this.signatureForm?.get('signature')?.setValue(this.signatureImg);
    signatureModal?.show();

  }

  public startDrawing(event: Event) {

  }

  public moved(event: Event) {

  }
  clearPad() {
    //this.signaturePad.clear();
    const canvas = <HTMLCanvasElement>document.getElementById('textCanvas');
    const tCtx = canvas.getContext('2d');
    tCtx.clearRect(0, 0, canvas.width, canvas.height);

    this.signatureImg = '';
    this.signatureImgString = '';
    this.signatureForm?.get('signature')?.setValue('');


  }

  savePad() {
    if (this.signaturePad.isEmpty()) {
      return;
    }
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.signatureImgString = "Signature saved"
    this.signatureForm?.get('signature')?.setValue(this.signatureImg);
  }

  public getDocumenttypeList() {
    // const collectionType = event.value;
    const collectionType = 'Encounter';
    const action = `record/listDocumentTypes?collectionType=${collectionType}`;
    this.addDocumentTypes = [];
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
       // console.log(data);
        this.addDocumentTypes = data?.responseObject;
        this.addDocumentTypes?.forEach((formList: any, index: number) => {
          
           if (formList === 'Claim Journal') {
                 this.addDocumentTypes = this.addDocumentTypes?.filter((listItem: any) => listItem !== 'Claim Journal') || [];
               }
          })

      }

    },
      error => {
        this.notifyText = error?.message || error?.msg || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.loadSpinner = false;
        this.exceptionModal?.modalShow();
      })
  }

  public uploadFileSelect(event) {

    if (event.target.files.length > 0) {
      const filesArr = event.target.files;
      this.documentsList = [...this.documentsList, ...Array.from(filesArr)];
      const files = event.target.files[0];
      const fileSize = files.size / 1024 / 1024;

      //alert(files.type);
      if (fileSize <= 25 && (files.type === 'application/pdf' || files.type === 'image/png' || files.type === 'image/jpeg')) {
        this.documentForm?.get('documentName')?.setValue(files.name);
        this.documentForm?.get('multipartFile')?.setValue(files);
        // alert(files.type);
        this.UploadedFiletype = files.type;
        //this.documentsList = files;
      }

      else {
        this.documentForm?.get('multipartFile')?.setValue(null);
        this.additionalDocs.nativeElement.value = null;
        this.notifyText = "Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded ";
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        return;
      }

    }
  }

  public uploadDocuments(documentUpload?: UiModalComponent): void {

    if (this.documentForm?.invalid) {
      this.documentForm?.markAllAsTouched();
      return;
    }
    const formData = new FormData();
    formData.append('idFile', this.documentForm?.value?.multipartFile);

    const timestamp = this.getCurrentTimeStamp();
    const documentType = this.documentForm?.get('documentType')?.value;
    const nameValue = this.documentForm?.get('documentName')?.value;

    const isFile = true;
    const documentName = documentType + "_" + timestamp + "_" + nameValue;
    this.documentForm?.get('documentName')?.setValue(documentName);
    let actionAppend = '';
    const action = `record/addDocument?appointmentId=${this.appointmentId}&documentName=${this.documentForm?.value?.documentName}&documentType=${this.documentForm?.value?.documentType}&isFile=${isFile}&fileType=${this.UploadedFiletype}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      this.PatientDetailsCardReload?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {

        documentUpload.hide();
        this.notifyText = data?.msg || data?.message || 'Document uploaded successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();

      } else {
        this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }

    },
      (error) => {
        this.PatientDetailsCardReload?.cardRefreshHide();

        this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();

      });

  }

  // get LAB / RAD Order details
  public labRadOrderDetails(): void {
    const action = `changeHealth/labOrder/${this.appointmentId}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action, '').subscribe(
      (data: any) => {
        if (data?.status === 'SUCCESS') {
          const labOrder = data?.responseObject?.order || [];
          this.alllabRadOrderDetails = labOrder || [];
        } else {
          this.notifyText =
            data?.message || AppConstantsListConfig?.uiErrorException;
            this.isSuccessNotify = false;
          // this.exceptionModal.modalShow();
        }
        this.loadSpinner = false;
      },
      (error) => {
        this.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
        // this.exceptionModal.modalShow();
        this.loadSpinner = false;
      }
    );
  }

  // Due to Payments Recipt Issue, getting src url here and rendering again
  public renderRecieptUrl(payInvoiceObj: any, finalCheckListPayRecieptModal?: UiModalComponent): void {
    if (payInvoiceObj && payInvoiceObj?.payInvoiceModal) {
      payInvoiceObj?.payInvoiceModal?.hide();
    }
    finalCheckListPayRecieptModal?.show();
    const iframeEle = document?.getElementById('final-check-list-common-new-payment-reciept-embed') as HTMLElement;
    if (iframeEle) {
      iframeEle.setAttribute('src', payInvoiceObj?.payFileUrl);
    }
    this.loadSpinner = false;
  }

  public loadPaymentInfo() {
    this.billingcodesComp.getBillingCodesList();
    this.paymentInfoReload?.emit(true);
  }

  public updateAmountPaid(){
    this.paymentInfoReload?.emit(true);
  }


}
