import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, SimpleChange, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CancelAppointmentComponent } from 'src/app/theme/shared/hop-common-components/cancel-appointment/cancel-appointment.component';
import { ReScheduleAppointmentComponent } from 'src/app/theme/shared/hop-common-components/re-schedule-appointment/re-schedule-appointment.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { Router } from '@angular/router';
import { EligibilityCheckComponent } from 'src/app/theme/shared/hop-common-components/eligibility-check/eligibility-check.component';
import { SendSmsNotifyComponent } from 'src/app/theme/shared/hop-common-components/send-sms-notify/send-sms-notify.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { UploadAdditionalDocumentsComponent } from '../upload-additional-documents/upload-additional-documents.component';
import { NewTaskComponent } from 'src/app/theme/shared/hop-common-components/new-task/new-task.component';

@Component({
  selector: 'app-future-appointments-for-user',
  templateUrl: './future-appointments-for-user.component.html',
  styleUrls: ['./future-appointments-for-user.component.scss']
})
export class FutureAppointmentsForUserComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() userInfoId: any;
  public dtOptionsFutureAppnt: DataTables.Settings = {};
  public dtTriggerFutureAppnt: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElementFutureAppnt: DataTableDirective;
  public allFutureAppointments = [];
  public loginDetails: any;
  @ViewChild('futureAppointments') futureAppointments: CardComponent;
  @ViewChild(UiModalComponent) modalComp: UiModalComponent;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;

  public futureAppointmentForm: FormGroup;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ReScheduleAppointmentComponent) reScheduleComp: ReScheduleAppointmentComponent;
  @ViewChild(CancelAppointmentComponent) cancelAppntComp: CancelAppointmentComponent;
  public isShownActions: boolean;
  public minAppointmentDate: Date;
  eligibilityAppointment: any;
  @ViewChild(EligibilityCheckComponent) eligibilityComp: EligibilityCheckComponent;
  public waitTimeDetails: any;
  public lateNotifyPatient: any;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  public minEndDate: Date;
  @ViewChild(UploadAdditionalDocumentsComponent) uploadDocument: UploadAdditionalDocumentsComponent;
  @ViewChild(NewTaskComponent) newTakComp: NewTaskComponent;
  

  constructor(private httpService: HttpService, private router: Router) { }

  ngOnInit(): void {
    this.dtOptionsFutureAppnt = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[2, "asc"]],
      columnDefs: [{ targets: [2], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
        },
    };
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.minAppointmentDate = this.httpService.convertDate(new Date());
    this.waitTimeDetails = JSON?.parse(sessionStorage?.getItem('waitTimeDetails'));
    this.minEndDate = this.httpService.convertDate(new Date());
  }

  get isShowHideElements(): any {
    const allItems = JSON.parse(sessionStorage?.getItem('allProvideLoginNavItems'));
    const getScreenItem = allItems?.find(item => item?.coreFeature === 'Future Appointments');
    return getScreenItem?.childFeature || {};
  }

  // customize the Appointment Date time
  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.userInfoId) {
        this.getFutureAppointmentsByUser();
      }
    }
  }

  ngAfterViewInit(): void {
  }

  // get future appointments by user info id
  public getFutureAppointmentsByUser(): void {
  
  
			
    const action = `appointment/registration/getCurrentFutureApptforUser?userInfoId=${this.userInfoId}`;
    this.rerender();
    this.httpService?.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status == 'SUCCESS') {
        this.allFutureAppointments = data?.resultList || [];
        if (this.allFutureAppointments && this.allFutureAppointments.length === 0) {
          // this.notifyText = 'No Appointments are available for selected date';
          // notifyModal.show();
        }
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.dtTriggerFutureAppnt.next();
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal.modalShow();
      this.dtTriggerFutureAppnt.next();
    });
  }


  // this is for table render, we need to destroy everytime to update the new data
  public rerender(): void {
    this.dtElementFutureAppnt?.dtInstance?.then((dtInstanceFutureAppnt: DataTables.Api) => {
      dtInstanceFutureAppnt?.destroy();
      //  this.dtTriggerFutureAppnt.next(); 
    });
  }

  // appointment status showing
  public getStatus(status: string, appointment: any): string {
    return this.httpService.getStatus(status, appointment);
  }

  // re schedule appointment 
  public reScheduleAppointment(appointment: any): void {
    this.reScheduleComp.openSetAppointment(appointment);
  }

  // Cancel Apppintment commom compoent open
  public cancelAppointment(appointment: any): void {
    this.cancelAppntComp.openCancelAppointment(appointment);
  }

  // refetching the updated reschedule or cancelled appointments
  public refresh(): void {
    this.allFutureAppointments = [];
    // this.rerender();
    if (this.userInfoId) {
      this.getFutureAppointmentsByUser();
    }
    // const dateValue = this.futureAppointmentForm.get('DATE').value;
  }

  public editAppointment(appointment: any): void {
    if (appointment?.signOff) {
      this.router?.navigate([`/hospital/claim-submission-new/${appointment?.id}`]);
      return;
    }
    this.router?.navigate([`hospital/hosp-updatepatientdetails/${appointment?.id}`]);
    sessionStorage?.setItem('futureAppointmentEdit', JSON?.stringify(true));
  }

  // check eligibility
  public checkEligibility(appointment: any): void {
    const getPrimaryInsurance = appointment?.insuranceDetails?.find(item => item?.priority === 'Primary');
    if (!getPrimaryInsurance || !getPrimaryInsurance?.memberId || !getPrimaryInsurance?.payerId || !getPrimaryInsurance?.subscriberlastname || !getPrimaryInsurance?.subscriberfirstname) {
      this.notifyText = 'Insurance details are unavailable for this Appointment.';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      return;
    }
    this.futureAppointments?.cardRefreshShow();
    this.eligibilityAppointment = appointment;
    this.eligibilityComp?.checkEligibilityPatient(getPrimaryInsurance, appointment);
  }

  // send Notifications
  public lateNotify(patient: any): void {
    this.lateNotifyPatient = patient || {};
    this.sendNotifyComp?.showNotifyModal(patient);
  }

  // open Upload Documents popup
  public openUploadDocument(user: any): void {
    this.uploadDocument?.openUploadDocument(user, 'Encounter', '');
  }
  
  public viewAppointment(id: any) {
    this.httpService.clearTreatmentFlowSession();
    // this.router.navigate([`/hospital/hosp-patientdetails/${id}`]);
    this.router?.navigate([`/hospital/past-visit/${id}`]);
  }
  
   public openTaskModal(appointment: any): void {
   
  
  this.newTakComp?.showTaskModal(appointment);
  
  }
 

  // to destroy the subscription
  ngOnDestroy(): void {
    this.dtTriggerFutureAppnt.unsubscribe();
  }


}
