<div class="row add-appointment-sectons" [formGroup]="demographicForm">
    <div class="col-sm-12">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="first-name">First Name <span class="text-danger">*</span></label>
                    <input class="form-control input-text" type="text" placeholder="First Name"
                        id="patient-demo-info-first-name" formControlName="FIRST_NAME" />
                    <span class="text-danger"
                        *ngIf="demographicForm.get('FIRST_NAME').errors?.required && demographicForm.get('FIRST_NAME').touched">First
                        Name is Required</span>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('FIRST_NAME').invalid && !demographicForm.get('FIRST_NAME').errors?.required">
                        Please enter a valid First Name
                    </span>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="Middle-name">Middle Name</label>
                    <input class="form-control input-text" type="text" placeholder="Middle Name"
                        id="patient-demo-info-middle-name" formControlName="MIDDLE_NAME" />
                    <span class="text-danger"
                        *ngIf="demographicForm.get('MIDDLE_NAME').errors?.required && demographicForm.get('MIDDLE_NAME').touched">Middle
                        Name is Required</span>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('MIDDLE_NAME').invalid && !demographicForm.get('MIDDLE_NAME').errors?.required">Please
                        enter a valid Middle Name</span>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">Last Name <span class="text-danger">*</span></label>
                    <input class="form-control input-text" type="text" placeholder="Last Name"
                        id="patient-demo-info-last-name" formControlName="LAST_NAME" />
                    <span class="text-danger"
                        *ngIf="demographicForm.get('LAST_NAME').errors?.required && demographicForm.get('LAST_NAME').touched">Last
                        Name is Required</span>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('LAST_NAME').invalid && !demographicForm.get('LAST_NAME').errors?.required">Please
                        enter a valid Last Name</span>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
                <!-- onkeydown="return false" -->
                <div class="form-group">
                    <label for="last-name">Date of Birth<span class="text-danger">*</span></label>
                    <input type="date" (change)="dobChange($event)" name="datemax" [min]="minDOB"
                        [max]="maxAppointmentDate" id="patient-demo-info-dob" class="form-control input-text"
                        value="this.patientDetails.dob" formControlName="DATE_OF_BIRTH"
                        (keyup.enter)="validateManualEnterdob($event)" (mouseout)="validateManualEnterdob($event)"
                        (blur)="validateManualEnterdob($event)">
                    <span class="text-danger"
                        *ngIf="demographicForm.get('DATE_OF_BIRTH').errors?.required && demographicForm.get('DATE_OF_BIRTH').touched">Date
                        of Birth is Required</span>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('DATE_OF_BIRTH').errors?.invalidDate && demographicForm.get('DATE_OF_BIRTH').touched">Please
                        Enter Valid Date Of Birth</span>
                    <!-- <div *ngIf="demographicForm.get('MATE_DATE_OF_BIRTH').errors?.invalidDate">Invalid date of birth for mate.</div> -->
                </div>
            </div>

        </div>
        <div class="row">

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="first-name">Gender <span class="text-danger">*</span></label>
                    <ng-select placeholder="Choose Gender" id="patient-demo-info-gender" [items]="genderList"
                        formControlName="GENDER" bindLabel="desc" bindValue="value"></ng-select>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('GENDER').errors?.required && demographicForm.get('GENDER').touched">Gender
                        is Required</span>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="MaritalStatus">Marital Status <span *ngIf="maritaMandatory"
                            class="text-danger">*</span> </label>
                    <div class="input-group mb-0">
                        <ng-select placeholder="Choose Marital Status" id="patient-demo-info-marital-status"
                            [items]="maritalStatusList" formControlName="MARITAL_STATUS" bindLabel="desc"
                            bindValue="value"></ng-select>
                    </div>
                    <span class="text-danger"
                        *ngIf="maritaMandatory && (demographicForm.get('MARITAL_STATUS').errors?.required && demographicForm.get('MARITAL_STATUS').touched)">Marital
                        Status is Required</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">Race <span *ngIf="validationRequired" class="text-danger">*</span></label>
                    <div class="input-group mb-0">

                        <ng-select placeholder="Choose Race" id="patient-demo-info-race" [items]="raceList"
                            formControlName="RACE" bindLabel="desc" bindValue="value"></ng-select>

                    </div>
                    <span class="text-danger"
                        *ngIf="validationRequired && (demographicForm.get('RACE').errors?.required && demographicForm.get('RACE').touched)">Race
                        is Required</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">Ethnicity <span *ngIf="validationRequired"
                            class="text-danger">*</span></label>
                    <div class="input-group mb-0">

                        <ng-select placeholder="Choose Ethnicity" id="patient-demo-info-ethnicity"
                            [items]="ethnicityList" formControlName="ETHNICITY" bindLabel="desc"
                            bindValue="value"></ng-select>
                    </div>
                    <span class="text-danger"
                        *ngIf="validationRequired && (demographicForm.get('ETHNICITY').errors?.required && demographicForm.get('ETHNICITY').touched)">Ethnicity
                        is Required</span>
                </div>
            </div>

        </div>

        <h5 class="mb-2">Contact Details </h5>
        <hr />

        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">Primary Phone Type <span class="text-danger">*</span></label>
                    <div class="input-group mb-0">

                        <ng-select placeholder="Choose Primary Phone Type" id="patient-demo-info-primary-phone-type"
                            [items]="phoneTypeList" formControlName="PHONE_TYPE" bindLabel="desc"
                            bindValue="value"></ng-select>
                    </div>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('PHONE_TYPE').errors?.required && demographicForm.get('PHONE_TYPE').touched">Primary
                        Phone Type is Required</span>
                </div>
            </div>
            <!-- minlength="10" maxlength="10" -->

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">Primary Phone number <span class="text-danger">*</span></label>
                    <input class="form-control input-text" type="text" mask="(000) 000-0000"
                        placeholder="(xxx) xxx-xxxx" id="patient-demo-info-primary-phone-number"
                        formControlName="PHONE_NUMBER" />
                    <span class="text-danger"
                        *ngIf="demographicForm.get('PHONE_NUMBER').errors?.required && demographicForm.get('PHONE_NUMBER').touched">Primary
                        Phone number is Required</span>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('PHONE_NUMBER').invalid && !demographicForm.get('PHONE_NUMBER').errors?.required">Please
                        enter a valid Phone number</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">Alternate Phone Type <span class="text-danger"
                            *ngIf="checkAlternatePhoneNUmber">*</span></label>
                    <div class="input-group mb-0">

                        <ng-select placeholder="Choose Alternate Phone Type" id="patient-demo-info-alt-phone-type"
                            (change)="changeAlternatePhoneType()" [items]="phoneTypeList"
                            formControlName="alternativePhoneType" bindLabel="desc" bindValue="value"></ng-select>

                        <span class="text-danger"
                            *ngIf="demographicForm.get('alternativePhoneType').errors?.required && demographicForm.get('alternativePhoneType').touched">Alternate
                            Phone Type is Required</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">Alternate Phone number <span class="text-danger"
                            *ngIf="demographicForm?.get('alternativePhoneType')?.value === true || this.demographicForm?.get('alternativePhoneType')?.value === false">*</span></label>
                    <input class="form-control input-text" type="text" mask="(000) 000-0000"
                        placeholder="(xxx) xxx-xxxx" id="patient-demo-info-alt-ph-number"
                        formControlName="ALTERNATE_PHONE_NUMBER" />
                    <span class="text-danger"
                        *ngIf="demographicForm.get('ALTERNATE_PHONE_NUMBER').errors?.required && demographicForm.get('ALTERNATE_PHONE_NUMBER').touched">Phone
                        number is Required beacuse you have selected alternate phone type</span>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('ALTERNATE_PHONE_NUMBER').invalid && !demographicForm.get('ALTERNATE_PHONE_NUMBER').errors?.required">Please
                        enter a valid Phone number</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="Email">Email </label>
                    <input class="form-control input-text" type="email" placeholder="Email" id="patient-demo-info-email"
                        formControlName="EMAIL" />
                    <!-- <span class="text-danger"
                        *ngIf="demographicForm.get('EMAIL').errors?.required && demographicForm.get('EMAIL').touched">Email
                        is Required</span> -->
                    <span class="text-danger"
                        *ngIf="demographicForm.get('EMAIL').invalid && !demographicForm.get('EMAIL').errors?.required">Please
                        enter a valid Email</span>
                </div>
            </div>
        </div>

        <h5 class="mb-2">Address Details </h5>
        <hr />
        <div class="row patient-address-details">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">Street <span class="text-danger">*</span></label>
                    <input type="text" class="form-control input-text" autocorrect="off" autocapitalize="off"
                        spellcheck="off" #search formControlName="ADDRESS" id="common-address-street"
                        placeholder="Enter Street">
                    <span class="text-danger"
                        *ngIf="demographicForm.get('ADDRESS').errors?.required && demographicForm.get('ADDRESS').touched">Street
                        is Required</span>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('ADDRESS').invalid && !demographicForm.get('ADDRESS').errors?.required">Please
                        enter a valid Street</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">City <span class="text-danger">*</span></label>
                    <input class="form-control input-text" type="text" placeholder="City" id="common-address-city"
                        formControlName="CITY" />
                    <span class="text-danger"
                        *ngIf="demographicForm?.get('CITY')?.errors?.required && demographicForm?.get('CITY')?.touched">City
                        is Required</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">State <span class="text-danger">*</span></label>
                    <input class="form-control input-text" type="text" placeholder="State" id="common-address-state"
                        formControlName="STATE" />
                    <span class="text-danger"
                        *ngIf="demographicForm?.get('STATE')?.errors?.required && demographicForm?.get('STATE')?.touched">State
                        is Required</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="last-name">ZIP Code <span class="text-danger">*</span></label>
                    <input class="form-control input-text" minlength="3" maxlength="5" type="text"
                        placeholder="ZIP Code" id="common-address-zipcode" formControlName="ZIPCODE"
                        (blur)="addPrefixZipCode(demographicForm?.get('ZIPCODE')?.value,'valid')" />
                    <span class="text-danger"
                        *ngIf="demographicForm.get('ZIPCODE').errors?.required && demographicForm.get('ZIPCODE').touched">ZIP
                        Code
                        is Required</span>
                    <span class="text-danger"
                        *ngIf="demographicForm.get('ZIPCODE').invalid && !demographicForm.get('ZIPCODE').errors?.required">Please
                        enter a valid ZIP</span>
                </div>
            </div>
        </div>

        <!-- <h5 class="mb-2">Emergency Contact Details </h5>
        <hr /> -->

        <app-emergency-contact-details [emergencyDetailsForm]="demographicForm"></app-emergency-contact-details>

        <!-- Preferred Pharmacy -->
        <h5 class="mb-2">Preferred Pharmacy</h5>
        <hr>

        <app-preferred-pharmacy [pharmacyFormGroup]="demographicForm?.get('pharmacy')"></app-preferred-pharmacy>

         <h5 class="mb-2">Preferred Language</h5>
        <hr>
		<div class="row">
		 <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="form-group">
                   <label for="last-name">Language for communication </label>
					
                    <div class="input-group mb-0">

                        <ng-select placeholder="Choose Language" id="preferred-language"
                             [items]="languageList" [clearable]="false"
                            formControlName="preferredLanguage" bindLabel="desc" bindValue="value"></ng-select>

                        
                    </div>
                </div>
            </div>
		</div>
        <br>
        <h5 class="mb-2">Consent For Digital Delivery</h5>
        <hr>
        <div class="form-group">
            <!-- <label>Please select one of the applicable Options <span
                    class="required">*</span></label><br /> -->
            <ng-container>
                <div class="form-check">
                    <input class="form-check-input" style="margin-right: 8px;  " type="checkbox" id="selectCheckbox"
                        [attr.disabled]="demographicForm?.get('consent_disable')?.value? 'true' : null"
                        formControlName="patient_consent">
                    <label class="form-check-label" for="createAccount">
                        Consent For Digital Delivery
                    </label><br>
                    <label style="margin-left: 10px;">(All communications about Labs, Radiology Orders, Results,
                        Clinical Documentation, and Pay statements will be delivered through secure Patient portals Via
                        SMS)</label>

                </div>
            </ng-container><br />
        </div>

    </div>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>