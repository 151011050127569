import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
} from '@angular/core';
import * as moment from 'moment';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http.service';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { ExistingPatientSearchComponent } from '../existing-patient-search/existing-patient-search.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';

@Component({
  selector: 'app-hosp-add-appnt',
  templateUrl: './hosp-add-appnt.component.html',
  styleUrls: ['./hosp-add-appnt.component.scss'],
})
export class HospAddAppntComponent implements OnInit, OnChanges {
  public adPatientAllDoctors: any[];
  @Input() addNewPatientForm: FormGroup;
  public minAppointmentDate: Date;
  public loginDetails: any;
  public addPatientPurposeVisit = [];
  public waitTimeDetails: any;
  public isUrgentCare: any;

  @Input() demographicForm: FormGroup;
  @Input() exceptionModal: ExceptionModalComponent;

  @Output() onSlotDateChange = new EventEmitter<boolean>();
  @Output() inQueueSlot = new EventEmitter<boolean>();
  @Output() patientTypeChange = new EventEmitter<string>();

  public addPatientTypeOfPatient = [
    { value: 'Old', desc: 'Established Patient' },
    { value: 'New', desc: 'New Patient' },
  ];
  public contactSearchResults: any[];
  public existingContactSearchClick: boolean;
  public notifyText: string;
  public isSuccessNotify: boolean;
  public addPatientTypeOfService = [
    { value: 'Regular Visit', desc: 'Regular Visit' },
    { value: 'Tele-Health', desc: 'Tele-Health' },
  ];

  public addPatientScheduleType = [
    { value: 'In Queue', desc: 'Walk-In' },
    { value: 'Future Appointment', desc: 'Future Appointment' },
  ];
  public getAppointmentSlotsAvailable = [];
  public checkCovidVisit: boolean;
  public covidVaccineDosage = [
    { value: '1', desc: 'First Dosage' },
    { value: '2', desc: 'Second Dosage' },
  ];
  public maxAppointmentDate: Date;
  @ViewChild(ExistingPatientSearchComponent) private existingPatientSearchComp: ExistingPatientSearchComponent;
  @Input() selectedPatientFlowBoard: any;
  isNoAppintmentReg: string;
  public providerWorkFlow: any;
  @Input() insuranceForm: FormGroup;
  @ViewChild('notifyTextModal')
  notifyTextModal: UiModalComponent;
  public isFutureAppt: boolean;
  public chiefCompNode: any[] = [];
  public selectedChiefComp = [];
  public additionalNotesSelected: null;
  public otherComplaintsSelected: null;
  public chiefCompArray: any[] = [];
  public selectedCCValues: any[] = [];


  constructor(public httpService: HttpService, public router: Router, public _fb: FormBuilder) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.isNoAppintmentReg = sessionStorage?.getItem('isNoAppnt');
    this.getWaitTime(this.loginDetails && this.loginDetails.facilityId);
  }

  ngOnInit(): void {
    this.minAppointmentDate = this.httpService.convertDate(new Date());
    this.maxAppointmentDate = this.httpService.convertDate(new Date());

    this.newRegistrationWithNoAppointment();
	this.getChiefComplaints();
	sessionStorage?.removeItem('allChiefComplaints');
	sessionStorage?.removeItem('additionalChiefComplaints');
	sessionStorage?.removeItem('otherChiefComplaints');
  }

  public newRegistrationWithNoAppointment(): void {
    if (this.isNoAppintmentReg && this.isNoAppintmentReg === 'Yes') {
      this.addPatientTypeOfPatient = [
        {
          value: 'New Registration With no Appointment',
          desc: 'New Registration With no Appointment',
        },
      ];
      // new registration with No Appointment
      this.addNewPatientForm
        ?.get('PATIENT_TYPE')
        ?.setValue('New Registration With no Appointment');
      this.addNewPatientForm?.get('SERVICE_TYPE')?.setValue('Regular Visit');
      this.addNewPatientForm?.get('SCHEDULE_TYPE')?.setValue('In Queue');
      // disable the fields
      this.addNewPatientForm?.get('PURPOSE_OF_VISIT')?.disable();
      this.addNewPatientForm?.get('DOCTOR')?.disable();
      this.addNewPatientForm?.get('SERVICE_TYPE')?.disable();
      this.addNewPatientForm?.get('SCHEDULE_TYPE')?.disable();
    }
  }

  ngOnChanges(): void {
    if (this.selectedPatientFlowBoard) {
      const contact = this.selectedPatientFlowBoard;
      if (!this.isNoAppintmentReg) {
        this.addNewPatientForm.controls['PATIENT_TYPE'].setValue('Old');
        this.changePatientType();
      }
      const name = contact?.middleName
        ? ` ${contact?.lastName}, ${contact?.firstName} ${contact?.middleName}`
        : `${contact?.lastName}, ${contact?.firstName}`;
      this.addNewPatientForm
        ?.get('EXISTING_PATIENT_SEARCH')
        ?.setValue(name || null);
      this.existingPatientSearchComp?.patchAppointmentForms(contact);
    }
  }

  // get doctors list based on purpose of visit
  public getDoctorsList(): void {
    if(this.addNewPatientForm?.get('PATIENT_TYPE')?.invalid) {
      this.addNewPatientForm?.get('PATIENT_TYPE')?.markAllAsTouched();
      this.addNewPatientForm?.get('PURPOSE_OF_VISIT')?.setValue(null);
      return;
    }
    if(!this.addNewPatientForm?.get('PURPOSE_OF_VISIT')?.value) {
      this.adPatientAllDoctors = [];
      return;
    }
    this.checkPurposeOfVisit();
    this.getProviderWorkFlow();
    this.onSlotDateChange.emit(true);
    this.adPatientAllDoctors = [];
    this.addNewPatientForm.controls['DOCTOR'].setValue(null);
    const purposeOfVisit = this.addNewPatientForm.value.PURPOSE_OF_VISIT || '';
    this.selectChiefComplaintByPOV(purposeOfVisit);
    const action = `npi/getvalidNPIforvisit?facilityId=${this.loginDetails.facilityId}&purposeOfVisit=${purposeOfVisit}&newPatient=${ this.addNewPatientForm.value.PATIENT_TYPE === 'New' ? true : false}`;
    this.httpService.makeGetRequest(action, '').subscribe(
      (data: any) => {
        // to get first name and last name of doctors
        const doctors =
          JSON?.parse(
            sessionStorage?.getItem('waitTimeDetails')
          )?.eligibilityDetails?.doctor?.map((doctorItem: any) => {
            return {
              firstName:
                doctorItem?.basic?.authorized_official_first_name || '',
              lastName: doctorItem?.basic?.authorized_official_last_name || '',
              npi: doctorItem?.number,
              fullName: `${doctorItem?.basic?.authorized_official_first_name || ''
                } ${doctorItem?.basic?.authorized_official_last_name || ''}`,
            };
          }) || [];

        data?.npiDoctorList?.forEach((item) => {
          const getDoctor = doctors?.find(
            (subDoc) =>
              subDoc?.npi === item.npi &&
              item?.doctorName?.includes(subDoc?.firstName || subDoc?.lastName)
          );
          item.firstName = getDoctor?.firstName;
          item.lastName = getDoctor?.lastName;
        });

        this.adPatientAllDoctors = data?.npiDoctorList || [];       
        this.onSlotDateChange.emit(false);
        if(this.adPatientAllDoctors.length == 1){
          this.addNewPatientForm?.get('DOCTOR')?.setValue(this.adPatientAllDoctors[0]?.npi);
          this.doctorChange(this.adPatientAllDoctors[0]);
        }
      },
      (error) => {
        this.onSlotDateChange.emit(false);
        this.exceptionModal.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
      }
    );
  }

  public getProviderWorkFlow(): void {
    const purposeOfVisit = this.addNewPatientForm.value.PURPOSE_OF_VISIT;
    const action = `providerInfo/getProviderConfig`;
    let params: any = {
      callerType: '',
      facilityId: this.loginDetails?.facilityId,
      futureappt: true,
      insurance: true,
      purposeOfVisit: purposeOfVisit,
    };
    this.onSlotDateChange.emit(true);
    this.httpService.makeRequestWithAction(action, params).subscribe(
      (data: any) => {
        if (data?.body?.status === 'SUCCESS') {
          this.providerWorkFlow = data?.body?.providerworkflow || null;
          if (this.providerWorkFlow?.regular === true) {
            this.addNewPatientForm?.get('SERVICE_TYPE')?.reset();
            this.addNewPatientForm?.get('SERVICE_TYPE')?.disable();
            this.addNewPatientForm
              ?.get('SERVICE_TYPE')
              ?.setValue('Regular Visit');
            this.addNewPatientForm
              ?.get('isRegularVisit')
              ?.setValue(this.providerWorkFlow?.regular);
            this.checkCovidVisit = true;
          } else {
            this.addNewPatientForm?.get('SERVICE_TYPE')?.enable();
            this.addNewPatientForm?.get('SERVICE_TYPE')?.reset();
            this.addNewPatientForm
              ?.get('isRegularVisit')
              ?.setValue(this.providerWorkFlow?.regular);
            this.checkCovidVisit = false;
          }
          this.addNewPatientForm
            ?.get('consentForm')
            ?.setValue(this.providerWorkFlow?.consentForms);
          this.addNewPatientForm
            ?.get('appointmentQuestionnaire')
            ?.setValue(this.providerWorkFlow?.patientQuestions);
        } else {
          this.notifyText =
            data?.message || AppConstantsListConfig?.uiErrorException;
            this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.onSlotDateChange.emit(false);
      },
      (error) => {
        this.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.onSlotDateChange.emit(false);
      }
    );
  }

  public getWaitTime(facilityId: any): void {
    const action = `waitTime/`;
    this.httpService.makeGetRequest(action, facilityId).subscribe(
      (data: any) => {
        this.waitTimeDetails = data;
        this.addPatientPurposeVisit = (data && data.purposeOfVisit) || [];
        this.isUrgentCare = data && data.urgentcare;
        // below condition is for new registration with no appointment
        if (this.isNoAppintmentReg && this.isNoAppintmentReg === 'Yes') {
          this.addPatientPurposeVisit = [
            'New Registration With no Appointment',
          ];
          this.addNewPatientForm
            ?.get('PURPOSE_OF_VISIT')
            ?.setValue('New Registration With no Appointment');
          const doctor = {
            doctorName: this.doctorNameWithNpi(
              parseInt(this.loginDetails?.providerNPI)
            ),
            npi: parseInt(this.loginDetails?.providerNPI),
          };
          this.adPatientAllDoctors = [doctor];
          this.addNewPatientForm
            ?.get('DOCTOR')
            ?.setValue(parseInt(this.loginDetails?.providerNPI) || null);
        }
        if (this.isUrgentCare) {
          this.addNewPatientForm.get('DOCTOR').setValidators([]);
          this.addNewPatientForm.get('PATIENT_TYPE').setValidators([]);
          this.addNewPatientForm.updateValueAndValidity();
        }
        // this.adPatientAllDoctors = (data && data.eligibilityDetails && data.eligibilityDetails.doctor) || [];
        // this.addPatientTypeOfService = (data && data.typeOfService) || [];
      },
      (error) => {
        this.exceptionModal.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
      }
    );
  }

  // get doctor name from list
  public doctorNameWithNpi(npi: number): string {
    const doctor = this.waitTimeDetails?.eligibilityDetails?.doctor?.find(
      (item: any) => {
        return npi === item?.number;
      }
    );

    return (
      `${doctor?.basic?.authorized_official_first_name || ''} ${doctor?.basic?.authorized_official_last_name || ''
      }` || '--'
    );
  }

  // check existing patient
  public changePatientType(isChangeFlag?: string): void {
    sessionStorage.removeItem('insuranceDetails');
    if(isChangeFlag) {
      this.addNewPatientForm?.get('PURPOSE_OF_VISIT')?.setValue(null);
      this.addNewPatientForm?.get('DOCTOR')?.setValue(null);
      this.adPatientAllDoctors = [];
    }
    this.demographicForm.reset();
    const patientType = this.addNewPatientForm.value.PATIENT_TYPE;
    if (patientType === 'Old') {
      this.addNewPatientForm.addControl(
        'EXISTING_PATIENT_SEARCH',
        new FormControl(null, Validators.required)
      );
    } else {
      this.addNewPatientForm.removeControl('EXISTING_PATIENT_SEARCH');
    }
    this.addNewPatientForm.updateValueAndValidity();
    this.patientTypeChange.emit(patientType);
	
	const preferredLanguage = this.demographicForm.get('preferredLanguage').value;
	if(preferredLanguage === null){
	  this.demographicForm.get('preferredLanguage').setValue('English');
	}
	else{
	  this.demographicForm.get('preferredLanguage').setValue(preferredLanguage);
	}
	console.log("demographicFormUpdated",this.demographicForm);
	
  }

  // check schedule type
  public changeScheduleType(): void {
    this.inQueueSlot.emit(false)
    this.getAppointmentSlotsAvailable = [];
    const scheduleType = this.addNewPatientForm.get('SCHEDULE_TYPE').value;
    //  === 'Future Appointment'
    if (scheduleType === 'Future Appointment') {
	  this.isFutureAppt = true;
      this.addNewPatientForm.addControl(
        'VISIT_DATE',
        new FormControl(null, [Validators.required,this.minDateValidator.bind(this)])
      );
      this.addNewPatientForm.addControl(
        'AVAILABLE_SLOT',
        new FormControl(null, Validators.required)
      );
      this.addNewPatientForm.addControl(
        'overBooking',
        new FormControl(null, null)
      );
      this.addNewPatientForm.addControl('ENDTIME_SLOT', new FormControl(null));
      this.addNewPatientForm.get('VISIT_DATE').setValue(null);
      this.addNewPatientForm.get('AVAILABLE_SLOT').setValue(null);
      this.addNewPatientForm.get('AVAILABLE_SLOT').disable();
    } else {
	  this.isFutureAppt = false;
      this.addNewPatientForm.removeControl('VISIT_DATE');
      this.addNewPatientForm.removeControl('AVAILABLE_SLOT');
      this.addNewPatientForm.removeControl('ENDTIME_SLOT');
      if (scheduleType === 'In Queue') {
        this.checkWalkInAvailability();
      }
    }
  }
  minDateValidator(control) {
    const selectedDate = moment.utc(control.value);
    const currentDate = moment.utc();

    const selectedDateOnlyDate = moment(selectedDate).format('YYYY-MM-DD');
    const currentDateOnlyDate = moment(currentDate).format('YYYY-MM-DD');

    if (moment(selectedDateOnlyDate).isSameOrAfter(currentDateOnlyDate)) {
      return null;
    } else {
      return { minDate: true };
    }
  }
  // check inqueue avaialability
  public checkWalkInAvailability(): void {
    if (
      this.addNewPatientForm.get('PATIENT_TYPE')?.invalid ||
      this.addNewPatientForm.get('PURPOSE_OF_VISIT')?.invalid ||
      this.addNewPatientForm.get('DOCTOR')?.invalid ||
      this.addNewPatientForm.get('SERVICE_TYPE')?.invalid
    ) {
      this.addNewPatientForm?.markAllAsTouched();
      this.addNewPatientForm.get('VISIT_DATE').setValue(null);
      return;
    }
    this.onSlotDateChange.emit(true);
    let params: any;
    const doctorNpi = this.addNewPatientForm.value.DOCTOR;
    const isNewPatient =
      this.addNewPatientForm.value.PATIENT_TYPE === 'New' ? true : false;
    let typeOfService =
      this.providerWorkFlow?.regular === true
        ? 'Regular Visit'
        : this.addNewPatientForm.value.SERVICE_TYPE;
    const purposeOfVisit =
      this.addNewPatientForm.value.PURPOSE_OF_VISIT || null;
    if (
      purposeOfVisit === 'Covid19 Vaccination' ||
      purposeOfVisit === 'Covid19 Testing'
    ) {
      typeOfService = 'Regular Visit';
    }
    const action = `appointment/registration/checkavailabilityforWalkIn`;
    params = {
      doctorNpi: doctorNpi,
      docFirstName: this.addNewPatientForm?.get('doctor_first_name').value,
      docLastName: this.addNewPatientForm?.get('doctor_last_name').value,
      facilityDate: this.minAppointmentDate,
      facilityId: this.loginDetails.facilityId,
      newPatient: isNewPatient,
      purposeOfVisit: purposeOfVisit,
      typeOfService: typeOfService,
    };
    this.httpService.makeRequestWithAction(action, params).subscribe(
      (data: any) => {
        if (data?.body?.status == 'FAILED') {
          this.notifyText =
            data?.body.message ||
            'Doctor is not available for the selected date';
            this.isSuccessNotify = false;
          this.notifyTextModal.show();
          this.inQueueSlot.emit(true)
        } else {
          this.inQueueSlot.emit(false);
        }
        this.onSlotDateChange.emit(false);
      },

      (error) => {
        this.exceptionModal.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
        this.onSlotDateChange.emit(false);
      }
    );
  }

  // change slot time
  public changeSlotTime(): void {
    const startTime = this.addNewPatientForm?.get('AVAILABLE_SLOT')?.value;
    const timeObj = this.getAppointmentSlotsAvailable?.find(
      (item) => item?.startTime === startTime
    );
    this.addNewPatientForm?.get('ENDTIME_SLOT')?.setValue(timeObj?.endTime);
  }

  // If type of service will change
  public checkPurposeOfVisit(): void {
    const purposeOfVisit = this.addNewPatientForm.get('PURPOSE_OF_VISIT').value;
    this.addNewPatientForm.get('SCHEDULE_TYPE').reset();
    this.changeScheduleType();
    // if(purposeOfVisit === 'Covid19 Vaccination' || purposeOfVisit === 'Covid19 Testing'){
    //   this.addNewPatientForm.get('SERVICE_TYPE').reset();
    //   this.addNewPatientForm.get('SERVICE_TYPE').disable();
    //   this.addNewPatientForm.get('SERVICE_TYPE').setValue('Regular Visit');
    //   this.checkCovidVisit = true;
    // }else{
    //   this.addNewPatientForm.get('SERVICE_TYPE').enable();
    //   this.addNewPatientForm.get('SERVICE_TYPE').reset();
    //   this.checkCovidVisit = false;
    // }
    if (purposeOfVisit === 'Covid19 Vaccination') {
      this.addNewPatientForm.addControl(
        'COVID_VACCINE_DOSAGE',
        new FormControl(null, Validators.required)
      );
    } else {
      this.addNewPatientForm.removeControl('COVID_VACCINE_DOSAGE');
    }
    this.addNewPatientForm.updateValueAndValidity();
  }

  // Get Available slots
  public getAppointmentSlots(notifyTextModal: UiModalComponent): void {

    const currentDate = new Date();
   const enteredDate = new Date(this.addNewPatientForm?.get('VISIT_DATE')?.value);
   if(enteredDate > currentDate){
		this.isFutureAppt = true;
	}else{
		this.isFutureAppt = false;
	}
	
	
    this.inQueueSlot.emit(false)
    if (
      this.addNewPatientForm.get('PATIENT_TYPE')?.invalid ||
      this.addNewPatientForm.get('PURPOSE_OF_VISIT')?.invalid ||
      this.addNewPatientForm.get('DOCTOR')?.invalid ||
      this.addNewPatientForm.get('SERVICE_TYPE')?.invalid
    ) {
      this.addNewPatientForm?.markAllAsTouched();
      this.addNewPatientForm.get('VISIT_DATE').setValue(null);
      return;
    }
    const appointmentDate = this.addNewPatientForm.value.VISIT_DATE;
    if (!appointmentDate || appointmentDate === '') {
      this.addNewPatientForm?.get('VISIT_DATE')?.setValue(null);
      this.addNewPatientForm?.get('AVAILABLE_SLOT')?.disable();
      this.addNewPatientForm?.get('AVAILABLE_SLOT')?.setValue(null);
      return;
    }
    this.onSlotDateChange.emit(true);
    this.getAppointmentSlotsAvailable = [];
    this.addNewPatientForm.get('AVAILABLE_SLOT').disable();
    this.addNewPatientForm.get('AVAILABLE_SLOT').setValue(null);
    let action: any;
    const doctorNpi = this.addNewPatientForm.value.DOCTOR;
    const isNewPatient =
      this.addNewPatientForm.value.PATIENT_TYPE === 'New' ? true : false;
    let typeOfService =
      this.providerWorkFlow?.regular === true
        ? 'Regular Visit'
        : this.addNewPatientForm.value.SERVICE_TYPE;
    const purposeOfVisit =
      this.addNewPatientForm.value.PURPOSE_OF_VISIT || null;
    if (
      purposeOfVisit === 'Covid19 Vaccination' ||
      purposeOfVisit === 'Covid19 Testing'
    ) {
      typeOfService = 'Regular Visit';
    }
    const overBook = this.addNewPatientForm.value.overBooking ? true : false;
    let params: any;
    var userInfoId ='';
    if(this.addNewPatientForm.value.PATIENT_TYPE == 'Old'){
      userInfoId =  sessionStorage.getItem('userInfoId');
    }
    
    if (this.isUrgentCare) {
    
      action = `appointment/registration/getAvailableSlotsForDay`;
      params = {
        doctorNpi: '',
        docFirstName: this.addNewPatientForm?.get('doctor_first_name').value,
        docLastName: this.addNewPatientForm?.get('doctor_last_name').value,
        facilityDate: appointmentDate,
        facilityId: this.loginDetails.facilityId,
        newPatient: '',
        purposeOfVisit: '',
        typeOfService: typeOfService,
        userInfoId: userInfoId?userInfoId:''
      };
      if (overBook) {
        params.doubleBook = true;
      }
    } else {
      action = `appointment/registration/getAvailableSlotsForDay`;
      params = {
        doctorNpi: doctorNpi,
        docFirstName: this.addNewPatientForm?.get('doctor_first_name').value,
        docLastName: this.addNewPatientForm?.get('doctor_last_name').value,
        facilityDate: appointmentDate,
        facilityId: this.loginDetails.facilityId,
        newPatient: isNewPatient,
        purposeOfVisit: purposeOfVisit,
        typeOfService: typeOfService,
        userInfoId: userInfoId?userInfoId:''
      };
      if (overBook) {
        params.doubleBook = true;
      }
    }
    // this.httpService.makeGetRequest(action, typeOfService)

    this.httpService.makeRequestWithAction(action, params).subscribe(
      (data: any) => {
        if (data?.body.status !== 'FAILED') {
          const allSlots = (data && data.body.resultList) || [];
          allSlots.forEach((element) => {
            const split = element.startTime.split(':');
            const array = split.splice(2, 1);
            element.startTime = split.join(':');
            element.descStartTime = this.httpService.timeConvert(
              element.startTime
            );
          });
          this.getAppointmentSlotsAvailable = allSlots || [];
          this.addNewPatientForm.get('AVAILABLE_SLOT').enable();
          if (
            this.getAppointmentSlotsAvailable &&
            this.getAppointmentSlotsAvailable.length === 0
          ) {
            this.notifyText =
              'No slots available for this provider on selected date';
              this.isSuccessNotify = false;
              this.inQueueSlot.emit(true)
            notifyTextModal.show();
          }
        } else {
          this.notifyText =
            data?.body.message ||
            'No slots available for this provider on selected date';
            this.isSuccessNotify = false;
            this.inQueueSlot.emit(true)
          notifyTextModal.show();
        }
        this.onSlotDateChange.emit(false);
      },
      (error) => {
        this.exceptionModal.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal.modalShow();
        this.onSlotDateChange.emit(false);
      }
    );
  }

  // when we change the doctor select
  public doctorChange(event?: any): void {
    console.log("event*********",event);
    this.addNewPatientForm?.get('SCHEDULE_TYPE')?.reset();
    this.addNewPatientForm?.get('overBooking')?.reset();
    this.addNewPatientForm
      ?.get('doctor_first_name')
      ?.setValue(event?.firstName);
    this.addNewPatientForm?.get('doctor_last_name')?.setValue(event?.lastName);
    this.changeScheduleType();
  }

  // open existing patient search modal
  public contactSearchModal(): void {
    this.existingPatientSearchComp.openSearchModal();
    this.existingPatientSearchComp?.autoFocusSearch();
  }
  public reSetDate(): void {
    this.addNewPatientForm?.get('VISIT_DATE')?.setValue(null);
    this.addNewPatientForm?.get('AVAILABLE_SLOT')?.disable();
    this.addNewPatientForm?.get('AVAILABLE_SLOT')?.setValue(null);
  }
  
  public getChiefComplaints(){
  
  const action = `careFlow/getCareFlowModuleAllInfo?facilityId=${this.loginDetails.facilityId}&module=Chief Complaints&name=CareFlow-ModuleDetails`;
    this.httpService.makeGetRequest(action, '').subscribe(
      (data: any) => {
        if (data?.status === 'SUCCESS') {
          
          this.chiefCompNode = data?.responseObject?.resourceType || [];
          this.chiefCompArray = this.chiefCompNode[0]?.sections[0]?.questions[0]?.values || [];
          console.log("this.chiefCompArray***********",this.chiefCompArray);
        }
      },
      (error) => {
        
      }
    );
  
  }
  
  // check if Other Complaint have selected 
  get isOtherComplaintSelected(): boolean {
    if (this.selectedChiefComp?.includes('Other')) {
      return true;
    }
    return false;
  }
  
  public checkChiefComp(event: any, name: string): void {
    if (event?.target?.checked) {
      this.selectedChiefComp.push(name);
    } else {
      this.selectedChiefComp = this.selectedChiefComp?.filter(
        (e) => e !== name
      );
    }
    sessionStorage.setItem(
      'allChiefComplaints',
      JSON.stringify(this.selectedChiefComp)
    );
  }
  
  public updateAdditionalNotes(event) {
    this.additionalNotesSelected = event.target.value;
	sessionStorage.setItem(
      'additionalChiefComplaints',
      this.additionalNotesSelected);
    
  }

  public updateOtherNotes(event) {
    this.otherComplaintsSelected = event.target.value;
	sessionStorage.setItem(
      'otherChiefComplaints',
      this.otherComplaintsSelected);
    
  }

  public selectedChiefCompients(event: any): void {
    console.log("event***",event)
    if (event.length > 0) {
      this.selectedChiefComp = event.map(item => item.actualValue);
    }else{
      sessionStorage.removeItem('allChiefComplaints');
      sessionStorage.removeItem('otherChiefComplaints');
      this.selectedChiefComp=[];
      this.isOtherComplaintSelected;
    } 
    this.selectedChiefComp = [... new Set(this.selectedChiefComp)]
    sessionStorage.setItem(
      'allChiefComplaints',
      JSON.stringify(this.selectedChiefComp)
    );
  }
  public selectChiefComplaintByPOV(pov:any){
    if(pov==='Chronic Care'){
      let chiefComplaints=this.chiefCompArray.find((x)=> x.seqId==2);
      
      let testVal=this.addNewPatientForm?.get('CHIEF_COMPLAINTS').value?this.addNewPatientForm?.get('CHIEF_COMPLAINTS').value:[];
      if (testVal && testVal.length > 0 && testVal.find((y) => y == 'Chronic Conditions Management')) {
        this.addNewPatientForm?.get('CHIEF_COMPLAINTS')?.setValue(testVal);
      }else{
        testVal.push(chiefComplaints?.actualValue);
        this.addNewPatientForm?.get('CHIEF_COMPLAINTS')?.setValue(testVal);
      }
      if(testVal&& testVal.length >0){
        this.selectedChiefComp = [...testVal];
        sessionStorage.setItem(
          'allChiefComplaints',
          JSON.stringify(this.selectedChiefComp)
        );
      }      
    }
    
  }
  
}
