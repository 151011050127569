import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { FormGroup } from '@angular/forms';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-encounter-view-insurance-edit',
  templateUrl: './encounter-view-insurance-edit.component.html',
  styleUrls: ['./encounter-view-insurance-edit.component.scss']
})
export class EncounterViewInsuranceEditComponent implements OnInit, OnChanges {
  public isSuccessNotify: boolean;
  public notifyText: any;
  @Input() appointmentId: string;
  @Input() claimsSubmissionCard: CardComponent;
  @Input() patientInformation: any;
  public updateAppointmentDetails: any;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild('addInsuranceFile') addInsuranceFile: ElementRef;
  public newInsuranceFormGroup: FormGroup;
  loadSpinner: boolean;
  loginDetails: any;
  checkCommonIdDocument: any;
  saveFileUrlForFullView: string;
  @Output() callPrepareClaims = new EventEmitter<any>();

  constructor(private httpService: HttpService) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }

  ngOnInit(): void {
    if (!this.patientInformation) {
      this.getAppointmentById();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.patientInformation) {
        this.updateAppointmentDetails = JSON?.parse(JSON?.stringify(this.patientInformation));
        if (this.patientInformation?.dob) {
          this.patientInformation.dob = this.httpService?.formatDobTimeZone(this.patientInformation?.dob);
        }
        this.getCommonIdDocument();
      }
    }
  }

  // this is for get the FacilityID details..
  public getAppointmentById(): void {
    let action: any = `appointment/registration/getAppointmentById?id=${this.appointmentId}`;
    this.claimsSubmissionCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe(async (data: any) => {
      if (data?.status == 'SUCCESS') {
        this.patientInformation = data?.responseObject || null;
        this.updateAppointmentDetails = JSON?.parse(JSON?.stringify(data?.responseObject));
        // getting the additonal Insurances with user info id
        if (!data?.responseObject?.selfPay) {
          const getInsurance = await this.httpService?.getInsuranceDetailsDocuments(data?.responseObject?.facilityId, data?.responseObject?.userInfoId, null)?.toPromise();
          const insuranceDetails = JSON.parse(JSON?.stringify(getInsurance?.responseObject || []));
          if (insuranceDetails?.length > 0) {
            this.updateAppointmentDetails['insuranceDetails'] = insuranceDetails || [];
            this.patientInformation['insuranceDetails'] = insuranceDetails || [];
          }
        }
        if (this.patientInformation) {
          // getting prod issue
          // this.patientInformation.dob = formatDate(this.patientInformation?.dob, 'MM/dd/yyyy', 'en-US');
          if (this.patientInformation?.dob) {
            this.patientInformation.dob = this.httpService?.formatDobTimeZone(this.patientInformation?.dob);
          }
        }
      }
      this.claimsSubmissionCard?.cardRefreshHide();
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal.modalShow();
      this.claimsSubmissionCard?.cardRefreshHide();
    });

  }

  // Update Appointment Details if any details updated
  public updateAppointment(appntDetails?: any): any {
    const action = 'appointment/registration/updateAppointment';
    // const this.updateAppointmentDetails = this.demographicsupdateComp?.demographicsDetails();
    // if (!this.demographicsupdateComp?.demographicsDetails()) {
    //   this.allRequiredFields = this.demographicsupdateComp?.findInvalidControls(this.demographicsupdateComp?.demographicsForm, []) || [];
    //   this.notifyText = 'Please fill in the required fields from all sections.';
    //   this.isSuccessNotify = false;
    //   this.requiredFieldsModal?.show();
    //   // this.exceptionModal.modalShow();
    //   return;
    // }

    if (appntDetails) {
      this.updateAppointmentDetails = appntDetails;
    }

    if (this.updateAppointmentDetails?.selfPay === false && this.updateAppointmentDetails?.healthPass === false && this.updateAppointmentDetails?.insuranceDetails && this.updateAppointmentDetails?.insuranceDetails?.length > 0 && !this.updateAppointmentDetails?.insuranceDetails?.find(item => item?.priority === 'Primary')) {
      this.notifyText = 'Prioritize the selection of the Primary coverage.';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      return;
    }

    this.claimsSubmissionCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, this.updateAppointmentDetails)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        this.notifyText = data?.body?.message || 'Patient information has been updated successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.getAppointmentById();
        this.callPrepareClaims?.emit(true);
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.claimsSubmissionCard?.cardRefreshHide();
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.claimsSubmissionCard?.cardRefreshHide();
      });
  }

  public addPrefixZipCode(addressZipCode: any): any {
    return this.httpService?.addPrefixZipCode(addressZipCode)
  }

  public getAppointmentTime(date: any): string {
    return this.httpService?.getAppointmentTime(date);
  }

  // ID Document download
  // Common id Document download
  private getCommonIdDocument(): void {
    this.httpService?.getDlIdDocument(this.loginDetails?.facilityId, this.patientInformation?.userInfoId, this.patientInformation?.id)?.subscribe((data: any) => {
      if (data?.responseObject?.identityImageId) {
        this.checkCommonIdDocument = data?.responseObject?.identityImageId;
        //this.downloadDlCard(data?.responseObject?.identityImageId);
        const documentType = "Identification Record";
        this.downloadIdentificationCard(data?.responseObject?.identityImageId, documentType, this.patientInformation?.userInfoId);
      }
    })
  }

  public downloadIdentificationCard(additionalKey, documentType, userInfoId, insuraceCardModal?: UiModalComponent) {
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.claimsSubmissionCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.claimsSubmissionCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {
          this.insuranceImagewithoutuserinfoId(documentType, additionalKey, userInfoId, insuraceCardModal);
        }
        else {
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          const fileType = data?.responseObject?.fileType;
          this.downloadIdFile(s3Key, s3Path, fileType, insuraceCardModal);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.claimsSubmissionCard?.cardRefreshHide();
    });
  }



  public insuranceImagewithoutuserinfoId(documentType, additionalKey: string, userInfoId, insuraceCardModal?: UiModalComponent) {
    const userInfoIdGet = null;
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoIdGet}&additionalKey=${additionalKey}`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data.responseObject !== null) {

        const s3Key = data?.responseObject?.s3Key;
        const s3Path = data?.responseObject?.s3Path;
        const additionalKey = data?.responseObject?.additionalKey;

        const fileType = data?.responseObject?.fileType;
        this.downloadIdFile(s3Key, s3Path, fileType, insuraceCardModal);
      }
    }, (error) => {
      this.claimsSubmissionCard?.cardRefreshHide();
    });
  }


  public downloadIdFile(s3Key, s3Path, fileType, insuraceCardModal?: UiModalComponent) {
    //alert(fileType);
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.claimsSubmissionCard?.cardRefreshShow();
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if (!dData) {
        this.loadSpinner = false;
        this.claimsSubmissionCard?.cardRefreshHide();
        return;
      }
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      this.claimsSubmissionCard?.cardRefreshHide();
      this.saveFileUrlForFullView = fileURL;
      const iframeEle = document.getElementById('identity-doc-card-view') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    }, (error) => {
      this.claimsSubmissionCard?.cardRefreshHide();
    });
  }

  // Identity image full view
  public fullViewIdentityImage(identityDocFullViewClaims: UiModalComponent): void {
    const iframeEle = document.getElementById('identity-doc-card-full-view-claims') as HTMLElement;
    iframeEle.setAttribute('src', this.saveFileUrlForFullView);
    identityDocFullViewClaims?.show();
  }

}
