<div class="summary-careplan-data">
    <ng-container *ngFor="let category of planData; let cati = index">
        <h5 *ngIf="category?.resourceType !== 'General'">{{category?.resourceType}}</h5>
        <ng-contaier *ngFor="let section of category?.sections">
            <!-- *ngIf="section?.name !== 'New Orders'" -->
            <ng-contaier>
                <h5 *ngIf="section?.name !== 'New Orders' && section?.name !== 'Assessment'">{{section?.name}}</h5>
                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let answer of section?.answers">

                        <div class="form-group row mb-0 inline-form"
                            *ngIf="section?.name !== 'Assessment' && category?.resourceType !== 'Billing Codes' && answer?.label !== 'Billing / Procedure/ HCPIC Codes' && answer?.label !== 'Prescriptions' && answer?.label !== 'New Lab/Rad Orders'">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">{{answer?.label}}</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <ul class="no-list-style inline-list" class="carePlan{{answer?.label}}">
                                    <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                                        <li>{{value?.answer || value?.duration || value?.units || '--'}}&nbsp;
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="answer?.actualValue?.length === 0">
                                        <li>--</li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>

                        <ng-container *ngIf="answer?.otherNotes">
                            <div class="form-group row mb-0 inline-form">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label class="labelStyle">{{answer?.label}} Additional Notes</label> <span
                                        class="label-colon"> :
                                    </span>
                                    <!-- </div>
                                <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                    <label>{{answer?.otherNotes || '--'}}</label>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Billing Codes -->
                        <ng-contaier
                            *ngIf="category?.resourceType === 'Billing Codes' && answer?.label === 'Billing / Procedure/ HCPIC Codes'">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>CPT Code</th>
                                                    <th>Description</th>
                                                    <th>MODIFIER</th>
                                                    <th>DOSAGE</th>
                                                    <!-- <th>Strength</th> -->
                                                    <!-- <th>Measurement Unit</th> -->
                                                    <th>DAYS/UNITS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container
                                                    *ngFor="let tbody of answer?.actualValue; let listi = index">
                                                    <tr *ngIf="tbody?.codeDescription !== 'DELETED'">
                                                        <td>{{tbody?.cptCode}}</td>
                                                        <td style="white-space: normal;">{{tbody?.codeDescription}}</td>
                                                        <td>{{tbody?.modifier || '--'}}</td>
                                                        <td>{{tbody?.dosage || '--'}}</td>
                                                        <!-- <td>{{tbody?.strength || '--'}}</td> -->
                                                        <!-- <td>{{tbody?.measurementUnitCode || '--'}}</td> -->
                                                        <td>{{tbody?.daysUnits || '--'}}</td>
                                                    </tr>
                                                </ng-container>

                                                <tr
                                                    *ngIf="answer?.actualValue?.length === 0 || !isOnlyDeleted(answer?.actualValue)">
                                                    <td colspan="5" class="text-center">Billing codes are unavailable
                                                        for this patient.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ng-contaier>
                    </div>
                </div>
            </ng-contaier>
        </ng-contaier>

        <ng-container *ngIf="category?.otherNotes">
            <div class="form-group row mb-0 inline-form">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                    </span>
                    <!-- </div>
                <div class="col-lg-6 col-md-6 col-sm-6"> -->
                    <label>{{category?.otherNotes || '--'}}</label>
                </div>
            </div>
        </ng-container>

    </ng-container>
</div>