import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { CareOutreachService } from './careoutreach.service';
import { ClaimsService } from './claims.service';
import { ExternalGatewayService } from './external-gateway.service';


@Injectable({
    providedIn: 'root'
})
export class ApisService {

    constructor(public http: HttpClient, private httpService: HttpService, private careOutreachService: CareOutreachService, private externalGatewayService: ExternalGatewayService, private claimsService: ClaimsService) { }

    public getBillingClaimCofigCodes(codeType?: string): Observable<any> {
        const action = `billingCodes/readClaimConfigCodes?codeType=${codeType}`;
        return this.httpService?.makeGetRequest(action, '');
    }

    public getFaceSummaryData(appointmentInfoDetails?: any): Observable<any> {
        const action = `face/summary?userInfoId=${appointmentInfoDetails?.userInfoId}&currentAppointmentDate=${appointmentInfoDetails?.appointment?.dateTime}`;
        return this.httpService?.makeGetRequest(action, '');
    }

    // to get all patient notes for user info id
    public getAllPatientQuickNotes(userInfoId?: string): Observable<any> {
        const action = `userInfo/getPatientQuickNotes?userInfoId=${userInfoId}`;
        return this.httpService?.makeGetRequest(action, '');
    }

    // Save Patient Quick NOtes
    public savePatientQuickNotes(request?: any): Observable<any> {
        const action = `userInfo/savePatientQuickNotes`;
        return this.httpService?.makeRequestWithAction(action, request);
    }


    // Care Outreach API's
    // get message format
    public getOutreachConfigMessage(request?: any): Observable<any> {
        const action = `outreach-config/getNotificationMessage`;
        return this.careOutreachService?.makeRequestWithAction(action, request);
    }

    // Post Message 
    public postNotificationMessage(request?: any): Observable<any> {
        const action = `outreach/postMessage`;
        return this.careOutreachService?.makeRequestWithAction(action, request);
    }

    // External Gateway Payment API's - New Repo
    // Create NON POS Payment
    public createNonPosPaymentExtrGateway(reqAction?: any): Observable<any> {
        const action = `payment/createPayment`;
        return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
    }

    // Cancel NO POS Create Payment
    public cancelNonPosPaymentExtrGateway(paymentId?: any): Observable<any> {
        const action = `payment/cancelPayment/${paymentId}`;
        return this.externalGatewayService?.makeRequestWithAction(action, '');
    }

    // read Status of NO POS Create Payment
    public getStatusNonPosPaymentExtrGateway(paymentId?: any): Observable<any> {
        const action = `payment/getPayment/${paymentId}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Create Terminal Payment
    public createTerminalPaymentExtrGateway(reqAction?: any): Observable<any> {
        const action = `payment/createTerminalPayment`;
        return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
    }

    // Cancel Terminal Payment
    public cancelTerminalPaymentExtrGateway(checkoutId?: any): Observable<any> {
        const action = `payment/cancelTerminalPayment/${checkoutId}`;
        return this.externalGatewayService?.makeRequestWithAction(action, '');
    }

    // read Status of Terminal Payment
    public getStatusTerminalPaymentExtrGateway(checkoutId?: any): Observable<any> {
        const action = `payment/getTerminalPayment/${checkoutId}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Create Cash Payment
    public createCashPaymentExtrGateway(reqAction?: any): Observable<any> {
        const action = `payment/createCashPayment`;
        return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
    }

    // Create Credit Payment
    public createCreditPaymentExtrGateway(reqAction?: any): Observable<any> {
        const action = `payment/createCreditPayment`;
        return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
    }

    // Create Payer Payments (AR Insurnace, Interest, Cap Payments)
    public createPayerPaymentExtrGateway(reqAction?: any): Observable<any> {
        // const action = `payment/createARInsurancepayment`;
        const action = `payment/createPayerPayment`;
        return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
    }

    // Update Patient Responsibility
    public updatePatientResponsibilityExtrGateway(reqAction?: any): Observable<any> {
        const action = `payment/updatePatientResponsibility`;
        return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
    }

    // Save Cost of care
    public saveCostOfCareExtrGateway(reqAction?: any): Observable<any> {
        const action = `payment/saveCostOfCare`;
        return this.externalGatewayService?.makeRequestWithAction(action, reqAction);
    }

    // Retreive Cost of care
    public retreiveAppntCostOfCareExtrGateway(appId?: any): Observable<any> {
        const action = `costOfCare/getByAppointmentId?appointmentId=${appId}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Get Patient Responsibility and Credits
    public getPatientRespNCreditsCall(userInfoId?: string, patientAccId?: string): Observable<any> {
        const action = `payment/getPatientResponsibilityNCredits?userInfoId=${userInfoId}&patientAccountId=${patientAccId || ''}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    public recieptGeneration(appointmentId: string, paymentId: any, userInfoId?: string, amount?: any, serviceType?: string): Observable<any> {
        let action: string;
        if (appointmentId) {
            action = `paymentReport/generateCashReceipt?appointmentId=${appointmentId}&paymentId=${paymentId}`;
        } else if (userInfoId) {
            action = `paymentReport/generateCashReceipt?userInfoId=${userInfoId}&paymentId=${paymentId}`;
            if (serviceType) {
                action = action + `&serviceType=${serviceType || ''}`;
            }
            if (amount) {
                action = action + `&amount=${amount || ''}`;
            }
        } else if (paymentId && !appointmentId && !userInfoId) {
            action = `paymentReport/generateCashReceipt?paymentId=${paymentId}`;
            if (serviceType) {
                action = action + `&serviceType=${serviceType || ''}`;
            }
            if (amount) {
                action = action + `&amount=${amount || ''}`;
            }
        }
        return this.externalGatewayService?.generatePDF(action, '');
    }

    // Get Account Balance 
    public getChartInfoAccountBalance(userInfoId?: string): Observable<any> {
        const action = `payment/getChartInfo?userInfoId=${userInfoId}&chartModuleName=Payments`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Get Payment Config
    public getPaymentConfig(facilityId?: string): Observable<any> {
        // const action = `payment/getPaymentConfig?facilityId=${facilityId}`;
        const action = `payment/getPaymentConfig`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Get Payments For the Day - Payments AUDIT
    public getPaymentsForTheDayAudit(paymentStartDate?: string, paymentEndDate?: string, paymentCategory?: string): Observable<any> {
        const action = `payment/getPaymentsForTheDay?paymentStartDate=${paymentStartDate}&paymentEndDate=${paymentEndDate}&paymentCategory=${paymentCategory}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Get Payments For the Day Individual Payments - Payments AUDIT
    public getPaymentsForTheDayIndPaymentsAudit(paymentStartDate?: string, paymentEndDate?: string,paymentStartDateRange?:string,paymentEndDateRange?:string,paymentCategory?:string): Observable<any> {
        const action = `payment/getPaymentsForTheDayForIndPayments?paymentStartDate=${paymentStartDate}&paymentEndDate=${paymentEndDate}&serviceStartDate=${paymentStartDateRange}&serviceEndDate=${paymentEndDateRange}&paymentCategory=${paymentCategory}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Update Healthpass Payment
    public updateHealthPassPayExtrGateway(userInfoId?: any, feeType?: any, payment?: any): Observable<any> {
        const action = `payment/updateHealthPassPayment?userInfoId=${userInfoId}&feeType=${feeType}&payment=${payment}`;
        return this.externalGatewayService?.makeRequestWithAction(action, '');
    }

    // Get Payments For the Auits Submission- Payments AUDIT
    public getPaymentsForAuditRecords(paymentToken?: any): Observable<any> {
        const action = `payment/getPaymentsAssociatedWithAuditRecord?paymentToken=${paymentToken}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Get Payments Count- Payments AUDIT
    public getPaymentsCounts(): Observable<any> {
        const action = `payment/getPaymentsCountForApproval`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Get Payments Audits records- Payments AUDIT
    public getPaymentsAuditsRecords(paymentStartDate?: string, paymentEndDate?: string): Observable<any> {
        const action = `payment/getPaymentsForTheDayForAudit?paymentStartDate=${paymentStartDate}&paymentEndDate=${paymentEndDate}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Submit Individual Payments For Approval or Rejections - Payment Audits
    public submitIndvPaymentsForAudits(params?: any, approverId?: any, notes?: any): Observable<any> {
        const action = `payment/submitIndividualPayments?approverId=${approverId}&notes=${notes}`;
        return this.externalGatewayService?.makeRequestWithAction(action, params);
    }

    // Update Approval or Rejections of Audit payments - Payment Audits
    public updateApprovalRejectionAuditRecords(params?: any, status?: any, notes?: any): Observable<any> {
        const action = `payment/updatePaymentsApprovalRejection?status=${status}&notes=${notes}`;
        return this.externalGatewayService?.makeRequestWithAction(action, params);
    }

    // Self-Pay billing codes Payments 
    public saveSelfPayBillingCodesPayments(params?: any): Observable<any> {
        const action = `paymentReport/saveSelfPayBillingCodes`;
        return this.externalGatewayService?.makeRequestWithAction(action, params);
    }

    // DELETE - Self-Pay billing codes Payments 
    public deleteSelfPayBillingCodesPayments(params?: any): Observable<any> {
        const action = `paymentReport/deleteSelfPayBillingCodes`;
        return this.externalGatewayService?.makeRequestWithAction(action, params);
    }

    // Get Payments Audits records- Payments AUDIT
    public getTotalPaidAmountForAppnt(appointmentId?: string): Observable<any> {
        const action = `payment/getTotalPaidAmountForAppointment?appointmentId=${appointmentId}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Get Payments Audits records- Payments AUDIT
    public generateAccountStatement(userInfoId?: string): Observable<any> {
        const action = `paymentReport/generatePaymentsPDF?userInfoId=${userInfoId}`;
        return this.externalGatewayService?.generatePDF(action, '');
    }

    // Update Payer payment check number and check date
    public saveCheckNumberDateDetails(params?: any): Observable<any> {
        const action = `payment/updatePayerPaymentCheckDetails?appointmentId=${params?.appointmentId}&checkNumber=${params?.checkNumber}&checkDate=${params?.checkDate}&processorType=${params?.processorType}`;
        return this.externalGatewayService?.makeRequestWithAction(action, '');
    }

    // Flow Board Payments counter
    public fbPaymentCounter(): Observable<any> {
        const action = `payment/paymentCount`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // CLAIMS
    public getTotalClaimsCountFB(): Observable<any> {
        const action = `claim/totalClaims`;
        return this.claimsService?.makeGetRequest(action, '');
    }

    // get list of documents
    public getListOfDocuments(documentType: string): Observable<any> {
        const action = `record/listDocuments?documentType=${documentType}`;
        return this.httpService?.makeGetRequest(action, '');
    }

    public updateClaimsSubscriberdetails(appointmentId: string, insurancePriority: string): Observable<any> {
        const action = `eligibility/updateSubscriberDetails?appointmentId=${appointmentId}&insurancePriority=${insurancePriority}`;
        return this.claimsService?.makeRequestWithAction(action, '');
    }

    public pauseStatement(userInfoId:any){
        const action = `payment/updatePauseStatement?userInfoId=${userInfoId}`;
        return this.externalGatewayService?.makeRequestWithAction(action, '');
    }

    // Health works
    public getAllProviderNetwroks(loginEmail: string): Observable<any> {
        const action = `healthWorks/getProviderNetworks?email=${loginEmail}`;
        return this.externalGatewayService?.makeGetRequest(action, '');
    }

    // Delete Member Activity
    public deleteMemeber(invoiceMasterId: string, memberActivityId: string): Observable<any> {
        const action = `healthWorks/deleteMemberActivity?invoiceMasterId=${invoiceMasterId}&memberActivityId=${memberActivityId}`;
        return this.externalGatewayService?.makeDeleteAction(action);
    }
}