import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { CommonRestService } from './common-rest.service';
import * as moment from 'moment';
import { ConfigResponse } from '../hop-common-components/short-words/short-words.component';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public baseUrl: string = environment.endPoint;
  private accessWithOptions: any
  public userDetails = JSON.parse(sessionStorage?.getItem('userDetails'));
  public allUsersListForFacility: any[] = [];
  public allShortcodesForFacility: any[] = [];
  public currentYear = new Date().getFullYear();
  public currentMonth = new Date().getMonth();
  public currentDate = new Date().getDate();

  constructor(
    public http: HttpClient, private commonRestServices: CommonRestService
    // private apiHeader: ApiHeader
    //test
  ) {
    this.accessWithOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-code': sessionStorage.getItem('token'),
      }),
    }
    this.getExistingProvidersByFacility();
    this.getShortCodesList();
  }


  makeRequestWithAction(action, params) {
    return this.commonRestServices?.makeRequestWithAction(this.baseUrl, action, params);
  }

  makeGetRequest(action, request) {
    return this.commonRestServices?.makeGetRequest(this.baseUrl, action, request);
  }

  public getRequest(action: string): any {
    return this.commonRestServices?.makeGetRequest(this.baseUrl, action, '');
  }

  public getRequestCheckoutUrl(action: string): any {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(this.baseUrl + action)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  public getAppointment(date: any): string {
    if (date) {
      const splitDate = date?.toString()?.split('T');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const formateDate = this.convertinvoiceDate(splitDate && splitDate[0]);
      return `${formateDate}`;
    } else {
      return 'N/A'
    }
  }
  public convertinvoiceDate(appntDate: string): any {
    const dateArray = appntDate && appntDate.split('-');
    let dateString = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
    return dateString;
  }
  public postRequest(action: string, params: any): any {
    return this.http.post(this.baseUrl + action, params, this.accessWithOptions)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  dateValidator(control) {
    
    const inputDate = new Date(control.value);

    if (isNaN(inputDate.getTime())) {
      return { 'invalidDate': true };
    }
    const selectedDate = moment.utc(inputDate);
    const currentDate = moment.utc();

    const selectedDateOnlyDate = moment(selectedDate).format('YYYY-MM-DD');
    const currentDateOnlyDate = moment(currentDate).format('YYYY-MM-DD');

    const minYear = 1900;
    const maxYear = new Date().getFullYear();  

    if (inputDate.getFullYear() < minYear || inputDate.getFullYear() > maxYear) {
      return { 'invalidDate': true };
    }
    if (moment(selectedDateOnlyDate).isSameOrBefore(currentDateOnlyDate)) {
      return null;
    } else {
      return { 'invalidDate': true };
    }

    // return null;
  }
  yearValidator(control) {
    const inputDate = new Date(control.value);

    if (isNaN(inputDate.getTime())) {
      return { 'invalidDate': true };
    }


    const minYear = 1900;
    const maxYear = new Date().getFullYear();
    const yearPart = inputDate.getFullYear().toString();
    if (yearPart && yearPart.length > 4) {
      control.setValue(null);
      return { 'invalidDate': true };
    }

    if (inputDate.getFullYear() < minYear || inputDate.getFullYear() > maxYear) {
      return { 'invalidDate': true };
    }

    return null;
  }

  addDoctor(npi, orgtype) {
    return this.http.get(
      //https://careeco.herokuapp.com//npi/getNpidetails?npi=1124021324&orgtype=Individual
      this.baseUrl + `npi/getNpidetails?npi=${npi}&orgtype=${orgtype}`)
      .pipe(map(res => res));
  }

  signUp() {
    url: 'https://careeco.herokuapp.com/support/createSupportInfo'
  }

  saveDoctor(data) {
    return this.http.post(
      this.baseUrl + `providerInfo/updateProvider`, data)
      .pipe(map(res => res));
  }

  preHealthQuestions(facilityId) {
    return this.http.get(
      this.baseUrl + `appointment/getPreAppointmentQuestions?facilityId=${facilityId}`)
      .pipe(map(res => res));
  }

  getPatientHealthDetails(userInfoId, appointmentId, patient_details) {
    return this.http.get(
      this.baseUrl + `getHealthHistory?userInfoId=${userInfoId}&appointmentId=${appointmentId}&facilityId=${patient_details.facilityId}&age=${patient_details.age}&gender=${patient_details.gender}&typeOfService=${patient_details.appointment.typeOfService}`)
      .pipe(map(res => res));
  }
  savePreAppointment(data) {
    return this.http.put(
      this.baseUrl + `savePreAppointmentAnswers`, data)
      .pipe(map(res => res)

      );
  }
  upcomingAppointments(facilityId, date) {
    return this.http.get(
      this.baseUrl + `appointment/getUpcoming?facilityId=${facilityId}&date=${date}`)
      .pipe(map(res => res));
  }
  getAvailableSlots(facilityId, date, patientType, doctor, serviceType) {
    return this.http.get(
      this.baseUrl + `appointment/getAvailableSlotsForDay?facilityId=${facilityId}&date=${date}&isNewPatient=${patientType}&doctorNpi=${doctor}&typeOfService=${serviceType}`)
      .pipe(map(res => res));
  }
  forgot_password(data) {
    return this.http.post(
      this.baseUrl + `providerLoginInfo/forgotPasswordProvider`, data)
      .pipe(map(res => res));
  }

  reset_password(data) {
    return this.http.post(
      this.baseUrl + `providerLoginInfo/resetPasswordProvider`, data)
      .pipe(map(res => res));
  }

  createMeeting(data) {
    return this.http.post(
      this.baseUrl + 'meeting/create', data)
      .pipe(map(res => res));
  }

  makeGetRequestWithCityZipcode(action, city, zipcode) {
    return this.http.get(this.baseUrl + 'providerSearch/?searchQry=' + action + '&city=' + city + '&zipcode=' + zipcode)
      .pipe(map(res => res));
  }

  // makeFacilityRequest(facilityid){
  //   let action : any = 'waitTime/facility?id=';
  //   let params: any = {
  //     'id' : facilityid
  //   }
  //   return this.http.post(this.baseUrl + action, params.id)
  //   .subscribe((data: any) => {
  //     if (data.status == 'SUCCESS') {
  //       this.dataService.setFacilityWaitTime(data);
  //     }
  //   })
  // }

  getPatientsByFacilityId(action, facilityid) {
    return this.http.get(this.baseUrl + action + facilityid)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  updateOutReachConfigs(action, params) {
    return this.http.post(
      this.baseUrl + action, params)
      .pipe(map(res => res));
  }

  saveOutReachConfigs(action, params) {
    return this.http.post(
      this.baseUrl + action, params)
      .pipe(map(res => res));
  }


  public convertDate(dob: string | number | Date): any {
    let currentDate = new Date(dob);
    let date = currentDate.getDate();
    let formatedate = ("0" + date).slice(-2);
    let month = currentDate.getMonth() + 1;
    let formateMonth = ("0" + month).slice(-2);
    let year = currentDate.getFullYear();
    let dateString = `${year}-${formateMonth}-${formatedate}`;
    return dateString;
  }

  // due to timezone is added in dob, split and 
  public formatDob(date: string): any {
    if (date != null) {
      let split = date?.includes('T') ? date?.toString()?.split('T') : date;
      const dateSplit = split && split[0] ? split[0] : split;
      return this.convertDob(dateSplit) || '';
    }
  }


  // to format the dob with timezone
  public convertDob(date: string | any): any {
    const dateArray = date && date?.split('-');
    let dateString = `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`;
    return dateString || date;
  }

  // Generate PDF
  public generatePDF(action: string, params: any): any {
    return this.commonRestServices?.generatePDF(this.baseUrl, action, params);
  }

  // Generate download Image
  public downloadImage(action: string): any {
    return this.commonRestServices?.downloadImage(this.baseUrl, action);
  }

  public downloadProfileImage(action: string): any {
    return this.commonRestServices?.downloadProfileImage(this.baseUrl, action);
  }

  // Generate download Image
  public downloadS3Image(action: string, params: any): any {
    return this.commonRestServices?.downloadS3Image(this.baseUrl, action, params);
  }
  
  // Delete Request Action
  public makeDeleteRequest(action: string): any {
    return this.commonRestServices?.makeDeleteRequest(this.baseUrl, action);
  }

  public calculateAge(birthday: any): number {
    const dobDate = birthday?.includes('T') ? birthday?.split('T')[0] : birthday;
    const date = new Date(dobDate);
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age || 0;
  }


  //This method is to validate manually entered date value
  public validateManualEnterdob(enteredDate: any, currentYear, currentMonth, currentDate): boolean {
    const date = new Date(enteredDate);
    const toDayDate = new Date();
    if (date != undefined && date != null) {
      // var inputYear = date.getFullYear();
      // var inputMonth = date.getMonth() + 1;
      // var inputDate = date.getDate();
      // if (inputYear >= 1900 && inputYear <= currentYear) {
      //   // if (!(inputYear < currentYear || (inputYear == currentYear && inputMonth < currentMonth) ||
      //   //   (inputYear == currentYear && inputMonth == currentMonth && inputDate <= currentDate))) {
      //   //   return true
      //   // }
      //   if(inputYear === currentYear){

      //   }
      // }
      // else {
      //   return false
      // }
      if (date.getFullYear() < 1910 || date > toDayDate) {
        return true; // Invalid date
      }

      return false; // Valid date
    }
    return true
  }

  patientRxOrderLookup(action, appointmentId, dob, doctorNpi, facilityId, firstName, lastName, orderOrRx, phoneNumber) {
    return this.http.get(this.baseUrl + action + appointmentId + dob + doctorNpi + facilityId + firstName + lastName + orderOrRx + phoneNumber)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.message || error))
      );
  }

  // customeize the Appointment Date Time...
  public getAppointmentTime(date: any): string {
    if (date) {
      const splitDate = date?.toString()?.split('T');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const timeConvert = this.timeConvert(time) || '';
      const formateDate = this.convertAppointmentDate(splitDate && splitDate[0]);
      return `${formateDate}, ${timeConvert}`;
    } else {
      return 'N/A'
    }
  }

  public convertAppointmentDate(appntDate: string): any {
    // let currentDate = new Date(dob);
    // let date = currentDate.getDate();
    // let formatedate = ("0" + date).slice(-2);
    // let month = currentDate.getMonth() + 1;
    // let formateMonth = ("0" + month).slice(-2);
    // let year = currentDate.getFullYear();
    const dateArray = appntDate && appntDate.split('-');
    let dateString = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
    return dateString;
  }

  // Time converstion from 24hrs format to 12hrs
  public timeConvert(time: any) {
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time?.length > 1) { // If time format correct
      time = time?.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time?.join(''); // return adjusted time or original string
  }

  // appointment status showing
  public getStatus(status: string, appointment: any): string {
    status = status && status.toUpperCase();
    let returnStr: string;
    if (appointment?.appointment?.virtualVisit && (appointment?.appointment?.typeOfService === 'Tele-Medicine' || appointment?.appointment?.typeOfService === 'Tele-Health') && status === 'NURSE_GREET') {
      return 'Doctor Consultation';
    }
    switch (status) {
      case 'SCHEDULED':
        returnStr = 'Scheduled';
        break;
      case 'CONFIRMED':
        returnStr = 'Confirmed';
        break;
      case (appointment && appointment?.confirmationFlag):
        returnStr = 'Confirmed';
        break;
      case 'NOTAVAILABLE':
        returnStr = 'No Show';
        break;
      case 'CANCELLED':
        returnStr = 'Cancelled';
        break;
      case 'CHECKOUT':
        returnStr = 'Completed';
        break;
      case 'QR_VERIFIED':
        returnStr = 'Walk-In';
        break;
      case 'NURSE_GREET':
        returnStr = 'Nurse Greet';
        break;
      case 'CONSULTATION':
        returnStr = 'Consultation';
        break;
      case 'TRIAGE':
        returnStr = 'Triage';
        break;
    }
    return returnStr || null;
  }

  // make object for screen items show or hide
  public screenItemsShowHide(item: any): any {
    return item?.values?.reduce((a: any, b: any) => (a[b] = true, a), {});
  }

  // remove timezone from dob
  public formatDobTimeZone(dob: string) {
    let split = dob?.includes('T') ? dob?.toString()?.split('T') : dob;
    split = split && split[0] ? split[0] : split;
    return split || '';
  }


  // make PUT request
  public makePutRequest(action: string, params: any) {
    return this.commonRestServices?.makePutRequest(this.baseUrl, action, params);
  }

  // clear treatment flow session data
  public clearTreatmentFlowSession(): void {
    sessionStorage?.removeItem('hpiForm');
    sessionStorage?.removeItem('examValues');
    sessionStorage?.removeItem('allOtherRos');
    sessionStorage?.removeItem('rosAdditionalNotes');
    sessionStorage?.removeItem('assessmentValues');
    sessionStorage?.removeItem('allChiefComplaints');
    sessionStorage?.removeItem('chiefComplaintsNotes');
    sessionStorage?.removeItem('vitalsForm');
    sessionStorage?.removeItem('allICDCodes');
    sessionStorage?.removeItem('billingValues');
    sessionStorage?.removeItem('allBehaviourScreening');
    sessionStorage?.removeItem('treatmentFlowAddNotes');
    sessionStorage?.removeItem('hpiFormValueChange');
    sessionStorage?.removeItem('inHouseLabOrdersDetails');
    sessionStorage?.removeItem('treatmentFlowNavigationSteps');
    sessionStorage?.removeItem('isChiefComplaintsModuleData');
    sessionStorage?.removeItem('isBehaviourScreenModuleInfoData');
    sessionStorage?.removeItem('isHPIModuleInfoData');
    sessionStorage?.removeItem('isRosModuleInfoData');
    sessionStorage?.removeItem('isVitalsModuleInfoData');
  }

  // Generate download Image
  public createPayment(action: string, params: any, accessToken: string): any {
    const accessWithOptions = {
      headers: new HttpHeaders({
        'Square-Version': '2022-01-20',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
    }
    return this.http.post(action, params, accessWithOptions)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // icd codes treatment flow
  public icdCodesTrackBy(term: string = null): Observable<any> {
    if (!term) {
      return of([]).pipe(delay(500));
    }
    const action = `treatmentFlow/codeSearch/${term}`;
    let subItem = this.makeGetRequest(action, '');
    return subItem;
  }

  // icd codes treatment flow
  public icdCodesTrackByCategory(term: string = null, category?: string): Observable<any> {
    if (!term) {
      return of([]).pipe(delay(500));
    }
    let action = `treatmentFlow/codesCategorySearch?codeSearch=${term}`;
    if (category) {
      action = action + `&category=${category}`;
    }
    let subItem = this.makeGetRequest(action, '');
    return subItem;
  }

  public vaccineTrackBy(term: string = null): Observable<any> {
    if (!term) {
      return of([]).pipe(delay(500));
    }
    const action = `vaccination/vaccineSearch/${term}`;
    let subItem = this.makeGetRequest(action, '');
    return subItem;
  }

  // billing or cpt code search from treatment flow
  public billingCptCodesTrackBy(term: string = null): Observable<any> {
    if (!term) {
      return of([]).pipe(delay(500));
    }
    const action = `billingCodes/search/${term}`;
    let subItem = this.makeGetRequest(action, '');
    return subItem;
  }

  private makeBillingPostCall(action, params) {
    return this.http.post(this.baseUrl + action, params)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // get billing codes for claim
  public getBillingCodes(term: string = null, claimAppointment: any): Observable<any> {
    if (!term) {
      return of([]).pipe(delay(500));
    }
    const action = 'billingCodes/getBillingCodeCharges';
    const params = {
      "facilityId": claimAppointment?.facilityId,
      "icdCode": term,
      "npi": claimAppointment?.appointment?.doctorNpi
    };
    let subItem = this.makeBillingPostCall(action, params);
    return subItem;
  }

  // get insurance list from the auto complete
  public getInsuranceAutoComplete(term: string = null): Observable<any> {
    if (!term) {
      return of([]).pipe(delay(500));
    }
    const action = 'insurance/getInsuranceList?searchQry=';
    let insuranceListSub = this.makeGetRequest(action, term);
    return insuranceListSub;
  }

  // get CPT codes for claim
  public getCptCodes(cptCodeDesc: any = null, claimAppointment: any): Observable<any> {
    if (!cptCodeDesc) {
      return of([]).pipe(delay(500));
    }
    const action = 'billingCodes/getCptCodeCharges';
    const params = {
      "facilityId": claimAppointment?.facilityId,
      "cptCode": cptCodeDesc?.cptCode,
      "npi": claimAppointment?.appointment?.doctorNpi,
      "cptCodedesc": cptCodeDesc?.codeDescription?.trim()
    };
    let subItem = this.makeBillingPostCall(action, params);
    return subItem;
  }

  // remove timezone from dob
  public formatDobTimeZoneWithDisplay(dob: string): any {
    let split = dob?.includes('T') ? dob?.toString()?.split('T') : dob;
    const date = split && Array.isArray(split) ? split[0] : split;
    const dateChange = date?.includes('-') ? date?.toString()?.split('-') : date;
    return ((dateChange && Array.isArray(dateChange)) ? `${dateChange[1]}/${dateChange[2]}/${dateChange[0]}` : dateChange) || '';
  }

  // dateFormate if its string format - YYYYMMDD
  public yymmddDateFormat(dateNum: string): any {
    if (!dateNum) {
      return null
    }
    const spDate = dateNum?.split('');
    const date = `${spDate[4]}${spDate[5]}/${spDate[6]}${spDate[7]}/${spDate[0]}${spDate[1]}${spDate[2]}${spDate[3]}`;
    return date || null;
  }

  // post request with FormData
  public makePostWithFormData(action, params) {
    return this.commonRestServices?.makePostWithFormData(this.baseUrl, action, params);
  }

  // drugs search HPI section Treatment Flow
  public drugsSearch(drugTerm: string = null): Observable<any> {
    if (!drugTerm) {
      return of([]).pipe(delay(500));
    }
    // const action = `drug/getDrugs/${drugTerm}`;
    const action = `changeHealth/getDrugByName?name=${drugTerm}`;
    let subItem = this.makeGetRequest(action, '');
    return subItem;
  }

  // Prefrered Pharmcy  search HPI section Treatment Flow
  public preferredPharmacy(zipCode: string = null): Observable<any> {
    if (!zipCode) {
      return of([]).pipe(delay(500));
    }
    const action = `changeHealth/getPharmacyByZip?zipCode=${zipCode}`;
    let subItem = this.makeGetRequest(action, '');
    return subItem;
  }

  // common service calls for get ID, Consent Forms and Insurances
  public getDlIdDocument(facilityId: number, userInfoId: string, appointmentId: string): any {
    const action = `common/getIdDocument?facilityId=${facilityId}&userInfoId=${userInfoId}&appointmentId=`;
    return this.makeGetRequest(action, appointmentId || '');
  }

  public getConsentFormsDocuments(facilityId: number, userInfoId: string, appointmentId: string): any {
    const action = `common/getConsentForms?facilityId=${facilityId}&userInfoId=${userInfoId}&appointmentId=`;
    return this.makeGetRequest(action, appointmentId || '');
  }

  public getInsuranceDetailsDocuments(facilityId: number, userInfoId: string, appointmentId: string): any {
    const action = `common/getInsuranceDetails?facilityId=${facilityId}&userInfoId=${userInfoId}&appointmentId=`;
    return this.makeGetRequest(action, appointmentId || '');
  }

  // Required fields get
  public findInvalidControls(_input: AbstractControl, _invalidControls: any[]): any[] {
    let en = FormControl;
    let z = FormGroup;
    let qr = FormArray;
    if (!_invalidControls) _invalidControls = [];
    if (_input instanceof FormControl || _input instanceof en) {
      if (_input.invalid) _invalidControls.push(_input);
      return _invalidControls;
    }

    if (!(_input instanceof FormArray || _input instanceof qr) && !(_input instanceof FormGroup || _input instanceof z)) return _invalidControls;

    const controls = _input.controls;
    for (const name in controls) {
      let control = controls[name];
      const obj = (control instanceof FormControl || _input instanceof z) ? { name, type: 'control' } : { name, type: 'group' };
      if (control.invalid) _invalidControls.push(obj);
      switch (control.constructor.name) {
        // case 'FormArray':
        //   (<FormArray>control).controls.forEach(_control => _invalidControls = this.findInvalidControls(_control, _invalidControls));
        //   break;
        case 'FormGroup':
        case 'z':
        case 'le':
          _invalidControls = this.findInvalidControls(control, _invalidControls);
          break;
      }
    }

    return _invalidControls || [];
  }

  public mmddyyyyFormatSplit(date: string): string {
    const dateSplit = date?.split('/');
    return (date && dateSplit && dateSplit?.length > 0) ? `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]}` : null;
  }

  // common clear form array
  public clearFormArray(formArray: FormArray): void {
    while (formArray?.length !== 0) {
      formArray.removeAt(0)
    }
  }

  // RAX/LAB/RAD order status common
  public statusCodeDesc(charStatus: string): string {
    if (!charStatus) {
      return '--'
    }
    charStatus = charStatus?.toUpperCase();
    let status: string;
    switch (charStatus) {
      case 'C':
        status = 'Corrected';
        break;
      case 'E':
        status = 'Entered';
        break;
      case 'F':
        status = 'Final Reported';
        break;
      case 'I':
        status = 'Inactive';
        break;
      case 'P':
        status = 'Partial Reported';
        break;
      case 'NA':
        status = 'Results Received';
        break;
      case 'TX':
      case 'R':
        status = 'Transmission Error';
        break;
      case 'T':
        status = 'Transmitted';
        break;
      case 'Completed':
      case 'COMPLETED':
        status = 'Completed';
        break;
      case 'Ordered':
      case 'ORDERED':
        status = 'Ordered';
        break;
      case 'InProgress':
        status = 'InProgress';
        break;
      case 'Cancelled':
        status = 'Cancelled';
    }
    return status;
  }

  // Save only Documented Resources or labels
  public sendOnlyDataGivenResourceTypes(requestPayload: any): any {
    const data = JSON?.parse(JSON?.stringify(requestPayload));
    const mainResourceTypeValues = [];
    data?.forEach((resourceTypeItem: any) => {
      if (resourceTypeItem?.sections && resourceTypeItem?.sections?.length > 0) {
        // START - Sections Loop
        const sectionsValues = [];
        resourceTypeItem?.sections?.forEach((secItem: any) => {
          if (secItem?.answers && (secItem?.answers?.length > 0 || secItem?.otherNotes)) {
            const actualValues = [];
            // START - Sections Answers Loop
            secItem?.answers?.forEach((ansSecItem: any) => {
              if ((ansSecItem?.actualValue && ansSecItem?.actualValue?.length > 0) || ansSecItem?.otherNotes) {
                actualValues?.push(ansSecItem);
              }
            });
            // END - Sections Answers Loop
            secItem.answers = actualValues || [];

            if (secItem.answers && (secItem.answers?.length > 0 || secItem?.otherNotes)) {
              sectionsValues?.push(secItem)
            }
          };
        });
        resourceTypeItem.sections = sectionsValues || [];
        if (resourceTypeItem?.sections && (resourceTypeItem?.sections?.length > 0 || resourceTypeItem?.otherNotes)) {
          mainResourceTypeValues?.push(resourceTypeItem);
        }
        // END - Sections Loop
      };
      delete resourceTypeItem?.sectionMetadata;
    });

    if (mainResourceTypeValues && mainResourceTypeValues?.length === 0) {
      mainResourceTypeValues?.push(data && data[0]);
    }

    return mainResourceTypeValues || data || [];
  }

  // customeize the Appointment Date Time...
  public getPayAppointmentTime(date: any): string {
    if (date && date?.includes('T')) {
      const splitDate = date?.toString()?.split('T');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const timeConvert = this.timeConvert(time) || '';
      const formateDate = this.convertAppointmentDate(splitDate && splitDate[0]);
      return `${formateDate}, ${timeConvert}`;
    } else if (date && date?.includes(' ')) {
      const splitDate = date?.toString()?.split(' ');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const timeConvert = this.timeConvert(time) || '';
      const formateDate = this.convertAppointmentDate(splitDate && splitDate[0]);
      return `${formateDate}, ${timeConvert}`;
    } else {
      return 'N/A'
    }
  }

  public validateLockAppointment(appointmentId?: string): any {
    const action = `treatmentFlow/validateLock?appointmentId=${appointmentId}`;
    return this.makeGetRequest(action, '');
  }

  public getFolowupDate(date: any): string {
    if (date) {
      const splitDate = date?.toString()?.split('T');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const timeConvert = this.timeConvert(time) || '';
      const formateDate = this.convertAppointmentDate(splitDate && splitDate[0]);
      return `${formateDate}`;
    } else {
      return 'N/A'
    }
  }

  public getFolowupTime(date: any): string {
    if (date && date?.includes('T')) {
      const splitDate = date?.toString()?.split('T');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const timeConvert = this.timeConvert(time) || '';
      const formateDate = this.convertAppointmentDate(splitDate && splitDate[0]);
      return `${timeConvert}`;
    } else if (date && date?.includes(' ')) {
      const splitDate = date?.toString()?.split(' ');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const timeConvert = this.timeConvert(time) || '';
      const formateDate = this.convertAppointmentDate(splitDate && splitDate[0]);
      return `${timeConvert}`;
    } else {
      return 'N/A'
    }
  }
  // Required fields get
  public findInvalidControlsWithFormArray(_input: AbstractControl, _invalidControls: any[]): any[] {
    if (!_invalidControls) _invalidControls = [];
    let en = FormControl;
    let z = FormGroup;
    let qr = FormArray;
    if (_input instanceof FormControl || _input instanceof en)  {
      if (_input.invalid) _invalidControls.push(_input);
      return _invalidControls;
    }

    if (!(_input instanceof FormArray || _input instanceof qr) && !(_input instanceof FormGroup || _input instanceof z)) return _invalidControls;

    const controls = _input?.controls;
    for (const name in controls) {
      let control = controls[name];
      const obj = (control instanceof FormControl  || control instanceof en) ? { name, type: 'control' } : { name, type: 'group' };
      if (control.invalid) _invalidControls.push(obj);
      switch (control.constructor.name) {
        case 'FormArray':
        case 'qr':
        case 'Or':
          (<FormArray>control).controls.forEach(_control => _invalidControls = this.findInvalidControls(_control, _invalidControls));
          break;
        case 'FormGroup':
        case 'z':
        case 'le':
          _invalidControls = this.findInvalidControls(control, _invalidControls);
          break;
      }
    }

    return _invalidControls || [];
  }

  public getCareflowDate(datevAL: string | number | Date): any {
    /*let currentDate = new Date(datevAL);
   let date = currentDate.getDate();
   let formatedate = ("0" + date).slice(-2);
   let month = currentDate.getMonth() + 1;
   let formateMonth = ("0" + month).slice(-2);
   let year = currentDate.getFullYear();
   let dateString = `${formateMonth}/${formatedate}/${year}`;
   return dateString;*/
    let currentDate = new Date(datevAL);
    let date = currentDate.getDate();
    let formatedate = ("0" + date).slice(-2);
    let month = currentDate.getMonth() + 1;
    let formateMonth = ("0" + month).slice(-2);
    let year = currentDate.getFullYear();
    let dateString = `${formateMonth}/${formatedate}/${year}`;
    return dateString;

  }

  // to remove spaces and speacia characters
  public removeSpaces(answerLabel: string): string {
    let checkSpChars = answerLabel?.replace(/[^a-zA-Z0-9]/g, '')
    return checkSpChars || answerLabel || '';
  }
  // Appointment Status Short form
  public getStatusShortName(status: string): string {
    status = status && status.toUpperCase();
    let returnStr: string;
    switch (status) {
      case 'SCHEDULED':
        returnStr = 'SCH';
        break;
      case 'CONFIRMED':
        returnStr = 'CON';
        break;
      case 'NO SHOW':
        returnStr = 'NOS';
        break;
      case 'COMPLETED':
        returnStr = 'COM';
        break;
      case 'CANCELLED':
        returnStr = 'CAN';
        break;
      case 'WALK-IN':
        returnStr = 'WIN';
        break;
      case 'DOCTOR CONSULTATION':
        returnStr = 'DCN';
        break;
      case 'NURSE GREET':
        returnStr = 'NUR';
        break;
      case 'CONSULTATION':
        returnStr = 'CNS';
        break;
      case 'TRIAGE':
        returnStr = 'TRI';
        break;
    }
    return returnStr || null;
  }

  // common Providers LIst API calling
  public getExistingProvidersByFacility(): void {
    if (!this.userDetails || !this.userDetails?.facilityId) {
      return;
    }
    const action = `provider/getProvidersListforfacility/${this.userDetails?.facilityId}`;
    this.allUsersListForFacility = [];
    this.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const allUsers = data?.responseObject;
        this.allUsersListForFacility = allUsers || [];
      }
    },
      (error) => {
      });
  }

  public getUserNameWithEmailId(emailId?: string): string {
    const getUser = this.allUsersListForFacility?.find((checkItem: any) => checkItem?.userId === emailId) || null;
    return getUser ? `${getUser?.firstName} ${getUser?.lastName}` : emailId;
  }

  public getDocumentUploadRequired(): void {
    sessionStorage?.setItem('documentUploadRequired', 'false');
    const action = `providerConfiguration/getGeneralConfig`;
    this.allUsersListForFacility = [];
    this.getRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const docReq = data?.responseObject?.allowDocUploadOnApptReg || false;
        const roomReq = data?.responseObject?.roomNumbers || [];
        //console.log("roomReq*****", roomReq);
        sessionStorage?.setItem('documentUploadRequired', JSON.stringify(docReq));
        sessionStorage?.setItem('roomNumbers', JSON.stringify(roomReq));
      }
    },
      (error) => {
      });
  }

  public formatTimeAMPM(time: any) {
    const splitTime = time?.split(':');
    let hours = splitTime[0];
    let minutes = splitTime[1];
    let ap = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.padStart(2, '0');
    let mergeTime = hours + ':' + minutes + ' ' + ap;
    return mergeTime;
  }

  // Care flow Navigation Path set
  public navigationDataSession(url: string, title: string, params?: any): void {
    const navigationDAta = {
      breadcrumbs: true,
      title,
      type: "item",
      url,
      params: params || null
    };
    sessionStorage.setItem('navigationPath', JSON.stringify(navigationDAta));
  }

  public getDefaultSSN(): void {

    const action = `providerConfiguration/getGeneralConfig`;
    this.getRequest(action)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const ssnInfo = data?.responseObject?.defaultSsn || null;

        sessionStorage.setItem('facilitySSN', ssnInfo);

      }
    },
      (error) => {
      });
  }


  public getShortcode(event: any, controlName: any) {

    const idVal = controlName + "_autofill";
    const vlaue = event.target.value;
    const oldValue = (document.getElementById(controlName) as HTMLInputElement).value;
    let valueSplit = [];
    var eachLine = vlaue.split('\n');
    if (eachLine.length > 0) {
      const lastLine = eachLine[eachLine.length - 1];
      valueSplit = lastLine.split(' ');
    }
    else {
      valueSplit = vlaue.split(' ');
    }
    const arrLength = valueSplit.length;
    const lastWord = valueSplit[arrLength - 1];
    valueSplit?.forEach((item: any, index: number) => {
      const word = item;
      if (word) {
        const shortWordsArr = this.allShortcodesForFacility;
       // console.log("shortWordsArr",shortWordsArr);
        const filteredWords = shortWordsArr?.filter(item => item?.shortCode === word) || [];
        let myNewWord = '';
        if (filteredWords && filteredWords.length > 0) {
          const newWord = vlaue + filteredWords[0]['description'];
          const searchWord = word + filteredWords[0]['description'];
          // const remainingArr = newWord.split(searchWord);
          // const lastNewText = remainingArr[remainingArr.length - 1];
          myNewWord = newWord.replace(searchWord, filteredWords[0]['description']);
          (document.getElementById(idVal) as HTMLInputElement).value = myNewWord;
        }
        else {
          (document.getElementById(idVal) as HTMLInputElement).value = '';
        }
      }
      else {
        (document.getElementById(idVal) as HTMLInputElement).value = '';
      }
    });
  }

  public setShortcode(event: any, controlName: any) {
    const idVal = controlName + "_autofill";
    //(document.getElementById(idVal) as HTMLInputElement).value = '';
    const oldWord = (document.getElementById(controlName) as HTMLInputElement).value;
    const word = (document.getElementById(idVal) as HTMLInputElement).value;
    if (word === '') {
      if (oldWord !== '') {
        (document.getElementById(controlName) as HTMLInputElement).value = oldWord;
        (document.getElementById(idVal) as HTMLInputElement).value = oldWord;
        return oldWord;
      }
      else {
        (document.getElementById(controlName) as HTMLInputElement).value = '';
        (document.getElementById(idVal) as HTMLInputElement).value = '';
        return '';
      }
    }
    else {
      (document.getElementById(controlName) as HTMLInputElement).value = word;
      (document.getElementById(idVal) as HTMLInputElement).value = '';
      return word;
    }
  }

  public getShortCodesList(): void {
    if (!this.userDetails || !this.userDetails?.facilityId) {
      return;
    }
    const action = `common/getShortCodes`;
    this.allShortcodesForFacility = [];
    this.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
      //  console.log("codesList",data?.responseObject);
        const allCodes = data?.responseObject;
        this.allShortcodesForFacility = allCodes || [];
      }
    },
      (error) => {
      });
  }

  // Common params for changeHealth/Orders
  public getChangeHealthOrders(rxPatientId?: any): any {
    const fromDate = new Date();
    fromDate?.setDate(fromDate?.getDate() - 2);
    const fDate = fromDate?.toLocaleDateString('en-US');

    const toDate = new Date();
    toDate?.setDate(toDate?.getDate() + 2);
    const tDate = toDate?.toLocaleDateString('en-US');

    const params = {
      "fromDate": fDate,
      "orderNumber": null,
      "orderStatus": null,
      "patientId": rxPatientId?.rxPatientId || "",
      "toDate": tDate
    }
    return params;
  }


  //This method is to validate manually entered date value
  public validateAppointmentDate(e: any, formGroup: FormGroup, fieldName: any): void {
    if (e.target.value != '' && e.target.value != null) {
      const date = new Date(e.target.value);
      if (date?.getFullYear() < this.currentYear) {
        return;
      }
      if (this.validateManualApptDate(e.target.value)) {
        formGroup?.get(fieldName)?.setValue(null);
      }
    }
  }

  public isPastDate(e: any, formGroup: FormGroup, fieldName: any): void {
    // Get the current date
    const currentDate = new Date();
    if (e.target.value != '' && e.target.value != null) {
      const inputDate = new Date(e.target.value);
    // Convert input date string to a Date object
    const enteredDate = new Date(inputDate);
  
    // Compare entered date with the current date
     if(enteredDate >= currentDate){
      formGroup?.get(fieldName)?.setValue(null);
     }else{
      return ;
     }
    }
  }

  public isPastDateEntry(inputDate:any): boolean {
    // Get the current date
    const currentDate = new Date();
    // if (e.target.value != '' && e.target.value != null) {
    //   const inputDate = new Date(e.target.value);
    // Convert input date string to a Date object
    const enteredDate = new Date(inputDate);
  
    // Compare entered date with the current date
     if(enteredDate <= currentDate){
      return false;
     }else{
      return true;
     }
   // }
  }

  //This method is to validate manually entered date value

  public validateManualApptDate(enteredDate: any): boolean {

    const selectedDate = moment.utc(enteredDate);
    const currentDate = moment.utc();

    const selectedDateOnlyDate = moment(selectedDate).format('YYYY-MM-DD');
    const currentDateOnlyDate = moment(currentDate).format('YYYY-MM-DD');

    if (moment(selectedDateOnlyDate).isSameOrAfter(currentDateOnlyDate)) {
      return false;
    } else {
      return true;
    }
  }

  // Change date field function with only value
  public changeAppntDateWithValValidation(selectedDate: any, formGroup?: FormGroup, controlName?: string): boolean {
    // const enteredDate = new Date(formGroup?.get(controlName)?.value || selectedDate);
    // if (enteredDate?.getFullYear() <= 1910) {
    //   return false;
    // }
    // if (this.validateManualApptDate(selectedDate, this.currentYear, this.currentMonth + 1, this.currentDate)) {
    //   formGroup?.get(controlName)?.setValue(null);
    //   return false;
    // }
    // return true;

    if(selectedDate==null){
      return false
    }
    return this.validateManualApptDate(selectedDate)
  }

  getEmbedConfig(endpoint: string): Observable<ConfigResponse> {
    return this.http?.get<ConfigResponse>(endpoint);
  }
  
  public addPrefixZipCode(addressZipCode: any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
   
    return prefixZipcode || addressZipCode || '';
  }

  public getnewPaymentTime(date: any): string {
    if (date) {
      const splitDate = date?.toString()?.split('T');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const timeConvert = this.timeConvert(time) || '';
      const formateDate = this.convertAppointmentDate(splitDate && splitDate[0]);
      return `${formateDate}`;
    } else {
      return 'N/A'
    }
  }

  public patientTrackBy(term: string = null): Observable<any> {
    if (!term) {
      return of([]).pipe(delay(500));
    }
    let searchParameter: string;
    if (term?.includes(',') || term.includes(' ')) {
      searchParameter = 'fullName';
      if (term.includes(' ')) {
        const newUserFirstName = term.split(' ')[0];
        const newUserLastName = term.split(' ')[1];
        term = `${newUserLastName},${newUserFirstName}`;
      }
    } else {
      searchParameter = 'lastName';
    }
    const action = `userInfo/patientSearch?searchParameter=${searchParameter || 'lastName'}&searchParameterValue=${term}`;
    let subItem = this.makeRequestWithAction(action, '');
    return subItem;
  }

  public getServiceDate(date: any){
    if (date) {
      const splitDate = date?.toString()?.split('T');
      const timeSplit = splitDate[1]?.split(':');
      const time = `${timeSplit[0]}:${timeSplit[1]}`;
      const formateDate = this.convertinvoiceDate(splitDate && splitDate[0]);
      return `${formateDate}`;
    } else {
      return null
    }
  }

}
