<div class="summary-inhouse-procedures-data">
    <div class="vaccination-sec">
        <ng-conatiner *ngFor="let procedure of inhouseProceduresData">
            <div class="inhouseLabs-bg">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Procedure Name</label> <span class="label-colon">
                                    : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.procedureName || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'Biopsies'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Biopsy Type</label> <span class="label-colon">
                                    : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.biospyType || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'Corneal Abrasion'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Encounter Type</label> <span class="label-colon">
                                    : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.encounterType || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-6 col-md-6 col-sm-12"
                        *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Laceration'">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Procedure Type</label> <span class="label-colon">
                                    : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{procedure?.procedureType || '--'}}</label>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">

                                <label class="labelStyle"
                                    *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">Procedure
                                    Ordered Date</label>
                                <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.procedureOrderedDate ?
                                    httpService?.getAppointmentTime(procedure?.procedureOrderedDate) : '--'
                                    || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12"
                        *ngIf="procedure?.procedureName === 'Nebulizer Treatment' || procedure?.procedureName === 'Electromyography Diagnostic' || procedure?.procedureName === 'EMG'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Performed Date</label>

                                <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.procedurePerformedDate ?
                                    httpService?.formatDobTimeZoneWithDisplay(procedure?.procedurePerformedDate)
                                    : '--'
                                    || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <ng-container
                                    *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Laceration'">
                                    <label class="labelStyle">Created Date</label> <span class="label-colon"> : </span>
                                </ng-container>
                                <ng-container
                                    *ngIf="procedure?.procedureName === 'ECG' || procedure?.procedureName === 'Laceration'">
                                    <label class="labelStyle">Performed
                                        Date</label> <span class="label-colon"> : </span>
                                </ng-container>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.createdDate ?
                                    httpService?.formatDobTimeZoneWithDisplay(procedure?.administeredDetails?.createdDate)
                                    : '--' || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Medication</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.medication || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Order Type</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.orderType === null ? '--' :
                                    procedure?.orderType[0]}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Nebulizer Time</label> <span class="label-colon">
                                    : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.duration.value || '--'}} <span
                                        *ngIf="procedure?.duration.value">mins</span></label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">CPT Code</label> <span class="label-colon">
                                    : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.adminCode || '--'}}</label>
                            </div>
                        </div>
                    </div>


                    <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Count</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{procedure?.administeredDetails?.count || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Position</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{procedure?.administeredDetails?.position || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Size</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{procedure?.administeredDetails?.size || '--'}}</label>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-lg-6 col-md-6 col-sm-12"
                        *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Nebulizer Treatment'">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Admin Code</label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{procedure?.administeredDetails?.adminCode || '--'}}</label>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'ECG'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Indication For ECG</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.indicationForECG || '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName === 'ECG'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">CPT Code</label> <span class="label-colon">
                                    : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.adminCode || '--'}}</label>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Vitals -->

                <div class="row" *ngIf="procedure?.procedureName === 'Nebulizer Treatment'">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5>Pulse Oximetry</h5>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Stage</th>
                                        <th>Pulse</th>
                                        <th>Oxygen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="procedure?.vitals?.length > 0">
                                        <tr *ngFor="let vital of procedure?.vitals">
                                            <td>{{vital?.stage || '--'}}</td>
                                            <td>{{vital?.pulse || '--'}}</td>
                                            <td>{{vital?.oxygen || '--'}}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="procedure?.vitals?.length === 0">
                                        <tr>
                                            <td colspan="3" class="text-center">Pulse Oximetry details are unavailable.
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- EMG - Nerve Condiiton -->
                <div class="row"
                    *ngIf="procedure?.procedureName === 'Electromyography Diagnostic' || procedure?.procedureName === 'EMG'">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5>Nerve Condition Study Details</h5>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Scope</th>
                                        <th>NCS Units</th>
                                        <th>NCS CPT/Procedure Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="procedure?.nerveConditionStudy?.length > 0">
                                        <tr *ngFor="let vital of procedure?.nerveConditionStudy">
                                            <td>{{vital?.scope || '--'}}</td>
                                            <td>{{vital?.ncsUnit || '--'}}</td>
                                            <td>{{vital?.ncsUnitCptCode || '--'}}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="procedure?.nerveConditionStudy?.length === 0">
                                        <tr>
                                            <td colspan="3" class="text-center">Nerve Condition Study Details are
                                                unavailable.</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Body Site -->
                <div class="row"
                    *ngIf="procedure?.procedureName !== 'ECG' && procedure?.procedureName !== 'Nebulizer Treatment' && procedure?.procedureName !== 'Electromyography Diagnostic' && procedure?.procedureName !== 'EMG'">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5>Body Site</h5>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Procedure Type</th>
                                        <th *ngIf="procedure?.procedureName === 'Injections and aspirations'">Joint Type
                                        </th>
                                        <th>Name</th>
                                        <th *ngIf="procedure?.procedureName === 'Incision and Drainage'">Nature of
                                            Lesion</th>
                                        <th
                                            *ngIf="procedure?.procedureName !== 'Trigger Point Injections' && procedure?.procedureName !== 'Injections and aspirations'">
                                            Depth</th>
                                        <th
                                            *ngIf="procedure?.procedureName !== 'Trigger Point Injections' && procedure?.procedureName !== 'Injections and aspirations' && procedure?.procedureName !== 'Corneal Abrasion'">
                                            Size</th>
                                        <th>Count/Units</th>
                                        <th>CPT/Procedure Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="procedure?.bodySite?.length > 0">
                                        <tr *ngFor="let site of procedure?.bodySite">
                                            <td>{{site?.procedureType || '--'}}</td>
                                            <td *ngIf="procedure?.procedureName === 'Injections and aspirations'">
                                                {{site?.jointType || '--'}}</td>
                                            <td>{{site?.name || '--'}}</td>
                                            <td *ngIf="procedure?.procedureName === 'Incision and Drainage'">
                                                {{site?.natureOfLesion || '--'}}</td>
                                            <td
                                                *ngIf="procedure?.procedureName !== 'Trigger Point Injections' && procedure?.procedureName !== 'Injections and aspirations'">
                                                {{site?.depth || '--'}}</td>
                                            <td
                                                *ngIf="procedure?.procedureName !== 'Trigger Point Injections' && procedure?.procedureName !== 'Injections and aspirations' && procedure?.procedureName !== 'Corneal Abrasion'">
                                                {{site?.revisedSize || site?.size || '--'}}</td>
                                            <td>{{site?.count || '--'}}</td>
                                            <td>{{site?.cptCode || '--'}}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="procedure?.bodySite?.length === 0">
                                        <tr>
                                            <td colspan="8" class="text-center">Body site details are unavailable.
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- ECG Interpretation -->
                <div class="row" *ngIf="procedure?.procedureName === 'ECG'">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5>Interpretation of ECG</h5>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Rate</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.ecgInterpretation?.rate || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Rhythm</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.ecgInterpretation?.rhythm || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Axis</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.ecgInterpretation?.axis || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">P Waves</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.ecgInterpretation?.pwaves || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">PR Interval</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.ecgInterpretation?.printerval || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">QRS Complex</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.ecgInterpretation?.qrscomplex || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">QT Interval</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.ecgInterpretation?.qtinterval || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">ST-Segment</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.ecgInterpretation?.stsegment || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">T Waves</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.ecgInterpretation?.twaves || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Impression</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-9 col-md-9 col-sm-9"> -->
                                <label>{{procedure?.ecgimpression || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Comments/Notes</label> <span class="label-colon">
                                    : </span>
                                <!-- </div>
                            <div class="col-lg-9 col-md-9 col-sm-9"> -->
                                <label>{{procedure?.administeredDetails?.administrationNotes ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Administartion Details -->
                <div class="row" *ngIf="procedure?.procedureName !== 'ECG'">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5>Administration Details</h5>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h6 class="mt-2"><b>With NPI</b></h6>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.npiDetails?.performedBy ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.npiDetails?.dateTime ?
                                    httpService?.getAppointmentTime(procedure?.administeredDetails?.npiDetails?.dateTime)
                                    : '--' || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.npiDetails?.reason ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h6 class="mt-2"><b>Consent Documented</b></h6>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.consentDetails?.performedBy ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.consentDetails?.dateTime ?
                                    httpService?.getAppointmentTime(procedure?.administeredDetails?.consentDetails?.dateTime)
                                    : '--' || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.consentDetails?.reason ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12"
                        *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                        <h6 class="mt-2"><b>Education Materials Provided</b></h6>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Performed By</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.educationDetails?.performedBy ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Date</label> <span class="label-colon"> : </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.educationDetails?.dateTime ?
                                    httpService?.getAppointmentTime(procedure?.administeredDetails?.educationDetails?.dateTime)
                                    : '--' || '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="procedure?.procedureName !== 'Nebulizer Treatment'">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">Reason</label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.educationDetails?.reason ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle"><b>Comments/Notes</b></label> <span class="label-colon"> :
                                </span>
                                <!-- </div>
                            <div class="col-lg-9 col-md-9 col-sm-6"> -->
                                <label>{{procedure?.administeredDetails?.administrationNotes ||
                                    '--'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-conatiner>
        <ng-conatiner *ngIf="!inhouseProceduresData || inhouseProceduresData?.length === 0">
            <span>Inhouse Procedures do not apply to this patient’s record.</span>
        </ng-conatiner>
    </div>
</div>