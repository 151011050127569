import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, AfterViewInit {

  @Input() addressForm: FormGroup;
  @ViewChild("searchStreet") public searchElementRef: ElementRef;
  public currentLocation: string;
  public latitude: number;
  public longitude: number;
  @Input() hideZipCode: boolean;
  @Input() isRequiredFields: boolean;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { }

  ngOnInit(): void {
 
    this.addressForm?.get('zipCode').setValue(this.addPrefixZipCode(this.addressForm?.get('zipCode').value,''));
  }

  ngAfterViewInit() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.setCurrentPosition();
        });
      });
    });
  }

  private setCurrentPosition(): void {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng = {
        lat: this.latitude,
        lng: this.longitude
      };
      geocoder.geocode({
        'location': latlng
      }, (results) => {
        if (results[0]) {
          this.currentLocation = results[0].formatted_address;
          this.setZipCode(results);
        } else {
          console.log('Not found');
        }
      });
    });
  }

  private setZipCode(results): void {
    this.addressForm?.get('street').setValue(null);
    let street = '';
    for (let i = 0; i < results.length; i++) {
      for (var j = 0; j < results[i].address_components.length; j++) {
        for (var k = 0; k < results[i].address_components[j].types.length; k++) {
          
          if (results[i].address_components[j].types[k] == "street_number") {
            let street_num = results[i].address_components[j].long_name;
            street += `${street_num} `;
          }
          if (this.addressForm?.get('street').value === null && results[i].address_components[j].types[k] == "route") {
            let route = results[i].address_components[j].long_name;
            street += `${route}`;
            if(this.addressForm?.get('street').value === null){
              this.addressForm?.get('street').setValue(street);
            }
          }

          if (results[i].address_components[j].types[k] == "postal_code") {
            const zipCode = results[i].address_components[j].short_name;
            const zipcodeUpdate = this.addPrefixZipCode(zipCode,'');
            this.addressForm?.get('zipCode').setValue(zipcodeUpdate || zipCode);
          }

          if (results[i].address_components[j].types[k] == "country") {
            const country = results[i].address_components[j].long_name;
            this.addressForm?.get('country').setValue(country);
          }

          if (results[i].address_components[j].types[k] == "administrative_area_level_1") {
            const state = results[i].address_components[j].short_name;
            this.addressForm?.get('state').setValue(state);
          }

          if (results[i].address_components[j].types[k] == "locality") {
            const city = results[i].address_components[j].long_name;
            this.addressForm?.get('city').setValue(city);
          }
        }
      }
    }
  }
      // Add PRefix of Zero before the zipcode if below 4 digits
      public addPrefixZipCode(addressZipCode: any,valid:any): any {
        let prefixZipcode = addressZipCode;
        if (prefixZipcode) {
          const checkLen = prefixZipcode?.toString()?.length;
          if (checkLen === 4) {
            prefixZipcode = '0' + prefixZipcode;
          }
          if (checkLen === 3) {
            prefixZipcode = '00' + prefixZipcode;
          }
        }
        if(valid){
          this.addressForm?.get('zipCode')?.setValue(prefixZipcode || addressZipCode || '');
        }
        
        return prefixZipcode || addressZipCode || '';
      }
  
    

}
