<div class="encounters-list">
    <!-- View all apppintments for Medical record download -->
    <app-ui-modal #medicalRecordsView [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
        id="medicalRecordsView">
        <div class="app-modal-header">
            <h5 class="modal-title">Encounters</h5>
            <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                (click)="medicalRecordsView?.hide();"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="app-modal-body add-app-body">
            <div class="table-responsive new-designed-table">
                <table datatable [dtOptions]="dtOptionsAppointmentsList" [dtTrigger]="dtTriggerAppointmentsList"
                    class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Service Date </th>
                            <th>Service Provider</th>
                            <th>Purpose Of Visit</th>
                            <th>Status </th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let appointment of allEncountersListUser; let i=index">
                            <!-- <input type="hidden" #referralSignature_{{appointment?.id}}
                            name="referralSignature_{{appointment?.id}}" id="referralSignature_{{appointment?.id}}"
                            value=""> -->
                            <td>{{getAppointmentTime(appointment?.appointment?.dateTime) || '--'}} </td>
                            <td>{{appointment?.appointment?.authorized_official_last_name}}
                                {{appointment?.appointment?.authorized_official_first_name}}</td>
                            <td>{{appointment?.appointment?.purposeOfVisit}}</td>
                            <td class="appointment-status-clrs">
                                <ng-container>
                                    <span class="status-clr"
                                        [class]="getStatus(appointment?.appointmentStatus?.currentStatus, appointment)">{{getStatus(appointment?.appointmentStatus?.currentStatus,
                                        appointment) || '--'}} </span>
                                </ng-container>
                            </td>

                            <td class="text-center">
                                <button class="icon-queue-events signoff-action-bg"
                                    (click)="openSignatureModal(signatureModal, appointment?.id, appointment?.appointment?.authorized_official_first_name, appointment?.appointment?.authorized_official_last_name);medicalRecordsView?.hide()"
                                    placement="left" ngbTooltip="Medical Record"><span
                                        class="material-symbols-outlined">download</span></button>

                                <!-- <button class="icon-inqueue-actions"
                                (click)="openSignatureModal(signatureModal,patientDetailsForm?.get('authorized_official_first_name')?.value,patientDetailsForm?.get('authorized_official_last_name')?.value)"><i
                                    class="las la-eye "></i>&nbsp; Medical Record for Referral </button> -->
                                <!--<button class="icon-inqueue-actions" (click)="downLoadLastAppointmentDetails(medicalRecordModal)"><i class="las la-eye "></i>&nbsp; Medical Record for Referral </button>-->
                                <!-- <button class="icon-inqueue-actions"
                                (click)="openInitiateSendFaxModal(sendNewFaxEmail, signatureCheckModal)"><i
                                    class="las la-clone"></i>&nbsp; Initiate Referral </button> -->

                                <!-- <div class="dropdown-actions">
                                <button class="icon-queue-events more-actions-hover-icon"><i
                                        class="las la-ellipsis-h"></i></button>
                                <div class="dropdown-content-actions">
                                    <a (click)="openSignatureModal(signatureModal,appointment?.appointment?.authorized_official_first_name,appointment?.appointment?.authorized_official_last_name,appointment?.id)"><i class="las la-eye "></i> &nbsp; Medical Record for Referral </a>
                                    <a (click)="openInitiateSendFaxModal(sendNewFaxEmail, signatureCheckModal,appointment)"><i class="las la-clone"></i> &nbsp; Initiate Referral</a>
                                </div>
                            </div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="app-modal-footer">
            <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
                (click)="medicalRecordsView?.hide()">Ok</button>
        </div>
    </app-ui-modal>
</div>

<!-- Signature form -->
<app-ui-modal #signatureModal [hideHeader]="false" [modalCentered]="true" id="signatureModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Electronically Signed by {{this.providerLastName}}, {{this.providerFirstName}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="signatureModal?.hide();clearPad()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <form [formGroup]="signatureForm">
            <div class="signatureModal">
                <input type="hidden" class="form-control" formControlName="signature" value="{{signatureImg}}">
                <canvas hidden id="textCanvas"></canvas>
                <div class="text-center">
                    <strong>E-Signature:</strong> <br><br>
                    <div class="signature" #signature_section id="image-section">
                        <h5><i>{{this.providerLastName}}, {{this.providerFirstName}}</i></h5>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok2"
            (click)="downloadMedicalRecord(selectedappointmentId, signatureModal, medicalRecordModal)">Ok</button>
    </div>
</app-ui-modal>

<!-- Display the Medical Recods PDF -->
<app-ui-modal #medicalRecordModal [hideHeader]="false" [modalCentered]="true" id="medicalRecordModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Medical Record</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="medicalRecordModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="medical-record-pdf" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok3"
            (click)="medicalRecordModal.hide()">Ok</button>
    </div>
</app-ui-modal>