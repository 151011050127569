import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-notification-request-response',
  templateUrl: './notification-request-response.component.html',
  styleUrls: ['./notification-request-response.component.scss']
})
export class NotificationRequestResponseComponent implements OnInit {

  public patientNotificationRequestForm: FormGroup;
  @Input() userInfoId: string;
  @Input() cardReload: CardComponent;
  notifyText: any;
  public isSuccessNotify: boolean;
  loadSpinner: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalModal: ExceptionModalComponent;
  @ViewChild('notificationRequestModal') requestModal: UiModalComponent;
  @Input() userJournalNotes: any;
  @Input() allRolesTypes: any;
  public yesOrNoString = [
    { value: 'Yes', desc: 'Yes' },
    { value: 'No', desc: 'No' }
  ];
  public documentUploadChannel: boolean;
  public allProviderDetails: any[] = [];
  public loginDetails: any;
  @Input() userInfoData: any;
  public journalNotesChannelList = ['Phone Call', 'SMS'];
  public typeRequest: any;
  @Output() reloadJournalNotes = new EventEmitter<any>();
  public ownerName: any;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
	this.ownerName = this.loginDetails.lastName + " " + this.loginDetails.firstName ;
    this.patientNotificationRequestForm = this.formBuilder?.group({
      "channel": [null, Validators?.required],
      "node": [null],
      "notes": [null, Validators?.required],
      "notifyResponder": [null, Validators?.required],
      "responderNPI": [null, Validators?.required],
      "responderName": [null],
      "responderRole": [null]
    });
  }

  ngOnInit(): void {
   /* const allDoctors = JSON?.parse(sessionStorage?.getItem('waitTimeDetails'))?.eligibilityDetails?.doctor?.map((doctorItem: any) => {
      const firstName = doctorItem?.basic?.authorized_official_first_name || '';
      const lastName = doctorItem?.basic?.authorized_official_last_name || '';
      return {
        name: `${firstName} ${lastName}`,
        npi: doctorItem?.number
      }
    }) || [];
    this.allProviderDetails = allDoctors || [];*/
    this.patientNotificationRequestForm?.get('notifyResponder')?.setValue('Yes');
    this.getExistingProvidersByFacility();
	
  }
  ngAfterViewInit(): void {
    
  }
  
  public getExistingProvidersByFacility(): void {
    const action = `provider/getProvidersListforfacility/${this.loginDetails?.facilityId}`;
    this.allProviderDetails = [];
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
	  //const allUsers = data?.responseObject?.filter(item => item?.active) || [];
    const allUsers = data?.responseObject;
	  
	  const allDoctors = allUsers?.map((doctorItem: any) => {
	  //console.log(doctorItem);
      const firstName = doctorItem?.firstName || '';
      const lastName = doctorItem?.lastName || '';
      const asssigneeUserId = doctorItem?.userId;
      return {
        name: `${lastName}, ${firstName}`,
        npi: doctorItem?.providerNPI,
		    responderRole: doctorItem?.roleType,
        asssigneeUserId: asssigneeUserId,
			  userId: doctorItem?.userId
      }
    }) || [];
    this.allProviderDetails = allDoctors || [];
	  
	 /* const allUsers = data?.responseObject?.filter(item => item?.active) || [];
        allUsers.forEach((element: any, index: number) => {
		
          const providerName = element.firstName + " " + element.lastName;
          element.firstName = providerName;

        });
        this.allProviderDetails = allUsers || [];*/
		

      }

      this.loadSpinner = false;
    },
      (error) => {

        this.loadSpinner = false;
      });
  }

  public changeNotifyResponder(event: any): void {
    const responserValue = this.patientNotificationRequestForm?.get('notifyResponder')?.value;
    if (responserValue === 'Yes') {
      this.patientNotificationRequestForm?.get('responderNPI')?.setValidators([Validators?.required]);
    } else {
      this.patientNotificationRequestForm?.get('responderNPI')?.reset();
      this.patientNotificationRequestForm?.get('responderNPI')?.setValidators(null);
      this.patientNotificationRequestForm?.get('responderNPI')?.clearValidators();
    }
    this.patientNotificationRequestForm?.get('responderNPI')?.updateValueAndValidity();
  }

  // open request modal
  public openRequestModal(type?: any): void {
    this.patientNotificationRequestForm?.get('notifyResponder')?.setValue('Yes');
    this.requestModal?.show();
    this.typeRequest = type;
  }

  // save patient journal notes
  public savePatientJournalNotesRequest(patientJournalNotes: UiModalComponent): void {
   // this.patientNotificationRequestForm?.get('responderRole')?.setValue('Doctor');
    if (this.patientNotificationRequestForm?.invalid) {
      this.patientNotificationRequestForm?.markAllAsTouched();
      return;
    }
    this.patientNotificationRequestForm?.get('userInfoId')?.setValue(this.userInfoId);
    
    // const params = this.patientNotificationRequestForm?.value;
   
    this.loadSpinner = true;
	const notifyResponder = this.patientNotificationRequestForm?.get('notifyResponder')?.value;
	const responderRole = this.patientNotificationRequestForm?.get('responderRole')?.value;
	const responderName = this.patientNotificationRequestForm?.get('responderName')?.value;
	const description = this.patientNotificationRequestForm?.get('notes')?.value;
	const channel = this.patientNotificationRequestForm?.get('channel')?.value;
	const taskType = "Patient Communication";
	const category = "Patient Query";

  const assigneeValue = this.patientNotificationRequestForm?.get('responderNPI')?.value;
	
	let action = null;
	let params = null;
	
	if(notifyResponder === 'Yes')
	{
	 action = `tasks/createTasks`;
     params = {
      "role": responderRole,
	  "channel": channel,
        "taskDetails": {
          "assignee": responderName,
          "asssigneeUserId": assigneeValue,
          "category": category,
          "createdBy": this.ownerName,
          "description": description,
          "priority": "Medium",
          "status": "Open"
        },
        "taskType": taskType,
        "userInfoId": this.userInfoId
    }
	
	}
	else{
	
	 action = `userInfo/savePatientJournalNotesRequest`;
     params = {
      "appointmentId": this.typeRequest?.id || null,
      "facilityId": this.loginDetails?.facilityId,
      "notesRequest": this.patientNotificationRequestForm?.value,
      "notesResponse": null,
      "patientFirstName": this.userInfoData?.firstName,
      "patientLastName": this.userInfoData?.lastName,
      //"type": this.typeRequest?.documentType || this.typeRequest || "Patient Journal Notes",
      "type": this.typeRequest?.documentType ||  "Patient Journal Notes",
      "uniqueId": null,
      "userInfoId": this.userInfoId
      }
	
	}
    
	
	this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message || data?.body?.msg;
        this.isSuccessNotify = true;
        this.exceptionalModal?.modalShow();
        this.reloadJournalNotes?.emit(true);
        this.resetPatientJournalNotesForm();
      } else {
        this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
      }
      patientJournalNotes?.hide();
      this.loadSpinner = false;
    }, (error) => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.loadSpinner = false;
    });
	
	
	
	
  }

  public resetPatientJournalNotesForm(): void {
    this.patientNotificationRequestForm?.reset();
    // this.patientNotificationRequestForm?.get('userInfoId')?.setValue(this.userInfoId);
  }

  public changeProviderDetails(event: any): void {
  
    this.patientNotificationRequestForm?.get('responderName')?.setValue(event?.name);
    this.patientNotificationRequestForm?.get('responderRole')?.setValue(event?.responderRole);
  }

}
