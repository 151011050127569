import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from "@angular/common";
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { CardComponent } from '../../components/card/card.component';
import { Subject, Subscription } from 'rxjs';
import { ApisService } from '../../services/apis.services';
//import { AccountBalanceComponent } from '../account-balance/account-balance.component';


@Component({
  selector: 'app-billing-codes',
  templateUrl: './billing-codes.component.html',
  styleUrls: ['./billing-codes.component.scss']
})
export class BillingCodesComponent implements OnInit, OnChanges {

  public dtOptionsBillingcodes: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public dtTriggerBillingcodes: Subject<any> = new Subject<any>();

  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public loadSpinner: boolean;
  @Input() cardReload: CardComponent;
  public createBilling: boolean;
  public billingcodesForm: FormGroup;
  public cptCodeList = [];

  // public userInfoId: string;
  @Input() selectedUserInfoId: string;
  @Input() appointmentId: string;
  public facilityId: string = '';
  public loginDetails: any;
  public billingList = [];
  // public paidAmount = 0;
  @Input() paidAmount: 0;
  public totalSelectedFee = 0;
  public pendingFee = 0;
  
  @Output() updateAmountPaid = new EventEmitter<any>();
  //@ViewChild(AccountBalanceComponent) public accBal: AccountBalanceComponent;
  constructor(public httpService: HttpService, public router: Router, public FormBuilder: FormBuilder, private apiService: ApisService) {
    this.billingcodesForm = this.FormBuilder.group({
      'cptCode': [null, Validators.required],
      'cptCodeDescription': [null, Validators.required],
      'selfpayFee': [null, Validators.required],
      'totalFee': [null]
    })

  }

  ngOnInit(): void {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.facilityId = this.loginDetails.facilityId;

    this.dtOptionsBillingcodes = {
      pagingType: 'simple_numbers',
      pageLength: 5,
      autoWidth: true,
      lengthChange: false,
      order: [[0, "desc"]],
      columnDefs: [{ targets: [0], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    //this.getAmountPaidForAppointment();
    this.getBillingCodesList();
    this.getCPTCodesList();
    //this.getAppointmentById();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.paidAmount) {
        this.pendingFee = this.totalSelectedFee - this.paidAmount;
      }
    }
  }

  public rerender(): void {
    this.dtElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();

    });
  }

  public showHideForm() {
    this.clearForm();
    this.createBilling = !this.createBilling;

  }

  /* public getAppointmentById(): void {
     if (!this.appointmentId) {
       return;
     }
     let action: any = 'appointment/registration/getAppointmentById?id=';
     let params: any = {
       'id': this.appointmentId
     }
     this.httpService.getPatientsByFacilityId(action, params.id)
       .subscribe((data: any) => {
         if (data.status == 'SUCCESS') {
           const dtPatientDetails = JSON.parse(JSON.stringify(data?.responseObject)) || null;
           if (dtPatientDetails) {
 
             this.userInfoId = dtPatientDetails?.userInfoId;
       //alert(this.userInfoId);
     	
 
           }
 
         }
 
       }, error => {
 
       });
   }*/


  public clearForm() {
    this.billingcodesForm.reset();
    this.createBilling = false;
  }

  public getCPTCodesList(): void {
    const action = `common/listAllCPTCodes`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.cptCodeList = data.responseObject || [];
    },
      (error) => {
      })
  }

  public getBillingCodesList(): void {

    const action = `common/listAllCPTCodesFeeDetails?appointmentId=${this.appointmentId}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {

      if (data?.responseObject && data?.responseObject !== null) {
        this.billingList = data?.responseObject;
        let sum = 0;
        this.billingList.forEach(element => {

          const selfPayFee = element.selfPayFee;
          sum += parseInt(selfPayFee, 10);

        });
        this.totalSelectedFee = sum;
        this.pendingFee = this.totalSelectedFee - this.paidAmount;


      }
      else {
        this.billingList = [];
      }


    },
      (error) => {


      })
  }

  public getCPTcodeInfo(event) {
    this.billingcodesForm?.get('cptCodeDescription')?.setValue(event.codeDescription);
    this.billingcodesForm?.get('selfpayFee')?.setValue(event.fee);

  }

  public saveBillingCodes(): void {
    if (this.billingcodesForm?.invalid) {
      this.billingcodesForm?.markAllAsTouched();
      return;
    };

    // this.loadSpinner = true;
    const cptCode = this.billingcodesForm?.get('cptCode').value;
    const cptCodeDescription = this.billingcodesForm?.get('cptCodeDescription').value;
    const selfpayFee = this.billingcodesForm?.get('selfpayFee').value;
    //const rowtotalFee = this.billingcodesForm?.get('totalFee').value;
    //const totalFee = selfpayFee + rowtotalFee;
    const totalFee = this.totalSelectedFee + selfpayFee;

    const cptCodeList = {
      'cptCode': cptCode,
      'cptCodeDescription': cptCodeDescription,
      'selfPayFee': selfpayFee
    };
    const selfpayDto = {
      'appointmentId': this.appointmentId,
      'cptCodeList': [cptCodeList],
      'facilityId': this.facilityId,
      'totalFee': totalFee,
      'userInfoId': this.selectedUserInfoId,
    };

    // const action = `common/saveSelfpayBillingcodes`;
    this.apiService?.saveSelfPayBillingCodesPayments(selfpayDto).subscribe((data: any) => {
      // this.loadSpinner = false;
      this.getBillingCodesList();

      // newBillingCode.hide();
      // this.closeBillingCodePopup();
      this.clearForm();
      if (data?.body?.status == 'SUCCESS') {
        // this.notifyText = data?.body?.msg || data?.body?.message || 'The billing code was added successfully.';
        this.notifyText = 'The billing code was added successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.updateAmountPaid?.emit(true);
        //this.accBal?.getAppointmentCost();
      } else {
        this.notifyText = data?.body?.msg || data?.body?.message || 'Something went wrong. Please try again later.';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    },
      error => {
        this.notifyText = error?.msg || error?.message || 'Something went wrong. Please try again later.';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();

      });
  }

  /*public openBillingcodeNew(newBillingCode: UiModalComponent): void {

    newBillingCode?.show();
  }
  
  public closeBillingCodePopup() {
    this.billingcodesForm.reset();
    this.loadSpinner = false;
  }*/

  public formateISOdateTime(date): any {
    const currentLocalDate = new Date().toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    const currentTime = new Date().toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit'
    });
    const splitDate = currentLocalDate.split('/');
    const dateConvert = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
    return `${dateConvert}T${currentTime}`;
  }

  // Delete selected code
  public deleteSelectedCode(billing) {
    const selfPayFee = billing.selfPayFee;
    const totalFee = this.totalSelectedFee - selfPayFee;
    const cptCodeList = {
      'cptCode': billing.cptCode,
      'cptCodeDescription': billing.cptCodeDescription
    };
    const selfpayDto = {
      'appointmentId': this.appointmentId,
      'cptCodeList': [cptCodeList],
      'facilityId': this.facilityId,
      'totalFee': totalFee,
      'userInfoId': this.selectedUserInfoId,
    };

    // const action = `common/deleteSelfpayBillingcodes`;
    this.apiService?.deleteSelfPayBillingCodesPayments(selfpayDto).subscribe((data: any) => {
      // this.loadSpinner = false;
      this.getBillingCodesList();
      this.totalSelectedFee = totalFee;
      if (data?.body?.status == 'SUCCESS') {
        this.notifyText = 'The billing code was deleted successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.updateAmountPaid?.emit(true);
      } else {
        this.notifyText = data?.body?.msg || data?.body?.message || 'Something went wrong. Please try again later.';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    },
      error => {
        this.notifyText = error?.msg || error?.message || 'Something went wrong. Please try again later.';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();

      });
  }


  ngOnDestroy(): void {
    if (this.dtTriggerBillingcodes) {
      this.dtTriggerBillingcodes?.unsubscribe();
    }

  }

}
