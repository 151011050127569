<app-ui-modal #rescheduleAppointmentModal [hideHeader]="false" [loadSpinner]="loadCard" [modalCentered]="true" id="rescheduleAppointmentModal">
    <div class="app-modal-header">
        <h5 *ngIf="!followUp" class="modal-title">Re-schedule Appointment</h5>
        <h5 *ngIf="followUp" class="modal-title">Schedule Follow Up Appointment</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="rescheduleAppointmentModal.hide();followUpEmitter(true);rescheduleForm?.reset()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body" [formGroup]="rescheduleForm">
        <div class="form-group add-appointment-sectons">
            <ng-container>

                <div class="form-group">
                    <label class="form-label">Purpose of Visit <span class="required">*</span></label>
                    <ng-select placeholder="Choose Purpose of Visit" id="reSchedule_pov" [items]="addPatientPurposeVisit" formControlName="PURPOSE_OF_VISIT" (change)="getDoctorsList()"></ng-select>
                    <label class="required" *ngIf="rescheduleForm?.controls['PURPOSE_OF_VISIT']?.hasError('required') && rescheduleForm?.controls['PURPOSE_OF_VISIT']?.touched">Purpose of Visit is required</label>
                </div>

                <div class="form-group" *ngIf="!isUrgentCare">
                    <label class="form-label">Service Provider <span class="required">*</span></label>
                    <ng-select placeholder="Choose Service Provider" id="reSchedule_service-provider" (change)="ifServiceProviderChange($event)"
                        [items]="allServiceProvidersList" formControlName="DOCTOR" bindLabel="doctorName"
                        bindValue="npi"></ng-select>
                    <label class="required"
                        *ngIf="rescheduleForm?.controls['DOCTOR']?.hasError('required') && rescheduleForm?.controls['DOCTOR']?.touched">Service Provider is required</label>
                </div>
                <!-- onkeydown="return false" -->
                <div class="form-group">
                    <label>Appointment Date <span class="required">*</span></label><br />
                    <input type="date" (change)="changeAppntDateWithValidation(rescheduleForm, 'APPOINTMENT_DATE')" name="datemin"
                        [min]="minAppointmentDate" (input)="inputAppntDateValidation($event, rescheduleForm, 'APPOINTMENT_DATE')" class="form-control input-text" id="reSchedule_visit-date"
                        formControlName="APPOINTMENT_DATE" placeholder="Appointment Date">
                    <span class="required"
                        *ngIf="rescheduleForm.get('APPOINTMENT_DATE').errors?.required && rescheduleForm.get('APPOINTMENT_DATE').touched">Appointment
                        Date is Required</span>
                        <span
                            *ngIf="rescheduleForm?.get('APPOINTMENT_DATE').errors?.invalidDate && rescheduleForm?.get('APPOINTMENT_DATE').touched"
                            class="text-danger">
                            Invalid date
                        </span>
                        
                </div>

                <div class="form-group">
                    <label class="form-label">Appointment Time <span class="required">*</span></label>

                    <ng-select placeholder="Choose Slot" id="reSchedule_Apptime" (change)="changeSlotTime()" [items]="getAppointmentTimeReschedule" formControlName="APPOINTMENT_TIME" bindLabel="descStartTime" bindValue="startTime"></ng-select>

                    <!-- <select class="form-control input-text" formControlName="APPOINTMENT_TIME" name="validation-cmbGear">
                        <option [value]="null" [selected]='true' [disabled]="true">Select Slot</option>
                        <option *ngFor="let slot of getAppointmentTimeReschedule" value="{{slot?.startTime}}">
                            {{slot?.startTime}}
                        </option>
                    </select> -->
                    <label class="required"
                        *ngIf="rescheduleForm.controls['APPOINTMENT_TIME'].hasError('required') && rescheduleForm.controls['APPOINTMENT_TIME'].touched">Appointment
                        Time is required</label>                      
                </div>

            </ng-container>

        </div>

        <!-- spinner for popup -->
        <!-- <div class="custom-loader justify-content-center" *ngIf="loadCard">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div> -->
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="rescheduleAppointmentModal.hide();followUpEmitter(true);rescheduleForm?.reset()" id="reSchedule_cancel">Cancel</button>
        <button class="btn btn-primary event-btn dash-actions" style="margin-right: 15px;" type="button" id="reSchedule_submit"
            (click)="reschduleUpdate(rescheduleAppointmentModal)">Submit
            <!-- <span *ngIf="this.rescheduleAppntClick" class="spinner-border spinner-border-sm" role="status"></span>
            <span *ngIf="this.rescheduleAppntClick" class="load-text">Submit...</span>
            <span *ngIf="!this.rescheduleAppntClick" class="btn-text">Submit</span> -->
        </button>

    </div>
</app-ui-modal>

<!-- notify popup -->

<app-exception-modal [notifyText]="notifyText" [redirectFlag]="redirectFlag"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>