<div class="insurance-view row view-encounter-details">
    <div class="col-md-12 ins-col">
        <h5 class="title">
            Insurance Details
        </h5>
        <div class="row enc-form">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="label">
                                Coverage Type<span>:</span>
                            </label>
                            <label class="label-val"
                                *ngIf="appointmentInfoDetails?.unInsured === true">Un-Insured</label>
                            <label class="label-val" *ngIf="appointmentInfoDetails?.selfPay === true">Self-Pay</label>
                            <label class="label-val" *ngIf="appointmentInfoDetails?.healthPass === true">Health
                                Pass</label>
                            <label class="label-val"
                                *ngIf="appointmentInfoDetails?.unInsured === false && appointmentInfoDetails?.selfPay === false && appointmentInfoDetails?.healthPass === false">Insurance</label>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <button class="btn btn-primary" (click)="editViewInsurnaceDetails(null, null, commonNewAddInsuEditInsurance, false)">+ ADD NEW INSURANCE</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="row enc-form enc-bg"
            *ngFor="let insurance of appointmentInfoDetails?.insuranceDetails; let i = index">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Insurance Name <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.name || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Payer ID <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.payerId || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Coverage Sequence <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.priority || '--'}}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Member ID / <br>
                                        Subscriber ID <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.memberId || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Group Number <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.groupNumber || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Insurance Class <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.insuranceClass || '--'}}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-10 ins-card">
                                        <button *ngIf="insurance?.active"
                                            class="btn btn-outline-secondary active">Active</button>
                                        <button *ngIf="!insurance?.active"
                                            class="btn btn-outline-secondary inActive">Inactive</button>
                                        <div style="width: 78%;height: 100px;" class="d-flex" *ngIf="insurance?.insuranceCardImageId">
                                            <ng-container *ngIf="insurance?.docType === 'application/pdf'">
                                                <iframe id="{{insurance?.insuranceCardImageId}}_edit_insu_claims" frameBorder="0" width="100%"
                                                    height="100%"></iframe>
                                            </ng-container>
                                            <ng-container *ngIf="insurance?.docType !== 'application/pdf'">
                                                <img style="width: 100%;height: 100%;" name="View-Edit-Insu"
                                                    id="{{insurance?.insuranceCardImageId}}_edit_insu_claims" />
                                            </ng-container>

                                            <!-- <iframe id="{{insurance?.insuranceCardImageId}}_edit_insu_claims" frameBorder="0" width="100%" height="auto"></iframe> -->
                                            <!-- <img style="width: 100%;height: 100%;"
                                                src="../../../../../assets/images/insc.jpg"> -->
                                            <span class="zoom-ic ml-2">
                                                <button placement="bottom" ngbtooltip="Full View" (click)="fullViewExpand(insurance?.fileUrl, fullViewInsuranceCardClaims)"
                                                    class="icon-queue-events signoff-action-bg clearfix">
                                                    <i class="las la-expand"></i>
                                                </button>
                                            </span>
                                        </div>
                                        <div class="download-insurance-card mt-4"
                                            *ngIf="!insurance?.insuranceCardImageId">
                                            <h5 class="download-insurance">Insurance card not available</h5>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="dropdown-actions">
                                            <button style="float: right;"
                                                class="icon-queue-events more-actions-hover-icon"><span>...</span></button>
                                            <div class="dropdown-content-actions past">
                                                <a href="javascript:" (click)="editViewInsurnaceDetails(insurance, i, commonNewAddInsuEditInsurance, true)"><i class="las la-pencil-alt"></i>Edit</a>
                                                <a href="javascript:" (click)="insuDetailsDeleteConfirm(deletConfirmInsurance, i)"> <i class="fa fa-trash-alt"
                                                        aria-hidden="true"></i>Delete</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <h5 class="title">
                    Primary Subscriber Details
                </h5>
                <div class="row enc-form">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        First Name <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.subscriberfirstname || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Gender<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.subscribergender || '--'}}
                                    </label>
                                </div>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Last Name<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.subscriberlastname || '--'}}
                                    </label>
                                </div>
        
                                <div class="form-group">
                                    <label class="label">
                                        Relationship to
                                        Subscriber<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.relationToSubscriber || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        DOB <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{(insurance?.subscriberdateOfBirth | yyymmdd) || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <h5 class="title">
                    Employer Details
                </h5>
                <div class="row enc-form">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label">
                                        Employer Name <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.employerName || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        Street<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.employerAddress?.street || '--'}}
                                    </label>
                                </div>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
        
                                </div>
        
                                <div class="form-group">
                                    <label class="label">
                                        City<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.employerAddress?.city || '--'}}
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        ZIP Code<span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{addPrefixZipCode(insurance?.employerAddress?.zipCode) || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
        
                                </div>
                                <div class="form-group">
                                    <label class="label">
                                        State <span>:</span>
                                    </label>
                                    <label class="label-val">
                                        {{insurance?.employerAddress?.state || '--'}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- Add new Insurance POP-Up -->
<app-ui-modal #commonNewAddInsuEditInsurance [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="commonNewAddInsuEditInsurance">
    <div class="app-modal-header">
        <h5 class="modal-title" *ngIf="!isEditMode">Add New Insurance</h5>
        <h5 class="modal-title" *ngIf="isEditMode">Update Insurance</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="commonNewAddInsuEditInsurance.hide();closeEditViewNewInsurance();editInsuranceEnable = false;selectedInsuranceDt = null"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="insurnace-add-edit-form-common">
            <div class="insurance-details" [formGroup]="newInsuranceFormGroup">
                <!-- *ngIf="newInsuranceFormGroup?.get('insuranceDetails')?.controls?.length > 1" -->
                <div class="multiple-insurances enc-bg mb-2">
                    <div class="row insurance-items">
                        <div class="col-lg-8 col-md-8 col-sm-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label>Insurance Name <span class="required">*</span></label>
                                        <ng-select placeholder="Search by Insurance Name or Payor ID or Plan Name"
                                            [items]="(allInsuranceList | async)?.responseObject" bindLabel="name"
                                            bindValue="name" [addTag]="true" [multiple]="false" [hideSelected]="true"
                                            [trackByFn]="trackByFn" [minTermLength]="3" [closeOnSelect]="true"
                                            (change)="selectedInsurance($event)" [loading]="insuranceLoading"
                                            typeToSearchText="Please enter 3 or more characters"
                                            [typeahead]="insuranceInput" clearAllText="Clear" formControlName="name"
                                            id="addl-insu-name">
                                        </ng-select>
                                        <span
                                            *ngIf="newInsuranceFormGroup?.get('name')?.errors?.required && newInsuranceFormGroup?.get('name')?.touched"
                                            class="text-danger">Insurance Name is Required</span>
                                        <span class="text-danger"
                                            *ngIf="newInsuranceFormGroup?.get('name')?.invalid && !newInsuranceFormGroup?.get('name')?.errors?.required">Please
                                            enter a valid Insurance Name</span>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label>Member ID <span class="required">*</span></label>
                                        <input type="text" class="form-control input-text" formControlName="memberId"
                                            id="addl-insu-memberId" maxlength="25" placeholder="Member ID">
                                        <span
                                            *ngIf="newInsuranceFormGroup?.get('memberId').errors?.required && newInsuranceFormGroup?.get('memberId').touched"
                                            class="text-danger">Member ID is Required</span>
                                        <span class="text-danger"
                                            *ngIf="newInsuranceFormGroup?.get('memberId').invalid && !newInsuranceFormGroup?.get('memberId').errors?.required">Member
                                            ID accepts only Alphanumeric & Hyphen Symbol</span>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label>Group Number </label>
                                        <input type="text" class="form-control input-text" formControlName="groupNumber"
                                            id="addl-insu-groupNumber" maxlength="25" placeholder="Group Number">
                                        <span class="text-danger"
                                            *ngIf="newInsuranceFormGroup?.get('groupNumber').invalid && !newInsuranceFormGroup?.get('groupNumber').errors?.required">
                                            Group Number accepts only Alphanumeric & Hyphen Symbol</span>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label>Payor ID <span class="required">*</span></label>
                                        <input type="text" class="form-control input-text" formControlName="payerId"
                                            id="addl-insu-payerId" placeholder="Payor ID">
                                        <span
                                            *ngIf="newInsuranceFormGroup?.get('payerId').errors?.required && newInsuranceFormGroup?.get('payerId').touched"
                                            class="text-danger">Payor ID is Required</span>
                                        <span class="text-danger"
                                            *ngIf="newInsuranceFormGroup?.get('payerId').invalid && !newInsuranceFormGroup?.get('payerId').errors?.required">Please
                                            enter a valid Payor ID</span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label for="Relationship"> Coverage <span class="required">*</span></label>
                                        <div class="mb-0">
                                            <ng-select placeholder="Choose Coverage Sequence" id="addl-insu-priority"
                                                (change)="changeCoverageSequence($event)" bindLabel="desc"
                                                bindValue="value" [items]="insuranceSequenceList"
                                                formControlName="priority"></ng-select>
                                        </div>
                                        <span
                                            *ngIf="newInsuranceFormGroup?.get('priority')?.errors?.required && newInsuranceFormGroup?.get('priority')?.touched"
                                            class="text-danger">Coverage Sequence is Required</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label for="Relationship"> Insurance Class <span
                                                class="required">*</span></label>
                                        <div class="mb-0">
                                            <ng-select placeholder="Choose Insurance class" [items]="insuranceClassList"
                                                formControlName="insuranceClass" id="addl-insu-insuranceClass">
                                            </ng-select>
                                        </div>
                                        <span
                                            *ngIf="newInsuranceFormGroup?.get('insuranceClass')?.errors?.required && newInsuranceFormGroup?.get('insuranceClass')?.touched"
                                            class="text-danger">Insurance Class is Required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <ng-container *ngIf="!newInsuranceFormGroup?.value?.insuranceCardImageId">
                                <!-- <app-upload-insurance-driving-docs [hideInsurance]="false" [insuranceDetails]="getInsurance(i)" [patientDetailsInfo]="patientDetailsInfo" [PatientDetailsCardReload]="PatientDetailsCardReload"></app-upload-insurance-driving-docs> -->
                                <div class="upload-documents mt-4">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <p class="text-info">* Note: Accept only PDF, PNG , JPEG files and
                                                Files larger than
                                                25MB cannot be uploaded</p>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <!-- <h5 class="head-color">Insurance Card</h5> -->
                                            <div class="item-wrapper one">
                                                <div class="item">
                                                    <form data-validation="true" action="#" method="post"
                                                        enctype="multipart/form-data">
                                                        <div class="item-inner">
                                                            <div class="item-content">
                                                                <div class="image-upload">
                                                                    <label (change)="insuranceFileChanged($event)"
                                                                        style="cursor: pointer;width: 100%;"
                                                                        for="user-insurance-license-upload">
                                                                        <img class="img-upload" alt="Document Upload"
                                                                            src="../../../../../../assets/images/ic_image_upload.svg" />
                                                                        <div class="h-100">
                                                                            <div class="dplay-tbl">
                                                                                <div class="dplay-tbl-cell">
                                                                                    <h5 style="padding: 0px 15px;">
                                                                                        <b>Choose
                                                                                            Patient Insurance
                                                                                            Card to
                                                                                            Upload</b>
                                                                                    </h5>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <input #addInsuranceFile data-required="image"
                                                                            type="file"
                                                                            name="user-insurance-license-upload"
                                                                            id="user-insurance-license-upload"
                                                                            class="image-input"
                                                                            data-traget-resolution="image_resolution"
                                                                            value="">
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </form>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="download-insurance-card mt-2"
                                *ngIf="newInsuranceFormGroup?.value?.insuranceCardImageId">
                                <h5 class="download-insurance">
                                    <ng-container>
                                        <div class="row">

                                            <div style="width: 100%;height: 160px;" class="col-lg-12 col-md-12 col-sm-12 text-center">
                                                <!-- <iframe id="{{newInsuranceFormGroup?.value?.insuranceCardImageId}}_chart_insu_doc" frameBorder="0"
                                width="300" height="150px"></iframe>-->
                                                <ng-container
                                                    *ngIf="newInsuranceFormGroup?.value?.docType === 'application/pdf'">
                                                    <iframe
                                                        id="{{newInsuranceFormGroup?.value?.insuranceCardImageId}}_chart_insu_doc"
                                                        frameBorder="0" width="100%" height="100%"></iframe>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="newInsuranceFormGroup?.value?.docType !== 'application/pdf'">
                                                    <img style="width: 100%;padding: 0px 20px;height: 100%;" name="Image Shown"
                                                        id="{{newInsuranceFormGroup?.value?.insuranceCardImageId}}_chart_insu_doc" />
                                                </ng-container>
                                            </div>
                                            <div class="col-sm-12 text-center mt-2">
                                                <button class="icon-queue-events signoff-action-bg clearfix"
                                                    id="commonInsuranceAddl_fullview" placement="bottom"
                                                    ngbTooltip="Full View">
                                                    <i class="las la-expand"
                                                        (click)="fullViewExpand(newInsuranceFormGroup?.value?.fileURL, fullViewInsuranceCardClaims)"></i>
                                                </button>
                                                <button class="icon-queue-events delete-action-bg clearfix"
                                                    id="commonInsuranceAddl_delete" placement="bottom"
                                                    ngbTooltip="Delete">
                                                    <i class="las la-trash-alt "
                                                        (click)="confirmDelete(newInsuranceFormGroup?.value?.insuranceCardImageId, selectedInsuranceDt, deletConfirmDocument)"
                                                        title="Delete"></i>
                                                </button>

                                            </div>
                                        </div>

                                    </ng-container>
                                </h5>

                                <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- Is the patient primary subscriber of Insurance?  -->
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Subscriber Details <span class="required">*</span></label><br />
                            <label class="radio-inline" *ngFor="let item of insurancePrimarySubscriber"><input
                                    formControlName="insurancePrimarySubscriber" type="radio"
                                    id="addl-insu-primary-sub-type" (change)="checkPrimarySubscriber('change')"
                                    name="insurancePrimarySubscriber" [value]="item.value"><span
                                    class="desc">{{item.desc}}</span></label>
                            <br />
                            <span
                                *ngIf="newInsuranceFormGroup?.get('insurancePrimarySubscriber').errors?.required && newInsuranceFormGroup?.get('insurancePrimarySubscriber').touched"
                                class="text-danger">Subscriber Details is Required</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Insurance Class <span class="required">*</span></label><br />
                            <label class="radio-inline" *ngFor="let item of insuranceType">
                                <input formControlName="medicareFlag" id="addl-insu-insurance-type" (change)="medicareFlagChange()"
                                    type="radio" name="medicareFlag" [value]="item.value">
                                <span class="desc">{{item.desc}}</span>
                            </label>
                            <br />
                            <span
                                *ngIf="newInsuranceFormGroup?.get('medicareFlag')?.errors?.required && newInsuranceFormGroup?.get('medicareFlag')?.touched"
                                class="text-danger">Insurance Class is Required</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12"></div>
                </div>

                <div class="preimary-subscriber">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="form-group ">
                                <label>Primary Subscriber First Name <span class="required">*</span></label>
                                <input type="text" class="form-control input-text" formControlName="subscriberfirstname"
                                    id="addl-insu-subscriberfirstname" placeholder="Patient First Name"
                                    [attr.disabled]="newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value === 'Self' ? 'true' : null">
                                <span
                                    *ngIf="newInsuranceFormGroup?.get('subscriberfirstname').errors?.required && newInsuranceFormGroup?.get('subscriberfirstname').touched"
                                    class="text-danger">Primary Subscriber First Name is Required</span>
                                <span class="text-danger"
                                    *ngIf="newInsuranceFormGroup?.get('subscriberfirstname').invalid && !newInsuranceFormGroup?.get('subscriberfirstname').errors?.required">Please
                                    enter a valid Primary Subscriber First Name</span>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="form-group">
                                <label>Primary Subscriber Last Name <span class="required">*</span></label>
                                <input type="text" class="form-control input-text" formControlName="subscriberlastname"
                                    id="addl-insu-subscriberlastname" placeholder="Patient Last Name"
                                    [attr.disabled]="newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value === 'Self' ? 'true' : null">
                                <span
                                    *ngIf="newInsuranceFormGroup?.get('subscriberlastname').errors?.required && newInsuranceFormGroup?.get('subscriberlastname').touched"
                                    class="text-danger">Primary Subscriber Last Name is Required</span>
                                <span class="text-danger"
                                    *ngIf="newInsuranceFormGroup?.get('subscriberlastname').invalid && !newInsuranceFormGroup?.get('subscriberlastname').errors?.required">Please
                                    enter a valid Primary Subscriber Last Name</span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="form-group">
                                <label>Primary Subscriber Date of Birth <span class="required">*</span></label>
                                <input type="date" class="form-control input-text"
                                    formControlName="subscriberdateOfBirth" id="addl-insu-subscriberdateOfBirth"
                                    placeholder="Date of Birth" [max]="maxAppointmentDate" [min]="minDOB"
                                    [attr.disabled]="newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value === 'Self' ? 'true' : null">
                                <span
                                    *ngIf="newInsuranceFormGroup?.get('subscriberdateOfBirth').errors?.required && newInsuranceFormGroup?.get('subscriberdateOfBirth').touched"
                                    class="text-danger">Primary Subscriber Date of Birth is Required</span>

                                <span
                                    *ngIf="newInsuranceFormGroup?.get('subscriberdateOfBirth').errors?.invalidDate && newInsuranceFormGroup?.get('subscriberdateOfBirth').touched"
                                    class="text-danger">
                                    Invalid date

                                </span>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="form-group">
                                <label>Primary Subscriber Gender <span class="required">*</span></label>

                                <ng-select placeholder="Choose Primary Subscriber Gender" [items]="genderList"
                                    id="commonInsuranceAddl_gender" formControlName="subscribergender" bindLabel="desc"
                                    bindValue="value"
                                    [readonly]="newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value === 'Self' ? true : null">
                                </ng-select>
                                <span
                                    *ngIf="newInsuranceFormGroup?.get('subscribergender').errors?.required && newInsuranceFormGroup?.get('subscribergender').touched"
                                    class="text-danger">Primary Subscriber Gender is Required</span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="form-group">
                                <label for="Relationship"> Relationship to Subscriber <span
                                        *ngIf="newInsuranceFormGroup?.get('insurancePrimarySubscriber').value === 'Other'"
                                        class="text-danger">*</span></label>
                                <div class="mb-0">
                                    <ng-select placeholder="Choose Relationship to Subscriber"
                                        [items]="insuranceRelationship" formControlName="relationToSubscriber"
                                        id="addl-insu-relationToSubscriber" bindLabel="desc" bindValue="value">
                                    </ng-select>
                                    <span
                                        *ngIf="newInsuranceFormGroup?.get('relationToSubscriber').errors?.required && newInsuranceFormGroup?.get('relationToSubscriber').touched"
                                        class="text-danger">Relationship to Subscriber is Required</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12"
                            *ngIf="newInsuranceFormGroup?.get('medicareFlag').value === true">
                            <div class="form-group">
                                <label for="last-name">Coverage Effective Start Date <span
                                        class="required">*</span></label>
                                <input type="date" formControlName="medicareStartDate"
                                    id="addl-insu-CoverageEffectiveStartDate" class="form-control input-text">
                                <span
                                    *ngIf="newInsuranceFormGroup?.get('medicareStartDate')?.errors?.required && newInsuranceFormGroup?.get('medicareStartDate').touched"
                                    class="text-danger">Coverage Effective Start Date is Required</span>
                                <span
                                    *ngIf="newInsuranceFormGroup?.get('medicareStartDate')?.errors?.invalidDate && newInsuranceFormGroup?.get('medicareStartDate')?.touched && !newInsuranceFormGroup?.get('medicareStartDate')?.errors?.required"
                                    class="text-danger">
                                    Invalid date
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Employer Details -->
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Employer Details</h5>
                        <hr>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="first-name"> Employer Name</label>
                            <input class="form-control input-text" type="text" placeholder="Name"
                                id="addl-insu-employer-name" formControlName="employerName" />
                        </div>
                    </div>
                </div>
                <div class="employer-address-details">
                    <app-address [addressForm]="newInsuranceFormGroup?.get('employerAddress')"></app-address>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" (click)="addSaveInsuranceDetails(commonNewAddInsuEditInsurance)" style="margin-right: 15px;" data-dismiss="modal"
            id="commonInsuranceAddl_save">Save</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>


<!-- Insurance Card -->
<app-ui-modal #fullViewInsuranceCardClaims [hideHeader]="false" [modalCentered]="true"
    id="fullViewInsuranceCardClaimsId">
    <div class="app-modal-header">
        <h5 class="modal-title">Insurance Card</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="fullViewInsuranceCardClaims.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="insurance-card-full-view-claims" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" id="ok1"
            data-dismiss="modal" (click)="fullViewInsuranceCardClaims.hide()">Ok</button>
    </div>
</app-ui-modal>

<app-ui-modal #deletConfirmDocument [hideHeader]="false" [modalCentered]="true" id="deletConfirmDocument">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title text-center">Are you sure want to delete Insurance Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="deletConfirmDocument?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 30px;margin: 0px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete Insurance Details?</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_yes"
            (click)="deletConfirmDocument?.hide(); deleteInsuranceDocument(existingS3Key,selectedInsuranceDt,deletConfirmDocument)">Yes</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_no"
            (click)="deletConfirmDocument.hide()">No</button>
    </div>
</app-ui-modal>

<!-- Delete Insurance confirm modal -->
<app-ui-modal #deletConfirmInsurance [hideHeader]="false" [modalCentered]="true" id="deletConfirmInsurance">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title text-center">Are you sure want to delete Insurance Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="deletConfirmInsurance?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 30px;margin: 0;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete Insurance Details?</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="commonInsuranceAddl_yes"
            (click)="deleteSaveInsuranceDetails(deletConfirmInsurance, insudetailsDeleteIndex)">Yes</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            id="commonInsuranceAddl_no" (click)="deletConfirmInsurance.hide()">No</button>
    </div>
</app-ui-modal>