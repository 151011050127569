<!-- Notifications Request -->
<app-ui-modal #notificationRequestModal [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
    id="notificationRequestModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="notificationRequestModal?.hide();resetPatientJournalNotesForm()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="patient-journal-notes-sec" [formGroup]="patientNotificationRequestForm">
            <div class="row">
            
                <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="responderRole">Responder Role<span class="text-danger">*</span></label>
                        <ng-select placeholder="Choose Responder Role" formControlName="responderRole"
                            [items]="allRolesTypes"></ng-select>
                        <span class="text-danger"
                            *ngIf="patientNotificationRequestForm?.get('responderRole')?.errors?.required && patientNotificationRequestForm?.get('responderRole')?.touched">Responder
                            Role is Required</span>
                    </div>
                </div> -->

                <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="!documentUploadChannel">
                    <div class="form-group">
                        <label for="channel">Channel<span class="text-danger">*</span></label>
                        <ng-select placeholder="Add or Choose Channel" id="notificationReqRes_channel" [addTag]="true" formControlName="channel"
                            [items]="journalNotesChannelList"></ng-select>
                        <!-- <input class="form-control input-text" type="text" placeholder="Channel" id="channel"
                            formControlName="channel" /> -->
                        <span class="text-danger"
                            *ngIf="patientNotificationRequestForm?.get('channel')?.errors?.required && patientNotificationRequestForm?.get('channel')?.touched">Channel
                            is Required</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="patientNotificationRequestForm?.get('notifyResponder')?.value === 'Yes'">
                    <div class="form-group">
                        <label for="responderNPI">Responder<span class="text-danger">*</span></label>
                        <ng-select placeholder="Choose Responder" id="notificationReqRes_responder" [addTag]="true" bindLabel="name" bindValue="asssigneeUserId"
                            (change)="changeProviderDetails($event)" formControlName="responderNPI" 
                            [items]="allProviderDetails"></ng-select>
                        <span class="text-danger"
                            *ngIf="patientNotificationRequestForm?.get('responderNPI')?.errors?.required && patientNotificationRequestForm?.get('responderNPI')?.touched">Responder
                            is Required</span>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="notes">Notes<span class="text-danger">*</span></label>
                        <textarea class="form-control" rows="5" placeholder="Write Notes" id="notes"
                            formControlName="notes"></textarea>
                        <span class="text-danger"
                            *ngIf="patientNotificationRequestForm?.get('notes')?.errors?.required && patientNotificationRequestForm?.get('notes')?.touched">Notes
                            is Required</span>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">Does the Provider/Staff need to be notified?<span
                                class="text-danger">*</span></label><br />
                        <label class="radio-inline" *ngFor="let type of yesOrNoString">
                            <input type="radio" id="notificationReqRes_radiobtn" (change)="changeNotifyResponder($event)" name="notifyResponder" formControlName="notifyResponder"
                                [value]="type?.value"><span class="desc">{{type?.desc}}</span>
                        </label><br />
                        <span class="text-danger"
                            *ngIf="patientNotificationRequestForm?.get('notifyResponder')?.errors?.required && patientNotificationRequestForm?.get('notifyResponder')?.touched">Notify Provider/Staff is Required</span>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="notificationReqRes_submit"
            (click)="savePatientJournalNotesRequest(notificationRequestModal)">Submit</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>