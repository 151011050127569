import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Hl7DetailsComponent } from 'src/app/theme/shared/hop-common-components/hl7-details/hl7-details.component';
import { LabRadOrderDetailsModalComponent } from 'src/app/theme/shared/hop-common-components/lab-rad-order-details-modal/lab-rad-order-details-modal.component';
import { LabRadResultsModalComponent } from 'src/app/theme/shared/hop-common-components/lab-rad-results-modal/lab-rad-results-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit, AfterViewInit {
  public othersReviewSystems = [];
  public chiefComplaints = [];
  public assessmentValues = [];
  public billingValues = [];
  public allBehaviourScreening = [];
  public hpiForm: any;
  public vitalsForm: any;
  public examValues = [];
  public allRxOrderDetails = [];
  public alllabRadOrderDetails = [];
  public notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @Input() appointmentId: any;
  @Input() patientDetailsInfo: any;
  @Input() PatientDetailsCardReload: CardComponent;
  @Input() treatmentFlowData: any;
  @Input() appointmentDetails: any;

  @Input() wizard: WizardComponent;

  // plan order table
  public dtOptionsRxOrders: DataTables.Settings = {};
  public dtOptionsLabOrders: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  public dtTriggerRxOrders: Subject<any> = new Subject<any>();
  public dtTriggerLabOrders: Subject<any> = new Subject<any>();
  treatmentFlowAddNotes: any;
  public selectedLetNavIndex: number = 2;
  public allInternalLabOrders: any[] = [];
  @Input() public gender: string;
  @Input() age: number;
  public getUpdateExamRoomData: any;
  public allergiesFromVaccine: any[] = [];
  loadSpinner: boolean;
  claimConfirmationMessage: any;
  @ViewChild('claimSubmitModal') private claimModal: UiModalComponent;

  @Input() moduleList: any;
  public allCareFlowData: any;
  allInternalVaccineDetails: any[];

  allInHouseProcedures: any;
  public careFlowModuleAccess = {
    'Demographics': 'Demographics',
    'Chief Complaints': 'ChiefComplaints',
    'HPI': 'HPI',
    'Vitals': 'Vitals',
    'ROS': 'ROS',
    'Medical History': 'MedicalHistory',
    'Bone Mineral Density Scan': 'BoneMineralDensityScan',
    'Behavioral Screening': 'BehaviorScreening',
    'Inhouse Orders': 'allInhouseOrders',
    'Inhouse Labs': 'InHouseTests',
    'Inhouse Injections': 'vaccination',
    'Inhouse procedures': 'inhouseProcedures',
    'Exam': 'Exam',
    'Orders': 'Plan',
    'Care Plan': 'carePlan',
    'Summary': 'Review',
    'E & M': 'EvaluationAndManagement',
    'Notes': 'PlanNotes',
    'Billing Codes': 'BillingCodes',
    'Subjective': 'SOAP_Subjective',
    'Objective': 'SOAP_Objective',
    'Assessment': 'SOAP_Assessment',
    'Plan': 'SOAP_Plan'
  }
  validateMessage: any;
  pastVisitMedications: any;
  public codeMissingAssessments: any[] = [];
  public loginUserDetails: any;
  @Output() subResourcesAdd = new EventEmitter<any>();

  constructor(private httpService: HttpService, private router: Router) {}

  ngOnInit(): void {
    this.dtOptionsRxOrders = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[5, 'desc']],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
      "emptyTable": "Data is unavailable.",
      "lengthMenu": "Showing _MENU_ Entries",
      "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    this.dtOptionsLabOrders = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[3, 'desc']],
      "language": {
      "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
      "emptyTable": "Data is unavailable.",
      "lengthMenu": "Showing _MENU_ Entries",
      "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    this.reviewAllSections();
    window.addEventListener('scroll', this.scrollEvent, true);
    this.loginUserDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }

  public scrollEvent = (event: any): void => {
    // const n = event.srcElement.scrollingElement.scrollTop;
    // if(n > 650) {
    // }
  }

  ngAfterViewInit(): void {
    // this.dtTriggerRxOrders.next();
    // this.dtTriggerLabOrders.next();
  }


 get chiefCmpHeader(): any[] {
    const allaChiefComplaints =
      JSON.parse(sessionStorage?.getItem('allChiefComplaints')) || [];
    // return this.chiefComplaintsHeaderShow || [];
    return allaChiefComplaints || [];
  }
  
  // get all the past visits medicatons and allergies with userInfoId
  private getAllMedicationDetails(): void { 
    const action = `common/getMedicationDetails?userInfoId=${this.patientDetailsInfo?.userInfoId}&filterCriterion=Active&current=true`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.pastVisitMedications = data?.orderRxes || [];
      this.pastVisitMedications?.sort((a: any, b: any) => {
        const bDate: any = new Date(b?.creation_date);
        const aDate: any = new Date(a?.creation_date);
        return bDate - aDate;
      });
    },error => {

    });
  };

  public async getAllModulesSavedData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.selectedLetNavIndex = 2;
      this.PatientDetailsCardReload.cardRefreshShow();
      const action = `careFlow/readCareFlowData?facilityId=${this.patientDetailsInfo?.facilityId}&appointmentId=${this.appointmentId}&userInfoId=${this.patientDetailsInfo?.userInfoId}`;
      this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
        this.allCareFlowData = data?.responseObject;
        this.PatientDetailsCardReload.cardRefreshHide();
        this.getBillingForInHouseLabs();
        this.getAssessmentCodesDesc();
        resolve();
      },
        (error) => {
          this.PatientDetailsCardReload.cardRefreshHide();
          resolve();
        });
    });
  }

  // get the cpt billing codes based on InHouse labs
  public getBillingForInHouseLabs(): void {
    const action = `treatmentFlow/getCPTCodesForAppointment?appointmentId=${this.appointmentId}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {

      const codeDescConvert = data?.map(item => {
        return {
          cptCode: item?.cptCode,
          codeDescription: item?.cptCodeDescription || item?.codeDescription
        }
      }) || [];

      if(this.allCareFlowData?.carePlan && this.allCareFlowData?.carePlan?.length > 0) {
        const billingData =  this.allCareFlowData?.carePlan?.find((secItem: any) => secItem?.resourceType === 'Billing Codes');
        if(!billingData?.sections && billingData?.sections?.length > 0) {
          billingData?.sections?.forEach((secAnsItem: any) => {
            if(secAnsItem?.answers && secAnsItem?.answers?.length > 0) {
              secAnsItem?.answers?.forEach((ansActVal: any) => {
                let allCodes = [...ansActVal?.actualValue, ...codeDescConvert];
                allCodes = [...new Map(allCodes?.map(item => [(item?.cptCode), item])).values()];
                ansActVal.actualValue = allCodes || [];
              });
            };
          });
        }
      }
      this.PatientDetailsCardReload?.cardRefreshHide();
    },
      (error) => {
        this.PatientDetailsCardReload?.cardRefreshHide();
      })
  }

  // patch the selected values
  public reviewAllSections(): void {
    this.selectedLetNavIndex = 2;
    this.othersReviewSystems =
      JSON?.parse(sessionStorage.getItem('allOtherRos') || '[]') || [];
    this.chiefComplaints =
      JSON?.parse(sessionStorage.getItem('allChiefComplaints') || '[]') || [];
    this.hpiForm = JSON?.parse(sessionStorage.getItem('hpiForm') || '{}') || {};
    this.vitalsForm =
      JSON?.parse(sessionStorage.getItem('vitalsForm') || '{}') || {};
    this.examValues =
      JSON?.parse(sessionStorage.getItem('examValues') || '[]') || [];
    this.assessmentValues =
      JSON?.parse(sessionStorage.getItem('assessmentValues') || '[]') || [];
    this.billingValues =
      JSON?.parse(sessionStorage.getItem('billingValues') || '[]') || [];
    this.allBehaviourScreening =
      JSON?.parse(sessionStorage.getItem('allBehaviourScreening') || '[]') ||
      [];
    this.treatmentFlowAddNotes =
      JSON?.parse(sessionStorage?.getItem('treatmentFlowAddNotes') || '{}') ||
      null;
  }

  // call API's in Parent compoent
  public getPlanOrderDetails(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.PatientDetailsCardReload.cardRefreshShow();
      this.getOrdersChangeHealth();
      this.getRxOrdersChangeHealth();
      this.getAllInternalVaccinationDetails();
      this.getInhouseProceduresByAppointment();
      this.getAllMedicationDetails();
      this.PatientDetailsCardReload.cardRefreshHide();
      this.PatientDetailsCardReload.cardRefreshShow();
      setTimeout(() => {
        this.moduleListForReview();
        this.PatientDetailsCardReload.cardRefreshHide();
      }, 2000);
      resolve();
    });
  }

  // get RX Order details
  private rxOrderDetails(): void {
    const action = `changeHealth/rxOrders/${this.appointmentId}`;
    this.httpService.makeGetRequest(action, '').subscribe(
      (data: any) => {
        if (data?.status === 'SUCCESS') {
          const rdOrder = data?.responseObject?.rxOrder || null;
          // if(typeof rdOrder === 'object'){
          //   this.allRxOrderDetails = [rdOrder];
          // }else{
          //   this.allRxOrderDetails = rdOrder || [];
          // }
          this.allRxOrderDetails = rdOrder || [];
        } else {
          this.notifyText =
            data?.message || AppConstantsListConfig?.uiErrorException;
            this.isSuccessNotify = false;
          // this.exceptionModal.modalShow();
        }
        this.dtTriggerRxOrders.next();
      },
      (error) => {
        this.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
        // this.exceptionModal.modalShow();
        this.dtTriggerLabOrders.next();
      }
    );
  }

  // get LAB / RAD Order details
  public labRadOrderDetails(): void{
    // const action = `changeHealth/getHl7messageDetails?appointmentId=${this.appointmentId}`;
    // const action = `changeHealth/labOrder/${this.appointmentId}`;
    const action = `changeHealth/getLabRadbyAppointment?appointmentId=${this.appointmentId}`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) =>{
      if(data?.status === 'SUCCESS'){
          const labOrder = data?.responseObject || [];
          // if(typeof labOrder === 'object'){
          //   this.alllabRadOrderDetails = [labOrder];
          // }else{
            const labRadOrders = JSON.parse(JSON.stringify(labOrder || []));
            this.alllabRadOrderDetails = labRadOrders?.filter((item: any) => item?.location !== 'Internal' && item?.orderType !== 'LAB') || [];
            const internalLabORders = JSON.parse(JSON.stringify(labOrder || []));
            const allInternalOrders = [];
            internalLabORders?.forEach(element => {
              if(element?.location === 'Internal' && element?.orderType === 'LAB') {
                allInternalOrders?.push(element?.internalLabdetails);
              }
            });
            this.allInternalLabOrders = allInternalOrders || [];
          // }
      }else{
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;;
        this.isSuccessNotify = false;
        // this.exceptionModal.modalShow();
      }
      this.dtTriggerLabOrders.next();
    },
    error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
      this.isSuccessNotify = false;
      // this.exceptionModal.modalShow();
      this.dtTriggerLabOrders.next();
    });
  }

  //  to read the all vaccinations from other caollection
  public async getAllInternalVaccinationDetails(): Promise<any> {
    this.allergiesFromVaccine = [];
    this.allInternalVaccineDetails = [];
    const action = `vaccinationHistory/readVaccinationDetails?appointmentId=${this.appointmentId}&userInfoId=${this.patientDetailsInfo?.userInfoId}`;
    try {
      const allVaccineDetails: any = await this.httpService?.makeGetRequest(action, '')?.toPromise();
      let vaccinations = [];
      allVaccineDetails?.responseObject?.forEach((element: any) => {
        vaccinations?.push(element?.vaccinationDetails);
      });
      this.allInternalVaccineDetails = vaccinations || [];
      this.allergiesFromVaccine = (allVaccineDetails?.responseObject && allVaccineDetails?.responseObject?.length > 0) ? allVaccineDetails?.responseObject[0]?.vaccineAllergies : [];
    } catch {
      this.allInternalVaccineDetails = [];
      this.allergiesFromVaccine = [];
    }
  }

  // Open Lab Requisition from Change HEalth
  public downloadLabRequisition(orderNumber: any): void {
    const action = `changeHealth/getLabRequisition?orderNumber=${orderNumber}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        window.open(data?.responseObject, 'order-requisition');
      }
      this.PatientDetailsCardReload?.cardRefreshHide();
    },
      (error) => {
        this.PatientDetailsCardReload?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      })
  }

  public getInhouseProceduresByAppointment(): void {
    const action = `treatmentFlow/getInhouseProcedures?appointmentId=${this.appointmentId}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.allInHouseProcedures = data?.responseObject || [];
      }else {
        // this.notifyText = data?.message || AppConstantsListConfig.uiErrorException;
        // this.exceptionModal?.modalShow();
      }
      this.PatientDetailsCardReload?.cardRefreshHide();
    },
    error => {
      // this.notifyText = error?.message || AppConstantsListConfig.uiErrorException;
      // this.exceptionModal?.modalShow();
      this.PatientDetailsCardReload?.cardRefreshHide();
    })
  }

  // Save Treatment Flow Data
  public getTreatmentFlowData(): void {
    const action = `treatmentFlow/${this.appointmentId}`;
    this.PatientDetailsCardReload.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe(
      (data: any) => {
        if (data?.status === 'SUCCESS') {
          // this.allergiesFromVaccine = [];
          this.getUpdateExamRoomData = data?.responseObject || {};
          // this.allergiesFromVaccine =
          //   this.getUpdateExamRoomData?.vaccineDetails?.vaccineAllergies || [];
        } else {
          // this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
          // this.exceptionModal?.modalShow();
        }
        this.PatientDetailsCardReload.cardRefreshHide();
      },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
        this.PatientDetailsCardReload.cardRefreshHide();
      }
    );
  }

  public makeTretmentFlowParams(): any {
    // exams
    const generalExams = [];
    this.examValues?.map((item) => {
      const object = {
        name: item?.itemName,
        values: [],
      };
      item?.main?.selectedValues?.map((slectedValue: any) => {
        object.values.push(slectedValue?.itemName);
      });
      generalExams.push(object);
    });

    // review of systems
    let allROSCategories = [];
    this.othersReviewSystems?.map((item) => {
      const object = {
        name: item?.name,
        values: [],
      };
      item?.list?.map((subItem: any) => {
        object.values.push(subItem?.item);
      });
      allROSCategories.push(object);
    });

    this.chiefComplaints?.map((item) => {
      const object = {
        name: item?.name,
        values: [],
      };
      item?.list?.map((subItem: any) => {
        object.values.push(subItem?.item);
      });
      allROSCategories.push(object);
    });

    // Pain sec Quality
    const quality = [];
    this.hpiForm?.pain?.quality?.map((item: any) => {
      quality.push(item?.itemName || item);
    });
    this.hpiForm.pain.quality = quality;

    // sick sec Symptoms
    const symptoms = [];
    this.hpiForm?.sick?.symptoms?.map((item: any) => {
      symptoms.push(item?.itemName || item);
    });
    this.hpiForm.sick.symptoms = symptoms;

    const examParams = {
      appointmentId: this.appointmentId,
      assessment: {
        icdCodes: this.assessmentValues || [],
      },
      exam: {
        generalExams: generalExams,
      },
      facilityId: this.patientDetailsInfo?.facilityId,
      hpi: this.hpiForm,
      plan: {},
      reviewOfSystems: {
        categories: allROSCategories || [],
      },
      userInfoId: this.patientDetailsInfo?.userInfoId,
      vitals: this.vitalsForm,
    };

    return examParams;
  }

  // make the orders api call for current date
  public getOrdersChangeHealth(): void {
    const action = `changeHealth/orders`;
    const params = this.httpService?.getChangeHealthOrders(this.patientDetailsInfo);
    this.PatientDetailsCardReload.cardRefreshShow();

    this.httpService?.makeRequestWithAction(action, params)?.subscribe(
      (data: any) => {
        if (data?.body?.status == 'SUCCESS') {
          // this.notifyText = data?.body?.message;
          // this.exceptionModal?.modalShow();
        } else {
          // this.notifyText = data?.body?.message;
          // this.exceptionModal?.modalShow();
        }
        this.labRadOrderDetails();
        this.PatientDetailsCardReload.cardRefreshHide();
      },
      (error) => {
        this.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
        // this.exceptionModal?.modalShow();
        this.PatientDetailsCardReload.cardRefreshHide();
        this.labRadOrderDetails();
      }
    );
  }

  // make the orders api call for current date
  public getRxOrdersChangeHealth(): void {
    const action = `changeHealth/rxOrders?appointmentId=${this.appointmentId}`;
    this.PatientDetailsCardReload.cardRefreshShow();

    this.httpService?.makeRequestWithAction(action, '')?.subscribe(
      (data: any) => {
        if (data?.body?.status == 'SUCCESS') {
          // this.notifyText = data?.body?.message;
          // this.exceptionModal?.modalShow();
        } else {
          // this.notifyText = data?.body?.message;
          // this.exceptionModal?.modalShow();
        }
        this.rxOrderDetails();
        this.PatientDetailsCardReload.cardRefreshHide();
      },
      (error) => {
        this.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
        // this.exceptionModal?.modalShow();
        this.PatientDetailsCardReload.cardRefreshHide();
        this.rxOrderDetails();
      }
    );
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  // check object and object keys have values or not
  public checkObjValues(obj: any): boolean {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != '') return true;
    }
    return false;
  }

  public createDynamicHtmlRefVar(module: string): string {
    return module?.split(" ")?.join('') + 'Section';
  }

  public scroll(moduleRef, index: number): void {
    const getTopCardHeight = document?.getElementById('top-card-sticky-height') as HTMLElement;
    const totalHeight = getTopCardHeight?.offsetHeight;
    const element = document?.getElementById(moduleRef) as HTMLElement;
    this.selectedLetNavIndex = index;
    // element?.scrollIntoView({ behavior: 'smooth', block: "start" });
    const headerOffset = totalHeight ? (totalHeight + 10) : 190;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }

  public goPrevious(): void {
    this.wizard?.goToPreviousStep();
  }

  // sign off the appointment
  public signOffCall(): void {
    const action = `treatmentFlow/signOff?appointmentId=${this.appointmentId}`;
    const params = '';
    this.loadSpinner = true;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe(
      (data: any) => {
        if (data?.body?.status === 'SUCCESS') {
          this.openClaimModal()
        }
        this.loadSpinner = false;
        this.PatientDetailsCardReload?.cardRefreshHide();
      },
      (error) => {
        this.loadSpinner = false;
        this.notifyText =
          error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.PatientDetailsCardReload?.cardRefreshHide();
      }
    );
  }

  // Validate the ICD and Billing Codes
  public validateIcdBillingCodes(validateICdBillingCodesModal: UiModalComponent): void {
    const action = `treatmentFlow/validateICDBillingCodeForAppt?appointmentId=${this.appointmentId}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === "SUCCESS") {
        if (!data?.responseObject) {
          this.validateMessage = data?.message || '';
          validateICdBillingCodesModal?.show();
        } else {
          this.signOffCall();
        }
      } else {
        this.validateMessage = data?.message || '';
        validateICdBillingCodesModal?.show();
      }
      this.PatientDetailsCardReload?.cardRefreshHide();
    },
      error => {
        this.validateMessage = error?.message || '';
        validateICdBillingCodesModal?.show();
        this.PatientDetailsCardReload?.cardRefreshHide();
      });
  }

  // Lock Unlock the record from signoff
  public lockUnlockSignoff(ifNoLock?: boolean): void {
    let action = `treatmentFlow/setSignoffLock?appointmentId=${this.appointmentId}`;
    if (ifNoLock) {
      action = `treatmentFlow/removeSignoffLock?appointmentId=${this.appointmentId}`;
    }
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.navigateTreatmentFlow();
    }, error => {
      this.navigateTreatmentFlow();
    });
  }

  // open claim modal
  public openClaimModal(): void {
    if (this.appointmentDetails?.selfPay) {
      this.claimConfirmationMessage =
        'This patient visit does not have Insurance on file, please collect required charges.';
    } else {
      this.claimConfirmationMessage = 'Do you want to submit the Claim now..?';
    }
    this.claimModal?.show();
  }

  // get intitials from doctor name
  public getInitailsDoctorName(): string {
    const firstNameLetter =
      this.appointmentDetails?.appointment?.authorized_official_first_name?.charAt(
        0
      ) || '';
    const lastNameLetter =
      this.appointmentDetails?.appointment?.authorized_official_last_name?.charAt(
        0
      ) || '';
    return firstNameLetter + lastNameLetter || '';
  }

  public navigateTreatmentFlow(): void {
    if (sessionStorage?.getItem('navigateClaim') === 'Pending Sign off') {
      this.router?.navigate([`hospital/all-claims-new`]);
      return;
    }
	
	if (sessionStorage?.getItem('isFromTask')) {
      this.router?.navigate([`hospital/tasks`]);
      return;
    }
	
	
    this.router?.navigate(['hospital/hosp-careflow-patient-list']);
  }

  get isShowHideElements(): any {
    const allItems = JSON.parse(
      sessionStorage?.getItem('allProvideLoginNavItems')
    );
    const getScreenItem = allItems?.find(
      (item) => item?.coreFeature === 'Care Flow'
    );
    return getScreenItem?.childFeature || {};
  }

  // Call Assessment Code with Desc if miss the Code and Description
  public getAssessmentCodesDesc(): void {
    let icdCodeForm = [];
    this.allCareFlowData?.carePlan?.forEach((formGroupItem: any) => {
      if(formGroupItem?.resourceType === 'General') {

        formGroupItem?.sections?.forEach((secFormGroup: any) => {
          if (secFormGroup && secFormGroup?.name === 'Assessment') {
            secFormGroup?.answers?.forEach((ansForm: any) => {
              if (secFormGroup?.name === 'Assessment' && ansForm?.label === 'Diagnosis Codes (ICD10)') {
                icdCodeForm = ansForm?.actualValue || [];
              }
            });
          }
        });
      }
      
    });

    if (icdCodeForm && icdCodeForm?.length === 0) {
      return;
    }

    if (icdCodeForm && icdCodeForm?.length > 0 && icdCodeForm[0]?.description) {
      return;
    }

    const icdCodes:Array<string> = icdCodeForm?.map(item => item?.code) || [];
    const action = `billingCodes/getICDCodesDesc`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action,  icdCodes)?.subscribe((data: any) => {
      this.PatientDetailsCardReload?.cardRefreshHide();
      if (data?.body?.status == 'SUCCESS') {
        this.codeMissingAssessments = data?.body?.responseObject || [];
      }
    },
      error => {
        this.PatientDetailsCardReload?.cardRefreshHide();
      });
  };

 
  // to check is care flow data is available or not
  public isDataisAvailable(moduleName?: string): boolean {
    if (!this.allCareFlowData) {
      return true;
    }
    switch (moduleName) {
      case 'Chief Complaints':
        return this.allCareFlowData?.chiefComplaints && this.checkIfSavedSections(this.allCareFlowData?.chiefComplaints) ? true : false;
        break;
      case 'HPI':
        return this.allCareFlowData?.hpi && this.allCareFlowData?.hpi?.length > 0 && this.checkIfSavedSections(this.allCareFlowData?.hpi) ? true : false;
        break;
      case 'Vitals':
        return this.allCareFlowData?.vitals && this.allCareFlowData?.vitals?.length > 0 && this.checkIfSavedSections(this.allCareFlowData?.vitals) ? true : false;
        break;
      case 'ROS':
        return this.allCareFlowData?.reviewOfsystems && this.checkIfSavedSections(this.allCareFlowData?.reviewOfsystems) ? true : false;
        break;
      case 'Behavioral Screening':
        return this.allCareFlowData?.behavioralScreening && this.checkIfSavedSections(this.allCareFlowData?.behavioralScreening) ? true : false;
        break;
      case 'Inhouse Orders':
        return this.allInHouseProcedures?.length > 0 || this.allInternalVaccineDetails?.length > 0 || this.allInternalLabOrders?.length > 0 ? true : false;
        break;
      case 'Exam':
        return this.allCareFlowData?.exam && this.checkIfSavedSections(this.allCareFlowData?.exam) ? true : false;
        break;
      case 'Orders':
        return this.allRxOrderDetails?.length > 0 || this.alllabRadOrderDetails?.length > 0 ? true : false;
        break;
      case 'Care Plan':
        return this.allCareFlowData?.carePlan && this.checkIfSavedSections(this.allCareFlowData?.carePlan) ? true : false;
        break;
    }
  }

  public moduleListForReview(): void {
    const allDataModules = [];
    if (this.allCareFlowData?.chiefComplaints && this.checkIfSavedSections(this.allCareFlowData?.chiefComplaints) ? true : false) {
      allDataModules?.push('Chief Complaints');
    }
    if (this.allCareFlowData?.hpi && this.allCareFlowData?.hpi?.length > 0 && this.checkIfSavedSections(this.allCareFlowData?.hpi) ? true : false) {
      allDataModules?.push('HPI');
    }
    if (this.allCareFlowData?.vitals && this.allCareFlowData?.vitals?.length > 0 && this.checkIfSavedSections(this.allCareFlowData?.vitals) ? true : false) {
      allDataModules?.push('Vitals');
    }
    if (this.allCareFlowData?.reviewOfsystems && this.checkIfSavedSections(this.allCareFlowData?.reviewOfsystems) ? true : false) {
      allDataModules?.push('ROS');
    }
    if (this.allCareFlowData?.behavioralScreening && this.checkIfSavedSections(this.allCareFlowData?.behavioralScreening) ? true : false) {
      allDataModules?.push('Behavioral Screening');
    }
    if (this.allInHouseProcedures?.length > 0 || this.allInternalVaccineDetails?.length > 0 || this.allInternalLabOrders?.length > 0 || this.allRxOrderDetails?.length > 0 || this.alllabRadOrderDetails?.length > 0 ? true : false) {
      allDataModules?.push('Orders');
    }
    if (this.allCareFlowData?.exam && this.checkIfSavedSections(this.allCareFlowData?.exam) ? true : false) {
      allDataModules?.push('Exam');
    }
    
    if (this.allCareFlowData?.carePlan && this.checkIfSavedSectionsCarePlan(this.allCareFlowData?.carePlan, 'Assessment') ? true : false) {
      allDataModules?.push('Assessment');
    }

    if (this.allCareFlowData?.carePlan && this.checkIfSavedSectionsCarePlan(this.allCareFlowData?.carePlan, 'Plan') ? true : false) {
      allDataModules?.push('Plan');
    }

    const addedResources = allDataModules?.map(addItem => {
      return {
        name: addItem,
        status: true,
        mainName: 'Summary'
      }
    }) || [];
    const values = {
      main: 'Summary',
      resources: addedResources,
    }
    this.subResourcesAdd?.emit(values);
  }

  // click to scroll from left navigation
  public scrollFromLeftNav(moduleName: any): void {
    this.scroll(this.createDynamicHtmlRefVar(moduleName), 0);
  }

  public checkIfSavedSections(moduleData?: any): boolean {
    if (moduleData && moduleData?.length > 0) {
      const allSections = [];
      moduleData?.forEach((checkItem: any) => {
        if (checkItem && (checkItem?.sections?.length > 0 || checkItem?.otherNotes)) {
          allSections?.push(checkItem);
        }
      });
      if (allSections && allSections?.length === 0) {
        return false;
      }
    };
    return true;
  }

  public checkIfSavedSectionsCarePlan(moduleData?: any, secType?: string): boolean {
    if (moduleData && moduleData?.length > 0) {
      const allSections = [];
      moduleData?.forEach((checkItem: any) => {
        if (secType === 'Assessment') {
          const haveAsse = checkItem?.sections?.find(secCheckI => secCheckI?.name === 'Assessment') || null;
          if (haveAsse) { //|| checkItem?.otherNotes
            allSections?.push(checkItem);
          }
        }

        if (secType === 'Plan') {
          const haveAsse = checkItem?.sections?.find(secCheckI => secCheckI?.name === 'E & M') || null;
          if (haveAsse || (checkItem?.resourceType === 'Billing Codes' && (checkItem?.sections?.length > 0 || checkItem?.otherNotes))) {
            allSections?.push(checkItem);
          }
        }
        // if (checkItem && (checkItem?.sections?.length > 0 || checkItem?.otherNotes)) {
        //   allSections?.push(checkItem);
        // }
      });
      if (allSections && allSections?.length === 0) {
        return false;
      }
    };
    return true;
  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerLabOrders.unsubscribe();
    this.dtTriggerRxOrders.unsubscribe();
    window.removeEventListener('scroll', this.scrollEvent, true);
  }
}
