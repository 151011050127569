<div id="createClaimJournalModal" [formGroup]="claimJournalForm" [ngClass]="{'toggle-collapsed': addCss}">
    <div class="row" id="taskDetails" [hidden]="getAllClaimJournalsNotes && getAllClaimJournalsNotes?.length === 0">
        <!--<div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 patient-info-sec">
                    
                    <span class="name">{{userInformation?.patientFirstName}} {{userInformation?.patientLastName}},</span>
                    <span class="gender-age"
                        *ngIf="userInformation?.purposeOfVisit !== 'Legacy Medical Record'"> | {{userInformation?.purposeOfVisit}}
                        Visit </span>
                    <span class="gender-age"
                        *ngIf="userInformation?.purposeOfVisit === 'Legacy Medical Record'"> | {{userInformation?.purposeOfVisit}}</span>
                    <span class="gender-age"
                        *ngIf="userInformation?.purposeOfVisit !== 'Legacy Medical Record'"> | {{userInformation?.serviceDate}}</span>
    
                </div>
            </div>-->

        <div class="left-col" [ngClass]="showPreview ? 'col-lg-8 col-md-8' : 'col-lg-12 col-md-12'"
            id="preview-main-sec">
            <app-card #taskCard cardClass="card-datatable" class="new-designed-card" [options]="false">
                <div class="table-responsive table-enc journal-tb">
                    <table datatable [dtOptions]="dtOptionsClaimJournalList" [dtTrigger]="dtTriggerCliamJournalList"
                        class="table table-striped table-hover custom-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Created By</th>
                                <th>CATEGORY</th>
                                <th>Notes</th>
                                <th>Reason</th>
                                <th>COB <br> Follow-up</th>
                                <th>Follow up <br> Date</th>
                                <th>Document</th>
                                <!-- <th class="no-sort">ACTIONS</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let journalNotes of getAllClaimJournalsNotes; let i = index">
                                <td>{{appointmentTime(journalNotes?.auditInfo?.createdDate ||
                                    journalNotes?.auditInfo?.acctCreationDateTime) || '--'}}</td>
                                <td>{{getCreatedByNameWithEmailId(journalNotes?.auditInfo?.createdBy) || '--'}}</td>
                                <td>{{journalNotes?.journalCategory || '--'}}</td>
                                <td>
                                    <ng-container *ngIf="journalNotes?.notes">
                                        <div [ngClass]="journalNotes?.readMoreLessCheck ? 'just-text' : 'ellipse-text'"
                                            title="{{journalNotes?.notes}}">{{journalNotes?.notes}}</div><a
                                            *ngIf="checkLettersCount(journalNotes?.notes)" href="javascript:;"
                                            (click)="journalNotes.readMoreLessCheck = !journalNotes?.readMoreLessCheck"
                                            class="readMoreLess"><span *ngIf="!journalNotes?.readMoreLessCheck">Read
                                                More</span><span *ngIf="journalNotes?.readMoreLessCheck">Read
                                                Less</span></a>
                                    </ng-container>
                                    <ng-container *ngIf="!journalNotes?.notes">
                                        --
                                    </ng-container>
                                </td>
                                <td>{{journalNotes?.reason || '--'}}</td>
                                <td>
                                    <span *ngIf="journalNotes?.cobFollowup">Yes</span>
                                    <span *ngIf="!journalNotes?.cobFollowup">No</span>

                                </td>
                                <td><span
                                        *ngIf="journalNotes?.followupDate">{{mmddyyyyFormatSplit(journalNotes?.followupDate)
                                        }}</span>
                                    <span *ngIf="!journalNotes?.followupDate">--</span>
                                </td>
                                <!-- <td>
                                        <ng-container *ngIf="journalNotes?.s3Key && journalNotes?.s3Key !== ' '">
                                            <span>{{journalNotes?.documentName}}</span> &nbsp;
                                        </ng-container>
                                        <ng-container *ngIf="!journalNotes?.s3Key || journalNotes?.s3Key === ' '">
                                            N/A
                                        </ng-container>
                                    </td> -->
                                <td>
                                    <ng-container>
                                        <button class="icon-queue-events view-action-bg" placement="left"
                                            [ngbTooltip]="journalNotes?.documentName"
                                            [class.no-doc]="!journalNotes?.s3Key || journalNotes?.s3Key === ' '"
                                            (click)="viewDownloadDocument(journalNotes, 'view')"><i
                                                class="fa fa-file-pdf"></i></button>
                                    </ng-container>
                                    <!-- <ng-container *ngIf="!journalNotes?.s3Key || journalNotes?.s3Key === ' '">
                                            N/A
                                        </ng-container> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </app-card>
            <hr class="hr-journal">
        </div>
        <ng-container *ngIf="showPreview">
            <div class="col-lg-4 col-md-4 col-sm-12 preview-spacer">
                <div class="toggle-icon" (click)="refreshCss($event)"></div>
                <h4>Document Preview <span (click)="showPreview = !showPreview; addCss = false"
                        class="material-symbols-outlined" style="cursor: pointer;float: right;">
                        cancel
                    </span></h4>
                <iframe id="claim-journal-docs-view" frameBorder="0" width="100%" height="500px"></iframe>
            </div>
        </ng-container>
    </div>

    <div class="row" id="claimJournalFields">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Category<span class="required">*</span></label>
                        <ng-select placeholder="Add or Choose Category" [items]="categoriesList"
                            formControlName="category" bindLabel="desc" bindValue="value"
                            [addTag]="addTag?.bind(this)"></ng-select>
                        <label class="required"
                            *ngIf="claimJournalForm?.controls['category'].hasError('required') && claimJournalForm?.controls['category'].touched">Category
                            is required</label>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Reason <span class="required">*</span></label>
                        <ng-select placeholder="Choose Reason" [items]="reasonsList" formControlName="reason"
                            bindLabel="desc" bindValue="value"></ng-select>
                        <label class="required"
                            *ngIf="claimJournalForm?.controls['reason'].hasError('required') && claimJournalForm?.controls['reason'].touched">
                            Reason is required</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-8">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="form-label">Notes <span class="required">*</span></label>
                                <textarea rows="4" class="form-control" formControlName="description"
                                    placeholder="Notes"></textarea>
                                <label class="required"
                                    *ngIf="claimJournalForm?.controls['description'].hasError('required') && claimJournalForm?.controls['description'].touched">Notes
                                    is required</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row mt-2 journal-fields">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">

                                        <input class="check-comp" type="checkbox" formControlName="cobFollowup"
                                            id="cobFollowup">
                                        <label class="cr list-name-label"> &nbsp; COB Follow-up Required</label>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="form-group">

                                        <input class="check-comp" type="checkbox" (change)="checkIfFollowupSelected()"
                                            formControlName="isfollowUp" id="isfollowUp">
                                        <label class="cr list-name-label"> &nbsp; Follow-up Required</label>
                                    </div>
                                </div>

                                <div class="col-lg-5 col-md-5 date-journal">
                                    <div class="form-group d-flex">
                                        <label class="followup-date">Follow-up Date <span class="required"
                                                *ngIf="isfollowUpSelected">*</span></label>

                                        <input type="date" class="form-control" id="followupDate"
                                            formControlName="followupDate" onkeydown="return false"
                                            [min]="minAppointmentDate" (change)="checkIfFollowup($event)">
                                        <label class="required"
                                            *ngIf="claimJournalForm.controls['followupDate'].hasError('required') && claimJournalForm.controls['followupDate'].touched">
                                            Follow-up Date is required</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="mt-4">
                        <app-common-upload-doc [uploadKeyForm]="claimJournalForm"
                            [encounterUserDetails]="userInformation"
                            (loadSpinnerShown)="shownLoadSpinner($event)"></app-common-upload-doc>
                    </div>
                   <!-- <div class="row">
                        <div class="col-lg-12">
                            <ul class="doc-type-list">
                                <li>
                                    <a>PDF</a>
                                </li>
                                <li>
                                    <a>PNG</a>
                                </li>
                                <li>
                                    <a>JPEG</a>
                                </li>
                                <li>
                                    <a>< below 25MB</a>
                                </li>
                            </ul>
                        </div>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12" style="text-align:center">
                <button class="btn btn-primary dash-actions" type="button"
                    (click)="resetForm();"><span
                        class="btn-text">cancel </span></button>&nbsp;
                <button class="btn btn-primary dash-actions" style="margin-right: 15px;" type="button"
                    (click)="saveClaimJournalNotes(createClaimJournalModal)"><span
                        class="btn-text">Submit</span></button>

            </div>
        </div>
    </div>
</div>



<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>