import { Component, Input } from '@angular/core';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-review-inhouselabs',
  templateUrl: './review-inhouselabs.component.html',
  styleUrls: ['./review-inhouselabs.component.scss']
})
export class ReviewInhouselabsComponent {

  @Input() inhouseLabsData: any;

  constructor(public httpService: HttpService) { }

  public getHcgResult(result: any) {
    let note: string = '';
    result = parseInt(result);
    if (result || result === 0) {
      if (result <= 5) note = 'Negative';
      else if (result >= 6 && result <= 24) note = 'Retest';
      else if (result >= 25) note = 'Positive';
      return note;
    }
  }

}
