<app-ui-modal #claimPrimarySecondaryCorrectedModal [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="claimPrimarySecondaryCorrectedModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Claim Submission Type</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="claimPrimarySecondaryCorrectedModal.hide();clearForm()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="primary-secondary-claim" [formGroup]="clamsSubmissionForm">
            <div class="row">
                <ng-container>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label>Claim Processor <span class="required">*</span></label>
                            <ng-select placeholder="Choose Claim Processor" (change)="changeClaimProcessorType($event)" id="claim-processor-type" [items]="primarySecondary" formControlName="claimProcessorType" [clearable]="false"></ng-select>
                            <!-- bindLabel="desc" bindValue="value" -->
                            <!-- <label class="radio-inline" *ngFor="let item of primarySecondary;let i = index">
                                <input formControlName="claimProcessorType"  type="radio" name="claimProcessorType"
                                    [value]="item?.value"><span class="desc">{{item?.desc}}</span></label>
                            <br /> -->
                            <span class="required"
                                *ngIf="clamsSubmissionForm?.get('claimProcessorType').errors?.required && clamsSubmissionForm?.get('claimProcessorType').touched">Claim Processor is Required</span>
                        </div>
                    </div>
                </ng-container>

                <!-- Show the Insurnace Details -->
                <ng-container *ngIf="claimTypeInsuranceDetails && claimTypeInsuranceDetails?.length > 0">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Insurance Name</th>
                                        <th>Priority</th>
                                        <th>Claim Payor ID</th>
                                        <th>Subscriber</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let insuDetails of claimTypeInsuranceDetails">
                                        <td>{{insuDetails?.name}}</td>
                                        <td>{{insuDetails?.priority}}</td>
                                        <td>{{insuDetails?.claimPayerId}}</td>
                                        <td>{{insuDetails?.insurancePrimarySubscriber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="correctedVoidedClaim">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label>Choose Corrected / Voided Claim? <span class="required">*</span></label><br />
                            <label class="radio-inline" *ngFor="let item of corrctedVoided;let i = index">
                                <input formControlName="isCorrected" type="radio" name="isCorrected"
                                    [value]="item?.value"><span class="desc">{{item?.desc}}</span></label>
                            <br />
                            <span class="required"
                                *ngIf="clamsSubmissionForm?.get('isCorrected').errors?.required && clamsSubmissionForm?.get('isCorrected').touched">Type
                                of claim is Required</span>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="last-name">Payor Claim Control Number <span class="text-danger">*</span></label>
                            <input class="form-control input-text" type="text" placeholder="Payor Claim Control Number" id="last-name"
                                formControlName="payerClaimControlNumber" />
                            <span class="text-danger"
                                *ngIf="clamsSubmissionForm?.get('payerClaimControlNumber').errors?.required && clamsSubmissionForm?.get('payerClaimControlNumber').touched">Payor Claim Control Number is Required</span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row" *ngIf="isExternal !== 'yes' && !isreadySubmit && submittedClaimDetails?.claimProcessorType === clamsSubmissionForm?.get('claimProcessorType')?.value">
                <ng-container>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                           <label>Re-Submission Reason <span class="required">*</span></label>
                            <ng-select placeholder="Choose Re-Submit Reason" id="claim-resubmitReason-code" [items]="resubmitReasonsCodes" bindLabel="description" bindValue="description" formControlName="resubmitReasonCode" ></ng-select>
                            
                            <span class="required"
                               
                                *ngIf="clamsSubmissionForm?.get('resubmitReasonCode').errors?.required && clamsSubmissionForm?.get('resubmitReasonCode').touched">Re-Submit Reason is required</span>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label>Notes <span class="required">*</span></label>
                            <textarea class="form-control" placeholder="Write  Notes" id="claim-resubmitReason"
                            formControlName="resubmitReason"></textarea>
                            <span class="required"
                                *ngIf="clamsSubmissionForm?.get('resubmitReason').errors?.required && clamsSubmissionForm?.get('resubmitReason').touched">Notes are Required</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="navigateClaimSubmission(claimPrimarySecondaryCorrectedModal)">Ok</button>
    </div>
</app-ui-modal>

<!-- Claim Submission Confirmation -->
<app-ui-modal #claimSubmitConfirmationModal [hideHeader]="false" [modalCentered]="true"
  id="claimSubmitConfirmationModal">
  <div class="app-modal-header">
    <!-- <h5 class="modal-title">Appointment Details</h5> -->
  </div>
  <div class="app-modal-body add-app-body">
    <h5 class="text-center">The Patient profile contains multiple insurances ; Make sure the relevant claim is submitted.</h5>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok2"
      (click)="claimSubmitConfirmationModal?.hide();claimPrimarySecondaryCorrectedModal?.show()">Ok</button>
  </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>