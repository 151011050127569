import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from '../../services/http.service';
import { CardComponent } from '../../components/card/card.component';


@Component({
  selector: 'app-review-patient-info',
  templateUrl: './review-patient-info.component.html',
  styleUrls: ['./review-patient-info.component.scss']
})
export class ReviewPatientInfoComponent implements OnInit, OnChanges {

  @Input() appointmentInfoDetails: any;
  @Input() pastVisitCard: CardComponent;
  public loginDetails: any;
  checkCommonIdDocument: any;
  loadSpinner: boolean;
  public userInfoData: any;

  constructor(private httpService: HttpService) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.appointmentInfoDetails) {
        this.getCommonIdDocument();
		//console.log(this.appointmentInfoDetails);
        if (this.appointmentInfoDetails?.insuranceDetails?.length > 0 && this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth) {
            this.appointmentInfoDetails.insuranceDetails[0].subscriberdateOfBirth = this.httpService?.formatDobTimeZoneWithDisplay(this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth);
            
			
			const insurances = this.appointmentInfoDetails?.insuranceDetails || [];
			//console.log("insurances..",insurances);
			 insurances?.forEach((item: any, index: number) => {
			  if (item?.active === true) {
			  //console.log("insuranceItem..",item);
				
				if (this.appointmentInfoDetails?.selfPay === false || this.appointmentInfoDetails?.selfPay === "Insurance") {
				  this.previewInsuranceImage(item?.insuranceCardImageId, index);
				}
			  }
			});
		
          }
      }
    }
  }

  ngOnInit(): void {
    this.getUserInfoDetails();
  } 
  
 
  public addPrefixZipCode(addressZipCode: any, valid: any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
    return prefixZipcode || addressZipCode || '';
  }

  // Insurance / DL Image Card Download
  public downloadInsuranceCard(cardId: string, insuraceCardModal: UiModalComponent): void {
    this.downloadIdentificationCard(cardId, 'Insurance', this.appointmentInfoDetails?.userInfoId, insuraceCardModal);
  }

  // Common id Document download
  private getCommonIdDocument(): void {
    this.httpService?.getDlIdDocument(this.loginDetails?.facilityId, this.appointmentInfoDetails?.userInfoId, this.appointmentInfoDetails?.id)?.subscribe((data: any) => {
      if (data?.responseObject?.identityImageId) {
        this.checkCommonIdDocument = data?.responseObject?.identityImageId;
        //this.downloadDlCard(data?.responseObject?.identityImageId);
        const documentType = "Identification Record";
        this.downloadIdentificationCard(data?.responseObject?.identityImageId, documentType, this.appointmentInfoDetails?.userInfoId);
      }
    })
  }

  public downloadIdentificationCard(additionalKey, documentType, userInfoId, insuraceCardModal?: UiModalComponent) {
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.pastVisitCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.pastVisitCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {
          this.insuranceImagewithoutuserinfoId(documentType, additionalKey, userInfoId, insuraceCardModal);
        }
        else {
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          const fileType = data?.responseObject?.fileType;
          this.downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.pastVisitCard?.cardRefreshHide();
    });
  }



  public insuranceImagewithoutuserinfoId(documentType, additionalKey: string, userInfoId, insuraceCardModal?: UiModalComponent) {
    const userInfoIdGet = null;
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoIdGet}&additionalKey=${additionalKey}`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data.responseObject !== null) {

        const s3Key = data?.responseObject?.s3Key;
        const s3Path = data?.responseObject?.s3Path;
        const additionalKey = data?.responseObject?.additionalKey;

        const fileType = data?.responseObject?.fileType;
        this.downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
      }
    }, (error) => {
      this.pastVisitCard?.cardRefreshHide();
    });
  }


  public downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal?: UiModalComponent) {
    //alert(fileType);
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.pastVisitCard?.cardRefreshShow();
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if (!dData) {
        this.loadSpinner = false;
        this.pastVisitCard?.cardRefreshHide();
        return;
      }
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      this.pastVisitCard?.cardRefreshHide();
      if (insuraceCardModal) {
        insuraceCardModal?.show();
        const iframeEle = document.getElementById('insurance-card-past-visit-multiple') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
      } else {
        const iframeEle = document.getElementById('dl-past-visit-card') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
      }

    }, (error) => {
      this.pastVisitCard?.cardRefreshHide();
    });
  }

  public downloadDlCard(cardId: string): void {
    if (!cardId) {
      return;
    }
    const action = `document/user/getIdCard/${cardId}`;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // window.open(fileURL);
      const iframeEle = document.getElementById('dl-past-visit-card') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    },
      (error: any) => {
      });
  }
  
  public downloaFullview(additionalKey: string, insuraceCardModal: UiModalComponent) {

    const documentType = "Identification Record";
    const userId = this.appointmentInfoDetails?.userInfoId;    

    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;      
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {}
        else {
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          const fileType = data?.responseObject?.fileType;
          this.downloaFullviewFile(userId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {

    });
  }

  public downloaFullviewFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal: UiModalComponent) {
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.loadSpinner = true;
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if(!dData) {
        this.loadSpinner = false;
        return;
      }
      this.loadSpinner = false;

      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);

      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }

      insuraceCardModal?.show();
     

      const iframeEle = document.getElementById('uplolad-insurance-card-multiple') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    }, (error) => {
      
    });
  }
  
  
  private getUserInfoDetails(): void {
    // const action = `userInfo/getUserInfo?id=${this.userInfoId}`;
    const action = `common/getChartInfo?userInfoId=${this.appointmentInfoDetails?.userInfoId}&chartModuleName=UserInfo`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        
        this.userInfoData = (data?.responseObject && data?.responseObject?.userInfo) || null;

        if (!this.userInfoData) {
          return;
        }
        const userInfoData = JSON.parse(JSON.stringify((data?.responseObject && data?.responseObject?.userInfo)));
        //console.log(userInfoData);

        // MApping the user info date to ui
        if (userInfoData?.primaryPhone?.includes('+1')) {
          userInfoData.primaryPhone = userInfoData?.primaryPhone?.replaceAll('+1', '');
        }
        if (userInfoData?.alternativePhoneNumber?.includes('+1')) {
          userInfoData.alternativePhoneNumber = userInfoData?.alternativePhoneNumber?.replaceAll('+1', '');
        }
        // if have id document as null

        const idDetails = {
          "idIssuedState": null,
          "number": null,
          "type": null,
          "validFromDate": null,
          "validToDate": null,
          "imageId": null,
        }
        if (userInfoData?.idDetails === null) {
          userInfoData.idDetails = idDetails;
        }
        const pharmacySample = {
          "address": {
            "city": null,
            "country": null,
            "state": null,
            "street": null,
            "zipCode": null,
          },
          "name": null,
          "pharmacyholderName": null,
          "fax": null,
          "phoneNumber": null,
        }
        userInfoData.pharmacy = userInfoData?.pharmacy || pharmacySample;
        if (userInfoData?.pharmacy) {
          //console.log(userInfoData.pharmacy);
          const pharmacyzipCode = userInfoData?.pharmacy?.address?.zipCode;
          userInfoData.pharmacy.address.zipCode = this.addPrefixZipCode(pharmacyzipCode, '');
        }

        userInfoData.usrDateOfBirth = this.httpService?.formatDobTimeZone(userInfoData?.dateOfBirth || userInfoData?.usrDateOfBirth);
        if (!userInfoData?.address) {
          userInfoData.address = {
            "city": null,
            "country": null,
            "state": null,
            "street": null,
            "zipCode": null,
          }
        }

        if (userInfoData?.address) {
          const zipCode = userInfoData?.address?.zipCode;
          userInfoData.address.zipCode = this.addPrefixZipCode(zipCode, '');
        }

       
        userInfoData.insuranceDetails = userInfoData?.insuranceDetails || [];
        console.log(userInfoData?.insuranceDetails);
		/*if (userInfoData?.insuranceDetails?.length > 0 && userInfoData?.insuranceDetails[0]?.subscriberdateOfBirth) {
		    const insurances = userInfoData?.insuranceDetails || [];
			//console.log("insurances..",insurances);
			 insurances?.forEach((item: any, index: number) => {
			  if (item?.active === true) {
			  console.log("insuranceItem..",item);
				
				if (userInfoData?.selfPay === false || userInfoData?.selfPay === "Insurance") {
				  this.previewInsuranceImage(item?.insuranceCardImageId, index);
				}
			  }
			});
			
		}*/	
        userInfoData.pcpDetails = userInfoData?.pcpDetails || [];
        userInfoData.emergencyDetails = userInfoData?.emergencyDetails || [];

       }
     
    },
      (error) => {
        
      });

  }
  
  public viewInsuranceCard(additionalKey, insuraceCardModal?: UiModalComponent) {
    const documentType = "Insurance";
	const userInfoId = this.appointmentInfoDetails?.userInfoId;
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.pastVisitCard?.cardRefreshShow();
	this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.pastVisitCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {
			 this.insuranceImagewithoutuserinfoId(documentType, additionalKey, userInfoId, insuraceCardModal);
        }
        else {
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;

          const fileType = data?.responseObject?.fileType;
          this.downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);

        }
      }
	  this.loadSpinner = false;
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
	this.loadSpinner = false;
      this.pastVisitCard?.cardRefreshHide();
    });
  }
  
  public async previewInsuranceImage(additionalKey: string, index?: number) {

   
    try {
      const documentType = "Insurance";
      const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${this.appointmentInfoDetails?.userInfoId}&additionalKey=${additionalKey}`;
      const getIndDoc: any = await this.httpService?.makeGetRequest(action, '')?.toPromise();
      if (getIndDoc?.responseObject == null || getIndDoc?.responseObject == '') {
        
      }
      else {
        console.log("getIndDoc",getIndDoc);
        const s3Key = getIndDoc?.responseObject?.s3Key;
        const s3Path = getIndDoc?.responseObject?.s3Path;
        const additionalKey = getIndDoc?.responseObject?.additionalKey;
        const fileType = getIndDoc?.responseObject?.fileType;
       
        try {
		
          this.downloadIdFilePreview(this.appointmentInfoDetails?.userInfoId, s3Key, s3Path, additionalKey, fileType);
        } catch (error) {
          
        }
      }
      
    } catch (error) {
     
    }
    }
	
  public downloadIdFilePreview(userInfoId, s3Key, s3Path, additionalKey, fileType) {
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if(!dData) {
        
        return;
      }
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }
    
      const iframeEle = document.getElementById(additionalKey + '_add_insu_card_display') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
      
    }, (error) => {
      
    });
  }

}
