import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';

@Component({
  selector: 'app-insurance-edit-form',
  templateUrl: './insurance-edit-form.component.html',
  styleUrls: ['./insurance-edit-form.component.scss']
})
export class InsuranceEditFormComponent implements OnInit, OnChanges {

  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild('addInsuranceFile') addInsuranceFile: ElementRef;
  public newInsuranceFormGroup: FormGroup;
  public loadSpinner: boolean;
  public idType: string;
  public hideInsurance: boolean;
  public isSuccessNotify: boolean;
  public notifyText: any;
  public additionalDocName: string;
  public existingS3Key: any;
  public insuranceCardFile: string;
  @Input() appointmentInfoDetails: any;
  @Input() claimsSubmissionCard: CardComponent;
  public selectedInsuranceDt: any;
  public insuranceSequenceList = AppConstantsListConfig?.insuranceSequenceList;
  public insuranceClassList = AppConstantsListConfig?.insuranceClassList;
  public insurancePrimarySubscriber = AppConstantsListConfig?.insurancePrimarySubscriber;
  public isSelfPay = AppConstantsListConfig?.isSelfPay;
  public insuranceType = AppConstantsListConfig?.insuranceType;
  public genderList = AppConstantsListConfig?.genderList;
  public insuranceRelationship = AppConstantsListConfig?.insuranceRelationship;
  public relationshipList = AppConstantsListConfig?.relationshipList;
  public maritalStatusList = AppConstantsListConfig?.maritalStatusList;
  public maxAppointmentDate: Date;
  public minDOB = this.httpService.convertDate(new Date('01 Jan 1910'));
  public insuranceInput = new Subject<string>();
  public insuranceLoading: boolean = false;
  public allInsuranceList: Observable<any>;
  editInsuranceEnable: boolean;
  isEditMode: boolean;
  indexViewEditInsurance: number;
  selectedMedicalRecordForDelete: any;
  subscriberDetails: any;
  insudetailsDeleteIndex: number;
  @Output() updateAppointment = new EventEmitter<any>();

  constructor(private httpService: HttpService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.newInsuranceFormGroup = this.insuranceDetailsArray();
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
    this.getInsuranceAutoComplete();
    // if (this.appointmentInfoDetails?.insuranceDetails?.length > 0 && this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth) {
    //   this.appointmentInfoDetails.insuranceDetails[0].subscriberdateOfBirth = this.httpService?.formatDobTimeZoneWithDisplay(this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth);
    //   this.subscriberDetails = this.appointmentInfoDetails?.insuranceDetails[0] || null;
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      // if (this.appointmentInfoDetails?.insuranceDetails?.length > 0 && this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth) {
      //   this.appointmentInfoDetails.insuranceDetails[0].subscriberdateOfBirth = this.httpService?.formatDobTimeZoneWithDisplay(this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth);
      //   this.subscriberDetails = this.appointmentInfoDetails?.insuranceDetails[0] || null;
      // }
      // if (this.appointmentInfoDetails) {
      //   this.appointmentInfoDetails.insuranceDetails?.forEach((insuItem: any, index: number) => {
      //     this.previewInsuranceImage(insuItem?.insuranceCardImageId, "view", index, insuItem);
      //   });
      // }
      if (this.appointmentInfoDetails) {
        for (let propName in changes) {
          if (propName === 'appointmentInfoDetails') {
            let chng = changes[propName];
            const previous: any = chng?.previousValue;
            const current: any = chng?.currentValue;
            if (JSON?.stringify(current) !== JSON?.stringify(previous)) {
              // this.appointmentInfoDetails = JSON?.parse(JSON?.stringify(this.appointmentInfoDetails));
              this.appointmentInfoDetails?.insuranceDetails?.forEach((insuItem: any, index: number) => {
                if (insuItem?.insuranceCardImageId) {
                  this.previewInsuranceImage(insuItem?.insuranceCardImageId, "view", index, insuItem);
                }
              });
            }
          }
        }
      }
    }
  }

  public clearFileUpload(): void {
    this.addInsuranceFile.nativeElement.value = "";
    // const element = document?.getElementById('user-insurance-license-upload') as HTMLElement;
    // element?.setAttribute('value', '');
  }

  // insurance autocomplete
  private getInsuranceAutoComplete() {
    this.allInsuranceList = concat(
      of([]), // default items
      this.insuranceInput.pipe(
        distinctUntilChanged(),
        tap(() => this.insuranceLoading = true),
        switchMap(term => this.httpService?.getInsuranceAutoComplete(term)?.pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.insuranceLoading = false)
        ))
      )
    );
  };

  public trackByFn(item: any) {
    return item?.name;
  }

  // add and edit insurance details form
  public insuranceDetailsArray(insurance?: any): FormGroup {
    return this.formBuilder?.group({
      "active": [(insurance?.active === false ? false : true) || true],
      "claimPayerId": [insurance?.claimPayerId || null],
      "eligibilityPortalID": [insurance?.eligibilityPortalID || null],
      "eligibilityRealtimePayerID": [insurance?.eligibilityRealtimePayerID || null],
      "erCost": [insurance?.erCost || null],
      "groupNumber": [insurance?.groupNumber || null],
      "healthPlan": [insurance?.healthPlan || null],
      "insuranceAddDate": [insurance?.insuranceAddDate || null],
      "insuranceCardImageId": [insurance?.insuranceCardImageId || null],
      "insuranceClass": [insurance?.insuranceClass || null, Validators?.required],
      "insurancePrimarySubscriber": [insurance?.insurancePrimarySubscriber || null, Validators?.required],
      "insuranceholderName": [insurance?.insuranceholderName || null],
      "medicareFlag": [insurance?.medicareFlag === true ? true : false, Validators?.required],
      "medicareStartDate": [insurance?.medicareStartDate || null, [this.httpService.yearValidator.bind(this)]],
      "memberId": [insurance?.memberId || null, Validators?.required],
      "name": [insurance?.name || null, Validators?.required],
      "officeVisitCost": [insurance?.officeVisitCost || null],
      "payerId": [insurance?.payerId || null, Validators?.required],
      "priority": [insurance?.priority || null, Validators?.required],
      "relationToSubscriber": [insurance?.relationToSubscriber || null],
      "specialityCost": [insurance?.specialityCost || null],
      "subscriberdateOfBirth": [insurance?.dob ? this.subscriberDobFormatChange(insurance?.dob) : insurance?.subscriberdateOfBirth ? this.subscriberDobFormatChange(insurance?.subscriberdateOfBirth) : null, Validators?.required],
      "subscriberfirstname": [insurance?.subscriberfirstname || null, Validators?.required],
      "subscribergender": [insurance?.subscribergender || null, Validators?.required],
      "subscriberlastname": [insurance?.subscriberlastname || null, Validators?.required],
      "urgentCareCost": [insurance?.urgentCareCost || null],
      'docType': [insurance?.docType || null],
      'fileURL': [null],
      "employerName": [insurance?.employerName || null],
      'employerAddress': this.formBuilder?.group({
        "city": [insurance?.employerAddress?.city || null],
        "country": [insurance?.employerAddress?.country || null],
        "state": [insurance?.employerAddress?.state || null],
        "street": [insurance?.employerAddress?.street || null],
        "zipCode": [insurance?.employerAddress?.zipCode || null]
      }),
    });
  }

  // Validate the file size and insurance other details
  public insuranceFileChanged(event: any): void {
    if (!this.newInsuranceFormGroup?.value || !this.newInsuranceFormGroup?.value?.memberId || !this.newInsuranceFormGroup?.value?.payerId || !this.newInsuranceFormGroup?.value?.name) {
      this.notifyText = 'Please add the Insurance Details to upload the Insurance Card.';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.clearFileUpload();
      return;
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg') {
        this.uploadInsuranceImage(file);
      }
      else {
        this.notifyText = "Supports only PDF, PNG, JPEG files ";
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.clearFileUpload();
        return;
      }
    }
  }

  public subscriberDobFormatChange(subscriberDob: string): string {
    let dob = subscriberDob?.includes(':') ? subscriberDob?.split(' ')[0] : subscriberDob;
    if (dob?.includes('/')) {
      return this.httpService?.mmddyyyyFormatSplit(dob);
    }
    return dob;
  }

  public uploadInsuranceImage(file: any, index?: number): void {
    const isConnect = true;
    this.loadSpinner = true;
    let action: any = `document/user/uploadInsuranceImage?userInfoId=${this.appointmentInfoDetails?.userInfoId}&isConnect=${isConnect}`;
    // convert to binary for data
    const formData = new FormData();
    formData.append('idFile', file);

    this.httpService.makePostWithFormData(action, formData)
      .subscribe((data: any) => {

        this.loadSpinner = false;
        if (data?.status === 'SUCCESS') {
          //this.insuranceCardFile = data?.fileName || null;
          this.insuranceCardFile = data?.keyName || null;
          const additionalKey = data?.keyName || null;
          this.newInsuranceFormGroup?.get('insuranceCardImageId')?.setValue(this.insuranceCardFile);
          const memberId = this.newInsuranceFormGroup?.get('memberId')?.value;

          this.updateInsuranceCardKeys('', data?.keyName, memberId, this.appointmentInfoDetails?.userInfoId || this.appointmentInfoDetails?.id);

          this.notifyText = 'Insurance Card has been uploaded successfully.';
          this.isSuccessNotify = true;
          this.exceptionModal?.modalShow();
          if (this.insuranceCardFile != '') {
            //this.updateInsuranceIdentityImageId('insuCard',additionalKey);
            this.previewInsuranceImage(additionalKey, "edit", index);
          }
        } else {
          this.notifyText = data?.status || data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.clearFileUpload();
      },
        error => {
          this.loadSpinner = false;
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();

          this.clearFileUpload();
        });
  }

  public deleteInsuranceDocument(existingS3Key: any, insurance: FormGroup, deletConfirmDocumentModal: UiModalComponent): void {
    this.loadSpinner = true;
    const action = `record/deleteDocument?s3Key=${existingS3Key}&userInfoId=${this.appointmentInfoDetails?.userInfoId}`;
    this.additionalDocName = "Insurance";
    // const action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.userInfoId}&additionalKey=${medicalRecord?.additionalKey}`;

    deletConfirmDocumentModal.hide();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;

      const memberId = insurance?.value?.memberId;
      this.updateInsuranceCardKeys(this.existingS3Key || existingS3Key, '', memberId, this.appointmentInfoDetails?.userInfoId || this.appointmentInfoDetails?.id);

      /*const insArray = this.appointmentInfoDetailsForm?.get('insuranceDetails') as FormArray;
      const insGroup = insArray.controls[this.indexViewEditInsurance] as FormGroup;
      insGroup?.get('insuranceCardImageId')?.setValue(null);
      insGroup?.patchValue(this.newInsuranceFormGroup?.value);*/
      this.notifyText = data?.body?.msg || data?.body?.message || 'Record Deleted Successfully';
      this.isSuccessNotify = true;
      this.exceptionModal?.modalShow();
      this.newInsuranceFormGroup?.get('insuranceCardImageId')?.setValue(null);
      const isViewDownload = "view";
      this.downloadIdentificationCard(isViewDownload, this.additionalDocName, this.appointmentInfoDetails?.id);
    }, error => {
      this.loadSpinner = false;
      this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
    });
  }

  public updateInsuranceCardKeys(existingS3Key: any, news3Key: any, memberId: any, userInfoId: any): void {
    let action = '';
    if (news3Key !== '') {
      action = `record/updateInsuranceImageKey?existingS3Key=${existingS3Key}&news3Key=${news3Key}&userInfoId=${userInfoId}`;
    } else {
      action = `record/updateInsuranceImageKey?existingS3Key=${existingS3Key}&userInfoId=${userInfoId}`;
    }
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data?.body?.status === 'SUCCESS') {
        } else {

        }
        this.loadSpinner = false;
      },
        error => {
          this.loadSpinner = false;
        });
  }

  public downloadIdentificationCard(isViewDownload, additionalKey: string, insuraceCardModal: UiModalComponent) {
    const documentType = "Insurance";
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${this.appointmentInfoDetails?.userInfoId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;

    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {

      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {
          this.hideInsurance = false;
          this.downloadIdentificationCardNouserInfo(isViewDownload, additionalKey, insuraceCardModal);
        }
        else {
          this.hideInsurance = true;
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          const fileType = data?.responseObject?.fileType;

          this.downloadIdFile(isViewDownload, this.appointmentInfoDetails?.id || this.appointmentInfoDetails?.USERINFO_ID, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
        }
      }
      this.loadSpinner = false;
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.loadSpinner = false;

    });
  }

  public downloadIdFile(isViewDownload: string, userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal: UiModalComponent) {
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;

    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if (!dData) {
        this.loadSpinner = false;

        return;
      }
      this.hideInsurance = true;

      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      if (insuraceCardModal) {
        insuraceCardModal?.show();
        const iframeEle = document.getElementById('chart-insurance-card-multiple') as HTMLElement;
        iframeEle?.setAttribute('src', fileURL);
      } else {
        const iframeEle = document.getElementById('chart-insurance-card-multiple') as HTMLElement;
        iframeEle?.setAttribute('src', fileURL);
      }

    }, (error) => {

    });
  }

  public downloadIdentificationCardNouserInfo(isViewDownload, additionalKey: string, insuraceCardModal: UiModalComponent) {
    const documentType = "Insurance";
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${this.appointmentInfoDetails?.userInfoId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data.responseObject !== null) {
        this.hideInsurance = true;
        const s3Key = data?.responseObject?.s3Key;
        const s3Path = data?.responseObject?.s3Path;
        const additionalKey = data?.responseObject?.additionalKey;
        const fileType = data?.responseObject?.fileType;
        this.downloadIdFile(isViewDownload, this.appointmentInfoDetails?.id || this.appointmentInfoDetails?.USERINFO_ID, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
      }
      this.loadSpinner = false;
    }, (error) => {
      this.loadSpinner = false;
      // this.updateUserdataCard?.cardRefreshHide();
    });
  }

  public async previewInsuranceImage(additionalKey: string, viewmode: any, index?: number, insuItem?: any) {
    try {
      const documentType = "Insurance";
      const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${this.appointmentInfoDetails?.userInfoId}&additionalKey=${additionalKey}`;
      this.loadSpinner = true;
      const getIndDoc: any = await this.httpService?.makeGetRequest(action, '')?.toPromise();
      this.loadSpinner = false;
      if (getIndDoc?.responseObject == null || getIndDoc?.responseObject == '') {
        this.hideInsurance = false;
        this.insuranceImagewithoutuserinfoId(additionalKey, viewmode, index, insuItem);
      }
      else {
        this.hideInsurance = true;
        const s3Key = getIndDoc?.responseObject?.s3Key;
        const s3Path = getIndDoc?.responseObject?.s3Path;
        const additionalKey = getIndDoc?.responseObject?.additionalKey;
        const fileType = getIndDoc?.responseObject?.fileType;
        if (insuItem) {
          insuItem.docType = fileType;
          this.appointmentInfoDetails.insuranceDetails[index] = insuItem;
        }
        this.newInsuranceFormGroup?.get('docType')?.setValue(fileType);
        if (this.selectedInsuranceDt) {
          this.selectedInsuranceDt.docType = fileType;
        }
        this.downloadIdFilePreview(this.appointmentInfoDetails?.userInfoId, s3Key, s3Path, additionalKey, fileType, viewmode, this.appointmentInfoDetails.insuranceDetails[index]);
      }
    } catch (error) {
      this.loadSpinner = false;
    }
  }

  public downloadIdFilePreview(userInfoId, s3Key, s3Path, additionalKey, fileType, viewMode, insuItem?: any) {
    if (!s3Key || !s3Path) {
      return;
    }
    this.loadSpinner = true;
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if (!dData) {
        this.loadSpinner = false;
        return;
      }
      this.loadSpinner = false;
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }
      let iframeEle = null;
      if (viewMode === 'view') {
        iframeEle = document.getElementById(additionalKey + '_edit_insu_claims') as HTMLElement;
      }
      else {
        iframeEle = document.getElementById(additionalKey + '_chart_insu_doc') as HTMLElement;
      }
      if (insuItem) {
        insuItem.fileUrl = fileURL;
        insuItem.docType = fileType;
      }
      iframeEle?.setAttribute('src', fileURL);
      this.newInsuranceFormGroup?.get('fileURL')?.setValue(fileURL);
    }, (error) => {
      this.loadSpinner = false;
    });
  }

  public insuranceImagewithoutuserinfoId(additionalKey: string, viewmode: any, index?: number, insuItem?: any) {

    const documentType = "Insurance";
    const userInfoId = null;

    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data.responseObject !== null) {
        const s3Key = data?.responseObject?.s3Key;
        const s3Path = data?.responseObject?.s3Path;
        const additionalKey = data?.responseObject?.additionalKey;
        const fileType = data?.responseObject?.fileType;
        this.newInsuranceFormGroup?.get('docType')?.setValue(fileType);
        if (this.selectedInsuranceDt) {
          this.selectedInsuranceDt.docType = fileType;
        }
        if(insuItem) {
          insuItem.docType = fileType;
          this.appointmentInfoDetails.insuranceDetails[index] = insuItem;
        }
        this.downloadIdFilePreview(this.appointmentInfoDetails?.userInfoId, s3Key, s3Path, additionalKey, fileType, viewmode, insuItem);
      }
      this.loadSpinner = false;
    }, (error) => {
      this.loadSpinner = false;
    });
  }


  // View or Edit the Insurnace Details
  public editViewInsurnaceDetails(insurance: any, index: number, addNewInsurance: UiModalComponent, isEdit?: boolean): void {
    // if (!isEdit) {
    //   this.newInsuranceFormGroup?.disable();
    // }
    this.editInsuranceEnable = isEdit ? true : false;
    this.isEditMode = isEdit ? true : false;
    if (insurance) {
      this.selectedInsuranceDt = insurance;
      this.newInsuranceFormGroup?.patchValue(insurance);
      this.checkPrimarySubscriber('edit', insurance);
      this.indexViewEditInsurance = index;
      if (insurance?.insuranceCardImageId) {
        this.previewInsuranceImage(insurance?.insuranceCardImageId, "edit", index);
      }
    }
    addNewInsurance?.show();
  }

  // set the insurance related values
  public selectedInsurance(event: any, index?: number): void {
    this.newInsuranceFormGroup?.get('name')?.setValue(event?.name || null);

    const formArray = this.appointmentInfoDetails?.insuranceDetails || [];
    const selectedInsuranceCheck = formArray?.find(item => item?.name === event?.name && item?.active);
    if (selectedInsuranceCheck && selectedInsuranceCheck?.name === event?.name && !this.editInsuranceEnable) {
      this.notifyText = `You have already selected ${event?.name} Insurance. Please select any other  Insurance.`;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.newInsuranceFormGroup?.get('name')?.setValue(null);
    }
    else {
      this.newInsuranceFormGroup?.get('payerId')?.setValue(event?.payorid || null);
      this.newInsuranceFormGroup?.get('eligibilityRealtimePayerID')?.setValue(event?.eligibilityRealtimePayerID || null);
      this.newInsuranceFormGroup?.get('claimPayerId')?.setValue(event?.cpid || null);
      this.newInsuranceFormGroup?.get('eligibilityPortalID')?.setValue(event?.eligibilityPortalID || null);

    }
  }

  // primary subscriber check
  public checkPrimarySubscriber(mode: string, insurance?: any): void {
    const insurancePrimary = this.newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value || null;
    if (insurancePrimary === 'Self') {
      this.newInsuranceFormGroup?.get('subscriberfirstname')?.clearValidators();
      this.newInsuranceFormGroup?.get('subscriberlastname')?.clearValidators();
      this.newInsuranceFormGroup?.get('subscriberfirstname')?.setValidators([Validators?.required]);
      this.newInsuranceFormGroup?.get('subscriberlastname')?.setValidators([Validators?.required]);
      this.newInsuranceFormGroup?.get('subscriberfirstname').setValue(this.appointmentInfoDetails?.firstName || insurance?.subscriberfirstname || null);
      this.newInsuranceFormGroup?.get('subscriberlastname').setValue(this.appointmentInfoDetails?.lastName || insurance?.subscriberlastname || null);
      this.newInsuranceFormGroup?.get('subscribergender').setValue(this.appointmentInfoDetails?.gender || insurance?.subscribergender || null);
      this.newInsuranceFormGroup?.get('subscriberdateOfBirth').setValue((this.appointmentInfoDetails?.dob ? this.httpService?.formatDob(this.appointmentInfoDetails?.dob) : null) || insurance?.subscriberdateOfBirth || null);
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValidators([this.httpService.yearValidator.bind(this)]);
      this.newInsuranceFormGroup?.get('relationToSubscriber')?.reset();
      this.newInsuranceFormGroup?.get('relationToSubscriber')?.setValue(null);
      this.newInsuranceFormGroup?.get('relationToSubscriber')?.disable();
    } else if (insurancePrimary === 'Other') {
      let insurance = null;
      if (this.isEditMode) {
        insurance = this.selectedInsuranceDt;
      }
      else {
        insurance = insurance;
      }
      this.newInsuranceFormGroup?.get('subscriberfirstname').setValue(insurance?.subscriberfirstname || null);
      this.newInsuranceFormGroup?.get('subscriberlastname').setValue(insurance?.subscriberlastname || null);
      this.newInsuranceFormGroup?.get('subscribergender').setValue(insurance?.subscribergender || null);
      this.newInsuranceFormGroup?.get('subscriberdateOfBirth').setValue(insurance?.subscriberdateOfBirth || null);
      this.newInsuranceFormGroup?.get('relationToSubscriber').setValue(insurance?.relationToSubscriber || null);

      this.newInsuranceFormGroup?.get('relationToSubscriber')?.enable();

      this.newInsuranceFormGroup?.get('subscriberfirstname')?.setValidators([Validators?.required, Validators.pattern(AppRegExpressionsConfig.alphabetonly)]);
      this.newInsuranceFormGroup?.get('subscriberlastname')?.setValidators([Validators?.required, Validators.pattern(AppRegExpressionsConfig.alphabetonly)]);
      this.newInsuranceFormGroup?.get('subscribergender')?.setValidators([Validators?.required]);
      this.newInsuranceFormGroup?.get('subscriberdateOfBirth')?.setValidators([Validators?.required, this.httpService.dateValidator.bind(this)]);
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValidators([this.httpService.yearValidator.bind(this)]);
      this.newInsuranceFormGroup?.get('relationToSubscriber')?.setValidators([Validators?.required]);
    }
    // this.newInsuranceFormGroup?.get('subscriberfirstname')?.updateValueAndValidity();
    // this.newInsuranceFormGroup?.get('subscriberlastname')?.updateValueAndValidity();
    this.newInsuranceFormGroup?.get('subscribergender')?.updateValueAndValidity();
    this.newInsuranceFormGroup?.get('subscriberdateOfBirth')?.updateValueAndValidity();
    this.newInsuranceFormGroup?.get('relationToSubscriber')?.updateValueAndValidity();
    this.newInsuranceFormGroup?.get('medicareStartDate')?.updateValueAndValidity();
  }


  // medicare flag change in inusrance section
  public medicareFlagChange(): void {
    const medicarStartDate = this.newInsuranceFormGroup?.get('medicareStartDate').value || null;
    const insurancePrimary = this.newInsuranceFormGroup?.get('medicareFlag').value;
    if (insurancePrimary === true) {
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValidators([Validators?.required, this.httpService?.yearValidator?.bind(this)]);
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValue(medicarStartDate || null);
    } else {
      //this.newInsuranceFormGroup?.get('medicareStartDate')?.reset();
      this.newInsuranceFormGroup?.get('medicareStartDate')?.setValidators(null);
    }
    this.newInsuranceFormGroup?.get('medicareStartDate')?.updateValueAndValidity();
  }

  public changeCoverageSequence(selectValue: any, index?: number): void {
    if (!selectValue) {
      return;
    }
    const formArray = this.appointmentInfoDetails?.insuranceDetails?.filter((items: any) => items?.active) || [];
    if (formArray?.length === 0 || formArray?.length == 1) {
      const checkIndex = formArray?.length === 0 ? true : (this.indexViewEditInsurance || this.indexViewEditInsurance === 0);
      if (selectValue?.value !== 'Primary' && checkIndex) {
        this.notifyText = 'Please select the Primary Coverage initially.';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.newInsuranceFormGroup?.get('priority')?.setValue(null);
        return;
      } else if (selectValue?.value === 'Tertiary' && formArray?.length < 2) {
        this.notifyText = 'Please select the Secondary Coverage initially.';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.newInsuranceFormGroup?.get('priority')?.setValue(null);
        return;
      }
    }

    const findPrimaryCheck = formArray?.find(item => item?.priority === 'Primary') || null;
    const findSecondaryCheck = formArray?.find(item => item?.priority === 'Secondary') || null;
    const findTertiaryCheck = formArray?.find(item => item?.priority === 'Tertiary') || null;

    if ((findPrimaryCheck && findPrimaryCheck?.priority === selectValue?.value) || (findSecondaryCheck && findSecondaryCheck?.priority === selectValue?.value) || (findTertiaryCheck && findTertiaryCheck?.priority === selectValue?.value)) {
      if (!this.selectedInsuranceDt || this.selectedInsuranceDt?.priority !== selectValue?.value) {
        this.notifyText = `You have already selected ${selectValue?.value} Coverage. Please select other sequence for this Insurance Details.`;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.newInsuranceFormGroup?.get('priority')?.setValue(this.selectedInsuranceDt?.priority || null);
    }
  }

  // confirm the delet Insurance
  public confirmDelete(existingS3Key, insurance: any, deleteConfirmModal: UiModalComponent): void {
    this.existingS3Key = existingS3Key;
    this.selectedInsuranceDt = insurance;
    this.selectedMedicalRecordForDelete = insurance;
    deleteConfirmModal?.show();
  }

  // Close the Insurnace Edit View or New add insurnace
  public closeEditViewNewInsurance(): void {
    this.indexViewEditInsurance = null;
    this.newInsuranceFormGroup?.reset();
  }

  public addPrefixZipCode(addressZipCode: any): any {
    return this.httpService?.addPrefixZipCode(addressZipCode)
  }

  // Confirm Insurance details edit
  public insuDetailsDeleteConfirm(confirmModal: UiModalComponent, index: number): void {
    this.insudetailsDeleteIndex = index;
    confirmModal?.show();
  }

  // Delete and save the insurance details
  public deleteSaveInsuranceDetails(confirmModal: UiModalComponent, index: number): void {
    // this.appointmentInfoDetails?.insuranceDetails?.splice(index, 1);
    this.appointmentInfoDetails.insuranceDetails[index].active = false;
    this.updateAppointment?.emit(this.appointmentInfoDetails);
    confirmModal?.hide();
  }

  // Add or update the insurance details
  public addSaveInsuranceDetails(commonNewAddInsuEditInsurance?: UiModalComponent): void {
    if (this.newInsuranceFormGroup?.invalid) {
      this.newInsuranceFormGroup?.markAllAsTouched();
      return;
    }
    if (this.indexViewEditInsurance || this.indexViewEditInsurance === 0) {
      this.appointmentInfoDetails.insuranceDetails[this.indexViewEditInsurance] = this.newInsuranceFormGroup?.value;
    } else {
      this.newInsuranceFormGroup.value.active = true;
      this.appointmentInfoDetails.insuranceDetails?.push(this.newInsuranceFormGroup?.value);
    }
    this.updateAppointment?.emit(this.appointmentInfoDetails);
    commonNewAddInsuEditInsurance?.hide();
  }

  // full view preview
  public fullViewExpand(fileUrl?: string, fullViewInsuranceCardClaims?: UiModalComponent): void {
    fullViewInsuranceCardClaims?.show();
    const iframeEle = document.getElementById('insurance-card-full-view-claims') as HTMLElement;
    iframeEle.setAttribute('src', fileUrl);
  }

}
