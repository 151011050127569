<div class="row col-space">
    <ng-container *ngIf="isShowHideElements['VisitsCard']">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 card-items">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="icon-bg doctor-visits">
                            <img src="../../../../../../assets/images/flowboardIcons/doctors.svg" />
                        </div>
                        <div class="left-main-counter">
                            <h2 class="m-b-0 count-st">{{facilityData?.total || 0}}</h2>
                            <h6 class="card-label-st m-b-10">Visits</h6>
                        </div>
                        <!-- <i class="fas fa-user-md icon-bg doctor-visits"></i> -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <!-- <h2 class="m-b-0 count-st">{{facilityData?.inProgress || 0}}</h2>
                        <h6 class="card-label-st text-muted m-b-10">Doctor Visits</h6> -->

                        <div class="labs-claims-sec">
                            
                            <div class="right-sub-counters">
                                <div class="right-counters">
                                    <div class="flex-sub-items" style="cursor: pointer;" (click)="navigateFollowup('hospital/followup-queue','followup', {type : 'Wait List' })"><span
                                        class="sub-count-value">{{counterInfo?.totWaitlistCount}}</span> <span class="count-sub-title" id="flowBoardCards_waitlist"> WAIT LIST</span></div>

                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigateFollowup('hospital/followup-queue', 'followup',{type : 'Follow Up' })"><span
                                        class="sub-count-value">{{counterInfo?.totFollowUpCount}}</span> <span class="count-sub-title" id="flowBoardCards_followup">FOLLOW-UP</span></div>
                                    <div class="flex-sub-items"><span
                                            class="sub-count-value">{{allCurrentDayAppointmentCounters?.nurseGreet
                                            || 0}}</span> <span class="count-sub-title" id="flowBoardCards_nurseGreet">Nurse Greet</span></div>
                                    <div class="flex-sub-items"><span
                                            class="sub-count-value">{{allCurrentDayAppointmentCounters?.consultation
                                            || 0}}</span> <span class="count-sub-title" id="flowBoardCards_consultation">Consultation</span></div>
                                    <div class="flex-sub-items"><span
                                            class="sub-count-value">{{allCurrentDayAppointmentCounters?.completed
                                            || 0}}</span> <span class="count-sub-title" id="flowBoardCards_completed">Completed</span></div>
                                    <!-- <div class="flex-sub-items"><span
                                            class="sub-count-value">{{allCurrentDayAppointmentCounters?.cancelled
                                            || 0}}</span> <span class="count-sub-title" id="flowBoardCards_cancelled">Cancelled</span></div>
                                    <div class="flex-sub-items"><span
                                                class="sub-count-value">{{allCurrentDayAppointmentCounters?.noShow
                                                || 0}}</span> <span class="count-sub-title" id="flowBoardCards_noShow">No Show</span></div> -->
                                    <!-- <div class="flex-sub-items" style="cursor: pointer;" [routerLink]="['/hospital/pending-signoff-appointments']"><span
                                            class="sub-count-value">{{pendingSignoffCount?.pendingSignoffCount ||
                                            0}}</span> <span class="count-sub-title">Pending SignOff</span></div> -->
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </app-card>
        </div>
    </ng-container>
    <ng-container *ngIf="isShowHideElements['TasksCard']">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 card-items">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row">
                    <i class="fa fa-bell bell_trigger bell-action" [ngClass]="{'bell-active-icon': tasksCount?.PendingSignoffCount > 0 || tasksCount?.OpenTasks > 0 || tasksCount?.InProgressTasks > 0 || tasksCount?.HoldTasks > 0}"style="cursor: pointer;" [routerLink]="['']"></i>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="">
                            <i class="las la-tasks icon-bg tasks"></i>
                        </div>
                        <div class="left-main-counter">
                            <h2 class="m-b-0 count-st">{{totalTaskCount || 0}}</h2>
                            <h6 class="card-label-st m-b-10">Tasks</h6>
                        </div>
                        <!-- <i class="fas fa-user-md icon-bg doctor-visits"></i> -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <!-- <h2 class="m-b-0 count-st">{{facilityData?.inProgress || 0}}</h2>
                        <h6 class="card-label-st text-muted m-b-10">Doctor Visits</h6> -->
                       
                        <div class="labs-claims-sec" >
                           
                            <div class="right-sub-counters">
                                <div class="right-counters">
                                    <div class="flex-sub-items" style="cursor: pointer;"><span
                                            class="sub-count-value">{{tasksCount?.ReferralTasks || 0}}</span> <span class="count-sub-title" (click)="navigate('hospital/tasks', {type : 'Referral' })" id="flowBoardCards_referral">Referrals</span></div>

                                    <div class="flex-sub-items" style="cursor: pointer;"><span
                                            class="sub-count-value">{{tasksCount?.PendingSignoffCount || 0}}</span> <span class="count-sub-title" (click)="navigate('hospital/tasks', {type : 'Pending Signoff' })" id="flowBoardCards_pendingSignoff">Pending SignOff</span></div>
								
								<div class="flex-sub-items" style="cursor: pointer;"><span
                                            class="sub-count-value">{{tasksCount?.OpenTasks || 0}}</span> <span class="count-sub-title" (click)="navigate('hospital/tasks', {status : 'Open' })" id="flowBoardCards_open">Open</span></div>
								
                                
								<div class="flex-sub-items" style="cursor: pointer;"><span
                                            class="sub-count-value">{{tasksCount?.InProgressTasks || 0}}</span> <span class="count-sub-title" (click)="navigate('hospital/tasks', {status : 'In-Progress' })" id="flowBoardCards_inprogress">In progress</span></div>

                                            
								
								<div class="flex-sub-items" style="cursor: pointer;"><span
                                            class="sub-count-value">{{tasksCount?.HoldTasks || 0}}</span> <span class="count-sub-title" (click)="navigate('hospital/tasks', {status : 'Hold' })" id="flowBoardCards_onhold">On Hold</span></div>

								</div>
                            </div>
                        </div>

                    </div>
                </div>
            </app-card>
        </div>
    </ng-container>

    <!-- <ng-container *ngIf="isShowHideElements['FutureAppointments']">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row" [routerLink]="['/hospital/future-appointments']" style="cursor: pointer;">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <div class="icon-bg future-appt">
                            <img src="../../../../../../assets/images/flowboardIcons/future_appointments.svg" />
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <div class="labs-claims-sec">
                            <div class="left-main-counter">
                                <h2 class="m-b-0 count-st">{{futureAppointmnetsCount?.futureAppointments || '0'}}</h2>
                                <h6 class="card-label-st m-b-10">Future Appointments</h6>

                                <div class="flex-sub-items"><span class="sub-count-value">{{
                                        futureAppointmnetsCount?.followUpAppointments || 0}}</span> <span
                                        class="count-sub-title">Follow-Up</span></div>
                            </div>
                        </div>
                    </div>

                </div>
            </app-card>
        </div>
    </ng-container> -->

    <ng-container *ngIf="isShowHideElements['CareGapsCard']">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 card-items">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row">
                    <!-- <i class="fa fa-bell bell_trigger bell-action" [ngClass]="{'bell-active-icon': tasksCount?.PendingSignoffCount > 0 || tasksCount?.OpenTasks > 0 || tasksCount?.InProgressTasks > 0 || tasksCount?.HoldTasks > 0}"style="cursor: pointer;" [routerLink]="['']"></i> -->
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="">
                            <div class="icon-bg future-appt">
                                <img src="../../../../../../assets/images/flowboardIcons/Handle With Care.png" />
                            </div>
                            <div class="left-main-counter">
                                <!-- <h2 class="m-b-0 count-st">{{totalTaskCount || 0}}</h2> -->
                                <h6 class="card-label-st m-b-10 mt-2" style="cursor: pointer;">CCM</h6>
                            </div>
                        </div>
                        <!-- <i class="fas fa-user-md icon-bg doctor-visits"></i> -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <!-- <h2 class="m-b-0 count-st">{{facilityData?.inProgress || 0}}</h2>
                        <h6 class="card-label-st text-muted m-b-10">Doctor Visits</h6> -->
                        <div class="labs-claims-sec">
                          
                            <div class="right-sub-counters">
                                <div class="right-counters">
                                    <!-- <div class="flex-sub-items" style="cursor: pointer;" (click)="navigateFollowup('hospital/followup-queue','followup', {type : 'Wait List' })"><span
                                            class="sub-count-value">{{counterInfo?.totWaitlistCount}}</span> <span class="count-sub-title" id="flowBoardCards_waitlist"> WAIT LIST</span></div>

                                    <div class="flex-sub-items" style="cursor: pointer;" (click)="navigateFollowup('hospital/followup-queue', 'followup',{type : 'Follow Up' })"><spanCARE GAPS
                                            class="sub-count-value">{{counterInfo?.totFollowUpCount}}</span> <span class="count-sub-title" id="flowBoardCards_followup">FOLLOW-UP</span></div> -->
								
								<div class="flex-sub-items" style="cursor: pointer;" (click)="navigateFollowup('hospital/enrolled-patients', 'caregap',{list : 'caregap' })"><span
                                            class="sub-count-value">{{counterInfo?.totCareGapsCount}}</span> <span class="count-sub-title" id="flowBoardCards_caregaps">TOTAL POPULATION</span></div>
                                            <div class="flex-sub-items" style="cursor: pointer;" (click)="navigateFollowup('hospital/enrolled-patients','carepathway', {list : 'carepathway' })"><span
                                                class="sub-count-value">{{counterInfo?.totCarePathwaysCount}}</span> <span class="count-sub-title" id="flowBoardCards_carepathways">ENROLLED</span></div>

                                            
                                
                                        </div>
                            </div>
                        </div>

                    </div>
                </div>
            </app-card>
        </div>
    </ng-container>

    <!-- <ng-container *ngIf="isShowHideElements['Patient']">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <i class="fas fa-user-injured text-c-red icon-bg"></i>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <h6 class="card-label-st text-muted m-b-10">Patient</h6>
                        <h2 class="m-b-0 count-st">{{facilityData?.total || 0}}</h2>
                    </div>
                </div>
            </app-card>
        </div>
    </ng-container> -->
    <ng-container *ngIf="isShowHideElements['MedicationRefills'] || isShowHideElements['RxOrders']">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 card-items">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="icon-bg medi-refills">
                            <img src="../../../../../../assets/images/flowboardIcons/medication refills.svg" />
                        </div>
                        <!-- <i class="fas fa-pills icon-bg medi-refills"></i> -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 align-flex">
                        <span class="medicatons-rxOrders" style="cursor: pointer;" [routerLink]="['/hospital/rx-orders']" *ngIf="isShowHideElements['RxOrders']"><span class="count-st">{{rxOrderCount?.RxOrders || 0}}</span> <span class="card-label-st" id="flowBoardCards_rxorder">Rx Orders</span></span>
                        <span class="medicatons-rxOrders" *ngIf="isShowHideElements['MedicationRefills']"><span class="count-st">{{rxOrderCount?.MedicationRefills || 0}}</span> <span class="card-label-st" id="flowBoardCards_refills">Refills</span></span><br />
                    </div>
                </div>
            </app-card>
        </div>
    </ng-container>
   


    <!-- <ng-container *ngIf="isShowHideElements['RxOrders']">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="row" [routerLink]="['/hospital/rx-orders']"
                    style="cursor: pointer;">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <div class="icon-bg rx-orders">
                            <img src="../../../../../../assets/images/flowboardIcons/rx_orders.svg"/>
                        </div>
                        <i class="fa fa-file-prescription icon-bg rx-orders"></i>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9">
                        <h2 class="m-b-0 count-st">{{rxOrderCount || 0}}</h2>
                        <h6 class="card-label-st text-muted m-b-10">Rx Orders</h6>
                    </div>
                </div>
            </app-card>
        </div>
    </ng-container> -->
    <ng-container *ngIf="isShowHideElements['LabOrders']">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 card-items">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row">
                    <i class="fa fa-bell bell_trigger bell-action" [ngClass]="{'bell-active-icon': labOrderCount?.abnormalReports > 0 || labOrderCount?.LABNotReviewedCount > 0}" style="cursor: pointer;" [routerLink]="['/hospital/lab-orders']"></i>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="icon-bg lab-orders">
                            <img src="../../../../../../assets/images/flowboardIcons/labs.svg" />
                        </div>
                        <div class="left-main-counter" style="cursor: pointer;" [routerLink]="['/hospital/lab-orders']" id="flowBoardCards_lab">
                            <h2 class="m-b-0 count-st">{{labOrderCount?.LABTotalOrderCount || 0}}</h2>
                            <h6 class="card-label-st m-b-10">Labs</h6>
                        </div>
                        <!-- <i class="fa fa-file-medical-alt icon-bg lab-orders"></i> -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <div class="labs-claims-sec">
                           
                            <div class="right-sub-counters">
                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigate('hospital/lab-orders', {order : 1 })"><span class="sub-count-value">{{labOrderCount?.LABORDEREDCount ||
                                        0}}</span> <span class="count-sub-title" id="flowBoardCards_ordered">Ordered</span></div>
                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigate('hospital/lab-orders', {order : 2 })"><span class="sub-count-value">{{labOrderCount?.LABRESULTSCount ||
                                        0}}</span> <span class="count-sub-title" id="flowBoardCards_resultReported">Results Reported</span></div>
                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigate('hospital/lab-orders', {order : 2 })"><span class="sub-count-value">{{labOrderCount?.LABNotReviewedCount
                                            ||
                                            0}}</span> <span class="count-sub-title" id="flowBoardCards_pendingReview">Pending Review</span></div>        
                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigate('hospital/lab-orders', {order : 2 })"><span class="sub-count-value">{{labOrderCount?.abnormalReports
                                        ||
                                        0}}</span> <span class="count-sub-title" id="flowBoardCards_abnormalResult">Abnormal results pending review</span></div>
                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigate('hospital/lab-orders', {order : 3 })"><span class="sub-count-value">{{labOrderCount?.LABUNSOLICITEDCount
                                            ||
                                            0}}</span> <span class="count-sub-title" id="flowBoardCards_unsolicited">UNSOLICITED</span></div>
                               
                                        
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
    </ng-container>
    <!-- RAD Orders -->
    <ng-container *ngIf="isShowHideElements['RadOrdersCard']">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 card-items">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row">
                    <i class="fa fa-bell bell_trigger bell-action" [ngClass]="{'bell-active-icon': labOrderCount?.RADNotReviewedCount > 0}"></i>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <span class="material-symbols-outlined icon-bg payments">
                            radiology
                            </span>
                            <div class="left-main-counter" [routerLink]="['/hospital/rad-orders']" style="cursor: pointer;" id="flowBoardCards_rad">
                                <h2 class="m-b-0 count-st">{{labOrderCount?.RADTotalOrderCount || 0}}</h2>
                                <h6 class="card-label-st m-b-10">RAD</h6>
                            </div>
                        <!-- <i class="fa fa-file-medical-alt icon-bg lab-orders"></i> -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <div class="labs-claims-sec">
                           
                            <div class="right-sub-counters">
                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigate('hospital/rad-orders', {order : 1 })"><span class="sub-count-value">{{labOrderCount?.RADORDEREDCount ||
                                        0}}</span> <span class="count-sub-title" id="flowBoardCards_ordered1">Ordered</span></div>
                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigate('hospital/rad-orders', {order : 2 })"><span class="sub-count-value">{{labOrderCount?.RADRESULTSCount ||
                                        0}}</span> <span class="count-sub-title" id="flowBoardCards_resultReported1">Results Reported</span></div>
                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigate('hospital/rad-orders', {order : 3 })"><span class="sub-count-value">{{labOrderCount?.RADUNSOLICITEDCount
                                        ||
                                        0}}</span> <span class="count-sub-title" id="flowBoardCards_unsolicited1">UNSOLICITED</span></div>
                                <div class="flex-sub-items" style="cursor: pointer;" (click)="navigate('hospital/rad-orders', {order : 2 })"><span class="sub-count-value">{{labOrderCount?.RADNotReviewedCount
                                        ||
                                        0}}</span> <span class="count-sub-title" id="flowBoardCards_pendingReview1">Pending Review</span></div>


                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
    </ng-container>

    <!-- Claims [routerLink]="['/hospital/all-claims']" -->
    <ng-container *ngIf="isShowHideElements['Claims']">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 card-items">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row">
                    <i class="fa fa-bell bell_trigger bell-action" style="cursor: pointer;"></i>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="icon-bg claims">
                            <img src="../../../../../../assets/images/flowboardIcons/claims.svg" />
                        </div>
                        <div class="left-main-counter" style="cursor: pointer;">
                            <h2 class="m-b-0 count-st">{{claimsCount?.totalClaims || 0}}</h2>
                            <h6 class="card-label-st m-b-10">Claims</h6>
                        </div>
                        <!-- <i class="fa fa-file-medical-alt icon-bg claims"></i> -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <div class="labs-claims-sec">
                            
                            <div class="right-sub-counters">
                                <div class="right-counters">
                                    <div class="flex-sub-items" id="flowBoardCards_submitted" style="cursor: pointer;" (click)="navigate('hospital/all-claims-new', {claims : 3 })"><span
                                            class="sub-count-value">{{claimsCount?.SubmittedClaims
                                            || 0}}</span> <span class="count-sub-title">Submitted</span></div>
                                    <div class="flex-sub-items" id="flowBoardCards_arPatient" style="cursor: pointer;" (click)="navigate('hospital/all-claims-new', {claims : 10 })"><span
                                            class="sub-count-value">{{claimsCount?.patientResponsibilityClaims
                                            || 0}}</span> <span class="count-sub-title" >AR Patient</span>
                                    </div>
                                    <div class="flex-sub-items" id="flowBoardCards_adjudicated" style="cursor: pointer;" (click)="navigate('hospital/all-claims-new', {claims : 8 })"><span
                                            class="sub-count-value">{{claimsCount?.AdjudicatedClaims
                                            || 0}}</span> <span class="count-sub-title">Adjudicated</span></div>
                                    <div class="flex-sub-items" id="flowBoardCards_closed" style="cursor: pointer;" (click)="navigate('hospital/all-claims-new', {claims : 12 })"><span
                                            class="sub-count-value">{{claimsCount?.ClosedClaims
                                            || 0}}</span> <span class="count-sub-title" >Closed</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
    </ng-container>

    <!-- Payments -->
    <ng-container *ngIf="isShowHideElements['PaymentsCard']">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 card-items">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <i class="fa fa-file-invoice-dollar icon-bg payments"></i>
                        <div class="left-main-counter" style="cursor: pointer;"
                        [routerLink]="['/hospital/payment-details']" id="flowBoardCards_payments">
                        <h2 class="m-b-0 count-st">{{paymentsCount || '0'}}</h2>
                        <h6 class="card-label-st m-b-10 pay-txt">Payments</h6>
                    </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <div class="labs-claims-sec">
                          
                            <div class="right-sub-counters">
                                <div class="right-counters" style="cursor: pointer;">
                                    <div class="flex-sub-items" *ngIf="loginDetails?.facilityId !== 89" [routerLink]="['/hospital/payment-details','pendingapproval']"><span class="sub-count-value">{{paymentApprovalCount
                                            || 0}}</span> <span class="count-sub-title" id="flowBoardCards_pendingApproval">Pending Approval</span></div>
                                            <div class="flex-sub-items" [routerLink]="['/hospital/payment-details','pendingbalances']"><span class="sub-count-value">{{pendingCount
                                                || 0}}</span> <span class="count-sub-title" id="flowBoardCards_pendingBalance">Pending Balances</span></div>
                                    <!-- <div class="flex-sub-items" [routerLink]="['/hospital/account-balances']"><span class="sub-count-value">{{pendingCount
                                                || 0}}</span> <span class="count-sub-title" id="flowBoardCards_pendingBalance">Pending Balances</span></div> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
    </ng-container>

    

    <!-- Workflow Efficiency card -->
    <ng-container *ngIf="isShowHideElements['WorkflowEfficiency'] && isReadMore">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 card-items">
            <app-card class="new-sub-designed-card" [hidHeader]="true">
                <div class="align-height row" [routerLink]="['/hospital/workflow-efficiency']" style="cursor: pointer;">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="icon-bg workflow-efficiency">
                            <img src="../../../../../../assets/images/flowboardIcons/workflow.svg" />
                        </div>
                        <!-- <i class="fa fa-tasks icon-bg workflow-efficiency"></i> -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <h2 class="m-b-0 count-st">70%</h2>
                        <h6 class="card-label-st m-b-10">Workflow Efficiency</h6>
                    </div>
                </div>
            </app-card>
        </div>
    </ng-container>


</div>
<div class="row" style="margin:10px;text-align:right;" *ngIf="allCardsLength > 8">
    <div class="col-lg-12 col-md-6 col-sm-6">
        <a (click)="showCard()" class="loadMore">{{ !isReadMore ? 'Load More':
            'Load Less' }}</a>
        <!--<button type="button" class="btn btn-info" (click)="showCard()">
			{{ !isReadMore ? 'Load More': 'Load Less' }}
			</button>-->
    </div>
</div>
<div class="row">
    <!-- error phone number details -->
    <app-ui-modal #errorPhoneDetailsModal [hideHeader]="false" id="errorPhoneDetailsModal">
        <div class="app-modal-header">
            <h5 class="modal-title">Incorrect Primary Phone Numbers</h5>
            <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                (click)="errorPhoneDetailsModal.hide()"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="app-modal-body add-app-body">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Patient Name</th>
                        <th>Gender</th>
                        <th>Age</th>
                        <th>Phone Number</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="allErrorPhoneNumAppointments?.length > 0">
                        <tr *ngFor="let errorPhoneAppnt of allErrorPhoneNumAppointments; let i = index">
                            <td>{{errorPhoneAppnt?.lastName}}, {{errorPhoneAppnt?.firstName}}
                                 {{errorPhoneAppnt?.middleName}}</td>
                            <td>{{errorPhoneAppnt?.gender}}</td>
                            <td>{{errorPhoneAppnt?.age}}</td>
                            <td>{{errorPhoneAppnt?.patientPrimaryPhone}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="allErrorPhoneNumAppointments?.length === 0">
                        <tr>
                            <td colspan="4" style="text-align: center;">No Appointments are Available.</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="app-modal-footer">
            <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="flowBoardCards_ok"
                (click)="errorPhoneDetailsModal.hide()">Ok</button>
        </div>
    </app-ui-modal>
</div>