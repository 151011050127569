<!-- Common Send Fax Modal -->
<app-ui-modal #sendFaxCommonModal [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
    id="sendFaxCommonModal">

    <div class="app-modal-header">
        <h5 class="modal-title">Send Fax</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="sendFaxCommonModal?.hide(); sendNewFaxForm?.reset()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="app-modal-body add-app-body">
        <div class="send-new-fax-form" [formGroup]="signatureForm">
            <input type="hidden" class="form-control" formControlName="signature" value="">
        </div>
        <div class="send-new-fax-form" [formGroup]="sendNewFaxForm">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="faxNumber">Reciever Fax Number <span class="required">*</span></label>
                        <input class="form-control input-text" type="text" mask="(000) 000-0000"
                            placeholder="(xxx) xxx-xxxx" id="faxNumber" formControlName="faxNumber" />
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('faxNumber')?.errors?.required && sendNewFaxForm?.get('faxNumber')?.touched">Reciever
                            Fax Number is required</span>
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('faxNumber')?.invalid && !sendNewFaxForm.get('faxNumber')?.errors?.required">Please
                            enter valid Fax Number. It should be 10 digit</span>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="recipientName">Reciever Name <span class="required">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Reciever Name"
                            id="recipientName" formControlName="recipientName" />
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('recipientName')?.errors?.required && sendNewFaxForm?.get('recipientName')?.touched">Reciever
                            Name is required</span>
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('recipientName')?.invalid && !sendNewFaxForm?.get('recipientName')?.errors?.required">Please
                            enter valid Receiver Name.</span>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="subject">Subject <span class="required">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Subject" id="subject"
                            formControlName="subject" />
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('subject')?.errors?.required && sendNewFaxForm?.get('subject')?.touched">Subject
                            is required</span>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="documentName">Document Name <span class="required">*</span></label>
                        <input class="form-control input-text" type="text" placeholder="Document Name "
                            id="documentName" formControlName="documentName" [readonly]="cologuardFax ? 'true' : null" />
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('documentName')?.errors?.required && sendNewFaxForm?.get('documentName')?.touched">Document
                            Name is required</span>
                    </div>
                </div>


                <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="multipartFile">Upload Document <span class="required">*</span></label><br />
                        <input type="file" name="file" (change)="onFileSelect($event)"><br />
                        <span class="required"
                            *ngIf="sendNewFaxForm?.get('multipartFile')?.errors?.required && sendNewFaxForm?.get('multipartFile')?.touched">File
                            Upload is required</span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <div class="app-modal-footer text-center">
        <button type="button" class="btn btn-primary dash-actions" id="sendFaxcommon_submit" style="margin-right: 15px;" data-dismiss="modal"
            (click)="createdNewFax(sendFaxCommonModal)">Submit</button>
    </div>

</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Display Cologuard PDF -->
<app-ui-modal #cologuardTestModal [hideHeader]="false" [modalCentered]="true" id="cologuardTestModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Cologuard Test Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="cologuardTestModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="CologuardTest">
            <iframe id="cologuard-test-pdf" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="sendFaxcommon_sendfax"
            (click)="cologuardTestModal.hide();sendFaxCommonModal?.show()">Send Fax</button>
    </div>
</app-ui-modal>