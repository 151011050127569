import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { AppRegExpressionsConfig } from 'src/app/theme/shared/litterals/app.regularexpressions';
import { HttpService } from 'src/app/theme/shared/services/http.service';

@Component({
  selector: 'app-fax-referral-common',
  templateUrl: './fax-referral-common.component.html',
  styleUrls: ['./fax-referral-common.component.scss']
})
export class FaxReferralCommonComponent implements OnInit, OnChanges {

  public sendNewFaxForm: FormGroup;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) private exceptionComp: ExceptionModalComponent;
  @Input() eFaxCard: CardComponent;
  public loadSpinner: boolean;
  public documentsList: any[] = [];
  @ViewChild('newFaxUpload') public newFaxUpload: ElementRef;
  @ViewChild('sendNewFax') sendNewFaxModal: UiModalComponent;
  @Input() providerFirstName: string;
  @Input() providerLastName: string;
  @Input() appointmentId: string;
  @Input() quickParentForm: FormGroup;
  @Input() documentName: string;
  @Input() defaultFile: any;
  @Input() userAppointmentDetails: any;
  @Input() userInfoId: string;
  public allLabRadResults: any[] = [];
  public custLabRadResultsDocs: any[] = [];
  @Input() isOutbound: string;
  @Output() outBoundRefresh = new EventEmitter<boolean>();
  @Input() filesentFrom: string;
  @Input() filesentSource: string;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
    this.sendNewFaxForm = formBuilder?.group({
      'faxNumber': [null, Validators?.compose([Validators?.required, Validators?.pattern(AppRegExpressionsConfig?.mobile)])],
      'recipientName': [null, Validators?.compose([Validators?.required, Validators?.pattern(AppRegExpressionsConfig?.nameValidator)])],
      'subject': [null, Validators?.required],
      'documentName': [null],
      'multipartFile': [null, Validators?.required],
      'patientName': [null],
      'referrralNotes': [null, Validators?.required],
      'labRadResults': [null]
    });
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      // if (this.defaultFile) {
      //   this.documentsList?.push(this.defaultFile);
      //   this.sendNewFaxForm?.get('multipartFile')?.setValue(this.defaultFile);
      // }
      // if(this.userAppointmentDetails) {
      //   this.sendNewFaxForm?.get('patientName')?.setValue(this.userAppointmentDetails?.firstName + ' ' + this.userAppointmentDetails?.lastName);
      // }
    }
  }

  public openFaxModal(): void {
    this.sendNewFaxModal?.show();    
    //console.log(this.filesentFrom);
    if (this.defaultFile) {
      this.defaultFile.noDelete = true;
      this.documentsList?.push(this.defaultFile);
      this.sendNewFaxForm?.get('multipartFile')?.setValue(this.defaultFile);
    }
    if (this.isOutbound === 'yes' || this.filesentFrom ==='1500form') {
      this.sendNewFaxForm?.get('referrralNotes')?.setValidators(null);
    } else {
      this.sendNewFaxForm?.get('referrralNotes')?.setValidators([Validators?.required]);
    }
    this.sendNewFaxForm?.get('referrralNotes')?.updateValueAndValidity();
    if (this.userAppointmentDetails) {
      this.sendNewFaxForm?.get('patientName')?.setValue(this.userAppointmentDetails?.lastName + ' ' + this.userAppointmentDetails?.firstName );
    }
    this.readLabRadDocs();
  }

  public deleteSelectedFile(index) {
    const files = this.documentsList;
    this.documentsList.splice(index, 1);
    this.newFaxUpload.nativeElement.value = "";
    if(this.defaultFile && this.documentsList?.length === 0) {
      this.sendNewFaxForm?.get('multipartFile')?.setValue(null);
    }
  }

  public resetData(): void {
    this.sendNewFaxForm?.reset();
    this.documentsList = [];
   // this.newFaxUpload.nativeElement.value = "";
  }

  public onFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const files = event.target.files;
      // ...this.documentsList, 
      // this.documentsList = [...this.documentsList, ...Array.from(files)]; 
      Array.from(files)?.forEach((fileDoc: any) => {
        const fileSize = fileDoc?.size / 1024 / 1024;
        if (fileSize <= 25 && (fileDoc?.type === 'application/pdf' || fileDoc?.type === 'image/png' || fileDoc?.type === 'image/jpeg')) {
          if(!this.documentsList?.find(item => item?.name === fileDoc?.name)) {
            this.documentsList?.push(fileDoc); 
          }
        }
        else {
          //this.resetData();
          if (this.documentsList && this.documentsList?.length === 0) {
            this.documentsList = [];
          }
          this.newFaxUpload.nativeElement.value = "";
          this.notifyText = "Supports only PDF, PNG, JPEG files and Files larger than 25MB cannot be uploaded";
          this.isSuccessNotify = false;
          this.exceptionComp?.modalShow();
          return;
        }
      });
      if (!this.defaultFile && this.documentsList?.length > 0) {
        this.sendNewFaxForm?.get('multipartFile')?.setValue(this.documentsList[0]);
      }
    }
  }

  // Send Fax / Referral Fax
  public async createdNewFax(sendFaxModal: UiModalComponent) {
    if (!this.defaultFile && this.documentsList?.length > 0) {
      this.sendNewFaxForm?.get('multipartFile')?.setValue(this.documentsList[0]);
    }
    if (this.sendNewFaxForm?.invalid) {
      this.sendNewFaxForm?.markAllAsTouched();
      return;
    }
    const allDocsUpload = [];
    let allDocsUploaded: any[] = [];
    if (this.documentsList && this.documentsList?.length > 1) {
      let dupDocList: any[] = [...this.documentsList] || [];
      dupDocList?.shift();
      dupDocList?.forEach((docFile: any) => {
        const getData = this.uploadAdditionalDocuments(docFile);
        allDocsUpload?.push(this.httpService?.makePostWithFormData(getData?.action, getData?.formData));
      });
      let allKeys: any[] = [];
      try {
        const allUploadKeys = await forkJoin(allDocsUpload)?.toPromise();
        allUploadKeys?.forEach((keyDoc: any) => {
          const action = `record/listIndividualDocument?documentType=Efax Additional Document&additionalKey=${keyDoc?.keyName}`;
          allKeys?.push(this.httpService?.makeGetRequest(action, ''));
        });
      } catch {
        this.loadSpinner = false;
      }
      // S3 document sissue
      try {
        const alS3Docs = await forkJoin(allKeys)?.toPromise();
        alS3Docs?.forEach((s3Doc: any) => {
          if (s3Doc?.responseObject) {
            allDocsUploaded?.push(s3Doc?.responseObject);
          }
        });
      } catch {
        this.loadSpinner = false;
      }
    }
    this.loadSpinner = false;
    if(this.filesentFrom !== '1500form'){
      allDocsUploaded = [...allDocsUploaded, ...this.custLabRadResultsDocs] || [];
    }
    else{
      allDocsUploaded = null;
    }
    
   
    // convert to binary for data
    const requestBody = {
      "additionalDocs": allDocsUploaded?.length > 0 ? allDocsUploaded : null,
      "addlInfo": "",
      "appointmentId": this.appointmentId || "",
      "documentName": this.documentName || 'Efax',
      "faxNumber": '+1' + this.sendNewFaxForm?.value?.faxNumber,
      "patientName": this.sendNewFaxForm?.value?.patientName || '',
      "recipientName": this.sendNewFaxForm?.value?.recipientName || '',
      "referrralNotes": this.quickParentForm?.value?.referrralNotes || this.sendNewFaxForm?.value?.referrralNotes || ' ',
      "referringProvider": (this.providerFirstName || this.providerLastName) ? ('Dr.' + (this.providerFirstName || '') + ' ' + (this.providerLastName || '')) : '',
      "subject": this.sendNewFaxForm?.get('subject')?.value || ''
    };

    if (!this.defaultFile) {
      this.sendNewFaxForm?.get('multipartFile')?.setValue(this.documentsList[0]);
    }
    // requestBody.documentName = this.sendNewFaxForm?.value?.patientName ? this.sendNewFaxForm?.value?.patientName?.trim()+'_' : '' + this.documentsList[0]?.name;

    const params = new URLSearchParams();
    for (const key in requestBody) {
      if (requestBody[key] !== null && requestBody[key] !== 'null' && requestBody[key] !== '') {
        const keyValue = requestBody[key] && (key === 'startDate' || key === 'endDate') ? requestBody[key]?.split('-')?.join('') : requestBody[key];
        let result = Array?.isArray(keyValue);
        params?.append(key, result ? JSON?.stringify(keyValue) : keyValue);
      }
    }

    const formData = new FormData();
    formData.append('file', this.sendNewFaxForm?.value?.multipartFile);
    const action = `sendFax2?${params}`;
    this.loadSpinner = true;
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.notifyText = data?.message || 'New Fax has been sent successfully.';
        this.isSuccessNotify = true;
        this.exceptionComp?.modalShow();
        sendFaxModal?.hide();
        this.resetData();
        if (this.isOutbound) {
          this.outBoundRefresh?.emit(true);
        }
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionComp?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionComp?.modalShow();
        this.loadSpinner = false;
      });
  }

  public getCurrentTimeStamp() {
    const current = new Date();
    current.setHours(0)
    current.setMinutes(0)
    current.setSeconds(0)
    current.setMilliseconds(0)
    const timestamp = current.getTime();
    return timestamp;
  }


  // Upload the Additonal Uploaded Documents
  private uploadAdditionalDocuments(uploadFile?: any) {
    const formData = new FormData();
    const timestamp = this.getCurrentTimeStamp();
    const documentType = this.sendNewFaxForm?.get('documentType')?.value;
    const nameValue = this.sendNewFaxForm?.get('documentName')?.value;

    const isFile = true;
    const documentName = documentType + "_" + timestamp + "_" + nameValue;
    this.sendNewFaxForm?.get('documentName')?.setValue(documentName);
    let actionAppend = '';

    formData.append('idFile', uploadFile);
    // 
    actionAppend = `record/addDocument?documentName=${this.sendNewFaxForm?.value?.patientName ? this.sendNewFaxForm?.value?.patientName?.trim() + '_' : ''}${uploadFile?.name}&documentType=Efax Additional Document&isFile=${isFile}&fileType=${uploadFile?.type}`;

    // formData.append('idFile', this.sendNewFaxForm?.value?.multipartFile);
    // actionAppend = `record/addDocument?userInfoId=${this.selectedAppointmentUser?.id}&documentName=${documentName}&documentType=${this.sendNewFaxForm?.value?.documentType}&isFile=${isFile}&fileType=${this.UploadedFiletype}`;

    const action = actionAppend;
    this.loadSpinner = true;
    // const action = `record/addDocument?documentName=${documentName}&documentType=${this.sendNewFaxForm?.value?.documentType}&appointmentId=${this.selectedAppointmentUser?.id}&isFile=${isFile}`;
    return {
      action,
      formData
    }
  }

  // Read all last 3 past visits lab and rad results docs
  public readLabRadDocs(): void {
    const action = `record/listLastNEncounterDocuments?documentTypes=Lab Results&documentTypes=Radiology Results&noOfEncounters=3&userInfoId=${this.userInfoId || this.userAppointmentDetails?.userInfoId || ''}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject && data?.responseObject?.length > 0) {
          this.sendNewFaxForm?.get('labRadResults')?.setValue(data?.responseObject);
          this.allLabRadResults = data?.responseObject || [];
          this.allLabRadResults?.forEach((item: any) => {
            item.isChecked = true;
          });
          this.custLabRadResultsDocs = JSON?.parse(JSON?.stringify(data?.responseObject));
        }
      }
    }, error => {

    });
  }

  // add and remove the lab rad results to upload
  public addLabRadResultstoUpload(event: any, labRadRes: any): void {
    // const value = this.sendNewFaxForm?.get('labRadResults')?.value || [];
    // this.custLabRadResultsDocs = value || [];
    if (event?.target?.checked) {
      if(!this.custLabRadResultsDocs?.find(checkItem => checkItem?.s3Key === labRadRes?.s3Key)) {
        this.custLabRadResultsDocs?.push(labRadRes);
        const dupResults = JSON?.parse(JSON?.stringify(this.allLabRadResults));
        let labRadResCheck = dupResults?.find(item => item?.s3Key === labRadRes?.s3Key) || [];
        if(labRadResCheck) {
          labRadResCheck.isChecked = true;
        }
        this.allLabRadResults = dupResults;
      }
    } else {
      this.custLabRadResultsDocs = this.custLabRadResultsDocs?.filter(item => item?.s3Key !== (event?.target?.value || labRadRes?.s3Key)) || [];
    }
    //console.log(this.custLabRadResultsDocs);
  }

  // delete lab rad docs
  public deleteLabRadDocs(index: number, doc?: any): void {
    this.custLabRadResultsDocs?.splice(index, 1);
    const dupResults = JSON?.parse(JSON?.stringify(this.allLabRadResults));
    let labRadRes = dupResults?.find(item => item?.s3Key === doc?.s3Key) || [];
    if(labRadRes) {
      labRadRes.isChecked = false;
    }
    this.allLabRadResults = dupResults;
    // const value = this.sendNewFaxForm?.get('labRadResults')?.value || [];
    // const filDocs = value?.filter(item => item?.s3Key !== doc?.s3Key) || [];
    // this.sendNewFaxForm?.get('labRadResults')?.setValue(filDocs);
  }

  // Uploaded doc view
  public viewDocumentUploaded(doc: any, docsUploadView: UiModalComponent): void {
    var file = new Blob([doc], { type: doc?.type || 'application/pdf' });
    var fileURL = URL?.createObjectURL(file);
    //console.log(fileURL);
    this.eFaxCard?.cardRefreshHide();
    let iframeEle = null;
    if(this.filesentSource === '1500formClaim'){
       iframeEle = document?.getElementById('docs-upload-pdf-view-hiffa') as HTMLElement;
      
    }
    else{
       iframeEle = document?.getElementById('docs-upload-pdf-view') as HTMLElement;
     
    }
  
    iframeEle?.setAttribute('src', fileURL);
    docsUploadView?.show();
    

    
    //console.log(iframeEle);
   
    
  }

  // View lab or rad results related docs
  public viewLabRadResultsDoc(labRadDoc: any, docsUploadView: UiModalComponent) {
    if (!labRadDoc?.s3Key || !labRadDoc?.s3Path) {
      return;
    }
    const fileType = labRadDoc?.fileType;
    this.loadSpinner = true;
    const action = `record/downloadDocument?s3Key=${labRadDoc?.s3Key}&s3Path=${labRadDoc?.s3Path}`;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      if (!data) {
        this.loadSpinner = false;
        return;
      }
      const file = new Blob([data], { type: fileType })
      const fileURL = URL.createObjectURL(file);
      setTimeout(() => {
        const iframeEle = document.getElementById('docs-upload-pdf-view') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
      }, 500);
      docsUploadView?.show();
      this.loadSpinner = false;
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionComp?.modalShow();
      });
  }

}
