<div class="content">
    <perfect-scrollbar [style.max-height]="'100%'">
        <div class="card-body">
            <div class="dropdown-menu-right notification">
                <!-- <div class="noti-head">
                    <h6 class="d-inline-block m-b-0">Past Visits</h6>
                </div> -->

                <div class="history-tl-container">
                    <ul class="tl">
                        <ng-container *ngFor="let patientPastVisit of patientPastVisits">
                            <li class="tl-item"
                                [ngClass]="patientPastVisit.appointmentId === selectedPrevisit ? 'selected' : ''"
                                (click)="appointmentPastVisit(patientPastVisit)">

                                <div class="tl-item-content">

                                    <div class="title">
                                        <div class="item-title">{{patientPastVisit?.purposeOfVisit}}</div>
                                        <div class="timestamp"
                                            [ngClass]="patientPastVisit.appointmentId === selectedPrevisit ? 'selected' : ''">
                                            {{patientPastVisit?.appointmentDate | date:'MM/dd/yyyy'}}
                                        </div>
                                    </div>
                                    <div class="item-detail">
                                        <strong> Chief Complaints </strong> :
                                        <ng-container
                                            *ngFor="let category of patientPastVisit?.chiefComplaints; let cati = index">
                                            <ng-contaier *ngFor="let section of category?.sections">
                                                <ng-contaier *ngFor="let answers of section?.answers">
                                                    <span>
                                                        <ng-container
                                                            *ngFor="let value of answers?.actualValue; let listi = index">
                                                            <span>{{value?.answer}} <span
                                                                    *ngIf="value?.answer === 'Other' && value?.notes"><b>{{value?.notes}}</b></span></span>

                                                            <span
                                                                *ngIf="listi < answers?.actualValue.length-1 ">,</span>
                                                        </ng-container>
                                                    </span>
                                                </ng-contaier>
                                                <ng-container
                                                    *ngIf="!section?.answers || section?.answers?.length === 0">
                                                    <label>No option has been selected under Chief Complaints.</label>
                                                </ng-container>
                                            </ng-contaier>
                                            <!--<ng-contaier *ngIf="category?.otherNotes">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="labelStyle">Additional Notes</label> <span
                                            class="label-colon"> :
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>{{category?.otherNotes || '--'}}</label>
                                    </div>
                                </div>
                            </ng-contaier>-->
                                        </ng-container>
                                        <ng-container *ngIf="patientPastVisit?.chiefComplaints === null">
                                            <label>No option has been selected under Chief Complaints.</label>
                                        </ng-container>

                                        <!-- {{patientPastVisit?.appointmentId}} -->
                                    </div>

                                </div>
                                <div class="item-info">
                                    <div class="item-info-message">

                                        <ng-container
                                            *ngFor="let category of patientPastVisit?.carePlan; let cati = index">
                                            <h5 *ngIf="category?.resourceType === 'General'">{{category?.resourceType}}
                                            </h5>
                                            <ng-contaier *ngFor="let section of category?.sections">
                                                <ng-contaier>
                                                    <h6 *ngIf="section?.name === 'Assessment'"><u>{{section?.name}}</u>
                                                    </h6>
                                                    <div class="row">

                                                        <div class="col-lg-12 col-md-12 col-sm-12"
                                                            *ngFor="let answer of section?.answers">

                                                            <!--  <div class="form-group row"
                                                            *ngIf="section?.name !== 'Assessment' && category?.resourceType !== 'Billing Codes' && answer?.label !== 'Billing / Procedure/ HCPIC Codes' && answer?.label !== 'Prescriptions' && answer?.label !== 'New Lab/Rad Orders'">
                                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                                <label class="labelStyle">{{answer?.label}}</label>
                                                                <span class="label-colon"> :
                                                                </span>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                                <ul class="no-list-style"
                                                                    class="carePlan{{answer?.label}}">
                                                                    <ng-container
                                                                        *ngFor="let value of answer?.actualValue; let listi = index">
                                                                        <li>{{value?.answer || value?.duration ||
                                                                            value?.units || '--'}}&nbsp;
                                                                        </li>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="answer?.actualValue?.length === 0">
                                                                        <li>--</li>
                                                                    </ng-container>
                                                                </ul>
                                                            </div>
                                                        </div>-->


                                                            <ng-container *ngIf="answer?.otherNotes">
                                                                <div class="form-group row">
                                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                                        <label class="labelStyle">{{answer?.label}} Additional
                                                                            Notes</label> <span class="label-colon">
                                                                            :
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                                        <label>{{answer?.otherNotes || '--'}}</label>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <!-- <ng-container *ngIf="answer?.otherNotes">
                                                                <div class="form-group row">
                                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                                        <label class="labelStyle">Additional
                                                                            Notes</label>
                                                                        <span class="label-colon">
                                                                            :
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                                        <label>{{answer?.otherNotes || '--'}}</label>
                                                                    </div>
                                                                </div>
                                                            </ng-container> -->
                                                            <ng-contaier *ngIf="section?.name === 'Assessment'">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                                        <div class="table-responsive">
                                                                            <table
                                                                                class="table table-striped table-bordered table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>ICD Code</th>
                                                                                        <th>Description</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr
                                                                                        *ngFor="let tbody of answer?.actualValue; let listi = index">
                                                                                        <td>{{tbody?.code}}</td>
                                                                                        <td>{{tbody?.description}}</td>
                                                                                    </tr>
                                                                                    <tr
                                                                                        *ngIf="answer?.actualValue?.length === 0">
                                                                                        <td colspan="2"
                                                                                            class="text-center">ICD
                                                                                            Codes are unavailable for
                                                                                            this patient.</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-contaier>

                                                        </div>
                                                    </div>
                                                </ng-contaier>
                                            </ng-contaier>

                                            <ng-container *ngIf="category?.otherNotes">
                                                <div class="form-group row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label class="labelStyle">Additional Notes</label> <span
                                                            class="label-colon"> :
                                                        </span>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                                        <label>{{category?.otherNotes || '--'}}</label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="patientPastVisit?.carePlan && patientPastVisit?.carePlan.length === 0">
                                            <span>No careplan added.</span>
                                        </ng-container>
                                        <ng-container *ngIf="!patientPastVisit?.carePlan">
                                            <span>No careplan added.</span>
                                        </ng-container>
                                    </div>
                                    <a class="btn-link">View More >></a>

                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>

                <ul class="noti-body no-past-visits"
                    *ngIf="patientPastVisits === undefined || patientPastVisits === null || patientPastVisits?.length === 0">
                    <li class="n-title">
                        <p class="m-b-0"> No Past Visits</p>
                    </li>
                </ul>

            </div>
        </div>
    </perfect-scrollbar>
</div>

<perfect-scrollbar>
    <!-- appointment details -->
    <!-- <ng-container>
        <div class="past-visit-details">
            
        </div>
    </ng-container> -->
</perfect-scrollbar>