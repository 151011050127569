import { formatDate } from '@angular/common';
import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { PaymentsCommonNewComponent } from '../payments-common-new/payments-common-new.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ViewportScroller } from "@angular/common";
import { PaymentsRefactorComponent } from '../payments-refactor/payments-refactor.component';
import { ApisService } from '../../services/apis.services';

@Component({
  selector: 'app-user-visit-details',
  templateUrl: './user-visit-details.component.html',
  styleUrls: ['./user-visit-details.component.scss']
})
export class UserVisitDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() appointmentId: string;
  public getRouteParam: Subscription;
  @ViewChild('userVisitCard') private userVisitCard: CardComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) private exceptionModal: ExceptionModalComponent;
  //public appointmentId: string;
  public appointmentInfoDetails: any;

  public navigationList: Array<any> = [
    // {
    //   breadcrumbs: true,
    //   title: "Treatment Flow",
    //   type: "item",
    //   url: '/hospital/hosp-patient'
    // },
    {
      breadcrumbs: true,
      title: "Patient Details",
      type: "item",
      url: ''
    },
    {
      breadcrumbs: true,
      title: "Past Visits",
      type: "item",
      url: ''
    }
  ]

  public othersReviewSystems = [];
  public chiefComplaints = [];
  public assessmentValues = [];
  public billingValues = [];
  public allBehaviourScreening = [];
  public hpiForm: any;
  public vitalsForm: any;
  public examValues = [];
  public allRxOrderDetails = [];
  public alllabRadOrderDetails = [];
  treatmentFlowAddNotes: any;
  public treatmentFlowData: any;
  public allLabResultsDocs = [];
  public allRadiologyResultsDocs = [];
  public allOtherDocs = [];
  public allReferralDocs = [];
  subscriberDetails: any;
  public allInternalLabOrders: any[] = [];

  // plan order table 
  public dtOptionsLabOrders: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public dtTriggerLabOrders: Subject<any> = new Subject<any>();
  public legacyLabOrderResults = [];

  public dtOptionsRxOrders: DataTables.Settings = {};
  public dtTriggerRxOrders: Subject<any> = new Subject<any>();
  public loginDetails: any;
  @ViewChild(PaymentsRefactorComponent) private payComp: PaymentsRefactorComponent;
  public paymentConfigDetails: any;
  public appointmentCostOfCare: FormGroup;
  appointmentCostOfCareDetails: any;
  wellnessFormDocs: any;
  public defaultStepIndex = 0;
  public allergiesFromVaccine: any[] = [];
  public otherAddedForms: any[] = [];
  public checkCommonIdDocument: any;
  public allConsentFormDocs: any[] = [];
  public allAdditonalDocuments: any[] = [];
  public saveComplete: boolean = true;
  @Output() cssRefresh = new EventEmitter<boolean>();
  allInternalVaccineDetails: any[];
  public downloadedFileType: any;


  constructor(private activateRoute: ActivatedRoute, private httpService: HttpService, private formBuilder: FormBuilder, private scroller: ViewportScroller, private apiService: ApisService) {

    this.getRouteParam = this.activateRoute?.params?.subscribe(params => {
      //const appointmentId = params['appointmentId'];
      //this.appointmentId = appointmentId;
    });

    this.activateRoute?.queryParams?.subscribe(
      (params: Params) => {
        if (params && params['payments'] === 'yes') {
          this.defaultStepIndex = 17;
        }
        if (params && params['labresults'] === 'yes') {
          this.defaultStepIndex = 18;
        }
      });
    this.appointmentCostOfCare = this.formBuilder?.group({
      "appointmentId": [this.appointmentId || null],
      "discount": [null, Validators?.required],
      "nonPayment": [null, Validators?.required],
      "patientResponsibility": [null, Validators?.required],
      "reimburseAmount": [null, Validators?.required],
      "credits": [null, Validators?.required],
      "approvedAmount": [null, Validators?.required],
      "coverageCost": [null, Validators?.required],
    });
  }

  ngOnInit(): void {

    this.dtOptionsLabOrders = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[1, "desc"]],
      columnDefs: [{ targets: [1], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
        },
    };

    this.dtOptionsRxOrders = {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      order: [[1, "desc"]],
      columnDefs: [{ targets: [1], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
        },
    };
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.getPaymentConfig();
    this.changeFormPaymentChanges();
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  ngAfterViewInit(): void {

    this.getAppointmentById(this.appointmentId);
    this.getAllAdditionalDocs(this.appointmentId);
    this.getTreatmenTFlowDataByAppointmentId();
    // this.getAppointmentCost();
    this.appointmentCostOfCare.controls['appointmentId'].setValue(this.appointmentId);
  }

  public getAppointmentById(appointmentId: string): void {
    let action: any = 'appointment/registration/getAppointmentById?id=';
    let params: any = {
      'id': appointmentId
    }
    this.userVisitCard?.cardRefreshShow();
    this.httpService.makeGetRequest(action, params?.id)
      .subscribe((data: any) => {
        if (data?.status == 'SUCCESS') {
          this.appointmentInfoDetails = data?.responseObject;
          this.getCommonIdDocument();
          this.getCommonConsentFormDocs();
          // this.downloadDlCard(this.appointmentInfoDetails?.idDocument?.identityImageId);
          // this.appointmentInfoDetails.dob = formatDate(this.appointmentInfoDetails?.dob, 'MM/dd/yyyy', 'en-US');
          this.appointmentInfoDetails.dob = this.httpService?.formatDobTimeZoneWithDisplay(this.appointmentInfoDetails?.dob);
          if (this.appointmentInfoDetails?.insuranceDetails?.length > 0 && this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth) {
            this.appointmentInfoDetails.insuranceDetails[0].subscriberdateOfBirth = this.httpService?.formatDobTimeZoneWithDisplay(this.appointmentInfoDetails?.insuranceDetails[0]?.subscriberdateOfBirth);
            this.subscriberDetails = this.appointmentInfoDetails?.insuranceDetails[0] || null;
          }
        } else {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
        this.userVisitCard?.cardRefreshHide();
      }, error => {
        this.userVisitCard?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      });
  }

  public getAllAdditionalDocs(appointmentId: string): void {
    const action = `record/listDocuments?appointmentId=${appointmentId}`;
    this.userVisitCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {

      if (data?.status == 'SUCCESS') {
        const responseObj = data?.responseObject || [];
        this.allAdditonalDocuments = responseObj?.filter((listItem: any) => listItem?.documentType !== 'Consent Form') || [];
        this.allAdditonalDocuments = JSON.parse(JSON?.stringify(data?.responseObject || []));
        this.allLabResultsDocs = responseObj?.filter(d => d.documentType == 'Lab Results') || [];
        this.allRadiologyResultsDocs = responseObj.filter(d => d.documentType == 'Radiology Results');
        this.allOtherDocs = responseObj.filter(d => d.documentType == 'Other');
        this.allReferralDocs = responseObj.filter(d => d.documentType == 'Referral');
        this.wellnessFormDocs = responseObj.filter(d => d.documentType == 'Wellness Forms') || [];
        this.otherAddedForms = responseObj.filter(d => d.documentType !== 'Lab Results' && d.documentType !== 'Wellness Forms' && d.documentType !== 'Radiology Results' && d.documentType !== 'Other' && d.documentType !== 'Referral') || [];
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    }, (error) => {
      this.userVisitCard?.cardRefreshHide();
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
    });



    /* 
    let action: any = 'appointment/registration/getAllAdditionalDocs?appointmentId=';
 	
     let params: any = {
       'appointmentId': appointmentId
     }
    this.httpService.makeGetRequest(action, params?.appointmentId)
       .subscribe((data: any) => {
         if (data?.status == 'SUCCESS') {
           const responseObj = data?.responseObject || [];
           this.allAdditonalDocuments = JSON.parse(JSON?.stringify(data?.responseObject || []));
           this.allLabResultsDocs = responseObj?.filter(d => d.documentType == 'Lab Results') || [];
           this.allRadiologyResultsDocs = responseObj.filter(d => d.documentType == 'Radiology Results');
           this.allOtherDocs = responseObj.filter(d => d.documentType == 'Other');
           this.allReferralDocs = responseObj.filter(d => d.documentType == 'Referral');
           this.wellnessFormDocs = responseObj.filter(d => d.documentType == 'Wellness Forms') || [];
           this.otherAddedForms = responseObj.filter(d => d.documentType !== 'Lab Results' && d.documentType !== 'Wellness Forms' && d.documentType !== 'Radiology Results' && d.documentType !== 'Other' && d.documentType !== 'Referral') || [];
         } else {
           this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
           this.exceptionModal?.modalShow();
         }
         this.userVisitCard?.cardRefreshHide();
       }, error => {
         this.userVisitCard?.cardRefreshHide();
         this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
         this.exceptionModal?.modalShow();
       });*/
  }

  public getAppointmentTime(date: any): string {
    return this.httpService?.getAppointmentTime(date);
  }

  // get RX Order details
  public rxOrderDetails(): void {
    const action = `changeHealth/rxOrders/${this.appointmentId}`;
    this.userVisitCard?.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const rdOrder = data?.responseObject?.rxOrder || null;
        this.allRxOrderDetails = rdOrder || [];
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        // this.exceptionModal.modalShow();
      }
      this.userVisitCard?.cardRefreshHide();
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        // this.exceptionModal.modalShow();
        this.userVisitCard?.cardRefreshHide();
      });
  }

  // get treatment Flow Data
  private getTreatmenTFlowDataByAppointmentId(): void {
    const action = `treatmentFlow/${this.appointmentId}`;
    // this.PatientDetailsCardReload.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.treatmentFlowData = data?.responseObject || {};
        this.othersReviewSystems = this.treatmentFlowData?.reviewOfSystems?.categories || [];
        this.chiefComplaints = this.treatmentFlowData?.chiefComplaints?.values || [];
        this.hpiForm = this.treatmentFlowData?.hpi || {};
        this.vitalsForm = this.treatmentFlowData?.vital || {};
        this.examValues = this.treatmentFlowData?.exam?.generalExams || [];
        this.assessmentValues = this.treatmentFlowData?.assessment?.icdCodes || [];
        this.billingValues = this.treatmentFlowData?.billing?.billings || [];
        delete this.treatmentFlowData?.behavioralScreening?.creationDate;
        delete this.treatmentFlowData?.behavioralScreening?.lastUpdatedDate;
        this.allBehaviourScreening = this.treatmentFlowData?.behavioralScreening || [];
        // this.allInternalLabOrders = this.treatmentFlowData?.internalLabOrders?.requisitions || [];
        this.treatmentFlowAddNotes = {
          'chiefComplaintsNotes': this.treatmentFlowData?.chiefComplaints?.notes,
          'hpiNotes': this.treatmentFlowData?.hpi?.notes,
          'rosNotes': this.treatmentFlowData?.reviewOfSystems?.notes,
          'examNotes': this.treatmentFlowData?.exam?.notes,
          'assessmentNotes': this.treatmentFlowData?.assessment?.notes,
          'planNotes': this.treatmentFlowData?.medications?.notes,
          'otherNotes': this.treatmentFlowData?.chiefComplaints?.otherNotes
        };
        // this.alllabRadOrderDetails = this.treatmentFlowData?.labRequisition?.order || [];
        // this.allRxOrderDetails = this.treatmentFlowData?.medications?.rxOrder || [];
        this.dtTriggerLabOrders?.next();
        this.dtTriggerRxOrders?.next();
        // Legacy Medical Order Lab Order Results
        let allLegacyLabResults: any = [];
        if (this.appointmentInfoDetails?.appointment?.purposeOfVisit === 'Legacy Medical Record') {
          this.treatmentFlowData?.labRequisition?.order?.forEach(element => {
            element?.reports?.forEach(reportMsg => {
              if (reportMsg?.hl7Message) {
                let replace = reportMsg?.hl7Message?.replace('"[', '');
                replace = replace?.replace(']"', '');
                const splitArray = replace?.split('||,');
                splitArray?.forEach(splitItem => {
                  splitItem = splitItem?.replace('[', '');
                  splitItem = splitItem?.replace(']', '');
                  const itemSplit = splitItem?.split(', ');
                  const obj = {
                    lastvisitdate: itemSplit && (itemSplit[0] === ' null' || itemSplit[0] === 'null') ? null : (itemSplit[0] || null),
                    orderName: itemSplit && itemSplit[1] || null,
                    labAttribute: itemSplit && itemSplit[2] || null,
                    labAttributeValue: itemSplit && itemSplit[3] || null,
                  }
                  allLegacyLabResults = [...allLegacyLabResults, ...[obj]];
                })
              }
            })
          });
        };
        this.legacyLabOrderResults = allLegacyLabResults || [];
        // this.allergiesFromVaccine = [];
        // this.allergiesFromVaccine = this.treatmentFlowData?.vaccineDetails?.vaccineAllergies || [];
      }
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.dtTriggerLabOrders?.next();
        this.dtTriggerRxOrders?.next();
      });
  }

  // get Internal LAB Order details
  public labRadOrderDetails(): void {
    const action = `changeHealth/getLabRadbyAppointment?appointmentId=${this.appointmentId}`;
    this.userVisitCard?.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const labOrder = data?.responseObject || [];
        const internalLabORders = JSON.parse(JSON.stringify(labOrder || []));
        const allInternalOrders = [];
        internalLabORders?.forEach(element => {
          if (element?.location === 'Internal' && element?.orderType === 'LAB') {
            allInternalOrders?.push(element?.internalLabdetails);
          }
        });
        this.allInternalLabOrders = allInternalOrders || [];

        const externalLabOrders = JSON.parse(JSON.stringify(labOrder || []));
        this.alllabRadOrderDetails = externalLabOrders?.filter((exOrder: any) => exOrder?.location === 'External') || [];

      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        // this.exceptionModal.modalShow();
      }
      this.userVisitCard?.cardRefreshHide();
      this.dtTriggerLabOrders.next();
    },
      error => {
        this.userVisitCard?.cardRefreshHide();
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal.modalShow();
        this.dtTriggerLabOrders.next();
      });
  }

  //  to read the all vaccinations from other caollection
  public async getAllInternalVaccinationDetails(): Promise<any> {
    this.allergiesFromVaccine = [];
    const action = `vaccinationHistory/readVaccinationDetails?appointmentId=${this.appointmentId}&userInfoId=${this.appointmentInfoDetails?.userInfoId}`;
    this.userVisitCard?.cardRefreshShow();
    try {
      const allVaccineDetails: any = await this.httpService?.makeGetRequest(action, '')?.toPromise();
      this.userVisitCard?.cardRefreshHide();
      let vaccinations = [];
      allVaccineDetails?.responseObject?.forEach((element: any) => {
        vaccinations?.push(element?.vaccinationDetails);
      });
      this.allInternalVaccineDetails = vaccinations || [];
      this.allergiesFromVaccine = (allVaccineDetails?.responseObject && allVaccineDetails?.responseObject?.length > 0) ? allVaccineDetails?.responseObject[0]?.vaccineAllergies : [];
    } catch {
      this.allInternalVaccineDetails = [];
      this.allergiesFromVaccine = [];
      this.userVisitCard?.cardRefreshHide();
    }
  }

  // check object and object keys have values or not
  public checkObjValues(obj: any): boolean {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "")
        return true;
    }
    return false;
  }

  public checkObjBehvaiourScreen(behavValue: any): boolean {
    for (const objeItem of behavValue) {
      if (objeItem?.value || objeItem?.value === 0) {
        return true;
      }
    }
    return false;
  }

  public behaviorScreeningValues(): boolean {
    let checkIfValue: boolean = false;
    for (const key in this.allBehaviourScreening) {
      const keyValue = this.allBehaviourScreening[key];
      for (const subKey of keyValue) {
        if (subKey?.value || subKey?.value === 0) {
          checkIfValue = true;
        }
      }
    }
    return checkIfValue;
  }

  public pointsCount(keyName: string): any {
    const sum = this.allBehaviourScreening[keyName]?.map(item => parseInt(item?.value || 0))?.reduce((prev, curr) => prev + curr);
    return sum;
  }

  // co-pay and c-insurance summation
  public coPayCoInsurance(payments: any): any {
    const sum = payments?.map((item: any) => parseInt(item?.amount || 0))?.reduce((prev, curr) => prev + curr);
    return (sum / 100) || 0;
  }

  // Amount Paid Summation summation
  public amountPaidSummation(payments: any): any {
    const filterSuccessPayment = payments?.filter((item: any) => item?.status === 'COMPLETED') || [];
    if (filterSuccessPayment?.length > 0) {
      const sum = filterSuccessPayment?.map((item: any) => parseInt(item?.amount || 0))?.reduce((prev, curr) => prev + curr);
      return (sum / 100) || 0;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.getRouteParam?.unsubscribe();
  }

  // open card payment 
  public openPayment(): void {
    if (!this.saveComplete) {
      this.notifyText = 'Please Save befor payment';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      return;
    }
    // this.payComp?.enableCardPay();
    this.payComp?.openModal(this.appointmentInfoDetails, true);
  }

  // to get payment config details
  private getPaymentConfig(): any {
    // const action = `providerConfiguration/getPaymentConfig?facilityId=${this.loginDetails?.facilityId}`;
    this.apiService?.getPaymentConfig(this.loginDetails?.facilityId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.paymentConfigDetails = data?.responseObject;
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionModal?.modalShow();
      });
  }

  // Show Documents view popup
  public downloadDocument(s3Key: string, s3Path: string, fileType: string, pdfConsentModal: UiModalComponent) {
    if (!s3Key || !s3Path) {
      return;
    }
    //const action = `appointment/registration/getAdditionalDoc?s3Key=${s3Key}`;
    const action = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.userVisitCard?.cardRefreshShow();
    this.downloadedFileType = fileType;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      if(!data) {
        this.userVisitCard?.cardRefreshHide();
        return;
      }
      var file = new Blob([data], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('consent-pdf') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      pdfConsentModal?.show();
      this.userVisitCard?.cardRefreshHide();
    },
      (error: any) => {
        this.notifyText = error?.message || 'User does not have privileges to perform action';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.userVisitCard?.cardRefreshHide();
      });
  }

  public getHcgResult(result: any) {
    let note: string = '';
    result = parseInt(result);
    if (result || result === 0) {
      if (result <= 5)
        note = 'Negative';
      else if (result >= 6 && result <= 24)
        note = 'Retest';
      else if (result >= 25)
        note = 'Positive';
      return note;
    }
  }

  // get appointment cost of care
  public getAppointmentCost(): void {
    const action = `appointment/registration/retrieveApptCostofCare?appointmentId=${this.appointmentId}`;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.appointmentCostOfCareDetails = data?.body?.responseObject || {};
        this.appointmentCostOfCare?.get('reimburseAmount')?.setValue(data?.body?.responseObject?.apptCostofCare?.reimburseAmount);
        this.appointmentCostOfCare?.get('discount')?.setValue(data?.body?.responseObject?.apptCostofCare?.discount);
        this.appointmentCostOfCare?.get('nonPayment')?.setValue(data?.body?.responseObject?.apptCostofCare?.nonPayment);
        this.appointmentCostOfCare?.get('credits')?.setValue(data?.body?.responseObject?.apptCostofCare?.credits);
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(data?.body?.responseObject?.apptCostofCare?.patientResponsibility);
        this.appointmentCostOfCare?.get('approvedAmount')?.setValue(data?.body?.responseObject?.apptCostofCare?.approvedAmount);
        this.appointmentCostOfCare?.get('coverageCost')?.setValue(data?.body?.responseObject?.apptCostofCare?.coverageCost);

        if (data?.body?.responseObject?.apptCostofCare?.patientResponsibility === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
          const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((data?.body?.responseObject?.apptCostofCare?.reimburseAmount || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (data?.body?.responseObject?.apptCostofCare?.discount || 0) + (data?.body?.responseObject?.apptCostofCare?.nonPayment || 0));
          this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
        }
      }
    }, (error) => {

    });
  }

  // submit the pay of const of care for appointment
  public submitPayCost(): void {
    const action = `appointment/registration/saveApptCostofCare`;
    const params = this.appointmentCostOfCare?.value;
    this.userVisitCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      this.userVisitCard?.cardRefreshHide();
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = 'Payment details have been updated successfully.';
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.saveComplete = true;
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    }, (error) => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.userVisitCard?.cardRefreshHide();
    });
  }

  // change paymeCore
  public changeFormPaymentChanges(): void {
    this.appointmentCostOfCare?.get('reimburseAmount')?.valueChanges?.subscribe(value => {
      if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
        const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((value || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (this.appointmentCostOfCare?.get('discount')?.value || 0) + (this.appointmentCostOfCare?.get('nonPayment')?.value || 0));
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      }
    });
    this.appointmentCostOfCare?.get('discount')?.valueChanges?.subscribe(value => {
      if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
        const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((this.appointmentCostOfCare?.get('reimburseAmount')?.value || 0) + (this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) + (value || 0) + (this.appointmentCostOfCare?.get('nonPayment')?.value || 0));
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      }
    });
    this.appointmentCostOfCare?.get('nonPayment')?.valueChanges?.subscribe(value => {
      if (this.appointmentCostOfCare?.get('patientResponsibility')?.value === 0 && this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges > 0) {
        const patientResponsibilityAmount = (this.appointmentCostOfCareDetails?.apptCostofCare?.totalVisitCharges || 0) - ((this.appointmentCostOfCare?.get('reimburseAmount')?.value || 0) + ((this.appointmentCostOfCareDetails?.apptCostofCare?.coPay || 0) || 0) + (this.appointmentCostOfCare?.get('discount')?.value || 0) + (value || 0));
        this.appointmentCostOfCare?.get('patientResponsibility')?.setValue(patientResponsibilityAmount);
      }
    });
  }

  // Insurance / DL Image Card Download
  public downloadInsuranceCard(cardId: string, insuraceCardModal: UiModalComponent): void {
    this.downloadIdentificationCard(cardId, 'Insurance', this.appointmentInfoDetails?.userInfoId, insuraceCardModal);
    // const action = `document/user/getInsuranceCard/${cardId}`;
    // this.userVisitCard?.cardRefreshShow();
    // this.httpService.downloadImage(action).subscribe((data: any) => {
    //   var file = new Blob([data], { type: 'application/pdf' })
    //   var fileURL = URL.createObjectURL(file);
    //   // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
    //   // if you want to open PDF in new tab
    //   // created dynamic iframe SRC attribute and assigned lookup url
    //   // window.open(fileURL);
    //   insuraceCardModal?.show();
    //   this.userVisitCard?.cardRefreshHide();
    //   const iframeEle = document.getElementById('insurance-card-past-visit-multiple') as HTMLElement;
    //   iframeEle.setAttribute('src', fileURL);
    // },
    //   (error: any) => {
    //     this.userVisitCard?.cardRefreshHide();
    //   });
  }

  // Common id Document download
  private getCommonIdDocument(): void {
    this.httpService?.getDlIdDocument(this.loginDetails?.facilityId, this.appointmentInfoDetails?.userInfoId, this.appointmentInfoDetails?.id)?.subscribe((data: any) => {
      if (data?.responseObject?.identityImageId) {
        this.checkCommonIdDocument = data?.responseObject?.identityImageId;
        //this.downloadDlCard(data?.responseObject?.identityImageId);
        const documentType = "Identification Record";
        const userInfoId = this.appointmentInfoDetails?.userInfoId;
        this.downloadIdentificationCard(data?.responseObject?.identityImageId, documentType, userInfoId);
      }
    })
  }

  private getCommonConsentFormDocs(): void {
    /*this.httpService?.getConsentFormsDocuments(this.loginDetails?.facilityId, this.appointmentInfoDetails?.userInfoId, this.appointmentInfoDetails?.id)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.allConsentFormDocs = data?.responseObject || [];
        this.allConsentFormDocs = this.allConsentFormDocs?.filter(item => item?.status === 'SIGNED') || [];
      }
    })*/
    const documentType = 'Consent Form';
    const action = `record/listDocuments?appointmentId=${this.appointmentInfoDetails?.id}&documentType=${documentType}`;
    this.userVisitCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.userVisitCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {

        this.allConsentFormDocs = data?.responseObject || [];
        // this.allConsentFormDocs = this.allConsentFormDocs?.filter((listItem: any) => listItem?.documentType === 'Consent Form') || [];
        /* this.allConsentFormDocs?.forEach((formList: any, index: number) => {
 
           if (formList == 'Consent Form') {
             this.allConsentFormDocs = this.allConsentFormDocs?.filter((listItem: any) => listItem !== 'Consent Form') || [];
           }
 
         });*/


      }
      else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.userVisitCard?.cardRefreshHide();
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
    });
  }

  /*public downloadDlCard(cardId: string): void {
    if (!cardId) {
      return;
    }
    const action = `document/user/getIdCard/${cardId}`;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // window.open(fileURL);
      const iframeEle = document.getElementById('dl-past-visit-card') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    },
      (error: any) => {
      });
  }*/



  public downloadIdentificationCard(additionalKey, documentType, userInfoId, insuraceCardModal?: UiModalComponent) {
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${userInfoId}&additionalKey=${additionalKey}`;
    this.userVisitCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.userVisitCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {

        }
        else {


          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;

          const fileType = data?.responseObject?.fileType;

          this.downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {
      this.userVisitCard?.cardRefreshHide();
    });
  }

  public downloadIdFile(userInfoId: any, s3Key: any, s3Path: any, additionalKey: any, fileType: any, insuraceCardModal?: UiModalComponent) {
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.userVisitCard?.cardRefreshShow();
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if(!dData) {
        this.userVisitCard?.cardRefreshHide();
        return;
      }
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);

      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }

      if (insuraceCardModal) {
        insuraceCardModal?.show();
        const iframeEle = document.getElementById('insurance-card-past-visit-multiple') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
      } else {
        const iframeEle = document.getElementById('dl-past-visit-card') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
      }
      this.userVisitCard?.cardRefreshHide();

    }, (error) => {
      this.userVisitCard?.cardRefreshHide();
    });
  }


  public checkAnnulWellnessVisit(othersReviewSystems: any): void {
    return othersReviewSystems?.filter(item => item?.name === 'Annual Wellness Visit') || [];
  }

  onAddClass() {
    this.cssRefresh.emit(true);
  }
  public responseChange(change: any) {
    this.saveComplete = false;
  }

  navigateToTop(el: HTMLElement) {
    // this.scroller.scrollToAnchor("mainDetails");
    el.scrollIntoView();

  }
  public statusCodeDesc(charStatus: string): string {
    if (!charStatus) {
      return '--'
    }
    charStatus = charStatus?.toUpperCase();
    let status: string;
    switch (charStatus) {
      case 'C':
        status = 'Corrected';
        break;
      case 'E':
        status = 'Entered';
        break;
      case 'F':
        status = 'Final Reported';
        break;
      case 'I':
        status = 'Inactive';
        break;
      case 'P':
        status = 'Partial Reported';
        break;
      case 'NA':
        status = 'Results Received';
        break;
      case 'TX':
        status = 'Transmission Error';
        break;
      case 'R':
        status = 'Transmission Error';
        break;
      case 'T':
        status = 'Transmitted';
    }
    return status;
  }
  public addPrefixZipCode(addressZipCode: any,valid:any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
   
    return prefixZipcode || addressZipCode || '';
  }

}
