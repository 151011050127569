<!-- <ng-container *ngIf="isShowHideElements['TreatmentFlowQueue']">
  <button class="btn btn-primary blue-btn" (click)="addApptNavigate()"> <i class="feather icon-plus"></i> New
    Appointment</button>
</ng-container> -->


<div class="row btn-page">

  <!-- <ng-container>
 <button class="btn btn-primary patient-list-btn" (click)="showHideList()" ngbTooltip="Patients List"> 
  <i class="feather icon-minus" *ngIf="showList" placement="right"></i> 
  <i class="feather icon-plus" *ngIf="!showList" placement="right"></i> </button>
</ng-container>-->

  <ng-container *ngIf="showList">
    <div class="col-sm-12">

      <app-card #TreatmentFlowCard class="new-designed-card" id="careflow-queue-quickselect" cardClass="card-datatable"
        [hidHeader]="true" [options]="false">
        <div class="table-responsive new-designed-table">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>Patient Info</th>
                <th>Service Provider</th>
                <th>Room</th>
                <th>Purpose of visit</th>
                <th>Appointment Time</th>
                <!--<th>Wait Time</th>-->
                <th style="line-height: 0px;">Nurse Greet
                  <p style="font-size: 10px; margin-bottom: 0px; margin-top: 11px;">(Mins)</p>
                </th>
                <th style="line-height: 0px;">Triage Room
                  <p style="font-size: 10px; margin-bottom: 0px; margin-top: 11px;">(Mins)</p>
                </th>
                <!--<th>Total Time</th>-->
                <th>Status</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="!isShowHideElements['TreatmentFlowQueue']">
                <tr>
                  <td colspan="7" class="text-center">You don't have Access for Care Flow Queue..!</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="isShowHideElements['TreatmentFlowQueue']">
                <tr *ngFor="let patientDetails of dtPatientDetails; let i=index">
                  <td>
                    <ng-container *ngIf="patientDetails?.appointmentStatus?.currentStatus === 'CANCELLED'">
                      <span
                        [ngClass]="patientDetails?.id === selectedAppointmentId ? 'selectedpatient' : ''">{{patientDetails?.lastName | titlecase}},
                        {{patientDetails?.firstName | titlecase}}
                        {{patientDetails?.middleName | titlecase}}
                        
                        </span>
                    </ng-container>
                    <!-- [class.disabled]="patientDetails?.lockAppt" <i placement="right"
                          ngbTooltip="{{patientDetails?.lockUser}}" class="las la-lock" style="font-size: 20px;"
                          *ngIf="patientDetails?.lockAppt"></i>-->
                    <ng-container
                      *ngIf="patientDetails?.appointmentStatus?.currentStatus !== 'CANCELLED' && isShowHideElements['PatientSummary']">
                      <a href="javascript:;"
                        [ngClass]="patientDetails?.id === selectedAppointmentId ? 'selectedpatient' : 'patientname'"
                        (click)="lockUnLocakAppointment(patientDetails,true,false)">{{patientDetails?.lastName | titlecase}},  
                        {{patientDetails?.firstName | titlecase}}                      
                        {{patientDetails?.middleName | titlecase}}
                         </a>
                    </ng-container>
                    <div class="gender-patient-type" style="font-size: 12px;">
                      <span><b>{{patientDetails?.gender || '--'}}</b></span> &nbsp;&nbsp;
                      <span><b>{{patientDetails?.age || '--'}}</b></span> &nbsp;&nbsp;
                      <span><b>{{ patientDetails?.newPatient ? 'New' : 'Established' }}</b></span>
                    </div>
                  </td>
                  <td>{{ doctorNameWithNpi(patientDetails?.appointment) | titlecase }}</td>
                  <td>{{patientDetails?.appointmentStatus?.roomAssigned || '--'}}
                    <span style="color: #5CA6DB;" (click)="openUpdateRoomNumber(patientDetails, updateRoomNumber)"  *ngIf="(patientDetails?.appointmentStatus?.checkIn && !patientDetails?.appointmentStatus?.checkOut && !patientDetails?.appointment?.virtualVisit) && patientDetails?.id != selectedAppointmentId"><i class="las la-pencil-alt" style="cursor: pointer;"></i></span>
                  </td>
                  <td>{{ patientDetails?.appointment?.purposeOfVisit }}
                    <div class="cc-details gender-patient-type chief-complaints-drop dropdown-actions"
                      *ngIf="patientDetails?.chiefComplaints && patientDetails?.chiefComplaints?.length > 0">
                      <span style="cursor: pointer; color: #5CA6DB;"><b>Chief Complaints</b></span>
                      <div class="chief-complaints-hover-content" style="height:{{getChiefComplaintsSectionHeight(patientDetails?.chiefComplaints)}}">
                        <ul class="chief-complaints-list" style="white-space: normal;">
                          <li *ngFor="let message of patientDetails?.chiefComplaints" [innerHTML]="message"></li>
                        </ul>
                      </div>
                    </div>


                    <!-- <div class="gender-patient-type chief-complaints-drop dropdown-actions"
                    *ngIf="patientDetails?.abnormalResults && patientDetails?.abnormalResults?.length > 0"
                  >
                    <span style="cursor: pointer; color: #5CA6DB;"><b>Abnormal Test Results</b></span>
                    <div class="chief-complaints-hover-content" >
                      <ul class="chief-complaints-list" style="white-space: normal;">
                        <li *ngFor="let result of patientDetails?.abnormalResults">
                          <span style="color:{{result?.colorCode}}"><span
                              *ngIf="result?.name !== null">{{result?.name}} :</span>
                            <span *ngIf="result?.status !== null"> {{result?.status}} - </span>
                            <span *ngIf="result?.subName !== null">{{result?.subName}} - </span>
                            <span *ngIf="result?.subValue !== null">{{result?.subValue}}</span></span>
                        </li>
                      </ul>
                    </div>
                  </div> -->
                  </td>
                  <td> {{ getAppointmentTime(patientDetails?.appointment?.dateTime) }}</td>
                  <!--<td>{{patientDetails?.waitTime || '--'}} <span *ngIf="patientDetails?.waitTime !== null && patientDetails?.waitTime !== 0"> Mins</span></td>-->
                  <td>{{patientDetails?.nurseGreet}} </td>
                  <td data-order="{{ConvertStringToNumber(patientDetails?.triageRoom)}}">{{patientDetails?.triageRoom}}
                  </td>
                  <!--<td>{{patientDetails?.totalTime || '--'}}  <span *ngIf="patientDetails?.totalTime !== null  && patientDetails?.totalTime !== 0"> Mins</span></td>-->


                  <!-- <td>{{getStatus(patientDetails?.appointmentStatus?.currentStatus, patientDetails) || '--'}}</td> -->
                  <!-- Status -->
                  <td class="appointment-status-clrs">
                    <span class="status-clr"
                      [class]="getStatus(patientDetails?.appointmentStatus?.currentStatus, patientDetails)">{{getStatus(patientDetails?.appointmentStatus?.currentStatus,
                      patientDetails) || '--'}}</span>
                      <span style="color: #5CA6DB;" (click)="openUpdateStatus(patientDetails, updateExamStatus)" *ngIf="((patientDetails?.appointmentStatus?.currentStatus =='TRIAGE' || patientDetails?.appointmentStatus?.currentStatus =='CONSULTATION') && (patientDetails?.appointmentStatus?.currentStatus !== 'CANCELLED') && !patientDetails?.appointmentStatus?.checkOut) && patientDetails?.id != selectedAppointmentId"><i class="las la-pencil-alt" style="cursor: pointer;"></i></span>
                     
                  </td>
                  <!-- <td class="appointment-status-clrs">
                  <ng-container
                    *ngIf="patientDetails?.appointmentStatus?.currentStatus !== 'SCHEDULED' && patientDetails?.appointmentStatus?.currentStatus !== 'NOTAVAILABLE'">
                    <span class="status-clr" [class]="getStatus(patientDetails?.appointmentStatus?.currentStatus, patientDetails)">{{getStatus(patientDetails?.appointmentStatus?.currentStatus, patientDetails) || '--'}}</span>
                  </ng-container>
                  <ng-container
                    *ngIf="patientDetails?.appointmentStatus?.currentStatus === 'SCHEDULED' || patientDetails?.appointmentStatus?.currentStatus === 'NOTAVAILABLE'">
                    <ng-select placeholder="Confirm Status" bindLabel="desc" bindValue="value" [searchable]="false"
                      [clearable]="false" [(ngModel)]="patientDetails.appointmentStatus.currentStatus" id="change-schedule-appointment"
                      [closeOnSelect]="true" [hideSelected]="true" (change)="confirmStatusUpdate(patientDetails, $event)"
                      [items]="confirmRelatedStatus"></ng-select>
                  </ng-container>
                </td> -->
                  <td class="text-center">
                    <!-- <button class="icon-queue-events" [ngClass]="patientDetails?.signoffLock ? 'error-action-bg' : 'signoff-action-bg'"
                    [class.redLock]="patientDetails?.signoffLock" placement="bottom" (click)="removelockSignoff(patientDetails)"
                    [ngbTooltip]="patientDetails?.signoffLock ? 'Chart is Secured ' : 'Chart is Open'"><span
                      *ngIf="patientDetails?.signoffLock" class="material-symbols-outlined">
                      lock
                    </span>
                    <span *ngIf="!patientDetails?.signoffLock" class="material-symbols-outlined">
                      lock_open
                    </span></button> -->

                    <button class="icon-queue-events view-action-bg lock-view"
                      (click)="viewAppointmentDetails(patientDetails?.id, viewCommonAppointmentDetails)"
                      placement="bottom" ngbTooltip="View"><span
                        class="material-symbols-outlined">visibility</span></button>

                    <button class="icon-queue-events edit-action-bg lock-edit"
                      [disabled]="patientDetails?.id === selectedAppointmentId ||  patientDetails?.appointmentStatus?.currentStatus === 'CANCELLED' || (patientDetails?.signoffLock && loginDetails?.roleType !== 'Doctor')"
                      (click)="lockUnLocakAppointment(patientDetails,true,false)" placement="bottom"
                      ngbTooltip="Edit"><span class="material-symbols-outlined">edit</span></button>

                    <button class="icon-queue-events secure-lock"
                      [class.gray-disabled]="patientDetails?.appointmentStatus?.currentStatus === 'CANCELLED'"
                      *ngIf="!patientDetails?.signoffLock" placement="bottom"
                      (click)="removelockSignoff(patientDetails)" ngbTooltip="Open">
                    </button>

                    <button class="icon-queue-events unsecure-lock"
                      [class.gray-disabled]="patientDetails?.appointmentStatus?.currentStatus === 'CANCELLED'"
                      *ngIf="patientDetails?.signoffLock" placement="bottom" (click)="removelockSignoff(patientDetails)"
                      ngbTooltip="Secured">
                    </button>

                  </td>
                </tr>
              </ng-container>

            </tbody>
          </table>
        </div>
      </app-card>
    </div>
  </ng-container>
</div>
<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Lock Notification -->
<app-ui-modal #securedEditConfirmation [hideHeader]="true" [modalCentered]="true" id="securedEditConfirmation">
  <div class="app-modal-body add-app-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <h5 class="text-center">Would you like to make more modifications to the chart? Please remember to secure the
          chart from the care flow queue actions after the changes.</h5>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
      (click)="securedEditConfirmation?.hide()">No</button>
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
      (click)="removelockSignoff(selectedAppointmentEdit, true)">Yes</button>
  </div>
</app-ui-modal>

<!-- Sign off Lock Notification -->
<app-ui-modal #signoffLockEditConfirmation [hideHeader]="true" [modalCentered]="true" id="signoffLockEditConfirmation">
  <div class="app-modal-body add-app-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <h5 class="text-center">{{notifyText}}</h5>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
      (click)="navigateToExam()">Yes</button>
    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
      (click)="signoffLockEditConfirmation?.hide()">No</button>

  </div>
</app-ui-modal>


<!-- Lock Notification -->
<app-ui-modal #lockAppointment [hideHeader]="true" [modalCentered]="true" id="lockAppointment">
  <div class="app-modal-body add-app-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <h5 class="text-center">{{getUserRole(selectedAppointmentEdit?.lockUser) === 'Doctor' ? 'Provider' :
          getUserRole(selectedAppointmentEdit?.lockUser)}} {{getUserName(selectedAppointmentEdit?.lockUser) ||
          selectedAppointmentEdit?.lockUser}} is currently working on this Patient’s chart.</h5>
        <!-- <h5 class="text-center" *ngIf="noLockUser">{{selectedAppointmentEdit?.roleType}} {{ selectedAppointmentEdit?.lastName}} is working on this patient's chart.</h5>-->
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
      (click)="lockAppointment?.hide()">Ok</button>
  </div>

  <!-- <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
      (click)="lockAppointment?.hide()">Ok</button>
  </div> -->
</app-ui-modal>

<!-- Lock Notification -->
<app-ui-modal #lockAppointmentEmpty [hideHeader]="true" [modalCentered]="true" id="lockAppointmentEmpty">
  <div class="app-modal-body add-app-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <h5 class="text-center">Some other person is working on this patient chart. Please refresh the list</h5>
        <!-- <h5 class="text-center" *ngIf="noLockUser">{{selectedAppointmentEdit?.roleType}} {{ selectedAppointmentEdit?.lastName}} is working on this patient's chart.</h5>-->
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok2"
      (click)="lockAppointmentEmpty?.hide(); refreshQueueList();">Ok</button>
  </div>
</app-ui-modal>


<app-ui-modal #viewCommonAppointmentDetails [hideHeader]="false" [modalCentered]="true"
  id="viewCommonAppointmentDetails">
  <div class="app-modal-header">
    <h5 class="modal-title">Appointment Details</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="viewCommonAppointmentDetails.hide();"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body add-app-body">
    <ng-container *ngIf="selectedViewAppointmentId">
      <app-commom-past-visit-details-wizard [appointmentId]="selectedViewAppointmentId" [noAccountBalanace]="true"
        (openNotifyModal)="sendNotification($event)"></app-commom-past-visit-details-wizard>
    </ng-container>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok3"
      (click)="viewCommonAppointmentDetails.hide();">Ok</button>
  </div>
</app-ui-modal>


<!-- remove lock Notification ConfirmLockRemove(selectedAppointmentEdit);-->
<app-ui-modal #removeLockConfirmation [hideHeader]="true" [modalCentered]="true" id="removeLockConfirmation">
  <div class="app-modal-body add-app-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <h5 class="text-center">{{removeLockMsg}}</h5>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">

    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok4"
      (click)="removeLockConfirmation?.hide()">Ok</button>
  </div>
</app-ui-modal>

<!-- Late Notify PopUp -->
<app-send-sms-notify></app-send-sms-notify>

<!-- Update Room Number -->
<app-ui-modal #updateRoomNumber [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
  id="updateRoomNumber">
  <div class="app-modal-header">
    <h5 class="modal-title">Exam Room</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="updateRoomNumber.hide();resetroomnum();"><span aria-hidden="true">&times;</span></button>
  </div> 
  <div class="app-modal-body send-notifications" [formGroup]="roomNumberForm">
    <div class="form-group mb-3">
        <ng-select placeholder="Choose Room Number" value="value" bindValue="value"
            [items]="roomNumbers" id="visitTypeUpdate-edit" formControlName="roomNumber">
        </ng-select>
        <span class="required"
            *ngIf="roomNumberForm.controls['roomNumber'].hasError('required') && roomNumberForm.controls['roomNumber'].touched">Room Number Is Required</span>
    </div>
</div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="careflowQueue_submit"
      (click)="submitUpdateRoomNumber(updateRoomNumber)">Submit</button>
  </div>
</app-ui-modal>
<!-- Update Status -->
<app-ui-modal #updateExamStatus [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
  id="updateExamStatus">
  <div class="app-modal-header">
    <h5 class="modal-title">Set Status</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="updateExamStatus.hide();resetStatus();"><span aria-hidden="true">&times;</span></button>
  </div> 
  <div class="app-modal-body send-notifications" [formGroup]="roomStatusForm">
    <div class="form-group mb-3">
        <ng-select placeholder="Choose Status" bindLabel="desc" bindValue="value"
            [items]="ConsultationStatus" id="visitTypeUpdate-edit" formControlName="examStatus">
        </ng-select>
        <span class="required"
            *ngIf="roomStatusForm.controls['examStatus'].hasError('required') && roomStatusForm.controls['examStatus'].touched">Status Is Required</span>
    </div>
</div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="careflowQueue_submit1"
      (click)="submitUpdateStatus(updateExamStatus)">Submit</button>
  </div>
</app-ui-modal>