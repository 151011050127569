import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { CardComponent } from '../../components/card/card.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
//import { CommonInsuranceAddlComponent } from '../common-insurance-addl/common-insurance-addl.component';

@Component({
  selector: 'app-additional-insurances',
  templateUrl: './additional-insurances.component.html',
  styleUrls: ['./additional-insurances.component.scss']
})
export class AdditionalInsurancesComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() insuranceForm: FormGroup;
  public insuranceDetails: FormArray;
  @Input() demographicForm: FormGroup;
  @Input() patientDetailsInfo: any;
  @Input() PatientDetailsCardReload: CardComponent;
  @Input() isUploadRequired: boolean;
  @Input() isHealthpassEnabled: string;
  public insuranceSequenceList = [
    { desc: 'Primary', value: 'Primary' },
    { desc: 'Secondary', value: 'Secondary' },
    { desc: 'Tertiary', value: 'Tertiary' }
  ];
  public insuranceClassList = ['Commercial', 'Medicare', 'Medicare Advantage', 'Medicaid'];

  public allInsuranceList: Observable<any>;
  public insuranceLoading = false;
  public insuranceInput = new Subject<string>();

  public insurancePrimarySubscriber = [
    { value: 'Self', desc: 'Self' },
    { value: 'Other', desc: 'Other' }
  ];
  public genderList = [
    { value: "Male", desc: "Male" },
    { value: "Female", desc: "Female" },
    { value: "Trans Female", desc: "Trans Female" },
    { value: "Trans Male", desc: "Trans Male" },
    { value: "Non-binary", desc: "Non-binary" },
    { value: "Other", desc: "Other" },
    { value: "Unknown", desc: "Unknown" },
  ];
  public insuranceRelationship = [
    /*{ value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' },
    { value: 'Child', desc: 'Child' }*/
    { value: 'Father', desc: 'Father' },
    { value: 'Mother', desc: 'Mother' },
    { value: 'Spouse', desc: 'Spouse' },
    { value: 'Siblings', desc: 'Siblings' },
    { value: 'Friend', desc: 'Friend' },
    { value: 'Guardian', desc: 'Guardian' },
    { value: 'Son', desc: 'Son' },
    { value: 'Daughter', desc: 'Daughter' },
    { value: 'Partner', desc: 'Partner' },
    { value: 'Child', desc: 'Child' }
  ];
  // public isSelfPay = [
  //   {value: false, desc: 'Insurance'},
  //   {value: true, desc: 'Self-Pay'},
  //   {value: 'Un-Insured', desc: 'Un-Insured'}
  // ];

  public isSelfPay = [
    { value: 'Insurance', desc: 'Insurance' },
    { value: 'Self-Pay', desc: 'Self-Pay' },
    { value: 'healthPass', desc: 'Health Pass' },
    // {value: 'Un-Insured', desc: 'Un-Insured'}
  ];

  public insuranceType = [
    { value: true, desc: 'Medicare' },
    { value: false, desc: 'Other' }
  ];
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionalModal: ExceptionModalComponent;
    
  insuranceCardFile: any;

  @ViewChild('addInsuranceFile') addInsuranceFile: ElementRef;
  public inActiveOrDeletedInsurances: any[] = [];
  public selectedInsuranceForDelete: any;
  insuDeletedIndex: number;
  public paymentTypeChangedInsuranceDeatils: any[] = [];
  public maxAppointmentDate: Date;
  public minDOB = this.httpService.convertDate(new Date('01 Jan 1910'));

  public insuranceDcoumentInfoData: any;
  public selectedMedicalRecordForDelete: any;
  public additionalDocName: any;
  public collectionName: any;
  public hideInsurance: boolean = false;
  public idDcoumentInfoData: any;
  public existingS3Key: any;
  public loadSpinner: boolean;
  public downloadedFileType: any;
  public isHealthpassDisabled: boolean = true;
  public selectedUserInfoId: any;
  public healthPassVal: boolean = false;
  isNoAppntReg: string;
  
  //@ViewChild(CommonInsuranceAddlComponent) public commonInsurance: CommonInsuranceAddlComponent;
  //@Input() userInfoDetails: any;
  //@Input() userInfoDetailsForm: FormGroup;
  
  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
    this.healthPassVal = JSON.parse(sessionStorage?.getItem('healthPass'));
	this.patientDetailsInfo?.push(this.insuranceDetails);
  }

  ngOnInit(): void {
    this.getInsuranceAutoComplete();
    this.maxAppointmentDate = this.httpService.convertDate(new Date());
	}

  ngAfterViewInit(): void {
    this.isNoAppntReg = sessionStorage?.getItem('isNoAppnt');
    if (this.isNoAppntReg === 'No') {
      this.insuranceForm?.get('selfPay')?.setValue('Insurance');
      this.addMoreInsurancesClick();
      this.checkPaymentType();
      this.clearInsuSubscriberDetails();
	  
	
    }
	
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {

      if (this.patientDetailsInfo) {
	  console.log(this.patientDetailsInfo);
        this.getAllInsurancesByUserID();
        this.validateHealthpass();
        // this.clearFormArray(this.insuranceForm?.get('insuranceDetails') as FormArray);
        // const insurances = this.patientDetailsInfo?.insuranceDetails || [];
        // insurances?.forEach((item) => {
        //   if(item?.active === true){
        //     this.addMoreInsurances(item);
        //   }
        // });
        // this.inActiveOrDeletedInsurances = insurances?.filter(item => item?.active === false);
      }
    }
  }
  public validateHealthpass() {
    if (this.patientDetailsInfo) {
      this.selectedUserInfoId = this.patientDetailsInfo?.userInfoId;
    }
    else {
      this.selectedUserInfoId = null;
    }

    const action = `healthPass/validateHealthpassUser?userInfoId=${this.selectedUserInfoId}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const responseDt = data?.responseObject || null;
        if (responseDt == true) {
          this.isHealthpassDisabled = false;
          this.insuranceForm?.get('healthPass')?.setValue(data?.responseObject || null);
          this.demographicForm?.get('healthPass')?.setValue(data?.responseObject || null);
        }
        else {
          this.isHealthpassDisabled = true;
        }
      } else {

      }
    },
      (error) => {
      });
  }

  // call the Insurace details API with appointment id or userinfo id
  public getAllInsurancesByUserID(): void {
    this.httpService?.getInsuranceDetailsDocuments(this.patientDetailsInfo?.facilityId, this.patientDetailsInfo?.userInfoId, this.patientDetailsInfo?.id)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const insuranceDetails = JSON.parse(JSON?.stringify(data?.responseObject || []));
        this.patientDetailsInfo['insuranceDetails'] = insuranceDetails;

        let patientPatchData: any = {};
        const employerAddress = {
          "city": null,
          "zipCode": null,
          "state": null,
          "street": null,
        }
        patientPatchData['employerName'] = insuranceDetails?.length > 0 ? insuranceDetails[0]?.employerName : null;
		if(insuranceDetails[0]?.employerAddress !== null && insuranceDetails[0]?.employerAddress !== ''){
		insuranceDetails[0].employerAddress.zipCode = this.addPrefixZipCode(insuranceDetails[0]?.employerAddress?.zipCode, '');
		
		}
        patientPatchData['employerAddress'] = insuranceDetails?.length > 0 ? (insuranceDetails[0]?.employerAddress || employerAddress) : employerAddress;

        // this.patientDetailsInfo.dob = this.httpService?.formatDobTimeZone(this.patientDetailsInfo?.dob);
        // if (this.patientDetailsInfo?.selfPay === false && this.patientDetailsInfo?.unInsured === false) {
        if (insuranceDetails && insuranceDetails?.length > 0) {
          patientPatchData.subscriberfirstname = insuranceDetails[0]?.subscriberfirstname || null;
          patientPatchData.subscriberlastname = insuranceDetails[0]?.subscriberlastname || null;
          patientPatchData.subscribergender = insuranceDetails[0]?.subscribergender || null;
          patientPatchData.subscriberdateOfBirth = insuranceDetails[0]?.subscriberdateOfBirth ? this.subscriberDobFormatChange(insuranceDetails[0]?.subscriberdateOfBirth) : null;
          // patientPatchData.subscriberdateOfBirth = (insuranceDetails[0]?.subscriberdateOfBirth?.includes(':') ? insuranceDetails[0]?.subscriberdateOfBirth?.split(' ')[0] : insuranceDetails[0]?.subscriberdateOfBirth) || null;
          patientPatchData.relationToSubscriber = insuranceDetails[0]?.relationToSubscriber || null;
          patientPatchData.insurancePrimarySubscriber = insuranceDetails[0]?.insurancePrimarySubscriber || null;
          patientPatchData.medicareFlag = insuranceDetails[0]?.medicareFlag;
          this.medicareFlagChange(insuranceDetails[0]?.medicareStartDate);
        }
        // }
        this.insuranceForm?.patchValue(patientPatchData);
        this.clearFormArray(this.insuranceForm?.get('insuranceDetails') as FormArray);
        const insurances = JSON.parse(JSON?.stringify(data?.responseObject || []));
        insurances?.forEach((item: any, index: number) => {
          if (item?.active === true) {
            this.addMoreInsurances(item);
            if (this.patientDetailsInfo?.selfPay === false || this.patientDetailsInfo?.selfPay === "Insurance") {
              this.previewInsuranceImage(item?.insuranceCardImageId, index);
            }
          }
        });
        this.inActiveOrDeletedInsurances = insurances?.filter(item => item?.active === false) || [];
      }
    },
      (error) => {
        // this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        // this.exceptionalModal?.modalShow();
      });
  }

  public subscriberDobFormatChange(subscriberDob: string): string {
    let dob = subscriberDob?.includes(':') ? subscriberDob?.split(' ')[0] : subscriberDob;
    if (dob?.includes('/')) {
      return this.httpService?.mmddyyyyFormatSplit(dob);
    }
    return dob;
  }

  // set the insurance related values
  public selectedInsurance(event: any, index: number): void {
    this.insuranceDetails = this.insuranceForm?.get('insuranceDetails') as FormArray;
    this.insuranceDetails?.controls[index]?.get('name')?.setValue(event?.name || null);
    this.insuranceDetails?.controls[index]?.get('payerId')?.setValue(event?.payorid || null);
    this.insuranceDetails?.controls[index]?.get('eligibilityRealtimePayerID')?.setValue(event?.eligibilityRealtimePayerID || null);
    this.insuranceDetails?.controls[index]?.get('claimPayerId')?.setValue(event?.cpid || null);
    this.insuranceDetails?.controls[index]?.get('eligibilityPortalID')?.setValue(event?.eligibilityPortalID || null);
  }


  // insurance autocomplete
  private getInsuranceAutoComplete() {
    this.allInsuranceList = concat(
      of([]), // default items
      this.insuranceInput.pipe(
        distinctUntilChanged(),
        tap(() => this.insuranceLoading = true),
        switchMap(term => this.httpService?.getInsuranceAutoComplete(term)?.pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.insuranceLoading = false)
        ))
      )
    );
  };

  public trackByFn(item: any) {
    return item?.name;
  }

  public addInsuranceGroup(insuDetails?: any): FormGroup {
    return this.formBuilder?.group({
      'name': [insuDetails?.name || insuDetails?.insuranceName || null, Validators?.required],
      'memberId': [insuDetails?.memberId || null, Validators?.required],
      'groupNumber': [insuDetails?.groupNumber || null],
      'payerId': [insuDetails?.payerId || null, Validators?.required],
      'priority': [insuDetails?.priority || null, Validators?.required],
      'insuranceClass': [insuDetails?.insuranceClass || null, Validators?.required],
      'eligibilityRealtimePayerID': [insuDetails?.eligibilityRealtimePayerID || null],
      'claimPayerId': [insuDetails?.claimPayerId || null],
      'eligibilityPortalID': [insuDetails?.eligibilityPortalID || null],
      'insuranceCardImageId': [insuDetails?.insuranceCardImageId || null],
      'active': [true],
      'docType': [null]
    });
  };

  public addMoreInsurances(insuranceDetails?: any): void {
    this.insuranceDetails = this.insuranceForm?.get('insuranceDetails') as FormArray;
    this.insuranceDetails?.push(this.addInsuranceGroup(insuranceDetails));
  }

  public addMoreInsurancesClick() {
    this.insuranceDetails = this.insuranceForm?.get('insuranceDetails') as FormArray;
    this.insuranceDetails?.push(this.addInsuranceGroup());
    this.insuranceForm?.markAsDirty();
  }

  // make mandatory if new patient registration
  public makeSubscriberMandatory(): void {
    if(this.isNoAppntReg === 'No') {
      this.insuranceForm?.get('insurancePrimarySubscriber')?.setValidators([Validators?.required]);
      this.insuranceForm?.get('subscriberfirstname')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
      this.insuranceForm?.get('subscriberlastname')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
      this.insuranceForm?.get('subscribergender')?.setValidators([Validators?.required]);
      this.insuranceForm?.get('subscriberdateOfBirth')?.setValidators([Validators?.required]);
      this.insuranceForm?.get('medicareFlag')?.setValidators([Validators?.required]);
    }
  }

  public deleteInsurances(index: number, insurance: FormGroup): void {
    this.insuranceDetails = this.insuranceForm?.get('insuranceDetails') as FormArray;
    this.insuranceDetails?.removeAt(index);
    this.insuranceForm?.markAsDirty();
    const insurances = this.patientDetailsInfo?.insuranceDetails || JSON.parse(sessionStorage.getItem('insuranceDetails')) || [];
    let filterInsurance = insurances?.find((item: any) => insurance?.value?.memberId === item?.memberId) || null;
    if (filterInsurance) {
      filterInsurance.active = false;
      this.inActiveOrDeletedInsurances = [...this.inActiveOrDeletedInsurances, ...[filterInsurance]];
    }
    this.clearInsuranceSubscriberOnDelete();
    this.clearInsuSubscriberDetails();
  }


  public changeCoverageSequence(selectValue: any, index: number): void {
    if (!selectValue) {
      return;
    }
    const formArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
    const findPrimaryCheck = formArray?.value?.find(item => item?.priority === 'Primary') || null;
    const findSecondaryCheck = formArray?.value?.find(item => item?.priority === 'Secondary') || null;

    const prmaryIndex = formArray?.value?.findIndex(x => x.priority === "Primary");
    const secondaryIndex = formArray?.value?.findIndex(x => x.priority === "Secondary");

    if (findPrimaryCheck && findPrimaryCheck?.priority === selectValue?.value && prmaryIndex !== index) {
      this.notifyText = 'You have already selected Primary Coverage. Please select other sequence for this Insurace Details.';
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      formArray?.at(index)?.get('priority')?.setValue(null);
      return;
    }
    // if(findSecondaryCheck && findSecondaryCheck?.priority === selectValue?.value && secondaryIndex !== index){
    //   this.notifyText = 'You have already selected Secondary Coverage. PLease select other sequence for this Insurace Details.';
    //   this.exceptionalModal?.modalShow();
    //   formArray?.at(index)?.get('priority')?.setValue(null);
    //   return;
    // }
  }

  // clear the Form Array
  public clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  public checkPaymentType(insuDetails?: any): void {
    const isSelfPay = this.insuranceForm?.get('selfPay')?.value;
    if (isSelfPay === 'Insurance') {
      this.insuranceDetails = this.insuranceForm?.get('insuranceDetails') as FormArray;
      if (this.insuranceDetails?.controls?.length === 0 && this.paymentTypeChangedInsuranceDeatils?.length === 0) {
        this.insuranceDetails?.push(this.addInsuranceGroup(insuDetails));
        if (insuDetails?.insuranceCardImageId) {
          this.previewInsuranceImage(insuDetails?.insuranceCardImageId, 0);
        }
      }

      const demographicFormValues = this.demographicForm?.value;
      if (this.paymentTypeChangedInsuranceDeatils?.length > 0) {
        this.clearFormArray(this.insuranceForm?.get('insuranceDetails') as FormArray);
        this.insuranceForm?.get('selfPay')?.setValue('Insurance');
        this.paymentTypeChangedInsuranceDeatils?.forEach((item: any, index: number) => {
          this.addMoreInsurances(item);
        });
        // this.insuranceForm?.get('employerName')?.patchValue(demographicFormValues?.employerName);
        // this.insuranceForm?.get('employerAddress')?.patchValue(demographicFormValues?.employerAddress);
        insuDetails = this.insuranceForm?.value;
      }

      // To show the documents for each insurance
      this.insuranceDetails = this.insuranceForm?.get('insuranceDetails') as FormArray;
      this.insuranceDetails?.value?.forEach((item: any, index: number) => {
        this.previewInsuranceImage(item?.insuranceCardImageId, index);
      });

      if(this.isNoAppntReg !== 'No') {
        this.insuranceForm?.get('insurancePrimarySubscriber')?.setValidators([Validators?.required]);
        this.insuranceForm?.get('subscriberfirstname')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
        this.insuranceForm?.get('subscriberlastname')?.setValidators([Validators.required, Validators.pattern(AppRegExpressionsConfig.nameValidator)]);
        this.insuranceForm?.get('subscribergender')?.setValidators([Validators?.required]);
        this.insuranceForm?.get('subscriberdateOfBirth')?.setValidators([Validators?.required]);
        this.insuranceForm?.get('medicareFlag')?.setValidators([Validators?.required]);
      }

      let subscriberDetails: any = insuDetails;
      if (!insuDetails || !insuDetails?.subscriberfirstname) {
        subscriberDetails = this.patientDetailsInfo?.insuranceDetails && this.patientDetailsInfo?.insuranceDetails?.length > 0 ? this.patientDetailsInfo?.insuranceDetails?.find((insuItem: any) => insuItem?.priority === "Primary" && insuItem?.active) : null;
      }
      // pre fill the subscriber details from established patient search or appointment details in exam room
      this.insuranceForm?.get('insurancePrimarySubscriber')?.setValue(subscriberDetails?.insurancePrimarySubscriber || this.patientDetailsInfo?.insurancePrimarySubscriber || null);
      this.insuranceForm?.get('subscriberfirstname')?.setValue(subscriberDetails?.subscriberfirstname || this.patientDetailsInfo?.subscriberfirstname || null);
      this.insuranceForm?.get('subscriberlastname')?.setValue(subscriberDetails?.subscriberlastname || this.patientDetailsInfo?.subscriberlastname || null);
      this.insuranceForm?.get('subscribergender')?.setValue(subscriberDetails?.subscribergender || this.patientDetailsInfo?.subscribergender || null);

      const subDob = subscriberDetails?.subscriberdateOfBirth ? this.subscriberDobFormatChange(subscriberDetails?.subscriberdateOfBirth) : null;
      // (subscriberDetails?.subscriberdateOfBirth?.includes(':') ? subscriberDetails?.subscriberdateOfBirth?.split(' ')[0] : subscriberDetails?.subscriberdateOfBirth) || null;
      const subDobApp = this.patientDetailsInfo?.subscriberdateOfBirth ? this.subscriberDobFormatChange(this.patientDetailsInfo?.subscriberdateOfBirth) : null;
      // (this.patientDetailsInfo?.subscriberdateOfBirth?.includes(':') ? this.patientDetailsInfo?.subscriberdateOfBirth?.split(' ')[0] : this.patientDetailsInfo?.subscriberdateOfBirth) || null;

      this.insuranceForm?.get('subscriberdateOfBirth')?.setValue(subDob || subDobApp || null);
      this.insuranceForm?.get('relationToSubscriber')?.setValue(subscriberDetails?.relationToSubscriber || this.patientDetailsInfo?.relationToSubscriber || null);
      this.insuranceForm?.get('medicareFlag')?.setValue(this.patientDetailsInfo?.medicareFlag || subscriberDetails?.medicareFlag);
      this.medicareFlagChange(subscriberDetails?.medicareStartDate || this.patientDetailsInfo?.medicareStartDate || null);
      if (subscriberDetails?.insurancePrimarySubscriber === "Self" || this.patientDetailsInfo?.insurancePrimarySubscriber == "Self") {
        this.insuranceForm?.get('relationToSubscriber')?.disable();
      }
      this.paymentTypeChangedInsuranceDeatils = [];
    } else {
      this.paymentTypeChangedInsuranceDeatils = this.insuranceForm?.get('insuranceDetails')?.value || [];
      // this.insuranceForm?.removeControl('insurancePrimarySubscriber');
      // this.insuranceForm?.removeControl('subscriberfirstname');
      // this.insuranceForm?.removeControl('subscriberlastname');
      // this.insuranceForm?.removeControl('subscribergender');
      // this.insuranceForm?.removeControl('subscriberdateOfBirth');
      // this.insuranceForm?.removeControl('relationToSubscriber');
      // this.insuranceForm?.removeControl('medicareFlag');

      this.insuranceForm?.get('subscriberfirstname')?.setValidators(null);
      this.insuranceForm?.get('subscriberlastname')?.setValidators(null);
      this.insuranceForm?.get('subscribergender')?.setValidators(null);
      this.insuranceForm?.get('subscriberdateOfBirth')?.setValidators(null);
      this.insuranceForm?.get('relationToSubscriber')?.setValidators(null);
      this.insuranceForm?.get('medicareFlag')?.setValidators(null);
      this.insuranceForm?.get('insurancePrimarySubscriber')?.setValidators(null);
      // to clear the all insurance details if select the self pay
      this.clearFormArray(this.insuranceForm?.get('insuranceDetails') as FormArray);
      // this.insuranceForm?.get('insurancePrimarySubscriber')?.setValue(null)
      // this.checkPrimarySubscriber();
      // this.insuranceForm?.get('employerName')?.reset();
      // this.insuranceForm?.get('employerAddress')?.reset();
    }
    this.insuranceForm?.get('relationToSubscriber')?.updateValueAndValidity();
    this.insuranceForm?.get('subscriberfirstname')?.updateValueAndValidity();
    this.insuranceForm?.get('subscriberlastname')?.updateValueAndValidity();
    this.insuranceForm?.get('subscribergender')?.updateValueAndValidity();
    this.insuranceForm?.get('medicareFlag')?.updateValueAndValidity();
    this.insuranceForm?.get('subscriberdateOfBirth')?.updateValueAndValidity();
    this.insuranceForm?.get('insurancePrimarySubscriber')?.updateValueAndValidity();
    // this.checkPrimarySubscriber();
    this.insuranceForm?.updateValueAndValidity();
  };

  // primary subscriber check
  public checkPrimarySubscriber(): void {
    const insurancePrimary = this.insuranceForm?.get('insurancePrimarySubscriber')?.value || null;
    const patientValue = this.insuranceForm?.value;
    const demographicValues = this.demographicForm?.value;
    if (insurancePrimary === 'Self') {
      this.insuranceForm?.get('subscriberfirstname').setValue(demographicValues?.FIRST_NAME || patientValue?.firstName || null);
      this.insuranceForm?.get('subscriberlastname').setValue(demographicValues?.LAST_NAME || patientValue?.lastName || null);
      this.insuranceForm?.get('subscribergender').setValue(demographicValues?.GENDER || patientValue?.gender || null);
      this.insuranceForm?.get('subscriberdateOfBirth').setValue(demographicValues?.DATE_OF_BIRTH || patientValue?.dob || null);
      this.insuranceForm?.get('relationToSubscriber')?.reset();
      this.insuranceForm?.get('relationToSubscriber')?.setValue(null);
      this.insuranceForm?.get('relationToSubscriber')?.disable();
    } else {
      this.insuranceForm?.get('subscriberfirstname').setValue(null);
      this.insuranceForm?.get('subscriberlastname').setValue(null);
      this.insuranceForm?.get('subscribergender').setValue(null);
      this.insuranceForm?.get('subscriberdateOfBirth').setValue(null);
      this.insuranceForm?.get('relationToSubscriber')?.enable();

      this.insuranceForm?.get('subscriberfirstname')?.setValidators([Validators?.required]);
      this.insuranceForm?.get('subscriberlastname')?.setValidators([Validators?.required]);
      this.insuranceForm?.get('subscribergender')?.setValidators([Validators?.required]);
      this.insuranceForm?.get('subscriberdateOfBirth')?.setValidators([Validators?.required]);

      this.insuranceForm?.get('relationToSubscriber')?.setValidators([Validators?.required]);
    }
    this.insuranceForm?.get('subscriberfirstname')?.updateValueAndValidity();
    this.insuranceForm?.get('subscriberlastname')?.updateValueAndValidity();
    this.insuranceForm?.get('subscribergender')?.updateValueAndValidity();
    this.insuranceForm?.get('subscriberdateOfBirth')?.updateValueAndValidity();
    this.insuranceForm?.get('relationToSubscriber')?.updateValueAndValidity();
  }

  // medicare flag change in inusrance section
  public medicareFlagChange(medicarStartDate?: any): void {
    const insurancePrimary = this.insuranceForm?.get('medicareFlag').value;
    if (insurancePrimary === true) {
      this.insuranceForm?.get('medicareStartDate')?.setValidators([Validators.required]);
      this.insuranceForm?.get('medicareStartDate')?.setValue(medicarStartDate);
    } else {
      this.insuranceForm?.get('medicareStartDate')?.reset();
      this.insuranceForm?.get('medicareStartDate')?.setValidators(null);
    }
    this.insuranceForm?.get('medicareStartDate')?.updateValueAndValidity();
  }

  // Insurance / DL Image Card Download
  public downloadInsuranceCard(cardId: string, insuraceCardModal: UiModalComponent): void {
    const action = `document/user/getInsuranceCard/${cardId}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService.downloadImage(action).subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // created dynamic iframe SRC attribute and assigned lookup url
      // window.open(fileURL);
      insuraceCardModal?.show();
      this.PatientDetailsCardReload?.cardRefreshHide();
      const iframeEle = document.getElementById('ins-card-multiple') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
    },
      (error: any) => {
        this.PatientDetailsCardReload?.cardRefreshHide();
      });
  }

  // public getInsurance(): FormGroup{
  //   const formArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
  //   return formArray.at(i) as FormGroup;
  // }

  // public checkInsuranceDetails(i): void{

  // }
  // it will call when file upload
  /* public insuranceFileChanged(event: any, i: number): void {
     const formArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
     const value = formArray.at(i)?.value;
     if (!value || !value?.memberId || !value?.payerId || !value?.name) {
       this.notifyText = 'Please add the Insurance Details to upload the Insurance Card.';
       this.exceptionalModal?.modalShow();
       this.clearFileUpload();
       return;
     }
     if (event.target.files.length > 0) {
       const file = event.target.files[0];
       this.uploadInsuranceImage(file, i);
     }
   }
 
   public uploadInsuranceImage(file: any, index: number): void {
     let action: any = `document/user/uploadInsuranceImage`;
     // convert to binary for data
     const formData = new FormData();
     formData.append('idFile', file);
     this.PatientDetailsCardReload?.cardRefreshShow();
     this.httpService.makePostWithFormData(action, formData)
       .subscribe((data: any) => {
         if (data?.status === 'SUCCESS') {
           this.insuranceCardFile = data?.fileName || null;
           if (this.insuranceCardFile != '') {
             this.updateInsuranceIdentityImageId('insuCard', index);
           }
         } else {
           this.notifyText = data?.status || data?.message || AppConstantsListConfig?.uiErrorException;
           this.exceptionalModal?.modalShow();
         }
         this.PatientDetailsCardReload?.cardRefreshHide();
         this.clearFileUpload();
       },
         error => {
           this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
           this.exceptionalModal?.modalShow();
           this.PatientDetailsCardReload?.cardRefreshHide();
           this.clearFileUpload();
         });
   }*/

  public insuranceFileChanged(event: any, i: number): void {
    const formArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
    const value = formArray.at(i)?.value;
    if (!value || !value?.memberId || !value?.payerId || !value?.name) {
      this.notifyText = 'Please add the Insurance Details to upload the Insurance Card.';
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.clearFileUpload();
      return;
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      if (fileSize <= 25 && (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg')) {
        this.uploadInsuranceImage(file, i);
      }
      else {
        this.notifyText = "Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded";
        this.isSuccessNotify = false;
        this.exceptionalModal?.modalShow();
        this.clearFileUpload();
        return;
      }
    }
  }

  public uploadInsuranceImage(file: any, index: number): void {
    const isConnect = true;
    let action: any = `document/user/uploadInsuranceImage?userInfoId=${this.patientDetailsInfo?.userInfoId}&isConnect=${isConnect}`;
    // convert to binary for data
    const formData = new FormData();
    formData.append('idFile', file);
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService.makePostWithFormData(action, formData)
      .subscribe((data: any) => {
        if (data?.status === 'SUCCESS') {
          //this.insuranceCardFile = data?.fileName || null;
          this.insuranceCardFile = data?.keyName || null;
          const additionalKey = data?.keyName || null;
          const formArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
          const value = formArray.at(index)?.value;
          formArray?.at(index)?.get('insuranceCardImageId')?.setValue(this.insuranceCardFile);
          const memberId = formArray?.at(index)?.get('memberId')?.value;

          this.updateInsuranceCardKeys('', data?.keyName, memberId, this.patientDetailsInfo?.userInfoId);

          this.notifyText = 'Insurance Card has been uploaded successfully.';
          this.isSuccessNotify = true;
          this.exceptionalModal?.modalShow();
          this.insuranceForm?.markAsDirty();

          if (this.insuranceCardFile != '') {
            //this.updateInsuranceIdentityImageId('insuCard',additionalKey);
            this.previewInsuranceImage(additionalKey, index);
          }
        } else {
          this.notifyText = data?.status || data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
        }
        this.PatientDetailsCardReload?.cardRefreshHide();
        this.clearFileUpload();
      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
          this.PatientDetailsCardReload?.cardRefreshHide();
          this.clearFileUpload();
        });
  }

  public updateInsuranceIdentityImageId(cardType: string, index): void {
    const insuranceCardFile = this.insuranceCardFile || '';
    const formArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
    const value = formArray.at(index)?.value;

    let action: string = `document/uploadInsuranceimageforAppointment?appointmentId=${this.patientDetailsInfo?.id}&insImageName=${insuranceCardFile}&memberId=${value?.memberId}`;

    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data?.body?.status === 'SUCCESS') {
          this.notifyText = data?.msg || data?.message || 'File uploaded successfully.';
          this.isSuccessNotify = true;
          this.exceptionalModal?.modalShow();
          // this.insuranceDetails?.get('insuranceCardImageId')?.setValue(insuranceCardFile);
          formArray?.at(index)?.get('insuranceCardImageId')?.setValue(insuranceCardFile);
        } else {
          this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
        }
        this.PatientDetailsCardReload?.cardRefreshHide();
        this.clearFileUpload();
      },
        error => {
          this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = false;
          this.exceptionalModal?.modalShow();
          this.PatientDetailsCardReload?.cardRefreshHide();
          this.clearFileUpload();
        });
  }

  public clearFileUpload(): void {
    this.addInsuranceFile.nativeElement.value = "";
    // const element = document?.getElementById('insurance-license-upload') as HTMLElement;
    // element?.removeAttribute('value');
  }

  // delete insurance card by appointment id
  public deleteInsuranceImageByAppointment(insurance: FormGroup): void {
    const action = `treatmentFlow/softDelete?appointmentId=${this.patientDetailsInfo?.id}&insuranceCardImageId=${insurance?.value?.insuranceCardImageId}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      insurance?.get('insuranceCardImageId')?.setValue(null);
      this.notifyText = 'Deleted Successfully';
      this.isSuccessNotify = true;
      this.exceptionalModal?.modalShow();
      this.PatientDetailsCardReload?.cardRefreshHide();
    }, error => {
      this.PatientDetailsCardReload?.cardRefreshHide();
      this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
    });
  }

  // confirm the Insurance Card delete
  public confirmDelete(existingS3Key, insurance: any, deleteConfirmModal: UiModalComponent): void {
    this.existingS3Key = existingS3Key;
    this.selectedInsuranceForDelete = insurance;
    deleteConfirmModal?.show();
  }

  // confirm insurance delete
  public deleteInsuranceConfirm(index: number, insurance: any, confirmDeleteModal: UiModalComponent): void {
    if (!insurance?.value?.name && !insurance?.value?.memberId && !insurance?.value?.groupNumber && !insurance?.value?.payerId && !insurance?.value?.priority && !insurance?.value?.insuranceClass) {
      this.deleteInsurances(index, insurance as FormGroup);
      this.clearInsuranceSubscriberOnDelete();
      this.clearInsuSubscriberDetails();
      return;
    };
    this.insuDeletedIndex = index;
    this.selectedInsuranceForDelete = insurance;
    confirmDeleteModal?.show();
  }

  public clearInsuranceSubscriberOnDelete(): void {
    const insuranceDetails = this.insuranceForm?.get('insuranceDetails') as FormArray;
    if (insuranceDetails?.length === 0) {
      this.insuranceForm?.get('selfPay')?.setValue(null);
      this.checkPaymentType();
      this.insuranceForm?.get('employerName')?.reset();
      this.insuranceForm?.get('employerAddress')?.reset();
      this.insuranceForm?.get('selfPay')?.markAllAsTouched();
    }
    if (this.isNoAppntReg === 'No') {
      this.insuranceForm?.get('selfPay')?.setValue('Insurance');
    }
  }

  // clear the added insurance subscriber details
  public clearInsuSubscriberDetails(): void {
    const array = this.insuranceForm?.get('insuranceDetails') as FormArray;
    if (this.isNoAppntReg === 'No' && this.insuranceForm?.get('selfPay')?.value === 'Insurance' && array?.controls?.length === 0) {
      this.insuranceForm?.get('subscriberfirstname')?.setValidators(null);
      this.insuranceForm?.get('subscriberlastname')?.setValidators(null);
      this.insuranceForm?.get('subscribergender')?.setValidators(null);
      this.insuranceForm?.get('subscriberdateOfBirth')?.setValidators(null);
      this.insuranceForm?.get('relationToSubscriber')?.setValidators(null);
      this.insuranceForm?.get('medicareFlag')?.setValidators(null);
      this.insuranceForm?.get('insurancePrimarySubscriber')?.setValidators(null);
      // to clear the all insurance details if select the self pay
      this.clearFormArray(this.insuranceForm?.get('insuranceDetails') as FormArray);

      this.insuranceForm?.get('relationToSubscriber')?.updateValueAndValidity();
      this.insuranceForm?.get('subscriberfirstname')?.updateValueAndValidity();
      this.insuranceForm?.get('subscriberlastname')?.updateValueAndValidity();
      this.insuranceForm?.get('subscribergender')?.updateValueAndValidity();
      this.insuranceForm?.get('medicareFlag')?.updateValueAndValidity();
      this.insuranceForm?.get('subscriberdateOfBirth')?.updateValueAndValidity();
      this.insuranceForm?.get('insurancePrimarySubscriber')?.updateValueAndValidity();
    }
  }

  public async previewInsuranceImage(additionalKey: string, index?: number) {

    this.PatientDetailsCardReload?.cardRefreshShow();
    try {
      const documentType = "Insurance";
      const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${this.patientDetailsInfo?.userInfoId}&additionalKey=${additionalKey}`;
      const getIndDoc: any = await this.httpService?.makeGetRequest(action, '')?.toPromise();
      if (getIndDoc?.responseObject == null || getIndDoc?.responseObject == '') {
        this.hideInsurance = false;
      }
      else {
        this.hideInsurance = true;
        const s3Key = getIndDoc?.responseObject?.s3Key;
        const s3Path = getIndDoc?.responseObject?.s3Path;
        const additionalKey = getIndDoc?.responseObject?.additionalKey;
        const fileType = getIndDoc?.responseObject?.fileType;
        this.downloadedFileType = fileType;

        const formArray = this.insuranceForm?.get('insuranceDetails') as FormArray;
        formArray?.at(index)?.get('docType')?.setValue(fileType);

        try {
          this.downloadIdFilePreview(this.patientDetailsInfo?.userInfoId, s3Key, s3Path, additionalKey, fileType);
        } catch (error) {
          this.PatientDetailsCardReload?.cardRefreshHide();
        }
      }
      this.PatientDetailsCardReload?.cardRefreshHide();
    } catch (error) {
      this.PatientDetailsCardReload?.cardRefreshHide();
    }

    // const documentType = "Insurance";
    // const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${this.patientDetailsInfo?.userInfoId}&additionalKey=${additionalKey}`;
    // this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
    //   this.PatientDetailsCardReload?.cardRefreshHide();
    //   if (data?.status === 'SUCCESS') {
    //     if (data?.responseObject == null || data?.responseObject == '') {
    //       this.hideInsurance = false;
    //     }
    //     else {
    //       this.hideInsurance = true;
    //       const s3Key = data?.responseObject?.s3Key;
    //       const s3Path = data?.responseObject?.s3Path;
    //       const additionalKey = data?.responseObject?.additionalKey;
    //       const fileType = data?.responseObject?.fileType;
    //       this.downloadedFileType = fileType;
    //       this.downloadIdFilePreview(this.patientDetailsInfo?.userInfoId, s3Key, s3Path, additionalKey, fileType);
    //     }
    //   }
    //   // this.dtTriggerAppointmentDocs?.next();
    // }, (error) => {

    // });
  }

  public downloadIdFilePreview(userInfoId, s3Key, s3Path, additionalKey, fileType) {
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if(!dData) {
        this.PatientDetailsCardReload?.cardRefreshHide();
        return;
      }
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }
      this.insuranceDcoumentInfoData = fileObj;
      const iframeEle = document.getElementById(additionalKey + '_add_insu_card_display') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
      this.PatientDetailsCardReload?.cardRefreshHide();
    }, (error) => {
      this.PatientDetailsCardReload?.cardRefreshHide();
    });
  }

  public downloadIdentificationCard(additionalKey: string, insuraceCardModal: UiModalComponent) {
    const documentType = "Insurance";
    const action = `record/listIndividualDocument?documentType=${documentType}&userInfoId=${this.patientDetailsInfo?.userInfoId}&additionalKey=${additionalKey}`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.loadSpinner = false;
      this.PatientDetailsCardReload?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject == null || data?.responseObject == '') {
          this.hideInsurance = false;
        }
        else {
          this.hideInsurance = true;
          const s3Key = data?.responseObject?.s3Key;
          const s3Path = data?.responseObject?.s3Path;
          const additionalKey = data?.responseObject?.additionalKey;
          const fileType = data?.responseObject?.fileType;
          this.downloadIdFile(this.patientDetailsInfo?.userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal);
        }
      }
      // this.dtTriggerAppointmentDocs?.next();
    }, (error) => {

    });
  }

  public downloadIdFile(userInfoId, s3Key, s3Path, additionalKey, fileType, insuraceCardModal: UiModalComponent) {
    if (!s3Key || !s3Path) {
      return;
    }
    const downloadaction = `record/downloadDocument?s3Key=${s3Key}&s3Path=${s3Path}`;
    this.httpService.downloadImage(downloadaction).subscribe((dData: any) => {
      if(!dData) {
        this.PatientDetailsCardReload?.cardRefreshHide();
        return;
      }
      this.hideInsurance = true;
      var file = new Blob([dData], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      const fileObj = {
        fileURL: fileURL,
        userInfoId: userInfoId,
        s3Key: s3Key,
        additionalKey: additionalKey
      }
      this.insuranceDcoumentInfoData = fileObj;
      /*const iframeEle = document.getElementById('dl-card') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);*/
      insuraceCardModal?.show();
      this.PatientDetailsCardReload?.cardRefreshHide();
      const iframeEle = document.getElementById('ins-card-multiple') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
    }, (error) => {
      this.PatientDetailsCardReload?.cardRefreshHide();
    });
  }

  public updateInsuranceCardKeys(existingS3Key: any, news3Key: any, memberId: any, userInfoId: any): void {
    let action = '';
    if (news3Key !== '') {
      action = `record/updateInsuranceImageKey?existingS3Key=${existingS3Key}&news3Key=${news3Key}&userInfoId=${userInfoId}`;
    }
    else {
      action = `record/updateInsuranceImageKey?existingS3Key=${existingS3Key}&userInfoId=${userInfoId}`;
    }

    this.httpService?.makeRequestWithAction(action, '')
      .subscribe((data: any) => {
        if (data?.body?.status === 'SUCCESS') {

        } else {

        }
      },
        error => {

        });
  }

  public deleteInsuranceDocument(insurance: FormGroup, medicalRecord: any, deletConfirmDocumentModal: UiModalComponent): void {
    //let actionAppend = '';
    let action = '';
    if (medicalRecord?.additionalKey == null && medicalRecord?.s3Key == null) {
      //actionAppend = '';
      action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.patientDetailsInfo?.userInfoId}`;
    }
    else {
      let keyValue = '';
      if (medicalRecord?.additionalKey !== '') {
        //actionAppend = '&additionalKey=${medicalRecord?.additionalKey}';
        action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.patientDetailsInfo?.userInfoId}&additionalKey=${medicalRecord?.additionalKey}`;
      }
      else {
        //actionAppend = '&additionalKey=${medicalRecord?.s3Key}';
        action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.patientDetailsInfo?.userInfoId}&additionalKey=${medicalRecord?.s3Key}`;
      }
    }

    //const action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.userInfoDetails?.id}${actionAppend}`;
    this.additionalDocName = "Insurance";
    // const action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.userInfoId}&additionalKey=${medicalRecord?.additionalKey}`;
    this.PatientDetailsCardReload?.cardRefreshShow();
    deletConfirmDocumentModal.hide();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      const memberId = insurance?.value?.memberId;
      this.updateInsuranceCardKeys(this.existingS3Key, '', memberId, this.patientDetailsInfo?.userInfoId);
      this.notifyText = data?.body?.msg || data?.body?.message || 'Record Deleted Successfully';
      this.isSuccessNotify = true;
      this.exceptionalModal?.modalShow();
      this.PatientDetailsCardReload?.cardRefreshHide();
      insurance?.get('insuranceCardImageId')?.setValue(null);
      this.insuranceForm?.markAsDirty();
      this.downloadIdentificationCard(this.additionalDocName, this.patientDetailsInfo?.userInfoId);
      //location.reload();
      // this.getAllDocumentsByUser();
    }, error => {
      this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionalModal?.modalShow();
      this.PatientDetailsCardReload?.cardRefreshHide();
    })
  }
  setDisable(insType: any) {
    if (insType == 'healthPass' && this.healthPassVal) {
      return true;
    }
  }
  public addPrefixZipCode(addressZipCode: any,valid:any): any {
    let prefixZipcode = addressZipCode;
    if (prefixZipcode) {
      const checkLen = prefixZipcode?.toString()?.length;
      if (checkLen === 4) {
        prefixZipcode = '0' + prefixZipcode;
      }
      if (checkLen === 3) {
        prefixZipcode = '00' + prefixZipcode;
      }
    }
   
    return prefixZipcode || addressZipCode || '';
  }
  ngOnDestroy(): void {
    this.insuranceInput?.unsubscribe();
  }

}
