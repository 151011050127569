import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';

@Component({
  selector: 'app-all-chart-documents-for-user',
  templateUrl: './all-chart-documents-for-user.component.html',
  styleUrls: ['./all-chart-documents-for-user.component.scss']
})
export class AllChartDocumentsForUserComponent implements OnInit {

  @Input() userInfoId: string;
  public additionalDocsForm: FormGroup;
  @ViewChild('fileUpload') public fileUpload: ElementRef;
  // public allDocumentTypes = ["Lab Results", "Radiology Results", "Referral", "Wellness Forms", 'Past Medical Record', "Other"];
  @Input() updateUserdataCard: CardComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;

  public dtOptionsAccountDocs: DataTables.Settings = {};

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public dtTriggerAccountDocs: Subject<any> = new Subject<any>();

  public allUploadedDocumentsForUser: any[] = [];
  public allAppointmentUploadedDocumentsForUser: any[] = [];
  public encounterData: any[] = [];
  public selectedMedicalRecordForDelete: any;
  @Output() callNotifycationUpdate = new EventEmitter<any>();
  @ViewChild('documentUploadNotify') documentUploadNotify: UiModalComponent;
  public addCss: boolean = false;
  public moreRecords: boolean = true;
  public showPreview: boolean = false;
  public accountDocs: boolean = true;
  public appointmentDocs: boolean = false;
  public viewAppointmentDocs: boolean = false;
  public selectedAppointmentId: any;
  public selectedRow: any;
  public checkClass: boolean = false;
  public activeId: any;
  public selectedAppDelete: any;
  public allDocumentTypes = [];
  public documentTypeSelected: any;
  public selectedRecord: any;
  public selectedDocumentName: any;
  public filterDoctypeForm: FormGroup;
  public loadSpinner: boolean;
  @Input() careFlowFont: boolean;
  
  public openActions: boolean = false;
  public enableActions: boolean = false;
  public selectedActionRow: any;
  
   @HostListener('document:click', ['$event'])
    clickInside(event) {
	
     const eventId = (event.target as HTMLInputElement).id;
	 if(!this.enableActions && eventId !== 'claimActions_btn'){
	     this.openActions = false;
	  }	
  }

  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
    this.additionalDocsForm = this.formBuilder?.group({
      'documentType': [null, Validators?.required],
      'multipartFile': [null, Validators?.required],
      'documentName': [null, Validators?.required]
    });
    this.filterDoctypeForm = this.formBuilder?.group({
      'filterdocumentType': [null, Validators?.required]
    });

    //this.documentTypeSelected = "All";

    //this.filterDoctypeForm?.get('filterdocumentType')?.setValue("All");

  }

  ngOnInit(): void {
    this.dtOptionsAccountDocs = {
      pagingType: 'simple_numbers',
      pageLength: 5,
      lengthChange: false,
      autoWidth: true,
      order: [[2, "desc"]],
      columnDefs: [{ targets: [2], type: 'date', orderable: true }],
      searching: false,
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable.",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    this.showPreview = false;
    this.documentTypeSelected = "All";
    this.filterDoctypeForm?.get('filterdocumentType')?.setValue(this.documentTypeSelected);
  }

  public rerender(): void {
    this.dtElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }

  ngAfterViewInit(): void {
    this.getDocumenttypeList();
    // this.openAccountDocuments();
  }

  public formateDate(date: any): any {
    return this.httpService?.getAppointmentTime(date);
  }

  public checkDocumentType(): void {
    if (this.additionalDocsForm?.get('documentType')?.invalid) {
      this.additionalDocsForm?.markAllAsTouched();
      this.notifyText = 'Document Type is required to upload document';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.fileUpload.nativeElement.value = "";
      return;
    }
  }

  public uploadFileSelect(event: any): void {
    if (this.additionalDocsForm?.get('documentType')?.invalid) {
      this.additionalDocsForm?.markAllAsTouched();
      this.notifyText = 'Document Type is required to upload document';
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.fileUpload.nativeElement.value = "";
      return;
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.additionalDocsForm?.get('documentName')?.setValue(file.name);
      this.additionalDocsForm?.get('multipartFile')?.setValue(file);
      this.uploadDocument();
    }
  }

  // upload Doucment
  public uploadDocument(): void {
    if (this.additionalDocsForm?.invalid) {
      this.additionalDocsForm?.markAllAsTouched();
      return;
    }
    // convert to binary for data
    const formData = new FormData();
    formData.append('idFile', this.additionalDocsForm?.value?.multipartFile);
    //const action = `userInfo/savePatientDocument?documentName=${this.additionalDocsForm?.value?.documentName}&documentType=${this.additionalDocsForm?.value?.documentType}&userInfoId=${this.userInfoId}`;
    const action = `common/getChartInfo?userInfoId=${this.userInfoId}&chartModuleName=Documents`;
    this.updateUserdataCard?.cardRefreshShow();
    this.httpService?.makePostWithFormData(action, formData)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.notifyText = data?.msg || data?.message || 'Document uploaded successfully.';
        this.isSuccessNotify = true;
        this.documentUploadNotify?.show();
        //this.rerender();
        this.getAllDocumentsByUser(this.documentTypeSelected);
      } else {
        this.notifyText = data?.msg || data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.updateUserdataCard?.cardRefreshHide();
    },
      (error) => {
        this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.updateUserdataCard?.cardRefreshHide();
      });
  };

  public openNotificationJournalNotesAdd(): void {
    this.callNotifycationUpdate?.emit(this.additionalDocsForm?.value?.documentType);
    this.resetFileForm();
  }

  // to get all thge uploaded documents by user info id
  public getAllDocumentsByUser(documentType): void {
    this.documentTypeSelected = documentType;
    this.filterDoctypeForm?.get('filterdocumentType')?.setValue(this.documentTypeSelected);
    this.rerender();
    //this.hidePreviewSection();
    //this.accountDocs = true;

    const action = `record/listDocumentsPatientChart?userInfoId=${this.userInfoId}&documentType=${documentType}`;

    this.updateUserdataCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      this.loadSpinner = true;
      this.updateUserdataCard?.cardRefreshHide();
      if (data?.status === 'SUCCESS') {
        //this.openAccountDocuments();
        this.allUploadedDocumentsForUser = data?.responseObject || [];
        this.allUploadedDocumentsForUser?.forEach((formList: any, index: number) => {
          //console.log(formList);

          if (formList.documentType == 'Image') {
            this.allUploadedDocumentsForUser = this.allUploadedDocumentsForUser?.filter((listItem: any) => listItem.documentType !== 'Image') || [];
          }
          /*else if (formList.documentType == 'Identification Record') {
                this.allUploadedDocumentsForUser = this.allUploadedDocumentsForUser?.filter((listItem: any) => listItem.documentType !== 'Identification Record') || [];
              }
              
              else if (formList.documentType == 'Insurance') {
                this.allUploadedDocumentsForUser = this.allUploadedDocumentsForUser?.filter((listItem: any) => listItem.documentType !== 'Insurance') || [];
              }
              else if (formList.documentType == 'Vaccination Record') {
                this.allUploadedDocumentsForUser = this.allUploadedDocumentsForUser?.filter((listItem: any) => listItem.documentType !== 'Vaccination Record') || [];
              }*/
          else if (formList.documentType == 'Claim Journal') {
            this.allUploadedDocumentsForUser = this.allUploadedDocumentsForUser?.filter((listItem: any) => listItem.documentType !== 'Claim Journal') || [];
          }

          this.allUploadedDocumentsForUser?.sort((a: any, b: any) => {
            const bDate: any = new Date(b?.auditInfo?.updatedDate);
            const aDate: any = new Date(a?.auditInfo?.updatedDate);
            return bDate - aDate;
          });


        });
        this.loadSpinner = false;
      }
      this.dtTriggerAccountDocs?.next();
    }, (error) => {
      this.loadSpinner = false;
      this.updateUserdataCard?.cardRefreshHide();
      this.dtTriggerAccountDocs?.next();
    });
  };


  public resetFileForm(): void {
    this.additionalDocsForm?.reset();
    this.fileUpload.nativeElement.value = "";
  }

  // Show Documents view popup
  public viewDownloadDocument(rowIndex: any, medicalRecord: any, isViewDownload: string, patientMedicalRecordModal: UiModalComponent) {
    if (!medicalRecord?.s3Key || !medicalRecord?.s3Path) {
      return;
    }
    this.selectedRecord = rowIndex;
    this.selectedDocumentName = medicalRecord?.documentName;
    const action = `record/downloadDocument?s3Key=${medicalRecord?.s3Key}&s3Path=${medicalRecord?.s3Path}`;
    //const action = `userInfo/getPatientDocument?s3Key=${medicalRecord?.s3Key}`;
    this.updateUserdataCard?.cardRefreshShow();
    this.httpService.downloadImage(action).subscribe((data: any) => {
      if(!data) {
        this.updateUserdataCard?.cardRefreshHide();
        return;
      }
      const fileType = medicalRecord.fileType;
      const file = new Blob([data], { type: fileType })
      const fileURL = URL.createObjectURL(file);
      if (isViewDownload === 'view') {
        const iframeEle = document.getElementById('consent-pdf-view') as HTMLElement;
        iframeEle.setAttribute('src', fileURL);
        patientMedicalRecordModal?.show();

      } else {
        let a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = medicalRecord?.documentName;
        document.body.appendChild(a);
        a.click();
      }
      this.updateUserdataCard?.cardRefreshHide();
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.updateUserdataCard?.cardRefreshHide();
      });
  }

  public deleteMedicalRecordOpen(document: any, deletConfirmPatientMedical: UiModalComponent): void {
    this.selectedMedicalRecordForDelete = document;
    deletConfirmPatientMedical?.show();
  }


  public deletePatientMedicalDocument(medicalRecord: any): void {
    console.log(medicalRecord);
    let action = '';

    if (medicalRecord.appointmentId !== null) {
      const appointmentId = medicalRecord.appointmentId;
      if (medicalRecord?.additionalKey == null && medicalRecord?.s3Key == null) {
        action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&appointmentId=${appointmentId}`;
      }
      else {
        if (medicalRecord?.additionalKey !== '' && medicalRecord?.additionalKey !== null) {
          action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&appointmentId=${appointmentId}&additionalKey=${medicalRecord?.additionalKey}`;
        }
        else {
          action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&appointmentId=${appointmentId}`;
        }
      }

    }
    else {
      if (medicalRecord?.additionalKey == null && medicalRecord?.s3Key == null) {
        action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.userInfoId}`;
      }
      else {
        if (medicalRecord?.additionalKey !== '' && medicalRecord?.additionalKey !== null) {
          action = `record/deleteDocument?userInfoId=${this.userInfoId}&additionalKey=${medicalRecord?.additionalKey}`;
        }
        else {
          action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.userInfoId}`;
        }

      }

    }

    // const action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&userInfoId=${this.userInfoId}&additionalKey=${medicalRecord?.additionalKey}`;
    this.updateUserdataCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      //this.notifyText = data?.body?.msg || data?.body?.message || 'Patient Medical Record Deleted Successfully';
      this.notifyText = 'Document deleted successfully.';
      this.isSuccessNotify = true;
      this.exceptionModal?.modalShow();
      this.updateUserdataCard?.cardRefreshHide();
      this.getAllDocumentsByUser(this.documentTypeSelected);
    }, error => {
      this.notifyText = error?.msg || error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.updateUserdataCard?.cardRefreshHide();
    })
  }

  public refreshCss(add: boolean) {

    //this.addCss = add ? true : false;
    this.addCss = !this.addCss;
    //this.showPreview = true;
  }


  public openAccountDocuments() {
    console.log(this.documentTypeSelected);

    this.getAllDocumentsByUser(this.documentTypeSelected);
    /*this.checkClass = false;
      this.showPreview = false;
      const sectionElement = document.getElementById("preview-main-sec") as HTMLElement;
      const list = sectionElement.classList;
      list.remove("col-lg-8");
      list.add("col-lg-12");*/
  }

  public openAppointmentDocuments() {
    this.checkClass = false;
    this.showPreview = false;
    const sectionElement = document.getElementById("preview-main-sec") as HTMLElement;
    const list = sectionElement.classList;
    list.remove("col-lg-8");
    list.add("col-lg-12");
  }

  public getAppointmentTime(date: any): string {
    return this.httpService?.getAppointmentTime(date);
  }

  public hidePreviewSection() {
    this.checkClass = false;
    this.showPreview = false;
    const sectionElement = document.getElementById("preview-main-sec") as HTMLElement;
    const list = sectionElement.classList;
    list.remove("col-lg-8");
    list.add("col-lg-12");
  }

  public getDocumenttypeList() {
    // const collectionType = event.value;
    const collectionType = 'All';
    const action = `record/listDocumentTypes?collectionType=${collectionType}`;
    this.allDocumentTypes = [];
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        // console.log(data);
        this.allDocumentTypes = data?.responseObject;
        this.allDocumentTypes?.forEach((formList: any, index: number) => {
          if (formList.collectionType == 'Facility') {
            this.allDocumentTypes = this.allDocumentTypes?.filter((listItem: any) => listItem.collectionType !== 'Facility') || [];
          }



          if (formList.documentType == 'Image') {
            this.allDocumentTypes = this.allDocumentTypes?.filter((listItem: any) => listItem.documentType !== 'Image') || [];
          }
          else if (formList.documentType == 'Claim Journal') {
            this.allDocumentTypes = this.allDocumentTypes?.filter((listItem: any) => listItem.documentType !== 'Claim Journal') || [];
          }
          /* else if (formList.documentType == 'Vaccination Record') {
                this.allDocumentTypes = this.allDocumentTypes?.filter((listItem: any) => listItem.documentType !== 'Vaccination Record') || [];
              }
          else if (formList.documentType == 'Identification Record') {
                this.allDocumentTypes = this.allDocumentTypes?.filter((listItem: any) => listItem.documentType !== 'Identification Record') || [];
              }
              else if (formList.documentType == 'Insurance') {
                this.allDocumentTypes = this.allDocumentTypes?.filter((listItem: any) => listItem.documentType !== 'Insurance') || [];
              }
          */



        })
        const obj = { "collectionType": "All", "documentType": "All" };
        this.allDocumentTypes.push(obj);
        const key = 'documentType';

        this.allDocumentTypes = [...new Map(this.allDocumentTypes.map(item =>
          [item[key], item])).values()];




      }

    },
      error => {

      })
  }

  public multiDimensionalUnique(arr) {
    var uniques = [];
    var itemsFound = {};
    for (var i = 0, l = arr.length; i < l; i++) {
      var stringified = JSON.stringify(arr[i]);
      if (itemsFound[stringified]) { continue; }
      uniques.push(arr[i]);
      itemsFound[stringified] = true;
    }
    return uniques;
  }
  public callNextPrev(action: any, record: any, isViewDownload: string, patientMedicalRecordModal: UiModalComponent) {

    let indexVal = record;
    this.selectedRecord = record;
    if (action === 'prev') {


      if (this.selectedRecord === 0) {
        indexVal = this.selectedRecord;
      }
      else {
        indexVal = this.selectedRecord - 1;
        this.viewDownloadDocument(indexVal, this.allUploadedDocumentsForUser[indexVal], isViewDownload, patientMedicalRecordModal);
      }



      //this.allUploadedDocumentsForUser
    }
    else {
      const arrLength = this.allUploadedDocumentsForUser.length;
      if (this.selectedRecord === (arrLength - 1)) {
        indexVal = this.selectedRecord;
      }
      else {
        indexVal = this.selectedRecord + 1;
        this.viewDownloadDocument(indexVal, this.allUploadedDocumentsForUser[indexVal], isViewDownload, patientMedicalRecordModal);
      }

    }

  }

  public filterDocuments(event: any) {
    // this.rerender();
    this.documentTypeSelected = event.documentType;
    this.getAllDocumentsByUser(this.documentTypeSelected);
  }
  
  public openUniversalActions(event: any, row) {
     if(this.selectedActionRow === row){
	 this.openActions = !this.openActions;
	 }
	 else{
	 this.openActions = true;
	 }
     
	 this.selectedActionRow = row;
	 
  }
   public closeUniversalActions() {
   
     this.openActions = false;
  }
  
  public mouseIn() {
  this.enableActions = true;
  
  }
  
   public mouseOut() {
	this.enableActions = false; 
	
  }

  ngOnDestroy(): void {
    if (this.dtTriggerAccountDocs) {
      this.dtTriggerAccountDocs?.unsubscribe();
    }
  }

}
