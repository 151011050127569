
<div class="insurance-details">
    <div class="row mb-3">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="add-new-items">
                <button type="button" (click)="opennewInsurance(addNewInsurance)" class="btn btn-primary" id="commonInsuranceAddl_add-new-insurance">+ Add
                    New
                    Insurance</button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12 col-md-12 table-responsive new-designed-table">
        <table class="table table-striped table-bordered table-hover" style="margin-bottom: 0px;">
            <thead>
                <tr>
                    <th>Member ID</th>
                    <th>Insurance Name</th>
                    <th>Insurance Class</th>
                    <th>Group Number</th>
                    <th>Payor ID</th>
                    <th>Coverage</th>
                    <th>Status</th>
                    <th class="text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr class="appointment-tr-queue"
                    *ngFor="let insurance of userInfoDetailsForm?.get('insuranceDetails')?.value; let insuI = index">
                    <td>{{insurance?.memberId || '--'}}</td>
                    <td>{{insurance?.name || '--'}}</td>
                    <td>{{insurance?.insuranceClass || '--'}}</td>
                    <td>{{insurance?.groupNumber || '--'}}</td>
                    <td>{{insurance?.payerId || '--'}}</td>
                    <td>{{insurance?.priority || '--'}}</td>
                    <td class="appointment-status-clrs">
                        <span
                            [ngClass]="insurance?.active ? 'status-clr-active' : 'status-clr-inactive'">{{insurance?.active
                            ? 'Active' : 'InActive'}}</span>
							
                    </td>
                    <td class="text-center">
                        <div class="dropdown-actions">
                            <button class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === insuI ?'dropdown-content-actions-selected':''}}" id="claimActions_btn" (click)="openUniversalActions($event, insuI)">...</button>
                            <div class="dropdown-content-actions" *ngIf="openActions && selectedActionRow === insuI" (mouseenter)="mouseIn();" (mouseleave)="mouseOut();">
                                <!-- <a href="javascript:"
                                    [ngClass]="{disabledDownload:insurance?.insuranceCardImageId == null}"
                                    (click)="downloadIdentificationCard('download', insurance?.insuranceCardImageId, insuraceCardModal);closeUniversalActions();"><i
                                        class="material-symbols-outlined">download</i>Download
                                    Document</a> -->

                                <a href="javascript:" id="commonInsuranceAddl_view"
                                    (click)="viewInsurnaceDetails(insurance, insuI, viewInsurance, false);closeUniversalActions();"><i
                                        class="material-symbols-outlined">visibility</i> View</a>
                                <a href="javascript:" id="commonInsuranceAddl_edit"
                                    (click)="editViewInsurnaceDetails(insurance, insuI, addNewInsurance, true);closeUniversalActions();"><i
                                        class="las la-pencil-alt"></i>Edit</a>
                                <a href="javascript:" id="commonInsuranceAddl_delete2"
                                    (click)="deleteInsuranceConfirm(insuI, insurance, confirmInsuranceCardDelete);closeUniversalActions();"><i
                                        class="lar la-trash-alt"></i>Delete</a>

                                <a href="javascript:" id="commonInsuranceAddl_download"
                                    [ngClass]="{disabledDownload:insurance?.insuranceCardImageId == null}"
                                    (click)="downloadIdentificationCard('download', insurance?.insuranceCardImageId, insuraceCardModal)"><i
                                        class="material-symbols-outlined">download</i>Download
                                    Document</a>

                            </div>
                        </div>
                        
                    </td>
                </tr>
                <tr>
                    <td colspan="8" align="center"
                        *ngIf="userInfoDetailsForm?.get('insuranceDetails')?.value.length === 0">No insurance details
                        found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Add new Insurance POP-Up -->
<app-ui-modal #addNewInsurance [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="addNewInsurance">
    <div class="app-modal-header">
        <h5 class="modal-title" *ngIf="!isEditMode">Add New Insurance</h5>
        <h5 class="modal-title" *ngIf="isEditMode">Update Insurance</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="addNewInsurance.hide();closeEditViewNewInsurance();editInsuranceEnable = false"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <!-- <div class="insurance-details">
            <div class="row mb-3">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="add-new-items">
                        <button type="button" (click)="addMoreInsurancesClick()" class="btn btn-primary">+ Add
                            New
                            Insurance</button>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="insurance-details" [formGroup]="newInsuranceFormGroup">
            <!-- *ngIf="newInsuranceFormGroup?.get('insuranceDetails')?.controls?.length > 1" -->
            <div class="multiple-insurances">

                <div class="row insurance-items">
                    <!-- <div class="delete col-lg-6 col-md-6 col-sm-1">
                            <div class="icon-queue-events delete-action-bg" placement="left"
                                ngbTooltip="Delete Insurance Details">
                                <i class="las la-trash-alt" style="color: black; font-size: 20px;"
                                    (click)="deleteInsuranceConfirm(i, insurance, confirmInsuranceCardDelete)"></i>
                            </div>
                        </div> -->
                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label>Insurance Name <span class="required">*</span></label>
                                    <ng-select placeholder="Search by Insurance Name or Payor ID or Plan Name"
                                        [items]="(allInsuranceList | async)?.responseObject" bindLabel="name"
                                        bindValue="name" [addTag]="true" [multiple]="false" [hideSelected]="true"
                                        [trackByFn]="trackByFn" [minTermLength]="3" [closeOnSelect]="true"
                                        (change)="selectedInsurance($event)" [loading]="insuranceLoading"
                                        typeToSearchText="Please enter 3 or more characters"
                                        [typeahead]="insuranceInput" clearAllText="Clear" formControlName="name"
                                        id="addl-insu-name">
                                    </ng-select>
                                    <span
                                        *ngIf="newInsuranceFormGroup?.get('name')?.errors?.required && newInsuranceFormGroup?.get('name')?.touched"
                                        class="text-danger">Insurance Name is Required</span>
                                    <span class="text-danger"
                                        *ngIf="newInsuranceFormGroup?.get('name')?.invalid && !newInsuranceFormGroup?.get('name')?.errors?.required">Please
                                        enter a valid Insurance Name</span>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                    <label>Member ID <span class="required">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="memberId"
                                        id="addl-insu-memberId" maxlength="25" placeholder="Member ID">
                                    <span
                                        *ngIf="newInsuranceFormGroup?.get('memberId').errors?.required && newInsuranceFormGroup?.get('memberId').touched"
                                        class="text-danger">Member ID is Required</span>
                                    <span class="text-danger"
                                        *ngIf="newInsuranceFormGroup?.get('memberId').invalid && !newInsuranceFormGroup?.get('memberId').errors?.required">Member
                                        ID accepts only Alphanumeric & Hyphen Symbol</span>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                    <label>Group Number </label>
                                    <input type="text" class="form-control input-text" formControlName="groupNumber"
                                        id="addl-insu-groupNumber" maxlength="25" placeholder="Group Number">
                                    <span class="text-danger"
                                        *ngIf="newInsuranceFormGroup?.get('groupNumber').invalid && !newInsuranceFormGroup?.get('groupNumber').errors?.required">
                                        Group Number accepts only Alphanumeric & Hyphen Symbol</span>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                    <label>Payor ID <span class="required">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="payerId"
                                        id="addl-insu-payerId" placeholder="Payor ID">
                                    <span
                                        *ngIf="newInsuranceFormGroup?.get('payerId').errors?.required && newInsuranceFormGroup?.get('payerId').touched"
                                        class="text-danger">Payor ID is Required</span>
                                    <span class="text-danger"
                                        *ngIf="newInsuranceFormGroup?.get('payerId').invalid && !newInsuranceFormGroup?.get('payerId').errors?.required">Please
                                        enter a valid Payor ID</span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label for="Relationship"> Coverage <span class="required">*</span></label>
                                    <div class="input-group mb-0">
                                        <ng-select placeholder="Choose Coverage Sequence" id="addl-insu-priority"
                                            (change)="changeCoverageSequence($event)" bindLabel="desc" bindValue="value"
                                            [items]="insuranceSequenceList" formControlName="priority"></ng-select>
                                    </div>
                                    <span
                                        *ngIf="newInsuranceFormGroup?.get('priority')?.errors?.required && newInsuranceFormGroup?.get('priority')?.touched"
                                        class="text-danger">Coverage Sequence is Required</span>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label for="Relationship"> Insurance Class <span class="required">*</span></label>
                                    <div class="input-group mb-0">
                                        <ng-select placeholder="Choose Insurance class" [items]="insuranceClassList"
                                            formControlName="insuranceClass" id="addl-insu-insuranceClass">
                                        </ng-select>
                                    </div>
                                    <span
                                        *ngIf="newInsuranceFormGroup?.get('insuranceClass')?.errors?.required && newInsuranceFormGroup?.get('insuranceClass')?.touched"
                                        class="text-danger">Insurance Class is Required</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <ng-container *ngIf="!newInsuranceFormGroup?.value?.insuranceCardImageId">
                            <!-- <app-upload-insurance-driving-docs [hideInsurance]="false" [insuranceDetails]="getInsurance(i)" [patientDetailsInfo]="patientDetailsInfo" [PatientDetailsCardReload]="PatientDetailsCardReload"></app-upload-insurance-driving-docs> -->
                            <div class="upload-documents mt-4">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <p class="text-info">* Note: Accept only PDF, PNG , JPEG files and
                                            Files larger than
                                            25MB cannot be uploaded</p>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <!-- <h5 class="head-color">Insurance Card</h5> -->
                                        <div class="item-wrapper one">
                                            <div class="item">
                                                <form data-validation="true" action="#" method="post"
                                                    enctype="multipart/form-data">
                                                    <div class="item-inner">
                                                        <div class="item-content">
                                                            <div class="image-upload">
                                                                <label (change)="insuranceFileChanged($event)"
                                                                    style="cursor: pointer;width: 100%;"
                                                                    for="user-insurance-license-upload">
                                                                    <img class="img-upload" alt="Document Upload"
                                                                        src="../../../../../../assets/images/ic_image_upload.svg" />
                                                                    <div class="h-100">
                                                                        <div class="dplay-tbl">
                                                                            <div class="dplay-tbl-cell">
                                                                                <h5 style="padding: 0px 15px;">
                                                                                    <b>Choose
                                                                                        Patient Insurance
                                                                                        Card to
                                                                                        Upload</b>
                                                                                </h5>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <input #addInsuranceFile data-required="image"
                                                                        type="file" name="user-insurance-license-upload"
                                                                        id="user-insurance-license-upload"
                                                                        class="image-input"
                                                                        data-traget-resolution="image_resolution"
                                                                        value="">
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </form>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="download-insurance-card mt-2"
                            *ngIf="newInsuranceFormGroup?.value?.insuranceCardImageId">
                            <h5 class="download-insurance">
                                <ng-container>
                                    <div class="row">

                                        <div class="col-lg-12 col-md-12 col-sm-12  text-center">
                                            <!-- <iframe id="{{newInsuranceFormGroup?.value?.insuranceCardImageId}}_chart_insu_doc" frameBorder="0"
                                    width="300" height="150px"></iframe>-->
                                            <ng-container
                                                *ngIf="newInsuranceFormGroup?.value?.docType === 'application/pdf'">
                                                <iframe
                                                    id="{{newInsuranceFormGroup?.value?.insuranceCardImageId}}_chart_insu_doc"
                                                    frameBorder="0" width="300" height="200px"></iframe>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="newInsuranceFormGroup?.value?.docType !== 'application/pdf'">
                                                <img style="width: 100%;padding: 0px 20px" name="Image Shown"
                                                    id="{{newInsuranceFormGroup?.value?.insuranceCardImageId}}_chart_insu_doc" />
                                            </ng-container>
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <button class="icon-queue-events signoff-action-bg clearfix" id="commonInsuranceAddl_fullview"
                                                placement="bottom" ngbTooltip="Full View">
                                                <i class="las la-expand "
                                                    (click)="downloadIdentificationCard('view',newInsuranceFormGroup?.value?.insuranceCardImageId, insuraceCardModal)"></i>
                                            </button>
                                            <button class="icon-queue-events delete-action-bg clearfix" id="commonInsuranceAddl_delete"
                                                placement="bottom" ngbTooltip="Delete">
                                                <i class="las la-trash-alt "
                                                    (click)="confirmDelete(newInsuranceFormGroup?.value?.insuranceCardImageId, selectedInsuranceDt, deletConfirmDocument)"
                                                    title="Delete"></i>
                                            </button>

                                        </div>
                                    </div>

                                </ng-container>
                            </h5>

                            <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="row">
                <!-- Is the patient primary subscriber of Insurance?  -->
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <label>Subscriber Details <span class="required">*</span></label><br />
                        <label class="radio-inline" *ngFor="let item of insurancePrimarySubscriber"><input
                                formControlName="insurancePrimarySubscriber" type="radio"
                                id="addl-insu-primary-sub-type" (change)="checkPrimarySubscriber('change')"
                                name="insurancePrimarySubscriber" [value]="item.value"><span
                                class="desc">{{item.desc}}</span></label>
                        <br />
                        <span
                            *ngIf="newInsuranceFormGroup?.get('insurancePrimarySubscriber').errors?.required && newInsuranceFormGroup?.get('insurancePrimarySubscriber').touched"
                            class="text-danger">Subscriber Details is Required</span>
                    </div>
                </div>
                <div class="form-group col-lg-4 col-md-4 col-sm-12">
                    <label>Insurance Class <span class="required">*</span></label><br />
                    <label class="radio-inline" *ngFor="let item of insuranceType">
                        <input formControlName="medicareFlag" id="addl-insu-insurance-type"
                            (change)="medicareFlagChange()" type="radio" name="medicareFlag" [value]="item.value">
                        <span class="desc">{{item.desc}}</span>
                    </label>
                    <br />
                    <span
                        *ngIf="newInsuranceFormGroup?.get('medicareFlag')?.errors?.required && newInsuranceFormGroup?.get('medicareFlag')?.touched"
                        class="text-danger">Insurance Class is Required</span>
                </div>
            </div>


            <div class="preimary-subscriber">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group ">
                            <label>Primary Subscriber First Name <span class="required">*</span></label>
                            <input type="text" class="form-control input-text" formControlName="subscriberfirstname"
                                id="addl-insu-subscriberfirstname" placeholder="Patient First Name"
                                [attr.disabled]="newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value === 'Self' ? 'true' : null">
                            <span
                                *ngIf="newInsuranceFormGroup?.get('subscriberfirstname').errors?.required && newInsuranceFormGroup?.get('subscriberfirstname').touched"
                                class="text-danger">Primary Subscriber First Name is Required</span>
                            <span class="text-danger"
                                *ngIf="newInsuranceFormGroup?.get('subscriberfirstname').invalid && !newInsuranceFormGroup?.get('subscriberfirstname').errors?.required">Please
                                enter a valid Primary Subscriber First Name</span>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Primary Subscriber Last Name <span class="required">*</span></label>
                            <input type="text" class="form-control input-text" formControlName="subscriberlastname"
                                id="addl-insu-subscriberlastname" placeholder="Patient Last Name"
                                [attr.disabled]="newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value === 'Self' ? 'true' : null">
                            <span
                                *ngIf="newInsuranceFormGroup?.get('subscriberlastname').errors?.required && newInsuranceFormGroup?.get('subscriberlastname').touched"
                                class="text-danger">Primary Subscriber Last Name is Required</span>
                            <span class="text-danger"
                                *ngIf="newInsuranceFormGroup?.get('subscriberlastname').invalid && !newInsuranceFormGroup?.get('subscriberlastname').errors?.required">Please
                                enter a valid Primary Subscriber Last Name</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Primary Subscriber Date of Birth  <span class="required">*</span></label>
                            <input type="date" class="form-control input-text" formControlName="subscriberdateOfBirth"
                                id="addl-insu-subscriberdateOfBirth" placeholder="Date of Birth" 
                                [max]="maxAppointmentDate" [min]="minDOB"
                                [attr.disabled]="newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value === 'Self' ? 'true' : null">
                            <span
                                *ngIf="newInsuranceFormGroup?.get('subscriberdateOfBirth').errors?.required && newInsuranceFormGroup?.get('subscriberdateOfBirth').touched"
                                class="text-danger">Primary Subscriber Date of Birth is Required</span>
                            
                            <span
                                *ngIf="newInsuranceFormGroup?.get('subscriberdateOfBirth').errors?.invalidDate && newInsuranceFormGroup?.get('subscriberdateOfBirth').touched"
                                class="text-danger">
                                Invalid date

                            </span>
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Primary Subscriber Gender <span class="required">*</span></label>

                            <ng-select placeholder="Choose Primary Subscriber Gender" [items]="genderList" id="commonInsuranceAddl_gender"
                                formControlName="subscribergender" bindLabel="desc" bindValue="value"
                                [readonly]="newInsuranceFormGroup?.get('insurancePrimarySubscriber')?.value === 'Self' ? true : null">
                            </ng-select>
                            <span
                                *ngIf="newInsuranceFormGroup?.get('subscribergender').errors?.required && newInsuranceFormGroup?.get('subscribergender').touched"
                                class="text-danger">Primary Subscriber Gender is Required</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                            <label for="Relationship"> Relationship to Subscriber <span
                                    *ngIf="newInsuranceFormGroup?.get('insurancePrimarySubscriber').value === 'Other'"
                                    class="text-danger">*</span></label>
                            <div class="input-group mb-0">
                                <ng-select placeholder="Choose Relationship to Subscriber"
                                    [items]="insuranceRelationship" formControlName="relationToSubscriber"
                                    id="addl-insu-relationToSubscriber" bindLabel="desc" bindValue="value">
                                </ng-select>
                                <span
                                    *ngIf="newInsuranceFormGroup?.get('relationToSubscriber').errors?.required && newInsuranceFormGroup?.get('relationToSubscriber').touched"
                                    class="text-danger">Relationship to Subscriber is Required</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="newInsuranceFormGroup?.get('medicareFlag').value === true">
                        <div class="form-group">
                            <label for="last-name">Coverage Effective Start Date <span class="required">*</span></label>
                            <input type="date" formControlName="medicareStartDate" id="addl-insu-CoverageEffectiveStartDate"
                                class="form-control input-text">
                            <span
                                *ngIf="newInsuranceFormGroup?.get('medicareStartDate')?.errors?.required && newInsuranceFormGroup?.get('medicareStartDate').touched"
                                class="text-danger">Coverage Effective Start Date is Required</span>
                            <span
                                *ngIf="newInsuranceFormGroup?.get('medicareStartDate')?.errors?.invalidDate && newInsuranceFormGroup?.get('medicareStartDate')?.touched && !newInsuranceFormGroup?.get('medicareStartDate')?.errors?.required"
                                class="text-danger">
                                Please enter a valid year
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Employer Details -->
            <div class="row">
                <div class="col-sm-12">
                    <h5>Employer Details</h5>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name"> Employer Name</label>
                        <input class="form-control input-text" type="text" placeholder="Name"
                            id="addl-insu-employer-name" formControlName="employerName" />
                    </div>
                </div>
            </div>
            <div class="employer-address-details">
                <app-address [addressForm]="newInsuranceFormGroup?.get('employerAddress')"></app-address>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_save"
            (click)="saveUpdateInsuranceDetails(addNewInsurance)">Save</button>
    </div>
</app-ui-modal>


<!-- confirm the delete insurance -->
<app-ui-modal #confirmInsuranceCardDelete [hideHeader]="false" [modalCentered]="true"
    id="confirmInsuranceCardDelete-farm-ins">
    <div class="app-modal-header">

        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="confirmInsuranceCardDelete?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row d-flex-ver-center">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <h5 class="modal-title text-info text-center" style="font-size: 30px; margin: 0px;">
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h5 class="modal-title" style=" text-align: left;">Are you sure you want to delete {{selectedInsuranceForDelete?.priority}} Insurance Details? <span *ngIf="selectedInsuranceForDelete?.priority === 'Primary' && userInfoDetailsForm?.get('insuranceDetails')?.controls?.length > 1">If Yes, Please ensure that you update the Primary coverage.</span></h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_yes2"
            (click)="confirmInsuranceCardDelete?.hide(); deleteInsuranceFromList(insuDeletedIndex, selectedInsuranceForDelete)">Yes</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_no2"
            (click)="confirmInsuranceCardDelete.hide()">No</button>
    </div>
</app-ui-modal>

<!-- confirm the delete insurance card -->
<app-ui-modal #deletConfirmInsurance [hideHeader]="false" [modalCentered]="true" id="deletConfirmInsurance-farm-ins">
    <div class="app-modal-header">
        <h5 class="modal-title">Are you sure want to delete Insurance Card?</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="deletConfirmInsurance?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_no1"
            (click)="deletConfirmInsurance.hide()">No</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_yes1"
            (click)="deletConfirmInsurance?.hide(); deleteInsuranceImageByUser(selectedInsuranceForDelete)">Yes</button>
    </div>
</app-ui-modal>

<!-- insurance image shown -->
<app-ui-modal #insuraceCardModal [hideHeader]="false" [modalCentered]="true" id="insuraceCardModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Insurance Card</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="chart-insurance-card-multiple" frameBorder="0" width="100%" height="450px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" id="commonInsuranceAddl_ok1"
            data-dismiss="modal" (click)="insuraceCardModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<app-ui-modal #deletConfirmDocument [hideHeader]="false" [modalCentered]="true" id="deletConfirmDocument">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title text-center">Are you sure want to delete Insurance Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="deletConfirmDocument?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">

                <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>

                </h5>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">
                <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete Insurance Details?</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_yes"
            (click)="deletConfirmDocument?.hide(); deleteInsuranceDocument(existingS3Key,selectedInsuranceDt,deletConfirmDocument)">Yes</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_no"
            (click)="deletConfirmDocument.hide()">No</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<app-ui-modal #viewInsurance [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true" id="viewInsurance">
    <div class="app-modal-header">
        <h5 class="modal-title"> Insurance Details</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="viewInsurance.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="insurance-details">

            <!-- <h5 class="header">Insurance Details</h5> -->
            <hr />

            <div class="row insurance-items">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h5 style="float: right;color: #296746;" *ngIf="selectedInsuranceDt?.active">Active</h5>
                    <h5 style="float: right;color: red" *ngIf="!selectedInsuranceDt?.active">Inactive</h5>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Insurance Name</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{selectedInsuranceDt?.name || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Member ID / Subscriber ID</label> <span
                                        class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{selectedInsuranceDt?.memberId || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Payor ID</label> <span class="label-colon"> : </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{selectedInsuranceDt?.payerId || '--'}} </label>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Group Number</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{selectedInsuranceDt?.groupNumber || '--'}} </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Coverage Sequence</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{selectedInsuranceDt?.priority || '--'}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label class="labelStyle">Insurance Class</label> <span class="label-colon"> :
                                    </span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label>{{selectedInsuranceDt?.insuranceClass || '--'}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="download-insurance-card mt-2" *ngIf="selectedInsuranceDt?.insuranceCardImageId">
                        <h5 class="download-insurance">
                            <ng-container>
                                <div class="row">

                                    <div class="col-lg-12 col-md-12 col-sm-12  text-center">
                                        <!-- <iframe id="{{newInsuranceFormGroup?.value?.insuranceCardImageId}}_chart_insu_doc" frameBorder="0"
                                    width="300" height="150px"></iframe>-->
                                        <ng-container *ngIf="selectedInsuranceDt?.docType === 'application/pdf'">
                                            <iframe style="width: 100%;"
                                                id="{{selectedInsuranceDt?.insuranceCardImageId}}_chart_insu_doc_view"
                                                frameBorder="0" width="300" height="200px"></iframe>
                                        </ng-container>
                                        <ng-container *ngIf="selectedInsuranceDt?.docType !== 'application/pdf'">
                                            <img style="width: 100%;padding: 0px 20px" name="Image Shown"
                                                id="{{selectedInsuranceDt?.insuranceCardImageId}}_chart_insu_doc_view" />
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-12 text-center">
                                        <button class="icon-queue-events signoff-action-bg clearfix" placement="bottom"
                                            ngbTooltip="Full View">
                                            <i class="las la-expand "
                                                (click)="downloadIdentificationCard('view',selectedInsuranceDt?.insuranceCardImageId, insuraceCardModal)"></i>
                                        </button>


                                    </div>
                                </div>

                            </ng-container>
                        </h5>
                        <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>




                    <!--<div class="download-insurance-card mt-2" *ngIf="selectedInsuranceDt?.insuranceCardImageId">
                        <h5 class="download-insurance">
                            <span
                                (click)="downloadInsuranceCard(selectedInsuranceDt?.insuranceCardImageId, insuraceCardPastVisitViewModal)">View/Download
                                Insurance Card <br /> <i class="las la-cloud-download-alt"></i></span>
                        </h5>
                    </div>-->
                    <div class="download-insurance-card mt-2" *ngIf="!selectedInsuranceDt?.insuranceCardImageId">
                        <h5 class="download-insurance">Insurance card not available</h5>
                    </div>
                </div>

            </div>


            <ng-container>
                <h5 class="header">Subscriber Details</h5>
                <hr />
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">First Name</label> <span class="label-colon"> :
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{selectedInsuranceDt?.subscriberfirstname || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Last Name</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{selectedInsuranceDt?.subscriberlastname || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">DOB</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{selectedInsuranceDt?.subscriberdateOfBirth || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Gender</label> <span class="label-colon"> : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{selectedInsuranceDt?.subscribergender || '--'}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Relationship to Subscriber</label> <span class="label-colon">
                                    : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{selectedInsuranceDt?.relationToSubscriber || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="selectedInsuranceDt?.medicareFlag === true">
                        <div class="form-group row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label class="labelStyle">Coverage Effective Start Date</label> <span
                                    class="label-colon">
                                    : </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <label>{{formatDobTimeZone(selectedInsuranceDt?.medicareStartDate) | date: 'MM/dd/yyyy'
                                    || '--'}}
                                </label>
                            </div>
                        </div>
                    </div>


                </div>
            </ng-container>





            <!-- Employer Details -->
            <h5 class="header">Employer Details</h5>
            <hr />
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-3 col-md-3 col-sm-6">
                            <label class="labelStyle">Employer Name</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-6">
                            <label>{{selectedInsuranceDt?.employerName || '--'}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">Street</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{selectedInsuranceDt?.employerAddress?.street || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">City</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{selectedInsuranceDt?.employerAddress?.city || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">State</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{selectedInsuranceDt?.employerAddress?.state || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="labelStyle">ZIP Code</label> <span class="label-colon"> : </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{addPrefixZipCode(selectedInsuranceDt?.employerAddress?.zipCode,'') || '--'}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonInsuranceAddl_close"
            (click)="viewInsurance.hide();">Close</button>
    </div>
</app-ui-modal>

<app-ui-modal #insuraceCardPastVisitViewModal [hideHeader]="false" [modalCentered]="true"
    id="insuraceCardPastVisitViewModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Insurance Card</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardPastVisitViewModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="chart-insurance-card-multiple" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" id="commonInsuranceAddl_ok2"
            data-dismiss="modal" (click)="insuraceCardPastVisitViewModal.hide()">Ok</button>
    </div>
</app-ui-modal>