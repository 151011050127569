import { AfterViewInit, Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { ExceptionModalComponent } from 'src/app/theme/shared/components/exception-modal/exception-modal.component';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from 'src/app/theme/shared/litterals/app.constants';
import { HttpService } from 'src/app/theme/shared/services/http.service';
import { ScheduleFollowupAppointmentComponent } from '../schedule-followup-appointment/schedule-followup-appointment.component';
import { FollowupNotificationComponent } from '../followup-notification/followup-notification.component';
import { Router } from '@angular/router';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-caremanagement-list',
  templateUrl: './caremanagement-list.component.html',
  styleUrls: ['./caremanagement-list.component.scss']
})
export class CaremanagementListComponent implements OnInit {
  @Input() userInfoId: any;
  public caregapList = [];
  public careProgrammList = [];
  public userDeviceList = [];
  public savedPrograms = [];
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  loadSpinner: boolean;
  public notifyText: string;
  @ViewChild('caregapCard') caregapCard: CardComponent;
  @Input() queueCard: CardComponent;
  @ViewChild(DataTableDirective, { static: false })
  dtElementCaregap: DataTableDirective;
  public dtOptionsCaregap: DataTables.Settings = {};
  public dtTriggerCaregap: Subject<any> = new Subject<any>();

  public openFilters: boolean = false;
  public loginDetails: any;
  public purposeOfVisitList = [];
  public typeList = ["Wait List", "Follow Up"];
  public selectedAppointmentId: any;
  public counterInfo: any;
  @ViewChild(ScheduleFollowupAppointmentComponent) private scheduleFollowup: ScheduleFollowupAppointmentComponent;
  @ViewChild(FollowupNotificationComponent) private followupNotify: FollowupNotificationComponent;

  public openActions: boolean = false;
  public enableActions: boolean = false;
  public selectedActionRow: any;
  public chronicConditionsList: any[] = [];
  /*public chronicConditionsList = [
    { value: 'Hypertension', desc: 'Hypertension' },
    { value: 'Diabetes', desc: 'Diabetes' },
    { value: 'Heart Disease', desc: 'Heart Disease' },
    { value: 'Chronic Obstructive Pulmonary Disease (COPD)', desc: 'Chronic Obstructive Pulmonary Disease (COPD)' },
    { value: 'Asthma', desc: 'Asthma' },
    { value: 'Arthritis', desc: 'Arthritis' },
    { value: 'Chronic Kidney Disease (CKD)', desc: 'Chronic Kidney Disease (CKD)' },
    { value: 'Mental Health Conditions', desc: 'Mental Health Conditions' }
  ];*/

  public masterPatientDetails: any;
  public totalEligiblePatientsCount: any;
  public selectedCaregap: any;
  public selectedConsent = null;
  public addProgrammForm: FormGroup;
  public deviceAddForm: FormGroup;
  public enrollPatientForm: FormGroup;
  public isUpdate: Boolean = false;
  public conditionsForm: FormGroup;
  public isVerbalConsent: boolean;
  public isPhysicalConsent: boolean;
  public consentNotSelected: boolean;
  public selectedProgram: any;
  public isCCM: Boolean = false;
  public savedItemAlert = '';
  public activeSelected: boolean;
  public maxIssueDate: Date;
  public currentYear = new Date().getFullYear();
  public currentMonth = new Date().getMonth();
  public currentDate = new Date().getDate();


  isSuccessNotify: boolean;

  public ccmStatusList = [
    { value: 'Enrolled', desc: 'Active' },
    { value: 'Paused', desc: 'Paused' },
    { value: 'Cancelled', desc: 'Cancelled' }
  ];

  public programmList = [
    { value: 'Chronic Care', desc: 'CCM' },
    { value: 'RPM', desc: 'RPM' }
  ];

  @HostListener('document:click', ['$event'])
  clickInside(event) {

    const eventId = (event.target as HTMLInputElement).id;
    if (!this.enableActions && eventId !== 'claimActions_btn') {
      this.openActions = false;
    }
  }

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router) {
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));

    this.enrollPatientForm = this.formBuilder?.group({
      'physicalConsent': [null],
      'verbalConsent': [null]
    });

    this.addProgrammForm = this.formBuilder?.group({
      'name': [null, Validators.required],
      'conditions': [null],
      'status': [null, Validators.required],
    });

    this.deviceAddForm = this.formBuilder?.group({
      'deviceName': [null, Validators.required],
      'deviceNumber': [null, Validators.required],
      'deviceIssueDate': [null, Validators.required]
      //  'devicePurpose': [null, Validators.required]
    });

    this.chronicConditionsList = [
      { value: 'Hypertension', desc: 'Hypertension' },
      { value: 'Diabetes', desc: 'Diabetes' },
      { value: 'Heart Disease', desc: 'Heart Disease' },
      { value: 'Chronic Obstructive Pulmonary Disease (COPD)', desc: 'Chronic Obstructive Pulmonary Disease (COPD)' },
      { value: 'Asthma', desc: 'Asthma' },
      { value: 'Arthritis', desc: 'Arthritis' },
      { value: 'Chronic Kidney Disease (CKD)', desc: 'Chronic Kidney Disease (CKD)' },
      { value: 'Mental Health Conditions', desc: 'Mental Health Conditions' }
    ];

  }

  ngOnInit(): void {
    this.maxIssueDate = this.httpService?.convertDate(new Date());
    this.dtOptionsCaregap = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      autoWidth: true,
      searching: false,
      lengthChange: false,
      paging: false,
      info: false,
      lengthMenu: [10, 25, 50, 75, 100],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };


    this.isCCM = true;
    this.deviceAddForm.get('deviceName').setValidators(null);
    this.deviceAddForm.get('deviceNumber').setValidators(null);
    this.deviceAddForm.get('deviceIssueDate').setValidators(null);

    this.deviceAddForm.get('deviceName').updateValueAndValidity();
    this.deviceAddForm.get('deviceNumber').updateValueAndValidity();
    this.deviceAddForm.get('deviceIssueDate').updateValueAndValidity();

    this.getCareProgramsList();
    this.activeSelected = false;

  }
  ngAfterViewInit(): void {
    // this.getCareProgramsList();
    //this.getCaregapList();
  }

  public rerenderCaregap(): void {
    this.dtElementCaregap?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }

  public getCareProgramsList() {
    const caregapDto = {}
    //this.userDeviceList = [];
    const action = `careGaps/searchMasterPatientCarePrograms?userInfoId=${this.userInfoId}`;
    this.rerenderCaregap();
    // this.queueCard?.cardRefreshShow();
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {

      if (data?.status === 'SUCCESS') {

        this.careProgrammList = data?.responseObject?.carePrograms || [];
        this.userDeviceList = data?.responseObject?.deviceList || [];
        // console.log(this.careProgrammList);
        this.savedPrograms = [];
        this.careProgrammList?.forEach((item: any) => {
          this.savedPrograms.push(item.name)
        });
        //console.log(this.savedPrograms);
      }
      else {
        this.careProgrammList = [];
      }
      this.dtTriggerCaregap?.next();



    },
      error => {
        this.dtTriggerCaregap?.next();

        this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
        this.exceptionModal.modalShow();

      })
  }




  /* public getCaregapList() {
 
     const caregapDto = {}
     const action = `careGaps/searchMasterPatientCareGaps?userInfoId=${this.userInfoId}`;
     this.rerenderCaregap();
     this.queueCard?.cardRefreshShow();
     this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
       console.log(data);
       console.log(data.responseObject);
       if (data?.status === 'SUCCESS') {
         
         this.caregapList = data?.responseObject || [];
         console.log(this.caregapList);
 
       }
       else {
         this.caregapList = [];
       }
       this.dtTriggerCaregap?.next();
       this.queueCard?.cardRefreshHide();
 
 
     },
       error => {
         this.dtTriggerCaregap?.next();
         this.queueCard?.cardRefreshHide();
         this.exceptionModal.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;;
         this.exceptionModal.modalShow();
 
       })
 
   }*/

  public getConditionslist(conditions: any) {
    return conditions.join(',');
  }

  public checkEnroll(addProgrammModal: UiModalComponent, enrollPatient: UiModalComponent) {
    if (this.addProgrammForm?.invalid) {
      this.addProgrammForm?.markAllAsTouched();
      return;
    }
    if (!this.isCCM) {
      if (this.deviceAddForm?.invalid) {
        this.deviceAddForm?.markAllAsTouched();
        return;
      }
    }

    if (this.isUpdate && this.selectedProgram?.status === 'Enrolled') {
      
      this.selectedConsent = this.selectedProgram.consentType;
      this.addProgramm(addProgrammModal, enrollPatient);
    }
    else {
     
      if (!this.savedItemAlert) {
        const formselectedStatus = this.addProgrammForm.get('status').value;
        if (formselectedStatus === 'Enrolled') {
          this.activeSelected = true;
        }
        else {
          this.activeSelected = false;
        }
        /*if (this.activeSelected) {
          enrollPatient?.show();
        }
        else {
          this.addProgramm(addProgrammModal, enrollPatient);
        }*/
        enrollPatient?.show();

      }

    }


  }

  public addProgramm(addProgrammModal: UiModalComponent, enrollPatient: UiModalComponent) {
    
    let action = null;
    if (this.isUpdate || this.activeSelected) {
      action = `careGaps/updateCareProgram`;
    }
    else {
      action = `careGaps/addCareProgram`;
    }

    const program = this.addProgrammForm.get('name')?.value;
    const careProgramDto = {
      "conditions": this.addProgrammForm.get('conditions')?.value,
      "consentType": this.selectedConsent,
      "name": this.addProgrammForm.get('name')?.value,
      "notQualified": null,
      "patientEmrId": null,
      "reason": null,
      "status": this.addProgrammForm.get('status')?.value,
      "userInfoId": this.userInfoId
    };

    this.httpService?.makeRequestWithAction(action, careProgramDto)?.subscribe((data: any) => {

      if (data?.body?.status == 'SUCCESS') {
        if (program === 'RPM') {
          this.saveDeviceDetails();
        }
        this.isSuccessNotify = true;
          this.notifyText = data?.body?.msg || data?.body?.message;
          this.exceptionModal?.modalShow();

        addProgrammModal?.hide();
        enrollPatient?.hide();
        this.enrollPatientForm.reset();
        this.addProgrammForm.reset();
        this.selectedConsent = null;
        if (this.isUpdate) {
          this.updateMasterPatient(careProgramDto);
        }
        else {
          this.resetAddProgram();
          this.getCareProgramsList();
         
        }

      }

    },
      (error) => {

      })

  }


  public saveDeviceDetails(): void {

    if (this.deviceAddForm?.invalid) {
      this.deviceAddForm?.markAllAsTouched();
      return;
    }
    this.loadSpinner = true;
    const name = this.deviceAddForm?.get('deviceName').value;
    const number = this.deviceAddForm?.get('deviceNumber').value;
    // const purpose = this.deviceAddForm?.get('devicePurpose').value;
    const administeredDate = this.deviceAddForm?.get('deviceIssueDate').value || new Date();
    const issueDate = formatDate(administeredDate, 'yyyy-MM-dd', 'en_US');


    var finalData: any;

    const params = {
      'userId': this.userInfoId,
      'name': name,
      'number': number,
      'purpose': null,
      'issueDate': issueDate
    };
    if (this.isUpdate && this.userDeviceList && this.userDeviceList.length !== 0) {
      //params['id'] = this.updatedData?.id;
      params['id'] = this.userDeviceList[0].id;
    }
    finalData = params;

    if (this.isUpdate) {
      const action = this.isUpdate && this.userDeviceList && this.userDeviceList.length !== 0 ? `rpm/updateRpmDevice` : `rpm/saveRpmDevice`;
      if (action === 'rpm/saveRpmDevice') {
        this.httpService.makeRequestWithAction(action, finalData).subscribe((data: any) => {
          this.deviceAddForm.reset();

        },
          error => {

          });
      }
      else if (action === 'rpm/updateRpmDevice') {
        this.httpService.makePutRequest(action, finalData).subscribe((data: any) => {
          this.deviceAddForm.reset();

        },
          error => {

          });

      }

    } else {
      const action = this.isUpdate && this.userDeviceList && this.userDeviceList.length !== 0 ? `rpm/updateRpmDevice` : `rpm/saveRpmDevice`;
      if (action === 'rpm/saveRpmDevice') {
        this.httpService.makeRequestWithAction(action, finalData).subscribe((data: any) => {
          this.deviceAddForm.reset();

        },
          error => {

          });
      }
      else if (action === 'rpm/updateRpmDevice') {
        this.httpService.makePutRequest(action, finalData).subscribe((data: any) => {
          this.deviceAddForm.reset();

        },
          error => {

          });
      }

    }

  }

  public toggleConsent(event: any, controlName) {

    const consentValue = this.enrollPatientForm.get(controlName)?.value;
    if (controlName === 'verbalConsent') {
      if (consentValue == true) {
        this.isVerbalConsent = true;
        this.isPhysicalConsent = false;
        this.selectedConsent = 'Verbal';
      }
      else {
        this.isVerbalConsent = false;
        this.selectedConsent = '';
      }

    }
    else if (controlName === 'physicalConsent') {
      if (consentValue == true) {
        this.isPhysicalConsent = true;
        this.isVerbalConsent = false;
        this.selectedConsent = 'Physical';
      }
      else {
        this.isPhysicalConsent = false;
        this.selectedConsent = '';
      }
    }

    if (this.selectedConsent !== '' && this.selectedConsent !== null && this.selectedConsent !== undefined) {
      this.consentNotSelected = false;
    }

    // console.log('selectedConsent',this.selectedConsent);

  }

  public enrollpatient(addProgrammModal: UiModalComponent, enrollPatient: UiModalComponent) {
    // console.log('selectedConsent', this.selectedConsent);
    // console.log(this.addProgrammForm.get('name')?.value);
    const statusVal = this.addProgrammForm.get('status')?.value;
    /*if (this.selectedConsent === '' || this.selectedConsent === null || this.selectedConsent === undefined) {
      this.consentNotSelected = true;
      return;
    }*/
    if (this.isUpdate && statusVal === 'Enrolled' && (this.selectedConsent === '' || this.selectedConsent === null || this.selectedConsent === undefined)) {
      this.consentNotSelected = true;
      return;
    }

    let addAction = null;
    if (this.isUpdate) {
      addAction = `careGaps/updateCareProgram`;
    }
    else {
      addAction = `careGaps/addCareProgram`;
    }



    const program = this.addProgrammForm.get('name')?.value;
    const addCareProgramDto = {
      "conditions": this.addProgrammForm.get('conditions')?.value,
      "consentType": null,
      "name": this.addProgrammForm.get('name')?.value,
      "notQualified": null,
      "patientEmrId": null,
      "reason": null,
      "status": this.addProgrammForm.get('status')?.value,
      "userInfoId": this.userInfoId
    };

    //console.log('addprogramm', addCareProgramDto);

    this.httpService?.makeRequestWithAction(addAction, addCareProgramDto)?.subscribe((data: any) => {

      if (data?.body?.status == 'SUCCESS') {
        if (!this.isUpdate) {
          this.isSuccessNotify = true;
          this.notifyText = data?.body?.msg || data?.body?.message;
          this.exceptionModal?.modalShow();
          }
          
        if(program === 'RPM') {
          this.saveDeviceDetails();
          }
        let updatedStatus = null;
        if (statusVal === 'Paused' && this.selectedConsent !== '' && this.selectedConsent !== null && this.selectedConsent !== undefined) {
          updatedStatus = 'Enrolled';
        }
        else {
          updatedStatus = statusVal;

        }

        const action = `careGaps/updateCareProgram`;

        const careProgramDto = {
          "conditions": this.addProgrammForm.get('conditions')?.value,
          "consentType": this.selectedConsent,
          "name": this.addProgrammForm.get('name')?.value,
          "notQualified": null,
          // "patientEmrId": this.selectedCaregap.patientEmrId,
          "reason": null,
          "status": updatedStatus,
          "userInfoId": this.userInfoId
        };

        // console.log('enrollprogramm', careProgramDto);

        this.httpService?.makeRequestWithAction(action, careProgramDto)?.subscribe((data: any) => {

          if (data?.body?.status == 'SUCCESS') {
            this.isSuccessNotify = true;
            enrollPatient?.hide();
            addProgrammModal?.hide();
            this.enrollPatientForm.reset();
            this.addProgrammForm.reset();
            this.selectedConsent = null;
            if (this.isUpdate) {
              this.isSuccessNotify = true;
              this.notifyText = data?.body?.msg || data?.body?.message;
              this.exceptionModal?.modalShow();
              }

            this.updateMasterPatient(careProgramDto);

          }

        },
          (error) => {

          })
          this.resetEnrollProgram();
         // this.getCareProgramsList();
      }
      
    },
      (error) => {

      })
  }
  public updateMasterPatient(caregap) {
    const action = `careGaps/updateMasterPatientCaregaps`;
    const careProgramDto = {
      "conditions": caregap?.conditions,
      //"consentType": null,
      "name": caregap?.name,
      //"patientEmrId": caregap?.patientEmrId,      
      "userInfoId": this.userInfoId
    };

    this.httpService?.makeRequestWithAction(action, careProgramDto)?.subscribe((data: any) => {

      if (data?.body?.status == 'SUCCESS') {
        this.resetAddProgram();
        this.getCareProgramsList();

       /* this.isSuccessNotify = true;
        this.notifyText = data?.body?.msg || data?.body?.message;
        this.exceptionModal?.modalShow();*/
      }

    },
      (error) => {

      })
  }

  public resetAddProgram() {
    this.addProgrammForm.reset();
    this.deviceAddForm.reset();
    this.savedItemAlert = null;
  }
  public resetEnrollProgram() {
    this.enrollPatientForm.reset();
    this.activeSelected = false;
    this.consentNotSelected = false;

  }

  public openUniversalActions(event: any, row) {
    if (this.selectedActionRow === row) {
      this.openActions = !this.openActions;
    }
    else {
      this.openActions = true;
    }

    this.selectedActionRow = row;

  }
  public closeUniversalActions() {

    this.openActions = false;
  }

  public mouseIn() {
    this.enableActions = true;

  }

  public mouseOut() {
    this.enableActions = false;

  }

  public openAddProgram(addProgram: UiModalComponent) {
    this.isUpdate = false;
    this.addProgrammForm?.get('status').setValue('Paused');
    addProgram?.show();

  }

  public openUpdateProgram(addProgram: UiModalComponent, program: any) {
    this.selectedProgram = program;

    this.addProgrammForm?.get('name').setValue(program?.name);
    this.addProgrammForm?.get('conditions').setValue(program?.conditions);
    this.addProgrammForm?.get('status').setValue(program?.status);

    if (program.name === 'Chronic Care') {
      this.isCCM = true;
    }
    else {

      this.isCCM = false;
      if (this.userDeviceList && this.userDeviceList.length !== 0) {
        const issueDate = this.userDeviceList[0]?.issueDate;
        const dateFormated = issueDate.split("T")[0];
        this.deviceAddForm?.get('deviceName').setValue(this.userDeviceList[0]?.name);
        this.deviceAddForm?.get('deviceNumber').setValue(this.userDeviceList[0]?.number);
        this.deviceAddForm?.get('deviceIssueDate').setValue(dateFormated);

      }

    }

    addProgram?.show();
    this.isUpdate = true;
  }

  public visitDateFormat(visitDate: string): string {

    return this.httpService?.getCareflowDate(visitDate);
  }

  public yymmddDateFormat(visitDate: string): string {

    return this.httpService?.yymmddDateFormat(visitDate);
  }

  public checkIfActive(event: any) {
    const statusVal = event.value;
    if (this.isUpdate && statusVal === 'Enrolled') {
      this.activeSelected = true;
    }
    else {
      this.activeSelected = false;
    }

  }
  public changeProgramType(event: any) {

    let pValue = null;
    if (event.value === 'Chronic Care') {
      pValue = 'CCM';
    }
    else {
      pValue = event.value;
    }
    if (this.savedPrograms.includes(event.value)) {
      this.savedItemAlert = pValue + " programm is already added";

      return;
    }
    else {
      this.savedItemAlert = null;
    }

    if (event.value === 'Chronic Care') {
      this.isCCM = true;
      this.deviceAddForm.get('deviceName').setValidators(null);
      this.deviceAddForm.get('deviceNumber').setValidators(null);
      this.deviceAddForm.get('deviceIssueDate').setValidators(null);

      this.deviceAddForm.get('deviceName').updateValueAndValidity();
      this.deviceAddForm.get('deviceNumber').updateValueAndValidity();
      this.deviceAddForm.get('deviceIssueDate').updateValueAndValidity();
    }
    else {
      this.isCCM = false;

      this.deviceAddForm.get('deviceName').setValidators([Validators?.required]);
      this.deviceAddForm.get('deviceNumber').setValidators([Validators?.required]);
      this.deviceAddForm.get('deviceIssueDate').setValidators([Validators?.required]);

      this.deviceAddForm.get('deviceName').updateValueAndValidity();
      this.deviceAddForm.get('deviceNumber').updateValueAndValidity();
      this.deviceAddForm.get('deviceIssueDate').updateValueAndValidity();

    }
  }

  public validateManualEnterdob(e: any): void {
    if (e.target.value != '' && e.target.value != null) {
      if (this.httpService?.validateManualEnterdob(e.target.value, this.currentYear, this.currentMonth + 1, this.currentDate)) {
        const passwordControl = this.deviceAddForm.get('deviceIssueDate');
        passwordControl.setErrors({ invalidDate: true });
      }
    }
  }


}
