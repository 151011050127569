<!-- Addendum -->
<app-ui-modal #AddendumModal class="common-modal" [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true" id="AddendumModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Addendum</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="AddendumModal?.hide(); addendumNotesForm?.reset()"><span aria-hidden="true">&times;</span></button>
       </div>
    <div class="app-modal-body add-app-body" [formGroup]="addendumNotesForm">
        <div class="row">
            <div class="col-md-8">
                <h6>Previous Notes</h6>
                <div class="table-responsive">
                <table class="table table-bordered common-table">
                    <thead>
                        <tr>
                            <th>NOTES</th>
                            <th>REASON</th>
                            <th>INITIALS</th>
                            <th>DATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let adendem of addendamData;">
                            <td>
                                {{adendem?.notes}}
                            </td>
                            <td>
                                {{adendem?.reason}}
                            </td>
                            <td>
                                {{adendem?.initials}}
                            </td>
                            <td>
                                {{adendem?.auditInfo?.lastUpdatedDateTime | date: 'MM/dd/yyyy hh:mm a'}}
                            </td>

                        </tr>                    
                    </tbody>
                </table>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                        <div class="form-group">
                            <label>Reason <span class="text-danger">*</span></label>
                            <ng-select placeholder="Add or Choose Reason" formControlName="reason" [items]="allAddendumReasons"
                                [addTag]="true"></ng-select>
                            <span class="text-danger"
                                *ngIf="addendumNotesForm?.get('reason').errors?.required && addendumNotesForm?.get('reason').touched">
                                Reason is Required</span>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                        <div class="form-group">
                            <label>Initials <span class="text-danger">*</span></label>
                            <input class="form-control input-text" type="text" formControlName="initials"
                                name="validation-required" placeholder="Initials">
                            <label class="text-danger"
                                *ngIf="addendumNotesForm?.get('initials')?.errors?.required && addendumNotesForm?.get('initials')?.touched">Initials
                                is required</label>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label>Notes <span class="text-danger">*</span></label>
                            <textarea class="form-control" formControlName="notes" name="validation-required"
                                placeholder="Write Notes..."></textarea>
                            <label class="text-danger"
                                *ngIf="addendumNotesForm?.get('notes')?.errors?.required && addendumNotesForm?.get('notes')?.touched">Notes
                                is required</label>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="app-modal-footer">
        <button class="btn btn-primary dash-actions" id="addendum_submit1" style="margin-right: 15px;" type="button"
            (click)="addendumNotesUpdate()">Submit</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>