<div class="summary-bs-data">
    <div class="table-responsive">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <!-- <th>Type</th> -->
                    <th style="width: 70% !important;">Question</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="mainSectionDisplay(behaviourScreeningData)">
                    <ng-container *ngFor="let item of behaviourScreeningData; let i = index">
                        <tr *ngIf="checkBehaviouralScreenigDisplay(item?.sections)">
                            <td colspan="2">
                                <h5 class="behaviour-screening-type"
                                    *ngIf="checkCountsSectionsDisplay(item?.resourceType, 'Alcohol')">
                                    {{item?.resourceType}}
                                    <div class="calculation result">
                                        <ng-container>
                                            <div class="negative">AUDIT-C Score<span
                                                    class="pointsCount">{{pointsCount(item?.sections)}}
                                                    points</span></div>
                                        </ng-container>
                                    </div>
                                </h5>
                                <h5 class="behaviour-screening-type"
                                    *ngIf="checkCountsSectionsDisplay(item?.resourceType, 'GAD')">
                                    {{item?.resourceType}}
                                    <div class="calculation result">
                                        <ng-container *ngIf="pointsCount(item?.sections) <= 2">
                                            <div class="negative">Negative<span
                                                    class="pointsCount">{{pointsCount(item?.sections)}}
                                                    points</span></div>
                                        </ng-container>
                                        <ng-container *ngIf="pointsCount(item?.sections) > 2">
                                            <div class="positive">Positive<span
                                                    class="pointsCount">{{pointsCount(item?.sections)}}
                                                    points</span></div>
                                        </ng-container>
                                    </div>
                                </h5>
                                <h5 class="behaviour-screening-type"
                                    *ngIf="checkCountsSectionsDisplay(item?.resourceType, 'PHQ9')">
                                    {{item?.resourceType}}
                                    <div class="calculation result">
                                        <ng-container *ngIf="pointsCount(item?.sections) <= 2">
                                            <div class="negative">Negative<span
                                                    class="pointsCount">{{pointsCount(item?.sections)}}
                                                    points</span></div>
                                        </ng-container>
                                        <ng-container *ngIf="pointsCount(item?.sections) > 2">
                                            <div class="positive">Positive.<span
                                                    class="pointsCount">{{pointsCount(item?.sections)}}
                                                    points</span></div>
                                        </ng-container>
                                    </div>
                                </h5>
                                <h5 class="behaviour-screening-type"
                                    *ngIf="checkCountsSectionsDisplay(item?.resourceType, 'PHQ2')">
                                    {{item?.resourceType}}
                                    <div class="calculation result">
                                        <ng-container *ngIf="pointsCount(item?.sections) <= 2">
                                            <div class="negative">Negative<span
                                                    class="pointsCount">{{pointsCount(item?.sections)}}
                                                    points</span></div>
                                        </ng-container>
                                        <ng-container *ngIf="pointsCount(item?.sections) > 2">
                                            <div class="positive">Positive. Use PHQ-9 for further
                                                evaluation<span class="pointsCount">{{pointsCount(item?.sections)}}
                                                    points</span></div>
                                        </ng-container>
                                    </div>
                                </h5>
                            </td>
                        </tr>

                        <!--  *ngIf="questioValue?.value !== null" -->
                        <ng-container *ngFor="let questioValue of item?.sections">
                            <ng-container *ngFor="let answersValue of questioValue?.answers">
                                <ng-container *ngFor="let actualValue of answersValue?.actualValue">
                                    <tr *ngIf="actualValue?.answer">
                                        <!-- <td></td> -->
                                        <td style="white-space: break-spaces;">{{actualValue?.question}}</td>
                                        <td>{{actualValue?.answer === null ? '--' : actualValue?.answer}}</td>
                                    </tr>
                                </ng-container>

                            </ng-container>
                        </ng-container>

                        <tr *ngIf="item?.otherNotes && item?.otherNotes != ''">
                            <td colspan="2">Additional Notes: &nbsp;&nbsp;{{item?.otherNotes}}</td>
                        </tr>
                    </ng-container>
                </ng-container>

                <ng-container
                    *ngIf="!behaviourScreeningData || behaviourScreeningData?.length === 0 || !mainSectionDisplay(behaviourScreeningData)">
                    <tr>
                        <td colspan="2" style="text-align: center;">Behavioral Screening has not been documented.</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>