import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';
import { ApisService } from '../../services/apis.services';

declare var Square: any;

@Component({
  selector: 'app-payments-common-new',
  templateUrl: './payments-common-new.component.html',
  styleUrls: ['./payments-common-new.component.scss']
})
export class PaymentsCommonNewComponent implements OnInit {

  @ViewChild('paymentsRefNewModal') paymentsRefNewModal: UiModalComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  public paymentServices = AppConstantsListConfig?.paymentServices || [];
  // private APPLICATION_ID = environment?.pfSquare?.applicationId;
  // private LOCATION_ID = environment?.pfSquare?.locationId;
  public enteredAmount: number;
  public totalAmount: number;
  public displayPaymentSection: boolean = false;
  public displayCreditCardSection: boolean = false;
  public displayDigitalWalletsSection: boolean = false;
  public displayACHPaymentSection: boolean = false;
  public title: string = 'Payments';

  // @ViewChild('paymentModal') paymentsRefNewModal: UiModalComponent;
  public loadSpinner: boolean;
  public selectedPayAppointment: any;
  public paymentType = ['POS', 'Non POS', 'Cash', 'Check', 'Online'];
  public arInsurancePaymentType = ['Cash', 'Check', 'Online'];
  public paymentMode = [
    { desc: 'Credit Card Payment', value: 'Credit Card Payment' },
    { desc: 'ACH Payment', value: 'ACH Payment (Pay with Bank Account)' },
    { desc: 'Digital wallets', value: 'Digital wallets' }
  ];
  public selectedPaymentType: string;
  public selectedPaymentMode: string;
  public amountForm: FormGroup;
  public subscription: Subscription;
  @ViewChild('paySuccessModal') paySuccessModal: UiModalComponent;
  successPayDetails: any;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public showPOSCancel: boolean;
  public posPaymentObj: any;
  public enableNonPosCancelPayment: boolean;
  @Input() private paymentConfigDetails: any;
  private APPLICATION_ID: string;
  private LOCATION_ID: string;
  cardPaymentObj: any;
  public cardPaymentSubscription: Subscription;

  public servicesForm: FormGroup;
  public totalMinimumAmountError: boolean = false;
  public patientResponsibilityResponse: any;
  public totalCredits: number = 0;
  public selectedPastVisitCreditsTotal: number = 0;
  @Output() patientRespCreditsUpdate = new EventEmitter<boolean>();
  public paymentServicesForm: FormArray;
  public newPaymentServicesForm: FormGroup;
  public partialPaymentsForm: FormGroup;
  public totalVisitChargesForm: FormGroup;
  public patientResponsibilityForm: FormGroup;
  public pastVisitPendingPayments: FormArray;
  public paymentTypeSelectionForm: FormGroup;
  @ViewChild(WizardComponent) public paymentWizard: WizardComponent;
  public isAccountBalancePayments: boolean;
  public isSelectedServiceIsPresent: string;
  totalChargesPayingCheck: string;
  public allArTypes = ['AR Patient', 'AR Insurance'];
  public allClaimProcessorTypes = ['Primary', 'Secondary', 'Tertiary'];
  public loginDetails: any;
  public facilityId: string = '';
  @Input() pageName: null;
  @Input() totalAmountPaid: any;
  // @Input() masterPatientId: any;
  @Input() selectedplanMode: any;
  @Output() helathpassPayment = new EventEmitter();
  public isHealthpassReadinly: boolean = false;
  public selectedUserInfoId: string;
  public selectedType: string;
  isArInsuranceSelected: boolean;
  showArInsuranceMessage: boolean;
  //  @ViewChild('planInvoiceModal') planInvoiceModal: UiModalComponent;
  @Input() accountLevelPay: boolean;
  @Input() accoutLevelUserInfoId: string;
  totalPaymentPaidCalc: any;
  public switchArPatientInsurancePayForm: FormGroup;
  allActiveEncounters: any;
  public arInsuranceFormGroup: FormGroup;
  @Output() receiptUrlShown = new EventEmitter<any>();
  @Output() checklistPayment = new EventEmitter();
  public allAppointments = [];
  public pastVisitAppointmentIds = [];
  @Input() recieptID: string;
  public totalCreditsPaidCalc: number = 0;
  public terminalTypes: any[] = [];
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  @Output() patientRespCreditsUpdateForFuterApp = new EventEmitter<any>();
  public selectedAppointmentDetails:any;
  @Input() userInfoData: any;

  constructor(public httpService: HttpService, private formBuilder: FormBuilder, private router: Router, private apiService: ApisService) {
    this.amountForm = formBuilder?.group({
      'amount': [null, Validators.required]
    });

    this.servicesForm = this.formBuilder?.group({
      'coPayAmount': [{ value: null, disabled: true }],
      'selectCoPayAmount': [null],
      'inHouseLabsAmount': [{ value: null, disabled: true }],
      'selectInHouseLabsAmount': [null],
      'consultationAmount': [{ value: null, disabled: true }],
      'selectConsultationAmount': [null],
      'externalLabsAmount': [{ value: null, disabled: true }],
      'selectExternalLabsAmount': [null],
      'otherAmount': [{ value: null, disabled: true }],
      'selectOtherAmount': [null],
      'amountTobePaid': [null],
      'pastVisitCredits': [null],
      'selectpastVisitCredits': [null],
      'isPartialPayment': [null]
    });

    this.newPaymentServicesForm = this.formBuilder?.group({
      'paymentServicesForm': this.formBuilder?.array([this.paymentServicesGroup()])
    });

    this.partialPaymentsForm = this.formBuilder?.group({
      'isPartialPayment': [null],
      'partialAmount': [null],
      'remainingChargesDues': [null]
    });

    this.totalVisitChargesForm = this.formBuilder?.group({
      'totalVisitCharges': [null, Validators?.required]
    });

    this.patientResponsibilityForm = this.formBuilder?.group({
      'pastVisitPendingPayments': this.formBuilder?.array([])
    });

    this.paymentTypeSelectionForm = this.formBuilder?.group({
      'paymentType': [null, Validators?.required],
      'terminalType': [null]
    });

    this.switchArPatientInsurancePayForm = this.formBuilder?.group({
      'switchCheck': [null],
      'claimProcessorType': [null]
    });

    this.arInsuranceFormGroup = this.formBuilder?.group({
      'arInsuranceTypes': this.formBuilder?.array([])
    });

  }

  ngOnInit(): void {
    // this.cardPayment();
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.facilityId = this.loginDetails.facilityId;
    this.selectedUserInfoId = null;
    this.selectedType = null;
  }

  // AR Insurance Form Group
  public arInsuranceGroup(): FormGroup {
    return this.formBuilder?.group({
      'appointmentId': [null, Validators?.required],
      'claimProcessorType': [null, Validators?.required],
      'amount': [null, Validators?.required]
    });
  }

  public addArInsurance(): void {
    const arInsuArray = this.arInsuranceFormGroup?.get('arInsuranceTypes') as FormArray;
    arInsuArray?.push(this.arInsuranceGroup());
  }

  public deleteArInsurance(index: number): void {
    const arInsuArray = this.arInsuranceFormGroup?.get('arInsuranceTypes') as FormArray;
    arInsuArray?.removeAt(index);
  }

  public openPaymentsModal(): void {
    this.paymentsRefNewModal?.show();
  }

  // open modal from flow board
  public openModal(appointment: any, isPatientResp?: boolean): void {
    if (this.pageName === 'Healthpass') {
      //this.selectedUserInfoId = this.masterPatientId;
      this.selectedUserInfoId = sessionStorage.getItem('masterPatientId');
    }
    else if (!this.accountLevelPay) {
      this.selectedUserInfoId = this.selectedPayAppointment?.userInfoId;
      this.paymentServices = this.paymentServices.filter(item => item !== 'Healthpass');
      if (this.pageName !== 'FlowBoard') {
      this.deletePaymentServices(0);
      }
    }else {
      if (this.pageName !== 'FlowBoard') {
        this.deletePaymentServices(0);
        }
    }

    this.selectedPayAppointment = appointment;
    this.isAccountBalancePayments = isPatientResp;
    this.servicesForm?.reset();
    this.amountForm?.reset();
    this.paymentsRefNewModal?.show();
    if (!this.accountLevelPay) {
      this.getPatientResponsibility(this.selectedPayAppointment?.userInfoId);
    }
    //this.getPatientResponsibility(this.selectedUserInfoId);

    if (!this.isAccountBalancePayments) {
      const accountArray = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
      accountArray?.controls.forEach((item: FormGroup) => {
        item?.get('serviceType')?.setValidators([Validators?.required]);
        item?.get('amount')?.setValidators([Validators?.required]);
        item?.get('serviceType')?.updateValueAndValidity();
        item?.get('amount')?.updateValueAndValidity();
      });
    }

    if (this.pageName === 'Healthpass') {
      //alert(this.masterPatientId);
      this.selectedType = 'Healthpass';
      this.isHealthpassReadinly = true;
      const accountArray = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
      accountArray?.controls.forEach((item: FormGroup) => {
        item?.get('serviceType')?.setValue('Healthpass');
        item?.get('amount')?.setValue(this.totalAmountPaid);

      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.APPLICATION_ID = this.paymentConfigDetails?.clientId;
      this.LOCATION_ID = this.paymentConfigDetails?.locationId;
      this.terminalTypes = this.paymentConfigDetails?.terminals || [];
      if (this.paymentConfigDetails?.terminals && this.paymentConfigDetails?.terminals?.length === 1) {
        this.paymentTypeSelectionForm?.get('terminalType')?.setValue(this.paymentConfigDetails?.terminals[0] || null);
      }
      // If Account Level Adhoc Payment
      if (this.accountLevelPay && this.accoutLevelUserInfoId) {
        this.paymentServices = ['Healthpass', 'Other'];
      }
    }
  }

  // payment services group
  public paymentServicesGroup(service?: any): FormGroup {
    return this.formBuilder?.group({
      'serviceType': [service?.serviceType || null],
      'amount': [service?.amount || null],
      'selfPay': [null]
    });
  }

  // add Payment services
  public addPaymentServices(): void {
    this.paymentServicesForm = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
    this.paymentServicesForm?.push(this.paymentServicesGroup());
    if (!this.isAccountBalancePayments) {
      this.paymentServicesForm?.controls.forEach((item: FormGroup) => {
        item?.get('serviceType')?.setValidators([Validators?.required]);
        item?.get('amount')?.setValidators([Validators?.required]);
        item?.get('serviceType')?.updateValueAndValidity();
        item?.get('amount')?.updateValueAndValidity();
      });
    }
  }

  // delete payment services
  public deletePaymentServices(index?: number): void {
    this.paymentServicesForm = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
    this.paymentServicesForm?.removeAt(index);
  }

  // Create Patient Responsibility Form Array
  public patientRespForm(patientRespForm?: any): FormGroup {
    return this.formBuilder?.group({
      'appointmentId': [patientRespForm?.appointmentId || null],
      'credits': [patientRespForm?.credits || null],
      'remainingCredits': [patientRespForm?.remainingCredits || null],
      'paymentAmount': [patientRespForm?.paymentAmount || null],
      'reimburseAmount': [patientRespForm?.reimburseAmount || null],
      'serviceDate': [patientRespForm?.serviceDate || null],
      'serviceDescription': [patientRespForm?.serviceDescription || null],
      'payingAmount': [patientRespForm?.paymentAmount || null],
      'isPartialPaymentCheck': [true],
      'claimProcessorType': [patientRespForm?.claimProcessorType || null],
      'arType': [(patientRespForm?.paymentAmount ? 'AR Patient' : null) || null]
    });
  }

  public changePayType(event: any): void {
    if (this.terminalTypes?.length > 1 && event === "POS") {
      this.paymentTypeSelectionForm?.get('terminalType')?.setValidators([Validators?.required]);
    } else {
      this.paymentTypeSelectionForm?.get('terminalType')?.setValidators(null);
    }
    this.paymentTypeSelectionForm?.get('terminalType')?.updateValueAndValidity();
    // this.amountForm?.get('amount')?.setValue(null);
  }

  public changePaymentMode(): void {
    if (this.selectedPaymentType === 'noTerminal' && this.selectedPaymentMode === 'Credit Card Payment') {
      this.cardPayment();
    }
    if (this.selectedPaymentMode === 'Credit Card Payment') {
      // this.enableCardPay();
    } else if (this.selectedPaymentMode === 'ACH Payment (Pay with Bank Account)') {
      this.achPayOpen();
    }
    if (this.selectedPaymentMode === 'Digital wallets') {
      this.applePayOpen();
      this.googlePayOpen();
    }
  }

  // check the partial payment
  public checkPartialPayment(event: any): void {
    if (event.target.checked) {
      this.servicesForm?.get('amountTobePaid')?.setValidators([Validators?.required]);
    } else {
      this.servicesForm?.get('amountTobePaid')?.setValidators(null);
    }
    this.servicesForm?.get('amountTobePaid')?.updateValueAndValidity();
  }

  // Make Amount to paid
  public makePayment(): void {
    this.totalAmount = (this.servicesForm?.get('coPayAmount')?.value + this.servicesForm?.get('inHouseLabsAmount')?.value + this.servicesForm?.get('consultationAmount')?.value + this.servicesForm?.get('externalLabsAmount')?.value + this.servicesForm?.get('otherAmount')?.value) - this.selectedPastVisitCreditsTotal;

    if (!this.servicesForm?.get('isPartialPayment')?.value) {
      this.servicesForm?.get('amountTobePaid')?.setValue(this.totalAmount)
    }

    if (this.servicesForm?.get('amountTobePaid')?.invalid || (this.servicesForm?.get('amountTobePaid')?.value > this.getTotalAmount())) {
      this.servicesForm?.get('amountTobePaid')?.markAllAsTouched();
      return;
    }

    if (this.selectedPaymentType === 'cashPayment' || this.selectedPaymentType === 'terminal' || this.selectedPaymentType === 'noTerminal' || this.selectedPaymentType === 'chequePayment' || this.selectedPaymentType === 'onlinePayment') {
      this.amountForm?.get('amount')?.setValue(this.servicesForm?.get('amountTobePaid')?.value || this.totalAmount);
    }

    if (!this.amountForm?.get('amount')?.value || this.amountForm?.get('amount')?.value === 0) {
      this.totalMinimumAmountError = true;
      return;
    }

    this.totalMinimumAmountError = false;
    this.displayPaymentSection = true;
    this.title = 'Make Payments';
  }

  public enableCardPay(): void {
    this.selectedPaymentType = null;
    this.showPOSCancel = false;
    this.enableNonPosCancelPayment = false;
    // this.cardPayment();
  }

  // trigget payment with ach payment
  public achPayOpen(): void {
    this.achPayment();
  }

  // trigger apple payment
  public applePayOpen(): void {
    this.applePay();
  }

  // trigger google payment
  public googlePayOpen(): void {
    this.googlePay();
  }

  // close modal
  public closeModal(): void {
    this.title = 'Payments';
    this.loadSpinner = false;
    this.amountForm?.reset();
    this.displayPaymentSection = false;
    this.servicesForm?.reset();
    this.selectedPaymentType = null;
    this.servicesForm?.get('coPayAmount')?.disable();
    this.servicesForm?.get('inHouseLabsAmount')?.disable();
    this.servicesForm?.get('consultationAmount')?.disable();
    this.servicesForm?.get('externalLabsAmount')?.disable();
    this.servicesForm?.get('otherAmount')?.disable();
    this.selectedPaymentMode = null;
    this.totalMinimumAmountError = false;
    this.paymentsRefNewModal?.hide();
    this.selectedPastVisitCreditsTotal = 0;

    this.newPaymentServicesForm?.reset();
    this.partialPaymentsForm?.reset();
    this.paymentTypeSelectionForm?.reset();
    this.totalVisitChargesForm?.reset();
    this.patientResponsibilityForm?.reset();
    this.paymentServicesForm = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
    this.paymentServicesForm?.controls.forEach((item: FormGroup) => {
      item?.get('serviceType')?.setValidators(null);
      item?.get('amount')?.setValidators(null);
      item?.get('serviceType')?.updateValueAndValidity();
      item?.get('amount')?.updateValueAndValidity();
    });
    this.httpService?.clearFormArray(this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray);
    this.httpService?.clearFormArray(this.patientResponsibilityForm?.get('pastVisitPendingPayments') as FormArray);
    this.paymentWizard?.reset();
    this.addPaymentServices();
    this.partialPaymentsForm?.get('partialAmount')?.clearValidators();
    this.partialPaymentsForm?.get('partialAmount')?.updateValueAndValidity();
    this.isArInsuranceSelected = false;
    this.showArInsuranceMessage = false;
    this.switchArPatientInsurancePayForm?.reset();
    // this.checkboxes.forEach((element) => {
    //   element.nativeElement.checked = false;
    // });
  }

  // card payment
  private async cardPayment() {
    this.loadSpinner = true;
    await new Promise(resolve => setTimeout(resolve, 2000));
    const containerEle = document?.getElementById('card-container');
    if (containerEle?.innerHTML) {
      containerEle.innerHTML = '';
    }
    const payments = Square?.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const card = await payments?.card();
    await card?.attach('#card-container');

    async function eventHandler(event) {
      event.preventDefault();
      try {
        this.loadSpinner = true;
        const result = await card?.tokenize();
        if (result?.status === 'OK') {
          this.createPayment(result?.token);
        }
        if (result?.status === 'Invalid') {
          this.loadSpinner = false;
        }
      } catch (e) {
        this.loadSpinner = false;
      }
    };
    const cardButton = document?.getElementById('card-button');
    cardButton?.addEventListener('click', eventHandler.bind(this));
    this.loadSpinner = false;
  }

  // ACH Payment
  public async achPayment() {
    this.loadSpinner = true;
    await new Promise(resolve => setTimeout(resolve, 1000));
    const payments = Square?.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const ach = await payments?.ach();
    async function eventHandler(event) {
      event?.preventDefault();
      try {
        this.loadSpinner = true;
        const result = await ach?.tokenize({
          accountHolderName: 'John Doe',
        });
        if (result?.status === 'OK') {
          this.createPayment(result?.token);
        }
      } catch (e) {
        this.loadSpinner = false;
      }
    };
    const achButton = document?.getElementById('ach-button');
    achButton?.addEventListener('click', eventHandler?.bind(this));
    this.loadSpinner = false;
  }


  // Digital Wallets - Apple Pay
  private async applePay() {
    const totalPayment = (this.grandTotalCharges() || 0);
    const amount = totalPayment?.toFixed(2);
    await new Promise(resolve => setTimeout(resolve, 2000));
    const payments = Square.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const paymentRequest = payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: amount,
        label: 'Total',
      },
    });

    const applePay = await payments.applePay(paymentRequest);
    async function eventHandler(event) {
      event.preventDefault();
      try {
        // this.loadSpinner = true;
        const result = await applePay.tokenize();
        if (result.status === 'OK') {
          this.createPayment(result?.token);
        }
      } catch (e) {
        this.loadSpinner = false;
      }
    };
    const applePayButtonTarget = document.getElementById('apple-pay-button');
    applePayButtonTarget.addEventListener('click', eventHandler?.bind(this));
  }


  // Digital Wallets - Google Pay
  private async googlePay() {
    this.loadSpinner = true;
    await new Promise(resolve => setTimeout(resolve, 2000));
    const containerEle = document?.getElementById('google-pay-button');
    if (containerEle?.innerHTML) {
      containerEle.innerHTML = '';
    }
    const totalPayment = (this.grandTotalCharges() || 0);
    const amount = totalPayment?.toFixed(2);
    await new Promise(resolve => setTimeout(resolve, 2000));
    this.loadSpinner = false;
    const payments = Square.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const paymentRequest = payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: amount,
        label: 'Total',
      },
    });
    this.loadSpinner = false;
    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach('#google-pay-button');
    async function eventHandler(event) {
      event.preventDefault();
      try {
        // this.loadSpinner = true;
        const result = await googlePay.tokenize();
        if (result.status === 'OK') {
          this.loadSpinner = false;
          this.createPayment(result?.token);
        }
      } catch (e) {
        this.loadSpinner = false;
      }
    };
    const googlePayButtonTarget = document.getElementById('google-pay-button');
    googlePayButtonTarget.addEventListener('click', eventHandler.bind(this));
  }

  // Create Card Payment
  private async createPayment(paymentToken: string) {
    const action = 'payment/createPayment';
    const totalPayment = (this.grandTotalCharges() || 0);
    const amount = (totalPayment)?.toFixed(2)?.split('.')?.join('');
    let params = null;
    if (this.pageName === 'Healthpass') {
      params = {
        "serviceCharges": this.serviceChargesRequest() || [],
        "modeOfPay": "NON_POS",
        "amount": parseInt(amount),
        "sourceId": paymentToken,
        "facilityId": this.facilityId,
        "type": this.selectedType,
        "userInfoId": this.selectedUserInfoId,
      }
    }
    else {
      params = {
        "serviceCharges": this.serviceChargesRequest() || [],
        "modeOfPay": "NON_POS",
        "amount": parseInt(amount),
        "appointmentId": this.selectedPayAppointment?.id,
        "sourceId": paymentToken,
        "facilityId": this.facilityId
      }
    }

    // If Account Level Adhoc Payment
    if (this.accountLevelPay) {
      params.userInfoId = this.accoutLevelUserInfoId
    }

    this.loadSpinner = true;
    this.updatepastvisitPayments();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject?.status === 'APPROVED') {
          this.enableNonPosCancelPayment = true;
          this.cardPaymentObj = data?.body?.responseObject;
          const paymentId = data?.body?.responseObject?.paymentId;
          const cardPaymentSource = interval(15000);
          this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
        }
        if (data?.body?.responseObject?.status === 'PENDING') {
          this.enableNonPosCancelPayment = true;
          this.cardPaymentObj = data?.body?.responseObject;
          const paymentId = data?.body?.responseObject?.paymentId;
          const cardPaymentSource = interval(15000);
          this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
        }
        if (data?.body?.responseObject?.status === 'COMPLETED') {
          if (((this.patientResponsibilityResponse?.totalRemainingCredits > 0 || (this.patientResponsibilityResponse?.patientRespNCredits && this.patientResponsibilityResponse?.patientRespNCredits?.length > 0)) && (this.partialPaymentsForm?.get('partialAmount')?.value < this.totalChargesCount()))) {

          }
          this.successPayDetails = data?.body?.responseObject;
          if (this.pageName !== 'Healthpass' && !this.accountLevelPay && this.isPastVisitsPr()) {
            this.updateSelectedCredits(this.successPayDetails, "NON_POS");

          } //not reqired to call this in case of healthpass
          this.title = 'Payments';
          this.cardPaymentSubscription?.unsubscribe();
          this.closeModal();
          if (this.pageName === 'Healthpass') {
            this.updateHealthpassPayment();
          }
          //this.getAppointmentCost(this.selectedPayAppointment?.id);


          this.paySuccessModal?.show();
        }
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // get the CREDIT CARD PAYMENT STATUS
  public getCardPaymentStatus(paymentId: string): void {
    const action = `payment/getPayment/${paymentId}`;
    // this.loadSpinner = true;
    this.successPayDetails = null;
    this.updatepastvisitPayments();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        // this.getAppointmentCost(this.selectedPayAppointment?.id);

        if (data?.responseObject?.status === 'COMPLETED') {
          if (((this.patientResponsibilityResponse?.totalRemainingCredits > 0 || (this.patientResponsibilityResponse?.patientRespNCredits && this.patientResponsibilityResponse?.patientRespNCredits?.length > 0)) && (this.partialPaymentsForm?.get('partialAmount')?.value < this.totalChargesCount()))) {

          }
          this.successPayDetails = data?.responseObject;
          if (this.pageName !== 'Healthpass' && !this.accountLevelPay && this.isPastVisitsPr()) {
            this.updateSelectedCredits(this.successPayDetails, "NON_POS");


          } //not reqired to call this in case of healthpass

          this.cardPaymentSubscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          if (this.pageName === 'Healthpass') { this.updateHealthpassPayment(); }
          this.paySuccessModal?.show();
        }
        if (data?.responseObject?.status === 'CANCELED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment request is cancelled.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
        if (data?.responseObject?.status === 'FAILED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment is Failed.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }


      }
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.cardPaymentSubscription?.unsubscribe();
        this.loadSpinner = false;
      });
  }

  // create POS payment - Terminal Payment
  public createPosPayment(): void {
    if (this.paymentTypeSelectionForm?.get('terminalType')?.invalid) {
      this.paymentTypeSelectionForm?.markAllAsTouched();
      this.loadSpinner = false;
      return;
    }
    const action = 'payment/createTerminalPayment';
    const totalPayment = (this.grandTotalCharges() || 0)
    const amount = totalPayment?.toFixed(2)?.split('.')?.join('');
    let params = null;
    if (this.pageName === 'Healthpass') {
      params = {
        "serviceCharges": this.serviceChargesRequest() || [],
        "amount": parseInt(amount),
        "modeOfPay": "POS",
        "sourceId": "",
        "facilityId": this.facilityId,
        "type": this.selectedType,
        "userInfoId": this.selectedUserInfoId,
        "terminal": this.paymentTypeSelectionForm?.get('terminalType')?.value,
      }
    }
    else {
      params = {
        "serviceCharges": this.serviceChargesRequest() || [],
        "amount": parseInt(amount),
        "modeOfPay": "POS",
        "appointmentId": this.selectedPayAppointment?.id,
        "sourceId": "",
        "facilityId": this.facilityId,
        "terminal": this.paymentTypeSelectionForm?.get('terminalType')?.value,
      }
    }

    // If Account Level Adhoc Payment
    if (this.accountLevelPay) {
      params.userInfoId = this.accoutLevelUserInfoId
    }
    // console.log('Yes..! POS Payment is ready to take.');
    // console.log(params);
    // return;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject?.status === 'PENDING') {
          this.posPaymentObj = data?.body?.responseObject;
          this.showPOSCancel = true;
          const checkoutId = data?.body?.responseObject?.paymentId;
          const source = interval(15000);
          this.subscription = source.subscribe(val => { this.getPosPaymentStatus(checkoutId) });

          /** New logic added by Yeshwanth on 11 apr 2022 */
          // this.amountForm?.reset(); 
          // this.closeModal();
          // this.successPayDetails = data?.responseObject;
          // this.paySuccessModal?.show();
          /**Logic End */

        }
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      }
      // this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // create POS payment - Terminal Payment
  public createCashPayment(): void {
    // if (this.amountForm?.invalid) {
    //   this.amountForm?.markAllAsTouched();
    //   this.loadSpinner = false;
    //   return;
    // }
    const action = 'payment/createCashPayment';
    let modeOfPay: string;
    if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash') {
      modeOfPay = "CASH";
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Check') {
      modeOfPay = 'CHECK'
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Online') {
      modeOfPay = 'ONLINE'
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Paper Work') {
      modeOfPay = 'PAPER_WORK'
    }

    const totalPayment = (this.grandTotalCharges() || 0);
    const amount = (totalPayment)?.toFixed(2)?.split('.')?.join('');

    let params = null;
    if (this.pageName === 'Healthpass') {
      params = {
        "serviceCharges": this.serviceChargesRequest() || [],
        "modeOfPay": modeOfPay,
        "amount": parseInt(amount),
        "sourceId": "",
        "facilityId": this.facilityId,
        "type": this.selectedType,
        "userInfoId": this.selectedUserInfoId,
      }
    }
    else {
      params = {
        "serviceCharges": this.serviceChargesRequest() || [],
        "modeOfPay": modeOfPay,
        "amount": parseInt(amount),
        "appointmentId": this.selectedPayAppointment?.id,
        "sourceId": "",
        "facilityId": this.facilityId

      }
    }

    // If Account Level Adhoc Payment
    if (this.accountLevelPay) {
      params.userInfoId = this.accoutLevelUserInfoId
    }

    this.loadSpinner = true;
    this.updatepastvisitPayments();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        // this.getAppointmentCost(this.selectedPayAppointment?.id);

        if (data?.body?.responseObject?.status === 'COMPLETED') {
          const checkoutId = data?.body?.responseObject?.paymentId;
          if (((this.patientResponsibilityResponse?.totalRemainingCredits > 0 || (this.patientResponsibilityResponse?.patientRespNCredits && this.patientResponsibilityResponse?.patientRespNCredits?.length > 0)) && (this.partialPaymentsForm?.get('partialAmount')?.value < this.totalChargesCount()))) {

          }
          this.successPayDetails = data?.body?.responseObject;

          if (this.pageName !== 'Healthpass' && !this.accountLevelPay && this.isPastVisitsPr()) {
            this.updateSelectedCredits(this.successPayDetails, modeOfPay);

          } //not reqired to call this in case of healthpass
          this.servicesForm?.reset();
          this.amountForm?.reset();
          this.closeModal();
          if (this.pageName === 'Healthpass') { this.updateHealthpassPayment(); }
          this.paySuccessModal?.show();
        }
      } else {
        this.closeModal();
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // make service charges payload
  public serviceChargesRequest(): any[] {
    let allServices: any[] = JSON.parse(JSON?.stringify(this.newPaymentServicesForm?.get('paymentServicesForm')?.value || [])) || [];
    // if (this.totalCredits && this.totalCredits > 0) {
    //   let amountPaying: any;
    //   if (this.grandTotalCharges() < 0) {
    //     amountPaying = this.grandTotalCharges() < 0 ? this.grandTotalCharges() * -1 : this.grandTotalCharges();
    //   }
    //   const charge = {
    //     "amount": amountPaying ? this.totalCredits - amountPaying : this.totalCredits,
    //     "serviceType": 'Past Visit Credits Applied'
    //   };
    //   allServices?.push(charge);
    // }
    const filterNullServices = allServices?.filter(item => item?.serviceType !== null && item?.amount !== null) || [];
    if (allServices?.length === 0 || filterNullServices?.length === 0) {
      // const charge = {
      //   "amount": null,
      //   "serviceType": null
      // };
      // allServices?.push(charge);
      allServices = [];
    }

    let pendingPatientResp: any;
    if (this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value < this.totalCharges()) {
      pendingPatientResp = this.totalCharges() - this.partialPaymentsForm?.get('partialAmount')?.value;
    }

    let checkAllPastVisits = JSON.parse(JSON.stringify(this.patientResponsibilityForm?.get('pastVisitPendingPayments')?.value || []));
    checkAllPastVisits = checkAllPastVisits?.filter((item: any) => item?.isPartialPaymentCheck);
    this.totalPaymentPaidCalc = this.grandTotalCharges();

    let pastVisistCharges = 0;
    checkAllPastVisits?.forEach((pastVisitItem: any) => {
      //console.log(pastVisitItem);
      // if (this.selectedPayAppointment?.id === pastVisitItem?.appointmentId) {
      const payCheckAmount = this.calculatPastVisitsPaymentAmount(this.totalPaymentPaidCalc, pastVisitItem?.paymentAmount, pendingPatientResp);
      this.totalPaymentPaidCalc = this.totalPaymentPaidCalc < pastVisitItem?.paymentAmount ? 0 : this.totalPaymentPaidCalc - payCheckAmount;
      pastVisitItem.paymentAmount = payCheckAmount;
      pastVisistCharges = pastVisistCharges + pastVisitItem?.paymentAmount;
      // };
    });

    let amount: number;
    if (this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value) {
      amount = this.partialPaymentsForm?.get('partialAmount')?.value || 0;
    } else {
      amount = (this.getTotalVisitCharges() + this.totalPatientRespCharges()) || 0;
    }

    // if(checkAllPastVisits && checkAllPastVisits?.length === 1) {
    //   const charge = {
    //     "amount": amount - this.calculateServiceItemizedPayment(allServices),
    //     "serviceType": 'Past Visit Charges'
    //   };
    //   allServices?.push(charge);
    // }

    if (checkAllPastVisits && checkAllPastVisits?.length > 0 && this.pageName !== 'Healthpass') {
      let checkPartial: any;
      if (this.partialPaymentsForm?.get('isPartialPayment')?.value) {
        checkPartial = this.partialPaymentsForm?.get('partialAmount')?.value || 0;
      }
      const charge = {
        "amount": this.totalPatientRespCharges(),
        // "amount": this.grandTotalCharges() < 0 ? amount : this.grandTotalCharges(),
        // "serviceType": this.isAccountBalancePayments ? 'Past Visit Charges': 'Co-Pay'
        "serviceType": 'Past Visit Charges'
      };
      if (this.totalPatientRespCharges()) {
        allServices?.push(charge);
      }
      // allServices = [charge];
    }

    if (this.totalCredits && this.totalCredits > 0 && this.pageName !== 'Healthpass') {
      let amountPaying: any;
      if (this.grandTotalCharges() < 0) {
        amountPaying = this.grandTotalCharges() < 0 ? this.grandTotalCharges() * -1 : this.grandTotalCharges();
      }
      const charge = {
        "amount": amountPaying ? this.totalCredits - amountPaying : this.totalCredits,
        "serviceType": 'Past Visit Credits Applied'
      };
      allServices?.push(charge);
    }

    return allServices || [];
  }

  public calculateServiceItemizedPayment(services: any): any {
    let sum: number;
    if (services?.controls?.length > 0) {
      sum = services?.map(item => item?.amount || 0)?.reduce((prev, curr) => prev + curr);
    }
    return sum || 0;
  }

  // get the status of an terminal pos payment
  public getPosPaymentStatus(checkoutId: string): void {
    const action = `payment/getTerminalPayment/${checkoutId}`;
    // this.loadSpinner = true;
    this.successPayDetails = null;
    this.updatepastvisitPayments();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        //this.getAppointmentCost(this.selectedPayAppointment?.id);

        if (data?.responseObject?.status === 'COMPLETED') {
          if (((this.patientResponsibilityResponse?.totalRemainingCredits > 0 || (this.patientResponsibilityResponse?.patientRespNCredits && this.patientResponsibilityResponse?.patientRespNCredits?.length > 0)) && (this.partialPaymentsForm?.get('partialAmount')?.value < this.totalChargesCount()))) {

          }
          this.successPayDetails = data?.responseObject;

          if (this.pageName !== 'Healthpass' && !this.accountLevelPay && this.isPastVisitsPr()) {
            this.updateSelectedCredits(this.successPayDetails, "POS");


          } //not reqired to call this in case of healthpass
          this.subscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          if (this.pageName === 'Healthpass') { this.updateHealthpassPayment(); }
          this.paySuccessModal?.show();
        }
        if (data?.responseObject?.status === 'CANCELED') {
          this.subscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment request is cancelled.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
        if (data?.responseObject?.status === 'CANCEL_REQUESTED') {
          this.subscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment is requested for cancel.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
      }
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.subscription?.unsubscribe();
        this.loadSpinner = false;
      });
  }

  // cancel POS payment
  public cancelPosPayment(): void {
    const action = `payment/cancelTerminalPayment/${this.posPaymentObj?.paymentId}`;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
      this.subscription.unsubscribe();
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        this.subscription.unsubscribe();
      });
  }

  // Cancel Non POS Payments
  public cancelPayment(): void {
    const action = `payment/cancelPayment/${this.cardPaymentObj?.paymentId}`;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
      this.cardPaymentSubscription?.unsubscribe();
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  public servicesChange(serviceName: string): void {
    const isChecked = this.servicesForm?.get(serviceName)?.value || null;
    this.servicesForm?.get('amountTobePaid')?.setValue(null);
    switch (serviceName) {
      case 'selectCoPayAmount':
        if (isChecked) {
          this.servicesForm?.get('coPayAmount')?.enable();
        } else {
          this.servicesForm?.get('coPayAmount')?.reset();
          this.servicesForm?.get('coPayAmount')?.disable();
        }
        break;
      case 'selectInHouseLabsAmount':
        if (isChecked) {
          this.servicesForm?.get('inHouseLabsAmount')?.enable();
        } else {
          this.servicesForm?.get('inHouseLabsAmount')?.reset();
          this.servicesForm?.get('inHouseLabsAmount')?.disable();
        }
        break;
      case 'selectConsultationAmount':
        if (isChecked) {
          this.servicesForm?.get('consultationAmount')?.enable();
        } else {
          this.servicesForm?.get('consultationAmount')?.reset();
          this.servicesForm?.get('consultationAmount')?.disable();
        }
        break;
      case 'selectExternalLabsAmount':
        if (isChecked) {
          this.servicesForm?.get('externalLabsAmount')?.enable();
        } else {
          this.servicesForm?.get('externalLabsAmount')?.reset();
          this.servicesForm?.get('externalLabsAmount')?.disable();
        }
        break;
      case 'selectOtherAmount':
        if (isChecked) {
          this.servicesForm?.get('otherAmount')?.enable();
        } else {
          this.servicesForm?.get('otherAmount')?.reset();
          this.servicesForm?.get('otherAmount')?.disable();
        }
    }
  }

  private servicesChargesRequestPayload(): any {
    const servicesCharges = [];
    if (this.servicesForm?.get('coPayAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('coPayAmount')?.value,
        "serviceType": 'Co-Pay'
      };
      servicesCharges?.push(charge);
    }
    if (this.servicesForm?.get('inHouseLabsAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('inHouseLabsAmount')?.value,
        "serviceType": 'In-House Labs'
      };
      servicesCharges?.push(charge);
    }
    if (this.servicesForm?.get('consultationAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('consultationAmount')?.value,
        "serviceType": 'Consultation'
      };
      servicesCharges?.push(charge);
    }
    if (this.servicesForm?.get('externalLabsAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('externalLabsAmount')?.value,
        "serviceType": 'External Labs'
      };
      servicesCharges?.push(charge);
    }
    if (this.servicesForm?.get('otherAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('otherAmount')?.value,
        "serviceType": 'Other'
      };
      servicesCharges?.push(charge);
    }
    if (this.servicesForm?.get('pastVisitCredits')?.value && this.servicesForm?.get('selectpastVisitCredits')?.value) {
      let amountPaying: any;
      if (this.getTotalAmount() < 0) {
        amountPaying = this.getTotalAmount() < 0 ? this.getTotalAmount() * -1 : this.getTotalAmount();
      }
      const charge = {
        "amount": amountPaying ? this.servicesForm?.get('pastVisitCredits')?.value - amountPaying : this.servicesForm?.get('pastVisitCredits')?.value,
        "serviceType": 'Past Visit Credits Applied'
      };
      servicesCharges?.push(charge);
    }
    return servicesCharges || [];
  }

  // generate payment recipt
  public viewPaymentReciept(paymentId: any, payInvoiceModal: UiModalComponent): void {

    let action = null;
    if (this.pageName === 'Healthpass') {
      const serviceType = "Healthpass";
      // const userInfoId = this.masterPatientId;
      const userInfoId = this.selectedUserInfoId;
      action = `payment/generateCashReceipt?serviceType=${serviceType}&paymentId=${paymentId}&userInfoId=${userInfoId}`;
    }
    else {
      action = `payment/generateCashReceipt?appointmentId=${this.selectedPayAppointment?.id}&paymentId=${paymentId}`;
    }

    this.loadSpinner = true;
    this.httpService?.generatePDF(action, '')?.subscribe((data: any) => {
      const payFile = new Blob([data], { type: 'application/pdf' })
      const payFileUrl = URL.createObjectURL(payFile);
      // const payFileUrl = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // window.open(payFileUrl);
      payInvoiceModal?.show();
      const iframeEle = document.getElementById(this.recieptID || 'common-new-payment-reciept-embed') as HTMLElement;
      iframeEle.setAttribute('src', payFileUrl);
      this.loadSpinner = false;
      this.closeModal();
      if (this.pageName !== 'Healthpass') { this.paySuccessModal?.hide(); }
      const obj = {
        payFileUrl,
        payInvoiceModal: payInvoiceModal as UiModalComponent
      }
      this.receiptUrlShown?.emit(obj);
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        if (this.pageName !== 'Healthpass') { this.paySuccessModal?.hide(); }

      })
  };

  // change event of past visit credits
  public pastVisitCredits(event: any): void {
    this.selectedPaymentType = null;
    if (event.target.checked) {
      this.selectedPastVisitCreditsTotal = this.totalCredits;
    } else {
      this.selectedPastVisitCreditsTotal = 0;
    }
  }

  // Amount 
  public getTotalAmount(): number {
    const amount = ((this.servicesForm?.get('coPayAmount')?.value || 0) + (this.servicesForm?.get('inHouseLabsAmount')?.value || 0) + (this.servicesForm?.get('consultationAmount')?.value || 0) + (this.servicesForm?.get('externalLabsAmount')?.value || 0) + (this.servicesForm?.get('otherAmount')?.value || 0)) - this.selectedPastVisitCreditsTotal;
    return amount || 0;
  }

  public getSelectedItemizedAmount(): number {
    const amount = ((this.servicesForm?.get('coPayAmount')?.value || 0) + (this.servicesForm?.get('inHouseLabsAmount')?.value || 0) + (this.servicesForm?.get('consultationAmount')?.value || 0) + (this.servicesForm?.get('externalLabsAmount')?.value || 0) + (this.servicesForm?.get('otherAmount')?.value || 0));
    return amount || 0;
  }

  // get Patient Responsibility and Credits
  public getPatientResponsibility(userInfoId: string): void {
    if (!userInfoId) {
      return;
    }
    const action = `appointment/registration/getPatientResponsibilityNCredits?userInfoId=${userInfoId}`
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.patientResponsibilityResponse = data?.responseObject;
        this.pastVisitPendingPayments = this.patientResponsibilityForm?.get('pastVisitPendingPayments') as FormArray;
        const pendingPayments = this.patientResponsibilityResponse?.patientRespNCredits || [];
        pendingPayments?.forEach((item: any) => {
          this.pastVisitPendingPayments?.push(this.patientRespForm(item));
        });
        // this.totalCredits = data?.responseObject?.totalCredits; // old - earlier its was total credits now introduced the remianing credits
        this.totalCredits = data?.responseObject?.totalRemainingCredits;
        // if(this.totalCredits > 0) {
        //   const obj = {
        //     serviceType: 'Credit',
        //     amount: this.totalCredits
        //   }
        //   this.paymentServicesForm = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
        //   this.httpService?.clearFormArray(this.paymentServicesForm);
        //   this.paymentServicesForm?.push(this.paymentServicesGroup(obj));
        // }
        this.servicesForm?.get('pastVisitCredits')?.setValue(this.totalCredits || null);
        if(!this.selectedPayAppointment?.id){
          this.selectedPayAppointment.id = pendingPayments?.length > 0  ? pendingPayments[0]?.appointmentId:null;
          this.selectedAppointmentDetails = JSON.parse(JSON.stringify(this.selectedPayAppointment));
        }
      } else {
        this.closeModal();
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // Update IF Credits have selected
  public updateSelectedCredits(successPayDetails: any, modeOfPay: string): void {
    const action = 'payment/updatePatientResponsibility';
    let pendingPatientResp: any;
    if (this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value < this.totalCharges()) {
      pendingPatientResp = this.totalCharges() - this.partialPaymentsForm?.get('partialAmount')?.value;
    }

    let totalCredits: any;
    if (this.grandTotalCharges() < 0) {
      totalCredits = this.grandTotalCharges() < 0 ? this.grandTotalCharges() * -1 : this.grandTotalCharges()
    }
    // this.patientResponsibilityResponse?.patientRespNCredits

    let amount: number;
    if (this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value) {
      amount = this.partialPaymentsForm?.get('partialAmount')?.value || 0;
    } else {
      amount = (this.getTotalVisitCharges() + this.totalPatientRespCharges()) || 0;
    }

    let checkPatientResp = JSON.parse(JSON.stringify(this.patientResponsibilityForm?.get('pastVisitPendingPayments')?.value || []));
    checkPatientResp = checkPatientResp?.filter((item: any) => item?.isPartialPaymentCheck);

    // Newly Added for Patient Resp payment paid
    checkPatientResp.sort((a, b) => {
      return b?.paymentAmount - a?.paymentAmount;
    });

    this.totalPaymentPaidCalc = this.grandTotalCharges() + this.totalCredits;
    const allPatientRespItems = [];
    let ifCommonIdPayment: number;
    checkPatientResp?.forEach(((item: any) => {
      if (this.selectedPayAppointment?.id !== item?.appointmentId) {
        const payCheckAmount = this.calculatPastVisitsPaymentAmount(this.totalPaymentPaidCalc, item?.paymentAmount, pendingPatientResp);
        this.totalPaymentPaidCalc = this.totalPaymentPaidCalc < item?.paymentAmount ? 0 : this.totalPaymentPaidCalc - payCheckAmount;
        item.paymentAmount = payCheckAmount < 0 ? item?.paymentAmount : payCheckAmount;
        // item.credits = 0;
        delete item?.payingAmount;
        delete item?.isPartialPaymentCheck;
        delete item?.arType;
        delete item?.claimProcessorType;
        allPatientRespItems?.push(item);
      }
      if (this.selectedPayAppointment?.id === item?.appointmentId) {
        const payCheckAmount = this.calculatPastVisitsPaymentAmount(this.totalPaymentPaidCalc, item?.paymentAmount, pendingPatientResp);
        this.totalPaymentPaidCalc = this.totalPaymentPaidCalc < item?.paymentAmount ? 0 : this.totalPaymentPaidCalc - payCheckAmount;
        item.paymentAmount = payCheckAmount < 0 ? item?.paymentAmount : payCheckAmount;
        // item.credits = 0;
        ifCommonIdPayment = item.paymentAmount;
      }
    }));
    // End

    // START - Update Creadits
    // To get used Credits appointments
    const creditsAppts: any[] = [];
    checkPatientResp?.sort((a, b) => parseFloat(b?.credits || 0) - parseFloat(a?.credits || 0));
    let reCountTheCredits: number = totalCredits;
    checkPatientResp?.forEach(element => {
      const dupReCreds = JSON?.parse(JSON?.stringify(element?.remainingCredits || 0));
      if (reCountTheCredits) {
        const getActCredits = element?.remainingCredits || element?.credits;
        element.remainingCredits = (!element?.credits || element?.credits === 0) ? null : (reCountTheCredits - getActCredits < 0) ? reCountTheCredits : getActCredits;
        reCountTheCredits = reCountTheCredits - getActCredits <= 0 ? 0 : reCountTheCredits - getActCredits;
      } else {
        element.remainingCredits = 0;
      }
      if (element?.credits > 0 && (dupReCreds !== element?.remainingCredits)) {
        creditsAppts?.push(element?.appointmentId);
      }
    });
  //  let patinetFirstId = this.selectedPayAppointment?.id;
    // if(allPatientRespItems.length >0 && !this.selectedPayAppointment?.id){
    //    patinetFirstId = allPatientRespItems[0].appointmentId;
    // }

    const params = {
      "latestAppointmentId": this.selectedPayAppointment?.id,
      "patientAccountId": this.patientResponsibilityResponse?.patientAccountId,
      "patientRespNCredits": allPatientRespItems || checkPatientResp || [],
      "totalCredits": this.patientResponsibilityResponse?.totalCredits,
      "totalRemainingCredits": totalCredits || 0,
      "creditsAppts": creditsAppts || [],
      "userInfoId": this.patientResponsibilityResponse?.userInfoId,

      "facilityId": this.selectedPayAppointment?.facilityId,
      "modeOfPay": modeOfPay || null,
      "paymentId": successPayDetails?.paymentId || null,
      "paymentOrderId": null,
      "receiptUrl": successPayDetails?.receiptUrl || null,
      "latestApptpendingPatientResponsibility": pendingPatientResp || 0,
      // "latestApptpaymentAmount": ((this.getTotalVisitCharges() - pendingPatientResp) < 0 ? pendingPatientResp - this.getTotalVisitCharges() : (this.getTotalVisitCharges() - pendingPatientResp)) || 0
      "latestApptpaymentAmount": ifCommonIdPayment || this.calculateLatestPaymentAmount(pendingPatientResp) || 0
    };
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.pastVisitAppointmentIds?.forEach((appointmentId: any) => {
          this.getAppointmentCost(appointmentId);
        });
        this.closeModal();
        // this.notifyText = data?.body?.message || data?.body?.msg;
        // this.exceptionModal?.modalShow();
        this.patientRespCreditsUpdate?.emit(true);
      } else {
        this.notifyText = data?.body?.message || data?.body?.msg;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message || error?.msg || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  };


  // Latest Payment Amount
  public calculatPastVisitsPaymentAmount(totalPaymentPaidCalc, itemPaymentAmount, pendingPatientResp): number {
    if (totalPaymentPaidCalc >= itemPaymentAmount && totalPaymentPaidCalc !== 0) {
      return itemPaymentAmount;
    }
    if (totalPaymentPaidCalc <= itemPaymentAmount && totalPaymentPaidCalc !== 0) {
      return totalPaymentPaidCalc;
    }

    if (totalPaymentPaidCalc === 0) {
      return 0;
    }
    return 0;
  }

  public calculateLatestPaymentAmount(pendingPatientResp: any): number {
    if (pendingPatientResp !== 0 && pendingPatientResp !== null && pendingPatientResp !== undefined) {
      let amount: number;
      if (this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value) {
        amount = this.partialPaymentsForm?.get('partialAmount')?.value || 0;
      } else {
        amount = (this.getTotalVisitCharges() + this.totalPatientRespCharges()) || 0;
      }

      if (amount > this.totalPatientRespCharges()) {
        return ((this.getTotalVisitCharges() - pendingPatientResp) < 0 ? pendingPatientResp - this.getTotalVisitCharges() : (this.getTotalVisitCharges() - pendingPatientResp)) || 0
      } else {
        return 0;
      }

    } else {
      return this.getTotalVisitCharges();
    }
  }

  // create Credit payment for current visit from past visit credits
  public createCreditPayment(): void {
    // if (this.amountForm?.invalid) {
    //   this.amountForm?.markAllAsTouched();
    //   this.loadSpinner = false;
    //   return;
    // }
    const action = 'payment/createCreditPayment';
    let amount: number;
    if (this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value) {
      amount = this.partialPaymentsForm?.get('partialAmount')?.value || 0;
    } else {
      amount = (this.getTotalVisitCharges() + this.totalPatientRespCharges()) || 0;
    }
    const amountCreditPay = amount?.toFixed(2)?.split('.')?.join('');


    let params = null;
    if (this.pageName === 'Healthpass') {
      params = {
        "amount": parseInt(amountCreditPay),
        "modeOfPay": "CREDIT",
        "serviceCharges": this.serviceChargesRequest() || [],
        "sourceId": "",
        "facilityId": this.facilityId,
        "type": this.selectedType,
        "userInfoId": this.selectedUserInfoId,
      }
    }
    else {
      params = {
        "amount": parseInt(amountCreditPay),
        "appointmentId": this.selectedPayAppointment?.id,
        "modeOfPay": "CREDIT",
        "serviceCharges": this.serviceChargesRequest() || [],
        "sourceId": "",
        "facilityId": this.facilityId
      }
    }

    // If Account Level Adhoc Payment
    if (this.accountLevelPay) {
      params.userInfoId = this.accoutLevelUserInfoId
    }

    this.loadSpinner = true;
    this.updatepastvisitPayments();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        //this.getAppointmentCost(this.selectedPayAppointment?.id);

        if (data?.body?.responseObject?.status === 'COMPLETED') {
          const checkoutId = data?.body?.responseObject?.paymentId;
          this.successPayDetails = data?.body?.responseObject;

          if (this.pageName !== 'Healthpass' && !this.accountLevelPay && this.isPastVisitsPr()) {
            this.updateSelectedCredits(this.successPayDetails, "CREDIT");


          } //not reqired to call this in case of healthpass
          if (this.pageName === 'Healthpass') { this.updateHealthpassPayment(); }
          this.paySuccessModal?.show();
        }
      } else {
        this.closeModal();
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // to get allVisitCharges
  public getTotalVisitCharges(): any {

    // if(this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value && this.totalCredits > 0) {
    //   return this.partialPaymentsForm?.get('partialAmount')?.value - this.totalCredits;
    // }

    // if(this.totalCredits === 0 || this.totalCredits === null) {
    //   return this.totalChargesCount();
    // }

    // if(this.totalCredits > 0 && this.totalCredits < this.totalChargesCount()) {
    //   return this.totalChargesCount() - this.totalCredits;
    // }

    // if(this.totalCredits > 0 ) {
    //   return this.totalCredits - this.totalChargesCount();
    // }

    return this.totalChargesCount();
  }

  // Grand Total Amount to be paid
  public grandTotalCharges(): any {
    let totalCharges = 0;
    if (this.switchArPatientInsurancePayForm?.get('switchCheck')?.value) {
      return this.totalArInsurnaceAmount();
    }

    if (this.pageName === 'Healthpass') {
      totalCharges = this.getTotalVisitCharges();

    }
    else {
      totalCharges = ((this.getTotalVisitCharges() + this.totalPatientRespCharges()) - this.totalCredits);
    }


    if (this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value) {
      return this.partialPaymentsForm?.get('partialAmount')?.value;
    }
    return totalCharges;
  }

  public totalChargesCount(): any {
    if (this.newPaymentServicesForm?.get('paymentServicesForm')?.value?.length === 0) {
      return 0;
    }
    const sum = this.newPaymentServicesForm?.get('paymentServicesForm')?.value?.map(item => parseFloat(item?.amount || 0))?.reduce((prev, curr) => prev + curr);
    const fixedTo2Decminals = sum ? parseFloat(sum?.toFixed(2)) : sum;
    this.totalVisitChargesForm?.get('totalVisitCharges')?.setValue(fixedTo2Decminals || null);
    return fixedTo2Decminals ? fixedTo2Decminals : null;
  }

  // Patient Responsibility 
  public totalPatientRespCharges(): any {
    if (this.patientResponsibilityForm?.get('pastVisitPendingPayments')?.value?.length === 0) {
      return 0;
    }
    const sum = this.patientResponsibilityForm?.get('pastVisitPendingPayments')?.value?.map(item => item?.isPartialPaymentCheck && parseFloat(item?.paymentAmount || 0))?.reduce((prev, curr) => prev + curr);
    const fixedTo2Decminals = sum ? parseFloat(sum?.toFixed(2)) : sum;
    return fixedTo2Decminals ? fixedTo2Decminals : null;
  }

  // total charges of Current Visit and Past Visit
  public totalCharges(): number {
    let amount = 0;
    if (this.switchArPatientInsurancePayForm?.get('switchCheck')?.value) {
      return this.totalArInsurnaceAmount() || 0;
    }
    if (this.pageName === 'Healthpass') {
      amount = this.totalChargesCount() || 0;
    }
    else {
      amount = (this.totalChargesCount() + this.totalPatientRespCharges()) - this.totalCredits || 0;
    }
    return amount < 0 ? 0 : amount;
  }

  // && this.totalPatientRespCharges() <= this.partialPaymentsForm?.get('partialAmount')?.value
  public canEnterPaymentTypeValidation(): boolean {

    if (this.switchArPatientInsurancePayForm?.get('switchCheck')?.value) {
      if (this.arInsuranceFormGroup?.invalid) {
        return false;
      }
      if (this.arInsuranceFormGroup?.valid) {
        return true;
      }
    }
    // && !this.isPriorChargesValidationShown()
    if (this.newPaymentServicesForm?.valid && this.partialPaymentsForm?.valid &&

      (this.partialPaymentsForm?.get('isPartialPayment')?.value ? (this.totalCharges() >= this.partialPaymentsForm?.get('partialAmount')?.value) && this.partialPaymentsForm?.get('partialAmount')?.value >= 1 : true)

      && this.patientResponsibilityForm?.valid && this.totalCharges() >= 1) {
      return true;
    }
    if (this.totalCredits > 0 && (this.totalCharges() === 0 || this.totalCharges() < 0)) {
      return (this.totalPatientRespCharges() + this.totalChargesCount()) < 1 ? false : true;
    }

    if ((this.totalPatientRespCharges() + this.totalChargesCount()) < 1) {
      return false;
    }

    return false;
  }

  // check AR Insurance selected
  public isArInsuranceCheck(): boolean {
    let checkArPayments = JSON.parse(JSON.stringify(this.patientResponsibilityForm?.get('pastVisitPendingPayments')?.value || []));
    const isArInsurance = checkArPayments?.filter((item: any) => item?.isPartialPaymentCheck && item?.arType === 'AR Insurance');
    const isArPatient = checkArPayments?.filter((item: any) => item?.isPartialPaymentCheck && item?.arType === 'AR Patient');

    if ((isArPatient && isArPatient?.length > 0) && (isArInsurance && isArInsurance?.length > 0)) {
      this.showArInsuranceMessage = true;
      return false;
    }

    if ((isArPatient && isArPatient?.length === 0) && (isArInsurance && isArInsurance?.length > 0)) {
      this.isArInsuranceSelected = true;
      this.showArInsuranceMessage = false;
      return true;
    }

    if ((isArPatient && isArPatient?.length > 0) && (isArInsurance && isArInsurance?.length === 0)) {
      this.isArInsuranceSelected = false;
      this.showArInsuranceMessage = false;
      return true;
    }

    if ((!isArPatient || isArPatient?.length === 0) && (!isArInsurance || isArInsurance?.length === 0)) {
      this.isArInsuranceSelected = false;
      this.showArInsuranceMessage = false;
      return true;
    }

    // return true;
  }

  public isPriorChargesValidationShown(): boolean {
    if (this.partialPaymentsForm?.get('isPartialPayment')?.value &&
      this.totalCharges() > 0 && ((this.totalPatientRespCharges() - this.totalCredits) > this.partialPaymentsForm?.get('partialAmount')?.value) &&
      this.totalCharges() > this.partialPaymentsForm?.get('partialAmount')?.value) {
      return true;
    }
    return false;
  }

  // Balance Due calculation
  public totalBalanceDue(): number {
    const balanceDue: number = (this.totalCharges() - this.partialPaymentsForm?.get('partialAmount')?.value) || 0;
    this.partialPaymentsForm?.get('remainingChargesDues')?.setValue(balanceDue || 0);
    return balanceDue || 0;
  }

  // if select partial Payment
  public makePartialPayment(event: any): void {
    if (event.target.checked) {
      // this.partialPaymentsForm?.get('partialAmount')?.setValidators([Validators?.required]);
    } else {
      this.partialPaymentsForm?.get('partialAmount')?.clearValidators();
      this.partialPaymentsForm?.get('partialAmount')?.setValue(null);
      this.partialPaymentsForm?.get('remainingChargesDues')?.setValue(null);
      this.pastVisitPendingPayments = this.patientResponsibilityForm?.get('pastVisitPendingPayments') as FormArray;
      if (this.pastVisitPendingPayments?.controls?.length > 0) {
        this.pastVisitPendingPayments?.controls?.forEach((item: FormGroup) => {
          item?.get('isPartialPaymentCheck')?.setValue(true);
        });
      }
    }
    this.partialPaymentsForm?.get('partialAmount')?.updateValueAndValidity();
  }

  // check validation payment services
  public checkPaymentServices(): void {
    this.newPaymentServicesForm?.markAllAsTouched();
    this.partialPaymentsForm?.markAllAsTouched();
    this.arInsuranceFormGroup?.markAllAsTouched();

    if (this.switchArPatientInsurancePayForm?.get('switchCheck')?.value) {
      if ((this.totalArInsurnaceAmount()) < 1) {
        this.totalChargesPayingCheck = `Total charges should be minimum of $1 to make payment.`;
        setTimeout(() => {
          this.totalChargesPayingCheck = null;
        }, 2000);
      }
    } else {
      if ((this.totalPatientRespCharges() + this.totalChargesCount()) < 1) {
        this.totalChargesPayingCheck = `Total charges should be minimum of $1 to make payment.`;
        setTimeout(() => {
          this.totalChargesPayingCheck = null;
        }, 2000);
      }
    }
  }

  // Check pay services
  public clickPayTypes(): void {
    this.paymentTypeSelectionForm?.markAllAsTouched();
  }

  // change the service type check if duplicate is selected
  public changeServiceTypeCheck(service: FormGroup, servI: number): void {
    const selectedService = service?.get('serviceType')?.value;
    const arrayValue = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
    // const checkIsPresent = arrayValue?.value?.map((item: any) => item?.serviceType === selectedService);
    const checkIsPresent = new Set(arrayValue?.value?.map(v => v?.serviceType));
    const filterNull = Array?.from(checkIsPresent)?.filter((a: any) => a !== null);
    const filterActualArray = arrayValue?.value?.filter((a: any) => a?.serviceType !== null);
    if (filterNull?.length < filterActualArray.length) {
      this.isSelectedServiceIsPresent = `${selectedService} service is already selected.`;
      service?.reset();
      setTimeout(() => {
        this.isSelectedServiceIsPresent = null;
      }, 2000);
    }
  }

  // check for paying payments
  public checkPastVisitPayments(service: FormGroup): void {
    if (service?.get('payingAmount')?.value > service?.get('paymentAmount')?.value) {
      service?.get('payingAmount')?.setValue(null);
      service?.get('payingAmount')?.markAllAsTouched();
    }
  }
  public clearSession() {
    this.helathpassPayment?.emit();
  }

  public loadPaymentInfo() {
    this.checklistPayment?.emit();
  }

  public updateHealthpassPayment() {
    //this.helathpassPayment?.emit(successPayDetails);
    const paymentTotal = this.totalAmountPaid;
    const feeType = this.selectedplanMode;

    // const action = `healthPass/updateHealthPassPayment?payment=${paymentTotal}&feeType=${feeType}&userInfoId=${this.selectedUserInfoId}`;
    this.apiService?.updateHealthPassPayExtrGateway(this.selectedUserInfoId, feeType, paymentTotal)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        sessionStorage.setItem('isPaymentUpdated', "success");

        // this.generateHealthpassPlanReceipt();

      } else {

      }

    },
      (error) => {

      }
    );

  }

  public generateHealthpassPlanReceipt(planInvoiceModal: UiModalComponent): void {

    const action = `pdf/generateHealthPassStatement?userInfoId=${this.selectedUserInfoId}`;
    this.loadSpinner = true;
    this.httpService?.generatePDF(action, '')?.subscribe((data: any) => {

      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);

      planInvoiceModal?.show();
      const iframeEle = document.getElementById('healthpass-receipt') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      this.loadSpinner = false;
    },
      (error: any) => {
        this.loadSpinner = false;

      });
  }

  // create AR-Insurnace Payment
  public createArInsurancePayment(): void {
    const action = 'payment/createARInsurancepayment';
    let modeOfPay: string;
    if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash') {
      modeOfPay = "CASH";
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Check') {
      modeOfPay = 'CHECK'
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Online') {
      modeOfPay = 'ONLINE'
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Paper Work') {
      modeOfPay = 'PAPER_WORK'
    }

    const allArInsurance = JSON.parse(JSON?.stringify(this.arInsuranceFormGroup?.get('arInsuranceTypes')?.value || []));
    const allParams = [];
    allArInsurance?.forEach((item: any) => {
      const amount = (item?.amount)?.toFixed(2)?.split('.')?.join('');
      const charges = {
        "amount": item?.amount,
        "serviceType": 'AR Insurance'
      }
      const obj = {
        "amount": parseInt(amount),
        "appointmentId": item?.appointmentId,
        "facilityId": this.facilityId,
        "modeOfPay": modeOfPay,
        "serviceCharges": [charges],
        "sourceId": null,
        "type": item?.claimProcessorType,
        "userInfoId": null
      }
      allParams?.push(obj);
    });

    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, allParams)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.successPayDetails = data?.body?.responseObject;
        this.servicesForm?.reset();
        this.amountForm?.reset();
        this.closeModal();
      } else {
        this.closeModal();
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // to check if have multiple Past Visist to call update Patient responsibility
  public isPastVisitsPr(): boolean {
    let checkPaendingBalance: any;
    if (this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value < this.totalCharges()) {
      checkPaendingBalance = this.totalCharges() - this.partialPaymentsForm?.get('partialAmount')?.value;
    }

    let checkPatientResp = JSON.parse(JSON.stringify(this.patientResponsibilityForm?.get('pastVisitPendingPayments')?.value || []));
    checkPatientResp = checkPatientResp?.filter((item: any) => item?.isPartialPaymentCheck);
    const withoutLatestApp = checkPatientResp?.filter((checkItem: any) => checkItem?.appointmentId !== this.selectedPayAppointment?.id);

    // if((!this.getTotalVisitCharges() || this.getTotalVisitCharges() === 0) && this.totalCredits === 0 && this.isAccountBalancePayments) {
    //   return false;
    // }

    if ((withoutLatestApp && withoutLatestApp?.length > 0) || (!this.isAccountBalancePayments && checkPaendingBalance && checkPaendingBalance > 0) || this.totalCredits > 0) {
      return true;
    } else if (!withoutLatestApp || withoutLatestApp?.length === 0) {
      return false;
    }
  }

  // Swicth Between AR Patient and AR Insurance
  public checkIsARInsurancePatient(event: any): void {
    const controlArray = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
    this.httpService?.clearFormArray(controlArray);
    this.pastVisitPendingPayments = this.patientResponsibilityForm?.get('pastVisitPendingPayments') as FormArray;
    this.httpService?.clearFormArray(this.pastVisitPendingPayments);

    const arInsuArray = this.arInsuranceFormGroup?.get('arInsuranceTypes') as FormArray;
    this.httpService?.clearFormArray(arInsuArray);

    if (event.target.checked) {
      arInsuArray?.push(this.arInsuranceGroup());
      this.getAllEncounters();
      // if (controlArray && controlArray?.controls?.length === 0) {
      //   this.addPaymentServices();
      // }
      // controlArray?.controls?.forEach((item: FormGroup) => {
      //   item?.get('serviceType')?.setValue('AR Insurance');
      // });
    } else {
      this.addPaymentServices();
      this.getPatientResponsibility(this.selectedUserInfoId || this.accoutLevelUserInfoId || this.selectedPayAppointment?.userInfoId)
    }
  }

  // get All Appointments
  public getAllEncounters(): void {
    const userId = this.selectedUserInfoId || this.accoutLevelUserInfoId || this.selectedPayAppointment?.userInfoId;
    const action = `common/getChartInfo?userInfoId=${userId}&chartModuleName=Encounters`;
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const getAppts = data?.responseObject || [];
        const allAppointments = [];
        getAppts?.forEach((checkItem: any) => {
          if (checkItem?.appointmentType === 'Past' && checkItem?.appointmentRegistration?.appointmentStatus?.checkOut) {
            const obj = {
              appointmentId: checkItem?.appointmentRegistration?.id,
              appointmentTime: this.httpService?.getAppointmentTime(checkItem?.appointmentRegistration?.appointment?.dateTime)
            }
            allAppointments?.push(obj);
          }
        })
        this.allActiveEncounters = allAppointments || [];
        this.loadSpinner = false;
      }
    }, error => {
      this.loadSpinner = false;
    });
  }

  // change the service type check if duplicate is selected
  public changeServiceDate(service: FormGroup, servI: number): void {
    const selectedService = service?.get('appointmentId')?.value;
    const arrayValue = this.arInsuranceFormGroup?.get('arInsuranceTypes') as FormArray;
    // const checkIsPresent = arrayValue?.value?.map((item: any) => item?.appointmentId === selectedService);
    const checkIsPresent = new Set(arrayValue?.value?.map(v => v?.appointmentId));
    const filterNull = Array?.from(checkIsPresent)?.filter((a: any) => a !== null);
    const filterActualArray = arrayValue?.value?.filter((a: any) => a?.appointmentId !== null);
    if (filterNull?.length < filterActualArray.length) {
      this.isSelectedServiceIsPresent = `Service date is already selected.`;
      service?.reset();
      setTimeout(() => {
        this.isSelectedServiceIsPresent = null;
      }, 2000);
    }
  }

  public totalArInsurnaceAmount(): any {
    if (this.arInsuranceFormGroup?.get('arInsuranceTypes')?.value?.length === 0) {
      return 0;
    }
    const sum = this.arInsuranceFormGroup?.get('arInsuranceTypes')?.value?.map(item => parseFloat(item?.amount || 0))?.reduce((prev, curr) => prev + curr);
    const fixedTo2Decminals = sum ? parseFloat(sum?.toFixed(2)) : sum;
    // this.totalVisitChargesForm?.get('totalVisitCharges')?.setValue(fixedTo2Decminals || null);
    return fixedTo2Decminals ? fixedTo2Decminals : null;
  }

  public getAppointmentCost(appointmentId: any): void {
    const action = `appointment/registration/retrieveApptCostofCare?appointmentId=${appointmentId}`;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {

      }
    }, (error) => {

    });
  }


  public updatepastvisitPayments(): void {
    let checkAllPastVisits = JSON.parse(JSON.stringify(this.patientResponsibilityForm?.get('pastVisitPendingPayments')?.value || []));
    //console.log(checkAllPastVisits);

    this.pastVisitAppointmentIds = [];
    checkAllPastVisits?.forEach((pastVisitItem: any) => {
      const appointmentId = pastVisitItem?.appointmentId;
      this.pastVisitAppointmentIds?.push(appointmentId);
      //this.getAppointmentCost(appointmentId);

    });
    //console.log(this.pastVisitAppointmentIds);
  }

  // Navigate to Chart Account Balance
  public navigateUserAccountBalance(): void {
    if (this.selectedUserInfoId || this.accoutLevelUserInfoId || this.selectedPayAppointment?.userInfoId) {
      this.router?.navigate([`/hospital/user-data-update/${this.selectedUserInfoId || this.accoutLevelUserInfoId || this.selectedPayAppointment?.userInfoId}`],
      {
        queryParams: {
          'accountBalance': 'yes'
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.cardPaymentSubscription?.unsubscribe();
  }

  public preventdefault(){
    return false ;
  }
  public futureBalnceUpdate(){
    this.patientRespCreditsUpdateForFuterApp.emit(this.selectedAppointmentDetails)
  }

  // Account Balance Notify
  public async sendAccountBalanceNotify() {
    let appointmentDetails: any;
    if (this.selectedPayAppointment?.id) {
      try {
        this.loadSpinner = true;
        //console.log(this.selectedPayAppointment?.id);
        //console.log(this.selectedPayAppointment?.appointmentId);
        let action: any = `appointment/registration/getAppointmentById?id=${this.selectedPayAppointment?.id}`;
        appointmentDetails = await this.httpService.makeGetRequest(action, '').toPromise();
        this.loadSpinner = false;
      }catch {
        this.loadSpinner = false;
      }
    }
    this.sendNotifyComp?.showNotifyModalForEncounter(appointmentDetails?.responseObject || this.userInfoData, !this.selectedPayAppointment?.id ? 'user' : null, 'claim');
  }

}
