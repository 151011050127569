<div class="add-appointment-sectons" [formGroup]="insuranceForm">

    <div class="row">
        <!-- check self pay -->
        <div class="form-group col-lg-8 col-md-8 col-sm-12">
            <ng-container *ngIf="isNoAppntReg !== 'No'">
                <label>Coverage Type <span class="text-danger">*</span></label><br />
                <label class="radio-inline" *ngFor="let item of isSelfPay">
                    <input
                        [ngClass]="item.value ==='healthPass' && ((insuranceForm && !insuranceForm?.get('healthPass')?.value) ||(demographicForm && !demographicForm?.get('healthPass')?.value) )?'disabled' : ''"
                        formControlName="selfPay" id="add-appt-insu-coverage-type" type="radio"
                        (change)="checkPaymentType()" name="selfPay" [value]="item.value"
                        [attr.disabled]="item.value ==='healthPass' && ((insuranceForm && !insuranceForm?.get('healthPass')?.value) ||(demographicForm && !demographicForm?.get('healthPass')?.value) )?'true':null">
                    <span class="desc"
                        [ngClass]="item.value ==='healthPass' && ((insuranceForm && !insuranceForm?.get('healthPass')?.value) ||(demographicForm && !demographicForm?.get('healthPass')?.value) )?'disabled' : ''">{{item.desc}}</span></label>
                <!--<label class="radio-inline" >
                            <input type="radio"  name="Health Pass"><span
                            class="desc">Health Pass</span></label>-->

                <br />
                <span *ngIf="insuranceForm?.get('selfPay')?.errors?.required && insuranceForm?.get('selfPay')?.touched"
                    class="text-danger">Coverage Type is Required</span>
            </ng-container>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="insuranceForm?.get('selfPay')?.value === 'Insurance'">
            <button type="button" style="float: right;" id="addl-insu-add-btn"
                (click)="addMoreInsurancesClick();makeSubscriberMandatory()" class="btn btn-primary">+ Add More
                Insurances</button>
        </div>
    </div>


    <div class="insurance-details" *ngIf="insuranceForm?.get('selfPay')?.value === 'Insurance'">
	<!-- <app-common-insurance-addl [userInfoDetailsForm]="userInfoDetailsForm"
                        [updateUserdataCard]="PatientDetailsCardReload" [userInfoDetails]="userInfoDetails"></app-common-insurance-addl>-->



        <!-- *ngIf="insuranceForm?.get('insuranceDetails')?.controls?.length > 1" -->
        <div class="multiple-insurances" formArrayName="insuranceDetails">
            <ng-container *ngFor="let insurance of insuranceForm?.get('insuranceDetails')?.controls; let i = index">
                <div class="row insurance-items" [formGroupName]="i">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="delete del-icon float-right d-flex" placement="left">
                            <!-- <i class="las la-trash-alt" id="addl-insu-delete-btn"
                                (click)="deleteInsuranceConfirm(i, insurance, confirmInsuranceCardDelete)"></i> -->

                            <button placement="left" ngbtooltip="Delete Insurance Details" (click)="deleteInsuranceConfirm(i, insurance, confirmInsuranceCardDelete)"
                                class="icon-queue-events delete-action-bg clearfix float-right d-flex">
                                <i id="addl-insu-delete-btn" class="las la-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div [ngClass]="isUploadRequired ? 'col-lg-8 col-md-8 col-sm-12' : 'col-lg-12 col-md-12 col-sm-12'">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label>Insurance Name <span class="text-danger">*</span></label>
                                    <ng-select placeholder="Search by Insurance Name or Payor ID or Plan Name"
                                        [items]="(allInsuranceList | async)?.responseObject" bindLabel="name"
                                        bindValue="name" [addTag]="true" [multiple]="false" [hideSelected]="false"
                                        [trackByFn]="trackByFn" [minTermLength]="3" [closeOnSelect]="true"
                                        (change)="selectedInsurance($event, i)" [loading]="insuranceLoading"
                                        typeToSearchText="Please enter 3 or more characters"
                                        [typeahead]="insuranceInput" id="addl-insu-name{{i}}" clearAllText="Clear"
                                        formControlName="name">
                                    </ng-select>
                                    <span
                                        *ngIf="insurance?.get('name')?.errors?.required && insurance?.get('name')?.touched"
                                        class="text-danger">Insurance Name is Required</span>
                                    <span class="text-danger"
                                        *ngIf="insurance?.get('name')?.invalid && !insurance?.get('name')?.errors?.required">Please
                                        enter a valid Insurance Name</span>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                    <label>Member ID <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="memberId"
                                        id="addl-insu-memberId{{i}}" maxlength="25" placeholder="Member ID">
                                    <span
                                        *ngIf="insurance.get('memberId').errors?.required && insurance.get('memberId').touched"
                                        class="text-danger">Member ID is Required</span>
                                    <span class="text-danger"
                                        *ngIf="insurance.get('memberId').invalid && !insurance.get('memberId').errors?.required">Member
                                        ID accepts only Alphanumeric & Hyphen Symbol</span>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                    <label>Group Number</label>
                                    <input type="text" class="form-control input-text" formControlName="groupNumber"
                                        id="addl-insu-groupNumber{{i}}" maxlength="25" placeholder="Group Number">
                                    <span class="text-danger"
                                        *ngIf="insurance.get('groupNumber').invalid && !insurance.get('groupNumber').errors?.required">
                                        Group Number accepts only Alphanumeric & Hyphen Symbol</span>
                                   
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                    <label>Payor ID <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control input-text" formControlName="payerId"
                                        id="addl-insu-payerId{{i}}" placeholder="Payor ID">
                                    <span
                                        *ngIf="insurance.get('payerId').errors?.required && insurance.get('payerId').touched"
                                        class="text-danger">Payor ID is Required</span>
                                    <span class="text-danger"
                                        *ngIf="insurance.get('payerId').invalid && !insurance.get('payerId').errors?.required">Please
                                        enter a valid Payor ID</span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label for="Relationship"> Coverage Sequence <span
                                            class="text-danger">*</span></label>
                                    <div class="input-group mb-0">
                                        <ng-select placeholder="Choose Coverage Sequence"
                                            (change)="changeCoverageSequence($event, i)" bindLabel="desc"
                                            id="addl-insu-priority{{i}}" bindValue="value"
                                            [items]="insuranceSequenceList" formControlName="priority"></ng-select>
                                    </div>
                                    <span
                                        *ngIf="insurance?.get('priority')?.errors?.required && insurance?.get('priority')?.touched"
                                        class="text-danger">Coverage Sequence is Required</span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label for="Relationship"> Insurance Class <span
                                            class="text-danger">*</span></label>
                                    <div class="input-group mb-0">
                                        <ng-select placeholder="Choose Insurance class"
                                            id="addl-insu-insuranceClass{{i}}" [items]="insuranceClassList"
                                            formControlName="insuranceClass"></ng-select>
                                    </div>
                                    <span
                                        *ngIf="insurance?.get('insuranceClass')?.errors?.required && insurance?.get('insuranceClass')?.touched"
                                        class="text-danger">Insurance Class is Required</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="isUploadRequired">
                        <ng-container *ngIf="!insurance?.value?.insuranceCardImageId">
                           <div class="upload-documents mt-4">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <p class="text-info">* Supports only PDF, PNG and JPEG files and Files larger
                                            than 25MB cannot be uploaded</p>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                       <div class="item-wrapper one">
                                            <div class="item">
                                                <form data-validation="true" action="#" method="post"
                                                    enctype="multipart/form-data">
                                                    <div class="item-inner">
                                                        <div class="item-content">
                                                            <div class="image-upload">
                                                                <label (change)="insuranceFileChanged($event, i)"
                                                                    style="cursor: pointer;width: 100%;"
                                                                    for="insurance-license-upload">
                                                                     <img class="img-upload" alt="Document Upload"
                                                                        src="../../../../../../assets/images/ic_image_upload.svg" />
                                                                    <div class="h-100">
                                                                        <div class="dplay-tbl">
                                                                            <div class="dplay-tbl-cell">
                                                                                <h5 style="padding: 0px 15px;"><b>Choose
                                                                                        Patient Insurance Card to
                                                                                        Upload</b></h5>
                                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                    <input data-required="image" #addInsuranceFile
                                                                        type="file" accept="application/pdf, image/*"
                                                                        name="insurance-license-upload"
                                                                        id="insurance-license-upload"
                                                                        class="image-input"
                                                                        data-traget-resolution="image_resolution"
                                                                        value="">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                           
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <div class="download-insurance-card mt-2" *ngIf="insurance?.value?.insuranceCardImageId">
                            <h5 class="download-insurance">
                                

                                <ng-container>
                                    <div class="row">

                                       <div class="col-lg-12 col-md-12 col-sm-12  text-center">
                                            <ng-container *ngIf="insurance?.value?.docType === 'application/pdf'">
                                                <iframe
                                                    id="{{insurance?.value?.insuranceCardImageId}}_add_insu_card_display"
                                                    frameBorder="0" width="300" height="200px"></iframe>
                                            </ng-container>
                                            <ng-container *ngIf="insurance?.value?.docType !== 'application/pdf'">
                                                <img style="width: 100%;padding: 0px 20px" name="Image Shown"
                                                    id="{{insurance?.value?.insuranceCardImageId}}_add_insu_card_display" />
                                            </ng-container>
                                        </div>

                                        <div class="col-sm-12 text-center">
                                            <button class="icon-queue-events signoff-action-bg clearfix"
                                                placement="bottom" ngbTooltip="Full View">
                                                <i class="las la-expand " id="expand-full-view"
                                                    (click)="downloadIdentificationCard(insurance?.value?.insuranceCardImageId, insuraceCardModal)"></i>
                                            </button>
                                            <button class="icon-queue-events delete-action-bg clearfix"
                                                placement="bottom" ngbTooltip="Delete">
                                                <i class="las la-trash-alt " id="addl-insu-delete"
                                                    (click)="confirmDelete(insurance?.value?.insuranceCardImageId,insurance, deletConfirmDocument)"
                                                    title="Delete"></i>
                                            </button>

                                        </div>
                                    </div>

                                </ng-container>
                            </h5>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="insuranceForm?.get('insuranceDetails')?.controls?.length > 0">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="form-group">
                        <label>Subscriber Details <span class="text-danger">*</span></label><br />
                        <label class="radio-inline" *ngFor="let item of insurancePrimarySubscriber"><input
                                formControlName="insurancePrimarySubscriber" type="radio"
                                id="addl-insu-primary-sub-type" (change)="checkPrimarySubscriber()"
                                name="insurancePrimarySubscriber" [value]="item.value"><span
                                class="desc">{{item.desc}}</span></label>
                        <br />
                        <span
                            *ngIf="insuranceForm.get('insurancePrimarySubscriber').errors?.required && insuranceForm.get('insurancePrimarySubscriber').touched"
                            class="text-danger">Subscriber Details is Required</span>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="form-group">
                        <label>Insurance Type <span class="text-danger">*</span></label><br />
                        <label class="radio-inline" *ngFor="let item of insuranceType">
                            <input formControlName="medicareFlag" id="addl-insu-insurance-type"
                                (change)="medicareFlagChange()" type="radio" name="medicareFlag" [value]="item.value">
                            <span class="desc">{{item.desc}}</span>
                        </label>
                        <br />
                        <span
                            *ngIf="insuranceForm?.get('medicareFlag')?.errors?.required && insuranceForm?.get('medicareFlag')?.touched"
                            class="text-danger">Insurance Type is Required</span>
                    </div>
                </div>
            </div>

            <div class="preimary-subscriber">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group ">
                            <label>Primary Subscriber First Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control input-text" formControlName="subscriberfirstname"
                                id="addl-insu-subscriberfirstname" placeholder="Patient First Name"
                                [attr.disabled]="insuranceForm?.get('insurancePrimarySubscriber')?.value === 'Self' ? 'true' : null">
                            <span
                                *ngIf="insuranceForm.get('subscriberfirstname').errors?.required && insuranceForm.get('subscriberfirstname').touched"
                                class="text-danger">Primary Subscriber First Name is Required</span>
                            <span class="text-danger"
                                *ngIf="insuranceForm.get('subscriberfirstname').invalid && !insuranceForm.get('subscriberfirstname').errors?.required">Please
                                enter a valid Primary Subscriber First Name</span>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Primary Subscriber Last Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control input-text" formControlName="subscriberlastname"
                                id="addl-insu-subscriberlastname" placeholder="Patient Last Name"
                                [attr.disabled]="insuranceForm?.get('insurancePrimarySubscriber')?.value === 'Self' ? 'true' : null">
                            <span
                                *ngIf="insuranceForm.get('subscriberlastname').errors?.required && insuranceForm.get('subscriberlastname').touched"
                                class="text-danger">Primary Subscriber Last Name is Required</span>
                            <span class="text-danger"
                                *ngIf="insuranceForm.get('subscriberlastname').invalid && !insuranceForm.get('subscriberlastname').errors?.required">Please
                                enter a valid Primary Subscriber Last Name</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Primary Subscriber Date of Birth <span class="text-danger">*</span></label>
                            <input type="date" class="form-control input-text" formControlName="subscriberdateOfBirth"
                                [min]="minDOB" [max]="maxAppointmentDate" id="addl-insu-subscriberdateOfBirth"
                                placeholder="Date of Birth"
                                [attr.disabled]="insuranceForm?.get('insurancePrimarySubscriber')?.value === 'Self' ? 'true' : null">
                            <span
                                *ngIf="insuranceForm.get('subscriberdateOfBirth').errors?.required && insuranceForm.get('subscriberdateOfBirth').touched"
                                class="text-danger">Primary Subscriber Date of Birth is Required</span>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Primary Subscriber Gender <span class="text-danger">*</span></label>
                            <ng-select placeholder="Choose Primary Subscriber Gender" [items]="genderList"
                                formControlName="subscribergender" bindLabel="desc" id="addl-insu-subscriberGender"
                                bindValue="value"
                                [readonly]="insuranceForm?.get('insurancePrimarySubscriber')?.value === 'Self' ? true : null">
                            </ng-select>
                            <span
                                *ngIf="insuranceForm.get('subscribergender').errors?.required && insuranceForm.get('subscribergender').touched"
                                class="text-danger">Primary Subscriber Gender is Required</span>
                        </div>
                    </div>
                   
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="Relationship"> Relationship to Subscriber <span
                                    *ngIf="insuranceForm?.get('insurancePrimarySubscriber').value === 'Other'"
                                    class="text-danger">*</span></label>
                            <div class="input-group mb-0">
                                <ng-select placeholder="Choose Relationship to Subscriber"
                                    [items]="insuranceRelationship" formControlName="relationToSubscriber"
                                    id="addl-insu-relationToSubscriber" bindLabel="desc" bindValue="value">
                                </ng-select>
                                <span
                                    *ngIf="insuranceForm.get('relationToSubscriber').errors?.required && insuranceForm.get('relationToSubscriber').touched"
                                    class="text-danger">Relationship to Subscriber is Required</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="insuranceForm?.get('medicareFlag').value === true">
                        <div class="form-group">
                            <label for="last-name">Coverage Effective Start Date <span
                                    class="text-danger">*</span></label>
                            <input type="date" formControlName="medicareStartDate"
                                id="addl-insu-CoverageEffectiveStartDate" class="form-control input-text">
                            <span
                                *ngIf="insuranceForm?.get('medicareStartDate')?.errors?.required && insuranceForm?.get('medicareStartDate').touched"
                                class="text-danger">Coverage Effective Start Date is Required</span>
                        </div>
                    </div>
                </div>

                

            </div>

            <div class="row">
                <div class="col-sm-12">
                    <h5>Employer Details</h5>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label for="first-name"> Employer Name</label>
                        <input class="form-control input-text" type="text" placeholder="Name"
                            id="addl-insu-employer-name" formControlName="employerName" />
                    </div>
                </div>
            </div>
            <div class="employer-address-details">
                <app-address [addressForm]="insuranceForm?.get('employerAddress')"></app-address>
            </div>
        </ng-container>
    </div>

</div>

<div class="custom-loader justify-content-center" *ngIf="loadSpinner">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<app-ui-modal #insuraceCardModal [hideHeader]="false" [modalCentered]="true" id="insuraceCardModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Insurance Card</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="ins-card-multiple" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" data-dismiss="modal" id="ok1"
            (click)="insuraceCardModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- confirm the delete insurance card -->
<app-ui-modal #deletConfirmInsurance [hideHeader]="false" [modalCentered]="true" id="deletConfirmInsurance">
    <div class="app-modal-header">
        <h5 class="modal-title">Are you sure want to delete Insurance Card?</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="deletConfirmInsurance?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="deletConfirmInsurance.hide()">No</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="deletConfirmInsurance?.hide(); deleteInsuranceImageByAppointment(selectedInsuranceForDelete)">Yes</button>
    </div>
</app-ui-modal>

<!-- confirm the delete insurance -->
<app-ui-modal #confirmInsuranceCardDelete [hideHeader]="false" [modalCentered]="true" id="confirmInsuranceCardDelete">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Are you sure want to delete Insurance Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="confirmInsuranceCardDelete?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">

                <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>

                </h5>

            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">

                <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete Insurance Details?</h5>

            </div>
        </div>

    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="confirmInsuranceCardDelete?.hide(); deleteInsurances(insuDeletedIndex, selectedInsuranceForDelete)">Yes</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="confirmInsuranceCardDelete.hide()">No</button>

    </div>
</app-ui-modal>
<app-ui-modal #deletConfirmDocument [hideHeader]="false" [modalCentered]="true" id="deletConfirmDocument">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title text-center">Are you sure want to delete Insurance Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="deletConfirmDocument?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">

                <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>

                </h5>

            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">

                <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete Insurance Details?</h5>

            </div>
        </div>

    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="deletConfirmDocument?.hide(); deleteInsuranceDocument(selectedInsuranceForDelete,selectedMedicalRecordForDelete,deletConfirmDocument)">Yes</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="deletConfirmDocument.hide()">No</button>

    </div>
</app-ui-modal>