import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from '../../services/http.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CardComponent } from 'src/app/theme/shared/components/card/card.component';
import { Router } from '@angular/router';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { CommonUploadDocComponent } from '../common-upload-doc/common-upload-doc.component';
import { ClaimsService } from '../../services/claims.service';

@Component({
  selector: 'app-claim-journal',
  templateUrl: './claim-journal.component.html',
  styleUrls: ['./claim-journal.component.scss']
})
export class ClaimJournalComponent implements OnInit {
  public claimJournalForm: FormGroup;
  public lateNotifySubmitClick: boolean;
  public loadSpinner: boolean;
  public lateNotifyPatient: any;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  @ViewChild('createClaimJournalModal') createClaimJournalModal: UiModalComponent;
  @Input() noDelay: boolean;
  @Input() isFromChart: boolean;

  public loginDetails: any;
  public facilityId: any;
  public showComposedNotifyMessages: boolean;
  selectedIndex: number;
  public isUserLevelNotification: string;
  public categoriesList = [
    { value: 'Analysis', desc: 'Analysis' },
    { value: 'Call', desc: 'Call' },
    { value: 'Voice Mail', desc: 'Voice Mail' }
  ];

  public statusList = [
    { value: 'Open', desc: 'Open' },
    { value: 'In-Progress', desc: 'In-Progress' },
    { value: 'Hold', desc: 'Hold' },
    { value: 'Completed', desc: 'Completed' }
  ];

  public priorityList = [
    { value: 'Low', desc: 'Low' },
    { value: 'Medium', desc: 'Medium' },
    { value: 'High', desc: 'High' }
  ];
  public asigneeList = [];
  public allTasks = [];
  public allExistingUsers = [];
  public taskTypeList = [];
  public loginUser: any;
  public isUpdate: boolean;
  public isUpdateResolution: boolean;
  @Input() idType: any;
  @Input() pageId: any;
  public selectedId: any;
  public displayTable: boolean = false;
  public disableField: boolean;
  public disableDescField: boolean;
  @ViewChild('claimsStatusGrid') taskCard: CardComponent;
  @ViewChild(DataTableDirective, { static: false })
  dtElementClaimsJournal: DataTableDirective;
  public dtOptionsClaimJournalList: DataTables.Settings = {};
  public dtTriggerCliamJournalList: Subject<any> = new Subject<any>();
  public selectedTaskId: any;
  public patientJournalUserId: any;
  public roleType: any;
  public userInformation: any;
  public ownerName: any;
  public taskInfo = [];
  @Output() onTaskUpdate = new EventEmitter();
  public disableCompleteField: boolean
  public selectedTask: string;
  public selectedUserInfoId: null;
  public selectedAppointmentId: null;
  public getAllClaimJournalsNotes = [];
  createdTaskId: any;
  @ViewChild(CommonUploadDocComponent) uploadComp: CommonUploadDocComponent;
  showPreview: boolean;
  addCss: boolean = false;
  public readMoreLessCheck?: boolean = false;
  public reasonsList = [
    { value: 'Claim Status', desc: 'Claim Status' },
    { value: "EOB/ERA", desc: "EOB/ERA" },
    { value: 'Rejects', desc: 'Rejects' },
    { value: "Denials", desc: "Denials" },
    { value: "Partial Billing", desc: "Partial Billing" },
    { value: "Secondary Insurances Confirmation", desc: "Secondary Insurances Confirmation" },
    { value: 'Patient Calls', desc: 'Patient Calls' }
  ];
  public minAppointmentDate: Date;
  public isfollowUpSelected: boolean = false;
  @Output() onClaimJournal = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router, private claimsService: ClaimsService) {
    this.loginUser = JSON.parse(sessionStorage?.getItem('userDetails'));
    this.ownerName = this.loginUser.lastName + ", " + this.loginUser.firstName;
    this.claimJournalForm = this.formBuilder.group({
      'category': [null, Validators.required],
      'taskType': [null],
      'description': [null, Validators.required],
      's3Key': [null],
      'documentName': [null],
      'isfollowUp': [null],
      'followupDate': [null],
      'reason': [null, Validators.required],
      'cobFollowup': [null],
    });
  }

  ngOnInit(): void {
    this.displayTable = true;
    this.dtOptionsClaimJournalList = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      autoWidth: true,
      order: [[0, "desc"]],
      searching: false,
      columnDefs: [{ targets: [0], type: 'date', orderable: true }],
      "language": {
        "info": "Showing _START_ to _END_ of _TOTAL_ Entries",
        "emptyTable": "Data is unavailable",
        "lengthMenu": "Showing _MENU_ Entries",
        "infoEmpty": "Showing 0 to 0 of 0 Entries",
      },
    };
    this.loadSpinner = false;
    this.getExistingProvidersByFacility();
    this.minAppointmentDate = this.httpService.convertDate(new Date());
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  public rerender(): void {
    this.dtElementClaimsJournal?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance?.destroy();
    });
  }

  public resetForm(): void {
    this.claimJournalForm?.reset();
    this.showPreview = false;
    this.addCss = false;
    if (this.uploadComp) {
      this.uploadComp.allUploadedFilesList = null;
      this.uploadComp?.uploadForm?.reset();
    }
  }

  public showClaimJournalModal(claimDetails: any): void {
    console.log(claimDetails);
    this.isfollowUpSelected = false;
    this.userInformation = claimDetails;
    this.createClaimJournalModal?.show();
    this.getAllClaimJournals();
  }

  // get all claims jorunal related to appointment
  public getAllClaimJournals(): void {
    this.rerender();
    let action = `userInfo/getClaimJournalNotes?appointmentId=${this.userInformation?.appointmentId}&actionType=All`;
    if (this.userInformation?.id) {
      action = action + `&claimId=${this.userInformation?.id}`
    }
    this.getAllClaimJournalsNotes = [];
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.getAllClaimJournalsNotes = data?.responseObject || [];
      }
      this.loadSpinner = false;
      // setTimeout(() => {
      this.dtTriggerCliamJournalList?.next();
      // });
    },
      error => {
        this.isSuccessNotify = false;
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        this.dtTriggerCliamJournalList?.next();
      });
  }

  public getExistingProvidersByFacility(): void {
    const action = `provider/getProvidersListforfacility/${this.loginUser?.facilityId}`;
    this.asigneeList = [];
    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        const allUsers = data?.responseObject;
        this.asigneeList = allUsers || [];
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.loadSpinner = false;
      });
  }

  public saveClaimJournalNotes(createClaimJournalModal?: UiModalComponent): void {
    if (this.claimJournalForm?.invalid) {
      this.claimJournalForm?.markAllAsTouched();
      return;
    }
    //console.log(this.claimJournalForm?.value?.cobFollowup);

    const action = `claim/saveClaimJournalNotes`;
    const params = {
      "actionType": this.claimJournalForm?.value?.taskType || 'Claim Journal' || null,
      "appointmentId": this.userInformation?.appointmentId || this.userInformation?.id,
      "claimId": this.userInformation?.id || null,
      "documentName": this.claimJournalForm?.value?.documentName || null,
      "facilityId": this.loginUser?.facilityId,
      "journalCategory": this.claimJournalForm?.value?.category || "Claim Journal",
      "notes": this.claimJournalForm?.value?.description,
      "s3Key": this.claimJournalForm?.value?.s3Key || null,
      "taskId": this.createdTaskId || null,
      "reason": this.claimJournalForm?.value?.reason || null,
      "cobFollowup": this.claimJournalForm?.value?.cobFollowup || null,
      "followupDate": this.claimJournalForm?.value?.followupDate || null,
      // "uniqueId": null,
      // "userInfoId": this.userInformation?.userInfoId
    };
    // console.log(params);
    //return;

    this.loadSpinner = true;
    this.claimsService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.isfollowUpSelected = false;
        // createClaimJournalModal?.hide();
        this.isSuccessNotify = true;
        this.notifyText = data?.body?.message || data?.body?.msg;
        this.exceptionModal?.modalShow();
        this.getAllClaimJournals();
        this.resetForm();
        this.onClaimJournal?.emit(true);
      } else {
        this.isSuccessNotify = false;
        this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    }, (error) => {
      this.isSuccessNotify = false;
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
    });
  }

  public navigateToTop(el: HTMLElement): void {
    // this.scroller.scrollToAnchor("mainDetails");
    el.scrollIntoView();
  }

  // Delete upload doc if not created claim jorunal
  public deleteDocIfUploadedWithoutCreate(): void {
    if (!this.claimJournalForm?.value?.s3Key) {
      return;
    }
    const action = `record/deleteDocument?s3Key=${this.claimJournalForm?.value?.s3Key}`;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {

    },
      error => {

      });
  }

  // Show Documents view popup
  public async viewDownloadDocument(medicalRecord: any, isViewDownload: string) {
    this.loadSpinner = true;
    let getIndRecord: any;
    try {
      const listAction = `record/listDocuments?appointmentId=${medicalRecord?.appointmentId}&documentType=Claim Journal`;
      const getListDocuments: any = await this.httpService?.makeGetRequest(listAction, '')?.toPromise() || [];
      getIndRecord = getListDocuments?.responseObject?.find(item => item?.s3Key === medicalRecord?.s3Key);
    } catch {
      this.loadSpinner = false;
    }
    if (!getIndRecord?.s3Key || !getIndRecord?.s3Path) {
      return;
    }
    const action = `record/downloadDocument?s3Key=${getIndRecord?.s3Key}&s3Path=${getIndRecord?.s3Path || null}`;
    this.loadSpinner = true;
    this.httpService.downloadImage(action).subscribe((data: any) => {
      if (!data) {
        this.loadSpinner = false;
        return;
      }
      this.showPreview = true;
      const fileType = getIndRecord?.fileType;
      const file = new Blob([data], { type: fileType })
      const fileURL = URL.createObjectURL(file);
      if (isViewDownload === 'view') {
        setTimeout(() => {
          const iframeEle = document.getElementById('claim-journal-docs-view') as HTMLElement;
          iframeEle?.setAttribute('src', fileURL);
        });
        // patientMedicalRecordModal?.show();
      } else {
        let a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = getIndRecord?.documentName;
        document.body.appendChild(a);
        a.click();
      }
      this.loadSpinner = false;
    },
      (error: any) => {
        this.isSuccessNotify = false;
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  public refreshCss(add: boolean) {
    this.addCss = !this.addCss;
  }

  // date conversion 
  public appointmentTime(dataTime?: string): string {
    return this.httpService?.getAppointmentTime(dataTime);
  }

  // to checl letters count to display read more and less
  public checkLettersCount(check: string): boolean {
    return check?.length > 30 ? true : false;
  }

  public shownLoadSpinner($event: any): void {
    this.loadSpinner = $event;
  }

  // Add additonal items
  public addTag(tag: string) {
    return {
      desc: tag,
      value: tag
    }
  }

  public getCreatedByNameWithEmailId(emailId: string): string {
    const getUser = this.asigneeList?.find((checkItem: any) => checkItem?.userId === emailId) || null;
    return getUser ? `${getUser?.lastName}, ${getUser?.firstName}` : emailId;
  }

  public checkIfFollowup(event: any) {
    const followupDate = this.claimJournalForm.get('followupDate')?.value;

    if (followupDate !== null && followupDate !== '') {
      this.claimJournalForm.get('isfollowUp').setValue(true);
      this.checkIfFollowupSelected();
    }
    else {
      this.isfollowUpSelected = false;
      this.claimJournalForm.get('isfollowUp').setValue(false);
      this.claimJournalForm?.get('followupDate')?.setValidators(null);
      this.claimJournalForm?.get('followupDate')?.updateValueAndValidity();

    }

  }

  public checkIfFollowupSelected() {
    const event = this.claimJournalForm.get('isfollowUp')?.value;

    const followupDate = this.claimJournalForm.get('followupDate')?.value;

    if (event) {
      this.isfollowUpSelected = true;
      this.claimJournalForm?.get('followupDate')?.setValidators([Validators.required]);
    }
    else {
      this.isfollowUpSelected = false;
      this.claimJournalForm?.get('followupDate')?.setValidators(null);
      this.claimJournalForm?.get('followupDate')?.setValue('');
    }
    this.claimJournalForm?.get('followupDate')?.updateValueAndValidity();


  }

  public mmddyyyyFormatSplit(date: string): string {
    //  return this.httpService?.getAppointmentTime(date) || date;
    let fDate: any;

    if (date?.includes('T')) {
      fDate = date?.toString()?.split('T');
    }
    else {
      fDate = date;
    }

    const splitDate = fDate?.toString()?.split('-');
    //console.log(splitDate);
    const followupDate = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0];
    //console.log(followupDate);

    return followupDate;

  }

  public formatServideDate(date: string): string {

    //  return this.httpService?.getAppointmentTime(date) || date;
    let fDate: any;
    if (date?.includes('T')) {
      fDate = date?.toString()?.split('T')[0];
    }
    else {
      fDate = date;
    }
    if (fDate) {
      const splitDate = fDate?.toString()?.split('-');
      console.log(splitDate);
      const followupDate = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0];
      console.log(followupDate);
      return followupDate;

    }

  }

  public lastcallDate(date: string): string {
    const fDate = date?.toString()?.split('T');
    const lastDate = fDate[0];
    const splitDate = lastDate?.toString()?.split('-');
    const followupDate = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0];

    return followupDate;

  }

  public resetJournalNotes() {


  }

  ngOnDestroy(): void {
    if (this.dtTriggerCliamJournalList) {
      // this.dtTriggerCliamJournalList?.unsubscribe();
    }
  }
}
