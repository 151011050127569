<div class="emergency-contact-details" [formGroup]="familyDetailsForm">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <!-- *ngIf="familyDetailsForm?.get('familyMemberdetails')?.value?.length < 5" -->
            <h5>Add Family Members 
			
			<!--<button class="btn btn-primary btn-addn-family" (click)="addMember()"><i
                        class="material-symbols-outlined">note_add</i>Add New</button>-->
			
			
			 <a type="button" class="family-add" id="family-contact-details-add" (click)="addMember()" placement="right" 
                        ngbTooltip="Add Family Member">
                <i class="las la-plus-circle" style="font-size: 30px; color: #5CA6DB; cursor: pointer;"></i>
            </a>
						
						
						</h5>
            <hr>
        </div>
    </div>


    <div class="formarray" formArrayName="familyMemberdetails">
        <ng-container
            *ngFor="let contactForm of familyDetailsForm?.get('familyMemberdetails')?.controls; let i = index">
            <div class="row insurance-items mt-2" [formGroupName]="i">

                <!--<div class="form-group">
            <label>Patient Type <span class="required">*</span></label><br />
            <label class="radio-inline" *ngFor="let type of addPatientTypeOfPatient;let i = index">
                <input formControlName="PATIENT_TYPE" (change)="changePatientType()" type="radio" name="PATIENT_TYPE"
                    [value]="type.value">
                    <span class="desc">{{type.desc}}</span>
                </label>
            <br />
        </div>-->



                <div class="col-lg-12 col-sm-12 col-md-12">

                    <div class="row col-md-3 ">

                        <input type="text" (click)="contactSearchModal(i)" class="form-control input-text" readonly
                            formControlName="EXISTING_PATIENT_SEARCH" name="validation-required" id="familyMember_search"
                            placeholder="Search Patient">


                    </div>


                   <!-- <div class="col-lg-4 col-md-4 col-sm-4">

                        <button (click)="clearFamilyDetails(i)" type="button" class="btn btn-primary dash-actions"
                            style="margin-left: 15px;"
                            *ngIf="contactForm?.get('EXISTING_PATIENT_SEARCH').value !== null">Clear</button>


                    </div>-->


                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="delete del-icon float-right d-flex" placement="left"
                        ngbTooltip="Delete Family Member">
                        <i class="las la-trash-alt" (click)="openDeleteContactConfirm(i, confirmContactCardDelete)" id="familyMember_delete"></i>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">First Name <span class="text-danger">*</span></label>
                        <input class="form-control input-text" type="text" formControlName="firstName"
                            placeholder="First Name" (blur)="onfamilyMemberdetailsChange(contactForm)"
                            id="FIRST_NAME " />
                        <span class="text-danger"
                            *ngIf="contactForm?.get('firstName').invalid && !contactForm?.get('firstName').errors?.required">Please
                            enter a valid First Name</span>
                        <span class="text-danger"
                            *ngIf="contactForm?.get('firstName').errors?.required && contactForm?.get('firstName').touched">
                            Please enter First Name
                        </span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                        <label for="Middle-name">Middle Name</label>

                        <input class="form-control input-text" type="text" formControlName="middleName"
                            placeholder="Middle Name" (blur)="onfamilyMemberdetailsChange(contactForm)"
                            id="middleName " />


                        <span class="text-danger"
                            *ngIf="contactForm?.get('middleName').errors?.required && contactForm?.get('middleName').touched">
                            Please enter Middle name
                        </span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                        <label for="Last-name">Last Name <span class="text-danger">*</span></label>

                        <input class="form-control input-text" type="text" formControlName="lastName"
                            placeholder="Last Name" (blur)="onfamilyMemberdetailsChange(contactForm)" id="lastName " />


                        <span class="text-danger"
                            *ngIf="contactForm?.get('lastName').errors?.required && contactForm?.get('lastName').touched">
                            Please enter Last name
                        </span>
                    </div>
                </div>


                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                        <label for="Middle-name">Date of Birth <span class="text-danger">*</span></label>

                        <input type="date" name="datemax" id="{{ 'dob' + i }}" formControlName="dateOfBirth"
                            class="form-control input-text" [max]="maxDate" [min]="minDOB" (keyup.enter)="validateManualEnterdob($event,contactForm)" (mouseout)="validateManualEnterdob($event,contactForm)" (blur)="validateManualEnterdob($event,contactForm)">
                        <span class="text-danger"
                            *ngIf="contactForm?.get('dateOfBirth').errors?.required && contactForm?.get('dateOfBirth').touched">Date
                            Of Bith is Required
                        </span>
                        <span class="text-danger"
                        *ngIf="contactForm.get('dateOfBirth').errors?.invalidDate && contactForm.get('dateOfBirth').touched">Please Enter Valid Date Of Birth</span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                        <label for="first-name">Gender <span class="text-danger">*</span></label>
                        <ng-select placeholder="Choose Gender" [items]="genderList" formControlName="gender" id="familyMember_gender"
                            bindLabel="desc" bindValue="value"></ng-select>
                        <span class="text-danger"
                            *ngIf="contactForm?.get('gender').errors?.required && contactForm?.get('gender').touched">Gender
                            is Required
                        </span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                        <label for="MaritalStatus">Marital Status <span class="text-danger" *ngIf="maritaMandatory">*</span> </label>

                        <ng-select placeholder="Choose Marital Status" [items]="maritalStatusList" id="maritalStatus"
                            formControlName="maritalStatus" bindLabel="desc" bindValue="value"></ng-select>

                        <span class="text-danger"
                            *ngIf="maritaMandatory && contactForm?.get('maritalStatus').errors?.required && contactForm?.get('maritalStatus').touched">Marital
                            Status is Required
                        </span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Race <span class="text-danger">*</span></label>

                        <ng-select placeholder="Choose Race" [items]="raceList" formControlName="race" bindLabel="desc" id="familyMember_race"
                            bindValue="value"></ng-select>

                        <span class="text-danger"
                            *ngIf="contactForm?.get('race').errors?.required && contactForm?.get('race').touched">Race
                            is Required
                        </span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="form-group">
                        <label for="last-name">Ethnicity <span class="text-danger">*</span></label>

                        <ng-select placeholder="Choose Ethnicity" [items]="ethnicityList" formControlName="ethnicity" id="familyMember_ethnicity"
                            bindLabel="desc" bindValue="value"></ng-select>

                        <span class="text-danger"
                            *ngIf="contactForm?.get('ethnicity').errors?.required && contactForm?.get('ethnicity').touched">Ethnicity
                            is Required
                        </span>
                    </div>
                </div>


            </div>
        </ng-container>

    </div>
</div>

<!-- confirm the delete insurance -->
<app-ui-modal #confirmContactCardDelete [hideHeader]="false" [modalCentered]="true" id="confirmContactCardDelete">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Are you sure want to delete Family member Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="confirmContactCardDelete?.hide();"><span aria-hidden="true">&times;</span></button>
        
    </div>
    <div class="app-modal-body">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-2">
    
            <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                <!-- <i class="las la-exclamation-triangle"></i> -->
                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>

            </h5>
    
          </div>
          <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">
    
            <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete Family member Details?</h5>
    
          </div>
        </div>    
    </div>
    <div class="app-modal-footer">

        <button type="button" id class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="confirmContactCardDelete?.hide(); deleteContactDetails(contactDeleteIndex)">Yes</button>
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="confirmContactCardDelete.hide()">No</button>
        
    </div>
</app-ui-modal>

<app-healthpass-patient-search [familyDetailsForm]="familyDetailsForm" [demographicForm]="familyDetailsForm"
    [selectedIndex]="selectedIndex" [screenName]="'healthpassFamily'"></app-healthpass-patient-search>