import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { CardComponent } from '../../components/card/card.component';
import { ClaimsService } from '../../services/claims.service';


@Component({
  selector: 'app-eligibility-check',
  templateUrl: './eligibility-check.component.html',
  styleUrls: ['./eligibility-check.component.scss']
})
export class EligibilityCheckComponent implements OnInit, OnChanges {

  @Input() public eligibilityDoctorDetails: any;
  @ViewChild('eligibilityCheck') private eligibilityCheck: UiModalComponent;
  public memberInfo: any;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionComp: ExceptionModalComponent;
  public planInfo: any;
  @Output() hideCardReload = new EventEmitter();
  @Output() reloadTable = new EventEmitter();
  @Input() cardLoader: CardComponent;

  constructor(private httpService: HttpService, private claimsService: ClaimsService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  // get doctor name from list
  public doctorNameWithNpi(npi: number): any{
    return this.eligibilityDoctorDetails?.doctor?.find((item: any) => npi === item?.number) || {};
  }

  public checkEligibilityPatient(getPrimaryInsurance: any, appointment: any): void{
    const action = `eligibility/checkForPatient`;
    const dateTime = appointment?.appointment?.dateTime?.split('T');
    const subscriberDob = getPrimaryInsurance?.subscriberdateOfBirth;
    const dob = appointment?.dob?.split('T');
    const doctor = this.doctorNameWithNpi(appointment?.appointment?.doctorNpi);
    const params = {
      "apptDate": dateTime && dateTime[0],
      "dateOfBirth": subscriberDob || (dob && dob[0]),
      "docFirstName": appointment?.appointment?.authorized_official_first_name || doctor?.basic?.authorized_official_first_name,
      "docLastName": appointment?.appointment?.authorized_official_last_name || doctor?.basic?.authorized_official_last_name,
      "doctorNpi": appointment?.appointment?.doctorNpi,
      "firstName": getPrimaryInsurance?.subscriberfirstname,
      "gender": getPrimaryInsurance?.subscribergender,
      "lastName": getPrimaryInsurance?.subscriberlastname,
      "memberId": getPrimaryInsurance?.memberId,
      "serviceTypeCode1": "98",
      "serviceTypeCode2": "",
      "serviceTypeCode3": "",
      "tradingPartnerServiceId": getPrimaryInsurance?.eligibilityRealtimePayerID || null,
      "userinfoId": appointment?.userInfoId,
      "appointmentId": appointment?.id
    }

    // const params = {
    //   "apptDate": "2021-12-20",
    //   "dateOfBirth": "1977-01-12",
    //   "docFirstName": "ORUGANTI NAGA",
    //   "docLastName": "VIJAYA",
    //   "doctorNpi": 1598887473, 
    //   "firstName": "RAM", 
    //   "gender": "Male",
    //   "lastName": "NEELAPU",
    //   "memberId": "940574232",
    //   "serviceTypeCode1": "98",
    //   "serviceTypeCode2": "",
    //   "serviceTypeCode3": "", 
    //   "tradingPartnerServiceId": 'UHC', 
    //   "userinfoId": "455b5b0909714ef2b9cbd8fdff997c62"
    // } 

    this.claimsService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if(data?.body?.status === 'SUCCESS'){
        if(data?.body?.subscriber === null && data?.body?.details === null && data?.body?.insuranceTypes === null && data?.body?.dependents === null && data?.body?.planinfo === null){
          this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = true;
          this.exceptionComp?.modalShow();
          this.reloadTable?.emit(true);
          this.hideCardReload.emit();
          return;
        }
        this.eligibilityCheck?.show();
        this.memberInfo = data?.body?.subscriber;
        this.planInfo = data?.body?.planinfo || [];
        this.reloadTable?.emit(true);
      }else{
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionComp?.modalShow();
        this.reloadTable?.emit(true);
      }
      this.hideCardReload.emit();
    },
    (error) => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionComp?.modalShow();
      this.hideCardReload.emit();
    })
  }

  // dateFormate 
  public dateFormate(dateNum: string): any{
    if(!dateNum){
      return null
    }
    const spDate = dateNum?.split('');
    const date = `${spDate[4]}${spDate[5]}/${spDate[6]}${spDate[7]}/${spDate[0]}${spDate[1]}${spDate[2]}${spDate[3]}`;
    return date || null;
  }

  public getBenefitsByCode(code: string, list: any[]): any[]{
    return list?.filter(item => item?.code === code);
  }

  // Eligibility check from Claims Queues
  public checkEligibilityFromClaims(claim?: any): void {
    const action = `eligibility/checkForPatient1?appointmentId=${claim?.appointmentId}`;
    this.cardLoader?.cardRefreshShow();
    this.claimsService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.subscriber === null && data?.details === null && data?.insuranceTypes === null && data?.dependents === null && data?.planinfo === null) {
          this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
          this.isSuccessNotify = true;
          this.exceptionComp?.modalShow();
          return;
        }
        this.eligibilityCheck?.show();
        this.memberInfo = data?.subscriber;
        this.planInfo = data?.planinfo || [];
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionComp?.modalShow();
      }
      this.cardLoader?.cardRefreshHide();
    },
      (error) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionComp?.modalShow();
        this.cardLoader?.cardRefreshHide();
      });
  }

}
