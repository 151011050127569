<div id="appointmentHistoryDetails" class="appointment-history-details " [ngClass]="{'toggle-expanded': addCss}">
    <div class="table-responsive new-designed-table table-enc">
        <table datatable [dtOptions]="dtOptionsAppntHistory" [dtTrigger]="dtTriggerAppntHistory"
            class="table table-borderless custom-table">
            <thead>
                <tr>
                    <th>SERVICE DATE </th>
                    <th>PURPOSE OF VISIT</th>
                    <th>SERVICE PROVIDER</th>

                    <th>STATUS</th>
                    <th>ORDERS</th>
                    <th>DIAGNOSTICS</th>
                    <th>VERIFY</th>
                    <th class="no-sort">ACTIONS</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of allAppntHistory; let i = index" >
                    <!--<td> <a 
                        [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                        class="view menulink" (click)="openViewAppointmentDetails(user?.appointmentRegistration?.id,i)">{{ getAppointmentTime(user?.appointmentRegistration?.appointment?.dateTime) }}</a></td>-->
						
					<td>{{ getAppointmentTime(user?.appointmentRegistration?.appointment?.dateTime) }}</td>
                    <!--<td>{{ doctorNameWithNpi(user?.appointment?.doctorNpi) }}</td>-->
                    <td>{{ user?.appointmentRegistration?.appointment?.purposeOfVisit || 'N/A' }}</td>
                    <td>{{ user?.appointmentRegistration?.appointment?.authorized_official_last_name | titlecase}}, {{
                        user?.appointmentRegistration?.appointment?.authorized_official_first_name | titlecase }}</td>


                    <td><button class="btn {{user?.appointmentRegistration?.appointmentStatus?.statusButtonClass}}">
                            {{getStatus(user?.appointmentRegistration?.appointmentStatus?.currentStatus, user) ||
                            '--'}}</button></td>
                    <td>
                        <span *ngIf="user?.rxOrder === true">RX</span>
                        <span *ngIf="user?.labOrder === true && user?.rxOrder === true"> / </span>
                        <span *ngIf="user?.labOrder === true">LAB</span>
                        <span *ngIf="(user?.rxOrder === true || user?.labOrder === true) && user?.radOrder === true"> /
                        </span>
                        <span *ngIf="user?.radOrder === true">RAD</span>
                        <span *ngIf="(user?.rxOrder === true || user?.labOrder === true || user?.radOrder === true)&& user?.emgOrder === true"> /
                        </span>
                        <span *ngIf="user?.emgOrder === true">EMG</span>
                        <span *ngIf="user?.labOrder === false && user?.rxOrder === false && user?.radOrder === false && user?.emgOrder === false">
                            
                            -- </span>
                    </td>
                    <td>
                        <button *ngIf= "(user?.labOrder&& user?.labOrderStatus =='F' )" (click)="openerifyReports(user,'LAB')" class="btn btn-primary {{(user?.labOrder&& user?.labOrderStatus =='F' )? 'btn-final' : 'btn-disabled'}}" ngbTooltip="{{(user?.labOrder&& user?.labOrderStatus =='F' )? 'Final Reported' : 'Not Available'}}">
                            {{(user?.labOrder&& user?.labOrderStatus =='F' )? 'FR' : 'NA'}}</button>
                            <span *ngIf="((user?.labOrder&& user?.labOrderStatus =='F' )) && user?.radOrder === true"> /
                            </span>
                            <button *ngIf= "(user?.radOrder&& user?.radOrderStatus =='F' )" (click)="openerifyReports(user,'RAD')" class="btn btn-primary {{(user?.radOrder&& user?.radOrderStatus =='F' )? 'btn-final' : 'btn-disabled'}}" ngbTooltip=" {{(user?.radOrder && user?.radOrderStatus =='F' )? 'Final Reported' : 'Not Available'}}">
                                {{(user?.radOrder && user?.radOrderStatus =='F' )? 'FR' : 'NA'}}</button>
                                <span *ngIf="( user?.labOrder === true || (user?.radOrder&& user?.radOrderStatus =='F' ))&& user?.emgOrder === true"> /
                                </span>
                                <button *ngIf= "(user?.emgOrder&& user?.emgOrderStatus =='F' )" (click)="openerifyReports(user,'EMG')" class="btn btn-primary {{(user?.emgOrder&& user?.emgOrderStatus =='F' )? 'btn-final' : 'btn-disabled'}}" ngbTooltip="{{(user?.emgOrder && user?.emgOrderStatus =='F' )? 'Final Reported' : 'Not Available'}}">
                                    {{(user?.emgOrder && user?.emgOrderStatus =='F' )? 'FR' : 'NA'}}</button>
                               <!-- <button  class="btn btn-primary btn-final">Final Reported</button>
                               <button  class="btn btn-primary btn-disabled">Not Available</button> -->
                               <span *ngIf="user?.labOrderStatus !='F' && user?.radOrderStatus !='F' && user?.emgOrderStatus !='F'">
                            
                                -- </span>
                    </td>
                    <td>
                        <!-- {{user?.labOrder ? user?.labDetails[0]?.reviewStatus : 'Not Available'}}/{{user?.radOrder ? user?.radDetails[0]?.reviewStatus : 'Not Available'}} -->
                       
                        <!-- <span class="done-state">Completed</span>
                        <span class="pending">Pending</span> -->
                        <span *ngIf = "user?.labOrder && user?.labDetails[0]?.reviewStatus"[ngClass]="{
                            'done-state': user?.labOrder && user?.labDetails[0]?.reviewStatus === 'COMPLETED',
                            'pending': user?.labOrder && user?.labDetails[0]?.reviewStatus === 'PENDING'
                          }">
                            {{ user?.labOrder ? user?.labDetails[0]?.reviewStatus || 'NA' : 'NA' }}
                          </span>
                          <span *ngIf = "user?.labOrder && user?.labDetails[0]?.reviewStatus && user?.radOrder && user?.radDetails[0]?.reviewStatus">/</span>
                          <span *ngIf = "user?.radOrder && user?.radDetails[0]?.reviewStatus" [ngClass]="{
                            'done-state':  user?.radOrder &&user?.radDetails[0]?.reviewStatus === 'COMPLETED',
                            'pending':  user?.radOrder && user?.radDetails[0]?.reviewStatus === 'PENDING'                            
                          }">
                            {{ user?.radOrder ? user?.radDetails[0]?.reviewStatus || 'NA' : 'NA' }}
                          </span>
                          <span *ngIf = "(user?.labOrder && user?.labDetails[0]?.reviewStatus || user?.radOrder && user?.radDetails[0]?.reviewStatus) && user?.emgOrder && user?.emgDetails[0]?.reviewStatus">/</span>
                          <span *ngIf = "user?.emgOrder && user?.emgDetails[0]?.reviewStatus" [ngClass]="{
                            'done-state':  user?.emgOrder &&user?.emgDetails[0]?.reviewStatus === 'COMPLETED',
                            'pending':  user?.emgOrder && user?.emgDetails[0]?.reviewStatus === 'PENDING'                            
                          }">
                            {{ user?.emgOrder ? user?.emgDetails[0]?.reviewStatus || 'NA' : 'NA' }}
                          </span>
                          <span *ngIf = "!(user?.labOrder && user?.labDetails[0]?.reviewStatus) && !(user?.radOrder && user?.radDetails[0]?.reviewStatus) && !(user?.emgOrder && user?.emgDetails[0]?.reviewStatus)">--</span>
                    </td>
                    <td>
                        <div class="dropdown-actions">
                            <button class="icon-queue-events more-actions-hover-icon  {{openActions && selectedActionRow === i ?'dropdown-content-actions-selected':''}}" id="claimActions_btn" (click)="openUniversalActions($event, i)">...</button>
                            <div class="dropdown-content-actions" *ngIf="openActions && selectedActionRow === i" (mouseenter)="mouseIn();" (mouseleave)="mouseOut();">
                                <a *ngIf="user?.appointmentType === 'Future' || user?.appointmentType === 'Current'"
                                    [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="time menulink" id="appHistoryForUser_reschedule"
                                    [class.a-disabled]="user?.appointmentRegistration?.appointmentStatus?.checkIn || user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                    (click)="reScheduleAppointment(user?.appointmentRegistration);closeUniversalActions();"><i placement="bottom"
                                        class="material-symbols-outlined">update</i> Reschedule </a>

                                <a *ngIf="user?.appointmentType === 'Future' || user?.appointmentType === 'Current'"
                                    [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="success menulink" id="appHistoryForUser_eligibility"
                                    [class.a-disabled]="user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                    [class.checkedEligibility]="user?.appointmentRegistration?.eligibilityFlag"
                                    (click)="checkEligibility(user?.appointmentRegistration);closeUniversalActions();"><i placement="bottom"
                                        class="material-symbols-outlined">check_circle</i> Eligibility</a>
                                <!-- <a *ngIf="user?.appointmentType === 'Past'"
                                    [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="view menulink" (click)="viewUserInfo(user?.appointmentRegistration?.id)"><i
                                        class="material-symbols-outlined">visibility</i> View</a> -->
                                <a *ngIf="user?.appointmentType === 'Past'"
                                    [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="view menulink" id="appHistoryForUser_view"
                                    (click)="openViewAppointmentDetails(user?.appointmentRegistration?.id, i);closeUniversalActions();"><i
                                        class="material-symbols-outlined">visibility</i>View</a>

                                <!-- *ngIf="user?.appointmentType === 'Future' || user?.appointmentType === 'Current'" -->
                                <a [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="edit menulink" id="appHistoryForUser_edit"
                                    [class.a-disabled]="user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CANCELLED' || user?.appointmentRegistration?.appointment?.purposeOfVisit === 'Legacy Medical Record'"
                                    (click)="editAppointment(user);closeUniversalActions();"><i placement="bottom"
                                        class="material-symbols-outlined">edit</i> Edit</a>
                                <a *ngIf="user?.appointmentType === 'Past'"
                                    [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="logout menulink" id="appHistoryForUser_addendum"
                                    (click)="openAddendumModalNotes(user?.appointmentRegistration);closeUniversalActions();"><i
                                        placement="bottom" class="material-symbols-outlined">create_new_folder</i>
                                    Addendum</a>
                                <a *ngIf="user?.appointmentType === 'Future' || user?.appointmentType === 'Current'"
                                    [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="delete menulink" id="appHistoryForUser_cancel"
                                    [class.a-disabled]="user?.appointmentRegistration?.appointmentStatus?.checkIn || user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CANCELLED'"
                                    (click)="cancelAppointment(user?.appointmentRegistration);closeUniversalActions();"><i placement="bottom"
                                        class="material-symbols-outlined">close</i> Cancel</a>
                                <a *ngIf="user?.appointmentType === 'Past'"
                                    [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="delete" id="appHistoryForUser_progressnotes"
                                    (click)="openJournamNotes(user?.appointmentRegistration, journalNotesModal);closeUniversalActions();"><i
                                        placement="bottom" class="las la-file-medical"></i> Progress Notes</a>
                                <a [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="delete menulink" id="appHistoryForUser_clinicalDoc"
                                    (click)="openUploadDocument(user?.appointmentRegistration);closeUniversalActions();"><i placement="bottom"
                                        class="material-symbols-outlined">upload_file</i> Clinical Documents</a>
                                <a *ngIf="user?.appointmentType === 'Past' && user?.appointment?.purposeOfVisit !== 'Legacy Medical Record'"
                                    [ngClass]="{'active': user?.appointmentRegistration?.appointmentStatus?.currentStatus === 'CHECKOUT'}"
                                    class="delete menulink" id="appHistoryForUser_mediRecord"
                                    (click)="openSignatureModal(signatureModal, user?.appointmentRegistration?.id, user?.appointmentRegistration?.appointment?.authorized_official_first_name, user?.appointmentRegistration?.appointment?.authorized_official_last_name);closeUniversalActions();"><i
                                        class="material-symbols-outlined" placement="bottom">download</i> Medical
                                    Record</a>
									
							   <a class="success menulink" id="appHistoryForUser_task" (click)="openTaskModal(user)"><i class="las la-tasks"></i> Task</a>
                               <a (click)="lateNotify(user?.appointmentRegistration);closeUniversalActions();" id="appHistoryForUser_notify"><i class="lab la-telegram"></i> Notify</a>
							   <!-- <a *ngIf="user?.appointmentType === 'Past'" id="doclink_{{user?.id}}"
                                    [class.a-disabled]="user?.clinicalDocumentCount === 0 || user?.clinicalDocumentCount === null"
                                    class="view menulink" (click)="viewDocumentInfo(user)">
									<span class="material-symbols-outlined">inventory</span> Document Review</a> -->
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--<div class="col-md-12 text-right mt-2">
        <button class="btn btn-default full-view"><i _ngcontent-frh-c243="" class="las la-expand"></i>&nbsp; Full View</button>
    
    </div>-->
    <div class="preview" id="previewDiv">
        <ng-container *ngIf="displayuserInfo">
            <div class="row" style="float:right;display:inline-table">

                <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                    (click)="showhidedetails()"><span aria-hidden="true">&times;</span></button>
            </div>

            <!-- View Past Visit Details -->
            <app-commom-past-visit-details-wizard class="table-visit" [appointmentId]="userAppointmentId"
                (cssRefresh)="refreshCss($event)" [showExpand]="true" [noAccountBalanace]="true"
                [isReviewDoc]="isReviewDoc"></app-commom-past-visit-details-wizard>

            <!-- <app-user-visit-details class="table-visit" [appointmentId]="userAppointmentId"
                (cssRefresh)=refreshCss($event)>
            </app-user-visit-details> -->

        </ng-container>
    </div>

    <!-- Spinner -->
    <div class="custom-loader justify-content-center" *ngIf="loadSpinner">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Journal Notes -->
<app-ui-modal #journalNotesModal class="common-modal" [hideHeader]="false" [loadSpinner]="journalNotesClick" [modalCentered]="true"
    id="journalNotesModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Progress Notes</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="journalNotesModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body" [formGroup]="journalNotes">
        <div class="row">
            <div class="col-md-6">
                <h6>Previous Notes</h6>
                <div class="table-responsive">
                    <table class="table table-bordered common-table">
                        <thead>
                            <tr>
                                <th>NOTES</th>
                                <th>DATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let notes of allCareFlowData?.progressNotes ">
                                <td>
                                    {{notes?.notes}}
                                </td>
                                <td>
                                    {{notes?.auditInfo?.createdDate | date: 'MM/dd/yyyy hh:mm a'}}
                                </td>

                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <h6>Progress Notes</h6>
                <textarea class="form-control" id="appHistoryForUser_notes" formControlName="notes" name="validation-required"
                    placeholder="Progress Notes"></textarea>
                <label class="text-danger"
                    *ngIf="journalNotes?.get('notes')?.errors?.required && journalNotes?.get('notes')?.touched">Notes is required</label>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button class="btn btn-primary dash-actions" style="margin-right: 15px;" type="button" id="appHistoryForUser_submit"
            (click)="journalNotesUpdate(journalNotesModal)" [disabled]="journalNotesClick">Submit
        </button>
    </div>
</app-ui-modal>

<app-addendum-notes-chart [addendamData]="allCareFlowData?.addendum"></app-addendum-notes-chart>

<!-- Upload additonal documents -->
<app-upload-additional-documents (notifyDocUpload)="updateNotifiyUpload($event)" [collection]="collectionName"
    [documentSelected]="additionalDocName"></app-upload-additional-documents>


<!-- Cancel Appointment compoenent -->
<app-cancel-appointment (onCancelAppntChange)="refresh()"></app-cancel-appointment>

<!-- re-schedule common appointment -->
<app-re-schedule-appointment (onReScheduleUpdateChange)="refresh()" [exceptionModal]="exceptionModal">
</app-re-schedule-appointment>


<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Eligibility check -->
<app-eligibility-check [eligibilityDoctorDetails]="waitTimeDetails?.eligibilityDetails"></app-eligibility-check>

<!-- Signature form -->
<app-ui-modal #signatureModal [hideHeader]="false" [modalCentered]="true" id="signatureModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Electronically Signed by {{this.providerLastName}}, {{this.providerFirstName}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="signatureModal?.hide();clearPad()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <form [formGroup]="signatureForm">
            <div class="signatureModal">

                <input type="hidden" class="form-control" formControlName="signature" value="{{signatureImg}}">
                <canvas hidden id="textCanvas"></canvas>
                <div class="text-center">
                    <strong>E-Signature:</strong> <br><br>

                    <div class="signature" #signature_section id="image-section">
                        <h5><i>{{this.providerLastName}}, {{this.providerFirstName}}</i></h5>
                    </div>

                </div>

                <!-- <span>
                    <input type="hidden" class="form-control" formControlName="signature" value="{{signatureImg}}">
                    <strong>Signature :</strong>
                    <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" id="test" ></canvas>

                    <br><button (click)="clearPad()">Clear</button>&nbsp;
                    <button color="secondary" (click)="savePad()">Save</button>
                    <p style="color:#5CA6DB">{{signatureImgString}}</p>
                    <img id= "signatureImg"src='{{ signatureImg }}' />
                </span>

                <span id="signatureValidation" class="required"
                    *ngIf="signatureForm?.get('signature')?.errors?.required && signatureForm?.get('signature')?.touched">
                    Signature should be signed and saved</span>-->
            </div>
        </form>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="appHistoryForUser_ok1"
            (click)="downloadMedicalRecord(selectedappointmentId,signatureModal,medicalRecordModal)">Ok</button>
    </div>
</app-ui-modal>


<!-- Display the Consent Form PDF -->
<app-ui-modal #medicalRecordModal [hideHeader]="false" [modalCentered]="true" id="medicalRecordModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Medical Record</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="medicalRecordModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="medical-record-pdf" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="appHistoryForUser_ok2"
            (click)="medicalRecordModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- Test Model -->
<app-ui-modal #testModal [hideHeader]="false" [modalCentered]="true" id="testModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Medical Record</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="testModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="consent-pdf">
            <iframe id="medical-record-pdf" frameBorder="0" width="100%" height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="appHistoryForUser_ok3"
            (click)="testModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<app-ui-modal #verifyAvailableReportModal [hideHeader]="false" [modalCentered]="true" id="verifyAvailableReportModal">
    <div class="app-modal-header">
        <h5 class="modal-title">Verify Reports</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="verifyAvailableReportModal.hide();refresh()"
        ><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <app-verify-reports *ngIf="LabShow" [userAppointmentDataVal] = "appointmentUSerData" [userData]="userInfo" [typeOfRecord] = "labType" (taskRecord)="openTaskModal($event)" (notifyValue)="lateNotify($event,'DocReview')" (refreshEncounter)="refresh();"></app-verify-reports> 

    </div>
    <!-- <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
           >Ok</button>
    </div> -->
</app-ui-modal>

<!-- Encounter View -->
<app-ui-modal #encounterViewDetails class="common-modal" [hideHeader]="false" [modalCentered]="true"
    id="encounterViewDetails">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title">Appointment Details</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="encounterViewDetails?.hide();viewEncounterDetailsAppId = null"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <ng-container *ngIf="viewEncounterDetailsAppId">
            <app-commom-past-visit-details-wizard [appointmentId]="selectedappointmentId" [noAccountBalanace]="true" (openNotifyModal)="sendNotification($event)">
            </app-commom-past-visit-details-wizard>
        </ng-container>
    </div>
    <div class="app-modal-footer">
        <div class="verify-stick-menu mt-3">
            <a href="javascript:;"   class="verify-prev">
              <span *ngIf="selectedApptIndex > 0 " (click)="callNextPrevEncounter('prev')">
                &lt; &nbsp; Previous
            </span> 
                </a>
            <ul class="stick-menu-list">
                <li class="menu-select">
                    <a class="btn btn-primary" (click)="encounterViewDetails?.hide(); viewEncounterDetailsAppId = null" data-dismiss="modal" id="appHistoryForUser_cancel">
                        Cancel
                    </a>
                </li>
            </ul>
            <a href="javascript:;"  class="verify-next">
                <span  *ngIf="selectedApptIndex < (allAppntHistory.length - 1)" (click)="callNextPrevEncounter('next')" id="appHistoryForUser_next">
                Next &nbsp; &gt;
            </span>
                
                </a>
        </div>
        <!-- <button type="button" class="btn btn-primary dash-actions" (click)="encounterViewDetails?.hide()"
            data-dismiss="modal">Ok</button> -->
    </div>
</app-ui-modal>

<!-- create task -->
<app-new-task [idType]="'appointmentId'"></app-new-task>

<!-- Late Notify PopUp -->
<app-send-sms-notify [pageType]="sendTypeDocs"></app-send-sms-notify>


<!-- Lock Notification -->
<app-ui-modal #lockAppointment [hideHeader]="true" [modalCentered]="true" id="lockAppointment">
    <div class="app-modal-body add-app-body">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5 class="text-center">{{getUserRole(selectedAppointmentEdit?.lockUser) === 'Doctor' ? 'Provider' : getUserRole(selectedAppointmentEdit?.lockUser)}} {{getUserName(selectedAppointmentEdit?.lockUser) || selectedAppointmentEdit?.lockUser}} is currently working on this Patient’s chart.</h5>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="appHistoryForUser_ok4"
            (click)="lockAppointment?.hide()">Ok</button>
    </div>
</app-ui-modal>



<!-- Lock Notification -->
<app-ui-modal #lockAppointmentEmpty [hideHeader]="true" [modalCentered]="true" id="lockAppointmentEmpty">
  <div class="app-modal-body add-app-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <h5 class="text-center">Some other person is working on this patient chart. Please refresh the list</h5>
        <!-- <h5 class="text-center" *ngIf="noLockUser">{{selectedAppointmentEdit?.roleType}} {{ selectedAppointmentEdit?.lastName}} is working on this patient's chart.</h5>-->
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="appHistoryForUser_ok5"
      (click)="lockAppointmentEmpty?.hide(); ">Ok</button>
  </div>
</app-ui-modal>