<app-ui-modal #paymentInsurance [modalCentered]="true" [loadSpinner]="loadSpinner" id="paymentInsurance">
    <div class="app-modal-header">
        <h5 class="modal-title">Payments</h5>
        <div class="ach-ar-ins">
            <label>ACH-AR Insurance</label>
        </div>
        <!-- <div class="add-service-sec">
            <ng-container>
                <button type="button" class="btn btn-secondary add-service" (click)="addPaymentServices()" id="navBar_addService">Add
                    Service</button>
            </ng-container>
        </div> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="paymentInsurance.hide();closeModal()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <form class="paymentform" [formGroup]="newPaymentServicesForm">
            <!-- Payment Category and Description with amount -->
            <ng-container *ngIf="newPaymentServicesForm?.get('paymentServicesForm')?.controls?.length > 0">
                <div class="ar-patient">
                    <div class="form-array-sec mt-2">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <h6 class="mt-2">
                                    <b>Category</b>
                                </h6>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <h6 class="mt-2">
                                    <b>Service Provider</b>
                                </h6>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <h6 class="mt-2">
                                    <b>Description</b>
                                </h6>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-12 ">
                                <h6 class="mt-2">
                                    <b>Amount</b>
                                </h6>
                            </div>
                        </div>
                        <div class="row" [formGroup]="paymentCategoryDescription">
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <div class="form-group">
                                    <ng-select formControlName="paymentCategory" placeholder="Choose Category"
                                        [items]="paymentCategories" (change)="changePaymentCategory($event)"></ng-select>
                                    <label class="required error"
                                        *ngIf="paymentCategoryDescription?.get('paymentCategory')?.errors?.required && paymentCategoryDescription?.get('paymentCategory')?.touched">Category
                                        is
                                        required</label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <div class="form-group">
                                    <ng-select formControlName="serviceProviderName" placeholder="Choose Service Provider"
                                        id="navBar_chooseProvider" bindLabel="doctorName"
                                        [items]="serviceProvider">
                                    </ng-select>
                                    <label class="required error"
                                        *ngIf="paymentCategoryDescription?.get('serviceProviderName')?.errors?.required && paymentCategoryDescription?.get('serviceProviderName')?.touched">Service
                                        Provider is required</label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <div class="form-group">
                                    <input class="form-control input-text" type="text" placeholder="Enter Payor Name"
                                        id="description" formControlName="payerName" />
                                    <label class="required error"
                                        *ngIf="paymentCategoryDescription?.get('payerName')?.errors?.required && paymentCategoryDescription?.get('payerName')?.touched">Insurance
                                        is required</label>
                                    <!-- <ng-select formControlName="description" placeholder="Choose Description"
                                                                    [items]="paymentDescriptions"></ng-select> -->
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12">
                                <div class="input-group input-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input type="text" mask="separator.2" formControlName="amount"
                                        class="form-control amount-form-contrl" placeholder="0.00"
                                        id="navBar_amount">
                                </div>
                                <label class="required error"
                                        *ngIf="paymentCategoryDescription?.get('amount')?.errors?.required && paymentCategoryDescription?.get('amount')?.touched">Amount
                                        is required</label>
                                <!-- <label class="required error">Amount
                                                    is required</label> -->
                            </div>
                            <!-- <div class="col-lg-1 col-md-1 col-sm-12 text-right">
                                <button class="icon-queue-events delete-service  delete-action-bg" id="navBar_delete"
                                    placement="bottom" (click)="deletePaymentServices(apI)" ngbTooltip="Delete">
                                    <i class="lar la-trash-alt"
                                        style="font-size: 20px; color: black; margin-top: 10x; cursor: pointer;"></i>
                                </button>
                            </div> -->
                        </div>
                    </div>

                </div>
            </ng-container>

            <ng-container>
                <hr>
                <h4 class="summary">Summary</h4>

                <!-- <div class="row">
                    <div class="col-md-4">
                        <label class="text-label">Current Visit
                            Charges</label>
                    </div>
                    <div class="col-md-8 text-right">
                        <label class="text-form-control">$ 00</label>
                    </div>
                </div> -->

                <div class="row">
                    <div class="col-md-4">
                        <label class="text-label">Total Charges</label>
                    </div>
                    <div class="col-md-8 text-right">
                        <label class="text-form-control">$ {{(paymentCategoryDescription?.get('amount')?.value || 0) |
                            number :
                            '1.2-2'}}</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <label class="amount-payable text-label">Payment Amount</label>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 text-right">
                        <label class="text-form-control"><b>$ {{(paymentCategoryDescription?.get('amount')?.value || 0)
                                | number :
                                '1.2-2'}}</b></label>
                    </div>
                </div>
                <ng-container *ngIf="totalChargesPayingCheck && pageName !== 'Healthpass'">
                    <h5 class="error">{{totalChargesPayingCheck}}</h5>
                </ng-container>
                <hr>
            </ng-container>


            <div class="row mt-2">
                <div class="col-lg-5 col-md-5 col-sm-12" [formGroup]="paymentTypeSelectionForm">
                    <!-- <h5 class="payment-type">Payment Type:</h5> -->
                    <h6 class="mt-2"><b>Payment Type</b></h6>
                    <div class="form-group">
                        <ng-select id="paymentTypeSel" (change)="changePayType($event)" formControlName="paymentType"
                            placeholder="Choose Payment Type" [items]="paymentType"></ng-select>
                        <label class="required error"
                            *ngIf="paymentTypeSelectionForm?.get('paymentType')?.errors?.required && paymentTypeSelectionForm?.get('paymentType')?.touched">Payment
                            Type is required</label>
                    </div>
                </div>
                <!-- Online mode Payment date capture -->
                <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit'">
                    <div class="form-group" [formGroup]="paymentTypeSelectionForm">
                        <h6 class="mt-2"><b>Payment Date</b></h6>
                        <input type="date" id="payment-online-date" [max]="maxStartDate" name="onlineDate"
                            class="form-control input-text" formControlName="onlineDate" placeholder="Payment Date">
                        <label class="required error"
                            *ngIf="paymentTypeSelectionForm?.get('onlineDate')?.errors?.required && paymentTypeSelectionForm?.get('onlineDate')?.touched">Payment
                            Date is required</label>
                        <label class="required error"
                            *ngIf="paymentTypeSelectionForm?.get('onlineDate')?.errors?.invalidDate && paymentTypeSelectionForm?.get('onlineDate')?.touched && !paymentTypeSelectionForm?.get('onlineDate')?.errors?.required">Invalid
                            Payment Date</label>
                    </div>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-12" *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Check'">
                    <div class="row" [formGroup]="checkNumberDateForm">
                        <div class="col-6">
                            <div class="form-group">
                                <h6 class="mt-2"><b>Check Number</b></h6>
                                <input class="form-control input-text" type="text" placeholder="Check Number" id="payment-check-number"
                                    formControlName="checkNumber" />
                                <label class="required error"
                                    *ngIf="checkNumberDateForm?.get('checkNumber')?.errors?.required && checkNumberDateForm?.get('checkNumber')?.touched">Check
                                    Number is Required</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <h6 class="mt-2"><b>Check Date</b></h6>
                                <input type="date" [max]="maxStartDate" id="payment-check-date" name="checkDate" class="form-control input-text"
                                    id="payment-check-date" formControlName="checkDate" placeholder="Check Date">
                                <label class="required error"
                                    *ngIf="checkNumberDateForm?.get('checkDate')?.errors?.required && checkNumberDateForm?.get('checkDate')?.touched">Check
                                    Date is required</label>
                                <label class="required error"
                                    *ngIf="checkNumberDateForm?.get('checkDate')?.errors?.invalidDate && checkNumberDateForm?.get('checkDate')?.touched && !checkNumberDateForm?.get('checkDate')?.errors?.required">Invalid Check Date</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-12"
                    *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS' && terminalTypes?.length > 1"
                    [formGroup]="paymentTypeSelectionForm">
                    <!-- <h5 class="mt-2">Terminal:</h5> -->
                    <h6 class="mt-2"><b>Terminal</b></h6>
                    <div class="form-group">
                        <ng-select formControlName="terminalType" placeholder="Choose Terminal" bindLabel="name"
                            [items]="terminalTypes"></ng-select>
                        <label class="required error"
                            *ngIf="paymentTypeSelectionForm?.get('terminalType')?.errors?.required && paymentTypeSelectionForm?.get('terminalType')?.touched">Terminal
                            is required</label>
                    </div>
                </div>
                <!-- <div
                    [ngClass]="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS' && terminalTypes?.length > 1 ? 'col-lg-1 col-md-1 col-sm-12' : 'col-lg-6 col-md-6 col-sm-12'">
                    <button class="icon-queue-events float-right"
                        style="background-color: #5ca6db;color: #fff;margin-right: 0px;" placement="left"
                        ngbTooltip="Notify - Account Balance" (click)="sendAccountBalanceNotify()"><i
                            class="lab la-telegram"></i></button>
                </div> -->
            </div>

            <!-- POS Terminal Payment -->
            <ng-container *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS'">
                <div class="accordion" id="paymentAccordion">

                    <div class="card">
                        <div class="card-header" id="posTerminalPayment">
                            <h2 class="text-left collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                <span class="pull-left">
                                    <form>
                                        <div class="custom-control">
                                            <label class="payment-label-name">POS Payment</label>
                                        </div>
                                    </form>
                                </span>
                                <span class="pull-left">
                                    Click on Make Payment to open payment in Terminal Device.
                                </span>
                                <span class="pull-right text-right">
                                    <img src="../../../../../assets/images/pay/pos.png"
                                        style="height: 50px; width: 50px;">
                                </span>
                            </h2>
                        </div>

                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Non POS'">
                <div id="paymentAccordion">
                    <!-- Credit Card Payment -->
                    <div class="card card-non-pos">
                        <div class="card-header" id="headingThree">
                            <h2 class="text-left" type="button" (click)="cardPayment()" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="pull-left">
                                    <form>
                                        <div class="custom-control">
                                            <!-- <input type="radio" class="custom-control-input" id="creditcardadio"
                                                name="nonPosPaymentsRadio" value="creditcardadio"> -->
                                            <label class="payment-label-name">Pay with Credit/Debit
                                                Card</label>
                                        </div>
                                    </form>
                                </span>
                                <span class="pull-left dnone">
                                    will be redirected to the ACH Payments website
                                    after submitting your order
                                </span>
                                <span class="pull-right text-right">
                                    <img src="../../../../../assets/images/pay/visa2.png">
                                    <img src="../../../../../assets/images/pay/discover2.png">
                                    <img src="../../../../../assets/images/pay/american-express.png">
                                    <img src="../../../../../assets/images/pay/mastercard2.png">

                                </span>
                            </h2>
                        </div>

                        <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
                            data-parent="#paymentAccordion">
                            <div class="card-body">
                                <form id="payment-form">
                                    <div id="card-container"></div>
                                    <div class="credit-card-btns">
                                        <div class="form-group" style=" text-align: center;">
                                            <button *ngIf="!enableNonPosCancelPayment" id="card-button"
                                                class="btn btn-primary dash-actions" type="button"><i
                                                    class="las la-check-double"></i>&nbsp; Submit Payment</button>
                                            <button *ngIf="enableNonPosCancelPayment" id="terminal-pay"
                                                class="btn btn-primary dash-actions" type="button"
                                                (click)="cancelPayment()">Cancel Payment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- Digital Wallets -->
                    <div class="card">
                        <div class="card-header" id="paycreditcard1">
                            <h2 class="text-left collapsed" type="button" (click)="applePayOpen();googlePayOpen()"
                                data-toggle="collapse" data-target="#paycreditcard" aria-expanded="false"
                                aria-controls="collapseThree">
                                <span class="pull-left">
                                    <form>
                                        <div class="custom-control">
                                            <!-- <input type="radio" class="custom-control-input" id="digitalwallet" name="nonPosPaymentsRadio"
                                                value="digitalwallet"> -->
                                            <label class="payment-label-name">Digital
                                                Wallets</label>
                                        </div>
                                    </form>
                                </span>
                                <span class="pull-left">
                                    You will be redirected to the Google pay
                                    & Apple Pay after submitting your order
                                </span>
                                <span class="pull-right text-right">

                                    <img src="../../../../../assets/images/pay/apple-pay.png">
                                    <img src="../../../../../assets/images/pay/google-pay.png">

                                </span>

                            </h2>
                        </div>
                        <div id="paycreditcard" class="collapse" aria-labelledby="paycreditcard1"
                            data-parent="#paymentAccordion">
                            <div class="card-body">
                                <div class="row" style="text-align: center;">
                                    <form style="width: 45%;">
                                        <button id="apple-pay-button" (click)="applePayOpen();"
                                            class="btn btn-primary dash-actions">Apple
                                            Pay</button>
                                    </form>
                                    <br />
                                    <form style="width: 45%;">
                                        <button (click)="googlePayOpen()"
                                            class="btn btn-primary dash-actions mb-3">Google Pay</button>
                                        <br />
                                        <div id="google-pay-button"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Check' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit' ">
                <div id="paymentAccordion">

                    <div class="card">
                        <div class="card-header" id="cashChecqueOtherPayments">
                            <h2 class="text-left collapsed" data-toggle="collapse" data-target="#cashChequePayments"
                                aria-expanded="false" aria-controls="cashChequePayments">
                                <span class="pull-left">
                                    <form>
                                        <div class="custom-control">
                                            <!-- <input type="radio" class="custom-control-input" id="achradio" name="nonPosPaymentsRadio"
                                                value="customEx"> -->
                                            <label
                                                class="payment-label-name">{{paymentTypeSelectionForm?.get('paymentType')?.value}}</label>
                                        </div>
                                    </form>
                                </span>
                                <span class="pull-left">
                                    <!-- Click on Make Payment to open payment in Terminal Device. -->
                                </span>
                                <span class="pull-right text-right">
                                    <!-- <img src="../../../../../assets/images/pay/Ach.png"> -->
                                </span>
                            </h2>
                        </div>

                    </div>
                </div>

            </ng-container>
        </form>


        <!-- Submit Payment Actions -->
        <div class="row mt-4">
            <div class="col-12">
                <div class="credit-card-btns text-center mt-4 mb-4">
                    <!-- If not selected any payment type -->
                    <ng-container *ngIf="!paymentTypeSelectionForm?.get('paymentType')?.value">
                        <button type="button" disabled class="btn btn-primary dash-actions"><i class="las la-check-double"></i>&nbsp;Submit Payment</button>
                    </ng-container>
                    
                    <ng-container *ngIf="paymentTypeSelectionForm?.get('paymentType')?.value === 'POS'">
                        <button type="button" (click)="createPosPayment()" class="btn btn-primary dash-actions"><i
                                class="las la-check-double"></i>&nbsp;
                            Submit Payment</button>
                    </ng-container>

                    <ng-container
                        *ngIf="(paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Check' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit') ">
                        <button type="button" class="btn btn-primary dash-actions" (click)="createCashPayment()"><i
                                class="las la-check-double"></i>&nbsp; Submit
                            Payment</button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- Success -->
<app-ui-modal #paySuccessModal [hideHeader]="true" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="paymentSuccessDailogue">
    <div class="app-modal-body">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="paySuccessModal?.hide(); clearSession(); loadPaymentInfo(); futureBalnceUpdate();"><span
                aria-hidden="true">&times;</span></button>

        <div class="success-container">
            <div class="row">
                <div class="col-md-12 text-success">
                    <i class="fa fa-check-circle" style="font-size: 50px;"></i>
                </div>
                <div class="col-md-12">
                    <h4>Payment Successful!</h4>
                </div>
                <div class="col-md-12">
                    <p>Transaction Number: {{successPayDetails?.paymentId}}</p>
                </div>
                <hr>
                <div class="col-md-12">
                    <p>
                        <span class="float-left">Amount Paid: </span>
                        <span class="float-right">$ {{successPayDetails?.amount}}</span>
                    </p>
                    <div class="link-text"
                        *ngIf="successPayDetails && successPayDetails?.paymentId && pageName !== 'Healthpass' && !accountLevelPay"
                        (click)="viewPaymentReciept(successPayDetails?.paymentId, commonPaymentInvoiceModal); futureBalnceUpdate();">
                        View/Print
                        Payment Receipt</div>

                    <div class="link-text" *ngIf="pageName === 'Healthpass'">
                        <div class="link-text-sub"
                            (click)="viewPaymentReciept(successPayDetails?.paymentId, commonPaymentInvoiceModal)">
                            View/Print Payment Receipt</div><br>
                        <div class="link-text-sub"
                            (click)="generateHealthpassPlanReceipt(planInvoiceModal); loadSpinner = false">View/Print
                            Plan Summary</div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" style="margin-right: 15px;" data-dismiss="modal"
            (click)="paySuccessModal.hide(); clearSession(); loadPaymentInfo(); futureBalnceUpdate(); loadSpinner = false">Close</button>
    </div>
</app-ui-modal>

<!-- Payment Invoice -->
<app-ui-modal #commonPaymentInvoiceModal [hideHeader]="false" [modalCentered]="true" id="commonPaymentInvoiceModal">

    <div class="app-modal-header">
        <h5 class="modal-title">Payment Receipt</h5>

        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="closeModal();commonPaymentInvoiceModal?.hide();loadPaymentInfo(); loadSpinner = false "><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="payment-reciept">
            <iframe id="{{recieptID || 'common-new-payment-reciept-embed'}}" frameBorder="0" width="100%"
                height="500px"></iframe>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary"
            (click)="closeModal();commonPaymentInvoiceModal?.hide();loadPaymentInfo(); loadSpinner = false ">Ok</button>
        <button *ngIf="pageName === 'Healthpass'" type="button" class="btn btn-primary" data-dismiss="modal"
            (click)="closeModal(); commonPaymentInvoiceModal?.hide(); loadSpinner = false;loadPaymentInfo();">Close</button>
    </div>
</app-ui-modal>


<!-- Payment Invoice -->
<app-ui-modal #planInvoiceModal [hideHeader]="false" [modalCentered]="true" id="planInvoiceModal">

    <div class="app-modal-header">

        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="planInvoiceModal?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <h5 class="modal-title">Plan Summary</h5>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="payment-reciept">

            <iframe id="healthpass-receipt" frameBorder="0" width="100%" height="500px"></iframe>

        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal"
            (click)="planInvoiceModal?.hide();">Close</button>
    </div>
</app-ui-modal>

<!-- Late Notify PopUp -->
<app-send-sms-notify [pageType]="'Claim AR'"></app-send-sms-notify>