import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../../../environments/environment';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';

@Component({
  selector: 'app-followup-notification',
  templateUrl: './followup-notification.component.html',
  styleUrls: ['./followup-notification.component.scss']
})
export class FollowupNotificationComponent implements OnInit {

@ViewChild('followupNotify') followupNotify: UiModalComponent;
loadSpinner: boolean;


  constructor() { }

  ngOnInit(): void {
  }
  
   public openModal() {
       
    this.followupNotify.show();
  
  }

}
