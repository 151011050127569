import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-lab-rad-order-details-modal',
  templateUrl: './lab-rad-order-details-modal.component.html',
  styleUrls: ['./lab-rad-order-details-modal.component.scss']
})
export class LabRadOrderDetailsModalComponent implements OnInit, OnChanges {

  @ViewChild('labOrderDetails') public labOrderDetailsModal: UiModalComponent;

  @Input() orderType: string;
  @Input() selectedOrderDetails: any;
  @Input() cardComp: CardComponent;
  @Input() showOrderDetails: any;

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {

    }
  }

  public openOrderDetailsPopup(orderType: string, showOrderDetails: any, selectedOrderDetails?: any,): void {
    this.orderType = orderType;
    this.selectedOrderDetails = selectedOrderDetails || null;
    this.showOrderDetails = showOrderDetails || null
    if (this.showOrderDetails) {
      this.labOrderDetailsModal?.show();
    } else {
      this.getOrderDetails();
    }
  }

  // to get Internal Lab Reults 
  public getOrderDetails(): void {
    const action = `changeHealth/getOrderinfobyOrder?location=${this.selectedOrderDetails?.location}&orderNumber=${this.selectedOrderDetails?.orderNumber}&testType=${this.orderType}`;
    this.cardComp?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.showOrderDetails = data?.responseObject || {};
        this.labOrderDetailsModal?.show();
      }
      this.cardComp?.cardRefreshHide();
    },
      (error) => {
        this.cardComp?.cardRefreshHide();
      })
  }

}
