<div class="summary-vitals-data">
    <ng-container *ngFor="let category of vitalsData; let cati = index">
        <h5 *ngIf="category?.resourceType !== 'Vitals' || cati !== 0">{{category?.resourceType}}</h5>
        <ng-contaier *ngFor="let section of category?.sections">
            <ng-contaier>
                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let answer of section?.answers">

                        <div class="form-group row mb-0 inline-form">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label class="labelStyle">{{answer?.label}}</label> <span class="label-colon"> :
                                </span>
                                <ul class="no-list-style inline-list"
                                    *ngIf="answer?.label !== 'Blood Pressure (Systolic/Diastolic)' && answer?.label !== 'Temperature (In *F)' && answer?.label !== 'Oxygen Saturation %'">
                                    <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                                        <li>{{value?.answer || '--'}}</li>
                                    </ng-container>
                                </ul>
                                <ul class="no-list-style inline-list"
                                    *ngIf="answer?.label === 'Blood Pressure (Systolic/Diastolic)'">
                                    <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                                        <li>Reading {{listi+1}}:&nbsp;<b>{{value?.vitalSignName}} &nbsp;
                                                {{value?.systolic || '--'}} / {{value?.diastolic || '--'}}</b>
                                            &nbsp;&nbsp;&nbsp;</li>
                                    </ng-container>
                                </ul>
                                <!-- Temperature -->
                                <ul class="no-list-style inline-list" *ngIf="answer?.label === 'Temperature (In *F)'">
                                    <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                                        <li>Reading {{listi+1}}:&nbsp;<b>{{value?.temperature || value?.answer ||
                                                '--'}}</b>&nbsp;&nbsp;&nbsp;</li>
                                    </ng-container>
                                </ul>
                                <!-- Oxygen Saturation -->
                                <ul class="no-list-style inline-list" *ngIf="answer?.label === 'Oxygen Saturation %'">
                                    <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                                        <li>Reading {{listi+1}}:&nbsp;<span
                                                *ngIf="value?.vitalSignName"><b>{{value?.vitalSignName}}&nbsp;-&nbsp;</b></span><b>{{value?.oxygenSaturation
                                                || value?.answer || '--'}}&nbsp;&nbsp;&nbsp;</b></li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>

                        <ng-container *ngIf="answer?.otherNotes">
                            <div class="form-group row mb-0 inline-form">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label class="labelStyle">{{answer?.label}} Additional Notes</label> <span
                                        class="label-colon"> :
                                    </span>
                                    <label>{{answer?.otherNotes || '--'}}</label>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </ng-contaier>
        </ng-contaier>
        <ng-container *ngIf="!category?.sections || category?.sections?.length === 0">
            <span>No {{category?.resourceType}} are captured for this patient.</span>
        </ng-container>

        <ng-container *ngIf="category?.otherNotes">
            <div class="form-group row mb-0 inline-form">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                    </span>
                    <label>{{category?.otherNotes || '--'}}</label>
                </div>
            </div>
        </ng-container>

    </ng-container>
</div>