<div class="container" id="final-checklist-page" [formGroup]="finalCheckListForm">

  <div class="row col-center">
    <div class="col-md-4" [formGroup]="finalCheckListForm?.get('followUp')">
      <div class="form-group followup-form">
        <label for="followupreson">Follow-Up Reason</label>
        <textarea class="form-control" formControlName="reason" placeholder="Write Follow-Up Reason"
          id="reason"></textarea>
        <span class="text-danger"
          *ngIf="finalCheckListForm?.get('followUp')?.get('reason')?.errors?.required && finalCheckListForm?.get('followUp')?.get('reason')?.touched">Follow-Up
          reason is required</span>
      </div>
    </div>
    <div class="col-md-8">
      <ul class="checklist-items">
        <li>
          <a style="cursor:pointer;" (click)="openReSchedule()"><i class="material-symbols-outlined" placement="bottom"
              ngbTooltip="Schedule">history</i>
          </a>
        </li>
        <li>
          <a style="cursor:pointer;" (click)="openScheduleFollowup()"><i class="material-symbols-outlined"
              placement="bottom" ngbTooltip="Follow-up Reminder">add_task</i>

          </a>

          <!-- <a style="cursor:pointer;" (click)="openScheduleFollowup(scheduleFollowupModal)"><i
            class="material-symbols-outlined" placement="bottom" ngbTooltip="Schedule Follow-up">add_task</i>

        </a>-->
        </li>
        <li>
          <a style="cursor:pointer;" (click)="openDischargeInstructions(dischargeInstructionsModal)"><i
              class="material-symbols-outlined" placement="bottom"
              ngbTooltip="Post Visit Summary Handout">rate_review</i> </a>
        </li>
        <li>
          <!-- <a style="cursor:pointer;" (click)="downLoadTreatmentFlow()"><i class="material-symbols-outlined"
              placement="bottom" ngbTooltip="Medical History">download_for_offline</i> </a>-->
          <a style="cursor:pointer;" (click)="openSignatureModal(signatureModal)"><i class="material-symbols-outlined"
              placement="bottom" ngbTooltip="Medical History">download_for_offline</i> </a>
        </li>
        <li>
          <a style="cursor:pointer;" (click)="openPayment()"><i class="material-symbols-outlined" placement="bottom"
              ngbTooltip="Pay">credit_card</i>
          </a>
        </li>
        <li>
          <a style="cursor:pointer;" (click)="openDocUpload(uploadDocumentsModal)"><i class="material-symbols-outlined"
              placement="bottom" ngbTooltip="Upload Documents">upload_file</i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="selfpayCheck">
    <hr>
    <app-billing-codes [appointmentId]="appointmentId" [selectedUserInfoId]="selectedUserInfoId"
      [paidAmount]="paidAmount" (updateAmountPaid)="updateAmountPaid()"></app-billing-codes>
  </div>


  <!-- notify popup -->
  <app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

  <!-- follow-up appointment -->
  <app-re-schedule-appointment [exceptionModal]="exceptionModal" [followUp]="true"
    (followUpCheck)="checkFollowUp($event)"></app-re-schedule-appointment>

  <app-schedule-followup-appointment [appointmentId]="appointmentId"
    [scheduleType]="scheduleType"></app-schedule-followup-appointment>


  <!-- Schedule Follow up -->

  <app-ui-modal #scheduleFollowupModal [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
    id="scheduleFollowupModal">
    <div class="app-modal-header">
      <h5 class="modal-title">Schedule Follow-Up</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="scheduleFollowupModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="app-modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Patient’s Phone Number<span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="phonenumber" placeholder="Patient’s Phone Number">
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Purpose Of Visit<span class="text-danger">*</span></label>
                <select class="form-control">
                  <option disabled selected hidden>Select Purpose Of Visit</option>
                  <option>New Patient Visit, Establish care</option>
                  <option>Annual Wellness Exam</option>
                  <option>Sick</option>
                  <option>Womens' Care</option>
                  <option>65 and above Annual Wellness Visit</option>
                  <option>Medication Refills</option>
                  <option>Blood work</option>
                </select>
                <div class="required">Notification
                  Type is Required</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Follow-up Date<span class="text-danger">*</span></label>
                <input type="date" class="form-control">
                <div class="required">Notification
                  Type is Required</div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Cohort Panel</label>
                <select class="form-control">

                </select>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-md-6">

              <div class="form-group">
                <label>Care Gap</label>
                <select class="form-control">

                </select>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Follow-up Reason<span class="text-danger">*</span></label>
                <textarea class="form-control" placeholder="Write Message....." id="notificationMessage"
                  style="height: 100px"></textarea>
                <div class="required">Notification
                  Type is Required</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" id="finalCheckList_submit"
        data-dismiss="modal">Submit</button>
    </div>
  </app-ui-modal>

  <!-- Post-Visit Summary -->
  <app-ui-modal #dischargeInstructionsModal [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
    id="dischargeInstructionsModal">
    <div class="app-modal-header">
      <h5 class="modal-title">Post Visit Summary Handout</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="dischargeInstructionsModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="app-modal-body">
      <div class="row" [formGroup]="dischargeForm">
        <div class="col-lg-12 col-md-12 col-sm-12 patient-info-sec">
          <span class="name">{{patientDetailsInfo?.lastName | titlecase}}, 
            {{patientDetailsInfo?.firstName  | titlecase}}
            {{patientDetailsInfo?.middleName | titlecase}}
              </span>
          <span class="gender-age">{{patientDetailsInfo?.gender}}&nbsp;&nbsp;&nbsp;{{patientDetailsInfo?.age}}</span>
        </div>
        <!-- chief complaints section -->
        <div class="col-lg-12 col-md-12 col-sm-12 mt-2 patient-info-sec">
          <h5>Chief Complaints:</h5>
          <!-- <ul class="chief-complaints mt-2 mb-0">
                    <li class="cc-names" *ngFor="let chCmp of chiefCmpHeader">{{chCmp?.item || chCmp}}</li>
                  </ul> -->
          <ng-container *ngFor="let category of treatmentFlowData?.chiefComplaints; let cati = index">
            <ng-contaier *ngFor="let section of category?.sections">
              <ng-contaier *ngFor="let answers of section?.answers; let last = last">
                <div class="chief_list">
                  <span *ngFor="let value of answers?.actualValue">{{value?.answer}}<span
                      *ngIf="value?.answer === 'Other' && value?.notes">&nbsp;&nbsp;-&nbsp;&nbsp;<b>{{value?.notes}}</b></span></span>
                </div>
                <span *ngIf="!last">,&nbsp;&nbsp;</span>
              </ng-contaier>
              <ng-container *ngIf="!section?.answers || section?.answers?.length === 0">
                <label class="cc-names">Chief Complaints are unavailable.</label>
              </ng-container>
            </ng-contaier>
            <ng-contaier *ngIf="category?.otherNotes">
              <div class="form-group row mb-1">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <label class="labelStyle cc-names">Additional Notes</label> <span class="label-colon"> :
                  </span>
                  <!-- </div>
                                      <div class="col-lg-6 col-md-6 col-sm-6"> -->
                  <label class="cc-names">{{category?.otherNotes || '--'}}</label>
                </div>
              </div>
            </ng-contaier>
          </ng-container>
          <ng-container *ngIf="!treatmentFlowData?.chiefComplaints || treatmentFlowData?.chiefComplaints?.length === 0">
            <span class="cc-names">Chief Complaints are unavailable.</span>
          </ng-container>
        </div>

        <!-- <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="checkIfOtherSelects">
        <label><b>Other Complaints: </b>{{treatmentFlowData?.chiefComplaints?.otherNotes || '--'}} </label>
      </div> -->
        <!-- Active Prescriptions: -->
        <div class="col-lg-12 col-md-12 col-sm-12 mt-2 patient-info-sec">
          <h5>Active Prescriptions:</h5>
          <!-- <ul class="chief-complaints mt-2 mb-0" *ngIf="pastVisitMedications && pastVisitMedications?.length > 0">
            <li class="cc-names" *ngFor="let medication of pastVisitMedications">{{medication?.drug_name}}</li>
          </ul> -->
          <ng-container *ngIf="pastVisitMedications && pastVisitMedications?.length > 0">
            <span class="chief_list" *ngFor="let medication of pastVisitMedications;let li = index">{{medication?.drug_name}}<span *ngIf="(li+1) < pastVisitMedications?.length">,&nbsp;&nbsp;</span> </span>
          </ng-container>
          <ng-container *ngIf="!pastVisitMedications || pastVisitMedications?.length === 0">
            <span class="cc-names">Active Prescriptions are unavailable.</span>
          </ng-container>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 mt-2 patient-info-sec">
          <h5>Lab/Rad Requisitions:</h5>
          <div class="table-responsive new-designed-table" *ngIf="alllabRadOrderDetails?.length > 0">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <!-- <th>SI.NO</th> -->
                  <th>Order Number</th>
                  <th>Order Type</th>
                  <!-- <th>Order Date</th> -->
                  <th>Order Status</th>
                  <th>Lab Name</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let labOrder of alllabRadOrderDetails; let i = index">
                  <tr>
                    <!-- <td>{{i+1}}</td> -->
                    <td>{{labOrder?.placer_order_number || '--'}}</td>
                    <td>{{labOrder?.order_type || '--'}}</td>
                    <!-- <td>{{labOrder?.request_date || '--'}}</td> -->
                    <td>{{statusCodeDesc(labOrder?.order_status) || '--'}}</td>
                    <td>{{labOrder?.lab_name || '--'}}</td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="alllabRadOrderDetails?.length === 0">
                  <tr>
                    <td colspan="4" style="text-align: center;">Lab/Rad Requisitions are unavailable.</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <ng-container *ngIf="!alllabRadOrderDetails || alllabRadOrderDetails?.length === 0">
            <span>Lab/Rad Requisitions are unavailable.</span>
          </ng-container>
        </div>

        <!-- Care Plan -->
        <div class="col-lg-12 col-md-12 col-sm-12 mt-2 patient-info-sec mb-2">
          <h5>Care Plan:</h5>
          <div class="item-info-message">
            <ng-container *ngIf="dupTreatmentFlowData?.carePlan">       
              <div class="table-responsive"
                *ngIf="dupTreatmentFlowData?.carePlan && dupTreatmentFlowData?.carePlan?.actualValue?.length > 0">
                <h6><u>Diagnosis</u></h6>
                <table class="table table-careplan-summary table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>ICD Code</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tbody of dupTreatmentFlowData?.carePlan?.actualValue; let listi = index">
                      <td>{{tbody?.code}}</td>
                      <td>{{tbody?.description}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
        
              <ng-container *ngIf="dupTreatmentFlowData?.carePlan?.otherNotes">
                <div class="form-group row">
                  <div class="col-lg-3 col-md-3 col-sm-4">
                    <label class="labelStyle cc-names">Additional Notes</label> <span class="label-colon"> :
                    </span>
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-8">
                    <label class="cc-names">{{dupTreatmentFlowData?.carePlan?.otherNotes || '--'}}</label>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="!dupTreatmentFlowData?.carePlan || (!dupTreatmentFlowData?.carePlan?.otherNotes && dupTreatmentFlowData?.carePlan?.actualValue?.length === 0)">
              <span class="cc-names">Care Plan is unavailable.</span>
            </ng-container>
          </div>
        </div>

        <!-- Post-Visit Summary field -->
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label>Summary <span class="required">*</span></label>
            <textarea class="form-control" rows="4" type="text" placeholder="Enter Post Visit Summary"
              formControlName="notes" id="dischargeInstructions"></textarea>
            <span class="required"
              *ngIf="dischargeForm?.get('notes')?.errors?.required && dischargeForm?.get('notes')?.touched">Post Visit
              Summary is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
        id="finalCheckList_savesend" (click)="saveDischargeInstructions(dischargeInstructionsModal)">Send</button>
    </div>
  </app-ui-modal>


  <!-- Upload Documents Modal -->
  <app-ui-modal #uploadDocumentsModal [hideHeader]="false" [modalCentered]="true" [loadSpinner]="loadSpinner"
    id="uploadDocumentsModal">
    <div class="app-modal-header">
      <h5 class="modal-title" style="font-size:15px;">Upload any Clinical documents about this patient visit</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="uploadDocumentsModal.hide();documentForm?.reset()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="app-modal-body">
      <div class="row" [formGroup]="documentForm">
        <div class="col-md-12">
          <div class="form-group choosefile">
            <label for="documenttype">Document Type <sup class="mandatory">*</sup></label>
            <ng-select placeholder="Add or Choose Document Type" bindLabel="documentType" bindValue="documentType"
              formControlName="documentType" [items]="addDocumentTypes" [addTag]="true"></ng-select>
            <span class="text-danger"
              *ngIf="documentForm?.get('documentType')?.errors?.required && documentForm?.get('documentType')?.touched">Document
              Type is Required</span>
          </div>
        </div>

        <div class="col-md-12">

          <form data-validation="true" action="#" method="post" class="custom-file-container"
            enctype="multipart/form-data">
            <div class="custom-file">
              <div class="">
                <div class="image-upload">
                  <label (change)="uploadFileSelect($event)" style="cursor: pointer;width: 100%;"
                    for="additonal-documents-upload">
                    <!-- <i class="las la-cloud-upload-alt"></i> -->
                    <i class="material-symbols-outlined">
                      upload_file
                    </i>

                    <h4><input type="file" class="custom-file-input" multiple id="additonal-documents-upload"
                        data-required="image" #additionalDocs type="file" name="additonal-documents-upload"
                        data-traget-resolution="image_resolution" value="" accept="application/pdf, image/*">
                      <label class="custom-file-label" for="additonal-documents-upload"
                        (change)="uploadFileSelect($event)"></label>
                    </h4>
                    <p> Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be uploaded </p>


                  </label>
                </div>
              </div>
              <!--item-content-->
            </div>
            <!--item-inner-->

          </form>
          <div class="uploaded-data" *ngIf="documentsList && documentsList.length > 0">
            <h5>Uploading - {{documentsList.length}}/{{documentsList.length}} files</h5>

            <ul class="uploaded-files" *ngFor="let doc of documentsList; let index = index">
              <li class="success" id="fileName{{index}}">
                {{doc.name}} <span class="material-symbols-outlined" (click)="deleteSelectedFile(index)"
                  style="cursor:pointer;">delete</span>

              </li>

            </ul>
            <div class="text-left">
              <button class="btn btn-primary" (click)="additionalDocsUpload(uploadDocumentsModal)"
                id="finalCheckList_uploadfiles">UPLOAD Files</button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="app-modal-footer">
      <!--<button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
      (click)="saveDischargeInstructions(dischargeInstructionsModal)">Save</button>-->
    </div>
  </app-ui-modal>

  <!-- <app-payment [paymentConfigDetails]="paymentConfigDetails"></app-payment> -->


  <!-- Signature form -->
  <app-ui-modal #signatureModal [hideHeader]="false" [modalCentered]="true" id="signatureModal">
    <div class="app-modal-header">
      <h5 class="modal-title">Electronically Signed by {{this.providerLastName}}, {{this.providerFirstName}}</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="signatureModal?.hide();clearPad()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
      <form [formGroup]="signatureForm">
        <div class="signatureModal">
          <input type="hidden" class="form-control" formControlName="signature" value="{{signatureImg}}">
          <canvas hidden id="textCanvas"></canvas>
          <div class="text-center">
            <strong>E-Signature:</strong><br><br>

            <div class="signature" #signature_section id="image-section">
              <h5><i>{{this.providerLastName}} {{this.providerFirstName}}</i></h5>
            </div>

          </div>
          <!-- <span>
          <input type="hidden" class="form-control" formControlName="signature" value="{{signatureImg}}">
          <strong>Signature :</strong>
          <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)"></canvas>

          <br><button (click)="clearPad()">Clear</button>&nbsp;
          <button color="secondary" (click)="savePad()">Save</button>
          <p style="color:#5CA6DB">{{signatureImgString}}</p>
          <img id= "signatureImg"src='{{ signatureImg }}' />
        </span>

        <span id="signatureValidation" class="required"
          *ngIf="signatureForm?.get('signature')?.errors?.required && signatureForm?.get('signature')?.touched">
          Signature should be signed and saved</span>-->
        </div>
      </form>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="finalCheckList_ok1"
        (click)="downLoadTreatmentFlow(signatureModal,medicalRecordModal) ">Ok</button>
    </div>
  </app-ui-modal>

  <!-- Display the Consent Form PDF -->
  <app-ui-modal #medicalRecordModal [hideHeader]="false" [modalCentered]="true" id="medicalRecordModal">
    <div class="app-modal-header">
      <h5 class="modal-title">Medical Record</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="medicalRecordModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
      <div class="consent-pdf">
        <iframe id="medical-record-pdf" frameBorder="0" width="100%" height="500px"></iframe>
      </div>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="finalCheckList_ok2"
        (click)="medicalRecordModal.hide()">Ok</button>
    </div>
  </app-ui-modal>

  <!-- Payment Invoice -->
  <app-ui-modal #finalCheckListPayRecieptModal [hideHeader]="false" [modalCentered]="true"
    id="finalCheckListPayRecieptModal">
    <div class="app-modal-header">
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="finalCheckListPayRecieptModal?.hide();loadPaymentInfo()"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <h5 class="modal-title">Payment Receipt</h5>
    </div>
    <div class="app-modal-body add-app-body">
      <div class="payment-reciept">
        <iframe id="final-check-list-common-new-payment-reciept-embed" frameBorder="0" width="100%"
          height="500px"></iframe>
      </div>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-primary" data-dismiss="modal" id="finalCheckList_close"
        (click)="finalCheckListPayRecieptModal?.hide();loadPaymentInfo()">Close</button>
    </div>
  </app-ui-modal>

  <!-- Square Payment Card -->
  <!-- <app-payments-common-new [paymentConfigDetails]="paymentConfigDetails"
    (receiptUrlShown)="renderRecieptUrl($event, finalCheckListPayRecieptModal)" [pageName]="'finalChecklist'"
    (checklistPayment)="loadPaymentInfo()" [recieptID]="'fine-checklist-reciept-id'"></app-payments-common-new> -->

  <app-payments-refactor [paymentConfigDetails]="paymentConfigDetails"
    (receiptUrlShown)="renderRecieptUrl($event, finalCheckListPayRecieptModal)" [pageName]="'finalChecklist'"
    (checklistPayment)="loadPaymentInfo()" [recieptID]="'fine-checklist-reciept-id'"></app-payments-refactor>