import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, delay, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { AppRegExpressionsConfig } from '../../litterals/app.regularexpressions';
import { HttpService } from '../../services/http.service';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';

@Component({
  selector: 'app-claim-submission',
  templateUrl: './claim-submission.component.html',
  styleUrls: ['./claim-submission.component.scss']
})
export class ClaimSubmissionComponent implements OnInit, AfterViewInit, OnDestroy {

  public allProviderNpiList = [];
  public claimSubmissionGenders = [
    { desc: 'Male', value: 'M' },
    { desc: 'Female', value: 'F' },
    { desc: 'Other', value: 'U' },
    { desc: 'Unknown', value: 'U' }
    // { value: "Male", desc: "Male" },
    // { value: "Female", desc: "Female" },
    // { value: "Trans Female", desc: "Trans Female" },
    // { value: "Trans Male", desc: "Trans Male" },
    // { value: "Non-binary", desc: "Non-binary" },
    // { value: "Other", desc: "Other" },
    // { value: "Unknown", desc: "Unknown" },
  ];
  public allPos = [];
  public claimInformation: FormArray;
  public claimSubmissionFrom: FormGroup;
  public billingProviderForm: FormGroup;
  public memberDetailsForm: FormGroup;
  public claimAppointmentDetails: any;
  @ViewChild('claimSubmissionCard') private claimSubCard: CardComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionComp: ExceptionModalComponent;
  public providersList = [
    // { npi: "New Claim", providerType: "New Claim" }
  ];
  public prepareClaimSubmissionData: any;
  public healthCareCodeInformation: FormArray;

  // icd codes search
  public allDiagnosisObservebale: Observable<any>;
  public icdCodesLoading = false;
  public diagnosisInput = new Subject<string>();
  // public icdCodesSelected: any[] = <any>[];

  // cpt codes search
  public allCptCodesObservebale: Observable<any>;
  public cptCodesLoading = false;
  public cptCodesInput = new Subject<string>();
  // public cptCodesSelected: any[] = <any>[];

  public diagnosisPointersList = [];
  public claimSubmitMessage: string;
  public icdSerchItems = [];
  public getRouterParams: Subscription
  @Input() appointmentId: any;

  // ICD Assesssts Auto Complete 
  public allIcdCodesObservebale: Observable<any>;
  public icdInput = new Subject<string>();

  // cpt code autocomplete
  public allBillingCodes: Observable<any>;
  public billingCodesLoading = false;
  public billingInput = new Subject<string>();
  public billingCodesSelected: any[];
  public claimAdditonalNotesForm: FormGroup;
  claimErrorValidation: any;
  public isExternalClaims: boolean;
  selectedViewAppointmentId: any;
  public newProviderTypeAdded: boolean;
  claimProcessingFlag: any;
  payerClaimControlNumber: any;
  correctVoidedClaim: any;
  public drugIdentificationFormGroup: FormGroup;
  public unitOfMeasureList = [
    { desc: 'F2 - International Unit', value: 'F2' },
    { desc: 'GR - Gram', value: 'GR' },
    { desc: 'ME - Milligram', value: 'ME' },
    { desc: 'ML - Milliliter', value: 'ML' },
    { desc: 'UN - Unit', value: 'UN' }
  ]
  individualServiceLineFormGroup: FormGroup;
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  public allClaimInformationInvalidaFields: any;
  public isDrugInfomationAvailable: any;
  public paperClaimsPayerAddress: FormGroup;
  public claimedInfo = [];

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private router: Router, private activatedRoute: ActivatedRoute) {
    // this.getRouterParams = this.activatedRoute?.params?.subscribe(params => {
    //   const appointmentId = params['appointmentId'];
    //   this.appointmentId = appointmentId;
    // });

    this.activatedRoute?.queryParams?.subscribe(
      (params: Params) => {
        if (params && params['external'] === 'yes') {
          this.isExternalClaims = true;
        }
        this.claimProcessingFlag = params['claimProcessingFlag'];
        this.correctVoidedClaim = params['corrctedClaim'];
        this.payerClaimControlNumber = params['payerControlNumber'];
      });

    this.claimSubmissionFrom = this.formBuilder.group({
      "claimInformation": this.formBuilder.array([]),
      // 'serviceDate': [null, Validators?.required],
      "healthCareCodeInformation": this.formBuilder.array([])
    });

    this.billingProviderForm = this.formBuilder.group({
      "npi": [null, Validators?.required],
      "firstName": [null, Validators?.required],
      "lastName": [null, Validators?.required],
      "employerId": [null],
      "stateLicenseNumber": [null, Validators?.required],
      "address": this.formBuilder.group({
        "address1": [null, Validators?.required],
        "address2": [null],
        "city": [null, Validators?.required],
        "state": [null, Validators?.required],
        "postalCode": [null, Validators?.required],
        "countryCode": [null],
        "countrySubDivisionCode": [null]
      }),
      "contactInformation": this.formBuilder.group({
        "email": [null, Validators.compose([Validators.pattern(AppRegExpressionsConfig?.email)])],
        "faxNumber": [null, Validators?.required],
        "phoneNumber": [null, Validators?.required],
        "name": [null, Validators?.required]
      }),
      "providerType": [null, Validators?.required],
      "taxonomyCode": [null, Validators?.required],
      "organizationName": [null]
    });

    this.memberDetailsForm = this.formBuilder.group({
      "memberId": [null, Validators?.required],
      // "patientAccountNumber": [null],
      "firstName": [null, Validators?.required],
      "lastName": [null, Validators?.required],
      "groupNumber": [null],
      "dateOfBirth": [null, Validators?.required],
      "gender": [null, Validators?.required],
      "paymentResponsibilityLevelCode": [null],
      "address": this.formBuilder.group({
        "address1": [null, Validators?.required],
        "city": [null, Validators?.required],
        "state": [null, Validators?.required],
        "postalCode": [null, Validators?.required],
        "address2": [''],
        "countryCode": [null],
        "countrySubDivisionCode": [null]
      }),
      "policyNumber": [null],
      "receiver": this.formBuilder.group({
        "organizationName": [null, Validators?.required],
        "tradingPartnerServiceId": [null, Validators?.required]
      })
    });

    this.claimAdditonalNotesForm = this.formBuilder?.group({
      'additionalInformation': [null]
    });

    // Paper Claims Paey Address
    this.paperClaimsPayerAddress = this.formBuilder.group({
      "isPaperClaim": [null],
      "address1": [null],
      "address2": [null],
      "city": [null],
      "countryCode": [null],
      "countrySubDivisionCode": [null],
      "postalCode": [null],
      "state": [null]
    });
  }

  ngOnInit(): void {
    const waitTimeDetails = JSON.parse(sessionStorage?.getItem('waitTimeDetails')) || null;
    this.allProviderNpiList = waitTimeDetails?.eligibilityDetails?.doctor || [];
    // this.claimAppointmentDetails = JSON.parse(sessionStorage?.getItem('claimAppointment')) || null;
    // this.getMemberDetails();
    // this.loadIcdCodes();
    // this.loadcptCodes();

    // newly added the icd and cpt codes autocomplete APIS
    this.loadAssesmentIcdCodes();
    this.loadBillingCodes();
	this.getCalimedInfo();
	
  }

  // Get the Appointment details...
  public getAppointmentById(): void {
    if (!this.appointmentId) {
      return;
    }
    let action: any = 'appointment/registration/getAppointmentById?id=';
    let params: any = {
      'id': this.appointmentId
    }
    this.claimSubCard.cardRefreshShow();
    this.httpService.getPatientsByFacilityId(action, params?.id)?.subscribe((data: any) => {
      this.claimSubCard.cardRefreshHide();
      if (data?.status == 'SUCCESS') {
        this.claimAppointmentDetails = { ...data?.responseObject } || null;
        this.getBillingProviderData();
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionComp?.modalShow();
      }
    }, error => {
      this.claimSubCard.cardRefreshHide();
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionComp?.modalShow();
    });
  }

  ngAfterViewInit(): void {
    this.getAppointmentById();
  }

  // Added the API calls for Auto complete for Diagnosis Codes
  private loadAssesmentIcdCodes() {
    this.allIcdCodesObservebale = concat(
      of([]), // default items
      this.diagnosisInput.pipe(
        distinctUntilChanged(),
        tap(() => this.icdCodesLoading = true),
        switchMap(term => this.httpService.icdCodesTrackBy(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.icdCodesLoading = false)
        ))
      )
    );
  }
  // Added the API calls for Auto complete for CPT codes
  private loadBillingCodes() {
    this.allBillingCodes = concat(
      of([]), // default items
      this.billingInput.pipe(
        distinctUntilChanged(),
        tap(() => this.billingCodesLoading = true),
        switchMap(term => this.httpService.billingCptCodesTrackBy(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.billingCodesLoading = false)
        ))
      )
    );
  }

  private addServiceLinesGroup(serviceLine?: any): FormGroup {
    if (this.unitOfMeasureList && !this.unitOfMeasureList?.find((item: any) => item?.value === serviceLine?.drugIdentification?.measurementUnitCode)) {
      const obj = { desc: serviceLine?.drugIdentification?.measurementUnitCode, value: serviceLine?.drugIdentification?.measurementUnitCode};
      this.unitOfMeasureList = [...this.unitOfMeasureList, ...[obj]];
    }
    return this.formBuilder.group({
      'serviceDate': [this.serviceDateFormate(serviceLine?.serviceDate) || this.claimAppointmentDetails?.appointment?.dateTime?.split('T')[0] || null, Validators?.required],
      'professionalService': this.formBuilder.group({
        'placeOfServiceCode': [serviceLine?.professionalService?.placeOfServiceCode || null, Validators?.required],
        'procedureCode': [serviceLine?.professionalService?.procedureCode || null, Validators?.required],
        'procedureIdentifier': [serviceLine?.professionalService?.procedureIdentifier || 'HC' || null, Validators?.required],
        'procedureModifiers': [serviceLine?.professionalService?.procedureModifiers && serviceLine?.professionalService?.procedureModifiers[0] || null],
        'serviceUnitCount': [serviceLine?.professionalService?.serviceUnitCount || null, Validators?.required],
        'lineItemChargeAmount': [serviceLine?.professionalService?.lineItemChargeAmount || null, Validators?.required],
        // 'diagnosisCodePointers': [serviceLine?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers || null, Validators?.required],
        'compositeDiagnosisCodePointers': this.formBuilder.group({
          'diagnosisCodePointers': [serviceLine?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers || null, Validators?.required]
        }),
        'description': [serviceLine?.professionalService?.description || null],
        'measurementUnit': [serviceLine?.professionalService?.measurementUnit || 'UN' || null]
      }),
      'drugIdentification': this.formBuilder?.group({
        'serviceIdQualifier': [serviceLine?.drugIdentification?.serviceIdQualifier || null],
        'nationalDrugCode': [((serviceLine?.drugIdentification?.nationalDrugCode ? this.drugCodeHyphenRemove(serviceLine?.drugIdentification?.nationalDrugCode) : null) || null), Validators?.compose([Validators.pattern(AppRegExpressionsConfig?.acceptNumeric)])],
        'nationalDrugUnitCount': [serviceLine?.drugIdentification?.nationalDrugUnitCount || null], // Validators?.compose([Validators.pattern(AppRegExpressionsConfig?.acceptNumeric)])
        'measurementUnitCode': [serviceLine?.drugIdentification?.measurementUnitCode || null],
        'daysUnits': [serviceLine?.professionalService?.serviceUnitCount || null],
        'strength': [serviceLine?.drugIdentification?.strength || null],
      }),
      'providerControlNumber': [serviceLine?.providerControlNumber || null]
    });
  };

  // Remove Hyphens in Drug Code
  private drugCodeHyphenRemove(drugCode: any): string {
    if (drugCode && drugCode?.includes('-')) {
      return drugCode?.replaceAll('-', '');
    }
    return null;
  }

  private addDiagnosisCodeGroup(disagnosis?: any): FormGroup {
    return this.formBuilder.group({
      'diagnosisCode': [disagnosis?.diagnosisCode || disagnosis?.code, Validators?.required],
      'diagnosisTypeCode': [disagnosis?.diagnosisTypeCode || 'ABF' || null],
      'description': [disagnosis?.description || null]
    });
  };

  public deleteDiagnosisCode(deletedIndex: number, icdCode: string): void {
    this.healthCareCodeInformation = this.claimSubmissionFrom.get('healthCareCodeInformation') as FormArray;
    this.claimInformation = this.claimSubmissionFrom.get('claimInformation') as FormArray;
    const serviceLinesValues = [...this.claimInformation?.value] || [];

    // const onlySinglePointerRemover = serviceLinesValues?.filter(itemCheck => itemCheck?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.length === 1 && itemCheck?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.indexOf((deletedIndex + 1).toString()) !== -1);

    // let multiPointerRemover = serviceLinesValues?.filter(itemCheck => itemCheck?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.length > 1 && itemCheck?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.indexOf((deletedIndex + 1).toString()) !== -1);

    // const noPointerAvailable = serviceLinesValues?.filter(itemCheck => itemCheck?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.indexOf((deletedIndex + 1).toString()) === -1);

    // multiPointerRemover = multiPointerRemover?.map((cptService: any) => {
    //   const pointerList = [...cptService?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers] || [];
    //   cptService.professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers = pointerList?.filter(indexItem => indexItem !== (deletedIndex + 1).toString());
    //   return cptService;
    // });

    // const concatList = [...onlySinglePointerRemover, ...noPointerAvailable, ...multiPointerRemover];
    // this.claimInformation?.clear();
    const newServicesLinesList = [];
    serviceLinesValues?.forEach((cptItems: any) => {
      let intIten: string = (deletedIndex + 1)?.toString();
      let pointerList = cptItems?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers || null;
      if (pointerList?.includes(intIten)) {
        pointerList?.splice(pointerList.indexOf(intIten), 1)
      };
      cptItems.professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers = (pointerList?.length === 0) ? null : pointerList;
      newServicesLinesList?.push(cptItems);
      // this.claimInformation.push(this.addServiceLinesGroup(cptItems));
    });
    this.claimInformation?.patchValue(newServicesLinesList);
    // concatList?.forEach(ele => {
    //   ele.professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers = ele?.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.map(pointerItem => {
    //     let intIten: number = parseInt(pointerItem);
    //     if(intIten > (deletedIndex + 1)){
    //       intIten = intIten - 1;
    //       return intIten?.toString();
    //     }
    //     return intIten?.toString();
    //   });
    //   this.claimInformation.push(this.addServiceLinesGroup(ele));
    // });
    this.healthCareCodeInformation.removeAt(deletedIndex);
    this.makeDiagnosisPointers();
  };

  private patchClaimData(clainInfo: any): void {
    this.claimInformation = this.claimSubmissionFrom.get('claimInformation') as FormArray;
    this.healthCareCodeInformation = this.claimSubmissionFrom.get('healthCareCodeInformation') as FormArray;
    clainInfo?.serviceLines?.forEach(element => {
      this.claimInformation.push(this.addServiceLinesGroup(element));
    });
    this.diagnosisPointersList = [];
    clainInfo?.healthCareCodeInformation?.forEach((element: any, index: number) => {
      this.diagnosisPointersList?.push((index + 1)?.toString());
      this.healthCareCodeInformation.push(this.addDiagnosisCodeGroup(element));
    });

  }

  public totalChargesCount(): any {
    const sum = this.claimSubmissionFrom.get('claimInformation')?.value?.map(item => parseFloat(item?.professionalService?.lineItemChargeAmount || 0))?.reduce((prev, curr) => prev + curr);
    return sum ? sum?.toFixed(2) : null;
  }

  // add diagnosis codes
  public addDiagnosisCodes(): void {
    this.healthCareCodeInformation = this.claimSubmissionFrom.get('healthCareCodeInformation') as FormArray;
    this.healthCareCodeInformation.push(this.addDiagnosisCodeGroup());
    this.makeDiagnosisPointers();
  }

  private makeDiagnosisPointers(): void {
    const count = this.claimSubmissionFrom.get('healthCareCodeInformation')?.value?.length;
    this.diagnosisPointersList = [];
    for (let i = 0; i < count; i++) {
      this.diagnosisPointersList?.push((i + 1)?.toString());
    }
  }

  // add Service Lines or CPT codes
  public addCodes(): void {
    this.claimInformation = this.claimSubmissionFrom.get('claimInformation') as FormArray;
    this.claimInformation.push(this.addServiceLinesGroup());
  }

  public deleteCode(index: number): void {
    this.claimInformation = this.claimSubmissionFrom.get('claimInformation') as FormArray;
    this.claimInformation.removeAt(index);
  }

  // value change of provider Type
  public changeProviderType(): void {
    const billingAddress = {
      "address1": null,
      "address2": null,
      "city": null,
      "state": null,
      "postalCode": null,
      "countryCode": null,
      "countrySubDivisionCode": null
    };
    const billingContactInformation = {
      "email": null,
      "faxNumber": null,
      "phoneNumber": null,
      "name": null
    }

    const providerTypeValue = this.billingProviderForm?.get('providerType')?.value;
    //  === this.prepareClaimSubmissionData?.billing?.providerType
    if (providerTypeValue) {
      // get the provider from provider list with billing npi
      let providerObj = this.prepareClaimSubmissionData?.providers?.find(item => item?.providerType === providerTypeValue) || null;
      if (!providerObj) {
        providerObj = this.billingProviderForm?.value;
      }
      // mapping the null object to contact infomation if its null
      // this.prepareClaimSubmissionData.billing.contactInformation = this.prepareClaimSubmissionData?.billing?.contactInformation || billingContactInformation;
      // mapping the address null object to billing address if its null
      // this.prepareClaimSubmissionData.billing.address = this.prepareClaimSubmissionData?.billing?.address || billingAddress;

      // again contact information mapped back to billing - Reverted on 29/05/22
      providerObj.contactInformation = providerObj?.contactInformation || billingContactInformation;
      providerObj.address = providerObj?.address || billingAddress;
      providerObj.contactInformation.name = providerObj?.contactInformation?.name || `${providerObj?.firstName || ''} ${providerObj?.lastName || ''}`

      this.billingProviderForm?.patchValue(providerObj);
      // this.prepareClaimSubmissionData.subscriber.dateOfBirth = this.serviceDateFormate(this.prepareClaimSubmissionData?.subscriber?.dateOfBirth) || null;
      // // add trading partiner id to a reciever obj
      // this.prepareClaimSubmissionData.receiver.tradingPartnerServiceId = this.prepareClaimSubmissionData?.tradingPartnerServiceId;
      // this.prepareClaimSubmissionData.subscriber.receiver = this.prepareClaimSubmissionData?.receiver;
      // this.memberDetailsForm?.patchValue(this.prepareClaimSubmissionData?.subscriber);

      // // add clain details
      // this.patchClaimData(this.prepareClaimSubmissionData?.claimInformation);
    }
    else {
      this.billingProviderForm?.reset();
      // this.newClaim();
    }
  }

  // get the claim submit related data
  public getBillingProviderData(): void {
    // const action = `treatmentFlow/getBillingProvider/${this.claimAppointmentDetails?.id}`;
    // const action = `eligibility/prepareClaims?appointmentId=90b11a574ea14211a358aea223cd9c0b`;
    const action = `eligibility/prepareClaims?appointmentId=${this.claimAppointmentDetails?.id}&correctionClaimNumber=${this.payerClaimControlNumber || ''}&correctionFlag=${this.correctVoidedClaim === 'corrected' ? true : false}&claimProcessingFlag=${this.claimProcessingFlag}&voidedFlag=${this.correctVoidedClaim === 'voided' ? true : false}`;

    this.claimSubCard?.cardRefreshShow();
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.prepareClaimSubmissionData = data?.responseObject;
        const parsedData = { ...data?.responseObject };
        console.log("parsedData",parsedData);
        console.log(parsedData?.firstName);
        this.providersList = [...this.providersList, ...data?.responseObject?.providers] || [];
        this.billingProviderForm?.get('providerType')?.setValue(parsedData?.billing?.providerType || null);
        this.claimAdditonalNotesForm?.get('additionalInformation')?.setValue(parsedData?.claimNote?.additionalInformation);

        // New Claim changes
        this.prepareClaimSubmissionData.subscriber.dateOfBirth = this.serviceDateFormate(this.prepareClaimSubmissionData?.subscriber?.dateOfBirth) || null;
        // add trading partiner id to a reciever obj
        this.prepareClaimSubmissionData.receiver.tradingPartnerServiceId = this.prepareClaimSubmissionData?.tradingPartnerServiceId;
        this.prepareClaimSubmissionData.subscriber.receiver = this.prepareClaimSubmissionData?.receiver;
        this.memberDetailsForm?.patchValue(this.prepareClaimSubmissionData?.subscriber);
        if(this.prepareClaimSubmissionData?.payerAddress) {
          this.paperClaimsPayerAddress?.patchValue(this.prepareClaimSubmissionData?.payerAddress);
        }
        // add clain details
        this.patchClaimData(this.prepareClaimSubmissionData?.claimInformation);

        this.changeProviderType();
      } else {
        this.notifyText = data?.message || 'Please validate the pre-requisite information needed for the Claim submission' || data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionComp?.modalShow();
      }
      this.claimSubCard?.cardRefreshHide();
    },
      (error) => {
        this.claimSubCard?.cardRefreshHide();
        this.notifyText = error?.message || 'Please validate the pre-requisite information needed for the Claim submission' || error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionComp?.modalShow();
      });
  }

  public getMemberDetails(): void {
    const action = `treatmentFlow/getMemberDetails/${this.claimAppointmentDetails?.id}`;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.memberDetailsForm?.patchValue(data?.responseObject);
      }
    },
      (error) => {

      });
  }

  public checkBillingProviderValid(showDependentModal?: UiModalComponent): void {
    this.billingProviderForm?.markAllAsTouched();
    // const params = { ...this.prepareClaimSubmissionData };
    const providerTypeValue = this.billingProviderForm?.get('providerType')?.value;
    const updatedClaimsProvider = [];
    if (this.newProviderTypeAdded) {
      updatedClaimsProvider?.push(this.billingProviderForm?.value);
    }
    this.prepareClaimSubmissionData?.providers?.forEach((providerModifyItem: any, index: number) => {
      if (providerModifyItem?.providerType === providerTypeValue) {
        updatedClaimsProvider?.push(this.billingProviderForm?.value);
      } else {
        updatedClaimsProvider?.push(providerModifyItem);
      }
    });
    this.prepareClaimSubmissionData.providers = updatedClaimsProvider || [];
    if (showDependentModal && this.billingProviderForm?.valid && this.prepareClaimSubmissionData?.dependent && (this.memberDetailsForm?.get('firstName')?.value?.trim()?.toLowerCase() === this.prepareClaimSubmissionData?.dependent?.firstName?.trim()?.toLowerCase() || this.memberDetailsForm?.get('lastName')?.value?.trim()?.toLowerCase() === this.prepareClaimSubmissionData?.dependent?.lastName?.trim()?.toLowerCase())) {
      showDependentModal?.show();
    }
  }

  public checkValidMember(): void {
    this.memberDetailsForm?.markAllAsTouched();
    this.paperClaimsPayerAddress?.markAllAsTouched();
  }

  // add new claim
  public newClaim(): void {
    this.memberDetailsForm?.reset();
    this.billingProviderForm?.reset();
    this.claimSubmissionFrom?.reset();
    this.clearFormArray(this.claimSubmissionFrom?.get('claimInformation') as FormArray);
    this.clearFormArray(this.claimSubmissionFrom?.get('healthCareCodeInformation') as FormArray);
    // this.billingProviderForm?.get('providerType')?.setValue('New Claim');
  }

  // clear the Form Array
  public clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  // dateFormate 
  public dateFormate(dateNum: string): any {
    if (!dateNum) {
      return null;
    }
    if (dateNum?.includes('-') || dateNum?.includes('/')) {
      return dateNum;
    }
    const spDate = dateNum?.split('');
    const date = `${spDate[4]}${spDate[5]}/${spDate[6]}${spDate[7]}/${spDate[0]}${spDate[1]}${spDate[2]}${spDate[3]}`;
    return date || null;
  }

  // dateFormate 
  public serviceDateFormate(dateNum: string): any {
    if (!dateNum) {
      return null;
    }
    if (dateNum?.includes('-') || dateNum?.includes('/')) {
      return dateNum;
    }
    const spDate = dateNum?.split('');
    const date = `${spDate[0]}${spDate[1]}${spDate[2]}${spDate[3]}-${spDate[4]}${spDate[5]}-${spDate[6]}${spDate[7]}`;
    return date || null;
  }

  // get ICD codes
  public trackByFn(item: any) {
    return item.code;
  }

  private loadIcdCodes() {
    this.allDiagnosisObservebale = of(concat(
      of([]), // default items
      this.diagnosisInput.pipe(
        distinctUntilChanged(),
        tap(() => this.icdCodesLoading = true),
        switchMap(term => this.httpService.getBillingCodes(term, this.claimAppointmentDetails).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.icdCodesLoading = false)
        ))
      )
    ).subscribe((data: any) => {
      let resData: any = data.responseObject || {};
      const obj = {
        code: resData?.icdCodes?.code || null,
        description: resData?.icdCodes?.description || null,
        cptFees: resData?.cptFees || []
      }
      resData = obj?.code ? [obj] : [];
      this.icdSerchItems = resData;
      // return resData;
    }));
  }

  // load the cpt codes
  private loadcptCodes() {
    this.allCptCodesObservebale = concat(
      of([]), // default items
      this.cptCodesInput.pipe(
        distinctUntilChanged(),
        tap(() => this.cptCodesLoading = true),
        switchMap(term => this.httpService.getCptCodes(term, this.claimAppointmentDetails).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.cptCodesLoading = false)
        ))
      )
    );
  }

  // get ICD codes
  public trackByFnCptCodes(item: any) {
    return item?.billingCodesMasterData?.cptCode || item?.cptCode;
  }

  // if change Diagnosis code values
  public diagnosisCodesValues(event: any, index: number): void {
    this.healthCareCodeInformation = this.claimSubmissionFrom.get('healthCareCodeInformation') as FormArray;
    if (this.healthCareCodeInformation?.value && this.healthCareCodeInformation?.value?.find(item => item?.diagnosisCode === event?.code)) {
      this.healthCareCodeInformation?.controls[index]?.get('diagnosisCode').setValue(null);
      this.notifyText = 'The selected diagnosis code is already used. Please choose a different code.';
      this.exceptionComp?.modalShow();
      // const formGroup = this.healthCareCodeInformation?.controls?.find(item => item?.value?.cptCode === event?.cptCode) as FormGroup;
    } else {
      this.healthCareCodeInformation?.controls[index]?.get('diagnosisCode').setValue(event?.code);
      this.healthCareCodeInformation?.controls[index]?.get('description').setValue(event?.description);
    }
    // return;
    // this.claimSubCard?.cardRefreshShow();
    // this.httpService.getBillingCodes(event?.code, this.claimAppointmentDetails)?.subscribe((data: any) => {
    //   if (data?.status === 'SUCCESS') {
    //     this.healthCareCodeInformation = this.claimSubmissionFrom.get('healthCareCodeInformation') as FormArray;
    //     this.healthCareCodeInformation?.controls[index]?.get('diagnosisCode').setValue(event?.code);
    //     this.healthCareCodeInformation?.controls[index]?.get('description').setValue(event?.description);
    //     // this.selectedDiagnosisCodeRelatedCptsMapping(event, index, data?.responseObject?.cptFees);
    //   } else {
    //     this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
    //     this.exceptionComp?.modalShow();
    //   }
    //   this.claimSubCard?.cardRefreshHide();
    // },
    //   (error) => {
    //     this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
    //     this.exceptionComp?.modalShow();
    //     this.claimSubCard?.cardRefreshHide();
    //   });
  };

  public selectedDiagnosisCodeRelatedCptsMapping(event: any, index: number, cptData: any): void {
    this.healthCareCodeInformation = this.claimSubmissionFrom.get('healthCareCodeInformation') as FormArray;
    this.healthCareCodeInformation?.controls[index]?.get('diagnosisCode').setValue(event?.code);
    this.healthCareCodeInformation?.controls[index]?.get('description').setValue(event?.description);
    const serviceLinesObj = {
      'serviceDate': this.claimAppointmentDetails?.appointment?.dateTime?.split('T')[0],
      'professionalService': {
        'placeOfServiceCode': null,
        'procedureCode': null,
        'procedureIdentifier': null,
        'procedureModifiers': null,
        'serviceUnitCount': null,
        'lineItemChargeAmount': null,
        'compositeDiagnosisCodePointers': {
          'diagnosisCodePointers': null
        },
        'description': null,
        'measurementUnit': null
      },
      'providerControlNumber': null
    };
    this.claimInformation = this.claimSubmissionFrom?.get('claimInformation') as FormArray;
    cptData?.forEach((cptItem: any) => {
      serviceLinesObj.professionalService.placeOfServiceCode = cptItem?.billingCodesMasterData?.placeOfService;
      serviceLinesObj.professionalService.procedureCode = cptItem?.billingCodesMasterData?.cptCode;
      serviceLinesObj.professionalService.description = cptItem?.billingCodesMasterData?.codeDescription;
      serviceLinesObj.professionalService.serviceUnitCount = cptItem?.billingCodesMasterData?.typeOfService;
      serviceLinesObj.professionalService.lineItemChargeAmount = cptItem?.feeDetail?.fee;
      serviceLinesObj.professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers = [(index + 1)?.toString()];
      // new lines start
      // const claimValues = [...this.claimInformation?.value] || [];
      // claimValues?.forEach((defItem: any, index: number) => {
      //   if(defItem?.professionalService?.procedureCode === cptItem?.billingCodesMasterData?.cptCode){
      //     const pointerValue = this.claimSubmissionFrom?.get('claimInformation')[index]?.get('professionalService')?.get('compositeDiagnosisCodePointers')?.get('diagnosisCodePointers')?.value || [];
      //     const setValuePointer = [...pointerValue, ...[(index + 1)?.toString()]];
      //     this.claimSubmissionFrom?.get('claimInformation')[index]?.get('professionalService')?.get('compositeDiagnosisCodePointers')?.get('diagnosisCodePointers')?.setValue(setValuePointer);
      //     // CPT Fee set
      //     const defAmount = this.claimSubmissionFrom?.get('claimInformation')[index]?.get('professionalService')?.get('lineItemChargeAmount');

      //     this.claimSubmissionFrom?.get('claimInformation')[index]?.get('professionalService')?.get('lineItemChargeAmount').setValue(parseInt(defAmount) + cptItem?.feeDetail?.fee);
      //   }else{
      //     this.claimInformation?.push(this.addServiceLinesGroup(serviceLinesObj));
      //   }
      // })
      // new lines end
      this.claimInformation?.push(this.addServiceLinesGroup(serviceLinesObj));
    });
  }

  // change CPT codes
  public changeCptCodes(event: any, index: number): void {
    this.claimInformation = this.claimSubmissionFrom.get('claimInformation') as FormArray;
    this.claimInformation?.controls[index]?.get('professionalService')?.get('placeOfServiceCode')?.setValue(event?.placeOfService?.trim());
    this.claimInformation?.controls[index]?.get('professionalService')?.get('procedureCode')?.setValue(event?.cptCode);
    this.claimInformation?.controls[index]?.get('professionalService')?.get('description')?.setValue(event?.codeDescription?.trim());
    this.claimInformation?.controls[index]?.get('professionalService')?.get('serviceUnitCount')?.setValue(event?.typeOfService?.trim());
    // Modifier value will be 25 if CPT code is started with 99
    const modifierValue = event?.cptCode?.toString()?.substring(0, 2);
    this.claimInformation?.controls[index]?.get('professionalService')?.get('procedureModifiers')?.setValue(modifierValue === '99' ? '25' : null);
    this.claimSubCard?.cardRefreshShow();
    this.httpService?.getCptCodes(event?.cptCode, this.claimAppointmentDetails)?.subscribe((data: any) => {
      // if (data?.status === 'SUCCESS') {
      //   const feeDetails = data?.responseObject?.find(cptFee => event?.cptCode === cptFee?.billingCodesMasterData?.cptCode) || {}
      //   this.claimInformation?.controls[index]?.get('professionalService')?.get('lineItemChargeAmount')?.setValue(feeDetails?.feeDetail?.fee || null);
      // }
      if (data?.status === 'SUCCESS') {
        this.claimInformation?.controls[index]?.get('professionalService')?.get('lineItemChargeAmount')?.setValue(data?.responseObject?.fee || null);
      }
      this.claimSubCard?.cardRefreshHide();
    },
      (error) => {
        this.claimSubCard?.cardRefreshHide();
      });
  }

  // Actual Fields name with invalid fields
  public invalidActualFieldName(claimItem: any): any {
    let fieldName: string;
    switch (claimItem?.name) {
      case 'serviceDate':
        fieldName = 'Service Date';
        break;
      case 'placeOfServiceCode':
        fieldName = 'Place of Service';
        break;
      case 'procedureCode':
        fieldName = 'CPT/HCPCS Code';
        break;
      case 'procedureIdentifier':
        fieldName = 'Identifier';
        break;
      case 'serviceUnitCount':
        fieldName = 'Days/Units';
        break;
      case 'lineItemChargeAmount':
        fieldName = 'Charges';
        break;
      case 'diagnosisCodePointers':
        fieldName = 'Diagnosis Pointer';
        break;
      case 'diagnosisCode':
        fieldName = 'Diagnosis Code';
        break;
      case 'serviceIdQualifier':
        fieldName = 'Service ID Qualifier';
        break;
      case 'nationalDrugCode':
        fieldName = 'National Drug Code';
        break;
      case 'strength':
        fieldName = 'Strength';
        break;
      case 'measurementUnitCode':
        fieldName = 'Measurement';
        break;
      case 'nationalDrugUnitCount':
        fieldName = 'Dosage';
        break;
      case 'daysUnits':
        fieldName = 'Days/Units';
        break;
    }
    return fieldName || '';
  }

  // submit claims
  public submitClaims(claimSubmitModal: UiModalComponent): void {
    if (this.claimSubmissionFrom?.invalid) {
      const getAllrequiredFields = this.httpService?.findInvalidControlsWithFormArray(this.claimSubmissionFrom, []);
      let allInvalidControls = JSON?.parse(JSON?.stringify(getAllrequiredFields))?.filter((fItem, index, self) => fItem?.type === 'control' && self?.findIndex(iFnd => iFnd?.name === fItem?.name) === index) || [];
      if(allInvalidControls && allInvalidControls?.length > 0) {
        allInvalidControls?.forEach((invaliItem: any) => {
          invaliItem.fieldName = this.invalidActualFieldName(invaliItem);
        });
      }
      this.allClaimInformationInvalidaFields = allInvalidControls || [];

      this.claimSubmissionFrom?.markAllAsTouched();
      this.notifyText = 'Please fill all the required fields to submit the claim.'
      this.isSuccessNotify = false;
      this.exceptionComp?.modalShow();
      return;
    }
    this.allClaimInformationInvalidaFields = null;
    const action = `eligibility/professionalClaims?appointmentId=${this.claimAppointmentDetails?.id}&claimProcessingFlag=${this.claimProcessingFlag}`;
    const params = JSON.parse(JSON.stringify(this.prepareClaimSubmissionData));

    if (this.claimSubmissionFrom.get('claimInformation')?.value?.length === 0 || this.claimSubmissionFrom.get('healthCareCodeInformation')?.value?.length === 0) {
      this.notifyText = 'Please add atleast one Diagnosis Codes (ICD 10) or Claim Information';
      this.isSuccessNotify = false;
      this.exceptionComp?.modalShow();
      return;
    }

    params.claimInformation.claimChargeAmount = this.totalChargesCount()?.toString();
    const payerAddressForm = JSON?.parse(JSON?.stringify(this.paperClaimsPayerAddress?.value));
    params.payerAddress = payerAddressForm?.isPaperClaim ? payerAddressForm : params.payerAddress;
    if(params?.payerAddress && payerAddressForm?.isPaperClaim) {
      delete params?.payerAddress?.isPaperClaim;
    }
    if(params?.payerAddress) {
      const payerAdHyphenRem = params?.payerAddress;
      if(payerAdHyphenRem?.postalCode?.includes('-')) {
        payerAdHyphenRem.postalCode = payerAdHyphenRem?.postalCode?.replaceAll('-', '');
      }
    }
    const serviceLinesValue = JSON.parse(JSON.stringify(this.claimSubmissionFrom.get('claimInformation')?.value)) || [];
    serviceLinesValue?.forEach((item: any, index: any) => {
      item.serviceDate = item?.serviceDate?.split('-')?.join('');
      if (item.professionalService.procedureModifiers) {
        item.professionalService.procedureModifiers = [item?.professionalService?.procedureModifiers];
      } else {
        delete item.professionalService.procedureModifiers;
      }
      // to check the drug identification
      if(item?.drugIdentification){
        delete item?.drugIdentification?.daysUnits;
      }
      if(item?.drugIdentification && (!item?.drugIdentification?.serviceIdQualifier || !item?.drugIdentification?.nationalDrugCode || !item?.drugIdentification?.measurementUnitCode)){
        delete item?.drugIdentification;
      }
      item.professionalService.lineItemChargeAmount = '' + (item?.professionalService?.lineItemChargeAmount ? item?.professionalService?.lineItemChargeAmount : 0);
    });
    params.claimInformation.serviceLines = serviceLinesValue;
    // params.billing = this.billingProviderForm?.value;
    const memberDetailsSubscriber = JSON.parse(JSON.stringify(this.memberDetailsForm?.value));
    memberDetailsSubscriber.firstName = memberDetailsSubscriber?.firstName?.trim();
    memberDetailsSubscriber.lastName = memberDetailsSubscriber?.lastName?.trim();
    params.subscriber = memberDetailsSubscriber;
    // newly added for updated provider info to providers list
    // again mapped billing to billing object so commenting these  -- Reverted on 29/05/22
    const providerTypeValue = this.billingProviderForm?.get('providerType')?.value;
    const updatedProviders = [];
    params?.providers?.forEach(item => {
      if (item?.providerType === providerTypeValue) {
        updatedProviders?.push(this.billingProviderForm?.value);
      } else {
        updatedProviders?.push(item);
      }
    });
    params.providers = updatedProviders || params?.providers || [];
    // Mapping updated provider details to billing object
    if(params?.billing && providerTypeValue === params?.billing?.providerType) {
      params.billing = JSON?.parse(JSON?.stringify(this.billingProviderForm?.value));
      params.billing.contactInformation = null;
    }

    // const splitDate = params?.subscriber?.dateOfBirth?.split('-');
    // params.subscriber.dateOfBirth = `${splitDate[2]}${splitDate[0]}${splitDate[1]}`;
    params.subscriber.dateOfBirth = params?.subscriber?.dateOfBirth?.split('-')?.join('');
    params.tradingPartnerServiceId = memberDetailsSubscriber?.receiver?.tradingPartnerServiceId;
    params.claimNote.additionalInformation = this.claimAdditonalNotesForm?.get('additionalInformation')?.value;
    delete params?.receiver?.tradingPartnerServiceId;
    delete params?.subscriber?.receiver;
    params.claimInformation.healthCareCodeInformation = this.claimSubmissionFrom.get('healthCareCodeInformation')?.value || [];
    params.claimInformation.healthCareCodeInformation = params.claimInformation.healthCareCodeInformation?.map((item: any, index: number) => {
      if (index === 0) {
        item.diagnosisTypeCode = 'ABK';
      } else {
        item.diagnosisTypeCode = 'ABF';
      }
      return item;
    });
    delete params?.tradingPartnerId;
    this.claimSubCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.claimSubmitMessage = 'Claim Submitted Successfully..!';
      } else {
        this.claimSubmitMessage = 'CareEco will review the errors and Re-Submit the claim.';
      }
      claimSubmitModal?.show();
      this.claimSubCard?.cardRefreshHide();
    },
      (error) => {
        this.claimSubmitMessage = error?.message || 'CareEco will review the errors and Re-Submit the claim.';
        claimSubmitModal?.show();
        this.claimSubCard?.cardRefreshHide();
      });
  }

  public claimValidation(claimValidationModal: UiModalComponent, isExternalFlag?: boolean): void {
    if (this.claimSubmissionFrom?.invalid) {
      const getAllrequiredFields = this.httpService?.findInvalidControlsWithFormArray(this.claimSubmissionFrom, []);
      let allInvalidControls = JSON?.parse(JSON?.stringify(getAllrequiredFields))?.filter((fItem, index, self) => fItem?.type === 'control' && self?.findIndex(iFnd => iFnd?.name === fItem?.name) === index) || [];
      if(allInvalidControls && allInvalidControls?.length > 0) {
        allInvalidControls?.forEach((invaliItem: any) => {
          invaliItem.fieldName = this.invalidActualFieldName(invaliItem);
        });
      };
      this.allClaimInformationInvalidaFields = allInvalidControls || [];

      this.claimSubmissionFrom?.markAllAsTouched();
      this.notifyText = 'Please fill all the required fields to submit the claim.'
      this.isSuccessNotify = false;
      this.exceptionComp?.modalShow();
      return;
    }
    this.allClaimInformationInvalidaFields = null;
    const action = `eligibility/claimsValidation?appointmentId=${this.claimAppointmentDetails?.id}&externalClaim=${isExternalFlag}&claimProcessingFlag=${this.claimProcessingFlag}`;
    const params = JSON.parse(JSON.stringify(this.prepareClaimSubmissionData));
    if (this.claimSubmissionFrom.get('claimInformation')?.value?.length === 0 || this.claimSubmissionFrom.get('healthCareCodeInformation')?.value?.length === 0) {
      this.notifyText = 'Please add atleast one Diagnosis Codes (ICD 10) or Claim Information';
      this.isSuccessNotify = false;
      this.exceptionComp?.modalShow();
      return;
    }
    const payerAddressForm = JSON?.parse(JSON?.stringify(this.paperClaimsPayerAddress?.value));
    params.payerAddress = payerAddressForm?.isPaperClaim ? payerAddressForm : params.payerAddress;
    if(params?.payerAddress && payerAddressForm?.isPaperClaim) {
      delete params?.payerAddress?.isPaperClaim;
    }
    if(params?.payerAddress) {
      const payerAdHyphenRem = params?.payerAddress;
      if(payerAdHyphenRem?.postalCode?.includes('-')) {
        payerAdHyphenRem.postalCode = payerAdHyphenRem?.postalCode?.replaceAll('-', '');
      }
    }
    params.claimInformation.claimChargeAmount = this.totalChargesCount()?.toString();
    const serviceLinesValue = JSON.parse(JSON.stringify(this.claimSubmissionFrom.get('claimInformation')?.value || [])) || [];
    serviceLinesValue?.forEach((item: any, index: any) => {
      item.serviceDate = item?.serviceDate?.split('-')?.join('');
      if (item.professionalService.procedureModifiers) {
        item.professionalService.procedureModifiers = [item?.professionalService?.procedureModifiers];
      } else {
        delete item.professionalService.procedureModifiers;
      }
      // to check the drug identification
      if(item?.drugIdentification){
        delete item?.drugIdentification?.daysUnits;
      }
      if(item?.drugIdentification && (!item?.drugIdentification?.serviceIdQualifier || !item?.drugIdentification?.nationalDrugCode || !item?.drugIdentification?.measurementUnitCode)){
        delete item?.drugIdentification;
      }
      item.professionalService.lineItemChargeAmount = '' + (item?.professionalService?.lineItemChargeAmount ? item?.professionalService?.lineItemChargeAmount : 0);
    });
    params.claimInformation.serviceLines = serviceLinesValue;
    // params.billing = this.billingProviderForm?.value;
    const memberDetailsSubscriber = JSON.parse(JSON.stringify(this.memberDetailsForm?.value));
    memberDetailsSubscriber.firstName = memberDetailsSubscriber?.firstName?.trim();
    memberDetailsSubscriber.lastName = memberDetailsSubscriber?.lastName?.trim();
    params.subscriber = memberDetailsSubscriber;
    // newly added for updated provider info to providers list
    // again mapped billing to billing object so commenting these  -- Reverted on 29/05/22
    const providerTypeValue = this.billingProviderForm?.get('providerType')?.value;
    const updatedProviders = [];
    params?.providers?.forEach(item => {
      if (item?.providerType === providerTypeValue) {
        updatedProviders?.push(this.billingProviderForm?.value);
      } else {
        updatedProviders?.push(item);
      }
    });
    params.providers = updatedProviders || params?.providers || [];
    // Mapping updated provider details to billing object
    if(params?.billing && providerTypeValue === params?.billing?.providerType) {
      params.billing = JSON?.parse(JSON?.stringify(this.billingProviderForm?.value));
      delete params?.billing?.contactInformation;
    }
    // const splitDate = params?.subscriber?.dateOfBirth?.split('-');
    // params.subscriber.dateOfBirth = `${splitDate[2]}${splitDate[0]}${splitDate[1]}`;
    params.subscriber.dateOfBirth = params?.subscriber?.dateOfBirth?.split('-')?.join('');
    params.tradingPartnerServiceId = memberDetailsSubscriber?.receiver?.tradingPartnerServiceId;
    params.claimNote.additionalInformation = this.claimAdditonalNotesForm?.get('additionalInformation')?.value;
    delete params?.receiver?.tradingPartnerServiceId;
    delete params?.subscriber?.receiver;
    params.claimInformation.healthCareCodeInformation = this.claimSubmissionFrom.get('healthCareCodeInformation')?.value || [];
    params.claimInformation.healthCareCodeInformation = params.claimInformation.healthCareCodeInformation?.map((item: any, index: number) => {
      if (index === 0) {
        item.diagnosisTypeCode = 'ABK';
      } else {
        item.diagnosisTypeCode = 'ABF';
      }
      return item;
    });
    delete params?.tradingPartnerId;
    this.claimSubCard?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.claimSubmitMessage = isExternalFlag ? 'External Claim Data Updated Successfully..!' : data?.body?.message;
        // const errors = data?.body?.responseObject || [];
        this.claimErrorValidation = [];
      } else {
        this.claimSubmitMessage = data?.body?.message;
        const errors = data?.body?.responseObject || [];
        this.claimErrorValidation = errors;
      }
      claimValidationModal?.show();
      this.claimSubCard?.cardRefreshHide();
    },
      (error) => {
        this.claimSubmitMessage = error?.body?.message || error?.message || AppConstantsListConfig?.uiErrorException
        claimValidationModal?.show();
        this.claimSubCard?.cardRefreshHide();
      });
  }

  // redirect to home once submit the calims
  public redirectHome(claimSubmitModal: UiModalComponent): void {
    claimSubmitModal?.hide();
    this.router?.navigate(['/hospital/all-claims']);
  }

  public viewAppointmentDetails(viewDetailsModal?: UiModalComponent) {
    // this.router?.navigate([`/hospital/past-visit/${this.appointmentId}`]);
    this.httpService.clearTreatmentFlowSession();
    this.selectedViewAppointmentId = this.appointmentId;
    viewDetailsModal?.show();
  }

  // Add New Provider Type
  public addNewProviderType(): void {
    this.billingProviderForm?.reset();
    this.newProviderTypeAdded = true;
  }
  // delete selected Provider Type
  public deleteProviderType(providerType: string): void {
    this.prepareClaimSubmissionData.providers = this.prepareClaimSubmissionData.providers?.filter((item: any) => item?.providerType !== providerType) || [];
    this.providersList = this.prepareClaimSubmissionData?.providers || [];
    this.billingProviderForm?.reset();
  }

  // Drug Identification
  public addDrugIdentification(drugIdentification: UiModalComponent, serviceLineForm: FormGroup): void {
    this.individualServiceLineFormGroup = serviceLineForm as FormGroup;
    this.drugIdentificationFormGroup = serviceLineForm?.get('drugIdentification') as FormGroup;
    // this.isDrugInfomationAvailable = JSON?.parse(JSON.stringify(serviceLineForm?.get('drugIdentification')?.value));
    this.drugIdentificationFormGroup?.get('serviceIdQualifier')?.setValue('N4');
    this.drugIdentificationFormGroup?.get('nationalDrugCode')?.setValidators([Validators?.compose([Validators?.required])]);
    this.drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.setValidators([Validators?.compose([Validators?.required])]); //,Validators.pattern(AppRegExpressionsConfig?.acceptNumeric)
    this.drugIdentificationFormGroup?.get('measurementUnitCode')?.setValidators([Validators?.required]);
    this.drugIdentificationFormGroup?.get('daysUnits')?.setValidators([Validators?.required]);
    this.drugIdentificationFormGroup?.get('strength')?.setValidators([Validators?.required]);

    this.drugIdentificationFormGroup?.get('daysUnits')?.setValue(serviceLineForm?.value?.professionalService?.serviceUnitCount || this.drugIdentificationFormGroup?.get('daysUnits')?.value || '1');

    this.drugIdentificationFormGroup?.get('serviceIdQualifier')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('nationalDrugCode')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('measurementUnitCode')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('daysUnits')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('strength')?.updateValueAndValidity();

    drugIdentification?.show();
  }

  public deleteSelectedNDC(): void {
    this.drugIdentificationFormGroup?.reset();
    this.drugIdentificationFormGroup?.get('serviceIdQualifier')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('nationalDrugCode')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('measurementUnitCode')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('daysUnits')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('strength')?.setValidators(null);
    
    this.drugIdentificationFormGroup?.get('serviceIdQualifier')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('nationalDrugCode')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('measurementUnitCode')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('daysUnits')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('strength')?.updateValueAndValidity();
  }

  public removeValidations(): void {
    // if (this.isDrugInfomationAvailable && this.isDrugInfomationAvailable?.nationalDrugCode) {
    //   if (this.drugIdentificationFormGroup?.invalid) {
    //     this.notifyText = 'Please make sure you have entered all the required information for Drug Identification, or else if not required Drug Identification for this Claim Service please delete NDC.';
    //     this.exceptionComp?.modalShow();
    //   };
    //   return;
    // };
    this.drugIdentificationFormGroup?.get('serviceIdQualifier')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('nationalDrugCode')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('measurementUnitCode')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('daysUnits')?.setValidators(null);
    this.drugIdentificationFormGroup?.get('strength')?.setValidators(null);
    
    this.drugIdentificationFormGroup?.get('serviceIdQualifier')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('nationalDrugCode')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('nationalDrugUnitCount')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('measurementUnitCode')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('daysUnits')?.updateValueAndValidity();
    this.drugIdentificationFormGroup?.get('strength')?.updateValueAndValidity();
  }

  public updateProfessionalServiceUnitCount(value: any): void {
    if (this.individualServiceLineFormGroup) {
      this.individualServiceLineFormGroup?.get('professionalService')?.get('serviceUnitCount')?.setValue(value);
    }
  }

  // Save NDC
  public saveNdc(drugIdentificationDetails: UiModalComponent): void {
    if (this.drugIdentificationFormGroup?.invalid) {
      this.drugIdentificationFormGroup?.markAllAsTouched();
      return;
    }
    this.updateProfessionalServiceUnitCount(this.drugIdentificationFormGroup?.get('daysUnits')?.value);
    drugIdentificationDetails?.hide();
  }

  public addTag(tag: string) {
    return {
      desc: tag,
      value: tag
    }
  }

  public sendNotification(appointment?: any): void {
    this.sendNotifyComp?.showNotifyModalForEncounter(appointment, 'user');
  }

  // Paper Claim - Payor adress update
  public paperClaimPayorAdress($event): void {
    if ($event?.target?.checked) {
      this.paperClaimsPayerAddress?.get('address1')?.setValidators([Validators?.required]);
      this.paperClaimsPayerAddress?.get('city')?.setValidators([Validators?.required]);
      this.paperClaimsPayerAddress?.get('postalCode')?.setValidators([Validators?.required]);
      this.paperClaimsPayerAddress?.get('state')?.setValidators([Validators?.required]);
    } else {
      this.paperClaimsPayerAddress?.get('address1')?.reset();
      this.paperClaimsPayerAddress?.get('city')?.reset();
      this.paperClaimsPayerAddress?.get('postalCode')?.reset();
      this.paperClaimsPayerAddress?.get('state')?.reset();
      this.paperClaimsPayerAddress?.get('address1')?.setValidators(null);
      this.paperClaimsPayerAddress?.get('city')?.setValidators(null);
      this.paperClaimsPayerAddress?.get('postalCode')?.setValidators(null);
      this.paperClaimsPayerAddress?.get('state')?.setValidators(null);
      if (this.prepareClaimSubmissionData?.payerAddress) {
        this.paperClaimsPayerAddress?.patchValue(this.prepareClaimSubmissionData?.payerAddress);
      }
    }
    this.paperClaimsPayerAddress?.get('address1')?.updateValueAndValidity();
    this.paperClaimsPayerAddress?.get('city')?.updateValueAndValidity();
    this.paperClaimsPayerAddress?.get('postalCode')?.updateValueAndValidity();
    this.paperClaimsPayerAddress?.get('state')?.updateValueAndValidity();
  }
  
  public getCalimedInfo(){
    const action = `claims/getClaimDetailsForAppointment?appointmentId=${this.appointmentId}`;	
	
	this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
	
      if (data?.status === 'SUCCESS') {
	    const claimData = data?.responseObject || [];
		  if(this.claimProcessingFlag === 'Primary' || this.claimProcessingFlag === 'primary'){
		    this.claimedInfo = [];
		  }
		  
		 else if(this.claimProcessingFlag === 'Secondary' || this.claimProcessingFlag === 'secondary'){
		    this.claimedInfo = claimData?.filter((item: any) => item?.claimProcessorType === 'primary');
		  }
		  else if(this.claimProcessingFlag === 'Tertiary' || this.claimProcessingFlag === 'tertiary'){
		    this.claimedInfo = claimData?.filter((item: any) => item?.claimProcessorType === 'primary' || item?.claimProcessorType === 'secondary');
		  }
		  else{
		  this.claimedInfo = claimData?.filter((item: any) => item?.claimProcessorType === 'primary' || item?.claimProcessorType === 'secondary' || item?.claimProcessorType === 'tertiary');
		  }
	  
        
		
      } else {
        this.claimedInfo = [];
      }
    console.log(this.claimedInfo); 
    },
      (error) => {
        
      });
  
  
  
  }

  ngOnDestroy(): void {
    sessionStorage?.removeItem('claimAppointment');
    this.diagnosisInput?.unsubscribe();
    this.cptCodesInput?.unsubscribe();
    this.getRouterParams?.unsubscribe();
    this.billingInput?.unsubscribe();
    this.icdInput?.unsubscribe();
  }
}
