import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-view-consent-forms',
  templateUrl: './view-consent-forms.component.html',
  styleUrls: ['./view-consent-forms.component.scss']
})
export class ViewConsentFormsComponent implements OnInit {

  @Input() allConsentFormDocs: any[];
  @Input() cardReload: CardComponent;
  public notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public selectedConsentFormDelete: any;
  @Input() showDelete: boolean;
  @Input() patientDetailsInfo: any;
  @Output() consentFormDelete = new EventEmitter();

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
  }

  // Show Consent Forms
  public downloadConsentForm(form: any, pdfConsentModal: UiModalComponent) {
    if (!form?.s3Key || !form?.s3Path) {
      return;
    }
    const fileType = form.fileType;
    let action: string;
    if (form?.s3Key) {
      // action = `SignForm/getSignedConsentForm?consentFormKey=${form?.s3Key}`;
      action = `record/downloadDocument?s3Key=${form?.s3Key}&s3Path=${form?.s3Path}`;
    } else if (form?.agreementId) {
      action = `SignForm/getSignedDoc?agreementId=${form?.agreementId}`;
    };
    this.cardReload?.cardRefreshShow();
    this.httpService?.downloadImage(action).subscribe((data: any) => {
      if(!data) {
        this.cardReload?.cardRefreshHide();
        return;
      }
      var file = new Blob([data], { type: fileType })
      var fileURL = URL.createObjectURL(file);
      const iframeEle = document.getElementById('consent-form-view-pdf') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      pdfConsentModal?.show();
      this.cardReload?.cardRefreshHide();
    },
      (error: any) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException || 'User does not have privileges to perform action';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.cardReload?.cardRefreshHide();
      });
  }

  // Delete Consent Form
  public deleteConsentFormConfirmation(form: any, deletConfirmConsentForm: UiModalComponent): void {
    this.selectedConsentFormDelete = form;
    deletConfirmConsentForm?.show();
  }

  public deleteConsentForm(medicalRecord: any)
  {
    const action = `common/deleteConsentForms?id=${this.patientDetailsInfo?.id}&templateName=${medicalRecord?.additionalKey}`;
    
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      
    },
      (error) => {
       
      })

  }

  public submitDeleteConsentForm(medicalRecord: any): void {
    
    //const action = `common/deleteConsentForms?id=${this.patientDetailsInfo?.id}&templateName=${selectedForm?.templateName}`;
    
    let action = '';
    if (medicalRecord?.additionalKey == null && medicalRecord?.s3Key == null) {
      action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&appointmentId=${this.patientDetailsInfo?.id}`;
    }
    else {
      if (medicalRecord?.additionalKey !== '' && medicalRecord?.additionalKey !== null) {
        action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&appointmentId=${this.patientDetailsInfo?.id}&additionalKey=${medicalRecord?.additionalKey}`;
      }
      else {
        action = `record/deleteDocument?s3Key=${medicalRecord?.s3Key}&appointmentId=${this.patientDetailsInfo?.id}`;
      }

    }



    this.cardReload?.cardRefreshShow();
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      this.cardReload?.cardRefreshHide();
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.consentFormDelete?.emit(true);
      } else {
        this.notifyText = data?.body?.message || data?.body?.msg || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
    },
      (error) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.cardReload?.cardRefreshHide();
        this.exceptionModal?.modalShow();
      })
  }

}
