<!-- Display the order results -->
<app-ui-modal #orderResultsModal [hideHeader]="false" [loadSpinner]="loadSpinner" [modalCentered]="true"
    id="InternalorderResultsModal">
    <div class="app-modal-header">
        <h5 class="modal-title" ><span *ngIf="internalOrder?.patientName">{{internalOrder?.patientName}} -</span> InHouse
            Labs</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="orderResultsModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div id="inhouselabs" class="mb-4">
            <div class="in-house-labs-sec">
                <ng-conatiner *ngIf="internalOrder?.testName === 'Anticoagulant Test'">
                    <ng-container *ngTemplateOutlet="anticoagulantTestTemplate"></ng-container>
                </ng-conatiner>

                <ng-conatiner *ngIf="internalOrder?.testName === 'Blood Glucose'">
                    <ng-container *ngTemplateOutlet="bloodGlucoseTemplate"></ng-container>
                </ng-conatiner>

                <ng-conatiner *ngIf="internalOrder?.testName === 'Covid-19 Test'">
                    <ng-container *ngTemplateOutlet="Covid19TestTemplate"></ng-container>
                </ng-conatiner>

                <ng-conatiner *ngIf="internalOrder?.testName === 'Drug Screening'">
                    <ng-container *ngTemplateOutlet="drugScreening"></ng-container>
                </ng-conatiner>

                <ng-conatiner *ngIf="internalOrder?.testName === 'Influenza Test'">
                    <ng-container *ngTemplateOutlet="influenzaTestTemplate"></ng-container>
                </ng-conatiner>

                <ng-conatiner *ngIf="internalOrder?.testName === 'Pregnancy Test'">
                    <ng-container *ngTemplateOutlet="pregnancyTestTemplate"></ng-container>
                </ng-conatiner>

                <ng-conatiner *ngIf="internalOrder?.testName === 'Rapid A Strep Test'">
                    <ng-container *ngTemplateOutlet="rapidAStrepTestTemplate"></ng-container>
                </ng-conatiner>

                <ng-conatiner *ngIf="internalOrder?.testName === 'Urine Analysis'">
                    <ng-container *ngTemplateOutlet="urineAnalysisTemplate"></ng-container>
                </ng-conatiner>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
            (click)="orderResultsModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- Urine Analysis Order -->
<ng-template #urineAnalysisTemplate>
    <div class="inhouseLabs-bg">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Urine Analysis ({{internalOrder?.urinAnalysisReportForm?.type}}) - {{internalOrder?.status}}:</h5>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5><u>Physical Examination</u></h5>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Appearance</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.appearance || '--'}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Color</label> <span class="label-colon"> : </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.color || '--'}} </label>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="labelStyle">Physical Examination Notes</label> <span class="label-colon"> :
                        </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label>{{internalOrder?.urinAnalysisReportForm?.physicalExamination?.notes || '--'}} </label>
                    </div>
                </div> -->
            </div>

            <!-- Chemical Examination -->
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5><u>Chemical Examination</u></h5>
                <div class="row">
                    <ng-container
                        *ngFor="let chemicalExam of internalOrder?.urinAnalysisReportForm?.chemicalExamination; let i = index">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label>{{chemicalExam?.examName}}&nbsp;&nbsp;:</label>
                            <label class="text-center">&nbsp;&nbsp;{{chemicalExam?.value || '--'}}</label>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Urine Analysis Notes</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.urinAnalysisReportForm?.notes || '--'}} </label>
            </div>

        </div>
    </div>
</ng-template>

<!-- Pregnancy Test Order -->
<ng-template #pregnancyTestTemplate>
    <div class="inhouseLabs-bg">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Pregnancy Test - {{internalOrder?.status}}:</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">HCG</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.pregnancyTest?.result || '--'}} </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">RgetHcgResultesult</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{getHcgResult(internalOrder?.pregnancyTest?.result) || '--'}} </label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.pregnancyTest?.notes || '--'}} </label>
            </div>
        </div>
    </div>
</ng-template>

<!-- Drug Screening Order -->
<ng-template #drugScreening>
    <div class="inhouseLabs-bg">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Drug Screening - {{internalOrder?.status}}:</h5>
            </div>
        </div>
        <div class="row">
            <ng-container *ngFor="let drugItem of internalOrder?.drugScreening?.drugs; let i = index">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label>{{drugItem?.drugName}} - {{drugItem?.deviceCode}}&nbsp;&nbsp;:</label>
                    <label class="text-center">&nbsp;&nbsp;{{drugItem?.result || '--'}}</label>
                </div>
            </ng-container>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.drugScreening?.notes || '--'}} </label>
            </div>
        </div>
    </div>
</ng-template>

<!-- Rapid A Strep Test Order -->
<ng-template #rapidAStrepTestTemplate>
    <div class="inhouseLabs-bg">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Rapid A Strep Test - {{internalOrder?.status}}:</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Result</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.strepTest?.result || '--'}} </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <label>Diagnosis Values:</label>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <ul>
                    <ng-container *ngFor="let diagnosis of internalOrder?.strepTest?.diagnosis; let i = index">
                        <li>{{diagnosis}}</li>
                    </ng-container>
                </ul>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.strepTest?.notes || '--'}} </label>
            </div>
        </div>
    </div>
</ng-template>

<!-- Anticoagulant Test Order -->
<ng-template #anticoagulantTestTemplate>
    <div class="inhouseLabs-bg">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Anticoagulant Test - {{internalOrder?.status}}:</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Result Range</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.anticoagulantTestResults?.range || '--'}} </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Units</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.anticoagulantTestResults?.units || '--'}} </label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.anticoagulantTestResults?.notes || '--'}} </label>
            </div>
        </div>
    </div>
</ng-template>

<!-- Blood Glucose Order -->
<ng-template #bloodGlucoseTemplate>
    <div class="inhouseLabs-bg">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Blood Glucose - {{internalOrder?.status}}:</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Blood Glucose (With Fasting)</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.bloodGlucoseResults?.bloodSugarWithFasting || '--'}} </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Blood Glucose (Without Fasting)</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.bloodGlucoseResults?.bloodSugarWithoutFasting || '--'}} </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">A1C Value</label> <span
                    class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.bloodGlucoseResults?.a1CValue || '--'}} </label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.bloodGlucoseResults?.notes || '--'}} </label>
            </div>
        </div>
    </div>
</ng-template>

<!-- Covid-19 Test Order -->
<ng-template #Covid19TestTemplate>
    <div class="inhouseLabs-bg">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Covid-19 Test - {{internalOrder?.status}}:</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Test Type</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.covidTestResults?.type || '--'}} </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Test Result</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.covidTestResults?.result || '--'}} </label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.covidTestResults?.notes || '--'}} </label>
            </div>
        </div>
    </div>
</ng-template>

<!-- Influenza Test Order -->
<ng-template #influenzaTestTemplate>
    <div class="inhouseLabs-bg">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h5>Influenza Test - {{internalOrder?.status}}:</h5>
            </div>
        </div>
        <div class="row">
            <ng-container *ngFor="let influTest of internalOrder?.influenzaTest?.tests; let i = index">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <label>{{influTest?.fluType}}&nbsp;&nbsp;:</label>
                    <label class="text-center">&nbsp;&nbsp;{{influTest?.result || '--'}}</label>
                </div>
            </ng-container>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label class="labelStyle">Additional Notes</label> <span class="label-colon"> : </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <label>{{internalOrder?.influenzaTest?.notes || '--'}} </label>
            </div>
        </div>
    </div>
</ng-template>