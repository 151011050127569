<div class="user-info media">
    <div class="profile-pic">
	<div class="custom-loader justify-content-center" *ngIf="loadSpinner">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

        <ng-container *ngIf="userProfileImage === null">
            <img src="../../../../../assets/images/user/avatar.png" class="profile" style="width:77px;">
        </ng-container>

        <ng-container *ngIf="userProfileImage !== null">
            
            <div class="profile-pic-container">
			

                <img style="width: 100%;padding: 0px 20px" name="Image Shown" id="profile_picture_display"
                    class="profile" style="width:77px; height: 77px; border-radius: 50%;" />



                <button *ngIf="userProfileImage !== null" class="icon-queue-events delete-action-bg" style="border-radius: 50%;"
                    (click)="deleteImage();" placement="bottom" ngbTooltip="Delete">
                    <i class="lar la-trash-alt"
                        style="font-size: 15px; color: black; margin-top: 10x; cursor: pointer;"></i>
                </button>

                <button *ngIf="userProfileImage !== null" class="icon-queue-events full-view-bg" style="border-radius: 50%;"
                    (click)="readProfilePic('fullview');" placement="bottom" ngbTooltip="Full View">
                    <i class="las la-expand"
                        style="font-size: 15px; color: black; margin-top: 10x; cursor: pointer;"></i>
                </button>

            </div>

        </ng-container>


        <p class="useric-actions">
            <!-- <i class="fa-sharp fa-solid fa-circle-plus" style="color: #1B154C;"></i> -->
            <a class="edit add-icon" style="cursor:pointer" (click)="openInsuranceCamera();"
                *ngIf="!showWebcam && userProfileImage === null"><i class="fa fa-plus " aria-hidden="true"></i></a>
            <a class="delete" style="cursor:pointer" (click)="closeInsuranceCamera();" *ngIf="showWebcam"><i
                    class="fa fa-trash"></i></a>
        </p>

    </div>
</div>


<app-ui-modal #deleteConfirmation [hideHeader]="false" id="deleteConfirmation">
    <div class="app-modal-header">
        <h5 class="modal-title">Profile Image</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="deleteConfirmation.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">

                <h5 class="modal-title text-info text-center" style="font-size: 50px; margin: 0px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </h5>

            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">

                <h5 class="modal-title" style=" text-align: left;">Are you sure want to delete profile image?</h5>

            </div>
        </div>

    </div>
    <div class="app-modal-footer">
        <button (click)="deleteProfilePic('delete',profiles3Key);" style="margin-right: 15px;"
            class="btn btn-primary continue-btn">Yes</button>
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
            (click)="deleteConfirmation.hide();">No</button>
    </div>
</app-ui-modal>
<app-ui-modal #profilepicUploadModal [hideHeader]="false" id="profilepicUploadModal"  [loadSpinner]="loadSpinner">
    <div class="app-modal-header">
        <h5 class="modal-title">Profile Image</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="profilepicUploadModal.hide();closeInsuranceCamera();"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="app-modal-body">
        <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 16px;">

                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                        <div class="webcam-capture">
                            <webcam [trigger]="triggerObservable" (imageCapture)="handleInsuranceImage($event)"
                                [height]="300" *ngIf="showInsuranceWebcam" [width]="300"
                                [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                [videoOptions]="videoOptions" [imageQuality]="1"
                                (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
                            </webcam>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="webcamInsuranceImage && this.webcamInsuranceImages?.length > 0">
                        <!-- Thumbnail images -->
                        <div class="row">

                            <div class="col-lg-12 col-md-12 col-sm-12 text-center">

                                <div (click)="currentInsuranceSlide(this,i)"
                                    *ngFor="let webcamInsuranceImage of webcamInsuranceImages;let i = index">

                                    <img style="width: 300px; height: 300px;" class="demo cursor mb-3"
                                        [src]="webcamInsuranceImage?.imageAsDataUrl" />
                                </div>

                                <div (click)="currentInsuranceSlide(this,i)"
                                    *ngFor="let webcamInsuranceImage of webcamInsuranceImages;let i = index">
                                    <!-- <img (click)="clearInsuranceSnapshot(i);" src="../../assets/images/DeleteBin.png"
                                    alt="Clear Snapshot" class="mb-3" style="cursor: pointer;"> -->
                                    <button class="icon-queue-events delete-bg" 
                                        (click)="clearInsuranceSnapshot(i);" placement="bottom" ngbTooltip="Delete">
                                        <i class="lar la-trash-alt"
                                            style="font-size: 25px; color: black; margin-top: 10x; cursor: pointer;"></i>
                                    </button>

                                </div>

                            </div>
                            </div>

                        </div>

                </div>

            </div>

        </div>
    </div>


    <div class="app-modal-footer">
        <button *ngIf="showInsuranceWebcam && webcamInsuranceImages.length < 1" (click)="triggerInsuranceSnapshot();"
            class="btn btn-primary continue-btn" style="margin-right: 15px;">Capture
        </button>
        <button *ngIf="webcamInsuranceImages.length !== 0" (click)="uploadProfilepic();" id="commonProfilePic_save"
            class="btn btn-primary continue-btn" style="margin-right: 15px;">Save</button>
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal"
            (click)="profilepicUploadModal.hide();closeInsuranceCamera();" id="commonProfilePic_cancel">Cancel</button>
    </div>
</app-ui-modal>

<app-ui-modal #fullView [hideHeader]="false" id="fullView"  [loadSpinner]="loadSpinner">
    <div class="app-modal-header">
        <h5 class="modal-title">Profile Image</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="fullView.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">

        <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 text-center">

                <!-- <iframe id="profile_picture_fullview" frameBorder="0" width="auto" height="auto"></iframe>-->

                <img style="width: 300px;height: 300px" name="Image Shown" id="profile_picture_fullview" />

            </div>
        </div>

    </div>
    <div class="app-modal-footer">

        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal" id="commonProfilePic_ok1"
            (click)="fullView.hide();">Ok</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>