import { Injectable } from '@angular/core';
import { AppConstantsListConfig } from '../../../shared/litterals/app.constants';
export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  isImage?: boolean;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}
const userDetails = JSON.parse(sessionStorage?.getItem('userDetails'));
const isKioskShown = userDetails?.roleType === 'Kiosk' ? true : false
const NavigationItems = [
  {
    id: 'navigation',
    // title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'hosp-dashboard',
        title: 'Flow Board',
        type: 'item',
        // icon: 'fa fa-th-list',
        icon: "las la-chalkboard",
        url: '/hospital/hosp-dashboard',
        isShown: 'P1'
      },
      // Health Works
      {
        id: 'hosp-InvoiceBoard',
        title: 'Invoice Board',
        type: 'item',
        // icon: 'fa fa-th-list',
        icon: "../../assets/images/Healthworks/menu-invoice.svg",
        url: '/hospital/invoiceBoard',
        isShown: 'P1',
        isImage: true
      },
      {
        id: 'hosp-ChargeMaster',
        title: 'Charge Master',
        type: 'item',
        // icon: 'fa fa-th-list',
        icon: "../../assets/images/Healthworks/menu-charge.svg",
        url: '/hospital/chargeMaster',
        isShown: 'P1',
        isImage: true
      },
      {
        id: 'full-calendar',
        title: 'Calendar',
        type: 'item',
        url: '/hospital/calendar',
        classes: 'nav-item',
        // icon: 'fa fa-calendar-alt',
        icon: 'las la-calendar',
        isShown: 'P1',
      },
      // {
      //   id: 'Patient',
      //   title: 'Treatment Flow',
      //   type: 'item',
      //   // icon: 'fa fa-procedures',
      //   icon: 'las la-medkit',
      //   url: '/hospital/hosp-patient',
      //   isShown: 'P1',
      // },
      /*{
        id: 'careflow',
        title: 'Care Flow',
        type: 'item',
        // icon: 'fa fa-procedures',
        icon: 'las la-medkit',
        url: '/hospital/hosp-careflow-patient-list',
        isShown: 'P1',
      },*/
      {
        id: 'careflow',
        title: 'Care Flow',
        type: 'item',
        // icon: 'fa fa-procedures',
        icon: 'las la-medkit',
        url: '/hospital/hosp-careflow-patient-list',
        isShown: 'P1',
      },
      // {
      //   id: 'future-appointmentsr',
      //   title: 'Future Appointments',
      //   type: 'item',
      //   url: '/hospital/future-appointments',
      //   classes: 'nav-item',
      //   // icon: 'fa fa-calendar-week',
      //   icon: 'las la-calendar-check',
      //   isShown: 'P1',
      // },
      // {
      //   id: 'claims',
      //   title: 'Claims',
      //   type: 'item',
      //   // icon: 'fa fa-file-invoice-dollar',
      //   icon: 'las la-file-invoice-dollar',
      //   url: '/hospital/all-claims',
      // },
      {
        id: 'Billing And Insights',
        title: 'Coding and Billing',
        type: 'collapse',
        // icon: 'fa fa-envelope-open-text',
        icon: 'las la-file-invoice-dollar',
        // url: '/hospital/health-pass',
        isShown: 'P1',
        children: [
          {
            id: 'claims',
            title: 'Claims',
            type: 'item',
            url: '/hospital/all-claims-new',
            // url: '/hospital/claims',
          },
          {
            id: 'AR Calls',
            title: 'AR Calls',
            type: 'item',
            url: '/hospital/ar-calls',
            // url: '/hospital/claims',
          },
          {
            id: 'Self-Pay',
            title: 'Self-Pay',
            type: 'item',
            url: '/hospital/self-pay',
            // url: '/hospital/claims',
          },
          // {
          //   id: 'encounter_insights',
          //   title: 'Encounter Insights',
          //   type: 'item',
          //   url: '/hospital/encounter-insight',
          // },
          ,
          {
            id: 'service_utilization',
            title: 'Service Utilization',
            type: 'item',
            url: '/hospital/service-encounter-utilization',
          },
          {
            id: 'ar_insights',
            title: 'AR Insights',
            type: 'item',
            url: '/hospital/ar-insight',
          },
          {
            id: 'powerbi_reports',
            title: 'Reports',
            type: 'item',
            url: '/hospital/reports',
          }
        ]
      },
      {
        id: 'Referral',
        title: 'Referral',
        type: 'item',
        // icon: 'fa fa-user-friends',
        icon: ' las la-clone',
        url: '/hospital/hosp-referral',
      },
      {
        id: 'Rx_Orders',
        title: 'Rx Orders',
        type: 'item',
        // icon: 'fa fa-file-prescription',
        icon: ' las la-file-prescription',
        url: '/hospital/rx-orders',
      },
      {
        id: 'Lab_Rad_Orders',
        title: 'Lab Orders',
        type: 'item',
        // icon: 'fa fa-file-medical-alt',
        icon: ' las la-file-medical-alt',
        url: '/hospital/lab-orders',
      },
      {
        id: 'Rad_Orders',
        title: 'Rad Orders',
        type: 'item',
        // icon: 'fa fa-file-prescription',
        icon: 'las la-x-ray',
        url: '/hospital/rad-orders',
      },
      // {
      //   id: 'fileUploads',
      //   title: 'File Uploads',
      //   type: 'item',
      //   // icon: 'fa fa-cloud-upload-alt',
      //   icon:' las la-file-upload',
      //   url: '/hospital/hosp-fileuploads',
      // },
      {
        id: 'e-fax',
        title: 'e-fax',
        type: 'item',
        // icon: 'fa fa-envelope-open-text',
        icon: ' las la-envelope-open-text',
        url: '/hospital/hosp-e-fax',
      },
      // Health Works
      {
        id: 'networkManagement',
        title: 'Network Management',
        type: 'item',
        // icon: 'fa fa-envelope-open-text',
        icon: '../../assets/images/Healthworks/menu-network.svg',
        url: '/hospital/networkManagement',
        isImage: true
      },
      {
        id: 'insights',
        title: 'Insights',
        type: 'item',
        // icon: 'fa fa-envelope-open-text',
        icon: '../../assets/images/Healthworks/menu-insights.svg',
        url: '/hospital/insights',
        isImage: true
      },
      {
        id: 'messages',
        title: 'Messages',
        type: 'item',
        // icon: 'fa fa-envelope-open-text',
        icon: '../../assets/images/Healthworks/menu-msg.svg',
        url: '/hospital/messages',
        isImage: true
      },
      {
        id: 'ptimize',
        title: 'Optimize',
        type: 'item',
        icon: 'fa fa-tasks',
        url: '/hospital/optimize',
        
      },
      {
        id: 'workflow-efficiency',
        title: 'Workflow Efficiency',
        type: 'item',
        icon: 'fa fa-tasks',
        url: '/hospital/workflow-efficiency',
        hidden: true
      },
      {
        id: 'health-pass',
        title: 'Health Pass',
        type: 'collapse',
        // icon: 'fa fa-envelope-open-text',
        icon: 'las la-address-card',
        // url: '/hospital/health-pass',
        isShown: 'P1',
        children: [
          {
            id: 'Enrollment',
            title: 'Enrollment',
            type: 'item',
            url: '/hospital/health-pass',
          },
          {
            id: 'Members',
            title: 'Members',
            type: 'item',
            url: '/hospital/health-pass-member',
          }
          /* {
             id: 'Members',
             title: 'Members',
             type: 'item',
             url: '/hospital/health-pass/health-pass-members',
           }*/
        ]
      },
      // {
      //   id: 'Profile',
      //   title: 'Profile',
      //   type: 'item',
      //   icon: 'fa fa-user-check',
      //   url: '/users/profile',
      // },
      {
        id: 'Care_Bridge',
        title: 'Care Outreach',
        type: 'collapse',
        icon: ' las la-chart-pie',
        isShown: 'P1',
        children: [
          {
            id: 'ScoreCard',
            title: 'Score Card',
            type: 'item',
            url: '/hospital/score-card',
          },
          {
            id: 'healthWorks',
            title: 'Health Works',
            type: 'item',
            url: '/hospital/care-bridge/health-Works'
          },
          {
            id: 'populationInsights',
            title: 'Population Insights',
            type: 'item',
            url: '/hospital/care-bridge/population-insights',
          },
          // {
          //   id: 'populationTrendz',
          //   title: 'Service utilization',
          //   type: 'item',
          //   url: '/hospital/care-bridge/population-trendz',
          // },
          {
            id: 'utilizationInsights',
            title: 'Utilization Insights',
            type: 'item',
            url: '/hospital/care-bridge/utilization-insights'
          },
          {
            id: 'utilizationInsightsCharts',
            title: 'Utilization Insights',
            type: 'item',
            url: '/hospital/care-bridge/utilization-insights-charts'
          },
          {
            id: 'careGaps',
            title: 'Care Gaps',
            type: 'item',
            url: '/hospital/care-bridge/care-gaps'
          },
          {
            id: 'campaigns',
            title: 'Campaigns',
            type: 'item',
            url: '/hospital/care-bridge/campaigns'
          },
          {
            id: 'outcomes',
            title: 'Outcomes',
            type: 'item',
            url: '/hospital/care-bridge/outcomes'
          },
                    
        ]
      },
      // {
      //   id: 'Connect_Bridge',
      //   title: 'Connect Bridge',
      //   type: 'collapse',
      //   // icon: 'fa fa-chart-pie',
      //   icon:' las la-chart-pie',
      //   isShown: 'P1',
      //   children: [
      //     {
      //       id: 'ScoreCard',
      //       title: 'Score Card',
      //       type: 'item',
      //       url: '/hospital/score-card',
      //     },
      // {
      //   id: 'Pilotscorecard',
      //   title: 'Pilot Score Card',
      //   type: 'item',
      //   url: '/hospital/hosp-pilot-score-card',
      // },
      // {
      //   id: 'Pre-BuiltCohorts',
      //   title: 'Patient Cohorts',
      //   type: 'item',
      //   url: '/hospital/hosp-pre-built-cohorts',
      // },
      // {
      //   id: 'PatientJourneys',
      //   title: 'Patient Journeys',
      //   type: 'item',
      //   url: '/hospital/hosp-patient-journeys'
      // },
      // {
      //   id: 'cohortoutcomes',
      //   title: 'Cohort Outcomes',
      //   type: 'item',
      //   url: '/hospital/hosp-cohort-outcomes'
      // },
      // {
      //   id: 'RPM',
      //   title: 'RPM',
      //   type: 'item',
      //   external: true,
      //   url: 'https://app.novorpm.com/',
      //   target: true,
      //   isShown: 'P1'
      // }
      //  ]
      // },
      // {
      //   id: 'Reporting',
      //   title: 'Reports',
      //   type: 'collapse',
      //   icon: 'fa fa-file-signature',
      //   isShown: 'P1',
      //   children: [
      //     {
      //       id: 'UtilizationInsights',
      //       title: 'Utilization Insights',
      //       type: 'item',
      //       url: '/hospital/hosp-utilization-insights',
      //       isShown: 'P1'
      //     },
      //     {
      //       id: 'FinancialInsights',
      //       title: 'Financial Insights',
      //       type: 'item',
      //       url: '/hospital/hosp-financial-insights'
      //     },
      //     {
      //       id: 'MarketInsights',
      //       title: 'Market Insights',
      //       type: 'item',
      //       url: '/hospital/hosp-market-insights'
      //     }
      //   ]
      // },
      {
        id: 'Configurations',
        title: 'Configurations',
        type: 'collapse',
        // icon: 'fa fa-users-cog',
        icon: ' las la-cogs',
        isShown: 'P1',
        children: [
          {
            id: 'OutreachOperations',
            title: 'Outreach Operations',
            type: 'item',
            url: '/hospital/hosp-patient-outreach',
          },

          // {
          //   id: 'ServicesOffered',
          //   title: 'Services Offered',
          //   type: 'item',
          //   url: '/email/admin-services-offered'
          // },
          // {
          //   id: 'InsurancesAccepted',
          //   title: 'Insurances Accepted',
          //   type: 'item',
          //   url: '/email/admin-insurances-accepted'
          // },
          // {
          //   id: 'PatientConsentForms',
          //   title: 'Patient Consent Forms',
          //   type: 'item',
          //   url: '/email/admin-patient-consent-forms'
          // },
          // {
          //   id: 'mail-inbox',
          //   title: 'mail-inbox',
          //   type: 'item',
          //   url: '/email/mail-inbox'
          // },
          // {
          //   id: 'Staff',
          //   title: 'Staff',
          //   type: 'item',
          //   url: '/email/admin-staff'
          // },   
          // {
          //   id: 'CohortsConfigurations_Outreach Priorities',
          //   title: 'Outreach Priorities',
          //   type: 'item',
          //   url: '/email/admin-cohorts-settings'
          // },
          // {
          //   id: 'OutreachConfigurations',
          //   title: 'Outreach Policies',
          //   type: 'item',
          //   url: '/hospital/hosp-outreach-configurations',
          //   isShown: 'P1'
          // },
          {
            id: 'carepathwaypolicies',
            title: 'Care Pathways Policies',
            type: 'item',
            url: '/hospital/hosp-care-pathways-configurations'
          },
          // {
          //   id: 'PracticeOperations',
          //   title: 'Practice Operations',
          //   type: 'item',
          //   url: '/hospital/operations-configurations'
          // },
          {
            id: 'Accesspolicies',
            title: 'Access Policies',
            type: 'item',
            url: '/hospital/manage-access-policies'
          },
          // {
          //   id: 'VaccineInventory',
          //   title: 'Inventory',
          //   type: 'item',
          //   url: '/hospital/inventory'
          // },
          // {
          //   id: 'CohortsSettings',
          //   title: 'Cohorts Settings',
          //   type: 'item',
          //   url: '/email/admin-cohorts-settings'
          // }
        ]
      },
      {
        id: 'PracticeOperations',
        title: 'Practice Operations',
        type: 'collapse',
        icon: 'fa fa-users-cog',
        isShown: 'P1',
        children: [
          {
            id: 'Preference',
            title: 'Preferences',
            type: 'item',
            url: '/hospital/operations-configurations'
          },
          {
            id: 'VaccineInventory',
            title: 'Inventory',
            type: 'item',
            url: '/hospital/inventory'
          },
        ]
      },
      // {
      //   id: 'support',
      //   title: 'Support',
      //   type: 'item',
      //   icon: 'feather icon-help-circle',
      //   url: '/hospital/hosp-support',
      // },
      {
        id: 'Support',
        title: 'Support',
        type: 'item',
        // icon: 'feather icon-help-circle',
        icon: ' las la-hand-holding-heart',
        external: true,
        //url: 'https://careeco.atlassian.net/servicedesk/customer/portal/1',
        url: 'https://careecoworkspace.slack.com/ssb/redirect',
        target: true
      },
      
      // {
      //   id: 'Slack',
      //   title: 'Slack',
      //   type: 'item',
      //   // icon: 'feather icon-help-circle',
      //   icon:' las la-hand-holding-heart',
      //   external: true,
      //   url: 'https://careecoworkspace.slack.com/ssb/redirect',
      //   target: true
      // },
      // {
      //   id: 'incorrect-phone-numbers',
      //   title: 'Incorrect Phone Numbers',
      //   type: 'item',
      //   icon: 'feather icon-help-circle',
      //   url: '/hospital/incorrect-phone-numbers',
      //   hidden: true
      // },
    ]
  }

];


const BusinessAdminNavigationItems = [
  {
    id: 'businessadminnavigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'Connect Dashboard',
        title: 'Connect Dashboard',
        type: 'item',
        icon: 'feather icon-grid',
        url: '/hospital/score-card',
      },
      {
        id: 'hosp-PatientConnect',
        title: 'Patient Connect',
        type: 'collapse',
        icon: 'feather icon-grid',
        url: '/hospital/score-card',
        children: [
          {
            id: 'Pre-BuiltCohorts',
            title: 'Pre-Built Cohorts',
            type: 'item',
            url: '/hospital/hosp-pre-built-cohorts',
          },
          {
            id: 'PatientOutreach',
            title: 'Patient Outreach',
            type: 'item',
            url: '/hospital/hosp-patient-outreach',
          },
          // {
          //   id: 'PatientJourneys',
          //   title: 'Patient Journeys',
          //   type: 'item',
          //   url: '/hospital/hosp-patient-journeys'
          // },
          {
            id: 'CohortOutcomes',
            title: 'Cohort Outcomes',
            type: 'item',
            url: '/hospital/hosp-cohort-outcomes'
          }
        ]
      },
      {
        id: 'Reporting',
        title: 'Reports',
        type: 'collapse',
        icon: 'feather icon-grid',
        children: [
          {
            id: 'UtilizationInsights',
            title: 'Utilization Insights',
            type: 'item',
            url: '/hospital/hosp-utilization-insights'
          },
          {
            id: 'FinancialInsights',
            title: 'Financial Insights',
            type: 'item',
            url: '/hospital/hosp-financial-insights'
          },
          {
            id: 'MarketInsights',
            title: 'Market Insights',
            type: 'item',
            url: '/hospital/hosp-market-insights'
          }
        ]
      },
      {
        id: 'Admin',
        title: 'Configurations',
        type: 'collapse',
        icon: 'feather icon-grid',
        children: [
          {

            id: 'operations',
            title: 'Operations',
            type: 'item',
            url: '/email/admin-cohorts-settings'
          },
          {

            id: 'CohortsConfigurations',
            title: 'Cohorts',
            type: 'item',
            url: '/email/admin-cohorts-settings'
          },
          {
            id: 'OutreachConfigurations',
            title: 'Out Reach',
            type: 'item',
            url: '/hospital/hosp-outreach-configurations'
          },
          {
            id: 'CarePathwaysConfigurations',
            title: 'Care Pathways',
            type: 'item',
            url: '/hospital/hosp-care-pathways-configurations'
          }
        ]
      },
    ]
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
  public getBusinessAdminNavigation() {
    return BusinessAdminNavigationItems;
  }
}
