import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-addendum-notes-chart',
  templateUrl: './addendum-notes-chart.component.html',
  styleUrls: ['./addendum-notes-chart.component.scss']
})
export class AddendumNotesChartComponent implements OnInit {



  public addendumNotesForm: FormGroup;
  @ViewChild('AddendumModal') addendumModal: UiModalComponent;
  public loadSpinner: boolean;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  selectedAppointmentId: any;
  public allAddendumReasons: any[] = ['Lab Results Review', 'Radiology Results Review', 'Medical Record Review', 'Billing Code Edits', 'Care Plan Review', 'Chart Edits'];
  isEditValidateLock: boolean;
  @Input() addendamData:any;
  allCareFlowData: any;
  constructor(private httpService: HttpService, private router: Router, private formBuilder: FormBuilder, private activateRoute: ActivatedRoute) {
    this.addendumNotesForm = formBuilder?.group({
      "dateTime": [null],
      "initials": [null, Validators.required],
      "notes": [null, Validators.required],
      "reason": [null, Validators?.required]
    });
  }

  ngOnInit(): void {
  }

  openAddendumModal(appointmentId?: string, isEdit?: boolean, allAppntDetails?: any): void {
    this.selectedAppointmentId = appointmentId;
    this.isEditValidateLock = isEdit || false;
    if(!this.addendamData && allAppntDetails) {
      this.getAllModulesSavedData(allAppntDetails);
    }
    this.addendumModal?.show();
  }

  public getAllModulesSavedData(allAppntDetails: any): void {
    const action = `careFlow/readCareFlowData?facilityId=${allAppntDetails?.facilityId}&appointmentId=${allAppntDetails?.id}&userInfoId=${allAppntDetails?.userInfoId}`;
    this.httpService.makeGetRequest(action, '').subscribe((data: any) => {
      this.allCareFlowData = data?.responseObject;
      this.addendamData = this.allCareFlowData?.addendum;
    },
      (error) => {
      })
  }

  // addendum notes update
  public addendumNotesUpdate(): void {
    if (this.addendumNotesForm?.invalid) {
      this.addendumNotesForm?.markAllAsTouched();
      return;
    }
    const action = `treatmentFlow/addAddendum?appointmentId=${this.selectedAppointmentId}`;
    const params = this.addendumNotesForm?.value;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message || data?.body?.msg;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.addendumModal?.hide();
        this.addendumNotesForm?.reset();
        if(this.isEditValidateLock) {
          sessionStorage.setItem('careflowPath', 'appointment');
          this.router?.navigate([`/hospital/hosp-patientdetails/${this.selectedAppointmentId}`]);
        }
      }
      this.loadSpinner = false;
    }, (error) => {
      this.notifyText = error?.message || error?.msg || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
    });
  }
}
