import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';

declare var Square: any;

@Component({
  selector: 'app-patient-responsibility',
  templateUrl: './patient-responsibility.component.html',
  styleUrls: ['./patient-responsibility.component.scss']
})
export class PatientResponsibilityComponent implements OnInit, OnChanges, OnDestroy {

  // private APPLICATION_ID = environment?.pfSquare?.applicationId;
  // private LOCATION_ID = environment?.pfSquare?.locationId;
  public enteredAmount: number;
  public totalAmount: number = 0;
  public displayPaymentSection: boolean = false;
  public displayCreditCardSection: boolean = false;
  public displayDigitalWalletsSection: boolean = false;
  public displayACHPaymentSection: boolean = false;
  public title: string = 'Pending Payments';

  @ViewChild('patientResponsibilityModal') paymentCard: UiModalComponent;
  public loadSpinner: boolean;
  public selectedPayAppointment: any;
  public paymentType = [
    { desc: 'POS Payment', value: 'terminal' },
    { desc: 'Non POS Payment', value: 'noTerminal' },
    { desc: 'Cash Payment', value: 'cashPayment' },
    { desc: 'Cheque Payment', value: 'chequePayment' },
    { desc: 'Online Payment', value: 'onlinePayment' },
  ];
  public paymentMode = [
    { desc: 'Credit Card Payment', value: 'Credit Card Payment' },
    { desc: 'ACH Payment', value: 'ACH Payment (Pay with Bank Account)' },
    { desc: 'Digital wallets', value: 'Digital wallets' }
  ];
  public selectedPaymentType: string;
  public selectedPaymentMode: string;
  public amountForm: FormGroup;
  public subscription: Subscription;
  @ViewChild('paySuccessModal') paySuccessModal: UiModalComponent;
  successPayDetails: any;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public showPOSCancel: boolean;
  public posPaymentObj: any;
  public enableNonPosCancelPayment: boolean;
  @Input() private paymentConfigDetails: any;
  private APPLICATION_ID: string;
  private LOCATION_ID: string;
  cardPaymentObj: any;
  public cardPaymentSubscription: Subscription;
  // public coPayAmount: number = null;
  // public disableCoPayAmount: boolean = true;
  // public inHouseLabsAmount: number = null;
  // public disableInHouseLabsAmount: boolean = true;
  // public consultationAmount: number = null;
  // public disableConsultationAmount: boolean = true;
  // public externalLabsAmount: number = null;
  // public disableExternalLabsAmount: boolean = true;
  // public otherAmount: number = null;
  // public disableOtherAmount: boolean = true;
  public servicesForm: FormGroup;
  public totalMinimumAmountError: boolean = false;
  public patientResponsibilityResponse;
  public patientResp = [];
  public totalCredits: number = 0;
  public selectedPatientRespCredits = [];
  @Output() patientRespCreditsUpdate = new EventEmitter<boolean>();


  constructor(private httpService: HttpService, private formBuilder: FormBuilder) {
    this.amountForm = formBuilder?.group({
      'amount': [null, Validators.required]
    });

    this.servicesForm = this.formBuilder?.group({
      'appointmentDate': [{ value: null, disabled: true }],
      'selectPatientResponsibility': [null],
    })
  }

  ngOnInit(): void {
    // this.cardPayment();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.APPLICATION_ID = this.paymentConfigDetails?.clientId;
      this.LOCATION_ID = this.paymentConfigDetails?.locationId;
    }
  }

  public getPatientResponsibility(userInfoId): void {

    const action = `appointment/registration/getPatientResponsibilityNCredits?userInfoId=${userInfoId}`

    this.loadSpinner = true;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        this.patientResponsibilityResponse = data?.responseObject;
        this.patientResp = data?.responseObject?.patientRespNCredits;
        this.totalCredits = data?.responseObject?.totalCredits;
      }
      else {
        this.closeModal();
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  public getAppointmentTime(date: any): string {
    return this.httpService.getAppointmentTime(date);
  }

  public calculateTotal(event: any, patientResp: any): void {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.totalAmount = this.totalAmount + patientResp?.patientResponsibility;
      this.selectedPatientRespCredits.push(patientResp)
    }
    else {
      this.totalAmount = this.totalAmount - patientResp?.patientResponsibility;
      this.selectedPatientRespCredits.forEach((element, index) => {
        if (element.appointmentId === patientResp.appointmentId)
          this.selectedPatientRespCredits.splice(index, 1)
      })
    }

    console.log(this.selectedPatientRespCredits)
  }


  public updatePatientResponsibility(successPayDetails: any, modeOfPay: string): void {
    const action = 'payment/updatePatientResponsibility';
    // this.selectedPatientRespCredits.forEach((item) => {
    //   item.patientResponsibility = 0;
    // })
    const params = {
      "facilityId": this.selectedPayAppointment?.facilityId,
      "modeOfPay": modeOfPay || null,
      "paymentId": successPayDetails?.paymentId || null,
      "paymentOrderId": null,
      "receiptUrl": successPayDetails?.receiptUrl || null,

      "latestAppointmentId": this.patientResponsibilityResponse?.latestAppointmentId,
      "patientAccountId": this.patientResponsibilityResponse?.patientAccountId,
      "patientRespNCredits": this.selectedPatientRespCredits,
      "totalCredits": this.patientResponsibilityResponse?.totalCredits,
      "userInfoId": this.patientResponsibilityResponse?.userInfoId,
      "pendingPatientResponsibility": null
    };

    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.selectedPatientRespCredits = [];
        this.patientRespCreditsUpdate?.emit(true);
      }
      else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  public changePayType(): void {
    this.amountForm?.get('amount')?.setValue(null);
  }


  public changePaymentMode(): void {
    if (this.selectedPaymentType === 'noTerminal' && this.selectedPaymentMode === 'Credit Card Payment') {
      this.cardPayment();
    }
    if (this.selectedPaymentMode === 'Credit Card Payment') {
      // this.enableCardPay();
    } else if (this.selectedPaymentMode === 'ACH Payment (Pay with Bank Account)') {
      this.achPayOpen();
    }
    if (this.selectedPaymentMode === 'Digital wallets') {
      this.applePayOpen();
      this.googlePayOpen();
    }
  }


  public makePayment(): void {
    if (this.selectedPaymentType === 'cashPayment'  || this.selectedPaymentType === 'chequePayment' || this.selectedPaymentType === 'terminal' || this.selectedPaymentType === 'noTerminal' || this.selectedPaymentType === 'onlinePayment') {
      // this.totalAmount = this.servicesForm?.get('coPayAmount')?.value + this.servicesForm?.get('inHouseLabsAmount')?.value + this.servicesForm?.get('consultationAmount')?.value + this.servicesForm?.get('externalLabsAmount')?.value + this.servicesForm?.get('otherAmount')?.value;
      this.amountForm?.get('amount')?.setValue(this.totalAmount);
    }
    if (!this.amountForm?.get('amount')?.value || this.amountForm?.get('amount')?.value === 0) {
      this.totalMinimumAmountError = true;
      return;
    }
    this.totalMinimumAmountError = false;
    this.displayPaymentSection = true;
    this.title = 'Make Payments';
  }

  public enableCardPay(): void {
    this.selectedPaymentType = null;
    this.showPOSCancel = false;
    this.enableNonPosCancelPayment = false;
    // this.cardPayment();
  }

  // trigget payment with ach payment
  public achPayOpen(): void {
    this.achPayment();
  }

  // trigger apple payment
  public applePayOpen(): void {
    this.applePay();
  }

  // trigger google payment
  public googlePayOpen(): void {
    this.googlePay();
  }

  // open modal from flow board
  public openModal(appointment: any): void {
    this.selectedPayAppointment = appointment;
    this.servicesForm?.reset();
    this.amountForm?.reset();
    this.paymentCard?.show();
    this.getPatientResponsibility(this.selectedPayAppointment.userInfoId)
  }

  // close modal
  public closeModal(): void {
    this.title = 'Payments';
    this.loadSpinner = false;
    // this.coPayAmount = null;
    // this.disableCoPayAmount = true;
    // this.inHouseLabsAmount= null;
    // this.disableInHouseLabsAmount = true;
    // this.consultationAmount = null;
    // this.disableConsultationAmount = true;
    // this.externalLabsAmount = null;
    // this.disableExternalLabsAmount = true;
    // this.otherAmount= null;
    // this.disableOtherAmount = true; 
    this.amountForm?.reset();
    this.displayPaymentSection = false;
    this.servicesForm?.reset();
    this.selectedPaymentType = null;
    this.servicesForm?.get('coPayAmount')?.disable();
    this.servicesForm?.get('inHouseLabsAmount')?.disable();
    this.servicesForm?.get('consultationAmount')?.disable();
    this.servicesForm?.get('externalLabsAmount')?.disable();
    this.servicesForm?.get('otherAmount')?.disable();
    this.selectedPaymentMode = null;
    this.totalMinimumAmountError = false;
    this.paymentCard?.hide();
    this.totalAmount = 0;
   
    // this.checkboxes.forEach((element) => {
    //   element.nativeElement.checked = false;
    // });
  }

  // card payment
  private async cardPayment() {
    this.loadSpinner = true;
    await new Promise(resolve => setTimeout(resolve, 2000));
    const containerEle = document?.getElementById('card-container');
    if (containerEle?.innerHTML) {
      containerEle.innerHTML = '';
    }
    const payments = Square?.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const card = await payments?.card();
    await card?.attach('#card-container');

    async function eventHandler(event) {
      event.preventDefault();
      try {
        this.loadSpinner = true;
        const result = await card?.tokenize();
        console.log(result);
        if (result?.status === 'OK') {
          console.log(result?.token);
          this.createPayment(result?.token);
        }
        if (result?.status === 'Invalid') {
          this.loadSpinner = false;
        }
      } catch (e) {
        this.loadSpinner = false;
        console.error(e);
      }
    };
    const cardButton = document?.getElementById('card-button');
    cardButton?.addEventListener('click', eventHandler.bind(this));
    this.loadSpinner = false;
  }

  // ACH Payment
  public async achPayment() {
    this.loadSpinner = true;
    await new Promise(resolve => setTimeout(resolve, 1000));
    const payments = Square?.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const ach = await payments?.ach();
    async function eventHandler(event) {
      event?.preventDefault();
      try {
        this.loadSpinner = true;
        const result = await ach?.tokenize({
          accountHolderName: 'John Doe',
        });
        if (result?.status === 'OK') {
          console.log(`ach Payment token is ${result?.token}`);
          this.createPayment(result?.token);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const achButton = document?.getElementById('ach-button');
    achButton?.addEventListener('click', eventHandler?.bind(this));
    this.loadSpinner = false;
  }


  // Digital Wallets - Apple Pay
  private async applePay() {
    const amount = this.amountForm?.get('amount')?.value?.toFixed(2);
    await new Promise(resolve => setTimeout(resolve, 2000));
    const payments = Square.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const paymentRequest = payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: amount,
        label: 'Total',
      },
    });

    const applePay = await payments.applePay(paymentRequest);
    async function eventHandler(event) {
      event.preventDefault();
      try {
        // this.loadSpinner = true;
        const result = await applePay.tokenize();
        if (result.status === 'OK') {
          console.log(`Payment token is ${result.token}`);
          this.createPayment(result?.token);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const applePayButtonTarget = document.getElementById('apple-pay-button');
    applePayButtonTarget.addEventListener('click', eventHandler?.bind(this));
  }


  // Digital Wallets - Google Pay
  private async googlePay() {
    const amount = this.amountForm?.get('amount')?.value?.toFixed(2);
    await new Promise(resolve => setTimeout(resolve, 2000));
    const payments = Square.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const paymentRequest = payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: amount,
        label: 'Total',
      },
    });
    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach('#google-pay-button');
    async function eventHandler(event) {
      event.preventDefault();
      try {
        // this.loadSpinner = true;
        const result = await googlePay.tokenize();
        if (result.status === 'OK') {
          console.log(`Payment token is ${result.token}`);
          this.createPayment(result?.token);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const googlePayButtonTarget = document.getElementById('google-pay-button');
    googlePayButtonTarget.addEventListener('click', eventHandler.bind(this));
  }

  // Create Card Payment
  private async createPayment(paymentToken: string) {
    // https://connect.squareup.com/v2/payments
    // const action = `https://connect.squareupsandbox.com/v2/payments`;
    // const action = 'https://connect.squareup.com/v2/payments';
    // const squareToken = `EAAAEEl7k6gcdOmxgBrG3875QzV47tTPQy4X_8ZGMAeiuA0B5hZazRJRiAlYjx4t`;
    // const params = {
    //   "idempotency_key": this.selectedPayAppointment?.id || "",
    //   "amount_money": {
    //     "amount": this.amountForm?.get('amount')?.value || 1,
    //     "currency": "USD"
    //   },
    //   "source_id": paymentToken || "",
    //   "autocomplete": true
    // }
    // this.httpService?.createPayment(action, params, squareToken)?.subscribe((data: any) => {
    //   console.log(data)
    //   this.loadSpinner = false;
    // },
    //   (error) => {
    //     this.loadSpinner = false;
    //   }
    // )
    if (this.amountForm?.invalid) {
      this.amountForm?.markAllAsTouched();
      this.loadSpinner = false;
      return;
    }
    const action = 'payment/createPayment';
    const amount = (this.amountForm?.get('amount')?.value)?.toFixed(2)?.split('.')?.join('');
    const params = {
      "serviceCharges": this.servicesChargesRequestPayload(),
      "modeOfPay": "NON_POS",
      "amount": parseInt(amount),
      "appointmentId": null,
      "sourceId": paymentToken
    }
    // const params = {
    //   "amount": parseInt(amount),
    //   "appointmentId": this.selectedPayAppointment?.id,
    //   "sourceId": paymentToken
    // }
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject?.status === 'APPROVED') {
          this.enableNonPosCancelPayment = true;
          this.cardPaymentObj = data?.body?.responseObject;
          const paymentId = data?.body?.responseObject?.paymentId;
          const cardPaymentSource = interval(15000);
          this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
        }
        if (data?.body?.responseObject?.status === 'PENDING') {
          this.enableNonPosCancelPayment = true;
          this.cardPaymentObj = data?.body?.responseObject;
          const paymentId = data?.body?.responseObject?.paymentId;
          const cardPaymentSource = interval(15000);
          this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
        }
        if (data?.body?.responseObject?.status === 'COMPLETED') {
          this.title = 'Payments';
          this.cardPaymentSubscription?.unsubscribe();
          this.closeModal();
          this.successPayDetails = data?.body?.responseObject;
          this.updatePatientResponsibility(this.successPayDetails, "NON_POS");
          this.paySuccessModal?.show();
        }
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // get the CREDIT CARD PAYMENT STATUS
  public getCardPaymentStatus(paymentId: string): void {
    const action = `payment/getPayment/${paymentId}`;
    // this.loadSpinner = true;
    this.successPayDetails = null;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject?.status === 'COMPLETED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.successPayDetails = data?.responseObject;
          this.updatePatientResponsibility(this.successPayDetails, "NON_POS");
          this.paySuccessModal?.show();
        }
        if (data?.responseObject?.status === 'CANCELED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment request is cancelled.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
        if (data?.responseObject?.status === 'FAILED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment is Failed.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
      }
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.cardPaymentSubscription?.unsubscribe();
        this.loadSpinner = false;
      });
  }

  // create POS payment - Terminal Payment
  public createPosPayment(): void {
    if (this.amountForm?.invalid) {
      this.amountForm?.markAllAsTouched();
      this.loadSpinner = false;
      return;
    }
    const action = 'payment/createTerminalPayment';
    const amount = (this.amountForm?.get('amount')?.value)?.toFixed(2)?.split('.')?.join('');
    const params = {
      "serviceCharges": this.servicesChargesRequestPayload(),
      "amount": parseInt(amount),
      "modeOfPay": "POS",
      "appointmentId": null,
      "sourceId": ""
    }
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject?.status === 'PENDING') {
          this.posPaymentObj = data?.body?.responseObject;
          this.showPOSCancel = true;
          const checkoutId = data?.body?.responseObject?.paymentId;
          const source = interval(15000);
          this.subscription = source.subscribe(val => { this.getPosPaymentStatus(checkoutId) });

          /** New logic added by Yeshwanth on 11 apr 2022 */
          // this.amountForm?.reset(); 
          // this.closeModal();
          // this.successPayDetails = data?.responseObject;
          // this.paySuccessModal?.show();
          /**Logic End */

        }
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // create POS payment - Terminal Payment
  public createCashPayment(): void {
    if (this.amountForm?.invalid) {
      this.amountForm?.markAllAsTouched();
      this.loadSpinner = false;
      return;
    }
    const action = 'payment/createCashPayment';
    let modeOfPay: string;
    if(this.selectedPaymentType === 'cashPayment') {
      modeOfPay = "CASH";
    } else if(this.selectedPaymentType === 'chequePayment') {
      modeOfPay = 'CHEQUE'
    } else if (this.selectedPaymentType === 'onlinePayment') {
      modeOfPay = 'ONLINE'
    }
    const amount = (this.amountForm?.get('amount')?.value)?.toFixed(2)?.split('.')?.join('');
    const params = {
      "serviceCharges": this.servicesChargesRequestPayload(),
      "modeOfPay": modeOfPay,
      "amount": parseInt(amount),
      "appointmentId": null,
      "sourceId": ""
    }
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject?.status === 'COMPLETED') {
          const checkoutId = data?.body?.responseObject?.paymentId;
          this.successPayDetails = data?.body?.responseObject;
          this.updatePatientResponsibility(this.successPayDetails, modeOfPay);
          // this.coPayAmount = null;
          // this.disableCoPayAmount = true;
          // this.inHouseLabsAmount = null;
          // this.disableInHouseLabsAmount = true;
          // this.consultationAmount = null;
          // this.disableConsultationAmount = true;
          // this.externalLabsAmount = null;
          // this.disableExternalLabsAmount = true;
          // this.otherAmount = null;
          // this.disableOtherAmount = true;
          this.servicesForm?.reset();
          this.amountForm?.reset();
          this.closeModal();
          this.paySuccessModal?.show();
        }
      } else {
        this.closeModal();
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // get the status of an terminal pos payment
  public getPosPaymentStatus(checkoutId: string): void {
    const action = `payment/getTerminalPayment/${checkoutId}`;
    // this.loadSpinner = true;
    this.successPayDetails = null;
    this.httpService?.makeGetRequest(action, '')?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject?.status === 'COMPLETED') {
          this.subscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.successPayDetails = data?.responseObject;
          this.updatePatientResponsibility(this.successPayDetails, "POS");
          this.paySuccessModal?.show();
        }
        if (data?.responseObject?.status === 'CANCELED') {
          this.subscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment request is cancelled.';
          this.isSuccessNotify = true;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
        if (data?.responseObject?.status === 'CANCEL_REQUESTED') {
          this.subscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment is requested for cancel.';
          this.isSuccessNotify = true;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
      }
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.subscription?.unsubscribe();
        this.loadSpinner = false;
      });
  }

  // cancel POS payment
  public cancelPosPayment(): void {
    const action = `payment/cancelTerminalPayment/${this.posPaymentObj?.paymentId}`;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
      this.subscription.unsubscribe();
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        this.subscription.unsubscribe();
      });
  }

  // Cancel Non POS Payments
  public cancelPayment(): void {
    const action = `payment/cancelPayment/${this.cardPaymentObj?.paymentId}`;
    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, '')?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
      this.cardPaymentSubscription?.unsubscribe();
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }



  public servicesChange(serviceName: string): void {
    const isChecked = this.servicesForm?.get(serviceName)?.value || null;
    switch (serviceName) {
      case 'selectCoPayAmount':
        if (isChecked) {
          this.servicesForm?.get('coPayAmount')?.enable();
        } else {
          this.servicesForm?.get('coPayAmount')?.reset();
          this.servicesForm?.get('coPayAmount')?.disable();
        }
        break;
      case 'selectInHouseLabsAmount':
        if (isChecked) {
          this.servicesForm?.get('inHouseLabsAmount')?.enable();
        } else {
          this.servicesForm?.get('inHouseLabsAmount')?.reset();
          this.servicesForm?.get('inHouseLabsAmount')?.disable();
        }
        break;
      case 'selectConsultationAmount':
        if (isChecked) {
          this.servicesForm?.get('consultationAmount')?.enable();
        } else {
          this.servicesForm?.get('consultationAmount')?.reset();
          this.servicesForm?.get('consultationAmount')?.disable();
        }
        break;
      case 'selectExternalLabsAmount':
        if (isChecked) {
          this.servicesForm?.get('externalLabsAmount')?.enable();
        } else {
          this.servicesForm?.get('externalLabsAmount')?.reset();
          this.servicesForm?.get('externalLabsAmount')?.disable();
        }
        break;
      case 'selectOtherAmount':
        if (isChecked) {
          this.servicesForm?.get('otherAmount')?.enable();
        } else {
          this.servicesForm?.get('otherAmount')?.reset();
          this.servicesForm?.get('otherAmount')?.disable();
        }
    }
  }

  private servicesChargesRequestPayload(): any {
    const servicesCharges = [];
    if (this.servicesForm?.get('coPayAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('coPayAmount')?.value,
        "serviceType": 'Co-Pay'
      };
      servicesCharges?.push(charge);
    }
    if (this.servicesForm?.get('inHouseLabsAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('inHouseLabsAmount')?.value,
        "serviceType": 'In-House Labs'
      };
      servicesCharges?.push(charge);
    }
    if (this.servicesForm?.get('consultationAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('consultationAmount')?.value,
        "serviceType": 'Consultation'
      };
      servicesCharges?.push(charge);
    }
    if (this.servicesForm?.get('externalLabsAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('externalLabsAmount')?.value,
        "serviceType": 'External Labs'
      };
      servicesCharges?.push(charge);
    }
    if (this.servicesForm?.get('otherAmount')?.value) {
      const charge = {
        "amount": this.servicesForm?.get('otherAmount')?.value,
        "serviceType": 'Other'
      };
      servicesCharges?.push(charge);
    }
    return servicesCharges || [];
  }

  // generate payment recipt
  public viewPaymentReciept(paymentId: any, payInvoiceModal: UiModalComponent): void {
    const action = `payment/generateCashReceipt?amount=${this.successPayDetails?.amount}&paymentId=${paymentId}&createAt=${this.successPayDetails?.createdAt}&serviceType=${this.successPayDetails?.sourceType}`;
    this.loadSpinner = true;
    this.httpService?.generatePDF(action, '')?.subscribe((data: any) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      // const fileURL = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // window.open(fileURL);
      payInvoiceModal?.show();
      const iframeEle = document.getElementById('patient-resp-payment-reciept-embed') as HTMLElement;
      iframeEle.setAttribute('src', fileURL);
      this.loadSpinner = false;
      this.closeModal();
      this.paySuccessModal?.hide();
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        this.paySuccessModal?.hide();
      })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.cardPaymentSubscription?.unsubscribe();
  }

}
