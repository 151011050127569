import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-ros',
  templateUrl: './review-ros.component.html',
  styleUrls: ['./review-ros.component.scss']
})
export class ReviewRosComponent {

  @Input() rosData: any;

}