<app-ui-modal #purposeOfVisitModal [modalCentered]="true" [loadSpinner]="loadSpinner" [hideHeader]="false">
    <div class="app-modal-header">
        <h5 class="modal-title">Update Purpose of Visit</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="purposeOfVisitModal?.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body send-notifications" [formGroup]="purposeofvisitForm">

        <div class="form-group mb-3">
            <label class="form-label">Purpose of Visit <span class="required">*</span></label>
            <ng-select placeholder="Choose Purpose of Visit" [items]="addPatientPurposeVisit"
                (change)="getDoctorsList()" id="update-purpose-of-visit" formControlName="purpose"></ng-select>
            <label class="required"
                *ngIf="purposeofvisitForm?.controls['purpose'].hasError('required') && purposeofvisitForm?.controls['purpose'].touched">Purpose
                of Visit should be required</label>
        </div>

        <!-- <div class="form-group">
            <label class="form-label">Doctor <span class="required">*</span></label>
            <ng-select placeholder="Choose Doctor" [items]="adPatientAllDoctors" formControlName="doctor"
                bindLabel="doctorName" bindValue="npi"></ng-select>
            <label class="required"
                *ngIf="purposeofvisitForm?.controls['doctor'].hasError('required') && purposeofvisitForm?.controls['doctor'].touched">Doctor
                should be required</label>
        </div> -->

    </div>
    <div class="app-modal-footer">
        <button class="btn btn-primary dash-actions" type="button" id="povUpdate_submit" (click)="updatePurposeOfVisit()">Submit</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>