import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yyymmdd'
})
export class YyymmddPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let split = value?.includes('T') ? value?.toString()?.split('T') : value;
    const date = split && Array.isArray(split) ? split[0] : split;
    const spliDate = date?.includes('-') ? date?.toString()?.split('-') : date;
    let dateFormate: any;
    if(spliDate) {
      dateFormate = `${spliDate[1]}/${spliDate[2]}/${spliDate[0]}`;
    }
    return dateFormate || value || null;
  }

}
