<div class="claim-status">
  <app-card #taskCard cardTitle="Tasks" cardClass="card-datatable" class="new-designed-card" [options]="false">
    <div class="table-responsive new-designed-table">
      <table datatable [dtOptions]="dtOptionsTasksStatus" id="report-table"
        class="table table-bordered table-striped mb-0">
        <tfoot style="display: table-header-group;">

        </tfoot>
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>Contact Number</th>

            <th>Service Provider</th>

            <th>Task Details</th>
            <th>Task Status</th>
            <th>Assignee</th>
            <th class="text-center no-sort">Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let task of allTasks">
            <tr class="appointment-tr-queue">
              <td>
                <ng-container>
                  <span>{{task?.patientLastName}}, {{task?.patientFirstName }}</span>
                </ng-container>
                <!-- [class.disabled]="patientDetails?.lockAppt" -->

                <div class="gender-patient-type" style="font-size: 12px;">
                  <span><b>{{task?.gender || '--'}}</b></span> &nbsp;&nbsp;
                  <span><b>{{task?.age || ''}}</b></span> &nbsp;&nbsp;

                </div>
              </td>


              <!--<td>{{getAppointmentTime(task?.taskDetails.createdDateTime)}}</td>-->
              <td>{{formatPrimaryPhone(task?.patientPrimaryPhone)}}</td>
              <td>{{task?.createdBy}}</td>
              <td>
                <ng-container>
                  <span>{{task?.category}} </span>
                </ng-container>

                <ng-container>
                  <span>{{task?.description}} </span>
                </ng-container>
                <!-- [class.disabled]="patientDetails?.lockAppt" -->

                <div class="gender-patient-type" style="font-size: 12px;">
                  <span><b>{{getAppointmentTime(task?.createdDate)}}</b></span>


                </div>
              </td>


              <td>{{task?.status}}</td>
              <td>{{task?.assignee}}</td>

              <td class="text-center" *ngIf="task?.taskType !== 'Pending Signoff'">

                <div class="dropdown-actions">
                  <button class="icon-queue-events more-actions-hover-icon"><i class="las la-ellipsis-h"></i></button>
                  <div class="dropdown-content-actions">

                    <a href="javascript:" (click)="openTaskModal(task)"><span
                        class="material-symbols-outlined">logout</span>Update</a>

                  </div>
                </div>


              </td>
              <div class="dropdown-actions" *ngIf="task?.taskType === 'Pending Signoff'">
                <button class="icon-queue-events more-actions-hover-icon"><i class="las la-ellipsis-h"></i></button>
                <div class="dropdown-content-actions">

                  <a href="javascript:"
                    (click)="viewAppointmentDetails(task?.appointmentId, viewCommonAppointmentDetails)"><span
                      class="material-symbols-outlined">visibility</span>View Appointment
                    Details</a>

                  <a href="javascript:" (click)="awaitingSignOffNavigate(task)"><span
                      class="material-symbols-outlined">logout</span>Pending SignOff</a>

                </div>
              </div>


            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </app-card>
</div>




<!-- Spinner -->
<div class="custom-loader justify-content-center" *ngIf="loadSpinner">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>

<!-- View Appointment Details -->
<app-ui-modal #viewCommonAppointmentDetails [hideHeader]="false" [modalCentered]="true"
  id="viewCommonAppointmentDetails">
  <div class="app-modal-header">
    <h5 class="modal-title">Appointment Details</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="viewCommonAppointmentDetails.hide();selectedViewAppointmentId=null"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body add-app-body">
    <ng-container *ngIf="selectedViewAppointmentId">
      <app-commom-past-visit-details-wizard [appointmentId]="selectedViewAppointmentId" [noAccountBalanace]="true"
        (openNotifyModal)="sendNotification($event)"></app-commom-past-visit-details-wizard>
      <!-- <app-view-common-appointment-details [appointmentId]="selectedViewAppointmentId">
      </app-view-common-appointment-details> -->
    </ng-container>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="ok1"
      (click)="viewCommonAppointmentDetails.hide();selectedViewAppointmentId=null">Ok</button>
  </div>
</app-ui-modal>


<!-- create task -->
<app-new-task [idType]="'userId'" [pageId]="'tasks'"></app-new-task>

<!-- Late Notify PopUp -->
<app-send-sms-notify [pageType]="'Chart'"></app-send-sms-notify>