<div class="summary-hpi-data">
    <ng-container *ngFor="let category of hpiSummaryData; let cati = index">

        <ng-container
            *ngIf="(category?.resourceType === 'Wellness Female Exam' || category?.resourceType === 'OB/GYN/Breast') && gender === 'Female'">
            <h5>{{category?.resourceType}}</h5>
            <ng-contaier *ngFor="let section of category?.sections">

                <ng-contaier>
                    <h6 *ngIf="category?.resourceType !== section?.name"><u>{{section?.name}}</u></h6>
                    <div class="row">

                        <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let answer of section?.answers">

                            <div class="form-group row mb-0 inline-form">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label class="labelStyle">{{answer?.label}}</label> <span class="label-colon"> :
                                    </span>
                                    <!-- </div>
                                <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                    <ul class="no-list-style inline-list">
                                        <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                                            <li *ngIf="value?.answer">{{value?.answer || '--'}}<span
                                                    *ngIf="value?.units">&nbsp;{{value?.units}}</span>&nbsp;&nbsp;</li>

                                            <li *ngIf="value?.label">{{value?.label || '--'}}<span
                                                    *ngIf="value?.medicalHistory || value?.notes">&nbsp;-
                                                    {{value?.medicalHistory || value?.notes}}</span>&nbsp;&nbsp;</li>
                                        </ng-container>

                                        <ng-container *ngIf="answer?.actualValue?.length === 0">
                                            <li>--</li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>

                            <!-- Additonal NOtes -->
                            <ng-container *ngIf="answer?.otherNotes">
                                <div class="form-group row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <label class="labelStyle">{{answer?.label}} Additional Notes</label> <span
                                            class="label-colon"> :
                                        </span>
                                        <!-- </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                        <label>{{answer?.otherNotes || '--'}}</label>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </ng-contaier>
            </ng-contaier>
            <ng-container *ngIf="!category?.sections || category?.sections?.length === 0">
                <span>No option has been selected under History of Present Illness.</span>
            </ng-container>
        </ng-container>

        <ng-container
            *ngIf="category?.resourceType !== 'Wellness Female Exam' && category?.resourceType !== 'OB/GYN/Breast'">
            <h5>{{category?.resourceType}}</h5>
            <ng-contaier *ngFor="let section of category?.sections">
                <ng-contaier *ngIf="section?.name !== 'Active Prescriptions'">
                    <h6 *ngIf="category?.resourceType !== section?.name"><u>{{section?.name}}</u></h6>
                    <div class="row">

                        <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let answer of section?.answers">

                            <div class="form-group row mb-0 inline-form"
                                *ngIf="answer?.label !== 'Other Medications' && answer?.label !== 'Active Prescriptions'">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label class="labelStyle">{{answer?.label}}</label> <span class="label-colon"> :
                                    </span>
                                    <ul class="no-list-style inline-list">
                                        <ng-container *ngFor="let value of answer?.actualValue; let listi = index">
                                            <li *ngIf="value?.answer">{{value?.answer || '--'}}<span
                                                    *ngIf="value?.units">&nbsp;{{value?.units}}</span><span
                                                    *ngIf="(listi+1) < answer?.actualValue.length"> ,</span></li>
                                            <!--  && answer?.label !== 'Family History' -->
                                            <li *ngIf="value?.label">{{value?.label || '--'}}<span
                                                    *ngIf="value?.medicalHistory || value?.notes || value?.location">&nbsp;-
                                                    {{value?.medicalHistory
                                                    || value?.notes || value?.location}} </span>
                                                <span *ngIf="value?.status || value?.severityStatus">&nbsp;-
                                                    {{value?.status ||
                                                    value?.severityStatus}}</span>
                                                <span *ngIf="value?.duration">&nbsp;-&nbsp;{{value?.duration}}
                                                    {{value?.units}}</span>
                                                <span *ngIf="(listi+1) < answer?.actualValue.length">,&nbsp;</span>
                                            </li>
                                        </ng-container>

                                        <ng-container *ngIf="answer?.actualValue?.length === 0">
                                            <li>--</li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>

                            <!-- Medications -->
                            <ng-contaier
                                *ngIf="answer?.label === 'Other Medications' || answer?.label === 'Active Medications'">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="table-responsive">
                                            <table class="table table-striped table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Order Date</th>
                                                        <th>Prescriber</th>
                                                        <th class="drug_name">Drug<span
                                                                [class.sig-text-width]="pastVisitMedications?.length > 0 || answer?.actualValue?.length > 0"></span>
                                                        </th>
                                                        <th>SIG<span
                                                                [class.sig-text-width]="pastVisitMedications?.length > 0"></span>
                                                        </th>
                                                        <th>Dosage & Units</th>
                                                        <th>Qty</th>
                                                        <th>Days Supply</th>
                                                        <th>Refills</th>
                                                        <th>Status</th>
                                                        <th>Change Reason</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let medication of pastVisitMedications; let i = index">
                                                        <tr>
                                                            <td>{{medication?.creation_date
                                                                || '--'}}
                                                            </td>
                                                            <td>{{(medication?.orderType === 'Internal' ?
                                                                medication?.prescriberName : medication?.orderType ) ||
                                                                '--'}}</td>
                                                            <td style="white-space: normal;">
                                                                {{medication?.drug_name
                                                                || '--'}}
                                                            </td>
                                                            <td style="white-space: normal;">
                                                                {{medication?.sig
                                                                || '--'}}
                                                            </td>
                                                            <td>{{medication?.change_of_dosage
                                                                ||
                                                                medication?.dosage
                                                                || '--'}}
                                                                {{medication?.activeIngredUnit}}
                                                                {{medication?.units_of_measure}}
                                                            </td>
                                                            <td>{{medication?.quantity || '--'}}</td>
                                                            <td>{{medication?.days_supply || '--'}}</td>
                                                            <td>{{medication?.refills || '--'}}</td>
                                                            <td>
                                                                <span *ngIf="medication?.lapsed">Lapsed</span>
                                                                <span
                                                                    *ngIf="!medication?.lapsed">{{medication?.changeOfMedications
                                                                    ? 'Discontinued' : 'Active'}}</span>
                                                            </td>
                                                            <td style="white-space: normal;">{{medication?.change_reason
                                                                || '--'}}
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <tr
                                                        *ngIf="answer?.actualValue?.length === 0 || pastVisitMedications?.length === 0">
                                                        <td colspan="10" class="text-center">Medications are unavailable
                                                            for this patient.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </ng-contaier>

                            <!-- Additonal NOtes -->
                            <ng-container *ngIf="answer?.otherNotes">
                                <div class="form-group row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <label class="labelStyle">{{answer?.label}} Additional Notes</label> <span
                                            class="label-colon"> :
                                        </span>
                                        <!-- </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                        <label>{{answer?.otherNotes || '--'}}</label>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </ng-contaier>
            </ng-contaier>
            <ng-container *ngIf="!category?.sections || category?.sections?.length === 0">
                <span>No option has been selected under History of Present Illness.</span>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="category?.otherNotes">
            <div class="form-group row mb-0 inline-form">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="labelStyle">Additional Notes</label> <span class="label-colon"> :
                    </span>
                    <!-- </div>
                <div class="col-lg-6 col-md-6 col-sm-6"> -->
                    <label>{{category?.otherNotes || '--'}}</label>
                </div>
            </div>
        </ng-container>

    </ng-container>
</div>