<!-- Display the Order Details -->
<app-ui-modal #labOrderDetails [hideHeader]="false" [modalCentered]="true" id="labOrderDetails">
    <div class="app-modal-header">
        <h5 class="modal-title">{{orderType}} Order Details ({{selectedOrderDetails?.patientName}})</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="labOrderDetails.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body add-app-body">
        <div class="report-pdf">
            <table>
                <thead>
                    <th>Test Name</th>
                    <th>&nbsp;&nbsp;</th>
                    <th>Diagnosis Codes</th>
                </thead>
                <tbody>
                    <tr *ngFor="let test of showOrderDetails?.requisitionDetails?.requestDetails">
                        <td>{{test?.testDescription || '--'}}</td>
                        <td>&nbsp;&nbsp;</td>
                        <td>
                            <ng-container *ngIf="!test?.diagnosisCodes">NA</ng-container>
                            <ng-container *ngIf="test?.diagnosisCodes">
                                <span *ngFor="let code of test?.diagnosisCodes">{{code?.code}} &nbsp;&nbsp;</span>
                            </ng-container>
                        </td>
                    </tr>
                    <tr
                        *ngIf="!showOrderDetails?.internalLabdetails && (!showOrderDetails || !showOrderDetails?.requisitionDetails?.requestDetails || showOrderDetails?.requisitionDetails?.requestDetails?.lengh == 0)">
                        <td colspan="3">Details for Care Gaps are unavailable.</td>
                    </tr>
                    <tr *ngIf="showOrderDetails?.internalLabdetails">
                        <td colspan="3">{{showOrderDetails?.internalLabdetails?.testName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" data-dismiss="modal" id="labRadOrder_ok1"
            (click)="labOrderDetails.hide()">Ok</button>
    </div>
</app-ui-modal>