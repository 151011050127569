import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiHeader } from './headers';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonRestService {

  private accessWithOptions: any;
  constructor(
    public http: HttpClient,
    // private apiHeader: ApiHeader
    //test
  ) {
    this.accessWithOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-code': sessionStorage.getItem('token'),
      }),
    }
  }

  // POST request
  public makeRequestWithAction(baseUrl: string, action: string, params: any): Observable<any> {
    return this.http?.post(baseUrl + action, params, { observe: 'response', responseType: 'json' })
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // GET request 
  public makeGetRequest(baseUrl: string, action: string, request: any): Observable<any> {
    return this.http?.get(baseUrl + action + request)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // Delete Request Action
  public makeDeleteRequest(baseUrl: string, action: string, params?: any): any {
    return this.http.delete(baseUrl + action, { observe: 'response', responseType: 'json' })
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // make PUT request
  public makePutRequest(baseUrl: string, action: string, params?: any) {
    return this.http.put(baseUrl + action, params)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // Generate PDF
  public generatePDF(baseUrl: string, action?: string, params?: any): any {
    const accessWithOptions = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/octet-stream'
      // }),
      responseType: 'blob' as 'json'
    }
    return this.http.post(baseUrl + action, params, accessWithOptions)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // Generate download Image
  public downloadImage(baseUrl: string, action: string, params?: any): any {
    const accessWithOptions = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/octet-stream'
      // }),
      responseType: 'blob' as 'json'
    }
    return this.http.get(baseUrl + action, accessWithOptions)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // Download Profile Image
  public downloadProfileImage(baseUrl: string, action: string, params?: any): any {
    const accessWithOptions = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/octet-stream'
      // }),
      responseType: 'text' as 'json'
    }
    return this.http.get(baseUrl + action, accessWithOptions)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // Generate download Image
  public downloadS3Image(baseUrl: string, action?: string, params?: any): any {
    const accessWithOptions = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/octet-stream'
      // }),
      responseType: 'blob' as 'json'
    }
    return this.http.post(baseUrl + action, params, accessWithOptions)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

  // post request with FormData
  public makePostWithFormData(baseUrl: string, action?: string, params?: any) {
    const HttpUploadOptions = {
      headers: new HttpHeaders(
        { "Content-Type": "multipart/form-data", "accept": "application/json" }
      )
    }
    return this.http.post(baseUrl + action, params)
      .pipe(
        map(res => res),
        catchError(error => throwError(error.error || error))
      );
  }

}
