<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>
<!-- 30 mins system logout -->
<app-ui-modal #sessionExpiredInActivity [hideHeader]="false" [modalCentered]="true" id="sessionExpiredInActivity">
  <div class="app-modal-header">
    <h5 class="modal-title text-danger text-left" style="font-size: 20px;"><i class="las la-minus-circle"></i>&nbsp; Your Session Has Expired</h5>    
  </div>
  <div class="app-modal-body add-app-body">
    <h5 class="text-left">To ensure the security of your Protected Health Information, we conduct regular activity checks. Unfortunately, your session has expired due to a period of inactivity. Please log in again to proceed with your activities.</h5>
  </div>
  <div class="app-modal-footer">
    <button type="button" id="relogin" class="icon-inqueue-actions" (click)="relogin()"> <i class="las la-sign-in-alt"></i> &nbsp;Please Re-Login</button>
  </div>
</app-ui-modal>