import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { SendSmsNotifyComponent } from '../send-sms-notify/send-sms-notify.component';
import { ApisService } from '../../services/apis.services';

declare var Square: any;

@Component({
  selector: 'app-facility-payment',
  templateUrl: './facility-payment.component.html',
  styleUrls: ['./facility-payment.component.scss']
})
export class FacilityPaymentComponent {
  @ViewChild('paymentInsurance') paymentInsurance: UiModalComponent;
  public notifyText: string;
  public isSuccessNotify: boolean;
  public paymentServices = AppConstantsListConfig?.paymentServices || [];
  // private APPLICATION_ID = environment?.pfSquare?.applicationId;
  // private LOCATION_ID = environment?.pfSquare?.locationId;
  public title: string = 'Payments';

  // @ViewChild('paymentModal') paymentInsurance: UiModalComponent;
  public loadSpinner: boolean;
  public selectedPayAppointment: any;
  public paymentType = AppConstantsListConfig?.paymentTypes || [];
  public arInsurancePaymentType = ['Cash', 'Check', 'Online'];
  public paymentMode = [
    { desc: 'Credit Card Payment', value: 'Credit Card Payment' },
    { desc: 'ACH Payment', value: 'ACH Payment (Pay with Bank Account)' },
    { desc: 'Digital wallets', value: 'Digital wallets' }
  ];
  public selectedPaymentType: string;
  public selectedPaymentMode: string;
  public amountForm: FormGroup;
  public subscription: Subscription;
  @ViewChild('paySuccessModal') paySuccessModal: UiModalComponent;
  successPayDetails: any;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;
  public showPOSCancel: boolean;
  public posPaymentObj: any;
  public enableNonPosCancelPayment: boolean;
  @Input() private paymentConfigDetails: any;
  private APPLICATION_ID: string;
  private LOCATION_ID: string;
  cardPaymentObj: any;
  public cardPaymentSubscription: Subscription;

  public totalMinimumAmountError: boolean = false;
  public patientResponsibilityResponse: any;
  public totalCredits: number = 0;
  public selectedPastVisitCreditsTotal: number = 0;
  @Output() patientRespCreditsUpdate = new EventEmitter<boolean>();
  public paymentServicesForm: FormArray;
  public newPaymentServicesForm: FormGroup;
  public partialPaymentsForm: FormGroup;
  public totalVisitChargesForm: FormGroup;
  public pastVisitPendingPayments: FormArray;
  public paymentTypeSelectionForm: FormGroup;
  @ViewChild(WizardComponent) public paymentWizard: WizardComponent;
  public isAccountBalancePayments: boolean;
  public isSelectedServiceIsPresent: string;
  totalChargesPayingCheck: string;
  public allArTypes = ['AR Patient', 'AR Insurance'];
  public allClaimProcessorTypes = ['Primary', 'Secondary', 'Tertiary'];
  public loginDetails: any;
  public facilityId: string = '';
  @Input() pageName: null;
  @Input() totalAmountPaid: any;
  // @Input() masterPatientId: any;
  @Input() selectedplanMode: any;
  @Output() helathpassPayment = new EventEmitter();
  public isHealthpassReadinly: boolean = false;
  public selectedUserInfoId: string;
  public selectedType: string;
  isArInsuranceSelected: boolean;
  showArInsuranceMessage: boolean;
  //  @ViewChild('planInvoiceModal') planInvoiceModal: UiModalComponent;
  @Input() accountLevelPay: boolean;
  @Input() accoutLevelUserInfoId: string;
  totalPaymentPaidCalc: any;
  public switchArPatientInsurancePayForm: FormGroup;
  allActiveEncounters: any;
  public arInsuranceFormGroup: FormGroup;
  @Output() receiptUrlShown = new EventEmitter<any>();
  @Output() checklistPayment = new EventEmitter();
  public allAppointments = [];
  public pastVisitAppointmentIds = [];
  @Input() recieptID: string;
  public totalCreditsPaidCalc: number = 0;
  public terminalTypes: any[] = [];
  @ViewChild(SendSmsNotifyComponent) private sendNotifyComp: SendSmsNotifyComponent;
  @Output() patientRespCreditsUpdateForFuterApp = new EventEmitter<any>();
  public selectedAppointmentDetails: any;
  @Input() userInfoData: any;
  public paymentCategoryDescription: FormGroup;
  public paymentCategories = ['ACH-Interest', 'ACH-CAP Payments', 'ACH-Unassociated', 'Other'];
  public paymentDescriptions = ['Pre-visit Charges', 'Patient Responsibility', 'Facility Adjustment', 'Write off', 'Refund', 'Reimbursements', 'Cap Payments', 'Adhoc Payments - Interest', 'Plan Scheduled Payment'];
  serviceProvider: any[];
  public checkNumberDateForm: FormGroup;
  dupPayCatDesc: any;
  public maxStartDate: Date = this.httpService?.convertDate(new Date());

  constructor(public httpService: HttpService, private formBuilder: FormBuilder, private router: Router, private apiService: ApisService) {
    this.amountForm = formBuilder?.group({
      'amount': [null, Validators.required]
    });

    this.newPaymentServicesForm = this.formBuilder?.group({
      'paymentServicesForm': this.formBuilder?.array([this.paymentServicesGroup()])
    });

    this.partialPaymentsForm = this.formBuilder?.group({
      'isPartialPayment': [null],
      'partialAmount': [null],
      'remainingChargesDues': [null]
    });

    this.totalVisitChargesForm = this.formBuilder?.group({
      'totalVisitCharges': [null, Validators?.required]
    });

    this.paymentTypeSelectionForm = this.formBuilder?.group({
      'paymentType': [null, Validators?.required],
      'terminalType': [null],
      'onlineDate': [null]
    });

    this.switchArPatientInsurancePayForm = this.formBuilder?.group({
      'switchCheck': [null],
      'claimProcessorType': [null]
    });

    this.arInsuranceFormGroup = this.formBuilder?.group({
      'arInsuranceTypes': this.formBuilder?.array([])
    });

    this.paymentCategoryDescription = this.formBuilder?.group({
      'paymentCategory': [null, Validators?.required],
      'description': [null],
      'serviceProviderName': [null, Validators?.required],
      'amount': [null, Validators?.required],
      'serviceProviderNpi': [null],
      'payerName': [null]
    });

    this.checkNumberDateForm = this.formBuilder?.group({
      'checkNumber': [null, Validators?.required],
      'checkDate': [null, [Validators?.required, this.httpService?.yearValidator?.bind(this)]]
    });
  }

  ngOnInit(): void {
    // this.cardPayment();
    this.loginDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.facilityId = this.loginDetails.facilityId;
    this.selectedUserInfoId = null;
    this.selectedType = null;
  }

  // AR Insurance Form Group
  public arInsuranceGroup(): FormGroup {
    return this.formBuilder?.group({
      'appointmentId': [null, Validators?.required],
      'claimProcessorType': [null, Validators?.required],
      'amount': [null, Validators?.required]
    });
  }

  public addArInsurance(): void {
    const arInsuArray = this.arInsuranceFormGroup?.get('arInsuranceTypes') as FormArray;
    arInsuArray?.push(this.arInsuranceGroup());
  }

  public deleteArInsurance(index: number): void {
    const arInsuArray = this.arInsuranceFormGroup?.get('arInsuranceTypes') as FormArray;
    arInsuArray?.removeAt(index);
  }

  public openPaymentsModal(): void {
    this.paymentInsurance?.show();
  }

  // open modal from flow board
  public openModal(): void {
    this.paymentTypeSelectionForm?.get('paymentType')?.setValue('POS');
    this.changePayType('POS');
    this.paymentInsurance?.show();
    this.getDoctorsList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.APPLICATION_ID = this.paymentConfigDetails?.clientId;
      this.LOCATION_ID = this.paymentConfigDetails?.locationId;
      this.terminalTypes = this.paymentConfigDetails?.terminals || [];
      if (this.paymentConfigDetails?.terminals && this.paymentConfigDetails?.terminals?.length > 0) {
        this.paymentTypeSelectionForm?.get('terminalType')?.setValue(this.paymentConfigDetails?.terminals[0] || null);
      }
      // If Account Level Adhoc Payment
      if (this.accountLevelPay && this.accoutLevelUserInfoId) {
        this.paymentServices = ['Healthpass', 'Other'];
      }
    }
  }

  // payment services group
  public paymentServicesGroup(): FormGroup {
    return this.formBuilder?.group({
      'paymentCategory': [null, Validators?.required],
      'amount': [null, Validators?.required],
      'description': [null, Validators?.required],
      'facilityId': [null],
      'modeOfPay': [null],
      'serviceProvider': [null, Validators?.required]
    });
  }

  // add Payment services
  public addPaymentServices(): void {
    this.paymentServicesForm = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
    this.paymentServicesForm?.push(this.paymentServicesGroup());
  }

  // delete payment services
  public deletePaymentServices(index?: number): void {
    this.paymentServicesForm = this.newPaymentServicesForm?.get('paymentServicesForm') as FormArray;
    this.paymentServicesForm?.removeAt(index);
  }

  public changePayType(event: any): void {
    if (this.terminalTypes?.length > 1 && event === "POS") {
      if (this.paymentConfigDetails?.terminals && this.paymentConfigDetails?.terminals?.length > 0) {
        this.paymentTypeSelectionForm?.get('terminalType')?.setValue(this.paymentConfigDetails?.terminals[0] || null);
      }
      this.paymentTypeSelectionForm?.get('terminalType')?.setValidators([Validators?.required]);
    } else {
      this.paymentTypeSelectionForm?.get('terminalType')?.setValidators(null);
    }
    // Online payment date validation
    this.paymentTypeSelectionForm?.get('onlineDate')?.setValue(null);
    if(event === 'Online' || event === 'Cash' || event === 'Direct Deposit') {
      this.paymentTypeSelectionForm?.get('onlineDate')?.setValidators([Validators?.required]);
    } else {
      this.paymentTypeSelectionForm?.get('onlineDate')?.setValidators(null);
    }
    this.paymentTypeSelectionForm?.get('onlineDate')?.updateValueAndValidity();
    this.paymentTypeSelectionForm?.get('terminalType')?.updateValueAndValidity();
    // this.amountForm?.get('amount')?.setValue(null);
    if (event === 'Non POS') {
      this.cardPayment();
    }
  }

  public changePaymentMode(): void {
    if (this.selectedPaymentType === 'noTerminal' && this.selectedPaymentMode === 'Credit Card Payment') {
      this.cardPayment();
    }
    if (this.selectedPaymentMode === 'Credit Card Payment') {
      // this.enableCardPay();
    } else if (this.selectedPaymentMode === 'ACH Payment (Pay with Bank Account)') {
      this.achPayOpen();
    }
    if (this.selectedPaymentMode === 'Digital wallets') {
      this.applePayOpen();
      this.googlePayOpen();
    }
  }


  public enableCardPay(): void {
    this.selectedPaymentType = null;
    this.showPOSCancel = false;
    this.enableNonPosCancelPayment = false;
    // this.cardPayment();
  }

  // trigget payment with ach payment
  public achPayOpen(): void {
    this.achPayment();
  }

  // trigger apple payment
  public applePayOpen(): void {
    this.applePay();
  }

  // trigger google payment
  public googlePayOpen(): void {
    this.googlePay();
  }

  // close modal
  public closeModal(): void {
    this.title = 'Payments';
    this.loadSpinner = false;
    this.dupPayCatDesc = JSON?.parse(JSON?.stringify(this.paymentCategoryDescription?.value));
    this.paymentInsurance?.hide();
    this.paymentCategoryDescription?.reset();
    this.checkNumberDateForm?.reset();
  }

  // card payment
  private async cardPayment() {
    this.loadSpinner = true;
    await new Promise(resolve => setTimeout(resolve, 2000));
    const containerEle = document?.getElementById('card-container');
    if (containerEle?.innerHTML) {
      containerEle.innerHTML = '';
    }
    const payments = Square?.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const card = await payments?.card();
    await card?.attach('#card-container');

    async function eventHandler(event) {
      event.preventDefault();
      try {
        this.loadSpinner = true;
        const result = await card?.tokenize();
        if (result?.status === 'OK') {
          this.createPayment(result?.token);
        }
        if (result?.status === 'Invalid') {
          this.loadSpinner = false;
        }
      } catch (e) {
        this.loadSpinner = false;
      }
    };
    const cardButton = document?.getElementById('card-button');
    cardButton?.addEventListener('click', eventHandler.bind(this));
    this.loadSpinner = false;
  }

  // ACH Payment
  public async achPayment() {
    this.loadSpinner = true;
    await new Promise(resolve => setTimeout(resolve, 1000));
    const payments = Square?.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const ach = await payments?.ach();
    async function eventHandler(event) {
      event?.preventDefault();
      try {
        this.loadSpinner = true;
        const result = await ach?.tokenize({
          accountHolderName: 'John Doe',
        });
        if (result?.status === 'OK') {
          this.createPayment(result?.token);
        }
      } catch (e) {
        this.loadSpinner = false;
      }
    };
    const achButton = document?.getElementById('ach-button');
    achButton?.addEventListener('click', eventHandler?.bind(this));
    this.loadSpinner = false;
  }


  // Digital Wallets - Apple Pay
  private async applePay() {
    const totalPayment = (this.getTotalVisitCharges() || 0);
    const amount = totalPayment?.toFixed(2);
    await new Promise(resolve => setTimeout(resolve, 2000));
    const payments = Square.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const paymentRequest = payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: amount,
        label: 'Total',
      },
    });

    const applePay = await payments.applePay(paymentRequest);
    async function eventHandler(event) {
      event.preventDefault();
      try {
        // this.loadSpinner = true;
        const result = await applePay.tokenize();
        if (result.status === 'OK') {
          this.createPayment(result?.token);
        }
      } catch (e) {
        this.loadSpinner = false;
      }
    };
    const applePayButtonTarget = document.getElementById('apple-pay-button');
    applePayButtonTarget.addEventListener('click', eventHandler?.bind(this));
  }


  // Digital Wallets - Google Pay
  private async googlePay() {
    this.loadSpinner = true;
    await new Promise(resolve => setTimeout(resolve, 2000));
    const containerEle = document?.getElementById('google-pay-button');
    if (containerEle?.innerHTML) {
      containerEle.innerHTML = '';
    }
    const totalPayment = (this.getTotalVisitCharges() || 0);
    const amount = totalPayment?.toFixed(2);
    await new Promise(resolve => setTimeout(resolve, 2000));
    this.loadSpinner = false;
    const payments = Square.payments(this.APPLICATION_ID, this.LOCATION_ID);
    const paymentRequest = payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: amount,
        label: 'Total',
      },
    });
    this.loadSpinner = false;
    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach('#google-pay-button');
    async function eventHandler(event) {
      event.preventDefault();
      try {
        // this.loadSpinner = true;
        const result = await googlePay.tokenize();
        if (result.status === 'OK') {
          this.loadSpinner = false;
          this.createPayment(result?.token);
        }
      } catch (e) {
        this.loadSpinner = false;
      }
    };
    const googlePayButtonTarget = document.getElementById('google-pay-button');
    googlePayButtonTarget.addEventListener('click', eventHandler.bind(this));
  }

  // Create Card Payment
  private async createPayment(paymentToken: string) {
    if (this.checkPaymentServices()) {
      this.loadSpinner = false;
      return;
    }
    const totalPayment = (this.paymentCategoryDescription?.get('amount')?.value || 0);
    const amount = totalPayment * 100;
    const params = this.createParamsForPayments(amount, "NON_POS", null, null, paymentToken);
    this.loadSpinner = true;
    this.apiService?.createNonPosPaymentExtrGateway(params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject?.status === 'APPROVED') {
          this.enableNonPosCancelPayment = true;
          this.cardPaymentObj = data?.body?.responseObject;
          const paymentId = data?.body?.responseObject?.paymentId;
          const cardPaymentSource = interval(15000);
          this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
        }
        if (data?.body?.responseObject?.status === 'PENDING') {
          this.enableNonPosCancelPayment = true;
          this.cardPaymentObj = data?.body?.responseObject;
          const paymentId = data?.body?.responseObject?.paymentId;
          const cardPaymentSource = interval(15000);
          this.cardPaymentSubscription = cardPaymentSource.subscribe(val => { this.getCardPaymentStatus(paymentId) });
        }
        if (data?.body?.responseObject?.status === 'COMPLETED') {
          this.successPayDetails = data?.body?.responseObject;
          this.title = 'Payments';
          this.cardPaymentSubscription?.unsubscribe();
          this.closeModal();
          this.paySuccessModal?.show();
        }
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // get the CREDIT CARD PAYMENT STATUS
  public getCardPaymentStatus(paymentId: string): void {
    this.successPayDetails = null;
    this.apiService?.getStatusNonPosPaymentExtrGateway(paymentId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject?.status === 'COMPLETED') {
          this.successPayDetails = data?.responseObject;
          this.cardPaymentSubscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.paySuccessModal?.show();
        }
        if (data?.responseObject?.status === 'CANCELED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment request is cancelled.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
        if (data?.responseObject?.status === 'FAILED') {
          this.cardPaymentSubscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment is Failed.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
      }
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.cardPaymentSubscription?.unsubscribe();
        this.loadSpinner = false;
      });
  }

  // create POS payment - Terminal Payment
  public createPosPayment(): void {
    if (this.paymentTypeSelectionForm?.get('terminalType')?.invalid) {
      this.paymentTypeSelectionForm?.markAllAsTouched();
      this.loadSpinner = false;
      return;
    }
    if (this.checkPaymentServices()) {
      return;
    }

    const totalPayment = (this.paymentCategoryDescription?.get('amount')?.value || 0)
    const amount = totalPayment * 100;

    const params = this.createParamsForPayments(amount, "POS", null, null, null, this.paymentTypeSelectionForm?.get('terminalType')?.value);

    this.loadSpinner = true;
    this.apiService?.createTerminalPaymentExtrGateway(params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject?.status === 'PENDING') {
          this.posPaymentObj = data?.body?.responseObject;
          this.showPOSCancel = true;
          const checkoutId = data?.body?.responseObject?.paymentId;
          const source = interval(15000);
          this.subscription = source.subscribe(val => { this.getPosPaymentStatus(checkoutId) });
        }
      } else {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      }
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // create POS payment - Terminal Payment
  public createCashPayment(): void {
    // if (this.amountForm?.invalid) {
    //   this.amountForm?.markAllAsTouched();
    //   this.loadSpinner = false;
    //   return;
    // }
    if (this.checkPaymentServices()) {
      return;
    }
    let modeOfPay: string;
    if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash') {
      modeOfPay = "CASH";
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Check') {
      modeOfPay = 'CHECK'
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Online') {
      modeOfPay = 'ONLINE'
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit') {
      modeOfPay = 'DIRECT_DEPOSIT'
    }

    const totalPayment = (this.paymentCategoryDescription?.get('amount')?.value || 0);
    const amount = (totalPayment) * 100;

    const params = this.createParamsForPayments(amount, modeOfPay);

    this.loadSpinner = true;
    this.apiService?.createCashPaymentExtrGateway(params)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        if (data?.body?.responseObject?.status === 'COMPLETED') {
          const checkoutId = data?.body?.responseObject?.paymentId;
          this.successPayDetails = data?.body?.responseObject;
          this.amountForm?.reset();
          this.paySuccessModal?.show();
          this.closeModal();
        }
      } else {
        this.closeModal();
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  // get the status of an terminal pos payment
  public getPosPaymentStatus(checkoutId: string): void {
    this.successPayDetails = null;
    this.apiService?.getStatusTerminalPaymentExtrGateway(checkoutId)?.subscribe((data: any) => {
      if (data?.status === 'SUCCESS') {
        if (data?.responseObject?.status === 'COMPLETED') {
          this.successPayDetails = data?.responseObject;
          this.subscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.paySuccessModal?.show();
        }
        if (data?.responseObject?.status === 'CANCELED') {
          this.subscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment request is cancelled.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
        if (data?.responseObject?.status === 'CANCEL_REQUESTED') {
          this.subscription?.unsubscribe();
          this.title = 'Payments';
          this.closeModal();
          this.notifyText = 'Your payment is requested for cancel.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
          this.loadSpinner = false;
        }
      }
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.subscription?.unsubscribe();
        this.loadSpinner = false;
      });
  }

  // cancel POS payment
  public cancelPosPayment(): void {
    this.loadSpinner = true;
    this.apiService?.cancelTerminalPaymentExtrGateway(this.posPaymentObj?.paymentId)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
      this.subscription.unsubscribe();
    },
      (error) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        this.subscription.unsubscribe();
      });
  }

  // Cancel Non POS Payments
  public cancelPayment(): void {
    this.loadSpinner = true;
    this.apiService?.cancelNonPosPaymentExtrGateway(this.cardPaymentObj?.paymentId)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
      this.cardPaymentSubscription?.unsubscribe();
    },
      (error) => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      })
  }

  // generate payment recipt
  public viewPaymentReciept(paymentId: any, payInvoiceModal: UiModalComponent): void {
    this.loadSpinner = true;
    this.apiService?.recieptGeneration(null, paymentId, null, this.dupPayCatDesc?.amount || null, this.dupPayCatDesc?.description || null)?.subscribe((data: any) => {
      const payFile = new Blob([data], { type: 'application/pdf' })
      const payFileUrl = URL.createObjectURL(payFile);
      // const payFileUrl = window.URL.createObjectURL(new Blob([data], {type: 'pdf'}));
      // if you want to open PDF in new tab
      // window.open(payFileUrl);
      payInvoiceModal?.show();
      const iframeEle = document.getElementById(this.recieptID || 'common-new-payment-reciept-embed') as HTMLElement;
      iframeEle.setAttribute('src', payFileUrl);
      this.loadSpinner = false;
      this.closeModal();
      if (this.pageName !== 'Healthpass') { this.paySuccessModal?.hide(); }
      const obj = {
        payFileUrl,
        payInvoiceModal: payInvoiceModal as UiModalComponent
      }
      this.receiptUrlShown?.emit(obj);
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        if (this.pageName !== 'Healthpass') { this.paySuccessModal?.hide(); }

      });
  };


  // to get allVisitCharges
  public getTotalVisitCharges(): any {
    return this.totalChargesCount();
  }

  // Grand Total Amount to be paid
  public grandTotalCharges(): any {
    let totalCharges = 0;
    if (this.partialPaymentsForm?.get('isPartialPayment')?.value && this.partialPaymentsForm?.get('partialAmount')?.value) {
      return this.partialPaymentsForm?.get('partialAmount')?.value;
    }
    return totalCharges || 0;
  }

  public totalChargesCount(): any {
    if (this.newPaymentServicesForm?.get('paymentServicesForm')?.value?.length === 0) {
      return 0;
    }
    const sum = this.newPaymentServicesForm?.get('paymentServicesForm')?.value?.map(item => parseFloat(item?.amount || 0))?.reduce((prev, curr) => prev + curr);
    const fixedTo2Decminals = sum ? parseFloat(sum?.toFixed(2)) : sum;
    this.totalVisitChargesForm?.get('totalVisitCharges')?.setValue(fixedTo2Decminals || null);
    return fixedTo2Decminals ? fixedTo2Decminals : null;
  }

  // check validation payment services
  public checkPaymentServices(): boolean {
    this.paymentCategoryDescription?.markAllAsTouched();
    if (this.paymentCategoryDescription?.invalid) {
      return true;
    }
    if (this.paymentTypeSelectionForm?.invalid && (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit')) {
      this.paymentTypeSelectionForm?.markAllAsTouched();
      return true;
    }
    if (this.checkNumberDateForm?.invalid && this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Check') {
      this.checkNumberDateForm?.markAllAsTouched();
      return true;
    }
    if (!this.paymentCategoryDescription?.get('amount')?.value || this.paymentCategoryDescription?.get('amount')?.value < 1) {
      this.totalChargesPayingCheck = `Total charges should be minimum of $1 to make payment.`;
      setTimeout(() => {
        this.totalChargesPayingCheck = null;
      }, 2000);
      return true;
    }
    return false;
  }

  // Check pay services
  public clickPayTypes(): void {
    this.paymentTypeSelectionForm?.markAllAsTouched();
  }

  public clearSession() {
    this.helathpassPayment?.emit();
  }

  public loadPaymentInfo() {
    this.checklistPayment?.emit();
  }

  // create AR-Insurnace Payment
  public createArInsurancePayment(): void {
    if (this.checkPaymentServices()) {
      return;
    }
    let modeOfPay: string;
    if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash') {
      modeOfPay = "CASH";
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Check') {
      modeOfPay = 'CHECK'
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Online') {
      modeOfPay = 'ONLINE'
    } else if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit') {
      modeOfPay = 'DIRECT_DEPOSIT'
    }

    const allArInsurance = JSON.parse(JSON?.stringify(this.arInsuranceFormGroup?.get('arInsuranceTypes')?.value || []));
    const allParams = [];
    allArInsurance?.forEach((item: any) => {
      const amount = (item?.amount) * 100;
      const charges = {
        "amount": item?.amount,
        "serviceType": 'AR Insurance'
      }
      const obj = this.createParamsForPayments(amount, modeOfPay, item, charges);
      allParams?.push(obj);
    });

    this.loadSpinner = true;
    this.apiService?.createPayerPaymentExtrGateway(allParams)?.subscribe((data: any) => {
      if (data?.body?.status === 'SUCCESS') {
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
        this.successPayDetails = data?.body?.responseObject;
        this.amountForm?.reset();
        this.closeModal();
      } else {
        this.closeModal();
        this.notifyText = data?.body?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
    },
      (error) => {
        this.notifyText = error?.message;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.cardPaymentSubscription?.unsubscribe();
  }

  public preventdefault() {
    return false;
  }
  public futureBalnceUpdate() {
    this.patientRespCreditsUpdateForFuterApp.emit(this.selectedAppointmentDetails)
  }

  // Account Balance Notify
  public async sendAccountBalanceNotify() {
    let appointmentDetails: any;
    if (this.selectedPayAppointment?.id) {
      try {
        this.loadSpinner = true;
        //console.log(this.selectedPayAppointment?.id);
        //console.log(this.selectedPayAppointment?.appointmentId);
        let action: any = `appointment/registration/getAppointmentById?id=${this.selectedPayAppointment?.id}`;
        appointmentDetails = await this.httpService.makeGetRequest(action, '').toPromise();
        this.loadSpinner = false;
      } catch {
        this.loadSpinner = false;
      }
    }
    this.sendNotifyComp?.showNotifyModalForEncounter(appointmentDetails?.responseObject || this.userInfoData, !this.selectedPayAppointment?.id ? 'user' : null, 'claim');
  }

  // Params for create Payments API's
  private createParamsForPayments(amount?: any, modeOfPay?: string, payItem?: any, serviceCharges?: any, paymentToken?: any, terminal?: any): any {
    const paymentParams: any = {
      "amount": parseInt(amount),
      "facilityId": this.facilityId,
      "modeOfPay": modeOfPay,
      "sourceId": paymentToken || null,
      "terminal": terminal || null,
      "type": payItem?.claimProcessorType || this.selectedType,
      "description": this.paymentCategoryDescription?.value?.description,
      "paymentCategory": this.paymentCategoryDescription?.value?.paymentCategory,
      "serviceProviderName": this.paymentCategoryDescription?.value?.serviceProviderName?.doctorName || this.paymentCategoryDescription?.value?.serviceProviderName,
      "serviceProviderNpi": this.paymentCategoryDescription?.value?.serviceProviderName?.npi,
      "payerName":  this.paymentCategoryDescription?.value?.payerName || null
    }
    if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Check') {
      const dateString = this.checkNumberDateForm?.get('checkDate')?.value ? new Date(this.checkNumberDateForm?.get('checkDate')?.value) : null;
      paymentParams.claimInfo = {
        checkNumber: this.checkNumberDateForm?.get('checkNumber')?.value,
        checkDate: dateString ? dateString?.toISOString() : dateString
      }
    }
    // Online Payment Date.
    if (this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Online' || this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Cash' || this.paymentTypeSelectionForm?.get('paymentType')?.value === 'Direct Deposit') {
      paymentParams.onlineDate = this.paymentTypeSelectionForm?.get('onlineDate')?.value || null;
    }
    return paymentParams;
  }

  // get doctors list based on purpose of visit
  public getDoctorsList(): void {
    this.serviceProvider = [];
    // const action = `npi/getValidNPIForFacility?facilityId=${this.loginDetails?.facilityId}`;
    // this.httpService?.makeGetRequest(action, '').subscribe((data: any) => {
    //   this.serviceProvider = (data && data.npiDoctorList) || [];
    // }, error => {
    // });
    const action = `waitTime/`;
    this.httpService.makeGetRequest(action, this.facilityId || this.loginDetails?.facilityId).subscribe((data: any) => {
      sessionStorage?.setItem('waitTimeDetails', JSON.stringify(data));
      if (data?.eligibilityDetails?.doctor && data?.eligibilityDetails?.doctor?.length > 0) {
        data?.eligibilityDetails?.doctor?.forEach((docItem: any) => {
          if (docItem?.number && docItem?.basic) {
            const obj = {
              doctorName: `${docItem?.basic?.authorized_official_first_name || ''} ${docItem?.basic?.authorized_official_last_name || ''}`,
              npi: docItem?.number
            }
            this.serviceProvider?.push(obj);
          }
        });
      }
      this.serviceProvider = JSON?.parse(JSON?.stringify(this.serviceProvider)) || [];
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal.modalShow();
    });
  }

  // Change payment category to update description
  public changePaymentCategory(event: any): void {
    if (event === 'ACH-Interest') {
      this.paymentCategoryDescription?.get('description')?.setValue('Interest');
    }
    if (event === 'ACH-CAP Payments') {
      this.paymentCategoryDescription?.get('description')?.setValue('CAP Payments');
    }
    if (event === 'ACH-Unassociated') {
      this.paymentCategoryDescription?.get('description')?.setValue('UnAssociated');
    }
    if (event === 'Other') {
      this.paymentCategoryDescription?.get('description')?.setValue('Other');
    }
  }

}
