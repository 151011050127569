import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-assessment',
  templateUrl: './review-assessment.component.html',
  styleUrls: ['./review-assessment.component.scss']
})
export class ReviewAssessmentComponent {

  @Input() carePlanData: any;
  @Input() codeMissingAssessments: any;
  
  public cmpareCodesDesc(icdCode: string): void {
    if (icdCode && this.carePlanData && this.carePlanData?.length > 0 && this.codeMissingAssessments && this.codeMissingAssessments?.length > 0) {
      const codesDesc = this.codeMissingAssessments?.map((item: any) => {
        return {
          code: item?.code,
          description: item?.codeDescription || item?.description
        }
      });

      return this.codeMissingAssessments?.find(item => item?.code === icdCode)?.codeDescription;
      // this.carePlanData?.forEach((formGroupItem: any) => {
      //   if (formGroupItem?.resourceType === 'General') {

      //     formGroupItem?.sections?.forEach((secFormGroup: any) => {
      //       if (secFormGroup && secFormGroup?.name === 'Assessment') {
      //         secFormGroup?.answers?.forEach((ansForm: any) => {
      //           if (secFormGroup?.value?.name === 'Assessment' && ansForm?.value?.label === 'Diagnosis Codes (ICD10)') {
      //             ansForm.actualValue = codesDesc || [];
      //           }
      //         });
      //       }
      //     });
      //   }

      // });
    }
  }

}
