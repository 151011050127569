<app-ui-modal #updateVisitDoctorModal [modalCentered]="true" [loadSpinner]="loadSpinner" [hideHeader]="false" id="updateServiceProvider">
    <div class="app-modal-header">
        <h5 class="modal-title">Update Service Provider</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="updateVisitDoctorModal?.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body send-notifications" [formGroup]="updateVisitDoctorForm">

        <div class="form-group mb-3">
            <label class="form-label">Service Provider <span class="required">*</span></label>
            <ng-select placeholder="Choose Service Provider" id="service-providerupdate-edit" (change)="updateDoctor($event)" bindLabel="fullName" bindValue="npi" [items]="allVisitingDoctors"
                formControlName="doctorNpi">
                <!-- <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div>{{item?.firstName}} {{item?.lastName}}</div>
                </ng-template> -->
            </ng-select>
            <label class="required"
                *ngIf="updateVisitDoctorForm?.controls['doctorNpi'].hasError('required') && updateVisitDoctorForm?.controls['doctorNpi'].touched">Service Provider is required</label>
        </div>
    </div>
    <div class="app-modal-footer">
        <button class="btn btn-primary dash-actions" type="button" id="updateVisitDoc_submit" (click)="updateVisitDoctor()">Submit</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>