<!-- <ng-container *ngIf="!patientDetailsInfo?.idDocument?.identityImageId && hideInsurance && !userdataupdate">
    <h5 class="head-color">ID Document is not available. Please upload here...</h5>
</ng-container> -->
<ng-container>
    <div class="upload-documents">
        <div class="row">
            <!-- <div class="col-lg-12 col-md-12 col-sm-12"> -->

            <!-- </div> -->
            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!selectedAdditionalKey">
                <p class="text-info">* Supports only PDF, PNG and JPEG files and Files larger than 25MB cannot be
                    uploaded</p>
                <!-- <h5 class="head-color">Driving Licence Card</h5> -->
                <div class="item-wrapper one">
                    <div class="item">
                        <form data-validation="true" action="#" method="post" enctype="multipart/form-data">
                            <div class="item-inner">
                                <div class="item-content">
                                    <div class="image-upload">
                                        <label (change)="drivingLicenceFileChanged($event)"
                                            style="cursor: pointer;width: 100%;" for="driving-license-upload">
                                            <!-- <i class="las la-cloud-upload-alt"></i> -->
                                            <img class="img-upload" alt="Document Upload"
                                                src="../../../../../../assets/images/ic_image_upload.svg" />
                                            <div class="h-100">
                                                <div class="dplay-tbl">
                                                    <div class="dplay-tbl-cell">
                                                        <h5 class="upload-title"><b>Choose Identity Document to
                                                                Upload</b></h5>
                                                        <!-- <h6 class="mt-10 mb-70">Or Drop Your Image Here</h6> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <!--upload-content image/png, image/jpeg, -->
                                            <input data-required="image" type="file" name="driving-license-upload"
                                                id="driving-license-upload" accept="application/pdf, image/*"
                                                class="image-input" data-traget-resolution="image_resolution" value="">
                                        </label>
                                    </div>
                                </div>
                                <!--item-content-->
                            </div>
                            <!--item-inner-->
                        </form>
                    </div>
                    <!--item-->
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div *ngIf="drivingLicenseFile && selectedAdditionalKey">
                    <div class="row">

                        <!-- <ng-container *ngIf="insuranceCardFile">
                            <div class="col-lg-6 col-md-6 coll-sm-12">
                                <h5 class="head-color">Insurance Card</h5>
                                <iframe id="insurance-card" frameBorder="0" width="100%" height="500px"></iframe>
                                <img alt="Inusrance Card" style="width: 100%;" [src]="downloadImageCard(true, patientDetailsInfo?.insuranceDetails?.insuranceCardImageId)"/>
                            </div>
                        </ng-container> -->
                        <!-- <ng-container>
                            <div class="row"> -->

                        <div class="col-lg-12 col-md-12 col-sm-12  text-center">
                            <!--<h5 class="head-color">Driving Licence Card</h5>-->
                            <ng-container *ngIf="downloadedFileType === 'application/pdf'">
                                <iframe style="width: 100%" id="dl-card" frameBorder="0" width="300" height="200px"></iframe>
                            </ng-container>
                            <ng-container *ngIf="downloadedFileType !== 'application/pdf'">
                                <img style="padding: 0px 20px; width: 85%;" name="Image Shown" id="dl-card" />
                            </ng-container>
                            <!-- <img alt="Appointment Form Image" style="width: 100%;" [src]="downloadImageCard(false, patientDetailsInfo?.idDocument?.identityImageId)"/> -->
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 text-center mt-2">
                            <button class="icon-queue-events signoff-action-bg clearfix" placement="bottom" id="uploadIns_fullview"
                                ngbTooltip="Full View">
                                <i class="las la-expand "
                                    (click)="downloaFullview(drivingLicenseFile, insuraceCardModal)"></i>
                            </button>
                            <button class="icon-queue-events delete-action-bg" placement="bottom" id="uploadIns_delete"
                                ngbTooltip="Delete ID Card">
                                <i class="las la-trash-alt delete"
                                    (click)="deleteIDRecordOpen(patientDetailsInfo,deletConfirmDocument)"> </i>

                            </button>
                        </div>
                        <!-- </div>
                        </ng-container> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="custom-loader justify-content-center" *ngIf="loadSpinner">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<!-- insurance image shown -->
<app-ui-modal #insuraceCardModal [hideHeader]="false" [modalCentered]="true" id="insuraceCardModalId">
    <div class="app-modal-header">
        <h5 class="modal-title">Identity Document</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="insuraceCardModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <iframe id="uplolad-insurance-card-multiple" frameBorder="0" width="100%" height="500px"></iframe>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary dash-actions" style="margin-right: 15px; cursor: pointer;" data-dismiss="modal" id="uploadIns_ok1"
            (click)="insuraceCardModal.hide()">Ok</button>
    </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText"  [isSuccessNotify]="isSuccessNotify"></app-exception-modal>
<app-ui-modal #deletConfirmDocument [hideHeader]="false" [modalCentered]="true" id="deletConfirmDocument">
    <div class="app-modal-header">
        <!-- <h5 class="modal-title text-center">Are you sure want to delete Identification Record Details?</h5> -->
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="deletConfirmDocument?.hide();"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">

                <h5 class="modal-title text-info text-center" style="font-size: 50px;">
                    <!-- <i class="las la-exclamation-triangle"></i> -->
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>

                </h5>

            </div>
            <div class="col-lg-10 col-md-10 col-sm-10" style="margin-top: 16px;">

                <h5 class="modal-title" style=" text-align: left;">Are you sure you want to delete the Identification Record Details?</h5>

            </div>
        </div>

    </div>
    <div class="app-modal-footer">
        <button type="button" id="uploadIns_yes" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="deletConfirmDocument?.hide(); deletePatientIDDocument(selectedIDinfo,deletConfirmDocument)">Yes</button>
        <button type="button" id="uploadIns_no" class="btn btn-primary dash-actions" style="margin-right: 15px;" data-dismiss="modal"
            (click)="deletConfirmDocument.hide()">No</button>
    </div>
</app-ui-modal>