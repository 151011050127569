import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule, BreadcrumbModule, CardModule, ModalModule } from './components';
import { DataFilterPipe } from './components/data-table/data-filter.pipe';
import { TodoListRemoveDirective } from './components/todo/todo-list-remove.directive';
import { TodoCardCompleteDirective } from './components/todo/todo-card-complete.directive';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { ApexChartComponent } from './components/chart/apex-chart/apex-chart.component';
import { ApexChartService } from './components/chart/apex-chart/apex-chart.service';
import { ToastComponent } from './components/toast/toast.component';
import { ToastService } from './components/toast/toast.service';
import { GalleryComponent } from './components/gallery/gallery.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LightboxModule } from 'ngx-lightbox';
import { DataService } from './services/data-sharing.service';
import { HttpService } from './services/http.service';
import { AppService } from './services/app.service';
import { AuthGuard } from './auth/auth.guard';
import { LogoutGuard } from './auth/logout.guard';
import { HospAddAppntComponent } from './hop-common-components/hosp-add-appnt/hosp-add-appnt.component';
import { HospDemographicsComponent } from './hop-common-components/hosp-demographics/hosp-demographics.component';
import { HospInsuranceComponent } from './hop-common-components/hosp-insurance/hosp-insurance.component';
/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { CancelAppointmentComponent } from './hop-common-components/cancel-appointment/cancel-appointment.component';
import { ReScheduleAppointmentComponent } from './hop-common-components/re-schedule-appointment/re-schedule-appointment.component';
import { ExistingPatientSearchComponent } from './hop-common-components/existing-patient-search/existing-patient-search.component';
import { HospInitiateReferralComponent } from './hop-common-components/hosp-initiate-referral/hosp-initiate-referral.component';
import { ExceptionModalComponent } from './components/exception-modal/exception-modal.component';
import { NgbAccordionModule, NgbCollapseModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RxLabOrderComponent } from './hop-common-components/rx-lab-order/rx-lab-order.component';
import { Interceptor } from './services/interceptor';
import { HealthpassPatientSearchComponent } from './hop-common-components/healthpass-patient-search/healthpass-patient-search.component';
import { FamilyMembersDetailsComponent } from './hop-common-components/family-members-details/family-members-details.component';
import { ScheduleFollowupAppointmentComponent } from './hop-common-components/schedule-followup-appointment/schedule-followup-appointment.component';
import { FollowupNotificationComponent } from './hop-common-components/followup-notification/followup-notification.component';

import { FollowupNotesComponent } from './hop-common-components/followup-notes/followup-notes.component';
import { WebcamModule } from 'ngx-webcam';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// Masking
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EligibilityCheckComponent } from './hop-common-components/eligibility-check/eligibility-check.component';
const maskConfig: Partial<IConfig> = {
  validation: false,
};

import { KioskGuard } from './auth/kiosk.guard';
import { PaymentComponent } from './hop-common-components/payment/payment.component';
import { SendSmsNotifyComponent } from './hop-common-components/send-sms-notify/send-sms-notify.component';
import { ClaimSubmissionComponent } from './hop-common-components/claim-submission/claim-submission.component';
import { PastVisitsComponent } from './hop-common-components/past-visits/past-visits.component';
import { ArchwizardModule } from 'angular-archwizard';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddressComponent } from './components/address/address.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SchedulerModule } from 'angular-calendar-scheduler';

import { UploadInsuranceDrivingDocsComponent } from './hop-common-components/upload-insurance-driving-docs/upload-insurance-driving-docs.component';
import { AdditionalInsurancesComponent } from './hop-common-components/additional-insurances/additional-insurances.component';
import { PurposeOfVisitUpdateComponent } from './hop-common-components/purpose-of-visit-update/purpose-of-visit-update.component';
import { FinalCheckListComponent } from './hop-common-components/final-check-list/final-check-list.component';
import { UpdateVisitDoctorComponent } from './hop-common-components/update-visit-doctor/update-visit-doctor.component';
import { BreadcrumbCustomComponent } from './hop-common-components/breadcrumb-custom/breadcrumb-custom.component';
import { AppointmentHistoryForUserComponent } from './hop-common-components/appointment-history-for-user/appointment-history-for-user.component';
import { FutureAppointmentsForUserComponent } from './hop-common-components/future-appointments-for-user/future-appointments-for-user.component';
import { DataTablesModule } from 'angular-datatables';
import { UploadAdditionalDocumentsComponent } from './hop-common-components/upload-additional-documents/upload-additional-documents.component';
import { PendingPaymentsForUserComponent } from './hop-common-components/pending-payments-for-user/pending-payments-for-user.component';
import { PreferredPharmacyComponent } from './hop-common-components/preferred-pharmacy/preferred-pharmacy.component';
import { AutoFocus } from './directives/autofocus.directive';
import { PatientResponsibilityComponent } from './hop-common-components/patient-responsibility/patient-responsibility.component';
//import { WaitTimeComponent } from '../layout/admin/nav-bar/wait-time/wait-time.component';
import { ViewConsentFormsComponent } from './hop-common-components/view-consent-forms/view-consent-forms.component';
import { NotificationsViewComponent } from '../layout/admin/nav-bar/notifications-view/notifications-view.component';
import { NotificationRequestResponseComponent } from './hop-common-components/notification-request-response/notification-request-response.component';
import { VisitTypeUpdateComponent } from './hop-common-components/visit-type-update/visit-type-update.component';
import { HospDemographicsUpdateComponent } from './hop-common-components/hosp-demographics-update/hosp-demographics-update.component';
import { ViewSubmittedClaimDetailsComponent } from './hop-common-components/view-submitted-claim-details/view-submitted-claim-details.component';
import { PostChargesUpdateComponent } from './hop-common-components/post-charges-update/post-charges-update.component';
import { SendFaxCommonComponent } from './hop-common-components/send-fax-common/send-fax-common.component';
import { ViewCommonAppointmentDetailsComponent } from './hop-common-components/view-common-appointment-details/view-common-appointment-details.component';
import { PaymentsCommonNewComponent } from './hop-common-components/payments-common-new/payments-common-new.component';
import { UserVisitDetailsComponent } from './hop-common-components/user-visit-details/user-visit-details.component';
import { ClaimPrimarySecondaryCorrectedSubmissionComponent } from './hop-common-components/claim-primary-secondary-corrected-submission/claim-primary-secondary-corrected-submission.component';
import { LabRadOrderDetailsModalComponent } from './hop-common-components/lab-rad-order-details-modal/lab-rad-order-details-modal.component';
import { InternalLabOrderResultsModalComponent } from './hop-common-components/internal-lab-order-results-modal/internal-lab-order-results-modal.component';
import { LabRadResultsModalComponent } from './hop-common-components/lab-rad-results-modal/lab-rad-results-modal.component';
import { Hl7DetailsComponent } from './hop-common-components/hl7-details/hl7-details.component';
import { CommomPastVisitDetailsWizardComponent } from './hop-common-components/commom-past-visit-details-wizard/commom-past-visit-details-wizard.component';
import { EmergencyContactDetailsComponent } from './hop-common-components/emergency-contact-details/emergency-contact-details.component';
import { DocumentReviewComponent } from './hop-common-components/document-review/document-review.component';

import { NewTaskComponent } from './hop-common-components/new-task/new-task.component';
import { PendingSignoffListComponent } from './hop-common-components/pending-signoff-list/pending-signoff-list.component';
import { TasksListComponent } from './hop-common-components/tasks-list/tasks-list.component';
import { DocumentNotificationRequestResponseComponent } from './hop-common-components/document-notification-request-response/document-notification-request-response.component';
import { AccountBalanceComponent } from './hop-common-components/account-balance/account-balance.component';
import { BillingCodesComponent } from './hop-common-components/billing-codes/billing-codes.component';
import { AccordianPastVisitDetailsWizardComponent } from './hop-common-components/accordian-past-visit-details-wizard/accordian-past-visit-details-wizard.component';
import { AddendumNotesComponent } from './hop-common-components/addendum-notes/addendum-notes.component';
import { CanDeactivateFormGuard } from './auth/can-deactivate-form.guard';
import { CareflowQueueComponent } from './hop-common-components/careflow-queue/careflow-queue.component';
import { PatientBasicInformationComponent } from './hop-common-components/patient-basic-information/patient-basic-information.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { CaregapsListComponent } from './hop-common-components/caregaps-list/caregaps-list.component';
import { EncountersMedicalRecordsComponent } from './hop-common-components/encounters-medical-records/encounters-medical-records.component';
import { FacilityActionComponent } from './hop-common-components/facility-action/facility-action.component';
import { ClaimJournalComponent } from './hop-common-components/claim-journal/claim-journal.component';
import { MoveClaimsQueueComponent } from './hop-common-components/move-claims-queue/move-claims-queue.component';
import { CommonUploadDocComponent } from './hop-common-components/common-upload-doc/common-upload-doc.component';
import { FollowupqueueListComponent } from './hop-common-components/followupqueue-list/followupqueue-list.component';
import { ApisService } from './services/apis.services';
import { VerifyReportsComponent } from './hop-common-components/verify-reports/verify-reports.component';
import { ViewAppointmentDetailsComponent } from './hop-common-components/view-appointment-details/view-appointment-details.component';
import { AddendumNotesChartComponent } from './hop-common-components/addendum-notes-chart/addendum-notes-chart.component';
import { CommonInsuranceAddlComponent } from './hop-common-components/common-insurance-addl/common-insurance-addl.component';
import { carepathwaysListComponent } from './hop-common-components/carepathways-list/carepathways-list.component';
import { AllChartDocumentsForUserComponent } from './hop-common-components/all-chart-documents-for-user/all-chart-documents-for-user.component';
import { CommonProfilePicUploadComponent } from './hop-common-components/common-profile-pic-upload/common-profile-pic-upload.component';
import { CommonProfilePicUploadCareflowComponent } from './hop-common-components/common-profile-pic-upload-careflow/common-profile-pic-upload-careflow.component';
import { AccountBalanceNewComponent } from './hop-common-components/account-balance-new/account-balance-new.component';
import { TwoDigitDecimaNumberDirective } from './directives/two-decimal.directive';
import { ClaimAddressComponent } from './components/claim-address/claim-address.component';
import { CareOutreachService } from './services/careoutreach.service';


import { ReviewCcComponent } from './hop-common-components/review-cc/review-cc.component';
import { ReviewRosComponent } from './hop-common-components/review-ros/review-ros.component';
import { ReviewVitalsComponent } from './hop-common-components/review-vitals/review-vitals.component';
import { ReviewExamComponent } from './hop-common-components/review-exam/review-exam.component';
import { ReviewBehaviousscreeningComponent } from './hop-common-components/review-behaviousscreening/review-behaviousscreening.component';
import { ReviewOrdersComponent } from './hop-common-components/review-orders/review-orders.component';
import { ReviewCareplanComponent } from './hop-common-components/review-careplan/review-careplan.component';
import { ReviewHpiComponent } from './hop-common-components/review-hpi/review-hpi.component';
import { ReviewInhouselabsComponent } from './hop-common-components/review-inhouselabs/review-inhouselabs.component';
import { ReviewImmunizationsComponent } from './hop-common-components/review-immunizations/review-immunizations.component';
import { ReviewInhouseProceduresComponent } from './hop-common-components/review-inhouse-procedures/review-inhouse-procedures.component';
import { ReviewAssessmentComponent } from './hop-common-components/review-assessment/review-assessment.component';
import { ReviewPatientInfoComponent } from './hop-common-components/review-patient-info/review-patient-info.component';
import { FollowupReviewComponent } from './hop-common-components/followup-review/followup-review.component';
import { ReviewLegacyMedicalRecordComponent } from './hop-common-components/review-legacy-medical-record/review-legacy-medical-record.component';
import { ShortWordsComponent } from './hop-common-components/short-words/short-words.component';
import { ClaimJournalDetailsComponent } from './hop-common-components/claim-journal-details/claim-journal-details.component';
import { FaxReferralCommonComponent } from './hop-common-components/fax-referral-common/fax-referral-common.component';
import { YyymmddPipe } from './pipes/yyymmdd.pipe';
import { ClaimSubmissionNewComponent } from './hop-common-components/claim-submission-new/claim-submission-new.component';
import { ClaimsService } from './services/claims.service';
import { PaymentsRefactorComponent } from './hop-common-components/payments-refactor/payments-refactor.component';
import { FacilityPaymentComponent } from './hop-common-components/facility-payment/facility-payment.component';
import { EobDocumentCheckUpdateComponent } from './hop-common-components/eob-document-check-update/eob-document-check-update.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular/components/powerbi-report-embed/powerbi-report-embed.component';
import { PowerBIEmbedComponent } from 'powerbi-client-angular/components/powerbi-embed/powerbi-embed.component';
import { HandoutsDocsComponent } from './hop-common-components/handouts-docs/handouts-docs.component';
import { EncounterViewInsuranceEditComponent } from './hop-common-components/encounter-view-insurance-edit/encounter-view-insurance-edit.component';
import { InsuranceEditFormComponent } from './hop-common-components/insurance-edit-form/insurance-edit-form.component';
import { AllAppointmentsListComponent } from './hop-common-components/all-appointments-list/all-appointments-list.component';
import { CaremanagementListComponent } from './hop-common-components/caremanagement-list/caremanagement-list.component';


@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    ClickOutsideModule,
    LightboxModule,
    HttpClientModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbTooltipModule,
    NgxMaskModule.forRoot(maskConfig),
    NgbModule,
    NgbAccordionModule,
    NgbCollapseModule,
    ArchwizardModule,
    NgSelectModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange' }),
    DataTablesModule,
    NgxBarcodeModule,
    WebcamModule,
    PowerBIEmbedModule
  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    ClickOutsideModule,
    SpinnerComponent,
    ApexChartComponent,
    GalleryComponent,
    ToastComponent,
    HttpClientModule,
    HospAddAppntComponent,
    HospDemographicsComponent,
    HospInsuranceComponent,
    TextMaskModule,
    NgNumberFormatterModule,
    CancelAppointmentComponent,
    ReScheduleAppointmentComponent,
    ExistingPatientSearchComponent,
    HospInitiateReferralComponent,
    ExceptionModalComponent,
    RxLabOrderComponent,
    EligibilityCheckComponent,
    PaymentComponent,
    SendSmsNotifyComponent,
    ClaimSubmissionComponent,
    PastVisitsComponent,
    AddressComponent,
    UploadInsuranceDrivingDocsComponent,
    AdditionalInsurancesComponent,
    PurposeOfVisitUpdateComponent,
    FinalCheckListComponent,
    UpdateVisitDoctorComponent,
    BreadcrumbCustomComponent,
    AppointmentHistoryForUserComponent,
    FutureAppointmentsForUserComponent,
    UploadAdditionalDocumentsComponent,
    PendingPaymentsForUserComponent,
    PreferredPharmacyComponent,
    PatientResponsibilityComponent,
    ViewConsentFormsComponent,
    NotificationsViewComponent,
    NotificationRequestResponseComponent,
    VisitTypeUpdateComponent,
    HospDemographicsUpdateComponent,
    ViewSubmittedClaimDetailsComponent,
    PostChargesUpdateComponent,
    SendFaxCommonComponent,
    ViewCommonAppointmentDetailsComponent,
    UserVisitDetailsComponent,
    PaymentsCommonNewComponent,
    PaymentsCommonNewComponent,
    ClaimPrimarySecondaryCorrectedSubmissionComponent,
    LabRadOrderDetailsModalComponent,
    InternalLabOrderResultsModalComponent,
    HealthpassPatientSearchComponent,
    NewTaskComponent,
    LabRadResultsModalComponent,
    Hl7DetailsComponent,
    CommomPastVisitDetailsWizardComponent,
    EmergencyContactDetailsComponent,
    DocumentReviewComponent,
    PendingSignoffListComponent,
    TasksListComponent,
    DocumentNotificationRequestResponseComponent,
    FamilyMembersDetailsComponent,
    AccountBalanceComponent,
    AddendumNotesComponent,
    AccordianPastVisitDetailsWizardComponent,
    CareflowQueueComponent,
    PatientBasicInformationComponent,
    ScheduleFollowupAppointmentComponent,
    FollowupNotificationComponent,
    CaregapsListComponent,
    FollowupqueueListComponent,
    FollowupNotesComponent,
    EncountersMedicalRecordsComponent,
    FacilityActionComponent,
    ClaimJournalComponent,
    MoveClaimsQueueComponent,
    CommonUploadDocComponent,
    VerifyReportsComponent,
    ViewAppointmentDetailsComponent,
    CommonInsuranceAddlComponent,
    carepathwaysListComponent,
    AddendumNotesChartComponent,
    AllChartDocumentsForUserComponent,
    CommonProfilePicUploadComponent,
    CommonProfilePicUploadCareflowComponent,
    AccountBalanceNewComponent,
    TwoDigitDecimaNumberDirective,
    ClaimAddressComponent,
    ReviewCcComponent,
    ReviewRosComponent,
    ReviewVitalsComponent,
    ReviewExamComponent,
    ReviewBehaviousscreeningComponent,
    ReviewOrdersComponent,
    ReviewCareplanComponent,
    ReviewHpiComponent,
    ReviewInhouselabsComponent,
    ReviewImmunizationsComponent,
    ReviewInhouseProceduresComponent,
    ReviewPatientInfoComponent,
    FollowupReviewComponent,
    ReviewLegacyMedicalRecordComponent,
    ShortWordsComponent,
    ClaimJournalDetailsComponent,
    FaxReferralCommonComponent,
    YyymmddPipe,
    ClaimSubmissionNewComponent,
    PaymentsRefactorComponent,
    FacilityPaymentComponent,
    EobDocumentCheckUpdateComponent,
    HandoutsDocsComponent,
    EncounterViewInsuranceEditComponent,
    InsuranceEditFormComponent,
    AllAppointmentsListComponent,
    CaremanagementListComponent
  ],
  declarations: [
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    SpinnerComponent,
    ApexChartComponent,
    ToastComponent,
    GalleryComponent,
    HospAddAppntComponent,
    HospDemographicsComponent,
    HospInsuranceComponent,
    CancelAppointmentComponent,
    ReScheduleAppointmentComponent,
    ExistingPatientSearchComponent,
    HospInitiateReferralComponent,
    ExceptionModalComponent,
    RxLabOrderComponent,
    EligibilityCheckComponent,
    PaymentComponent,
    SendSmsNotifyComponent,
    ClaimSubmissionComponent,
    PastVisitsComponent,
    AddressComponent,
    UploadInsuranceDrivingDocsComponent,
    AdditionalInsurancesComponent,
    PurposeOfVisitUpdateComponent,
    FinalCheckListComponent,
    UpdateVisitDoctorComponent,
    BreadcrumbCustomComponent,
    AppointmentHistoryForUserComponent,
    FutureAppointmentsForUserComponent,
    UploadAdditionalDocumentsComponent,
    PendingPaymentsForUserComponent,
    PreferredPharmacyComponent,
    AutoFocus,
    PatientResponsibilityComponent,
    //WaitTimeComponent,
    ViewConsentFormsComponent,
    NotificationsViewComponent,
    ViewConsentFormsComponent,
    NotificationsViewComponent,
    NotificationRequestResponseComponent,
    HospDemographicsUpdateComponent,
    ViewSubmittedClaimDetailsComponent,
    PostChargesUpdateComponent,
    VisitTypeUpdateComponent,
    SendFaxCommonComponent,
    ViewCommonAppointmentDetailsComponent,
    UserVisitDetailsComponent,
    PaymentsCommonNewComponent,
    PaymentsCommonNewComponent,
    ClaimPrimarySecondaryCorrectedSubmissionComponent,
    LabRadOrderDetailsModalComponent,
    InternalLabOrderResultsModalComponent,
    HealthpassPatientSearchComponent,
    NewTaskComponent,
    LabRadResultsModalComponent,
    Hl7DetailsComponent,
    CommomPastVisitDetailsWizardComponent,
    EmergencyContactDetailsComponent,
    DocumentReviewComponent,
    PendingSignoffListComponent,
    TasksListComponent,
    DocumentNotificationRequestResponseComponent,
    FamilyMembersDetailsComponent,
    AccountBalanceComponent,
    BillingCodesComponent,
    AddendumNotesComponent,
    AccordianPastVisitDetailsWizardComponent,
    CareflowQueueComponent,
    PatientBasicInformationComponent,
    ScheduleFollowupAppointmentComponent,
    FollowupNotificationComponent,
    CaregapsListComponent,
    FollowupqueueListComponent,
    FollowupNotesComponent,
    EncountersMedicalRecordsComponent,
    FacilityActionComponent,
    ClaimJournalComponent,
    MoveClaimsQueueComponent,
    CommonUploadDocComponent,
    VerifyReportsComponent,
    ViewAppointmentDetailsComponent,
    AddendumNotesChartComponent,
    CommonInsuranceAddlComponent,
    carepathwaysListComponent,
    AllChartDocumentsForUserComponent,
    CommonProfilePicUploadComponent,
    CommonProfilePicUploadCareflowComponent,
    AccountBalanceNewComponent,
    TwoDigitDecimaNumberDirective,
    ClaimAddressComponent,
    ReviewCcComponent,
    ReviewRosComponent,
    ReviewVitalsComponent,
    ReviewExamComponent,
    ReviewBehaviousscreeningComponent,
    ReviewOrdersComponent,
    ReviewCareplanComponent,
    ReviewHpiComponent,
    ReviewInhouselabsComponent,
    ReviewImmunizationsComponent,
    ReviewInhouseProceduresComponent,
    ReviewAssessmentComponent,
    ReviewPatientInfoComponent,
    FollowupReviewComponent,
    ReviewLegacyMedicalRecordComponent,
    ShortWordsComponent,
    ClaimJournalDetailsComponent,
    FaxReferralCommonComponent,
    YyymmddPipe,
    ClaimSubmissionNewComponent,
    PaymentsRefactorComponent,
    FacilityPaymentComponent,
    EobDocumentCheckUpdateComponent,
    HandoutsDocsComponent,
    EncounterViewInsuranceEditComponent,
    InsuranceEditFormComponent,
    AllAppointmentsListComponent,
    CaremanagementListComponent
  ],

  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ApexChartService,
    ToastService,
    DataService,
    HttpService,
    ApisService,
    { provide: AuthGuard, useClass: AuthGuard },
    { provide: KioskGuard, useClass: KioskGuard },
    AppService,
    { provide: LOCALE_ID, useValue: 'en-US' },
    LogoutGuard,
    CanDeactivateFormGuard,
    CareOutreachService,
    ClaimsService,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
  ]
})
export class SharedModule { }
