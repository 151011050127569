import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-purpose-of-visit-update',
  templateUrl: './purpose-of-visit-update.component.html',
  styleUrls: ['./purpose-of-visit-update.component.scss']
})
export class PurposeOfVisitUpdateComponent implements OnInit {

  public purposeofvisitForm: FormGroup;
  public appoitnmentDetails: any;
  @ViewChild('purposeOfVisitModal') purposeOfVisitModal: UiModalComponent;
  public addPatientPurposeVisit: any[];
  public adPatientAllDoctors: any[];
  public loadSpinner: boolean;
  public notifyText: string;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) exceptionModal: ExceptionModalComponent;

  @Output() reloadAppointmentQueue = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
    this.purposeofvisitForm = this.formBuilder?.group({
      'purpose': [null, Validators?.required],
      // 'doctor': [null, Validators?.required]
    });
  }

  ngOnInit(): void {
    const waitTimeDetails = JSON.parse(sessionStorage?.getItem('waitTimeDetails'));
    this.addPatientPurposeVisit = (waitTimeDetails && waitTimeDetails?.purposeOfVisit) || [];
	
  }

  // get doctors list based on purpose of visit
  public getDoctorsList(): void {
    const purposeOfVisit = this.purposeofvisitForm?.value?.purpose || '';
    // this.purposeofvisitForm?.get('doctor')?.reset();
    const action = `npi/getvalidNPIforvisit?facilityId=${this.appoitnmentDetails?.facilityId}&newPatient=${this.appoitnmentDetails?.newPatient}&purposeOfVisit=`;
    this.loadSpinner = true;
    this.httpService.makeGetRequest(action, purposeOfVisit).subscribe((data: any) => {
      this.adPatientAllDoctors = (data && data.npiDoctorList) || [];
      this.loadSpinner = false;
    }, error => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
    });
  }

  public openModal(appoitnmentDetails: any): void {
    this.appoitnmentDetails = appoitnmentDetails;
    this.purposeofvisitForm?.get('purpose')?.setValue(appoitnmentDetails?.appointment?.purposeOfVisit);
    // this.getDoctorsList();
    // this.purposeofvisitForm?.get('doctor')?.setValue(appoitnmentDetails?.appointment?.doctorNpi);
    const waitTimeDetails = JSON.parse(sessionStorage?.getItem('waitTimeDetails'));
    this.addPatientPurposeVisit = (waitTimeDetails && waitTimeDetails?.purposeOfVisit) || [];
    this.purposeOfVisitModal?.show();
  }

  public updatePurposeOfVisit(): void {
    const action = 'appointment/registration/updateAppointment';
    if (this.purposeofvisitForm?.invalid) {
      this.purposeofvisitForm?.markAllAsTouched();
      return;
    }
    const params = { ...this.appoitnmentDetails };
    params.appointment.purposeOfVisit = this.purposeofvisitForm?.get('purpose')?.value;
    // params.appointment.doctorNpi = this.purposeofvisitForm?.get('doctor')?.value;

    this.loadSpinner = true;
    this.httpService?.makeRequestWithAction(action, params)?.subscribe((data: any) => {
      if (data?.body?.status == 'SUCCESS') {
        this.notifyText = "Purpose of Visit has been updated successfully.";
        this.isSuccessNotify = true;
        this.exceptionModal?.modalShow();
      } else {
        this.notifyText = data?.body?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.loadSpinner = false;
      this.purposeOfVisitModal?.hide();
      this.reloadAppointmentQueue?.emit(true);
    },
      error => {
        this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
        this.reloadAppointmentQueue?.emit(true);
      });
  }

}
