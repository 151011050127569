import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UiModalComponent } from '../../components/modal/ui-modal/ui-modal.component';
import { ApisService } from '../../services/apis.services';
import { AppConstantsListConfig } from '../../litterals/app.constants';
import { ExceptionModalComponent } from '../../components/exception-modal/exception-modal.component';
import { HttpService } from '../../services/http.service';
import { CardComponent } from '../../components/card/card.component';

@Component({
  selector: 'app-handouts-docs',
  templateUrl: './handouts-docs.component.html',
  styleUrls: ['./handouts-docs.component.scss']
})
export class HandoutsDocsComponent implements OnInit {

  @ViewChild('handoutModal') handoutModal: UiModalComponent;
  public allHandoutDocs: any;
  public notifyText: any;
  public isSuccessNotify: boolean;
  @ViewChild(ExceptionModalComponent) public exceptionModal: ExceptionModalComponent;
  public loadSpinner: boolean = false;
  @Input() PatientDetailsCardReload: CardComponent;
  selectedIndexActive: number;

  constructor(private apisService: ApisService, private httpService: HttpService) { }

  ngOnInit(): void {

  }

  public openHandoutsModal(): void {
    this.getAllHandoutsDocs();
  }

  // List all the handous docs
  private getAllHandoutsDocs(): void {
    this.loadSpinner = true;
    this.PatientDetailsCardReload?.cardRefreshShow();
    this.apisService?.getListOfDocuments('Handout')?.subscribe((data: any) => {
      this.loadSpinner = false;
      if (data?.status === 'SUCCESS') {
        this.allHandoutDocs = data?.responseObject || [];
        if (this.allHandoutDocs && this.allHandoutDocs?.length > 0) {
          this.handoutModal?.show();
          this.viewSelectedDoc(this.allHandoutDocs[0], 0);
        } else {
          this.notifyText = 'No Handouts documents are available.';
          this.isSuccessNotify = false;
          this.exceptionModal?.modalShow();
        }
      } else {
        this.notifyText = data?.message || AppConstantsListConfig?.uiErrorException;
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
      }
      this.PatientDetailsCardReload?.cardRefreshHide();
    }, (error) => {
      this.notifyText = error?.message || AppConstantsListConfig?.uiErrorException;
      this.isSuccessNotify = false;
      this.exceptionModal?.modalShow();
      this.loadSpinner = false;
      this.PatientDetailsCardReload?.cardRefreshHide();
    });
  }

  // Download and show the selected doc
  public viewSelectedDoc(upDoc: any, index: number) {
    if (!upDoc?.s3Key || !upDoc?.s3Path) {
      return;
    }
    this.selectedIndexActive = index;
    this.loadSpinner = true;
    const action = `record/downloadDocument?s3Key=${upDoc?.s3Key}&s3Path=${upDoc?.s3Path}`;
    this.httpService?.downloadImage(action)?.subscribe((data: any) => {
      if (!data) {
        this.loadSpinner = false;
        return;
      }
      const file = new Blob([data], { type: (upDoc?.fileType === 'pdf' || upDoc?.fileType === 'PDF') ? 'application/pdf' : upDoc?.fileType });
      const fileURL = URL?.createObjectURL(file);
      const iframeEle = document?.getElementById('handouts-doc-pdf') as HTMLElement;
      iframeEle?.setAttribute('src', fileURL);
      this.loadSpinner = false;
    },
      (error: any) => {
        this.notifyText = error?.message || 'User does not have privileges to perform action';
        this.isSuccessNotify = false;
        this.exceptionModal?.modalShow();
        this.loadSpinner = false;
      });
  }

}
