<ul class="navbar-nav ml-auto">
  <!-- <li>
    <a href="https://github.com/phoenixcoded/flat-able-free-angular-admin-template" target="_blank" class="">
      <i class="icon feather icon-github pulse-button"></i>
    </a>
  </li> -->
  <!-- <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" style="display: none;">
        <i class="icon feather icon-bell"></i>
        <span class="badge badge-pill badge-danger">5</span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
          <div class="float-right">
            <a href="javascript:" class="m-r-10">mark as read</a>
            <a href="javascript:">clear all</a>
          </div>
        </div>
        <ul class="noti-body">
          <li class="n-title">
            <p class="m-b-0">NEW</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>John Doe</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>New ticket Added</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>Prchace New Theme and make payment</p>
              </div>
            </div>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Sara Soudein</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>currently login</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="noti-footer">
          <a href="javascript:">show all</a>
        </div>
      </div>
    </div>
  </li> -->
  <li style="padding-left: 0px;">
    <div class="drp-user dropdown" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle>
        <div class="user-avatar">
          <i class="fas fa-user-md"></i>
          <!-- <div class="name-user" (click)="isNavProfile = !isNavProfile">
            <span class="name-chev-up">{{loginUserName}} &nbsp;<i class="fa m-l-5"
                [ngClass]="{'fa-chevron-up': isNavProfile, 'fa-chevron-down': !isNavProfile }"></i></span>
            <span>{{loginUserType}}</span>
          </div> -->

          <div class="profile-tooltip">
            <p><b>{{loginUserName}}</b></p>
            <p><b>{{loginUserType}}</b></p>
            <p>{{loginUserId}}</p>
            <span class="material-symbols-outlined arrow">
              arrow_drop_up
            </span>
          </div>

        </div>

      </a>

      <div class=" dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <span>
            <div class="row">
              <div class="col-md-12">
                {{loginUserId}}

              </div>
            </div>
          </span>
          <!-- <span>{{loginUserName}}</span> -->
          <a href="javascript:" class="dud-logout">
            <!-- <i class="feather icon-log-out"></i> -->
            <!-- <span class="material-symbols-outlined">
              close
            </span> -->
          </a>
        </div>
        <ul class="pro-body">

          <div class="user-pro">
            <div class="row">
              <div class="col-md-12">
                <i class="fas fa-user-md"></i>
                <!-- <span class="material-symbols-outlined ">
                    edit
                  </span> -->

              </div>
              <div class="col-md-12 user-name-margin">
                <div class="user-name"><b>{{loginUserName}}</b></div>
                <div class="user-name user-role"><b>{{loginUserType}}</b></div>
              </div>
              <div class="col-md-12">
                <button class="btn btn-profile" id="navRightProfile">Profile</button>
                <a class="btn btn-primary btn-usermanual" id="navRightUserManual" href="javascript:;" (click)="openUserManual()"><i class="las la-question-circle"></i> User Manual
                  </a>
              </div>
              <div class="col-md-12">
                <div class="btn-group">
                  <button class="btn btn-switch" id="navRightSwitchFacility" (click)="SwitchPortalPopup.show();getAllActiveFacilities()"><i
                      class="las la-random"></i> Switch Facility</button>
                  <button class="btn btn-logout" id="navRightLogout" (click)="logout()"><i class="feather icon-log-out"></i> Signout</button>
                </div>

              </div>

              <div class="col-md-12 privacy-terms">
                <span (click)="privacy()" class="privacy" id="navRightPrivacy"><b> Privacy Policy</b></span>
                <span (click)="terms()" class="term" id="navRightTerm"><b>Terms of Service</b></span>

              </div>
            </div>

          </div>


          <!-- <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li> -->

          <!-- <li><a href="javascript:" class="dropdown-item"><i class="feather icon-bell"></i> My Notifications</a></li> -->
          <!-- <li><a href="http://careeco.online/features/" class="dropdown-item" target="_blank"><i class="las la-question-circle"></i> User Manual</a></li> -->
          <!-- <li><a href="javascript:" class="dropdown-item" (click)="openUserManual()"><i class="las la-question-circle"></i> User Manual</a></li> -->

          <!-- <li><a href="javascript:;" (click)="openUserManual()" class="dropdown-item" target="_blank"><i
                class="las la-question-circle"></i> User Manual</a></li>
          <li><a href="javascript:" class="dropdown-item" (click)="SwitchPortalPopup.show();getAllActiveFacilities()"><i
                class="las la-random"></i> Switch Facility</a></li> -->

          <!-- <li><a href="javascript:" class="dropdown-item"><i class="feather icon-lock"></i> Lock Screen</a></li> -->
        </ul>
      </div>
    </div>
  </li>

</ul>


<app-ui-modal #SwitchPortalPopup [hideHeader]="false" [loadSpinner]="loadSpinner" id="SwitchPortalPopup">
  <div class="app-modal-header">
    <h5 class="modal-title">Switch Facility</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" id="navRightClose"
      (click)="SwitchPortalPopup.hide();this.switchPortal?.reset();closeModal() "><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body" [formGroup]="switchPortal">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="form-group">
          <label class="label-name">Facility Name <span class="required">*</span></label>
          <ng-select placeholder="Choose Facility Name" (change)="changeFacility($event)" formControlName="facilityName" id="navRightFacilityName"
            [items]="allActiveFacilities" bindLabel="facilityName" bindValue="facilityId"></ng-select>
          <!-- <input type="text" class="form-control input-text" name="facilityName" formControlName="facilityName"
                  placeholder="Facility Name"> -->
          <span class="required"
            *ngIf="switchPortal?.controls['facilityName']?.hasError('required') && switchPortal?.controls['facilityName']?.touched">Facility
            Name should be required</span>
        </div>
        <!-- <div class="form-group">
          <label>Role <span class="required">*</span></label>
          <ng-select placeholder="Choose Facility Name" formControlName="roleTypeVal"
            [items]="roleType" bindLabel="name" bindValue="name"></ng-select>
          <span class="required"
            *ngIf="switchPortal?.controls['roleTypeVal']?.hasError('required') && switchPortal?.controls['roleTypeVal']?.touched">Role
            type should be required</span>

        </div> -->
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="form-group text-left list-group" (click)="checkDefaultLoginFacility($event)">
          <div class="checkbox checkbox-primary d-inline">
            <input type="checkbox" name="defaultLogin" *ngIf="showblankCheckbox" id="default-login-check"
              formControlName="defaultLogin">
            <input type="checkbox" name="defaultLogin" *ngIf="enableCheckbox" id="default-login-check">
            <label for="default-login-check" class="cr list-name-label ml-2">Do you wish to make this as default login
              profile?</label>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="form-group text-left list-group">
          <div class="checkbox checkbox-primary d-inline">
            <input class="check-exam-comp" type="checkbox" name="defaultLogin" id="default-login-check"
              formControlName="defaultLogin" (click)="checkDefaultLoginFacility($event)">
            <label class="cr list-name-label ml-2" for="default-login-check">Do you wish to make this as default login
              profile
              ?</label>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" id="navRightSubmit" class="btn btn-primary dash-actions" data-dismiss="modal"
      (click)="changPortal()">Submit</button>
  </div>
</app-ui-modal>

<!-- notify popup -->
<app-exception-modal [notifyText]="notifyText" [isSuccessNotify]="isSuccessNotify"></app-exception-modal>